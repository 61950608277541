import { powerOfAttorneyAPI } from '../rest';
import { PoaChain, PoaRequest } from '../difinitions';

export interface CheckPoaChainStateProps {
	correlationId: string;
}
export type CheckPoaChainStateResult = Promise<{ success: boolean; data?: PoaChain }>;
export const checkPoaChainState = function ({ correlationId }: CheckPoaChainStateProps): CheckPoaChainStateResult {
	return new Promise((resolve) => {
		if (!correlationId) {
			resolve({ success: false });
			return;
		}

		const interval = setInterval(() => {
			const url = edi.utils.formatString(powerOfAttorneyAPI.CHAIN.GET, {
				id: correlationId
			});
			edi.rest
				.asyncSendRequest({
					url,
					method: 'GET'
				})
				.then(({ data, success }: { data: PoaRequest; success: boolean }) => {
					const state = data.data?.state;
					if (!success || (state && state !== 'PROCESSING' && success)) {
						clearInterval(interval);
						resolve({
							success,
							data: data.data
						});
					}
				});
		}, 1000);
	});
};
