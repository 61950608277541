import './scss/invitations.scss';

import {
	createGrid,
	createActionsColumnConfig,
	createGridActionBar,
	createGridCheckboxSelectionModel
} from '@UIkit/components/grid';
import { createSidebar } from '@UIkit/components/sidebar/Sidebar';
import { createTabPanel } from '@UIkit/components/tab';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createModalPanel } from '@UIkit/components/modal';
import { createContainer, createFieldBlock, FIELD_BLOCK_CLS, createModalForm } from '@UIkit/components/panels';
import { createLabel, createLabelBlock, createTextField } from '@UIkit/components/fields';
import { createModulePanel } from '@Components/panels';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { tourEvents, tours } from '@Ediweb/components/tour';
import { createActionsButton } from '@Components/buttons';

const INVITATIONS_MODULE_NAME = 'invitations';
const INVITATIONS_TOUR_TARGET_1 = 'invitations-tour-target-1';
const INVITATIONS_TOUR_TARGET_2 = 'invitations-tour-target-2';
const INVITATIONS_TOUR_TARGET_3 = 'invitations-tour-target-3';

Ext.namespace('edi.modules');
edi.modules[INVITATIONS_MODULE_NAME] = function () {
	let moduleData,
		filterForms = {},
		filterObjects = {},
		fireSearchObject = {},
		grids = {},
		gridNameTab,
		tabPanel,
		activeFilter;

	const sidebar = createSidebar({
		showCloseBtn: true,
		enableBackdrop: true,
		layout: {
			type: 'vbox',
			align: 'stretch'
		},
		width: edi.constants.DEFAULT.COMPONENTS_SETTINGS.SIDEBAR.WIDTH,
		parent: edi.core.getViewPort(),
		close: function () {
			if (sidebar.isToggle()) {
				sidebar.toggle(false);
			}
		}
	});

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		moduleData.getTourOpts = () => ({
			getGridName: () => gridNameTab,
			getGrid: () => grids[gridNameTab]
		});

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.invitations.on('change', gridDataChangedHandler);
		edi.events.invitations.on('activatetab', activateTab);
	};

	var rejectReasonModal = function (rejectAction, actionName) {
		var reason = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage('invitation.' + actionName + '.reason.subtitle'),
			items: [
				createTextField({
					emptyText: edi.i18n.getMessage('invitation.' + actionName + '.reason.emptyText'),
					isTextarea: true,
					name: 'reason',
					allowBlank: false
				})
			]
		});
		var formPanel = createModalForm({
			submitEmptyText: false,
			items: [reason]
		});
		var modal = createModalPanel({
			title: edi.i18n.getMessage('invitation.' + actionName + '.reason.title'),
			items: [formPanel],
			buttonsBefore: [
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage(
						actionName === 'reject' ? 'document.reject.document' : 'ediweb.form.btn.revoke'
					),
					handler: function () {
						var form = formPanel.getForm();
						if (!form.isValid()) {
							edi.core.showError('document.reject.reason.fill.fields.error');
						} else {
							var values = edi.utils.collectFormValues(form);
							modal.close();
							var data = {
								REJECT_REASON: values.reason
							};
							actionName === 'reject' ? (data.REJECT = true) : (data.REVOKE = true);
							rejectAction(data);
						}
					}
				})
			],
			closeButtonOptions: {
				hidden: actionName === 'revoke'
			}
		});
		modal.show();
	};

	var sentProcess = async function (record, action, data) {
		var url = edi.utils.formatString(
			edi.rest.services.INVITATIONS[action].PUT,
			{
				id: record.id
			},
			true
		);
		if (action === 'DELETE') {
			url = edi.utils.formatString(
				edi.rest.services.INVITATIONS.DELETE,
				{
					id: record.id
				},
				true
			);
		}
		data = data || {};

		if (action !== 'DELETE') {
			Object.assign(data, {
				STANDALONE: 'true'
			});
		}

		let sendRequestAction = function (data) {
			return new Promise((resolve, reject) => {
				edi.rest.sendRequest(
					url,
					action === 'DELETE' ? 'DELETE' : 'PUT',
					Ext.encode(data),
					function (data) {
						if (data && data.data) {
							if (
								!(
									data.data.hasOwnProperty('registeredOrganization') ||
									data.data.hasOwnProperty('connectedOrganization') ||
									data.data.hasOwnProperty('activeOrganization')
								)
							) {
								gridDataChangedHandler();
							} else {
								edi.core.showInfo('ediweb.invitations.try.later');
							}
							closeSidebar();
						} else {
							// failure(data);
							reject(data);
						}
						resolve(data);
					},
					function (err) {
						// failure(err);
						reject(err);
					}
				);
			});
		};

		if (action === 'REJECT' || action === 'REVOKE') {
			rejectReasonModal(await sendRequestAction, action.toLowerCase());
			return false;
		}

		try {
			await sendRequestAction(data);
		} catch (err) {
			throw err;
		}
	};
	var openSideBar = function (record) {
		let recData = (!!record && record.getData()) || {};
		moduleData.tab.setLoading();

		let showSidebar = function () {
			if (!moduleData.tab.isDestroyed) {
				sidebar.toggle(true);
				moduleData.tab.setLoading(false);
			}
		};

		var update = function (recData) {
			sidebar.removeAll();

			const title = (!!recData.senderName ? recData.senderName : recData.receiverName) || '';
			const sidebarTitle = edi.i18n.getMessage('ediweb.invitations.sidebar.title', [title]);

			const { state, statusKey } = edi.renderers._invitationStatus(recData);
			const renderStatus = edi.renderers.baseStateRenderer(state, edi.i18n.getMessage(statusKey));

			const renderRole = function (data, dir) {
				let direction = edi.utils.getDocumentDirection(recData.ediDocument.toOrg, recData.ediDocument.fromOrg);
				var side;
				if (direction === edi.constants.DIRECTIONS.INCOMING) {
					side = dir !== 'loop' ? 'senderRoles' : 'receiverRoles';
				} else {
					side = dir === 'loop' ? 'senderRoles' : 'receiverRoles';
				}
				let roles = edi.utils.getObjectProperty(data, `invitation.roleModel.${side}`);
				roles = roles
					.map((item) => {
						return edi.i18n.getMessage(`ediweb.invitations.${item}`);
					})
					.join(', ');

				return roles;
			};

			const getProduct = function () {
				let obj = edi.stores.initAllProductList().data.items?.find(function (item) {
					return item.get('name') === data.product;
				});

				return createContainer({
					layout: 'hbox',
					items: []
				});
			};

			sidebar.add(
				createContainer({
					flex: 1,
					width: 'auto',
					autoScroll: true,
					padding: 24,
					items: [
						createLabel({
							typography: 'heading_02',
							cls: 'test-sidebar-header-text',
							text: sidebarTitle,
							isBlock: true,
							margin: '0 0 24 0',
							padding: '0 24 0 0'
						}),
						createContainer({
							html: renderStatus,
							margin: '0 0 24 0'
						}),
						createContainer({
							cls: 'notification'
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('invitations.column.creationDate'),
							items: [
								createLabel({
									text: edi.renderers.dateTimeFromMs(+recData?.ediDocument?.creationDate)
								})
							],
							margin: '0 0 24 0'
						}),
						createFieldBlock({
							title: edi.i18n.getMessage(
								`document.${gridNameTab === 'incoming' ? 'sender' : 'receiver'}`
							),
							items: [
								createLabelBlock({
									gap: [4, 8],
									contents: [
										createLabel({
											text: recData.hasOwnProperty('senderName')
												? recData?.senderName
												: recData?.receiverName,
											isBlock: true
										}),
										createLabel({
											title: edi.i18n.getMessage('company.inn.short'),
											text: !!recData?.senderInn ? recData?.senderInn : recData?.receiverInn
										}),
										createLabel({
											title: edi.i18n.getMessage('company.kpp.short'),
											text: !!recData?.senderKpp ? recData?.senderKpp : recData?.receiverKpp
										})
									]
								})
							],
							margin: '0 0 24 0'
						}),
						gridNameTab === 'outgoing'
							? createFieldBlock({
									title: edi.i18n.getMessage('email'),
									items: [
										createLabel({
											text: recData?.invitation.parties.receiver.email
										})
									],
									margin: '0 0 24 0'
							  })
							: null,
						createFieldBlock({
							title: edi.i18n.getMessage('ediweb.invitations.your.role'),
							items: [
								createLabel({
									text: renderRole(recData, 'loop')
								})
							],
							margin: '0 0 24 0'
						}),
						createFieldBlock({
							title: edi.i18n.getMessage(
								`ediweb.invitations.${gridNameTab === 'incoming' ? 'senderRole' : 'receiverRole'}`
							),
							items: [
								createLabel({
									text: renderRole(recData)
								})
							],
							margin: '0 0 24 0'
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('invitations.column.product'),
							items: [
								createContainer({
									html: edi.renderers.getProductLogo(recData.product)
								})
							],
							margin: '0 0 24 0'
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('document.dsf.commentText'),
							items: [
								createLabel({
									text: recData?.invitation.comments?.invitationText
								})
							]
						})
					]
				})
			);

			var changeBtn = createButton({
				margin: '0 24 0 0',
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('ediweb.columns.action.edit'),
				glyph: edi.constants.ICONS.EDIT,
				handler: function () {
					closeSidebar();
					edi.core.openModule('invitations.person', recData, null, true);
				}
			});

			var sendBtn = createButton({
				margin: '0 24 0 0',
				cls: [BUTTON_CLS.primary],
				text: edi.i18n.getMessage('form.btn.send'),
				glyph: edi.constants.ICONS.SEND,
				handler: function () {
					sentProcess(recData, 'SENT', {
						doctime: +new Date()
					}).catch((err) => {
						closeSidebar();
						edi.core.logMessage(err, 'error');
						edi.core.showError(getResendingErrorText(err, recData));
					});
				}
			});

			var resendBtn = createButton({
				margin: '0 24 0 0',
				cls: [BUTTON_CLS.primary],
				text: edi.i18n.getMessage('ediweb.form.btn.resend'),
				handler: function () {
					sentProcess(recData, 'RESEND', {
						doctime: +new Date()
					}).catch((err) => {
						closeSidebar();
						edi.core.logMessage(err, 'error');
						edi.core.showError(getResendingErrorText(err, recData));
					});
				}
			});

			var acceptBtn = createButton({
				margin: '0 24 0 0',
				cls: [BUTTON_CLS.primary],
				text: edi.i18n.getMessage('ediweb.form.btn.accept'),
				glyph: edi.constants.ICONS.DONE_ALL,
				handler: function () {
					sentProcess(recData, 'PUSH').catch((err) => {
						closeSidebar();
						edi.core.logMessage(err, 'error');
						edi.core.showError(getResendingErrorText(err, recData));
					});
				}
			});

			var rejectBtn = createButton({
				margin: '0 24 0 0',
				cls: [BUTTON_CLS.secondary],
				text: edi.i18n.getMessage('document.reject.document'),
				glyph: edi.constants.ICONS.ANNUL,
				handler: function () {
					sentProcess(recData, 'REJECT').catch((err) => {
						closeSidebar();
						edi.core.logMessage(err, 'error');
						edi.core.showError(getResendingErrorText(err, recData));
					});
				}
			});

			var revokeBtn = createButton({
				margin: '0 24 0 0',
				cls: [BUTTON_CLS.primary],
				text: edi.i18n.getMessage('ediweb.form.btn.revoke'),
				handler: function () {
					sentProcess(recData, 'REVOKE').catch((err) => {
						closeSidebar();
						edi.core.logMessage(err, 'error');
						edi.core.showError(getResendingErrorText(err, recData));
					});
				}
			});

			var copyBtn = createButton({
				margin: '0 24 0 0',
				cls: [BUTTON_CLS.secondary],
				text: edi.i18n.getMessage('ediweb.form.btn.copy'),
				glyph: edi.constants.ICONS.COPY,
				handler: function () {
					sentProcess(recData, 'COPY')
						.then(() => {
							edi.core.showInfo('ediweb.continue.to.draft');
							edi.events.invitations.fireEvent('change');
						})
						.catch((err) => {
							closeSidebar();
							edi.core.logMessage(err, 'error');
							edi.core.showError(getResendingErrorText(err, recData));
						});
				}
			});

			let direction = edi.utils.getDocumentDirection(recData.ediDocument.toOrg, recData.ediDocument.fromOrg);
			let buttons = [];

			if (recData.state === edi.constants.INVITATIONS.STATE.DRAFT) {
				buttons = [changeBtn, sendBtn];
			} else if (direction === edi.constants.DIRECTIONS.OUTGOING || direction === edi.constants.DIRECTIONS.LOOP) {
				switch (recData.state) {
					case edi.constants.INVITATIONS.STATE.SENT:
					case edi.constants.INVITATIONS.STATE.EXPECTATION:
						buttons = [revokeBtn];
						break;
					case edi.constants.INVITATIONS.STATE.REJECTED:
					case edi.constants.INVITATIONS.STATE.REVOKE:
					case edi.constants.INVITATIONS.STATE.EXPIRED:
						buttons = [resendBtn, copyBtn];
				}
			} else if (direction === edi.constants.DIRECTIONS.INCOMING) {
				if (recData.state === edi.constants.INVITATIONS.STATE.SENT) {
					buttons = [acceptBtn, rejectBtn];
				}
			}

			if (buttons.length > 0) {
				sidebar.add(
					createContainer({
						layout: 'hbox',
						cls: 'edi-ediweb-sidebar-buttons',
						items: buttons
					})
				);
			}
		};

		let renderNotification = function (recData) {
			let items = [];
			let org = edi.utils.getObjectProperty(recData, 'ediDocument.fromOrg');
			let isFromOrg = org.id === edi.core.getUserOrgID();
			let directionAgreement = isFromOrg ? 'senderAgreementWaiting' : 'receiverAgreementWaiting';

			if (recData?.agreementWaiting) {
				Object.entries(recData.agreementWaiting).forEach(([key, val]) => {
					for (let i in val) {
						let documentId = val[i]?.id;
						let confBtn = {
							text: edi.utils.formatString(
								edi.i18n.getMessage('ediweb.invitations.waiting.sign.document'),
								{
									document: edi.i18n.getMessage(
										`documents.doctype.${edi.constants.DOCUMENT_TYPES[val[i].docType]}`
									),
									receiver: edi.utils.getObjectProperty(
										recData,
										`ediDocument.${key === 'senderAgreementWaiting' ? 'fromOrg' : 'toOrg'}.name`
									)
								}
							)
						};

						if (key === directionAgreement && !!documentId) {
							Object.assign(confBtn, {
								button: {
									text: edi.i18n.getMessage('ediweb.columns.action.details'),
									handler: function () {
										moduleData.tab.setLoading();
										edi.rest.sendRequest(
											edi.utils.formatString(
												edi.rest.services.DOCUMENTS.HEADER.GET,
												{
													documentId: documentId
												},
												true
											),
											'GET',
											undefined,
											function (data) {
												if (data && data.data) {
													edi.core.openModule('document.details.dsf', data.data);
													moduleData.tab.setLoading(false);
												}
											}
										);
									},
									data: {
										docId: documentId
									}
								}
							});
						}

						items.push(confBtn);
					}
				});
			}

			if (!!recData.invitation.rejectReason) {
				items.push({
					title:
						recData.state === 'REJECTED'
							? edi.i18n.getMessage('document.reject.reason')
							: edi.i18n.getMessage('document.revoke.reason'),
					text: recData.invitation.rejectReason
				});
			}

			let tpl = Ext.create(
				'Ext.XTemplate',
				'<tpl for=".">',
				'<div class="invitations-notification-block">',
				'<tpl if="!!title">',
				'<div class="fieldset-label">{title}</div>',
				'</tpl>',
				'<tpl if="!!text">',
				'<div class="fieldset-text">{text}</div>',
				'</tpl>',
				'<tpl if="!!button">',
				'<div class="fieldset-wrap" id="{[this.getId(values)]}">{[this.initButton(values)]}</div>',
				'</tpl>',
				'</div>',
				'</tpl>',
				{
					getId: function (values) {
						values.button.wrapId = Ext.id();
						return values.button.wrapId;
					},
					initButton: function (values) {
						setTimeout(() => {
							createButton({
								cls: 'invitations-notification-block-link',
								text: values.button.text,
								handler: values.button?.handler,
								renderTo: values.button.wrapId,
								data: values.button?.data
							});
						}, 50);
					}
				}
			);
			return !!items.length ? tpl.apply(items) : null;
		};

		if (recData.state === edi.constants.INVITATIONS.STATE.EXPECTATION) {
			let getAgreementWaitingData = new Promise((resolve, reject) => {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.INVITATIONS.DSF_DATA.GET, {
						id: recData.id
					}),
					'GET',
					undefined,
					resolve,
					reject
				);
			});

			getAgreementWaitingData
				.then((data) => {
					showSidebar();

					let el = Ext.select('.notification').item(0);

					if (data?.data) {
						Object.assign(recData, {
							agreementWaiting: data.data
						});
					}

					el?.update(renderNotification(recData));
				})
				.catch((err) => {
					edi.core.logMessage(err, 'error');
					if (!moduleData.tab.isDestroyed) {
						moduleData.tab.setLoading(false);
						sidebar.destroy();
					}
				});
		} else if (
			recData.state === edi.constants.INVITATIONS.STATE.REVOKE ||
			recData.state === edi.constants.INVITATIONS.STATE.REJECTED
		) {
			setTimeout(function () {
				showSidebar();

				let el = Ext.select('.notification').item(0);
				el?.update(renderNotification(recData));
			}, 10);
		} else {
			setTimeout(function () {
				showSidebar();
			}, 10);
		}

		update(recData);
		edi.events.module.on('moduleActivated', closeSidebar);
	};

	var closeSidebar = function () {
		if (sidebar.isToggle()) {
			sidebar.toggle(false);
		}
	};

	/**
	 * Initialization of module filter panel
	 */
	const createOutgoingFilterForm = function () {
		const gridName = 'outgoing';
		fireSearchObject[gridName] = function () {
			if (grids[gridName]) {
				if (!filterObjects[gridName]) {
					filterObjects[gridName] = edi.filters.createGridFilter(
						edi.rest.services.INVITATIONS.GET,
						form,
						grids[gridName],
						edi.filters.config.ew_invitations.createArgs,
						{ gridName: gridName },
						{
							persistence: {
								enabled: true,
								name: `${moduleData.modName}_outgoing_grid`
							}
						}
					);
				}
				filterObjects[gridName].filter();
			}
		};
		let { formItemsMap, items } = edi.filters.config.ew_invitations.createFormItems();

		edi.events.invitations.on('change', () => {
			formItemsMap.receiver.getStore()?.reload();
		});

		const form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${moduleData.modName}_grid_outgoing`
				},
				border: 1,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
					formItemsMap.creationDateRange?.activatePeriod(defaultModifyDateRange);
					formItemsMap.statusDateRange?.activatePeriod(defaultModifyDateRange);
				}
			},
			fireSearchObject[gridName]
		);

		return form;
	};
	/**
	 * Initialization of module filter panel
	 */
	const createIncomingFilterForm = function () {
		const gridName = 'incoming';
		fireSearchObject[gridName] = function () {
			if (grids[gridName]) {
				if (!filterObjects[gridName]) {
					filterObjects[gridName] = edi.filters.createGridFilter(
						edi.rest.services.INVITATIONS.GET,
						form,
						grids[gridName],
						edi.filters.config.ew_invitations_incoming.createArgs,
						{ gridName: gridName },
						{
							persistence: {
								enabled: true,
								name: `${moduleData.modName}_incoming_grid`
							}
						}
					);
				}
				filterObjects[gridName].filter();
			}
		};
		let { formItemsMap, items } = edi.filters.config.ew_invitations_incoming.createFormItems();

		edi.events.invitations.on('change', () => {
			formItemsMap.sender.getStore()?.reload();
		});

		const form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${moduleData.modName}_grid_incoming`
				},
				border: 1,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
					formItemsMap.fromDocTime?.activatePeriod(defaultModifyDateRange);
					formItemsMap.modifyDate?.activatePeriod(defaultModifyDateRange);
				}
			},
			fireSearchObject[gridName]
		);

		return form;
	};
	/**
	 * Initialization of module filter panel
	 */
	let createMassFilterForm = function () {
		const gridName = 'mass';
		fireSearchObject[gridName] = function () {
			if (grids[gridName]) {
				if (!filterObjects[gridName]) {
					filterObjects[gridName] = edi.filters.createGridFilter(
						edi.rest.services.MASS_INVITATIONS.GET,
						form,
						grids[gridName],
						edi.filters.config.ew_invitations_mass.createArgs,
						{},
						{
							persistence: {
								enabled: true,
								name: `${moduleData.modName}_mass_grid`
							}
						}
					);
				}
				filterObjects[gridName].filter();
			}
		};
		let { formItemsMap, items } = edi.filters.config.ew_invitations_mass.createFormItems();
		const form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${moduleData.modName}_grid_mass`
				},
				border: 1,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
					formItemsMap.creationDateRange?.activatePeriod(defaultModifyDateRange);
					formItemsMap.statusDateRange?.activatePeriod(defaultModifyDateRange);
				}
			},
			fireSearchObject[gridName]
		);

		return form;
	};
	/**
	 * Initialization of module filter panel
	 */
	let createDraftsFilterForm = function () {
		const gridName = 'draft';
		fireSearchObject[gridName] = function () {
			if (grids[gridName]) {
				if (!filterObjects[gridName]) {
					filterObjects[gridName] = edi.filters.createGridFilter(
						edi.rest.services.INVITATIONS.GET,
						form,
						grids[gridName],
						edi.filters.config.ew_invitations_drafts.createArgs,
						{ gridName: gridName },
						{
							persistence: {
								enabled: true,
								name: `${moduleData.modName}_draft_grid`
							}
						}
					);
				}
				filterObjects[gridName].filter();
			}
		};
		let { formItemsMap, items } = edi.filters.config.ew_invitations_drafts.createFormItems();

		edi.events.invitations.on('change', () => {
			formItemsMap.receiver.getStore()?.reload();
		});

		const form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${moduleData.modName}_grid_draft`
				},
				border: 1,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
					formItemsMap.modifyDate?.activatePeriod(defaultModifyDateRange);
				}
			},
			fireSearchObject[gridName]
		);

		return form;
	};

	const deleteInvitation = function (id) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.INVITATIONS.DELETE, {
				id: id
			}),
			'DELETE',
			Ext.encode({ STANDALONE: 'true' }),
			function () {
				edi.events.invitations.fireEvent('change');
			},
			edi.rest.getErrorHandler(),
			function () {
				moduleData.tab.setLoading(false);
			}
		);
	};

	const resendInvitationInGrid = function (record, data, resultsMap) {
		Object.assign(data, {
			STANDALONE: 'true'
		});
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.INVITATIONS.RESEND.PUT, {
				id: record.id
			}),
			'PUT',
			Ext.encode(data),
			function () {
				edi.events.invitations.fireEvent('change');
			},
			function (err) {
				resultsMap.push(getResendingErrorText(err, record));
				resultsMap = [...new Set(resultsMap)];

				moduleData.tab.setLoading(false);
				if (resultsMap.length > 0) {
					resultsMap = resultsMap.map((item) => edi.i18n.getMessage(item));
					edi.core.showError(resultsMap.join('<br><br>'));
				}
				edi.events.invitations.fireEvent('change');
			},
			function () {
				moduleData.tab.setLoading(false);
			}
		);
	};

	const deleteMassInvitation = function (id) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.MASS_INVITATIONS.DELETE, {
				id: id
			}),
			'DELETE',
			Ext.encode({}),
			function () {
				edi.events.invitations.fireEvent('change');
			},
			edi.rest.getErrorHandler(),
			function () {
				moduleData.tab.setLoading(false);
			}
		);
	};

	var openInvitation = function (record, gridName) {
		if (gridName === 'mass') {
			const invData = record.getData();
			const massInvitationName = invData?.invitationName ?? '';
			edi.core.openModule('invitations.details.mass.person', invData, massInvitationName);
			return;
		}
		openSideBar(record);
	};

	var createActionColumn = function (gridName) {
		let actionColumns = [
			{
				tooltip: edi.i18n.getMessage('ediweb.columns.action.details'),
				glyph: edi.constants.ICONS.DETAILS,
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					openInvitation(record, gridName);
				}
			}
		];
		if (gridName === 'draft') {
			actionColumns = actionColumns.concat(
				{
					tooltip: edi.i18n.getMessage('ediweb.columns.action.edit'),
					glyph: edi.constants.ICONS.EDIT,
					handler: function (grid, rowIndex) {
						var record = grid.getStore().getAt(rowIndex);
						edi.core.openModule('invitations.person', record.getData(), null, true);
					}
				},
				{
					tooltip: edi.i18n.getMessage('ediweb.columns.action.delete'),
					glyph: edi.constants.ICONS.DELETE,
					handler: function (grid, rowIndex) {
						edi.core.confirm(null, 'delete.invitations.confirmation', function () {
							moduleData.tab.setLoading();
							const record = grid.getStore().getAt(rowIndex);
							const id = record.get('id');
							deleteInvitation(id);
						});
					}
				}
			);
		} else if (gridName === 'mass') {
			actionColumns = actionColumns.concat(
				{
					glyph: edi.constants.ICONS.EDIT,
					isActionDisabled: function (view, rowIndex, colIndex, item, record) {
						const state = record?.get('state') ?? null;
						return state !== edi.constants.STATE.DRAFT;
					},
					handler: function (grid, rowIndex) {
						var record = grid.getStore().getAt(rowIndex);
						const invData = record.getData();
						const massInvitationName = invData?.invitationName ?? '';
						edi.core.openModule('invitations.create.mass.person', invData, massInvitationName, true);
					}
				},
				{
					glyph: edi.constants.ICONS.DELETE,
					isActionDisabled: function (view, rowIndex, colIndex, item, record) {
						const state = record?.get('state') ?? null;
						return state !== edi.constants.STATE.DRAFT;
					},
					handler: function (grid, rowIndex) {
						edi.core.confirm(null, 'ediweb.invitations.mass.confirm.delete.all', function () {
							moduleData.tab.setLoading();
							const record = grid.getStore().getAt(rowIndex);
							const id = record?.get('massInvitation').header ?? null;
							if (!id) {
								moduleData.tab.setLoading(false);
								return;
							}
							deleteMassInvitation(id);
						});
					}
				}
			);
		}
		return createActionsColumnConfig({
			items: actionColumns
		});
	};

	/**
	 * Compile error text from the fault resending response
	 * @param	{Object}	error
	 * @param	{Object}	invitationData
	 * @returns	{String}	error text
	 */
	let getResendingErrorText = function (err, invitationData) {
		let typeErrorText = {
			'controller.invitation.receiver.org.is.deleted.when.copy':
				'ediweb.error.invitation.not.copy.receiver.deleted',
			'controller.invitation.receiver.org.is.deleted.when.resend':
				'ediweb.error.invitation.not.send.receiver.deleted'
		};

		let errorTpl, errorText;
		if (Object.keys(typeErrorText).some((it) => it === err.typeError)) {
			errorText = typeErrorText[err.typeError];
		} else {
			let specialTypeError = [
				'invitations.receiver.email.empty',
				'invitations.receiver.email.incorrect',
				'invitations.receiver.inn.empty',
				'invitations.receiver.inn.incorrect',
				'invitations.receiver.kpp.empty',
				'invitations.receiver.kpp.incorrect',
				'invitations.invitation.not.send'
			];

			var isShowSpecialError =
				specialTypeError.filter(function (error) {
					return edi.i18n.getMessage(error) === err.additionalData[0];
				}).length > 0;

			errorTpl = edi.utils.formatComplexServerError(err, 'error.getting.data');
			errorText = edi.utils.formatString(errorTpl, {
				receiver: invitationData.receiverName,
				product: invitationData.product
			});

			errorText = isShowSpecialError ? err.additionalData[0] : errorText;
		}

		return errorText;
	};

	/**
	 * Store errors into map by receiver and product excluding duplicates
	 * @param	{Object}	resultsMap
	 * @param	{Object}	rowRecordData
	 * @param	{Boolean}	ok
	 * @param	{Object}	[error]
	 */
	let storeResendingResultInMap = function (resultsMap, rowRecordData, ok, error) {
		if (!resultsMap || !rowRecordData) {
			return;
		}

		let ident = `${rowRecordData.receiverName}_${rowRecordData.receiverInn}_${rowRecordData.product}`;
		let result = resultsMap[ident];
		//если уже было успешное приглашение - значит остальные ошибки уже не важны
		//т.к. работа выполнена - мы переотправили приглашение
		if (ok === true || result?.ok === true) {
			resultsMap[ident] = { ok: true, error: null };
		} else if (error) {
			let errTxt = getResendingErrorText(error, rowRecordData);
			resultsMap[ident] = { ok: false, error: errTxt };
		}
	};

	/**
	 * Converts resultsMap into flat array of errors
	 * @param	{Object}	resultsMap
	 * @returns	{String[]}	array of errors
	 */
	let collectErrorsFromMap = function (resultsMap) {
		return Object.values(resultsMap || {})
			.filter((res) => res.ok !== true && res.error)
			.map((res) => res.error);
	};

	/**
	 * Resend invitation
	 * @param	{Number}	invitationId
	 * @returns	{Promise<{ok: Boolean, error?: Object}>}
	 */
	let resendInvitation = (invitationId) =>
		new Promise((resolve) => {
			let fail = function (response) {
				resolve({
					ok: false,
					error: response
				});
			};

			let success = () =>
				resolve({
					ok: true
				});

			let url = edi.utils.formatString(edi.rest.services.INVITATIONS.RESEND.PUT, {
				id: invitationId
			});

			edi.rest.sendRequest(url, 'PUT', Ext.encode({ doctime: +new Date() }), success, fail);
		});

	const tryToStartTour = function () {
		const activeModule = edi.modulesHandler.getActiveModule().modName;
		if (activeModule === moduleData.modName) {
			if (
				gridNameTab === 'mass' &&
				edi.core.getExtraData('user.sentMassInvitationHint') !== 'true' &&
				grids['mass']?.getStore()?.getCount() > 0
			) {
				const tourTargetRecord = grids['mass']
					.getStore()
					.getRange()
					.find((r) => r.data._isTargetForTour);
				if (tourTargetRecord) {
					//т.к. в гриде может быть скролл, и нужная нам строка спряталась,
					//то сделаем селект что бы она показалась юзеру и деселект что б не оставалась выделенной
					grids['mass'].selModel.select(tourTargetRecord);
					if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
						tourEvents.fireEvent('start_tour', INVITATIONS_MODULE_NAME, moduleData);
					}
					grids['mass'].selModel.deselect(tourTargetRecord);
				}
			} else if (gridNameTab === 'outgoing' && edi.core.getExtraData('user.outgoingInvitationHints') !== 'true') {
				if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
					tourEvents.fireEvent('start_tour', INVITATIONS_MODULE_NAME, moduleData);
				}
			}
		}
	};

	var createGridCmp = function (gridName, columns) {
		columns = columns ? columns : [];
		var actions = createActionColumn(gridName),
			tabArray = [],
			model;
		switch (gridName) {
			case 'incoming':
				model = edi.models.getModel('EW_INVITATIONS_INCOMING');
				break;
			case 'mass':
				model = edi.models.getModel('EW_INVITATIONS_MASS');
				break;
			default:
				model = edi.models.getModel('EW_INVITATIONS');
		}

		var actionItems = [],
			topBar,
			selectionProcessor,
			groupActionsItemsSelected = [],
			totalsLabel = createLabel({
				text: edi.i18n.getMessage('documents.selected.totals', {
					selectedRows: 0
				}),
				cls: 'edi-tbar-total-label-right'
			});

		if (actions !== null) {
			columns.push(actions);
		}

		if (gridName === 'outgoing') {
			actionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.SEND,
				name: edi.i18n.getMessage('ediweb.invitations.resend')
			});
		}

		if (gridName === 'draft' || gridName === 'mass') {
			actionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.DELETE,
				name: edi.i18n.getMessage('form.btn.delete'),
				isAvailableFunc:
					gridName === 'mass'
						? function (invData) {
								const state = invData?.get('state') ?? null;
								return state === edi.constants.INVITATIONS.STATE.DRAFT;
						  }
						: null
			});
		}

		var getCreateButton = function (handler) {
			return createButton({
				text: edi.i18n.getMessage('form.btn.create'),
				cls: [BUTTON_CLS.primary, INVITATIONS_TOUR_TARGET_1],
				glyph: edi.constants.ICONS.PLUS,
				handler: handler
			});
		};

		var createInvitationButton = getCreateButton(function () {
			edi.core.openModule('invitations.person');
			closeSidebar();
		});

		var createMassButton = edi.permissions.hasPermission('CLIENT_MASS_INVITATION_CREATE')
			? getCreateButton(function () {
					edi.core.openModule('invitations.create.mass.person');
					closeSidebar();
			  })
			: null;

		if (actionItems.length > 0) {
			var groupActionsItemClick = function (id) {
				var i;
				if (edi.constants.DOCUMENT_ACTIONS.DELETE === id) {
					const deleteQuestion =
						gridName === 'mass'
							? edi.i18n.getMessage('delete.mass.invitations.confirmation', null, null, null)
							: edi.i18n.getMessage('delete.invitations.confirmation', null, null, null);
					edi.core.confirm(null, deleteQuestion, function () {
						moduleData.tab.setLoading();
						for (i = 0; i < groupActionsItemsSelected.length; i++) {
							gridName === 'mass'
								? deleteInvitation(groupActionsItemsSelected[i].get('id'))
								: deleteMassInvitation(groupActionsItemsSelected[i].get('id'));
						}
					});
				} else if (edi.constants.DOCUMENT_ACTIONS.SEND === id) {
					edi.core.confirm(null, 'ediweb.resend.documents.confirmation', async function () {
						let resultsMap = [];
						moduleData.tab.setLoading(true);
						for (i = 0; i < groupActionsItemsSelected.length; i++) {
							let rowRecordData = groupActionsItemsSelected[i].getData();
							resendInvitationInGrid(
								rowRecordData,
								{
									doctime: +new Date()
								},
								resultsMap
							);
						}
					});
				}
				closeSidebar();
			};
			topBar = createGridActionBar({
				actionCfgs: actionItems,
				defaultHandler: groupActionsItemClick,
				totalsLabel: totalsLabel
			});
			var resendStatuses = [
				edi.constants.INVITATIONS.STATE.REVOKE,
				edi.constants.INVITATIONS.STATE.REJECTED,
				edi.constants.INVITATIONS.STATE.EXPIRED
			];
			selectionProcessor = function (model, selected, actionItems, isProcessible) {
				var selectedDocsCount;
				groupActionsItemsSelected = [];
				if (selected.length) {
					for (var i = 0; i < actionItems.length; i++) {
						if (isProcessible(actionItems[i].rulesData)) {
							selectedDocsCount = 0;
							for (var j = 0; j < selected.length; j++) {
								if (actionItems[i].rulesData.id === edi.constants.DOCUMENT_ACTIONS.SEND) {
									var resendSelected = selected.filter(function (item) {
										return resendStatuses.some((it) => it === item.get('state'));
									});
									if (resendSelected.length) {
										selectedDocsCount++;
										groupActionsItemsSelected = resendSelected;
									}
								} else {
									const isAvailableFunc = actionItems[i]?.rulesData?.isAvailableFunc ?? null;
									let isAvailable = true;
									if (isAvailableFunc) {
										const buttonData = actionItems[i]?.rulesData?.buttonData ?? {};
										isAvailable = isAvailableFunc(selected[j], buttonData);
									}
									if (isAvailable) {
										selectedDocsCount++;
										groupActionsItemsSelected.push(selected[j]);
									}
								}
							}
							actionItems[i].setTooltip(actionItems[i].rulesData.name + ' (' + selectedDocsCount + ')');
							actionItems[i].setDisabled(!selectedDocsCount);
						}
					}
				} else {
					for (i = 0; i < actionItems.length; i++) {
						if (isProcessible(actionItems[i].rulesData)) {
							actionItems[i].setTooltip(actionItems[i].rulesData.name + ' (0)');
							actionItems[i].setDisabled(true);
						}
					}
				}
				if (totalsLabel) {
					totalsLabel.setText(
						edi.i18n.getMessage('documents.selected.totals', {
							selectedRows: edi.renderers.currencyWithSeparators(selected.length)
						})
					);
				}
			};
			tabArray = [topBar];
			switch (gridName) {
				case 'outgoing':
					tabArray.unshift(createInvitationButton);
					break;
				case 'mass':
					tabArray.unshift(createMassButton);
					break;
			}
		}

		const invitationGenerateEmptyTextContent = function (emptyTextTplOptions = {}) {
			let isInlineText = gridName === 'outgoing' || gridName === 'mass';
			const me = this;
			let contentText = isInlineText
				? `<span>${emptyTextTplOptions.contentText ?? ''}</span>`
				: `<div>${emptyTextTplOptions.contentText ?? ''}</div>`;
			const tplBaseCls = `${me.baseCls}-empty-text-with-image`;
			return (
				`<div class="${tplBaseCls}-content">` +
				contentText +
				'<div>' +
				`<span>${emptyTextTplOptions.beforeButtonText ?? ''}</span>` +
				me.generateEmptyTextButton(emptyTextTplOptions.buttonText, emptyTextTplOptions.buttonName) +
				'</div>' +
				'</div>'
			);
		};

		return createGrid({
			saveSorters: true,
			savedSortersName: `${moduleData.modName}_${gridName}`,
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: model,
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: false,
				listeners: {
					load: function () {
						if (gridNameTab === 'mass') {
							//от стора грида зависят только подсказки на строках массовых приглашений
							//таймаут нужен что б грид успел отрендерится и подсказка появилась в нужном месте
							setTimeout(tryToStartTour, 500);
						}
					}
				}
			},
			viewConfig: {
				getRowClass: function (record) {
					const isTargetForTour =
						gridName === 'mass' && record.get('state') === edi.constants.INVITATIONS.STATE.SENT;
					if (isTargetForTour) {
						record.data._isTargetForTour = isTargetForTour;
					}
					return isTargetForTour ? INVITATIONS_TOUR_TARGET_3 : '';
				},
				emptyTextTplOptions: {
					enabled: true,
					iconName: 'invitation',
					title: edi.i18n.getMessage('ediweb.invitation.empty.text.tpl.title.' + gridName),
					contentText: edi.i18n.getMessage('ediweb.invitation.empty.text.tpl.contentText.' + gridName),
					isInlineText: gridName === 'outgoing' || gridName === 'mass',
					beforeButtonText: edi.i18n.getMessage(
						'ediweb.invitation.empty.text.tpl.beforeButtonText.' + gridName
					),
					buttonText: edi.i18n.getMessage('ediweb.invitation.empty.text.tpl.buttonText.' + gridName),
					buttonName: 'emptyTextButton_' + gridName,
					buttonClickHandler: function (e, btn, name) {
						filterForms[gridName].toggleHandler();
					}
				}
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: selectionProcessor
						  })
						: undefined,
				tbar: tabArray,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						openInvitation(record, gridName);
					}
				},
				generateEmptyTextContent: invitationGenerateEmptyTextContent
			},
			pagingBarConfig: {
				allowExport: false,
				totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
			}
		});
	};

	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		tabPanel = createTabPanel({
			margin: '16 0 0 0'
		});

		grids = {
			outgoing: createGridCmp('outgoing', edi.columns.get('ew_invitations')),
			incoming: createGridCmp('incoming', edi.columns.get('ew_invitations_incoming')),
			draft: createGridCmp('draft', edi.columns.get('ew_invitations_draft')),
			mass: createGridCmp('mass', edi.columns.get('ew_invitations_mass'))
		};

		var toggleActiveFilter = function (filter) {
			if (activeFilter) {
				activeFilter.hide();
			}
			if (filter) {
				activeFilter = filter;
				activeFilter.show();
				filter.fireSearch();
			}
		};

		var onTabActivate = function (tabName, component) {
			gridNameTab = tabName;
			toggleActiveFilter(filterForms[tabName]);
			component.updateLayout();
		};

		var isFirst = true;
		var activeTab = edi.utils.getObjectProperty(moduleData, 'initData.data.activeTab');
		if (activeTab && grids.hasOwnProperty(activeTab)) {
			isFirst = false;
		}
		for (var i in grids) {
			if (grids.hasOwnProperty(i)) {
				initTabWithGrid(
					i,
					grids[i],
					isFirst || activeTab === i,
					tabPanel,
					{
						title: 'ediweb.invitations.tab.' + i,
						callback: onTabActivate,
						cls: i === 'mass' ? INVITATIONS_TOUR_TARGET_2 : ''
					},
					{
						badgeCount: true,
						hideIcon: true
					}
				);

				isFirst = false;
			}
		}
		filterForms['outgoing'] = createOutgoingFilterForm();
		filterForms['outgoing'].hide();
		filterForms['draft'] = createDraftsFilterForm();
		filterForms['draft'].fireSearch();
		filterForms['draft'].hide();
		filterForms['incoming'] = createIncomingFilterForm();
		filterForms['incoming'].fireSearch();
		filterForms['incoming'].hide();
		filterForms['mass'] = createMassFilterForm();
		filterForms['mass'].fireSearch();
		filterForms['mass'].hide();

		modulePanel.add(filterForms['outgoing']);
		modulePanel.add(filterForms['draft']);
		modulePanel.add(filterForms['incoming']);
		modulePanel.add(filterForms['mass']);

		modulePanel.add(tabPanel);
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
			tryToStartTour();
		}
	};

	var isActiveTab = function (tab) {
		var tabId = tab && tab.getId ? tab.getId() : null;
		var activeTab = tabPanel && tabPanel.getActiveTab ? tabPanel.getActiveTab() : null;
		var tabPanelId = activeTab && activeTab.getId ? activeTab.getId() : null;
		return tabId && tabPanelId && tabId === tabPanelId;
	};

	/**
	 * Activates module's tab by it's name
	 * @param	{String}	newTabName
	 */
	var activateTab = function (newTabName) {
		let grid = grids[newTabName] || grids['incoming'];
		let tab = grid?.tab;
		if (tab && !tab.isDestroyed && tabPanel && !tabPanel.isDestroyed) {
			if (isActiveTab(tab)) {
				gridChangeHandler(grid);
				return;
			}
			tabPanel.setActiveTab(tab);
			tryToStartTour();
		}
	};

	var gridChangeHandler = function (grid) {
		var store = grid.getStore();
		var gridSelModel = grid.getSelectionModel();
		if (gridSelModel) {
			gridSelModel.deselectAll();
		}
		store.reload();
	};

	/**
	 * Reloads grid and resets filter form
	 */
	var gridDataChangedHandler = function () {
		for (var i in grids) {
			if (grids.hasOwnProperty(i)) {
				gridChangeHandler(grids[i]);
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		if (sidebar && sidebar.rendered) {
			sidebar.toggle(false);
			sidebar.close(function () {
				edi.events.module.un('moduleActivated', closeSidebar);
				sidebar.destroy();
			});
		}
		edi.events.invitations.un('change', gridDataChangedHandler);
		edi.events.invitations.un('activatetab', activateTab);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name, null);
		return true;
	};
};

const isMassInvitationsTourAvailable = function (grid) {
	const records = grid?.getStore()?.getRange();
	return records?.some((r) => r.get('state') === edi.constants.INVITATIONS.STATE.SENT);
};

tours[INVITATIONS_MODULE_NAME] = {
	getName: () => INVITATIONS_MODULE_NAME,
	getStartErrorText: function () {
		const tour = this;
		if (tour.externalOpts?.eventFromUser === true) {
			const gridName = tour.externalOpts?.getGridName();
			if (gridName === 'mass') {
				return !isMassInvitationsTourAvailable(tour.externalOpts.getGrid())
					? 'invitations.tour.error.grid.is.empty'
					: null;
			} else if (gridName === 'outgoing') {
				return null;
			} else {
				return 'error.tour.not.available';
			}
		}
	},
	createSteps: function () {
		const tour = this;
		if (tour.externalOpts?.getGridName() === 'outgoing') {
			return [
				{
					getTitle: () => edi.i18n.getMessage('invitations.tour.step.1.title'),
					getContent: () => edi.i18n.getMessage('invitations.tour.step.1.content'),
					getTargetEl: (tour) => (tour?.queryRoot || document).querySelector(`.${INVITATIONS_TOUR_TARGET_1}`),
					position: 'right'
				},
				{
					getTitle: () => edi.i18n.getMessage('invitations.tour.step.2.title'),
					getContent: () => edi.i18n.getMessage('invitations.tour.step.2.content'),
					getTargetEl: (tour) => (tour?.queryRoot || document).querySelector(`.${INVITATIONS_TOUR_TARGET_2}`),
					position: 'right'
				}
			];
		} else if (tour.externalOpts?.getGridName() === 'mass') {
			const records = tour.externalOpts.getGrid()?.getStore()?.getRange();
			return records?.some((r) => r.get('state') === edi.constants.INVITATIONS.STATE.SENT)
				? [
						{
							getTitle: () => edi.i18n.getMessage('invitations.tour.step.3.title'),
							getContent: () => edi.i18n.getMessage('invitations.tour.step.3.content'),
							getTargetEl: (tour) =>
								(tour?.queryRoot || document).querySelector(`.${INVITATIONS_TOUR_TARGET_3}`),
							position: 'bottom'
						}
				  ]
				: null;
		}
	},
	onTourClosed: function () {
		const tour = this;
		//поставим флаг что юзер прошел все страницы тура
		if (tour.currentStepNum === tour.steps.length - 1) {
			if (tour.externalOpts?.getGridName() === 'outgoing') {
				edi.core.setExtraData('user.outgoingInvitationHints', 'true');
			} else if (tour.externalOpts?.getGridName() === 'mass') {
				edi.core.setExtraData('user.sentMassInvitationHint', 'true');
			}
		}
	}
};
