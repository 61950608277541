const RETANN_LINE_MODEL = 'RETANN_LINE';
const RETANN_SUMMARY_MODEL = 'RETANN_SUMMARY';

edi.models.setModels({
	[RETANN_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'ReceivedQuantity',
				type: 'string'
			},
			{
				name: 'ReturnedQuantity',
				type: 'string'
			},
			{
				name: 'UnitNetPrice',
				type: 'string'
			},
			{
				name: 'UnitGrossPrice',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'NetAmount',
				type: 'string'
			},
			{
				name: 'TaxAmount',
				type: 'string'
			},
			{
				name: 'GrossAmount',
				type: 'string'
			},
			{
				name: 'Remarks',
				type: 'string'
			}
		],
		idProperty: 'LineNumber'
	},
	[RETANN_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'TotalReceivedAmount',
				type: 'string'
			},
			{
				name: 'TotalReturnedAmount',
				type: 'string'
			},
			{
				name: 'TotalNetAmount',
				type: 'float'
			},
			{
				name: 'TotalGrossAmount',
				type: 'string'
			},
			{
				name: 'TotalTaxAmount',
				type: 'string'
			}
		]
	}
});

export { RETANN_LINE_MODEL, RETANN_SUMMARY_MODEL };
