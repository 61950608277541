const createRetannHeader = function (config) {
	config = config ? config : {};
	return {
		ReturnAnnouncementNumber: config.ReturnAnnouncementNumber,
		ReturnAnnouncementDate: config.ReturnAnnouncementDate,
		DocumentFunctionCode: config.DocumentFunctionCode,
		DespatchDate: config.DespatchDate,
		DespatchTime: config.DespatchTime,
		Remarks: config.Remarks,
		Location: config.Location,
		WaybillNumber: config.WaybillNumber,
		WaybillDate: config.WaybillDate,
		Reference: config.Reference
	};
};

const createRetann = function (config) {
	config = config ? config : {};
	var orderLines = [],
		summary = {};

	if (config.productValues) {
		summary = {
			TotalReceivedAmount: config.productValues.TotalReceivedAmount,
			TotalReturnedAmount: config.productValues.TotalReturnedAmount,
			TotalNetAmount: config.productValues.TotalNetAmount,
			TotalTaxAmount: config.productValues.TotalTaxAmount,
			TotalGrossAmount: config.productValues.TotalGrossAmount
		};

		for (var i = 0; i < config.productValues.products.length; i++) {
			var product = config.productValues.products[i];
			var line = {};
			if (product[edi.constants.INTERNAL_LINE_ID]) {
				line[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}
			delete product[edi.constants.INTERNAL_LINE_ID];
			line['Line-Item'] = product;
			orderLines.push(line);
		}
	}

	return {
		'ReturnAnnouncement-Header': createRetannHeader(config.header),
		'ReturnAnnouncement-Parties': config.parties || {},
		'ReturnAnnouncement-Lines': {
			Line: orderLines
		},
		'ReturnAnnouncement-Summary': summary
	};
};
export { createRetannHeader, createRetann };
