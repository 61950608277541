import './COMMON_PRICAT/index.js';
import './CONTAINER_COMMON_PRICAT/document.details.container_common_pricat.js';
import './CONTAINER_COMMON_PRICAT/actionRules.js';
import './DELFOR/document.create.delfor.js';
import './DELFOR/document.details.delfor.js';
import './DELFOR/actionRules.js';
import './DSF/actionRules.js';
import './DSF/document.create.dsf.js';
import './DSF/document.details.dsf.js';
import './DSF_CLIENT/index.js';
import './DSF_REESTR_ROUTE/document.details.dsf_reestr_route.js';
import './DSF_REESTR_ROUTE/actionRules.js';
import './AGREEMENT_PACKAGES/index.js';
import './ON_AVZ/document.details.on_avz.js';
import './ON_AVZ/actionRules.js';
import './ON_SERVICE_CONTAINER/document.create.on.service.container.js';
import './ON_SERVICE_CONTAINER/document.details.on.service.container.js';
import './ON_SERVICE_CONTAINER/actionRules.js';
import './EDI_IFTMBC/document.create.iftmbc.js';
import './EDI_IFTMBC/document.details.iftmbc.js';
import './EDI_IFTMBC/actionRules.js';
import './EDI_IFTMBC_APPROVAL/document.create.iftmbc_approval.js';
import './EDI_IFTMBC_APPROVAL/document.details.iftmbc_approval.js';
import './EDI_IFTMBC_APPROVAL/actionRules.js';
import './EDI_IFTMBF/document.create.iftmbf.js';
import './EDI_IFTMBF/document.details.iftmbf.js';
import './EDI_IFTMBF/actionRules.js';
import './EDI_IFTMBF_APPROVAL/document.create.iftmbf_approval.js';
import './EDI_IFTMBF_APPROVAL/document.details.iftmbf_approval.js';
import './EDI_IFTMBF_APPROVAL/actionRules.js';
import './EDI_SLSFCT/index.js';
import './EDI_SLSRPT/index.js';
import './FORMALIZED_DOCUMENT/document.create.formalized.js';
import './FORMALIZED_DOCUMENT/document.details.formalized.js';
import './FORMALIZED_DOCUMENT/actionRules.js';
import './INVRPT/index.js';
import './LEGACY_ALCDES/index.js';
import './LEGACY_DELJIT/index.js';
import './LEGACY_DES_ADV/index';
import './LEGACY_INVOICE/index.js';
import './LEGACY_ORDER/index.js';
import './LEGACY_ORDER_RESP/index.js';
import './LEGACY_REC_ADV/index.js';
import './LEGACY_RETANN/index.js';
import './LOGSET/document.create.logset.js';
import './LOGSET/document.details.logset.js';
import './LOGSET/actionRules.js';
import './X5_AGREEMENT_CONTAINER/index.js';
import './PRICAT_EANCOM/actionRules.js';
import './PRICAT_EANCOM/document.create.pricateancom.js';
import './PRICAT_EANCOM/document.details.pricateancom.js';
import './PRICAT_EANCOM/document.create.pricateancom_v2';
import './PRICAT_EANCOM/document.details.pricateancom_v2';
import './FROV_SIG_SO_ACT2_FROM_X5/index.js';
import './OVZ_FROM_X5/document.details.ovz_from_x5.js';
import './OVZ_FROM_X5/actionRules.js';
