export const recadvSelectors = {
	getBuyerFieldsMap: function (prefix) {
		const fieldsMap = edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'company_util_reg_nr', 'company_inn'].concat(
				edi.selectors.COMMON_FIELDS_MAP
			)
		});
		fieldsMap['code_by_sender'] = `${prefix}.CodeBySender`;
		fieldsMap['code_by_receiver'] = `${prefix}.CodeByReceiver`;
		fieldsMap['code_by_supplier'] = `${prefix}.CodeBySupplier`;
		return fieldsMap;
	},
	getBuyerModalCong: function () {
		const modalConf = edi.selectors.mergeModalCfgWithCommonOrgAddress(
			edi.selectors.getLegacyModalConf({
				contact: null,
				main: {
					company_inn: edi.selectors.defaults.company_inn
				}
			})
		);
		modalConf.title = edi.i18n.getMessage('documents.recadv.buyer');
		return modalConf;
	},
	getSellerFieldsMap: function (prefix) {
		const fieldsMap = edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'company_util_reg_nr', 'company_inn'].concat(
				edi.selectors.COMMON_FIELDS_MAP
			)
		});
		fieldsMap['code_by_sender'] = `${prefix}.CodeBySender`;
		fieldsMap['code_by_receiver'] = `${prefix}.CodeByReceiver`;
		fieldsMap['code_by_supplier'] = `${prefix}.CodeBySupplier`;
		return fieldsMap;
	},
	getSellerModalConf: function () {
		const modalConf = edi.selectors.mergeModalCfgWithCommonOrgAddress(
			edi.selectors.getLegacyModalConf({
				contact: null,
				main: {
					company_inn: edi.selectors.defaults.company_inn
				}
			})
		);
		modalConf.title = edi.i18n.getMessage('documents.recadv.seller');
		return modalConf;
	},
	getDeliveryFieldsMap: function (prefix) {
		const fieldsMap = edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'company_inn'].concat(edi.selectors.COMMON_FIELDS_MAP)
		});
		fieldsMap['code_by_sender'] = `${prefix}.CodeBySender`;
		fieldsMap['code_by_receiver'] = `${prefix}.CodeByReceiver`;
		fieldsMap['code_by_supplier'] = `${prefix}.CodeBySupplier`;
		return fieldsMap;
	},
	getDeliveryModalConf: function () {
		const modalConf = edi.selectors.mergeModalCfgWithCommonOrgAddress({
			tabs: {
				main: {
					company_type: edi.selectors.mergeConfigs('company_type_combo', {
						fieldConfig: {
							listeners: null
						}
					}),
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
						regex: null,
						maxLength: 35
					})
				},
				address: {
					country: edi.selectors.mergeConfigs('iso2_country_combo', {
						fieldConfig: {
							listeners: null,
							mandatory: false
						}
					}),
					city: edi.selectors.defaults.city,
					zip: edi.selectors.defaults.zip,
					street: edi.selectors.defaults.street
				}
			}
		});
		modalConf.title = edi.i18n.getMessage('document.delivery');
		return modalConf;
	},
	getShipFromFieldsMap: function (prefix) {
		const fieldsMap = edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'company_inn'].concat(edi.selectors.COMMON_FIELDS_MAP)
		});
		fieldsMap['code_by_sender'] = `${prefix}.CodeBySender`;
		fieldsMap['code_by_receiver'] = `${prefix}.CodeByReceiver`;
		fieldsMap['code_by_supplier'] = `${prefix}.CodeBySupplier`;
		return fieldsMap;
	},
	getShipFromModalConf: function () {
		const modalConf = edi.selectors.mergeModalCfgWithCommonOrgAddress(
			edi.selectors.getLegacyModalConf({
				main: {
					company_reg_number: null,
					company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
						regex: null,
						maxLength: 35
					})
				},
				contact: null
			})
		);
		modalConf.title = edi.i18n.getMessage('document.ship.from');
		return modalConf;
	},
	getUltimateCustomerFieldsMap: function (prefix) {
		return edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'addr_zip'].concat(edi.selectors.COMMON_FIELDS_MAP)
		});
	},
	getUltimateCustomerModalConf: function () {
		const modalConf = edi.selectors.mergeModalCfgWithCommonOrgAddress(
			edi.selectors.getLegacyModalConf({
				main: {
					company_reg_number: null
				},
				contact: null
			})
		);
		modalConf.title = edi.i18n.getMessage('documents.ultimate.customer');
		return modalConf;
	},
	getCommonDetailsFieldsMap: function (prefix) {
		return edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['type', 'company_name', 'company_iln', 'company_inn', 'addr_zip'].concat(
				edi.selectors.COMMON_FIELDS_MAP
			),
			additionalMap: {
				type: prefix + '.Type'
			}
		});
	}
};
