/**
 * Events managers
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.events');

/**
 * Document sharing events
 * @type {Ext.util.Observable}
 */
edi.events.documentsSharing = new Ext.util.Observable();
//edi.events.documentsSharing.addEvents({
//    "change": true
//});

/**
 * Supply agreement reports events
 * @type {Ext.util.Observable}
 */
edi.events.deferredReport = new Ext.util.Observable();
//edi.events.deferredReport.addEvents({
//	"create": true,
//	"change": true,
//	"delete": true
//});

/**
 * Delfor events
 * @type {Ext.util.Observable}
 */
edi.events.delfor = new Ext.util.Observable();
//edi.events.delfor.addEvents({
//	"create": true,
//	"change": true,
//	"delete": true
//});

/**
 * Delfor events
 * @type {Ext.util.Observable}
 */
edi.events.documentsPackage = new Ext.util.Observable();
//edi.events.documentsPackage.addEvents({
//	"create": true,
//	"change": true,
//	"changeAttaches": true,
//	"delete": true,
//	"changeSigner": true
//});

/**
 * service events
 * @type {Ext.util.Observable}
 */
edi.events.documentsService = new Ext.util.Observable();
//edi.events.documentsService.addEvents({
//	"create": true,
//	"change": true,
//	"delete": true
//});

edi.events.modules.on('rendered', function () {
	edi.methods.certificate.expiringCerts();
});

/**
 * Document sharing events
 * @type {Ext.util.Observable}
 */
edi.events.uploadDocument = new Ext.util.Observable();
//edi.events.uploadDocument.addEvents({
//	"change": true
//});

/**
 * delivery grid events
 * @type {Ext.util.Observable}
 */
edi.events.deliveryGrid = new Ext.util.Observable();
//edi.events.deliveryGrid.addEvents({
//	"add": true,
//	"edit": true
//});
edi.events.onAddExternalBtnHandler = new Ext.util.Observable();

edi.events.updateExternalStatus = new Ext.util.Observable();
