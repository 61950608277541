import { createLabelForDetails } from '@UIkit/components/fields';
import { createCheckbox, createCombo, createDateField, createFieldSet, createTextField } from '@Components/fields';
import { createFieldBlock, createFieldBlockForDetails, createFieldSetForDetails } from '@UIkit/components/panels';

Ext.Object.merge(edi.methods, {
	custom_fields: {
		checkGridRow: function (customFieldsObj, recordData, options) {
			let requiredFields = customFieldsObj.customFieldsConfigs.grid.filter((field) => {
				return field.needfull === true;
			});
			if (Array.isArray(options?.tabsForCheck)) {
				requiredFields = requiredFields.filter((field) => {
					return options.tabsForCheck.some((it) => it === field.tab);
				});
			}
			//проверяем, что все обязательные кастомные поля заполнены
			return requiredFields.every((field) => {
				let propName = field.xpath;
				let altName = customFieldsObj.xPathMap[field.xpath];
				let value = recordData[propName];
				let altValue = recordData[altName];
				return !Ext.isEmpty(value) || !Ext.isEmpty(altValue);
			});
		},
		getFieldType: function (customFieldsObj, place, key) {
			var fields = customFieldsObj?.customFieldsConfigs[place] ?? [];
			return fields.find((field) => {
				return field.xpath === key;
			});
		},
		getCustomFieldsData: function (customFieldsObj, values, products, topPath, isTree) {
			var customFieldsData = {},
				c1 = 0,
				c2 = 1;
			Object.entries(values).forEach(function ([key, val]) {
				if (key.match(topPath)) {
					var field = edi.methods.custom_fields.getFieldType(customFieldsObj, 'header', key);
					if (
						field &&
						field.fieldType === edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX &&
						typeof val === 'boolean' &&
						val.toString
					) {
						customFieldsData[key] = val.toString();
					} else {
						customFieldsData[key] = val;
					}
				}
			});
			if (products) {
				products.forEach(function (line, index) {
					Object.entries(line).forEach(function ([key, val]) {
						if (key.match(topPath) && val) {
							key = customFieldsObj.xPathMap[key];
							var field = edi.methods.custom_fields.getFieldType(customFieldsObj, 'grid', key);

							if (isTree) {
								if (line.packingLevel === 1) {
									key = key.replace('@', ++c1);
									c2 = 1;
								} else {
									key = key.replace('@', c1).replace('@', c2++);
								}
							} else {
								key = key.replace('@', index + 1);
							}

							if (
								field &&
								field.fieldType === edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX &&
								typeof val === 'boolean' &&
								val.toString
							) {
								customFieldsData[key] = val.toString();
							} else {
								customFieldsData[key] = val;
							}
						}
					});
				});
			}
			return customFieldsData;
		},
		initCustomFields: function (config, createCustomFieldsConf, insertCustomFieldsConf) {
			config = config || {};
			createCustomFieldsConf = createCustomFieldsConf ?? {};
			insertCustomFieldsConf = insertCustomFieldsConf ?? {};
			let previousCustomFieldsObj = Ext.clone(config.customFieldsObj) || null;
			var customFieldsData = config.initialCustomFieldsData;
			var continueFn = function (data) {
				config.customFieldsObj = edi.methods.custom_fields.createCustomFields({
					fieldsData: data.items,
					customFieldsData,
					readOnly: config.readOnly,
					...createCustomFieldsConf
				});
				edi.methods.custom_fields.insertCustomFields({
					customFieldsObj: config.customFieldsObj,
					container: config.container,
					grid: config.grid,
					isTree: config.isTree,
					options: {
						insertionCallback: config.isTree ? config.insertionCallback : null,
						previousCustomFieldsObj,
						readOnly: config.readOnly
					},
					...insertCustomFieldsConf
				});
				if (typeof config.finishCallback === 'function') {
					config.finishCallback(config.customFieldsObj, previousCustomFieldsObj);
				}
			};

			let fail = function () {
				edi.rest.getErrorHandler()();
				if (typeof config.failCallback === 'function') {
					config.failCallback();
				}
			};
			let successGetFields = function (response) {
				if (response.success && response.items && response.items.length) {
					if (config.docId) {
						let successGetFieldsData = function (fdata) {
							if (fdata.success) {
								customFieldsData = fdata.data;
								continueFn(response);
							} else {
								fail();
							}
						};

						let fieldsDataUrl = edi.utils.formatString(edi.rest.services.CUSTOM_FIELDS.GET_DATA, {
							docId: config.docId
						});
						edi.rest.sendRequest(fieldsDataUrl, 'GET', null, successGetFieldsData, fail);
					} else {
						continueFn(response);
					}
				} else {
					continueFn({ items: [] });
				}
			};
			let always = function () {
				if (Ext.isFunction(config.callback)) {
					config.callback(config.customFieldsObj);
				}
			};
			let fieldsUrl = edi.utils.formatString(edi.rest.services.CUSTOM_FIELDS.GET, {
				docType: config.docType,
				toOrgId: config.toOrgId,
				fromOrgId: config.fromOrgId
			});
			if (config.subDocType) {
				fieldsUrl = edi.utils.compileURL(fieldsUrl, {
					subDocType: config.subDocType
				});
			}

			if (!edi.permissions.hasPermission('EDI_DOCUMENT_CUSTOM_FIELDS_GET')) {
				successGetFields({ success: true });
				always();
			} else {
				edi.rest.sendRequest(fieldsUrl, 'GET', null, successGetFields, fail, always);
			}
		},
		createCustomFields: function ({ fieldsData, customFieldsData, readOnly }) {
			var getInputForReadHeaderForm = function (item) {
				let fieldType = item?.fieldType ?? null,
					itemValue = customFieldsData ? edi.utils.getObjectProperty(customFieldsData, item.xpath) : null;

				if (
					!fieldType ||
					fieldType === edi.constants.CUSTOM_FIELDS_TYPES.NULL ||
					fieldType === edi.constants.CUSTOM_FIELDS_TYPES.COMBOBOX
				) {
					return createLabelForDetails({
						text: itemValue
					});
				} else if (fieldType === edi.constants.CUSTOM_FIELDS_TYPES.DATE) {
					return createLabelForDetails({
						date: edi.utils.formatDate(itemValue, edi.constants.DATE_FORMAT.FNS, item.dateFormat)
					});
				} else if (fieldType === edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX) {
					const value = typeof itemValue === 'boolean' ? itemValue : itemValue === 'true';
					return createCheckbox({
						boxLabel: item.fieldName,
						disabled: true,
						checked: value
					});
				}
			};

			var getInputForEditHeaderForm = function (item) {
				const fieldType = item?.fieldType ?? null;
				const itemValue = customFieldsData ? edi.utils.getObjectProperty(customFieldsData, item.xpath) : null;

				if (!fieldType || fieldType === edi.constants.CUSTOM_FIELDS_TYPES.NULL) {
					const itemStoreValues = (item.defaultValues || []).map((it) => ({
						id: it,
						name: it
					}));

					return itemStoreValues.length === 0
						? createTextField({
								name: item.xpath,
								allowBlank: !item.needfull,
								value: itemValue
						  })
						: createCombo({
								name: item.xpath,
								allowBlank: !item.needfull,
								value: itemValue,
								store: edi.stores.createMemoryStore(itemStoreValues, 'SIMPLE', !item.needfull),
								forceSelection: false,
								queryMod: 'local',
								anyMatch: true
						  });
				} else if (fieldType === edi.constants.CUSTOM_FIELDS_TYPES.DATE) {
					return createDateField({
						allowBlank: !item.needfull,
						submitFormat: item.dateFormat,
						name: item.xpath,
						value: itemValue
					});
				} else if (fieldType === edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX) {
					return createCheckbox({
						name: item.xpath,
						boxLabel: item.fieldName,
						checked: typeof itemValue === 'boolean' ? itemValue : itemValue === 'true',
						inputValue: true,
						uncheckedValue: false
					});
				} else if (fieldType === edi.constants.CUSTOM_FIELDS_TYPES.COMBOBOX) {
					const itemStoreValues = (item.listValues || []).map((it) => ({
						id: it,
						name: it
					}));
					return createCombo({
						name: item.xpath,
						allowBlank: !item.needfull,
						value: itemValue,
						store: edi.stores.createMemoryStore(itemStoreValues, 'SIMPLE', !item.needfull),
						forceSelection: false,
						queryMod: 'local',
						anyMatch: true
					});
				}
			};

			var getInputForEditTabForm = function (item, values, cfg) {
				let fieldType = item?.fieldType ?? null,
					itemStoreValues = (item.defaultValues || []).map((it) => ({
						id: it,
						name: it
					})),
					xPath = item.xpath.replace(/\[@\]/g, '');

				if (!fieldType || fieldType === edi.constants.CUSTOM_FIELDS_TYPES.NULL) {
					return itemStoreValues.length === 0
						? createTextField(
								Ext.merge(
									{
										title: item.fieldName,
										allowBlank: !item.needfull,
										name: xPath,
										valueSrc: values
									},
									cfg
								)
						  )
						: createCombo(
								Ext.merge(
									{
										allowBlank: !item.needfull,
										name: xPath,
										valueSrc: values,
										valueInitialize: true,
										store: edi.stores.createMemoryStore(itemStoreValues, 'SIMPLE', !item.needfull),
										forceSelection: false,
										queryMod: 'local',
										anyMatch: true
									},
									cfg
								)
						  );
				} else if (fieldType === edi.constants.CUSTOM_FIELDS_TYPES.DATE) {
					return createDateField(
						Ext.merge(
							{
								allowBlank: !item.needfull,
								submitFormat: item.dateFormat,
								name: xPath,
								valueSrc: values
							},
							cfg
						)
					);
				} else if (fieldType === edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX) {
					if (values && values[xPath]) {
						const itemValue = values[xPath];
						typeof itemValue === 'boolean' ? null : (values[xPath] = itemValue === 'true');
					}

					return createCheckbox(
						Ext.merge(
							{
								checked: edi.utils.getObjectProperty(values, xPath),
								name: xPath,
								boxLabel: item.fieldName,
								valueSrc: values,
								inputValue: true,
								uncheckedValue: false
							},
							cfg
						)
					);
				} else if (fieldType === edi.constants.CUSTOM_FIELDS_TYPES.COMBOBOX) {
					const itemStoreValues = (item.listValues || []).map((it) => ({
						id: it,
						name: it
					}));
					return createCombo(
						Object.assign(
							{
								name: xPath,
								allowBlank: !item.needfull,
								valueSrc: values,
								store: edi.stores.createMemoryStore(itemStoreValues, 'SIMPLE', !item.needfull),
								forceSelection: false,
								queryMod: 'local',
								anyMatch: true
							},
							cfg
						)
					);
				}
			};

			var customFieldsObj = {
				headerFields: [],
				tabFields: {},
				customModelFields: [],
				customFieldsConfigs: {
					header: [],
					grid: []
				},
				customFieldsData: customFieldsData,
				xPathMap: {}
			};
			fieldsData?.forEach(function (item) {
				const fieldsWithoutTitle = [edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX];
				if (item.area === 'HEADER') {
					const inputField = !readOnly ? getInputForEditHeaderForm(item) : getInputForReadHeaderForm(item);
					const title = !fieldsWithoutTitle.includes(item.fieldType)
						? edi.utils.safeString(edi.i18n.getMessage(item.fieldName))
						: undefined;
					let area = [6];
					switch (item.fieldType) {
						case edi.constants.CUSTOM_FIELDS_TYPES.NULL:
						case edi.constants.CUSTOM_FIELDS_TYPES.COMBOBOX:
							area = [4];
							break;
						case edi.constants.CUSTOM_FIELDS_TYPES.DATE:
							area = [2];
							break;
					}
					const fieldWrapperConf = {
						title,
						custom: true,
						layout: {
							type: 'grid',
							area
						},
						items: [inputField]
					};
					const fieldWrapper = !readOnly
						? createFieldBlock(fieldWrapperConf)
						: createFieldBlockForDetails(fieldWrapperConf);
					customFieldsObj.headerFields.push(fieldWrapper);

					customFieldsObj.customFieldsConfigs.header.push(Ext.clone(item));
				} else {
					if (!customFieldsObj.tabFields[item.tab]) {
						customFieldsObj.tabFields[item.tab] = [];
					}
					var xPath = item.xpath.replace(/\[@\]/g, '');
					customFieldsObj.xPathMap[xPath] = item.xpath;
					customFieldsObj.xPathMap[item.xpath] = xPath;
					const title = !fieldsWithoutTitle.includes(item.fieldType)
						? edi.utils.safeString(edi.i18n.getMessage(item.fieldName))
						: null;
					customFieldsObj.tabFields[item.tab].push({
						title,
						type: function (cfg, values) {
							if (readOnly) {
								Object.assign(cfg, { readOnly: true });
							}
							return getInputForEditTabForm(item, values, cfg);
						}
					});
					customFieldsObj.customModelFields.push({
						name: xPath,
						type: 'string'
					});
					customFieldsObj.customFieldsConfigs.grid.push(Ext.clone(item));
				}
			});
			return customFieldsObj;
		},
		insertCustomFields: function ({
			customFieldsObj,
			container,
			grid,
			isTree,
			options,
			extraCustomFieldsWrapperConf = {}
		}) {
			//в некоторых документах есть слушатель события change, который пересоздаёт содержимое модуля
			//при этом запрос на кастомные поля отправляется повторно, а тот первый (при открытии модуля)
			//не сможет найти удаленный container и упадет в ошибку
			if (container?.destroyed || !container?.items) {
				return;
			}

			let opts = Ext.merge(
				{
					insertionCallback: null,
					previousCustomFieldsObj: null
				},
				options
			);

			if (container) {
				// удаление старых кастомных полей из формы
				container.removeAll();

				const headerFields = customFieldsObj.headerFields.filter(Boolean);
				container.setVisible(headerFields.length);
				if (headerFields.length) {
					const customFieldsWrapperConf = Object.assign(
						{
							title: edi.i18n.getMessage('custom.fields'),
							collapsible: true,
							layout: {
								type: 'grid',
								gap: 24
							},
							items: headerFields
						},
						extraCustomFieldsWrapperConf
					);

					const customFieldsWrapper = options.readOnly
						? createFieldSetForDetails(customFieldsWrapperConf)
						: createFieldSet(customFieldsWrapperConf);
					container.add(customFieldsWrapper);
				}
			}

			//insert custom fields into grid
			if (!grid) {
				return;
			}
			//update grid's model
			let gridModel;
			const extId = Ext.id();
			if (isTree) {
				//gridModel = Ext.ModelManager.getModel(edi.models.getModel(grid.gridModel));
				gridModel = Ext.define('edi.models.' + grid.id + extId, {
					extend: edi.models.getModel(grid.gridModel)
				});
				grid.productsGrid.getStore().getProxy().setModel(gridModel);
				grid.gridModel = grid.id;
				edi.models.names[grid.id] = 'edi.models.' + grid.id + extId;
			} else {
				gridModel = grid.productsGrid.getStore().model;
				gridModel = Ext.define('edi.models.' + grid.id + extId, {
					extend: gridModel.$className
				});
				grid.productsGrid.getStore().getProxy().setModel(gridModel);
				grid.gridModel = grid.id;
				edi.models.names[grid.id] = 'edi.models.' + grid.id + extId;
			}

			//update grid's modal form
			if (grid.modalFormConfig.snapshot) {
				grid.modalFormConfig.modalFields = grid.modalFormConfig.snapshot;
			}
			if (isTree) {
				grid.customPackingFields = {};
			}
			if (Array.isArray(opts.previousCustomFieldsObj) && opts.previousCustomFieldsObj.length) {
				gridModel.removeFields(opts.previousCustomFieldsObj);
			}

			if (Object.keys(customFieldsObj.tabFields).length > 0) {
				grid.modalFormConfig.snapshot = Ext.clone(grid.modalFormConfig.modalFields);
				Object.entries(customFieldsObj.tabFields).forEach(function ([tabName, fields]) {
					var tab = grid.modalFormConfig.modalFields.find(function (item) {
						return item.customFieldTab === tabName;
					});
					if (tab) {
						tab.items = tab.items.concat(fields);
					}
				});
			}
			if (isTree) {
				grid.customPackingFields = customFieldsObj.tabFields;
			}
			if (customFieldsObj.customModelFields && customFieldsObj.customModelFields.length) {
				//var modelFields = gridModel.snapshot = gridModel.getFields();
				gridModel.addFields(customFieldsObj.customModelFields);
			}

			//insert custom fields data into grid rows
			if (isTree) {
				if (typeof opts.insertionCallback === 'function') {
					opts.insertionCallback(customFieldsObj, opts.previousCustomFieldsObj);
				}
			} else {
				let storeItems = grid.productsGrid.getStore().getRange();
				let storeProxyItems = grid.productsGrid.getStore().getProxy()?.data?.items;
				let gridRecords =
					Array.isArray(storeItems) && storeItems.length > 0
						? storeItems.map((r) => r.data)
						: Array.isArray(storeProxyItems) && storeProxyItems.length > 0
						? storeProxyItems
						: [];
				//remove old unnecessary custom fields data from rows
				let oldXpathMap = opts.previousCustomFieldsObj?.xPathMap;
				if (oldXpathMap && Object.keys(oldXpathMap).length > 0) {
					//remove fields which are not presented in new customFields
					let fieldsToRemove = Object.keys(oldXpathMap).filter((f) => f.match(/@/));

					let newXpathMap = customFieldsObj?.xPathMap;
					if (newXpathMap && Object.keys(newXpathMap).length > 0) {
						fieldsToRemove = fieldsToRemove.filter((key) => {
							return !newXpathMap[key];
						});
					}
					if (fieldsToRemove.length > 0) {
						gridRecords.forEach((line) => {
							fieldsToRemove
								.map((key) => oldXpathMap[key])
								.forEach((key) => {
									delete line[key];
								});
						});
					}
				}

				//add new custom fields data to row
				if (customFieldsObj?.customFieldsData) {
					gridRecords.forEach((line, index) => {
						Object.entries(customFieldsObj.customFieldsData).forEach(function ([key, val]) {
							var regexp = new RegExp(`\\[${index + 1}\\]`);
							if (key.match(regexp)) {
								key = key.replace(regexp, '[@]');
								var lineKey = customFieldsObj.xPathMap[key];
								lineKey ? (line[lineKey] = val) : null;
							}
						});
					});
				}
			}
		}
	}
});
