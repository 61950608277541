const isApprovalStateRejected = function (checkOptions) {
	let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
	return approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
};

const dsfUnformalizedRules = Ext.clone(edi.action.rulesByDocType[edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED]);
const dsfInvoiceRules = Ext.clone(edi.action.rulesByDocType[edi.constants.DOCUMENT_TYPES.DSF_INVOICE]);

dsfUnformalizedRules.SIGN.INCOMING.DOCUMENTS.push(
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
);

dsfUnformalizedRules.SIGN.conditions.unshift({
	documents: edi.constants.DSF_WITH_APPROVE,
	customMethod: isApprovalStateRejected,
	allow: false
});

// Строки
//dsfUnformalizedRules.REJECT.OUTGOING.BUSINESS_STATE.push(edi.constants.STATE.SENDER_REVIEW)
//dsfUnformalizedRules.REJECT.OUTGOING.DOCUMENTS.push(edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED)
// заменены на те, что ниже
// удалить после того, как полностью проработаются action rules для ДСФ

dsfUnformalizedRules.REJECT.OUTGOING = {
	BUSINESS_STATE: [edi.constants.STATE.SENDER_REVIEW],
	DOCUMENTS: edi.constants.DSF_WITH_APPROVE
};

//dsfUnformalizedRules.REJECT.OUTGOING.BUSINESS_STATE.push(edi.constants.STATE.SENDER_REVIEW)
//dsfUnformalizedRules.REJECT.OUTGOING.DOCUMENTS.push(edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED)
dsfUnformalizedRules.REJECT.conditions.unshift({
	documents: edi.constants.DSF_WITH_APPROVE,
	states: [edi.constants.STATE.SENDER_REVIEW],
	allow: isApprovalStateRejected
});

dsfUnformalizedRules.EXPORT.conditions.push({
	documents: [
		edi.constants.DOCUMENT_TYPES.DSF_NDA,
		edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
		edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP
	]
});

dsfUnformalizedRules.SEND_TO_APPROVE_DOCUMENTS = {
	OUTGOING: {
		DOCUMENTS: edi.constants.DSF_WITH_APPROVE,
		BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
	},
	INCOMING: {
		DOCUMENTS: edi.constants.DSF_WITH_APPROVE,
		BUSINESS_STATE: [
			edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
			edi.constants.STATE.RECEIVER_REVIEW,
			edi.constants.STATE.SENT,
			edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL
		]
	},
	conditions: [
		{
			documents: edi.constants.DSF_WITH_APPROVE,
			directions: [edi.constants.DIRECTIONS.OUTGOING],
			states: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW],
			allow: () => true
		},
		{
			documents: edi.constants.DSF_WITH_APPROVE,
			directions: [edi.constants.DIRECTIONS.INCOMING],
			states: [
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SENT
			],
			allow: function (checkOptions) {
				let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
				return !approvalState || approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
			}
		}
	]
};
dsfUnformalizedRules.SEND_TO_APPROVE_EMPLOYEES = {
	OUTGOING: {
		DOCUMENTS: edi.constants.DSF_WITH_APPROVE,
		BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
	},
	INCOMING: {
		DOCUMENTS: edi.constants.DSF_WITH_APPROVE,
		BUSINESS_STATE: [
			edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
			edi.constants.STATE.RECEIVER_REVIEW,
			edi.constants.STATE.SENT,
			edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL
		]
	},
	conditions: [
		{
			documents: edi.constants.DSF_WITH_APPROVE,
			directions: [edi.constants.DIRECTIONS.OUTGOING],
			states: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW],
			allow: () => true
		},
		{
			documents: edi.constants.DSF_WITH_APPROVE,
			directions: [edi.constants.DIRECTIONS.INCOMING],
			states: [
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SENT
			],
			allow: function (checkOptions) {
				let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
				return !approvalState || approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
			}
		}
	]
};

dsfUnformalizedRules.COPY.OUTGOING.DOCUMENTS.push(
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
);
dsfUnformalizedRules.COPY.LOOP.DOCUMENTS.push(
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
);

dsfUnformalizedRules.ANNUL.INCOMING.DOCUMENTS.push(
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
);
dsfUnformalizedRules.ANNUL.OUTGOING.DOCUMENTS.push(
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
);

dsfUnformalizedRules.ANNUL.conditions.unshift({
	documents: [
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
		edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6
	],
	allow() {
		return false;
	}
});

dsfInvoiceRules.ANNUL.conditions.unshift({
	documents: [edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP, edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02],
	allow() {
		return false;
	}
});

dsfInvoiceRules.REJECT.conditions.unshift({
	documents: [edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP, edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02],
	allow() {
		return false;
	}
});

//Применим все изменения конфига для всех документов
//клонирование нужно, что бы для каждого документа конфиг был свой независимый (не мутировал по ссылке из другого дока)
[
	...edi.constants.DSF_WITH_APPROVE,
	edi.constants.DOCUMENT_TYPES.DSF_NDA,
	edi.constants.DOCUMENT_TYPES.DSF_AKTPRM,
	edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC1,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC2,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC3,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC4,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC5,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_DC6,
	edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_CP
].forEach((type) => {
	edi.action.rulesByDocType[type] = Ext.clone(dsfUnformalizedRules);
});
[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP, edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02].forEach((type) => {
	edi.action.rulesByDocType[type] = Ext.clone(dsfInvoiceRules);
});
