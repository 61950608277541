import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { getReestrColumns } from './columns';
import { getReestrModelName } from './models';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createActionsButton } from '@Components/buttons';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createActionsColumnConfig, createGrid, ROW_COLOR_CLS } from '@UIkit/components/grid';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import {
	createFieldBlock,
	createFieldBlockForDetails,
	createModalForm,
	FIELD_BLOCK_CLS,
	createContainer
} from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createTextField, createLabelBlockForDetails } from '@UIkit/components/fields';

const DATE_SRC_FORMAT = 'Ymd';
const DATE_DISPLAY_FORMAT = 'd.m.Y';
const MODE_DETAILS = 'details';
const MODE_REJECTING = 'rejecting';
const PATH_TO_GRID_CONTENT = 'POSITIONS.POSITION';
const ERR_STATE = 'ERR';

Ext.namespace('edi.modules');
edi.modules['document.details.dsf_reestr_route'] = function () {
	let moduleData,
		docHeader,
		docContent,
		detailsActionsPanel,
		rejectActionsPanel,
		routesGrid,
		direction,
		signBtn,
		rejectBtn,
		restoreBtn1,
		restoreBtn2;
	let documentMode = MODE_DETAILS;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called after initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docHeader = moduleData.initData.data || {};
		direction = edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg);
		docContent = {};
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Alias for edi.i18n.getMessage(key)
	 * @param	{String}	key
	 * @return	{String}	translation or key if not found in messages
	 */
	let i18n = function (key) {
		return edi.i18n.getMessage(key, undefined, undefined, undefined);
	};

	/**
	 * Alias for edi.utils.getObjectProperty(docContent, path);
	 * @param	{String}	path
	 * @return	{String|Object|Array}	result or empty string
	 */
	let getContentValue = function (path) {
		return edi.utils.getObjectProperty(docContent, path);
	};

	/**
	 * Creates document's head panel with header main data
	 * @returns	{Object}
	 */
	let createDocHeadPanel = function () {
		return createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noHistory: true,
			noSumm: true,
			noUsage: true,
			noPackage: true,
			noAperakStatus: true
		});
	};

	/**
	 * Finds organization by it's GLN in partners and itself
	 * @param	{String}	gln
	 * @returns	{Object|null}	organization
	 */
	let findOrgByGln = function (gln) {
		let relations = edi.relations.getRelations({ self: true });
		return relations.find((r) => r.iln === gln) || null;
	};

	/**
	 * Creates company.selector with default params
	 * @param	{Object}	config
	 * @returns	{Object}	company.selector instance
	 */
	let createOS = function (config) {
		let conf = Ext.merge(
			{
				readOnly: true,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				useHiddenFields: false,
				valuesByMap: false,
				is_valid: true
			},
			config
		);
		return createOrgSelector(conf);
	};

	const createTypeNumberDate = function () {
		return createFieldBlockForDetails({
			userCls: edi.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: i18n('field.name.number'),
							text: getContentValue('REESTR_NUM')
						},
						{
							title: i18n('date'),
							text: edi.utils.formatDate(
								getContentValue('RGNUM_DATE'),
								DATE_DISPLAY_FORMAT,
								DATE_SRC_FORMAT
							)
						}
					]
				})
			]
		});
	};

	let createAdditionalInfo = function () {
		const reestrTypeFromJson = getContentValue('REESTR_TYPE');
		const reestrTypeTranslationKey = `dsf_reestr_route.reestr_type.${reestrTypeFromJson}`;
		const reestrTypeTranslation = edi.i18n.getMessage(reestrTypeTranslationKey);
		const reestrType =
			reestrTypeTranslation !== reestrTypeTranslationKey ? reestrTypeTranslation : reestrTypeFromJson;

		return createFieldBlockForDetails({
			title: i18n('documents.dsf.additionalInformation'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: i18n('dsf_reestr_route.identifier'),
							text: edi.utils.getAttributeByName(docHeader.attributes, 'messageId')
						},
						{
							title: i18n('dsf_reestr_route.reestr_type'),
							text: reestrType
						},
						{
							title: i18n('dsf_reestr_route.sbitovoj_zakaz'),
							text: getContentValue('VBELN_SD')
						},
						{
							isNewLine: true,
							title: i18n('dsf_reestr_route.contract_number'),
							text: getContentValue('N_KONTR_SD')
						},
						{
							title: i18n('dsf_reestr_route.contract_date'),
							text: edi.utils.formatDate(
								getContentValue('DT_KONTR_SD'),
								DATE_DISPLAY_FORMAT,
								DATE_SRC_FORMAT
							)
						},
						{
							isNewLine: true,
							title: i18n('dsf_reestr_route.total_wo_tax'),
							text: getContentValue('SUM_BZ_NDS')
						},
						{
							title: i18n('dsf_reestr_route.total_tax'),
							text: getContentValue('SUM_NDS')
						},
						{
							title: i18n('dsf_reestr_route.total_with_tax'),
							text: getContentValue('SUM_S_NDS')
						},
						{
							isNewLine: true,
							title: i18n('dsf_reestr_route.period_be'),
							date: [getContentValue('PERIOD_B'), getContentValue('PERIOD_E')]
						}
					]
				})
			]
		});
	};

	/**
	 * Creates document's form with fields and grid
	 * @returns	{Object}
	 */
	let createDocForm = function () {
		let sender = createFieldBlockForDetails({
			title: i18n('dsf_reestr_route.sender'),
			items: [
				createOS({
					fieldValues: edi.converters.convertOrgToLegacyPartie(findOrgByGln(docHeader.fromOrg.iln))
				})
			]
		});

		let receiver = createFieldBlockForDetails({
			title: i18n('dsf_reestr_route.receiver'),
			items: [
				createOS({
					fieldValues: edi.converters.convertOrgToLegacyPartie(findOrgByGln(docHeader.toOrg.iln))
				})
			]
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6], 12, 12]
			},
			items: [
				createDocHeadPanel(),
				createTypeNumberDate(),
				sender,
				receiver,
				createAdditionalInfo(),
				createRoutesGrid()
			]
		});
	};

	/**
	 * Sets date\time and send rejected doc
	 */
	let rejectActionHandler = function () {
		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'document.error.in.reject.process');

		let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, { documentId: docHeader.id }, true);

		let successPush = function () {
			let successReject = function () {
				moduleData.tab.setLoading(false);
				edi.events.documents.fireEvent('change', { id: docHeader.id });
			};
			let rejectData = Ext.encode({
				REJECT: true
			});
			edi.rest.sendRequest(url, 'PUT', rejectData, successReject, failure);
		};

		let pushData = Ext.encode({
			SET_ERR: true,
			UPDATE: true
		});
		moduleData.tab.setLoading(true);
		edi.rest.sendRequest(url, 'PUT', pushData, successPush, failure);
	};

	/**
	 * Restores grid's data from previously saved values
	 */
	let resetGridChanges = function () {
		let success = function () {
			moduleData.tab.setLoading(false);
			edi.events.documents.fireEvent('change', { id: docHeader.id });
		};

		let failure = edi.document.actions.defaultFailureHandler(
			moduleData.tab,
			'dsf_reestr_route.error.resetting.changes'
		);

		let url = edi.utils.formatString(
			edi.rest.services.DOCUMENTS.SEND.PUT,
			{
				documentId: docHeader.id
			},
			true
		);

		let payload = Ext.encode({
			UPDATE: true,
			CLEAN: true
		});

		moduleData.tab.setLoading(true);
		edi.rest.sendRequest(url, 'PUT', payload, success, failure);
	};

	/**
	 * Opens window for editing reject reason for grid's row
	 * @param	{Object}	record
	 * @returns	{Object}	instance of Ext.window.Window
	 */
	let openEditRejectReasonModal = function (record) {
		let reasonFieldInput;
		let reasonField = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: i18n('dsf_reestr_route.reject.reason'),
			items: [
				(reasonFieldInput = createTextField({
					maxLength: 40
				}))
			]
		});

		let saveBtn = createButton({
			cls: BUTTON_CLS.primary,
			text: i18n('form.btn.save'),
			handler() {
				let reasonTxt = reasonFieldInput.getValue();

				let success = function () {
					modal.setLoading(false);
					record.set({
						STATUS_DESCR: reasonTxt,
						STATUS_POS: ERR_STATE
					});
					updateActionButtons();
					modal.close();
				};

				let failure = edi.document.actions.defaultFailureHandler(
					modal,
					'dsf_reestr_route.error.resetting.changes'
				);

				let url = edi.utils.formatString(
					edi.rest.services.DOCUMENTS.SEND.PUT,
					{
						documentId: docHeader.id
					},
					true
				);
				let putData = {
					UPDATE: true,
					UPDATE_PARAMS: {
						data: Ext.encode([
							{
								POSITION_NUM: record.get('POSITION_NUM'),
								STATUS_POS: ERR_STATE,
								STATUS_DESCR: reasonTxt
							}
						])
					}
				};

				modal.setLoading(true);
				edi.rest.sendRequest(url, 'PUT', Ext.encode(putData), success, failure);
			}
		});

		let modal = createModalPanel({
			title: i18n('dsf_reestr_route.reject_modal.title'),
			autoShow: true,
			width: MODAL_SIZE.widthLarge,
			items: [
				createModalForm({
					items: [reasonField],
					listeners: {
						validitychange: function (form) {
							saveBtn.setDisabled(!form.isValid());
						}
					}
				})
			],
			buttonsBefore: [saveBtn]
		});

		return modal;
	};

	/**
	 * Creates grid with routes
	 * @returns	{Object}	Ext.grid.Panel instance
	 */
	let createRoutesGrid = function () {
		let columns = getReestrColumns();
		columns.push(
			createActionsColumnConfig({
				items: [
					{
						tooltip: i18n(''),
						glyph: edi.constants.ICONS.ADD,
						handler(_gridView, _rIndex, _cIndex, _item, _e, record) {
							openEditRejectReasonModal(record);
						},
						isHidden() {
							return documentMode === MODE_DETAILS;
						}
					}
				]
			})
		);

		routesGrid = createGrid({
			storeConfig: {
				model: getReestrModelName(),
				data: getContentValue(PATH_TO_GRID_CONTENT) || [],
				autoLoad: false
			},
			gridConfig: {
				title: i18n('dsf_reestr_route.routes'),
				columns,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				padding: 0,
				border: 1
			},
			viewConfig: {
				getRowClass(record) {
					return record.get('STATUS_POS') === ERR_STATE ? ROW_COLOR_CLS.error : '';
				}
			},
			pagingBarConfig: {
				allowExport: false,
				refreshBtnHandler: () => {}
			}
		});
		return routesGrid;
	};

	/**
	 * Enable\disable sign and reset buttons
	 */
	let updateActionButtons = function () {
		if (
			docHeader.state === edi.constants.STATE.RECEIVER_REVIEW &&
			edi.constants.DIRECTIONS.INCOMING === direction
		) {
			let hasErrorRows = routesGrid
				.getStore()
				.getRange()
				.some((r) => {
					return r.get('STATUS_POS') === ERR_STATE;
				});
			if (typeof signBtn?.setDisabled === 'function') {
				signBtn.setDisabled(hasErrorRows);
			}
			if (typeof rejectBtn?.setDisabled === 'function') {
				rejectBtn.setDisabled(!hasErrorRows);
			}
			if (typeof restoreBtn1?.setDisabled === 'function') {
				restoreBtn1.setDisabled(!hasErrorRows);
			}
			if (typeof restoreBtn2?.setDisabled === 'function') {
				restoreBtn2.setDisabled(!hasErrorRows);
			}
		}
	};

	/**
	 * Switches interface to rejecting edit mode and make grid's data backup
	 * @param	{String}	newState
	 */
	let changeDocumentMode = function (newState) {
		documentMode = newState || MODE_DETAILS;

		if (documentMode === MODE_DETAILS) {
			rejectActionsPanel.hide();
			detailsActionsPanel.show();
		} else if (documentMode === MODE_REJECTING) {
			detailsActionsPanel.hide();
			rejectActionsPanel.show();
		}

		routesGrid.getView().refresh();
		updateActionButtons();
	};

	/**
	 * Creates document's details actions panel
	 * @returns	{Object}	Ext.Panel instance
	 */
	let createDetailsActionsPanels = function () {
		let needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(docHeader, edi.constants.CONTRACT_SIDES.CONSUMER);
		let signaturesCount =
			edi.document.actions.getSignCount(docHeader) + needSignatures2 - docHeader.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount;

		let customButtons = [];
		let allowRejectAction =
			docHeader.state === edi.constants.STATE.RECEIVER_REVIEW && direction === edi.constants.DIRECTIONS.INCOMING;
		if (allowRejectAction) {
			restoreBtn1 = createActionsButton({
				showInFirstHalf: true,
				order: 275,
				tooltip: i18n('dsf_reestr_route.action.cancel.rejecting'),
				text: i18n('dsf_reestr_route.action.cancel.rejecting'),
				glyph: edi.constants.ICONS.RESTORE,
				handler() {
					edi.core.confirm(null, 'dsf_reestr_route.action.cancel.rejecting.warning', function () {
						resetGridChanges();
					});
				}
			});
			customButtons.push(restoreBtn1);

			customButtons.push(
				createActionsButton({
					showInFirstHalf: true,
					order: 276,
					tooltip: i18n('dsf_reestr_route.action.reject.with.correction'),
					text: i18n('dsf_reestr_route.action.reject.with.correction'),
					glyph: edi.constants.ICONS.REJECT,
					handler() {
						changeDocumentMode(MODE_REJECTING);
						edi.core.showInfo('dsf_reestr_route.help_text.reject');
					}
				})
			);
		}

		detailsActionsPanel = createActionsPanel();
		edi.document.actions.createDocumentActionButtons(detailsActionsPanel, {
			data: docHeader,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				SIGN: {
					methodAddOptions: {
						buttonProps: {
							itemId: 'sign_button',
							tooltip: i18n('form.btn.sign')
						},
						useBeforeAction: false,
						signProperties: {
							//ПУШ перед получением контента на подпись
							beforeSetSignature: allowRejectAction
								? function (continueSign, failCallback) {
										let data = Ext.encode({
											SET_OK: true,
											UPDATE: true
										});
										let url = edi.utils.formatString(
											edi.rest.services.DOCUMENTS.SEND.PUT,
											{ documentId: docHeader.id },
											true
										);
										edi.rest.sendRequest(url, 'PUT', data, continueSign, failCallback);
								  }
								: null
						}
					}
				},
				EXPORT: {
					label: i18n('action.export.document'),
					hideDefaultExport: true,
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					extendedExportBtnLabel: i18n('export.group.request.menu.btn.all'),
					addExtendedExport:
						docHeader.state === edi.constants.STATE.SENDER_REVIEW ||
						docHeader.state === edi.constants.STATE.COMPLETED,
					extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: docHeader.id
					})
				},
				REFRESH: {
					handler: changeHandler
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});
		signBtn = detailsActionsPanel.down('button[itemId="sign_button"]');

		return detailsActionsPanel;
	};

	/**
	 * Creates document's reject actions panel
	 * @returns	{Object}	Ext.Panel instance
	 */
	let createRejectActionsPanels = function () {
		rejectActionsPanel = createActionsPanel();
		rejectActionsPanel.add(
			createActionsButton({
				tooltip: i18n('dsf_reestr_route.action.close.rejecting'),
				text: i18n('dsf_reestr_route.action.close.rejecting'),
				glyph: edi.constants.ICONS.CLOSE,
				handler() {
					changeDocumentMode(MODE_DETAILS);
				}
			})
		);

		restoreBtn2 = createActionsButton({
			tooltip: i18n('dsf_reestr_route.action.cancel.rejecting'),
			text: i18n('dsf_reestr_route.action.cancel.rejecting'),
			glyph: edi.constants.ICONS.RESTORE,
			handler() {
				edi.core.confirm(null, 'dsf_reestr_route.action.cancel.rejecting.warning', function () {
					resetGridChanges();
				});
			}
		});
		rejectActionsPanel.add(restoreBtn2);

		rejectBtn = createActionsButton({
			tooltip: i18n('dsf_reestr_route.action.send.rejected'),
			text: i18n('dsf_reestr_route.action.send.rejected'),
			glyph: edi.constants.ICONS.REJECT,
			handler() {
				edi.core.confirm(null, 'dsf_reestr_route.action.send.rejected.warning', function () {
					rejectActionHandler();
				});
			}
		});
		rejectActionsPanel.add(rejectBtn);

		return rejectActionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	let changeHandler = function () {
		edi.document.actions.changeHandler(
			docHeader,
			moduleData,
			function (headerData) {
				docHeader = headerData.data;
				moduleData.initData.data = docHeader;
			},
			renderData
		);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called after initialization
	 */
	let renderData = function (initCallBack) {
		moduleData.tab.setLoading(true);

		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', () =>
			edi.modulesHandler.removeModule(moduleData)
		);

		let success = function (data) {
			if (data && data.data) {
				docContent = data.data;

				moduleData.tab.removeAll();
				moduleData.tab.add(createDetailsActionsPanels());
				moduleData.tab.add(createRejectActionsPanels());
				moduleData.tab.add(
					createDetailsModulePanel({
						items: [createDocForm()]
					})
				);
				changeDocumentMode(MODE_DETAILS);

				if ('function' === typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}
			} else {
				failure(data);
			}
		};

		let url = edi.document.actions.formatDetailsUri(moduleData.initData);
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	let onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		return true;
	};
};

export { DATE_SRC_FORMAT, DATE_DISPLAY_FORMAT };
