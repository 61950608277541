import './css_ediweb.js';

import 'core-js/stable';
import '../../src/main/webapp/js/constants.js';
import '@UIkit';
import '../../src/main/webapp/js/utils.js';
import '../../src/main/webapp/js/extensions.js';
import '../../src/main/webapp/js/core.js';
import '../../src/main/webapp/js/loading.js';
import '../../src/main/webapp/js/core/index.js';
import '../../src/main/webapp/js/ediweb/index.js';

import './startApp.js';
