import { createActionsColumnConfig, createGrid, createToolBar } from '@Components/grid';
import { createModulePanel } from '@Components/panels';
import { createAddButton } from '@Components/buttons';

Ext.namespace('edi.modules');
/**
 *
 * @author Alexander Dudin
 */
edi.modules['patterns.generation.doc.num'] = function () {
	var moduleData, grid;
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.numberDocGen.on('change', gridDataChangedHandler);
	};

	/**
	 * Initializes grid
	 * @returns {Object} Instance of grid
	 */
	var createModuleGrid = function () {
		var columns = edi.columns.get('document_generation_numbers');

		var deleteNumberDoc = function (record) {
			var activeModule = edi.modulesHandler.getActiveModule();
			activeModule.tab.setLoading();

			var url = edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.DELETE;

			url = edi.utils.formatString(url, {
				id: record.id
			});

			edi.rest.sendRequest(url, 'DELETE', null, function () {
				edi.events.numberDocGen.fireEvent('change');
				activeModule.tab.setLoading(false);
			});
		};

		var typesAllow = function (records) {
			var documentTypes = [],
				itemsReal = [];

			var pushDocTypes = function (value) {
				documentTypes.push({
					id: value,
					name: edi.i18n.getMessage('documents.doctype.' + value)
				});
			};

			for (var k = 0; k < records.data.items.length; k++) {
				itemsReal.push(records.data.items[k].data.typeDoc);
			}
			edi.constants.MODULES.DOCUMENTS.GENERATION_DOC_NUM =
				edi.constants.MODULES.DOCUMENTS.GENERATION_DOC_NUM || [];
			let includeType = edi.constants.MODULES.DOCUMENTS.INCLUDED_TYPES.concat(
				edi.constants.MODULES.DOCUMENTS.GENERATION_DOC_NUM
			);
			const excludedTypes = [
				edi.constants.DOCUMENT_TYPES.EDI_CONTRL,
				edi.constants.DOCUMENT_TYPES.EDI_INVRPT,
				edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
				edi.constants.DOCUMENT_TYPES.SLSRPT,
				edi.constants.DOCUMENT_TYPES.EDI_SLSRPT,
				edi.constants.DOCUMENT_TYPES.EDI_SLSFCT
			];
			includeType = includeType.filter((doctype) => !excludedTypes.includes(doctype));

			for (var i in edi.constants.DOCUMENT_TYPES) {
				if (edi.constants.DOCUMENT_TYPES.hasOwnProperty(i)) {
					if (includeType.some((it) => it === edi.constants.DOCUMENT_TYPES[i])) {
						var temp = true;
						for (var j = 0; j < itemsReal.length; j++) {
							if (edi.constants.DOCUMENT_TYPES[i] === itemsReal[j]) {
								temp = !temp;
								break;
							}
						}
						if (temp) {
							pushDocTypes(edi.constants.DOCUMENT_TYPES[i]);
						}
					}
				}
			}

			return documentTypes;
		};

		columns.push(
			createActionsColumnConfig({
				items: [
					{
						glyph: edi.constants.ICONS.COPY,
						handler: function (grid, rowIndex) {
							var record = grid.getStore().getAt(rowIndex).getData();
							var docTypes = typesAllow(grid.getStore());
							edi.methods.numberDocumentGen.copy(record, docTypes);
						}
					},
					{
						glyph: edi.constants.ICONS.EDIT,
						testCls: 'test-action-column-edit',
						handler: function (grid, rowIndex) {
							var record = grid.getStore().getAt(rowIndex).getData();
							edi.methods.numberDocumentGen.edit(record);
						}
					},
					{
						glyph: edi.constants.ICONS.REMOVE,
						testCls: 'test-action-column-remove',
						handler: function (grid, rowIndex) {
							var record = grid.getStore().getAt(rowIndex).getData();
							deleteNumberDoc(record);
						}
					},
					{
						glyph: edi.constants.ICONS.DETAILS,
						handler: function (grid, rowIndex) {
							var record = grid.getStore().getAt(rowIndex).getData();
							edi.methods.numberDocumentGen.detail(record);
						}
					}
				]
			})
		);

		grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET
			},
			storeConfig: {
				model: edi.models.getModel('GENERATION_NUMBER_DOC'),
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: true
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				dockedItems: createToolBar({
					items: [
						createAddButton(() => {
							var docTypes = typesAllow(grid.getStore());
							edi.methods.numberDocumentGen.create(null, docTypes);
						})
					]
				}),
				border: 0,
				autoScroll: true,
				disableSelection: true
			}
		});

		return grid;
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});
		grid = createModuleGrid();
		modulePanel.add(grid);
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};
	/**
	 * Reloads grid
	 */
	var gridDataChangedHandler = function () {
		var gridSelModel = grid.getSelectionModel();
		if (gridSelModel) {
			gridSelModel.deselectAll();
		}
		grid.getStore().reload();
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.numberDocGen.un('change', gridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
