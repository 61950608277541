const APPLICATION_COLUMN = 'ew_org_profile_application_to_fns';

edi.columns.addColumns({
	[APPLICATION_COLUMN]: {
		type: {
			text: 'ediweb.application_to_fns.application',
			dataIndex: 'type',
			sortable: false,
			renderer: function (value, metaData, record) {
				var previous = edi.utils.getAttributeByName(record.get('attributes'), 'PREVIOUS');
				let type = !previous ?
					edi.constants.DOCUMENT_TYPES.EDI_APPLICATION_TO_FNS
					: 	edi.constants.DOCUMENT_TYPES.EDI_APPLICATION_TO_FNS_CHANGE;
				return edi.i18n.getMessage('ediweb.application_to_fns.application.type.' + type);
			},
			flex: 1
		},
		creationDate: {
			text: 'ediweb.application_to_fns.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateFromMs'
		}
	}
});

export { APPLICATION_COLUMN };
