import { getPartinBranchesColumns, getPartinHeadquartersColumns, getPartinLocalizationsColumns } from './columns.js';
import {
	getPartinBranchesModel,
	getPartinHeadquartersModel,
	getPartinLicensesModel,
	getPartinLocalizationsModel,
	PARTIN_BRANCHES_MODEL_CONFIG_NAME,
	PARTIN_HEADQUARTERS_MODEL_CONFIG_NAME,
	PARTIN_LICENSES_MODEL_CONFIG_NAME,
	PARTIN_LOCALIZATIONS_MODEL_CONFIG_NAME
} from './models.js';
import {
	checkOrgValid,
	convertOrgBeforeRender,
	createCatalogGrid,
	createLicenseTypeStore,
	createLocalizationStatusStore,
	createRowCatalog,
	getLicenseTypeText,
	getPartiesFieldsMap,
	getPartiesModalConf,
	SERVER_DATE_FORMAT
} from './methods';
import { createCombo, createDateField, createFieldsForProductGrid, createTextField } from '@Components/fields';
import {
	createAddModulePanel,
	createForm,
	createFormForModule,
	createTab,
	createTabPanel,
	TAB_PANEL_CLS
} from '@Components/panels';
import { createCreateSaveButton, createSaveButton } from '@Components/buttons';
import { createEditableGrid } from '@Core/specialComponents/editableGrid';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createContainer, createFieldBlock, createFieldSet } from '@UIkit/components/panels';
import { createButtonContainer } from '@UIkit/components/panels/buttonContainer/ButtonContainer';
import { createLabel } from '@UIkit/components/fields';
import { createInputOrgSelector } from '@Components/InputOrgSelector/InputOrgSelector';

Ext.namespace('edi.modules');
edi.modules['partin.create'] = function () {
	let moduleData,
		docHeader,
		docId,
		isEdit,
		docContent,
		receiver,
		localizationsGrid,
		headquartersGrid,
		branchesGrid,
		saveButton,
		moduleForm,
		userData,
		activeTab;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		userData = edi.core.getUserData();
		docHeader = edi.utils.getObjectProperty(moduleData, 'initData.data') || {};
		activeTab = docHeader && docHeader.activeTab ? docHeader.activeTab : 0;
		docId = docHeader.id;
		isEdit = !!docId;

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.catalog.on('change', changeHandler);
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	let changeHandler = function (eventData) {
		if (eventData) {
			if (eventData.deleted || eventData.reloadCatalog) {
				edi.modulesHandler.removeModule(moduleData);
			} else if (!moduleData.tab.isDestroyed) {
				switch (eventData.subCatalogType) {
					case 'localization':
						localizationsGrid.getStore().reload();
						break;
					case 'branch':
						branchesGrid.getStore().reload();
						break;
					case 'headquarter':
						headquartersGrid.getStore().reload();
						break;
					default:
						moduleData.tab.removeAll();
						renderData();
						break;
				}
			}
		}
	};

	/**
	 *  Alias for edi.utils.getObjectProperty(docContent, name)
	 *	@param	{string|number}	name	name of property
	 * 	@return	{any}	property value
	 */
	let get = function (name) {
		return edi.utils.getObjectProperty(docContent, name);
	};

	/**
	 * Creates company selector field
	 * @param	{Object}	selectorConfig
	 * @return	{Object}    company selector field
	 */
	let createOS = function (selectorConfig) {
		Ext.applyIf(selectorConfig, {
			allowBlank: false,
			allowReset: true,
			valuesByMap: true,
			fieldsMapOnly: true,
			selectedRelationByMap: true,
			relationsOnly: true,
			ownOrg: true,
			forceChangeAnyField: true,
			relations: edi.relations.getRelations(),
			fieldValues: docContent,
			selectedOrgValues: isEdit ? docContent : null,
			useHiddenFields: true,
			modalConf: getPartiesModalConf()
		});
		return createInputOrgSelector(selectorConfig);
	};

	/**
	 * Displays modal catalog data form Localizations
	 * @param    {Object}    record      record from existent catalog row in grid, used for editing values
	 * @param    {Object}    readOnly    boolean
	 */
	let showModalLocalizationsForm = function (record, readOnly) {
		let updateStateCode = function (cmp) {
			let form = cmp.up('form');
			const regionCodeCombo = form.down('combobox[name=stateCode]');
			const regionCodeComboFieldBlock = regionCodeCombo?.getFieldBlock();
			const zip = form.down('textfield[name=postalCode]');
			const regionCodeTextField = form.down('textfield[name=stateCode]');
			const regionCodeTextFieldFieldBlock = regionCodeTextField?.getFieldBlock();
			let country = form.down('combobox[name=country]').getValue(),
				isRussia = country === edi.constants.DEFAULT.COUNTRY;
			regionCodeComboFieldBlock?.setVisible(isRussia);
			if (regionCodeCombo) {
				regionCodeCombo.allowBlank = !isRussia;
				regionCodeCombo.setDisabled(!isRussia);
			}
			if (zip) {
				zip.allowBlank = !isRussia;
			}

			regionCodeTextFieldFieldBlock?.setVisible(!isRussia);
			regionCodeTextField?.setDisabled(isRussia);

			form.isValid();
		};
		let isEditRow = !!record,
			form;
		let objectData = record ? record.getData() : null;
		let inputConf = {
			readOnly: readOnly ? readOnly : false
		};
		let validIln = function (field, itemId) {
			const value = field.getValue ? field.getValue() : null;
			let iln = form.down('[itemId="' + itemId + '"]');
			if (iln) {
				iln.allowBlank = !value;
				iln.isValid();
				form.isValid();
			}
		};
		let validIlnFranchise = function () {
			let iln = form.down('[itemId="franchiseeIln"]');
			let name = form.down('[itemId="franchiseeName"]');
			let codeBySender = form.down('[itemId="franchiseeCodeBySender"]');
			let codeByReceiver = form.down('[itemId="franchiseeCodeByReceiver"]');
			if (iln && name && codeBySender && codeByReceiver) {
				let value = name.getValue() || codeBySender.getValue() || codeByReceiver.getValue();
				iln.allowBlank = !value;
				iln.isValid();
				form.isValid();
			}
		};
		let modal = createModalPanel({
			title: edi.i18n.getMessage('catalog.partin.localization'),
			width: MODAL_SIZE.widthLarge,
			layout: 'fit',
			items: [
				(form = createForm({
					layout: 'fit',
					items: [
						createTabPanel({
							cls: TAB_PANEL_CLS.simpleWithoutPadding,
							items: [
								createTab({
									title: edi.i18n.getMessage('catalog.partin.tab.localization.info'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.iln',
												type: 'text',
												name: 'iln',
												regex: edi.constants.VALIDATORS.ILN,
												maskRe: /\d/i,
												enforceMaxLength: true,
												allowBlank: false,
												minLength: 13,
												maxLength: 13
											},
											{
												title: 'column.org.inn',
												name: 'taxId',
												regex: edi.constants.VALIDATORS.INN,
												allowBlank: false,
												minLength: 10,
												maxLength: 12
											},
											{
												title: 'column.org.kpp',
												name: 'taxRegistrationReasonCode',
												allowBlank: false,
												regex: edi.constants.VALIDATORS.KPP,
												minLength: 9,
												maxLength: 9
											},
											{
												title: 'column.register.number',
												name: 'utilizationRegisterNumber',
												regex: /^(\d{13}|\d{15})$/i,
												allowBlank: false,
												minLength: 13,
												maxLength: 15
											},
											{
												title: 'company.okpo.short',
												name: 'enterpriseClassificationCode',
												regex: edi.constants.VALIDATORS.OKPO,
												minLength: 8,
												maxLength: 10
											},
											{
												title: 'column.code.by.sender',
												name: 'codeBySender',
												maxLength: 35
											},
											{
												title: 'column.code.by.receiver',
												name: 'codeByReceiver',
												maxLength: 35
											},
											{
												title: 'column.name',
												name: 'name',
												allowBlank: false,
												maxLength: 175
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('catalog.partin.tab.localization.adress'),
									closable: false,
									autoScroll: true,
									bodyPadding: '16 24 0 24',
									layout: {
										type: 'grid',
										gap: [24, 16],
										ignoreHiddenElements: true,
										area: [[6, 6], [6, 6], [6, 6], [6, 6], [6, 6], [6, 6], [6]]
									},
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.country',
												name: 'country',
												maxLength: 175,
												type: 'combo',
												store: edi.stores.initValidCountryFullStore(),
												valueField: 'iso_2',
												displayField: 'name',
												allowBlank: false,
												listeners: {
													select: updateStateCode,
													render: function (cmp) {
														setTimeout(function () {
															updateStateCode(cmp);
														}, 100);
													}
												}
											},
											{
												title: 'company.zip',
												name: 'postalCode',
												maxLength: 9
											},

											{
												title: edi.i18n.getMessage('column.state.code'),
												name: 'stateCode',
												maxLength: 9
											},
											{
												title: 'company.street',
												name: 'streetAndNumber',
												maxLength: 255
											},
											{
												title: edi.i18n.getMessage('column.state.code'),
												name: 'stateCode',
												type: 'combo',
												store: edi.stores.initRegionsStore(),
												valueField: 'id',
												displayField: 'display_name',
												anyMatch: true,
												listeners: {
													select: function (combo, record) {
														let region = record && record.getData ? record.getData() : null;
														let fields = edi.utils.getFormFields(
															combo.findParentByType('form')
														);
														let regionName = fields['state'];
														if (regionName) {
															regionName.setValue(region ? region.name : null);
														}
													}
												}
											},
											{
												title: 'company.home',
												name: 'houseNumber',
												maxLength: 20
											},
											{
												title: edi.i18n.getMessage('company.region'),
												name: 'state',
												maxLength: 175
											},
											{
												title: 'column.housing',
												name: 'housing',
												maxLength: 20
											},

											{
												title: 'company.district',
												name: 'district',
												maxLength: 140
											},
											{
												title: 'company.flat.location',
												name: 'roomNumber',
												maxLength: 20
											},

											{
												title: 'column.city.name',
												name: 'cityName',
												maxLength: 140
											},
											{
												title: 'catalog.partin.additionalInformation',
												name: 'additionalInformation',
												maxLength: 140
											},

											{
												title: 'company.community',
												name: 'locality',
												maxLength: 140
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('catalog.partin.localization.tradeFormat'),
									closable: false,
									autoScroll: true,
									bodyPadding: '16 24 0 24',
									layout: {
										type: 'grid',
										gap: [24, 16],
										area: [[6, 6], [6, 6], [6, 6], [6, 6], [6, 6], [6], [6]]
									},
									items: createFieldsForProductGrid(
										[
											{
												title: 'catalog.partin.localization.tradeFormat',
												name: 'tradeFormat',
												maxLength: 70
											},
											{
												title: 'catalog.partin.localization.contactPerson',
												name: 'contactPerson',
												maxLength: 175
											},

											{
												title: 'catalog.partin.localization.format',
												name: 'format',
												maxLength: 70
											},
											{
												title: 'catalog.partin.localization.phoneNumber',
												name: 'phoneNumber',
												maxLength: 35
											},

											{
												title: 'catalog.partin.localization.formatArea',
												name: 'formatArea',
												maxLength: 35
											},
											{
												title: 'catalog.partin.localization.eMail',
												name: 'eMail',
												regex: edi.constants.VALIDATORS.EMAIL,
												maxLength: 350
											},

											{
												title: 'catalog.partin.localization.numberOfCashDesks',
												name: 'numberOfCashDesks',
												regex: /^[0-9]{3}$/
											},
											{
												title: 'catalog.partin.localization.latitude',
												name: 'latitude',
												maxLength: 20
											},

											{
												title: 'catalog.partin.localization.status',
												name: 'status',
												type: 'combo',
												store: createLocalizationStatusStore()
											},
											{
												title: 'catalog.partin.localization.longitude',
												name: 'longitude',
												maxLength: 20
											},

											{
												title: 'catalog.partin.localization.storeOpeningDate',
												name: 'storeOpeningDate',
												type: 'date',
												submitFormat: SERVER_DATE_FORMAT,
												format: SERVER_DATE_FORMAT
											},

											{
												title: 'catalog.partin.localization.dateNewOrders',
												name: 'dateNewOrders',
												type: 'date',
												submitFormat: SERVER_DATE_FORMAT,
												format: SERVER_DATE_FORMAT
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('company.type.company'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.iln',
												name: 'headquarter.iln',
												itemId: 'headquarterIln',
												regex: edi.constants.VALIDATORS.ILN,
												maskRe: /\d/i,
												enforceMaxLength: true,
												minLength: 13,
												maxLength: 13
											},
											{
												title: 'column.name',
												name: 'headquarter.name',
												maxLength: 175,
												listeners: {
													afterrender: function (field) {
														validIln(field, 'headquarterIln');
													},
													change: function (field) {
														validIln(field, 'headquarterIln');
													}
												}
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('catalog.partin.branche'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.iln',
												name: 'branch.iln',
												itemId: 'branchIln',
												regex: edi.constants.VALIDATORS.ILN,
												maskRe: /\d/i,
												enforceMaxLength: true,
												minLength: 13,
												maxLength: 13
											},
											{
												title: 'column.name',
												name: 'branch.name',
												maxLength: 175,
												listeners: {
													afterrender: function (field) {
														validIln(field, 'branchIln');
													},
													change: function (field) {
														validIln(field, 'branchIln');
													}
												}
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('catalog.partin.franchise'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.iln',
												name: 'franchisee.iln',
												itemId: 'franchiseeIln',
												regex: edi.constants.VALIDATORS.ILN,
												maskRe: /\d/i,
												enforceMaxLength: true,
												minLength: 13,
												maxLength: 13
											},
											{
												title: 'column.name',
												name: 'franchisee.name',
												itemId: 'franchiseeName',
												maxLength: 175,
												listeners: {
													afterrender: validIlnFranchise,
													change: validIlnFranchise
												}
											},
											{
												title: 'catalog.partin.franchisees.codeBySender',
												name: 'franchisee.codeBySender',
												itemId: 'franchiseeCodeBySender',
												maxLength: 35,
												listeners: {
													afterrender: validIlnFranchise,
													change: validIlnFranchise
												}
											},
											{
												title: 'catalog.partin.franchisees.codeByReceiver',
												name: 'franchisee.codeByReceiver',
												itemId: 'franchiseeCodeByReceiver',
												maxLength: 35,
												listeners: {
													afterrender: validIlnFranchise,
													change: validIlnFranchise
												}
											}
										],
										objectData,
										{},
										inputConf
									)
								})
							]
						})
					]
				}))
			],
			buttonsBefore: [
				createSaveButton(
					function () {
						let values = edi.utils.collectFormValues(form);
						let store = localizationsGrid.getStore();
						let saveRecord = function () {
							let recordToEdit =
								record || edi.models.createInstance(PARTIN_LOCALIZATIONS_MODEL_CONFIG_NAME);
							for (let i in values) {
								if (values.hasOwnProperty(i)) {
									recordToEdit.set(i, values[i]);
								}
							}
							if (!isEdit) {
								if (!isEditRow) {
									store.add(recordToEdit);
								}
								store.commitChanges();
								modal.close();
							} else {
								createRowCatalog(record, docId, 'localization', values, modal);
							}
						};

						saveRecord();
					},
					{
						bindToForm: form,
						text: edi.i18n.getMessage(isEditRow ? 'form.btn.save' : 'form.btn.add')
					}
				)
			]
		});
		modal.show();
		form.isValid();
	};

	/**
	 * Displays modal catalog data form Headquarters
	 * @param    {Object}    record      record from existent catalog row in grid, used for editing values
	 * @param    {Object}    readOnly    boolean
	 */
	let showModalHeadquartersForm = function (record, readOnly) {
		let updateStateCode = function (cmp) {
			let form = cmp.up('form');
			const regionCodeCombo = form.down('combobox[name=stateCode]');
			const regionCodeComboFieldBlock = regionCodeCombo?.getFieldBlock();
			const regionCodeTextField = form.down('textfield[name=stateCode]');
			const regionCodeTextFieldFieldBlock = regionCodeTextField?.getFieldBlock();
			let country = form.down('combobox[name=country]').getValue(),
				isRussia = country === edi.constants.DEFAULT.COUNTRY;
			regionCodeComboFieldBlock?.setVisible(isRussia);
			regionCodeCombo?.setDisabled(!isRussia);

			regionCodeTextFieldFieldBlock?.setVisible(!isRussia);
			regionCodeTextField?.setDisabled(isRussia);
		};
		let isEditRow = !!record,
			form,
			licenseGrid;
		let objectData = record ? record.getData() : null;
		let inputConf = {
			readOnly: readOnly ? readOnly : false
		};

		let validLicense = function (values) {
			for (var i in values) {
				if (values.hasOwnProperty(i) && values[i]) {
					return true;
				}
			}
			edi.core.showError(edi.i18n.getMessage('catalog.partin.licenses.empty'));
			return false;
		};

		let modal = createModalPanel({
			title: edi.i18n.getMessage('catalog.partin.headquarter'),
			width: MODAL_SIZE.widthLarge,
			layout: 'fit',
			items: [
				(form = createForm({
					layout: 'fit',
					items: [
						createTabPanel({
							cls: TAB_PANEL_CLS.simpleWithoutPadding,
							items: [
								createTab({
									title: edi.i18n.getMessage('catalog.partin.localization.info'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.iln',
												name: 'iln',
												regex: edi.constants.VALIDATORS.ILN,
												maskRe: /\d/i,
												enforceMaxLength: true,
												allowBlank: false,
												minLength: 13,
												maxLength: 13
											},
											{
												title: 'column.org.inn',
												allowBlank: false,
												name: 'taxId',
												minLength: 10,
												maxLength: 12,
												regex: edi.constants.VALIDATORS.INN
											},
											{
												title: 'column.org.kpp',
												allowBlank: false,
												name: 'taxRegistrationReasonCode',
												regex: edi.constants.VALIDATORS.KPP,
												minLength: 9,
												maxLength: 9
											},
											{
												title: 'column.register.number',
												allowBlank: false,
												name: 'utilizationRegisterNumber',
												minLength: 13,
												maxLength: 15,
												regex: /^(\d{13}|\d{15})$/i
											},
											{
												title: 'company.okpo.short',
												name: 'enterpriseClassificationCode',
												regex: edi.constants.VALIDATORS.OKPO,
												minLength: 8,
												maxLength: 10
											},
											{
												title: 'column.code.by.sender',
												name: 'codeBySender',
												maxLength: 35
											},
											{
												title: 'column.code.by.receiver',
												name: 'codeByReceiver',
												maxLength: 35
											},
											{
												title: 'column.name',
												name: 'name',
												maxLength: 175
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('catalog.partin.localization.adress'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16],
										ignoreHiddenElements: true,
										area: [[6, 6], [6, 6], [6, 6], [6, 6], [6, 6], [6, 6], [6]]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.country',
												name: 'country',
												maxLength: 175,
												type: 'combo',
												store: edi.stores.initValidCountryFullStore(),
												valueField: 'iso_2',
												displayField: 'name',
												listeners: {
													select: updateStateCode,
													render: function (cmp) {
														setTimeout(function () {
															updateStateCode(cmp);
														}, 100);
													}
												}
											},
											{
												title: 'company.zip',
												name: 'postalCode',
												maxLength: 9
											},

											{
												title: edi.i18n.getMessage('column.state.code'),
												name: 'stateCode',
												maxLength: 9
											},
											{
												title: 'company.street',
												name: 'streetAndNumber',
												maxLength: 255
											},

											{
												title: edi.i18n.getMessage('column.state.code'),
												name: 'stateCode',
												type: 'combo',
												store: edi.stores.initRegionsStore(),
												valueField: 'id',
												displayField: 'display_name',
												anyMatch: true,
												listeners: {
													select: function (combo, record) {
														let region = record && record.getData ? record.getData() : null;
														let fields = edi.utils.getFormFields(
															combo.findParentByType('form')
														);
														let regionName = fields['state'];
														if (regionName) {
															regionName.setValue(region ? region.name : null);
														}
													}
												}
											},
											{
												title: 'company.home',
												name: 'houseNumber',
												maxLength: 20
											},

											{
												title: edi.i18n.getMessage('company.region'),
												name: 'state',
												maxLength: 175
											},
											{
												title: 'column.housing',
												name: 'housing',
												maxLength: 20
											},

											{
												title: 'company.district',
												name: 'district',
												maxLength: 140
											},
											{
												title: 'company.flat.location',
												name: 'roomNumber',
												maxLength: 20
											},

											{
												title: 'column.city.name',
												name: 'cityName',
												maxLength: 140
											},
											{
												title: 'catalog.partin.additionalInformation',
												name: 'additionalInformation',
												maxLength: 140
											},

											{
												title: 'company.community',
												name: 'locality',
												maxLength: 140
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('company.details'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'company.bank.account',
												name: 'checkingAccount',
												regex: edi.constants.VALIDATORS.BANK_ACC,
												regexText: 'invalid.value.bank.acc'
											},
											{
												title: 'column.corr.acc.number',
												name: 'correspondentAccount',
												regex: edi.constants.VALIDATORS.BANK_ACC,
												regexText: 'invalid.value.bank.acc'
											},
											{
												title: 'company.bank.id',
												name: 'bankId',
												regexText: 'invalid.value.bank.id',
												regex: edi.constants.VALIDATORS.BIK
											},
											{
												title: 'company.bank.name',
												name: 'bankName',
												maxLength: 175
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('catalog.partin.licenses'),
									closable: false,
									autoScroll: true,
									layout: 'grid',
									bodyPadding: '16 24 0 24',
									items: [
										(licenseGrid = createEditableGrid(
											{
												name: 'licenses.license',
												fields: [
													{
														title: 'catalog.partin.license.type',
														name: 'type',
														type: 'combo',
														store: createLicenseTypeStore(),
														renderer: function (value) {
															return value ? getLicenseTypeText(value) : '';
														}
													},
													{
														title: 'catalog.partin.license.name',
														name: 'name',
														maxLength: 175
													},
													{
														title: 'catalog.partin.license.number',
														name: 'number',
														maxLength: 35
													},
													{
														title: 'catalog.partin.license.issuingAuthority',
														name: 'issuingAuthority',
														maxLength: 70
													},
													{
														title: 'catalog.partin.license.dateOfIssue',
														name: 'dateOfIssue',
														type: 'date',
														submitFormat: SERVER_DATE_FORMAT,
														format: SERVER_DATE_FORMAT
													},
													{
														title: 'catalog.partin.license.expirationDate',
														name: 'expirationDate',
														type: 'date',
														submitFormat: SERVER_DATE_FORMAT,
														format: SERVER_DATE_FORMAT
													}
												],
												model: PARTIN_LICENSES_MODEL_CONFIG_NAME,
												modalWidth: MODAL_SIZE.widthLarge,
												readOnly: readOnly,
												gridConfig: {
													cls: 'modal-grid',
													maxHeight: 400,
													title: edi.i18n.getMessage('catalog.partin.license')
												},
												storeConfig: {
													model: getPartinLicensesModel(),
													data: edi.utils.getObjectProperty(
														objectData,
														'licenses.license',
														true
													)
												},
												validFormFunc: validLicense
											},
											objectData
										))
									]
								})
							]
						})
					]
				}))
			],
			buttonsBefore: [
				createSaveButton(
					function () {
						let values = edi.utils.collectFormValues(form);
						let licenseStore = licenseGrid.getStore().data.items;
						let licenses = licenseStore.map(function (item) {
							return item.data;
						});
						edi.utils.setObjectProperty(values, 'licenses.license', licenses);

						let store = headquartersGrid.getStore();
						let saveRecord = function () {
							let recordToEdit =
								record || edi.models.createInstance(PARTIN_HEADQUARTERS_MODEL_CONFIG_NAME);
							for (let i in values) {
								if (values.hasOwnProperty(i)) {
									recordToEdit.set(i, values[i]);
								}
							}
							if (!isEdit) {
								if (!isEditRow) {
									store.add(recordToEdit);
								}
								store.commitChanges();
								modal.close();
							} else {
								createRowCatalog(record, docId, 'headquarter', values, modal);
							}
						};
						saveRecord();
					},
					{
						bindToForm: form,
						text: edi.i18n.getMessage(isEditRow ? 'form.btn.save' : 'form.btn.add')
					}
				)
			]
		});
		modal.show();
		form.isValid();
	};

	/**
	 * Displays modal catalog data form Branches
	 * @param    {Object}    record      record from existent catalog row in grid, used for editing values
	 * @param    {Object}    readOnly    boolean
	 */
	let showModalBranchesForm = function (record, readOnly) {
		let updateStateCode = function (cmp) {
			let form = cmp.up('form');
			const regionCodeCombo = form.down('combobox[name=stateCode]');
			const regionCodeComboFieldBlock = regionCodeCombo?.getFieldBlock();
			const regionCodeTextField = form.down('textfield[name=stateCode]');
			const regionCodeTextFieldFieldBlock = regionCodeTextField?.getFieldBlock();
			let country = form.down('combobox[name=country]').getValue(),
				isRussia = country === edi.constants.DEFAULT.COUNTRY;
			regionCodeComboFieldBlock?.setVisible(isRussia);
			regionCodeCombo?.setDisabled(!isRussia);

			regionCodeTextFieldFieldBlock?.setVisible(!isRussia);
			regionCodeTextField?.setDisabled(isRussia);
		};
		let isEditRow = !!record,
			form;
		let objectData = record ? record.getData() : null;
		let inputConf = {
			readOnly: readOnly ? readOnly : false
		};
		let modal = createModalPanel({
			title: edi.i18n.getMessage('catalog.partin.branche'),
			width: MODAL_SIZE.widthLarge,
			layout: 'fit',
			items: [
				(form = createForm({
					layout: 'fit',
					items: [
						createTabPanel({
							cls: TAB_PANEL_CLS.simpleWithoutPadding,
							items: [
								createTab({
									title: edi.i18n.getMessage('catalog.partin.localization.info'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.iln',
												allowBlank: false,
												name: 'iln',
												regex: edi.constants.VALIDATORS.ILN,
												maskRe: /\d/i,
												enforceMaxLength: true,
												minLength: 13,
												maxLength: 13
											},
											{
												title: 'column.code.by.sender',
												name: 'codeBySender',
												maxLength: 35
											},
											{
												title: 'column.code.by.receiver',
												name: 'codeByReceiver',
												maxLength: 35
											},
											{
												title: 'column.name',
												name: 'name',
												maxLength: 175
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('catalog.partin.localization.adress'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16],
										ignoreHiddenElements: true,
										area: [[6, 6], [6, 6], [6, 6], [6, 6], [6, 6], [6, 6], [6]]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.country',
												name: 'country',
												maxLength: 175,
												type: 'combo',
												store: edi.stores.initValidCountryFullStore(),
												valueField: 'iso_2',
												displayField: 'name',
												listeners: {
													select: updateStateCode,
													render: function (cmp) {
														setTimeout(function () {
															updateStateCode(cmp);
														}, 100);
													}
												}
											},
											{
												title: 'company.zip',
												name: 'postalCode',
												maxLength: 9
											},

											{
												title: edi.i18n.getMessage('column.state.code'),
												name: 'stateCode',
												maxLength: 9
											},
											{
												title: 'company.street',
												name: 'streetAndNumber',
												maxLength: 255
											},

											{
												title: edi.i18n.getMessage('column.state.code'),
												name: 'stateCode',
												type: 'combo',
												store: edi.stores.initRegionsStore(),
												valueField: 'id',
												displayField: 'display_name',
												anyMatch: true,
												listeners: {
													select: function (combo, record) {
														let region = record && record.getData ? record.getData() : null;
														let fields = edi.utils.getFormFields(
															combo.findParentByType('form')
														);
														let regionName = fields['state'];
														if (regionName) {
															regionName.setValue(region ? region.name : null);
														}
													}
												}
											},
											{
												title: 'company.home',
												name: 'houseNumber',
												maxLength: 20
											},

											{
												title: edi.i18n.getMessage('company.region'),
												name: 'state',
												maxLength: 175
											},
											{
												title: 'column.housing',
												name: 'housing',
												maxLength: 20
											},

											{
												title: 'company.district',
												name: 'district',
												maxLength: 140
											},
											{
												title: 'company.flat.location',
												name: 'roomNumber',
												maxLength: 20
											},

											{
												title: 'column.city.name',
												name: 'cityName',
												maxLength: 140
											},
											{
												title: 'catalog.partin.additionalInformation',
												name: 'additionalInformation',
												maxLength: 140
											},

											{
												title: 'company.community',
												name: 'locality',
												maxLength: 140
											}
										],
										objectData,
										{},
										inputConf
									)
								}),
								createTab({
									title: edi.i18n.getMessage('company.type.company'),
									closable: false,
									autoScroll: true,
									layout: {
										type: 'grid',
										gap: [24, 16]
									},
									bodyPadding: '16 24 0 24',
									items: createFieldsForProductGrid(
										[
											{
												title: 'column.iln',
												name: 'headquarter.iln',
												regex: edi.constants.VALIDATORS.ILN,
												maskRe: /\d/i,
												enforceMaxLength: true,
												minLength: 13,
												maxLength: 13,
												requiredFields: ['headquarter.name']
											},
											{
												title: 'column.name',
												name: 'headquarter.name',
												maxLength: 175,
												requiredFields: ['headquarter.iln']
											}
										],
										objectData,
										{},
										inputConf
									)
								})
							]
						})
					]
				}))
			],
			buttonsBefore: [
				createSaveButton(
					function () {
						let values = edi.utils.collectFormValues(form);
						let store = branchesGrid.getStore();
						let saveRecord = function () {
							let recordToEdit = record || edi.models.createInstance(PARTIN_BRANCHES_MODEL_CONFIG_NAME);
							for (let i in values) {
								if (values.hasOwnProperty(i)) {
									recordToEdit.set(i, values[i]);
								}
							}
							if (!isEdit) {
								if (!isEditRow) {
									store.add(recordToEdit);
								}
								store.commitChanges();
								modal.close();
							} else {
								createRowCatalog(record, docId, 'branch', values, modal);
							}
						};
						saveRecord();
					},
					{
						bindToForm: form,
						text: edi.i18n.getMessage(isEditRow ? 'form.btn.save' : 'form.btn.add')
					}
				)
			]
		});
		modal.show();
		form.isValid();
	};

	/**
	 * Returns values from grid
	 */
	let getValuesGrid = function (grid) {
		let store = grid.getStore();
		let values = [];
		if (store.getCount()) {
			store.each(function (record) {
				values.push(record.data);
				return true;
			});
		}
		return values;
	};

	const createMainDataBlock = () => {
		const number = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			name: 'partyInformationCatalogHeader.partyInformationCatalogNumber',
			emptyText: edi.i18n.getMessage('nr'),
			valueSrc: docContent,
			allowBlank: false,
			maxLength: 35
		});

		const date = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			format: SERVER_DATE_FORMAT,
			submitFormat: SERVER_DATE_FORMAT,
			allowBlank: false,
			valueSrc: docContent,
			name: 'partyInformationCatalogHeader.partyInformationCatalogDate'
		});

		const documentFunctionCode = createCombo({
			fieldLabel: edi.i18n.getMessage('catalog.partin.functionCode'),
			name: 'partyInformationCatalogHeader.documentFunctionCode',
			allowBlank: false,
			store: edi.stores.createSimpleInlineStore(['9', '4'], function (id) {
				return edi.i18n.getMessage('functional.document.code.' + id);
			}),
			value: isEdit
				? edi.utils.getObjectProperty(docContent, 'partyInformationCatalogHeader.documentFunctionCode')
				: '9',
			anyMatch: true,
			readOnly: isEdit
		});

		const remarks = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.column.remark'),
			name: 'partyInformationCatalogHeader.remarks',
			rowsHtmlAttributeValue: 5,
			valueSrc: docContent,
			maxLength: 255,
			isTextarea: true
		});

		receiver = isEdit
			? createTextField({
					disabled: true,
					value: get('documentParties.receiver.name')
			  })
			: createOS({
					callback() {
						checkOrgValid(receiver);
					},
					itemId: 'receiver',
					selectedOrgValues: get('documentParties.receiver') ?? {},
					fieldsMap: getPartiesFieldsMap('documentParties.receiver'),
					modalConf: getPartiesModalConf(null, {
						glnReadOnly: isEdit
					})
			  });

		return createFieldSet({
			title: edi.i18n.getMessage('catalog.details'),
			collapsible: true,
			layout: {
				type: 'grid',
				area: [[4, 4], 6]
			},
			items: [
				createContainer({
					layout: {
						type: 'grid',
						gap: 11,
						area: [12, [6, 6]]
					},
					items: [number, date, documentFunctionCode]
				}),
				createContainer({
					items: remarks
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('catalog.partin.receiver'),
					items: receiver
				})
			]
		});
	};

	/**
	 * Creates Localizations grid
	 * @return    {Object}   grid
	 */
	let createLocalizationsGrid = function () {
		localizationsGrid = createCatalogGrid(
			{
				columns: getPartinLocalizationsColumns(),
				title: edi.i18n.getMessage('catalog.partin.localizations'),
				model: getPartinLocalizationsModel(),
				type: 'localization',
				isEdit: isEdit,
				docId: docId
			},
			get('partyInformationCatalogLocalizations.localizationsCatalog.localization') || [],
			showModalLocalizationsForm
		);

		return createTab({
			title: edi.i18n.getMessage('catalog.partin.localizations'),
			closable: false,
			layout: 'fit',
			bodyPadding: '24 12 40 24',
			items: [localizationsGrid]
		});
	};

	/**
	 * Creates Headquarters grid
	 * @return    {Object}   grid
	 */
	let createHeadquartersGrid = function () {
		headquartersGrid = createCatalogGrid(
			{
				columns: getPartinHeadquartersColumns(),
				title: edi.i18n.getMessage('catalog.partin.headquarters'),
				model: getPartinHeadquartersModel(),
				type: 'headquarter',
				isEdit: isEdit,
				docId: docId
			},
			get('partyInformationCatalogLocalizations.headquartersCatalog.headquarter') || [],
			showModalHeadquartersForm
		);

		return createTab({
			title: edi.i18n.getMessage('catalog.partin.headquarters'),
			closable: false,
			layout: 'fit',
			bodyPadding: '24 12 40 24',
			items: [headquartersGrid]
		});
	};

	/**
	 * Creates Branches grid
	 * @return    {Object}   grid
	 */
	let createBranchesGrid = function () {
		branchesGrid = createCatalogGrid(
			{
				columns: getPartinBranchesColumns(),
				title: edi.i18n.getMessage('catalog.partin.branches'),
				model: getPartinBranchesModel(),
				type: 'branch',
				isEdit: isEdit,
				docId: docId
			},
			get('partyInformationCatalogLocalizations.branchesCatalog.branch') || [],
			showModalBranchesForm
		);

		return createTab({
			title: edi.i18n.getMessage('catalog.partin.branches'),
			closable: false,
			layout: 'fit',
			bodyPadding: '24 12 40 24',
			items: [branchesGrid]
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	let renderData = function (initCallBack) {
		if (moduleData.tab.isDestroyed) {
			return;
		}

		let render = function () {
			let catalogTab = createTabPanel(
				{
					cls: [TAB_PANEL_CLS.simple, 'module-tabs', 'edi-additional-tbar'],
					region: 'center',
					activeTab: activeTab,
					items: [createLocalizationsGrid(), createHeadquartersGrid(), createBranchesGrid()]
				},
				true
			);

			saveButton = createCreateSaveButton(
				{
					handler: () => {
						if (edi.utils.setFocusToDocumentsWithGrid(moduleForm, ['receiver'], [receiver])) {
							saveFunction();
						}
					}
				},
				isEdit
			);

			moduleForm = createFormForModule({
				cls: 'edi-form document-add-form',
				autoScroll: true,
				region: 'north',
				items: [
					createLabel({
						typography: 'heading_02',
						text: edi.i18n.getMessage(isEdit ? 'catalog.partin' : 'catalog.add.partin')
					}),
					createMainDataBlock(),
					catalogTab
				]
			});

			const modulePanel = createAddModulePanel({
				layout: 'border',
				autoScroll: true,
				buttons: createButtonContainer({
					items: saveButton
				})
			});
			modulePanel.add(moduleForm);
			modulePanel.add(catalogTab);

			moduleData.tab.add(modulePanel);

			if ('function' == typeof initCallBack) {
				initCallBack();
			}
			if (!isEdit) {
				checkOrgValid(receiver);
			}
			moduleForm.isValid();
		};
		docContent = {};
		if (isEdit) {
			let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', () =>
				edi.modulesHandler.removeModule(moduleData)
			);
			let success = function (respData) {
				if (!respData.data) {
					failure(respData);
					return;
				}
				docContent.documentParties = {
					sender: convertOrgBeforeRender(edi.utils.getObjectProperty(docHeader, 'fromOrg')),
					receiver: convertOrgBeforeRender(edi.utils.getObjectProperty(docHeader, 'toOrg'))
				};
				docContent.partyInformationCatalogHeader = respData.data;
				docContent.id = docId;
				render();
			};
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.CATALOGS.LEGACY_PARTIN.HEADER.GET, {
					id: docId
				}),
				'GET',
				{},
				success,
				failure
			);
		} else {
			render();
		}
	};

	/**
	 * Organization conversion for save
	 */
	let convertOrgBeforeSave = function (orgValues) {
		let values = orgValues || {};
		values = Ext.clone(values);

		let allFieldsAreEmpty = true;
		Object.keys(values).forEach((key) => {
			if (values[key]) {
				allFieldsAreEmpty = false;
			}
		});
		if (allFieldsAreEmpty) {
			return {};
		}
		return values;
	};

	/**
	 * Saves partin
	 */
	let saveFunction = function () {
		let values = edi.utils.collectFormValues(moduleForm);
		let docData = Ext.clone(docContent);
		if (isEdit) {
			let success = function () {
				moduleData.isChanged = false;
				edi.modulesHandler.removeModule(moduleData);
				if (isEdit) {
					moduleData.tab.setLoading(false);
					edi.events.catalog.fireEvent('change', {
						id: docId,
						catalogType: edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN
					});
				}
			};

			let failure = function (data) {
				edi.core.logMessage('Error saving partin', 'warn');
				edi.core.showError(edi.utils.formatComplexServerError(data, 'partin.save.error'), function () {
					moduleData.tab.setLoading(false);
				});
			};

			moduleData.tab.setLoading(true);
			let postData = values.partyInformationCatalogHeader;
			edi.utils.clearEmptyValues(postData);

			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.CATALOGS.LEGACY_PARTIN.HEADER.PUT, {
					id: docId
				}),
				'PUT',
				Ext.encode(postData),
				success,
				failure
			);
		} else {
			//На форме создания больше нет orgSelector'а sender, поэтому подставим эти данные
			const defaultDocumentParties = {
				sender: {
					iln: userData.org.iln,
					name: userData.org.name
				}
			};
			Ext.merge(docData, { documentParties: defaultDocumentParties }, values);
			['documentParties.sender', 'documentParties.receiver'].forEach((path) => {
				let oldValues = edi.utils.getObjectProperty(docData, path);
				let newValues = convertOrgBeforeSave(oldValues);
				edi.utils.setObjectProperty(docData, path, newValues);
			});

			edi.utils.clearEmptyValues(docData);

			//бек просит при создании присылать всегде partyInformationCatalogLocalizations даже если гриды пустые
			let localizationsValue = getValuesGrid(localizationsGrid);
			edi.utils.clearEmptyValues(localizationsValue);
			if (!Ext.isEmpty(localizationsValue)) {
				localizationsValue.forEach((localization) => {
					~localization?.id?.indexOf('edi.models') ? delete localization.id : null;
				});
			}
			edi.utils.setObjectProperty(
				docData,
				'partyInformationCatalogLocalizations.localizationsCatalog.localization',
				localizationsValue.length > 0 ? localizationsValue : null
			);

			let headquartersValue = getValuesGrid(headquartersGrid);
			edi.utils.clearEmptyValues(headquartersValue);
			if (!Ext.isEmpty(headquartersValue)) {
				headquartersValue.forEach((headquarter) => {
					~headquarter?.id?.indexOf('edi.models') ? delete headquarter.id : null;
				});
			}
			edi.utils.setObjectProperty(
				docData,
				'partyInformationCatalogLocalizations.headquartersCatalog.headquarter',
				headquartersValue.length > 0 ? headquartersValue : null
			);

			let branchesValue = getValuesGrid(branchesGrid);
			edi.utils.clearEmptyValues(branchesValue);
			if (!Ext.isEmpty(branchesValue)) {
				branchesValue.forEach((branch) => {
					~branch?.id?.indexOf('edi.models') ? delete branch.id : null;
				});
			}
			edi.utils.setObjectProperty(
				docData,
				'partyInformationCatalogLocalizations.branchesCatalog.branch',
				branchesValue.length > 0 ? branchesValue : null
			);

			delete docData.header;
			let toOrgId = receiver.selectedOrg && receiver.selectedOrg.id;
			docData = {
				data: Ext.encode(docData),
				toOrgId: toOrgId
			};

			let success = function (data) {
				moduleData.isChanged = false;
				moduleData.tab.setLoading(false);
				edi.events.catalog.fireEvent('change');
				edi.modulesHandler.removeModule(moduleData);
				let transformData = {
					id: data.data.header,
					fromOrg: userData.org,
					toOrg: receiver.selectedOrg,
					state: edi.constants.STATE.DRAFT,
					type: edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN
				};
				var moduleTitle = edi.i18n.getMessage(
					'catalog.type.' + edi.constants.CATALOG_TYPES[edi.constants.DOCUMENT_TYPES.LEGACY_PARTIN]
				);
				var catalogNumber =
					edi.utils.getObjectProperty(
						Ext.decode(docData.data),
						'partyInformationCatalogHeader.partyInformationCatalogNumber'
					) || '';
				edi.document.actions.showDocumentCreatedMessage(
					edi.core.openModule('partin.details', transformData, moduleTitle + ' №' + catalogNumber, false),
					'partin.create.success'
				);
			};

			let failure = function (data) {
				edi.core.logMessage('Error saving partin', 'warn');
				edi.core.showError(edi.utils.formatComplexServerError(data, 'partin.save.error'), function () {
					moduleData.tab.setLoading(false);
				});
			};

			moduleData.tab.setLoading(true);

			edi.rest.sendRequest(
				edi.rest.services.CATALOGS.LEGACY_PARTIN.POST,
				'POST',
				Ext.encode(docData),
				success,
				failure
			);
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	let onDestroy = function () {
		edi.events.catalog.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
