export interface GetUserOrgCostDecimalsProps {
	defaultCostDecimals?: number;
}

export const getUserOrgCostDecimals = (props?: GetUserOrgCostDecimalsProps): number => {
	const { defaultCostDecimals = 2 } = props ?? {};
	const userData = edi.core.getUserData();
	const attributes = userData.org.attributes;
	const costDecimals =
		edi.utils.getAttributeByName(attributes, 'costDecimals', undefined, true) ?? defaultCostDecimals;
	return costDecimals;
};
