const COMMONPRICAT_PRICE_REGIONS_COLUMN = 'commonpricat_price_regions_lines';
const COMMONPRICAT_LINE_COLUMN = 'commonpricat_lines';

edi.columns.addColumns({
	[COMMONPRICAT_PRICE_REGIONS_COLUMN]: {
		Code: {
			text: 'column.code',
			dataIndex: 'Code',
			flex: 1
		},
		Name: {
			text: 'column.name',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'Name',
			flex: 1
		},
		Remarks: {
			text: 'documents.column.remark',
			dataIndex: 'Remarks',
			flex: 1
		}
	},
	[COMMONPRICAT_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		ean: {
			text: 'document.slsfct.column.column.barCode',
			dataIndex: 'EAN',
			flex: 1
		},
		itemDescription: {
			text: 'common.pricat.productName',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 2
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'column.unit.net.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitNetPrice',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 0.3
		},
		unitGrossPrice: {
			text: 'column.unit.gross.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitGrossPrice',
			flex: 1
		},
		brand: {
			text: 'line.item.brand',
			hidden: true,
			dataIndex: 'Brand'
		},
		minOrderedQuantity: {
			text: 'line.item.min.ordered.quantity',
			hidden: true,
			dataIndex: 'PackDetails',
			renderer: function (value) {
				return edi.utils.getObjectProperty(value, 'MinOrderedQuantity');
			}
		},
		campaign: {
			text: 'line.item.сampaign',
			dataIndex: 'Campaign',
			renderer: function (value) {
				return '' === value
					? ''
					: 'false' === value
					? edi.i18n.getMessage('answer.no')
					: edi.i18n.getMessage('answer.yes');
			}
		}
	}
});

export { COMMONPRICAT_PRICE_REGIONS_COLUMN, COMMONPRICAT_LINE_COLUMN };
