import { createForm } from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

/**
 * Opens modal for UKD line
 * @param	{Object}	tabPanel
 * @param	{Object}	record
 * @param	{Object}	dataFormGridConfig
 * @param	{Boolean}	[isEdit]
 * @param	{Function}	[callback]
 */
const createUKDLineModalForm = function (tabPanel, record, dataFormGridConfig, isEdit, callback) {
	let modal, saveBtn;

	const form = createForm({
		bodyPadding: 0,
		layout: 'fit',
		submitEmptyText: false,
		items: [tabPanel]
	});

	saveBtn = isEdit
		? createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('form.btn.save'),
				formBind: true,
				disabled: false,
				handler: function () {
					let values = edi.utils.collectFormValues(form);

					Ext.Object.each(dataFormGridConfig, function (key, value) {
						edi.utils.setObjectProperty(values, key, edi.utils.getDataFromGrid(value));
					});

					'function' == typeof callback ? callback(values) : null;
					modal.close();
				}
		  })
		: null;

	modal = createModalPanel({
		cls: 'edi-modal-product-data-selector',
		title: edi.i18n.getMessage('modal.product.data'),
		width: MODAL_SIZE.widthLarge,
		maxHeight: 600,
		items: [form],
		buttonsBefore: [saveBtn]
	});

	modal.show();
};

const KSCHF_BussinessProcess = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14
];
const isKSCHF_BussinessProcess = function (bpName) {
	return KSCHF_BussinessProcess.some((it) => it === bpName);
};

const SCHFDOP_DIS_BussinessProcess = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_DIS,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_DIS_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_ROAMING_OUT_BPV4,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_ROAMING_IN_BPV4,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_DIS_BPV4
];
const isSCHFDOP_DIS_BussinessProcess = function (bpName) {
	return SCHFDOP_DIS_BussinessProcess.some((it) => it === bpName);
};

const KSCHF_DIS_BussinessProcess = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHFDIS,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DIS_ROAMING_IN,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_DIS_ROAMING_OUT,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_ROAMING_IN_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DIS_ROAMING_OUT_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.UKD_KSCHFDIS_BPV4
];
const isKSCHF_DIS_BussinessProcess = function (bpName) {
	return KSCHF_DIS_BussinessProcess.some((it) => it === bpName);
};

const docsWithoutEconomicLifeDocName = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_IN,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.KSCHF_ROAMING_OUT,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_IN_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_KSCHF_ROAMING_OUT_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISRK_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.FNS_UKD_SVISZK_DECREE_14
];
const isWithoutEconomicLifeDocName = function (bpName) {
	return docsWithoutEconomicLifeDocName.some((it) => it === bpName);
};

export {
	createUKDLineModalForm,
	KSCHF_BussinessProcess,
	isKSCHF_BussinessProcess,
	SCHFDOP_DIS_BussinessProcess,
	isSCHFDOP_DIS_BussinessProcess,
	KSCHF_DIS_BussinessProcess,
	isKSCHF_DIS_BussinessProcess,
	docsWithoutEconomicLifeDocName,
	isWithoutEconomicLifeDocName
};
