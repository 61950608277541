import { createProductGridBase } from '@Components/product.grid';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createFieldSet,
	createHiddenField,
	createNumberField,
	createTextField
} from '@Components/fields';
import { createAddModulePanel, createFormForModule, createPanel } from '@Components/panels';
import { createGrid } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { INVOICE_LINE_COLUMN, INVOICE_SUMMARY_EDITABLE_COLUMN, INVOICE_TAX_SUMMARY_COLUMN } from './columns';
import { INVOICE_LINE_MODEL, INVOICE_SUMMARY_MODEL, INVOICE_TAX_SUMMARY_MODEL } from './models';
import {
	createInvoice,
	createInvoiceHeader,
	createInvoiceLines,
	createInvoicePartie,
	createInvoiceParties,
	createInvoiceSigner,
	createInvoiceSummary,
	createInvoiceTaxSummaryLines,
	processInvoice
} from './methods';
import {
	createButtonContainer,
	createContainer,
	createFieldBlock,
	createMaxWidthContainer
} from '@UIkit/components/panels';
import { createLabel } from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { invoiceSelectors } from './selectors';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { coreMethods } from '@Core/commons';

/**
 * Class for new invoice creation
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.create.invoice'] = function () {
	let moduleData,
		id,
		buyerId,
		taxRatesStore,
		availableTaxRates = [],
		userData = edi.core.getUserData(),
		invoice = {},
		afterSave,
		buyer,
		buyerOrg,
		initialData,
		costDecimals,
		seller,
		delivery,
		shipFrom,
		parentId,
		isEdit = false,
		isCopy = false,
		isBasedOnDoc = false,
		productsGrid,
		form,
		isNumberAutoGenerated = false,
		productValues = {
			products: []
		},
		taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
	const defaultCurrency = userData.org.country === 'BLR' ? 'BYN' : edi.constants.DEFAULT.CURRENCY;
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		costDecimals = coreMethods.getUserOrgCostDecimals();

		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			invoice = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			initialData = invoice;
			delete initialData.fileId;
			buyerOrg = moduleData.initData.data.buyerOrg;
		}

		if (buyerOrg && buyerOrg.id) {
			buyerId = buyerOrg.id;
			isBasedOnDoc = true;
			if (!buyerOrg.hasOwnProperty('attributes')) {
				buyerOrg = edi.utils.getOrg({
					orgId: buyerOrg.id
				});
			}
			taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
		}

		if (invoice) {
			processInvoice(invoice, function () {
				renderData(initCallBack);
			});
		} else {
			renderData(initCallBack);
		}
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};
	/**
	 * Creates invoice form
	 * @returns {Object}
	 */
	const createModForm = function (document) {
		const header = edi.utils.getObjectProperty(document ? document : invoice, 'Invoice-Header');
		const parties = edi.utils.getObjectProperty(document ? document : invoice, 'Invoice-Parties');

		const createMainDataBlock = function () {
			const transactionType = createCombo({
				fieldLabel: edi.i18n.getMessage('transaction.type'),
				value: edi.utils.getObjectProperty(document ? document : invoice, 'Invoice-Header.TransactionType'),
				name: 'Invoice-Header.TransactionType',
				store: edi.stores.createSimpleInlineStore(['D', 'P', 'E'], function (id) {
					return edi.i18n.getMessage('transaction.type.' + id);
				})
			});

			const numberDocument = createTextField({
				fieldLabel: edi.i18n.getMessage('invoice.number'),
				emptyText: isNumberAutoGenerated
					? edi.i18n.getMessage('document.generation.numbers.text')
					: edi.i18n.getMessage('nr'),
				columnWidth: 0.7333,
				allowBlank: isNumberAutoGenerated,
				disabled: isNumberAutoGenerated,
				maxLength: 35,
				value:
					document || invoice
						? edi.utils.getObjectProperty(document ? document : invoice, 'Invoice-Header.InvoiceNumber') +
						  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
						: undefined,
				name: 'Invoice-Header.InvoiceNumber'
			});

			const autogenCheckbox = isNumberAutoGenerated
				? createCheckbox({
						boxLabel: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
						inputValue: true,
						checked: isNumberAutoGenerated,
						name: 'numberAutoGenerated',
						listeners: {
							change: function (checkbox, isChecked) {
								numberDocument.setEmptyText(
									isChecked
										? edi.i18n.getMessage('document.generation.numbers.text')
										: edi.i18n.getMessage('nr')
								);
								numberDocument.setValue('');
								numberDocument.setDisabled(isChecked);
								numberDocument.allowBlank = isChecked;
								numberDocument.validate();
							}
						}
				  })
				: null;

			const documentDate = createDateField({
				fieldLabel: edi.i18n.getMessage('date'),
				allowBlank: false,
				value:
					(document || invoice) && !isCopy ? edi.utils.getObjectProperty(header, 'InvoiceDate') : new Date(),
				name: 'Invoice-Header.InvoiceDate'
			});

			const invoiceCurrency = createCombo({
				fieldLabel: edi.i18n.getMessage('invoice.currency'),
				value:
					edi.utils.getObjectProperty(document ? document : invoice, 'Invoice-Header.InvoiceCurrency') ||
					defaultCurrency,
				name: 'Invoice-Header.InvoiceCurrency',
				store: edi.stores.initCurrencyStore(),
				anyMatch: true,
				allowBlank: false
			});

			const invoicePaymentDueDate = createDateField({
				fieldLabel: edi.i18n.getMessage('invoice.payment.due.date'),
				allowBlank: false,
				valueSrc: document ? document : invoice,
				name: 'Invoice-Header.InvoicePaymentDueDate'
			});

			const invoicePaymentTerms = createNumberField({
				fieldLabel: edi.i18n.getMessage('invoice.payment.terms'),
				valueSrc: document ? document : invoice,
				name: 'Invoice-Header.InvoicePaymentTerms',
				maxLength: 5
			});

			const despatchNumberValue = edi.utils.getObjectProperty(invoice, 'Invoice-Header.Delivery.DespatchNumber');
			const hiddenDespatchNumber = despatchNumberValue
				? createHiddenField({
						valueSrc: invoice,
						name: 'Invoice-Header.Delivery.DespatchNumber'
				  })
				: null;

			const despatchNumber = createTextField({
				fieldLabel: edi.i18n.getMessage('delivery.despatch.number'),
				maxLength: 35,
				disabled: !!despatchNumberValue,
				name: 'Invoice-Header.Delivery.DespatchNumber',
				valueSrc: despatchNumberValue ? invoice : document
			});

			const deliveryDate = createDateField({
				fieldLabel: edi.i18n.getMessage('delivery.delivery.date'),
				name: 'Invoice-Header.Delivery.DeliveryDate',
				valueSrc: document ? document : invoice
			});

			return createMaxWidthContainer({
				layout: {
					type: 'grid',
					area: autogenCheckbox ? [[4, 2], [4], [4], [3, 2, 2], [3, 2]] : [[4, 2], [4], [3, 2, 2], [3, 2]]
				},
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					numberDocument,
					documentDate,
					autogenCheckbox,
					transactionType,
					invoiceCurrency,
					invoicePaymentDueDate,
					invoicePaymentTerms,
					despatchNumber,
					deliveryDate,
					hiddenDespatchNumber
				]
			});
		};

		/**
		 * Дополнительная информация
		 */
		const createAdditionalInformationBlock = function () {
			const contractNumber = createTextField({
				fieldLabel: edi.i18n.getMessage('invoice.reference.receiving.contract.number'),
				maxLength: 175,
				name: 'Invoice-Header.Reference.ContractNumber',
				valueSrc: document ? document : invoice
			});

			const orderNumberValue = edi.utils.getObjectProperty(invoice, 'Invoice-Header.Order.BuyerOrderNumber');
			const hiddenOrderNumber = orderNumberValue
				? createHiddenField({
						valueSrc: invoice,
						name: 'Invoice-Header.Order.BuyerOrderNumber'
				  })
				: null;

			const buyerOrderNumber = createTextField({
				fieldLabel: edi.i18n.getMessage('field.name.number'),
				maxLength: 35,
				disabled: !!orderNumberValue,
				name: 'Invoice-Header.Order.BuyerOrderNumber',
				valueSrc: orderNumberValue ? invoice : document
			});

			const orderDateValue = edi.utils.getObjectProperty(invoice, 'Invoice-Header.Order.BuyerOrderDate');
			const hiddenOrderDate = orderDateValue
				? createHiddenField({
						valueSrc: invoice,
						name: 'Invoice-Header.Order.BuyerOrderDate'
				  })
				: null;
			const buyerOrderDate = createDateField({
				fieldLabel: edi.i18n.getMessage('date'),
				name: 'Invoice-Header.Order.BuyerOrderDate',
				disabled: !!orderDateValue,
				valueSrc: orderDateValue ? invoice : document
			});

			const recAdvNumVal = edi.utils.getObjectProperty(invoice, 'Invoice-Header.Reference.ReceivingAdviceNumber');
			const hiddenRecAdvNum = recAdvNumVal
				? createHiddenField({
						valueSrc: invoice,
						name: 'Invoice-Header.Reference.ReceivingAdviceNumber'
				  })
				: null;
			const receivingAdviceNumber = createTextField({
				fieldLabel: edi.i18n.getMessage('field.name.number'),
				maxLength: 35,
				disabled: !!recAdvNumVal,
				name: 'Invoice-Header.Reference.ReceivingAdviceNumber',
				valueSrc: recAdvNumVal ? invoice : document
			});
			const recAdvDateVal = edi.utils.getObjectProperty(invoice, 'Invoice-Header.Reference.ReceivingAdviceDate');
			const hiddenRecAdvDate = recAdvDateVal
				? createHiddenField({
						valueSrc: invoice,
						name: 'Invoice-Header.Reference.ReceivingAdviceDate'
				  })
				: null;
			const receivingAdviceDate = createDateField({
				fieldLabel: edi.i18n.getMessage('date'),
				disabled: !!recAdvDateVal,
				name: 'Invoice-Header.Reference.ReceivingAdviceDate',
				valueSrc: recAdvDateVal ? invoice : document
			});

			return createFieldSet({
				title: edi.i18n.getMessage('documents.special.identifiers'),
				collapsible: true,
				items: createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: 24
					},
					items: [
						createFieldBlock({
							userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
							layout: {
								type: 'grid',
								area: [3]
							},
							items: [contractNumber]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('document.order'),
							userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
							layout: {
								type: 'grid',
								area: [[3, 2]]
							},
							items: [buyerOrderNumber, buyerOrderDate, hiddenOrderNumber, hiddenOrderDate]
						}),
						createFieldBlock({
							title: edi.i18n.getMessage('document.recadv'),
							userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
							layout: {
								type: 'grid',
								area: [[3, 2]]
							},
							items: [receivingAdviceNumber, receivingAdviceDate, hiddenRecAdvNum, hiddenRecAdvDate]
						})
					]
				})
			});
		};

		const remarks = createTextField({
			name: 'Invoice-Header.Remarks',
			valueSrc: document ? document : invoice,
			maxLength: 500,
			isTextarea: true
		});

		const createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				valuesByMap: !!document,
				callback: checkValid,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				processValues: orgConverter,
				forceChangeFields: {
					bank_acc: true,
					company_iln: true
				}
			});
			return createOrgSelector(selectorConfig);
		};

		/**
		 * Стороны
		 */
		const createPartiesBlock = function () {
			const sellerGLN = edi.utils.getObjectProperty(parties, 'Seller.ILN');
			const sellerOrg =
				edi.relations.getRelations({ self: true }).find(function (org) {
					return org.iln === sellerGLN;
				}) || userData.org;
			const sellerValues = edi.converters.convertOrgToLegacyPartie(sellerOrg);
			const sellerID =
				moduleData.initData.data?.fromOrg?.id ??
				moduleData.initData.data?.parties?.fromOrg?.id ??
				userData.org?.id;
			seller = createCS({
				itemId: 'seller',
				selectedRelationByMap: false,
				fieldValues: document || Object.keys(invoice).length > 0 ? parties : sellerValues,
				valuesByMap: !!(document || Object.keys(invoice).length > 0),
				selectedOrgValues: sellerValues,
				orgFromRelation: true,
				originalValues: orgSelectorMethods.getOrgCardValues({
					orgId: sellerID,
					converter: orgConverter
				}),
				selectedOrg: userData.org,
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				readOnly: isEdit,
				is_valid: (parties && parties.Seller && parties.Seller.ILN) || userData.org.iln,
				fieldsMap: invoiceSelectors.getSellerFieldsMap(),
				modalConf: invoiceSelectors.getSellerModalConf(),
				callback: function (values, org) {
					checkValid();
					const sellerId = org ? org.id : null;
					if (shipFrom.getOrgIdForDelcat() && shipFrom.getOrgIdForDelcat() != sellerId) {
						shipFrom.reset();
						shipFrom.setOrgIdForLoccat(sellerId);
					}
				}
			});

			const buyerOrgValues = buyerOrg ? edi.converters.convertOrgToLegacyPartie(buyerOrg) : null;
			const buyerFieldValues =
				!isEdit && buyerOrg
					? buyerOrgValues
					: edi.utils.getObjectProperty(parties, 'Buyer')
					? parties
					: undefined;

			const partiesBuyer = edi.utils.getObjectProperty(parties, 'Buyer');
			const isBuyerSelected = !isEdit && buyerOrg;
			const buyerID = moduleData.initData.data?.toOrg?.id ?? moduleData.initData.data?.parties?.toOrg?.id;

			buyer = createCS({
				itemId: 'buyer',
				relationsOnly: true,
				allowBlank: false,
				orgFromRelation: true,
				originalValues: orgSelectorMethods.getOrgCardValues({
					orgId: buyerID,
					converter: orgConverter
				}),
				is_valid: !!(buyerOrg || partiesBuyer.ILN),
				relations: isBuyerSelected ? buyerOrg : edi.relations.getRelations(),
				allowReset: !(!document && partiesBuyer) && !isCopy,
				fieldValues: isCopy || isBasedOnDoc ? parties : buyerFieldValues,
				selectedRelationByMap: !isBuyerSelected,
				selectedOrg: edi.utils.getOrg({
					orgId: buyerOrg?.id || moduleData.initData.data?.toOrg?.id
				}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				selectedOrgValues: isBuyerSelected ? buyerOrgValues : partiesBuyer ? parties : undefined,
				valuesByMap: !isBuyerSelected || isCopy || isBasedOnDoc,
				readOnly: isEdit,
				callback: function (values, org) {
					const orgId = org ? org.id : null;

					if (orgId && orgId !== buyerId) {
						availableTaxRates = edi.methods.taxRates.getRatesByCountry(org.country);
						taxRatesStore.loadData(availableTaxRates);
					}

					buyerId = orgId;
					if (productsGrid.getPartnerId() != orgId) {
						productsGrid.productsGrid.getStore().removeAll();
					}
					productsGrid.setPartnerId(orgId);
					if (delivery.getOrgIdForDelcat() != orgId) {
						delivery.reset();
						delivery.setOrgIdForDelcat(orgId);
						delivery.setPartnerOrg(org);
					}

					if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
						buyerOrg = org;
						taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
					} else if (!orgId) {
						buyerOrg = null;
						taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
					}

					checkValid();
				},
				fieldsMap: invoiceSelectors.getBuyerFieldsMap(),
				modalConf: invoiceSelectors.getBuyerModalConf()
			});

			const deliveryValues = edi.utils.getObjectProperty(header, 'Delivery');
			const deliveryFieldValues = deliveryValues ? document || invoice : undefined;
			const isDeliveryDefined = !document && deliveryValues;

			delivery = createCS({
				itemId: 'delivery',
				partnerOrgText: 'form.btn.buyer.org',
				allowBlank: false,
				ownCatalog: false,
				selectedRelationByMap: true,
				valuesByMap: true,
				alwaysShowSelect: !isDeliveryDefined,
				allowReset: !isDeliveryDefined,
				relationsFromDelcatByOrgId: buyerId,
				is_valid: !!deliveryValues.DeliveryLocationNumber,
				callback: function () {
					initialData ? edi.utils.setObjectProperty(initialData, 'Invoice-Header.Delivery', null) : null;
					checkValid();
				},
				partnerOrg: !(!document && edi.utils.getObjectProperty(invoice, 'Invoice-Header.Delivery')),
				fieldValues: deliveryFieldValues,
				partnerOrgValues: buyerOrg,
				selectedOrgValues: deliveryFieldValues,
				fieldsMap: invoiceSelectors.getDeliveryFieldsMap(),
				modalConf: invoiceSelectors.getDeliveryModalConf()
			});

			const partiesShipper = edi.utils.getObjectProperty(parties, 'ShipFrom');
			const shipFieldValues = partiesShipper ? parties : undefined;

			shipFrom = createCS({
				itemId: 'shipFrom',
				allowReset: true,
				ownCatalog: false,
				allowBlank: true,
				selectedRelationByMap: true,
				valuesByMap: true,
				ownOrg: !(!document && partiesShipper),
				relationsFromLoccatByOrgId: sellerValues.id || null,
				is_valid: !partiesShipper || partiesShipper.ILN,
				callback: function () {
					initialData ? edi.utils.setObjectProperty(initialData, 'Invoice-Parties.ShipFrom', null) : null;
					checkValid();
				},
				fieldValues: shipFieldValues,
				selectedOrgValues: shipFieldValues,
				fieldsMap: invoiceSelectors.getShipFromFieldsMap(),
				modalConf: invoiceSelectors.getShipFromModalConf()
			});

			return createFieldSet({
				title: edi.i18n.getMessage('document.section.parties'),
				collapsible: true,
				items: [
					createMaxWidthContainer({
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [
								[6, 6],
								[6, 6]
							]
						},
						items: [
							createFieldBlock({
								title: edi.i18n.getMessage('document.seller'),
								items: [seller]
							}),
							createFieldBlock({
								title: edi.i18n.getMessage('document.buyer'),
								items: [buyer]
							}),
							createFieldBlock({
								title: edi.i18n.getMessage('document.ship.from'),
								items: [shipFrom]
							}),
							createFieldBlock({
								title: edi.i18n.getMessage('document.delivery'),
								items: [delivery]
							})
						]
					})
				]
			});
		};

		/**
		 * Calculates price and tax summary according to tax recalculation method
		 * @param    {Object}     values              product data
		 * @param    {Object}     calcFieldsData      object with additional data that could override/extend values returned by gatherValuesRow
		 * @param    {Boolean}    roundResults        true to round calculated values
		 * @returns {*}
		 */
		const calculateValues = function (values, calcFieldsData, roundResults) {
			const data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE);
			if (calcFieldsData && 'object' == typeof calcFieldsData) {
				Ext.merge(data, calcFieldsData);
			}
			const calcData = edi.methods.product.price.recalculation(
				{
					taxRate: data.rateVal,
					amount: data.amount,
					netPrice: data.price,
					grossPrice: data.grossPrice,
					netSum: values.NetAmount,
					taxSum: values.TaxAmount,
					grossSum: values.GrossAmount
				},
				taxCalculationMethod,
				undefined,
				undefined,
				costDecimals
			);
			values.GrossAmount = calcData.grossSum;
			values.TaxAmount = calcData.taxSum;
			values.NetAmount = calcData.netSum;
			values.InvoiceUnitGrossPrice = calcData.grossPrice;
			values.InvoiceUnitNetPrice = calcData.netPrice;
			roundResults
				? (values = edi.methods.product.grid.roundValues(
						values,
						['GrossAmount', 'TaxAmount', 'NetAmount', 'InvoiceUnitGrossPrice', 'InvoiceUnitNetPrice'],
						costDecimals
				  ))
				: null;
			return values;
		};

		const processRecordValues = function (values) {
			const calculated = Ext.clone(values);
			calculateValues(calculated, undefined, true);
			return values;
		};

		availableTaxRates = edi.methods.taxRates.getRatesByCountry(buyerOrg ? buyerOrg.country : 'RUS');
		taxRatesStore = edi.stores.createInlineStore(availableTaxRates);
		const lines = edi.utils.getObjectProperty(document ? document : invoice, 'Invoice-Lines.Line', true);
		if ('string' != typeof lines) {
			for (let i = 0; i < lines.length; i++) {
				const values = Ext.clone(lines[i]['Line-Item']);
				edi.document.actions.setInternalLineId(lines[i], values);
				productValues.products.push(processRecordValues(values));
			}
		}

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.invoice.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: INVOICE_LINE_MODEL,
			totalModel: INVOICE_SUMMARY_MODEL,
			gridColumnConfig: INVOICE_LINE_COLUMN,
			totalColumnConfig: INVOICE_SUMMARY_EDITABLE_COLUMN,
			data: productValues.products,
			lockIfNoPartner: true,
			exclude: [],
			lineNumberFieldName: 'LineNumber',
			isValidRecord: function (record) {
				const product = record.getData();
				const taxValid = edi.methods.taxRates.isValidRate(product.TaxRate, availableTaxRates);

				return taxValid && !!(product.TaxCategoryCode && product.InvoiceUnitNetPrice);
			},
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			afterRowEdit: function (values, field) {
				if (field === 'InvoiceQuantity') {
					calculateValues(values, undefined, true);
				}
			},
			docData: document,
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				updateTaxSummary();
				checkValid();
			},
			resetFormOnCatalogSelection: true,
			selectProductHandler: function (product) {
				const fields = this.getFormFields();
				const PackDetails = product.PackDetails ? product.PackDetails : {};

				fields.BuyerItemCode?.setValue(product.BuyerItemCode);
				fields.EAN ? fields.EAN.setValue(product.EAN) : null;
				fields.InvoiceQuantity && fields.InvoiceQuantity.getValue() < PackDetails.MinOrderedQuantity
					? fields.InvoiceQuantity.setValue(PackDetails.MinOrderedQuantity)
					: null;
				fields.ItemType ? fields.ItemType.setValue(product.ItemType) : null;
				fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
				fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;
				fields.InvoiceUnitNetPrice ? fields.InvoiceUnitNetPrice.setValue(product.UnitNetPrice) : null;
				fields.InvoicedUnitPackSize?.setValue(PackDetails.unitPacksize);
				fields.PreviousTaxRate ? fields.PreviousTaxRate.setValue(String(product.TaxRate)) : null;
				fields.SupplierItemCode?.setValue(product.SupplierItemCode);
				fields.TaxCategoryCode ? fields.TaxCategoryCode.setValue(product.TaxCategoryCode) : null;

				fields.UnitOfMeasure ? fields.UnitOfMeasure.findAndSetValue(product.UnitOfMeasure) : null;
			},
			modalFormConfig: {
				title: 'document.invoice.line',
				formMethods: {
					setFieldsInvoice: function () {
						let values = this.getFormValues();
						const fields = this.getFormFields();

						values = calculateValues(values, undefined, true);
						fields.NetAmount ? fields.NetAmount.setValue(values.NetAmount) : null;
						fields.TaxAmount ? fields.TaxAmount.setValue(values.TaxAmount) : null;
						fields.GrossAmount ? fields.GrossAmount.setValue(values.GrossAmount) : null;
						fields.InvoiceUnitNetPrice
							? fields.InvoiceUnitNetPrice.setValue(values.InvoiceUnitNetPrice)
							: null;
						fields.InvoiceUnitGrossPrice
							? fields.InvoiceUnitGrossPrice.setValue(values.InvoiceUnitGrossPrice)
							: null;
					}
				},
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [5, [6, 3], [3, 3], [3, 3]]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								value: 'CU',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.item.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								maskRe: /\d/i,
								validator: edi.methods.validators.ean
							},
							{
								title: edi.i18n.getMessage('line.item.invoice.quantity'),
								name: 'InvoiceQuantity',
								type: 'number',
								validator: edi.methods.validators.positiveNonZero,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: costDecimals,
								listeners: {
									change: function () {
										this.setFieldsInvoice();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode',
								anyMatch: true
							},
							{
								name: 'SupplierItemCode',
								maxLength: 175
							},
							{
								name: 'BuyerItemCode',
								maxLength: 35
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [3, [3, 3], [3, 3], 3, [3, 3, 3]]
						},
						items: [
							{
								title: edi.i18n.getMessage('organization.taxCalculationMethod'),
								readOnly: true,
								value: edi.i18n.getMessage('organization.taxCalculationMethod.' + taxCalculationMethod)
							},
							{
								title: edi.i18n.getMessage('line.item.invoice.unit.net.price'),
								name: 'InvoiceUnitNetPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								allowBlank: false,
								listeners: {
									change(field, newVal, oldVal) {
										if (+newVal === +oldVal) {
											return;
										}
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.NET_PRICE ||
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.WITHOUT
											? this.setFieldsInvoice()
											: null;
										const fields = this.getFormFields();
										if (fields.InvoiceUnitGrossPrice) {
											fields.InvoiceUnitGrossPrice.isValid();
										}
									}
								},
								validator: function (value) {
									let valid = true;
									const fields = this.getFormFields();
									if (fields.InvoiceUnitGrossPrice) {
										if (!value && fields.InvoiceUnitGrossPrice.getValue()) {
											valid = edi.i18n.getMessage('error.value.notvalid');
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'InvoiceUnitGrossPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 10,
								listeners: {
									change(field, newVal, oldVal) {
										if (+newVal === +oldVal) {
											return;
										}
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.GROSS_PRICE
											? this.setFieldsInvoice()
											: null;
										const fields = this.getFormFields();
										if (fields.InvoiceUnitNetPrice) {
											fields.InvoiceUnitNetPrice.isValid();
										}
									}
								},
								validator: function (value) {
									let valid = true;
									const fields = this.getFormFields();
									if (fields.InvoiceUnitNetPrice) {
										if (
											(!value && fields.InvoiceUnitNetPrice.getValue()) ||
											Number(value) < Number(fields.InvoiceUnitNetPrice.getValue())
										) {
											valid = edi.i18n.getMessage('error.value.notvalid');
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate',
								type: 'combo',
								allowBlank: false,
								store: taxRatesStore,
								valueInitialize: true,
								forceSelection: !isEdit,
								validator: isEdit
									? function (value) {
											return (
												edi.methods.taxRates.isValidRate(value, availableTaxRates) ||
												edi.i18n.getMessage('form.field.tax.invalid')
											);
									  }
									: null,
								listeners: {
									change: function (comp, value) {
										const fields = this.getFormFields();
										if (fields.PreviousTaxRate && !fields.PreviousTaxRate.getValue()) {
											fields.PreviousTaxRate.setValue(value);
										}
										this.setFieldsInvoice();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.previous.tax.rate'),
								name: 'PreviousTaxRate',
								type: 'combo',
								store: taxRatesStore,
								forceSelection: !isEdit,
								validator: isEdit
									? function (value) {
											return (
												edi.methods.taxRates.isValidRate(value, availableTaxRates, true) ||
												edi.i18n.getMessage('form.field.tax.invalid')
											);
									  }
									: null
							},
							{
								title: edi.i18n.getMessage('line.item.tax.category.code'),
								name: 'TaxCategoryCode',
								type: 'combo',
								allowBlank: false,
								store: edi.stores.createSimpleInlineStore(['S', 'Z', 'E'], function (id) {
									return edi.i18n.getMessage('tax.category.code.' + id);
								}),
								value: 'S'
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								name: 'NetAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								name: 'TaxAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								name: 'GrossAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [6]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.invoice.unit.packsize'),
								name: 'InvoicedUnitPackSize',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 10
							}
						]
					}
				]
			}
		});
		productsGrid.setPartnerId(buyerId);

		const getTaxSummaryData = function () {
			const data = [];
			const taxSummaryLines = createInvoiceTaxSummaryLines(productValues);
			for (let i = 0; i < taxSummaryLines.length; i++) {
				data.push(edi.models.createInstance(INVOICE_TAX_SUMMARY_MODEL, taxSummaryLines[i]));
			}
			return data;
		};

		const taxSummaryGrid = createGrid({
			storeConfig: {
				proxy: createProxyConfig({
					type: 'memory',
					data: {
						items: getTaxSummaryData()
					}
				}),
				model: edi.models.getModel(INVOICE_TAX_SUMMARY_MODEL),
				remoteSort: false
			},
			gridConfig: {
				title: edi.i18n.getMessage('grid.title.tax'),
				columnWidth: 0.5,
				columns: edi.columns.get(INVOICE_TAX_SUMMARY_COLUMN),
				disablePaging: true,
				disableSelection: true,
				enableColumnMove: false,
				enableColumnResize: false,
				sortableColumns: false
			},
			viewConfig: {
				emptyText: ''
			}
		});

		const updateTaxSummary = function () {
			taxSummaryGrid.getStore().loadData(getTaxSummaryData());
		};

		const taxSummaryGridWrapper = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				{
					xtype: 'component',
					columnWidth: 0.5,
					html: '<br/>'
				},
				taxSummaryGrid
			]
		});

		const createButton = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom', 'delivery'],
							[buyer, seller, delivery],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					save();
				}
			},
			isEdit
		);

		let createAndSendButton;
		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE
				)
			)
		) {
			createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom', 'delivery'],
							[buyer, seller, delivery],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					afterSave = function (documentId, callback, responseData) {
						const isWarning =
							edi.utils.getAttributeByName(responseData.data?.attributes, 'isWarning') === 'true';
						const isBlocked =
							edi.utils.getAttributeByName(responseData.data?.attributes, 'isBlocked') === 'true';
						if (!isWarning && !isBlocked) {
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: documentId
									},
									true
								),
								'PUT',
								Ext.encode({}),
								null,
								edi.document.actions.defaultFailureHandler(
									moduleData.tab,
									'document.error.in.sending.process'
								),
								callback
							);
						} else {
							if ('function' == typeof callback) {
								callback();
							}
						}
					};
					save();
				},
				null,
				isEdit
			);
		}

		form = createFormForModule({
			cls: 'edi-form document-add-form',
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage(isEdit ? 'document.invoice' : 'document.add.invoice')
				}),
				createMainDataBlock(),
				createPartiesBlock(),
				createAdditionalInformationBlock(),
				createFieldBlock({
					title: edi.i18n.getMessage('documents.column.remark'),
					cls: 'edi-form-maxwidth',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					layout: {
						type: 'grid',
						area: [6]
					},
					items: [remarks]
				}),
				productsGrid,
				taxSummaryGridWrapper
			],
			buttons: createButtonContainer({
				items: [createAndSendButton, createButton]
			})
		});

		form.on('validitychange', checkValid);
		form.isValid();
		form.on('render', updateTaxSummary);
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		let valid = false;
		const total = productValues.products.length;
		const validCompanies = validateCompanySelectors();
		const hasInvalid = form.hasInvalidField();
		const validGrid = productsGrid.isValid();
		if (total && !hasInvalid && validCompanies && validGrid) {
			valid = true;
		}
		return valid;
	};
	/**
	 * Checks company controls, and marks them, if not valid
	 */
	const validateCompanySelectors = function () {
		const isBuyerValid = buyer.isValid();
		const isSellerValid = seller.isValid();
		const isDeliveryValid = delivery.isValid();
		const isShipFromValid = shipFrom.isValid();

		return isBuyerValid && isSellerValid && isDeliveryValid && isShipFromValid;
	};
	/**
	 * Saves invoice
	 */
	const save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		const values = edi.utils.collectFormValues(form);
		const lines = productValues.products;

		const header = edi.utils.getObjectProperty(values, 'Invoice-Header');
		header.DocumentFunctionCode = 9;
		const numberAutoGenerated = values.numberAutoGenerated;
		delete values.numberAutoGenerated;

		let document = createInvoice(
			createInvoiceHeader(header),
			createInvoiceParties(
				createInvoicePartie(edi.utils.getObjectProperty(values, 'Buyer')),
				createInvoicePartie(edi.utils.getObjectProperty(values, 'Seller')),
				createInvoicePartie(edi.utils.getObjectProperty(values, 'ShipFrom')),
				createInvoicePartie(edi.utils.getObjectProperty(values, 'Buyer')) // Payer actually
			),
			createInvoiceLines(lines, edi.utils.getObjectProperty(values, 'Invoice-Header.Delivery')),
			createInvoiceSummary({}, productValues),
			createInvoiceSigner(edi.utils.getObjectProperty(values, 'Signer'))
		);

		document = edi.document.actions.mergeDataBeforeSave(initialData, document, [
			{
				path: 'Invoice-Lines.Line'
			}
		]);

		edi.utils.clearEmptyValues(document);
		const headerData = {
			data: Ext.encode(document),
			date: edi.utils.getObjectProperty(document, 'Invoice-Header.InvoiceDate')
		};
		if (isNumberAutoGenerated && numberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(document, 'Invoice-Header.InvoiceNumber');
		}
		const success = edi.document.actions.createSaveSuccessHandler(
			moduleData,
			isEdit ? id : undefined,
			afterSave,
			isEdit
		);
		const failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE
		);
		edi.document.actions.processDocument(
			buyerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
			parentId,
			isEdit ? id : undefined,
			success,
			failure,
			headerData
		);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const data = moduleData.initData.data;
		const modulePanel = createAddModulePanel();

		const createModuleForm = function (docData) {
			const form = createModForm(docData);
			modulePanel.add(form);

			moduleData.tab.add(modulePanel);
		};

		if (data && data.id) {
			id = data.id;
			buyerOrg = data.toOrg;
			if (!buyerOrg?.hasOwnProperty('attributes')) {
				buyerOrg = edi.utils.getOrg({ orgId: buyerOrg.id });
			}
			buyerId = buyerOrg.id;
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;

			taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						initialData = data.data;
						createModuleForm(data.data);

						if (productValues.products.length) {
							edi.methods.taxRates.showInvalidProductsWarnIfNeeded(
								productValues.products,
								availableTaxRates
							);
						}

						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			const createModulePanel = function () {
				createModuleForm();
				buyer.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});

				if (isBasedOnDoc && productValues.products.length) {
					edi.methods.taxRates.showInvalidProductsWarnIfNeeded(productValues.products, availableTaxRates);
				}
			};

			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' === docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
