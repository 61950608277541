const createCommonPricat = function (config) {
	config = config ? config : {};
	var commonPricatLines = [];

	if (config.productValues) {
		for (var i = 0; i < config.productValues.products.length; i++) {
			var line = {},
				product = config.productValues.products[i];
			if (product.PriceRegionText) {
				line['Line-PriceRegions'] = {
					PriceRegionText: product.PriceRegionText
				};
			}
			if (product[edi.constants.INTERNAL_LINE_ID]) {
				line[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}
			delete product[edi.constants.INTERNAL_LINE_ID];
			line['Line-Item'] = product;
			commonPricatLines.push(line);
		}
	}

	return {
		'PriceCatalogue-Header': createCommonPricatHeader(config['PriceCatalogue-Header']),
		'PriceCatalogue-Parties': config.parties || {},
		'Document-Parties': config.documentParties || {},
		'PriceCatalogue-Lines': {
			Line: commonPricatLines
		},
		'PriceCatalogue-Summary': {
			TotalLines: commonPricatLines.length
		}
	};
};

const createCommonPricatHeader = function (config) {
	config = config ? config : {};

	return {
		PriceCatalogueNumber: config.PriceCatalogueNumber,
		DocumentFunctionCode: config.DocumentFunctionCode,
		PriceCatalogueDate: config.PriceCatalogueDate,
		DocumentNameCode: config.DocumentNameCode,
		PeriodStartDate: config.PeriodStartDate,
		PeriodEndDate: config.PeriodEndDate,
		PriceCatalogueCurrency: config.PriceCatalogueCurrency,
		Reference: config.Reference,
		Remarks: config.Remarks,
		SegmentPrice: config.SegmentPrice,
		IDFile: config.IDFile
	};
};

const createCommonPricatParties = function (config) {
	config = config ? config : {};

	return {
		Buyer: {
			ILN: edi.utils.getObjectProperty(config, 'Buyer.ILN'),
			FnsID: edi.utils.getObjectProperty(config, 'Buyer.FnsID'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'Buyer.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'Buyer.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'Buyer.CodeByCarrier'),
			TaxID: edi.utils.getObjectProperty(config, 'Buyer.TaxID'),
			TaxRegistrationReasonCode: edi.utils.getObjectProperty(config, 'Buyer.TaxRegistrationReasonCode'),
			UtilizationRegisterNumber: edi.utils.getObjectProperty(config, 'Buyer.UtilizationRegisterNumber'),
			Name: edi.utils.getObjectProperty(config, 'Buyer.Name'),
			CityName: edi.utils.getObjectProperty(config, 'Buyer.CityName'),
			Country: edi.utils.getObjectProperty(config, 'Buyer.Country'),
			HouseNumber: edi.utils.getObjectProperty(config, 'Buyer.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'Buyer.Housing'),
			PostalCode: edi.utils.getObjectProperty(config, 'Buyer.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'Buyer.RoomNumber'),
			AddressType: edi.utils.getObjectProperty(config, 'Buyer.AddressType'),
			State: edi.utils.getObjectProperty(config, 'Buyer.State'),
			StateCode: edi.utils.getObjectProperty(config, 'Buyer.StateCode'),
			District: edi.utils.getObjectProperty(config, 'Buyer.District'),
			Locality: edi.utils.getObjectProperty(config, 'Buyer.Locality'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'Buyer.StreetAndNumber')
		},
		Seller: {
			ILN: edi.utils.getObjectProperty(config, 'Seller.ILN'),
			FnsID: edi.utils.getObjectProperty(config, 'Seller.FnsID'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'Seller.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'Seller.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'Seller.CodeByCarrier'),
			TaxID: edi.utils.getObjectProperty(config, 'Seller.TaxID'),
			TaxRegistrationReasonCode: edi.utils.getObjectProperty(config, 'Seller.TaxRegistrationReasonCode'),
			UtilizationRegisterNumber: edi.utils.getObjectProperty(config, 'Seller.UtilizationRegisterNumber'),
			Name: edi.utils.getObjectProperty(config, 'Seller.Name'),
			CityName: edi.utils.getObjectProperty(config, 'Seller.CityName'),
			Country: edi.utils.getObjectProperty(config, 'Seller.Country'),
			HouseNumber: edi.utils.getObjectProperty(config, 'Seller.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'Seller.Housing'),
			PostalCode: edi.utils.getObjectProperty(config, 'Seller.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'Seller.RoomNumber'),
			AddressType: edi.utils.getObjectProperty(config, 'Seller.AddressType'),
			State: edi.utils.getObjectProperty(config, 'Seller.State'),
			StateCode: edi.utils.getObjectProperty(config, 'Seller.StateCode'),
			District: edi.utils.getObjectProperty(config, 'Seller.District'),
			Locality: edi.utils.getObjectProperty(config, 'Seller.Locality'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'Seller.StreetAndNumber')
		},
		PriceRegions: {
			PriceRegionText: edi.utils.getObjectProperty(config, 'PriceRegions.PriceRegionText'),
			PriceRegionsList: edi.utils.getObjectProperty(config, 'PriceRegions.PriceRegions')
		}
	};
};

const createCommonPricatDocumentParties = function (config) {
	config = config ? config : {};

	return {
		Receiver: {
			ILN: edi.utils.getObjectProperty(config, 'Buyer.ILN'),
			CodeBySender: edi.utils.getObjectProperty(config, 'Buyer.CodeBySender'),
			CodeByReceiver: edi.utils.getObjectProperty(config, 'Buyer.CodeByReceiver'),
			FnsID: edi.utils.getObjectProperty(config, 'Buyer.FnsID'),
			Name: edi.utils.getObjectProperty(config, 'Buyer.Name')
		},
		Sender: {
			ILN: edi.utils.getObjectProperty(config, 'Seller.ILN'),
			CodeBySender: edi.utils.getObjectProperty(config, 'Seller.CodeBySender'),
			CodeByReceiver: edi.utils.getObjectProperty(config, 'Seller.CodeByReceiver'),
			FnsID: edi.utils.getObjectProperty(config, 'Seller.FnsID'),
			Name: edi.utils.getObjectProperty(config, 'Seller.Name')
		}
	};
};

export { createCommonPricat, createCommonPricatParties, createCommonPricatDocumentParties };
