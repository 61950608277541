import {
	AMOUNT_FIELD_NAME,
	get,
	getFormFields,
	getProductIdent,
	LINE_NUMBER_FIELD_NAME,
	PACKING_ID_FIELD_NAME
} from './createTree';
import { recalculateProductValues } from './methods';
import { createFieldsForProductGrid } from '@Components/fields';
import { createForm, createTab, createTabPanel, TAB_PANEL_CLS } from '@Components/panels';
import { createAddSaveButton } from '@Components/buttons';
import { createEditableGrid } from '@Core/specialComponents/editableGrid';
import { createModalProductSelect } from '@Edi/specialComponents/modalProductSelect';
import { Packing, Product, Tree, TreeRecord } from './definitions';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { ROW_COLOR_CLS } from '@Components/grid';
// @ts-ignore
import { showConfirm } from '@UIkit/components/modal/MessageBox';

let tabPanel: ExtComponent;

/**
 * Return values from product modal form
 */
const getProductFormValues = function (modal: ExtComponent, useModelData = false): Product {
	let values = edi.utils.collectFormValues(modal.formPanel, undefined, useModelData);
	if (useModelData) {
		values = Ext.Object.merge(Ext.clone(values), edi.utils.collectFormValues(modal.formPanel));
	}

	for (let key in modal.formGrids) {
		if (modal.formGrids.hasOwnProperty(key)) {
			edi.utils.setObjectProperty(values, key, edi.utils.getDataFromGrid(modal.formGrids[key]));
		}
	}

	return values;
};

/**
 * Sets focus on invalid field in window
 */
const focusOnInvalidField = function (formPanel: ExtComponent, tree: Tree, tabPanel: ExtComponent) {
	const fields = formPanel.getForm().getFields().items;
	let invalidField: ExtComponent | null = null;
	let invalidTab: ExtComponent | null = null;
	for (let i = 0, len = fields.length; i < len; i++) {
		let field = fields[i];
		if (!!field) {
			if (!field.isValid()) {
				invalidField = field;
				let tab = field.up('panel');
				if (tab && !tab.hasOwnProperty('tab')) {
					tab = tab.up('panel');
				}
				invalidTab = tab;
				break;
			} else if (tree.allowBlankEditableGrids.length > 0) {
				for (let j = 0; j < tree.allowBlankEditableGrids.length; j++) {
					let gridItem = tree.allowBlankEditableGrids[j];
					let gridName = Object.keys(gridItem)[0];
					let editableGrid = tree.formGrids[gridName];
					let isNotValidRecord = false;

					if (!!editableGrid) {
						let editableGridStore = editableGrid.getStore();
						isNotValidRecord =
							editableGridStore.data.items.filter(function (record: ExtRecord<AnyObject>) {
								let isHasEmptyValue = false;
								for (let k = 0; k < Object.values(gridItem)[0].length; k++) {
									let value = Object.values(gridItem)[0][k];

									if (record.get(value).length === 0) {
										isHasEmptyValue = record.get(value).length === 0;
										break;
									}
								}
								return isHasEmptyValue;
							}).length > 0;
					}
					if (isNotValidRecord) {
						invalidTab = editableGrid.up('panel');
						break;
					}
				}
			}
		}
	}

	if (invalidTab) {
		tabPanel.setActiveTab(invalidTab);
	}
	if (invalidField) {
		setTimeout(() => invalidField?.focus(), 0);
	}
};

/**
 * Main method construct modal form
 */
const constructModalForm = function (tree: Tree, modalConf: Tree['modalFormConfig'], productData?: Product) {
	let tabs = [];

	let formPanel = createForm({
		layout: 'fit',
		submitEmptyText: false
	}) as ExtComponent;

	let inputConfig = {
		readOnly: tree.readOnly
	};

	let configForm = Ext.clone(modalConf.modalFields);

	let getRowClassFn = function (tabConfig: AnyObject) {
		let fields = tabConfig.config.fields,
			gridConfig = tabConfig.config;
		return function (record: ExtRecord<AnyObject>) {
			let res = ROW_COLOR_CLS.valid;
			if ('function' == typeof gridConfig.getRowClass) {
				res = gridConfig.getRowClass(record, fields);
			} else {
				for (let j = 0; j < fields.length; j++) {
					if (
						fields[j].hasOwnProperty('allowBlank') &&
						!fields[j].allowBlank &&
						!record.get(fields[j].name)
					) {
						res = ROW_COLOR_CLS.error;
					}
				}
			}
			return res;
		};
	};

	let formGrids: {
		[key: string]: ExtComponent;
	} = {};
	for (let i = 0; i < configForm.length; i++) {
		let tabConfig = Ext.clone(configForm[i]);
		if (Array.isArray(tabConfig.items) && tabConfig.items.length) {
			let fields: ExtComponent[] = [];
			let simpleFieldConfigs: AnyObject[] = [];
			tabConfig.items.forEach(function (fieldConfig: AnyObject) {
				if (!fieldConfig) {
					return;
				}

				//Save simple fields while meet an editable grid
				if (fieldConfig.type !== 'editableGrid') {
					simpleFieldConfigs.push(fieldConfig);
				} else {
					//create simple fields, add them to tab fields and create an editable grid
					fields = fields.concat(
						createFieldsForProductGrid(simpleFieldConfigs, productData, undefined, inputConfig)
					);
					simpleFieldConfigs = [];
					formGrids[fieldConfig.name] = createEditableGrid(
						Ext.merge(fieldConfig.config, {
							readOnly: tree.readOnly,
							gridConfig: {
								viewConfig: {
									getRowClass: getRowClassFn(fieldConfig)
								}
							}
						}),
						edi.utils.getObjectProperty(productData, fieldConfig.name, true)
					) as ExtComponent;

					fields.push(formGrids[fieldConfig.name]);
				}
			});

			if (simpleFieldConfigs.length) {
				fields = fields.concat(
					createFieldsForProductGrid(simpleFieldConfigs, productData, undefined, inputConfig)
				);
			}

			tabConfig.items = fields;
		}

		Ext.applyIf(tabConfig, {
			title: 'Untitled',
			closable: false,
			padding: '16, 24',
			layout: {
				type: 'grid',
				gap: [24, 16]
			},
			scrollable: 'y',
			items: []
		});
		tabs.push(createTab(tabConfig));
	}

	tabPanel = createTabPanel({
		cls: TAB_PANEL_CLS.simpleWithoutPadding,
		activeTab: 0,
		items: tabs
	}) as ExtComponent;
	formPanel.add(tabPanel);
	formPanel.isValid();

	if (tree.isFocusOnInvalidField) {
		setTimeout(function () {
			focusOnInvalidField(formPanel, tree, tabPanel);
		}, 100);
	}

	return {
		formPanel,
		formGrids
	};
};

/**
 * Close modal if it exists
 */
const closeModal = function (modal: ExtComponent) {
	if (modal && !modal.isDestroyed) {
		modal.close();
	}
};

/**
 * Find max line number and return it+1
 */
const getNextLineNumber = function (tree: Tree) {
	let maxLineNumber = 0;
	Object.values(tree.maps.productsById).forEach((prod) => {
		if (prod[LINE_NUMBER_FIELD_NAME] > maxLineNumber) {
			maxLineNumber = prod[LINE_NUMBER_FIELD_NAME];
		}
	});
	return maxLineNumber + 1;
};

/**
 * Saves product modal values to tree record
 */
const saveProductValues = function (
	tree: Tree,
	modal: ExtComponent,
	record?: TreeRecord<Product>,
	packing?: TreeRecord<Packing>
) {
	if (!modal.formPanel.isValid()) {
		if (tree.isFocusOnInvalidField) {
			focusOnInvalidField(modal.formPanel, tree, tabPanel);
			return;
		} else {
			edi.core.showError('product.error.fill.all.fields');
			return;
		}
	}

	let productValues: Product = getProductFormValues(modal, true);
	let productId = record ? get(record, 'productID') : edi.core.getId();
	productValues.productID = productId;

	let newRecordData: AnyObject = {};
	if (record) {
		newRecordData = Ext.clone(record.data.data);
	}

	Object.assign(newRecordData, productValues);
	newRecordData = recalculateProductValues(tree, newRecordData) as Product;

	if (record) {
		//update all tree data with this productId
		delete newRecordData.packingId;
		delete newRecordData.secondLvlPackingIds;

		//update product data and total despatched quantity
		let productByIdInMap = tree.maps.productsById[productId];
		Ext.merge(productByIdInMap, newRecordData);
		productByIdInMap[AMOUNT_FIELD_NAME] = productByIdInMap.ProductQuantityDespatched;
		recalculateProductValues(tree, productByIdInMap);

		//update product data in packs
		let newQuantityDespatched = +newRecordData[AMOUNT_FIELD_NAME] || 0;
		let amountInAllPacks = 0;
		Object.entries(tree.maps.productsByPack)
			.filter(([ident, _]) => ident.includes(productId))
			.forEach(([_, prod]) => {
				Ext.merge(prod, newRecordData);
				recalculateProductValues(tree, prod);
				if (prod.packingId) {
					amountInAllPacks += +prod[AMOUNT_FIELD_NAME] || 0;
				}
			});

		//if total amount more than placed in packs, then we have to place unplacedAmount in "without pack"
		let unplacedAmount = newQuantityDespatched - amountInAllPacks;
		if (unplacedAmount > 0) {
			let newProdIdent = getProductIdent({
				productID: productId,
				packingId: undefined
			});
			let currentWithoutPackProduct = tree.maps.productsByPack[newProdIdent];
			if (currentWithoutPackProduct) {
				currentWithoutPackProduct[AMOUNT_FIELD_NAME] = String(unplacedAmount);
				recalculateProductValues(tree, currentWithoutPackProduct);
			} else {
				let newProd: Product = Ext.clone(productByIdInMap);
				tree.maps.productsByPack[newProdIdent] = newProd;
				newProd[AMOUNT_FIELD_NAME] = String(unplacedAmount);
				recalculateProductValues(tree, newProd);
			}
		}
	} else {
		//create new product record and put it into tree
		let packingId = (packing && get(packing, PACKING_ID_FIELD_NAME)) || undefined;
		newRecordData[AMOUNT_FIELD_NAME] = String(+newRecordData.ProductQuantityDespatched || 0);
		newRecordData[LINE_NUMBER_FIELD_NAME] = getNextLineNumber(tree);
		newRecordData.secondLvlPackingIds = packingId ? [packingId] : [];

		tree.maps.productsById[productId] = Ext.clone(newRecordData) as Product;

		let ident = getProductIdent({
			productID: productId,
			packingId: packingId
		});
		newRecordData.packingId = packingId;
		tree.maps.productsByPack[ident] = Ext.clone(newRecordData) as Product;

		tree.createAndAppend(newRecordData, packing);
	}

	closeModal(modal);

	tree.rebuildTree();

	if (typeof tree.callback === 'function') {
		tree.callback();
	}
	if (typeof tree.totalsHandler === 'function') {
		tree.totalsHandler();
	}
	tree.getStore().sort();
};

/**
 * Sets selected product data to modal's fields
 */
const selectProductHandler = function (
	tree: Tree,
	product: Product & { PackDetails?: { MinOrderedQuantity: number } }
) {
	let fields = getFormFields(tree.openedModal);
	let PackDetails = product.PackDetails ? product.PackDetails : { MinOrderedQuantity: 0 };
	fields.EAN ? fields.EAN.setValue(product.EAN) : null;
	fields[AMOUNT_FIELD_NAME] && fields[AMOUNT_FIELD_NAME].getValue() < PackDetails.MinOrderedQuantity
		? fields[AMOUNT_FIELD_NAME].setValue(PackDetails.MinOrderedQuantity)
		: null;
	fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
	fields.ItemType ? fields.ItemType.setValue(product.ItemType) : null;
	fields.SupplierItemCode ? fields.SupplierItemCode.setValue(product.SupplierItemCode) : null;
	fields.BuyerItemCode ? fields.BuyerItemCode.setValue(product.BuyerItemCode) : null;
	fields.UnitNetPrice ? fields.UnitNetPrice.setValue(product.UnitNetPrice) : null;
	fields.UnitGrossPrice ? fields.UnitGrossPrice.setValue(product.UnitGrossPrice) : null;
	fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;
	fields.ExpirationDate ? fields.ExpirationDate.setValue(product.ExpirationDate) : null;
	fields.UnitOfMeasure ? fields.UnitOfMeasure.findAndSetValue(product.UnitOfMeasure || '') : null;
};

const resetFormFields = function ({
	formPanel,
	formGrids
}: {
	formPanel: ExtComponent;
	formGrids: { [key: string]: ExtComponent };
}) {
	const fields: ExtComponent[] = formPanel.form.getFields().items;
	fields.forEach((field) => {
		const isAllowReset = field && !field.isDestroyed && !(field.isDisabled() || field.readOnly);
		if (isAllowReset) field.setValue(null);
	});
	Object.values(formGrids).forEach((grid) => {
		if (grid && !grid.isDestroyed) {
			const store = grid.getStore();
			store.removeAll();
		}
	});
};

/**
 * Displays modal product data form
 */
const showModalProductForm = function (
	tree: Tree,
	record?: TreeRecord<Product>,
	packing?: TreeRecord<Packing>,
	options?: { isDetails?: boolean; isEdit?: boolean; isCreate?: boolean }
) {
	let recordData = record?.data?.data;

	if (!!recordData && 'function' == typeof tree.changeValuesBeforeEdit) {
		tree.changeValuesBeforeEdit(recordData);
	}

	let { formPanel, formGrids } = constructModalForm(tree, tree.modalFormConfig, recordData);

	let title = edi.i18n.getMessage('modal.product.data');
	if (tree.modalFormConfig.hasOwnProperty('title')) {
		let titleKey =
			tree.modalFormConfig.title + (!record ? '.add.title' : tree.readOnly ? '.read.title' : '.edit.title');
		if (edi.i18n.getMessage(titleKey) !== titleKey) {
			title = edi.i18n.getMessage(titleKey);
		} else if (edi.i18n.getMessage(tree.modalFormConfig.title) !== tree.modalFormConfig.title) {
			title = edi.i18n.getMessage(tree.modalFormConfig.title);
		}
	}

	let buttons: ExtComponent[] = [];

	const isFormFieldsChanged = function (): boolean {
		const isFieldChanged = formPanel.isDirty();
		const isGridsFill = Object.values(formGrids).some((grid) => {
			if (grid && !grid.isDestroyed) return !!grid.getStore().getCount();
		});
		return isFieldChanged || isGridsFill;
	};

	if (!tree.readOnly) {
		buttons.push(
			createAddSaveButton(
				{
					handler: () => saveProductValues(tree, modal, record, packing)
				},
				!!options?.isEdit
			) as ExtComponent
		);

		if (!tree.modalFormConfig.forbidSelectProductFromCatalog) {
			buttons.push(
				createButton({
					cls: BUTTON_CLS.secondary,
					text: edi.i18n.getMessage('form.btn.select.product'),
					glyph: edi.constants.ICONS.SEARCH,
					handler() {
						if (tree.partnerId) {
							createModalProductSelect(
								modal,
								{
									toOrg: tree.ownCatalog ? tree.partnerId : edi.core.getUserOrgID(),
									fromOrg: tree.ownCatalog ? edi.core.getUserOrgID() : tree.partnerId
								},
								(product: Product) => {
									//аналог функционала из ProductGrid (resetFormOnCatalogSelection)
									if (options?.isEdit || isFormFieldsChanged()) {
										showConfirm({
											msgText: edi.i18n.getMessage('product.select.confirm.message'),
											yesBtnConfig: {
												text: edi.i18n.getMessage('product.select.confirm.merge')
											},
											noBtnConfig: {
												text: edi.i18n.getMessage('product.select.confirm.replace')
											},
											failure: () => resetFormFields({ formPanel, formGrids }),
											callback: () => selectProductHandler(tree, product)
										});
									} else {
										selectProductHandler(tree, product);
									}
								}
							);
						} else {
							edi.core.showInfo(edi.i18n.getMessage('info.no.seller'));
						}
					}
				}) as ExtComponent
			);
		}
	}

	if (Array.isArray(tree.modalFormConfig.buttonsBefore)) {
		buttons = buttons.concat(
			tree.modalFormConfig.buttonsBefore.map(function (buttonConfig: AnyObject) {
				return createButton(
					Object.assign(
						{
							cls: BUTTON_CLS.secondary,
							scope: tree
						},
						buttonConfig
					)
				) as ExtComponent;
			})
		);
	}

	let modal: ExtComponent = createModalPanel({
		cls: 'edi-modal-product-data-selector',
		title: title,
		width: MODAL_SIZE.widthLarge,
		layout: 'fit',
		items: [formPanel],
		buttonsBefore: buttons
	});

	modal.formPanel = formPanel;
	modal.formGrids = formGrids;

	tree.openedModal = modal;

	modal.show();
};

export { showModalProductForm, getProductFormValues };
