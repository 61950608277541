import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createTool } from '@UIkit/components/buttons';

import './previewPanel.scss';

const createPreviewPanel = function (data = {}, config = {}) {
	const { fileId, fileName = '', imagesBase64Data = [] } = data ?? {};
	const { url: confUrl, modalConf = {} } = config ?? {};

	const images = imagesBase64Data?.map((imgBase64) => {
		return imgBase64
			? Ext.create('Ext.Img', {
					src: `data:image/jpg;base64,${imgBase64}`,
					alt: 'image preview',
					width: '100%'
			  })
			: null;
	});

	let modal = createModalPanel(
		Object.assign(
			{
				cls: 'edi-modal-preview',
				title: fileName,
				resizable: true,
				tools: [
					createTool({
						tooltip: edi.i18n.getMessage('action.export'),
						glyph: edi.constants.ICONS.FILE_DOWNLOAD,
						handler: function () {
							const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.ATTACHMENT, {
								documentId: fileId
							});
							edi.rest.downloadFile(confUrl || url, fileId);
						}
					})
				],
				width: MODAL_SIZE.widthLarge,
				items: images
			},
			modalConf ?? {}
		),
		true
	);

	modal.setHeight(window.innerHeight * 0.8);
	modal.show();
	modal.center();
	return modal;
};

export default createPreviewPanel;
