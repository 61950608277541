import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { EDI_SLSRPT_LINE_COLUMN } from '../EDI_SLSRPT/columns';
import { EDI_SLSFCT_DELIVERY_COLUMN } from './columns';
import { DOCUMENT_SLSFCT_LINE_DELIVERY_MODEL, DOCUMENT_SLSFCT_MODEL } from './models';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { MODAL_SIZE } from '@UIkit/components/modal';

/**
 * Class for pricat eancom details
 * @author Anastasiya Zholudeva
 */
Ext.namespace('edi.modules');
edi.modules['document.details.slsfct'] = function () {
	var moduleData;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates invoice form
	 * @returns {Object}
	 */
	var createDetailsPanel = function (documentData) {
		var docHeaderConfig = {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noModify: true,
			noSumm: true,
			noSignatures: true,
			noUsage: true,
			noPackage: true,
			noAperakStatus: true,
			noLinked: true
		};
		var headPanel = createDocumentHeaderPanel(moduleData.initData, docHeaderConfig);

		var headData1 = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.slsfct.report.number'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(
							documentData,
							'SalesForecastReport-Header.SalesForecastReportNumber'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.5,
					text: edi.utils.getObjectProperty(
						documentData,
						'SalesForecastReport-Header.SalesForecastReportDate'
					),
					valueLabel: true
				})
			]
		});

		var headData2 = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.slsfct.report.subscription'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.i18n.getMessage('document.slsfct.report.subscription.date', [
							edi.utils.getObjectProperty(
								documentData,
								'SalesForecastReport-Header.SubscriptionStartDate'
							),
							edi.utils.getObjectProperty(documentData, 'SalesForecastReport-Header.SubscriptionEndDate')
						]),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.slsfct.report.retail'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(documentData, 'SalesForecastReport-Header.Remarks'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				})
			]
		});

		var sender = createCompanySelector({
			labelConfig: {
				title: 'document.buyer',
				columnWidth: 0.1333
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			readOnly: true,
			is_valid: true,
			columnWidth: 0.8666,
			fieldValues: edi.utils.getObjectProperty(documentData, 'Document-Parties.Sender'),
			fieldsMap: {
				company_name: 'Name',
				company_iln: 'ILN'
			},
			valuesByMap: true
		});

		var receiver = createCompanySelector({
			labelConfig: {
				title: 'document.seller',
				columnWidth: 0.1333
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			readOnly: true,
			is_valid: true,
			columnWidth: 0.8666,
			fieldValues: edi.utils.getObjectProperty(documentData, 'Document-Parties.Receiver'),
			fieldsMap: {
				company_name: 'Name',
				company_iln: 'ILN'
			},
			valuesByMap: true
		});

		var twoColumnsLayout = createTwoColumnsLayout([headData1, receiver], [headData2, sender], 0.5);

		var lines = edi.utils.getObjectProperty(documentData, 'SalesForecastReport-Lines.Line', true);
		var linesData = [];
		if ('string' != typeof lines) {
			lines.forEach(function (line) {
				var values = line['Line-Item'];
				values.LineDelivery = [];
				values['Line-Delivery']?.forEach(function (lineDelivery) {
					lineDelivery.SalesForecast?.forEach(function (salesForecast) {
						var tmpLineDelivery = Ext.clone(lineDelivery);
						Ext.Object.merge(tmpLineDelivery, salesForecast);
						delete tmpLineDelivery.SalesForecast;
						values.LineDelivery.push(tmpLineDelivery);
					});
				});
				delete values['Line-Delivery'];
				linesData.push(values);
			});
		}

		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: DOCUMENT_SLSFCT_MODEL,
			gridColumnConfig: EDI_SLSRPT_LINE_COLUMN,
			data: linesData,
			margin: '16 0 0',
			readOnly: true,
			hasTotal: false,
			modalConf: {
				width: MODAL_SIZE.widthLarge,
				maxHeight: undefined
			},
			modalFormConfig: {
				title: 'pricat.product.modal.title',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.slsfct.plan.sales'),
						bodyPadding: 5,
						items: [
							{
								type: 'editableGrid',
								name: 'LineDelivery',
								config: {
									columnsConfig: EDI_SLSFCT_DELIVERY_COLUMN,
									fields: [],
									autoScroll: true,
									model: DOCUMENT_SLSFCT_LINE_DELIVERY_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										minHeight: 200
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('tab.title.additional'),
						items: [
							{
								title: edi.i18n.getMessage('document.slsfct.NameHierarchy1'),
								name: 'NameHierarchy1'
							},
							{
								title: edi.i18n.getMessage('document.slsfct.NameHierarchy2'),
								name: 'NameHierarchy2'
							},
							{
								title: edi.i18n.getMessage('document.slsfct.NameHierarchy3'),
								name: 'NameHierarchy3'
							},
							{
								title: edi.i18n.getMessage('document.slsfct.NameHierarchy4'),
								name: 'NameHierarchy4'
							}
						]
					}
				]
			}
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, productsGrid]
		});
	};

	/**
	 * Creates action panel
	 */
	var createModuleActionsPanel = function () {
		var data = moduleData.initData.data;
		var actionsPanel = createActionsPanel();
		var excludeActions = {};

		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.REFRESH] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PACKAGING] = true;

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			moduleData: moduleData,
			excludeActions: excludeActions
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					var documentData = data.data;
					modulePanel.add(createDetailsPanel(documentData));

					moduleData.tab.add(createModuleActionsPanel());
					moduleData.tab.add(modulePanel);
					if ('function' == typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					failure(data);
				}
			},
			failure
		);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
