const ROSEU_INVITATIONS_MODEL_CONFIG_NAME = 'ROSEU_INVITATIONS';
const ROSEU_INVITATION_RECEIVER_MODEL_CONFIG_NAME = 'ROSEU_INVITATION_RECEIVER';

edi.models.setModels({
	[ROSEU_INVITATIONS_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{ name: 'attributes' },
			{
				name: 'modifyDate',
				type: 'number'
			},
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'fromOrgFnsId',
				type: 'string'
			},
			{
				name: 'fromOrgName',
				type: 'string'
			},
			{
				name: 'fromOrgINN',
				type: 'string'
			},
			{
				name: 'toOrgFnsId',
				type: 'string'
			},
			{
				name: 'toOrgName',
				type: 'string'
			},
			{
				name: 'toOrgINN',
				type: 'string'
			},
			{
				name: 'state',
				type: 'string'
			}
		]
	},
	[ROSEU_INVITATION_RECEIVER_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'inn',
				type: 'string',
				convert: function (value, record) {
					let orgData = record.get('orgData');
					return orgData?.inn || '';
				}
			},
			{
				name: 'fnsId',
				type: 'string'
			},
			{
				name: 'kpp',
				type: 'string',
				convert: function (value, record) {
					let orgData = record.get('orgData');
					return orgData?.kpp || '';
				}
			}
		]
	}
});

export { ROSEU_INVITATIONS_MODEL_CONFIG_NAME, ROSEU_INVITATION_RECEIVER_MODEL_CONFIG_NAME };
