import { createAutocomplete, createCombo, createDateField } from '@UIkit/components/fields';
import { createFormContainer } from '@UIkit/components/panels';
import { createProxyConfig } from '@Components/storeComponents';
import { certificatesAPI } from './rest';
import { CERTIFICATES_MODEL } from './models';

const createFormItems = function (options) {
	let formItemsMap = {
		status: createCombo({
			fieldLabel: edi.i18n.getMessage('documents.column.cert.status'),
			name: 'state',
			store: edi.stores.createSimpleInlineStore(
				edi.constants.CERTIFICATES.STATES,
				function (id) {
					return edi.i18n.getMessage('certificate.status.' + id);
				},
				true
			)
		}),
		fullName: createAutocomplete(
			{
				fieldLabel: edi.i18n.getMessage('ediweb.certificates.fio.employee'),
				name: 'fullName',
				showQtips: true,
				minChars: 3,
				valueField: 'fullName',
				displayField: 'fullName',
				queryParam: 'fullName',
				filterField: 'fullName',
				allowManualInput: true
			},
			{
				proxy: createProxyConfig({
					type: 'ajax',
					url: options?.fullNameUrl ?? certificatesAPI.FULL_NAME.GET
				}),
				model: edi.models.getModel(CERTIFICATES_MODEL),
				autoLoad: false
			}
		)
	};
	let items = [
		createFormContainer({
			gap: [32, 16],
			area: [[4, 4]],
			items: [formItemsMap.status, formItemsMap.fullName]
		})
	];
	return {
		formItemsMap,
		items
	};
};

const createArgs = function (values) {
	edi.utils.clearEmptyValues(values);
	return values;
};

export { createFormItems, createArgs };
