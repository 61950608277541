import { createFieldBlock } from '@UIkit/components/panels';
import { createCheckbox, createField, createLabel } from '@Components/fields';
import { filterInvitationsErrorGrid, getCountErrorInvitation, renderCountError } from './utils';

const getErrorReportPanel = function (massInvitationsList, isInit, massInvitationsGrid) {
	const countErrorInvitation = getCountErrorInvitation(massInvitationsList);
	return createFieldBlock({
		padding: '0 0 8 0',
		layout: 'hbox',
		items: [
			createLabel({
				padding: '6 0 0 0',
				html: renderCountError(countErrorInvitation, isInit)
			}),
			createField({
				input: createCheckbox({
					boxLabel: edi.i18n.getMessage('show.only.error'),
					checked: false,
					disabled: countErrorInvitation === 0,
					listeners: {
						change: function (comp, value) {
							filterInvitationsErrorGrid(comp, value, massInvitationsGrid, []);
						}
					}
				}),
				containerConfig: {
					padding: '0 0 0 20'
				}
			})
		]
	});
};

export { getErrorReportPanel };
