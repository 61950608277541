const DOCUMENT_SLSFCT_MODEL = 'DOCUMENT_SLSFCT';
const DOCUMENT_SLSFCT_LINE_DELIVERY_MODEL = 'DOCUMENT_SLSFCT_LINE_DELIVERY';

edi.models.setModels({
	[DOCUMENT_SLSFCT_MODEL]: {
		fields: [
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'FinancialCode',
				type: 'string'
			},
			{
				name: 'FinancialName',
				type: 'string'
			},
			{
				name: 'NameHierarchy1',
				mapping: 'ItemGroup.NameHierarchy1',
				type: 'string'
			},
			{
				name: 'NameHierarchy2',
				mapping: 'ItemGroup.NameHierarchy2',
				type: 'string'
			},
			{
				name: 'NameHierarchy3',
				mapping: 'ItemGroup.NameHierarchy3',
				type: 'string'
			},
			{
				name: 'NameHierarchy4',
				mapping: 'ItemGroup.NameHierarchy4',
				type: 'string'
			},
			{
				name: 'LineDelivery',
				mapping: 'LineDelivery'
			}
		]
	},
	[DOCUMENT_SLSFCT_LINE_DELIVERY_MODEL]: {
		fields: [
			{
				name: 'Reference-Id',
				type: 'string'
			},
			{
				name: 'Reference-Type',
				type: 'string'
			}
		]
	}
});

export { DOCUMENT_SLSFCT_MODEL, DOCUMENT_SLSFCT_LINE_DELIVERY_MODEL };
