const DELJIT_LINE_MODEL = 'DELJIT_LINE';
const DELJIT_SUMMARY_MODEL = 'DELJIT_SUMMARY';
const DELJIT_ROW_SHIPMENT_CONDITION_MODEL = 'DELJIT_ROW_SHIPMENT_CONDITION';
const DELJIT_SHIPMENT_CONDITION_MODEL = 'DELJIT_SHIPMENT_CONDITION';

edi.models.setModels({
	[DELJIT_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'ItemType',
				type: 'string'
			},
			{
				name: 'OrderedQuantity',
				type: 'string'
			},
			{
				name: 'QuantityToBeDelivered',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'OrderedUnitNetPrice',
				type: 'string'
			},
			{
				name: 'SupplierUnitNetPrice',
				type: 'string'
			},
			{
				name: 'Discount',
				type: 'string'
			},
			{
				name: 'OrderedNetAmount',
				type: 'string'
			},
			{
				name: 'ExpirationDate',
				type: 'string'
			},
			{
				name: 'ItemStatusCode',
				type: 'string'
			},
			{
				name: 'ExpectedDeliveryDate',
				type: 'string'
			},
			{
				name: 'MaxExpirationDate',
				type: 'string'
			},
			{
				name: 'BuyerLocationCode',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'OrderedUnitGrossPrice',
				type: 'string'
			},
			{
				name: 'OrderedTaxAmount',
				type: 'string'
			},
			{
				name: 'OrderedGrossAmount',
				type: 'string'
			},
			{
				name: 'Shipment-Condition'
				//type: 'array'
			}
		],
		idProperty: 'LineNumber'
	},
	[DELJIT_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'TotalLines',
				type: 'number'
			},
			{
				name: 'TotalOrderedAmount',
				type: 'string'
			},
			{
				name: 'TotalNetAmount',
				type: 'float'
			},
			{
				name: 'TotalGrossAmount',
				type: 'float'
			},
			{
				name: 'TotalTaxAmount',
				type: 'string'
			},
			{
				name: 'TotalNetWeight',
				type: 'string'
			},
			{
				name: 'TotalGrossWeight',
				type: 'string'
			},
			{
				name: 'TotalPalletAmount',
				type: 'string'
			}
		]
	},
	[DELJIT_ROW_SHIPMENT_CONDITION_MODEL]: {
		fields: [
			{
				name: 'CumulativeQuantity',
				type: 'string'
			},
			{
				name: 'QuantityToBeDelivered',
				type: 'string'
			},
			{
				name: 'ExpectedDespatchDateTime',
				type: 'string'
			},
			{
				name: 'RequestedDeliveryDateTime',
				type: 'string'
			},
			{
				name: 'ExpectedDespatchDate',
				type: 'string',
				convert: function (value, record) {
					var dateTime = (record.get('ExpectedDespatchDateTime') || '').split(' ');
					return dateTime.length === 2 ? dateTime[0] : value;
				}
			},
			{
				name: 'ExpectedDespatchTime',
				type: 'string',
				convert: function (value, record) {
					var dateTime = (record.get('ExpectedDespatchDateTime') || '').split(' ');
					return dateTime.length === 2 ? dateTime[1] : value;
				}
			},
			{
				name: 'RequestedDeliveryDate',
				type: 'string',
				convert: function (value, record) {
					var dateTime = (record.get('RequestedDeliveryDateTime') || '').split(' ');
					return dateTime.length === 2 ? dateTime[0] : value;
				}
			},
			{
				name: 'RequestedDeliveryTime',
				type: 'string',
				convert: function (value, record) {
					var dateTime = (record.get('RequestedDeliveryDateTime') || '').split(' ');
					return dateTime.length === 2 ? dateTime[1] : value;
				}
			}
		]
	},
	[DELJIT_SHIPMENT_CONDITION_MODEL]: {
		fields: [
			{
				name: 'CumulativeQuantity',
				type: 'string'
			},
			{
				name: 'QuantityToBeDelivered',
				type: 'string'
			},
			{
				name: 'ExpectedDespatchDateTime',
				type: 'string',
				convert: function (value, record) {
					if (!value) {
						var date = record.get('ExpectedDespatchDate'),
							time = record.get('ExpectedDespatchTime');
						value = date && time ? date + ' ' + time : value;
					}
					return value;
				}
			},
			{
				name: 'RequestedDeliveryDateTime',
				type: 'string',
				convert: function (value, record) {
					if (!value) {
						var date = record.get('RequestedDeliveryDate'),
							time = record.get('RequestedDeliveryTime');
						value = date && time ? date + ' ' + time : value;
					}
					return value;
				}
			},
			{
				name: 'ExpectedDespatchDate',
				type: 'string'
			},
			{
				name: 'ExpectedDespatchTime',
				type: 'string'
			},
			{
				name: 'RequestedDeliveryDate',
				type: 'string'
			},
			{
				name: 'RequestedDeliveryTime',
				type: 'string'
			}
		]
	}
});

export {
	DELJIT_LINE_MODEL,
	DELJIT_SUMMARY_MODEL,
	DELJIT_ROW_SHIPMENT_CONDITION_MODEL,
	DELJIT_SHIPMENT_CONDITION_MODEL
};
