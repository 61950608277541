/**
 * Created by Дарья on 29.06.2017.
 */

Ext.namespace('edi.selectors');

/**
 * Create UPD fields map
 * @param       fieldsMapPrefix     Fields map prefix
 * @param       isDetails           True to create map for UPD details document
 * @param       isNewUPD
 * @return      {Object}
 */
edi.selectors.getTorg2FieldsMap = function (fieldsMapPrefix, isNewTorg2) {
	fieldsMapPrefix = fieldsMapPrefix + '.';

	var company = fieldsMapPrefix + 'identification.organizationInfo.legalEntity.',
		individual = fieldsMapPrefix + 'identification.individualPerson.',
		rusAddress = fieldsMapPrefix + 'address.addressRu.',
		foreignAddr = fieldsMapPrefix + 'address.addressInfo.',
		contact = fieldsMapPrefix + 'contact.',
		bank = fieldsMapPrefix + 'bankInfo.',
		foreigner = fieldsMapPrefix + 'identification.organizationInfo.informationAboutForeigner.',
		physical = fieldsMapPrefix + 'identification.individual.';

	var map = {
		company_name: company + 'orgName',
		company_inn: company + 'tin',
		company_kpp: company + 'kpp',
		company_okpo: fieldsMapPrefix + 'okpo',
		structural_subdivision: fieldsMapPrefix + 'structuralSubdivision',
		fns_id: 'fake_field.fnsId',

		individual_inn: individual + 'tin',
		individual_lastname: individual + 'name.lastName',
		individual_firstname: individual + 'name.firstName',
		individual_patronymicname: individual + 'name.middleName',
		individual_certificateRegistrationIP:
			fieldsMapPrefix + 'identification.individualPerson.certificateRegistrationIP',

		foreign_name: foreigner + 'orgName',
		foreign_info: foreigner + 'additInfo',

		addr_rus_zip: rusAddress + 'index',
		addr_rus_region: rusAddress + 'regionCode',
		addr_rus_county: rusAddress + 'area',
		addr_rus_city: rusAddress + 'city',
		addr_rus_street: rusAddress + 'street',
		addr_rus_community: rusAddress + 'settlement',
		addr_rus_home: rusAddress + 'house',
		addr_rus_block: rusAddress + 'housing',
		addr_rus_flat: rusAddress + 'flat',

		addr_for_country: foreignAddr + 'codeOfCountry',
		addr_for_text: foreignAddr + 'addressText',

		contact_phone: contact + 'telephone',
		contact_email: contact + 'mail',
		contact_fax: contact + 'fax',

		bank_corr_acc: bank + 'bank.coreAccountNumber',
		bank_acc: bank + 'accountNumber',
		bank_name: bank + 'bank.bankName',
		bank_id: bank + 'bank.bankCode',

		address_type: fieldsMapPrefix + 'address_type',
		type: true
	};

	if (isNewTorg2 == true) {
		Object.assign(map, {
			foreign_identifier: foreigner + 'identifier',

			physical_lastname: physical + 'name.lastName',
			physical_firstname: physical + 'name.firstName',
			physical_patronymicname: physical + 'name.middleName',
			physical_inn: physical + 'tin',
			physical_requisites: physical + 'requisites',
			physical_additInfo: physical + 'additInfo',

			info_for_members: fieldsMapPrefix + 'infoForMembers',
			short_name: fieldsMapPrefix + 'shortName',

			addr_code_gar: fieldsMapPrefix + 'address.codeStateAddressRegister'
		});
	}

	return map;
};

/**
 * Create UPD fields map
 * @param       fieldsMapPrefix     Fields map prefix
 * @param       isDetails           True to create map for UPD details document
 * @param       isNewUPD
 * @return      {Object}
 */
edi.selectors.getUPDFieldsMap = function (fieldsMapPrefix, isDetails, isNewUPD, isNewUKD) {
	isNewUKD = isNewUKD || false;
	fieldsMapPrefix = fieldsMapPrefix + '.';

	var company = fieldsMapPrefix + 'identification.legalEntity.',
		individual = fieldsMapPrefix + 'identification.individualPerson.',
		rusAddress = fieldsMapPrefix + 'address.addressRu.',
		foreignAddr = fieldsMapPrefix + 'address.addressInfo.',
		contact = fieldsMapPrefix + 'contact.',
		bank = fieldsMapPrefix + 'bankInfo.',
		foreigner = fieldsMapPrefix + 'identification.informationAboutForeigner.',
		physical = fieldsMapPrefix + 'identification.individualParticipant.';

	if (isNewUPD && isNewUPD == '5.02') {
		var notregistered = fieldsMapPrefix + 'identification.informationAboutLegalForeigner.';
		foreigner = fieldsMapPrefix + 'identification.informationAboutForeigner.';
	}

	if (isNewUKD) {
		foreigner = fieldsMapPrefix + 'identification.informationAboutForeigner.';
	}

	var map = {
		company_name: company + 'orgName',
		company_inn: company + 'tin',
		company_kpp: company + 'kpp',
		company_okpo: fieldsMapPrefix + 'okpo',
		structural_subdivision: fieldsMapPrefix + 'structuralSubdivision',

		individual_inn: individual + 'tin',
		individual_lastname: individual + 'name.lastName',
		individual_firstname: individual + 'name.firstName',
		individual_patronymicname: individual + 'name.middleName',
		individual_certificateRegistrationIP:
			fieldsMapPrefix + 'identification.individualPerson.certificateRegistrationIP',

		foreign_name: foreigner + 'name',
		foreign_info: foreigner + 'additionalInfo',

		addr_rus_zip: rusAddress + 'index',
		addr_rus_region: rusAddress + 'regionCode',
		addr_rus_county: rusAddress + 'area',
		addr_rus_city: rusAddress + 'city',
		addr_rus_street: rusAddress + 'street',
		addr_rus_community: rusAddress + 'settlement',
		addr_rus_home: rusAddress + 'house',
		addr_rus_block: rusAddress + 'housing',
		addr_rus_flat: rusAddress + 'flat',

		addr_for_country: foreignAddr + 'codeOfCountry',
		addr_for_text: foreignAddr + 'addressText',

		contact_phone: contact + 'telephone',
		contact_email: contact + 'mail',
		contact_fax: contact + 'fax',

		bank_corr_acc: bank + 'bank.coreAccountNumber',
		bank_acc: bank + 'accountNumber',
		bank_name: bank + 'bank.bankName',
		bank_id: bank + 'bank.bankCode',

		address_type: true,
		type: true
	};

	if (isDetails) {
		Object.assign(map, {
			addr_rus_flat: rusAddress + 'flatNumber'
		});
	}

	if (isNewUPD == true) {
		Object.assign(map, {
			foreign_identifier: foreigner + 'identifier',

			physical_lastname: physical + 'name.lastName',
			physical_firstname: physical + 'name.firstName',
			physical_patronymicname: physical + 'name.middleName',
			physical_inn: physical + 'tin',
			physical_requisites: physical + 'requisites',
			physical_additInfo: physical + 'additInfo',

			info_for_members: fieldsMapPrefix + 'infoForMembers',
			short_name: fieldsMapPrefix + 'shortName',

			addr_code_gar: fieldsMapPrefix + 'address.codeStateAddressRegister',
			address_type: fieldsMapPrefix + 'address_type'
		});
	} else if (isNewUPD && isNewUPD == '5.02') {
		Object.assign(map, {
			notregistered_name: notregistered + 'name',
			notregistered_info: notregistered + 'additionalInfo'
		});
	}

	return map;
};

/**
 * Returns UPD's company selector modalConf merged with additionalConf
 * @param	{Object}	[additionalConf]
 * @returns	{Object}	modalConf
 */
edi.selectors.getFnsUpdCompanyModalConf = function (additionalConf) {
	var modalAdditionalConf = {
		main: {
			company_okpo: null,
			company_gln: null,
			individual_gln: {
				allowBlank: true
			},
			individual_inn: {
				allowBlank: false
			}
		},
		address: {
			block: {
				maxLength: 20
			}
		},
		bank: {
			bank_acc: {
				allowBlank: true
			},
			bank_corr_acc: {
				maxLength: 20
			},
			bank_id: {
				regex: edi.constants.VALIDATORS.BIK,
				invalidText: edi.i18n.getMessage('invalid.value.bank.id'),
				listeners: {
					change: function (cmp, newValue) {
						edi.methods.fillBankNameByBik(cmp, newValue);
					}
				}
			},
			bank_name: {
				maxLength: 1000
			}
		},
		contact: {
			fax: null
		}
	};
	let conf = edi.selectors.getFNSModalConf(modalAdditionalConf, {
		main: {
			company_data: {
				fields: {
					okpo: {
						name: 'company_okpo',
						title: 'company.okpo',
						emptyText: 'company.okpo',
						regex: edi.constants.VALIDATORS.OKPO,
						maskRe: /\d/i
					}
				}
			}
		}
	});
	return Ext.merge(Ext.clone(conf), additionalConf);
};
