Ext.namespace('edi.permissions');
edi.permissions.setUiPermissions(
	{
		READ_DOCUMENTS_REST_SERVICES: 'READ_DOCUMENTS_REST_SERVICES'
	},
	{
		CREATE_DOCUMENTS: {
			OR: ['CREATE_DSF_NDA', 'CREATE_DSF_SUPAGREEMENT_ANNUL', 'CREATE_DSF_AKTPRM']
		},
		READ_DOCUMENTS: {
			OR: ['READ_DSF_NDA', 'READ_DSF_SUPAGREEMENT_ANNUL', 'READ_DSF_AKTPRM']
		},
		CREATE_DSF: {
			OR: [
				'CREATE_DSF_NDA',
				'CREATE_DSF_SUPAGREEMENT_ANNUL',
				'CREATE_DSF_AKTPRM',

				'CREATE_DSF_SUPPLEMENTARY_AGREEMENT',
				'CREATE_DSF_INVOICE'
			]
		},
		EDIT_DSF: {
			OR: ['EDIT_DSF_NDA', 'EDIT_DSF_SUPAGREEMENT_ANNUL', 'EDIT_DSF_AKTPRM']
		},
		READ_DSF: {
			OR: [
				'READ_DSF_NDA',
				'READ_DSF_SUPAGREEMENT_ANNUL',
				'READ_DSF_AKTPRM',
				'READ_DSF_ONBOARDING_CP',
				'READ_DSF_ONBOARDING_FP',
				'READ_DSF_ONBOARDING_FP02',
				'READ_DSF_ONBOARDING_DC1',
				'READ_DSF_ONBOARDING_DC2',
				'READ_DSF_ONBOARDING_DC3',
				'READ_DSF_ONBOARDING_DC4',
				'READ_DSF_ONBOARDING_DC5',
				'READ_DSF_ONBOARDING_DC6'
			]
		},
		REVOKE_INVITATION: 'CLIENT_INVITATION_REVOKE',
		COMPLETE_INVITATION: 'CLIENT_INVITATION_COMPLETE',
		REJECT_INVITATION: 'CLIENT_INVITATION_REJECT',
		UPDATE_INVITATION: 'CLIENT_INVITATION_EDIT',
		DELETE_INVITATION: 'CLIENT_INVITATION_DELETE',
		CREATE_INVITATION: 'CLIENT_INVITATION_CREATE',
		SENT_INVITATION: 'CLIENT_INVITATION_CREATE',
		DELETE_MASS_INVITATION: 'CLIENT_MASS_INVITATION_DELETE',

		SIGN_EDI_FNS_UPD: {
			OR: ['SIGN_EDI_FNS_UPD_SCHF_WITHOUT_OPERATOR', 'SIGN_EDI_FNS_UPD_SCHFDOP_WITHOUT_OPERATOR_CONSUMER']
		},
		SIGN_EDI_FNS_UKD: {
			OR: ['SIGN_EDI_FNS_UKD_KSCHF_WITHOUT_OPERATOR', 'SIGN_EDI_FNS_UKD_KSCHF_DIS_WITHOUT_OPERATOR_CONSUMER']
		}
	}
);
