import { createCompanySelector } from '@Components/company.selector.js';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createField,
	createFile,
	createLabel,
	createTextField
} from '@Components/fields';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createCreateSaveButton } from '@Components/buttons';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createButtonContainer, createContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

/**
 * @author Vsevolod Liapin
 * Class for creation FORMALIZED_DOCUMENT document
 */
Ext.namespace('edi.modules');
edi.modules['document.create.formalized'] = function () {
	var moduleData,
		docHeader,
		id,
		isEdit,
		isCopy,
		receiverId,
		fileValue,
		subTypes = [],
		userData = edi.core.getUserData(),
		receiver,
		sender,
		createButton,
		initialData,
		uploadForm,
		maxFileSize = 10,
		createAndSendButton,
		form,
		parentId,
		isNumberAutoGenerated,
		documentData,
		subTypeCombo;
	let customFieldsObj,
		twoColumnsLayout,
		customFieldsContainer,
		topPath = '//FormalizedDocument/';

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docHeader = moduleData.initData.data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	let initCustomFields = function () {
		let subDocType = subTypeCombo.getValue();
		let fromOrgId = docHeader?.fromOrg?.id || sender.getValues()?.id;
		let toOrgId = docHeader?.toOrg?.id || receiverId || receiver.getValues()?.id || receiver.selectedOrg?.id;

		if (subDocType && fromOrgId && toOrgId) {
			edi.methods.custom_fields.initCustomFields({
				customFieldsObj,
				docType: edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
				subDocType,
				docId: docHeader?.id,
				toOrgId: toOrgId,
				fromOrgId: fromOrgId,
				container: customFieldsContainer,
				topPath: topPath,
				finishCallback(obj) {
					customFieldsObj = obj;
					form.isValid();
				},
				fail() {
					form.isValid();
				}
			});
		}
	};

	/**
	 * Creates order create/edit form
	 * @param   {Object}    document    order data for editing, undefined for new order
	 * @returns {Object}
	 */
	var createModuleForm = function (document) {
		var i,
			dateValue = edi.utils.getObjectProperty(document, 'date');
		var isValidOrganization = function (values) {
			var isValidIndividual =
					values['individual_firstname'] && values['individual_lastname'] && values['individual_inn'],
				isValidAddress = values['addr_rus_region'] || values['addr_for_country'],
				isValidLegal = values['company_name'] && values['company_inn'],
				isValidBank =
					(values['bank_acc'] && values['bank_name']) || (!values['bank_acc'] && !values['bank_name']);
			return !!(isValidAddress && values['company_iln'] && (isValidLegal || isValidIndividual) && isValidBank);
		};

		var numberDocument = createTextField({
			margin: '0 0 0 5',
			emptyText: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('nr'),
			columnWidth: 0.2,
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			value: docHeader ? docHeader.number : undefined,
			maxLength: 35,
			name: 'number'
		});

		var headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.data'),
					input: (subTypeCombo = createCombo({
						emptyText: edi.i18n.getMessage('document.type'),
						columnWidth: 0.67,
						allowBlank: false,
						name: 'doctype',
						store: edi.stores.createSimpleInlineStore(subTypes, function (id) {
							return edi.i18n.getMessage('documents.subdoctype.' + id);
						}),
						value: isEdit || isCopy ? edi.utils.getObjectProperty(document, 'doctype') : undefined,
						autoSelect: isEdit || isCopy,
						listeners: {
							change: initCustomFields
						}
					})),
					mandatory: false,
					containerConfig: {
						columnWidth: 0.6
					}
				}),
				numberDocument,
				createDateField({
					emptyText: edi.i18n.getMessage('date'),
					margin: '0 0 0 5',
					columnWidth: 0.2,
					allowBlank: false,
					value:
						docHeader?.doctime && !isCopy
							? edi.utils.formatDate(docHeader.doctime, edi.constants.DATE_FORMAT.FNS)
							: new Date(),
					name: 'date'
				})
			]
		});

		var autogenCheckbox = isNumberAutoGenerated
			? createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					items: [
						createField({
							title: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
							input: createCheckbox({
								columnWidth: 0.8,
								inputValue: true,
								checked: isNumberAutoGenerated,
								name: 'numberAutoGenerated',
								listeners: {
									change: function (checkbox, isChecked) {
										numberDocument.setEmptyText(
											isChecked
												? edi.i18n.getMessage('document.generation.numbers.text')
												: edi.i18n.getMessage('nr')
										);
										numberDocument.setValue('');
										numberDocument.setDisabled(isChecked);
										numberDocument.allowBlank = isChecked;
										numberDocument.validate();
									}
								}
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
			  })
			: null;

		var fieldValues = edi.converters.convertOrgToPartie(userData.org),
			isValidSender = isValidOrganization(fieldValues);

		var buyers = edi.relations.getRelations();
		if (document) {
			var buyerRelation = null;
			for (i = 0; i < buyers.length; i++) {
				if (buyers == buyers[i].iln) {
					buyerRelation = buyers[i];
					break;
				}
			}
		}

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				useHiddenFields: true,
				disableAutoValidation: true,
				fieldValues: isEdit || isCopy ? document : null,
				selectedOrgValues: isEdit || isCopy ? document : null,
				disableCommonAddressRenderer: true,
				disableCommonAddressTitleRenderer: true,
				forceChangeFields: {
					bank_id: true,
					bank_corr_acc: true,
					bank_name: true,
					bank_acc: true
				}
			});
			return createCompanySelector(selectorConfig);
		};

		var bankFieldListeners = {
			regex: edi.constants.VALIDATORS.BANK_ACC,
			invalidText: edi.i18n.getMessage('invalid.value.bank.acc'),
			allowBlank: true
		};
		var modalAdditionalConf = {
			main: {
				company_okpo: null,
				company_inn_kpp: {
					fields: {
						kpp: {
							allowBlank: true
						}
					}
				},
				individual_inn: {
					allowBlank: false
				}
			},
			bank: {
				bank_acc_select: Ext.applyIf(
					{
						fieldConfig: {
							listeners: {
								render: (cmp) =>
									edi.selectors.fieldControls.setBankNameAllowBlankByBankAccValue({
										bankAccField: cmp
									}),
								change: (cmp, newValue) =>
									edi.selectors.fieldControls.setBankNameAllowBlankByBankAccValue({
										bankAccField: cmp,
										bankAccValue: newValue
									})
							}
						}
					},
					bankFieldListeners
				),
				bank_corr_acc: bankFieldListeners,
				bank_id: Ext.applyIf(
					{
						regex: edi.constants.VALIDATORS.BIK,
						invalidText: edi.i18n.getMessage('invalid.value.bank.id'),
						listeners: {
							change: function (cmp, newValue) {
								edi.methods.fillBankNameByBik(cmp, newValue);
							}
						}
					},
					bankFieldListeners
				),
				bank_name: {
					listeners: {
						render: function (cmp) {
							var modal = cmp.modalInstance,
								form = modal ? modal.formPanel : null,
								value = cmp.getValue();
							if (!form) {
								return;
							}
							var field = form.down('textfield[name=bank_acc]');
							field.allowBlank = !value.length;
							field.validate();
						},
						change: function (cmp, newValue) {
							var modal = cmp.modalInstance,
								form = modal ? modal.formPanel : null;
							if (!form) {
								return;
							}
							var field = form.down('textfield[name=bank_acc]');
							field.allowBlank = !newValue.length;
							field.validate();
						}
					}
				}
			}
		};

		let senderFieldsMap = edi.selectors.getFormalizedDocumentFieldsMap('sender');
		delete senderFieldsMap.addr_category;

		sender = createCS({
			title: 'document.sender',
			allowBlank: false,
			readOnly: isEdit,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: docHeader?.fromOrg?.id || userData.org?.id
			}),
			fieldValues: isEdit || isCopy ? document : fieldValues,
			selectedOrgValues: isEdit || isCopy ? document : fieldValues,
			is_valid: isEdit || isCopy || isValidSender,
			valuesByMap: isEdit || isCopy,
			callback: function (values) {
				sender.is_valid = isValidOrganization(values);
				checkValid();
			},
			fieldsMap: senderFieldsMap,
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf)
		});

		let receiverFieldsMap = edi.selectors.getFormalizedDocumentFieldsMap('receiver');
		delete receiverFieldsMap.addr_category;

		receiver = createCS({
			title: 'document.receiver',
			relationsOnly: true,
			allowBlank: false,
			valuesByMap: true,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: docHeader?.toOrg?.id
			}),
			readOnly: isEdit,
			relations: edi.relations.getRelations(),
			is_valid: isEdit || isCopy,
			callback: function (values, org) {
				receiver.is_valid = isValidOrganization(values);
				receiverId = org ? org.id : null;
				initCustomFields();
				checkValid();
			},
			fieldsMap: receiverFieldsMap,
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf)
		});

		var isAttachmentMandatory = true;
		if (isEdit) {
			isAttachmentMandatory = !document || !document.file || !document.file.id;
		}
		uploadForm = createForm({
			submitEmptyText: false,
			items: [
				createPanel({
					layout: 'column',
					margin: '0 0 5',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createField({
							title: edi.i18n.getMessage('document.file'),
							input: createFile(
								{
									columnWidth: 0.7
								},
								{
									//emptyText: isEdit
									//	? edi.utils.getObjectProperty(document, 'file.fileName')
									//	: edi.i18n.getMessage('document.file'),
									name: 'attachment',
									allowBlank: !isAttachmentMandatory,
									listeners: {
										afterrender: function (cmp) {
											cmp.fileInputEl.set({
												accept: '.xml'
											});
										},
										reset: function (cmp) {
											cmp.fileInputEl.set({
												accept: '.xml'
											});
										},
										change: function (cmp) {
											var indexOfPeriod = cmp.getValue().lastIndexOf('.'),
												uploadedExtension = cmp
													.getValue()
													.substr(indexOfPeriod + 1, cmp.getValue().length - indexOfPeriod);
											if (uploadedExtension && 'xml' !== uploadedExtension.toLowerCase()) {
												edi.core.showWarn(
													edi.i18n.getMessage('controller.file.not.allowed.extension', {
														0: uploadedExtension.toLowerCase()
													})
												);
												cmp.reset();
											}
										}
									}
								}
							),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				}),
				createPanel({
					layout: 'column',
					margin: '0 0 20',
					cls: 'edi-create-field-line',
					items: [
						{
							xtype: 'container',
							html: '<br/>',
							columnWidth: 0.3
						},
						createLabel({
							columnWidth: 0.7,
							text: edi.i18n.getMessage('document.dsf.upload.file.limit.text.dynamic', {
								limit: maxFileSize
							})
						})
					]
				})
			]
		});

		var commentInput = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('remarks'),
					input: createTextField({
						columnWidth: 0.7,
						name: 'comment',
						maxLength: 255,
						isTextarea: true,
						valueSrc: document,
						height: 200
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var receiverSignatureField = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('column.expected.recipients.signature.under.document'),
					input: createCheckbox({
						name: 'reference.receiverSignatureExpected',
						columnWidth: 0.7,
						checked:
							isEdit || isCopy
								? edi.utils.getObjectProperty(document, 'reference.receiverSignatureExpected') == 'true'
								: document,
						inputValue: true,
						uncheckedValue: false
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var receiverIzvipolExpectedField = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.formalized.field.receiver.izvipol.expected'),
					input: createCheckbox({
						name: 'reference.receiverIzvpolExpected',
						columnWidth: 0.7,
						checked:
							isEdit || isCopy
								? edi.utils.getObjectProperty(document, 'reference.receiverIzvpolExpected') == 'true'
								: document,
						inputValue: true,
						uncheckedValue: false
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		createButton = createCreateSaveButton(
			{
				formBind: true,
				disabled: true,
				handler: function () {
					var form = this.up('form').getForm();
					if (form.isValid()) {
						var values = edi.utils.collectFormValues(form);
						edi.utils.clearEmptyValues(values);
						!values.filename && fileValue
							? (values.filename = edi.utils.base64.encode(fileValue.name))
							: null;
						save(values);
					}
				}
			},
			isEdit
		);

		twoColumnsLayout = createTwoColumnsLayout(
			[headData, autogenCheckbox, sender, receiver],
			[uploadForm, commentInput, receiverSignatureField, receiverIzvipolExpectedField]
		);

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		form = createForm({
			cls: 'edi-form document-add-form',
			autoScroll: true,
			bodyPadding: 10,
			items: [twoColumnsLayout, createCustomFieldsContainer()],
			buttons: createButtonContainer({
				items: [createButton]
			})
		});

		form.on('validitychange', checkValid);
		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};
	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isSenderValid = sender.isValid(),
			isReceiverValid = receiver.isValid();
		return isReceiverValid && isSenderValid;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var valid = false;
		var validCompanies = validateCompanySelectors();
		var hasInvalid = form.hasInvalidField();
		if (!createButton.disabled && (hasInvalid || !validCompanies)) {
			createButton.setDisabled(true);
			createAndSendButton ? createAndSendButton.setDisabled(true) : null;
		} else if (createButton.disabled && !hasInvalid && validCompanies) {
			createButton.setDisabled(false);
			createAndSendButton ? createAndSendButton.setDisabled(false) : null;
		}
		if (!hasInvalid && validCompanies) {
			valid = true;
		}
		return valid;
	};
	var buildDocument = function (formValues, fileId) {
		if (fileId) {
			formValues['file'] = fileId;
		} else if (fileValue) {
			formValues['file'] = fileValue.id;
		}
		delete formValues.numberAutoGenerated;

		edi.utils.clearEmptyValues(formValues);

		return formValues;
	};
	/**
	 * Saves deljit
	 * @param {Object}    formValues    values from form
	 */
	var save = function (formValues) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var numberAutoGenerated = formValues.numberAutoGenerated;
		delete formValues.numberAutoGenerated;

		let customFieldsData = edi.methods.custom_fields.getCustomFieldsData(
			customFieldsObj,
			formValues,
			null,
			topPath
		);
		Object.keys(customFieldsData).forEach(function (key) {
			delete formValues[key];
		});

		var onUploaded = function (fileId) {
			moduleData.tab.setLoading(edi.i18n.getMessage('dsf.create.saving'));

			var document = buildDocument(formValues, fileId);
			delete document['true'];
			document = edi.document.actions.mergeDataBeforeSave(initialData, document);

			var headerData = {
				data: Ext.encode(document),
				date: document.date,
				dateFormat: edi.constants.DATE_FORMAT.CLIENT
			};
			if (customFieldsData && Object.keys(customFieldsData).length > 0) {
				headerData.customFields = customFieldsData;
			}

			if (isNumberAutoGenerated && numberAutoGenerated) {
				headerData.autogen = true;
			} else {
				headerData.number = formValues.number;
			}

			var success = edi.document.actions.createSaveSuccessHandler(
				moduleData,
				isEdit ? id : undefined,
				undefined,
				isEdit
			);
			var failure = edi.document.actions.createSaveErrorHandler(
				isEdit,
				moduleData,
				edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
				function () {
					createButton.setDisabled(false);
				}
			);

			edi.document.actions.processDocument(
				receiverId,
				null,
				edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
				parentId,
				isEdit ? id : undefined,
				success,
				failure,
				headerData
			);
		};

		var failure = function (data) {
			edi.core.logMessage(
				'Error uploading dsf file' + (data && data.status ? ' status - ' + data.status : ''),
				'warn'
			);
			edi.core.showError(
				edi.utils.formatComplexServerError(
					data,
					data && data.status ? 'error.server.' + data.status : 'dsf.upload.failure'
				),
				function () {
					moduleData.tab.setLoading(false);
					createButton.setDisabled(false);
				}
			);
		};

		var fileCmp = uploadForm.getForm().findField('attachment');
		if (!fileCmp || !fileCmp.getValue()) {
			onUploaded(null);
		} else {
			edi.core.submitUploadForm(
				uploadForm,
				edi.rest.services.DSF.FILE.POST,
				'dsf.create.saving',
				function (responseData) {
					if (responseData && responseData.data && responseData.data.id) {
						onUploaded(responseData.data.id);
					} else {
						failure(responseData);
					}
				},
				failure
			);
		}
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var continueLoading = function () {
			var startCallBack = function () {
				var modulePanel = createAddModulePanel({
					items: [createModuleForm(documentData)]
				});

				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);

				if (isEdit) {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
					moduleData.tab.updateLayout();
					initCustomFields();
				} else {
					receiver.presetFromRelation(function () {
						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					});
				}
			};

			if (edi.permissions.hasPermission('CLIENT_READ_DOCUMENT_SUBTYPE')) {
				edi.rest.sendRequest(
					edi.rest.services.DOCUMENTS.SUB_DOC_TYPES.GET,
					'GET',
					{
						doctype: edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT
					},
					function (data) {
						if (data && data.items && data.items.length) {
							subTypes = data.items.map(function (item) {
								return item.subtype;
							});
						}
					},
					failure,
					startCallBack
				);
			} else {
				startCallBack();
			}
		};

		if (docHeader && docHeader.id) {
			id = docHeader.id;
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
					documentId: docHeader.id
				}),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						initialData = data.data;
						documentData = data.data;

						fileValue = {
							id: edi.utils.getObjectProperty(documentData, 'file.id'),
							name: edi.utils.getObjectProperty(documentData, 'file.realName')
						};
						continueLoading();
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET,
					'GET',
					{},
					function (data) {
						if (data && data.items) {
							for (var i = 0; i < data.items.length; i++) {
								var numberingRule = data.items[i];
								if (
									numberingRule.typeDoc == edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT &&
									'ALLOW' == numberingRule.policy
								) {
									isNumberAutoGenerated = true;
									break;
								}
							}
							continueLoading();
						} else {
							failure(data);
						}
					},
					failure
				);
			} else {
				continueLoading();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
