const EDI_SLSRPT_LINE_MODEL = 'EDI_SLSRPT_LINE';
const EDI_SLSRPT_REFERENCE_ELEMENTS_MODEL = 'EDI_SLSRPT_REFERENCE_ELEMENTS';

edi.models.setModels({
	[EDI_SLSRPT_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'SenderItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'ReturnQuantity',
				type: 'string'
			},
			{
				name: 'StatisticalSales',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'Line-Item'
			},
			{
				name: 'LocationParty'
			},

			{
				name: 'Line-Reference'
			},
			{
				name: 'Reference-Elements'
			},
			{
				name: 'Reference-Element'
			}
		],
		idProperty: 'LineNumber'
	},
	[EDI_SLSRPT_REFERENCE_ELEMENTS_MODEL]: {
		fields: [
			{
				name: 'Reference-Id',
				type: 'string'
			},
			{
				name: 'Reference-Type',
				type: 'string'
			}
		]
	}
});

export { EDI_SLSRPT_LINE_MODEL, EDI_SLSRPT_REFERENCE_ELEMENTS_MODEL };
