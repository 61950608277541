import { stateRenderer } from './moduleCommons';

const ROSEU_INVITATIONS_COLUMNS_CONFIG_NAME = 'ROSEU_INVITATIONS';
const ROSEU_INVITATION_RECEIVER_COLUMNS_CONFIG_NAME = 'ROSEU_INVITATION_RECEIVER';

edi.columns.addColumns({
	[ROSEU_INVITATIONS_COLUMNS_CONFIG_NAME]: {
		id: {
			text: 'documents.column.id',
			sortable: false,
			flex: 1,
			dataIndex: 'id'
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			sortable: true,
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			sortable: true,
			flex: 1,
			renderer: 'dateTimeFromMs'
		},
		fromOrgFnsId: {
			text: 'roseu_invitations.from_org_fns_id',
			dataIndex: 'attributes',
			sortable: false,
			flex: 1,
			renderer(attributes) {
				return edi.utils.getAttributeByName(attributes, 'SENDER_FNSID');
			}
		},
		fromOrgName: {
			text: 'roseu_invitations.from_org_name',
			dataIndex: 'attributes',
			sortable: false,
			flex: 1,
			renderer(attributes) {
				return edi.utils.getAttributeByName(attributes, 'SENDER_NAME');
			}
		},
		fromOrgINN: {
			text: 'roseu_invitations.from_org_inn',
			dataIndex: 'attributes',
			sortable: false,
			flex: 1,
			renderer(attributes) {
				return edi.utils.getAttributeByName(attributes, 'SENDER_INN');
			}
		},
		toOrgFnsId: {
			text: 'roseu_invitations.to_org_fns_id',
			dataIndex: 'attributes',
			sortable: false,
			flex: 1,
			renderer(attributes) {
				return edi.utils.getAttributeByName(attributes, 'RECEIVER_FNSID');
			}
		},
		toOrgName: {
			text: 'roseu_invitations.to_org_name',
			dataIndex: 'attributes',
			sortable: false,
			flex: 1,
			renderer(attributes) {
				return edi.utils.getAttributeByName(attributes, 'RECEIVER_NAME');
			}
		},
		toOrgINN: {
			text: 'roseu_invitations.to_org_inn',
			dataIndex: 'attributes',
			sortable: false,
			flex: 1,
			renderer(attributes) {
				return edi.utils.getAttributeByName(attributes, 'RECEIVER_INN');
			}
		},
		state: {
			text: 'column.status',
			dataIndex: 'state',
			sortable: false,
			flex: 1,
			htmlEncode: true,
			renderer: stateRenderer
		}
	},
	[ROSEU_INVITATION_RECEIVER_COLUMNS_CONFIG_NAME]: {
		name: {
			text: 'column.org.name',
			dataIndex: 'name',
			flex: 2,
			sortable: false,
			hideable: false
		},
		inn: {
			text: 'column.org.inn',
			dataIndex: 'inn',
			flex: 1,
			sortable: false
		},
		fnsId: {
			text: 'company.fns.id',
			dataIndex: 'fnsId',
			flex: 1,
			sortable: false
		},
		kpp: {
			text: 'column.org.kpp',
			dataIndex: 'kpp',
			flex: 1,
			sortable: false
		}
	}
});

export { ROSEU_INVITATIONS_COLUMNS_CONFIG_NAME, ROSEU_INVITATION_RECEIVER_COLUMNS_CONFIG_NAME };
