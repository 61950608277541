import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule, createPanel } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { INVOICE_LINE_COLUMN, INVOICE_SUMMARY_COLUMN, INVOICE_TAX_SUMMARY_COLUMN } from './columns';
import { INVOICE_LINE_MODEL, INVOICE_SUMMARY_MODEL, INVOICE_TAX_SUMMARY_MODEL } from './models';
import { createInvoiceTaxSummaryLines } from './methods';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import {
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createFieldBlock,
	createMaxWidthContainerForDetail
} from '@UIkit/components/panels';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { invoiceSelectors } from './selectors';

/**
 * Class for invoice details
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.details.invoice'] = function () {
	let moduleData,
		invoice = null,
		expenditureValues = [],
		showExp = false,
		productValues = {
			products: []
		};
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};
	/**
	 * Creates invoice form
	 * @returns {Object}title: 'document.buyer',
	 */
	const createDetailsPanel = function (invoice) {
		const createMainDataBlock = function () {
			const content = [
				{
					title: edi.i18n.getMessage('document.form.number'),
					text: edi.utils.getObjectProperty(invoice, 'Invoice-Header.InvoiceNumber')
				},
				{
					title: edi.i18n.getMessage('documents.column.date'),
					date: edi.utils.formatDate(
						edi.utils.getObjectProperty(invoice, 'Invoice-Header.InvoiceDate'),
						edi.constants.DATE_FORMAT.FNS
					)
				},
				{
					title: edi.i18n.getMessage('transaction.type'),
					text: edi.renderers.transactionType(
						edi.utils.getObjectProperty(invoice, 'Invoice-Header.TransactionType')
					),
					isBlock: true
				},
				{
					title: edi.i18n.getMessage('invoice.currency'),
					text: edi.renderers.currency(edi.utils.getObjectProperty(invoice, 'Invoice-Header.InvoiceCurrency'))
				},
				{
					title: edi.i18n.getMessage('invoice.payment.due.date'),
					date: edi.utils.formatDate(
						edi.utils.getObjectProperty(invoice, 'Invoice-Header.InvoicePaymentDueDate'),
						edi.constants.DATE_FORMAT.FNS
					)
				},
				{
					title: edi.i18n.getMessage('invoice.payment.terms'),
					text: edi.utils.getObjectProperty(invoice, 'Invoice-Header.InvoicePaymentTerms')
				},
				{
					title: edi.i18n.getMessage('delivery.despatch.number'),
					text: edi.utils.getObjectProperty(invoice, 'Invoice-Header.Delivery.DespatchNumber'),
					isNewLine: true
				},
				{
					title: edi.i18n.getMessage('delivery.delivery.date'),
					date: edi.utils.formatDate(
						edi.utils.getObjectProperty(invoice, 'Invoice-Header.Delivery.DeliveryDate'),
						edi.constants.DATE_FORMAT.FNS
					)
				}
			];

			return createFieldBlockForDetails({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				cls: 'edi-form-maxwidth',
				items: [
					createLabelBlockForDetails({
						gap: [8, 16],
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						contents: content
					})
				]
			});
		};

		const createAdditionalInformationBlock = function () {
			const buyerOrderContent = [
				{
					title: edi.i18n.getMessage('field.name.number'),
					text: edi.utils.getObjectProperty(invoice, 'Invoice-Header.Order.BuyerOrderNumber')
				},
				{
					title: edi.i18n.getMessage('date'),
					date: edi.utils.formatDate(
						edi.utils.getObjectProperty(invoice, 'Invoice-Header.Order.BuyerOrderDate'),
						edi.constants.DATE_FORMAT.FNS
					)
				}
			];

			const receivingAdviceContent = [
				{
					title: edi.i18n.getMessage('field.name.number'),
					text: edi.utils.getObjectProperty(invoice, 'Invoice-Header.Reference.ReceivingAdviceNumber')
				},
				{
					title: edi.i18n.getMessage('date'),
					date: edi.utils.formatDate(
						edi.utils.getObjectProperty(invoice, 'Invoice-Header.Reference.ReceivingAdviceDate'),
						edi.constants.DATE_FORMAT.FNS
					)
				}
			];

			return createFieldSetForDetails({
				title: edi.i18n.getMessage('documents.special.identifiers'),
				collapsible: true,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				layout: {
					type: 'grid',
					gap: 8
				},
				items: [
					createLabelForDetails({
						title: edi.i18n.getMessage('invoice.reference.receiving.contract.number'),
						text: edi.utils.getObjectProperty(invoice, 'Invoice-Header.Reference.ContractNumber')
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.order'),
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						items: [
							createLabelBlockForDetails({
								gap: [0, 16],
								contents: buyerOrderContent
							})
						]
					}),
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.recadv'),
						cls: 'edi-form-maxwidth',
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						items: [
							createLabelBlockForDetails({
								gap: [0, 16],
								contents: receivingAdviceContent
							})
						]
					})
				]
			});
		};

		const createPartiesBlock = function () {
			const parties = edi.utils.getObjectProperty(invoice, 'Invoice-Parties');

			const createCS = function (selectorConfig) {
				Ext.applyIf(selectorConfig, {
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					fieldsMapOnly: true,
					is_valid: true,
					readOnly: true,
					showDetailsButton: true,
					valuesByMap: true,
					fieldValues: parties
				});

				return createOrgSelector(selectorConfig);
			};

			const buyer = createCS({
				selectedOrg: edi.utils.getOrg({
					orgId: moduleData.initData.data.toOrg.id
				}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				fieldsMap: invoiceSelectors.getBuyerFieldsMap(),
				modalConf: invoiceSelectors.getBuyerModalConf()
			});

			const seller = createCS({
				selectedOrg: edi.utils.getOrg({
					orgId: moduleData.initData.data.fromOrg.id
				}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				fieldsMap: invoiceSelectors.getSellerFieldsMap(),
				modalConf: invoiceSelectors.getSellerModalConf()
			});

			const shipFrom = createCS({
				fieldsMap: invoiceSelectors.getShipFromFieldsMap(),
				modalConf: invoiceSelectors.getShipFromModalConf()
			});

			const delivery = createCS({
				fieldValues: invoice,
				fieldsMap: invoiceSelectors.getDeliveryFieldsMap(),
				modalConf: invoiceSelectors.getDeliveryModalConf()
			});

			return createFieldSetForDetails({
				title: edi.i18n.getMessage('document.section.parties'),
				collapsible: true,
				items: [
					createMaxWidthContainerForDetail({
						layout: {
							type: 'grid',
							area: [
								[6, 6],
								[6, 6]
							]
						},
						items: [
							createFieldBlockForDetails({
								title: edi.i18n.getMessage('document.seller'),
								items: [seller]
							}),
							createFieldBlockForDetails({
								title: edi.i18n.getMessage('document.buyer'),
								items: [buyer]
							}),
							createFieldBlockForDetails({
								title: edi.i18n.getMessage('document.ship.from'),
								items: [shipFrom]
							}),
							createFieldBlockForDetails({
								title: edi.i18n.getMessage('document.delivery'),
								items: [delivery]
							})
						]
					})
				]
			});
		};

		const remarks = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.remark'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(invoice, 'Invoice-Header.Remarks')
				})
			]
		});

		const lines = edi.utils.getObjectProperty(invoice, 'Invoice-Lines.Line', true);
		if ('string' != typeof lines) {
			for (let i = 0; i < lines.length; i++) {
				const values = lines[i]['Line-Item'];
				values['TaxRate'] = edi.renderers.taxRateString(values['TaxRate']);
				values['PreviousTaxRate'] = edi.renderers.taxRateString(values['PreviousTaxRate']);
				for (let j = 0; j < expenditureValues.length; j++) {
					const value = expenditureValues[j];
					if (value.lineId == values.LineNumber) {
						values.account = value.value;
						break;
					}
				}
				productValues.products.push(values);
			}
		}
		let columns = INVOICE_LINE_COLUMN;
		if (showExp && edi.permissions.hasPermission('EDIT_ACCOUNT_RELATIONS')) {
			columns = edi.columns.get(columns);
			columns.push({
				flex: 1,
				text: edi.i18n.getMessage('column.invoice.line.account'),
				dataIndex: 'account'
			});
			columns.push(
				createActionsColumnConfig({
					width: 30,
					items: [
						{
							glyph: edi.constants.ICONS.EDIT,
							testCls: 'test-action-column-edit',
							handler: function (grid, rowIndex) {
								const record = grid.getStore().getAt(rowIndex);
								if (record) {
									edi.methods.expenditure.showModalAccountSelectionForm(
										record,
										expenditureValues,
										moduleData
									);
								}
							}
						}
					]
				})
			);
		}

		const productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.invoice.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: INVOICE_LINE_MODEL,
			totalModel: INVOICE_SUMMARY_MODEL,
			gridColumnConfig: columns,
			totalColumnConfig: INVOICE_SUMMARY_COLUMN,
			data: productValues.products,
			readOnly: true,
			lineNumberFieldName: 'LineNumber',
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
			docData: invoice,
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			modalFormConfig: {
				title: 'document.invoice.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [5, [6, 3], [3, 3], [3, 3]]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.item.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN'
							},
							{
								title: edi.i18n.getMessage('line.item.invoice.quantity'),
								name: 'InvoiceQuantity',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								name: 'SupplierItemCode'
							},
							{
								name: 'BuyerItemCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [[3, 3], [3, 3], 3, [3, 3, 3]]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.invoice.unit.net.price'),
								name: 'InvoiceUnitNetPrice',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'), // Цена единицы с НДС
								name: 'InvoiceUnitGrossPrice',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate'
							},
							{
								title: edi.i18n.getMessage('line.item.previous.tax.rate'),
								name: 'PreviousTaxRate'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.category.code'),
								name: 'TaxCategoryCode',
								type: 'combo',
								allowBlank: false,
								store: edi.stores.createSimpleInlineStore(['S', 'Z', 'E'], function (id) {
									return edi.i18n.getMessage('tax.category.code.' + id);
								}),
								autoSelect: true
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								name: 'NetAmount',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								name: 'TaxAmount',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								name: 'GrossAmount',
								allowDecimals: true,
								type: 'number'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [6]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.invoice.unit.packsize'),
								name: 'InvoicedUnitPackSize',
								type: 'number',
								allowDecimals: true
							}
						]
					}
				]
			}
		});

		const getTaxSummaryData = function () {
			const data = [];
			const taxSummaryLines = createInvoiceTaxSummaryLines(productValues);
			for (let i = 0; i < taxSummaryLines.length; i++) {
				data.push(edi.models.createInstance(INVOICE_TAX_SUMMARY_MODEL, taxSummaryLines[i]));
			}
			return data;
		};

		const taxSummaryGrid = createGrid({
			storeConfig: {
				proxy: createProxyConfig({
					type: 'memory',
					data: {
						items: getTaxSummaryData()
					}
				}),
				model: edi.models.getModel(INVOICE_TAX_SUMMARY_MODEL),
				remoteSort: false
			},
			gridConfig: {
				title: edi.i18n.getMessage('grid.title.tax'),
				columnWidth: 0.5,
				columns: edi.columns.get(INVOICE_TAX_SUMMARY_COLUMN),
				disablePaging: true,
				disableSelection: true,
				enableColumnMove: false,
				enableColumnResize: false,
				sortableColumns: false
			},
			viewConfig: {
				emptyText: ''
			}
		});

		const taxSummaryGridWrapper = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				{
					xtype: 'component',
					columnWidth: 0.5,
					html: '<br/>'
				},
				taxSummaryGrid
			]
		});

		const headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing)
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				headPanel,
				createMainDataBlock(),
				createPartiesBlock(),
				createAdditionalInformationBlock(),
				remarks,
				productsGrid,
				taxSummaryGridWrapper
			]
		});
	};

	/**
	 * Creates action pane
	 */
	const createModuleActionsPanel = function (invoice) {
		const data = moduleData.initData.data;
		invoice.id = data.id;
		const actionsPanel = createActionsPanel();
		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const needSignatures = edi.document.actions.getSignCount(data);
		const needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING == direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.SUPPLIER);
		let signaturesCount = needSignatures + needSignatures2 - data.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.invoice'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					hideDefaultExport: true,
					addOriginalExport: true,
					addTransitExport: true
				}
			}
		});
		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				productValues = {
					products: []
				};
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		const modulePanel = createDetailsModulePanel();

		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					invoice = data.data;
					modulePanel.add(createDetailsPanel(invoice));
					const actionsPanel = createModuleActionsPanel(invoice);
					moduleData.tab.add(actionsPanel);
					moduleData.tab.add(modulePanel);
					if ('function' == typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
