import {
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createFormContainer,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createLabel, createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import { createActionsPanel, createAddModulePanel, createPanel } from '@Components/panels';
import { createContainer } from '@Components/miscComponents';
import { createField } from '@Components/fields';
import { moduleMethods } from './methods';
import { createSimpleSelector } from '@UIkit/components/selectors';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { POA_SIGN_INFO_COLUMNS } from '@Edi/modules/power_of_attorney/columns';

/**
 * Класс для просмотра машиночитаемых доверенностей (МЧД)
 * @author Petr Manyahin
 */
Ext.namespace('edi.modules');
edi.modules['power.of.attorney.details'] = function () {
	let moduleData, docId, docHeader, docContent, isPeredov;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		//когда открываем из грида, то там своя кастомная вьюха и айди лежит в docId
		//а когда открываем после создания, то там уже нормальный хэдер
		//при открытии из "Информация о подписях" id хранится в header
		docId = moduleData.initData.data.docId || moduleData.initData.data.id || moduleData.initData.data.header;
		moduleData.initData.data.id = docId;
		renderData(initCallBack);
		return onDestroy;
	};

	this.onRender = function () {
		edi.events.powerOfAttorney.on('change', outsideChangeHandler);
	};

	const createPowerConfirmBlock = function (partPath) {
		let powerConfirmTitle = createField({
			input: createLabel({
				text: edi.i18n.getMessage('power.of.attorney.principal.person.power.doc')
			}),
			containerConfig: {
				margin: '24 0 8 0',
				columnWidth: 0.5
			}
		});

		let powerConfirmDocDate = createField({
			title: edi.i18n.getMessage('person.identity.card.issue.date'),
			input: createLabel({
				columnWidth: 0.6,
				text: partPath?.dataVid || '',
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let powerConfirmDocIssuer = createField({
			title: edi.i18n.getMessage('power.of.attorney.principal.power.issuer'),
			input: createLabel({
				columnWidth: 0.6,
				text: partPath?.kemVid || '',
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let powerConfirmDocInfo = createField({
			title: edi.i18n.getMessage('power.of.attorney.principal.power.doc.info'),
			input: createLabel({
				columnWidth: 0.6,
				text: partPath?.svUdDok || '',
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		return [powerConfirmTitle, powerConfirmDocDate, powerConfirmDocIssuer, powerConfirmDocInfo];
	};

	const createPrincipalFieldSet = function () {
		const principalData =
			(isPeredov
				? docContent.document?.peredov?.svLitsPeredPoln?.[0]
				: docContent.document?.dover?.svDoverit?.[0]) || null;
		let principalType =
			principalData?.[isPeredov ? 'tipLitsPrdPoln' : 'tipDover'] || moduleMethods.defPrincipalType.COMPANY;
		let result;

		if (principalType === moduleMethods.defPrincipalType.COMPANY) {
			// блок данных доверителя-ЮЛ
			const rosOrgData = principalData?.[isPeredov ? 'rosOrgPrdPoln' : 'rosOrgDover'];
			let principalOrgType = createLabel({
				typography: 'heading_01',
				text: edi.i18n.getMessage('company.type.company')
			});

			const legalEntitySelector = createSimpleSelector(
				{
					cls: 'ui-org-selector',
					readOnly: true,
					infoPanelComponentTpl: moduleMethods.getLegalEntityTpl('')
				},
				edi.utils.flattenObject(isPeredov ? rosOrgData : rosOrgData?.svRosOrg || {})
			);

			let principalPersonColumn = createLabel({
				typography: 'heading_01',
				text: edi.i18n.getMessage('power.of.attorney.principal.person.column')
			});

			const litsoBezDovFlSelector = createSimpleSelector(
				{
					cls: 'ui-org-selector',
					readOnly: true,
					infoPanelComponentTpl: moduleMethods.getLitsoBezDovFlTpl('')
				},
				edi.utils.flattenObject(principalData?.rosOrgDover?.litsoBezDov || {})
			);

			let delegateCompanyName = rosOrgData?.litsoBezDov?.svOrg?.naimOrg;
			let optionalCompany = [];

			// блок ЮЛ (представителя доверителя)
			if (!!delegateCompanyName) {
				const litsoBezDovUlSelector = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods.getLitsoBezDovUlTpl('')
					},
					edi.utils.flattenObject(rosOrgData?.litsoBezDov.svOrg || {})
				);

				optionalCompany = [
					createLabel({
						typography: 'heading_01',
						text: edi.i18n.getMessage('power.of.attorney.principal.delegate.company.info')
					}),
					litsoBezDovUlSelector
				];
			}

			result = createFieldSetForDetails({
				title: edi.i18n.getMessage('power.of.attorney.principal.block'),
				collapsible: true,
				layout: {
					type: 'grid',
					gap: [8, 0]
				},
				items: [
					createFieldSetForDetails({
						cls: 'fieldset-without-header',
						collapsible: false,
						layout: {
							type: 'grid',
							gap: [8, 0]
						},
						items: [
							principalOrgType,
							legalEntitySelector,
							//В передоверии отсутствует ЛицоБезДов (litsoBezDov)
							isPeredov ? null : principalPersonColumn,
							isPeredov ? null : litsoBezDovFlSelector,
							...optionalCompany
						]
					})
				]
			});
		} else if (principalType === moduleMethods.defPrincipalType.INDIV_ENTREPRENEUR) {
			let principalOrgType = createLabel({
				typography: 'heading_01',
				text: edi.i18n.getMessage('company.type.individual')
			});
			const ipDoverData = principalData?.[isPeredov ? 'IPPrdPoln' : 'ipDover'] || {};
			const legalEntitySelector = createSimpleSelector(
				{
					cls: 'ui-org-selector',
					readOnly: true,
					infoPanelComponentTpl: moduleMethods.getIpDoverTpl('')
				},
				edi.utils.flattenObject(ipDoverData)
			);

			let individualDataColumn = createLabel({
				typography: 'heading_01',
				text: edi.i18n.getMessage('power.of.attorney.individual.data')
			});

			const ipDoverFlSelector = createSimpleSelector(
				{
					cls: 'ui-org-selector',
					readOnly: true,
					infoPanelComponentTpl: moduleMethods.getIpDoverFlTpl('')
				},
				edi.utils.flattenObject(ipDoverData?.svedFL || {})
			);

			result = createFieldSetForDetails({
				title: edi.i18n.getMessage('power.of.attorney.principal.block'),
				collapsible: true,
				layout: {
					type: 'grid',
					gap: [8, 0]
				},
				items: [
					createFieldSetForDetails({
						cls: 'fieldset-without-header',
						collapsible: false,
						layout: {
							type: 'grid',
							gap: [8, 0]
						},
						items: [principalOrgType, legalEntitySelector, individualDataColumn, ipDoverFlSelector]
					})
				]
			});
		} else if (principalType === moduleMethods.defPrincipalType.INDIVIDUAL) {
			const flDover = principalData?.[isPeredov ? 'FLPrdPoln' : 'flDover'] || {};
			let principalOrgType = createField({
				title: edi.i18n.getMessage('power.of.attorney.principal.org.type'),
				input: createLabel({
					columnWidth: 0.8,
					text: edi.i18n.getMessage('company.type.individualParticipant'),
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let lastName = createField({
				title: edi.i18n.getMessage('last.name'),
				input: createLabel({
					columnWidth: 0.8,
					text: flDover?.fio?.lastName || '',
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let firstName = createField({
				title: edi.i18n.getMessage('first.name'),
				input: createLabel({
					columnWidth: 0.8,
					text: flDover?.fio?.firstName || '',
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let middleName = createField({
				title: edi.i18n.getMessage('patronymic.name'),
				input: createLabel({
					columnWidth: 0.8,
					text: flDover?.fio?.middleName || '',
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let flInn = createField({
				title: edi.i18n.getMessage('company.inn.short'),
				input: createLabel({
					columnWidth: 0.8,
					text: flDover?.innfl || '',
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let flSnils = createField({
				title: edi.i18n.getMessage('person.snils'),
				input: createLabel({
					columnWidth: 0.8,
					text: flDover?.snils || '',
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let flGender = flDover?.svedFL?.gender
				? createField({
						title: edi.i18n.getMessage('power.of.attorney.confidant.gender'),
						input: createLabel({
							columnWidth: 0.8,
							text: edi.i18n.getMessage(`power.of.attorney.gender.${flDover.svedFL.gender}`),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 1
						}
				  })
				: null;

			let flBirthdate = createField({
				title: edi.i18n.getMessage('person.birthday'),
				input: createLabel({
					columnWidth: 0.8,
					text: flDover?.svedFL?.dateOfBirth || '',
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let flPlaceOfBirth = createField({
				title: edi.i18n.getMessage('power.of.attorney.birth.place'),
				input: createLabel({
					columnWidth: 0.8,
					text: flDover?.svedFL?.placeOfBirth || '',
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let flPhone = createField({
				title: edi.i18n.getMessage('power.of.attorney.contact.phone'),
				input: createLabel({
					columnWidth: 0.8,
					text: flDover?.svedFL?.contactTelephone || '',
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let citizenship = flDover?.svedFL?.prGrazhd || '1';

			let flCitizenship = createField({
				title: edi.i18n.getMessage('person.citizenship'),
				input: createLabel({
					columnWidth: 0.8,
					text: edi.i18n.getMessage('power.of.attorney.citizenship.' + citizenship),
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let countryStore = edi.stores.createInlineStore(edi.stores.data.countries_full, 'COUNTRY');
			let countryId = flDover?.svedFL?.grazhdanstvo || '';
			let countryRec = countryStore.findRecord('iso_number_3', countryId, 0, false, false, true);

			let flCountry = createField({
				title: edi.i18n.getMessage('person.country'),
				input: createLabel({
					columnWidth: 0.8,
					text: countryRec?.get('name'),
					valueLabel: true
				}),
				containerConfig: {
					hidden: citizenship !== '2',
					columnWidth: 1
				}
			});

			let validitySign = flDover?.prDeesp || moduleMethods.validitySign.VALID;

			let flValidity = createField({
				title: edi.i18n.getMessage('power.of.attorney.principal.validity'),
				input: createLabel({
					columnWidth: 0.8,
					text: edi.i18n.getMessage('power.of.attorney.validity.' + validitySign),
					valueLabel: true
				}),
				containerConfig: {
					columnWidth: 1
				}
			});

			let delegatePersonForInvalid = undefined;
			// блок законного представителя доверителя
			if (validitySign === moduleMethods.validitySign.INVALID) {
				let validityDoc = createField({
					title: edi.i18n.getMessage('power.of.attorney.principal.invalidity.confirmation'),
					input: createLabel({
						columnWidth: 0.8,
						text: flDover?.dokNedeesp || '',
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				});

				let delegateInn = createField({
					title: edi.i18n.getMessage('company.inn.short'),
					input: createLabel({
						columnWidth: 0.8,
						text: flDover?.svZakPred?.svPoFL?.innfl || '',
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				});

				let delegateSnils = createField({
					title: edi.i18n.getMessage('person.snils'),
					input: createLabel({
						columnWidth: 0.8,
						text: flDover?.svZakPred?.svPoFL?.snils || '',
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				});

				let delegatePersonBlock = getIndividualPartView(flDover?.svZakPred?.svPoFL?.svedFL);

				let [powerConfirmDocTitle, powerConfirmDocDate, powerConfirmDocIssuer, powerConfirmDocInfo] =
					createPowerConfirmBlock(flDover?.svZakPred?.svPoFL?.rekvDokPdtv);

				delegatePersonForInvalid = [
					createContainer({
						layout: 'column',
						items: [validityDoc]
					}),
					createContainer({
						layout: 'column',
						items: [delegateInn, delegateSnils]
					}),
					...delegatePersonBlock,
					createContainer({
						layout: 'column',
						items: [powerConfirmDocTitle]
					}),
					createContainer({
						layout: 'column',
						items: [powerConfirmDocDate, powerConfirmDocIssuer]
					}),
					createContainer({
						layout: 'column',
						items: [powerConfirmDocInfo]
					})
				];

				let delegateCompanyName = flDover?.svZakPred?.svOrg?.naimOrg;

				// блок ЮЛ (представителя доверителя)
				if (!!delegateCompanyName) {
					let delegateCompanyBlock = getCompanyViewBlock(flDover?.svZakPred?.svOrg);

					delegatePersonForInvalid = delegatePersonForInvalid.concat([
						createLabel({
							margin: '24 0 0 0',
							text: edi.i18n.getMessage('power.of.attorney.principal.delegate.company.info')
						}),
						...delegateCompanyBlock
					]);
				}
			}

			let individualPrincipalInfo = [
				createContainer({
					layout: 'column',
					items: [principalOrgType]
				}),
				validitySign === moduleMethods.validitySign.INVALID
					? createContainer({
							layout: 'column',
							items: [lastName, firstName, middleName]
					  })
					: undefined,
				createContainer({
					layout: 'column',
					items: [flInn, flSnils]
				}),
				createField({
					input: createLabel({
						text: edi.i18n.getMessage('power.of.attorney.individual.data')
					}),
					containerConfig: {
						margin: '24 0 8 0',
						columnWidth: 1
					}
				}),
				createContainer({
					layout: 'column',
					items: [flBirthdate, flPlaceOfBirth, flPhone]
				}),
				createContainer({
					layout: 'column',
					items: [flGender, flCitizenship, flCountry]
				}),
				createLabel({
					margin: '24 0 0 0',
					text: edi.i18n.getMessage('power.of.attorney.residence.address')
				}),
				getAddressView(flDover?.svedFL?.adrMZH),
				...getIdentityDocumentView(flDover?.svedFL?.udLichnFL, true),
				createContainer({
					layout: 'column',
					items: [flValidity]
				})
			];

			if (Array.isArray(delegatePersonForInvalid)) {
				individualPrincipalInfo = individualPrincipalInfo.concat(delegatePersonForInvalid);
			}

			result = createFieldSetForDetails({
				title: edi.i18n.getMessage('power.of.attorney.principal.block'),
				collapsible: true,
				items: individualPrincipalInfo
			});
		}

		return result;
	};

	/**
	 * Блок полей физического лица (svedFL)
	 * @param    {Object}    [recordData]
	 * @returns {Object[]}    array of fields
	 */
	const getIndividualPartView = function (recordData) {
		let gender = recordData?.gender
			? createField({
					title: edi.i18n.getMessage('power.of.attorney.confidant.gender'),
					input: createLabel({
						columnWidth: 0.6,
						text: edi.i18n.getMessage('power.of.attorney.gender.' + recordData.gender),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
			  })
			: null;

		let birthday = createField({
			title: edi.i18n.getMessage('person.birthday'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.dateOfBirth,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let placeOfBirth = createField({
			title: edi.i18n.getMessage('power.of.attorney.birth.place'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.placeOfBirth,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let phone = createField({
			title: edi.i18n.getMessage('power.of.attorney.contact.phone'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.contactTelephone,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let countryStore = edi.stores.createInlineStore(edi.stores.data.countries_full, 'COUNTRY');
		let countryId = recordData?.grazhdanstvo || '';
		let countryRec = countryStore.findRecord('iso_number_3', countryId, 0, false, false, true);

		let citizenship = createField({
			title: edi.i18n.getMessage('person.citizenship'),
			input: createLabel({
				columnWidth: 0.6,
				text: edi.i18n.getMessage('power.of.attorney.citizenship.' + recordData?.prGrazhd),
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let country =
			recordData?.prGrazhd === '2'
				? createField({
						title: edi.i18n.getMessage('person.country'),
						input: createLabel({
							columnWidth: 0.6,
							text: countryRec?.get('name'),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 0.5
						}
				  })
				: undefined;

		return [
			createField({
				input: createLabel({
					text: edi.i18n.getMessage('power.of.attorney.individual.data')
				}),
				containerConfig: {
					margin: '24 0 8 0',
					columnWidth: 1
				}
			}),
			createContainer({
				layout: 'column',
				items: [birthday, placeOfBirth, phone]
			}),
			createContainer({
				layout: 'column',
				items: [gender, citizenship, country]
			}),
			createLabel({
				margin: '24 0 0 0',
				text: edi.i18n.getMessage('power.of.attorney.residence.address')
			}),
			getAddressView(recordData?.adrMZH),
			...getIdentityDocumentView(recordData?.udLichnFL, true)
		];
	};

	/**
	 * Creates container with confidant's address
	 * @param    {Object}    [recordData]
	 * @param    {Object}    [config]
	 * @returns    {Object}    Ext.container.Container instance
	 */
	const getAddressView = function (recordData, config = {}) {
		let regionStore = edi.stores.initRegionsStore();
		let regionId = recordData?.region || '';
		let regionRec = regionStore.findRecord('id', regionId, 0, false, false, true);

		const defaultInputConfig = {
			columnWidth: 0.8,
			valueLabel: true
		};

		const inputConfig = { ...defaultInputConfig, ...config.inputConfig };

		let region = createField({
			title: edi.i18n.getMessage('power.of.attorney.rus.region'),
			input: createLabel({
				text: regionRec?.get('name'),
				...inputConfig
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		let address = createField({
			title: edi.i18n.getMessage('power.of.attorney.rus.address'),
			input: createLabel({
				text: recordData?.adrRF,
				...inputConfig
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		return createContainer({
			layout: 'column',
			items: [region, address]
		});
	};

	/**
	 * Creates array of identity document fields
	 * @param    {Object}    [recordData]
	 * @param    {Boolean}    [isGroup] true if need to group fields in container
	 * @returns    {Array}
	 */
	const getIdentityDocumentView = function (recordData, isGroup) {
		let docTypeStore = moduleMethods.createPersonIdentityCardTypeStore();
		let docTypeId = recordData?.documentCode;
		let docTypeRec = docTypeStore.findRecord('id', docTypeId, 0, false, false, true);

		let docTitle = createField({
			input: createLabel({
				text: edi.i18n.getMessage('power.of.attorney.identity.document')
			}),
			containerConfig: {
				margin: '24 0 8 0',
				columnWidth: 1
			}
		});

		let docType = createField({
			title: edi.i18n.getMessage('person.identity.card.type'),
			input: createLabel({
				columnWidth: 0.6,
				text: docTypeRec?.get('name'),
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let docNumber = createField({
			title: edi.i18n.getMessage('person.identity.card.number'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.serNomDok || '',
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let docIssueDate = createField({
			title: edi.i18n.getMessage('person.identity.card.issue.date'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.dataDok || '',
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let docIssuer = createField({
			title: edi.i18n.getMessage('person.identity.card.issuer'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.vidDok || '',
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let docIssuerCode = createField({
			title: edi.i18n.getMessage('person.identity.card.issuer.code'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.kodVidDok || '',
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		if (!docTypeId) {
			return [null];
		} else if (isGroup) {
			return [
				docTitle,
				createContainer({
					layout: 'column',
					items: [docType, docNumber, docIssueDate]
				}),
				createContainer({
					layout: 'column',
					items: [docIssuer, docIssuerCode]
				})
			];
		} else {
			return [docTitle, docType, docNumber, docIssueDate, docIssuer, docIssuerCode];
		}
	};

	/**
	 * Creates container with confidant's credentials
	 * @param    {Object}    [recordData]
	 * @returns    {Object}    Ext.container.Container instance
	 */
	const getNameView = function (recordData) {
		let lastName = createField({
			title: edi.i18n.getMessage('last.name'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.lastName,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let firstName = createField({
			title: edi.i18n.getMessage('first.name'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.firstName,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let middleName = createField({
			title: edi.i18n.getMessage('patronymic.name'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.middleName,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		return createContainer({
			layout: 'column',
			items: [lastName, firstName, middleName]
		});
	};

	/**
	 * Creates Individual entrepreneur's fields
	 * @param    {Object}    [recordData]
	 * @returns {Object[]}    fields array
	 */
	const getIndivEntrepreneurViewBlock = function (recordData) {
		let ipName = createField({
			title: edi.i18n.getMessage('company.name'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.naimIP,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let ipOgrn = createField({
			title: edi.i18n.getMessage('column.register.number'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.ogrnip,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let ipInn = createField({
			title: edi.i18n.getMessage('company.inn.short'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.innfl,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		let ipSnils = createField({
			title: edi.i18n.getMessage('person.snils'),
			input: createLabel({
				columnWidth: 0.6,
				text: recordData?.snils,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 0.5
			}
		});

		return [
			createContainer({
				layout: 'column',
				items: [ipName, ipInn, ipSnils, ipOgrn]
			}),
			getNameView(recordData?.fio),
			...getIndividualPartView(recordData?.svedFL)
		];
	};

	/**
	 * Creates Individual entrepreneur's fields
	 * @param    {Object}    [recordData]
	 * @returns {Object[]}    fields array
	 */
	const getCompanyViewBlock = function (recordData, fieldConf) {
		const inputConfig = Object.assign(
			{
				columnWidth: 0.8
			},
			fieldConf?.input
		);
		let orgName = createField({
			title: edi.i18n.getMessage('company.name'),
			input: createLabel({
				...inputConfig,
				text: recordData?.naimOrg,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		let orgOgrn = createField({
			title: edi.i18n.getMessage('column.register.number'),
			input: createLabel({
				...inputConfig,
				text: recordData?.ogrn,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		let orgInn = createField({
			title: edi.i18n.getMessage('company.inn.short'),
			input: createLabel({
				...inputConfig,
				text: recordData?.innyul,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		let orgKpp = createField({
			title: edi.i18n.getMessage('column.org.kpp'),
			input: createLabel({
				...inputConfig,
				text: recordData?.kpp,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		let establishDocName = createField({
			title: edi.i18n.getMessage('power.of.attorney.establish.doc.name'),
			input: createLabel({
				...inputConfig,
				text: recordData?.naimUchrDok,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		let phone = createField({
			title: edi.i18n.getMessage('power.of.attorney.contact.phone'),
			input: createLabel({
				...inputConfig,
				text: recordData?.kontaktTlf,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		let regNumber = createField({
			title: edi.i18n.getMessage('company.regNr'),
			input: createLabel({
				...inputConfig,
				text: recordData?.regNomer,
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		return [
			createContainer({
				layout: 'column',
				items: [orgName, orgOgrn, orgInn, orgKpp]
			}),
			createContainer({
				layout: 'column',
				items: [regNumber, phone, establishDocName]
			}),
			createLabel({
				margin: '24 0 0 0',
				text: edi.i18n.getMessage('power.of.attorney.registration.address')
			}),
			getAddressView(recordData?.adrReg, {
				inputConfig: inputConfig
			}),
			createLabel({
				margin: '24 0 0 0',
				text: edi.i18n.getMessage('power.of.attorney.actual.address')
			}),
			getAddressView(recordData?.adrMNFakt, {
				inputConfig: inputConfig
			})
		];
	};

	const getConfidantFieldSet = function () {
		const getConfidantFieldBlock = function (isFirstRow, recordData) {
			let confTypeName = moduleMethods.getSideTypes().filter((item) => {
				return item.id === recordData?.tipPred;
			})[0];

			let currentType = recordData?.tipPred || '';

			let confidantType = createLabel({
				typography: 'heading_01',
				text: confTypeName.name
			});

			let confidantSection = [];

			if (currentType === moduleMethods.defConfidantType.INDIVIDUAL) {
				const confidantIndividualSection = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods.getSvedFizLTpl('')
					},
					edi.utils.flattenObject(recordData?.svedFizL || {})
				);
				confidantSection = [confidantIndividualSection];
			} else if (currentType === moduleMethods.defConfidantType.COMPANY) {
				let svUpPredCompanySection = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods.getSvUpPredTpl('')
					},
					edi.utils.flattenObject(recordData?.svOrg?.svOrgTip || {})
				);
				let individualDataColumn = createLabel({
					typography: 'heading_01',
					text: edi.i18n.getMessage('power.of.attorney.individual.data')
				});

				let svUpPredFlSection = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods.getSvUpPredFlTpl('')
					},
					edi.utils.flattenObject(recordData?.svOrg?.svFL?.[0] || {})
				);

				confidantSection = [svUpPredCompanySection, individualDataColumn, svUpPredFlSection];
			} else if (currentType === moduleMethods.defConfidantType.INDIV_ENTREPRENEUR) {
				confidantSection = getIndivEntrepreneurViewBlock(recordData?.svedIP);
			}

			return createContainer({
				margin: '8 0',
				layout: {
					type: 'grid',
					gap: [8, 0]
				},
				items: [
					createFieldSetForDetails({
						cls: 'fieldset-without-header',
						collapsible: false,
						layout: {
							type: 'grid',
							gap: [8, 0],
							area: [5, 5, 5, 5]
						},
						items: [confidantType, ...confidantSection]
					})
				]
			});
		};

		const confidantPath = isPeredov ? 'document.peredov.svLitsPoluchPoln' : 'document.dover.svUpPred';
		return createFieldSetForDetails({
			title: edi.i18n.getMessage('power.of.attorney.confidant.info'),
			collapsible: true,
			items: [moduleMethods.createRowsBlock(getConfidantFieldBlock, docContent, confidantPath)]
		});
	};

	const createPoaInformationSection = function () {
		const doverData = docContent.document?.[isPeredov ? 'peredov' : 'dover'];
		const svDovData = isPeredov ? doverData?.svDovPer?.svPereDover : doverData?.svDov;
		const svDovPer = docContent.document?.peredov?.svDovPer;
		const svDovers = svDovPer?.svOsnDover?.svDovers?.[0];
		const parentalPoaData = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('parental.power.of.attorney.data'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('main.power.of.attorney.number'),
							text: svDovPer?.svOsnDover?.doverNumberMain
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.principal.inn'),
							text: svDovers?.rosOrgDover?.innyul || svDovers?.ipDover?.innfl || svDovers?.flDover?.innfl
						},
						{
							isNewLine: true,
							title: edi.i18n.getMessage('parental.power.of.attorney.number'),
							//на текущий момент по схеме данные о родительской доверенности должны лежать в nomDover
							//если это доверенность 3го уровня, а если она 2го, то родительская и основная совпадают и данных тут нет
							//бэк вынес номер родительской в хэдер и сам разруливает эту логику
							//text: docContent.document?.peredov?.svDovPer?.svPereDover?.nomDover
							text: docHeader.parentPoaNumber
						}
					]
				})
			]
		});

		const numberData = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('power.of.attorney.data'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('power.of.attorney.internal.number'),
							text: svDovData?.vnNomDover
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.external.number'),
							text: svDovData?.doverRegNumber
						}
					]
				})
			]
		});

		let validLabelText = edi.renderers.getTextDiffBetweenTwoDate(
			new Date(docContent.document?.dover?.svDov?.dataVidDover),
			new Date(docContent.document?.dover?.svDov?.dataKonDover)
		);
		if (validLabelText) {
			validLabelText = `(${edi.i18n.getMessage('power.of.attorney.valid.text')} ${validLabelText})`;
		}

		const dateValid = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('power.of.attorney.date.validity'),
			items: [
				createLabelForDetails({
					date: [svDovData?.dataVidDover, svDovData?.dataKonDover],
					dateFormat: edi.constants.DATE_FORMAT.FNS
				}),
				createLabel({
					typography: 'body-short_01',
					color: '--color-grey-50',
					padding: '14 0 0 8',
					hidden: !validLabelText,
					text: validLabelText
				})
			]
		});

		const additionConditions = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('power.of.attorney.addition.conditions'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('power.of.attorney.reassignment'),
							text: edi.i18n.getMessage('power.of.attorney.reassignment.' + svDovData?.prPeredov)
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.loss.of.authority'),
							text:
								svDovData?.prUtrPoln &&
								edi.i18n.getMessage('power.of.attorney.loss.of.authority.' + svDovData.prUtrPoln)
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.power.select'),
							text: edi.i18n.getMessage('power.of.attorney.power.code.' + svDovData?.prSovmPoln)
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.revoke.possibility'),
							text: edi.i18n.getMessage(
								'power.of.attorney.revoke.mark.' + (svDovData?.bezotziv?.prBezotziv || '1')
							)
						}
					]
				})
			]
		});

		const items = [parentalPoaData, numberData, dateValid, additionConditions];

		return createContainer({
			layout: 'anchor',
			defaults: {
				anchor: '100%'
			},
			items: items.filter((it) => !!it)
		});
	};

	const createSignerSection = function () {
		const principalData =
			(isPeredov ? docContent.document?.peredov?.svLitsPeredPoln : docContent.document?.dover?.svDoverit) || null;

		const signerData = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('power.of.attorney.signer.block'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('last.name'),
							text: principalData?.[0]?.podpisant?.lastName || ''
						},
						{
							title: edi.i18n.getMessage('first.name'),
							text: principalData?.[0]?.podpisant?.firstName || ''
						},
						{
							title: edi.i18n.getMessage('patronymic.name'),
							text: principalData?.[0]?.podpisant?.middleName || ''
						}
					]
				})
			]
		});

		return !!docHeader.countSignatures
			? createContainer({
					layout: 'anchor',
					defaults: {
						anchor: '100%'
					},
					items: [signerData]
			  })
			: null;
	};

	const createModuleForm = function () {
		const config = {
			noUsage: true,
			noLinked: true,
			noLog: true,
			noCountSignatures: true,
			CountSignaturesColumn: POA_SIGN_INFO_COLUMNS,
			rejectReasonText: edi.utils.getAttributeByName(docHeader.attributes, 'REVOKE_REASON'),
			revokeReason: true,
			signaturesTitle: edi.i18n.getMessage('power.of.attorney.signature'),
			getStateField: function () {
				return moduleMethods.getStateField({ docHeader });
			},
			panelConfig: {
				margin: '22 0 0 0'
			}
		};
		moduleData.initData.data.errorMessage = docContent.errorMessage;
		const headPanel = createDocumentHeaderPanel(moduleData.initData, config);

		return createPanel({
			cls: 'edi-details-panel',
			autoScroll: true,
			bodyPadding: 24,
			items: [
				headPanel,
				createFormContainer({
					gap: [24, 0],
					items: [
						createPoaInformationSection(),
						createPrincipalFieldSet(),
						getConfidantFieldSet(),
						moduleMethods.createPowerListSection({
							powerLists: isPeredov
								? docContent.document.peredov.svPoln
								: docContent?.document?.dover?.svPoln
						}),
						createSignerSection()
					]
				})
			]
		});
	};

	const createModuleActionsPanel = function () {
		let actionsPanel = createActionsPanel();

		const clone = Ext.clone(docHeader);
		clone.data = docContent; // нужно при копировании, чтобы не качать данные с бэка

		const moduleDataClone = Ext.clone(moduleData);
		moduleDataClone.initData.id = moduleDataClone.initData.data.id; // для корректного экспорта
		clone.data.fromSignature = moduleDataClone.initData.fromSignature || false;
		let businessState = docHeader.businessState;

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			infoTitle: edi.i18n.getMessage('power.of.attorney.title'),
			infoNumber: docContent.document?.dover?.svDov?.vnNomDover,
			docNumberTitle: '',
			data: clone,
			direction: edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg),
			moduleData: moduleDataClone,
			needSignatures: edi.document.actions.getSignCount(docHeader),
			actionProps: {
				EDIT: {
					moduleName: 'power.of.attorney.create',
					documentNumber: docContent.document?.dover?.svDov?.vnNomDover,
					success: () => edi.events.powerOfAttorney.fireEvent('change', { id: docId })
				},
				DELETE: {
					success: () => {
						moduleData.tab.close();
						edi.events.powerOfAttorney.fireEvent('delete');
					}
				},
				SIGN: {
					label: edi.i18n.getMessage('document.sign.document'),
					methodAddOptions: {
						signUrl: edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.SIGN.GET, {
							documentId: docId
						}),
						signProperties: {
							disabledPoa: true
						}
					},
					success: changeHandler,
					failure: function (response, silent = false) {
						if (!silent) {
							edi.core.showError(
								edi.utils.formatComplexServerError(response, 'power.of.attorney.general.sign.error')
							);
						}

						return changeHandler();
					}
				},
				SEND: {
					label: edi.i18n.getMessage('power.of.attorney.action.send'),
					success: () => edi.events.powerOfAttorney.fireEvent('change', { id: docId })
				},
				REFRESH: {
					handler: changeHandler
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE, {
						documentId: docId
					}),
					addExtendedExport:
						businessState === edi.constants.STATE.REVOKED || businessState === edi.constants.STATE.ACTIVE,
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: docId
					})
				},
				COPY: {
					showInFirstHalf: true,
					order: 185,
					methodAddOptions: {
						glyph: edi.constants.ICONS.REFRESH
					},
					label: edi.i18n.getMessage('form.btn.reissue')
				},
				REVOKE: {
					label: edi.i18n.getMessage('form.btn.revoke'),
					failure: function (errorData) {
						edi.core.showError(
							edi.utils.formatComplexServerError(errorData, 'power.of.attorney.error.revoking')
						);
						moduleData.tab.setLoading(false);
					},
					methodAddOptions: {
						maxInputLength: 500,
						modalCfg: {
							modalTitle: edi.i18n.getMessage('document.revoke.reason.field.title'),
							reasonText: edi.i18n.getMessage('document.revoke.reason.title'),
							buttonText: edi.i18n.getMessage('form.btn.revoke')
						},
						fieldConfig: {
							fieldTitle: edi.i18n.getMessage('fill.document.revoke.reason.title'),
							emptyText: edi.i18n.getMessage('document.revoke.reason.title'),
							modalTitle: edi.i18n.getMessage('power.of.attorney.revoke'),
							buttonText: edi.i18n.getMessage('form.btn.revoke')
						},
						getValidationError: function () {
							return 'document.revoke.reason.fill.fields.error';
						},
						process: {
							url: edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.REVOKE.POST, {
								documentId: docId
							}),
							method: 'POST',
							properties: function (reasonText) {
								return {
									REVOKE: true,
									REVOKE_REASON: reasonText
								};
							},
							//после отправки отклонения его нужно еще и подписать
							callback: function (response, success, failure) {
								moduleData.tab.setLoading(false);
								if (response.success === true) {
									edi.utils.sign(
										response.data,
										moduleData.tab,
										function (failed, responseData) {
											moduleData.tab.setLoading(false);
											if (failed) {
												failure(responseData);
											} else {
												moduleData.tab.setLoading(true);
												renderData(function () {
													edi.core.showInfo(
														edi.i18n.getMessage(
															'power.of.attorney.error.revoked.successfully'
														)
													);
												});
											}
										},
										undefined,
										undefined,
										undefined,
										undefined,
										{
											signContentUrl: edi.utils.formatString(
												edi.rest.services.POWER_OF_ATTORNEY.SIGN.GET,
												{
													documentId: response.data.id
												}
											),
											disabledPoa: true
										}
									);
								} else {
									failure(response);
								}
							}
						}
					}
				},
				REJECT: {
					success: () => edi.events.powerOfAttorney.fireEvent('change', { id: docId })
				}
			}
		});

		return actionsPanel;
	};

	const renderData = function (initCallback) {
		moduleMethods
			.fetchData({
				poaId: docId,
				fromSignature: moduleData.initData.fromSignature,
				docId: moduleData.initData.signedWithPoaDocId
			})
			.then(function ({ header, content }) {
				docHeader = header;
				docHeader.id = docId;
				docContent = content;
				moduleData.initData.data = docHeader;
				isPeredov = !Ext.Object.isEmpty(docContent?.document?.peredov);

				const modulePanel = createAddModulePanel();
				modulePanel.add(createModuleForm());

				const vnNomDover = isPeredov
					? docContent?.document?.peredov?.svDovPer?.svPereDover?.vnNomDover
					: docContent?.document?.dover?.svDov?.vnNomDover;
				if (vnNomDover) {
					const title = edi.i18n.getMessage('power.of.attorney.details') + ' ' + vnNomDover;
					moduleData.tab.setTitle(title);
				}

				moduleData.tab.removeAll();
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);

				moduleData.tab.setLoading(false);
				if (typeof initCallback === 'function') {
					initCallback();
				}
			})
			.catch(function (resp) {
				moduleData.tab.close();
				edi.rest.getErrorHandler()(resp);
			});
	};

	const changeHandler = function () {
		moduleData.tab.setLoading(true);
		renderData();
	};

	const outsideChangeHandler = function (data) {
		if (data?.id === moduleData.initData.id) {
			changeHandler();
		}
	};

	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		edi.events.powerOfAttorney.un('change', outsideChangeHandler);
		return true;
	};
};
