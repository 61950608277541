/**
 * Object for document action methods
 * @authors Vsevolod L.
 */
Ext.namespace('edi.document.actions');

edi.document.actions.availableFromGrid.SIGN.push({
	documents: edi.constants.UPD_UKD_DOCUMENT_TYPES,
	allow: function (document) {
		var direction = edi.utils.getDocumentDirection(document.toOrg, document.fromOrg),
			is501nVerion = document.versionId && '5.01-N' === document.versionId;

		return is501nVerion || direction !== 'INCOMING';
	}
});
