const createOrderHeader = function (config) {
	config = config ? config : {};
	var returnObject = {
		OrderNumber: config.OrderNumber,
		OrderDate: config.OrderDate,
		OrderCurrency: config.OrderCurrency,
		Discount: config.Discount,
		ExpectedDeliveryDate: config.ExpectedDeliveryDate,
		ExpectedDeliveryTime: config.ExpectedDeliveryTime,
		LastExpectedDeliveryDate: config.LastExpectedDeliveryDate,
		LastExpectedDeliveryTime: config.LastExpectedDeliveryTime,
		DeliveryPeriod:
			config.DeliveryPeriodFrom && config.DeliveryPeriodTo
				? `${config.DeliveryPeriodFrom}-${config.DeliveryPeriodTo}`
				: null,
		PromotionReference: config.PromotionReference,
		DocumentFunctionCode: config.DocumentFunctionCode,
		Remarks: config.Remarks,
		SpecialInstructions: config.SpecialInstructions,
		'Order-Transport': config['Order-Transport'],
		Reference: config.Reference
	};
	if (config.DocumentNameCode) {
		returnObject['DocumentNameCode'] = config.DocumentNameCode;
	}
	return returnObject;
};

const createOrder = function (config) {
	config = config ? config : {};
	var orderLines = [],
		summary = {};

	if (config.productValues) {
		summary = {
			TotalLines: config.productValues.TotalLines,
			TotalOrderedAmount: config.productValues.TotalOrderedAmount,
			TotalNetAmount: config.productValues.TotalNetAmount,
			TotalTaxAmount: config.productValues.TotalTaxAmount,
			TotalGrossAmount: config.productValues.TotalGrossAmount
		};
		for (var i = 0; i < config.productValues.products.length; i++) {
			var product = config.productValues.products[i];
			var BuyerLocationCode = product.BuyerLocationCode,
				ExpectedDeliveryDate = product.ExpectedDeliveryDate,
				LineReference = product['Line-Reference'];
			var line = {
				'Line-Delivery': {
					BuyerLocationCode: BuyerLocationCode,
					ExpectedDeliveryDate: ExpectedDeliveryDate
				},
				'Line-Reference': LineReference
			};
			if (product[edi.constants.INTERNAL_LINE_ID]) {
				line[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}
			delete product['BuyerLocationCode'];
			delete product['ExpectedDeliveryDate'];
			delete product['Line-Reference'];
			delete product[edi.constants.INTERNAL_LINE_ID];
			line['Line-Item'] = product;
			orderLines.push(line);
		}
	}

	return {
		'Order-Header': createOrderHeader(config['Order-Header']),
		'Order-Parties': config.parties || {},
		'Order-Lines': {
			Line: orderLines
		},
		'Order-Summary': summary
	};
};

const orderMethods = {
	companyDivisionStoreData: [
		{
			id: null,
			name: 'form.combo.not.selected'
		},
		{
			id: 'МСК',
			name: 'order.org.division.MSK'
		},
		{
			id: 'СПБ',
			name: 'order.org.division.SPB'
		},
		{
			id: 'СЗФО',
			name: 'order.org.division.SZFO'
		}
	],
	getCompanyDivisionStoreData: () =>
		orderMethods.companyDivisionStoreData.map((it) => ({
			id: it.id,
			name: edi.i18n.getMessage(it.name)
		}))
};

export { orderMethods, createOrder, createOrderHeader };
