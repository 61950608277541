/**
 * @author Pavel Pirogov
 * Total handler methods
 */
Ext.namespace('edi.total');

Object.assign(
	edi.total,
	(function () {
		var totals = {};
		/**
		 * EDI_FNS_UPD_SERVICE_AKT products total handler
		 * @param    {Number}    lines
		 * @param    {Object}    store
		 * @param    {Object}    totalRecord
		 * @param    {Object}    documentData
		 */
		totals[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT] = function (
			lines,
			store,
			totalRecord,
			documentData
		) {
			if (documentData) {
				totalRecord.set(
					'totalPay',
					edi.utils.getObjectProperty(documentData, 'actServiceBonusType.totalPay', false) || 0
				);
			} else {
				var totalPay = 0;
				store.each(function (record) {
					if (record.get('bonusAmountTotal')) {
						totalPay += parseFloat(record.get('bonusAmountTotal')) || 0;
					}
				});
				totalRecord.set('totalPay', edi.utils.roundTo(totalPay, 2) || 0);
			}
		};
		/**
		 * UPD products total handler
		 * @param    {Number}    lines
		 * @param    {Object}    store
		 * @param    {Object}    totalRecord
		 * @param    {Object}    documentData
		 */
		totals[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD] = function (lines, store, totalRecord, documentData) {
			if (documentData && !documentData.skipGetObjectTotalProperty) {
				totalRecord.set(
					'totalWithoutNDS',
					edi.utils.getObjectProperty(documentData, 'invoiceTable.totalPay.totalWithoutNDS', false) || 0
				);
				totalRecord.set(
					'totalNDS',
					edi.utils.getObjectProperty(documentData, 'invoiceTable.totalPay.totalTaxAmount.taxSum', false) || 0
				);
				totalRecord.set(
					'totalWithNDS',
					edi.utils.getObjectProperty(documentData, 'invoiceTable.totalPay.totalWithNDS', false) || 0
				);
			} else {
				var totalWithNDS = 0;
				var totalNDS = 0;
				var totalWithoutNDS = 0;

				store.each(function (record) {
					if (record.get('costWithoutNDS')) {
						totalWithoutNDS += parseFloat(record.get('costWithoutNDS')) || 0;
					}
					if (record.get('taxSum') && record.get('taxSum').taxSum) {
						totalNDS += parseFloat(record.get('taxSum').taxSum) || 0;
					}
					if (record.get('info')) {
						totalWithNDS += parseFloat(record.get('info')) || 0;
					}
				});
				totalRecord.set('totalWithoutNDS', edi.utils.roundTo(totalWithoutNDS, 2) || 0);
				totalRecord.set('totalNDS', edi.utils.roundTo(totalNDS, 2) || 0);
				totalRecord.set('totalWithNDS', edi.utils.roundTo(totalWithNDS, 2) || 0);
			}
		};

		return totals;
	})()
);
