const actionRulesForOnServiceContainerModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {},
	EXPORT_GROUP: {},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		OUTGOING: {
			STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.OPERATOR_INVOICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_INVOICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE,
				edi.constants.STATE.RECEIVER_INVOICE_RECEIVED_ACK,
				edi.constants.STATE.SENDER_INVOICE_RECEIVED_ACK_ADVISE,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER,
				edi.constants.STATE.OPERATOR_UPD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_UPD_SERVICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_UPD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_UPD_SERVICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE,
				edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK,
				edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK_DECREE_14,
				edi.constants.STATE.RECEIVER_UPD_SERVICE_RECEIVED_ACK,
				edi.constants.STATE.SENDER_UPD_RECEIVED_ACK_ADVISE,
				edi.constants.STATE.SENDER_UPD_SERVICE_RECEIVED_ACK_ADVISE,
				edi.constants.STATE.OPERATOR_UKD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_UKD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE,
				edi.constants.STATE.RECEIVER_UKD_RECEIVED_ACK,
				edi.constants.STATE.SENDER_UKD_RECEIVED_ACK_ADVISE,
				edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.RECEIVER_UKD_RECEIVED_ACK_DECREE_14
			],
			SINGLE_APPLY: []
		},
		LOOP: {
			STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.OPERATOR_UPD_SENT_ACK_TO_SENDER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_UPD_SERVICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_UPD_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_UPD_SERVICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE,
				edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK,
				edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK_DECREE_14,
				edi.constants.STATE.RECEIVER_UPD_SERVICE_RECEIVED_ACK,
				edi.constants.STATE.SENDER_UPD_RECEIVED_ACK_ADVISE,
				edi.constants.STATE.SENDER_UPD_SERVICE_RECEIVED_ACK_ADVISE,
				edi.constants.STATE.WAIT_SIGNATURE_STATUS10,
				edi.constants.STATE.WAIT_PARTNER_DECISION,
				edi.constants.STATE.RECEIVER_UKD_RECEIVED_ACK_DECREE_14
			]
		},
		INCOMING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.PROCESSING_TITLES,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT,
				edi.constants.STATE.PRICAT_EANCOM_DP_UTOCH_RECEIVER
			]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [
					edi.constants.STATE.DEBTOR_REVIEW,
					edi.constants.STATE.RECEIVER_REVIEW,
					edi.constants.STATE.READ
				],
				allow: function (documentData) {
					return documentData.needSignatures;
				}
			}
		]
	},
	REJECT: {
		INCOMING: {
			STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.WAIT_SIGNATURE,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER
			]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.SENT],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.COMPLETED],
				allow: false
			}
		]
	},
	ROUTE_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	ROUTE_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	CLARIFY: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.COMPLETED
			]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive;
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.REJECTED
			]
		},
		LOOP: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.SELLER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: edi.constants.FREE_DOCUMENT_TYPES,
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.REJECTED],
				allow: false
			}
		]
	},
	EDIT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	COMPLETE: {
		INCOMING: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.READ, edi.constants.STATE.DEBTOR_REVIEW, edi.constants.STATE.RECEIVER_REVIEW]
		},
		LOOP: {
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.SELLER_REVIEW, edi.constants.STATE.DRAFT]
		},
		OUTGOING: {
			SINGLE_APPLY: [],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	READ: {
		INCOMING: {
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		permissionChanging: [],
		conditions: []
	},
	READ_AND_COMPLETE: {
		INCOMING: {
			DOCUMENTS: edi.constants.LEGACY_DOCUMENTS,
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: []
	},
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {
			SINGLE_APPLY: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENDER_REVIEW],
				allow: false
			}
		]
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED, edi.constants.STATE.SENT]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENT],
				allow: false
			}
		]
	},
	CONVERT_TO_DRAFT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.REJECTED],
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	COPY: {
		OUTGOING: {
			DOCUMENTS: []
		},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					if (checkOptions.record.get('packageId')) {
						return edi.permissions.hasPermission('UNLINK_PACKAGE_OBJECT');
					} else {
						return (
							edi.permissions.hasPermission('LINK_PACKAGE_OBJECT') ||
							edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
						);
					}
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER,
				edi.constants.STATE.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER
			]
		},
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER
			]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					var annual = true;
					if (checkOptions.annulStatus) {
						annual = [
							edi.constants.STATE.CREATED,
							edi.constants.STATE.DELETED,
							edi.constants.STATE.DRAFT
						].some((it) => it === checkOptions.annulStatus);
					}
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive ? annual : false;
				}
			}
		],
		permissionChanging: [
			{
				change: function (actionPermission, checkOptions) {
					return checkOptions.docType.substr(0, 4) === 'DSF_' ? 'ANNUL_DSF' : actionPermission;
				}
			}
		]
	},
	CREATE_UKD: {
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED
			]
		},
		permissionChanging: [],
		conditions: []
	},
	RENOUNCEMENT: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (data) {
					var annulStatus = edi.utils.getAttributeByName(data.attributes, 'ANNUL_STATUS'),
						utochReason = edi.utils.getAttributeByName(data.attributes, 'UTOCH_REASON'),
						rawBpName = edi.utils.getAttributeByName(data.attributes, 'bpName');

					return !(
						utochReason ||
						!edi.permissions.hasPermission('CREATE_REFUSAL_TO_SIGN') ||
						(annulStatus && annulStatus === 'ACTIVE') ||
						!['SCHF', 'SCHFDOP'].some((it) => it === edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[rawBpName])
					);
				}
			}
		]
	},
	CREATE_CUD: {
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED
			]
		},
		permissionChanging: [],
		conditions: []
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.ON_SERVICE_CONTAINER]: actionRulesForOnServiceContainerModule
});

export { actionRulesForOnServiceContainerModule };
