//за основу возьмем текущие правила для УПД и склонируем их что бы не мутировать по ссылке другие конфиги
const updActionRules = edi.action.rulesByDocType[edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD];

const isApprovalStateRejected = function (checkOptions) {
	let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
	return approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
};

//На первом месте в исходном массиве стоит кондишен с customMethod, поэтому наш новый кондишен мы вставляем после него
//По факту это копия оригинального кондишена, в которой добавили в bpNamesWithoutSignAction ..._WITHOUT_OPERATOR
updActionRules.SIGN.conditions.splice(1, 0, {
	directions: [edi.constants.DIRECTIONS.INCOMING],
	documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
	allow: function (checkOptions) {
		let attrs = checkOptions.attributes;
		let bpName = edi.utils.getAttributeByName(attrs, 'bpName');
		let bpNamesWithoutSignAction = [
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DECREE_14_WITHOUT_OPERATOR,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.FNS_UPD_SCHF_DECREE_14_WITHOUT_OPERATOR,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14
		];
		let isRenounced =
			edi.utils.getAttributeByName(attrs, 'RENOUNCEMENT') === 'true' &&
			(checkOptions.state === edi.constants.STATE.SENT ||
				checkOptions.state === edi.constants.STATE.READ ||
				checkOptions.state === edi.constants.STATE.RECEIVER_REVIEW);
		let annulIsActive =
			checkOptions.annulStatus === edi.constants.STATE.ACTIVE ||
			checkOptions.annulStatus === edi.constants.STATE.CREATED;
		return (
			!isRenounced &&
			!annulIsActive &&
			!bpNamesWithoutSignAction.some((it) => it === bpName) &&
			checkOptions.state !== edi.constants.STATE.SIGNED_BY_CONSUMER
		);
	}
});

updActionRules.SIGN.conditions.unshift({
	customMethod: isApprovalStateRejected,
	allow: false
});

//Это копия оригинального кондишена, в которой добавили в bpNamesWithoutReadAction ..._WITHOUT_OPERATOR
updActionRules.READ.conditions.unshift({
	documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
	customMethod: function (checkOptions) {
		return checkOptions.annulNotActive;
	},
	allow: function (checkOptions) {
		let attrs = checkOptions.attributes;
		let bpName = edi.utils.getAttributeByName(attrs, 'bpName');
		let bpNamesWithoutReadAction = [
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DECREE_14_WITHOUT_OPERATOR,
			edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.FNS_UPD_SCHF_DECREE_14_WITHOUT_OPERATOR
		];
		let isRenounced =
			checkOptions.state === edi.constants.STATE.SENT &&
			edi.utils.getAttributeByName(attrs, 'RENOUNCEMENT') === 'true';
		return checkOptions.annulNotActive && !isRenounced && !bpNamesWithoutReadAction.some((it) => it === bpName);
	}
});

updActionRules.RENOUNCEMENT.conditions.unshift({
	allow: function (documentData) {
		const bpName = edi.utils.getAttributeByName(documentData.attributes, 'bpName');
		const allowBpNames = [
			edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHFDOP_WITHOUT_OPERATOR,
			edi.constants.FNS_BPNAMES_PERMISSION_PREFIX.SCHF_WITHOUT_OPERATOR
		];
		const isAllowedBpName = allowBpNames.find(
			(allowBpName) => edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[bpName] === allowBpName
		);
		return isAllowedBpName;
	}
});

updActionRules.SEND_TO_APPROVE_DOCUMENTS = {
	OUTGOING: {
		DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
		BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
	},
	INCOMING: {
		DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
		BUSINESS_STATE: [edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT]
	},
	conditions: [
		{
			documents: edi.constants.UPD_WITH_APPROVE,
			directions: [edi.constants.DIRECTIONS.OUTGOING],
			states: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW],
			allow: () => true
		},
		{
			documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
			directions: [edi.constants.DIRECTIONS.INCOMING],
			states: [edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT],
			allow: function (checkOptions) {
				let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
				return !approvalState || approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
			}
		}
	]
};
updActionRules.SEND_TO_APPROVE_EMPLOYEES = {
	OUTGOING: {
		DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
		BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
	},
	INCOMING: {
		DOCUMENTS: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
		BUSINESS_STATE: [edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT]
	},
	conditions: [
		{
			documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
			directions: [edi.constants.DIRECTIONS.OUTGOING],
			states: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW],
			allow: () => true
		},
		{
			documents: [edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD],
			directions: [edi.constants.DIRECTIONS.INCOMING],
			states: [edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT],
			allow: function (checkOptions) {
				let approvalState = edi.utils.getAttributeByName(checkOptions.attributes, 'APPROVAL_STATE');
				return !approvalState || approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED;
			}
		}
	]
};
