import { createCompanySelector } from '@Components/company.selector.js';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createCheckbox, createDateLabel, createField, createLabel } from '@Components/fields';
import { createActionsButton } from '@Components/buttons';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import createPreviewPanel from '@Components/previewPanel/previewPanel';
import { createContainer } from '@UIkit/components/panels';

/**
 * Class for FORMALIZED_DOCUMENT details
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.modules');
edi.modules['document.details.formalized'] = function () {
	let moduleData, docHeader, docContent, previewModal, checkReportTimer, twoColumnsLayout, customFieldsContainer;
	let topPath = '//FormalizedDocument/';

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docHeader = moduleData.initData.data;
		docContent = {};
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Alias for edi.utils.getObjectProperty(docContent, name)
	 * @param	{string}	name
	 * @return 	{string}
	 */
	let get = function (name) {
		return edi.utils.getObjectProperty(docContent, name);
	};

	let setFileDataToModal = function (reportId) {
		previewModal.add({
			xtype: 'component',
			height: 600,
			autoEl: {
				tag: 'iframe',
				src:
					edi.utils.formatString(edi.rest.services.DOCUMENTS.FD.PRINT_FORM.GET, {
						reportId: reportId
					}) + '?authType=EDI'
			}
		});

		previewModal.center();
		previewModal.setLoading(false);
	};

	var checkReportStatus = function () {
		var failure = function (resp) {
			if (previewModal && !previewModal.isDestroyed) {
				previewModal.close();
			}
			edi.rest.getErrorHandler('error.getting.data')(resp);
		};
		var success = function (resp) {
			if (!resp || !resp.items || !resp.items[0]) {
				failure(resp);
			}
			if (resp.items[0].reportStatus === 'READY') {
				setFileDataToModal(resp.items[0].requestId);
			} else if (previewModal && !previewModal.isDestroyed) {
				if (checkReportTimer) {
					clearTimeout(checkReportTimer);
				}
				checkReportTimer = setTimeout(function () {
					checkReportStatus();
				}, 1000);
			}
		};
		var url = edi.utils.formatString(edi.rest.services.REPORT.TEMPLATES_AND_STATUSES.GET, {
			docId: docHeader.id,
			format: 'PDF'
		});
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};

	var getPreviewFile = function () {
		var failure = function (resp) {
			if (previewModal && !previewModal.isDestroyed) {
				previewModal.close();
			}
			edi.rest.getErrorHandler('error.getting.data')(resp);
		};
		var success = function (resp) {
			if (!resp || !resp.data || !resp.data.id) {
				failure(resp);
			}
			checkReportStatus();
		};
		var url = edi.utils.formatString(edi.rest.services.DOCUMENTS.FD.VIEW, { docId: docHeader.id });
		edi.rest.sendRequest(url, 'POST', {}, success, failure);
	};

	/**
	 * create preview modal panel
	 */
	var openPreviewModal = function () {
		previewModal = createPreviewPanel({
			fileId: docHeader.id,
			fileName: docContent.file.name
		});
		previewModal.setLoading(true);
		getPreviewFile();
	};

	/**
	 * Creates order form
	 * @returns {Object}
	 */
	var createDetailsPanel = function () {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing)
		});

		var filePanel = null;
		if (docContent.file) {
			var fileName = docContent.file.name;
			filePanel = createPanel({
				layout: 'column',
				margin: '0 0 5',
				cls: 'edi-create-field-line',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabel({
						columnWidth: 0.3,
						cls: 'edi-fieldset-label',
						text: edi.i18n.getMessage('document.file')
					}),
					createButton({
						cls: [BUTTON_CLS.light, BUTTON_CLS.small],
						tooltip: fileName,
						glyph: edi.constants.ICONS.DETAILS,
						handler: openPreviewModal,
						hidden: docHeader.state === edi.constants.STATE.DRAFT
					}),
					createLabel({
						columnWidth: 0.7,
						text: fileName,
						valueLabel: true
					})
				]
			});
		}
		var headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('documents.subdoctype.' + get('doctype')),
					input: createLabel({
						columnWidth: 0.7333,
						text: docHeader.number,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.25,
					text: docContent.date,
					format: edi.constants.DATE_FORMAT.FNS,
					valueLabel: true
				})
			]
		});

		var createCS = function (selectorConf) {
			Ext.applyIf(selectorConf, {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				disableCommonAddressRenderer: true,
				disableCommonAddressTitleRenderer: true,
				fieldValues: docContent
			});

			return createCompanySelector(selectorConf);
		};

		let receiverFieldsMap = edi.selectors.getFormalizedDocumentFieldsMap('receiver');
		delete receiverFieldsMap.addr_category;

		var receiver = createCS({
			title: 'document.receiver',
			fieldsMap: receiverFieldsMap
		});

		let senderFieldsMap = edi.selectors.getFormalizedDocumentFieldsMap('sender');
		delete senderFieldsMap.addr_category;

		var sender = createCS({
			title: 'document.sender',
			fieldsMap: senderFieldsMap
		});

		var commentText = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.dsf.commentText'),
					input: createLabel({
						columnWidth: 0.7,
						text: get('comment'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var receiverSignatureField = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('column.expected.recipients.signature.under.document'),
					input: createCheckbox({
						columnWidth: 0.7,
						checked:
							edi.utils.getAttributeByName(
								moduleData.initData.data.attributes,
								'RECEIVER_SIGNATURE_EXPECTED'
							) === 'true',
						disabled: true,
						uncheckedValue: false
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var receiverIzvipolExpectedField = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.formalized.field.receiver.izvipol.expected'),
					input: createCheckbox({
						columnWidth: 0.7,
						checked:
							edi.utils.getAttributeByName(
								moduleData.initData.data.attributes,
								'RECEIVER_IZVPOL_EXPECTED'
							) === 'true',
						disabled: true,
						uncheckedValue: false
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		twoColumnsLayout = createTwoColumnsLayout(
			[headData, sender, receiver],
			[filePanel, commentText, receiverSignatureField, receiverIzvipolExpectedField],
			0.5
		);

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, createCustomFieldsContainer()]
		});
	};

	/**
	 * Creates action panel
	 */
	var createModuleActionsPanel = function () {
		var direction = edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg);
		var needSignatures = edi.document.actions.getSignCount(docHeader);
		var needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(docHeader, edi.constants.CONTRACT_SIDES.CONSUMER);
		var signaturesCount = needSignatures + needSignatures2 - docHeader.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero
		var actionsPanel = createActionsPanel();

		var readCmp = null;
		if (
			edi.constants.DIRECTIONS.INCOMING === direction &&
			docHeader.state === edi.constants.STATE.DP_IZVPOL_RECEIVER
		) {
			readCmp = createActionsButton({
				text: edi.i18n.getMessage('document.mark.read'),
				glyph: edi.constants.ICONS.READ,
				handler: function () {
					edi.core.confirm(
						edi.i18n.getMessage('document.mark.read'),
						edi.i18n.getMessage('document.mark.read.question'),
						function () {
							moduleData.tab.setLoading();
							var success = function () {
								moduleData.tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: docHeader.id
								});
							};
							var failure = edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.mark.read'
							);
							var markRead = function () {
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: docHeader.id
										},
										true
									),
									'PUT',
									Ext.encode({}),
									success,
									failure
								);
							};
							markRead();
						}
					);
				}
			});
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: docHeader,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				SIGN: {
					methodAddOptions: {
						useBeforeAction:
							docHeader.state === edi.constants.STATE.DRAFT ||
							edi.constants.STATE.WAIT_RECEIVER_DECISION === docHeader.state
					}
				},
				SEND: {
					beforeAction: function (continueSent, refuse) {
						edi.core.confirm(
							undefined,
							edi.i18n.getMessage('document.formalized.action.send.confirm'),
							continueSent,
							refuse
						);
					},
					methodAddOptions: {
						pushData: {
							PUSH_TO_SENT: true
						}
					}
				},
				EDIT: {
					moduleName: 'document.create.formalized'
				},
				READ: {
					component: readCmp
				},
				REFRESH: {
					handler: function () {
						changeHandler(docHeader);
					}
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				docHeader = headerData.data;
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		let success = function (data) {
			if (data && data.data) {
				docContent = data.data;
				docContent.id = docHeader.id;
				modulePanel.add(createDetailsPanel(document));
				moduleData.tab.add(createModuleActionsPanel(document));
				moduleData.tab.add(modulePanel);
				if ('function' == typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}

				if (docContent.doctype && docHeader?.toOrg?.id && docHeader?.fromOrg?.id) {
					edi.methods.custom_fields.initCustomFields({
						docType: edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
						subDocType: docContent.doctype,
						docId: docHeader.id,
						toOrgId: docHeader.toOrg.id,
						fromOrgId: docHeader.fromOrg.id,
						container: customFieldsContainer,
						topPath: topPath,
						readOnly: true
					});
				}
			} else {
				failure(data);
			}
		};
		let url = edi.document.actions.formatDetailsUri(moduleData.initData);
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		clearTimeout(checkReportTimer);
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
