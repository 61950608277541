const ORDER_LINE_MODEL = 'ORDER_LINE';
const ORDER_SUMMARY_MODEL = 'ORDER_SUMMARY';

edi.models.setModels({
	[ORDER_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'ItemType',
				type: 'string'
			},
			{
				name: 'OrderedQuantity',
				type: 'string'
			},
			{
				name: 'OrderedUnitPacksize',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'OrderedUnitNetPrice',
				type: 'string'
			},
			{
				name: 'OrderedNetAmount',
				type: 'string'
			},
			{
				name: 'ExpirationDate',
				type: 'string'
			},
			{
				name: 'ExpectedDespatchDate',
				type: 'string'
			},
			{
				name: 'RequestedDeliveryDate',
				type: 'string'
			},
			{
				name: 'ExpectedDeliveryDate',
				type: 'string'
			},
			{
				name: 'MaxExpirationDate',
				type: 'string'
			},
			{
				// added for value transferring in grid row
				name: 'BuyerLocationCode',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'OrderedUnitGrossPrice',
				type: 'string'
			},
			{
				name: 'OrderedTaxAmount',
				type: 'string'
			},
			{
				name: 'OrderedGrossAmount',
				type: 'string'
			},
			{
				name: 'Discount',
				type: 'string'
			},
			{
				name: 'Model',
				type: 'string'
			},
			{
				name: 'Reference-Elements'
				//type: 'array'
			},
			{
				name: 'Line-Reference'
				//type: 'object'
			},
			{
				name: 'Reference-Element'
				//type: 'object'
			}
		],
		idProperty: 'LineNumber'
	},
	[ORDER_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'TotalLines',
				type: 'number'
			},
			{
				name: 'TotalOrderedAmount',
				type: 'string'
			},
			{
				name: 'TotalNetAmount',
				type: 'float'
			},
			{
				name: 'TotalGrossAmount',
				type: 'float'
			},
			{
				name: 'TotalTaxAmount',
				type: 'string'
			},
			{
				name: 'TotalNetWeight',
				type: 'string'
			},
			{
				name: 'TotalGrossWeight',
				type: 'string'
			},
			{
				name: 'TotalPalletAmount',
				type: 'string'
			}
		]
	}
});

export { ORDER_LINE_MODEL, ORDER_SUMMARY_MODEL };
