import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createField, createFieldSet, createLabel } from '@Components/fields';
import { createContainer, createTwoColumnsLayout } from '@Components/miscComponents';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createSimpleStore } from '@Components/storeComponents';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { MODAL_SIZE } from '@UIkit/components/modal';

/**
 * Class for torg_2 details
 * @author Anatoli Deryshev
 */
Ext.namespace('edi.modules');
edi.modules['document.details.torg_2'] = function () {
	var moduleData, part1, part1Head, part2Head, annulDoc, utochReasonText, rejectReasonText;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	//разделяет компоненты на 3 колонки равного размера
	var createThreeColumnsLayout = function (arrayFields) {
		var arrayFieldLayout = [];
		arrayFields.forEach(function (field) {
			if (!field) {
				return;
			}

			var fieldLayout = createField({
				title: field.title,
				input: createLabel({
					columnWidth: 0.8,
					text: field.text,
					valueLabel: true
				}),
				containerConfig: {
					margin: '5 0 0 5',
					columnWidth: field.columnWidth ? field.columnWidth : 0.33
				}
			});

			arrayFieldLayout.push(fieldLayout);
		});
		return createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			items: arrayFieldLayout
		});
	};

	const createDateWithTimeFieldFn = (conf) =>
		createContainer({
			grid: conf.grid,
			layout: 'column',
			items: [
				createField({
					columnWidth: 0.7,
					title: conf.title || '',
					input: createLabel({
						columnWidth: 0.2,
						text: edi.utils.formatDate(
							conf.text,
							edi.constants.DATE_FORMAT.FNS,
							edi.constants.DATE_FORMAT.DATE_TIME_XSD
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.8
					}
				}),
				createLabel({
					columnWidth: 0.16,
					text: edi.utils.formatDate(
						conf.text,
						edi.constants.DATE_FORMAT.TIME,
						edi.constants.DATE_FORMAT.DATE_TIME_XSD
					),
					valueLabel: true
				})
			]
		});

	var createCS = function (selectorConfig) {
		Ext.applyIf(selectorConfig, {
			readOnly: true,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			margin: '5 0 0 5',
			valuesByMap: true,
			fieldValues: part1
		});

		return createCompanySelector(selectorConfig);
	};

	var createLabelFn = function (text) {
		return createPanel({
			layout: 'column',
			items: [
				createLabel({
					margin: '5 0 0 5',
					text: edi.i18n.getMessage(text)
				})
			]
		});
	};

	var companySelectorLine = function (leftSelector, rightSelector) {
		return createTwoColumnsLayout([leftSelector], [rightSelector], 0.5);
	};

	//Сведения о документе
	var createDocumentInformationItems = function () {
		var valuableAcceptanceDocument = [
			{
				title: '',
				text: edi.utils.getObjectProperty(part1, 'document.primaryDocName'),
				columnWidth: 0.8
			}
		];
		var documentInfo = [
			{
				title: edi.i18n.getMessage('document.form.number'),
				text: edi.utils.getObjectProperty(part1, 'document.acceptanceDocInfo.docNumber'),
				columnWidth: 0.35
			},
			{
				title: edi.i18n.getMessage('document.torg2.document.date'),
				text: edi.utils.getObjectProperty(part1, 'document.acceptanceDocInfo.docDate'),
				columnWidth: 0.21
			},
			{
				title: edi.i18n.getMessage('documents.column.correction.number'),
				text: edi.utils.getObjectProperty(part1, 'document.correctionsDocInfo.docNumber'),
				columnWidth: 0.23
			},
			{
				title: edi.i18n.getMessage('document.torg2.correction.date'),
				text: edi.utils.getObjectProperty(part1, 'document.correctionsDocInfo' + '.docDate'),
				columnWidth: 0.21
			}
		];
		var buyer = createCS({
			title: 'document.torg2.buyer',
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.buyer', true)
		});
		var seller = createCS({
			title: edi.i18n.getMessage('document.torg2.seller'),
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.seller', true)
		});

		var circumstancesFillingDocument = [
			{
				title: edi.i18n.getMessage('document.torg2.basis.economic.entity'),
				text: edi.utils.getObjectProperty(part1, 'document.baseFileInvoiceCreation'),
				columnWidth: 1
			}
		];

		let circumstancesStore = createSimpleStore({
			data: edi.methods.torg2.getCreationCircumstances(),
			model: edi.models.getModel('SIMPLE')
		});
		let circumstancesStoreId = edi.utils.getObjectProperty(part1, 'document.createDocInfo');
		let circumstancesStoreRec = circumstancesStore.findRecord('id', circumstancesStoreId, 0, false, false, true);
		var circumstancesDrawingDocument = [
			{
				title: edi.i18n.getMessage('document.torg2.circumstances.drawing.up.document'),
				text: circumstancesStoreRec?.get('name') || '',
				columnWidth: 0.8
			}
		];

		var additionStore = createSimpleStore({
			data: edi.methods.torg2.getAdditionalInfoOptions(),
			model: edi.models.getModel('SIMPLE')
		});
		var additionalInfoValues = [];
		edi.utils.getObjectProperty(part1, 'document.infoCreateAdditionalInfo')?.forEach(function (elem) {
			additionalInfoValues.push(additionStore.findRecord('id', elem, 0, false, false, true).get('name'));
		});

		var additionalInfo = createField({
			title: edi.i18n.getMessage('document.torg2.additional.info.options'),
			input: createLabel({
				columnWidth: 0.8,
				text: additionalInfoValues.join(', '),
				valueLabel: true
			}),
			containerConfig: {
				margin: '5 0 0 5',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columnWidth: 0.8
			}
		});

		var additionalInfoSigners = createField({
			title: edi.i18n.getMessage('document.torg2.additional.info.assigning.signers'),
			input: createLabel({
				columnWidth: 0.8,
				text: edi.utils.getObjectProperty(part1, 'document.destinationAdditionalInfo'),
				valueLabel: true
			}),
			containerConfig: {
				margin: '5 0 0 5',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columnWidth: 0.8
			}
		});

		return createFieldSet({
			title: edi.i18n.getMessage('document.torg2.document.information'),
			collapsible: true,
			items: [
				createThreeColumnsLayout(valuableAcceptanceDocument),
				createThreeColumnsLayout(documentInfo),
				companySelectorLine(buyer, seller),
				createLabelFn('document.torg2.circumstances.filling.document'),
				createThreeColumnsLayout(circumstancesFillingDocument),
				createThreeColumnsLayout(circumstancesDrawingDocument),
				additionalInfo,
				additionalInfoSigners
			]
		});
	};

	//Сведения об обстоятельствая приёмки (бывшие "сведения о транспортировке")
	var shippingDetailsInformationItems = function () {
		var shipper = createCS({
			title: 'document.ship.from',
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.cargoSender', true)
		});

		var consignee = createCS({
			title: 'document.delivery',
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.cargoReceiver', true)
		});

		var insurer = createCS({
			title: 'document.torg2.insurance.company',
			fieldsMap: edi.selectors.getTorg2FieldsMap('document.contentFactEconomLife1.insurer', true)
		});

		var stateContract = [
			{
				title: edi.i18n.getMessage('document.torg2.identifier.state.contract'),
				text: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife1.govAgreementId'),
				columnWidth: 0.6
			}
		];

		var conditionsCode = [
			{
				title: edi.i18n.getMessage('document.torg2.creation.conditions.code'),
				text: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife1.formationCode'),
				columnWidth: 0.335
			}
		];

		var orderOnAppointmentCommission = [
			{
				title: edi.i18n.getMessage('document.torg2.order.number'),
				text: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife1.order.orderNumber'),
				columnWidth: 0.335
			},
			{
				title: edi.i18n.getMessage('date'),
				text: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife1.order.orderDate'),
				columnWidth: 0.335
			}
		];

		var idFile = [
			{
				title: edi.i18n.getMessage('document.torg2.inspection.id.file'),
				text: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife1.info.fileId'),
				columnWidth: 0.9
			}
		];

		var acceptanceResultsInfo = createRowsBlock(createInfoField, 'document.contentFactEconomLife1.info.textInfo');

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.inspection.conditions.info'),
				collapsible: true,
				items: [
					companySelectorLine(shipper, consignee),
					createThreeColumnsLayout(conditionsCode),
					createLabelFn('document.torg2.state.contract'),
					createThreeColumnsLayout(stateContract),
					companySelectorLine(insurer),
					createLabelFn('document.torg2.order.on.appointment.commission'),
					createThreeColumnsLayout(orderOnAppointmentCommission),
					createLabelFn('document.torg2.additional.information.head'),
					createThreeColumnsLayout(idFile),
					createInfoLabels(),
					acceptanceResultsInfo
				]
			})
		];
	};

	//TODO УБРАТЬ ДУБЛИКАТЫ в блоке редактирования такой же метод
	/**
	 * Creates row of labels
	 * @param	{Object[]}	configs				Config object
	 * @param   {string}	configs.name	  	Translation key for title
	 * @param   {number}	configs.columnWidth	Column width in percents 0 - 1.0
	 * @param	{Object}	panelConfig					Panel configuration object
	 * @param	{number}	panelConfig.columnWidth		Panel width in percents 0 - 1.0
	 * @return	{Object}	panel with row of labels
	 */
	var createLabelsRow = function (configs, panelConfig) {
		var confs = Array.isArray(configs) ? configs : [];
		var pConf = panelConfig || {};
		var items = confs.map(function (c) {
			return createField({
				input: createLabel({
					margin: c.margin || 0,
					text: edi.i18n.getMessage(c.name || '')
				}),
				containerConfig: {
					columnWidth: c.columnWidth || 0.25
				}
			});
		});
		var panel = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			items: items
		});

		return createTwoColumnsLayout(panel, [], pConf.columnWidth || 1);
	};

	var createInfoLabels = function () {
		return createLabelsRow(
			[
				{
					name: 'document.torg2.identifier',
					margin: '0 0 0 5',
					columnWidth: 0.6
				},
				{
					name: 'document.torg2.value',
					margin: '0 0 0 10',
					columnWidth: 0.4
				}
			],
			{
				columnWidth: 0.68
			}
		);
	};

	/**
	 * Creates block of rows via function and array of values
	 * @param	{Function}	createContentFieldsFn	function that creates fields row
	 * @param 	{string}	fieldsPropertyName		path to array of rows values
	 * @return	{Object}	panel with rows array
	 */
	var createRowsBlock = function (createContentFieldsFn, fieldsPropertyName) {
		var createRow = function (isFirstRow, rowData) {
			return createContentFieldsFn(false, rowData);
		};

		return createPanel({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: createFormItemsByValues(createRow, fieldsPropertyName)
		});
	};

	var createInfoField = function (isFirstRow, fieldData) {
		fieldData = fieldData || {};

		var identifier = createField({
			input: createLabel({
				columnWidth: 1,
				valueLabel: true,
				text: fieldData && fieldData.identifier ? fieldData.identifier : ''
			}),
			containerConfig: {
				columnWidth: 0.6
			}
		});
		var value = createField({
			input: createLabel({
				columnWidth: 1,
				valueLabel: true,
				text: fieldData && fieldData.value ? fieldData.value : ''
			}),
			containerConfig: {
				margin: '0 0 0 5',
				columnWidth: 0.4
			}
		});
		var panel = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			items: [identifier, value]
		});
		return createTwoColumnsLayout(panel, [], 0.68);
	};

	//TODO УБРАТЬ ДУБЛИКАТЫ в блоке редактирования а также в ФНС документах   вынести прсто в эди.методс
	var createFormItemsByValues = function (method, valuePath) {
		var fields = [];
		var fieldsValues = edi.utils.getObjectProperty(part1, valuePath) || [];
		if (fieldsValues.length > 0) {
			for (var i = 0; i < fieldsValues.length; i++) {
				fields.push(method(!i, fieldsValues[i], i === fieldsValues.length - 1));
			}
		}
		return fields.length > 0 ? fields : [method(true)];
	};

	// блок Осмотр груза
	var cargoInspectionInformationItems = function () {
		var CargoInspectionDate = [
			{
				title: edi.i18n.getMessage('document.torg2.cargo.date'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.inspectionCargoInfo.inspectionDate'
				),
				columnWidth: 0.3
			},
			{
				title: edi.i18n.getMessage('document.torg2.start.inspection.time'),
				text: edi.utils.formatDate(
					edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife1.inspectionCargoInfo.startTime'),
					edi.constants.DATE_FORMAT.TIME,
					edi.constants.DATE_FORMAT.TIME_XSD
				),
				columnWidth: 0.3
			},
			{
				title: edi.i18n.getMessage('document.torg2.finish.inspection.time'),
				text: edi.utils.formatDate(
					edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife1.inspectionCargoInfo.endTime'),
					edi.constants.DATE_FORMAT.TIME,
					edi.constants.DATE_FORMAT.TIME_XSD
				),
				columnWidth: 0.3
			},
			{
				title: edi.i18n.getMessage('document.torg2.send.cargo.date'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.inspectionCargoInfo.sendDate'
				),
				columnWidth: 0.3
			},
			{
				title: edi.i18n.getMessage('document.torg2.creation.place'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.inspectionCargoInfo.createDocPlace'
				),
				columnWidth: 0.5
			}
		];

		var accompanyingDocumentsFields = [
			{
				title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.name'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.nameAccompanyingDoc'
				),
				columnWidth: 0.3
			},
			{
				title: edi.i18n.getMessage('document.form.number'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.numberAccompanyingDoc'
				),
				columnWidth: 0.2
			},
			{
				title: edi.i18n.getMessage('document.torg2.document.date'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.dateAccompanyingDoc'
				),
				columnWidth: 0.1
			},
			{
				title: edi.i18n.getMessage('document.torg2.additional.document.identifier'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.inspectionCargoInfo.accompanyingDoc.additionalIdAccompanyingDoc'
				),
				columnWidth: 0.4
			}
		];

		var createCertificateOfCompliance = function (isFirstRow, fieldData) {
			var certificateNumber = createField({
				input: createLabel({
					columnWidth: 0.8,
					valueLabel: true,
					text: fieldData ? fieldData : ''
				}),
				containerConfig: {
					columnWidth: 1
				}
			});
			return createPanel({
				layout: 'column',
				items: [certificateNumber]
			});
		};

		var certificateOfComplianceField = createRowsBlock(
			createCertificateOfCompliance,
			'document.contentFactEconomLife1.inspectionCargoInfo.conformCertificate'
		);

		var idFile = [
			{
				title: edi.i18n.getMessage('document.torg2.inspection.id.file'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.fileId'
				),
				columnWidth: 0.9
			}
		];

		var infoFieldField = createRowsBlock(
			createInfoField,
			'document.contentFactEconomLife1.inspectionCargoInfo.contentInspectionCargoInfo.textInfo'
		);

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.cargo.inspection'),
				collapsible: true,
				items: [
					createThreeColumnsLayout(CargoInspectionDate), //строка 1
					createLabelFn('document.torg2.covering.document'),
					createThreeColumnsLayout(accompanyingDocumentsFields), //строка 2
					createLabelFn('document.torg2.certificate.of.compliance'),
					certificateOfComplianceField, //строка 3
					createLabelFn('document.torg2.inspection.info.field'),
					createThreeColumnsLayout(idFile),
					createInfoLabels(),
					infoFieldField //строка 4
				]
			})
		];
	};

	// сведения о грузе по сопроводительным документам
	var cargoDetailsCoveringDocumentsGrid = function () {
		const cargoInfoByAccompanyingDocumentsData = edi.utils.getObjectProperty(
			part1,
			'document.contentFactEconomLife1.attachedDocInfo'
		);
		var cargoInfoByAccompanyingDocumentsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.torg2.cargo.covering.document.info'),
			margin: '16 0 0',
			data: cargoInfoByAccompanyingDocumentsData,
			readOnly: true,
			hidden: Ext.isEmpty(cargoInfoByAccompanyingDocumentsData),
			hasTotal: false,
			allowBlank: true,
			gridModel: 'CARGO_INFO_BY_ACCOMPANYING_DOCUMENTS',
			gridColumnConfig: edi.columns.get('cargoInfoByAccompanyingDocuments'),
			modalFormConfig: {
				title: 'document.torg2.cargo.covering.document.info',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.torg2.cargo.covering.document.info'),
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.cargo.name'),
								name: 'nameCargo'
							},
							{
								title: edi.i18n.getMessage('document.torg2.count.place'),
								name: 'spaceCount'
							},
							{
								title: edi.i18n.getMessage('document.torg2.ok'),
								name: 'productOkei'
							},
							{
								title: edi.i18n.getMessage('product.unit.name'),
								name: 'unitOfMeasure'
							},
							{
								title: edi.i18n.getMessage('document.torg2.view.packaging'),
								name: 'packType'
							},
							{
								title: edi.i18n.getMessage('document.torg2.sealing.mark'),
								name: 'sealMark'
							},
							{
								title: edi.i18n.getMessage('document.torg2.sent.gross.mass'),
								name: 'weightCargoStart.gross'
							},
							{
								title: edi.i18n.getMessage('document.torg2.sent.net.mass'),
								name: 'weightCargoStart.net'
							},
							{
								title: edi.i18n.getMessage('document.torg2.received.gross.mass'),
								name: 'weightCargoActual.gross'
							},
							{
								title: edi.i18n.getMessage('document.torg2.received.net.mass'),
								name: 'weightCargoActual.net'
							},
							{
								title: edi.i18n.getMessage('document.torg2.identifier'),
								name: 'textInfo.identifier'
							},
							{
								title: edi.i18n.getMessage('document.torg2.value'),
								name: 'textInfo.value'
							}
						]
					}
				]
			}
		});

		return [cargoInfoByAccompanyingDocumentsGrid];
	};

	// блок "Сведения о дате и времени событий, связанных с приемкой груза"
	var cargoReceptionTimeDetailsItems = function () {
		const dateTimeCargoAcceptanceFields = [
			{
				title: edi.i18n.getMessage('document.torg2.cargo.arrival.time'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.acceptanceTimeInfo.shipmentCargoTime'
				),
				grid: {
					col: 6,
					row: 1,
					scol: 1,
					srow: 1
				}
			},
			{
				title: edi.i18n.getMessage('document.torg2.cargo.delivery.time'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.acceptanceTimeInfo.giveAwayCargoTime'
				),
				grid: {
					col: 6,
					row: 1,
					scol: 7,
					srow: 1
				}
			},
			{
				title: edi.i18n.getMessage('document.torg2.cargo.opening.time'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.acceptanceTimeInfo.openCargoTime'
				),
				grid: {
					col: 6,
					row: 1,
					scol: 1,
					srow: 2
				}
			},
			{
				title: edi.i18n.getMessage('document.torg2.warehouse.delivery.time'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.acceptanceTimeInfo.deliveryCargoTime'
				),
				grid: {
					col: 6,
					row: 1,
					scol: 7,
					srow: 2
				}
			}
		];

		const dateTimeCargoAcceptanceContainer = createContainer({
			layout: {
				type: 'grid'
			},
			items: dateTimeCargoAcceptanceFields.map((fieldConf) => {
				return createDateWithTimeFieldFn(fieldConf);
			})
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.cargo.reception.time.details'),
				collapsible: true,
				hidden: Ext.isEmpty(
					edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife1.acceptanceTimeInfo')
				),
				items: [dateTimeCargoAcceptanceContainer]
			})
		];
	};

	// блок "Другие обстоятельства приемки ценностей"
	var otherCargoReceptionItems = function () {
		var storageConditions = [
			{
				title: edi.i18n.getMessage('document.torg2.warehouse.storage.conditions'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.otherInfoAcceptance.storageConditions'
				),
				columnWidth: 0.48
			},
			{
				title: edi.i18n.getMessage('document.torg2.examined.package.container.condition'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.otherInfoAcceptance.containerCondition'
				),
				columnWidth: 0.48
			}
		];

		var typeDefinitionMissingValues = [
			{
				title: edi.i18n.getMessage('document.torg2.type.definition.missing.values'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.otherInfoAcceptance.definitionShortage'
				),
				columnWidth: 0.48
			},
			{
				title: edi.i18n.getMessage('document.torg2.additional.information'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.otherInfoAcceptance.additionalInfo'
				),
				columnWidth: 0.48
			}
		];

		var transporter = createCS({
			title: 'document.torg2.cargo.transporter',
			fieldsMap: edi.selectors.getTorg2FieldsMap(
				'document.contentFactEconomLife1.otherInfoAcceptance.transporter',
				true
			)
		});

		var coveringTransportLabel = createLabel({
			margin: '5 0 0 5',
			text: edi.i18n.getMessage('document.torg2.covering.document.details')
		});

		var coveringTransportDocInfo = [
			{
				title: edi.i18n.getMessage('pricate_eancom.document.name'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.nameAccompanyingDoc'
				),
				columnWidth: 0.3
			},
			{
				title: edi.i18n.getMessage('documents.column.number'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.numberAccompanyingDoc'
				),
				columnWidth: 0.2
			},
			{
				title: edi.i18n.getMessage('document.torg2.document.date'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.dateAccompanyingDoc'
				),
				columnWidth: 0.1
			},
			{
				title: edi.i18n.getMessage('document.torg2.additional.document.identifier'),
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.otherInfoAcceptance.accompanyingDoc.additionalIdAccompanyingDoc'
				),
				columnWidth: 0.4
			}
		];

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.other.cargo.reception.circumstances'),
				collapsible: true,
				items: [
					createThreeColumnsLayout(storageConditions),
					createThreeColumnsLayout(typeDefinitionMissingValues),
					companySelectorLine(transporter),
					coveringTransportLabel,
					createThreeColumnsLayout(coveringTransportDocInfo)
				]
			})
		];
	};

	//Лицо, принявшее товар
	var personAcceptedGoodsInformationItems = function () {
		var position = [
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeePosition'
				),
				title: edi.i18n.getMessage('document.torg2.position')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeeAuthority'
				),
				title: edi.i18n.getMessage('document.torg2.basis.authority')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.buyerEmployee.buyerEmployeeAdditionalInfo'
				),
				title: edi.i18n.getMessage('document.torg2.other.information')
			}
		];
		var definitionMissingValues = [
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.buyerEmployee.name.lastName'
				),
				title: edi.i18n.getMessage('document.torg2.surname')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.buyerEmployee.name.firstName'
				),
				title: edi.i18n.getMessage('document.torg2.name')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.buyerEmployee.name.middleName'
				),
				title: edi.i18n.getMessage('document.torg2.patronymic')
			}
		];
		var memberPosition = [
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonPosition'
				),
				title: edi.i18n.getMessage('document.torg2.position')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonAuthority'
				),
				title: edi.i18n.getMessage('document.torg2.basis.authority')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonAdditionalInfo'
				),
				title: edi.i18n.getMessage('document.torg2.other.information')
			}
		];
		var memberOrganization = [
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonOrgName'
				),
				title: edi.i18n.getMessage('document.torg2.name.organization')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.receiverPersonRightPresentResults'
				),
				title: edi.i18n.getMessage('document.torg2.base.authority.organization')
			}
		];
		var memberInfo = [
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.lastName'
				),
				title: edi.i18n.getMessage('document.torg2.surname')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.firstName'
				),
				title: edi.i18n.getMessage('document.torg2.name')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.middleName'
				),
				title: edi.i18n.getMessage('document.torg2.patronymic')
			}
		];
		var individualPosition = [
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.individualPersonReceiverGoogsAuthority'
				),
				title: edi.i18n.getMessage('document.torg2.basis.authority')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.individualPersonReceiverGoogsAdditInfo'
				),
				title: edi.i18n.getMessage('document.torg2.other.information')
			}
		];
		var individualInfo = [
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.lastName'
				),
				title: edi.i18n.getMessage('document.torg2.surname')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.firstName'
				),
				title: edi.i18n.getMessage('document.torg2.name')
			},
			{
				text: edi.utils.getObjectProperty(
					part1,
					'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.middleName'
				),
				title: edi.i18n.getMessage('document.torg2.patronymic')
			}
		];

		var isReceiverPerson = !Ext.isEmpty(
			edi.utils.getObjectProperty(
				part1,
				'document.contentFactEconomLife1.personInfo.otherPerson.receiverPerson.name.lastName'
			)
		);
		var isIndividualPerson = !Ext.isEmpty(
			edi.utils.getObjectProperty(
				part1,
				'document.contentFactEconomLife1.personInfo.otherPerson.individualPersonReceiverGoogs.name.lastName'
			)
		);
		var receiverHead = isReceiverPerson
			? 'receiverPerson'
			: isIndividualPerson
			? 'individualPerson'
			: 'buyerEmployee';

		var acceptorHeader = createLabelFn('document.torg2.receiver.' + receiverHead);

		var buyerEmployeePanel = createPanel({
			hidden: isReceiverPerson || isIndividualPerson,
			items: [createThreeColumnsLayout(position), createThreeColumnsLayout(definitionMissingValues)]
		});

		var receiverPersonPanel = createPanel({
			hidden: !isReceiverPerson,
			items: [
				createThreeColumnsLayout(memberPosition),
				createThreeColumnsLayout(memberOrganization),
				createThreeColumnsLayout(memberInfo)
			]
		});

		var individualPersonPanel = createPanel({
			hidden: !isIndividualPerson,
			items: [createThreeColumnsLayout(individualPosition), createThreeColumnsLayout(individualInfo)]
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.cargo.received.person'),
				collapsible: true,
				items: [acceptorHeader, buyerEmployeePanel, receiverPersonPanel, individualPersonPanel]
			})
		];
	};

	//Результаты приемки
	var acceptanceResultsInformationItems = function () {
		var createEvent = function () {
			var event = createField({
				input: createLabel({
					columnWidth: 1,
					valueLabel: true,
					text: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife2.eventDescription')
				}),
				containerConfig: {
					columnWidth: 0.5
				}
			});
			var result = createField({
				input: createLabel({
					columnWidth: 1,
					valueLabel: true,
					text: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife2.conclusion')
				}),
				containerConfig: {
					margin: '0 0 0 5',
					columnWidth: 0.5
				}
			});

			var panel = createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				items: [event, result]
			});

			return createTwoColumnsLayout(panel, [], 1);
		};

		var createEventLabels = createLabelsRow(
			[
				{
					name: 'document.torg2.brief.description.event',
					margin: '0 0 0 5',
					columnWidth: 0.5
				},
				{
					name: 'document.torg2.brief.conclusion.event',
					margin: '0 0 0 10',
					columnWidth: 0.5
				}
			],
			{
				columnWidth: 1
			}
		);

		var createDescriptionEvent = createRowsBlock(createEvent, '');

		var operationKind = createPanel({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					input: createLabel({
						columnWidth: 0.4,
						valueLabel: true,
						text: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife2.operationType')
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.4
					}
				})
			]
		});

		var createAdditionalInfo = function (isFirstRow, fieldData) {
			var additionalInfoIdent = createField({
				input: createLabel({
					columnWidth: 0.4,
					valueLabel: true,
					text: edi.utils.getObjectProperty(fieldData, 'identifier')
				}),
				containerConfig: {
					columnWidth: 0.5
				}
			});
			var additionalInfoValue = createField({
				input: createLabel({
					columnWidth: 0.4,
					valueLabel: true,
					text: edi.utils.getObjectProperty(fieldData, 'value')
				}),
				containerConfig: {
					columnWidth: 0.5
				}
			});
			return createPanel({
				layout: 'column',
				items: [additionalInfoIdent, additionalInfoValue]
			});
		};

		var createAdditionalInfoBlock = function () {
			return createRowsBlock(createAdditionalInfo, 'document.contentFactEconomLife2.info');
		};

		var createAttachedDoc = function (isFirstRow, fieldData) {
			var attachedDocType = createField({
				input: createLabel({
					columnWidth: 0.8,
					valueLabel: true,
					text: edi.utils.getObjectProperty(fieldData, 'docType')
				}),
				containerConfig: {
					columnWidth: 0.5
				}
			});
			var attachedDocNumber = createField({
				input: createLabel({
					columnWidth: 0.8,
					valueLabel: true,
					text: edi.utils.getObjectProperty(fieldData, 'docNumber')
				}),
				containerConfig: {
					columnWidth: 0.2
				}
			});
			var attachedDocDate = createField({
				input: createLabel({
					columnWidth: 0.9,
					valueLabel: true,
					text: edi.utils.getObjectProperty(fieldData, 'docDate')
				}),
				containerConfig: {
					columnWidth: 0.2
				}
			});
			return createPanel({
				layout: 'column',
				items: [attachedDocType, attachedDocNumber, attachedDocDate]
			});
		};

		var createAttachedDocsBlock = function () {
			return createRowsBlock(createAttachedDoc, 'document.contentFactEconomLife2.attachedDoc');
		};

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.information.acceptance.discrepancies'),
				collapsible: true,
				items: [
					createLabelFn('document.torg2.acceptance.results'),
					createEventLabels,
					createDescriptionEvent,

					createLabelFn('operation.kind'),
					operationKind,

					createLabelFn('document.torg2.additional.information.head'),
					createAdditionalInfoBlock(),

					createLabelFn('document.torg2.attached.documents'),
					createAttachedDocsBlock()
				]
			})
		];
	};

	var createFieldSetFn = function (title, items, collapsible) {
		return createFieldSet({
			title: edi.i18n.getMessage(title),
			collapsible: !!collapsible,
			items: items
		});
	};

	var createReceptionLinesBlock = function () {
		var textInfos = edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife2.info', true);

		if (!textInfos.length) {
			return null;
		}

		//Creates panel
		var createTextInfoPanel = function (identifier, value) {
			return createPanel({
				layout: 'column',
				margin: '0 0 5 10',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: identifier,
						input: createLabel({
							columnWidth: 0.8,
							text: value,
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 1
						}
					})
				]
			});
		};

		var panels = createTwoColumnsLayout(
			textInfos.map(function (info) {
				return createTextInfoPanel(info.identifier, info.value);
			}),
			[],
			1
		);

		return createFieldSetFn('documents.fns_upd.special_identifiers', [panels], true);
	};

	var documentLinesInformationItems = function () {
		var deltaStore = edi.stores.createSimpleInlineStore(
			['positiveDelta', 'negativeDelta'],
			function (id) {
				return edi.i18n.getMessage('document.torg2.delta.' + id);
			},
			true
		);

		var documentLinesGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.torg2.cargo.reception.results'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			margin: '16 0 0',
			data: edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife2.acceptanceResult'),
			showSelectProductBtn: false,
			lockIfNoPartner: false,
			hasTotal: false,
			readOnly: true,
			gridModel: 'TORG2_LINE',
			lineNumberFieldName: 'LineNumber',
			gridColumnConfig: edi.columns.get('torg_2_document_lines'),
			docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2,
			modalConf: {
				width: MODAL_SIZE.widthLarge,
				height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
				minHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGE,
				maxHeight: undefined
			},
			modalFormConfig: {
				title: 'document.torg2.cargo.reception.results',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.torg2.cargo.valuables'),
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.column.unit.of.measure'),
								name: 'productOkei',
								store: edi.stores.initOkeiStore(),
								type: 'combo',
								displayField: 'name_code'
							},
							{
								title: edi.i18n.getMessage('document.torg2.discrepancy.info'),
								name: 'discrepancyInfo',
								type: 'text'
							},
							{
								title: edi.i18n.getMessage('document.torg2.covering.document.line.number'),
								name: 'values.lineNumberAttachedDoc',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.name'),
								name: 'values.valueName',
								type: 'text'
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.feature'),
								name: 'values.productDesc',
								type: 'text'
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.grade'),
								name: 'values.productSort',
								type: 'text'
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.vendor.code'),
								name: 'values.productReference'
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.code'),
								name: 'values.productCode'
							},
							{
								title: edi.i18n.getMessage('document.torg2.passport.number'),
								name: 'values.passport'
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.cargo.info.from.sender'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'senderInfo.count',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.price'),
								name: 'senderInfo.cost',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.column.fns.tax.rate'),
								name: 'senderInfo.rateNDS',
								type: 'combo',
								store: createSimpleStore({
									data: edi.methods.torg2.getTaxRatesList(),
									model: edi.models.getModel('SIMPLE')
								})
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.cost.with.NDS'),
								name: 'senderInfo.costWithNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'senderInfo.sumNDS',
								type: 'number'
							},
							{
								type: 'editableGrid',
								name: 'senderInfo.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											renderer: 'desadvLineEAN'
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											name: 'type'
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'packNumbers',
											type: 'tagTextField'
										}
									],
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.column.count.to.return.in.fact'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'actualInfo.count',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.price'),
								name: 'actualInfo.cost',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.column.fns.tax.rate'),
								name: 'actualInfo.rateNDS',
								type: 'combo',
								store: createSimpleStore({
									data: edi.methods.torg2.getTaxRatesList(),
									model: edi.models.getModel('SIMPLE')
								})
							},
							{
								title: edi.i18n.getMessage('document.torg2.item.cost.with.NDS'),
								name: 'actualInfo.costWithNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'actualInfo.sumNDS',
								type: 'number'
							},
							{
								type: 'editableGrid',
								name: 'actualInfo.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											renderer: 'desadvLineEAN'
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type'
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'packNumbers',
											type: 'tagTextField'
										}
									],
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.cargo.defect'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'defect.count',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.position.sum.with.NDS'),
								name: 'defect.costWithNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'defect.sumNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.comment.on.discrepancies'),
								name: 'defect.info'
							},
							{
								type: 'editableGrid',
								name: 'defect.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											renderer: 'desadvLineEAN'
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type'
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'packNumbers',
											type: 'tagTextField'
										}
									],
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.cargo.damage'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'breakage.count',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.position.sum.with.NDS'),
								name: 'breakage.costWithNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'breakage.sumNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.comment.on.discrepancies'),
								name: 'breakage.info'
							},
							{
								type: 'editableGrid',
								name: 'breakage.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											renderer: 'desadvLineEAN'
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type'
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'packNumbers',
											type: 'tagTextField'
										}
									],
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.dsf.deficit'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'shortage.count',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.position.sum.with.NDS'),
								name: 'shortage.costWithNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'shortage.sumNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.comment.on.discrepancies'),
								name: 'shortage.info'
							},
							{
								type: 'editableGrid',
								name: 'shortage.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											renderer: 'desadvLineEAN'
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											requiredFields: ['packNumbers'],
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type'
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'packNumbers',
											type: 'tagTextField'
										}
									],
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.column.count.to.return.surplus'),
						autoScroll: true,
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.amount'),
								name: 'surplus.count',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.position.sum.with.NDS'),
								name: 'surplus.costWithNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('tax.amount'),
								name: 'surplus.sumNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.comment.on.discrepancies'),
								name: 'surplus.info'
							},
							{
								type: 'editableGrid',
								name: 'surplus.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text',
											renderer: 'desadvLineEAN'
										},
										{
											store: edi.stores.createSimpleInlineStore(
												['kiz', 'packNumber'],
												function (id) {
													return edi.i18n.getMessage(
														'line.item.identifyProductToolNumbers.' + id
													);
												},
												true
											),
											displayField: 'name',
											valueField: 'id',
											type: 'combo',
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type'
										},
										{
											title: edi.i18n.getMessage('document.torg2.product.code'),
											name: 'packNumbers',
											type: 'tagTextField'
										}
									],
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									gridConfig: {
										cls: 'modal-grid',
										flex: 1,
										title: edi.i18n.getMessage('line.item.identifyProductToolNumbers')
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.cargo.price.divergences'),
						items: [
							{
								title: edi.i18n.getMessage('document.torg2.delta.cost') + ' +',
								name: 'costDeviation.positiveDelta.deltaCostWithNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.costWithNDS') + ' +',
								name: 'costDeviation.positiveDelta.deltaNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.sumNDS') + ' +',
								name: 'costDeviation.positiveDelta.deltaSumNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.info') + ' +',
								name: 'costDeviation.positiveDelta.info'
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.cost') + ' -',
								name: 'costDeviation.negativeDelta.deltaCostWithNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.costWithNDS') + ' -',
								name: 'costDeviation.negativeDelta.deltaNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.sumNDS') + ' -',
								name: 'costDeviation.negativeDelta.deltaSumNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.torg2.delta.info') + ' -',
								name: 'costDeviation.negativeDelta.info'
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.torg2.info.fields'),
						layout: 'fit',
						items: [
							{
								type: 'editableGrid',
								name: 'info',
								config: {
									fields: [
										{
											name: 'identifier',
											type: 'text'
										},
										{
											name: 'value',
											type: 'text'
										}
									],
									model: 'UPD_PRODUCT_TEXT_INFO',
									gridConfig: {
										cls: 'modal-grid',
										title: edi.i18n.getMessage('line.item.tab.inf.pol.str')
									}
								}
							}
						]
					}
				]
			}
		});

		return [documentLinesGrid];
	};

	//Подписант
	var signatoryInformationItems = function () {
		var signer = edi.utils.getObjectProperty(part1, 'document.signer');
		if (signer && signer.length > 0) {
			part1.signer = signer[0];
		}

		var authArea = edi.stores.data.torg2AuthorityAreas.OUTBOX.filter((it) => {
			return +it.id === +part1?.signer?.authorityArea;
		})[0];
		var signerStatus = edi.stores.data.torg2SignerStatuses.OUTBOX.filter((it) => {
			return +it.id === +part1?.signer?.status;
		})[0];
		var areaAuthority = [
			{
				text: authArea
					? `${authArea.id} - ${authArea.name}`
					: edi.utils.getObjectProperty(part1, 'signer.authorityArea'),
				title: edi.i18n.getMessage('document.torg2.area.authority')
			},
			{
				text: signerStatus
					? `${signerStatus.id} - ${signerStatus.name}`
					: edi.utils.getObjectProperty(part1, 'signer.status'),
				title: edi.i18n.getMessage('document.torg2.status')
			},
			{
				text: edi.utils.getObjectProperty(part1, 'signer.baseAuthory'),
				title: edi.i18n.getMessage('document.torg2.base.authority')
			}
		];
		var foundationAuthority = [
			{
				text: edi.utils.getObjectProperty(part1, 'signer.baseOrgAuthory'),
				title: edi.i18n.getMessage('document.torg2.foundation.authority.organization'),
				columnWidth: 0.66
			}
		];

		var signerPosition = [
			{
				text: part1?.signer?.legalEntity?.position,
				title: edi.i18n.getMessage('document.torg2.position')
			}
		];
		var signerFullName = [
			{
				text: part1?.signer?.legalEntity?.fullName?.lastName || part1?.signer?.individual?.name?.lastName,
				title: edi.i18n.getMessage('document.torg2.surname')
			},
			{
				text: part1?.signer?.legalEntity?.fullName?.firstName || part1?.signer?.individual?.name?.firstName,
				title: edi.i18n.getMessage('document.torg2.name')
			},
			{
				text: part1?.signer?.legalEntity?.fullName?.middleName || part1?.signer?.individual?.name?.middleName,
				title: edi.i18n.getMessage('document.torg2.patronymic')
			}
		];

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.torg2.signatory'),
				collapsible: true,
				items: [
					createThreeColumnsLayout(areaAuthority),
					createThreeColumnsLayout(foundationAuthority),
					createThreeColumnsLayout(signerPosition),
					createThreeColumnsLayout(signerFullName)
				]
			})
		];
	};

	/**
	 * Creates details panel of torg2
	 * @returns {Object}
	 */
	var createDetailsPanel = function () {
		var mainData = Ext.clone(moduleData.initData.data);
		mainData.modifyDate = part2Head ? part2Head.modifyDate : part1Head.modifyDate;
		mainData.countSignatures =
			(part2Head ? part2Head.countSignatures : 0) + (part1Head ? part1Head.countSignatures : 0);
		mainData.ids = [part1Head.id];
		if (part2Head) {
			mainData.ids.push(part2Head.id);
		}
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			utochReasonText: utochReasonText,
			rejectReasonText: rejectReasonText,
			isReceipts: !(moduleData.initData && moduleData.initData.isDocumentSharing)
		});
		var formItems = [headPanel];

		formItems = formItems.concat(createDocumentInformationItems());
		formItems = formItems.concat(shippingDetailsInformationItems());
		formItems = formItems.concat(cargoInspectionInformationItems());
		formItems = formItems.concat(cargoDetailsCoveringDocumentsGrid());
		formItems = formItems.concat(cargoReceptionTimeDetailsItems());
		formItems = formItems.concat(otherCargoReceptionItems());
		formItems = formItems.concat(personAcceptedGoodsInformationItems());
		formItems = formItems.concat(acceptanceResultsInformationItems());
		formItems = formItems.concat(documentLinesInformationItems());
		formItems = formItems.concat(signatoryInformationItems());

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: formItems
		});
	};

	/**
	 * Creates action pane
	 */
	var createModuleActionsPanel = function () {
		var actionsPanel = createActionsPanel();
		var data = moduleData.initData.data;
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg),
			isOutGoingDocument = edi.constants.DIRECTIONS.OUTGOING === direction;
		var needSignatures = edi.document.actions.getSignCount(data);
		var hasPart2 = !!edi.utils.getAttributeByName(data.attributes, 'hasPart2') || !!part2Head?.id;
		var signaturesCount =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? needSignatures - part1Head.countSignatures
				: needSignatures - (hasPart2 && part2Head ? part2Head.countSignatures : 0);

		var editCmp = null;

		if (
			isOutGoingDocument &&
			edi.action.isAvailable(
				edi.constants.DOCUMENT_ACTIONS.EDIT,
				edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data))
			)
		) {
			editCmp = edi.document.actions.createEditActionButton('document.create.torg_2', data, data.number);
		}

		let modData = Ext.clone(moduleData);
		let initialFromOrg = modData.initData.data.fromOrg;
		modData.initData.data.fromOrg = modData.initData.data.toOrg;
		modData.initData.data.toOrg = initialFromOrg;

		const customButtons = [];
		let createDocConfig = [
			{
				permission: 'CREATE_EDI_FNS_UKD',
				title: 'documents.doctype.UKD_DIS_501_N',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				createCustomMethod: edi.document.actions.methodCreateUKDfromDocuments(
					part1Head.id,
					direction,
					edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife2.acceptanceResult'),
					'UnitGrossPrice',
					modData,
					{
						version: '5.01-N',
						UKDFunction: 'DIS'
					}
				),
				config: {
					isFromTransformation: false,
					buyerOrg: initialFromOrg
				}
			},
			{
				permission: 'CREATE_EDI_FNS_UKD',
				title: 'documents.doctype.UKD_KSCHF_DIS_501_N',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				createCustomMethod: edi.document.actions.methodCreateUKDfromDocuments(
					part1Head.id,
					direction,
					edi.utils.getObjectProperty(part1, 'document.contentFactEconomLife2.acceptanceResult'),
					'UnitGrossPrice',
					modData,
					{
						version: '5.01-N',
						UKDFunction: 'KSCHF_DIS'
					}
				),
				config: {
					isFromTransformation: false,
					buyerOrg: initialFromOrg
				}
			}
		];

		let basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_UKD',
			data,
			moduleData,
			part1Head,
			null,
			createDocConfig
		);

		if (basedDocuments?.length) {
			customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			hasPart2: hasPart2,
			actionProps: {
				SIGN: {
					methodAddOptions: {
						contentId: isOutGoingDocument ? part1Head.id : part2Head?.id ? part2Head.id : null,
						useBeforeAction: !!(
							data.state === edi.constants.STATE.DRAFT || data.state === edi.constants.STATE.READ
						)
					}
				},
				EDIT: {
					component: editCmp
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				ANNUL: {
					methodAddOptions: annulDoc
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					addExtendedExport: true,
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE, {
						documentId: moduleData.initData.id
					}),
					extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: moduleData.initData.id
					}),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		if (data && data.id && (data.id == moduleData.initData.data.id || (part1Head && data.id == part1Head.id))) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				renderData();
			}
		}
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading(true);
		var continueWithRendering = function () {
			const businessState = edi.utils.getObjectProperty(documentHeaderData, 'businessState');

			var finishLoad = function () {
				moduleData.tab.removeAll();
				modulePanel.add(createDetailsPanel());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);

				if (
					annulDoc &&
					annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
					annulDoc.toOrg.id == edi.core.getUserOrgID()
				) {
					edi.methods.documents.showActiveAnnulModal(annulDoc, moduleData);
				}

				if ('function' == typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}
			};

			annulDoc = null;
			utochReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'UTOCH_REASON');
			rejectReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'REJECT_REASON');
			const isAnnualUtoch =
				utochReasonText ||
				businessState === edi.constants.STATE.ON_ANNUL ||
				businessState === edi.constants.STATE.ANNUL_SIGN;

			if (isAnnualUtoch) {
				edi.rest.sendRequest(
					edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
						depth: edi.constants.DEFAULT.TREE_DEPTH
					}),
					'GET',
					{},
					function (responseData) {
						if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
							for (var i = 0; i < responseData.items.length; i++) {
								if (
									isAnnualUtoch &&
									responseData.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL &&
									edi.constants.STATE.WAIT_PARTNER_DECISION === responseData.items[i].state
								) {
									annulDoc = responseData.items[i];
								}
							}
						}
						finishLoad();
					},
					failure
				);
			} else {
				finishLoad();
			}
		};

		var modulePanel = createDetailsModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var documentHeaderData = moduleData.initData.data;
		edi.document.actions.updateDocumentHeaderData(moduleData, function () {
			documentHeaderData = moduleData.initData.data;
			edi.rest.sendRequest(
				edi.document.actions.formatLinkedUri(moduleData.initData),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						var ids = [];
						for (var i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1) {
								ids.push(data.data.children[i].id);
							} else if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P2) {
								ids.push(data.data.children[i].id);
							}
							if (ids.length === 2) {
								break;
							}
						}
						if (ids.length) {
							edi.rest.sendRequest(
								edi.document.actions.formatBatchHeaderUri(moduleData.initData),
								'POST',
								Ext.encode(ids),
								function (data) {
									for (var i = 0; i < data.items.length; i++) {
										if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1) {
											part1Head = data.items[i];
											edi.document.actions.changeTabTitle(moduleData.tab, part1Head.number);
										} else if (
											data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P2
										) {
											part2Head = data.items[i];
										}
									}
									edi.rest.sendRequest(
										edi.document.actions.formatBatchContentUri(moduleData.initData),
										'POST',
										Ext.encode(ids),
										function (data) {
											if (data && data.items) {
												for (var i = 0; i < data.items.length; i++) {
													if (part1Head.id == data.items[i].header) {
														part1 = data.items[i];
													}
												}
												continueWithRendering();
											} else {
												failure(data);
											}
										},
										failure
									);
								}
							);
						} else {
							failure();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		});
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
