import {
	createButtonContainer,
	createFieldBlock,
	createMaxWidthContainer,
	createModalForm,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createGrid, createActionsColumnConfig, ROW_COLOR_CLS, createToolBar } from '@UIkit/components/grid';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createCombo, createDateField, createFile, createLabel, createTextField } from '@Components/fields';
import { createProxyConfig } from '@Components/storeComponents';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import { createAddButton, createCreateButton, createCreateSaveButton, createSendButton } from '@Components/buttons';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { getDocumentFormsDR, dpContainerSign } from '../methods';
import { getPackageAttachColumns, getPackageStatutoryColumns } from '../columns';
import { getPackageAttachModelName, getPackageStatutoryModelName } from '../models';

/**
 * @author Vsevolod Liapin
 *
 */
Ext.namespace('edi.modules');
edi.modules['document.create.package.dr'] = function () {
	var moduleData,
		form,
		isNumberAutoGenerated = false,
		numberDocument,
		documentsFormValues,
		documentData,
		userData = edi.core.getUserData(),
		isEdit = false,
		isCreate = false,
		buyerOrg,
		seller,
		buyer,
		attachGrid,
		dateDocument,
		statutoryAddButton,
		packageDocTypes,
		isCreateDogPostDr,
		attachDocuments,
		packageId,
		dogFileId,
		savePackageButton,
		availableStatutoryDocTypes,
		statutoryDocTypeStore,
		statutoryDocuments,
		statutoryGridStore,
		signPackageButton,
		sortedGridItems;
	let isOnDestroy = false;
	const documentFormsDR = getDocumentFormsDR();

	var initVariables = function () {
		if (moduleData.initData.data && moduleData.initData.data.toOrg) {
			buyerOrg = moduleData.initData.data.toOrg;
			documentData = moduleData.initData.data;
			isEdit = true;
			isCreate = true;
		}
		isNumberAutoGenerated = false;
		documentsFormValues = {};
		packageDocTypes = [];
		attachDocuments = [];
		sortedGridItems = [];
		statutoryDocTypeStore = edi.stores.createSimpleInlineStore([]);
		availableStatutoryDocTypes = Ext.clone(
			edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES[userData.org.orgType]
		);
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeAttachesHandler = function (data) {
		if (!(data && data.id && documentData && documentData.id && data.id === documentData.id)) {
			return;
		}
		getPackageLinkeds(data.id, data, function () {
			if (statutoryDocTypeStore && Array.isArray(availableStatutoryDocTypes)) {
				statutoryDocTypeStore.loadData(
					availableStatutoryDocTypes.map(function (item) {
						return {
							id: item,
							name: edi.i18n.getMessage('documents.doctype.' + item)
						};
					})
				);
			}
			if (statutoryGridStore && Array.isArray(statutoryDocuments)) {
				statutoryGridStore.loadData(statutoryDocuments);
			}
		});
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isBuyerValid = buyer.isValid();
		var isSellerValid = seller.isValid();
		return isBuyerValid && isSellerValid;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		if (data && data.id) {
			if (!(documentData && documentData.id && data.id === documentData.id)) {
				return;
			}
			if (data.action === 'save') {
				return;
			}
			var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				edi.modulesHandler.removeModule(moduleData);
			});
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.HEADER.GET,
					{
						documentId: data.id
					},
					true
				),
				'GET',
				undefined,
				function (data) {
					moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
					moduleData.tab.removeAll();
					if (data && data.data) {
						moduleData.initData.data = data.data;
						documentData = moduleData.initData.data;
					}
					initVariables();
					renderData();
					edi.events.documents.fireEvent('change');
				},
				failure
			);
		} else {
			moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
			moduleData.tab.removeAll();
			initVariables();
			renderData();
		}
	};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		initVariables();
		renderData(initCallBack);

		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documentsPackage.on('changeAttaches', changeAttachesHandler);
		edi.events.documentsPackage.on('change', changeHandler);
	};

	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		form.isValid();
		var validForm = !form.hasInvalidField();
		var validCompanies = validateCompanySelectors();
		var isValid = validForm && validCompanies;
		var validAttachDocs = !attachDocuments.some((item) => !item.isAttach);

		if (signPackageButton && !signPackageButton.isDestroyed) {
			signPackageButton.setDisabled(!isValid);
		}

		if (attachGrid) {
			attachGrid.getStore().reload();
		}

		if (statutoryAddButton) {
			if (isValid && packageId) {
				statutoryAddButton.enable();
			} else {
				statutoryAddButton.disable();
			}
		}

		return isValid && validAttachDocs;
	};

	/**
	 * Create invoice form
	 */
	var createMainDataBlock = function () {
		numberDocument = createTextField({
			fieldLabel: edi.i18n.getMessage('field.name.number'),
			emptyText: isNumberAutoGenerated ? edi.i18n.getMessage('document.generation.numbers.text') : '',
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			regex: edi.constants.VALIDATORS.PACKAGE_DOC_NUMBER,
			maxLength: 35,
			value:
				edi.utils.getObjectProperty(documentsFormValues, 'documentInfo.documentNumber') ||
				edi.utils.getObjectProperty(documentData, 'number'),
			name: 'number',
			listeners: {
				change: checkValid
			}
		});
		dateDocument = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			value: edi.utils.getObjectProperty(documentsFormValues, 'documentInfo.documentDate') || new Date(),
			maxValue: new Date(),
			name: 'doctime',
			submitFormat: edi.constants.DATE_FORMAT.FNS
		});

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				selectedRelationByMap: true,
				valuesByMap: false,
				useHiddenFields: true,
				disableAutoValidation: true,
				fieldsMapOnly: true,
				manualChangeDisabled: true,
				processValues: edi.converters.convertOrgToLegacyPartie
			});

			return createOrgSelector(selectorConfig);
		};

		var organizationFieldsMap = {
			company_iln: 'company_iln',
			company_name: 'company_name',
			contact_phone: 'contact_phone',
			contact_email: 'contact_email',
			contact_fax: 'contact_fax',
			addr_category: 'addr_category',
			addr_zip: 'addr_zip',
			addr_locality: 'addr_locality',
			addr_block: 'addr_block',
			addr_addInf: 'additionalInformation',
			addr_area: 'addr_area',
			addr_flat: 'addr_flat',
			addr_home: 'addr_home',
			addr_region_code: 'addr_region_code',
			addr_city: 'addr_city',
			addr_country: 'addr_country',
			addr_country_name: 'addr_country_name',
			addr_region_name: 'addr_region_name',
			addr_street: 'addr_street'
		};

		/**
		 * Checks company data validation
		 */
		var isValidOrganization = function (params) {
			params = params || {};

			var organization = params.organization;
			var useOrganizationMap = params.useOrganizationMap;

			function getPropertyName(keyName) {
				return useOrganizationMap ? organizationFieldsMap[keyName] : keyName;
			}

			return !!edi.utils.getObjectProperty(organization, getPropertyName('company_iln'));
		};

		var getOrganizationFieldsMap = function (prefix) {
			var newOrganizationFieldsMap = {};
			for (var field in organizationFieldsMap) {
				if (organizationFieldsMap.hasOwnProperty(field)) {
					newOrganizationFieldsMap[field] = prefix + '.' + organizationFieldsMap[field];
				}
			}
			return newOrganizationFieldsMap;
		};

		var modalConf = {
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_inn: edi.selectors.defaults.company_inn,
					company_register_number: edi.selectors.defaults.company_register_number,
					contact: edi.selectors.defaults.contact
				},
				address: {
					country: edi.selectors.defaults.iso2_country_combo,
					region: edi.selectors.defaults.region,
					city: edi.selectors.defaults.city,
					street: edi.selectors.defaults.street
				}
			}
		};

		const userOrg = userData.org;
		const userOrgValues = edi.converters.convertOrgToLegacyPartie(userOrg);
		const sellerOrg = edi.utils.getObjectProperty(documentData, 'fromOrg');
		const sellerValues = edi.converters.convertOrgToLegacyPartie(sellerOrg);
		const sellerOrgValues = sellerOrg ? sellerValues : userOrgValues;
		const isValidSeller = !!sellerOrgValues?.id;

		seller = createCS({
			selectedRelationByMap: isEdit,
			useHiddenFields: true,
			fieldValues: sellerOrgValues,
			selectedOrgValues: sellerOrgValues,
			selectedOrg: sellerOrg || userOrg,
			readOnly: isEdit,
			is_valid: isValidSeller,
			disableAutoValidation: true,
			fieldsMap: getOrganizationFieldsMap('bookingResponseParties.seller'),
			modalConf: Object.assign(
				{
					title: 'document.seller'
				},
				modalConf
			),
			callback: function (values) {
				seller.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'seller'
				});
				checkValid();
			}
		});
		const sellerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.seller'),
			items: [seller]
		});

		var buyerInfo = edi.converters.convertOrgToLegacyPartie(buyerOrg),
			isBuyerDefined = !!buyerInfo;

		var isValidBuyer = !!buyerInfo?.id;

		buyer = createCS({
			relationsOnly: true,
			allowBlank: false,
			is_valid: isValidBuyer,
			relations: !isCreate ? (!isEdit && buyerOrg ? buyerOrg : edi.relations.getRelations()) : undefined,
			allowReset: !isEdit && !isBuyerDefined,
			fieldValues: buyerInfo,
			readOnly: isEdit,
			selectedOrgValues: buyerInfo,
			selectedOrg: buyerOrg ? buyerOrg : null,
			selectedRelationByMap: isEdit || buyerOrg,
			callback: function (values, org) {
				var orgId = org ? org.id : isCreate && buyerOrg ? buyerOrg.id : null,
					sameOrgSelected = buyerOrg && buyerOrg.id == orgId;

				if (orgId && !sameOrgSelected) {
					buyerOrg = org;
				} else if (!orgId) {
					buyerOrg = null;
				}

				buyer.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'buyer'
				});

				checkValid();
			},
			fieldsMap: getOrganizationFieldsMap('bookingResponseParties.buyer'),
			modalConf: Object.assign(
				{
					title: 'document.buyer'
				},
				modalConf
			)
		});
		const buyerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.buyer'),
			items: [buyer]
		});

		return createMaxWidthContainer({
			layout: {
				type: 'grid',
				area: [
					[3, 2],
					[6, 6]
				]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [numberDocument, dateDocument, sellerBlock, buyerBlock]
		});
	};

	var deleteContainerIfEmpty = function () {
		var hasDocs =
			attachGrid.getStore().data.items.some((doc) => doc.get('id')) ||
			statutoryGridStore.data.items.some((doc) => doc.get('id'));
		if (!packageId || hasDocs) {
			return;
		}

		var success = function () {
			edi.events.documents.fireEvent('change', { id: packageId });
		};
		var failure = function () {};
		var url = edi.utils.formatString(
			edi.rest.services.DOCUMENTS.SEND.PUT,
			{
				documentId: packageId
			},
			true
		);
		var postData = {};
		postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE] = true;
		edi.rest.sendRequest(url, 'PUT', Ext.encode(postData), success, failure);
	};

	var createDocumentsAttachGrid = function () {
		var addedDocTypes = Ext.Array.difference(
			edi.constants.DOCUMENT_PACKAGES_DR_INCLUDES_DOC_TYPES,
			packageDocTypes
		);
		for (var i = 0; i < addedDocTypes.length; i++) {
			attachDocuments.push({
				id: null,
				type: addedDocTypes[i],
				isAttach: false
			});
		}

		var sortedDocTypes = [
			'DP_DOGPOSTDR',
			'DP_PRILPROTSOGLDR',
			'DP_PRILORGEDOCDR',
			'DP_PRILUVIZMCENDR',
			'DP_PRILOSTRBDR',
			'DP_PRILCOMMUSLDR',
			'DP_PRILSANKDR',
			'DP_PRILKPOSTDR',
			'DP_PRILKTOVDR',
			'DP_PRILSHSVERDR',
			'DP_PRILFORMZVTDR',
			'DP_PRILFAOPTUZDR',
			'DP_PRILANTIKORDR'
		];

		attachDocuments.forEach(function (item) {
			var index = sortedDocTypes.indexOf(item.type);
			sortedGridItems[index] = item;
		});
		var columns = getPackageAttachColumns();
		var actionColumnsConfig = [
			{
				getGlyph: function (value, metadata, record) {
					return record.get('isAttach') ? edi.constants.ICONS.EDIT : edi.constants.ICONS.ADD;
				},
				getClass: function (value, metadata, record) {
					return record.get('isAttach') ? 'test-action-column-edit' : 'test-action-column-add';
				},
				handler: function (grid, rowIndex) {
					var values,
						record = grid.getStore().getAt(rowIndex);
					var docType = record.get('type');
					var docId = record.get('id');

					var createDocument = function () {
						if (!docId && docType == 'DP_DOGPOSTDR') {
							Ext.merge(documentsFormValues, {
								documentInfo: {
									documentNumber: values.number,
									documentDate: values.doctime
								}
							});
						}
						var isEdit = !!docId;
						var titlePostfix = edi.i18n.getMessage('documents.doctype.' + docType);
						var customId = 'dp-attach-form-' + docType + (isEdit ? '-' + docId : '-new');
						var addConf = {
							docType: docType,
							formConfig: documentFormsDR[docType],
							formValues: !docId ? documentsFormValues : undefined,
							isCreateDogPostDr: isCreateDogPostDr ? documentsFormValues : undefined,
							packageId: packageId,
							buyerOrg: buyerOrg,
							dogFileId: dogFileId,
							documentHasCreated: function (formValues, documentId, callback) {
								signPackageButton.show();
								savePackageButton.show();
								if (statutoryAddButton) {
									statutoryAddButton.enable();
								}
								Ext.merge(documentsFormValues, formValues);
								record.set({
									isAttach: true,
									state: edi.constants.STATE.DRAFT,
									id: documentId
								});
								if (docType == 'DP_DOGPOSTDR') {
									isCreateDogPostDr = true;
									var attachStore = attachGrid.getStore().reload();
									attachStore.fireEvent('fireEvent', attachStore);
									edi.rest.sendRequest(
										edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.FOR_PARENT.GET, {
											parentId: documentId,
											docType: docType + '_P1'
										}),
										'GET',
										{},
										function (data) {
											if (data && data.data) {
												dogFileId = edi.utils.getObjectProperty(data.data, 'fileId');
											} else {
												failure(data);
											}
										},
										failure
									);
								}
								callback();
							},
							onClose: function (documentId) {
								if (docType === 'DP_DOGPOSTDR' && !documentId) {
									deleteContainerIfEmpty();
								}
							}
						};

						edi.core.openModule(
							'package.dr.document.form',
							isEdit ? { id: docId } : undefined,
							titlePostfix,
							isEdit,
							customId,
							addConf
						);
					};

					values = edi.utils.collectFormValues(form);
					if (!packageId && buyerOrg && buyerOrg.id) {
						var failure = edi.document.actions.createSaveErrorHandler(
							isEdit,
							moduleData,
							edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR
						);
						var success = function (data) {
							if (data && data.data && data.data.id) {
								documentData = data.data;
								packageId = data.data.id;
							}
							moduleData.tab.setLoading(false);
							if (packageId) {
								createDocument();
							}
						};

						edi.document.actions.processDocument(
							buyerOrg.id,
							undefined,
							edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR,
							undefined,
							undefined,
							success,
							failure,
							{
								data: '{}',
								number: values.number,
								toOrgId: buyerOrg.id,
								docType: edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR
							},
							{
								postData: {
									doctime: new Date().getTime()
								}
							}
						);
					} else {
						createDocument();
					}
				},
				isHidden: function (view, rowIndex, colIndex, item, record) {
					var docType = record.get('type');
					var isCreateFromBackAttachment =
						record.get('isAttach') &&
						(docType === 'DP_PRILORGEDOCDR' ||
							docType === 'DP_PRILUVIZMCENDR' ||
							docType === 'DP_PRILSANKDR' ||
							docType === 'DP_PRILSHSVERDR' ||
							docType === 'DP_PRILFORMZVTDR' ||
							docType === 'DP_PRILFAOPTUZDR' ||
							docType === 'DP_PRILANTIKORDR');
					return !(
						documentFormsDR.hasOwnProperty(record.get('type')) &&
						buyerOrg &&
						buyerOrg.id &&
						numberDocument.isValid() &&
						dateDocument.isValid() &&
						(docType === 'DP_DOGPOSTDR' || isCreateDogPostDr) &&
						!isCreateFromBackAttachment
					);
				}
			},
			{
				glyph: edi.constants.ICONS.PRINT,
				testCls: 'test-action-column-print',
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return !record.get('id');
				},
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex),
						recordData = record.getData();
					edi.document.actions.createPrintModal(
						recordData,
						{
							usingReport: true
						},
						recordData.id
					);
				}
			},
			{
				glyph: edi.constants.ICONS.REMOVE,
				testCls: 'test-action-column-remove',
				handler: function (grid, rowIndex) {
					edi.core.confirm(null, 'document.package.agreement.attachment.delete.confirm', function () {
						var store = grid.getStore();
						var record = store.getAt(rowIndex);
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.PUT, {
								documentId: record.get('id')
							}),
							'PUT',
							Ext.encode({ DELETE: true }),
							function () {
								edi.events.documentsPackage.fireEvent('change', {
									id: packageId
								});
							}
						);
					});
				},
				isHidden: function (view, rowIndex, colIndex, item, record) {
					var docType = record.get('type');
					var isCreateFromBackAttachment =
						record.get('isAttach') &&
						(docType === 'DP_PRILORGEDOCDR' ||
							docType === 'DP_PRILUVIZMCENDR' ||
							docType === 'DP_PRILSANKDR' ||
							docType === 'DP_PRILSHSVERDR' ||
							docType === 'DP_PRILFORMZVTDR' ||
							docType === 'DP_PRILFAOPTUZDR' ||
							docType === 'DP_PRILANTIKORDR');
					return !record.get('id') || record.get('type') === 'DP_DOGPOSTDR' || isCreateFromBackAttachment;
				}
			}
		];
		columns.push(
			createActionsColumnConfig({
				align: 'center',
				items: actionColumnsConfig
			})
		);

		return (attachGrid = createGrid({
			proxyConfig: createProxyConfig(),
			storeConfig: {
				model: getPackageAttachModelName(),
				data: {
					items: sortedGridItems
				}
			},
			gridConfig: {
				title: edi.i18n.getMessage('document.package.agreement.attachments'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columns: columns,
				region: 'center',
				disablePaging: true
			},
			viewConfig: {
				getRowClass: function (record) {
					return !record.get('isAttach') ? ROW_COLOR_CLS.error : '';
				}
			}
		}));
	};

	var createStatutoryDocumentsGrid = function () {
		var columns = getPackageStatutoryColumns();
		columns.push(
			createActionsColumnConfig({
				align: 'center',
				items: [
					{
						glyph: edi.constants.ICONS.CLOUD_DOWNLOAD,
						testCls: 'test-action-column-download',
						text: edi.i18n.getMessage('action.export.attachment'),
						handler: function (grid, rowIndex) {
							var store = grid.getStore();
							var recordData = store.getAt(rowIndex).getData();
							var url = edi.document.actions.formatExportAttachementUrl({
								id: recordData.id
							});
							edi.rest.downloadFile(url, recordData.id);
						}
					},
					{
						glyph: edi.constants.ICONS.REMOVE,
						testCls: 'test-action-column-remove',
						handler: function (grid, rowIndex) {
							edi.core.confirm(
								null,
								'document.package.agreement.statutory.document.delete.confirm',
								function () {
									var store = grid.getStore();
									var record = store.getAt(rowIndex);
									edi.rest.sendRequest(
										edi.utils.formatString(edi.rest.services.DOCUMENTS.PUT, {
											documentId: record.get('id')
										}),
										'PUT',
										Ext.encode({ DELETE: true }),
										function () {
											edi.events.documentsPackage.fireEvent('changeAttaches', {
												id: packageId
											});
										}
									);
								}
							);
						}
					},
					{
						glyph: edi.constants.ICONS.PRINT,
						testCls: 'test-action-column-print',
						isHidden: function (view, rowIndex, colIndex, item, record) {
							return !record.get('id') || record.get('type') !== edi.constants.DOCUMENT_TYPES.DP_PRAZN;
						},
						handler: function (grid, rowIndex) {
							var record = grid.getStore().getAt(rowIndex),
								recordData = record.getData();
							edi.document.actions.createPrintModal(
								recordData,
								{
									usingReport: true
								},
								recordData.id
							);
						}
					}
				]
			})
		);

		if (statutoryDocTypeStore && Array.isArray(availableStatutoryDocTypes)) {
			statutoryDocTypeStore.loadData(
				availableStatutoryDocTypes.map(function (item) {
					return {
						id: item,
						name: edi.i18n.getMessage('documents.doctype.' + item)
					};
				})
			);
		}

		return createGrid({
			proxyConfig: createProxyConfig(),
			storeConfig: {
				model: getPackageStatutoryModelName(),
				data: {
					items: statutoryDocuments
				}
			},
			gridConfig: {
				title: edi.i18n.getMessage('document.package.agreement.statutory.documents'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columns: columns,
				region: 'center',
				disablePaging: true,
				dockedItems: createToolBar({
					cls: 'edi-product-grid-toolbar',
					dock: 'bottom',
					items: [
						(statutoryAddButton = createAddButton(
							() => {
								var statutoryDocType,
									uploadForm = createModalForm({
										submitEmptyText: false,
										items: [
											createFieldBlock({
												cls: FIELD_BLOCK_CLS.small,
												title: edi.i18n.getMessage('documents.form.type'),
												items: [
													(statutoryDocType = createCombo({
														name: 'statutoryDocType',
														store: statutoryDocTypeStore,
														displayField: 'name',
														allowBlank: false,
														forceSelection: true
													}))
												]
											}),
											createFieldBlock({
												cls: FIELD_BLOCK_CLS.small,
												title: edi.i18n.getMessage('document.file'),
												items: [
													createFile(
														{},
														{
															name: 'attachment',
															allowBlank: false,
															listeners: edi.fields.config.pdfFilefieldListeners
														}
													)
												]
											}),
											createLabel({
												text: edi.i18n.getMessage('document.package.upload.file.limit.text')
											})
										]
									});

								var modal = createModalPanel({
									title: edi.i18n.getMessage('document.package.agreement.statutory.document.upload'),
									width: MODAL_SIZE.widthLarge,
									items: [uploadForm],
									buttonsBefore: [
										createCreateButton(
											function () {
												if (edi.utils.searchErrorField(uploadForm)) return;

												var failure = function (data) {
													edi.core.logMessage(
														'Error uploading dsf file' +
															(data && data.status ? ' status - ' + data.status : ''),
														'warn'
													);
													edi.core.showError(
														edi.utils.formatComplexServerError(
															data,
															data && data.status
																? 'error.server.' + data.status
																: 'dsf.upload.failure'
														),
														function () {
															moduleData.tab.setLoading(false);
														}
													);
												};
												var statutoryDocTypeValue = statutoryDocType.getValue();
												edi.core.submitUploadForm(
													uploadForm,
													edi.rest.services.DSF.FILE.POST,
													'dsf.create.saving',
													function (responseData) {
														if (responseData && responseData.data && responseData.data.id) {
															edi.document.actions.processDocument(
																buyerOrg.id,
																undefined,
																statutoryDocTypeValue,
																packageId,
																undefined,
																function (containerData) {
																	edi.events.documentsPackage.fireEvent(
																		'changeAttaches',
																		{
																			id: packageId
																		}
																	);
																	modal.close();
																},
																failure,
																{
																	data: Ext.encode({ file: responseData.data.id }),
																	date: Ext.Date.format(new Date(), 'd-m-Y')
																}
															);
														} else {
															failure(responseData);
														}
													},
													failure
												);
											},
											{
												scale: 'small',
												bindToForm: form,
												formBind: true
											}
										)
									]
								});
								modal.show();
							},
							{
								disabled: true
							}
						))
					]
				})
			}
		});
	};

	var createModuleForm = function () {
		signPackageButton = createSendButton(
			function () {
				moduleData.tab.setLoading(true);
				dpContainerSign(moduleData.initData.data || documentData, moduleData.tab);
			},
			{
				hidden:
					!moduleData.initData.data ||
					(moduleData.initData.data.state != edi.constants.STATE.DRAFT &&
						moduleData.initData.data.state != edi.constants.STATE.SENDER_REVIEW),
				text: edi.i18n.getMessage('document.sign.and.send.document')
			}
		);
		savePackageButton = createCreateSaveButton(
			{
				formBind: true,
				hidden:
					!isEdit ||
					(moduleData.initData.data && moduleData.initData.data.state != edi.constants.STATE.DRAFT),
				handler: function () {
					moduleData.tab.setLoading(true);
					var values = edi.utils.collectFormValues(form);
					var afterSave = function (documentId, callback) {
						callback();
						edi.events.documentsPackage.fireEvent('change', {
							action: 'save',
							id: documentId
						});
					};
					var failure = edi.document.actions.createSaveErrorHandler(
						isEdit,
						moduleData,
						edi.constants.DOCUMENT_TYPES.DP_CONTAINER
					);
					var success = edi.document.actions.createSaveSuccessHandler(
						moduleData,
						isEdit ? moduleData.initData.data.id : undefined,
						afterSave,
						isEdit
					);

					edi.document.actions.processDocument(
						buyerOrg.id,
						undefined,
						edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR,
						undefined,
						moduleData.initData.data.id,
						success,
						failure,
						{
							data: {
								number: values.number,
								doctime: values.doctime
							},
							number: values.number,
							date: values.doctime,
							toOrgId: buyerOrg.id,
							docType: edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR
						}
					);
				}
			},
			isEdit
		);

		var statutoryGrid = createStatutoryDocumentsGrid();
		statutoryGridStore = statutoryGrid.getStore();
		form = createFormForModule({
			cls: 'edi-form document-add-form',
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage(
						isEdit ? 'documents.doctype.DP_CONTAINER_DR' : 'document.create.package.dr'
					)
				}),
				createMainDataBlock(),
				createDocumentsAttachGrid(),
				statutoryGrid
			],
			buttons: createButtonContainer({
				items: [signPackageButton, savePackageButton]
			})
		});

		form.on('validitychange', checkValid);
		form.isValid();

		//if (isCreate) {
		//    edi.utils.processModuleFormChange(form, moduleData);
		//}

		return form;
	};

	var getPackageLinkeds = function (packageId, responseData, callback) {
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		statutoryDocuments = [];

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
				documentId: packageId
			}),
			'GET',
			undefined,
			function (data) {
				if (data && data.data && data.data.children && data.data.children.length) {
					var idDogPostDr,
						attachedTypes = {};
					for (var i = 0; i < data.data.children.length; i++) {
						var attach = data.data.children[i];
						if (
							edi.constants.DOCUMENT_PACKAGES_DR_INCLUDES_DOC_TYPES.some((it) => it === attach.type) &&
							attach.state != edi.constants.STATE.DELETED
						) {
							attachDocuments.push(
								Object.assign(attach, {
									isAttach: true
								})
							);
							packageDocTypes.push(attach.type);

							if (attach.type == 'DP_DOGPOSTDR') {
								idDogPostDr = attach.id;
							}
						} else if (
							edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES[userData.org.orgType].some(
								(it) => it === attach.type
							) &&
							attach.state != edi.constants.STATE.DELETED
						) {
							if (attach.type != 'DP_USTDOK_ADDITIONAL' && attach.type != 'DP_USTDOKADDITIONAL') {
								attachedTypes[attach.type] = true;
							}
							statutoryDocuments.push({
								id: attach.id,
								type: attach.type,
								state: attach.state
							});
						}
					}

					var allStatutoryDocTypes = Ext.clone(
						edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES[userData.org.orgType]
					);
					availableStatutoryDocTypes = allStatutoryDocTypes.filter(function (type) {
						return !attachedTypes[type];
					});

					callback({
						idDogPostDr: idDogPostDr
					});
					if (responseData && responseData.state === edi.constants.STATE.SENT && responseData.type) {
						//moduleData.isChanged = false;
						moduleData.tab.setLoading(false);
						edi.document.actions.openDetailsModule(responseData.type, responseData);
						edi.modulesHandler.removeModule(moduleData);
					}
				} else {
					callback({});
				}
			},
			failure
		);
	};

	var renderData = function (initCallBack) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var createModulePanel = function () {
			if (!isOnDestroy) {
				var form = createModuleForm();
				modulePanel.add(form);
				moduleData.tab.add(modulePanel);
			}
			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};

		if (data && data.id) {
			isEdit = true;
			packageId = data.id;
			buyerOrg = data.toOrg;
			getPackageLinkeds(packageId, data, function (data) {
				if (data.idDogPostDr) {
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.FOR_PARENT.GET, {
							parentId: data.idDogPostDr,
							docType: 'DP_DOGPOSTDR_P1'
						}),
						'GET',
						{},
						function (data) {
							isCreateDogPostDr = true;
							if (data && data.data) {
								documentsFormValues = edi.utils.getObjectProperty(data.data, 'document');
								dogFileId = edi.utils.getObjectProperty(data.data, 'fileId');
							}
							createModulePanel();
						},
						failure
					);
				} else {
					createModulePanel();
				}
			});
		} else {
			createModulePanel();
		}
	};

	var onDestroy = function () {
		isOnDestroy = true;
		edi.events.documentsPackage.un('changeAttaches', changeAttachesHandler);
		edi.events.documentsPackage.un('change', changeHandler);
		edi.events.documents.fireEvent('create');

		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
