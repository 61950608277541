import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createField, createFieldSet, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createActionsButton } from '@Components/buttons';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';

/**
 * Class for iftmbf details
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.modules');
edi.modules['document.details.iftmbf_approval'] = function () {
	var moduleData,
		docData = null;
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	var createPanelFn = function (items, panelConfig) {
		panelConfig = Object.assign(
			{
				columnWidth: 1,
				layout: 'column',
				cls: 'edi-create-field-line',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: items
			},
			panelConfig
		);

		return createPanel(panelConfig);
	};

	var createLabelFn = function (config) {
		var label = createLabel(
			Object.assign(
				{
					valueLabel: true,
					columnWidth: config.labelWidth || 0.5,
					text: config.text || edi.utils.getObjectProperty(docData, config.fieldName)
				},
				config.labelConfig || {}
			)
		);

		//If title is defined, means we hedd to create field
		return config.title
			? createField({
					title: edi.i18n.getMessage(config.title),
					input: label,
					containerConfig: Object.assign(
						{
							columnWidth: config.containerWidth || 1
						},
						config.containerConfig || {}
					)
			  })
			: label;
	};

	var createTransportInfoItems = function () {
		var cargoName = createLabelFn({
			title: 'document.iftmbf_approval.cargo.name',
			fieldName: 'cargo.name',
			labelWidth: 0.8,
			containerConfig: {
				margin: '0 10 5 10',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			}
		});

		var cargoInfo = createPanelFn([
			createLabelFn({
				title: 'document.iftmbf_approval.unitOfMeasure',
				fieldName: 'cargo.unitOfMeasure',
				containerWidth: 0.4
			}),
			createLabelFn({
				title: 'document.iftmbf_approval.palletsCount',
				fieldName: 'cargo.pallet',
				labelWidth: 0.6,
				containerConfig: {
					margin: '0 0 0 20',
					columnWidth: 0.6
				}
			})
		]);

		var weightAndTemperature = createPanelFn([
			createLabelFn({
				title: 'document.iftmbf_approval.weight',
				fieldName: 'cargo.weight',
				containerWidth: 0.4
			}),
			createField({
				title: 'document.iftmbf_approval.temperature',
				columnWidth: 1,
				layout: 'column',
				cls: 'edi-create-field-line',
				input: createPanel({
					columnWidth: 0.65,
					margin: '0 0 0 20',
					items: [
						createLabelFn({
							labelWidth: 0.5,
							text:
								edi.i18n.getMessage('document.iftmbf_approval.temp.min') +
								edi.utils.getObjectProperty(docData, 'cargo.temperatureConditions.min')
						}),
						createLabelFn({
							labelWidth: 0.5,
							text:
								edi.i18n.getMessage('document.iftmbf_approval.temp.max') +
								edi.utils.getObjectProperty(docData, 'cargo.temperatureConditions.max'),
							labelConfig: {
								margin: '0 0 0 10'
							}
						})
					]
				}),
				containerConfig: {
					margin: '0 0 0 20',
					columnWidth: 0.6
				}
			})
		]);

		var loadingType = createLabelFn({
			title: 'document.iftmbf_approval.cargo.loadingType',
			fieldName: 'cargo.loadingType.name',
			labelWidth: 0.8,
			containerConfig: {
				margin: '0 10 5 10',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			}
		});

		var transportInfo = createPanelFn([
			createLabelFn({
				title: 'document.iftmbf_approval.cargo.mountingTools',
				text: edi.renderers.yesNo(edi.utils.getObjectProperty(docData, 'carRequirements.mountingTools')),
				containerWidth: 0.4
			}),
			createLabelFn({
				title: 'document.iftmbf_approval.carRequirements.carType',
				fieldName: 'carRequirements.truckType.name',
				labelWidth: 0.8,
				containerConfig: {
					margin: '0 0 0 20',
					columnWidth: 0.6
				}
			})
		]);

		var remarks = createLabelFn({
			title: 'document.iftmbf.transportInfo.remarks',
			fieldName: 'specialConditions',
			labelWidth: 0.8,
			containerConfig: {
				margin: '10 10 5 10',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			}
		});

		return createFieldSet({
			title: edi.i18n.getMessage('document.iftmbf_approval.cargoInfo'),
			items: [
				cargoName,
				cargoInfo,
				weightAndTemperature,
				remarks,
				loadingType,
				transportInfo,
				createFieldSet({
					margin: '10 0 0 0',
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.neededClip'),
					items: [
						createPanelFn([
							createLabelFn({
								title: 'document.iftmbf.transportInfo.crossbarRequired',
								text: edi.renderers.yesNo(
									edi.utils.getObjectProperty(
										docData,
										'carRequirements.requirementForEquipment.crossbarRequired.value'
									)
								),
								labelWidth: 0.6,
								containerWidth: 0.5
							}),
							createLabelFn({
								title: 'document.iftmbf.transportInfo.beltsRequired',
								text: edi.renderers.yesNo(
									edi.utils.getObjectProperty(
										docData,
										'carRequirements.requirementForEquipment.beltsRequired.value'
									)
								),
								labelWidth: 0.6,
								containerConfig: {
									margin: '0 0 0 5',
									columnWidth: 0.5
								}
							})
						]),

						createPanelFn([
							createLabelFn({
								title: 'document.iftmbf.transportInfo.fasteningDevicesRequired',
								text: edi.renderers.yesNo(
									edi.utils.getObjectProperty(
										docData,
										'carRequirements.requirementForEquipment.fasteningDevicesRequired.value'
									)
								),
								labelWidth: 0.6,
								containerWidth: 0.5
							})
						]),
						createLabelFn({
							title: 'document.iftmbf.transportInfo.otherRequired',
							fieldName: 'carRequirements.requirementForEquipment.otherRequired.text',
							labelWidth: 0.8,
							containerConfig: {
								margin: '0 10 5 10',
								userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
							}
						})
					]
				})
			]
		});
	};

	var createRateInfoBlock = function () {
		var currency = createLabelFn({
			title: 'document.iftmbf_approval.cargo.currency',
			fieldName: 'rateInfo.currency.name',
			labelWidth: 0.8,
			containerConfig: {
				margin: '0 10 5 10',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			}
		});

		var selectedRate = edi.utils.getObjectProperty(docData, 'rateInfo.taxRate');
		var taxRates = createPanelFn([
			createLabelFn({
				title: 'document.iftmbf_approval.cargo.rate',
				fieldName: 'rateInfo.rate',
				containerWidth: 0.4
			}),
			createLabelFn({
				title: 'document.iftmbf_approval.cargo.taxRate',
				text: edi.renderers.taxRateString(selectedRate),
				labelWidth: 0.8,
				containerConfig: {
					margin: '0 0 0 20',
					columnWidth: 0.6
				}
			})
		]);

		return createFieldSet({
			title: edi.i18n.getMessage('document.iftmbf_approval.rate.block'),
			items: [currency, taxRates]
		});
	};

	/**
	 * Creates document form
	 * @returns {Object}
	 */
	var createDetailsPanel = function () {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSumm: true,
			noUsage: true
		});

		var mainDataInputs = createPanelFn([
			createLabelFn({
				title: 'document.iftmbf.documentNumber',
				fieldName: 'offerNumber',
				containerWidth: 0.6
			}),
			createLabelFn({
				text: edi.i18n.getMessage(
					'document.iftmbf_approval.offerType.' + edi.utils.getObjectProperty(docData, 'offerType')
				),
				labelWidth: 0.4,
				labelConfig: {
					margin: '0 0 0 10'
				}
			})
		]);

		var uniqueApplicationId = createLabelFn({
			title: 'document.iftmbf.uuid',
			fieldName: 'idTrnsrdr',
			labelWidth: 0.7,
			containerConfig: {
				margin: '0 10 5 10',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			}
		});

		var startDateTime = createPanelFn([
			createLabelFn({
				title: 'document.iftmbf_approval.startDate',
				text: edi.utils.formatDate(
					docData.startDate,
					edi.constants.DATE_FORMAT.FNS,
					edi.constants.DATE_FORMAT.SERVER
				),
				labelWidth: 0.625,
				containerWidth: 0.8
			}),
			createLabelFn({
				fieldName: 'startTime',
				labelWidth: 0.2,
				labelConfig: {
					margin: '0 0 0 10'
				}
			})
		]);

		var orderNumber = createLabelFn({
			title: 'document.iftmbf_approval.orderNumber',
			fieldName: 'orderNumber',
			labelWidth: 0.7,
			containerConfig: {
				margin: '0 10 5 10',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			}
		});

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				labelConfig: {
					columnWidth: 0.3,
					title: selectorConfig.title
				},
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columnWidth: 0.7,
				fieldsMapOnly: true,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				fieldValues: docData
			});

			return createCompanySelector(selectorConfig);
		};

		var seller = createCS({
			title: 'document.seller',
			fieldsMap: {
				company_iln: 'shipper.gln'
			}
		});

		var buyer = createCS({
			title: 'document.buyer',
			fieldsMap: {
				company_org_form: 'companyDescription.opf',
				company_name: 'companyDescription.name',
				company_inn: 'companyDescription.inn',
				company_iln: 'companyDescription.gln',
				company_okpo: 'companyDescription.okpo',
				company_okonh: 'companyDescription.okonh',
				address: 'companyDescription.address',
				bank_id: 'companyDescription.bik',
				bank_acc: 'companyDescription.checkingAccount',
				bank_corr_acc: 'companyDescription.correspondentAccount',
				bank_address: 'companyDescription.bankAddress',
				contact_phone: 'companyDescription.phone'
			}
		});

		var twoColumnsLayout = createTwoColumnsLayout(
			[mainDataInputs, startDateTime, uniqueApplicationId, orderNumber, seller, buyer],
			[createTransportInfoItems(), createRateInfoBlock()],
			0.5
		);

		var routesValues = { products: null },
			routesLines = edi.utils.getObjectProperty(docData, 'routes.route', true);
		if ('string' != typeof routesLines) {
			routesValues.products = routesLines;
		}

		var routesGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.iftmbf.routes'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'IFTMBF_APPROVAL_ROUTE',
			gridColumnConfig: 'iftmbf_approval_route',
			data: routesValues.products,
			readOnly: true,
			hasTotal: false,
			changeValuesBeforeEdit: function (values) {
				if (values.dateFrom) {
					values.dateFrom = edi.utils.formatDate(
						values.dateFrom.replace('T', ' '),
						edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
						edi.constants.DATE_FORMAT.DOCUMENT
					);
				}
				if (values.dateTo) {
					values.dateTo = edi.utils.formatDate(
						values.dateTo.replace('T', ' '),
						edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
						edi.constants.DATE_FORMAT.DOCUMENT
					);
				}
			},
			modalFormConfig: {
				title: 'document.iftmbf.routes',
				modalFields: [
					{
						title: edi.i18n.getMessage('documents.iftmbf.line.modal.tab.main.data'),
						items: [
							{
								title: edi.i18n.getMessage('document.iftmbf.routes.dateFrom'),
								name: 'dateFrom',
								type: 'label'
							},
							{
								title: edi.i18n.getMessage('document.iftmbf.routes.dateTo'),
								name: 'dateTo',
								type: 'label'
							},
							{
								title: edi.i18n.getMessage('column.timing'),
								regex: /^[0-9]{2}$/i,
								name: 'timing'
							},
							{
								title: edi.i18n.getMessage('column.point.ilnFrom'),
								name: 'routeFrom',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.iftmbf.routes.addressFrom'),
								name: 'addressfrom'
							},
							{
								title: edi.i18n.getMessage('column.point.ilnTo'),
								name: 'routeTo',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.iftmbf.routes.addressTo'),
								name: 'addressto'
							}
						]
					}
				]
			}
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, routesGrid]
		});
	};

	/**
	 * Creates action pane
	 */
	var createModuleActionsPanel = function (docData) {
		var data = moduleData.initData.data;
		docData.id = data.id;
		var actionsPanel = createActionsPanel();
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);

		var excludeActions = {};
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PACKAGING] = true;

		const customButtons = [];
		if (
			edi.action.isAvailable(
				'CREATE_FROM',
				edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data))
			)
		) {
			const getCreateDocButtonConfig = function (title, docType, documentProperty, docContent, parent) {
				return {
					text: edi.i18n.getMessage(title),
					handler: function () {
						edi.methods.transformations.createDocViaTransformation({
							docType: docType,
							parentId: parent.id,
							buyerOrg:
								edi.constants.DIRECTIONS.OUTGOING === direction
									? moduleData.initData.data.toOrg
									: moduleData.initData.data.fromOrg,
							moduleTab: moduleData.tab
						});
					}
				};
			};

			const createDocsByTransform = [
				{
					permission: 'CREATE_EDI_IFTMBC_APPROVAL',
					title: 'document.create.iftmbc_approval',
					docType: edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL,
					createMethod: getCreateDocButtonConfig
				}
			];

			const actionsCreateDoc = [];
			createDocsByTransform.forEach((action) => {
				if ('string' == typeof action.permission && edi.permissions.hasPermission(action.permission)) {
					actionsCreateDoc.push(getCreateDocButtonConfig(action.title, action.docType, 'recadv', null, data));
				}
			});

			if (actionsCreateDoc.length) {
				customButtons.push(
					createActionsButton({
						showInFirstHalf: true,
						order: 500,
						text: edi.i18n.getMessage('action.create'),
						glyph: edi.constants.ICONS.ADD,
						allowMultiClick: true,
						menu: {
							plain: true,
							hideMode: 'display',
							items: actionsCreateDoc
						}
					})
				);
			}
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			excludeActions: excludeActions,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.iftmbf_approval'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					docData = data.data;
					var buyerGln = edi.utils.getObjectProperty(docData, 'customer.gln');
					edi.utils.setObjectProperty(docData, 'companyDescription.gln', buyerGln);
					var continueLoading = function () {
						modulePanel.add(createDetailsPanel());
						var actionsPanel = createModuleActionsPanel(docData);
						moduleData.tab.add(actionsPanel);
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						} else {
							moduleData.tab.setLoading(false);
						}
					};
					continueLoading();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
