import { showConfirmWithException } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { ROW_COLOR_CLS } from '@UIkit/components/grid';

import './styles.scss';
import { createLabel, createLabelBlockForDetails } from '@UIkit/components/fields';
export const errorAndWarningsConfig = {
	ERROR_PROCESSING_STATE_ATTR_NAME: 'errorProcessingState'
};
export const errorsAndWarningsMethods = {
	createCopyButton: function ({ docHeaderData, handler }) {
		const isAvailable = edi.action.isAvailable(
			edi.constants.DOCUMENT_ACTIONS.COPY,
			edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', docHeaderData))
		);

		if (isAvailable) {
			return createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('copy.and.change.document.action'),
				handler
			});
		}
	},
	showErrorConfirmWithException: async function ({ docHeaderData, callback }) {
		const { toOrg, fromOrg, attributes } = docHeaderData || {};
		const errorMessage = edi.utils.getAttributeByName(attributes, 'errorMessage', null);
		const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.MESSAGE.BLOCKING.GET, {
			id: docHeaderData.id
		});
		const {
			data: { data: blockingMessage }
		} = await edi.rest.asyncSendRequest({ url });
		const direction = edi.utils.getDocumentDirection(toOrg, fromOrg);
		const isOutgoing = [edi.constants.DIRECTIONS.OUTGOING, edi.constants.DIRECTIONS.LOOP].includes(direction);
		let error = errorMessage || blockingMessage;
		const errorMessagesList = error?.split(' , ') ?? [];
		const errorMessagesCls = 'ui-messagebox-exception exception-type-error';

		if (blockingMessage === 'BlockedByActionWithDoc') {
			error = edi.i18n.getMessage('document.blockingMessage.BlockedByActionWithDoc');
		}

		const modal = showConfirmWithException({
			title: edi.i18n.getMessage('error.processing.document.header'),
			exceptionType: 'error',
			exceptionText: error,
			items: [
				errorMessagesList.length > 1
					? createLabelBlockForDetails({
							cls: errorMessagesCls,
							autoEl: 'ul',
							contents: errorMessagesList.map((warning) => ({
								text: `"${warning}"`,
								autoEl: 'li'
							}))
					  })
					: createLabel({
							cls: errorMessagesCls,
							text: error
					  }),
				isOutgoing
					? createLabel({
							cls: 'ui-messagebox-msg-text',
							margin: '8 24',
							text: edi.i18n.getMessage('error.processing.document.body')
					  })
					: null
			],
			buttons: isOutgoing
				? [
						errorsAndWarningsMethods.createCopyButton({
							docHeaderData,
							handler: function () {
								modal.close();
								edi.document.actions.copyUsingUI({ documentHeader: docHeaderData });
							}
						})
				  ]
				: []
		});

		if (typeof callback === 'function') {
			callback();
		}
	},
	showWarningConfirmWithException: async function ({ docHeaderData, callback }) {
		const { toOrg, fromOrg } = docHeaderData || {};
		const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.MESSAGE.WARNING.GET, {
			id: docHeaderData.id
		});
		const {
			data: { data: warningMessage }
		} = await edi.rest.asyncSendRequest({ url });
		const direction = edi.utils.getDocumentDirection(toOrg, fromOrg);
		const isOutgoing = [edi.constants.DIRECTIONS.OUTGOING, edi.constants.DIRECTIONS.LOOP].includes(direction);
		const warningMessagesList = warningMessage?.split(' , ') ?? [];
		const warningMessagesCls = 'ui-messagebox-exception exception-type-warning';

		const modal = showConfirmWithException({
			title: edi.i18n.getMessage('warn'),
			items: [
				warningMessagesList.length > 1
					? createLabelBlockForDetails({
							cls: warningMessagesCls,
							autoEl: 'ul',
							contents: warningMessagesList.map((warning) => ({
								text: `"${warning}"`,
								autoEl: 'li'
							}))
					  })
					: createLabel({
							cls: warningMessagesCls,
							text: warningMessage
					  }),
				createLabel({
					cls: 'ui-messagebox-msg-text',
					margin: '8 24',
					text: edi.i18n.getMessage('warning.processing.document.text')
				}),
				isOutgoing
					? createLabel({
							cls: 'ui-messagebox-msg-text',
							margin: '8 24',
							text: edi.i18n.getMessage('processing.document.action')
					  })
					: null
			],
			buttons: isOutgoing
				? [
						errorsAndWarningsMethods.createCopyButton({
							docHeaderData,
							handler: function () {
								modal.close();
								edi.document.actions.copyUsingUI({ documentHeader: docHeaderData });
							}
						})
				  ]
				: []
		});

		if (typeof callback === 'function') {
			callback();
		}
	},
	getShowErrorDocumentAction: function (moduleData) {
		return {
			glyph: edi.constants.ICONS.ERROR,
			iconCls: 'documents-grid-warningAndErrorDocs icon-error',
			testCls: 'test-action-icon-show-error-document',
			handler: function (grid, rowIndex) {
				const record = grid.getStore().getAt(rowIndex);
				if (moduleData?.tab && !moduleData.tab.isDestroyed) {
					moduleData.tab.setLoading(true);
				}
				errorsAndWarningsMethods.showErrorConfirmWithException({
					callback: () => {
						if (moduleData?.tab && !moduleData.tab.isDestroyed) {
							moduleData.tab.setLoading(false);
						}
					},
					docHeaderData: record.getData()
				});
			},
			isHidden: function (grid, rowIndex) {
				const record = grid.getStore().getAt(rowIndex),
					attributes = record.get('attributes'),
					errorMessage =
						edi.utils.getAttributeByName(attributes, 'errorMessage', null) ||
						edi.utils.getAttributeByName(attributes, 'blockingMessage', null);
				return !errorMessage;
			}
		};
	},
	getShowWarningDocumentAction: function (moduleData) {
		return {
			glyph: edi.constants.ICONS.WARNING,
			iconCls: 'documents-grid-warningAndErrorDocs icon-warning',
			testCls: 'test-action-icon-show-warning-document',
			handler: function (grid, rowIndex) {
				const record = grid.getStore().getAt(rowIndex);
				if (moduleData?.tab && !moduleData.tab.isDestroyed) {
					moduleData.tab.setLoading(true);
				}
				errorsAndWarningsMethods.showWarningConfirmWithException({
					callback: function () {
						if (moduleData?.tab && !moduleData.tab.isDestroyed) {
							moduleData.tab.setLoading(false);
						}
					},
					docHeaderData: record.getData()
				});
			},
			isHidden: function (grid, rowIndex) {
				const record = grid.getStore().getAt(rowIndex),
					attributes = record.get('attributes'),
					errorMessage = edi.utils.getAttributeByName(attributes, 'errorMessage'),
					warningMessage = edi.utils.getAttributeByName(attributes, 'warningMessage');
				return !(warningMessage && !errorMessage);
			}
		};
	},
	getRowDocumentClass: function (record) {
		const attributes = record.get('attributes');
		const errorMessage =
			edi.utils.getAttributeByName(attributes, 'errorMessage', null) ||
			edi.utils.getAttributeByName(attributes, 'blockingMessage', null);
		if (errorMessage) {
			return ROW_COLOR_CLS.error + ' ' + ROW_COLOR_CLS.markedError;
		}
		const warningMessage = edi.utils.getAttributeByName(attributes, 'warningMessage', null);
		if (warningMessage) {
			return ROW_COLOR_CLS.warning + ' ' + ROW_COLOR_CLS.markedWarning;
		}
		return ROW_COLOR_CLS.valid;
	},
	/**
	 * Для колонок "Ошибки" и "Предупреждения", если ошибок/предупреждений несколько,
	 * каждая ошибка/предупреждение должны быть на своей строке
	 *
	 * Колонка1   | Ошибка   | Колонка3
	 * Значение11 | Ошибка11 | Значение13
	 *            | Ошибка12 |
	 *            | Ошибка13 |
	 * Значение21 | Ошибка21 | Значение23
	 */
	errorWarningCollectExportData: function ({ columns, selection, table }) {
		const me = this;
		const columnsWithMultiLineValue = ['errors', 'warnings'];
		const headers = columns.map((column) => me.getColumnName({ column }));
		const data = {
			headers,
			data: []
		};
		selection.forEach((record) => {
			const rowData = [];
			const currentRowIndex = data.data.length;
			data.data[currentRowIndex] = [];
			columns.forEach((column, columnIndex) => {
				const columnValue = me.getColumnValue({ column, record, table });
				if (columnsWithMultiLineValue.includes(column.cName) && columnValue) {
					const valuesList = columnValue.split(' , ');
					valuesList.forEach((value, valueIndex) => {
						if (!valueIndex) {
							rowData.push(value);
						} else {
							const newRow = [];
							newRow[columns.length - 1] = ' ';
							newRow[columnIndex] = value;
							data.data.push(newRow);
						}
					});
				} else {
					rowData.push(columnValue);
				}
			});
			data.data[currentRowIndex] = rowData;
		});
		return data;
	},
	reprocessManuallyDocument: function (record, grid, callback, failure, options) {
		return function () {
			const gridSelModel = grid.getSelectionModel();
			const documentId = record.get('id');
			const success = function () {
				edi.events.documents.fireEvent('change', {
					id: documentId
				});
				gridSelModel.deselect(record);
			};
			const payload = Ext.encode({
				[errorAndWarningsConfig.ERROR_PROCESSING_STATE_ATTR_NAME]: 'processed'
			});
			const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.ATTRIBUTES.PUT, {
				docId: documentId
			});
			edi.rest.sendRequest(url, 'PUT', payload, success, (error) => failure(record, error), callback);
		};
	}
};
