const dsfClientMethods = {
	getCompanyFieldsMap: function (prefix) {
		return edi.selectors.getDSFFieldsMap(prefix);
	},
	getCompanyModalConf: function () {
		let bankFieldListeners = {
			regex: edi.constants.VALIDATORS.BANK_ACC,
			invalidText: edi.i18n.getMessage('invalid.value.bank.acc'),
			allowBlank: true
		};
		let modalAdditionalConf = {
			main: {
				company_okpo: edi.selectors.defaultsFNS.main.company_okpo,
				company_inn_kpp: {
					fields: {
						kpp: {
							allowBlank: true
						}
					}
				},
				individual_inn: {
					allowBlank: false
				}
			},
			address: {
				region: {
					fieldConfig: {
						validator: function () {
							return !!this.getValue() || edi.i18n.getMessage('error.value.mandatory');
						}
					}
				}
			},
			bank: {
				bank_acc_select: Ext.applyIf(
					{
						fieldConfig: {
							listeners: {
								render: (cmp) =>
									edi.selectors.fieldControls.setBankNameAllowBlankByBankAccValue({
										bankAccField: cmp
									}),
								change: (cmp, newValue) =>
									edi.selectors.fieldControls.setBankNameAllowBlankByBankAccValue({
										bankAccField: cmp,
										bankAccValue: newValue
									})
							}
						}
					},
					bankFieldListeners
				),
				bank_corr_acc: bankFieldListeners,
				bank_id: Ext.applyIf(
					{
						regex: edi.constants.VALIDATORS.BIK,
						invalidText: edi.i18n.getMessage('invalid.value.bank.id'),
						listeners: {
							change: function (cmp, newValue) {
								edi.methods.fillBankNameByBik(cmp, newValue);
							}
						}
					},
					bankFieldListeners
				),
				bank_name: {
					listeners: {
						render: function (cmp) {
							var modal = cmp.modalInstance,
								form = modal ? modal.formPanel : null,
								value = cmp.getValue();
							if (!form) {
								return;
							}
							var field = form.down('textfield[name=bank_acc]');
							field.allowBlank = !value.length;
							field.validate();
						},
						change: function (cmp, newValue) {
							var modal = cmp.modalInstance,
								form = modal ? modal.formPanel : null;
							if (!form) {
								return;
							}
							var field = form.down('textfield[name=bank_acc]');
							field.allowBlank = !newValue.length;
							field.validate();
						}
					}
				}
			}
		};
		return edi.selectors.getFNSModalConf(modalAdditionalConf);
	}
};

Ext.namespace('edi.modules.dsf_client.methods');
edi.modules.dsf_client.methods = dsfClientMethods;
export { dsfClientMethods };
