import { AbstractPanel } from './abstract.panel.js';
import { createActionsColumnConfig, createGrid } from '@UIkit/components/grid';
import { createCombo, createTextField } from '@UIkit/components/fields';
import { createModalPanel } from '@UIkit/components/modal';
import { createPanel, createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

class CredentialsPanel extends AbstractPanel {
	_grid;
	_panel;
	_moduleData;

	constructor(moduleData) {
		super();
		let me = this;
		me._moduleData = moduleData;

		let updateGrid = function () {
			if (!me.isDestroyed) {
				me.reloadCredentials();
			}
		};

		me._panel = createPanel({
			layout: 'border',
			onDestroy: function () {
				edi.events.credentials.un('change', updateGrid);
			}
		});

		let { items, initiator } = me.createCredentialsPanel();

		me._panel.add(items);

		edi.events.credentials.on('change', updateGrid);
		initiator();
	}

	getGrid() {
		return this._grid;
	}

	getPanel() {
		return this._panel;
	}

	reloadCredentials() {
		this.getGrid().getStore().reload();
	}

	createCredentialsPanel() {
		let me = this;
		let userCredentialsURL = edi.rest.services.USER_SELF_SIGNER_SETTINGS.GET;
		let failure = edi.document.actions.defaultFailureHandler(me._moduleData, 'error.server');

		let items = [];
		let columns = edi.columns.get('user_signer_settings');
		let addCredentailsButton = this.createAddButton();

		if (edi.permissions.hasPermission('CLIENT_UPDATE_EDI_SIGNER')) {
			items.push({
				glyph: edi.constants.ICONS.EDIT,
				handler: (grid, rowIndex) => {
					this.createSignerSettingModal(grid.getStore().getAt(rowIndex).getData());
				}
			});
		}

		if (edi.permissions.hasPermission('CLIENT_DELETE_EDI_SIGNER')) {
			items.push({
				glyph: edi.constants.ICONS.DELETE,
				handler: function (grid, rowIndex) {
					edi.core.confirm(null, 'user.profile.form.signer.settings.delete.confirm', function () {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.USER_SELF_SIGNER_SETTINGS.DELETE,
								{
									id: grid.getStore().getAt(rowIndex).get('id')
								},
								false
							),
							'DELETE',
							null,
							undefined,
							failure,
							function () {
								me._grid.getStore().reload();
							}
						);
					});
				}
			});
		}

		columns.push(
			createActionsColumnConfig({
				items: items,
				width: edi.utils.getActionColumnWidth(items.length)
			})
		);

		me._grid = createGrid({
			storeConfig: {
				model: edi.models.getModel('USER_SIGNER_SETTING'),
				sortOnLoad: true,
				autoLoad: true,
				sorters: {
					property: 'creationDate',
					direction: 'DESC'
				}
			},
			gridConfig: {
				region: 'center',
				border: 0,
				viewConfig: {
					emptyTextTplOptions: {
						enabled: true,
						iconName: 'credentials',
						title: edi.i18n.getMessage('ediweb.credentials.empty.text.tpl.title'),
						contentText: edi.i18n.getMessage('ediweb.credentials.empty.text.tpl.contentText')
					}
				},
				autoScroll: true,
				disableSelection: true,
				columns: columns,
				disablePaging: true,
				listeners: {
					celldblclick: (view, td, cellIndex, record) => {
						this.createSignerSettingModal(record.getData());
					}
				},
				tbar: {
					items: [addCredentailsButton]
				}
			},
			proxyConfig: {
				type: 'ajax',
				url: userCredentialsURL
			}
		});

		return {
			items: [me._grid],
			initiator: () => {}
		};
	}

	createAddButton() {
		return createButton({
			text: edi.i18n.getMessage('form.btn.add.credentials'),
			cls: [BUTTON_CLS.primary],
			glyph: edi.constants.ICONS.PLUS,
			handler: () => {
				this.createSignerSettingModal();
			}
		});
	}

	createSignerSettingModal(objectData) {
		let me = this;
		var statusesFullStore = edi.stores.initSignerBuyerStatusesUPD501NStore();

		var authorityAreasFullStore = edi.stores.initAuthorityAreasFullStore();

		var updateAuthorityAndStatus = function (docType, direction) {
			if (!docType || !direction) {
				return;
			}

			let authorityAreaInput = modalForm.down('[name=authorityArea]');
			let prevAuthorityAreaValue = authorityAreaInput.getValue();
			authorityAreasFullStore.loadData(
				Ext.clone(
					docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
						? edi.stores.data.torg2AuthorityAreas[direction]
						: edi.stores.data.fullAuthorityAreas
				)
			);

			let areaIds = edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_DOC_TYPES[docType][direction];
			authorityAreasFullStore.clearFilter(true);
			authorityAreasFullStore.filter({
				filterFn: function (item) {
					return areaIds.some((it) => it === parseInt(item.get('id'), 10));
				}
			});
			// authorityAreasFullStore.reload();
			authorityAreaInput.setValue(prevAuthorityAreaValue);

			let statusInput = modalForm.down('[name=state]');
			let prevStatusValue = statusInput.getValue();
			statusesFullStore.loadData(
				Ext.clone(
					docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2
						? edi.stores.data.torg2SignerStatuses[direction]
						: direction === 'OUTBOX'
						? edi.stores.data.signerBuyerUPD501NStatuses
						: edi.stores.data.signerBuyerStatuses
				)
			);

			let statusIds = edi.constants.SIGN_DOCUMENTS.STATUSES_FOR_DOC_TYPES[docType][direction];
			statusesFullStore.clearFilter(true);
			statusesFullStore.filter({
				filterFn: function (item) {
					return statusIds.some((it) => it === parseInt(item.get('id'), 10));
				}
			});
			// statusesFullStore.reload();
			statusInput.setValue(prevStatusValue);
		};

		let isCreate = !objectData;
		let modalForm = createModalForm({
			layout: {
				type: 'grid',
				gap: [24]
			},
			items: [
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('column.doc.type'),
					items: [
						createCombo({
							name: 'docType',
							valueSrc: objectData,
							store: edi.stores.createMemoryStore(
								[
									{
										id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
										name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UPD')
									},
									{
										id: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
										name: edi.i18n.getMessage('documents.doctype.EDI_FNS_UKD')
									}
								],
								'SIMPLE',
								null
							),
							allowBlank: false,
							listeners: {
								change: function (comp, newValue) {
									var docType = newValue;
									var directionInput = modalForm.down('[name=direction]');
									var directionValue = directionInput.getValue();
									updateAuthorityAndStatus(docType, directionValue);
								}
							}
						})
					]
				}),
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('column.direction'),
					items: [
						createCombo({
							name: 'direction',
							valueSrc: objectData,
							store: edi.stores.createMemoryStore(
								[
									{
										id: 'INBOX',
										name: edi.i18n.getMessage('user.profile.signer.direction.INBOX')
									},
									{
										id: 'OUTBOX',
										name: edi.i18n.getMessage('user.profile.signer.direction.OUTBOX')
									}
								],
								'SIMPLE',
								null
							),
							listeners: {
								change: function (comp, newValue) {
									var direction = newValue;
									var docTypeInput = modalForm.down('[name=docType]');
									var docTypeValue = docTypeInput.getValue();
									updateAuthorityAndStatus(docTypeValue, direction);
								}
							},
							allowBlank: false
						})
					]
				}),
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
					items: [
						createCombo({
							title: 'documents.fns_upd.signer.authorityArea',
							name: 'authorityArea',
							valueSrc: objectData,
							type: 'combo',
							store: authorityAreasFullStore,
							displayField: 'name'
						})
					]
				}),
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('documents.fns_upd.signer.status'),
					items: [
						createCombo({
							name: 'state',
							valueSrc: objectData,
							store: statusesFullStore,
							listeners: {
								select: function (comp, record) {
									var stateRec = Array.isArray(record) ? record[0] : record;
									let state = stateRec.get('id');
									var baseAuthoryInput = modalForm.down('[name=baseAuthory]');
									var baseOrgAuthoryInput = modalForm.down('[name=baseOrgAuthory]');
									if (state == 4) {
										baseAuthoryInput.setValue(
											edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default.state.4')
										);
									} else {
										baseAuthoryInput.setValue(
											edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory.default')
										);
									}
									baseOrgAuthoryInput.allowBlank = state != 3;
									modalForm.isValid();
								}
							},
							displayField: 'name'
						})
					]
				}),
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
					items: [
						createTextField({
							maxLength: 255,
							name: 'baseAuthory',
							valueSrc: objectData,
							width: '100%'
						})
					]
				}),
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
					items: [
						createTextField({
							name: 'baseOrgAuthory',
							valueSrc: objectData,
							maxLength: 255,
							width: '100%',
							validator: function (value) {
								if (!value) {
									return true;
								}
								return (
									value === '' ||
									!edi.constants.VALIDATORS.SPACES.test(value) ||
									edi.i18n.getMessage('invalid.value.format')
								);
							}
						})
					]
				})
			]
		});

		let modal = createModalPanel({
			title: edi.i18n.getMessage('ediweb.user.profile.form.signer.settings'),
			items: [modalForm],
			buttonsBefore: [
				createButton({
					text: edi.i18n.getMessage('form.btn.save'),
					cls: BUTTON_CLS.primary,
					bindToForm: modalForm,
					formBind: true,
					disabled: true,
					handler: function () {
						let formValues = modalForm.getValues();
						let gridStore = me._grid.getStore();
						let notSaved;

						gridStore.each(function (record) {
							var recordData = record.getData();
							if (
								(isCreate || recordData.id != objectData.id) &&
								formValues.direction === recordData.direction &&
								formValues.docType === recordData.docType
							) {
								notSaved = edi.core.showError(
									'user.profile.form.signer.settings.create.same.direction.alert'
								);
							}
						});
						if (!notSaved) {
							var saveData = modalForm.getValues();
							edi.rest.sendRequest(
								isCreate
									? edi.rest.services.USER_SELF_SIGNER_SETTINGS.POST
									: edi.utils.formatString(edi.rest.services.USER_SELF_SIGNER_SETTINGS.PUT, {
											id: objectData.id
									  }),
								isCreate ? 'POST' : 'PUT',
								Ext.encode(saveData),
								function (data) {
									if (data.success) {
										gridStore.reload();
									}
								},
								edi.rest.getErrorHandler(),
								function () {
									modal.close();
									modal.setLoading(false);
								}
							);
						}
					}
				})
			]
		});

		modal.show();
	}
}

export { CredentialsPanel };
