import {
	createPanel,
	createContainer,
	createFieldSetForDetails,
	createFieldBlockForDetails,
	FIELD_BLOCK_CLS,
	createMaxWidthContainerForDetail
} from '@UIkit/components/panels';
import { createLabelForDetails, createLabelBlockForDetails } from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import {
	getPurposeTranslation,
	getPurposeTypeValue,
	hasSchfInBpName,
	isDOP_BusinessProcess,
	isEwDocWithoutSomeActions,
	isSCHFDOP_BussinessProcess,
	PURPOSES_TYPES
} from './methods';
import { createField, createLabel } from '@Components/fields';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createActionsButton } from '@Components/buttons';
import { checkCrptValid } from './checkCrptValid';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER } from '@Fns/modules/documents/EDI_FNS_UPD/document.create.fns_upd_5_01n';

export class DocumentDetailsFns_upd_5_01nModule {
	moduleData;
	part1;
	part2;
	part1Head;
	part2Head;
	bpName;
	utochReasonText = null;
	annulDoc;
	updRoamingStatus6;
	infoColumnsConfig;
	rejectReasonText;
	textNoticeUtoch;
	rejectReasonTextValue;
	productsValues;

	constructor() {
		const me = this;

		me.positionsStore = edi.stores.initPositionsStore();
		me.areaStore = edi.stores.initAuthorityAreasStore();
		me.signerStatusesStore = edi.stores.initSignerStatusesStore();
		me.signerBuyerStatusesStore = edi.stores.initSignerBuyerStatusesStore();

		//Биндим методы, т.к. они будут передаваться как колбэки и вызываться опосредовано без контекста
		me._changeHandler = me.changeHandler.bind(this);
		me._signHandler = me.signHandler.bind(this);

		me._detailsPanel = me.createDetailsPanel.bind(this);
	}
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data, initCallBack) {
		const me = this;
		me.moduleData = data;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	onRender() {
		const me = this;
		edi.events.documents.on('change', me._changeHandler);
		edi.events.documents.on('sign', me._signHandler);
	}

	getPositionShortName(value) {
		const me = this;
		let target = me.positionsStore.findRecord('id', value, 0, false, false, true);
		return target ? target.get('shortName') : value;
	}

	createOS(selectorConfig) {
		const me = this;

		Object.assign(selectorConfig, {
			cls: '',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			is_valid: true,
			readOnly: true,
			valuesByMap: true,
			disableCommonAddressRenderer: true,
			disableCommonAddressTitleRenderer: true,
			fieldValues: me.part1
		});

		return createOrgSelector(selectorConfig);
	}

	createTextInfosBlock() {
		const me = this;

		let textInfos = edi.utils.getObjectProperty(me.part1, 'document.invoice.info.textInfos', true);

		textInfos = textInfos.concat(
			edi.utils.getObjectProperty(
				me.part1,
				'document.receiptPartDoc.informationOfTheFactOfeconomicLife.textInfos',
				true
			)
		);

		if (!textInfos.length) {
			return null;
		}

		textInfos.forEach((inf) => {
			if (
				inf.identifier === getPurposeTypeValue(PURPOSES_TYPES.VID_OBOBOROTA) ||
				inf.identifier === getPurposeTypeValue(PURPOSES_TYPES.SV_VIBITIYA)
			) {
				inf.value = getPurposeTranslation(inf.value);
			}
		});

		//Creates panel
		const createTextInfoPanel = function (identifier, value) {
			return createFieldBlockForDetails({
				items: [
					createLabelForDetails({
						title: identifier,
						text: value
					})
				]
			});
		};

		textInfos = textInfos.map(function (info) {
			return createTextInfoPanel(info.identifier, info.value);
		});

		return createFieldSetForDetails({
			margin: '24 0 0 0',
			title: edi.i18n.getMessage('documents.fns_upd.special_identifiers'),
			collapsed: true,
			collapsible: true,
			items: textInfos
		});
	}

	createDetailsPanel() {
		const me = this;

		let mainData = Ext.clone(me?.moduleData?.initData?.data) || {};
		let attrs = me?.moduleData?.initData?.data?.attributes || {};
		let firstDueDate = edi.utils.getAttributeByName(attrs, 'firstDueDate'),
			fieldFirstDueDate = null,
			lastDueDate = edi.utils.getAttributeByName(attrs, 'lastDueDate'),
			fieldLastDueDate = null;
		mainData.countSignatures =
			(me.part2Head ? me.part2Head.countSignatures : 0) + (me.part1Head ? me.part1Head.countSignatures : 0);
		mainData.ids = [me.part1Head?.id];
		if (me.part2Head) {
			mainData.ids.push(me.part2Head?.id);
		}
		let currency = edi.utils.getObjectProperty(me.part1, 'document.invoice.okv');
		if (currency) {
			const curObj = edi.utils.getOkv({
				code: currency
			});
			currency = curObj.charCode + ' - ' + curObj.codeStr + ' - ' + curObj.name;
		}

		const headData = createFieldBlockForDetails({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			itemId: 'updHeadData',
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('documents.form.type'),
							text: edi.utils.getObjectProperty(me.part1, 'document.function')
						},
						{
							title: edi.i18n.getMessage('document.form.number'),
							text: edi.utils.getObjectProperty(me.part1, 'document.invoice.invoiceNumber')
						},
						{
							title: edi.i18n.getMessage('documents.column.date'),
							text: edi.utils.getObjectProperty(me.part1, 'document.invoice.invoiceDate')
						},
						{
							title: edi.i18n.getMessage('invoice.currency'),
							itemId: 'updCurrency',
							isBlock: true,
							text: currency
						}
					]
				})
			]
		});

		const correctUpdTitles = [
			{
				title: edi.i18n.getMessage('document.form.number'),
				text: edi.utils.getObjectProperty(me.part1, 'document.invoice.corrections.Number')
			},
			{
				title: edi.i18n.getMessage('date'),
				date: edi.utils.getObjectProperty(me.part1, 'document.invoice.corrections.Date')
			}
		];

		const correctUpdFieldItems = createLabelBlockForDetails({
			contents: correctUpdTitles
		});

		const correctUpdField = edi.utils.getObjectProperty(me.part1, 'document.invoice.corrections.Number')
			? createFieldBlockForDetails({
					title: edi.i18n.getMessage('documents.fns_upd.invoice.corrections'),
					items: [correctUpdFieldItems]
			  })
			: null;

		if (firstDueDate) {
			fieldFirstDueDate = createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				items: [
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('upd.detail.field.firstDueDate'),
						items: [
							createLabelForDetails({
								date: edi.utils.formatDate(
									firstDueDate,
									edi.constants.DATE_FORMAT.FNS,
									edi.constants.DATE_FORMAT.SERVER
								)
							})
						]
					})
				]
			});
		}

		if (lastDueDate) {
			fieldLastDueDate = createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				items: [
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('upd.detail.field.lastDueDate'),
						items: [
							createLabelForDetails({
								date: edi.utils.formatDate(
									lastDueDate,
									edi.constants.DATE_FORMAT.FNS,
									edi.constants.DATE_FORMAT.SERVER
								)
							})
						]
					})
				]
			});
		}

		const createShipDocConfirmationField = function () {
			let items = [];
			const fieldData = edi.utils.getObjectProperty(me.part1, 'document.invoice.shipDocConfirmation');
			for (let i = 0; i < fieldData.length; i++) {
				const shipDocItems = createFieldBlockForDetails({
					title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.shipDocConfirmation'),
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createLabelBlockForDetails({
							contents: [
								{
									title: edi.i18n.getMessage('document.type'),
									text: edi.utils.getObjectProperty(fieldData[i], 'shipDocName')
								},
								{
									title: edi.i18n.getMessage('document.form.number'),
									text: edi.utils.getObjectProperty(fieldData[i], 'shipDocNameNumber')
								},
								{
									title: edi.i18n.getMessage('date'),
									date: edi.utils.getObjectProperty(fieldData[i], 'shipDocDate')
								}
							]
						})
					]
				});
				items.push(shipDocItems);
			}

			return items;
		};

		let organizationRight = [],
			organizationLeft = [];
		const configLeft = [
			{
				title: 'document.buyer',
				path: 'document.invoice.buyerInfo'
			}
		];

		const configRight = [
			{
				title: 'document.seller',
				path: 'document.invoice.sellerInfo'
			},
			{
				title: 'document.delivery',
				path: 'document.invoice.cargoReceiver'
			}
		];
		configLeft.forEach(function (org) {
			if (edi.utils.getObjectProperty(me.part1, org.path, true).length > 0) {
				const orgPath = edi.utils.getObjectProperty(me.part1, org.path, true);
				orgPath.forEach(function (item, index) {
					organizationLeft.push(
						createFieldBlockForDetails({
							title: edi.i18n.getMessage(org.title),
							items: [
								me.createOS({
									fieldsMap: edi.selectors.getUPDFieldsMap(org.path + '.' + index, true, true)
								})
							]
						})
					);
				});
			} else {
				organizationLeft.push(
					createFieldBlockForDetails({
						title: edi.i18n.getMessage(org.title),
						items: [me.createOS({})]
					})
				);
			}
		});

		configRight.forEach(function (org) {
			const orgPath = edi.utils.getObjectProperty(me.part1, org.path, true);
			if (orgPath.length > 0) {
				orgPath.forEach(function (item, index) {
					organizationRight.push(
						createFieldBlockForDetails({
							title: edi.i18n.getMessage(org.title),
							items: [
								me.createOS({
									fieldsMap: edi.selectors.getUPDFieldsMap(org.path + '.' + index, true, true)
								})
							]
						})
					);
				});
			} else {
				organizationRight.push(
					createFieldBlockForDetails({
						title: edi.i18n.getMessage(org.title),
						items: [me.createOS({})]
					})
				);
			}
		});
		const cargoFrom = edi.utils.getObjectProperty(me.part1, 'document.invoice.cargoFrom', true);
		if (cargoFrom.length > 0) {
			cargoFrom.forEach(function (item, index) {
				let cargoFromFieldsMap = edi.selectors.getUPDFieldsMap(
					'document.invoice.cargoFrom.' + index + '.cargoSender',
					true,
					true
				);
				if (item.alsoKnownAs === 'он же') {
					cargoFromFieldsMap = edi.selectors.getUPDFieldsMap('document.invoice.sellerInfo.0', true, true);
				}

				organizationLeft.push(
					createFieldBlockForDetails({
						title: edi.i18n.getMessage('document.ship.from'),
						items: [
							me.createOS({
								fieldsMap: cargoFromFieldsMap
							})
						]
					})
				);
			});
		} else {
			organizationLeft.push(
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.ship.from'),
					items: [me.createOS({})]
				})
			);
		}

		let part2SignerFieldBlock = null,
			acceptLabelBlock = null;

		if (me.part2) {
			const signer = edi.utils.getObjectProperty(me.part2, 'buyerInfo.signer')[0];
			const legalEntityData = edi.utils.getObjectProperty(signer, 'legalEntity');
			const individualPersonData =
				edi.utils.getObjectProperty(signer, 'individualInfo') ||
				edi.utils.getObjectProperty(signer, 'individualPerson') ||
				edi.utils.getObjectProperty(signer, 'individual');
			const authArea = me.areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(signer, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = me.signerBuyerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(signer, 'state'),
					0,
					false,
					false,
					true
				);

			const part2SignerTestClassPrefix = 'part2signer';
			part2SignerFieldBlock = createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.fns_upd.secondary.data'),
				margin: '32 0 0 0',
				items: [
					createLabelBlockForDetails({
						gap: [16, 16],
						margin: '8 0 0 0',
						contents: [
							{
								typography: 'body-short_02',
								text: edi.i18n.getMessage('documents.create.torg.title.receive.description'),
								isBlock: true
							},
							{
								title: edi.i18n.getMessage('job.title'),
								textCls: `${part2SignerTestClassPrefix}-position`,
								text: me.getPositionShortName(
									edi.utils.getObjectProperty(legalEntityData || individualPersonData, 'position')
								)
							},
							{
								title: edi.i18n.getMessage('last.name'),
								textCls: `${part2SignerTestClassPrefix}-lastName`,
								text:
									edi.utils.getObjectProperty(legalEntityData, 'fullName.lastName') ||
									edi.utils.getObjectProperty(individualPersonData, 'name.lastName') ||
									edi.utils.getObjectProperty(individualPersonData, 'fullName.lastName')
							},
							{
								title: edi.i18n.getMessage('first.name'),
								textCls: `${part2SignerTestClassPrefix}-firstName`,
								text:
									edi.utils.getObjectProperty(legalEntityData, 'fullName.firstName') ||
									edi.utils.getObjectProperty(individualPersonData, 'name.firstName') ||
									edi.utils.getObjectProperty(individualPersonData, 'fullName.firstName')
							},
							{
								title: edi.i18n.getMessage('patronymic.name'),
								textCls: `${part2SignerTestClassPrefix}-middleName`,
								text:
									edi.utils.getObjectProperty(legalEntityData, 'fullName.middleName') ||
									edi.utils.getObjectProperty(individualPersonData, 'name.middleName') ||
									edi.utils.getObjectProperty(individualPersonData, 'fullName.middleName')
							},
							{
								title: edi.i18n.getMessage('upd.receive.date'),
								isBlock: true,
								textCls: `${part2SignerTestClassPrefix}-receiveDate`,
								text: edi.utils.getObjectProperty(
									me.part2,
									'buyerInfo.contentFactsEconomicLife.infoGoodsReception.dateReceiptGoods'
								)
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
								textCls: `${part2SignerTestClassPrefix}-authorityArea`,
								isBlock: true,
								text: authArea
									? authArea.get('name')
									: edi.utils.getObjectProperty(signer, 'authorityArea')
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.signer.status'),
								textCls: `${part2SignerTestClassPrefix}-state`,
								isBlock: true,
								text: signerState
									? signerState.get('name')
									: edi.utils.getObjectProperty(signer, 'state')
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
								textCls: `${part2SignerTestClassPrefix}-baseAuthory`,
								text: edi.utils.getObjectProperty(signer, 'baseAuthory')
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
								textCls: `${part2SignerTestClassPrefix}-baseOrgAuthory`,
								text: edi.utils.getObjectProperty(signer, 'baseOrgAuthory')
							},
							{
								title: edi.i18n.getMessage('individual.certificateRegistration'),
								textCls: `${part2SignerTestClassPrefix}-certificateRegistration`,
								isBlock: true,
								hidden: !individualPersonData,
								text: edi.utils.getObjectProperty(individualPersonData, 'certificateRegistrationIP')
							}
						]
					})
				]
			});

			const acceptTestClassPrefix = 'accept';
			acceptLabelBlock = createLabelBlockForDetails({
				gap: [16, 16],
				margin: '16 0 0 0',
				contents: [
					{
						typography: 'body-short_02',
						text: edi.i18n.getMessage('upd.accept.tab.title'),
						isBlock: true
					},
					{
						title: edi.i18n.getMessage('job.title'),
						textCls: `${acceptTestClassPrefix}-position`,
						text: edi.utils.getObjectProperty(
							me.part2,
							'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.position'
						)
					},
					{
						title: edi.i18n.getMessage('last.name'),
						textCls: `${acceptTestClassPrefix}-lastName`,
						text: edi.utils.getObjectProperty(
							me.part2,
							'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.lastName'
						)
					},
					{
						title: edi.i18n.getMessage('first.name'),
						textCls: `${acceptTestClassPrefix}-firstName`,
						text: edi.utils.getObjectProperty(
							me.part2,
							'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.firstName'
						)
					},
					{
						title: edi.i18n.getMessage('patronymic.name'),
						textCls: `${acceptTestClassPrefix}-middleName`,
						text: edi.utils.getObjectProperty(
							me.part2,
							'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.middleName'
						)
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
						textCls: `${acceptTestClassPrefix}-authority`,
						isBlock: true,
						text: edi.utils.getObjectProperty(
							me.part2,
							'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.authority'
						)
					}
				]
			});
		}

		me.productsValues = Ext.clone(me.part1.document.invoiceTable.productInfo);
		for (let i = 0; i < me.productsValues.length; i++) {
			if ('object' == typeof me.productsValues[i].excise) {
				me.productsValues[i].excise.sumExcise =
					me.productsValues[i].excise.sumExcise !== null
						? me.productsValues[i].excise.sumExcise
						: me.productsValues[i].excise.withoutExcise
						? me.productsValues[i].excise.withoutExcise
						: 'без акциза';
			}
		}
		let columnsRegen;
		if (me.infoColumnsConfig) {
			const newConfig = edi.methods.createInfoColumns(me.infoColumnsConfig);

			const valuesColumnConfigurator = function (config) {
				Ext.merge(config, newConfig);
				return config;
			};
			columnsRegen = edi.columns.get('fns_upd_lines', function (config) {
				return valuesColumnConfigurator(config);
			});
		}

		const productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'FNS_UPD_LINE',
			totalModel: 'FNS_UPD_SUMMARY',
			gridColumnConfig: me.infoColumnsConfig ? columnsRegen : 'fns_upd_lines',
			totalColumnConfig: 'fns_upd_summary',
			readOnly: true,
			data: me.productsValues,
			margin: '16 0 0',
			totalsHandler: function (component) {
				edi.total.process(edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD, component, me.part1.document);
			},
			changeValuesBeforeEdit: function (values) {
				if (values.tax) {
					values.tax = values.tax.replace(/[%]*/g, '');
				}

				const textInfos = values.textInfos || [];
				const textInfosUnitGrossPrice = textInfos.find(
					(textInfo) => textInfo.identifier === TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER && textInfo.value
				);
				if (textInfosUnitGrossPrice) {
					values[TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER] = textInfosUnitGrossPrice.value;
				}
			},
			modalConf: {
				width: MODAL_SIZE.widthLarge
			},
			modalFormConfig: {
				title: 'document.upd.line.details',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								grid: {
									col: 8
								},
								title: edi.i18n.getMessage('line.name'),
								name: 'productName'
							},
							{
								grid: {
									scol: 1,
									col: 3,
									srow: 2,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.invoice.quantity'),
								allowDecimals: true,
								name: 'count',
								type: 'number'
							},
							{
								grid: {
									scol: 1,
									col: 4,
									srow: 3,
									row: 1
								},
								store: edi.stores.initOkeiStoreForN(),
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								name: 'productOkei',
								type: 'combo',
								valueField: 'id',
								displayField: 'name_code'
							},
							{
								grid: {
									scol: 1,
									col: 4,
									srow: 4,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.unit.of.measure.inputManual'),
								name: 'additionalProductInfo.unitOfMeasure',
								isHidden: function (field, record) {
									return record.productOkei != '0000';
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						layout: {
							type: 'grid',
							area: [3, 3, 3, 3, 3, 3, 3]
						},
						items: [
							{
								title: edi.i18n.getMessage('column.bill.unit.net.price'),
								allowDecimals: true,
								name: 'cost',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('pricat.product.unitGrossPrice'),
								allowDecimals: true,
								name: TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.fns.tax.rate'),
								name: 'tax',
								type: 'combo',
								store: edi.stores.initFNSTaxRateStore()
							},
							{
								title: edi.i18n.getMessage('line.upd.excise'),
								name: 'excise.sumExcise',
								showZero: true
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								allowDecimals: true,
								name: 'costWithoutNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								allowDecimals: true,
								name: 'taxSum.taxSum',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								allowDecimals: true,
								name: 'info',
								type: 'number'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.additional.data'),
						items: [
							{
								grid: {
									col: 6
								},
								title: edi.i18n.getMessage('line.item.additional.productCode'),
								name: 'additionalProductInfo.productCode',
								type: 'number'
							},
							{
								grid: {
									scol: 1,
									col: 3,
									srow: 2,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.additional.numberShouldRelease'),
								allowDecimals: true,
								name: 'additionalProductInfo.numberShouldRelease',
								type: 'number'
							},
							{
								grid: {
									scol: 1,
									col: 3,
									srow: 3,
									row: 1
								},
								store: edi.stores.createSimpleInlineStore(['1', '2', '3', '4', '5'], function (id) {
									return edi.i18n.getMessage('documents.fns_upd.product.type.' + id);
								}),
								displayField: 'name',
								valueField: 'id',
								type: 'combo',
								title: edi.i18n.getMessage('line.item.additional.feature'),
								name: 'additionalProductInfo.feature'
							},
							{
								grid: {
									scol: 1,
									col: 6,
									srow: 4,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.additional.featureAdditionalInfo'),
								type: 'number',
								name: 'additionalProductInfo.featureAdditionalInfo'
							},
							{
								grid: {
									scol: 1,
									col: 6,
									srow: 5,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.additional.productDesc'),
								type: 'string',
								name: 'additionalProductInfo.productDesc'
							},
							{
								grid: {
									scol: 1,
									col: 3,
									srow: 6,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.additional.productSort'),
								type: 'string',
								name: 'additionalProductInfo.productSort'
							},
							{
								grid: {
									scol: 1,
									col: 4,
									srow: 7,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.additional.productReference'),
								type: 'string',
								name: 'additionalProductInfo.productReference'
							},
							{
								grid: {
									scol: 1,
									col: 4,
									srow: 8,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.additional.catalogCode'),
								type: 'string',
								name: 'additionalProductInfo.catalogCode'
							},
							{
								grid: {
									scol: 1,
									col: 3,
									srow: 9,
									row: 1
								},
								title: edi.i18n.getMessage('line.item.additional.productTypeCode'),
								type: 'string',
								name: 'additionalProductInfo.productTypeCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.sv.td'),
						bodyPadding: 24,
						items: [
							{
								type: 'editableGrid',
								name: 'customsDeclarationInfo',
								config: {
									columnsConfig: 'upd_declaration_info',
									fields: [
										{
											title: 'column.kod.proiskh',
											name: 'countryOrigin',
											type: 'combo',
											store: edi.stores.initValidCountryFullStore(),
											valueField: 'iso_number_3',
											displayField: 'display_name'
										},
										{
											title: 'column.nomer.td',
											name: 'number',
											type: 'text'
										}
									],
									limit: undefined,
									model: 'UPD_DECLARATION_INFORMATION',
									modalWidth: MODAL_SIZE.widthLarge,
									gridConfig: {
										cls: 'modal-grid'
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.infoTracking'),
						bodyPadding: 24,
						items: [
							{
								type: 'editableGrid',
								name: 'additionalProductInfo.infoTracking',
								config: {
									fields: [
										{
											title: edi.i18n.getMessage('line.item.infoTracking.trackingProdNum'),
											name: 'trackingProdNum',
											type: 'text'
										},
										{
											title: edi.i18n.getMessage('line.item.infoTracking.trackingUnitMes'),
											name: 'trackingUnitMes',
											type: 'text',
											renderer(val, meta, rec) {
												return rec.get('trackingUnitMesName') || val;
											}
										},
										{
											title: edi.i18n.getMessage('line.item.infoTracking.trackingProdQuan'),
											name: 'trackingProdQuan',
											type: 'text'
										},
										{
											title: edi.i18n.getMessage('line.item.infoTracking.trackingAdd'),
											name: 'trackingAdd',
											type: 'text'
										}
									],
									autoScroll: true,
									limit: 20,
									model: 'UPD_PRODUCT_INFO_TRACKING',
									gridConfig: {
										cls: 'modal-grid',
										minHeight: 200
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.identifyProductToolNumbers'),
						bodyPadding: 24,
						items: [
							{
								type: 'editableGrid',
								name: 'additionalProductInfo.identifyProductToolNumbers',
								config: {
									modalWidth: MODAL_SIZE.widthLarge,
									columnsConfig: 'fns_upd_line_identifyProductToolNumbers',
									fields: [
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.packId'),
											name: 'packId',
											type: 'text'
										},
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.type'),
											name: 'type',
											type: 'text'
										},
										{
											title: edi.i18n.getMessage('line.item.identifyProductToolNumbers.numbers'),
											name: 'numbers',
											type: 'text'
										}
									],
									autoScroll: true,
									limit: 20,
									model: 'UPD_PRODUCT_IDENTIFY_PRODUCT_TOOL_NUMBERS',
									gridConfig: {
										cls: 'modal-grid'
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
						bodyPadding: 24,
						items: [
							{
								type: 'editableGrid',
								name: 'textInfos',
								config: {
									fields: [
										{
											name: 'identifier',
											type: 'text'
										},
										{
											name: 'value',
											type: 'text'
										}
									],
									autoScroll: true,
									limit: 20,
									model: 'UPD_PRODUCT_TEXT_INFO',
									storeConfig: {
										filters: [
											function (textInfo) {
												return (
													textInfo.get('identifier') !==
													TEXT_INFOS_UNIT_GROSS_PRICE_IDENTIFIER
												);
											}
										]
									},
									gridConfig: {
										cls: 'modal-grid',
										minHeight: 200
									}
								}
							}
						]
					}
				]
			}
		});
		let organizationsTwoColumnsLayout;
		const organizationsTwoColumnsLayoutContainer = createMaxWidthContainerForDetail({
			items: [
				(organizationsTwoColumnsLayout = createTwoColumnsLayout(organizationLeft, organizationRight, 0.5, {
					items2Conf: {
						margin: '0 0 0 10'
					}
				}))
			]
		});
		organizationsTwoColumnsLayout.on('boxready', function (comp) {
			let leftHeight = 10,
				rightHeight = 10;
			let leftOrg = edi.utils.getObjectProperty(comp.items.items[0], 'items.items')[0];
			let rightOrg = edi.utils.getObjectProperty(comp.items.items[1], 'items.items')[0];
			leftHeight = leftOrg.getHeight();
			rightHeight = rightOrg.getHeight();
			const maxHeight = Math.max(leftHeight, rightHeight);
			leftOrg.setHeight(maxHeight);
			rightOrg.setHeight(maxHeight);
		});

		let fields = [headData, correctUpdField, fieldFirstDueDate, fieldLastDueDate];
		fields = fields.concat(
			organizationsTwoColumnsLayoutContainer,
			me.createPaymentInformationField(),
			createShipDocConfirmationField()
		);
		fields.push(me.createPersonHandingGoods(), me.createAdditionalInformation(), me.createTextInfosBlock());

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				me.createModuleHeadPanel(mainData, attrs),
				...fields,
				productsGrid,
				createMaxWidthContainerForDetail({
					items: [me.createSignerItems(), part2SignerFieldBlock, acceptLabelBlock]
				})
			]
		});
	}

	createModuleHeadPanel(mainData, attrs) {
		const me = this;
		let headPanel = createDocumentHeaderPanel(me.moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			utochReasonText: me.utochReasonText,
			rejectReasonText: me.textNoticeUtoch,
			showAnnulLog: true,
			isReceipts: !(me.moduleData.initData && me.moduleData.initData.isDocumentSharing),
			usingReport: true,
			isCrptStatus: true,
			showCRPTPrintBtn: edi.methods.checkAllowToShowCrptPrintBtn(attrs),
			defaultFormat: edi.constants.REPORT_FORMATS.XLS,
			noLog: isEwDocWithoutSomeActions(mainData.type),
			noUsage:
				!!(me.moduleData.initData && me.moduleData.initData.isDocumentSharing) ||
				isEwDocWithoutSomeActions(mainData.type),
			costDecimal: edi.constants.UPD_PRICE_PRECISION
		});

		let projectName = edi.utils.getAttributeByName(me.moduleData.initData.data?.attributes, 'project_s');
		if (projectName) {
			let prj_name = edi.i18n.getMessage('project.name.' + projectName);
			projectName = prj_name !== 'project.name.' + projectName ? prj_name : projectName;

			headPanel.add(
				createPanel({
					layout: 'column',
					cls: 'edi-document-header-panel-row',
					margin: '0 0 5',
					items: [
						createField({
							title: edi.i18n.getMessage('documents.fns_upd.project'),
							input: createLabel({
								columnWidth: 0.5,
								text: projectName,
								valueLabel: true
							}),
							containerConfig: {
								margin: '0 10 0 0',
								columnWidth: 0.25
							}
						})
					]
				})
			);
		}

		return headPanel;
	}

	createPaymentInformationField() {
		const me = this;

		let items = [];
		const fieldData = edi.utils.getObjectProperty(me.part1, 'document.invoice.paymentInformation');
		for (let i = 0; i < fieldData.length; i++) {
			const paymentInformationItems = createFieldBlockForDetails({
				title: edi.i18n.getMessage('documents.fns_upd.paymentInformation'),
				items: [
					createLabelBlockForDetails({
						contents: [
							{
								title: edi.i18n.getMessage('document.form.number'),
								text: edi.utils.getObjectProperty(fieldData[i], 'number')
							},
							{
								title: edi.i18n.getMessage('date'),
								date: edi.utils.getObjectProperty(fieldData[i], 'date')
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.paymentInformation.sum'),
								text: edi.utils.getObjectProperty(fieldData[i], 'sum')
							}
						]
					})
				]
			});
			items.push(paymentInformationItems);
		}
		return items;
	}

	// Create base documents panels
	createBaseShipmentOfGoodsFields() {
		const me = this;

		let items = [];
		const fields = edi.utils.getObjectProperty(
			me.part1,
			'document.receiptPartDoc.transferInformation.baseShipmentOfGoods',
			true
		);
		for (let i = 0; i < fields.length; i++) {
			const baseShipmentOfGoodsItems = createFieldBlockForDetails({
				cls: `edi-form-maxwidth`,
				title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						contents: [
							{
								title: edi.i18n.getMessage('document.type'),
								text: edi.utils.getObjectProperty(fields[i], 'name')
							},
							{
								title: edi.i18n.getMessage('document.form.number'),
								text: edi.utils.getObjectProperty(fields[i], 'number')
							},
							{
								title: edi.i18n.getMessage('date'),
								isBlock: true,
								date: edi.utils.getObjectProperty(fields[i], 'date')
							},
							{
								title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.baseId'),
								text: edi.utils.getObjectProperty(fields[i], 'baseId')
							},
							{
								title: edi.i18n.getMessage(
									'documents.fns_upd.transferInfo.baseShipmentOfGoods.additionalInfo'
								),
								text: edi.utils.getObjectProperty(fields[i], 'additionalInfo')
							}
						]
					})
				]
			});
			items.push(baseShipmentOfGoodsItems);
		}
		return items;
	}

	createPersonHandingGoods() {
		const me = this;

		const personHandingGoodsValues = edi.utils.getObjectProperty(
			me.part1,
			'document.receiptPartDoc.transferInformation.personHandingGoods'
		);
		let fieldData = edi.utils.getObjectProperty(personHandingGoodsValues, 'sellerOrganizationWorker');
		let handingGoodsType = 'sellerOrganizationWorker';
		if (!fieldData && personHandingGoodsValues.anotherPerson) {
			if (personHandingGoodsValues.anotherPerson.shippedGoodsWorker) {
				fieldData = edi.utils.getObjectProperty(personHandingGoodsValues, 'anotherPerson.shippedGoodsWorker');
				handingGoodsType = 'shippedGoodsWorker';
			} else if (personHandingGoodsValues.anotherPerson.individualPersonShipsGoogs) {
				fieldData = edi.utils.getObjectProperty(
					personHandingGoodsValues,
					'anotherPerson.individualPersonShipsGoogs'
				);
				handingGoodsType = 'individualPersonShipsGoogs';
			}
		}

		const personHandingGoods = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.personHandingGoods'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('documents.fns_upd.' + handingGoodsType),
							text:
								handingGoodsType !== 'individualPersonShipsGoogs'
									? me.getPositionShortName(edi.utils.getObjectProperty(fieldData, 'position'))
									: edi.i18n.getMessage('documents.fns_upd.' + handingGoodsType)
						},
						{
							title: edi.i18n.getMessage('first.name'),
							text: edi.utils.getObjectProperty(fieldData, 'fullName.firstName')
						},
						{
							title: edi.i18n.getMessage('last.name'),
							text: edi.utils.getObjectProperty(fieldData, 'fullName.lastName')
						},
						{
							title: edi.i18n.getMessage('patronymic.name'),
							text: edi.utils.getObjectProperty(fieldData, 'fullName.middleName')
						}
					]
				})
			]
		});

		const baseAuthority = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.sellerOrganizationWorker.baseAuthority'),
			hidden: handingGoodsType === 'individualPersonShipsGoogs',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text:
						handingGoodsType === 'shippedGoodsWorker'
							? edi.utils.getObjectProperty(fieldData, 'rightPresentResults')
							: edi.utils.getObjectProperty(fieldData, 'baseAuthority')
				})
			]
		});

		const authority = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.individualPersonShipsGoogs.authority'),
			hidden: handingGoodsType === 'sellerOrganizationWorker',
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(fieldData, 'authority')
				})
			]
		});

		const orgName = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.company.name'),
			hidden: handingGoodsType !== 'shippedGoodsWorker',
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(fieldData, 'orgName')
				})
			]
		});

		const carrier = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.carrier'),
			items: [
				me.createOS({
					fieldsMap: edi.selectors.getUPDFieldsMap(
						'document.receiptPartDoc.transferInformation.transportAndCargo.carrier',
						true,
						true
					)
				})
			]
		});

		const transportAndCargoColumns = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.create.torg.trans.name.full'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: me.createWayBillField()
		});

		const thingTransferInfoInfo = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transportAndCargo.info'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.receiptPartDoc.transferInformation.thingTransferInfo.info'
							)
						},
						{
							title: edi.i18n.getMessage('documents.fns_upd.transferInfo.date'),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.receiptPartDoc.transferInformation.thingTransferInfo.date'
							)
						}
					]
				})
			]
		});

		const contentActionItems = {
			itemsTransferred: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.itemsTransferred'),
			workPassed: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.workPassed'),
			servicesRendered: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.servicesRendered')
		};
		let contentActionValue = '';

		for (let key in contentActionItems) {
			if (
				contentActionItems[key] ===
				edi.utils.getObjectProperty(me.part1, 'document.receiptPartDoc.transferInformation.contentAction')
			) {
				contentActionValue = edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction.' + key);
			} else {
				contentActionValue = edi.utils.getObjectProperty(
					me.part1,
					'document.receiptPartDoc.transferInformation.contentAction'
				);
			}
		}

		const contentAction = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: contentActionValue
				})
			]
		});

		const typeAction = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.typeAction'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(
						me.part1,
						'document.receiptPartDoc.transferInformation.typeAction'
					)
				})
			]
		});

		const shippingDate = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.shippingDate'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(
						me.part1,
						'document.receiptPartDoc.transferInformation.shippingDate'
					)
				})
			]
		});

		const transferInformationDates = (startingDate, endingDate) =>
			!endingDate ? startingDate : [startingDate, endingDate];
		const transferInfoDate = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.starting'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					date: transferInformationDates(
						edi.utils.getObjectProperty(
							me.part1,
							'document.receiptPartDoc.transferInformation.startingDate'
						),
						edi.utils.getObjectProperty(me.part1, 'document.receiptPartDoc.transferInformation.endingDate')
					)
				})
			]
		});

		let items = me.createBaseShipmentOfGoodsFields();
		items.push(
			personHandingGoods,
			orgName,
			authority,
			baseAuthority,
			carrier,
			transportAndCargoColumns,
			thingTransferInfoInfo,
			contentAction,
			typeAction,
			shippingDate,
			transferInfoDate
		);

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.transferInfo.baseShipmentOfGoods.additionalInfo'),
			collapsible: true,
			items: createMaxWidthContainerForDetail({
				layout: {
					type: 'grid',
					gap: 24
				},
				items: items
			})
		});
	}

	createWayBillField() {
		const me = this;

		let items = [];
		const fieldData = edi.utils.getObjectProperty(
			me.part1,
			'document.receiptPartDoc.transferInformation.transportAndCargo.wayBill'
		);
		for (let i = 0; i < fieldData.length; i++) {
			const wayBillFieldItems = createLabelBlockForDetails({
				contents: [
					{
						isBlock: true,
						title: edi.i18n.getMessage('document.form.number'),
						text: edi.utils.getObjectProperty(fieldData[i], 'number')
					},
					{
						title: edi.i18n.getMessage('date'),
						content: edi.utils.getObjectProperty(fieldData[i], 'date')
					}
				]
			});

			items.push(wayBillFieldItems);
		}

		return items;
	}

	createAdditionalInformation() {
		const me = this;

		const id = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.govContract'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('document.data'),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoice.additionalInfo.govAgreementId'
							)
						},
						{
							title: edi.i18n.getMessage('nr'),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoice.additionalInfo.additionalInfoSeller.numberGovCon'
							)
						},
						{
							title: edi.i18n.getMessage('column.license.number'),
							date: edi.utils.getObjectProperty(
								me.part1,
								'document.invoice.additionalInfo.additionalInfoSeller.dateGovContract'
							)
						}
					]
				})
			]
		});

		const currency = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.currencyName'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text:
						edi.utils.getObjectProperty(me.part1, 'document.invoice.additionalInfo.currencyName') ||
						edi.utils.getOkv({
							charCode: edi.constants.DEFAULT.CURRENCY
						}).name
				})
			]
		});

		const exchangeRate = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.exchangeRate'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(me.part1, 'document.invoice.additionalInfo.exchangeRate')
				})
			]
		});

		const infoInvoiceValue = edi.utils.getObjectProperty(me.part1, 'document.invoice.additionalInfo.infoInvoice');
		const infoInvoice = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.infoInvoice'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: infoInvoiceValue
						? `${infoInvoiceValue} - ${edi.i18n.getMessage(
								'documents.fns_upd.additionalInfo.infoInvoice.' + infoInvoiceValue
						  )}`
						: ''
				})
			]
		});

		const additionalInfoSellerInfo = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.seller'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage(
								'documents.fns_upd.additionalInfo.additionalInfoSeller.accountSeller'
							),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoice.additionalInfo.additionalInfoSeller.accountSeller'
							)
						},
						{
							title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.code'),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoice.additionalInfo.additionalInfoSeller.codeSeller'
							)
						},
						{
							title: edi.i18n.getMessage(
								'documents.fns_upd.additionalInfo.additionalInfoSeller.codeGoal'
							),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoice.additionalInfo.additionalInfoSeller.codeSellerGoal'
							)
						}
					]
				})
			]
		});

		const sellerTreasuryInfo = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.additionalInfoSeller.sellerTreasury'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('column.code'),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoice.additionalInfo.additionalInfoSeller.codeSellerTreasury'
							)
						},
						{
							title: edi.i18n.getMessage('column.short.name'),
							text: edi.utils.getObjectProperty(
								me.part1,
								'document.invoice.additionalInfo.additionalInfoSeller.nameSellerTreasury'
							)
						}
					]
				})
			]
		});

		const createAssignmentBasisField = function () {
			const fieldData = edi.utils.getObjectProperty(me.part1, 'document.invoice.additionalInfo.assignmentBasis');

			const assignmentBasisFieldItems = createFieldBlockForDetails({
				title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.assignmentBasis'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createMaxWidthContainerForDetail({
						items: [
							createLabelBlockForDetails({
								contents: [
									{
										title: edi.i18n.getMessage(
											'documents.fns_upd.transferInfo.baseShipmentOfGoods.name'
										),
										text: edi.utils.getObjectProperty(fieldData, 'name')
									},
									{
										title: edi.i18n.getMessage('nr'),
										text: edi.utils.getObjectProperty(fieldData, 'number')
									},
									{
										title: edi.i18n.getMessage('date'),
										isBlock: true,
										date: edi.utils.getObjectProperty(fieldData, 'date')
									},
									{
										title: edi.i18n.getMessage(
											'documents.fns_upd.transferInfo.baseShipmentOfGoods.baseId'
										),
										text: edi.utils.getObjectProperty(fieldData, 'baseId')
									},
									{
										title: edi.i18n.getMessage(
											'documents.fns_upd.transferInfo.baseShipmentOfGoods.additionalInfo'
										),
										text: edi.utils.getObjectProperty(fieldData, 'additionalInfo')
									}
								]
							})
						]
					})
				]
			});

			return assignmentBasisFieldItems;
		};

		const factorInfo = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo.factorInfo'),
			items: [
				me.createOS({
					fieldsMap: edi.selectors.getUPDFieldsMap('document.invoice.additionalInfo.factorInfo', true, true)
				})
			]
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.fns_upd.additionalInfo'),
			collapsible: true,
			items: [
				createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						gap: 24
					},
					items: [
						id,
						infoInvoice,
						additionalInfoSellerInfo,
						sellerTreasuryInfo,
						currency,
						exchangeRate,
						factorInfo,
						createAssignmentBasisField()
					]
				})
			]
		});
	}

	createSignerItems() {
		const me = this;

		let fieldData = edi.utils.getObjectProperty(me.part1, 'document.signer');
		if (!Array.isArray(fieldData)) {
			fieldData = [fieldData];
		}

		const createFields = function (fieldData) {
			const legalEntityData = edi.utils.getObjectProperty(fieldData, 'legalEntity');
			const individualPersonData =
				edi.utils.getObjectProperty(fieldData, 'individualPerson') ||
				edi.utils.getObjectProperty(fieldData, 'individual');
			const authArea = me.areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = me.signerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'state'),
					0,
					false,
					false,
					true
				);

			const signerTestClassPrefix = 'signer';
			return createLabelBlockForDetails({
				gap: [16, 16],
				margin: '8 0 0 0',
				contents: [
					{
						title: edi.i18n.getMessage('column.position'),
						hidden: !legalEntityData,
						textCls: `${signerTestClassPrefix}-position`,
						text: me.getPositionShortName(edi.utils.getObjectProperty(legalEntityData, 'position'))
					},
					{
						title: edi.i18n.getMessage('last.name'),
						textCls: `${signerTestClassPrefix}-lastName`,
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.lastName' : 'name.lastName'
						)
					},
					{
						title: edi.i18n.getMessage('first.name'),
						textCls: `${signerTestClassPrefix}-firstName`,
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.firstName' : 'name.firstName'
						)
					},
					{
						title: edi.i18n.getMessage('patronymic.name'),
						textCls: `${signerTestClassPrefix}-middleName`,
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.middleName' : 'name.middleName'
						)
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
						textCls: `${signerTestClassPrefix}-authorityArea`,
						isBlock: true,
						text: authArea ? authArea.get('name') : edi.utils.getObjectProperty(fieldData, 'authorityArea')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.status'),
						textCls: `${signerTestClassPrefix}-state`,
						isBlock: true,
						text: signerState ? signerState.get('name') : edi.utils.getObjectProperty(fieldData, 'state')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
						textCls: `${signerTestClassPrefix}-baseAuthory`,
						text: edi.utils.getObjectProperty(fieldData, 'baseAuthory')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
						textCls: `${signerTestClassPrefix}-baseOrgAuthory`,
						text: edi.utils.getObjectProperty(fieldData, 'baseOrgAuthory')
					}
				]
			});
		};

		return createContainer({
			items: fieldData.map((signer, i) =>
				createFieldBlockForDetails({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					title:
						fieldData.length > 1 && i > 0
							? `${edi.i18n.getMessage('company.signer')} ${++i}`
							: edi.i18n.getMessage('company.signer'),
					items: [createFields(signer)]
				})
			)
		});
	}

	createModuleActionsPanel() {
		const me = this;

		const data = me.moduleData.initData.data;
		const businessState = edi.utils.getObjectProperty(data, 'businessState');

		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg),
			isOutGoingDocument = edi.constants.DIRECTIONS.OUTGOING === direction;
		const hasPart2 = !!edi.utils.getAttributeByName(data.attributes, 'hasPart2') || !!me.part2Head;
		const totalSignaturesCount = edi.document.actions.getSignCount(data);

		const neededSignaturesCount = isOutGoingDocument
			? totalSignaturesCount - me.part1Head.countSignatures
			: totalSignaturesCount - (hasPart2 && me.part2Head ? me.part2Head.countSignatures : 0);
		let actionsPanel = createActionsPanel();

		const editCmp = edi.document.actions.createEditActionButton('document.create.fns_upd_5_01n', data, data.number);
		const readCmp = createActionsButton({
			text: edi.i18n.getMessage('document.mark.read'),
			glyph: edi.constants.ICONS.READ,
			handler: function () {
				edi.core.confirm(
					edi.i18n.getMessage('document.mark.read'),
					edi.i18n.getMessage('document.mark.read.question'),
					function () {
						me.moduleData.tab.setLoading();
						const success = function () {
							me.moduleData.tab.setLoading(false);
							edi.events.documents.fireEvent('change', {
								id: data.id
							});
						};
						const failure = edi.document.actions.defaultFailureHandler(
							me.moduleData.tab,
							'document.error.mark.read'
						);
						const markRead = function () {
							let postData = {};
							postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID] = edi.constants.STATE.SENT;
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: data.id
									},
									true
								),
								'PUT',
								Ext.encode(postData),
								success,
								failure
							);
						};
						if (me.updRoamingStatus6) {
							edi.utils.sign(
								me.updRoamingStatus6,
								me.moduleData.tab,
								function (failed) {
									if (failed) {
										failure();
									} else {
										success();
									}
								},
								undefined,
								undefined,
								true,
								null,
								{
									signRefuse: () => me.moduleData.tab?.setLoading(false)
								}
							);
						} else {
							markRead();
						}
					}
				);
			}
		});

		let excludeActions = {};

		let versionFormat = data.versionFormat || data.versionId;
		let allowCRPTChecking =
			data.state === edi.constants.STATE.DRAFT &&
			(isSCHFDOP_BussinessProcess(me.bpName) || isDOP_BusinessProcess(me.bpName)) &&
			versionFormat === '5.01-N' &&
			edi.utils.getAttributeByName(data.attributes, 'DOCUMENT_INCLUDE_MARK') === 'true';

		// Костыль, чтобы была возможность подписать УПД в статусе UTOCH
		// т.к. при UTOCH действие READ должно быть скрыто, но оно нужно для подписания по БП (если state = sent)
		// мы разрешаем его в actionRule, но скрываем в excludeActions, чтобы нельзя было прочитать на UI
		if (data.businessState === edi.constants.STATE.UTOCH) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.READ] = true;
		}

		let customButtons = [];
		let renouncement = edi.utils.getAttributeByName(data.attributes, 'RENOUNCEMENT', true);
		let utochReason = edi.utils.getAttributeByName(data.attributes, 'UTOCH_REASON', true);
		let isRenounced = utochReason && renouncement && renouncement.value === 'true';

		let hidePlusButton = false;
		if (isOutGoingDocument) {
			const rawBpName = edi.utils.getAttributeByName(data.attributes, 'bpName');
			if (isRenounced && hasSchfInBpName(rawBpName)) {
				hidePlusButton = true;
			}
		}

		const actionCreateUKDConfig = [
			{
				permission: 'CREATE_EDI_FNS_UKD',
				title: 'action.ukd_5_01n',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				config: { versionId: '5.01-N' }
			}
		];

		const actionCreateCUDConfig = [
			{
				permission: 'CREATE_EDI_FNS_UPD',
				title: 'action.cupd',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				config: {
					buyerOrg: me.moduleData.initData.data.toOrg,
					versionId: '5.01-N'
				}
			}
		];
		let modData = Ext.clone(me.moduleData);
		let initialFromOrg = modData.initData.data.fromOrg;
		modData.initData.data.fromOrg = modData.initData.data.toOrg;
		modData.initData.data.toOrg = initialFromOrg;

		let createTORG2Config = [
			{
				permission: 'CREATE_EDI_FNS_TORG2',
				title: 'documents.doctype.EDI_FNS_TORG2',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_TORG2_P1,
				createCustomMethod: edi.document.actions.methodCreateTORG2fromDocuments(
					me.part1Head.id,
					direction,
					me.productsValues,
					'UnitGrossPrice',
					modData
				),
				config: {
					isFromTransformation: false
				}
			}
		];

		let basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_UKD',
			data,
			me.moduleData,
			me.part1,
			'fns_upd',
			actionCreateUKDConfig
		);
		basedDocuments = basedDocuments.concat(
			edi.document.actions.createListBasedDocuments(
				'CREATE_CUD',
				data,
				me.moduleData,
				me.part1,
				'cupd',
				actionCreateCUDConfig
			)
		);
		basedDocuments = basedDocuments.concat(
			edi.document.actions.createListBasedDocuments(
				'CREATE_TORG2',
				data,
				me.moduleData,
				me.part1,
				null,
				createTORG2Config
			)
		);
		if (!hidePlusButton && basedDocuments?.length) {
			customButtons.push(
				edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments, {
					text: edi.i18n.getMessage('action.correct.or.fix')
				})
			);
		}

		let customButtonsOptions = {
			// EW start
			direction,
			hasPart2,
			neededSignaturesCount,
			allowCRPTChecking
			// EW end
		};

		customButtons = customButtons.concat(me.addCustomButtons(customButtonsOptions));
		excludeActions = { ...excludeActions, ...me.addExcludeButtons() };
		const actionProps = {
			SIGN: {
				methodAddOptions: {
					contentId:
						edi.constants.DIRECTIONS.OUTGOING === direction
							? me.part1Head.id
							: me.part2Head
							? me.part2Head.id
							: null,
					useBeforeAction:
						data.state === edi.constants.STATE.DRAFT ||
						data.state === edi.constants.STATE.READ ||
						data.state === edi.constants.STATE.READ,
					beforeInit: allowCRPTChecking
						? function (continueSign) {
								checkCrptValid(data).then(({ success, skipped, selectedCertificate }) => {
									if (success || skipped) {
										continueSign(selectedCertificate);
									}
								});
						  }
						: null
				}
			},
			EDIT: {
				component: editCmp
			},
			READ: {
				component: readCmp
			},
			REFRESH: {
				handler: function () {
					me._changeHandler(data);
				}
			},
			ANNUL: {
				methodAddOptions: me.annulDoc
			},
			EXPORT: {
				label: edi.i18n.getMessage('action.export.document'),
				exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
				exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE_WITH_SIGN, {
					documentId: data.id
				}),
				extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
				extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON_ALL_DOCUMENT, {
					documentId: data.id
				}),
				addExtendedExport: true,
				xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
				addXmlExport: true
			},
			CUSTOM_BUTTONS: {
				buttons: customButtons
			}
		};

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: me.moduleData,
			needSignatures: neededSignaturesCount,
			hasPart2: hasPart2,
			excludeActions: excludeActions,
			actionProps: actionProps
		});

		return actionsPanel;
	}

	addCustomButtons(options) {
		return [];
	}

	addExcludeButtons(options) {
		return {};
	}

	changeHandler(data) {
		const me = this;

		if (
			data &&
			data.id &&
			(data.id == me.moduleData.initData.data.id ||
				(me.part1Head && data.id == me.part1Head.id) ||
				(me.part2Head && data.id == me.part2Head.id))
		) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(me.moduleData);
			} else {
				me.renderData();
			}
		}
	}

	signHandler(data) {
		const me = this;

		if (data && data.id && data.id == me.moduleData.initData.data.id) {
			let markNotification =
					edi.utils.getObjectProperty(edi.core.getUserData(), 'org.attributes.markNotification.value') ===
					'true',
				productInfo = edi.utils.getObjectProperty(me.part1, 'document.invoiceTable.productInfo'),
				condition = false;
			if (markNotification) {
				if (!Ext.isEmpty(productInfo)) {
					productInfo.forEach(function (item) {
						condition =
							condition ||
							!Ext.isEmpty(
								edi.utils.getObjectProperty(item, 'additionalProductInfo.identifyProductToolNumbers')
							);
					});
				}
				if (condition) {
					edi.methods.checkMarkingPartners();
				}
			}
		}
	}

	addModuleActionsPanel() {
		const me = this;
		me.moduleData.tab.add(me.createModuleActionsPanel());
	}

	finishLoad(documentHeaderData, initCallBack) {
		const me = this;

		let modulePanel = createDetailsModulePanel();

		if (me.moduleData.tab.isDestroyed) {
			return;
		}
		modulePanel.add(me.createDetailsPanel());
		me.moduleData.tab.removeAll();

		me.addModuleActionsPanel();
		me.moduleData.tab.add(modulePanel);

		const isBlocked = edi.utils.getAttributeByName(documentHeaderData.attributes, 'isBlocked', undefined, true);
		if (
			!isBlocked &&
			me.annulDoc &&
			me.annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
			me.annulDoc.toOrg.id == edi.core.getUserOrgID()
		) {
			edi.methods.documents.showActiveAnnulModal(me.annulDoc, me.moduleData);
		}

		if ('function' == typeof initCallBack) {
			initCallBack();
		} else {
			me.moduleData.tab.setLoading(false);
		}
	}

	failure(responseData) {
		const errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
		edi.core.showWarn(errorMsg);
	}

	continueWithRendering(documentHeaderData, initCallBack) {
		const me = this;

		const direction = edi.utils.getDocumentDirection(documentHeaderData.toOrg, documentHeaderData.fromOrg);

		me.bpName = edi.utils.getAttributeByName(documentHeaderData.attributes, 'bpName');
		const businessState = edi.utils.getObjectProperty(documentHeaderData, 'businessState');

		me.annulDoc = null;
		me.utochReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'UTOCH_REASON');
		const isAnnulUtoch =
			me.utochReasonText ||
			businessState === edi.constants.STATE.ON_ANNUL ||
			businessState === edi.constants.STATE.ANNUL_SIGN;
		me.rejectReasonTextValue =
			edi.utils.getObjectProperty(documentHeaderData.attributes, 'utochCreated').value === 'true';
		const isIncomingRoaming =
			edi.constants.DIRECTIONS.INCOMING === direction &&
			documentHeaderData.state === edi.constants.STATE.SENT &&
			me.bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_DECREE_14;
		if (isAnnulUtoch || isIncomingRoaming) {
			edi.rest.sendRequest(
				edi.document.actions.formatLinkedTreeUri(me.moduleData.initData, {
					depth: edi.constants.DEFAULT.TREE_DEPTH
				}),
				'GET',
				{},
				function (responseData) {
					let i, utochId;
					if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
						for (i = 0; i < responseData.items.length; i++) {
							if (
								isAnnulUtoch &&
								responseData.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL &&
								edi.constants.STATE.WAIT_PARTNER_DECISION === responseData.items[i].state
							) {
								me.annulDoc = responseData.items[i];
							} else if (
								isAnnulUtoch &&
								responseData.items[i].type ===
									edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH &&
								responseData.items[i].state !== edi.constants.STATE.DRAFT &&
								responseData.items[i].state !== edi.constants.STATE.DELETED
							) {
								utochId = responseData.items[i].id;
							} else if (
								isIncomingRoaming &&
								responseData.items[i].type ===
									edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL &&
								responseData.items[i].state === edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK
							) {
								me.updRoamingStatus6 = responseData.items[i];
							}
						}
					}

					if (!utochId) {
						me.finishLoad(documentHeaderData, initCallBack);
					} else {
						edi.rest.sendRequest(
							edi.document.actions.formatDetailsUri(me.moduleData.initData, utochId, true),
							'GET',
							{},
							function (responseData) {
								if (responseData.success && responseData.data) {
									me.utochReasonText = edi.utils.getObjectProperty(
										responseData.data,
										'Document.DataOfUvUtoch.TextNoticeUtoch'
									);
								}
								me.finishLoad(documentHeaderData, initCallBack);
							},
							me.failure
						);
					}
				},
				me.failure
			);
		} else {
			me.finishLoad(documentHeaderData, initCallBack);
		}
	}

	getRejectReason(documentHeaderData, initCallBack) {
		const me = this;

		edi.rest.sendRequest(
			edi.document.actions.formatRejectReasonUri(me.moduleData.initData),
			'GET',
			null,
			function (data) {
				me.textNoticeUtoch = data?.data?.TextNoticeUtoch;
				me.continueWithRendering(documentHeaderData, initCallBack);
			},
			function () {
				return me.continueWithRendering(documentHeaderData, initCallBack);
			}
		);
	}

	getOrganizationAdditionalFields(orgId, documentHeaderData, initCallBack) {
		const me = this;

		const failure = function (responseData) {
			const errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
			edi.core.showWarn(errorMsg);
		};
		edi.rest.sendRequest(
			edi.document.actions.formatDocDataUri(me.moduleData.initData, {
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				orgId: orgId
			}),
			'GET',
			null,
			function (data) {
				if (data && data.items) {
					me.infoColumnsConfig = data.items.length ? data.items : null;
					if (me.rejectReasonTextValue) {
						me.getRejectReason(documentHeaderData, initCallBack);
					} else {
						me.continueWithRendering(documentHeaderData, initCallBack);
					}
				} else {
					failure();
				}
			},
			failure
		);
	}

	getPartsData(ids, documentHeaderData, initCallBack) {
		const me = this;

		return edi.rest.sendRequest(
			edi.document.actions.formatBatchContentUri(me.moduleData.initData),
			'POST',
			Ext.encode(ids),
			function (data) {
				me.part2 = null;
				if (data && data.items) {
					for (let i = 0; i < data.items.length; i++) {
						if (me.part1Head.id == data.items[i].header) {
							me.part1 = data.items[i];
						} else if (me.part2Head && me.part2Head.id == data.items[i].header) {
							me.part2 = data.items[i];
						}
					}
					me.getOrganizationAdditionalFields(documentHeaderData.toOrg.id, documentHeaderData, initCallBack);
				} else {
					me.failure(data);
				}
			},
			me.failure
		);
	}

	renderData(initCallBack) {
		const me = this;

		me.moduleData.tab.setLoading();
		const failure = edi.document.actions.defaultFailureHandler(
			me.moduleData.tab,
			'error.getting.data',
			function () {
				edi.modulesHandler.removeModule(me.moduleData);
			}
		);
		let documentHeaderData = me.moduleData.initData.data;

		edi.document.actions.updateDocumentHeaderData(me.moduleData, function () {
			documentHeaderData = me.moduleData.initData.data;
			me.rejectReasonTextValue =
				edi.utils.getObjectProperty(documentHeaderData.attributes, 'utochCreated').value === 'true';

			edi.rest.sendRequest(
				edi.document.actions.formatLinkedUri(me.moduleData.initData),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						let i,
							ids = [];
						for (i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL) {
								me.rejectReasonText = edi.utils.getObjectProperty(
									data.data.children[i],
									'attributes.REJECT_REASON.value'
								);
							}
						}

						!(function (children) {
							let latestAnnulRejectReason = null;

							for (i = 0; i < children.length; i++) {
								let child = children[i];
								if (child.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL) {
									if (
										!latestAnnulRejectReason ||
										latestAnnulRejectReason.modifyDate < child.modifyDate
									) {
										latestAnnulRejectReason = child;
									}
								}
							}
						})(data.data.children);

						for (i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
								ids.push(data.data.children[i].id);
							} else if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2) {
								ids.push(data.data.children[i].id);
							}
							if (ids.length === 2) {
								break;
							}
						}
						if (ids.length) {
							edi.rest.sendRequest(
								edi.document.actions.formatBatchHeaderUri(me.moduleData.initData),
								'POST',
								Ext.encode(ids),
								function (data) {
									me.part2Head = null;
									for (let i = 0; i < data.items.length; i++) {
										if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
											me.part1Head = data.items[i];
											edi.document.actions.changeTabTitle(me.moduleData.tab, me.part1Head.number);
										} else if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2) {
											me.part2Head = data.items[i];
										}
									}
									me.getPartsData(ids, documentHeaderData, initCallBack);
								},
								failure
							);
						} else {
							failure();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		});
	}

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy() {
		const me = this;

		edi.events.documents.un('change', me._changeHandler);
		edi.events.documents.un('sign', me._signHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name);
		return true;
	}
}

Ext.namespace('edi.modules');
edi.modules['document.details.fns_upd_5_01n'] = DocumentDetailsFns_upd_5_01nModule;
