const TREE_LINE_MODEL = 'DESADV_LINE_NEW';
const TREE_SUMMARY_MODEL = 'DESADV_SUMMARY_NEW';
const PRODUCT_REFERENCE_MODEL = 'DESADV_REFERENCE_NEW';
const PRODUCT_REFERENCE_ELEMENT_MODEL = 'DESADV_REFERENCE_ELEMENT_NEW';

edi.models.setModels({
	[TREE_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'productID',
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'items'
				//type: 'object'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'OrderedQuantity',
				type: 'string'
			},
			{
				name: 'QuantityDespatched',
				type: 'string'
			},
			{
				name: 'ProductQuantityDespatched',
				type: 'string'
			},
			{
				name: 'UnitPacksize',
				type: 'string'
			},
			{
				name: 'UnitVolume',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'ExpirationDate',
				type: 'string'
			},
			{
				name: 'BatchNumber',
				type: 'string'
			},
			{
				name: 'ItemStatusCode',
				type: 'string'
			},
			{
				name: 'ColourCode',
				type: 'string'
			},
			{
				name: 'ItemSize',
				type: 'string'
			},
			{
				name: 'SupplierPackCode',
				type: 'string'
			},
			{
				name: 'UnitNetPrice',
				type: 'string'
			},
			{
				name: 'UnitGrossPrice',
				type: 'string'
			},
			{
				name: 'ItemType',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'SuggestedPrice',
				type: 'string'
			},
			{
				name: 'MaxRetailPrice',
				type: 'string'
			},
			{
				name: 'NetAmount',
				type: 'string'
			},
			{
				name: 'TaxAmount',
				type: 'string'
			},
			{
				name: 'GrossAmount',
				type: 'string'
			},
			{
				name: 'ProductNetAmount',
				type: 'string'
			},
			{
				name: 'ProductTaxAmount',
				type: 'string'
			},
			{
				name: 'ProductGrossAmount',
				type: 'string'
			},
			{
				name: 'Remarks',
				type: 'string'
			},
			{
				name: 'Package-Identification'
				//type: 'object'
			},
			{
				name: 'VSDNumber',
				type: 'string'
			},
			{
				name: 'SerialNumber',
				type: 'string'
			},
			{
				name: 'ProductionDate',
				type: 'string'
			},
			{
				name: 'Line-AdditionalInformation'
				//type: 'object'
			},
			{
				name: 'Line-Order'
				//type: 'object'
			},
			{
				name: 'LinePartyName',
				type: 'string'
			},
			{
				name: 'CertificateNumber',
				type: 'string'
			},
			{
				name: 'BoxesQuantity',
				type: 'string'
			},
			{
				name: 'CertificateDateOfIssue',
				type: 'string'
			},
			{
				name: 'Line-Reference'
				//type: 'array'
			},
			{
				name: 'ReasonCode',
				type: 'string'
			},
			//Packing fields

			{
				name: 'Type',
				type: 'string',
				defaultValue: ''
			},
			{
				name: 'ID-Begin',
				type: 'string'
			},
			{
				name: 'PackagingUnitUniversalCode',
				type: 'string'
			},
			{
				name: 'PackagingUnitName',
				type: 'string'
			},
			{
				name: 'PackagingUnitBuyerCode',
				type: 'string'
			},
			{
				name: 'PackagingUnitSupplierCode',
				type: 'string'
			},
			{
				name: 'VSDNumber',
				type: 'string'
			},
			{
				name: 'BatchNumber',
				type: 'string'
			},
			{
				name: 'PackProductionDate',
				type: 'string'
			},
			{
				name: 'PackExpirationDate',
				type: 'string'
			},
			{
				name: 'packingLevel',
				type: 'number'
			},
			{
				name: 'packingId',
				type: 'string'
			},
			{
				name: 'PackagingUnit',
				type: 'string'
			},
			{
				name: 'SizeUnitOfMeasure',
				type: 'string'
			},
			{
				name: 'SizeLength',
				type: 'string'
			},
			{
				name: 'SizeWidth',
				type: 'string'
			},
			{
				name: 'SizeHeight',
				type: 'string'
			},
			{
				name: 'WeightUnitOfMeasure',
				type: 'string'
			},
			{
				name: 'WeightNetWeight',
				type: 'string'
			},
			{
				name: 'WeightGrossWeight',
				type: 'string'
			},
			{
				name: 'WeightUnitWeight',
				type: 'string'
			},
			{
				name: 'WeightBoxWeight',
				type: 'string'
			},
			{
				name: 'WeightPalletWeight',
				type: 'string'
			}
		]
	},
	[TREE_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'TotalPSequence',
				type: 'number'
			},
			{
				name: 'TotalLines',
				type: 'number'
			},
			{
				name: 'TotalGoodsDespatchedAmount',
				type: 'string'
			},
			{
				name: 'TotalNetAmount',
				type: 'float'
			},
			{
				name: 'TotalGrossAmount',
				type: 'float'
			},
			{
				name: 'TotalTaxAmount',
				type: 'string'
			}
		]
	},
	[PRODUCT_REFERENCE_MODEL]: {
		idProperty: 'fake_id',
		fields: [
			{
				name: 'fake_id',
				type: 'string'
			},
			{
				name: 'Reference-Id',
				type: 'string'
			},
			{
				name: 'countryOrigin',
				type: 'string'
			}
		]
	},
	[PRODUCT_REFERENCE_ELEMENT_MODEL]: {
		idProperty: 'fake_id',
		fields: [
			{
				name: 'fake_id',
				type: 'string'
			},
			{
				name: 'Reference-Id',
				type: 'string'
			},
			{
				name: 'Reference-Type',
				type: 'string'
			}
		]
	}
});

export { TREE_LINE_MODEL, TREE_SUMMARY_MODEL, PRODUCT_REFERENCE_MODEL, PRODUCT_REFERENCE_ELEMENT_MODEL };
