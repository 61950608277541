import { ArchiveDocumentsGridModule } from '@Edi/modules/documentGrids/document.archive';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createLabel } from '@Components/fields';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel,
	createToolBar
} from '@UIkit/components/grid';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { MODAL_SIZE, showMessageBox } from '@UIkit/components/modal';
import { createTab } from '@Components/panels';

export class EWArchiveDocumentsGridModule extends ArchiveDocumentsGridModule {
	constructor() {
		super();
	}

	downloadApprovalArchive = function (docId) {
		edi.rest.downloadFile(
			edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.EXPORT.GET, {
				id: docId
			}),
			docId
		);
	};
	createApprovalTab() {
		const me = this;

		const url = edi.utils.compileURL(edi.rest.services.APPROVAL_DOCUMENTS.GET, {
			gridName: 'archive'
		});

		const createFilterForm = function () {
			let filterObject;
			const fireSearch = function () {
				if (!filterObject) {
					filterObject = edi.filters.createGridFilter(
						url,
						filterForm,
						grid,
						edi.filters.config.ew_archive.createArgs,
						{
							gridName: 'archived'
						},
						{
							persistence: {
								enabled: true,
								name: `${me.moduleData.modName}_archive_approval_grid`
							}
						}
					);
				}
				filterObject.filter();
			};

			let { formItemsMap, items } = edi.filters.config.ew_archive.createFormItems();
			const filterForm = createModuleFilterForm(
				{
					saveFilter: {
						name: `${me.moduleData.modName}_archive_approval_grid`
					},
					border: 1,
					formItemsMap,
					items,
					setFormDefaults: function () {
						let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod('week');
						formItemsMap.creationDateRange.activatePeriod(defaultModifyDateRange);
					},
					listeners: {
						afterrender: function (filterForm) {
							filterForm.fireSearch();
						},
						destroy: function () {
							if (filterObject && filterObject.searchTimeout) {
								clearTimeout(filterObject.searchTimeout);
							}
						}
					}
				},
				fireSearch
			);
			filterForm.fireSearch = fireSearch;
			return filterForm;
		};

		const filter = createFilterForm();

		/**
		 * Initializes grid
		 * @returns {Object} Instance of grid
		 */
		const createGridFn = function () {
			const gridDataChangedHandler = function () {
				edi.events.documents.fireEvent('change');
				grid.getStore().reload();
			};
			const columns = edi.columns.get('ew_approvals_archive');
			const actionItems = [],
				totalsLabel = createLabel({
					text: edi.i18n.getMessage('documents.selected.totals', {
						selectedRows: 0,
						totalSumm: '0.00'
					}),
					cls: 'edi-tbar-total-label-right'
				});

			const actionColumn = createActionsColumnConfig({
				align: 'center',
				items: [
					{
						glyph: edi.constants.ICONS.FILE_DOWNLOAD,
						tooltip: edi.i18n.getMessage('documents.archive.download'),
						handler: function (grid, rowIndex) {
							me.downloadApprovalArchive(grid.getStore().getAt(rowIndex).get('headerId'));
						},
						isActionDisabled: function () {
							return !edi.permissions.hasPermission('CLIENT_ARCHIVED_APPROVAL_LOAD');
						}
					}
				]
			});
			columns.push(actionColumn);

			let topBar = undefined;
			if (actionItems.length) {
				let percent,
					progress,
					packId,
					userStopMe = false;
				const onMenuItemClick = function (id) {
					const options = {
						grid: grid,
						moduleData: me.moduleData,
						action: id
					};
					if (edi.constants.DOCUMENT_ACTIONS.ARCHIVE_RESTORE === id) {
						Object.assign(options, {
							loadingMessage: 'documents.archive.restore.document.started',
							confirmTitle: 'documents.archive.restore.document',
							confirmAction: false,
							handler: function (record, grid, callback, failure) {
								const id = record.get('id');
								const url = edi.utils.formatString(
									edi.rest.services.DOCUMENTS.ARCHIVE.RESTORE.GET,
									{ archivedObjectId: id },
									true
								);
								let fail = function (respData) {
									let errorText = edi.utils.formatComplexServerError(respData, 'error.getting.data');
									if (errorText) {
										edi.core.showError(errorText);
									}
									failure(record, errorText);
								};
								let success = function () {
									grid.getSelectionModel().deselect(record);
									callback && 'function' == typeof callback ? callback() : null;
									gridDataChangedHandler();
								};
								edi.rest.sendRequest(url, 'GET', undefined, success, fail);
							}
						});
					}
					edi.document.actions.documentGridActionProcess(options);
				};
				const success = function (data) {
					if (!userStopMe) {
						if (data && data.data && data.data.hasOwnProperty('percentageOfReadiness')) {
							percent = data.data.percentageOfReadiness;
						}
						progress.updateProgress(percent / 100, percent + ' %');
						if (percent === 100 && progress) {
							progress.hide();
							progress = null;
							if (
								data.data.hasOwnProperty('state') &&
								edi.constants.EXPORT_GROUP_REQUEST.STATE.READY === data.data.state
							) {
								edi.rest.downloadFile(
									edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.EXPORT.GET, {
										id: data.data.id
									})
								);
							}
						} else {
							setTimeout(startSearch, 1000);
						}
					} else {
						if (progress) {
							progress.hide();
							progress = null;
						}
						const closeMsg = edi.core.confirm(
							edi.i18n.getMessage('export.group.request.progress.title'),
							edi.i18n.getMessage('export.group.request.progress.close'),
							undefined,
							undefined,
							undefined,
							undefined,
							{
								closable: false,
								buttons: [
									createButton({
										cls: BUTTON_CLS.primary,
										text: edi.i18n.getMessage('form.btn.download.later'),
										handler: function () {
											closeMsg.close();
										}
									}),
									createButton({
										cls: BUTTON_CLS.secondary,
										text: edi.i18n.getMessage('form.btn.stop'),
										handler: function () {
											edi.rest.sendRequest(
												edi.utils.formatString(
													edi.rest.services.EXPORT.GROUP_REQUEST.PUT,
													{
														id: packId
													},
													true
												),
												'PUT',
												Ext.encode({ REJECT: true }),
												undefined,
												undefined
											);
										}
									})
								]
							}
						);
					}
				};
				const failure = function (data) {
					if (progress && userStopMe) {
						progress.hide();
						progress = null;
					}
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.accept.registry.find'));
					me.moduleData.tab.setLoading(false);
				};
				const startSearch = function () {
					if (!progress) {
						percent = 0;
						progress = showMessageBox({
							closable: false,
							title: edi.i18n.getMessage('export.group.request.progress.title'),
							width: MODAL_SIZE.widthMedium,
							progress: true,
							progressBarConfig: {
								title: edi.i18n.getMessage('export.group.request.progress.msg'),
								description: percent + ' %'
							},
							okBtnConfig: {
								text: edi.i18n.getMessage('form.btn.close'),
								handler: function () {
									progress.close();
									userStopMe = true;
								}
							}
						});
					} else {
						progress.updateProgress(percent / 100, percent + ' %');
					}
					const url = edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.GET, { id: packId });
					edi.rest.sendRequest(url, 'GET', null, success, failure);
				};
				topBar = createGridActionBar({
					actionCfgs: actionItems,
					defaultHandler: onMenuItemClick,
					totalsLabel: totalsLabel
				});
			}
			/**
			 * Checkbox selection model processor linked with grid top bar action items
			 * @param model
			 * @param selected
			 * @param actionItems
			 * @param isProcessible
			 */
			const selectionProcessor = function (model, selected, actionItems, isProcessible) {
				let i,
					j,
					groupActionBtn,
					actionConf,
					totalSum = 0;
				for (i = 0; i < actionItems.length; i++) {
					groupActionBtn = actionItems[i];
					actionConf = groupActionBtn.rulesData;
					if (isProcessible(actionConf)) {
						var allowedDocs,
							allowFromGridConf = edi.document.actions.availableFromGrid[actionConf.id] || {},
							isAvailableFromGrid = true;

						allowedDocs = selected.filter(function (docRecord) {
							isAvailableFromGrid = true;
							var document = docRecord.getData();

							if (!Ext.isEmpty(allowFromGridConf) && Array.isArray(allowFromGridConf)) {
								Ext.each(allowFromGridConf, function (config) {
									if ('function' == typeof config.allow) {
										//Check action for allow performing from grid if doctypes  aren't specified in alloFromGrid conf
										//Or if  doctype exists in specified doctypes
										if (
											!config.documents ||
											(Array.isArray(config.documents) &&
												config.documents.indexOf(document.type) > -1)
										) {
											isAvailableFromGrid = config.allow(document);
										}
									}
								});
							}

							return (
								isAvailableFromGrid &&
								edi.action.isAvailable(actionConf.id, edi.action.getDocumentData(docRecord))
							);
						});

						if (edi.constants.DOCUMENT_ACTIONS.COPY == actionConf.id && allowedDocs.length > 1) {
							groupActionBtn.setDisabled(true);
						} else if (!actionConf.notDisabled) {
							groupActionBtn.setDisabled(!allowedDocs.length);
						}
					}
				}
				for (j = 0; j < selected.length; j++) {
					totalSum += Math.round(selected[j].get('totalSumm') * 100) / 100;
				}
				edi.methods.grid.displaySelectedTotals(grid, totalSum, selected.length, 'documents.selected.totals');
			};
			const grid = createGrid({
				saveSorters: true,
				proxyConfig: {
					type: 'ajax',
					url
				},
				storeConfig: {
					pageSize: edi.constants.ITEMS_PER_PAGE,
					model: edi.models.getModel('DOCUMENT_BASE_ARCH'),
					groupField: null,
					sortOnLoad: true,
					sorters: {
						property: 'modifyDate',
						direction: 'DESC'
					},
					autoLoad: false
				},
				gridConfig: {
					columns: columns,
					padding: 0,
					region: 'center',
					layout: 'fit',
					border: 1,
					selModel:
						topBar && topBar.menuActionItemsCnt
							? createGridCheckboxSelectionModel({
									topBar: topBar,
									selectionProcessor: selectionProcessor
							  })
							: undefined,
					dockedItems: createToolBar({
						items: [topBar]
					})
				},
				viewConfig: {
					emptyTextTplOptions: {
						enabled: true,
						iconName: 'archive-approval',
						title: edi.i18n.getMessage('ediweb.archive.empty.text.tpl.title.approval'),
						contentText: edi.i18n.getMessage('ediweb.archive.empty.text.tpl.contentText.approval'),
						beforeButtonText: edi.i18n.getMessage(
							'ediweb.archive.empty.text.tpl.beforeButtonText.approval'
						),
						buttonText: edi.i18n.getMessage('ediweb.archive.empty.text.tpl.buttonText.approval'),
						buttonName: 'emptyTextButton',
						buttonClickHandler: function (e, btn, name) {
							filter.toggleHandler();
						}
					}
				},
				pagingBarConfig: {
					itemsPerPage: edi.constants.ITEMS_PER_PAGE,
					totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
				}
			});
			grid.totalsLabel = topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined;
			return grid;
		};

		const grid = createGridFn();

		return createTab({
			title: edi.i18n.getMessage('approval.documents'),
			closable: false,
			bodyPadding: 0,
			layout: 'border',
			border: 0,
			autoScroll: false,
			items: [filter, grid]
		});
	}
}
Ext.namespace('edi.modules');
edi.modules['document.archive'] = EWArchiveDocumentsGridModule;
