import '../edi/index';
import '../fns/index';
import '../edi/modules/index';
import '../fns/modules/documents/index';

import './extensions';
import './core';
import './converters.js';
import './components/index';
import './components/tour';
import './login.js';

import './rest';
import './configuration';
import './i18n';
import './permissions';
import './store';
import './filters';
import './document.actions.js';
import './renderers.js';
import './columns';
import './models';
import './navigation';
import './modules';
import './notifications';
import './events';
import './selectors.js';
import './renderers.js';
import './methods';

import './realtime_messages.js';
import '../realtime_messages.js';
import '../signature/cryptopro/sign';

import './modules/index';

edi.core.loadLocalizationGroups.push('edi_core_client');
edi.core.loadLocalizationGroups.push('ediweb_client');
