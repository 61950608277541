import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createActionsButton } from '@Components/buttons';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import { createContainer, createFieldBlockForDetails } from '@UIkit/components/panels';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { PRICAT_EANCOM_LINE_COLUMN } from '@Edi/modules/documents/PRICAT_EANCOM/columns';
import { MODAL_SIZE } from '@UIkit/components/modal';

Ext.namespace('edi.modules');
edi.modules['document.details.pricateancom'] = function () {
	let moduleData,
		invoice = null,
		noticeDoc = null,
		utochDoc = null,
		failure;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates invoice form
	 * @returns {Object}
	 */
	const createDetailsPanel = function (pricatEancom) {
		const mainData = Ext.clone(moduleData.initData.data);
		mainData.hasLinkedDocuments = true;

		const getComboBoxValue = function (valuesArray, fieldName) {
			let nameCode = edi.utils.getObjectProperty(pricatEancom, fieldName);
			if (!valuesArray.some((it) => it === String(nameCode))) {
				nameCode = null;
			}
			return nameCode;
		};

		const getPricatEancomValue = function (fieldName, asArray) {
			return edi.utils.getObjectProperty(pricatEancom, fieldName, asArray);
		};

		const formatPricatDate = function (date) {
			return edi.utils.formatDate(
				date,
				edi.constants.DATE_FORMAT.FNS,
				edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM
			);
		};

		const headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			noUsage: true
		});

		const headData = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: 'column',
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('pricat_eancom.document.type'),
							text: edi.i18n.getMessage(
								'pricate_eancom.name.code.' + getComboBoxValue(['9', '25E'], 'bgm.c002.e1001')
							)
						},
						{
							title: edi.i18n.getMessage('pricat.nr'),
							text: getPricatEancomValue('bgm.c106.e1004')
						},
						{
							title: edi.i18n.getMessage('date'),
							text: formatPricatDate(getPricatEancomValue('dtm.0.c507.e2380'))
						}
					]
				})
			]
		});

		const seller_parties = edi.utils.getObjectPropertyEx(pricatEancom, 'sg2[nad.e3035==SU]');
		const seller = createOrgSelector({
			readOnly: true,
			valuesByMap: true,
			is_valid: true,
			fieldValues: seller_parties,
			fieldsMap: {
				company_name: 'nad.c080.e3036',
				company_iln: 'nad.c082.e3039',
				company_inn: 'sg3[rff.c506.e1153==FC]rff.c506.e1154',
				company_kpp: 'sg3[rff.c506.e1153==XA]rff.c506.e1154'
			},
			getObjectProperty: edi.utils.getObjectPropertyEx
		});

		const sellerBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.pricate_eancom.seller'),
			items: [seller]
		});

		const buyer_parties = edi.utils.getObjectPropertyEx(pricatEancom, 'sg2[nad.e3035==BY]');
		const buyer = createOrgSelector({
			readOnly: true,
			valuesByMap: true,
			is_valid: true,
			fieldValues: buyer_parties,
			fieldsMap: {
				company_name: 'nad.c080.e3036',
				company_iln: 'nad.c082.e3039',
				company_inn: 'sg3[rff.c506.e1153==FC]rff.c506.e1154',
				contact_person: 'sg4.0.cta.c056.e3412',
				contact_email: 'sg4.0.com.c076.e3148'
			},
			getObjectProperty: edi.utils.getObjectPropertyEx
		});

		const buyerBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.pricate_eancom.buyer'),
			items: [buyer]
		});

		const pricePeriodStart = formatPricatDate(getPricatEancomValue('dtm.1.c507.e2380'));
		const pricePeriodEnd = formatPricatDate(getPricatEancomValue('dtm.2.c507.e2380'));
		const pricePeriod = createFieldBlockForDetails({
			title: edi.i18n.getMessage('pricat_eancom.new.price.period'),
			items: [
				createLabelForDetails({
					title: edi.i18n.getMessage('date'),
					date: pricePeriodEnd ? [pricePeriodStart, pricePeriodEnd] : pricePeriodStart
				})
			]
		});

		const contractBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('pricat_eancom.contract.details'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('pricate_eancom.delivery.contract.number'),
							text: getPricatEancomValue('sg1.0.rff.c506.e1154')
						},
						{
							title: edi.i18n.getMessage('pricate_eancom.date.delivery.agreement'),
							text: formatPricatDate(getPricatEancomValue('sg1.0.dtm.c507.e2380'))
						},
						{
							title: edi.i18n.getMessage('pricat_eancom.contract.details.price.type.notice'),
							text: edi.i18n.getMessage(
								'pricate_eancom.type.price.notice.code.' +
									getComboBoxValue(['ZAK', 'MAR'], 'sg17.ftx.c108.e4440')
							)
						}
					]
				})
			]
		});

		const documentFormValues = {};
		for (let key in pricatEancom) {
			if (pricatEancom.hasOwnProperty(key) && key === 'sg9' && Array.isArray(pricatEancom[key])) {
				pricatEancom[key].forEach(function (item) {
					const itemType = edi.utils.getObjectProperty(item, 'loc.e3227');
					if (itemType == '7') {
						if (!documentFormValues.hasOwnProperty('storageInformation')) {
							documentFormValues.storageInformation = [];
						}
						documentFormValues.storageInformation.push({
							iln: item.loc.c517.e3225,
							name: item.loc.c517.e3224
						});
					} else if (itemType == '12') {
						if (!documentFormValues.hasOwnProperty('exceptionsFromStorage')) {
							documentFormValues.exceptionsFromStorage = [];
						}
						documentFormValues.exceptionsFromStorage.push({
							iln: item.loc.c517.e3225,
							name: item.loc.c517.e3224
						});
					}
				});
			}
		}

		const deliveryPlaceGrid = createProductGridBase({
			title: edi.i18n.getMessage('documents.pricate_eancom.storage'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'PARTIN_DELIVERY_PLACE',
			gridColumnConfig: 'partin_delivery_place',
			data: edi.utils.getObjectProperty(documentFormValues, 'storageInformation'),
			readOnly: true,
			allowBlank: true,
			lockIfNoPartner: false,
			showSelectProductBtn: false,
			hasTotal: false,
			modalConf: {
				width: MODAL_SIZE.widthSmall,
				maxHeight: undefined
			},
			modalFormConfig: {
				title: 'documents.pricate_eancom.storage',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.partin.tab.localization.info'),
						layout: {
							type: 'grid',
							gap: 24
						},
						items: [
							{
								title: 'column.name',
								name: 'name',
								maxLength: 128
							},
							{
								title: 'company.gln.short',
								maxLength: 100,
								name: 'iln',
								regex: edi.constants.VALIDATORS.GLN
							}
						]
					}
				]
			}
		});

		const exceptionsPlaceGrid = createProductGridBase({
			title: edi.i18n.getMessage('documents.pricate_eancom.exception.from.storage'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'PARTIN_DELIVERY_PLACE',
			gridColumnConfig: 'partin_delivery_place',
			data: edi.utils.getObjectProperty(documentFormValues, 'exceptionsFromStorage'),
			readOnly: true,
			allowBlank: true,
			lockIfNoPartner: false,
			showSelectProductBtn: false,
			hasTotal: false,
			modalConf: {
				width: MODAL_SIZE.widthSmall,
				maxHeight: undefined
			},
			modalFormConfig: {
				title: 'documents.pricate_eancom.exception.from.storage',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.partin.tab.localization.info'),
						layout: {
							type: 'grid',
							gap: 24
						},
						items: [
							{
								title: 'column.name',
								name: 'name',
								maxLength: 128
							},
							{
								title: 'company.gln.short',
								maxLength: 100,
								name: 'iln',
								regex: edi.constants.VALIDATORS.GLN
							}
						]
					}
				]
			}
		});

		const lines = edi.utils.getObjectProperty(pricatEancom, 'sg17.sg36'),
			pricatLines = [];
		if (lines && 'string' != typeof lines) {
			for (let i = 0; i < lines.length; i++) {
				pricatLines.push(lines[i]);
			}
		}

		const products = pricatLines.map(function (product) {
			const sg40 = edi.utils.getObjectProperty(product, 'sg40');
			const productValues = {
				LineNumber: edi.utils.getObjectProperty(product, 'lin.e1082'),
				EAN: edi.utils.getObjectProperty(product, 'lin.c212.e7140'),
				ItemCode: edi.utils.getObjectProperty(product, 'pia.0.c212.0.e7140'),
				Heading: edi.utils.getObjectProperty(product, 'imd.c273.e7008')
			};
			sg40?.forEach(function (item) {
				const c509 = edi.utils.getObjectProperty(item, 'pri.c509');
				if (c509.e5125 === 'ABL') {
					productValues.CurrentPrice = c509.e5118;
				} else if (c509.e5125 === 'AAE') {
					productValues.NewPrice = c509.e5118;
				}
			});
			return productValues;
		});

		const productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('pricat_eancom.lines.grid'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'EANCOM_LINE',
			gridColumnConfig: PRICAT_EANCOM_LINE_COLUMN,
			data: products,
			readOnly: true,
			hasTotal: false,
			modalConf: {
				width: MODAL_SIZE.widthLarge,
				maxHeight: undefined
			},
			modalFormConfig: {
				title: 'pricat.product.modal.title',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						layout: {
							type: 'grid',
							gap: 24
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN'
							},
							{
								title: edi.i18n.getMessage('column.pricate.eancom.item.code'),
								name: 'ItemCode'
							},
							{
								title: edi.i18n.getMessage('column.pricate.eancom.heading'),
								name: 'Heading'
							},
							{
								title: edi.i18n.getMessage('column.pricate.eancom.current.price'),
								name: 'CurrentPrice'
							},
							{
								title: edi.i18n.getMessage('column.pricate.eancom.new.price'),
								name: 'NewPrice'
							}
						]
					}
				]
			}
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6]]
			},
			items: [
				headPanel,
				createContainer({
					cls: 'edi-form-maxwidth',
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [12, [6, 6]]
					},
					items: [headData, sellerBlock, buyerBlock, pricePeriod, contractBlock]
				}),
				deliveryPlaceGrid,
				exceptionsPlaceGrid,
				productsGrid
			]
		});
	};

	/**
	 * Creates action panel
	 */
	const createModuleActionsPanel = function (invoice) {
		const data = moduleData.initData.data;
		invoice.id = data.id;
		const actionsPanel = createActionsPanel();
		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const needSignatures = edi.document.actions.getSignCount(data);
		const needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.SUPPLIER);
		let signaturesToSet = needSignatures + needSignatures2 - data.countSignatures;
		if (signaturesToSet < 0) {
			signaturesToSet = 0;
		}

		let readCmp = null;
		if (
			edi.constants.DIRECTIONS.INCOMING === direction &&
			data.state === edi.constants.STATE.WAIT_RECEIVER_DECISION
		) {
			readCmp = createActionsButton({
				text: edi.i18n.getMessage('document.mark.read'),
				glyph: edi.constants.ICONS.READ,
				handler: function () {
					edi.core.confirm(
						edi.i18n.getMessage('document.mark.read'),
						edi.i18n.getMessage('document.mark.read.question'),
						function () {
							moduleData.tab.setLoading();
							const success = function () {
								moduleData.tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: data.id
								});
							};
							const failure = edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.mark.read'
							);
							const markRead = function () {
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: data.id
										},
										true
									),
									'PUT',
									Ext.encode({
										CREATE_IZVPOL: true
									}),
									success,
									failure
								);
							};
							markRead();
						}
					);
				}
			});
		}
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesToSet,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.pricateancom'
				},
				SIGN: {
					methodAddOptions: {
						useBeforeAction:
							data.state === edi.constants.STATE.DRAFT ||
							data.state === edi.constants.STATE.SENT ||
							data.state === edi.constants.STATE.WAIT_RECEIVER_DECISION
					}
				},
				READ: {
					component: readCmp
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				REJECT: {
					methodAddOptions: {
						process: {
							callback: function (_signData, success, failed) {
								edi.document.actions.updateDocumentHeaderData(moduleData, function (headData) {
									getLinkedDocument(function (responseData) {
										if (
											responseData &&
											Array.isArray(responseData.items) &&
											responseData.items.length
										) {
											const linkedDocs = responseData.items;
											for (let i = 0; i < linkedDocs.length; i++) {
												if (
													linkedDocs[i].type ===
													edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_UVUTOCH
												) {
													utochDoc = linkedDocs[i];
												}
											}

											if (utochDoc && utochDoc.id) {
												edi.document.actions.signUtochDocument(
													utochDoc,
													headData,
													function () {
														edi.core.showInfo('dsf.utoch.created.success');
														success();
													},
													failed,
													moduleData.tab,
													null,
													{
														signContentUrlMethod: 'PUT'
													}
												);
											} else {
												changeHandler(headData);
											}
										} else {
											changeHandler(headData);
										}
									});
								});
								edi.events.documents.fireEvent('change', {
									id: data.id
								});
							}
						}
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE, {
						documentId: moduleData.initData.id
					})
				}
			}
		});
		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};

	const getLinkedDocument = function (callback) {
		const url = edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
			depth: edi.constants.DEFAULT.TREE_DEPTH
		});
		edi.rest.sendRequest(url, 'GET', {}, callback, failure);
	};

	const showIzvPolSignModal = function (document) {
		edi.core.showWarn('dsf.need.sign.izv.pol', function () {
			edi.utils.sign(
				document,
				moduleData.tab,
				function (failed, responseData) {
					if (failed) {
						edi.core.showError(edi.utils.formatComplexServerError(responseData));
					} else {
						edi.events.documents.fireEvent('change', {
							id: document.id
						});
					}
				},
				undefined,
				undefined,
				undefined,
				undefined,
				{
					signContentUrl: edi.utils.formatString(
						'AB' === edi.login.getAuthType()
							? edi.rest.services.DOCUMENTS.SIGN_AB.GET
							: edi.rest.services.DOCUMENTS.SIGN.GET,
						{
							documentId: noticeDoc.id
						}
					),
					signContentUrlMethod: 'PUT'
				}
			);
		});
	};
	const showUtochSignModal = function (document) {
		edi.core.showWarn('pricate_eancom.need.sign.rejection', function () {
			edi.document.actions.signUtochDocument(
				utochDoc,
				document,
				function (response) {
					if (response?.data) {
						edi.events.documents.fireEvent('change', {
							id: document.id,
							deleted: true
						});
						edi.core.showInfo('dsf.utoch.created.success');
					}
				},
				function (responseData) {
					edi.core.showError(edi.utils.formatComplexServerError(responseData, 'error.sign.document'));
				},
				null,
				null,
				{
					signContentUrlMethod: 'PUT'
				}
			);
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		const data = moduleData.initData.data;
		const documentDirection = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const modulePanel = createDetailsModulePanel();

		failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const finishLoad = function () {
			const needSignIzvPol =
				data.state === edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER &&
				edi.constants.DIRECTIONS.INCOMING === documentDirection;
			const needSignUtoch =
				data.state === edi.constants.STATE.PRICAT_EANCOM_DP_UTOCH_RECEIVER &&
				edi.constants.DIRECTIONS.INCOMING === documentDirection;

			if (needSignUtoch && data.id) {
				showUtochSignModal(data);
			} else if (needSignIzvPol && data.id) {
				showIzvPolSignModal(data);
			}

			edi.rest.sendRequest(
				edi.document.actions.formatDetailsUri(moduleData.initData),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						moduleData.tab.removeAll();

						invoice = data.data;
						modulePanel.add(createDetailsPanel(invoice));
						const actionsPanel = createModuleActionsPanel(invoice);
						moduleData.tab.add(actionsPanel);
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						} else {
							moduleData.tab.setLoading(false);
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		};

		const annulStatus = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'ANNUL_STATUS');
		const rejectReasonText = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'REJECT_REASON'),
			hasUnfinishedAnnul =
				annulStatus &&
				(annulStatus === edi.constants.STATE.CREATED || annulStatus === edi.constants.STATE.DRAFT),
			hasIzvPol = data.state === edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER;

		if (rejectReasonText || hasUnfinishedAnnul || hasIzvPol) {
			getLinkedDocument(function (responseData) {
				if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
					const linkedDocs = responseData.items;
					for (let i = 0; i < linkedDocs.length; i++) {
						if (linkedDocs[i].type === edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_IZVPOL) {
							if (data.state === edi.constants.STATE.PRICAT_EANCOM_DP_IZVPOL_RECEIVER) {
								noticeDoc = linkedDocs[i];
							}
						} else if (linkedDocs[i].type === edi.constants.DOCUMENT_TYPES.EDI_PRICAT_EANCOM_DP_UVUTOCH) {
							utochDoc = linkedDocs[i];
						}
					}
				}
				finishLoad();
			});
		} else {
			finishLoad();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
