const APPLICATION_MODEL = 'EW_ORG_PROFILE_APPLICATION_TO_FNS';

edi.models.setModels({
	[APPLICATION_MODEL]: {
		fields: [
			{
				name: 'creationDate',
				type: 'number'
			},
			{
				name: 'type',
				type: 'string'
			}
		]
	}
});

export { APPLICATION_MODEL };
