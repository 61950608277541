import { AbstractPanel } from './abstract.panel.js';
import {
	createForm,
	createPanel,
	createFormContainer,
	createFieldBlock,
	createContainer
} from '@UIkit/components/panels';
import { createCombo, createLabel } from '@UIkit/components/fields';

class NotifiesPanel extends AbstractPanel {
	/**
	 * @type {Ext.panel.Panel}
	 * @private
	 */
	_panel;

	_optionsList = [
		'NEW_DOCUMENT',
		'NEW_STATUS_OF_DOCUMENT',
		'NEW_INVITATION',
		'NEW_STATUS_OF_INVITATION',
		'NEW_CONNECTED_PRODUCT',
		'INFORMATION',
		'PARTNERSHIP',
		'DOCUMENTS_APPROVAL',
		'TARIFFS',
		'POWER_OF_ATTORNEY'
	];

	_defaultFloatingNotificationSettings = {
		displayNotificationsTime: edi.constants.DEFAULT.DISPLAY_NOTIFICATIONS_TIME,
		maxNotificationsCount: edi.constants.DEFAULT.MAX_NOTIFICATIONS_COUNT,
		NEW_DOCUMENT: true,
		NEW_STATUS_OF_DOCUMENT: true,
		NEW_INVITATION: true,
		NEW_STATUS_OF_INVITATION: true,
		NEW_CONNECTED_PRODUCT: true,
		INFORMATION: true,
		PARTNERSHIP: true,
		DOCUMENTS_APPROVAL: true,
		TARIFFS: true,
		POWER_OF_ATTORNEY: true
	};

	/**
	 * @param {{defaultFilteringPeriod:String}} defaults
	 */
	constructor(defaults) {
		super();

		let form = this.createForm(defaults);
		let saveButton = this.createSaveButton(form);

		this._panel = this.createPanel({
			form,
			saveButton
		});

		this.loadFormValuesFromServer();
		this.makePanelConfirmable(this._panel);
	}

	getPanel() {
		return this._panel;
	}

	getValues() {
		return this._form.getValues();
	}

	createPanel({ form, saveButton }) {
		return createPanel({
			layout: 'fit',
			items: [form],
			bbar: {
				padding: '16 24',
				items: [saveButton]
			}
		});
	}

	getDisplayNotificationsTimeStore() {
		let maxNotificationsCountArray = ['10', '20', '30', '40'].map((maxCount) => {
			return {
				id: maxCount,
				name: edi.utils.formatString(edi.i18n.getMessage('ediweb.time.display.float.notification.second'), [
					maxCount
				])
			};
		});
		maxNotificationsCountArray.unshift({
			id: '0',
			name: edi.i18n.getMessage('ediweb.time.display.float.notification.do.not.display')
		});
		return edi.stores.createInlineStore(maxNotificationsCountArray, 'SIMPLE');
	}

	getMaxNotificationsCountStore() {
		let displayNotificationsTimeArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'].map((count) => {
			return {
				id: count,
				name: count
			};
		});
		return edi.stores.createInlineStore(displayNotificationsTimeArray, 'SIMPLE');
	}

	createForm() {
		return (this._form = createForm({
			padding: 24,
			autoScroll: true,
			items: [
				createContainer({
					layout: 'hbox',
					items: [
						createLabel({
							text: edi.i18n.getMessage('user.profile.notifies.form.description'),
							cls: 'ediweb-form-panel-description',
							margin: '0 0 32 0'
						}),
						createLabel({
							text: '.',
							margin: '4 0 0 16'
						})
					]
				}),
				createPanel({
					layout: 'hbox',
					items: [
						createPanel({
							width: 390,
							items: this.createOptionList()
						}),
						createFormContainer({
							width: 390,
							padding: 0,
							gap: 24,
							items: [
								this.createField(
									{
										width: 294,
										fieldLabel: edi.i18n.getMessage('ediweb.time.display.float.notification'),
										store: this.getDisplayNotificationsTimeStore(),
										name: 'displayNotificationsTime'
									},
									createCombo
								),
								this.createField(
									{
										width: 88,
										fieldLabel: edi.i18n.getMessage('ediweb.max.count.display.float.notification'),
										store: this.getMaxNotificationsCountStore(),
										name: 'maxNotificationsCount'
									},
									createCombo
								),
								createContainer({
									items: [
										this.createActionButton({
											width: 210,
											text: edi.i18n.getMessage('ediweb.set.default.notification.settings'),
											handler: async () => {
												this.setValues(this._defaultFloatingNotificationSettings);
											}
										})
									]
								})
							]
						})
					]
				})
			]
		}));
	}

	defineFieldStates() {
		return {};
	}

	createOptionList() {
		let items = [];

		this._optionsList?.forEach((name) => {
			items.push(
				createFieldBlock({
					items: [
						this.createCheckboxField({
							title: edi.i18n.getMessage(`ediweb.notifications.title.UI Platform Maven Webapp`),
							name
						})
					]
				})
			);
		});

		return createFormContainer({
			gap: 16,
			items
		});
	}

	async loadFormValuesFromServer() {
		try {
			this.showLoader();

			let values = {};

			let disabledNotifies = await this.loadNotifiesSetting();
			this._optionsList?.forEach((name) => {
				values[name] = !~disabledNotifies.indexOf(name);
			});

			let configNotifies = await this.loadNotifiesConfig();
			let isEmptyObject =
				configNotifies &&
				Object.keys(configNotifies).length === 0 &&
				Object.getPrototypeOf(configNotifies) === Object.prototype;
			if (isEmptyObject) {
				configNotifies = Object.assign({}, this._defaultFloatingNotificationSettings);
			}
			Object.entries(configNotifies).forEach(([k, v]) => {
				let value = v ? v : this._defaultFloatingNotificationSettings[k];
				values[k] = String(value);
			});

			this.setValues(values);
		} catch (error) {
			console.error(error);
		} finally {
			this.hideLoader();
		}
	}

	async saveFormValues(values) {
		await this.saveTypesValues(values);
		await this.saveConfigValues(values);
	}

	saveTypesValues(values) {
		let itemsMap = {};
		this._optionsList?.forEach((key) => {
			itemsMap[key] = !!values[key];
		});

		return new Promise((resolve, reject) => {
			edi.rest.sendRequest(
				edi.rest.services.NOTIFICATIONS.DISABLED.TYPES.PUT,
				'PUT',
				Ext.encode(itemsMap),
				resolve,
				reject
			);
		});
	}

	saveConfigValues(values, saveDefaultValues = false) {
		const getNotificationConfigParams = () => {
			let notificationConfigParams = {};
			Object.entries(this._defaultFloatingNotificationSettings).forEach(function ([key, v]) {
				notificationConfigParams[key] = values[key];
			});
			return notificationConfigParams;
		};

		const notificationConfigParams = saveDefaultValues
			? this._defaultFloatingNotificationSettings
			: getNotificationConfigParams();
		return new Promise((resolve, reject) => {
			edi.rest.sendRequest(
				edi.rest.services.NOTIFICATIONS.CONFIG.PUT,
				'PUT',
				Ext.encode(notificationConfigParams),
				(response) => {
					resolve(response?.data ?? {});
				},
				reject
			);
		});
	}

	loadNotifiesSetting() {
		return new Promise((resolve, reject) => {
			edi.rest.sendRequest(
				edi.rest.services.NOTIFICATIONS.DISABLED.TYPES.GET,
				'GET',
				null,
				({ items }) => {
					resolve(items);
				},
				reject
			);
		});
	}

	loadNotifiesConfig() {
		return new Promise((resolve) => {
			edi.rest.sendRequest(
				edi.rest.services.NOTIFICATIONS.CONFIG.GET,
				'GET',
				null,
				(response) => {
					resolve(response?.data ?? {});
				},
				() => {
					resolve({});
				}
			);
		});
	}
}

export { NotifiesPanel };
