import { createButton } from '@UIkit/components/buttons';
// import { createLabel } from '@UIkit/components/fields/Label/Label';
import { createLabelBlock } from '@UIkit/components/fields/Label/LabelBlock';
import { createContainer } from '@UIkit/components/panels';
import { createModulePanel } from '@Components/panels';

Ext.namespace('edi.modules');
edi.modules['sandbox'] = function () {
	let moduleData;
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	this.onRender = function () {};

	var renderData = function (initCallBack) {
		let labelBlock;
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center',
			items: [
				createContainer({
					items: [
						(labelBlock = createLabelBlock({
							width: 300,
							// textConfig: {
							// 	typography: 'caption_01'
							// },
							gap: [4, 8],
							contents: [
								{
									// title: 'LALA',
									typography: 'body-short_02',
									text: 'ООО «Гвоздика»',
									color: '--color-blue',
									isBlock: true
								},
								{
									title: 'ИНН',
									// text: '6449013711'
									// color: '--color-blue',
									showEmptyValue: true
								},
								{
									title: 'КПП',
									text: '644901001'
								},
								{
									title: 'Регистрационный код',
									text: '9950200532041'
								},
								{
									title: 'Период',
									date: [1692598513899, 1692599513899],
									dateFormat: 'Y.m.d H:i'
								}
							]
						})),
						createButton({
							text: 'set title',
							handler: function () {
								labelBlock.getByIndex(0).setColor('--color-yellow');
								// labelBlock.getByIndex(0).setText('--color-yellow');
							}
						}),
						createButton({
							text: 'set text',
							handler: function () {
								label.setText('TEXT');
							}
						}),
						createButton({
							text: 'set text html',
							handler: function () {
								label.setText('<b>TEXT</b>', false);
							}
						})
					]
				})
			]
		});
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};

	var onDestroy = function () {
		return true;
	};
};
