import { createProductGridBase } from '@Components/product.grid';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createFieldSet,
	createNumberField,
	createTextField,
	createTimeField
} from '@Components/fields';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { ORDER_LINE_COLUMN, ORDER_SUMMARY_EDITABLE_COLUMN } from './columns';
import { ORDER_LINE_MODEL, ORDER_SUMMARY_MODEL } from './models';
import { createOrder } from './methods';
import {
	createButtonContainer,
	createContainer,
	createFieldBlock,
	createMaxWidthContainer,
	createRangeContainer
} from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createLabel } from '@UIkit/components/fields';
import { createCurrencyStore } from '@Edi/methods/methods';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { orderSelectors } from './selectors';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { coreMethods } from '@Core/commons';

/**
 * Class for new order creation, draft order edit
 * @author Pavel Pirogov, Anatoly Deryshev
 */
Ext.namespace('edi.modules');
edi.modules['document.create.order'] = function () {
	let moduleData,
		docHeader,
		id,
		isEdit = false,
		isCopy = false,
		sellerId;

	let userData = edi.core.getUserData(),
		direction,
		buyer,
		seller,
		shipFrom,
		delivery,
		ultimateCustomer,
		initialData,
		form,
		parentId,
		productsGrid,
		isNumberAutoGenerated = false,
		availableTaxRates = edi.methods.taxRates.getRatesByCountry(userData.org.country),
		linesData = [],
		costDecimals,
		productValues = {
			products: []
		},
		taxCalculationMethod,
		customFieldsContainer,
		topPath = '//Document-Order/',
		customFieldsObj;
	const defaultCurrency = userData.org.country === 'BLR' ? 'BYN' : edi.constants.DEFAULT.CURRENCY;
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docHeader = moduleData.initData.data;
		direction = edi.utils.getDocumentDirection(docHeader?.toOrg, docHeader?.fromOrg);
		costDecimals = coreMethods.getUserOrgCostDecimals();

		renderData(initCallBack);
		return onDestroy;
	};

	const onBlockRemoved = function (eventData) {
		if (eventData.docId === docHeader.id && !moduleData.tab.isDestroyed) {
			moduleData.isChanged = false;
			edi.modulesHandler.removeModule(moduleData);
		}
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('incomingBlockRemoved', onBlockRemoved);
	};

	/**
	 * Creates order create/edit form
	 * @param   {Object}    document    order data for editing, undefined for new order
	 * @returns {Object}
	 */
	var createModForm = function (document) {
		var i;

		const orderRemarks = createTextField({
			name: 'Order-Header.Remarks',
			value: document ? edi.utils.getObjectProperty(document, 'Order-Header.Remarks') : undefined,
			maxLength: 4000,
			isTextarea: true
		});

		const createMainDataBlock = function () {
			const nameCodeInput = createCombo({
				fieldLabel: edi.i18n.getMessage('order.name.code'),
				name: 'Order-Header.DocumentNameCode',
				value: edi.utils.getObjectProperty(document, 'Order-Header.DocumentNameCode') || '220',
				store: edi.stores.createSimpleInlineStore(
					['105', '788', '22E', '222', '220'],
					function (id) {
						return edi.i18n.getMessage('document.ordersp.order.name.code.' + id);
					},
					true
				),
				anyMatch: true
			});

			const numberDocument = createTextField({
				fieldLabel: edi.i18n.getMessage('order.number'),
				emptyText: isNumberAutoGenerated
					? edi.i18n.getMessage('document.generation.numbers.text')
					: edi.i18n.getMessage('nr'),
				allowBlank: isNumberAutoGenerated,
				disabled: isNumberAutoGenerated,
				value: document
					? edi.utils.getObjectProperty(document, 'Order-Header.OrderNumber') +
					  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
					: undefined,
				maxLength: 35,
				name: 'Order-Header.OrderNumber'
			});

			const autogenCheckbox = isNumberAutoGenerated
				? createCheckbox({
						boxLabel: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
						inputValue: true,
						checked: isNumberAutoGenerated,
						name: 'numberAutoGenerated',
						listeners: {
							change: function (checkbox, isChecked) {
								numberDocument.setEmptyText(
									isChecked
										? edi.i18n.getMessage('document.generation.numbers.text')
										: edi.i18n.getMessage('nr')
								);
								numberDocument.setValue('');
								numberDocument.setDisabled(isChecked);
								numberDocument.allowBlank = isChecked;
								numberDocument.validate();
							}
						}
				  })
				: null;

			const documentDate = createDateField({
				emptyText: edi.i18n.getMessage('date'),
				allowBlank: false,
				value:
					document && !isCopy ? edi.utils.getObjectProperty(document, 'Order-Header.OrderDate') : new Date(),
				name: 'Order-Header.OrderDate'
			});

			const orderCurrency = createCombo({
				fieldLabel: edi.i18n.getMessage('order.currency'),
				name: 'Order-Header.OrderCurrency',
				value: document ? edi.utils.getObjectProperty(document, 'Order-Header.OrderCurrency') : defaultCurrency,
				store: createCurrencyStore(),
				anyMatch: true
			});

			const orderPromotion = createTextField({
				fieldLabel: edi.i18n.getMessage('promotion.reference'),
				maxLength: 100,
				value: document ? edi.utils.getObjectProperty(document, 'Order-Header.PromotionReference') : undefined,
				name: 'Order-Header.PromotionReference'
			});

			const documentFunctionCodeValue = edi.utils.getObjectProperty(
				document,
				'Order-Header.DocumentFunctionCode'
			);
			const functionalDocumentCode = createCombo({
				fieldLabel: edi.i18n.getMessage('functional.document.code'),
				name: 'Order-Header.DocumentFunctionCode',
				store: edi.stores.createSimpleInlineStore(['9', '4', '3', '16', 'R'], function (id) {
					return edi.i18n.getMessage('functional.document.code.' + id);
				}),
				value: document && documentFunctionCodeValue !== 'O' ? documentFunctionCodeValue : '9',
				anyMatch: true,
				allowBlank: false,
				showRequiredMsg: false
			});

			const discount = createNumberField({
				fieldLabel: edi.i18n.getMessage('document.form.discount'),
				value: document ? edi.utils.getObjectProperty(document, 'Order-Header.Discount') : undefined,
				name: 'Order-Header.Discount',
				allowDecimals: true,
				decimalPrecision: 2,
				maxLength: 16,
				maxText: ''
			});

			const specialInstructions = createTextField({
				fieldLabel: edi.i18n.getMessage('document.form.specialInstructions'),
				maxLength: 128,
				value: document ? edi.utils.getObjectProperty(document, 'Order-Header.SpecialInstructions') : undefined,
				name: 'Order-Header.SpecialInstructions'
			});

			return createMaxWidthContainer({
				layout: {
					type: 'grid',
					area: autogenCheckbox ? [[3, 4, 2], [4], [4], [3, 2, 4], 6] : [[3, 4, 2], [4], [3, 2, 4], 6]
				},
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					nameCodeInput,
					numberDocument,
					documentDate,
					autogenCheckbox,
					orderCurrency,
					functionalDocumentCode,
					discount,
					orderPromotion,
					specialInstructions
				]
			});
		};

		/**
		 * Информация о доставке
		 */
		const createTransportInfoBlock = function () {
			const modeOfTransport = createCombo({
				fieldLabel: edi.i18n.getMessage('document.kind.of.transport'),
				name: 'Order-Header.Order-Transport.ModeOfTransport',
				value: document
					? edi.utils.getObjectProperty(document, 'Order-Header.Order-Transport.ModeOfTransport')
					: undefined,
				store: edi.stores.initModeOfTransportStore()
			});

			const termsOfDelivery = createCombo({
				fieldLabel: edi.i18n.getMessage('document.ordersp.terms.of.delivery'),
				name: 'Order-Header.Order-Transport.TermsOfDelivery',
				value: edi.utils.getObjectProperty(document, 'Order-Header.Order-Transport.TermsOfDelivery'),
				store: edi.stores.initTermsOfDeliveryStore(),
				forceSelection: true,
				getRecordDisplayData: function (record) {
					const recId = record.data.id;
					if (['4', '7', '10E'].includes(recId)) {
						return {
							id: recId,
							name: edi.i18n.getMessage('document.ordersp.terms.of.delivery.' + recId)
						};
					}
					return record.data;
				}
			});

			const conveyanceReferenceNumber = createTextField({
				fieldLabel: edi.i18n.getMessage('document.ordersp.transport.number'),
				maxLength: 35,
				value: document
					? edi.utils.getObjectProperty(document, 'Order-Header.Order-Transport.ConveyanceReferenceNumber')
					: undefined,
				name: 'Order-Header.Order-Transport.ConveyanceReferenceNumber'
			});

			let deliveryTo, deliveryFrom;
			const expectedDeliveryPanel = createFieldBlock({
				title: edi.i18n.getMessage('document.expected.delivery'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				layout: {
					type: 'grid',
					area: [[3, 5, 2]]
				},
				items: [
					(deliveryFrom = createDateField({
						name: 'Order-Header.ExpectedDeliveryDate',
						value: document
							? edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryDate')
							: undefined,
						fieldLabel: edi.i18n.getMessage('expected.delivery.period.start'),
						listeners: {
							change: function (comp, value) {
								if (deliveryTo) {
									deliveryTo.setMinValue(value);
									deliveryTo.isValid();
								}
							}
						}
					})),
					createRangeContainer({
						fieldFrom: createTimeField({
							minWidth: 120,
							name: 'Order-Header.ExpectedDeliveryTime',
							flex: 2,
							value: document
								? edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryTime')
								: undefined,
							fieldLabel: edi.i18n.getMessage('time')
						}),
						fieldTo: (deliveryTo = createDateField({
							name: 'Order-Header.LastExpectedDeliveryDate',
							flex: 3,
							value: document
								? edi.utils.getObjectProperty(document, 'Order-Header.LastExpectedDeliveryDate')
								: undefined,
							fieldLabel: edi.i18n.getMessage('expected.delivery.period.end'),
							listeners: {
								change: function (comp, value) {
									if (deliveryFrom) {
										deliveryFrom.setMaxValue(value);
										deliveryFrom.isValid();
									}
								}
							}
						}))
					}),
					createTimeField({
						minWidth: 120,
						name: 'Order-Header.LastExpectedDeliveryTime',
						value: document
							? edi.utils.getObjectProperty(document, 'Order-Header.LastExpectedDeliveryTime')
							: undefined,
						fieldLabel: edi.i18n.getMessage('time')
					})
				]
			});

			const deliveryPeriodValue = edi.utils.getObjectProperty(document, 'Order-Header.DeliveryPeriod');
			//Период доставки хранится на бэке в виде одной строки "hh:mm-hh:mm"
			if (deliveryPeriodValue) {
				const deliveryPeriodValuesArr = deliveryPeriodValue.split('-');
				edi.utils.setObjectProperty(document, 'Order-Header.DeliveryPeriodFrom', deliveryPeriodValuesArr[0]);
				edi.utils.setObjectProperty(document, 'Order-Header.DeliveryPeriodTo', deliveryPeriodValuesArr[1]);
			}

			let deliveryPeriodFrom, deliveryPeriodTo;
			const updateDeliveryPeriodMandatory = function () {
				const atLeastOneValue = deliveryPeriodFrom.getValue() || deliveryPeriodTo.getValue();
				deliveryPeriodFrom.allowBlank = deliveryPeriodTo.allowBlank = !atLeastOneValue;
				deliveryPeriodFrom.isValid();
				deliveryPeriodTo.isValid();
			};

			const deliveryPeriod = createFieldBlock({
				title: edi.i18n.getMessage('period.of.delivery'),
				layout: {
					type: 'grid',
					area: [4]
				},
				items: [
					createRangeContainer({
						fieldFrom: (deliveryPeriodFrom = createTimeField({
							name: 'Order-Header.DeliveryPeriodFrom',
							value: document
								? edi.utils.getObjectProperty(document, 'Order-Header.DeliveryPeriodFrom')
								: undefined,
							increment: 30,
							minWidth: 50,
							flex: 2,
							listeners: {
								change: updateDeliveryPeriodMandatory
							}
						})),
						fieldTo: (deliveryPeriodTo = createTimeField({
							name: 'Order-Header.DeliveryPeriodTo',
							value: document
								? edi.utils.getObjectProperty(document, 'Order-Header.DeliveryPeriodTo')
								: undefined,
							increment: 30,
							minWidth: 50,
							flex: 2,
							listeners: {
								change: updateDeliveryPeriodMandatory
							}
						}))
					})
				]
			});

			return createFieldSet({
				title: edi.i18n.getMessage('document.section.delivery'),
				collapsible: true,
				listeners: {
					afterrender: updateDeliveryPeriodMandatory
				},
				items: [
					createMaxWidthContainer({
						layout: {
							type: 'grid',
							gap: 24
						},
						items: [
							createFieldBlock({
								userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
								layout: {
									type: 'grid',
									area: [[3, 4, 5]]
								},
								items: [modeOfTransport, termsOfDelivery, conveyanceReferenceNumber]
							}),
							expectedDeliveryPanel,
							deliveryPeriod
						]
					})
				]
			});
		};

		/**
		 * Дополнительная информация
		 */
		const createAdditionalInformationBlock = function () {
			const orderCorrectionNumber = createTextField({
				fieldLabel: edi.i18n.getMessage('field.name.number'),
				maxLength: 35,
				value: document
					? edi.utils.getObjectProperty(document, 'Order-Header.Reference.OrderCorrectionNumber')
					: undefined,
				name: 'Order-Header.Reference.OrderCorrectionNumber',
				listeners: {
					change: function (comp, value) {
						orderCorrectionDate.allowBlank = !value;
						form.isValid();
					}
				}
			});

			const orderCorrectionDate = createDateField({
				fieldLabel: edi.i18n.getMessage('date'),
				name: 'Order-Header.Reference.OrderCorrectionDate',
				value: document
					? edi.utils.getObjectProperty(document, 'Order-Header.Reference.OrderCorrectionDate')
					: undefined,
				emptyText: edi.i18n.getMessage('date')
			});

			const agreementNumber = createTextField({
				fieldLabel: edi.i18n.getMessage('field.name.number'),
				maxLength: 175,
				value: document
					? edi.utils.getObjectProperty(document, 'Order-Header.Reference.ContractNumber')
					: undefined,
				name: 'Order-Header.Reference.ContractNumber'
			});

			const agreementDate = createDateField({
				fieldLabel: edi.i18n.getMessage('date'),
				name: 'Order-Header.Reference.ContractDate',
				value: document
					? edi.utils.getObjectProperty(document, 'Order-Header.Reference.ContractDate')
					: undefined,
				emptyText: edi.i18n.getMessage('date')
			});

			return createFieldSet({
				title: edi.i18n.getMessage('documents.special.identifiers'),
				collapsible: true,
				layout: {
					type: 'grid',
					gap: 24
				},
				items: [
					createFieldBlock({
						cls: 'edi-form-maxwidth',
						title: edi.i18n.getMessage('document.order.correction'),
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						layout: {
							type: 'grid',
							area: [[3, 2]]
						},
						items: [orderCorrectionNumber, orderCorrectionDate]
					}),
					createFieldBlock({
						cls: 'edi-form-maxwidth',
						title: edi.i18n.getMessage('document.agreement'),
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						layout: {
							type: 'grid',
							area: [[3, 2]]
						},
						items: [agreementNumber, agreementDate]
					})
				]
			});
		};

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		const createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				valuesByMap: !!document,
				processValues: orgConverter,
				forceChangeFields: {
					bank_acc: true,
					company_iln: true
				}
			});
			return createOrgSelector(selectorConfig);
		};

		/**
		 * Стороны
		 */
		const createPartiesBlock = function () {
			const parties = document ? edi.utils.getObjectProperty(document, 'Order-Parties') : undefined,
				fieldValues = edi.converters.convertOrgToLegacyPartie(userData.org);

			buyer = createCS({
				itemId: 'buyer',
				is_valid: (parties && parties.Buyer && parties.Buyer.ILN) || userData.org.iln,
				fieldValues: document ? parties : fieldValues,
				selectedOrgValues: document ? undefined : fieldValues,
				orgFromRelation: true,
				originalValues: orgSelectorMethods.getOrgCardValues({
					orgId: docHeader?.fromOrg.id || userData.org?.id,
					converter: orgConverter
				}),
				selectedOrg: docHeader?.fromOrg.id
					? edi.utils.getOrg({
							orgId: docHeader.fromOrg.id
					  })
					: userData.org,
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				callback: function (values, org) {
					var orgId = org ? org.id : null;
					if (orgId) {
						taxCalculationMethod = edi.utils.getTaxCalculationMethod(org.attributes);
					} else {
						taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE;
					}
					checkValid();
				},
				fieldsMap: orderSelectors.getBuyerFieldsMap(),
				modalConf: orderSelectors.getBuyerModalCong()
			});

			const sellers = edi.relations.getRelations({ self: direction === edi.constants.DIRECTIONS.INCOMING });
			var sellerRelation = null;
			const sellerIln = moduleData.initData.data?.toOrg?.iln;
			if (document && sellerIln) {
				for (i = 0; i < sellers.length; i++) {
					if (sellerIln === sellers[i].iln) {
						sellerRelation = sellers[i];
						break;
					}
				}
			}

			seller = createCS({
				itemId: 'seller',
				relationsOnly: true,
				allowBlank: false,
				allowReset: !isEdit,
				relations: isEdit ? null : sellers,
				orgFromRelation: true,
				originalValues: orgSelectorMethods.getOrgCardValues({
					orgId: docHeader?.toOrg.id,
					converter: orgConverter
				}),
				selectedOrgValues: document ? sellerRelation : null,
				downloadBankAccountsData: true,
				is_valid: !!document,
				fieldValues: document ? parties : undefined,
				selectedOrg: docHeader?.toOrg.id
					? edi.utils.getOrg({
							orgId: docHeader.toOrg.id
					  })
					: undefined,
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				callback: function (values, org) {
					const orgId = org?.id || values?.id;
					sellerId = orgId;
					var continueFn = function () {
						if (
							productsGrid.getPartnerId() != orgId &&
							productsGrid.productsGrid.getStore().getCount() > 0
						) {
							var modalClearWarn = edi.core.confirm(
								edi.i18n.getMessage('documents.order.modalClearWarn.title'),
								edi.i18n.getMessage('documents.order.modalClearWarn.text'),
								undefined,
								undefined,
								undefined,
								undefined,
								{
									closable: false,
									buttons: [
										createButton({
											cls: BUTTON_CLS.primary,
											text: edi.i18n.getMessage('documents.order.modalClearWarn.clear'),
											handler: function () {
												productsGrid.productsGrid.getStore().removeAll();
												modalClearWarn.close();
											}
										}),
										createButton({
											cls: BUTTON_CLS.secondary,
											text: edi.i18n.getMessage('documents.order.modalClearWarn.stay'),
											handler: function () {
												modalClearWarn.close();
											}
										})
									]
								}
							);
						}
						productsGrid.setPartnerId(orgId);
						if (shipFrom.getOrgIdForLoccat() !== orgId) {
							shipFrom.reset();
							shipFrom.checkOrgDataValid();
							shipFrom.setOrgIdForLoccat(orgId);
							shipFrom.setPartnerOrg(
								edi.utils.getOrg({
									orgId: orgId
								})
							);
						}
						checkValid();
					};

					var buyerId = buyer.getValues().id || buyer.selectedOrg?.id;
					if (orgId && buyerId) {
						edi.methods.custom_fields.initCustomFields({
							customFieldsObj,
							docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
							toOrgId: orgId,
							fromOrgId: buyerId,
							container: customFieldsContainer,
							grid: productsGrid,
							topPath: topPath,
							finishCallback(obj) {
								customFieldsObj = obj;
								form.isValid();
								continueFn();
							},
							fail() {
								form.isValid();
								continueFn();
							}
						});
					} else {
						continueFn();
					}
				},
				fieldsMap: orderSelectors.getSellerFieldsMap(),
				modalConf: orderSelectors.getSellerModalConf()
			});

			const shipFromDirectionConfig =
				direction === edi.constants.DIRECTIONS.INCOMING
					? {
							ownOrg: true
					  }
					: {
							partnerOrg: true,
							partnerOrgText: edi.i18n.getMessage('form.btn.supplier.org'),
							partnerOrgValues: sellerId
								? edi.utils.getOrg({
										orgId: sellerId
								  })
								: undefined
					  };
			shipFrom = createCS({
				itemId: 'shipFrom',
				allowReset: true,
				alwaysShowSelect: true,
				...shipFromDirectionConfig,
				allowBlank: true,
				ownCatalog: false,
				downloadBankAccountsData: true,
				relationsFromLoccatByOrgId: sellerId,
				fieldValues: document ? parties : undefined,
				is_valid: document ? !!edi.utils.getObjectProperty(parties, 'ShipFrom.ILN') : true,
				callback: function () {
					initialData ? edi.utils.setObjectProperty(initialData, 'Order-Parties.ShipFrom', null) : null;
					checkValid();
				},
				fieldsMap: orderSelectors.getShipFromFieldsConf(),
				modalConf: orderSelectors.getShipFromModalConf()
			});

			let buyerId = buyer.getValues().id || buyer.selectedOrg?.id || parties?.Buyer?.id || userData.org.id;
			const deliveryDirectionConfig =
				direction === edi.constants.DIRECTIONS.INCOMING
					? {
							partnerOrg: true,
							partnerOrgText: edi.i18n.getMessage('form.btn.buyer.org'),
							partnerOrgValues: buyerId
								? edi.utils.getOrg({
										orgId: buyerId
								  })
								: undefined
					  }
					: {
							ownOrg: true
					  };
			delivery = createCS({
				itemId: 'delivery',
				tooltip: 'document.delivery.tooltip',
				allowReset: true,
				alwaysShowSelect: true,
				...deliveryDirectionConfig,
				ownCatalog: false,
				allowBlank: false,
				relationsFromDelcatByOrgId: buyerId,
				fieldValues: document ? parties : undefined,
				is_valid: !!edi.utils.getObjectProperty(parties, 'DeliveryPoint.ILN'),
				callback: function () {
					initialData ? edi.utils.setObjectProperty(initialData, 'Order-Parties.DeliveryPoint', null) : null;
					checkValid();
				},
				fieldsMap: orderSelectors.getDeliveryFieldsConf(),
				modalConf: orderSelectors.getDeliveryModalConf()
			});

			const ultimateCustomerDirectionConfig =
				direction === edi.constants.DIRECTIONS.INCOMING
					? {
							partnerOrg: true,
							partnerOrgText: edi.i18n.getMessage('form.btn.buyer.org'),
							partnerOrgValues: buyerId
								? edi.utils.getOrg({
										orgId: buyerId
								  })
								: undefined
					  }
					: {
							ownOrg: true
					  };
			ultimateCustomer = createCS({
				itemId: 'ultimateCustomer',
				tooltip: 'documents.ultimate.customer.tooltip',
				allowReset: true,
				alwaysShowSelect: true,
				...ultimateCustomerDirectionConfig,
				ownCatalog: false,
				is_valid: true,
				allowBlank: true,
				relationsFromDelcatByOrgId: buyerId,
				fieldValues: document ? parties : undefined,
				callback: function () {
					initialData
						? edi.utils.setObjectProperty(initialData, 'Order-Parties.UltimateCustomer', null)
						: null;
					checkValid();
				},
				fieldsMap: orderSelectors.getUltimateCustomerFieldsConf(),
				modalConf: orderSelectors.getUltimateCustomerModalConf()
			});

			return createFieldSet({
				title: edi.i18n.getMessage('document.section.parties'),
				collapsible: true,
				items: [
					createMaxWidthContainer({
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [[6, 6], [6, 6], [6]]
						},
						items: [
							createFieldBlock({
								title: edi.i18n.getMessage('documents.order.buyer'),
								items: [buyer]
							}),
							createFieldBlock({
								title: edi.i18n.getMessage('documents.order.seller'),
								items: [seller]
							}),
							createFieldBlock({
								title: edi.i18n.getMessage('document.ship.from'),
								items: [shipFrom]
							}),
							createFieldBlock({
								title: edi.i18n.getMessage('document.delivery'),
								items: [delivery]
							}),
							createFieldBlock({
								title: edi.i18n.getMessage('documents.ultimate.customer'),
								items: [ultimateCustomer]
							})
						]
					})
				]
			});
		};

		/**
		 * Calculates price and tax summary according to tax recalculation method
		 * @param    {Object}     values              product data
		 * @param    {Object}     calcFieldsData      object with additional data that could override/extend values returned by gatherValuesRow
		 * @param    {Boolean}    roundResults        true to round calculated values
		 * @returns {*}
		 */
		var calculateValues = function (values, calcFieldsData, roundResults) {
			var data = edi.methods.product.grid.gatherValuesRow(values, edi.constants.DOCUMENT_TYPES.LEGACY_ORDER);
			if (calcFieldsData && 'object' == typeof calcFieldsData) {
				Ext.merge(data, calcFieldsData);
			}
			var calcData = edi.methods.product.price.recalculation(
				{
					taxRate: data.rateVal,
					amount: data.amount,
					netPrice: data.price,
					grossPrice: data.grossPrice,
					netSum: values.OrderedNetAmount,
					taxSum: values.OrderedTaxAmount,
					grossSum: values.OrderedGrossAmount
				},
				taxCalculationMethod,
				undefined,
				undefined,
				costDecimals
			);
			values.OrderedGrossAmount = calcData.grossSum;
			values.OrderedTaxAmount = calcData.taxSum;
			values.OrderedNetAmount = calcData.netSum;
			values.OrderedUnitGrossPrice = calcData.grossPrice;
			values.OrderedUnitNetPrice = calcData.netPrice;
			roundResults
				? (values = edi.methods.product.grid.roundValues(
						values,
						[
							'OrderedGrossAmount',
							'OrderedTaxAmount',
							'OrderedNetAmount',
							'OrderedUnitGrossPrice',
							'OrderedUnitNetPrice'
						],
						costDecimals
				  ))
				: null;
			return values;
		};

		if (document) {
			var lines = edi.utils.getObjectProperty(document, 'Order-Lines.Line', true);

			var processRecordValues = function (values) {
				var calculated = Ext.clone(values);
				calculateValues(calculated, undefined, true);
				return values;
			};

			if ('string' != typeof lines) {
				for (i = 0; i < lines.length; i++) {
					var values = Ext.clone(lines[i]['Line-Item']);
					values['Line-Reference'] = lines[i]['Line-Reference'];

					var lineReference = edi.utils.getObjectProperty(
						lines[i],
						'Line-Reference.Reference-Elements.Reference-Element',
						true
					);
					if (Array.isArray(lineReference)) {
						lineReference = lineReference.filter(function (item) {
							var referenceType = edi.utils.getObjectProperty(item, 'Reference-Type', false);
							return referenceType === 'spc';
						});
					} else {
						lineReference = [];
					}
					edi.utils.setObjectProperty(
						values,
						'Line-Reference.Reference-Elements.Reference-Element',
						lineReference[0]
					);

					values['BuyerLocationCode'] = edi.utils.getObjectProperty(
						lines[i],
						'Line-Delivery.BuyerLocationCode',
						true
					);
					values['ExpectedDeliveryDate'] = edi.utils.getObjectProperty(
						lines[i],
						'Line-Delivery.ExpectedDeliveryDate',
						true
					);
					edi.document.actions.setInternalLineId(lines[i], values);
					linesData.push(processRecordValues(values));
				}
				productValues.products = linesData;
			}
		}

		productsGrid = createProductGridBase({
			itemId: 'productsGrid',
			ownCatalog: false,
			title: edi.i18n.getMessage('document.order'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			data: document ? linesData : undefined,
			lockIfNoPartner: true,
			gridModel: ORDER_LINE_MODEL,
			totalModel: ORDER_SUMMARY_MODEL,
			gridColumnConfig: ORDER_LINE_COLUMN,
			totalColumnConfig: ORDER_SUMMARY_EDITABLE_COLUMN,
			lineNumberFieldName: 'LineNumber',
			isValidRecord: function (record) {
				var product = record.getData(),
					taxValid = edi.methods.taxRates.isValidRate(product.TaxRate, availableTaxRates, true);
				return (
					taxValid &&
					!!product.OrderedQuantity &&
					parseFloat(product.OrderedGrossAmount || 0) <= edi.utils.getMaxValueByLength(12)
				);
			},
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				checkValid();
			},
			afterRowEdit: function (values, field) {
				if (field === 'OrderedQuantity') {
					calculateValues(values, undefined, true);
				}
			},
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
			docData: document,
			resetFormOnCatalogSelection: true,
			selectProductHandler: function (product) {
				var fields = this.getFormFields();
				var PackDetails = product.PackDetails ? product.PackDetails : {};

				fields.EAN ? fields.EAN.setValue(product.EAN) : null;
				fields.OrderedQuantity && fields.OrderedQuantity.getValue() < PackDetails.MinOrderedQuantity
					? fields.OrderedQuantity.setValue(PackDetails.MinOrderedQuantity)
					: null;
				fields.ItemType ? fields.ItemType.setValue(product.ItemType) : null;
				fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
				fields.SupplierItemCode ? fields.SupplierItemCode.setValue(product.SupplierItemCode) : null;
				fields.BuyerItemCode ? fields.BuyerItemCode.setValue(product.BuyerItemCode) : null;
				fields.OrderedUnitNetPrice ? fields.OrderedUnitNetPrice.setValue(product.UnitNetPrice) : null;
				fields.OrderedUnitPacksize ? fields.OrderedUnitPacksize.setValue(PackDetails.UnitPacksize) : null;
				fields.ExpirationDate ? fields.ExpirationDate.setValue(product.ExpirationDate) : null;
				fields.MaxExpirationDate ? fields.MaxExpirationDate.setValue(product.MaxExpirationDate) : null;
				fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;
				fields.Discount?.setValue(product.Discount);

				fields.UnitOfMeasure ? fields.UnitOfMeasure.findAndSetValue(product.UnitOfMeasure) : null;
			},
			modalFormConfig: {
				title: 'document.order.line',
				formMethods: {
					setFieldsOrder: function () {
						var values = this.getFormValues();
						var fields = this.getFormFields();

						values = calculateValues(values, undefined, true);
						fields.OrderedNetAmount ? fields.OrderedNetAmount.setValue(values.OrderedNetAmount) : null;
						fields.OrderedTaxAmount ? fields.OrderedTaxAmount.setValue(values.OrderedTaxAmount) : null;
						fields.OrderedGrossAmount
							? fields.OrderedGrossAmount.setValue(values.OrderedGrossAmount)
							: null;
						fields.OrderedUnitNetPrice
							? fields.OrderedUnitNetPrice.setValue(values.OrderedUnitNetPrice)
							: null;
						fields.OrderedUnitGrossPrice
							? fields.OrderedUnitGrossPrice.setValue(values.OrderedUnitGrossPrice)
							: null;
					}
				},
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						customFieldTab: 'PRODUCT',
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [5, [6, 3, 3], [3, 3], [3, 3]]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								value: 'CU',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.product.description'),
								maxLength: 512,
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								maskRe: /\d/i,
								validator: edi.methods.validators.ean
							},
							{
								title: edi.i18n.getMessage('line.item.model'),
								maxLength: 100,
								name: 'Model'
							},
							{
								title: edi.i18n.getMessage('line.item.ordered.quantity'),
								name: 'OrderedQuantity',
								validator: edi.methods.validators.positiveNonZero,
								type: 'number',
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3,
								allowBlank: false,
								listeners: {
									change: function () {
										this.setFieldsOrder();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								maxLength: 35,
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								maxLength: 175,
								name: 'SupplierItemCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						customFieldTab: 'PRICES',
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [3, [3, 3], 3, [3, 3, 3], 3]
						},
						items: [
							{
								title: edi.i18n.getMessage('organization.taxCalculationMethod'),
								readOnly: true,
								value: edi.i18n.getMessage('organization.taxCalculationMethod.' + taxCalculationMethod)
							},
							{
								title: edi.i18n.getMessage('line.item.ordered.unit.net.price'),
								name: 'OrderedUnitNetPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 12,
								listeners: {
									change(field, newVal, oldVal) {
										if (+newVal === +oldVal) {
											return;
										}
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.NET_PRICE ||
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.WITHOUT
											? this.setFieldsOrder()
											: null;
										var fields = this.getFormFields();
										if (fields.OrderedUnitGrossPrice) {
											fields.OrderedUnitGrossPrice.isValid();
										}
									}
								},
								validator: function (value) {
									var valid = true;
									var fields = this.getFormFields();
									if (fields.OrderedUnitGrossPrice) {
										if (!value && fields.OrderedUnitGrossPrice.getValue()) {
											valid = edi.i18n.getMessage('error.value.notvalid');
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'OrderedUnitGrossPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 10,
								listeners: {
									change(field, newVal, oldVal) {
										if (+newVal === +oldVal) {
											return;
										}
										taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.GROSS_PRICE
											? this.setFieldsOrder()
											: null;
										var fields = this.getFormFields();
										if (fields.OrderedUnitNetPrice) {
											fields.OrderedUnitNetPrice.isValid();
										}
									}
								},
								validator: function (value) {
									var valid = true;
									var fields = this.getFormFields();
									if (fields.OrderedUnitNetPrice) {
										if (
											(!value && fields.OrderedUnitNetPrice.getValue()) ||
											Number(value) < Number(fields.OrderedUnitNetPrice.getValue())
										) {
											valid = edi.i18n.getMessage('error.value.notvalid');
										}
									}
									return valid;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate',
								type: 'combo',
								store: edi.stores.createInlineStore(availableTaxRates),
								valueInitialize: true,
								validator: isEdit
									? function (value) {
											return (
												edi.methods.taxRates.isValidRate(value, availableTaxRates, true) ||
												edi.i18n.getMessage('form.field.tax.invalid')
											);
									  }
									: null,
								listeners: {
									change: function () {
										this.setFieldsOrder();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								name: 'OrderedNetAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								value: 0
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								name: 'OrderedTaxAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								name: 'OrderedGrossAmount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('line.item.discount'),
								name: 'Discount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 16,
								maxText: ''
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
						customFieldTab: 'PACKAGE_AND_DELIVERY',
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [6, [4, 3], 4, [4, 4], 4]
						},
						items: [
							{
								title: edi.i18n.getMessage('line.delivery.buyer.location.code'),
								name: 'BuyerLocationCode',
								maxLength: 6
							},
							{
								title: edi.i18n.getMessage('line.item.ordered.unit.packsize'),
								name: 'OrderedUnitPacksize',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: 3,
								maxLength: 12
							},
							{
								title: edi.i18n.getMessage('line.item.expiration.date'),
								name: 'ExpirationDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.max.expiration.date'),
								name: 'MaxExpirationDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.tab.package.ExpectedDespatchDate'),
								name: 'ExpectedDespatchDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.tab.package.RequestedDeliveryDate'),
								name: 'RequestedDeliveryDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.tab.package.ExpectedDeliveryDate'),
								name: 'ExpectedDeliveryDate',
								type: 'date'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.item.description.long'),
						customFieldTab: 'ADDITIONAL_DATA',
						layout: {
							type: 'grid',
							gap: [24, 16],
							area: [[6, 6]]
						},
						items: [
							{
								title: edi.i18n.getMessage('column.reference.element.reference.id'),
								name: 'Line-Reference.Reference-Elements.Reference-Element.Reference-Id',
								requiredFields: ['Line-Reference.Reference-Elements.Reference-Element.Reference-Date'],
								maxLength: 150
							},
							{
								title: edi.i18n.getMessage('column.reference.element.reference.date'),
								name: 'Line-Reference.Reference-Elements.Reference-Element.Reference-Date',
								type: 'date'
							}
						]
					}
				]
			}
		});
		if (document) {
			productsGrid.setPartnerId(sellerId);
		}

		var createBtn = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom', 'delivery', 'ultimateCustomer'],
							[buyer, seller, shipFrom, delivery, ultimateCustomer],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					save(values);
				}
			},
			isEdit
		);

		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.LEGACY_ORDER
				)
			) &&
			direction !== edi.constants.DIRECTIONS.INCOMING
		) {
			var createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom', 'delivery', 'ultimateCustomer'],
							[buyer, seller, shipFrom, delivery, ultimateCustomer],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					const afterSave = function (documentId, callback, responseData) {
						const isWarning =
							edi.utils.getAttributeByName(responseData.data?.attributes, 'isWarning') === 'true';
						const isBlocked =
							edi.utils.getAttributeByName(responseData.data?.attributes, 'isBlocked') === 'true';
						if (!isWarning && !isBlocked) {
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: documentId
									},
									true
								),
								'PUT',
								Ext.encode({}),
								null,
								edi.document.actions.defaultFailureHandler(
									moduleData.tab,
									'document.error.in.sending.process'
								),
								callback
							);
						} else {
							if ('function' == typeof callback) {
								callback();
							}
						}
					};
					save(values, afterSave);
				},
				null,
				isEdit
			);
		}

		form = createFormForModule({
			cls: 'edi-form document-add-form',
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage(isEdit ? 'document.order' : 'document.add.order')
				}),
				createMainDataBlock(),
				createPartiesBlock(),
				createTransportInfoBlock(),
				createAdditionalInformationBlock(),
				createFieldBlock({
					title: edi.i18n.getMessage('documents.column.remark'),
					cls: 'edi-form-maxwidth',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					layout: {
						type: 'grid',
						area: [6]
					},
					items: [orderRemarks]
				}),
				createCustomFieldsContainer(),
				productsGrid
			],
			buttons: createButtonContainer({
				items: [createAndSendButton, createBtn]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};
	/**
	 * Checks company controls, and marks them, if not valid
	 */
	const validateCompanySelectors = function () {
		const isBuyerValid = buyer.isValid(),
			isSellerValid = seller.isValid(),
			isDeliveryValid = delivery.isValid(),
			isUltimateCustomerValid = ultimateCustomer.isValid();

		return isBuyerValid && isSellerValid && isDeliveryValid && isUltimateCustomerValid;
	};
	/**
	 * Set focus On error field
	 */
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		let valid = false;
		const total = productValues.products.length;
		const validCompanies = validateCompanySelectors();
		const hasInvalid = form.hasInvalidField();
		const validGrid = productsGrid.isValid();
		if (total && !hasInvalid && validCompanies && validGrid) {
			valid = true;
		}
		return valid;
	};
	/**
	 * Saves order
	 * @param	{Object}	formValues    values from form
	 * @param	{Function}	[afterSave]
	 */
	const save = function (formValues, afterSave) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = formValues || {};
		var numberAutoGenerated = formValues.numberAutoGenerated;
		delete formValues.numberAutoGenerated;

		let clonedProductValues = Ext.clone(productsGrid.getValues());
		var customFieldsData = edi.methods.custom_fields.getCustomFieldsData(
			customFieldsObj,
			values,
			clonedProductValues.products,
			topPath
		);
		Object.keys(customFieldsData).forEach(function (key) {
			delete values[key];
		});
		clonedProductValues.products.forEach(function (line) {
			Object.keys(line).forEach(function (key) {
				if (key.match(topPath)) {
					delete line[key];
				}
			});
		});
		for (var j = 0; j < clonedProductValues.products.length; j++) {
			var referenceElementArray = [];
			var lineReference = edi.utils.getObjectProperty(
				clonedProductValues.products[j],
				'Line-Reference.Reference-Elements.Reference-Element',
				true
			);
			lineReference.forEach(function (item) {
				edi.utils.setObjectProperty(item, 'Reference-Type', 'spc');
				referenceElementArray.push(item);
			});
			edi.utils.setObjectProperty(
				clonedProductValues.products[j],
				'Line-Reference.Reference-Elements.Reference-Element',
				referenceElementArray
			);
		}

		var document = createOrder({
			'Order-Header': values['Order-Header'],
			parties: {
				Buyer: values['Buyer'],
				Seller: values['Seller'],
				ShipFrom: values['ShipFrom'],
				DeliveryPoint: values['DeliveryPoint'],
				UltimateCustomer: values['UltimateCustomer']
			},
			productValues: clonedProductValues
		});
		document = edi.document.actions.mergeDataBeforeSave(initialData, document, [
			{
				path: 'Order-Lines.Line'
			}
		]);

		edi.utils.clearEmptyValues(document);
		var documentNameCode = edi.utils.getObjectProperty(values, 'Order-Header.DocumentNameCode');
		if (!documentNameCode) {
			edi.utils.setObjectProperty(document, 'Order-Header.DocumentNameCode', null);
		}
		var headerData = {
			data: Ext.encode(document),
			date: edi.utils.getObjectProperty(document, 'Order-Header.OrderDate')
		};
		if (customFieldsData && Object.keys(customFieldsData).length > 0) {
			headerData.customFields = customFieldsData;
		}

		if (isNumberAutoGenerated && numberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(document, 'Order-Header.OrderNumber');
		}
		var success = edi.document.actions.createSaveSuccessHandler(
			moduleData,
			isEdit ? id : undefined,
			afterSave,
			isEdit
		);
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER
		);
		edi.document.actions.processDocument(
			sellerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
			parentId,
			isEdit ? id : undefined,
			success,
			failure,
			headerData
		);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createAddModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var createModuleForm = function (docData) {
			modulePanel.add(createModForm(docData));

			moduleData.tab.add(modulePanel);
		};

		if (docHeader && docHeader.id) {
			id = docHeader.id;
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			sellerId = docHeader.toOrg.id;

			taxCalculationMethod = edi.utils.getTaxCalculationMethod(docHeader.fromOrg.attributes);
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (resp) {
					if (resp && resp.data) {
						initialData = resp.data;
						createModuleForm(resp.data);

						var products = productValues.products.length ? productValues.products : linesData;
						if (products.length) {
							edi.methods.taxRates.showInvalidProductsWarnIfNeeded(products, availableTaxRates, {
								allowBlankRate: true
							});
						}
						if (docHeader?.toOrg?.id && docHeader?.fromOrg?.id) {
							edi.methods.custom_fields.initCustomFields({
								customFieldsObj,
								docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
								toOrgId: docHeader.toOrg.id,
								fromOrgId: docHeader.fromOrg.id,
								docId: id,
								container: customFieldsContainer,
								grid: productsGrid,
								topPath: topPath,
								finishCallback(obj) {
									customFieldsObj = obj;
									form.isValid();
									checkValid();
								},
								fail() {
									form.isValid();
									checkValid();
								}
							});
						}
						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						failure(resp);
					}
				},
				failure
			);
		} else {
			var createModulePanel = function () {
				createModuleForm();
				seller.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			};

			if (!taxCalculationMethod) {
				taxCalculationMethod = edi.utils.getTaxCalculationMethod(userData.org.attributes);
			}
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' === docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('incomingBlockRemoved', onBlockRemoved);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
