import { createModulePanel } from '@Components/panels';
import { createFieldContainer } from '@Components/fields';
import { createGrid, createGridActionBar, createGridCheckboxSelectionModel } from '@Components/grid';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { checkBeforeSignRules } from '@Edi/modules/documentGrids/methods';
Ext.namespace('edi.modules');
/**
 * Class for grid documents statuses
 * @author Vsevolod Liapin
 */
edi.modules['document.statuses'] = function () {
	var moduleData,
		grid,
		filterObject,
		fireSearch,
		allActiveCerts = {},
		signProperties = {},
		useActiveCerts = false,
		filterForm,
		selectedCertificate,
		selectedPoa,
		notHaveActiveCertType = [];

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', gridDataChangedHandler);
		edi.events.documents.on('delete', gridDataChangedHandler);
	};
	/**
	 * Creates filter form
	 * @returns {Object}
	 */
	var createFilterForm = function () {
		fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(
					edi.rest.services.DOCUMENTS.GET,
					filterForm,
					grid,
					edi.filters.config.documents.createArgs,
					{
						gridName: 'statuses'
					}
				);
			}
			filterObject.filter();
		};
		let { formItemsMap, items } = edi.filters.config.documents.createFormItems();
		filterForm = createModuleFilterForm(
			{
				border: 1,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod('week');
					formItemsMap.modifyDateRange.activatePeriod(defaultModifyDateRange);
				}
			},
			fireSearch
		);

		return filterForm;
	};
	var createFieldContainerFn = function (fieldLabel, input, hidden) {
		return createFieldContainer({
			fieldLabel: edi.i18n.getMessage(fieldLabel),
			layout: 'hbox',
			hidden: hidden,
			margin: '0 12 0 17',
			defaults: {
				layout: '100%'
			},
			fieldDefaults: {
				msgTarget: 'under',
				labelAlign: 'top'
			},
			columnWidth: 0.25,
			items: [input],
			cls: 'edi-form-field'
		});
	};
	/**
	 * Certificate handlers object
	 * @type {{get: Function, set: Function}}
	 */
	var sertificateHandler = {
		get: function () {
			return selectedCertificate;
		},
		set: function (cert) {
			selectedCertificate = cert;
		}
	};
	/**
	 * POA handlers object
	 * @type {{get: Function, set: Function}}
	 */
	var poaHandler = {
		get: function () {
			return selectedPoa;
		},
		set: function (poa) {
			selectedPoa = poa;
		}
	};
	/**
	 * Find active certificate for document type
	 * @param {String} docType
	 * @returns {null|Object}
	 */
	var findActiveCertForDocType = function (docType) {
		if (allActiveCerts.hasOwnProperty(docType)) {
			return allActiveCerts[docType];
		}
		if (allActiveCerts.hasOwnProperty('ALL')) {
			return allActiveCerts['ALL'];
		}
		return null;
	};
	/**
	 * Check that any active certificate contains at least one document type from records
	 * @param {Array} records
	 * @returns {boolean}
	 */
	var checkExistsActiveCertsForDocs = function (records) {
		for (var i = 0; i < records.length; i++) {
			var recordData = records[i].getData();
			if (findActiveCertForDocType(recordData.type)) {
				return true;
			}
		}
		return false;
	};
	/**
	 * Find all active certificates for current user
	 * @param {function} callback
	 */
	var getAllActiveCerts = function (callback) {
		allActiveCerts = {};
		edi.rest.sendRequest(
			edi.rest.services.USER.SELF.CERTIFICATE.LIST.GET,
			'GET',
			{ gridName: 'without_deactivated' },
			function (data) {
				var i, certs;
				if (data && data.items && data.items.length) {
					certs = data.items;
					for (i = 0; i < certs.length; i++) {
						if (certs[i].header.state == 'ACTIVE') {
							certs[i].data.availableDocTypesForSignature?.forEach(function (docType) {
								allActiveCerts[docType] = certs[i];
							});
						}
					}
				}
				callback();
			},
			edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data')
		);
	};
	/**
	 * Creates callback for sign
	 * @param    {Object}    record      grid row record
	 * @param    {Object}    grid        grid where from we receive records
	 * @param    {Function}  callback    callback on success
	 * @param    {Function}  failure     callback on failure
	 */
	var createdPart2DocIds = [];
	var signActionHandler = function (record, grid, callback, failure) {
		return function () {
			var recordData = record.getData();
			var realType = recordData.type;
			var direction = edi.utils.getDocumentDirection(recordData.toOrg, recordData.fromOrg);
			var beforeSign = function (success, failure) {
				var data = {};
				if (edi.constants.DOCUMENT_TYPES.EDI_FNS_INVOICE == recordData.type) {
					data[edi.constants.BUSINESS_PROCESS_PROPERTIES.SIMPLE_FNS] = false;
				}
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.SEND.PUT,
						{
							documentId: recordData.id
						},
						true
					),
					'PUT',
					Ext.encode(data),
					success,
					failure
				);
			};

			var signCallback = function (failed, errorData) {
				var gridSelModel = grid.getSelectionModel();
				var continueMethod = function () {
					edi.events.documents.fireEvent('sign', recordData);
					gridSelModel.deselect(record);
					callback && 'function' == typeof callback ? callback() : null;
				};
				if (failed) {
					edi.core.logMessage(
						'Error occurred during signing of document ' +
							edi.i18n.getMessage('documents.doctype.' + recordData.type) +
							' ' +
							recordData.number,
						'warn'
					);
					failure(record, errorData);
					gridSelModel.deselect(record);
					callback && 'function' == typeof callback ? callback() : null;
				} else {
					if (
						'object' == typeof edi.constants.PUSH_AFTER_SIGN_RULES &&
						edi.constants.PUSH_AFTER_SIGN_RULES.DOCUMENT_TYPES.some((it) => it === recordData.type) &&
						edi.constants.PUSH_AFTER_SIGN_RULES.STATES.some((it) => it === recordData.state)
					) {
						beforeSign(continueMethod, failure);
					} else {
						continueMethod();
					}
				}
			};
			var startSign = function (contentId, beforeSign) {
				Object.assign(signProperties, {
					signObjectProcessor: function (signature) {
						var signObj = {};
						signObj[edi.constants.BUSINESS_PROCESS_PROPERTIES.SIGNATURE] = signature;
						if (edi.methods.documents.needToAddTaskId(recordData)) {
							signObj[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID] = recordData.state;
						}
						return signObj;
					},
					signRefuse: function () {
						moduleData.tab.setLoading(false);
						grid.getStore().reload();
						grid.getSelectionModel().deselectAll();
					}
				});

				var nextActionStartSign = function () {
					//case when we are signing a several docs and using an active certificates
					if (useActiveCerts) {
						var currentCert = findActiveCertForDocType(recordData.type);
						if (currentCert) {
							edi.sign.getCertificates({
								callback: function (data) {
									var cert = edi.utils.findObjectInArray(
										data.data,
										edi.constants.CERTIFICATE.ACTIVE_SEARCH_PARAMS.STORE,
										currentCert.data[edi.constants.CERTIFICATE.ACTIVE_SEARCH_PARAMS.DB]
									);
									if (cert) {
										sertificateHandler.set(cert, currentCert.data);
										poaHandler.set(currentCert?.poaData?.header);
										edi.utils.sign(
											recordData,
											moduleData.tab,
											signCallback,
											contentId,
											beforeSign,
											false,
											sertificateHandler,
											signProperties,
											poaHandler
										);
									} else {
										signCallback();
									}
								}
							});
						} else {
							notHaveActiveCertType.push(recordData.type);
							signCallback();
						}
					} else {
						edi.utils.sign(
							recordData,
							moduleData.tab,
							signCallback,
							contentId,
							beforeSign,
							false,
							sertificateHandler,
							signProperties,
							poaHandler
						);
					}
				};

				if (
					'object' == typeof edi.constants.BEFORE_SIGN_PARENT_DOC &&
					edi.constants.BEFORE_SIGN_PARENT_DOC.some((it) => it === recordData.type)
				) {
					var signIzv = function (id) {
						Object.assign(signProperties, {
							signUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, { documentId: id })
						});
						nextActionStartSign();
					};
					var getParentDocId = function (parentData) {
						var parentDocId = parentData.data.parent.id;
						signIzv(parentDocId);
					};
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
							documentId: recordData.id
						}),
						'GET',
						undefined,
						getParentDocId
					);
				} else if (
					'object' == typeof edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES &&
					edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.DOCUMENT_TYPES.some((it) => it === recordData.type) &&
					edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.STATES.some((it) => it === recordData.state)
				) {
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: recordData.id
							},
							true
						),
						'PUT',
						Ext.encode({}),
						nextActionStartSign
					);
				} else {
					nextActionStartSign();
				}
			};

			if (edi.constants.PARTS_DOC_TYPES.hasOwnProperty(recordData.type)) {
				var objKey =
					edi.constants.STATE.SENDER_REVIEW == recordData.state ||
					edi.constants.STATE.DRAFT == recordData.state
						? 'P1'
						: [
								edi.constants.STATE.RECEIVER_REVIEW,
								edi.constants.STATE.DEBTOR_REVIEW,
								edi.constants.STATE.READ
						  ].some((it) => it === recordData.state) ||
						  createdPart2DocIds.some((it) => it === recordData.id)
						? 'P2'
						: null;

				if (objKey) {
					realType = edi.constants.PARTS_DOC_TYPES[recordData.type][objKey];
				}
			}

			var loading = edi.utils.formatString(edi.i18n.getMessage('document.sign.loading.message'), {
				type: edi.i18n.getMessage('documents.doctype.' + recordData.type),
				number: recordData.number
			});
			moduleData.tab.setLoading(loading);

			if (
				Array.isArray(edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES) &&
				edi.constants.USE_BEFORE_SIGN_DOCUMENT_TYPES.some((it) => it === recordData.type)
			) {
				if (edi.constants.PARTS_DOC_TYPES.hasOwnProperty(recordData.type)) {
					var startSignPart = function (docPartType) {
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
								documentId: recordData.id
							}),
							'GET',
							undefined,
							function (data) {
								if (data && data.data && data.data.children && data.data.children.length) {
									var headData;
									for (var i = 0; i < data.data.children.length; i++) {
										if (data.data.children[i].type == docPartType) {
											headData = data.data.children[i];
											break;
										}
									}
									if (headData) {
										startSign(
											headData.id,
											recordData.state == edi.constants.STATE.DRAFT ||
												recordData.state == edi.constants.STATE.READ ||
												createdPart2DocIds.some((it) => it === recordData.id)
												? beforeSign
												: null
										);
									} else {
										signCallback(true);
									}
								} else {
									signCallback(true);
								}
							},
							function () {
								signCallback(true);
							}
						);
					};
					if (
						edi.constants.DIRECTIONS.OUTGOING == direction ||
						recordData.hasPart2 ||
						createdPart2DocIds.some((it) => it === recordData.id)
					) {
						startSignPart(realType);
					} else {
						signCallback(true);
					}
				} else if (
					recordData.type == edi.constants.DOCUMENT_TYPES.DP_CONTAINER ||
					recordData.type == edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE
				) {
					edi.methods.dpContainerSign(recordData, moduleData.tab);
				} else {
					var useBeforeSign =
						recordData.state === edi.constants.STATE.DRAFT ||
						recordData.state === edi.constants.STATE.READ ||
						checkBeforeSignRules(recordData.type, recordData.state);
					if (
						edi.utils.getAttributeByName(recordData.attributes, 'bpName') ===
						edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION
					) {
						useBeforeSign = false;
					}
					startSign(undefined, useBeforeSign ? beforeSign : null);
				}
			} else {
				startSign();
			}
		};
	};
	/**
	 * Initializes grid
	 * @returns {Object} Instance of grid
	 */
	var createGridFn = function () {
		var columns = edi.columns.get('documents_all_statuses');
		var actionItems = [
			{
				id: edi.constants.DOCUMENT_ACTIONS.SIGN,
				name: edi.i18n.getMessage('document.sign.document')
			}
		];
		// if (edi.permissions.hasPermission("CREATE_EDI_GROUP_EXPORT_REQUEST")) {
		// 	actionItems.push({
		// 		id: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP,
		// 		name: edi.i18n.getMessage("export.group.request.menu.tooltip"),
		// 		menu: [
		// 			{
		// 				name: edi.i18n.getMessage("export.group.request.menu.btn.sign"),
		// 				handler: function() {
		// 					getMenuExportGroupHandler(edi.constants.EXPORT_GROUP_REQUEST.TYPE.SIMPLE);
		// 				}
		// 			},
		// 			{
		// 				name: edi.i18n.getMessage("export.group.request.menu.btn.all"),
		// 				handler: function() {
		// 					getMenuExportGroupHandler(edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON);
		// 				}
		// 			}
		// 		]
		// 	});
		// }

		var topBar = undefined;
		if (actionItems.length) {
			var percent,
				progress,
				packId,
				userStopMe = false;
			var onMenuItemClick = function (id) {
				var options = {
					grid: grid,
					moduleData: moduleData,
					action: id
				};
				if (edi.constants.DOCUMENT_ACTIONS.SIGN == id) {
					Object.assign(options, {
						handler: signActionHandler,
						beforeStart: function (data, callback, options) {
							//before start docFilter
							var processDocs = [];
							var noProcessDocs = [];
							data?.forEach(function (doc) {
								var docData = doc.getData();
								var direction = edi.utils.getDocumentDirection(docData.toOrg, docData.fromOrg);
								if (
									edi.constants.DIRECTIONS.INCOMING == direction &&
									(docData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
										docData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD)
								) {
									var versionFormat = docData.versionFormat || docData.versionId;
									if (versionFormat === '5.01-N') {
										processDocs.push(doc);
									} else {
										doc.tooltip = 'documents.error.upd.old.version';
										noProcessDocs.push(doc);
									}
								} else {
									processDocs.push(doc);
								}
							});
							data = processDocs;
							options.noProcessDocs = noProcessDocs;
							options.data = processDocs;
							//

							Object.assign(signProperties, {
								showActiveCertConfirm: !edi.constants.CERTIFICATE.ONLY_ACTIVE,
								doNotUseDefaultCert: false
							});

							var continueStart = function () {
								edi.document.actions.defaultGridBeforeSignStart(data, callback);
							};

							//if signing several documents and allow active certs - looking for at least one doc with active cert
							if (data.length > 1 && edi.constants.CERTIFICATE.ALLOW_ACTIVE) {
								getAllActiveCerts(function () {
									if (checkExistsActiveCertsForDocs(data)) {
										if (!edi.constants.CERTIFICATE.ONLY_ACTIVE) {
											edi.core.confirm(
												null,
												'use.active.certificate.to.sign.confirmation',
												function () {
													Object.assign(signProperties, { showActiveCertConfirm: false });
													useActiveCerts = true;
													continueStart();
												},
												function () {
													Object.assign(signProperties, {
														showActiveCertConfirm: false,
														doNotUseDefaultCert: true
													});
													useActiveCerts = false;
													continueStart();
												},
												undefined,
												function () {
													useActiveCerts = false;
													return false;
												}
											);
										} else {
											useActiveCerts = true;
											continueStart();
										}
									} else {
										continueStart();
									}
								});
							} else {
								continueStart();
							}
						},
						loadingMessage: 'document.sign.started',
						forceCompleteEvent: true,
						completeCallback: function () {
							if (notHaveActiveCertType.length > 0) {
								var msgTypes = '';
								for (var i = 0; i < notHaveActiveCertType.length; i++) {
									if (i + 1 == notHaveActiveCertType.length) {
										msgTypes +=
											edi.i18n.getMessage('documents.doctype.' + notHaveActiveCertType[i]) + ' ';
									} else {
										msgTypes +=
											edi.i18n.getMessage('documents.doctype.' + notHaveActiveCertType[i]) + ', ';
									}
								}

								Ext.Msg.show({
									closable: false,
									title: edi.i18n.getMessage('info'),
									msg: edi.i18n.getMessage('sign.document.types.not.available', {
										types: msgTypes
									}),
									width: MODAL_SIZE.widthMedium,
									buttons: Ext.MessageBox.OK
								});
								notHaveActiveCertType = [];
							}
							useActiveCerts = false;
							selectedCertificate = null;
							selectedPoa = null;
						}
					});
				}
				edi.document.actions.documentGridActionProcess(options);
			};
			var success = function (data) {
				if (!userStopMe) {
					if (data && data.data && data.data.hasOwnProperty('percentageOfReadiness')) {
						percent = data.data.percentageOfReadiness;
					}
					progress.updateProgress(percent / 100, percent + ' %');
					if (percent === 100 && progress) {
						progress.hide();
						progress = null;
						if (
							data.data.hasOwnProperty('state') &&
							edi.constants.EXPORT_GROUP_REQUEST.STATE.READY === data.data.state
						) {
							edi.rest.downloadFile(
								edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.EXPORT.GET, {
									id: data.data.id
								})
							);
						}
					} else {
						setTimeout(startSearch, 1000);
					}
				} else {
					if (progress) {
						progress.hide();
						progress = null;
					}
					var closeMsg = Ext.Msg.show({
						closable: false,
						title: edi.i18n.getMessage('export.group.request.progress.title'),
						msg: edi.i18n.getMessage('export.group.request.progress.close'),
						width: MODAL_SIZE.widthMedium,
						buttons: Ext.MessageBox.YESNO,
						buttonText: {
							no: edi.i18n.getMessage('form.btn.stop'),
							yes: edi.i18n.getMessage('form.btn.download.later')
						},
						fn: function (val) {
							if ('yes' === val) {
								closeMsg.close();
							} else if ('no' === val) {
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.EXPORT.GROUP_REQUEST.PUT,
										{
											id: packId
										},
										true
									),
									'PUT',
									Ext.encode({ REJECT: true }),
									undefined,
									undefined
								);
							}
						}
					});
				}
			};
			var failure = function (data) {
				if (progress && userStopMe) {
					progress.hide();
					progress = null;
				}
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.accept.registry.find'));
				moduleData.tab.setLoading(false);
			};
			var startSearch = function () {
				if (!progress) {
					percent = 0;
					progress = Ext.Msg.show({
						closable: false,
						title: edi.i18n.getMessage('export.group.request.progress.title'),
						msg: edi.i18n.getMessage('export.group.request.progress.msg'),
						progress: true,
						width: MODAL_SIZE.widthMedium,
						progressText: percent + ' %',
						buttons: Ext.MessageBox.NO,
						buttonText: { no: edi.i18n.getMessage('form.btn.close') },
						fn: function (val) {
							if ('no' === val) {
								userStopMe = true;
							}
						}
					});
				} else {
					progress.updateProgress(percent / 100, percent + ' %');
				}
				var url = edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.GET, { id: packId });
				edi.rest.sendRequest(url, 'GET', null, success, failure);
			};
			var getMenuExportGroupHandler = function (type) {
				edi.document.actions.documentGridActionProcess({
					grid: grid,
					moduleData: moduleData,
					groupProcessedUrl: edi.rest.services.EXPORT.GROUP_REQUEST.POST,
					action: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP,
					genParamsMethod: function (data) {
						var props = {},
							ids = [];
						props.exportType = type;
						for (var i = 0, len = data.length; i < len; i++) {
							var row = data[i].getData();
							ids.push(row.id);
						}
						props.docIds = ids;
						return props;
					},
					completeEvent: false,
					completeCallback: function (docs, resp) {
						if (resp && resp.data && resp.data.id) {
							userStopMe = false;
							packId = resp.data.id;
							startSearch();
						} else {
							edi.core.showInfo('accept.registry.nothing.to.search');
							moduleData.tab.setLoading(false);
						}
					},
					processMethod: 'POST'
				});
			};
			topBar = createGridActionBar({
				actionCfgs: actionItems,
				defaultHandler: onMenuItemClick
			});
		}
		/**
		 * Checkbox selection model processor linked with grid top bar action items
		 * @param model
		 * @param selected
		 * @param actionItems
		 * @param isProcessible
		 */
		var selectionProcessor = function (model, selected, actionItems, isProcessible) {
			var i,
				j,
				groupActionBtn,
				actionConf,
				totalSum = 0;
			for (i = 0; i < actionItems.length; i++) {
				groupActionBtn = actionItems[i];
				actionConf = groupActionBtn.rulesData;
				if (isProcessible(actionConf)) {
					var allowedDocs,
						allowFromGridConf = edi.document.actions.availableFromGrid[actionConf.id] || {},
						isAvailableFromGrid = true;

					allowedDocs = selected.filter(function (docRecord) {
						isAvailableFromGrid = true;
						var document = docRecord.getData();

						if (!Ext.isEmpty(allowFromGridConf) && Array.isArray(allowFromGridConf)) {
							Ext.each(allowFromGridConf, function (config) {
								if ('function' == typeof config.allow) {
									//Check action for allow performing from grid if doctypes  aren't specified in alloFromGrid conf
									//Or if  doctype exists in specified doctypes
									if (
										!config.documents ||
										(Array.isArray(config.documents) &&
											config.documents.indexOf(document.type) > -1)
									) {
										isAvailableFromGrid = config.allow(document);
									}
								}
							});
						}

						return (
							isAvailableFromGrid &&
							edi.action.isAvailable(actionConf.id, edi.action.getDocumentData(docRecord))
						);
					});

					if (edi.constants.DOCUMENT_ACTIONS.COPY == actionConf.id && allowedDocs.length > 1) {
						groupActionBtn.setDisabled(true);
					} else if (!actionConf.notDisabled) {
						groupActionBtn.setDisabled(!allowedDocs.length);
					}
				}
			}
			for (j = 0; j < selected.length; j++) {
				totalSum += Math.round(selected[j].get('totalSumm') * 100) / 100;
			}
		};
		var grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: edi.rest.services.DOCUMENTS.GET
			},
			storeConfig: {
				pageSize: edi.constants.STATUSES_PER_PAGE,
				model: edi.models.getModel('DOCUMENT'),
				groupField: null,
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				listeners: {
					load: function (store, records) {
						if (records && records.length) {
							var view = grid.getView();
							if (view && view.body && view.body.dom) {
								grid.getSelectionModel().selectAll();
							} else {
								view.on(
									'afterrender',
									function () {
										grid.getSelectionModel().selectAll();
									},
									undefined,
									{
										single: true
									}
								);
							}
						}
					}
				},
				autoLoad: false
			},
			gridConfig: {
				columns: columns,
				padding: '0 0 0 0',
				region: 'center',
				layout: 'fit',
				border: 1,
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: selectionProcessor
						  })
						: undefined,
				tbar: topBar,
				detailsArrowBtnConfig: !edi.constants.DEFAULT.COMPONENTS_SETTINGS.GRID.USE_OLD_DETAILS_BUTTON
					? {
							handler: function (grid, rowIndex) {
								rowClick(null, grid.getStore().getAt(rowIndex));
							},
							isActionDisabled: function (view, rowIndex, colIndex, item, record) {
								return !(
									edi.document.actions.checkDocumentActionPermission(
										record.get('type'),
										edi.constants.DOCUMENT_ACTIONS.DETAILS
									) &&
									edi.constants.STATE.DELETED != record.get('state') &&
									!record.get('archived') &&
									canPerformAction(record, edi.constants.DOCUMENT_ACTIONS.DETAILS)
								);
							}
					  }
					: null
			},
			viewConfig: {
				emptyTextTplOptions: {
					enabled: true,
					iconName: 'document-statuses',
					title: edi.i18n.getMessage('ediweb.document.statuses.empty.text.tpl.title'),
					contentText: edi.i18n.getMessage('ediweb.document.statuses.empty.text.tpl.contentText'),
					beforeButtonText: edi.i18n.getMessage('ediweb.document.statuses.empty.text.tpl.beforeButtonText'),
					buttonText: edi.i18n.getMessage('ediweb.document.statuses.empty.text.tpl.buttonText'),
					buttonName: 'emptyTextButton',
					buttonClickHandler: function (e, btn, name) {
						filterForm.toggleHandler();
					}
				}
			},
			pagingBarConfig: {
				// addButtonsConfig: createButton({
				// 	tooltip: edi.i18n.getMessage("document.group.print.documents"),
				// 	cls: "edi-label-button test-grid-paginator-group-print",
				// 	margin: "0 0 0 35",
				// 	width: 24,
				// 	height: 18,
				// 	glyph: edi.document.actions.getActionIcon(edi.constants.DOCUMENT_ACTIONS.GROUP_PRINT),
				// 	handler: function() {
				// 		edi.methods.documentsGroupPrint(grid, moduleData);
				// 	}
				// }),
				allowExport: null,
				itemsPerPage: edi.constants.STATUSES_PER_PAGE
			}
		});
		return grid;
	};
	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		grid = createGridFn();
		filterForm = createFilterForm();
		modulePanel.add(filterForm);

		modulePanel.add(grid);
		moduleData.tab.add(modulePanel);

		if (typeof initCallBack === 'function') {
			initCallBack();
		}
		filterForm.fireSearch();
	};
	/**
	 * Reloads grid and resets filter form
	 */
	var gridDataChangedHandler = function () {
		grid.getStore().reload();
	};
	/**
	 * Routine that must be done before module destroy
	 */
	var onDestroy = function () {
		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.events.documents.un('change', gridDataChangedHandler);
		edi.events.documents.un('delete', gridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
	};
};
