const CERTIFICATES_COLUMN = 'ew_org_profile_certificates';

edi.columns.addColumns({
	[CERTIFICATES_COLUMN]: {
		fullName: {
			text: 'certificate.title',
			dataIndex: 'fullName',
			sortable: false,
			flex: 2
		},
		validFromDate: {
			text: 'ediweb.ads.dateFrom',
			dataIndex: 'validFromDate',
			flex: 1,
			renderer: 'docTime'
		},
		validToDate: {
			text: 'ediweb.ads.dateTo',
			flex: 1,
			dataIndex: 'validToDate',
			renderer: 'docTime'
		},
		state: {
			text: 'document.state',
			dataIndex: 'state',
			renderer(value, meta) {
				let text = edi.i18n.getMessage('certificate.status.' + value);
				return edi.renderers.baseStateRenderer(value, text, meta);
			},
			flex: 1.5,
			htmlEncode: true
		}
	}
});

export { CERTIFICATES_COLUMN };
