import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createField,
	createLabel,
	createTextField,
	createTimeField
} from '@Components/fields';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { DELJIT_LINE_COLUMN, DELJIT_LINE_SHIPMENT_CONDITIONS_COLUMN, DELJIT_SUMMARY_DETAIL_COLUMN } from './columns';
import {
	DELJIT_LINE_MODEL,
	DELJIT_ROW_SHIPMENT_CONDITION_MODEL,
	DELJIT_SHIPMENT_CONDITION_MODEL,
	DELJIT_SUMMARY_MODEL
} from './models';
import { createDeljit, dateTimeRenderer } from './methods';
import { createButtonContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

/**
 * @author Vsevolod Liapin
 * Class for creation DELJIT document
 */
Ext.namespace('edi.modules');
edi.modules['document.create.deljit'] = function () {
	var moduleData,
		id,
		isEdit = false,
		sellerId,
		isChangedGrid = false;

	var userData = edi.core.getUserData(),
		buyer,
		seller,
		delivery,
		ultimateCustomer,
		initialData,
		afterSave,
		form,
		parentId,
		productsGrid,
		isNumberAutoGenerated = false,
		mandatoryGridWarn,
		availableTaxRates = edi.methods.taxRates.getRatesByCountry(userData.org.country),
		linesData = [],
		productValues = {
			products: []
		};
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Creates order create/edit form
	 * @param   {Object}    document    order data for editing, undefined for new order
	 * @returns {Object}
	 */
	var createModForm = function (document) {
		var i;
		const ADDRESS_MAP = {
			addr_locality: '',
			addr_addInf: '',
			addr_block: '',
			addr_area: '',
			addr_region_name: '',
			addr_region_code: '',
			addr_home: '',
			addr_flat: ''
		};

		var numberDocument = createTextField({
			emptyText: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('nr'),
			columnWidth: 0.7333,
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			value: document ? edi.utils.getObjectProperty(document, 'Order-Header.OrderNumber') : undefined,
			maxLength: 35,
			name: 'Order-Header.OrderNumber'
		});

		var headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('documents.create.deljit.nr'),
					input: numberDocument,
					mandatory: false,
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createDateField({
					emptyText: edi.i18n.getMessage('date'),
					margin: '0 0 0 5',
					columnWidth: 0.25,
					allowBlank: false,
					value: document ? edi.utils.getObjectProperty(document, 'Order-Header.OrderDate') : new Date(),
					name: 'Order-Header.OrderDate'
				})
			]
		});

		var autogenCheckbox = isNumberAutoGenerated
			? createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					items: [
						createField({
							title: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
							input: createCheckbox({
								columnWidth: 0.8,
								inputValue: true,
								checked: isNumberAutoGenerated,
								name: 'numberAutoGenerated',
								listeners: {
									change: function (checkbox, isChecked) {
										numberDocument.setEmptyText(
											isChecked
												? edi.i18n.getMessage('document.generation.numbers.text')
												: edi.i18n.getMessage('nr')
										);
										numberDocument.setValue('');
										numberDocument.setDisabled(isChecked);
										numberDocument.allowBlank = isChecked;
										numberDocument.validate();
									}
								}
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
			  })
			: null;

		var orderCurrency = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('order.currency'),
					input: createCombo({
						columnWidth: 0.6,
						name: 'Order-Header.OrderCurrency',
						value: document
							? edi.utils.getObjectProperty(document, 'Order-Header.OrderCurrency')
							: edi.constants.DEFAULT.CURRENCY,
						store: edi.stores.initCurrencyStore(),
						anyMatch: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				})
			]
		});

		var orderRemarks = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('remarks'),
					input: createTextField({
						columnWidth: 0.8,
						name: 'Order-Header.Remarks',
						value: document ? edi.utils.getObjectProperty(document, 'Order-Header.Remarks') : undefined,
						maxLength: 500,
						isTextarea: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var agreementNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('order.agreement.number'),
					input: createTextField({
						columnWidth: 0.5,
						maxLength: 175,
						value: document
							? edi.utils.getObjectProperty(document, 'Order-Header.Reference.ContractNumber')
							: undefined,
						name: 'Order-Header.Reference.ContractNumber'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var agreementDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('order.agreement.date'),
					input: createDateField({
						columnWidth: 0.5,
						name: 'Order-Header.Reference.ContractDate',
						value: document
							? edi.utils.getObjectProperty(document, 'Order-Header.Reference.ContractDate')
							: undefined,
						emptyText: edi.i18n.getMessage('date')
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		const documentFunctionCodeValue = edi.utils.getObjectProperty(document, 'Order-Header.DocumentFunctionCode');
		var functionalDocumentCode = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('functional.document.code'),
					input: createCombo({
						columnWidth: 0.5,
						name: 'Order-Header.DocumentFunctionCode',
						store: edi.stores.createSimpleInlineStore(['9', '4', '3', 'R'], function (id) {
							return edi.i18n.getMessage('functional.document.code.' + id);
						}),
						value: document && documentFunctionCodeValue !== 'O' ? documentFunctionCodeValue : '9',
						anyMatch: true,
						allowBlank: false
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var deliveryDatePanel, deliveryPeriodPanel;

		deliveryDatePanel = createPanel({
			layout: 'column',
			columnWidth: 0.8,
			items: [
				createDateField({
					margin: '0 0 0 5',
					columnWidth: 0.7,
					name: 'Order-Header.ExpectedDeliveryDate',
					value: document
						? edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryDate')
						: undefined,
					emptyText: edi.i18n.getMessage('date')
				}),
				createTimeField({
					margin: '0 0 0 5',
					columnWidth: 0.3,
					name: 'Order-Header.ExpectedDeliveryTime',
					value: document
						? edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryTime')
						: undefined,
					emptyText: edi.i18n.getMessage('time')
				})
			]
		});

		var deliveryTo, deliveryFrom;
		deliveryPeriodPanel = createPanel({
			hidden: true,
			layout: 'column',
			columnWidth: 0.8,
			items: [
				(deliveryFrom = createDateField({
					margin: '0 0 0 5',
					columnWidth: 0.4,
					allowBlank: false,
					name: 'Order-Header.ExpectedDeliveryDate',
					value: document
						? edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryDate')
						: undefined,
					emptyText: edi.i18n.getMessage('expected.delivery.period.start'),
					listeners: {
						change: function (comp, value) {
							if (deliveryTo) {
								deliveryTo.setMinValue(value);
								deliveryTo.isValid();
							}
						}
					}
				})),
				createTimeField({
					margin: '0 0 0 5',
					columnWidth: 0.1,
					name: 'Order-Header.ExpectedDeliveryTime',
					value: document
						? edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryTime')
						: undefined,
					emptyText: edi.i18n.getMessage('time')
				}),
				(deliveryTo = createDateField({
					margin: '0 0 0 10',
					columnWidth: 0.4,
					allowBlank: false,
					name: 'Order-Header.LastExpectedDeliveryDate',
					value: document
						? edi.utils.getObjectProperty(document, 'Order-Header.LastExpectedDeliveryDate')
						: undefined,
					emptyText: edi.i18n.getMessage('expected.delivery.period.end'),
					listeners: {
						change: function (comp, value) {
							if (deliveryFrom) {
								deliveryFrom.setMaxValue(value);
								deliveryFrom.isValid();
							}
						}
					}
				})),
				createTimeField({
					margin: '0 0 0 5',
					columnWidth: 0.1,
					name: 'Order-Header.LastExpectedDeliveryTime',
					value: document
						? edi.utils.getObjectProperty(document, 'Order-Header.LastExpectedDeliveryTime')
						: undefined,
					emptyText: edi.i18n.getMessage('time')
				})
			]
		});

		var deliveryCombo = createCombo({
			allowBlank: false,
			value: 'DATE',
			columnWidth: 0.2,
			store: edi.stores.createSimpleInlineStore(['DATE', 'PERIOD'], function (id) {
				return edi.i18n.getMessage('expected.delivery.period.' + id);
			}),
			listeners: {
				select: function (combo, records) {
					if (records && (records.data || records.length)) {
						var record = records.length ? records[0] : records,
							fields = [],
							i,
							isPeriod = record.get('id') == 'PERIOD';

						if (deliveryDatePanel) {
							fields = deliveryDatePanel.query('field');
							for (i = 0; i < fields.length; i++) {
								fields[i].setDisabled(isPeriod);
							}

							deliveryDatePanel.setVisible(!isPeriod);
						}

						if (deliveryPeriodPanel) {
							fields = deliveryPeriodPanel.query('field');
							for (i = 0; i < fields.length; i++) {
								fields[i].setDisabled(!isPeriod);
							}

							deliveryPeriodPanel.setVisible(isPeriod);
						}
					}
				}
			}
		});

		var deliveryPanel = createPanel({
			layout: 'column',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [deliveryCombo, deliveryDatePanel, deliveryPeriodPanel]
		});

		var modeOfTransport = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('mode.of.transport'),
					input: createCombo({
						columnWidth: 0.5,
						name: 'Order-Header.Order-Transport.ModeOfTransport',
						value: document
							? edi.utils.getObjectProperty(document, 'Order-Header.Order-Transport.ModeOfTransport')
							: undefined,
						store: edi.stores.initModeOfTransportStore()
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var parties = document ? edi.utils.getObjectProperty(document, 'Order-Parties') : undefined,
			fieldValues = edi.converters.convertOrgToLegacyPartie(userData.org);

		var sellers = edi.relations.getRelations();
		if (document) {
			var sellerRelation = null;
			for (i = 0; i < sellers.length; i++) {
				if (sellers == sellers[i].iln) {
					sellerRelation = sellers[i];
					break;
				}
			}
		}

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				valuesByMap: !!document,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				processValues: orgConverter,
				forceChangeFields: {
					bank_acc: true,
					company_iln: true
				}
			});
			return createCompanySelector(selectorConfig);
		};

		var modalAdditionalConf = {
			address: {
				country: {
					fieldConfig: {
						mandatory: false,
						listeners: null
					}
				},
				region: null
			},
			bank: {
				bank_acc: edi.selectors.defaults.bank_acc_select
			}
		};

		buyer = createCS({
			itemId: 'buyer',
			title: 'documents.order.buyer',
			is_valid: (parties && parties.Buyer && parties.Buyer.ILN) || userData.org.iln,
			fieldValues: document ? parties : fieldValues,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id || userData.org?.id,
				converter: orgConverter
			}),
			selectedOrgValues: document ? undefined : fieldValues,
			callback: checkValid,
			fieldsMap: edi.selectors.getOrderFieldsMap('Buyer', {
				operator_name: 'Buyer.OperatorDetails.Name',
				operator_phone: 'Buyer.OperatorDetails.TelephoneNumber',
				operator_email: 'Buyer.OperatorDetails.E-mail',
				...ADDRESS_MAP
			}),
			modalConf: edi.selectors.getLegacyModalConf(modalAdditionalConf)
		});

		seller = createCS({
			itemId: 'seller',
			title: 'documents.order.seller',
			relationsOnly: true,
			allowBlank: false,
			allowReset: true,
			readOnly: isEdit,
			relations: sellers,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			selectedOrgValues: document ? sellerRelation : null,
			is_valid: !!document,
			fieldValues: document ? parties : undefined,
			callback: function (values, org) {
				var orgId = org ? org.id : null;
				if (productsGrid.getPartnerId() != orgId) {
					productsGrid.productsGrid.getStore().removeAll();
				}
				sellerId = orgId;
				productsGrid.setPartnerId(orgId);
				if (delivery.getOrgIdForDelcat() != orgId) {
					delivery.reset();
					delivery.setOrgIdForDelcat(orgId);
				}
				if (ultimateCustomer.getOrgIdForDelcat() != orgId) {
					ultimateCustomer.reset();
					ultimateCustomer.setOrgIdForDelcat(orgId);
				}
				checkValid();
			},
			fieldsMap: edi.selectors.getOrderFieldsMap('Seller', {
				operator_name: 'Seller.OperatorDetails.Name',
				operator_phone: 'Seller.OperatorDetails.TelephoneNumber',
				operator_email: 'Seller.OperatorDetails.E-mail',
				representative_name: 'Seller.SalesRepresentative.Name',
				representative_phone: 'Seller.SalesRepresentative.TelephoneNumber',
				representative_email: 'Seller.SalesRepresentative.E-mail',
				...ADDRESS_MAP
			}),
			modalConf: edi.selectors.getLegacyModalConf(
				Ext.merge(
					{
						contact: {
							representative_name: edi.selectors.defaults.representative_name,
							representative_phone: edi.selectors.defaults.representative_phone,
							representative_email: edi.selectors.defaults.representative_email
						}
					},
					modalAdditionalConf
				)
			)
		});

		Ext.merge(modalAdditionalConf, {
			contact: {
				operator_name: null,
				operator_phone: null,
				operator_email: null,

				contact_name: edi.selectors.defaults.contact_name,
				contact_phone: edi.selectors.defaults.contact_phone,
				contact_email: edi.selectors.defaults.contact_email,
				contact_web: edi.selectors.defaults.contact_web
			}
		});

		delivery = createCS({
			itemId: 'delivery',
			title: 'document.delivery',
			tooltip: 'document.delivery.tooltip',
			allowReset: true,
			alwaysShowSelect: true,
			ownOrg: true,
			ownCatalog: true,
			allowBlank: false,
			relationsFromDelcatByOrgId: sellerId,
			fieldValues: document ? parties : undefined,
			is_valid: !!edi.utils.getObjectProperty(parties, 'DeliveryPoint.ILN'),
			callback: function () {
				initialData ? edi.utils.setObjectProperty(initialData, 'Order-Parties.DeliveryPoint', null) : null;
				checkValid();
			},
			fieldsMap: edi.selectors.getOrderFieldsMap('DeliveryPoint', {
				contact_name: 'DeliveryPoint.ContactPerson',
				contact_phone: 'DeliveryPoint.PhoneNumber',
				contact_email: 'DeliveryPoint.E-mail',
				contact_web: 'DeliveryPoint.Web',
				...ADDRESS_MAP
			}),
			modalConf: edi.selectors.getLegacyModalConf(modalAdditionalConf)
		});

		ultimateCustomer = createCS({
			itemId: 'ultimateCustomer',
			title: 'documents.ultimate.customer',
			tooltip: 'documents.ultimate.customer.tooltip',
			allowReset: true,
			alwaysShowSelect: true,
			ownOrg: true,
			ownCatalog: true,
			is_valid: true,
			allowBlank: true,
			relationsFromDelcatByOrgId: sellerId,
			fieldValues: document ? parties : undefined,
			callback: function () {
				initialData ? edi.utils.setObjectProperty(initialData, 'Order-Parties.UltimateCustomer', null) : null;
				checkValid();
			},
			fieldsMap: edi.selectors.getOrderFieldsMap('UltimateCustomer', {
				contact_name: 'UltimateCustomer.ContactPerson',
				contact_phone: 'UltimateCustomer.PhoneNumber',
				contact_email: 'UltimateCustomer.E-mail',
				contact_web: 'UltimateCustomer.Web',
				...ADDRESS_MAP
			}),
			modalConf: edi.selectors.getLegacyModalConf(modalAdditionalConf)
		});

		if (document) {
			var lines = edi.utils.getObjectProperty(document, 'Order-Lines.Line', true);
			if ('string' != typeof lines) {
				for (i = 0; i < lines.length; i++) {
					var values = Ext.clone(lines[i]['Line-Item']);
					values['BuyerLocationCode'] = edi.utils.getObjectProperty(
						lines[i],
						'Line-Delivery.BuyerLocationCode',
						true
					);
					values['ExpectedDeliveryDate'] = edi.utils.getObjectProperty(
						lines[i],
						'Line-Delivery.ExpectedDeliveryDate',
						true
					);
					values['Shipment-Condition'] = edi.utils.getObjectProperty(
						lines[i],
						'Shipment-Conditions.Shipment-Condition',
						true
					);
					edi.document.actions.setInternalLineId(lines[i], values);
					linesData.push(values);
				}
			}
		}

		mandatoryGridWarn = createPanel({
			layout: 'column',
			margin: '30 0 0 0',
			items: [
				createLabel({
					text: edi.i18n.getMessage('documents.fns_ukd.change.grid.warn'),
					cls: 'error-description'
				})
			]
		});
		productsGrid = createProductGridBase({
			ownCatalog: false,
			title: edi.i18n.getMessage('document.order.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			margin: '16 0 0',
			data: document ? linesData : undefined,
			lockIfNoPartner: true,
			gridModel: DELJIT_LINE_MODEL,
			totalModel: DELJIT_SUMMARY_MODEL,
			gridColumnConfig: DELJIT_LINE_COLUMN,
			totalColumnConfig: DELJIT_SUMMARY_DETAIL_COLUMN,
			lineNumberFieldName: 'LineNumber',
			subRowDataConfig: {
				columns: DELJIT_LINE_SHIPMENT_CONDITIONS_COLUMN,
				rowId: 'LineNumber',
				model: edi.models.getModel(DELJIT_ROW_SHIPMENT_CONDITION_MODEL),
				parentPropName: 'Shipment-Condition'
			},
			isValidRecord: function (record) {
				var product = record.getData(),
					taxValid = edi.methods.taxRates.isValidRate(product.TaxRate, availableTaxRates);
				return (
					taxValid &&
					!!product.OrderedQuantity &&
					parseFloat(product.OrderedGrossAmount || 0) <= edi.utils.getMaxValueByLength(12)
				);
			},
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				if (isEdit) {
					isChangedGrid = true;
				}
				checkValid();
			},
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				checkValid();
			},
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
			selectProductHandler: function (product) {
				var fields = this.getFormFields();
				var PackDetails = product.PackDetails ? product.PackDetails : {};

				fields.BuyerItemCode?.setValue(product.BuyerItemCode);
				fields.Discount?.setValue(product.Discount);
				fields.EAN ? fields.EAN.setValue(product.EAN) : null;
				fields.OrderedQuantity && fields.OrderedQuantity.getValue() < PackDetails.MinOrderedQuantity
					? fields.OrderedQuantity.setValue(PackDetails.MinOrderedQuantity)
					: null;
				fields.ItemType ? fields.ItemType.setValue(product.ItemType) : null;
				fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
				fields.SupplierItemCode ? fields.SupplierItemCode.setValue(product.SupplierItemCode) : null;
				fields.OrderedUnitNetPrice ? fields.OrderedUnitNetPrice.setValue(product.UnitNetPrice) : null;
				fields.OrderedUnitPacksize ? fields.OrderedUnitPacksize.setValue(PackDetails.UnitPacksize) : null;
				fields.ExpirationDate ? fields.ExpirationDate.setValue(product.ExpirationDate) : null;
				fields.MaxExpirationDate ? fields.MaxExpirationDate.setValue(product.MaxExpirationDate) : null;
				fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;

				fields.UnitOfMeasure ? fields.UnitOfMeasure.findAndSetValue(product.UnitOfMeasure) : null;
			},
			changeValuesAfterEdit: edi.methods.product.grid.setValuesDeljit,
			modalFormConfig: {
				title: 'document.order.line',
				formMethods: {
					setFieldsOrder: function () {
						var values = this.getFormValues();
						var fields = this.getFormFields();
						var data = edi.methods.product.grid.gatherValuesRow(
							values,
							edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT
						);
						var calculated = edi.methods.product.grid.calculateOrder(data);

						fields.OrderedNetAmount
							? fields.OrderedNetAmount.setValue(edi.utils.roundTo(calculated.total, 3))
							: null;
					}
				},
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								maskRe: /\d/i,
								validator: edi.methods.validators.ean
							},
							{
								title: edi.i18n.getMessage('line.item.ordered.quantity'),
								name: 'OrderedQuantity',
								validator: edi.methods.validators.positiveNonZero,
								type: 'number',
								allowBlank: false,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: 3,
								listeners: {
									change: function () {
										this.setFieldsOrder();
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								value: 'CU',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.item.status.code'),
								name: 'ItemStatusCode',
								type: 'combo',
								value: '39',
								store: edi.stores.initItemStatusCodeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.item.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								maxLength: 35,
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								maxLength: 175,
								name: 'SupplierItemCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ordered.unit.net.price'),
								name: 'OrderedUnitNetPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: 4,
								maxLength: 12,
								allowBlank: false,
								listeners: {
									change: function (comp, value) {
										this.setFieldsOrder();
										var fields = this.getFormFields();
										if (fields.SupplierUnitNetPrice) {
											fields.SupplierUnitNetPrice.setValue(value);
										}
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.unit.net.price'),
								name: 'SupplierUnitNetPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: 4,
								maxLength: 12,
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('line.item.tax.rate'),
								name: 'TaxRate',
								type: 'combo',
								store: edi.stores.createInlineStore(availableTaxRates),
								allowBlank: false,
								validator: isEdit
									? function (value) {
											return (
												edi.methods.taxRates.isValidRate(value, availableTaxRates, true) ||
												edi.i18n.getMessage('form.field.tax.invalid')
											);
									  }
									: null,
								listeners: {
									change: function (comp, value) {
										var values = this.getFormValues();
										var fields = this.getFormFields();
										edi.methods.product.grid.setFieldsInvoice(values, fields);

										if (fields.PreviousTaxRate && !fields.PreviousTaxRate.getValue()) {
											fields.PreviousTaxRate.setValue(value);
										}
									}
								}
							},
							{
								title: edi.i18n.getMessage('pricat.product.discount'),
								name: 'Discount',
								maxLength: 12
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
						items: [
							{
								title: edi.i18n.getMessage('line.delivery.buyer.location.code'),
								name: 'BuyerLocationCode',
								maxLength: 6
							},
							{
								title: edi.i18n.getMessage('line.item.tab.package.ExpectedDeliveryDate'),
								name: 'ExpectedDeliveryDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.expiration.date'),
								name: 'ExpirationDate',
								type: 'date',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('line.item.max.expiration.date'),
								name: 'MaxExpirationDate',
								type: 'date',
								allowBlank: false
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.shipment.conditions'),
						bodyPadding: 0,
						items: [
							{
								type: 'editableGrid',
								name: 'Shipment-Condition',
								config: {
									fields: [
										{
											title: 'line.item.shipment.conditions.CumulativeQuantity',
											name: 'CumulativeQuantity',
											type: 'number'
										},
										{
											title: 'line.item.shipment.conditions.QuantityToBeDelivered',
											name: 'QuantityToBeDelivered',
											type: 'number'
										},
										{
											title: 'line.item.shipment.conditions.ExpectedDespatchDate',
											name: 'ExpectedDespatchDateTime',
											type: 'date',
											format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
											submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_NO_S,
											renderer: dateTimeRenderer
										},
										{
											title: 'line.item.shipment.conditions.RequestedDeliveryDate',
											name: 'RequestedDeliveryDateTime',
											type: 'date',
											format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
											submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_NO_S,
											renderer: dateTimeRenderer
										}
									],
									limit: 20,
									model: DELJIT_SHIPMENT_CONDITION_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										minHeight: 200,
										title: edi.i18n.getMessage('line.item.tab.shipment.conditions')
									}
								}
							}
						]
					}
				]
			}
		});
		if (document) {
			productsGrid.setPartnerId(sellerId);
		}

		var createButton = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'ultimateCustomer', 'delivery'],
							[buyer, seller, ultimateCustomer, delivery],
							productsGrid,
							!productValues.products.length || (!isChangedGrid && isEdit)
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					save(values);
				}
			},
			!!id
		);

		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT
				)
			)
		) {
			var createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'ultimateCustomer', 'delivery'],
							[buyer, seller, ultimateCustomer, delivery],
							productsGrid,
							!productValues.products.length || (!isChangedGrid && isEdit)
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					afterSave = function (documentId, callback) {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							Ext.encode({}),
							null,
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.in.sending.process'
							),
							callback
						);
					};
					save(values);
				},
				null,
				isEdit
			);
		}

		var twoColumnsLayout = createTwoColumnsLayout(
			[
				headData,
				autogenCheckbox,
				orderCurrency,
				deliveryPanel,
				orderRemarks,
				buyer,
				seller,
				delivery,
				ultimateCustomer
			],
			[modeOfTransport, agreementNumber, agreementDate, functionalDocumentCode],
			0.625
		);

		form = createForm({
			cls: 'edi-form document-add-form',
			autoScroll: true,
			bodyPadding: 10,
			items: [twoColumnsLayout, mandatoryGridWarn, productsGrid],
			buttons: createButtonContainer({
				items: [createAndSendButton, createButton]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var valid = false;
		var total = productValues.products.length;
		var validCompanies = validateCompanySelectors();
		var hasInvalid = form.hasInvalidField();
		var validGrid = productsGrid.isValid();

		mandatoryGridWarn.setVisible(!isChangedGrid && isEdit);
		if (total && !hasInvalid && validCompanies && validGrid) {
			valid = true;
		}
		return valid;
	};
	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isBuyerValid = buyer.isValid(),
			isSellerValid = seller.isValid(),
			isDeliveryValid = delivery.isValid(),
			isUltimateCustomerValid = ultimateCustomer.isValid();

		return isBuyerValid && isSellerValid && isDeliveryValid && isUltimateCustomerValid;
	};
	/**
	 * Saves deljit
	 * @param {Object}    formValues    values from form
	 */
	var save = function (formValues) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = formValues || {};
		var numberAutoGenerated = formValues.numberAutoGenerated;
		delete formValues.numberAutoGenerated;
		var document = createDeljit({
			'Order-Header': values['Order-Header'],
			parties: {
				Buyer: values['Buyer'],
				Seller: values['Seller'],
				DeliveryPoint: values['DeliveryPoint'],
				UltimateCustomer: values['UltimateCustomer']
			},
			productValues: productValues
		});
		document = edi.document.actions.mergeDataBeforeSave(initialData, document, [
			{
				path: 'Order-Lines.Line'
			}
		]);
		edi.utils.clearEmptyValues(document);
		var headerData = {
			data: Ext.encode(document),
			date: edi.utils.getObjectProperty(document, 'Order-Header.OrderDate')
		};

		if (isNumberAutoGenerated && numberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(document, 'Order-Header.OrderNumber');
		}
		var success = function (responseData) {
			var finishSaving = function () {
				moduleData.isChanged = false;
				isChangedGrid = false;
				moduleData.tab.setLoading(false);
				edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit);
				if (id) {
					edi.events.documents.fireEvent('change', {
						id: id
					});
				} else {
					edi.events.documents.fireEvent('create');
				}
			};
			var documentId = id || responseData.data.id;
			if (afterSave && documentId) {
				afterSave(documentId, function () {
					edi.document.actions.getDocumentHeaderData(documentId, function (headerData) {
						headerData ? (responseData.data = headerData) : null;
						finishSaving();
					});
				});
			} else {
				finishSaving();
			}
		};
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT
		);
		edi.document.actions.processDocument(
			sellerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
			parentId,
			id,
			success,
			failure,
			headerData
		);
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var createModuleForm = function (docData) {
			modulePanel.add(createModForm(docData));

			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);
		};

		if (data && data.id) {
			id = data.id;
			isEdit = true;
			sellerId = data.toOrg.id;

			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						initialData = data.data;
						createModuleForm(data.data);

						var products = productValues.products.length ? productValues.products : linesData;
						if (products.length) {
							edi.methods.taxRates.showInvalidProductsWarnIfNeeded(products, availableTaxRates, {
								allowBlankRate: true
							});
						}

						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			var createModulePanel = function () {
				createModuleForm();
				seller.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			};

			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' == docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
