import { x5ContainerMethods } from './x5ContainerMethods.js';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createProxyConfig } from '@Components/storeComponents';
import { createActionsColumnConfig, createGrid } from '@UIkit/components/grid';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, createFieldBlockForDetails, createModalForm } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton, createActionsButton } from '@UIkit/components/buttons';
import { createLabelBlockForDetails, createLabel } from '@UIkit/components/fields';
import { createSignaturesField } from '@Edi/specialComponents/documentHeaderPanel/fields';

Ext.namespace('edi.modules');
edi.modules['document.details.x5.agreement.container'] = function () {
	let moduleData,
		document,
		children,
		supplierAgreementChildren,
		senderOrg,
		receiverOrg,
		data,
		loas = [],
		objectData,
		direction,
		supplierAgreement,
		rejectReasonText = '';

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		objectData = moduleData.initData.data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	const createHeadPanel = function () {
		//Клонируем хэдер, что бы подменить вывод количества подписей, но что бы это не влияло на actionRules
		const docHeaderClone = Ext.clone(moduleData.initData.data);
		docHeaderClone.countSignatures = supplierAgreement.countSignatures || 0;

		return createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: docHeaderClone,
			noUsage: true,
			noSignatures: false,
			rejectReasonText: rejectReasonText,
			getSignaturesField: function () {
				const me = this;
				const countSignatures = me?.documentData?.countSignatures || 0;
				return createSignaturesField(me, {
					labelCfg: {
						text: countSignatures > 1 ? countSignatures - 1 : countSignatures
					},
					buttonCfg: {
						handler: function () {
							edi.document.actions.signatureInfoDialog(me.initData, me.documentData.id, {
								gridCfg: {
									storeConfig: {
										filters: [
											function (signRecord) {
												const signRole = signRecord.get('role');
												//не показываем подпись оператора
												return signRole !== 'OPERATOR';
											}
										]
									}
								}
							});
						}
					}
				});
			}
		});
	};

	const createMainDataBlock = function () {
		return createFieldBlockForDetails({
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('documents.x5.agreement.container.nr'),
							text: edi.utils.getObjectProperty(supplierAgreementChildren, 'DOC_NUMBER')
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.utils.getObjectProperty(supplierAgreementChildren, 'DOC_DATE')
						},
						{
							isNewLine: true,
							title: 'DOC ID',
							text: edi.utils.getObjectProperty(supplierAgreementChildren, 'DOCID_s')
						},
						{
							title: 'CARD ID',
							text: edi.utils.getObjectProperty(supplierAgreementChildren, 'CARD_ID_s')
						}
					]
				})
			]
		});
	};

	const createUploadStatutoryDocBlock = function () {
		const isSender = senderOrg.id === edi.core.getUserData().org.id;
		const showUploadButton = data.state === edi.constants.STATE.UPDATE_RECEIVER_LOA && !isSender;
		if (!showUploadButton) {
			return null;
		}
		const statutoryAddButton = createButton({
			cls: [BUTTON_CLS.light, BUTTON_CLS.small, 'edi-label-button details-button active'],
			text: edi.i18n.getMessage('document.upload.select'),
			width: 200,
			handler: () =>
				x5ContainerMethods
					.uploadStatutoryDoc(
						document.id,
						senderOrg.id,
						function (submitResp) {
							edi.events.documents.fireEvent('change', {
								id: objectData.id
							});
						},
						function () {
							loas = [];
							children = [];
						},
						function () {
							let postData;
							if (
								data.state === edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT &&
								supplierAgreement.countSignatures > 0
							) {
								postData = {
									PUSH_TO_WAIT_SENDER_DECISION: true
								};
							} else if (data.state !== edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT) {
								postData = {};
							}
							return postData;
						}
					)
					.then(({ success, canceled, errorText, errorObject }) => {
						if (canceled) return;

						if (!success) {
							const err =
								errorText || edi.utils.formatComplexServerError(errorObject, 'error.getting.data');
							edi.core.showError(err);
						} else {
							edi.events.documents.fireEvent('change', {
								id: objectData.id
							});
						}
						moduleData.tab.setLoading(false);
					})
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.x5.agreement.container.file.loas'),
			items: [statutoryAddButton]
		});
	};

	const createSenderBlock = function () {
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.sender'),
			items: [
				createOrgSelector({
					is_valid: true,
					readOnly: true,
					fieldValues: edi.converters.convertOrgToPartie(senderOrg)
				})
			]
		});
	};

	const createReceiverBlock = function () {
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.receiver'),
			items: [
				createOrgSelector({
					is_valid: true,
					readOnly: true,
					fieldValues: edi.converters.convertOrgToPartie(receiverOrg)
				})
			]
		});
	};

	const createDocsGrid = function () {
		const columns = edi.columns.get('x5_agreement_container');
		columns.push(
			createActionsColumnConfig({
				align: 'center',
				items: [
					{
						glyph: edi.constants.ICONS.CLOUD_DOWNLOAD,
						testCls: 'test-action-column-download',
						tooltip: edi.i18n.getMessage('action.export.attachment'),
						handler: function (_view, _rowIndex, _colIndex, _clickItem, _e, record) {
							const recordData = record.getData();
							const url = edi.document.actions.formatExportAttachementUrl({
								id: recordData.id
							});
							edi.rest.downloadFile(url, recordData.id);
						}
					},
					{
						tooltip: edi.core.getMessage('action.print'),
						testCls: 'test-action-column-print',
						glyph: edi.constants.ICONS.PRINT,
						handler: function (_view, _rowIndex, _colIndex, _clickItem, _e, record) {
							const recordData = record.getData();
							edi.document.actions.createPrintModal(
								recordData,
								{
									usingReport: true
								},
								recordData.id
							);
						},
						isHidden: function (_view, _rowIndex, _colIndex, _clickItem, record) {
							return (
								record.get('type') === edi.constants.DOCUMENT_TYPES.LOA_RECEIVER ||
								record.get('type') === edi.constants.DOCUMENT_TYPES.LOA_SENDER
							);
						}
					}
				]
			})
		);

		return createGrid({
			proxy: createProxyConfig({
				type: 'pagingmemory',
				data: children
			}),
			storeConfig: {
				pageSize: edi.constants.MAX_PAGE_SIZE,
				model: edi.models.getModel('X5_AGREEMENT_CONTAINER'),
				remoteFilter: true,
				sortOnLoad: true,
				sorters: {
					property: 'name',
					direction: 'ASC'
				}
			},
			gridConfig: {
				title: edi.i18n.getMessage('documents.x5.agreement.container.loas'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columns: columns,
				region: 'center',
				autoScroll: true,
				disablePaging: false
			}
		});
	};

	/**
	 * Creates details form
	 * @returns {Object}
	 */
	const createDetailsPanel = function () {
		return createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6], 12, 12]
			},
			items: [
				createHeadPanel(),
				createMainDataBlock(),
				createSenderBlock(),
				createReceiverBlock(),
				createUploadStatutoryDocBlock(),
				createDocsGrid()
			]
		});
	};

	const replacementModal = function () {
		const modal = createModalPanel({
			width: MODAL_SIZE.widthMedium,
			items: [
				createModalForm({
					items: [
						createLabel({
							text: edi.i18n.getMessage('documents.x5.agreement.replacement.msg')
						})
					]
				})
			],
			buttonsBefore: [
				createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('form.btn.download'),
					glyph: edi.constants.ICONS.FILE_DOWNLOAD,
					handler: function () {
						const url = edi.document.actions.formatExportAttachementUrl({
							id: supplierAgreement.id
						});
						edi.rest.downloadFile(url, supplierAgreement.id);
						edi.events.documents.fireEvent('change');
						modal.close();
					}
				})
			],
			listeners: {
				close: function () {
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.X5NOTIFICATION.NOTIFIED_REPLACEMENT.GET,
							{
								id: objectData.id
							},
							true
						),
						'PUT',
						{},
						function (data) {
							if (data && data.data) {
								edi.events.documents.fireEvent('change');
								modal.close();
							}
						}
					);
				}
			}
		});
		modal.show();
	};

	/**
	 * Creates action panel
	 */
	const createModuleActionsPanel = function (document) {
		const data = moduleData.initData.data;
		document.id = data.id;
		const excludeActions = {};
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PACKAGING] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.COMPLETE] = true;
		if (
			data.state !== edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT &&
			data.state !== edi.constants.STATE.READ
		) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.REJECT] = true;
		}

		if (
			data.state === edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT &&
			supplierAgreement.countSignatures > 0
		) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.SIGN] = true;
		}
		direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const needSignatures = edi.document.actions.getSignCount(data);
		const needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.CONSUMER);
		let signaturesCount = needSignatures + needSignatures2 - data.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount;
		const actionsPanel = createActionsPanel();

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			excludeActions: excludeActions,
			actionProps: {
				SIGN: {
					component: createActionsButton({
						text: edi.i18n.getMessage('form.btn.sign'),
						glyph: edi.constants.ICONS.SIGN,
						handler() {
							moduleData.tab.setLoading(true);
							x5ContainerMethods
								.x5ContainerSign(data, {
									senderOrgId: senderOrg.id,
									hasUploadedPoa: !!loas.find(function (loa) {
										return loa.type === edi.constants.DOCUMENT_TYPES.LOA_RECEIVER;
									}),
									getPushData: function () {
										let postData;
										if (
											data.state === edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT &&
											supplierAgreement.countSignatures > 0
										) {
											postData = {
												PUSH_TO_WAIT_SENDER_DECISION: true
											};
										} else if (
											data.state !== edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT
										) {
											postData = {};
										}
										return postData;
									}
								})
								.then(({ success, canceled, hasChanges, errorText, errorObject }) => {
									if (!success && !canceled) {
										const err =
											errorText ||
											edi.utils.formatComplexServerError(errorObject, 'error.getting.data');
										edi.core.showError(err);
									}
									moduleData.tab.setLoading(false);
									if (hasChanges) {
										//Обновим контейнер т.к. могла быть загружена доверенность, но само подписание не прошло
										edi.events.documents.fireEvent('change', {
											id: objectData.id
										});
									}
								});
						}
					})
				},
				REJECT: {
					methodAddOptions: {
						useBeforeAction:
							data.state === edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT ||
							data.state === edi.constants.STATE.SIGNED_BY_CONSUMER
					}
				},
				REFRESH: {
					handler: function () {
						edi.events.documents.fireEvent('change', {
							id: objectData.id
						});
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document')
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				objectData = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		data = moduleData.initData.data;

		const continueWithRendering = function () {
			const replacementAttr = edi.utils.getObjectProperty(objectData, 'attributes.UPDATED');
			const replacementAttrValue = edi.utils.getAttributeByName(objectData.attributes, 'UPDATED');
			const notifiedReplacementAttr = edi.utils.getObjectProperty(objectData, 'attributes.NOTIFIED_REPLACEMENT');
			const notifiedReplacementAttrValue = edi.utils.getAttributeByName(
				objectData.attributes,
				'NOTIFIED_REPLACEMENT'
			);
			if (replacementAttrValue && !notifiedReplacementAttrValue) {
				replacementModal();
			} else if (
				replacementAttrValue &&
				!!notifiedReplacementAttrValue &&
				replacementAttr.modifyDate > notifiedReplacementAttr.modifyDate
			) {
				replacementModal();
			}
			//При открытии формы просмотра перезагрузим док, т.к. происходит отметка о прочтении и меняется статус
			if (objectData.state === edi.constants.STATE.RECEIVED && edi.constants.DIRECTIONS.INCOMING === direction) {
				moduleData.tab.setLoading(false);
				if ('function' == typeof initCallBack) {
					initCallBack();
				}
				changeHandler(data);
				return;
			}

			moduleData.tab.removeAll();
			const modulePanel = createDetailsModulePanel();
			modulePanel.add(createDetailsPanel(document));
			moduleData.tab.add(createModuleActionsPanel(document));
			moduleData.tab.add(modulePanel);
			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};

		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const loadChildren = function () {
			edi.rest.sendRequest(
				edi.document.actions.formatLinkedChildrenUri(moduleData.initData),
				'GET',
				undefined,
				function (responseData) {
					if (responseData && responseData.items && responseData.items.length) {
						children = [];
						loas = [];
						for (let i = 0; i < responseData.items.length; i++) {
							if (responseData.items[i].type === edi.constants.DOCUMENT_TYPES.SUPPLIER_AGREEMENT) {
								receiverOrg = responseData.items[i].toOrg;
								senderOrg = responseData.items[i].fromOrg;
								supplierAgreement = responseData.items[i];
								if (!!responseData.items[i].id) {
									edi.rest.sendRequest(
										edi.utils.formatString(
											edi.rest.services.DOCUMENTS.CONTENT.GET,
											{
												documentId: responseData.items[i].id
											},
											true
										),
										'GET',
										{},
										function (response) {
											if (response && response.data) {
												supplierAgreementChildren = response.data;
												continueWithRendering();
											}
										},
										failure
									);
								} else {
									continueWithRendering();
								}
							}
							if (
								responseData.items[i].type === edi.constants.DOCUMENT_TYPES.LOA_RECEIVER ||
								responseData.items[i].type === edi.constants.DOCUMENT_TYPES.LOA_SENDER
							) {
								loas.push(responseData.items[i]);
							}
							children.push(responseData.items[i]);
						}
					}
				},
				failure
			);
		};

		const getDocContent = function () {
			const contentSuccess = function (contentResponse) {
				if (!contentResponse?.data) {
					failure(contentResponse);
					return;
				}

				document = contentResponse.data;
				if (
					objectData.state === edi.constants.STATE.REJECTED ||
					objectData.state === edi.constants.STATE.UPDATE_RECEIVER_LOA
				) {
					const notificationSuccess = function (resp) {
						rejectReasonText = resp?.data || '';
						loadChildren();
					};

					const notificationUrl = edi.utils.formatString(
						edi.rest.services.DOCUMENTS.X5NOTIFICATION.GET,
						{ id: objectData.id },
						true
					);
					edi.rest.sendRequest(notificationUrl, 'GET', {}, notificationSuccess, loadChildren);
				} else {
					loadChildren();
				}
			};

			const contentUrl = edi.document.actions.formatDetailsUri(moduleData.initData);
			edi.rest.sendRequest(contentUrl, 'GET', {}, contentSuccess, failure);
		};
		const direction = edi.utils.getDocumentDirection(objectData.toOrg, objectData.fromOrg);
		if (objectData.state === edi.constants.STATE.RECEIVED && edi.constants.DIRECTIONS.INCOMING === direction) {
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.SEND.PUT,
					{
						documentId: objectData.id
					},
					true
				),
				'PUT',
				Ext.encode({}),
				function () {
					edi.events.documents.fireEvent('change', {
						id: objectData.id
					});
					getDocContent();
				},
				failure
			);
		} else {
			getDocContent();
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
