import { createPanel } from '@Components/panels';
import { createButton } from '@UIkit/components/buttons';

const baseCls = 'ui-carousel';
Ext.define('edi.components.Carousel', {
	extend: 'Ext.panel.Panel',
	baseCls,
	dotsButtonCls: `${baseCls}-dots-item`,
	cardCls: `${baseCls}-cards-item`,
	cardsPanelCls: `${baseCls}-cards`,
	dotsPanelCls: `${baseCls}-dots`,
	gap: 16,
	stopScrollRight: true,
	layout: 'auto',
	cards: [],

	initComponent: function () {
		this.setOwnConfig();
		this.callParent(arguments);
		this.afterInit();
	},

	setOwnConfig() {
		this.createCarouselPanel();
		this.createCards();
	},

	afterInit() {},

	isElementInViewport(element, cardPanelX, cardPanelWidth, cardWidth, isLeftSide) {
		const bounding = element.getBoundingClientRect();
		const gridDom = Ext.dom.Query.select('.edi-main-modules-panel');
		const clientWidth = gridDom[0] ? gridDom[0].clientWidth : window.innerWidth;
		if (isLeftSide) {
			return clientWidth + Math.abs(bounding.left) + this.gap >= cardPanelWidth;
		} else {
			return bounding.right + cardWidth > cardPanelWidth;
		}
	},

	nextButtonHandler() {
		let me = this;
		let width = me.cardPanel.getWidth(),
			x = me.cardPanel.getX(),
			card = me.cards[0],
			cardWidth = card.getWidth();
		let domEl = me.cardPanel.getEl().dom;
		let isDisabled = me.isElementInViewport(domEl, x, width, cardWidth, true);
		me.nextBtn?.setDisabled(isDisabled);
		me.prevBtn?.setDisabled(false);
		me.stopScrollLeft = isDisabled;
		me.stopScrollRight = false;

		me.cardPanel.animate({
			duration: 800,
			from: {
				x: x
			},
			to: {
				x: x - cardWidth - me.gap
			}
		});
	},

	prevButtonHandler() {
		let me = this;
		let width = me.cardPanel.getWidth(),
			x = me.cardPanel.getX(),
			card = me.cards[0],
			cardWidth = card.getWidth();

		let domEl = me.cardPanel.getEl().dom;
		let isDisabled = me.isElementInViewport(domEl, x, width, cardWidth);
		me.prevBtn?.setDisabled(isDisabled);
		me.nextBtn?.setDisabled(false);
		me.stopScrollRight = isDisabled;
		me.stopScrollLeft = false;

		me.cardPanel.animate({
			duration: 800,
			from: {
				x: x
			},
			to: {
				x: x + cardWidth + me.gap
			}
		});
	},

	isShowDots() {
		let me = this;
		return me.cardsWidth + me.cards.length * me.gap > window.innerWidth;
	},

	createDots() {
		let me = this;
		me.cardPanel.setWidth(me.cardsWidth);

		me.prevBtn = createButton({
			cls: me.dotsButtonCls,
			glyph: edi.constants.ICONS.CHEVRON_DOUBLE_LEFT,
			handler: () => me.prevButtonHandler()
		});
		this.prevBtn.setDisabled(true);

		this.nextBtn = createButton({
			cls: me.dotsButtonCls,
			glyph: edi.constants.ICONS.CHEVRON_DOUBLE_RIGHT,
			handler: () => me.nextButtonHandler()
		});
		me.dotsPanel.removeAll();
		me.dotsPanel.add(me.prevBtn);
		me.dotsPanel.add(me.nextBtn);
	},

	createCardCarousel(item) {
		const me = this;
		let isDown = false;
		let startX;
		let scrollLeft;

		item.addCls(me.cardCls);
		item.on({
			element: 'el',
			mousedown: {
				fn: function (e) {
					if (me.isShowDots()) {
						e.preventDefault();
						let domEl = me.cardPanel.getEl().dom;
						isDown = true;
						startX = e.parentEvent.pageX - domEl.offsetLeft;
						scrollLeft = domEl.scrollLeft;
					}
				},
				element: 'el',
				scope: item
			},
			mouseup: {
				fn: function () {
					if (me.isShowDots()) {
						isDown = false;
					}
				},
				element: 'el'
			},
			mousemove: {
				fn: function (e) {
					if (me.isShowDots()) {
						if (!isDown) return;
						e.preventDefault();
						let domEl = me.cardPanel.getEl().dom;
						const x = e.parentEvent.pageX - domEl.offsetLeft;
						if (x - startX < 0) {
							if (!me.stopScrollLeft) {
								me.nextButtonHandler();
							}
						} else {
							if (!me.stopScrollRight) {
								me.prevButtonHandler();
							}
						}

						isDown = false;
					}
				},
				element: 'el'
			},
			mouseleave: {
				fn: function () {
					if (me.isShowDots()) {
						isDown = false;
					}
				},
				element: 'el'
			}
		});
		return item;
	},

	// этот метод вызывают чтобы карточки подстроились по высоте к самой высокой
	// path - это путь к самой карточке, чтобы получить ее высоту
	setCardHeight(path) {
		let me = this;
		let maxCardHeight = 0;
		let maxCardItemHeight = 0;
		me.cardPanel.suspendLayouts();
		me.cardPanel.items.items.forEach(function (card) {
			if (card) {
				let box = card.el.dom;
				let cardItems = card.getRefItems();
				let boxItem = edi.utils.getObjectProperty(cardItems, path);
				let height = box.offsetHeight;
				let itemHeight = boxItem?.offsetHeight;
				if (maxCardHeight < height) {
					maxCardHeight = height;
				}
				if (maxCardItemHeight < itemHeight) {
					maxCardItemHeight = itemHeight;
				}
			}
		});

		me.cardPanel.items.items.forEach(function (card) {
			if (card) {
				let boxItem = card.getRefItems()[0];
				card.setHeight(maxCardHeight);
				if (boxItem) {
					boxItem.setHeight(maxCardItemHeight);
				}
			}
		});
		me.cardPanel.resumeLayouts();
		me.cardPanel.updateLayout();
	},

	createCards() {
		let me = this,
			cardsItems = [];
		me.cardsWidth = 0;
		me.cards.forEach(function (card) {
			cardsItems.push(me.createCardCarousel(card, me));
			me.cardsWidth += card.width + me.gap;
		});
		if (me.cardPanel) {
			me.cardPanel.removeAll();
			me.cardPanel.add(cardsItems);
		}
		if (me.isShowDots()) {
			me.createDots();
		}
	},

	createCarouselPanel() {
		let me = this;
		me.dotsPanel = createPanel({
			cls: me.dotsPanelCls,
			layout: 'column'
		});
		me.cardPanel = createPanel({
			cls: me.cardsPanelCls,
			layout: 'hbox'
		});

		me.items = [this.cardPanel, this.dotsPanel];
	}
});

const createCarousel = function (cfg) {
	return Ext.create('edi.components.Carousel', cfg);
};

export { createCarousel };
