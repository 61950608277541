import { moduleMethods } from '@Edi/modules/power_of_attorney/POA_002/methods';

const POA_LIST_COLUMNS = 'powers_of_attorney_list';
const POA_SIGN_INFO_COLUMNS = 'powers_of_attorney_sign_info';
const POA_ERROR_MODAL_COLUMNS = 'powers_of_attorney_modal_with_error';

edi.columns.addColumns({
	[POA_LIST_COLUMNS]: {
		id: {
			text: 'power.of.attorney.internal.id',
			dataIndex: 'id',
			hidden: true,
			renderer: function (value, meta, rec) {
				return rec.data.docId || '';
			},
			flex: 1,
			sortable: true
		},
		internalPoaNumber: {
			text: 'power.of.attorney.internal.number',
			dataIndex: 'internalPoaNumber',
			flex: 1,
			sortable: false
		},
		poaNumber: {
			text: 'power.of.attorney.external.number',
			dataIndex: 'poaNumber',
			flex: 1,
			sortable: false
		},
		creationDate: {
			text: 'documents.column.creationDate',
			dataIndex: 'creationDate',
			flex: 1,
			renderer: 'dateTimeFromMs',
			sortable: true
		},
		modifyDate: {
			text: 'documents.column.modifyDate',
			dataIndex: 'modifyDate',
			flex: 1,
			hidden: true,
			renderer: 'dateTimeFromMs',
			sortable: true
		},
		validFrom: {
			text: 'power.of.attorney.valid.from',
			dataIndex: 'validFrom',
			flex: 1,
			renderer: 'dateFromMs',
			sortable: true
		},
		validTo: {
			text: 'power.of.attorney.valid.to',
			dataIndex: 'validTo',
			flex: 1,
			renderer: 'dateFromMs',
			sortable: true
		},
		principals: {
			text: 'power.of.attorney.principals.column',
			dataIndex: 'principals',
			renderer: 'poaPrincipals',
			flex: 1,
			sortable: false
		},
		orgName: {
			text: 'power.of.attorney.principal.org.column',
			dataIndex: 'orgName',
			flex: 1,
			hidden: true,
			sortable: false
		},
		confidants: {
			text: 'power.of.attorney.confidants.column',
			dataIndex: 'confidants',
			renderer: 'poaConfidants',
			flex: 1,
			sortable: false
		},
		principalInn: {
			text: 'power.of.attorney.principal.inn',
			dataIndex: 'principals',
			flex: 1,
			hidden: true,
			sortable: false,
			renderer: function (value, meta) {
				const result = (value || [])
					.filter((p) => p?.inn)
					.map((p) => p.inn)
					.join(', ');
				meta.tdAttr = 'data-qtip="' + result + '"';
				return result;
			}
		},
		confidantInn: {
			text: 'power.of.attorney.confidant.inn',
			dataIndex: 'confidants',
			flex: 1,
			hidden: true,
			sortable: false,
			renderer: function (value, meta) {
				const result = (value || [])
					.filter((c) => c?.inn)
					.map((c) => c.inn)
					.join(', ');
				meta.tdAttr = 'data-qtip="' + result + '"';
				return result;
			}
		},
		state: {
			text: 'power.of.attorney.status',
			dataIndex: 'state',
			flex: 1,
			sortable: true,
			renderer: 'poaStateRenderer',
			htmlEncode: true
		}
	},
	[POA_SIGN_INFO_COLUMNS]: {
		parents: 'documents_sign_info',
		config: {
			doverRegNumber: undefined,
			naimOrgDover: undefined,
			poaStatus: undefined
		}
	},
	[POA_ERROR_MODAL_COLUMNS]: {
		internalPoaNumber: {
			text: 'power.of.attorney.internal.number',
			dataIndex: 'internalPoaNumber',
			flex: 1,
			sortable: false
		},
		poaNumber: {
			text: 'power.of.attorney.external.number',
			dataIndex: 'poaNumber',
			flex: 1,
			sortable: false
		},
		state: {
			text: 'column.status',
			dataIndex: 'state',
			htmlEncode: true,
			renderer: 'status',
			sortable: false,
			flex: 1
		},
		error: {
			text: 'documents.list.document.error',
			dataIndex: 'error',
			htmlEncode: true,
			sortable: false,
			flex: 2,
			variableRowHeight: true,
			renderer: function (value, metaData) {
				metaData.tdCls = 'column-error-description';
				return value;
			}
		}
	}
});

export { POA_LIST_COLUMNS, POA_SIGN_INFO_COLUMNS, POA_ERROR_MODAL_COLUMNS };
