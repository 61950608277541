import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import {
	createCombo,
	createDateField,
	createField,
	createNumberField,
	createTextField,
	createTimeField
} from '@Components/fields';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import {
	ALCDES_LINE_CERTIFICATE_COLUMN,
	ALCDES_LINE_COLUMN,
	ALCDES_LINE_PARTY_COLUMN,
	ALCDES_LINE_REFERENCE_COLUMN,
	ALCDES_SUMMARY_DETAIL_COLUMN
} from './columns';
import {
	ALCDES_LINE_CERTIFICATE_MODEL,
	ALCDES_LINE_MODEL,
	ALCDES_LINE_PARTY_MODEL,
	ALCDES_LINE_REFERENCE_MODEL,
	ALCDES_SUMMARY_DETAIL_MODEL
} from './models';
import { createAlcDes, createAlcDesParties } from './methods';
import { createButtonContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { coreMethods } from '@Core/commons';

/**
 * Class for new alcdes creation
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.create.alcdes'] = function () {
	var moduleData,
		id,
		isEdit,
		isCopy = false,
		isBasedOnDoc = false,
		buyerId,
		buyerOrg,
		buyerOrgValues,
		form,
		initialData,
		parentId,
		buyer,
		seller,
		sellerData,
		delivery,
		carrier,
		shipFrom,
		afterSave,
		costDecimals,
		productsGrid,
		contractInput,
		presetLicenseData = {},
		userData = edi.core.getUserData(),
		taxRatesStore = edi.stores.initTaxRatesWithFractStore(),
		availableTaxRates = [],
		alcdes,
		productValues = {
			products: []
		};
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		costDecimals = coreMethods.getUserOrgCostDecimals();

		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		sellerData = edi.converters.convertOrgToLegacyPartie(userData.org);
		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			alcdes = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			initialData = alcdes;
			delete initialData.fileId;
			buyerOrg = moduleData.initData.data.buyerOrg;
			isBasedOnDoc = true;
		}

		if (buyerOrg) {
			buyerOrgValues = edi.converters.convertOrgToPartie(buyerOrg);
			buyerId = buyerOrg.id;
		}

		taxRatesStore.each(function (record) {
			availableTaxRates.push({
				id: record.get('id'),
				name: record.get('name')
			});
		});

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Creates despatch advise form
	 * @returns {Object}
	 */
	var createModuleForm = function (document) {
		var i;
		var header = edi.utils.getObjectProperty(document ? document : alcdes, 'DespatchAdvice-Header');
		var parties = edi.utils.getObjectProperty(document ? document : alcdes, 'DespatchAdvice-Parties');

		var headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('documents.create.alcdes.nr'),
					input: createTextField({
						emptyText: edi.i18n.getMessage('nr'),
						columnWidth: 0.7333,
						allowBlank: false,
						maxLength: 35,
						value:
							document || alcdes
								? edi.utils.getObjectProperty(
										document ? document : alcdes,
										'DespatchAdvice-Header.DespatchAdviceNumber'
								  ) + (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
								: undefined,
						name: 'DespatchAdvice-Header.DespatchAdviceNumber'
					}),
					mandatory: true,
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createDateField({
					emptyText: edi.i18n.getMessage('date'),
					margin: '0 0 0 5',
					columnWidth: 0.25,
					allowBlank: false,
					value: document && !isCopy ? edi.utils.getObjectProperty(header, 'DespatchAdviceDate') : new Date(),
					name: 'DespatchAdvice-Header.DespatchAdviceDate'
				})
			]
		});

		var WaybillNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('waybill.number'),
					input: createTextField({
						columnWidth: 0.5,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.WaybillNumber',
						maxLength: 35
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var WaybillDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('waybill.date'),
					input: createDateField({
						columnWidth: 0.5,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.WaybillDate'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		contractInput = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.select.contract'),
					input: createTextField({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Header.Reference.ContractNumber',
						valueSrc: document ? document : alcdes,
						maxLength: 35
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var orderNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('buyer.order.number'),
					input: createTextField({
						columnWidth: 0.5,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.BuyerOrderNumber',
						maxLength: 35
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var orderDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('buyer.order.date'),
					input: createDateField({
						columnWidth: 0.5,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.BuyerOrderDate',
						allowBlank: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var DespatchReferenceNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('despatch.reference.number'),
					input: createTextField({
						columnWidth: 0.5,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.DespatchReferenceNumber',
						maxLength: 35,
						minLength: 1
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var DespatchReferenceDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('despatch.reference.date'),
					input: createDateField({
						columnWidth: 0.5,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.DespatchReferenceDate'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var invoiceNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('alcdes.invoice.number'),
					input: createTextField({
						columnWidth: 0.5,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.InvoiceNumber',
						maxLength: 35
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var invoiceDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('alcdes.invoice.date'),
					input: createDateField({
						columnWidth: 0.5,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.InvoiceDate'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var despatchDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('despatch.date'),
					input: createDateField({
						columnWidth: 0.6,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.DespatchDate',
						emptyText: edi.i18n.getMessage('date')
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createTimeField({
					margin: '0 0 0 5',
					columnWidth: 0.125,
					name: 'DespatchAdvice-Header.DespatchTime',
					valueSrc: document ? document : alcdes,
					emptyText: edi.i18n.getMessage('time')
				})
			]
		});

		var estimatedDeliveryDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('estimated.delivery.date'),
					input: createDateField({
						columnWidth: 0.6,
						valueSrc: document ? document : alcdes,
						name: 'DespatchAdvice-Header.EstimatedDeliveryDate'
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				})
			]
		});

		var alcdesRemarks = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('remarks'),
					input: createTextField({
						columnWidth: 0.8,
						name: 'DespatchAdvice-Header.Remarks',
						valueSrc: document ? document : alcdes,
						maxLength: 500,
						isTextarea: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		//transport
		var modeOfTransport = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('mode.of.transport'),
					input: createCombo({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Transport.ModeOfTransport',
						valueSrc: document ? document : alcdes,
						store: edi.stores.initModeOfTransportStore()
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var Capacity = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('column.capacity'),
					input: createNumberField({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Transport.Capacity',
						valueSrc: document ? document : alcdes,
						maxLength: 10,
						allowDecimals: true,
						decimalPrecision: costDecimals
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var Track = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('column.track'),
					input: createCombo({
						store: edi.stores.createSimpleInlineStore(['', 'true', 'false'], function (id) {
							return edi.i18n.getMessage(id.length ? 'track.' + id : 'form.combo.not.selected');
						}),
						columnWidth: 0.5,
						name: 'DespatchAdvice-Transport.Track',
						valueSrc: document ? document : alcdes
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var ConveyanceReferenceNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('conveyance.reference.number'),
					input: createTextField({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Transport.ConveyanceReferenceNumber',
						valueSrc: document ? document : alcdes,
						maxLength: 20,
						minLength: 1
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var carrierName = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('carrier.name'),
					input: createTextField({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Transport.CarrierName',
						valueSrc: document ? document : alcdes,
						maxLength: 175
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var termsOfDelivery = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('terms.of.delivery'),
					input: createTextField({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Transport.TermsOfDelivery',
						valueSrc: document ? document : alcdes,
						maxLength: 35
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var vehicleType = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('vehicle.type'),
					input: createTextField({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Transport.VehicleType',
						valueSrc: document ? document : alcdes,
						maxLength: 175
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var sealNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('seal.number'),
					input: createTextField({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Transport.SealNumber',
						valueSrc: document ? document : alcdes,
						maxLength: 175
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		const documentFunctionCodeValue = edi.utils.getObjectProperty(
			document,
			'DespatchAdvice-Header.DocumentFunctionCode'
		);
		var functionalDocumentCode = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('functional.document.code'),
					input: createCombo({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Header.DocumentFunctionCode',
						store: edi.stores.createSimpleInlineStore(['9', '4', '3'], function (id) {
							return edi.i18n.getMessage('functional.document.code.' + id);
						}),
						value: document && documentFunctionCodeValue !== 'O' ? documentFunctionCodeValue : '9',
						anyMatch: true,
						allowBlank: false
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				valuesByMap: true,
				forceChangeFields: {
					company_iln: true
				},
				processValues: orgConverter
			});

			return createCompanySelector(selectorConfig);
		};

		var licenseTabConf = {
			type: {
				title: 'company.license.type',
				name: 'license_type',
				maxLength: 3
			},
			name: {
				title: 'company.license.name',
				name: 'license_name',
				maxLength: 175
			},
			serial: {
				title: 'company.license.series',
				name: 'license_series',
				maxLength: 35
			},
			number: {
				title: 'company.license.number',
				name: 'license_number',
				maxLength: 35
			},
			authority: {
				title: 'company.license.issuing.authority',
				name: 'license_issuing_authority',
				maxLength: 75
			},
			license_period: {
				type: 'panel',
				fields: {
					licence_from: {
						panelConf: {
							columnWidth: 0.65
						},
						labelConf: {
							html: edi.i18n.getMessage('company.license.date'),
							columnWidth: 0.46
						},
						type: 'date',
						name: 'license_date_of_issue',
						emptyText: 'building.date.start',
						columnWidth: 0.54
					},
					licence_to: {
						panelConf: {
							columnWidth: 0.35
						},
						labelConf: {
							columnWidth: 0.05
						},
						type: 'date',
						margin: '0 0 0 5',
						name: 'license_expiration_date',
						emptyText: 'building.date.end',
						columnWidth: 0.95
					}
				}
			}
		};

		var mainTabWithInnFields = {
			company_name: edi.selectors.defaults.company_name,
			company_gln: edi.selectors.defaults.company_gln,
			company_inn_kpp: edi.selectors.mergeConfigs('company_inn_kpp', {
				fields: {
					kpp: {
						allowBlank: true
					}
				}
			}),
			company_reg_number: edi.selectors.defaults.company_reg_nr
		};

		var buyerGLN = edi.utils.getObjectProperty(parties, 'Buyer.ILN'),
			buyerHasData = !!buyerGLN,
			isBuyerDefined = !isEdit && buyerOrg;

		buyer = createCS({
			itemId: 'buyer',
			title: 'document.buyer',
			relationsOnly: true,
			allowBlank: false,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			readOnly: isEdit,
			is_valid: buyerHasData,
			relations: isBuyerDefined ? buyerOrg : edi.relations.getRelations(),
			allowReset: !buyerHasData,
			fieldValues: parties ? parties : undefined,
			selectedOrgValues: isBuyerDefined ? buyerOrgValues : buyerHasData ? parties : undefined,
			selectedOrg: isBuyerDefined ? buyerOrg : null,
			selectedRelationByMap: !isBuyerDefined,
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('Buyer', {
				includeFields: ['company_name', 'company_iln', 'company_util_reg_nr', 'company_inn']
			}),
			modalConf: edi.selectors.getLegacyModalConf({
				address: null,
				contact: null
			}),
			callback: function (values, org) {
				var orgId = org ? org.id : null;
				buyerId = orgId;
				if (productsGrid.getPartnerId() != orgId) {
					productsGrid.productsGrid.getStore().removeAll();
				}
				productsGrid.setPartnerId(orgId);
				if (delivery.getOrgIdForDelcat() != orgId) {
					delivery.reset();
					delivery.setOrgIdForDelcat(orgId);
					delivery.setPartnerOrg(org);
				}
				if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
					buyerOrg = org;
					buyerOrgValues = edi.converters.convertOrgToPartie(buyerOrg);
				} else if (!orgId) {
					buyerOrg = null;
					buyerOrgValues = null;
				}
				checkValid();
			}
		});

		var sellerGLN = edi.utils.getObjectProperty(parties, 'Seller.ILN'),
			isSellerDefined = !!sellerGLN;

		seller = createCS({
			itemId: 'seller',
			title: 'document.seller',
			readOnly: false,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id || userData.org?.id,
				converter: orgConverter
			}),
			selectedRelationByMap: false,
			is_valid: isSellerDefined || (!alcdes && !document),
			valuesByMap: parties,
			fieldValues: parties || sellerData,
			selectedOrgValues: sellerData,
			selectedOrg: userData.org,
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('Seller', {
				excludeFields: ['license_type', 'license_name']
			}),
			modalConf: {
				tabs: {
					main: mainTabWithInnFields,
					address: {
						country: edi.selectors.defaults.iso2_country_combo,
						region: edi.selectors.defaults.region,
						area: {
							title: 'company.district',
							emptyText: edi.i18n.getMessage('company.district.empty'),
							regex: /^\d{2}$/i,
							maskRe: /\d/i,
							name: 'addr_area'
						},
						city: edi.selectors.defaults.city,
						street: edi.selectors.defaults.street
					},
					license: Ext.applyIf(
						{
							type: null,
							name: null
						},
						licenseTabConf
					)
				}
			},
			callback: function (values) {
				presetLicenseData = {
					'Line-License': {
						Series: values.license_series,
						Number: values.license_number,
						IssuingAuthority: values.license_issuing_authority,
						DateOfIssue: values.license_date_of_issue,
						ExpirationDate: values.license_expiration_date
					}
				};
				checkValid();
			}
		});

		var deliveryGLN = edi.utils.getObjectProperty(parties, 'DeliveryPoint.ILN'),
			isDeliveryDefined = !!deliveryGLN;

		delivery = createCS({
			itemId: 'delivery',
			title: 'document.delivery',
			tooltip: 'document.delivery.tooltip',
			allowBlank: false,
			selectedRelationByMap: true,
			ownCatalog: false,
			is_valid: parties && parties.DeliveryPoint && parties.DeliveryPoint.ILN,
			partnerOrg: !!(document || !isDeliveryDefined),
			partnerOrgText: 'form.btn.buyer.org',
			partnerOrgValues: buyerId
				? edi.utils.getOrg({
						orgId: buyerId
				  })
				: undefined,
			alwaysShowSelect: !isDeliveryDefined,
			allowReset: !isDeliveryDefined,
			fieldValues: isDeliveryDefined ? parties : undefined,
			selectedOrgValues: isDeliveryDefined ? parties : undefined,
			relationsFromDelcatByOrgId: buyerId,
			callback: function () {
				initialData
					? edi.utils.setObjectProperty(initialData, 'DespatchAdvice-Parties.DeliveryPoint', null)
					: null;
				checkValid();
			},
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('DeliveryPoint', {
				includeFields: ['company_name', 'company_iln', 'addr_zip', 'addr_country', 'addr_city', 'addr_street']
			}),
			modalConf: edi.selectors.getLegacyModalConf({
				main: {
					company_reg_number: null
				},
				address: {
					region: null
				},
				contact: null
			})
		});

		var carrierValues = edi.utils.getObjectProperty(parties, 'Carrier'),
			isCarrierDefined = !!carrierValues;

		carrier = createCS({
			itemId: 'carrier',
			title: 'document.carrier',
			tooltip: 'document.carrier.tooltip',
			allowReset: true,
			alwaysShowSelect: true,
			ownCatalog: true,
			allowBlank: true,
			selectedRelationByMap: true,
			ownOrg: document || !isCarrierDefined,
			relationsFromLoccatByOrgId: !isCarrierDefined ? buyerId : null,
			is_valid: !isCarrierDefined || !!carrierValues.ILN,
			fieldValues: isCarrierDefined ? parties : undefined,
			selectedOrgValues: isCarrierDefined ? parties : undefined,
			callback: function () {
				initialData ? edi.utils.setObjectProperty(initialData, 'DespatchAdvice-Parties.Carrier', null) : null;
				checkValid();
			},
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('Carrier', {
				excludeFields: [
					'company_util_reg_nr',
					'addr_area',
					'addr_region_code',
					'addr_region_name',
					'addr_city',
					'addr_street',
					'addr_country'
				]
			}),
			modalConf: {
				tabs: {
					main: Ext.applyIf(
						{
							company_reg_number: null
						},
						mainTabWithInnFields
					),
					license: licenseTabConf
				}
			}
		});

		var shipFromValues = edi.utils.getObjectProperty(parties, 'ShipFrom'),
			isShipFromDefined = !!shipFromValues;
		shipFrom = createCS({
			itemId: 'shipFrom',
			title: 'document.ship.from',
			tooltip: 'document.ship.from.tooltip',
			useHiddenFields: true,
			fieldsMapOnly: true,
			allowReset: true,
			alwaysShowSelect: false,
			ownCatalog: true,
			allowBlank: true,
			selectedRelationByMap: true,
			valuesByMap: true,
			fieldValues: isShipFromDefined ? parties : undefined,
			selectedOrgValues: isShipFromDefined ? parties : undefined,
			ownOrg: document || !isShipFromDefined,
			relationsFromLoccatByOrgId: !isShipFromDefined ? buyerId : null,
			is_valid: !isShipFromDefined || !!shipFromValues.ILN,
			callback: function () {
				initialData ? edi.utils.setObjectProperty(initialData, 'DespatchAdvice-Parties.ShipFrom', null) : null;
				checkValid();
			},
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('ShipFrom', {
				includeFields: ['company_name', 'company_iln', 'company_kpp']
			}),
			modalConf: {
				tabs: {
					main: {
						company_name: edi.selectors.defaults.company_name,
						company_gln: edi.selectors.defaults.company_gln,
						company_kpp: edi.selectors.mergeConfigs('company_inn_kpp.fields.kpp', {
							panelConf: null,
							labelConf: null,
							title: 'company.kpp',
							columnWidth: 0.7
						})
					}
				}
			}
		});

		var lines = edi.utils.getObjectProperty(
			document ? document : alcdes,
			'DespatchAdvice-Consignment.Packing-Sequence.Line',
			true
		);
		if ('string' != typeof lines) {
			for (i = 0; i < lines.length; i++) {
				var values = Ext.clone(lines[i]['Line-Item']);
				values['Line-License'] = lines[i]['Line-License'];
				values['Line-Parties'] = lines[i]['Line-Parties'];
				values['Line-Reference'] = lines[i]['Line-Reference'];
				values['Line-Certificate'] = lines[i]['Line-Certificate'];
				values['Package-Identification'] = lines[i]['Package-Identification'];
				values['Line-AdditionalInformation'] = lines[i]['Line-AdditionalInformation']; //TODO
				edi.document.actions.setInternalLineId(lines[i], values);
				productValues.products.push(values);
			}
		}

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('despatch.advice.items'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: ALCDES_LINE_MODEL,
			totalModel: ALCDES_SUMMARY_DETAIL_MODEL,
			gridColumnConfig: ALCDES_LINE_COLUMN,
			totalColumnConfig: ALCDES_SUMMARY_DETAIL_COLUMN,
			data: productValues.products,
			lockIfNoPartner: true,
			margin: '16 0 0',
			lineNumberFieldName: 'LineNumber',
			defaultValuesGetter: function () {
				return presetLicenseData;
			},
			isValidRecord: function (record) {
				var isValidRate = edi.methods.taxRates.isValidRate(record.get('TaxRate'), availableTaxRates, true);
				return !!record.get('QuantityDespatched') && isValidRate;
			},
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				checkValid();
			},
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			changeValuesAfterEdit: edi.methods.product.grid.setValuesInvoice,
			selectProductHandler: function (product) {
				var fields = this.getFormFields();
				var PackDetails = product.PackDetails ? product.PackDetails : {};

				fields.EAN ? fields.EAN.setValue(product.EAN) : null;
				fields.QuantityDespatched && fields.QuantityDespatched.getValue() < PackDetails.MinOrderedQuantity
					? fields.QuantityDespatched.setValue(PackDetails.MinOrderedQuantity)
					: null;
				fields.ItemType ? fields.ItemType.setValue(product.ItemType) : null;
				fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
				fields.SupplierItemCode ? fields.SupplierItemCode.setValue(product.SupplierItemCode) : null;
				fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;
				fields.ExpirationDate ? fields.ExpirationDate.setValue(product.ExpirationDate) : null;
				fields.PackagingDate ? fields.PackagingDate.setValue(product.PackagingDate) : null;
				fields.AlcoholCode ? fields.AlcoholCode.setValue(product.AlcoholCode) : null;
				fields['Line-AdditionalInformation.CountryOfOrigin']
					? fields['Line-AdditionalInformation.CountryOfOrigin'].setValue(product.CountryOfOrigin)
					: null;
				fields.UnitVolume ? fields.UnitVolume.setValue(product.UnitVolume) : null;

				fields.UnitOfMeasure ? fields.UnitOfMeasure.findAndSetValue(product.UnitOfMeasure) : null;
			},
			modalFormConfig: {
				title: 'document.desadv.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								maskRe: /\d/i,
								validator: edi.methods.validators.ean
							},
							{
								title: edi.i18n.getMessage('column.quantity.ordered'),
								name: 'OrderedQuantity',
								type: 'number',
								validator: edi.methods.validators.positiveNonZero,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.despatched'),
								name: 'QuantityDespatched',
								allowBlank: false,
								type: 'number',
								validator: edi.methods.validators.positiveNonZero,
								allowDecimals: true,
								maxLength: 10,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								value: 'CU',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.item.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								maxLength: 35,
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								maxLength: 175,
								name: 'SupplierItemCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.unit.net.price'),
								name: 'UnitNetPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 10
							},
							{
								title: edi.i18n.getMessage('line.item.tax.rate'),
								name: 'TaxRate',
								type: 'combo',
								store: edi.stores.initTaxRatesWithFractStore()
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.expiration.date'),
								name: 'ExpirationDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.packaging.date'),
								name: 'PackagingDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.alcohol.code'),
								name: 'AlcoholCode',
								maxLength: 255,
								listeners: {
									change: function (field, value) {
										var fields = this.getFormFields();
										var allowBlank = false,
											fieldsPresent = false;
										if (value && edi.constants.VALIDATORS.ALCOHOL_CODE_SKIP_LICENSE.test(value)) {
											allowBlank = true;
										}
										if (fields['Line-License.Series']) {
											fieldsPresent = true;
											fields['Line-License.Series'].allowBlank = allowBlank;
										}
										if (fields['Line-License.Number']) {
											fieldsPresent = true;
											fields['Line-License.Number'].allowBlank = allowBlank;
										}
										if (fields['Line-License.IssuingAuthority']) {
											fieldsPresent = true;
											fields['Line-License.IssuingAuthority'].allowBlank = allowBlank;
										}
										if (fields['Line-License.DateOfIssue']) {
											fieldsPresent = true;
											fields['Line-License.DateOfIssue'].allowBlank = allowBlank;
										}
										if (fields['Line-License.ExpirationDate']) {
											fieldsPresent = true;
											fields['Line-License.ExpirationDate'].allowBlank = allowBlank;
										}
										if (fieldsPresent) {
											this.formPanel.isValid();
										}
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.volume'),
								name: 'UnitVolume',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								maxLength: 9
							},
							{
								title: edi.i18n.getMessage('line.item.country.of.origin'),
								name: 'Line-AdditionalInformation.CountryOfOrigin',
								type: 'combo',
								store: edi.stores.initOrgCountryStoreISO2()
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.license'),
						items: [
							{
								title: edi.i18n.getMessage('column.license.series'),
								name: 'Line-License.Series',
								allowBlank: false,
								maxLength: 35
							},
							{
								title: edi.i18n.getMessage('column.license.number'),
								name: 'Line-License.Number',
								allowBlank: false,
								maxLength: 35
							},
							{
								title: edi.i18n.getMessage('column.license.issuing.authority'),
								name: 'Line-License.IssuingAuthority',
								allowBlank: false,
								maxLength: 75
							},
							{
								title: edi.i18n.getMessage('column.license.date.of.issue'),
								name: 'Line-License.DateOfIssue',
								allowBlank: false,
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('column.license.expiration.date'),
								name: 'Line-License.ExpirationDate',
								allowBlank: false,
								type: 'date'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.parties'),
						bodyPadding: 0,
						items: [
							{
								type: 'editableGrid',
								name: 'Line-Parties.Line-Party',
								config: {
									columnsConfig: ALCDES_LINE_PARTY_COLUMN,
									fields: [
										{
											name: 'PartyType',
											type: 'combo',
											store: edi.stores.createSimpleInlineStore(['MF', 'IM'], function (id) {
												return edi.i18n.getMessage('party.type.' + id);
											}),
											forceSelection: true,
											allowBlank: false
										},
										{
											name: 'Name',
											maxLength: 175
										},
										{
											name: 'ILN',
											maxLength: 13,
											type: 'number',
											allowBlank: true,
											regex: edi.constants.VALIDATORS.GLN,
											maskRe: /\d/i,
											enforceMaxLength: true
										},
										{
											name: 'TaxID',
											allowBlank: false,
											maxLength: 35,
											enforceMaxLength: true
										},
										{
											name: 'TaxRegistrationReasonCode',
											maxLength: 35,
											enforceMaxLength: true
										}
									],
									limit: undefined,
									model: ALCDES_LINE_PARTY_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										title: edi.i18n.getMessage('product.partie.title'),
										height: 300,
										disablePaging: true
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.reference'),
						bodyPadding: 0,
						items: [
							{
								type: 'editableGrid',
								name: 'Line-Reference.ReferenceElements.Reference-Element',
								config: {
									columnsConfig: ALCDES_LINE_REFERENCE_COLUMN,
									fields: [
										{
											name: 'Reference-Id',
											maxLength: 150,
											isTextarea: true,
											allowBlank: false
										},
										{
											name: 'Reference-Type',
											type: 'hidden',
											value: 'ABT'
										}
									],
									limit: undefined,
									model: ALCDES_LINE_REFERENCE_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										title: edi.i18n.getMessage('product.reference.title'),
										height: 300,
										disablePaging: true
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.certificate'),
						bodyPadding: 0,
						items: [
							{
								type: 'editableGrid',
								name: 'Line-Certificate.Certificates.certificate',
								config: {
									columnsConfig: ALCDES_LINE_CERTIFICATE_COLUMN,
									fields: [
										{
											name: 'Number',
											maxLength: 35,
											allowBlank: false
										},
										{
											name: 'IssuingAuthority',
											maxLength: 75,
											allowBlank: false
										},
										{
											name: 'DateOfIssue',
											type: 'date',
											allowBlank: false
										},
										{
											name: 'ExpirationDate',
											title: edi.i18n.getMessage('column.license.expiration.date'),
											type: 'date',
											allowBlank: false
										}
									],
									limit: undefined,
									model: ALCDES_LINE_CERTIFICATE_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										title: edi.i18n.getMessage('product.certificate.title'),
										height: 300,
										disablePaging: true
									}
								}
							}
						]
					}
				]
			}
		});

		var totalWeight = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('column.total.weight'),
					input: createNumberField({
						columnWidth: 0.5,
						name: 'DespatchAdvice-Summary.TotalWeight',
						valueSrc: document ? document : alcdes,
						maxLength: 10,
						allowDecimals: true,
						decimalPrecision: 4
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		if (buyerId) {
			productsGrid.setPartnerId(buyerId);
		}

		var createButton = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'delivery', 'carrier', 'shipFrom'],
							[buyer, seller, delivery, carrier, shipFrom],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					save(values);
				}
			},
			isEdit
		);

		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES
				)
			)
		) {
			var createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'delivery', 'carrier', 'shipFrom'],
							[buyer, seller, delivery, carrier, shipFrom],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					afterSave = function (documentId, callback) {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							Ext.encode({}),
							null,
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.in.sending.process'
							),
							callback
						);
					};
					save(values);
				},
				null,
				isEdit
			);
		}

		var twoColumnsLayout = createTwoColumnsLayout(
			[headData, despatchDate, estimatedDeliveryDate, alcdesRemarks, seller, buyer, delivery, carrier, shipFrom],
			[
				orderNumber,
				orderDate,
				invoiceNumber,
				invoiceDate,
				WaybillNumber,
				WaybillDate,
				DespatchReferenceNumber,
				DespatchReferenceDate,
				modeOfTransport,
				carrierName,
				vehicleType,
				sealNumber,
				termsOfDelivery,
				contractInput,
				Capacity,
				Track,
				ConveyanceReferenceNumber,
				totalWeight,
				functionalDocumentCode
			],
			0.625
		);

		form = createForm({
			cls: 'edi-form document-add-form',
			autoScroll: true,
			bodyPadding: 10,
			items: [twoColumnsLayout, productsGrid],
			buttons: createButtonContainer({
				items: [createButton, createAndSendButton]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var valid = false;
		var total = productValues.products.length;
		var validCompanies = validateCompanySelectors();
		var hasInvalid = form.hasInvalidField();
		var validGrid = productsGrid.isValid();
		if (total && !hasInvalid && validCompanies && validGrid) {
			valid = true;
		}
		return valid;
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isBuyerValid = buyer.isValid(),
			isSellerValid = seller.isValid(),
			isDeliveryValid = delivery.isValid(),
			isCarrierValid = carrier.isValid(),
			isShipFromValid = shipFrom.isValid();

		return isBuyerValid && isSellerValid && isDeliveryValid && isCarrierValid && isShipFromValid;
	};

	/**
	 * @param {Object}    formValues    values from form
	 */
	var save = function (formValues) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = formValues || {};

		for (var j = 0; j < productValues.products.length; j++) {
			var lineReference = edi.utils.getObjectProperty(
				productValues.products[j],
				'Line-Reference.ReferenceElements.Reference-Element',
				true
			);
			for (var i = 0; i < lineReference.length; i++) {
				delete lineReference[i].id;
			}
		}
		productValues.TotalWeight = edi.utils.getObjectProperty(values, 'DespatchAdvice-Summary.TotalWeight');
		var document = createAlcDes({
			'DespatchAdvice-Header': values['DespatchAdvice-Header'],
			'DespatchAdvice-Transport': values['DespatchAdvice-Transport'],
			parties: createAlcDesParties({
				Buyer: values['Buyer'],
				Seller: values['Seller'],
				DeliveryPoint: values['DeliveryPoint'],
				Carrier: values['Carrier'],
				ShipFrom: values['ShipFrom']
			}),
			productValues: productValues
		});
		document = edi.document.actions.mergeDataBeforeSave(initialData, document, [
			{
				path: 'DespatchAdvice-Consignment.Packing-Sequence.Line'
			}
		]);
		edi.utils.clearEmptyValues(document);

		var stringified = Ext.encode(document);

		var success = edi.document.actions.createSaveSuccessHandler(
			moduleData,
			isEdit ? id : undefined,
			afterSave,
			isEdit
		);
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES
		);

		edi.document.actions.processDocument(
			buyerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
			parentId,
			isEdit ? id : undefined,
			success,
			failure,
			{
				data: stringified,
				number: edi.utils.getObjectProperty(values, 'DespatchAdvice-Header.DespatchAdviceNumber'),
				date: edi.utils.getObjectProperty(values, 'DespatchAdvice-Header.DespatchAdviceDate')
			}
		);
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Renders module
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var callback = function () {
			buyer.presetFromRelation(function () {
				'function' == typeof initCallBack ? initCallBack() : null;

				if (isBasedOnDoc && productValues.products.length) {
					edi.methods.taxRates.showInvalidProductsWarnIfNeeded(productValues.products, availableTaxRates);
				}
			});
		};
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();
		if (data && data.id) {
			id = data.id;
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			buyerOrg = data.toOrg;
			buyerId = buyerOrg.id;
			var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				edi.modulesHandler.removeModule(moduleData);
			});
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						initialData = data.data;
						var document = data.data;
						var form = createModuleForm(document);
						modulePanel.add(form);

						moduleData.tab.add(createModuleActionsPanel());
						moduleData.tab.add(modulePanel);
						callback();
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			var form = createModuleForm();
			modulePanel.add(form);

			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);

			callback();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
