// http://confluence.jira.lan:8090/pages/viewpage.action?pageId=56005157

const actionRulesForDsfReestrRouteModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {
			DOCUMENTS: [],
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
					ALL_STATE: true
				}
			]
		},
		INCOMING: {
			DOCUMENTS: [],
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
					ALL_STATE: true
				}
			]
		},
		INCOMING_FACTOR: {
			DOCUMENTS: [],
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
					ALL_STATE: true
				}
			]
		},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
					STATE: ['SENDER_REVIEW', 'COMPLETED']
				}
			],
			STATE: []
		},
		INCOMING: {
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
					STATE: ['SENDER_REVIEW', 'COMPLETED']
				}
			],
			STATE: []
		},
		INCOMING_FACTOR: {
			SINGLE_APPLY: [
				{
					DOCUMENT: edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE,
					STATE: ['SENDER_REVIEW', 'COMPLETED']
				}
			],
			STATE: []
		},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		OUTGOING: {
			STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.RECEIVER_INVOICE_RECEIVED_ACK,
				edi.constants.STATE.SENDER_INVOICE_RECEIVED_ACK_ADVISE
			],
			SINGLE_APPLY: []
		},
		LOOP: {
			STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.WAIT_SIGNATURE_STATUS10,
				edi.constants.STATE.WAIT_PARTNER_DECISION
			]
		},
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE],
			STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.PROCESSING_TITLES,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE],
				states: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.RECEIVER_REVIEW],
				allow(doc) {
					return doc.direction === edi.constants.DIRECTIONS.OUTGOING
						? doc.state === edi.constants.STATE.SENDER_REVIEW
						: doc.state === edi.constants.STATE.RECEIVER_REVIEW;
				}
			},
			{
				states: [
					edi.constants.STATE.DEBTOR_REVIEW,
					edi.constants.STATE.RECEIVER_REVIEW,
					edi.constants.STATE.READ
				],
				allow: function (documentData) {
					return documentData.needSignatures;
				}
			}
		]
	},
	REJECT: {
		INCOMING: {
			STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.WAIT_SIGNATURE,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER
			]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.SENT],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.COMPLETED],
				allow: false
			}
		]
	},
	ROUTE_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	ROUTE_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	CLARIFY: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [],
				states: [edi.constants.STATE.SIGNED_BY_CONSUMER, edi.constants.STATE.COMPLETED],
				directions: [edi.constants.DIRECTIONS.INCOMING],
				allow: false
			},
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive;
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.REJECTED
			]
		},
		LOOP: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.SELLER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.REJECTED],
				allow: false
			}
		]
	},
	EDIT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [
				{
					DOCUMENT: [],
					STATE: [edi.constants.STATE.READ]
				}
			]
		},
		permissionChanging: [],
		conditions: []
	},
	COMPLETE: {
		INCOMING: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.READ, edi.constants.STATE.DEBTOR_REVIEW, edi.constants.STATE.RECEIVER_REVIEW]
		},
		LOOP: {
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.SELLER_REVIEW, edi.constants.STATE.DRAFT]
		},
		OUTGOING: {
			SINGLE_APPLY: [],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	READ: {
		INCOMING: {
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		permissionChanging: [],
		conditions: []
	},
	READ_AND_COMPLETE: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: []
	},
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {
			SINGLE_APPLY: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENDER_REVIEW],
				allow: false
			}
		]
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED, edi.constants.STATE.SENT]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENT],
				allow: false
			}
		]
	},
	CONVERT_TO_DRAFT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.REJECTED],
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	COPY: {
		OUTGOING: {
			DOCUMENTS: []
		},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE],
				allow: false
			},
			{
				allow: function (checkOptions) {
					if (checkOptions.record.get('packageId')) {
						return edi.permissions.hasPermission('UNLINK_PACKAGE_OBJECT');
					} else {
						return (
							edi.permissions.hasPermission('LINK_PACKAGE_OBJECT') ||
							edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
						);
					}
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE
			]
		},
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					let annual = true;
					if (checkOptions.annulStatus) {
						annual = [
							edi.constants.STATE.CREATED,
							edi.constants.STATE.DELETED,
							edi.constants.STATE.DRAFT
						].some((it) => it === checkOptions.annulStatus);
					}
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive ? annual : false;
				}
			}
		],
		permissionChanging: [
			{
				change: function (actionPermission, checkOptions) {
					return checkOptions.docType.substr(0, 4) === 'DSF_' ? 'ANNUL_DSF' : actionPermission;
				}
			}
		]
	},
	CREATE_UKD: {
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED
			]
		},
		permissionChanging: [],
		conditions: []
	},
	RENOUNCEMENT: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.READ, edi.constants.STATE.RECEIVER_REVIEW]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (data) {
					let annulStatus = edi.utils.getAttributeByName(data.attributes, 'ANNUL_STATUS'),
						utochReason = edi.utils.getAttributeByName(data.attributes, 'UTOCH_REASON'),
						rawBpName = edi.utils.getAttributeByName(data.attributes, 'bpName');

					return !(
						utochReason ||
						!edi.permissions.hasPermission('CREATE_REFUSAL_TO_SIGN') ||
						(annulStatus && annulStatus === 'ACTIVE') ||
						!['SCHF', 'SCHFDOP'].some((it) => it === edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[rawBpName])
					);
				}
			}
		]
	},
	CREATE_CUD: {
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED
			]
		},
		permissionChanging: [],
		conditions: []
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE]: actionRulesForDsfReestrRouteModule
});

export { actionRulesForDsfReestrRouteModule };
