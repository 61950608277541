import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';

/**
 * Class for delfor details
 * @author Anastasiya Zholudeva
 */
Ext.namespace('edi.modules');
edi.modules['document.details.delfor'] = function () {
	var moduleData,
		valueMap,
		tableValueMap,
		tableSubValue,
		documentValues = {};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates dsf panel
	 * @returns {Object}
	 */
	var createDetailsPanel = function () {
		var docHeaderConfig = {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSumm: true,
			noUsage: true,
			noPackage: true,
			noAperakStatus: true
		};
		var headPanel = createDocumentHeaderPanel(moduleData.initData, docHeaderConfig);

		var headData1 = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.details.logset.number'),
					input: createLabel({
						columnWidth: 0.7333,
						text: documentValues.number,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.5,
					text: documentValues.date,
					valueLabel: true,
					srcFormat: 'Ymd'
				})
			]
		});

		var headData2 = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.details.logset.origin'),
					input: createLabel({
						columnWidth: 0.7333,
						text: documentValues.originNumber,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.6
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.4,
					text: documentValues.originDate,
					valueLabel: true,
					srcFormat: 'Ymd'
				})
			]
		});

		var retail = documentValues.retail;
		switch (retail) {
			case '1':
				retail = edi.i18n.getMessage('document.details.logset.retail1');
				break;
			case '2':
				retail = edi.i18n.getMessage('document.details.logset.retail2');
				break;
			case '3':
				retail = edi.i18n.getMessage('document.details.logset.retail3');
				break;
		}
		var retailField = createField({
			title: edi.i18n.getMessage('document.details.logset.retail'),
			containerConfig: {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			},
			input: createLabel({
				columnWidth: 0.86,
				text: retail,
				valueLabel: true
			})
		});

		var seller = createCompanySelector({
			title: 'document.seller',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			is_valid: true,
			fieldValues: moduleData.initData.data.toOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.toOrg)
				: null,
			readOnly: true
		});

		var buyer = createCompanySelector({
			title: 'document.buyer',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			is_valid: true,
			fieldValues: moduleData.initData.data.fromOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.fromOrg)
				: null,
			readOnly: true
		});

		var twoColumnsLayout = createTwoColumnsLayout([headData1, retailField, seller], [headData2, buyer], 0.5);

		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'EDI_DELFOR_LINE',
			gridColumnConfig: 'edi_delfor_lines',
			data: documentValues.lines,
			hasTotal: false,
			readOnly: true,
			totalsHandler: function (component) {
				component.callback(component.getValues());
			},
			modalFormConfig: {
				title: 'document.delfor.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.delfor.settings.tab'),
						items: [
							{
								title: edi.i18n.getMessage('column.line.number'),
								name: 'LineNumber'
							},
							{
								title: edi.i18n.getMessage('column.artikul.buyer'),
								name: 'BuyerCode'
							},
							{
								title: edi.i18n.getMessage('column.supplier.comment'),
								name: 'SupplierComment'
							},
							{
								title: edi.i18n.getMessage('column.order.calendar.code'),
								name: 'OrderCalenderCode',
								type: 'combo',
								store: edi.stores.initDelforCalendarCodeStore(),
								valueField: 'Delshchedulecode',
								displayField: 'Delshchedulename',
								disabled: true
							},
							{
								title: edi.i18n.getMessage('column.delivery.calendar.code'),
								name: 'DeliveryCalenderCode',
								type: 'combo',
								store: edi.stores.initDelforCalendarCodeStore(),
								valueField: 'Delshchedulecode',
								displayField: 'Delshchedulename',
								disabled: true
							},
							{
								title: edi.i18n.getMessage('column.exeption.calendar.code'),
								name: 'ExeptionCalenderCode',
								type: 'combo',
								store: edi.stores.initDelforCalendarCodeStore(),
								valueField: 'Delshchedulecode',
								displayField: 'Delshchedulename',
								disabled: true
							},
							{
								title: edi.i18n.getMessage('column.delivery.terms'),
								name: 'DeliveryTerms'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.delivery.point'),
						items: [
							{
								title: edi.i18n.getMessage('column.delivery.point.iln'),
								name: 'DeliveryPointIln'
							},
							{
								title: edi.i18n.getMessage('column.delivery.chain.name'),
								name: 'ChainName'
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.ship.from'),
						items: [
							{
								type: 'editableGrid',
								name: 'ShipmentPoint',
								config: {
									fields: [
										{
											name: 'iln',
											type: 'number'
										}
									],
									limit: undefined,
									model: 'EDI_DELFOR_SHIPMENTPOINT',
									gridConfig: {
										cls: 'modal-grid',
										columns: edi.columns.get('edi_delfor_shipmentpoint'),
										minHeight: 200,
										disablePaging: true
									}
								}
							}
						]
					}
				]
			}
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, productsGrid]
		});
	};
	/**
	 * Creates action panel above the data panel
	 */
	var createModuleActionsPanel = function () {
		var data = moduleData.initData.data;
		var actionsPanel = createActionsPanel();
		var excludeActions = {};

		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PACKAGING] = true;
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			moduleData: moduleData,
			excludeActions: excludeActions,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.delfor'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				REJECT: {
					methodAddOptions: {
						isDisableRejectModal: true
					}
				},
				SIGN: {
					methodAddOptions: {
						beforeInit: function (success) {
							if (edi.constants.STATE.SENT === data.state) {
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: data.id
										},
										true
									),
									'PUT',
									Ext.encode({}),
									success,
									null,
									function () {
										changeHandler(data);
									}
								);
							} else {
								success();
							}
						}
					}
				}
			}
		});
		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					var documentData = data.data;
					valueMap = {
						number: 'bgm.c106.e1004',
						date: 'dtm.c507[e2005==137]e2380',
						originNumber: 'sg1[rff.c506.e1153==ALM]rff.c506.e1154',
						originDate: 'sg1[dtm.c507.e2005==171]dtm.c507.e2380',
						retail: 'sg1[rff.c506.e1153==PB]rff.c506.e1154',
						seller: 'sg2.nad[e3035==SU]c082',
						buyer: 'sg2.nad[e3035==BY]c082'
					};
					tableValueMap = {
						LineNumber: 'sg12.lin.e1082',
						BuyerCode: 'sg12.pia.c212.e7140',
						SupplierComment: 'sg12.ftx.c108.e4440',
						OrderCalenderCode: 'sg12.sg13[rff.c506.e1153==AAJ]rff.c506.e1154',
						DeliveryCalenderCode: 'sg12.sg13[rff.c506.e1153==AAN]rff.c506.e1154',
						ExeptionCalenderCode: 'sg12.sg13[rff.c506.e1153==EXC]rff.c506.e1154',
						DeliveryTerms: 'sg12.sg17.sg18.qty.0.c186.e6060',
						DeliveryPointIln: 'sg7.nad.c082.e3039',
						ChainName: 'sg7.ftx.c108.e4440',
						ShipmentPoint: 'sg12.sg22'
					};
					tableSubValue = {
						iln: 'nad.c082.e3039'
					};
					Object.entries(valueMap).forEach(function ([name, path]) {
						documentValues[name] = edi.utils.getObjectPropertyEx(documentData, path);
					});
					documentValues.lines = [];
					var lines = edi.utils.getObjectProperty(documentData, 'sg6');
					lines?.forEach(function (line) {
						var lineValues = {};
						Object.entries(tableValueMap).forEach(function ([name, path]) {
							lineValues[name] = edi.utils.getObjectPropertyEx(line, path);
						});
						documentValues.lines.push(lineValues);
					});
					modulePanel.add(createDetailsPanel(documentData));
					moduleData.tab.add(createModuleActionsPanel(documentData));
					moduleData.tab.add(modulePanel);
					if ('function' == typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
