const certificatesAPI = {
	GET: edi.rest.prfx.c + 'certificates',
	WITH_CHECK: {
		POST: edi.rest.prfx.c + 'user/certificates/withCheck'
	},
	POST: edi.rest.prfx.c + 'user/certificates',
	CONTENT: {
		GET: edi.rest.prfx.c + 'certificates/{id}'
	},
	FULL_NAME: {
		GET: edi.rest.prfx.c + 'certificates/find-by-name'
	},
	DEACTIVATE: {
		PUT: edi.rest.prfx.c + 'user/certificates/deactivate/{id}'
	},
};

export { certificatesAPI };
