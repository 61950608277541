Ext.define('edi.ToastManager', {
	baseToastContainerCls: 'edi-ediweb-toasts-container',
	baseToastCls: 'edi-ediweb-toast-wrapper',
	closeButtonBaseCls: 'edi-ediweb-toast__close-button',

	parentContainer: null, //!!! should be a dom element !!!
	toastsContainer: null, //creates in constructor
	queue: [],
	toastsMap: {},
	_removeFromDomTimeout: 5000, //delay for close animations

	defaultToastConfig: {
		//cls: 'some_class'
		closable: true,
		autoCloseTimeout: 5000,
		content: "It's a toast",
		delayBeforeShow: 0
		//clickHandler: () => {}
	},

	constructor(externalCfg) {
		this.setOwnConfig(externalCfg);
		this.callParent();
		this.finishInit();
	},

	setOwnConfig(externalCfg) {
		Ext.merge(this, externalCfg);
	},

	finishInit() {
		let me = this;
		if (!me.parentContainer) {
			me.parentContainer = document.querySelector('.x-panel.edi-main-modules-panel') || document.body;
		}
		me.createToastsContainer();
	},

	createToastsContainer() {
		let me = this;
		let newContainer = document.createElement('div');
		newContainer.id = `${me.baseToastContainerCls}__${Ext.id()}`;
		newContainer.classList.add(me.baseToastContainerCls);
		if (me.cls) {
			newContainer.classList.add(me.cls);
		}
		me.toastsContainer = newContainer;
		me.parentContainer.appendChild(newContainer);
	},

	closeToast(toast) {
		if (!toast) {
			return;
		}

		clearTimeout(toast.autoCloseTimer);

		let me = this;
		delete me.toastsMap[toast.id];
		toast.classList.add(`${me.baseToastCls}-animate-close`);
		setTimeout(function () {
			me.toastsContainer.removeChild(toast);
		}, me._removeFromDomTimeout);
	},

	onCloseBtnClick(btn, toast, manager, e) {
		e.stopPropagation();
		let mgr = manager || this;
		mgr.closeToast(toast);
	},

	makeClosable(toast) {
		if (typeof toast?.append !== 'function') {
			return;
		}
		let me = this;
		let closeBtn = document.createElement('a');
		closeBtn.id = `${me.baseToastCls}__close-button__${Ext.id()}`;
		closeBtn.classList.add(me.closeButtonBaseCls);
		closeBtn.addEventListener('click', function (e) {
			me.onCloseBtnClick(closeBtn, toast, me, e);
		});

		toast.appendChild(closeBtn);
	},

	makeAutoClosable(toast, timeout) {
		if (!toast || !timeout) {
			return;
		}
		let me = this;
		toast.autoCloseTimer = setTimeout(function () {
			me.closeToast(toast);
		}, +timeout);
	},

	showToast(config) {
		let me = this;
		let cfg = Ext.merge({}, me.defaultToastConfig, config);

		if (typeof cfg.isAllowed === 'function' && cfg.isAllowed() === false) {
			return;
		}

		let newToast = document.createElement('div');
		newToast.id = `${me.baseToastCls}__${Ext.id()}`;
		newToast.classList.add(me.baseToastCls);
		if (cfg.cls) {
			newToast.classList.add(cfg.cls);
		}

		if (cfg.content) {
			newToast.innerHTML = cfg.content;
		}

		if (cfg.closable) {
			me.makeClosable(newToast);
		}

		newToast.closeToast = function () {
			me.closeToast(newToast);
		};

		if (typeof cfg.clickHandler === 'function') {
			newToast.addEventListener('click', function (e) {
				//задержка нужна, что бы синтетические ивенты и обработчики Экста (для кнопок например)
				//могли остановить событие, если им нужно
				setTimeout(function () {
					if (!e.stopped) {
						cfg.clickHandler(e, newToast, me);
					}
				}, 10);
			});
		}

		let appendToast = function () {
			if (cfg.autoCloseTimeout) {
				me.makeAutoClosable(newToast, cfg.autoCloseTimeout);
			}

			const firstChild = me?.toastsContainer?.firstChild ?? null;
			firstChild && firstChild.before ? firstChild.before(newToast) : me.toastsContainer.append(newToast);
			me.toastsMap[newToast.id] = newToast;
			if (typeof cfg.afterAppend === 'function') {
				cfg.afterAppend(newToast, cfg.values);
			}
		};

		if (Number.isFinite(cfg.delayBeforeShow) && cfg.delayBeforeShow > 0) {
			setTimeout(appendToast, cfg.delayBeforeShow);
		} else {
			appendToast();
		}

		return newToast;
	},

	clearAll() {
		let me = this;
		Object.values(me.toastsMap).forEach((toast) => me.closeToast(toast));
	}
});

const initToastManager = function (cfg) {
	return Ext.create('edi.ToastManager', cfg);
};

export { initToastManager };
