import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { GRID_SHARING_COLUMN_CONFIG_NAME } from './columns';

const sharingGridMethods = {
	/**
	 * Restores archived document
	 * @param    {Object}    record
	 * @param    {Object}    moduleData
	 */
	openDetail: function (record, moduleData) {
		const recordData = record.getData();
		const failure = edi.document.actions.defaultFailureHandler(
			moduleData.tab,
			'documents.archive.restore.document.error',
			function () {
				moduleData.tab.setLoading(false);
			}
		);
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SHARING.HEADER.GET,
				{
					containerDocId: recordData.id
				},
				true
			),
			'GET',
			undefined,
			function (data) {
				if (data && data.data) {
					const modData = data.data;
					edi.document.actions.openDetailsModule(modData.type, modData, {
						docHeaderUri: edi.rest.services.DOCUMENTS.SHARING.HEADER.GET,
						docDetailsUri: edi.rest.services.DOCUMENTS.SHARING.CONTENT.GET,
						docHeaderUriBatch: edi.rest.services.DOCUMENTS.SHARING.BATCH.POST,
						docDetailsUriBatch: edi.rest.services.DOCUMENTS.SHARING.BATCH_CONTENT.POST,
						docLinkedUri: edi.rest.services.DOCUMENTS.SHARING.LINKED.GET,
						docLinkedChildrenUri: edi.rest.services.DOCUMENTS.SHARING.LINKED.CHILDREN.GET,
						docLinkedTreeUri: edi.rest.services.DOCUMENTS.SHARING.LINKED_TREE.GET,
						docExportUri: edi.rest.services.DOCUMENTS.SHARING.EXPORT.GET,
						docSignersUri: edi.rest.services.DOCUMENTS.SHARING.SIGNERS.GET,
						docDataUri: edi.rest.services.DOCUMENTS.SHARING.DOCDATA.GET,
						containerDocId: recordData.id,
						isDocumentSharing: true
					});
					moduleData.tab.setLoading(false);
				} else {
					failure(data);
				}
			},
			failure
		);
	},
	/**
	 * Creates action column
	 * @param	{Object}    moduleData
	 * @returns	{Object}	action column config
	 */
	createActionColumn: function (moduleData) {
		const items = [];
		items.push({
			glyph: edi.constants.ICONS.DETAILS,
			handler: function (_grid, _rowIndex, _colindex, _actionItem, _event, record) {
				sharingGridMethods.openDetail(record, moduleData);
			},
			isActionDisabled: function (_view, _rowIndex, _colIndex, _item, record) {
				return !(
					edi.permissions.hasPermission('CLIENT_READ_CONTEXT_SHARED_ACCESS_DOCUMENT') &&
					record.get('docId').archived === 'false'
				);
			}
		});
		items.push({
			glyph: edi.constants.ICONS.RESTORE,
			tooltip: edi.i18n.getMessage('documents.archive.restore.document'),
			handler: function (_grid, _rowIndex, _colindex, _actionItem, _event, record) {
				sharingGridMethods.restoreDocument(moduleData, record);
			},
			isActionDisabled: function (_view, _rowIndex, _colIndex, _item, record) {
				return !(
					edi.permissions.hasPermission('RESTORING_ARCHIVED_DOCUMENT') &&
					record.get('docId').archived === 'true'
				);
			}
		});
		return createActionsColumnConfig({
			align: 'center',
			items: items
		});
	},
	/**
	 * Restores archived document
	 * @param    {Object}    moduleData
	 * @param    {Object}    record
	 */
	restoreDocument: function (moduleData, record) {
		const recordData = record.getData();
		moduleData.tab.setLoading();
		const failure = edi.document.actions.defaultFailureHandler(
			moduleData.tab,
			'documents.archive.restore.document.error',
			function () {
				moduleData.tab.setLoading(false);
			}
		);
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SHARING.RESTORE.GET,
				{
					containerDocId: recordData.id
				},
				true
			),
			'GET',
			undefined,
			function () {
				sharingGridMethods.openDetail(record, moduleData);
			},
			failure
		);
	},
	/**
	 * Initializes grid
	 * @param	{Object}	moduleData
	 * @param	{Object}	options
	 * @returns  {Object}            Instance of grid component
	 */
	createGridCmp: function (moduleData, options) {
		const columns = edi.columns.get(GRID_SHARING_COLUMN_CONFIG_NAME);
		const actionColumn = sharingGridMethods.createActionColumn(moduleData);
		actionColumn ? columns.push(actionColumn) : null;
		return createGrid({
			saveSorters: options?.saveSorters,
			savedSortersName: options?.savedSortersName,
			proxyConfig: {
				type: 'ajax',
				url: null
			}, //  get store reload data in line 212
			storeConfig: {
				model: edi.models.getModel('DOCUMENTS_SHARING_CONTEXT'),
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: false
			},
			gridConfig: {
				cls: 'edi-documents-grid',
				columns: columns,
				listeners: {
					celldblclick: function (_view, _td, _cellIndex, record) {
						if (edi.permissions.hasPermission('CLIENT_READ_CONTEXT_SHARED_ACCESS_DOCUMENT')) {
							sharingGridMethods.openDetail(record, moduleData);
						}
					}
				},
				padding: 0,
				region: 'center',
				border: 1
			},
			viewConfig: {
				emptyTextTplOptions: {
					enabled: true,
					title: edi.i18n.getMessage('grid.empty.documents'),
					buttonText: edi.i18n.getMessage('grid.empty.expand.filter'),
					buttonClickHandler: function () {
						options?.filterForm?.floatCollapsedPanel();
					}
				}
			}
		});
	}
};

export { sharingGridMethods };
