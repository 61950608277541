const actionRulesForLegacyOrderRespModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		conditions: []
	},
	REPROCESS: {
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_LEGACY_DOCUMENT_REPROCESS';
				}
			}
		],
		INCOMING: {},
		OUTGOING: {},
		LOOP: {}
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {}
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		conditions: []
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		conditions: []
	},
	SIGN: {},
	REJECT: {
		OUTGOING: {
			DOCUMENTS: []
		},
		INCOMING: {
			STATE: [edi.constants.STATE.SENT]
		},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		conditions: [],
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_LEGACY_ORDER_RESP_TO_APERAK';
				}
			}
		]
	},
	CLARIFY: {
		conditions: []
	},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [],
			STATE: []
		},
		conditions: []
	},
	EDIT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	COMPLETE: {
		INCOMING: {
			STATE: [edi.constants.STATE.READ]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		OUTGOING: {
			DOCUMENTS: []
		},
		conditions: [
			{
				allow: function (checkOptions) {
					return !(
						checkOptions.state === edi.constants.STATE.READ &&
						(checkOptions.businessState === edi.constants.STATE.ACCEPTED ||
							checkOptions.businessState === edi.constants.STATE.REJECTED_BY_RECEIVER)
					);
				}
			}
		]
	},
	READ: {},
	READ_AND_COMPLETE: {
		INCOMING: {
			DOCUMENTS: edi.constants.LEGACY_ORDER_RESP,
			STATE: [edi.constants.STATE.SENT]
		},
		conditions: []
	},
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			SINGLE_APPLY: []
		},
		conditions: [
			{
				allow: function (checkOptions) {
					return edi.utils.getAttributeByName(checkOptions.record.get('attributes'), 'isBlocked') !== 'true';
				}
			}
		]
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: []
	},
	CONVERT_TO_DRAFT: {},
	COPY: {
		OUTGOING: {},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {}
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		conditions: [
			{
				allow: function (checkOptions) {
					if (checkOptions.record.get('packageId')) {
						return edi.permissions.hasPermission('UNLINK_PACKAGE_OBJECT');
					} else {
						return (
							edi.permissions.hasPermission('LINK_PACKAGE_OBJECT') ||
							edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
						);
					}
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {},
	REPROCESS_MANUALLY: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {}
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP]: actionRulesForLegacyOrderRespModule
});

export { actionRulesForLegacyOrderRespModule };
