import { createToast } from '@UIkit/components/toast';

const showNeutralToast = function (title, html, options, callback) {
	options = options || {};

	createToast(
		Ext.merge(
			{
				titleContent: title,
				content: html,
				callback: callback
			},
			options
		)
	);
};

const showErrorToast = function (title, html, options, callback) {
	options = options || {};

	createToast(
		Ext.merge(
			{
				type: 'error',
				titleContent: title,
				content: html,
				callback: callback
			},
			options
		)
	);
};

const showSuccessToast = function (title, html, options, callback) {
	options = options || {};

	createToast(
		Ext.merge(
			{
				type: 'success',
				titleContent: title,
				content: html,
				callback: callback
			},
			options
		)
	);
};

const showWarningToast = function (title, html, options, callback) {
	options = options || {};

	createToast(
		Ext.merge(
			{
				type: 'warning',
				titleContent: title,
				content: html,
				callback: callback
			},
			options
		)
	);
};

const showInfoToast = function (title, html, options, callback) {
	options = options || {};

	createToast(
		Ext.merge(
			{
				type: 'info',
				titleContent: title,
				content: html,
				callback: callback
			},
			options
		)
	);
};

export { showNeutralToast, showErrorToast, showSuccessToast, showWarningToast, showInfoToast };
