import { createPanel, createFieldBlock, createMaxWidthContainerForDetail } from '@UIkit/components/panels';
import './DocumentHeaderPanel.scss';
import {
	createAperakStatusField,
	createCreationDateField,
	createCrptStatusField,
	createDocVersionField,
	createEdemStatusField,
	createFunctionCodeField,
	createIdField,
	createLinkedDocsField,
	createLogField,
	createModifyDateField,
	createNotifyField,
	createPackageField,
	createReasonFields,
	createReceiptsField,
	createSignaturesField,
	createStateField,
	createTotalSummField,
	createUsageField,
	getPoaErrorsField,
	createWarningAndErrorDocsField
} from './fields';

Ext.define('UI.components.DocumentHeaderPanel', {
	extend: 'Ext.panel.Panel',
	baseCls: 'edi-document-header-panel',

	items: [],
	documentData: {},
	headerFields: [],

	FIELDS_ON_ROW: 6,
	DEFAULT_GRID_FIELD_CONFIG: {
		col: 2
	},
	DEFAULT_LAYOUT_FIELD_CONFIG: {
		type: 'hbox',
		align: 'middle'
	},
	DEFAULT_FIELD_ORDER: 1000,

	noCreate: false,
	noModify: false,
	isCrptStatus: false,
	noStatus: false,
	noSumm: false,
	noSignatures: false,
	noLinked: false,
	isReceipts: false,
	noLog: false,
	noId: false,
	noUsage: false,
	isLegacy: false,
	showAnnulLog: false,
	isClarify: false,
	noPackage: false,
	rejectReasonText: '',
	noRejectReason: false,
	noPoaState: false,
	noAperakStatus: false,

	beforeInit() {
		this.collectHeaderFields();
		this.renderFields();
	},

	afterInit() {},

	initComponent: function () {
		this.beforeInit();
		this.callParent();
		this.afterInit();
	},

	filterAndSortFieldsByOrder: function (fields) {
		const me = this;
		const resultFields = fields.filter((field) => !!field);
		return resultFields.sort(
			(fieldA, FieldB) => (fieldA.order || me.DEFAULT_FIELD_ORDER) - (FieldB.order || me.DEFAULT_FIELD_ORDER)
		);
	},

	/**
	 * Returns an array of additional fields
	 * 	 * @returns {*[]} An array of additional fields
	 */
	getAdditionFields: function () {
		return [];
	},

	collectHeaderFields: function () {
		const me = this;
		const headerFields = [];
		headerFields.push(
			me.getCreationDateField(),
			me.getModifyDateField(),
			me.getStateField(),
			me.getCrptStatusField(),
			me.getTotalSummField(),
			me.getSignaturesField(),
			me.getLinkedDocsField(),
			me.getReceiptsField(),
			me.getLogField(),
			me.getIdField(),
			me.getUsageField(),
			me.getPackageField(),
			me.getFunctionCodeField(),
			me.getDocVersionField(),
			me.getNotifyField(),
			me.getEdemStatusField(),
			me.getPoaErrorsField(),
			me.getWarningAndErrorDocsField(),
			...me.getAdditionFields()
		);
		me.headerFields = me.filterAndSortFieldsByOrder(headerFields);
	},

	renderFields: function () {
		const me = this;
		const headerFields = Ext.clone(me.headerFields);
		const count = Math.ceil(headerFields.length / me.FIELDS_ON_ROW);
		const items = [];
		for (let i = 0; i < count; i++) {
			items.push(me.createRow(headerFields.splice(0, me.FIELDS_ON_ROW)));
		}
		me.items = [
			createMaxWidthContainerForDetail({
				items: items.concat(me.getReasonFields(), me.getAperakStatusField()).filter((item) => !!item)
			})
		];
	},

	createRow: function (items) {
		return createPanel({
			layout: {
				type: 'grid',
				gap: [22, 24]
			},
			items: items
		});
	},

	getCreationDateField: function () {
		const me = this;
		return !me.noCreate ? createCreationDateField(me) : null;
	},

	getModifyDateField: function () {
		const me = this;
		return !me.noModify ? createModifyDateField(me) : null;
	},

	getCrptStatusField: function () {
		const me = this;
		const actualCrptState =
			typeof edi.renderers.utils.convertAttributesToCrptStatus === 'function'
				? edi.renderers.utils.convertAttributesToCrptStatus(me.documentData.attributes, me.documentData.type)
				: null;
		return me.isCrptStatus && actualCrptState ? createCrptStatusField(me, actualCrptState) : null;
	},

	getStateField: function () {
		const me = this;
		return !me.noStatus ? createStateField(me) : null;
	},

	getTotalSummField: function () {
		const me = this;
		const totalSumm = me.documentData.totalSumm;
		return !me.noSumm && totalSumm ? createTotalSummField(me, totalSumm) : null;
	},

	getSignaturesField: function () {
		const me = this;
		return !me.noSignatures ? createSignaturesField(me) : null;
	},

	getLinkedDocsField: function () {
		const me = this;
		const isVisible =
			!me.noLinked &&
			((me.documentData.toOrg && me.documentData.toOrg.id == edi.core.getUserOrgID()) ||
				(me.documentData.fromOrg && me.documentData.fromOrg.id == edi.core.getUserOrgID()));
		return isVisible ? createLinkedDocsField(me) : null;
	},

	getReceiptsField: function () {
		const me = this;
		return me.isReceipts ? createReceiptsField(me) : null;
	},

	getLogField: function () {
		const me = this;
		return !me.noLog && edi.permissions.hasPermission('READ_LOG') ? createLogField(me) : null;
	},

	getIdField: function () {
		const me = this;
		return !me.noId ? createIdField(me) : null;
	},

	getUsageField: function () {
		const me = this;
		return !me.noUsage && edi.permissions.hasPermission('PROCESS_DOCUMENT_USAGE_MODULES')
			? createUsageField(me)
			: null;
	},

	getPackageField: function () {
		const me = this;
		return !me.noPackage && me.documentData.packageId && edi.permissions.hasPermission('READ_PACKAGE_OBJECT')
			? createPackageField(me)
			: null;
	},

	getFunctionCodeField: function () {
		const me = this;
		return me.isLegacy && edi.constants.THEME !== 'AB' ? createFunctionCodeField(me) : null;
	},

	getDocVersionField: function () {
		const me = this;
		return me.isLegacy && edi.constants.THEME !== 'AB' ? createDocVersionField(me) : null;
	},

	getNotifyField: function () {
		const me = this;
		return me.showAnnulLog &&
			edi.permissions.hasPermission('CLIENT_READ_ANNUL_LOG') &&
			edi.constants.DOCS_WITH_ANNUL_LOG.includes(me.documentData.type) &&
			!!edi.utils.getAttributeByName(me.documentData.attributes, 'ANNUL_REASON')
			? createNotifyField(me)
			: null;
	},

	getEdemStatusField: function () {
		const me = this;
		let edemStatusText;
		if (typeof edi?.renderers?.utils?.showEdemStatus === 'function') {
			const edemStatusElems = edi.renderers.utils.showEdemStatus(
				me.documentData?.type || me.documentData?.data?.docType, // EW override
				me.documentData?.attributes,
				false
			);
			edemStatusText = edemStatusElems.text;
		}
		return edemStatusText ? createEdemStatusField(me, edemStatusText) : null;
	},

	getPoaErrorsField: function () {
		const me = this;
		const poaState = edi.utils.getAttributeByName(me.documentData.attributes, 'PoaState');
		return !me.noPoaState && !!poaState ? getPoaErrorsField(me, poaState, me.documentData.id) : null;
	},

	getReasonFields: function () {
		const me = this;
		return !me.noRejectReason ? createReasonFields(me) : null;
	},

	getAperakStatusField: function () {
		const me = this;
		const aperakCode = edi.utils.getAttributeByName(me.documentData.attributes, 'APERAK_CODE');
		const aperakText = edi.utils.getAttributeByName(me.documentData.attributes, 'APERAK_TEXT');

		return !me.noAperakStatus && aperakCode && aperakText
			? createAperakStatusField(me, aperakCode, aperakText)
			: null;
	},

	getWarningAndErrorDocsField: function () {
		const me = this;
		const attributes = me.documentData.attributes;
		const errorMessage =
			edi.utils.getAttributeByName(attributes, 'errorMessage') ||
			edi.utils.getAttributeByName(attributes, 'blockingMessage');
		const warningMessage = edi.utils.getAttributeByName(attributes, 'warningMessage');

		return errorMessage || warningMessage
			? createWarningAndErrorDocsField(me, errorMessage || warningMessage, !!errorMessage)
			: null;
	}
});

const createDocumentHeaderPanel = (initData = {}, config = {}) => {
	const documentData = config.objectData || initData.data || {};
	return new UI.components.DocumentHeaderPanel(Object.assign({}, config, { documentData, initData }));
};

export { createDocumentHeaderPanel };
