import { checkExecutorMatchesApprovalStage, getCurrentExecutor } from './methods';

const actionRulesForApprovalDocuments = {
	QUICK_ANSWER_CONFIRM: {},
	QUICK_ANSWER_REJECT: {},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {},
	EXPORT_GROUP: {
		OUTGOING: {},
		LOOP: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		INCOMING: {
			STATE: [edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL]
		},
		OUTGOING: {
			STATE: [edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL]
		},
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_APPROVAL_DOCUMENTS_SIGN';
				}
			}
		],
		conditions: [
			{
				allow: function (documentData) {
					return checkExecutorMatchesApprovalStage(documentData.parent, 'SIGNING_REFUSAL');
				}
			}
		]
	},

	REJECT: {
		INCOMING: {
			STATE: [edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL]
		},
		OUTGOING: {
			STATE: [edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL]
		},
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_APPROVAL_DOCUMENTS_REJECT';
				}
			}
		],
		conditions: [
			{
				allow: function (documentData) {
					return (
						checkExecutorMatchesApprovalStage(documentData.parent, 'SIGNING_REFUSAL') ||
						checkExecutorMatchesApprovalStage(documentData.parent, 'APPROVAL_REFUSAL')
					);
				}
			}
		]
	},
	ROUTE_REJECT: {},
	ROUTE_CONFIRM: {},
	CLARIFY: {},
	DELETE: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_APPROVAL_DOCUMENTS_DELETE';
				}
			}
		],
		conditions: []
	},
	EDIT: {
		OUTGOING: {
			DOCUMENTS: edi.constants.DSF_WITH_APPROVE,
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			DOCUMENTS: edi.constants.DSF_WITH_APPROVE,
			STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_APPROVAL_DOCUMENTS_EDIT';
				}
			}
		],
		conditions: []
	},
	READ: {
		OUTGOING: {
			STATE: [edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL]
		},
		LOOP: {
			STATE: [edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL]
		},
		INCOMING: {
			STATE: [edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL]
		},
		permissionChanging: [
			{
				change: function () {
					return 'CLIENT_APPROVAL_DOCUMENTS_COMPLETE';
				}
			}
		],
		conditions: [
			{
				allow: function (documentData) {
					let signers = (documentData.parent?.signers || []).filter((s) => {
						return s.stage?.stageNumber === documentData.parent?.currentStageNumber;
					});
					if (documentData?.docType === edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES) {
						if (documentData.direction === 'OUTGOING') {
							return true;
						} else {
							return (
								!checkExecutorMatchesApprovalStage(documentData.parent, 'APPROVAL_REFUSAL') &&
								signers.length > 0
							);
						}
					} else {
						return false;
					}
				}
			}
		]
	},
	COMPLETE: {},
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		conditions: []
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {},
	CONVERT_TO_DRAFT: {},
	COPY: {},
	PACKAGING: {},
	ARCHIVE_RESTORE: {},
	ANNUL: {},
	CREATE_UKD: {},
	RENOUNCEMENT: {},
	CREATE_CUD: {}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS]: actionRulesForApprovalDocuments,
	[edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES]: actionRulesForApprovalDocuments
});

export { actionRulesForApprovalDocuments };
