import { orderMethods } from './methods';

export const orderSelectors = {
	getBuyerFieldsMap: function () {
		const fieldsMapPrefix = 'Buyer';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			license_type: `${fieldsMapPrefix}.License.Type`,
			license_name: `${fieldsMapPrefix}.License.Name`,
			license_series: `${fieldsMapPrefix}.License.Series`,
			license_number: `${fieldsMapPrefix}.License.Number`,
			license_issuing_authority: `${fieldsMapPrefix}.License.IssuingAuthority`,
			license_date_of_issue: `${fieldsMapPrefix}.License.DateOfIssue`,
			license_expiration_date: `${fieldsMapPrefix}.License.ExpirationDate`,
			operator_name: `${fieldsMapPrefix}.OperatorDetails.Name`,
			operator_phone: `${fieldsMapPrefix}.OperatorDetails.TelephoneNumber`,
			operator_email: `${fieldsMapPrefix}.OperatorDetails.E-mail`,
			id: true,
			bank_acc: `${fieldsMapPrefix}.AccountNumber`
		};
	},
	getBuyerModalCong: function () {
		return {
			title: edi.i18n.getMessage('documents.order.buyer'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn: edi.selectors.defaults.company_inn
				},
				address: {
					category: edi.selectors.defaults.category,
					country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
						maxLength: 140,
						fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
							mandatory: false,
							allowBlank: true
						})
					}),
					area: Object.assign({}, edi.selectors.defaults.area, {
						name: 'addr_area',
						maxLength: 140
					}),
					city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
					zip: edi.selectors.defaults.zip,
					street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
					locality: Object.assign({}, edi.selectors.defaults.locality, {
						name: 'addr_locality',
						maxLength: 140
					}),
					home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
					block: Object.assign({}, edi.selectors.defaults.block, {
						name: 'addr_block',
						maxLength: 20
					}),
					flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
					addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
				},
				bank: {
					bank_acc: edi.selectors.defaults.bank_acc_select
				},
				contact: {
					operator_name: edi.selectors.defaults.operator_name,
					operator_phone: edi.selectors.defaults.operator_phone,
					operator_email: edi.selectors.defaults.operator_email
				}
			}
		};
	},
	getSellerFieldsMap: function () {
		const fieldsMapPrefix = 'Seller';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,

			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,

			license_type: `${fieldsMapPrefix}.License.Type`,
			license_name: `${fieldsMapPrefix}.License.Name`,
			license_series: `${fieldsMapPrefix}.License.Series`,
			license_number: `${fieldsMapPrefix}.License.Number`,
			license_issuing_authority: `${fieldsMapPrefix}.License.IssuingAuthority`,
			license_date_of_issue: `${fieldsMapPrefix}.License.DateOfIssue`,
			license_expiration_date: `${fieldsMapPrefix}.License.ExpirationDate`,

			operator_name: `${fieldsMapPrefix}.OperatorDetails.Name`,
			operator_phone: `${fieldsMapPrefix}.OperatorDetails.TelephoneNumber`,
			operator_email: `${fieldsMapPrefix}.OperatorDetails.E-mail`,

			representative_name: `${fieldsMapPrefix}.SalesRepresentative.Name`,
			representative_phone: `${fieldsMapPrefix}.SalesRepresentative.TelephoneNumber`,
			representative_email: `${fieldsMapPrefix}.SalesRepresentative.E-mail`,

			code_by_buyer: `${fieldsMapPrefix}.CodeByBuyer`,
			company_division: `${fieldsMapPrefix}.Division`,
			id: true,

			bank_acc: `${fieldsMapPrefix}.AccountNumber`
		};
	},
	getSellerModalConf: function () {
		return {
			title: edi.i18n.getMessage('documents.order.seller'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn: edi.selectors.defaults.company_inn
				},
				address: {
					category: edi.selectors.defaults.category,
					country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
						maxLength: 140,
						fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
							mandatory: false,
							allowBlank: true
						})
					}),
					area: Object.assign({}, edi.selectors.defaults.area, {
						name: 'addr_area',
						maxLength: 140
					}),
					city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
					zip: edi.selectors.defaults.zip,
					street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
					locality: Object.assign({}, edi.selectors.defaults.locality, {
						name: 'addr_locality',
						maxLength: 140
					}),
					home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
					block: Object.assign({}, edi.selectors.defaults.block, {
						name: 'addr_block',
						maxLength: 20
					}),
					flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
					addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
				},
				bank: {
					bank_acc: edi.selectors.defaults.bank_acc_select
				},
				contact: {
					operator_name: edi.selectors.defaults.operator_name,
					operator_phone: edi.selectors.defaults.operator_phone,
					operator_email: edi.selectors.defaults.operator_email,
					representative_name: edi.selectors.defaults.representative_name,
					representative_phone: edi.selectors.defaults.representative_phone,
					representative_email: edi.selectors.defaults.representative_email
				},
				tabConfig: {
					title: edi.i18n.getMessage('product.tab.additional.data'),
					code_by_buyer: {
						title: 'column.code',
						name: 'code_by_buyer',
						emptyText: 'column.code',
						maxLength: 35
					},
					company_division: {
						type: 'combo',
						title: 'order.org.division',
						fieldConfig: {
							store: edi.stores.createInlineStore(orderMethods.getCompanyDivisionStoreData(), 'SIMPLE'),
							name: 'company_division'
						}
					}
				}
			}
		};
	},
	getShipFromFieldsConf: function () {
		const fieldsMapPrefix = 'ShipFrom';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			license_type: `${fieldsMapPrefix}.License.Type`,
			license_name: `${fieldsMapPrefix}.License.Name`,
			license_series: `${fieldsMapPrefix}.License.Series`,
			license_number: `${fieldsMapPrefix}.License.Number`,
			license_issuing_authority: `${fieldsMapPrefix}.License.IssuingAuthority`,
			license_date_of_issue: `${fieldsMapPrefix}.License.DateOfIssue`,
			license_expiration_date: `${fieldsMapPrefix}.License.ExpirationDate`,
			contact_name: `${fieldsMapPrefix}.ContactPerson`,
			contact_phone: `${fieldsMapPrefix}.PhoneNumber`,
			contact_email: `${fieldsMapPrefix}.E-mail`,
			contact_web: `${fieldsMapPrefix}.Web`,
			id: true,
			bank_acc: `${fieldsMapPrefix}.AccountNumber`
		};
	},
	getShipFromModalConf: function () {
		return {
			title: edi.i18n.getMessage('document.ship.from'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: Object.assign({}, edi.selectors.defaults.company_gln, { allowBlank: true }),
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
						regex: null,
						maxLength: 35
					})
				},
				address: {
					category: edi.selectors.defaults.category,
					country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
						maxLength: 140,
						fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
							mandatory: false,
							allowBlank: true
						})
					}),
					area: Object.assign({}, edi.selectors.defaults.area, {
						name: 'addr_area',
						maxLength: 140
					}),
					city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
					zip: edi.selectors.defaults.zip,
					street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
					locality: Object.assign({}, edi.selectors.defaults.locality, {
						name: 'addr_locality',
						maxLength: 140
					}),
					home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
					block: Object.assign({}, edi.selectors.defaults.block, {
						name: 'addr_block',
						maxLength: 20
					}),
					flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
					addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
				},
				bank: {
					bank_acc: edi.selectors.defaults.bank_acc_select
				},
				contact: {
					contact_name: edi.selectors.defaults.contact_name,
					contact_phone: edi.selectors.defaults.contact_phone,
					contact_email: edi.selectors.defaults.contact_email,
					contact_web: edi.selectors.defaults.contact_web
				}
			}
		};
	},
	getDeliveryFieldsConf: function () {
		const fieldsMapPrefix = 'DeliveryPoint';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			license_type: `${fieldsMapPrefix}.License.Type`,
			license_name: `${fieldsMapPrefix}.License.Name`,
			license_series: `${fieldsMapPrefix}.License.Series`,
			license_number: `${fieldsMapPrefix}.License.Number`,
			license_issuing_authority: `${fieldsMapPrefix}.License.IssuingAuthority`,
			license_date_of_issue: `${fieldsMapPrefix}.License.DateOfIssue`,
			license_expiration_date: `${fieldsMapPrefix}.License.ExpirationDate`,
			contact_name: `${fieldsMapPrefix}.ContactPerson`,
			contact_phone: `${fieldsMapPrefix}.PhoneNumber`,
			contact_email: `${fieldsMapPrefix}.E-mail`,
			contact_web: `${fieldsMapPrefix}.Web`,
			address_text: `${fieldsMapPrefix}.AddressText`,
			id: true,
			bank_acc: `${fieldsMapPrefix}.AccountNumber`
		};
	},
	getDeliveryModalConf: function () {
		return {
			title: edi.i18n.getMessage('document.delivery'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
						regex: null,
						maxLength: 35
					})
				},
				address: {
					category: edi.selectors.defaults.category,
					country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
						maxLength: 140,
						fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
							mandatory: false,
							allowBlank: true
						})
					}),
					area: Object.assign({}, edi.selectors.defaults.area, {
						name: 'addr_area',
						maxLength: 140
					}),
					city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
					zip: edi.selectors.defaults.zip,
					street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
					locality: Object.assign({}, edi.selectors.defaults.locality, {
						name: 'addr_locality',
						maxLength: 140
					}),
					home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
					block: Object.assign({}, edi.selectors.defaults.block, {
						name: 'addr_block',
						maxLength: 20
					}),
					flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
					addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 }),
					address_text: {
						title: 'document.org.address_text',
						name: 'address_text',
						maxLength: 1000
					}
				},
				bank: {
					bank_acc: edi.selectors.defaults.bank_acc_select
				},
				contact: {
					contact_name: edi.selectors.defaults.contact_name,
					contact_phone: edi.selectors.defaults.contact_phone,
					contact_email: edi.selectors.defaults.contact_email,
					contact_web: edi.selectors.defaults.contact_web
				}
			}
		};
	},
	getUltimateCustomerFieldsConf: function () {
		const fieldsMapPrefix = 'UltimateCustomer';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			license_type: `${fieldsMapPrefix}.License.Type`,
			license_name: `${fieldsMapPrefix}.License.Name`,
			license_series: `${fieldsMapPrefix}.License.Series`,
			license_number: `${fieldsMapPrefix}.License.Number`,
			license_issuing_authority: `${fieldsMapPrefix}.License.IssuingAuthority`,
			license_date_of_issue: `${fieldsMapPrefix}.License.DateOfIssue`,
			license_expiration_date: `${fieldsMapPrefix}.License.ExpirationDate`,
			contact_name: `${fieldsMapPrefix}.ContactPerson`,
			contact_phone: `${fieldsMapPrefix}.PhoneNumber`,
			contact_email: `${fieldsMapPrefix}.E-mail`,
			contact_web: `${fieldsMapPrefix}.Web`,
			id: true,
			bank_acc: `${fieldsMapPrefix}.AccountNumber`
		};
	},
	getUltimateCustomerModalConf: function () {
		return {
			title: edi.i18n.getMessage('documents.ultimate.customer'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
						regex: null,
						maxLength: 35
					})
				},
				address: {
					category: edi.selectors.defaults.category,
					country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
						maxLength: 140,
						fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
							mandatory: false,
							allowBlank: true
						})
					}),
					area: Object.assign({}, edi.selectors.defaults.area, {
						name: 'addr_area',
						maxLength: 140
					}),
					city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
					zip: edi.selectors.defaults.zip,
					street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
					locality: Object.assign({}, edi.selectors.defaults.locality, {
						name: 'addr_locality',
						maxLength: 140
					}),
					home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
					block: Object.assign({}, edi.selectors.defaults.block, {
						name: 'addr_block',
						maxLength: 20
					}),
					flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
					addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
				},
				bank: {
					bank_acc: edi.selectors.defaults.bank_acc_select
				},
				contact: {
					contact_name: edi.selectors.defaults.contact_name,
					contact_phone: edi.selectors.defaults.contact_phone,
					contact_email: edi.selectors.defaults.contact_email,
					contact_web: edi.selectors.defaults.contact_web
				}
			}
		};
	}
};
