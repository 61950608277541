Ext.namespace('edi.selectors');

Ext.merge(edi.selectors.defaults, {
	company_type_combo: {
		type: 'combo',
		title: 'company.type.selector',
		fieldConfig: {
			disabled: function (org) {
				if (!!org) {
					var orgs = edi.selectors.orgConfig.DEFAULT_NAMES.filter(function (item) {
						return item === org.type;
					});
					return orgs.length > 0;
				}
				return false;
			},
			displayField: 'type',
			store: 'initCompanyTypeStore',
			valueField: 'id',
			name: 'type',
			listeners: {
				change: function (cmp) {
					edi.selectors.fieldControls.switchCompanyTypeBlocks(cmp);
				},
				afterRender: function (cmp) {
					edi.selectors.fieldControls.switchCompanyTypeBlocks(cmp);
				}
			}
		}
	},
	company_kpp: {
		title: 'column.org.kpp',
		emptyText: '',
		maskRe: /\d/i,
		regex: edi.constants.VALIDATORS.KPP,
		name: 'company_kpp',
		regexText: 'company.kpp.error'
	},
	company_name: {
		emptyText: '',
		allowBlank: false
	},
	company_gln: {
		emptyText: '',
		title: 'ediweb.company.gln'
	},
	company_okpo: {
		title: 'company.okpo.short',
		emptyText: '',
		regex: edi.constants.VALIDATORS.OKPO,
		maskRe: /\d/i
	},
	company_inn: {
		emptyText: '',
		allowBlank: false
	},
	info_for_members: {
		title: 'company.infoForMembers',
		name: 'info_for_members',
		allowBlank: true,
		maxLength: 255
	},
	short_name: {
		title: 'company.shortName',
		name: 'short_name',
		allowBlank: true,
		maxLength: 255
	}
});

Ext.merge(edi.selectors.defaultsFNS, {
	main: {
		individual_gln: {
			emptyText: ''
		},
		individual_okpo: {
			emptyText: '',
			title: 'ediweb.company.okpo',
			allowBlank: true
		},
		individual_inn: {
			emptyText: '',
			title: 'ediweb.company.inn.individual',
			allowBlank: false
		},
		individual_lastName: {
			emptyText: ''
		},
		individual_firstName: {
			emptyText: ''
		},
		individual_patronymicName: {
			emptyText: ''
		},
		certificateRegistrationIP: {
			emptyText: '',
			title: 'ediweb.individual.certificateRegistration'
		},
		structural_subdivision: {
			emptyText: ''
		}
	},
	address: {
		zip: {
			emptyText: '______'
		},
		region: {
			emptyText: '',
			allowBlank: false
		},
		area: {
			emptyText: ''
		},
		city: {
			emptyText: ''
		},
		locality: {
			emptyText: ''
		},
		street: {
			emptyText: ''
		},
		home: {
			emptyText: ''
		},
		block: {
			emptyText: ''
		},
		flat: {
			emptyText: '',
			title: 'ediweb.company.flat'
		}
	},
	bank: {
		bank_acc_select: {
			fieldConfig: {
				emptyText: ''
			}
		},
		bank_corr_acc: {
			emptyText: ''
		},
		bank_id: {
			emptyText: ''
		},
		bank_name: {
			emptyText: ''
		}
	},
	contact: {
		phone: {
			emptyText: '',
			inputMask: edi.constants.FIELD_MASK.PHONE
		},
		fax: {
			emptyText: ''
		},
		email: {
			emptyText: '',
			title: 'ediweb.company.contact.email'
		}
	}
});

Ext.merge(edi.selectors.defaults, {
	person_lastname: {
		title: 'last.name',
		type: 'text',
		name: 'person_lastname'
	},
	person_firstname: {
		title: 'first.name',
		type: 'text',
		name: 'person_firstname'
	},
	person_patronymicname: {
		title: 'patronymic.name',
		type: 'text',
		name: 'person_patronymicname'
	},
	edisoft_id: {
		title: 'edisoft.id.gln',
		order: 6,
		type: 'text',
		name: 'company_iln'
	},
	person_inn: {
		name: 'person_inn',
		order: 7,
		title: 'company.inn.short',
		emptyText: 'company.inn.individual.error',
		maskRe: /\d/i,
		regex: edi.constants.VALIDATORS.INDI_INN,
		regexText: 'company.inn.individual.error',
		allowBlank: true
	},
	birthday: {
		title: 'birth.date',
		type: 'date',
		order: 9,
		format: edi.constants.DATE_FORMAT.CLIENT,
		name: 'dateOfBirth'
	},
	fns_id: {
		title: 'company.util.reg.fnsId',
		order: 10,
		type: 'text',
		name: 'fns_id'
	}
});

edi.selectors.orgConfig = {
	/**
	 * {тип_организации: обязательное_поле}
	 * использовать для определения типа организации по наличию заполнения обязательного поля
	 */
	DEFAULT: [
		{ company: 'company_name' },
		{ individual: 'individual_inn' },
		{ person: 'person_inn' },
		{ legalForeigner: 'foreign_name' },
		{ individualParticipant: 'physical_firstname' },
		{ companyNotRegistered: 'notregistered_name' }
	],
	DEFAULT_NAMES: [
		'companyNotRegistered',
		'legalForeigner',
		'individual',
		'company',
		'individualParticipant',
		'person'
	]
};

/**
 * Create DSF fields map
 * @param       fieldsMapPrefix     Fields map prefix
 * @return      {Object}
 */
edi.selectors.getDSFFieldsMap = function (fieldsMapPrefix) {
	var prefix = fieldsMapPrefix + '.',
		company = prefix + 'identification.legalEntity.',
		individual = prefix + 'identification.individualPerson.',
		person = prefix + 'identification.individual.',
		rusAddress = prefix + 'address.russianAddress.',
		foreignAddr = prefix + 'address.foreginAddress.',
		contact = prefix + 'contact.',
		bank = prefix + 'bankInfo.',
		structural_subdivision = 'senderDocumentFields.' + prefix;

	return {
		company_inn: company + 'inn',
		company_name: company + 'name',
		company_iln: prefix + 'iln',
		company_kpp: company + 'kpp',
		company_okpo: company + 'okpo',

		individual_inn: individual + 'inn',
		individual_lastname: individual + 'name.lastName',
		individual_firstname: individual + 'name.firstName',
		individual_patronymicname: individual + 'name.middleName',

		person_inn: person + 'inn',
		person_lastname: person + 'name.lastName',
		person_firstname: person + 'name.firstName',
		person_patronymicname: person + 'name.middleName',
		person_birthday: person + 'dateOfBirth',
		person_snils: person + 'snils',

		addr_rus_zip: rusAddress + 'addrIndex',
		addr_rus_region: rusAddress + 'codeReg',
		addr_rus_county: rusAddress + 'county',
		addr_rus_city: rusAddress + 'city',
		addr_rus_street: rusAddress + 'street',
		addr_rus_community: rusAddress + 'community',
		addr_rus_home: rusAddress + 'home',
		addr_rus_block: rusAddress + 'addrBlock',
		addr_rus_flat: rusAddress + 'flat',

		addr_for_country: foreignAddr + 'countryCode',
		addr_for_text: foreignAddr + 'addrText',

		contact_phone: contact + 'tel',
		contact_fax: contact + 'fax',
		contact_email: contact + 'eMail',

		bank_acc: bank + 'accountNumber',
		bank_corr_acc: bank + 'bankAcc',
		bank_name: bank + 'name',
		bank_id: bank + 'bankBIC',

		fns_id: prefix + 'fnsId',
		address_type: true,
		structural_subdivision: structural_subdivision + 'structural_subdivision',
		type: true
	};
};
Ext.merge(edi.selectors.defaultsFNS, {
	main: {
		person_lastname: {
			title: 'last.name',
			type: 'text',
			name: 'person_lastname',
			emptyText: '',
			allowBlank: false
		},
		person_firstname: {
			title: 'first.name',
			type: 'text',
			name: 'person_firstname',
			emptyText: '',
			allowBlank: false
		},
		person_patronymicname: {
			title: 'patronymic.name',
			type: 'text',
			name: 'person_patronymicname',
			emptyText: ''
		},
		person_snils: {
			title: 'snils.code',
			type: 'text',
			regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
			inputMask: edi.constants.FIELD_MASK.SNILS,
			name: 'person_snils',
			emptyText: '',
			allowBlank: false
		},
		person_inn: {
			name: 'person_inn',
			title: 'company.inn.short',
			emptyText: '',
			maskRe: /\d/i,
			regex: edi.constants.VALIDATORS.INDI_INN,
			regexText: 'company.inn.individual.error',
			allowBlank: false
		},
		person_birthday: {
			title: 'birth.date',
			type: 'date',
			format: edi.constants.DATE_FORMAT.FNS,
			name: 'person_birthday',
			emptyText: ''
		},
		fns_id: {
			title: 'company.util.reg.fnsId',
			type: 'text',
			name: 'fns_id',
			emptyText: ''
		}
	}
});

const getGridFNSModalConfOLD = edi.selectors.getGridFNSModalConf;
edi.selectors.getGridFNSModalConf = function () {
	const result = getGridFNSModalConfOLD.apply(this, arguments);
	let fnsDefaults = Ext.clone(edi.selectors.defaultsFNS);

	result.tabs.main.person_data = {
		type: 'container',
		blockId: 'company_data_block',
		fields: {
			person_data_block: {
				type: 'fieldsBlock',
				containerConf: {
					margin: '16 0 0 0',
					layout: {
						type: 'grid',
						area: [[4, 4, 4], 4, [4, 4], 8]
					}
				},
				fields: {
					individual_lastName: fnsDefaults.main.person_lastname,
					individual_firstName: fnsDefaults.main.person_firstname,
					individual_patronymicName: fnsDefaults.main.person_patronymicname,
					person_birthday: fnsDefaults.main.person_birthday,
					company_inn: fnsDefaults.main.person_inn,
					company_kpp: fnsDefaults.main.person_snils,
					fns_id: fnsDefaults.main.fns_id
				}
			}
		}
	};

	return result;
};
