// @ts-ignore
import { createLabel, createLabelBlock } from '@UIkit/components/fields';
// @ts-ignore
import { showError } from '@UIkit/components/modal/MessageBox';
// @ts-ignore
import { createContainer, createModalForm } from '@UIkit/components/panels';
import { getPoaErrorReasonByType } from '@Edi/modules/power_of_attorney/methods';
import { PoaFromChain } from '../difinitions';

export interface showPoAChainErrorProps {
	poaList: PoaFromChain[];
	poaWithErrors: PoaFromChain[];
	poaWithoutErrors: PoaFromChain[];
}
export const showPoAChainError = function ({ poaList, poaWithErrors, poaWithoutErrors }: showPoAChainErrorProps) {
	const createPoAErrorBlock = (poa: PoaFromChain, poaIndex: number): ExtComponent => {
		const poaNumber = poa.number;
		const errorType = typeof poa.errorMessage === 'object' ? poa.errorMessage.type : null;
		const errorText = getPoaErrorReasonByType(errorType, poaNumber);
		const errorMessage = edi.utils.safeString(poa.errorMessage, true);
		const contents = [
			{
				text: `${++poaIndex}. №${poaNumber}`
			},
			{
				text: errorText || errorMessage,
				cls: 'poa-error-details-text'
			}
		];
		return createLabelBlock({
			contents
		});
	};

	showError({
		title: edi.i18n.getMessage('power.of.attorney.chain.import.error.title'),
		items: [
			createModalForm({
				layout: {
					type: 'grid',
					gap: 24
				},
				items: [
					createLabel({
						typography: 'body-short_02',
						text: edi.i18n.getMessage('power.of.attorney.success.chain.import.count', [
							poaWithoutErrors.length,
							poaList.length
						])
					}),
					createContainer({
						layout: {
							type: 'grid',
							gap: 16
						},
						items: [
							createLabel({
								text: edi.i18n.getMessage('power.of.attorney.chain.import.error.subtitle')
							}),
							...poaWithErrors.map(createPoAErrorBlock)
						]
					})
				]
			})
		],
		okBtnConfig: {
			text: edi.i18n.getMessage('power.of.attorney.reload.btn')
		}
	});
};
