const actionRulesForAcceptModule = {
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				allow: true
			}
		]
	}
};

edi.action.rulesByDocType[edi.constants.DOCUMENT_TYPES.REGISTRATION_ACCEPTANCE] = actionRulesForAcceptModule;

export { actionRulesForAcceptModule };
