const FILE_NAME_ATTACH_MODEL_CONFIG_NAME = 'FILE_NAME_ATTACH';

edi.models.setModels({
	[FILE_NAME_ATTACH_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'attributes'
			},
			{
				name: 'FILE_ID',
				mapping: 'attributes',
				type: 'string',
				convert: (value) => {
					return value?.FILE_ID?.value || '';
				}
			}
		]
	}
});

let getFileNameAttachModelName = () => edi.models.getModel(FILE_NAME_ATTACH_MODEL_CONFIG_NAME);

export { getFileNameAttachModelName, FILE_NAME_ATTACH_MODEL_CONFIG_NAME };
