// ON_AVZ

const actionRulesForOnAvzModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.COMPLETED],
				allow: true
			}
		]
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.OPERATOR_INVOICE_SENT_ACK_TO_SENDER_RECEIVE_ADVISE,
				edi.constants.STATE.OPERATOR_INVOICE_SENT_ACK_TO_RECEIVER_RECEIVE_ADVISE,
				edi.constants.STATE.RECEIVER_INVOICE_RECEIVED_ACK,
				edi.constants.STATE.SENDER_INVOICE_RECEIVED_ACK_ADVISE,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT,
				edi.constants.STATE.WAIT_SIGNATURE_STATUS10,
				edi.constants.STATE.WAIT_PARTNER_DECISION
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.ON_AVZ],
				states: [edi.constants.STATE.DSF_DP_IZVPOL_SENDER, edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER],
				allow: function (document) {
					return (
						!document.isUnsupportedDSF &&
						!(
							edi.utils.getAttributeByName(document.attributes, 'RECEIVER_SIGNATURE_EXPECTED') ===
								'false' &&
							document.state === 'DSF_DP_IZVPOL_RECEIVER' &&
							document.direction === edi.constants.DIRECTIONS.INCOMING
						)
					);
				}
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.ON_AVZ],
				customMethod: function (checkOptions) {
					//Restrict sign documents with active annul
					return (
						checkOptions.isUnsupportedDSF ||
						!checkOptions.annulNotActive ||
						checkOptions.annulStatus == edi.constants.STATE.CREATED ||
						checkOptions.utochNotFinished
					);
				},
				allow: false
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.ON_AVZ],
				states: [edi.constants.STATE.SENT],
				allow: function (documentData) {
					let isNoHasIntegration =
						edi.utils.getAttributeByName(documentData.attributes, 'INTEGRATION') === 'false' &&
						edi.utils.getAttributeByName(documentData.attributes, 'RECEIVER_SIGNATURE_EXPECTED') === 'true';
					let trueAttribute =
						edi.utils.getAttributeByName(documentData.attributes, 'bpName') ==
							edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.V2 ||
						edi.utils.getAttributeByName(documentData.attributes, 'bpName') ==
							edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION;
					return (!documentData.isUnsupportedDSF && trueAttribute) || isNoHasIntegration;
				}
			},
			{
				states: [
					edi.constants.STATE.DEBTOR_REVIEW,
					edi.constants.STATE.RECEIVER_REVIEW,
					edi.constants.STATE.READ
				],
				allow: function (documentData) {
					return documentData.needSignatures;
				}
			}
		]
	},
	REJECT: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.WAIT_SIGNATURE,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.READ,
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_APPROVAL,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER,
				edi.constants.STATE.WAIT_RECEIVER_SIGNATURE_X5_AGREEMENT,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER
			]
		}
	},
	ROUTE_REJECT: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	ROUTE_CONFIRM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_INBOX]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.WAITIGN_FOR_CONFIRM_OUTBOX]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.SELLER_REVIEW, edi.constants.STATE.DRAFT, edi.constants.STATE.REJECTED]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.ON_AVZ],
			BUSINESS_STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.REJECTED],
				allow: false
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.ON_AVZ],
				states: [edi.constants.STATE.SENT],
				allow: function (document) {
					return (
						!document.isUnsupportedDSF &&
						edi.utils.getAttributeByName(document.attributes, 'bpName') ==
							edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION
					);
				}
			}
		]
	},
	EDIT: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [],
		conditions: []
	},
	COMPLETE: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.READ,
				edi.constants.STATE.DEBTOR_REVIEW,
				edi.constants.STATE.RECEIVER_REVIEW
			]
		},
		LOOP: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENDER_REVIEW,
				edi.constants.STATE.SELLER_REVIEW,
				edi.constants.STATE.DRAFT
			]
		},
		permissionChanging: [],
		conditions: []
	},
	READ: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		permissionChanging: [],
		conditions: [
			{
				//DSF in state  can be read only if old buiseness process used (Edidsfv1)
				documents: [edi.constants.DOCUMENT_TYPES.ON_AVZ],
				allow: function (documentData) {
					let notBlockedByAnnul =
						documentData.annulNotActive && documentData.annulStatus != edi.constants.STATE.CREATED;
					let isNoHasIntegration =
						edi.utils.getAttributeByName(documentData.attributes, 'INTEGRATION') === 'false' &&
						edi.utils.getAttributeByName(documentData.attributes, 'RECEIVER_SIGNATURE_EXPECTED') === 'true';

					return (
						!documentData.isUnsupportedDSF &&
						notBlockedByAnnul &&
						edi.utils.getAttributeByName(documentData.attributes, 'bpName') !=
							edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.V2 &&
						edi.utils.getAttributeByName(documentData.attributes, 'bpName') !=
							edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION &&
						!isNoHasIntegration
					);
				}
			}
		]
	},
	SEND: {
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {
			SINGLE_APPLY: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENDER_REVIEW],
				allow: false
			}
		]
	},
	CREATE_FROM: {
		INCOMING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.ACCEPTED, edi.constants.STATE.SENT]
		},
		OUTGOING: {
			BUSINESS_STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENT],
				allow: false
			}
		]
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					if (checkOptions.record.get('packageId')) {
						return edi.permissions.hasPermission('UNLINK_PACKAGE_OBJECT');
					} else {
						return (
							edi.permissions.hasPermission('LINK_PACKAGE_OBJECT') ||
							edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
						);
					}
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_SENDER,
				edi.constants.STATE.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER,
				edi.constants.STATE.ON_ANNUL
			]
		},
		OUTGOING: {
			BUSINESS_STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.READ,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SIGNED_BY_CONSUMER,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.DSF_DP_UTOCH_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER
			]
		},
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.ON_AVZ],
				allow: function (checkOptions) {
					let annulDoc = checkOptions.methodAddOptions ? checkOptions.methodAddOptions : null;
					let annualDirection;
					let annualWait =
						annulDoc && annulDoc.id && annulDoc.state == edi.constants.STATE.WAIT_PARTNER_DECISION;
					if (annulDoc) {
						annualDirection = edi.utils.getDocumentDirection(annulDoc.toOrg, annulDoc.fromOrg);
					}
					return annualWait && annualDirection == edi.constants.DIRECTIONS.INCOMING;
				}
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.ON_AVZ],
				customMethod: function (checkOptions) {
					let bpName = edi.utils.getAttributeByName(checkOptions.attributes, 'bpName');
					//Restrict annul  DSF with old business process
					return (
						checkOptions.isUnsupportedDSF ||
						bpName == edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.V1 ||
						bpName == edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION ||
						bpName == edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_APERAK ||
						bpName == edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_CONTRL
					);
				},
				allow: false
			},
			{
				allow: function (checkOptions) {
					let annual = true;
					if (checkOptions.annulStatus) {
						annual = [
							edi.constants.STATE.CREATED,
							edi.constants.STATE.DELETED,
							edi.constants.STATE.DRAFT
						].some((it) => it === checkOptions.annulStatus);
					}
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive ? annual : false;
				}
			}
		],
		permissionChanging: [
			{
				change: function (actionPermission, checkOptions) {
					return checkOptions.docType.substr(0, 4) === 'DSF_' ? 'ANNUL_DSF' : actionPermission;
				}
			}
		]
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.ON_AVZ]: actionRulesForOnAvzModule
});

export { actionRulesForOnAvzModule };
