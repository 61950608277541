import {
	PACKAGE_ATTACH_PRIL02OSTRB_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRIL06KTOV_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILCOMMUSL_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILKTOVDR_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILPROTSOGL_COLUMN_CONFIG_NAME,
	PACKAGE_ATTACH_PRILUVIZMCEN_COLUMN_CONFIG_NAME
} from './columns';
import {
	PACKAGE_ATTACH_PRIL02OSTRB_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRIL06KTOV_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILCOMMUSL_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILKTOVDR_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILPROTSOGL_MODEL_CONFIG_NAME,
	PACKAGE_ATTACH_PRILUVIZMCEN_MODEL_CONFIG_NAME
} from './models';
import { agreementPackagesAPI } from './rest';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { showMessageBox } from '@UIkit/components/modal/MessageBox';
import { createTextField } from '@UIkit/components/fields';

const getDocumentForms = () => ({
	DP_DOGPOST: [
		{
			type: 'fieldset',
			title: edi.i18n.getMessage('document.package.agreement.textInfos.label.termsAndConditions'),
			layout: {
				type: 'grid',
				area: [6, [3, 3], 6, 6, 6, 6, 6, 6, 6, 6]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.itemNumber'),
					type: 'combo',
					name: 'documentContent.textInfos.itemNumber',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					editable: false,
					allowBlank: false,
					multiSelect: true,
					store: edi.stores.createSimpleInlineStore(['4.1.1', '4.1.2', '4.1.3'], function (id) {
						return id;
					}),
					listeners: {
						change: function (combo, newValue) {
							let tooltipTexts = Ext.clone(newValue || [])
								.sort()
								.map((v) => {
									let description = edi.i18n.getMessage(
										'document.package.agreement.textInfos.title.itemNumber.tooltip.' + v
									);
									return `${v} - ${description}`;
								});
							if (tooltipTexts.length) {
								combo.setToolTip(tooltipTexts.join('<br/>'));
							} else {
								combo.clearTip();
							}
						}
					}
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.returnType'),
					type: 'combo',
					name: 'documentContent.textInfos.returnType',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					store: 'initDpAttachDocReturnType',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.daysCount1'),
					name: 'documentContent.textInfos.daysCount1',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.daysCount6'),
					name: 'documentContent.textInfos.daysCount6',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.daysCount2'),
					name: 'documentContent.textInfos.daysCount2',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.daysCount3'),
					name: 'documentContent.textInfos.daysCount3',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.daysCount4'),
					name: 'documentContent.textInfos.daysCount4',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.daysCount5'),
					name: 'documentContent.textInfos.daysCount5',
					allowBlank: false
				}
			]
		}
	],
	DP_PRIL01ORGEDOC: [],
	DP_PRIL02OSTRB: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [2, 5]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.hours'),
					name: 'documentContent.textInfos.hours',
					type: 'time',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.reviseEmail'),
					name: 'documentContent.textInfos.reviseEmail1',
					maxLength: 255,
					allowBlank: false
				}
			]
		},
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRIL02OSTRB_COLUMN_CONFIG_NAME,
				fields: [
					{
						title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addressEntrance'),
						name: 'addressEntrance',
						allowBlank: false
					},
					{
						title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addressReturn'),
						name: 'addressReturn',
						allowBlank: false
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isRowValid: function (record) {
					var recordData = record.getData();
					return !!recordData.addressEntrance && !!recordData.addressReturn;
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				modalTitle: edi.i18n.getMessage('document.package.agreement.PRIL02OSTRB.title'),
				model: PACKAGE_ATTACH_PRIL02OSTRB_MODEL_CONFIG_NAME,
				gridConfig: {
					title: ' ',
					cls: 'modal-grid',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					disablePaging: true
				}
			}
		}
	],
	DP_PRILUVIZMCEN: [
		{
			type: 'editableGrid',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRILUVIZMCEN_COLUMN_CONFIG_NAME,
				limit: 0,
				model: PACKAGE_ATTACH_PRILUVIZMCEN_MODEL_CONFIG_NAME,
				gridConfig: {
					title: ' ',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					cls: 'modal-grid',
					disablePaging: true
				}
			}
		}
	],
	DP_PRIL03COMMUSL: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [4, 4, 4, 4]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.amountProcurements'),
					name: 'documentContent.textInfos.amountProcurements',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusRC'),
					name: 'documentContent.textInfos.percentBonusRC',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusGM'),
					name: 'documentContent.textInfos.percentBonusGM',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusMM'),
					name: 'documentContent.textInfos.percentBonusMM',
					allowBlank: false
				}
			]
		}
	],
	DP_PRIL04SANK: [],
	DP_PRIL05KPOST: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [4, 6, 3, 6, 4, [3, 3], 4]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.fioAccounter'),
					name: 'documentContent.textInfos.fioAccounter',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.address'),
					name: 'documentContent.textInfos.address',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.buyerNds'),
					name: 'documentContent.textInfos.buyerNds',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					store: 'initDpAttachDocBuyerNds',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addConds'),
					name: 'documentContent.textInfos.addConds',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.okved'),
					name: 'documentContent.textInfos.okved',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.emailSRM1'),
					name: 'documentContent.textInfos.emailSRM1',
					allowBlank: false,
					regex: edi.constants.VALIDATORS.EMAIL,
					invalidText: edi.i18n.getMessage('invalid.email.format')
				},

				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.emailSRM2'),
					name: 'documentContent.textInfos.emailSRM2',
					allowBlank: false,
					regex: edi.constants.VALIDATORS.EMAIL,
					invalidText: edi.i18n.getMessage('invalid.email.format')
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.fio'),
					name: 'documentContent.textInfos.fio',
					allowBlank: false
				}
			]
		}
	],
	DP_PRIL06KTOV: [
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRIL06KTOV_COLUMN_CONFIG_NAME,
				fields: [
					{
						type: 'container',
						layout: {
							type: 'grid',
							area: [[6, 6], [6, 6], [4], 12, [6, 6], [6, 6], [6, 6], [6, 6], [4, 4, 4], 12, 12]
						},
						items: [
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productName'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.productName.ttip'
								),
								name: 'productName',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.positionCode'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.positionCode.ttip'
								),
								name: 'positionCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.vendorCode'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.vendorCode.ttip'
								),
								name: 'vendorCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.trademark'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.trademark.ttip'
								),
								name: 'trademark'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.tax'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.tax.ttip'),
								name: 'tax',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.unitOfMeasure'),
								type: 'fieldblock',
								layout: {
									type: 'grid',
									area: [[6, 6]]
								},
								createCustomItems: function (_conf, src, { readOnly, disabled }) {
									return [
										createTextField({
											fieldLabel: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasureMeter'
											),
											qtipText: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasureMeter.ttip'
											),
											name: 'unitOfMeasureMeter',
											allowBlank: false,
											valueSrc: src,
											readOnly,
											disabled
										}),
										createTextField({
											fieldLabel: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasure'
											),
											qtipText: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasure.ttip'
											),
											name: 'unitOfMeasure',
											valueSrc: src,
											readOnly,
											disabled
										})
									];
								}
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.shelfLife'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.shelfLife.ttip'
								),
								name: 'shelfLife'
							},
							{
								title: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.storageTemperature'
								),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.storageTemperature.ttip'
								),
								name: 'storageTemperature'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.batch'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.batch.ttip'),
								name: 'batch'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.bulk'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.bulk.ttip'),
								name: 'bulk'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.weightOfUnit'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.weightOfUnit.ttip'
								),
								name: 'weightOfUnit'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.itemWeight'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.itemWeight.ttip'
								),
								name: 'itemWeight'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.manufacturer'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.manufacturer.ttip'
								),
								name: 'manufacturer',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.countryManufacturer'
								),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.countryManufacturer.ttip'
								),
								name: 'countryManufacturer'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.things'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.things.ttip'),
								name: 'things'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.boxes'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.boxes.ttip'),
								name: 'boxes'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.block'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.block.ttip'),
								name: 'block'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.props'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.props.ttip'),
								name: 'props'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.delay'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.delay.ttip'),
								name: 'delay'
							}
						]
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isHideDeleteManyBtn: false,
				isRowValid: function (record) {
					var recordData = record.getData();
					return (
						!!recordData.productName &&
						!!recordData.positionCode &&
						!!recordData.vendorCode &&
						!!recordData.tax &&
						!!recordData.unitOfMeasureMeter &&
						!!recordData.manufacturer
					);
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				model: PACKAGE_ATTACH_PRIL06KTOV_MODEL_CONFIG_NAME,
				uploadFileUrl: edi.utils.formatString(agreementPackagesAPI.LOAD_ATTAH.POST, {
					type: 'prodcat',
					containerType: 'dp_container'
				}),
				modalTitle: edi.i18n.getMessage('document.package.agreement.PRIL06KTOV.title'),
				uploadFileDocType: 'DP_PRIL06KTOV',
				gridConfig: {
					title: ' ',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					cls: 'modal-grid',
					disablePaging: true
				}
			}
		}
	],
	DP_PRIL07SHSVER: [],
	DP_PRIL08FORMZVT: [],
	DP_PRIL09FAOPTUZ: [],
	DP_PRIL10TOSGPT: [],
	DP_PRIL11ANTIKOR: [],
	DP_PRILPROTSOGL: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [6, 2, 2]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.deliverPoint'),
					name: 'documentContent.textInfos.deliverPoint',
					isTextarea: true,
					allowBlank: false,
					maxLength: 2000
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.isTax'),
					name: 'documentContent.textInfos.tax',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					allowBlank: false,
					store: 'initDpAttachDocIsTax'
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.date'),
					name: 'documentContent.textInfos.date',
					type: 'date',
					allowBlank: false,
					minValue: new Date(),
					format: edi.constants.DATE_FORMAT.FNS,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					srcFormat: edi.constants.DATE_FORMAT.FNS,
					typeDate: 'string'
				}
			]
		},
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRILPROTSOGL_COLUMN_CONFIG_NAME,
				fields: [
					{
						type: 'container',
						layout: {
							type: 'grid',
							area: [6, 12, 3, 3]
						},
						items: [
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.serialNumber'),
								name: 'serialNumber',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productOfName'),
								name: 'productOfName',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productOfCode'),
								name: 'productOfCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.price'),
								name: 'price',
								allowBlank: false
							}
						]
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isHideDeleteManyBtn: false,
				isRowValid: function (record) {
					var recordData = record.getData();
					return (
						!!recordData.serialNumber &&
						!!recordData.productOfCode &&
						!!recordData.productOfName &&
						!!recordData.price
					);
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				model: PACKAGE_ATTACH_PRILPROTSOGL_MODEL_CONFIG_NAME,
				uploadFileUrl: edi.utils.formatString(agreementPackagesAPI.LOAD_ATTAH.POST, {
					type: 'price_agreement',
					containerType: 'dp_container'
				}),
				modalTitle: edi.i18n.getMessage('document.package.agreement.PRILPROTSOG.title'),
				uploadFileDocType: 'DP_PRILPROTSOGL',
				gridConfig: {
					title: ' ',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					cls: 'modal-grid',
					disablePaging: true
				}
			}
		}
	]
});

const getDocumentFormsNE = () => ({
	DP_PRILPROTSOGLNE: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [3, 2]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.isTax'),
					name: 'documentContent.textInfos.tax',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					allowBlank: false,
					store: 'initDpAttachDocIsTax'
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.date'),
					name: 'documentContent.textInfos.date',
					type: 'date',
					allowBlank: false,
					minValue: new Date(),
					format: edi.constants.DATE_FORMAT.FNS,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					srcFormat: edi.constants.DATE_FORMAT.FNS,
					typeDate: 'string'
				}
			]
		},
		{
			type: 'deliveryGrid',
			editableGridConfig: {
				name: 'documentContent.textInfos',
				columnsConfig: PACKAGE_ATTACH_PRILPROTSOGL_COLUMN_CONFIG_NAME,
				fields: [
					{
						type: 'container',
						layout: {
							type: 'grid',
							area: [6, 3, 12, 3]
						},
						items: [
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.serialNumber'),
								name: 'serialNumber',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productOfCode'),
								name: 'productOfCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productOfName'),
								name: 'productOfName',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.price'),
								name: 'price',
								allowBlank: false
							}
						]
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isHideDeleteManyBtn: false,
				isRowValid: function (record) {
					var recordData = record.getData();
					return (
						!!recordData.serialNumber &&
						!!recordData.productOfCode &&
						!!recordData.productOfName &&
						!!recordData.price
					);
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				model: PACKAGE_ATTACH_PRILPROTSOGL_MODEL_CONFIG_NAME,
				uploadFileUrl: edi.utils.formatString(agreementPackagesAPI.LOAD_ATTAH.POST, {
					type: 'price_agreement_ne',
					containerType: 'dp_container_ne'
				}),
				uploadFileDocType: 'DP_PRILPROTSOGL',
				gridConfig: {
					cls: 'modal-grid-with-header',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					title: edi.i18n.getMessage('document.package.agreement.products.title')
				}
			}
		}
	],
	DP_DOGPOSTNE: [
		{
			type: 'fieldset',
			title: edi.i18n.getMessage('document.package.agreement.textInfos.label.termsAndConditions'),
			layout: {
				type: 'grid',
				area: [6, [3, 3], 6, 6, 6, 6, 6, 6, 6, 6]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.itemNumber'),
					name: 'documentContent.textInfos.itemNumber',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					editable: false,
					allowBlank: false,
					multiSelect: true,
					store: edi.stores.createSimpleInlineStore(
						['4.2.1а', '4.2.1б', '4.2.2а', '4.2.2б', '4.2.3', '4.2.4', '4.2.5а', '4.2.5б'],
						function (id) {
							return id;
						}
					),
					listeners: {
						change: function (combo, newValue) {
							let values = (newValue || []).map((v) => v.slice(0, 5));
							let uniqueValues = [...new Set(values)];
							let tooltipTexts = uniqueValues.sort().map((v) => {
								let description = edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.itemNumber.tooltip.' + v
								);
								return `${v} - ${description}`;
							});
							if (tooltipTexts.length) {
								combo.setToolTip(tooltipTexts.join('<br/>'));
							} else {
								combo.clearTip();
							}
						}
					}
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.returnType'),
					name: 'documentContent.textInfos.returnType',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					store: 'initDpAttachDocReturnType',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.daysCount1'),
					name: 'documentContent.textInfos.daysCount1',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount6'),
					name: 'documentContent.textInfos.daysCount6',
					qtipText: edi.i18n.getMessage('document.package.ne.agreement.textInfos.tooltip'),
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount2'),
					qtipText: edi.i18n.getMessage('document.package.ne.agreement.textInfos.tooltip'),
					name: 'documentContent.textInfos.daysCount2',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount3'),
					name: 'documentContent.textInfos.daysCount3',
					qtipText: edi.i18n.getMessage('document.package.ne.agreement.textInfos.tooltip'),
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount4'),
					name: 'documentContent.textInfos.daysCount4',
					qtipText: edi.i18n.getMessage('document.package.ne.agreement.textInfos.tooltip'),
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount5'),
					name: 'documentContent.textInfos.daysCount5',
					qtipText: edi.i18n.getMessage('document.package.ne.agreement.textInfos.tooltip'),
					allowBlank: false
				}
			]
		}
	],
	DP_PRILORGEDOCNE: [],
	DP_PRILUVIZMCENNE: [],
	DP_PRILOSTRBNE: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.reviseEmail1'),
					name: 'documentContent.textInfos.reviseEmail1',
					allowBlank: false
				}
			]
		},
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRIL02OSTRB_COLUMN_CONFIG_NAME,
				fields: [
					{
						title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addressEntrance'),
						name: 'addressEntrance',
						allowBlank: false
					},
					{
						title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addressReturn'),
						name: 'addressReturn',
						allowBlank: false
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isRowValid: function (record) {
					var recordData = record.getData();
					return !!recordData.addressEntrance && !!recordData.addressReturn;
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				modalTitle: edi.i18n.getMessage('document.package.agreement.PRIL02OSTRB.title'),
				model: PACKAGE_ATTACH_PRIL02OSTRB_MODEL_CONFIG_NAME,
				gridConfig: {
					title: ' ',
					cls: 'modal-grid',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					disablePaging: true
				}
			}
		}
	],
	DP_PRILCOMMUSLNE: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [6, 4, [4, 4, 4], 4]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.DP_PRIL03COMMUSL.email'),
					name: 'documentContent.textInfos.email',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.amount.purchase.product'),
					name: 'documentContent.textInfos.amountPurchaseProduct',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusRC'),
					name: 'documentContent.textInfos.percentBonusRC',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusGM'),
					name: 'documentContent.textInfos.percentBonusGM',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusMM'),
					name: 'documentContent.textInfos.percentBonusMM',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.oneTimePremium'),
					name: 'documentContent.textInfos.oneTimePremium',
					allowBlank: false
				}
			]
		},
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRILCOMMUSL_COLUMN_CONFIG_NAME,
				fields: [
					{
						type: 'container',
						layout: {
							type: 'grid',
							area: [6, 12, 6]
						},
						items: [
							{
								title: 'document.package.agreement.textInfos.title.productOfCode',
								name: 'productOfCode',
								allowBlank: false,
								//цифры или прочерк -
								regex: /^(\d+|-)$/i,
								regexText: 'document.package.agreement.textInfos.title.productOfCode.regex_error'
							},
							{
								title: 'document.package.agreement.textInfos.title.productOfName.trademark',
								name: 'productOfName',
								allowBlank: false,
								//буквы, цифры и пробелы или прочерк -
								regex: /^([a-zа-яёЁ\d\s]+|-)$/i,
								regexText:
									'document.package.agreement.textInfos.title.productOfName.trademark.regex_error'
							},
							{
								title: 'document.package.agreement.textInfos.title.percentage.premium',
								name: 'premium',
								allowBlank: false,
								//число в формате 123 или 123.123 или 123,123 или прочерк -
								regex: /^(\d+[.|,]\d+|\d+|-)$/i,
								regexText: 'document.package.agreement.textInfos.title.percentage.premium.regex_error'
							}
						]
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isHideDeleteManyBtn: false,
				isRowValid: function (record) {
					var recordData = record.getData();
					return !!recordData.productOfName && !!recordData.productOfCode && !!recordData.premium;
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				modalTitle: edi.i18n.getMessage('document.package.agreement.DP_PRILCOMMUSLNE.title'),
				model: PACKAGE_ATTACH_PRILCOMMUSL_MODEL_CONFIG_NAME,
				uploadFileUrl: edi.utils.formatString(agreementPackagesAPI.LOAD_ATTAH.POST, {
					type: 'dp_prilcommuslne_items',
					containerType: 'dp_container_ne'
				}),
				uploadFileDocType: 'DP_PRILCOMMUSLNE',
				gridConfig: {
					title: edi.i18n.getMessage('document.package.agreement.DP_PRILCOMMUSLNE.grid.title'),
					cls: 'modal-grid',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					disablePaging: true
				}
			}
		}
	],
	DP_PRILSANKNE: [],
	DP_PRILKPOSTNE: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [4, 6, [4, 4], 3, 6, 6, [3, 3], [3, 3], 4]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.shortNameAccounter'),
					name: 'documentContent.textInfos.nameSeller',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.address'),
					name: 'documentContent.textInfos.address',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.fioAccounter'),
					name: 'documentContent.textInfos.fioAccounter',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.fioDirector'),
					name: 'documentContent.textInfos.fioDirector',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.buyerNds'),
					name: 'documentContent.textInfos.buyerNds',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					store: 'initDpAttachDocBuyerNds',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addConds'),
					name: 'documentContent.textInfos.addConds',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.okonh'),
					name: 'documentContent.textInfos.okonh',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.emailSRM1'),
					name: 'documentContent.textInfos.emailSRM1',
					allowBlank: false,
					regex: edi.constants.VALIDATORS.EMAIL,
					invalidText: edi.i18n.getMessage('invalid.email.format')
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.emailSRM2'),
					name: 'documentContent.textInfos.emailSRM2',
					allowBlank: false,
					regex: edi.constants.VALIDATORS.EMAIL,
					invalidText: edi.i18n.getMessage('invalid.email.format')
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.fio'),
					name: 'documentContent.textInfos.fio',
					allowBlank: false
				}
			]
		}
	],
	DP_PRILKTOVNE: [
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRIL06KTOV_COLUMN_CONFIG_NAME,
				fields: [
					{
						type: 'container',
						layout: {
							type: 'grid',
							area: [[6, 6], [6, 6], [4], 12, [6, 6], [6, 6], [6, 6], [6, 6], [4, 4, 4], 12, 12]
						},
						items: [
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productName'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.productName.ttip'
								),
								name: 'productName',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.positionCode'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.positionCode.ttip'
								),
								name: 'positionCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.vendorCode'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.vendorCode.ttip'
								),
								name: 'vendorCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.trademark'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.trademark.ttip'
								),
								name: 'trademark'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.tax'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.tax.ttip'),
								name: 'taxRate',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.unitOfMeasure'),
								type: 'fieldblock',
								layout: {
									type: 'grid',
									area: [[6, 6]]
								},
								createCustomItems: function (_conf, src, { readOnly, disabled }) {
									return [
										createTextField({
											fieldLabel: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasureMeter'
											),
											qtipText: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasureMeter.ttip'
											),
											name: 'unitOfMeasureMeter',
											allowBlank: false,
											valueSrc: src,
											readOnly,
											disabled
										}),
										createTextField({
											fieldLabel: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasure'
											),
											qtipText: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasure.ttip'
											),
											name: 'unitOfMeasure',
											valueSrc: src,
											readOnly,
											disabled
										})
									];
								}
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.unitOfMeasure'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.unitOfMeasure.ttip'
								),
								name: 'unitOfMeasure'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.shelfLife'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.shelfLife.ttip'
								),
								name: 'shelfLife'
							},
							{
								title: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.storageTemperature'
								),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.storageTemperature.ttip'
								),
								name: 'storageTemperature'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.batch'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.batch.ttip'),
								name: 'batch'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.bulk'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.bulk.ttip'),
								name: 'bulk'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.weightOfUnit'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.weightOfUnit.ttip'
								),
								name: 'weightOfUnit'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.itemWeight'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.itemWeight.ttip'
								),
								name: 'itemWeight'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.manufacturer'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.manufacturer.ttip'
								),
								name: 'manufacturer',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.countryManufacturer'
								),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.countryManufacturer.ttip'
								),
								name: 'countryManufacturer'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.things'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.things.ttip'),
								name: 'things'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.boxes'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.boxes.ttip'),
								name: 'boxes'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.block'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.block.ttip'),
								name: 'block'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.props'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.props.ttip'),
								name: 'props'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.delay'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.delay.ttip'),
								name: 'delay'
							}
						]
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isHideDeleteManyBtn: false,
				isRowValid: function (record) {
					var recordData = record.getData();
					return (
						!!recordData.productName &&
						!!recordData.positionCode &&
						!!recordData.vendorCode &&
						!!recordData.taxRate &&
						!!recordData.unitOfMeasureMeter &&
						!!recordData.manufacturer
					);
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				model: PACKAGE_ATTACH_PRIL06KTOV_MODEL_CONFIG_NAME,
				uploadFileUrl: edi.utils.formatString(agreementPackagesAPI.LOAD_ATTAH.POST, {
					type: 'prodcat_ne',
					containerType: 'dp_container_ne'
				}),
				uploadFileDocType: 'DP_PRIL06KTOV',
				gridConfig: {
					title: ' ',
					cls: 'modal-grid',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					disablePaging: true
				}
			}
		}
	],
	DP_PRILSHSVERNE: [],
	DP_PRILFORMZVTNE: [],
	DP_PRILFAOPTUZNE: [],
	DP_PRILANTIKORNE: []
});

const getDocumentFormsDR = () => ({
	DP_DOGPOSTDR: [
		{
			type: 'fieldset',
			title: edi.i18n.getMessage('document.package.agreement.textInfos.label.termsAndConditions'),
			layout: {
				type: 'grid',
				area: [6, [3, 3], 6, 6, 6, 6, 6, 6, 6, 6]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.itemNumber'),
					name: 'documentContent.textInfos.itemNumber',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					editable: false,
					allowBlank: false,
					multiSelect: true,
					store: edi.stores.createSimpleInlineStore(['4.1.1', '4.1.2', '4.1.3', '4.1.4'], function (id) {
						return id;
					}),
					listeners: {
						change: function (combo, newValue) {
							let values = (newValue || []).map((v) => v.slice(0, 5));
							let uniqueValues = [...new Set(values)];
							let tooltipTexts = uniqueValues.sort().map((v) => {
								let description = edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.itemNumber.tooltip.' + v
								);
								return `${v} - ${description}`;
							});
							if (tooltipTexts.length) {
								combo.setToolTip(tooltipTexts.join('<br/>'));
							} else {
								combo.clearTip();
							}
						}
					}
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.returnType'),
					name: 'documentContent.textInfos.returnType',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					store: 'initDpAttachDocReturnType',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.daysCount1'),
					qtipText: edi.i18n.getMessage('document.package.dr.agreement.textInfos.count.days'),
					name: 'documentContent.textInfos.daysCount1',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount4'),
					qtipText: edi.i18n.getMessage('document.package.dr.agreement.textInfos.count.days'),
					name: 'documentContent.textInfos.daysCount6',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount2'),
					qtipText: edi.i18n.getMessage('document.package.dr.agreement.textInfos.count.days'),
					name: 'documentContent.textInfos.daysCount4',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount3'),
					qtipText: edi.i18n.getMessage('document.package.dr.agreement.textInfos.count.days'),
					name: 'documentContent.textInfos.daysCount5',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount5'),
					qtipText: edi.i18n.getMessage('document.package.dr.agreement.textInfos.count.days'),
					name: 'documentContent.textInfos.daysCount7',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.daysCount6'),
					qtipText: edi.i18n.getMessage('document.package.dr.agreement.textInfos.count.days'),
					name: 'documentContent.textInfos.daysCount8',
					allowBlank: false
				}
			]
		}
	],
	DP_PRILPROTSOGLDR: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [3, 2]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.isTax'),
					name: 'documentContent.textInfos.tax',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					allowBlank: false,
					store: 'initDpAttachDocIsTax'
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.date'),
					name: 'documentContent.textInfos.date',
					type: 'date',
					allowBlank: false,
					minValue: new Date(),
					format: edi.constants.DATE_FORMAT.FNS,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					srcFormat: edi.constants.DATE_FORMAT.FNS,
					typeDate: 'string'
				}
			]
		},
		{
			type: 'deliveryGrid',
			editableGridConfig: {
				name: 'documentContent.textInfos',
				columnsConfig: PACKAGE_ATTACH_PRILPROTSOGL_COLUMN_CONFIG_NAME,
				fields: [
					{
						type: 'container',
						layout: {
							type: 'grid',
							area: [6, 3, 12, 3]
						},
						items: [
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.serialNumber'),
								name: 'serialNumber',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productOfCode'),
								name: 'productOfCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productOfName'),
								name: 'productOfName',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.price'),
								name: 'price',
								allowBlank: false
							}
						]
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isHideDeleteManyBtn: false,
				isRowValid: function (record) {
					var recordData = record.getData();
					return (
						!!recordData.serialNumber &&
						!!recordData.productOfCode &&
						!!recordData.productOfName &&
						!!recordData.price
					);
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				model: PACKAGE_ATTACH_PRILPROTSOGL_MODEL_CONFIG_NAME,
				uploadFileUrl: edi.utils.formatString(agreementPackagesAPI.LOAD_ATTAH.POST, {
					type: 'price_agreement_dr',
					containerType: 'dp_container_dr'
				}),
				uploadFileDocType: 'DP_PRILPROTSOGL',
				gridConfig: {
					cls: 'modal-grid-with-header',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					title: edi.i18n.getMessage('document.package.agreement.products.title')
				}
			}
		}
	],
	DP_PRILCOMMUSLDR: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [6, 4, [4, 4, 4], 4]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.DP_PRIL03COMMUSL.email'),
					name: 'documentContent.textInfos.email',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.amount.purchase.product'),
					name: 'documentContent.textInfos.amountPurchaseProduct',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusRC'),
					name: 'documentContent.textInfos.percentBonusRC',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusGM'),
					name: 'documentContent.textInfos.percentBonusGM',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.percentBonusMM'),
					name: 'documentContent.textInfos.percentBonusMM',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.oneTimePremium'),
					name: 'documentContent.textInfos.oneTimePremium',
					allowBlank: false
				}
			]
		},
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRILCOMMUSL_COLUMN_CONFIG_NAME,
				fields: [
					{
						type: 'container',
						layout: {
							type: 'grid',
							area: [6, 12, 6]
						},
						items: [
							{
								title: 'document.package.agreement.textInfos.title.productOfCode',
								name: 'productOfCode',
								allowBlank: false,
								//цифры или прочерк -
								regex: /^(\d+|-)$/i,
								regexText: 'document.package.agreement.textInfos.title.productOfCode.regex_error'
							},
							{
								title: 'document.package.agreement.textInfos.title.productOfName.trademark',
								name: 'productOfName',
								allowBlank: false,
								//буквы, цифры и пробелы или прочерк -
								regex: /^([a-zа-яёЁ\d\s]+|-)$/i,
								regexText:
									'document.package.agreement.textInfos.title.productOfName.trademark.regex_error'
							},
							{
								title: 'document.package.agreement.textInfos.title.percentage.premium',
								name: 'premium',
								allowBlank: false,
								//число в формате 123 или 123.123 или 123,123 или прочерк -
								regex: /^(\d+[.|,]\d+|\d+|-)$/i,
								regexText: 'document.package.agreement.textInfos.title.percentage.premium.regex_error'
							}
						]
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isHideDeleteManyBtn: false,
				isRowValid: function (record) {
					var recordData = record.getData();
					return !!recordData.productOfName && !!recordData.productOfCode && !!recordData.premium;
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				modalTitle: edi.i18n.getMessage('document.package.agreement.DP_PRILCOMMUSLNE.title'),
				model: PACKAGE_ATTACH_PRILCOMMUSL_MODEL_CONFIG_NAME,
				uploadFileUrl: edi.utils.formatString(agreementPackagesAPI.LOAD_ATTAH.POST, {
					type: 'dp_prilcommusldr_items',
					containerType: 'dp_container_ne'
				}),
				uploadFileDocType: 'DP_PRILCOMMUSLDR',
				gridConfig: {
					title: ' ',
					cls: 'modal-grid',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					disablePaging: true
				}
			}
		}
	],
	DP_PRILKPOSTDR: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [4, 6, [4, 4], 3, 6, 6, [3, 3], [3, 3], 4]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.shortNameAccounter'),
					name: 'documentContent.textInfos.nameSeller',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.address'),
					name: 'documentContent.textInfos.address',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.fioAccounter'),
					name: 'documentContent.textInfos.fioAccounter',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.fioDirector'),
					name: 'documentContent.textInfos.fioDirector',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.buyerNds'),
					name: 'documentContent.textInfos.buyerNds',
					type: 'combo',
					valueField: 'name',
					forceSelection: true,
					anyMatch: true,
					store: 'initDpAttachDocBuyerNds',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addConds'),
					name: 'documentContent.textInfos.addConds',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.okved'),
					name: 'documentContent.textInfos.okved',
					allowBlank: false
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.emailSRM1'),
					name: 'documentContent.textInfos.emailSRM1',
					allowBlank: false,
					regex: edi.constants.VALIDATORS.EMAIL,
					invalidText: edi.i18n.getMessage('invalid.email.format')
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.emailSRM2'),
					name: 'documentContent.textInfos.emailSRM2',
					allowBlank: false,
					regex: edi.constants.VALIDATORS.EMAIL,
					invalidText: edi.i18n.getMessage('invalid.email.format')
				},
				{
					title: edi.i18n.getMessage('document.package.agreement.textInfos.title.fio'),
					name: 'documentContent.textInfos.fio',
					allowBlank: false
				}
			]
		}
	],
	DP_PRILOSTRBDR: [
		{
			type: 'container',
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				{
					title: edi.i18n.getMessage('document.package.ne.agreement.textInfos.title.reviseEmail1'),
					name: 'documentContent.textInfos.reviseEmail1',
					allowBlank: false
				}
			]
		},
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRIL02OSTRB_COLUMN_CONFIG_NAME,
				fields: [
					{
						title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addressEntrance'),
						name: 'addressEntrance',
						allowBlank: false
					},
					{
						title: edi.i18n.getMessage('document.package.agreement.textInfos.title.addressReturn'),
						name: 'addressReturn',
						allowBlank: false
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isRowValid: function (record) {
					var recordData = record.getData();
					return !!recordData.addressEntrance && !!recordData.addressReturn;
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				modalTitle: edi.i18n.getMessage('document.package.agreement.PRIL02OSTRB.title'),
				model: PACKAGE_ATTACH_PRIL02OSTRB_MODEL_CONFIG_NAME,
				gridConfig: {
					title: ' ',
					cls: 'modal-grid',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					disablePaging: true
				}
			}
		}
	],
	DP_PRILKTOVDR: [
		{
			type: 'editableGrid',
			name: 'documentContent.textInfos',
			config: {
				columnsConfig: PACKAGE_ATTACH_PRILKTOVDR_COLUMN_CONFIG_NAME,
				fields: [
					{
						type: 'container',
						layout: {
							type: 'grid',
							area: [[6, 6], [6, 6], [4], 12, [6, 6], [6, 6], [6, 6], [6, 6], [4, 4, 4], 12, 12]
						},
						items: [
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productionName'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.productName.ttip'
								),
								name: 'productionName',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.productionCode'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.positionCode.ttip'
								),
								name: 'productionCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.vendorCode'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.vendorCode.ttip'
								),
								name: 'vendorCode',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.trademark'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.trademark.ttip'
								),
								name: 'trademark'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.tax'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.tax.ttip'),
								name: 'taxRate',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.unitOfMeasure'),
								type: 'fieldblock',
								layout: {
									type: 'grid',
									area: [[6, 6]]
								},
								createCustomItems: function (_conf, src, { readOnly, disabled }) {
									return [
										createTextField({
											fieldLabel: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasureMeter'
											),
											qtipText: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasureMeter.ttip'
											),
											name: 'unitOfMeasureMeter',
											allowBlank: false,
											valueSrc: src,
											readOnly,
											disabled
										}),
										createTextField({
											fieldLabel: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasure'
											),
											qtipText: edi.i18n.getMessage(
												'document.package.agreement.textInfos.title.unitOfMeasure.ttip'
											),
											name: 'unitOfMeasure',
											valueSrc: src,
											readOnly,
											disabled
										})
									];
								}
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.unitOfMeasure'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.unitOfMeasure.ttip'
								),
								name: 'unitOfMeasure'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.shelfLife'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.shelfLife.ttip'
								),
								name: 'shelfLife'
							},
							{
								title: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.storageTemperature'
								),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.storageTemperature.ttip'
								),
								name: 'storageTemperature'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.batch'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.batch.ttip'),
								name: 'batch'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.bulk'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.bulk.ttip'),
								name: 'bulk'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.weightOfUnit'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.weightOfUnit.ttip'
								),
								name: 'weightOfUnit'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.itemWeight'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.itemWeight.ttip'
								),
								name: 'itemWeight'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.manufacturer'),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.manufacturer.ttip'
								),
								name: 'manufacturer',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.countryManufacturer'
								),
								qtipText: edi.i18n.getMessage(
									'document.package.agreement.textInfos.title.countryManufacturer.ttip'
								),
								name: 'countryManufacturer'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.things'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.things.ttip'),
								name: 'things'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.boxes'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.boxes.ttip'),
								name: 'boxes'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.block'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.block.ttip'),
								name: 'block'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.props'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.props.ttip'),
								name: 'props'
							},
							{
								title: edi.i18n.getMessage('document.package.agreement.textInfos.title.delay'),
								qtipText: edi.i18n.getMessage('document.package.agreement.textInfos.title.delay.ttip'),
								name: 'delay'
							}
						]
					}
				],
				useCanDeleteHandlerForAllRows: true,
				isHideDeleteManyBtn: false,
				isRowValid: function (record) {
					var recordData = record.getData();
					return (
						!!recordData.productionName &&
						!!recordData.productionCode &&
						!!recordData.vendorCode &&
						!!recordData.taxRate &&
						!!recordData.unitOfMeasureMeter &&
						!!recordData.manufacturer
					);
				},
				isGridValid: function (gridStore) {
					return gridStore.getCount();
				},
				limit: undefined,
				model: PACKAGE_ATTACH_PRILKTOVDR_MODEL_CONFIG_NAME,
				modalTitle: edi.i18n.getMessage('document.package.agreement.PRIL06KTOV.title'),
				uploadFileUrl: edi.utils.formatString(agreementPackagesAPI.LOAD_ATTAH.POST, {
					type: 'prodcat_dr',
					containerType: 'dp_container_dr'
				}),
				uploadFileDocType: 'DP_PRIL06KTOV',
				gridConfig: {
					title: ' ',
					cls: 'modal-grid',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					disablePaging: true
				}
			}
		}
	],
	DP_PRILUVIZMCENDR: [],
	DP_PRILSHSVERDR: [],
	DP_PRILFORMZVTDR: [],
	DP_PRILFAOPTUZDR: [],
	DP_PRILSANKDR: [],
	DP_PRILANTIKORDR: [],
	DP_PRILORGEDOCDR: []
});

const dpContainerSign = function (dpContainerData, tab, certificate, poa) {
	let certificateHandler = {
		_selectedCertificate: certificate,
		get: function () {
			return this._selectedCertificate;
		},
		set: function (cert) {
			this._selectedCertificate = cert;
		}
	};
	let poaHandler = {
		_selectedPoa: poa,
		get: function () {
			return this._selectedPoa;
		},
		set: function (poa) {
			this._selectedPoa = poa;
		},
		_poaConfirmChecked: false,
		setPoaConfirmCheck: function (poaConfirmChecked) {
			const handler = this;
			handler._poaConfirmChecked = poaConfirmChecked;
		},
		getPoaConfirmCheck: function () {
			const handler = this;
			return handler._poaConfirmChecked;
		}
	};

	var failure = edi.document.actions.defaultFailureHandler(tab, 'error.getting.data');

	var startSignAttachments = function () {
		var documentsOnSign = [];
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
				documentId: dpContainerData.id
			}),
			'GET',
			undefined,
			function (data) {
				if (data && data.data && data.data.children && data.data.children.length) {
					for (var i = 0; i < data.data.children.length; i++) {
						if (data.data.children[i].state === edi.constants.STATE.SENDER_REVIEW) {
							documentsOnSign.push(data.data.children[i]);
						}
					}
					processSigningSelection(documentsOnSign);
				} else {
					failure(data);
				}
			},
			failure
		);
	};

	var signOne = function (data, callback, signRefuse, contentId) {
		edi.utils.sign(
			{
				id: data.id,
				type: data.type
			},
			undefined,
			function (failure, resultData) {
				if (failure) {
					if (
						resultData.typeError === 'controller.document.cant.get.content.to.sign.error' &&
						resultData.hasOwnProperty('additionalData') &&
						Array.isArray(resultData.additionalData)
					) {
						resultData.additionalData[2] = resultData.additionalData[2] || '';
						resultData.additionalData[5] = resultData.additionalData[5] || '';
						confirmChangeSigner(dpContainerData.id, resultData.additionalData);
					} else {
						callback(
							data,
							edi.utils.formatComplexServerError(resultData, 'accept.registry.error.document.sign')
						);
					}
				} else {
					callback();
				}
			},
			contentId,
			undefined,
			true,
			certificateHandler,
			{
				signUrlMethod: 'PUT',
				signRefuse: function () {
					tab.setLoading(false);
					'function' == typeof signRefuse ? signRefuse() : null;
				}
			},
			poaHandler
		);
	};
	/**
	 *
	 * @param docs
	 * @returns {string}
	 */
	var createErrorDocumentsList = function (docs) {
		var htmlOut = '<table class="documents-list">';
		htmlOut +=
			'<thead><th>' +
			edi.i18n.getMessage('documents.list.document.number') +
			'</th><th>' +
			edi.i18n.getMessage('documents.list.document.type') +
			"</th><th colspan='2'>" +
			edi.i18n.getMessage('documents.list.document.error') +
			'</th></thead>';
		htmlOut += '<tbody>';
		if (docs && docs.length) {
			for (var i = 0; i < docs.length; i++) {
				htmlOut += '<tr>';
				htmlOut += '<td>' + docs[i]['number'] + '</td>';
				htmlOut += '<td>' + edi.renderers.doctype(docs[i]['type']) + '</td>';
				htmlOut += "<td colspan='2'>" + docs[i]['errorData'] + '</td>';
				htmlOut += '</tr>';
			}
		}
		htmlOut += '</tbody></table>';
		return htmlOut;
	};
	/**
	 * Initiates signing of selected rows
	 * @param    {Array}      documents            collection of data we are working with
	 */
	var processSigningSelection = function (documents) {
		var total,
			signed = 0,
			processed = 0,
			errors = [],
			progress,
			signCollection;
		tab.setLoading();
		var processSign = function (data, error) {
			if (data) {
				errors.push({
					number: data.number,
					type: data.type,
					date: data.date,
					amount: data.amount,
					errorData: error,
					header: data.header,
					isMain: data.isMain
				});
			} else if (signCollection.length < total) {
				signed++;
			}
			if (signCollection.length) {
				data = signCollection.shift();
				var addedDocTypes = [];
				if (dpContainerData.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE) {
					addedDocTypes = Ext.Array.difference(
						edi.constants.DOCUMENT_PACKAGES_NE_INCLUDES_DOC_TYPES,
						edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES.NE
					);
				} else if (dpContainerData.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR) {
					addedDocTypes = Ext.Array.difference(
						edi.constants.DOCUMENT_PACKAGES_DR_INCLUDES_DOC_TYPES,
						edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES.DR
					);
				}
				var isAttachDoc =
					edi.constants.DOCUMENT_PACKAGES_INCLUDES_DOC_TYPES.some((it) => it === data.type) ||
					addedDocTypes.some((it) => it === data.type);
				var docTitle = edi.i18n.getMessage('documents.doctype.' + data.type) + ' № ' + data.number;
				if (processed) {
					var percent = Math.round(((processed + 1) / total) * 100);
					var progressText = edi.utils.formatString(edi.i18n.getMessage('progress.sign.text'), {
						current: processed + 1,
						total: total,
						percent: percent
					});
					if (!progress) {
						progress = showMessageBox({
							closable: false,
							title: edi.i18n.getMessage('progress.sign.documents'),
							msgText: docTitle,
							progress: true,
							progressBarConfig: {
								title: progressText
							}
						});
					} else {
						progress.updateProgress(percent / 100, progressText, docTitle);
					}
				}
				if (isAttachDoc) {
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
							documentId: data.id
						}),
						'GET',
						undefined,
						function (partsData) {
							if (
								partsData &&
								partsData.data &&
								partsData.data.children &&
								partsData.data.children.length
							) {
								for (var j = 0; j < partsData.data.children.length; j++) {
									if (partsData.data.children[j].type.slice(-3) === '_P1') {
										signOne(
											data,
											processSign,
											function () {
												if (progress) {
													progress.hide();
												}
												rollbackToDraft();
											},
											partsData.data.children[j].id
										);
									}
								}
							}
						},
						undefined,
						function () {
							processed++;
						}
					);
				} else if (
					[
						...edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES.LEGAL_ENTITY,
						...edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES.INDIVIDUAL,
						...edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES.NE,
						...edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES.DR
					].some((it) => it === data.type)
				) {
					signOne(data, processSign, function () {
						if (progress) {
							progress.hide();
						}
						rollbackToDraft();
					});
					processed++;
				} else {
					processSign();
					processed++;
				}
			} else {
				certificateHandler.set(null);
				poaHandler.set(null);
				if (progress) {
					progress.hide();
				}
				progress = null;
				if (!errors.length) {
					edi.core.showInfo(
						edi.utils.formatString(edi.i18n.getMessage('progress.sign.success'), {
							amount: signed,
							total: total
						})
					);
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: dpContainerData.id
							},
							true
						),
						'PUT',
						Ext.encode({}),
						function () {
							edi.events.documents.fireEvent('change', {
								id: dpContainerData.id
							});
							edi.events.documentsPackage.fireEvent('change', {
								id: dpContainerData.id,
								isSign: true
							});
						},
						failure
					);
				} else {
					edi.core.showWarn(
						edi.utils.formatString(edi.i18n.getMessage('progress.sign.warn'), {
							amount: signed,
							total: total,
							errors: createErrorDocumentsList(errors)
						}),
						function () {
							edi.events.documentsPackage.fireEvent('change', {
								id: dpContainerData.id
							});
						}
					);
				}
				tab.setLoading(false);
			}
		};

		signCollection = documents;
		total = signCollection.length;
		processSign();
	};

	var rollbackToDraft = function () {
		tab.setLoading(true);
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SEND.PUT,
				{
					documentId: dpContainerData.id
				},
				true
			),
			'PUT',
			Ext.encode({ RETURN: true }),
			function () {
				tab.setLoading(false);
				edi.events.documentsPackage.fireEvent('change', {
					id: dpContainerData.id
				});
			},
			function () {
				tab.setLoading(false);
				edi.document.actions.defaultFailureHandler(tab, 'error.server');
			}
		);
	};

	/**
	 * Show the modal with the ability to replace the data in the package documents with the data from the certificate.
	 * Called when document signer FIO and certificate FIO not equal.
	 * @param {Number} docId - package Id
	 * @param {Array} data - part of backend response with document signer data and chosen certificate data. Where
	 *                        keys mean: 0 - doc first name, 1 - doc last name, 2 - doc middle name,
	 *                                   3 - cert first name, 4 - cert last name, 5 - cert middle name
	 */
	var confirmChangeSigner = function (docId, data) {
		tab.setLoading(false);
		var confirmModal = edi.core.confirm(
			edi.i18n.getMessage('warn'),
			edi.i18n.getMessage('controller.document.cant.get.content.to.sign.error', {
				docFio: data[1] + ' ' + data[0] + ' ' + data[2],
				certFio: data[4] + ' ' + data[3] + ' ' + data[5]
			}),
			undefined,
			undefined,
			undefined,
			undefined,
			{
				closable: false,
				buttons: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('form.btn.replace'),
						handler: function () {
							tab.setLoading(true);

							var params = {
								firstName: data[3],
								lastName: data[4],
								middleName: data[5] || null
							};
							var failure = edi.document.actions.defaultFailureHandler(tab, 'error.server');

							edi.rest.sendRequest(
								edi.utils.formatString(agreementPackagesAPI.SIGNER_DATA_UPDATE.PUT, {
									docId: docId,
									containerType: edi.utils.getObjectProperty(dpContainerData, 'type').toLowerCase()
								}),
								'PUT',
								Ext.encode(params),
								showSuccessSignerChangeModal,
								failure
							);
							confirmModal.close();
						}
					}),
					createButton({
						cls: BUTTON_CLS.secondary,
						text: edi.i18n.getMessage('btn.cancel'),
						handler: function () {
							confirmModal.close();
							rollbackToDraft();
						}
					})
				]
			}
		);
	};

	/**
	 * Show user that signer has been changed and restart to signing documents
	 */
	var showSuccessSignerChangeModal = function () {
		edi.core.showInfo(
			edi.i18n.getMessage('documents.package.change.signer.info.success'),
			function () {
				dpContainerData.wasCreated = true;
				dpContainerSign(dpContainerData, tab, certificateHandler.get(), poaHandler.get());
			},
			undefined,
			{
				closable: false
			}
		);
	};

	if (
		dpContainerData.state === edi.constants.STATE.SENDER_REVIEW ||
		(dpContainerData.hasOwnProperty('wasCreated') && dpContainerData.wasCreated)
	) {
		startSignAttachments();
	} else {
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SEND.PUT,
				{
					documentId: dpContainerData.id
				},
				true
			),
			'PUT',
			Ext.encode({}),
			function () {
				startSignAttachments();
				tab.setLoading(false);
				edi.events.documentsPackage.fireEvent('change', {
					id: dpContainerData.id
				});
				edi.events.documents.fireEvent('change');
			},
			function (data) {
				if (
					data.typeError === 'controller.document.not.enough.documents.to.fill.the.contract.error' &&
					data.additionalData.length &&
					Array.isArray(data.additionalData[0])
				) {
					var errorsAttachDocTypes = data.additionalData[0].map(function (item) {
						var docTypeTitle = edi.i18n.getMessage('documents.doctype.' + item);
						if (docTypeTitle === 'documents.doctype.' + item) {
							docTypeTitle = edi.i18n.getMessage('documents.doctype.' + item);
						}
						return docTypeTitle;
					});
					edi.core.showError(
						edi.i18n.getMessage('controller.document.not.enough.documents.to.fill.the.contract.error', [
							'<br />' + errorsAttachDocTypes.join(',<br />')
						]),
						function () {
							tab.setLoading(false);
						}
					);
				} else {
					edi.core.showError(
						edi.utils.formatComplexServerError(data, 'payments.factory.error.removing.instruction'),
						function () {
							tab.setLoading(false);
						}
					);
				}
			}
		);
	}
};

/**
 * convert organization to package partie
 * @param    {Object}    org        organization's data
 * @returns    {Object}            legacy partie's data
 */
const convertOrgToPackageDocumentsPartie = function (org) {
	var orgData = edi.converters.convertOrgToPartie(org, {
		separateMainData: false
	});

	if (orgData.company_util_reg_nr) {
		orgData.company_register_number = orgData.company_util_reg_nr;
	}
	if (orgData.type == 'individual') {
		orgData.individual_inn = orgData.company_inn;
		orgData.individual_certificateRegistrationIP = orgData.company_util_reg_nr;
		delete orgData.company_name;
		delete orgData.company_inn;
		delete orgData.company_kpp;
		delete orgData.company_gln;
	}

	return orgData;
};
/**
 *
 * @param       {String}    dpAttachDocType
 * @param       {Object}    values
 * @param       {Object}    textInfosObj
 * @param       {Object}    dogPostData
 * @param       {Object}    formFields
 * @returns     {Object}
 */
const convertFieldValueToTextInfos = function (dpAttachDocType, values, textInfosObj, dogPostData, formFields) {
	var concatAddress = function (dataValues) {
		var fullAddress = [],
			addressRu = edi.utils.getObjectProperty(dataValues, 'documentInfo.seller.legalAddress.addressRu');
		if (addressRu && 'object' == typeof addressRu) {
			if (addressRu.index) {
				fullAddress.push(addressRu.index);
			}
			if (addressRu.regionCode) {
				var regionsStore = edi.stores.initRegionsStore();
				var regionRec = regionsStore.findRecord('id', addressRu.regionCode);
				var regionName = regionRec.get('name');
				if (regionName) {
					fullAddress.push(regionName);
				}
			}
			if (addressRu.area) {
				fullAddress.push(addressRu.area);
			}
			if (addressRu.city) {
				fullAddress.push(addressRu.city);
			}
			if (addressRu.settlement) {
				fullAddress.push(addressRu.settlement);
			}
			if (addressRu.street) {
				fullAddress.push(addressRu.street);
			}
			if (addressRu.house) {
				fullAddress.push(addressRu.house);
			}
			if (addressRu.housing) {
				fullAddress.push(addressRu.housing);
			}
			if (addressRu.flat) {
				fullAddress.push(addressRu.flat);
			}
		}
		return fullAddress.length ? fullAddress.join(', ') : null;
	};

	var signerFio = function (dataValues) {
		var fullSigner = [],
			signer = edi.utils.getObjectProperty(dataValues, 'documentInfo.seller.signer');
		if (signer && 'object' == typeof signer) {
			if (signer.name.lastName) {
				fullSigner.push(signer.name.lastName);
			}
			if (signer.name.firstName) {
				fullSigner.push(signer.name.firstName);
			}
			if (signer.name.middleName) {
				fullSigner.push(signer.name.middleName);
			}
		}
		return fullSigner.length ? fullSigner.join(' ') : null;
	};

	var ulNameConverter = function (values, ulDataIndex, ipDataIndex) {
		var personDataIndex = ipDataIndex ? ipDataIndex : 'documentInfo.seller.member.name.fullName';
		var personName = edi.utils.getObjectProperty(values, personDataIndex);
		if (personName && 'object' == typeof personName) {
			var fullName = [];
			if (personName.lastName) {
				fullName.push(personName.lastName);
			}
			if (personName.firstName) {
				fullName.push(personName.firstName);
			}
			if (personName.middleName) {
				fullName.push(personName.middleName);
			}
			return fullName.length ? 'ИП ' + fullName.join(' ') : null;
		} else {
			var dataIndex = ulDataIndex ? ulDataIndex : 'documentInfo.seller.member.memberLegalEntity.orgName';
			return edi.utils.getObjectProperty(values, dataIndex);
		}
	};

	var convertMap = {
		DP_DOGPOSTDR: {
			inn: 'documentInfo.seller.identification.legalEntity.tin',
			kpp: 'documentInfo.seller.identification.legalEntity.kpp',
			urAddress: function () {
				return concatAddress(values);
			},
			email: 'documentInfo.seller.contact.mail',
			bik: 'documentInfo.seller.bankInfo.bank.bik',
			okpo: 'documentInfo.seller.okpo',
			schet: 'documentInfo.seller.bankInfo.accountNumber',
			bankName: 'documentInfo.seller.bankInfo.bank.bankName',
			CorSchet: 'documentInfo.seller.bankInfo.bank.coreAccountNumber',
			okato: 'documentInfo.seller.okato',
			ogrn: 'documentInfo.seller.identification.legalEntity.ogrn',
			itemNumber: function (values) {
				var itemNumber = edi.utils.getObjectProperty(values, 'documentContent.textInfos.itemNumber');
				return Array.isArray(itemNumber) && itemNumber.length ? itemNumber.join(', ') : itemNumber;
			},
			ulName: function (values) {
				return ulNameConverter(
					values,
					'documentInfo.seller.identification.legalEntity.orgName',
					'documentInfo.seller.identification.individualPerson.name'
				);
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(
						values,
						'documentInfo.seller.identification.individualPerson'
					);
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			signerFio: function () {
				return signerFio(values);
			},
			signerPosition: 'documentInfo.seller.signer.position',
			signerBase: 'documentInfo.seller.signer.baseAuthory'
		},
		DP_PRILPROTSOGLDR: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: function (values) {
				return ulNameConverter(values, 'documentInfo.seller.member.legalEntity.orgName');
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILCOMMUSLDR: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			emailSeller: 'documentInfo.seller.contact.mail',
			ulName: function (values) {
				return ulNameConverter(values, 'documentInfo.seller.member.legalEntity.orgName');
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILKPOSTDR: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			nameSeller: ulNameConverter,
			urAddress: function () {
				var addrData = !edi.utils.getObjectProperty(values, 'documentInfo.seller.legalAddress.addressRu')
					? dogPostData
					: values;
				return concatAddress(addrData);
			},
			phone: 'documentInfo.seller.contact.telephone',
			tinSeller: function (values) {
				var personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				return personName && 'object' == typeof personName
					? personName.innFl
					: edi.utils.getObjectProperty(values, 'documentInfo.seller.member.memberLegalEntity.inn');
			},
			email2: 'documentInfo.seller.contact.mail',
			bik: 'documentInfo.seller.bankInfo.bank.bik',
			okpo: 'documentInfo.seller.okpo',
			kpp: 'documentInfo.seller.member.memberLegalEntity.kpp',
			schet: 'documentInfo.seller.bankInfo.accountNumber',
			bankName: 'documentInfo.seller.bankInfo.bank.bankName',
			CorSchet: 'documentInfo.seller.bankInfo.bank.coreAccountNumber',
			fioDirector: function () {
				var fullName = [],
					signer = edi.utils.getObjectProperty(values, 'documentInfo.seller.signer');

				if (signer && 'object' == typeof signer && signer.name) {
					if (signer.name.lastName) {
						fullName.push(signer.name.lastName);
					}
					if (signer.name.firstName) {
						fullName.push(signer.name.firstName);
					}
					if (signer.name.middleName) {
						fullName.push(signer.name.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			ulName: function (values) {
				return ulNameConverter(values, 'documentInfo.seller.member.legalEntity.orgName');
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILOSTRBDR: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: function (values) {
				return ulNameConverter(values, 'documentInfo.seller.member.legalEntity.orgName');
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILKTOVDR: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_DOGPOST: {
			inn: 'documentInfo.seller.identification.legalEntity.tin',
			kpp: 'documentInfo.seller.identification.legalEntity.kpp',
			urAddress: function () {
				return concatAddress(values);
			},
			email: 'documentInfo.seller.contact.mail',
			bik: 'documentInfo.seller.bankInfo.bank.bik',
			okpo: 'documentInfo.seller.okpo',
			schet: 'documentInfo.seller.bankInfo.accountNumber',
			bankName: 'documentInfo.seller.bankInfo.bank.bankName',
			CorSchet: 'documentInfo.seller.bankInfo.bank.coreAccountNumber',
			okato: 'documentInfo.seller.okato',
			ogrn: 'documentInfo.seller.identification.legalEntity.ogrn',
			itemNumber: function (values) {
				var itemNumber = edi.utils.getObjectProperty(values, 'documentContent.textInfos.itemNumber');
				return Array.isArray(itemNumber) && itemNumber.length ? itemNumber.join(', ') : itemNumber;
			},
			ulName: function (values) {
				return ulNameConverter(
					values,
					'documentInfo.seller.identification.legalEntity.orgName',
					'documentInfo.seller.identification.individualPerson.name'
				);
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(
						values,
						'documentInfo.seller.identification.individualPerson'
					);
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			signerFio: function () {
				return signerFio(values);
			},
			signerPosition: 'documentInfo.seller.signer.position',
			signerBase: 'documentInfo.seller.signer.baseAuthory'
		},
		DP_DOGPOSTNE: {
			inn: 'documentInfo.seller.identification.legalEntity.tin',
			kpp: 'documentInfo.seller.identification.legalEntity.kpp',
			urAddress: function () {
				return concatAddress(values);
			},
			email: 'documentInfo.seller.contact.mail',
			bik: 'documentInfo.seller.bankInfo.bank.bik',
			okpo: 'documentInfo.seller.okpo',
			schet: 'documentInfo.seller.bankInfo.accountNumber',
			bankName: 'documentInfo.seller.bankInfo.bank.bankName',
			CorSchet: 'documentInfo.seller.bankInfo.bank.coreAccountNumber',
			okato: 'documentInfo.seller.okato',
			ogrn: 'documentInfo.seller.identification.legalEntity.ogrn',
			itemNumber: function (values) {
				var itemNumber = edi.utils.getObjectProperty(values, 'documentContent.textInfos.itemNumber');
				return Array.isArray(itemNumber) && itemNumber.length ? itemNumber.join(', ') : itemNumber;
			},
			ulName: function (values) {
				return ulNameConverter(
					values,
					'documentInfo.seller.identification.legalEntity.orgName',
					'documentInfo.seller.identification.individualPerson.name'
				);
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(
						values,
						'documentInfo.seller.identification.individualPerson'
					);
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			signerFio: function () {
				return signerFio(values);
			},
			signerPosition: 'documentInfo.seller.signer.position',
			signerBase: 'documentInfo.seller.signer.baseAuthory'
		},
		DP_PRIL01ORGEDOC: {
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRILORGEDOCNE: {
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRIL02OSTRB: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILOSTRBNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: function (values) {
				return ulNameConverter(values, 'documentInfo.seller.member.legalEntity.orgName');
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILUVIZMCEN: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			emailSeller: 'documentInfo.seller.contact.mail',
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRIL03COMMUSL: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			emailSeller: 'documentInfo.seller.contact.mail',
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILCOMMUSLNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			emailSeller: 'documentInfo.seller.contact.mail',
			ulName: function (values) {
				return ulNameConverter(values, 'documentInfo.seller.member.legalEntity.orgName');
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRIL04SANK: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILSANKNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRIL05KPOST: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			nameSeller: ulNameConverter,
			urAddress: function () {
				var addrData = !edi.utils.getObjectProperty(values, 'documentInfo.seller.legalAddress.addressRu')
					? dogPostData
					: values;
				return concatAddress(addrData);
			},
			phone: 'documentInfo.seller.contact.telephone',
			tinSeller: function (values) {
				var personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				return personName && 'object' == typeof personName
					? personName.innFl
					: edi.utils.getObjectProperty(values, 'documentInfo.seller.member.memberLegalEntity.inn');
			},
			email: 'documentInfo.seller.contact.mail',
			bik: 'documentInfo.seller.bankInfo.bank.bik',
			okpo: 'documentInfo.seller.okpo',
			kpp: 'documentInfo.seller.member.memberLegalEntity.kpp',
			schet: 'documentInfo.seller.bankInfo.accountNumber',
			bankName: 'documentInfo.seller.bankInfo.bank.bankName',
			CorSchet: 'documentInfo.seller.bankInfo.bank.coreAccountNumber',
			fioDirector: function () {
				var fullName = [],
					signer = edi.utils.getObjectProperty(values, 'documentInfo.seller.signer');

				if (signer && 'object' == typeof signer && signer.name) {
					if (signer.name.lastName) {
						fullName.push(signer.name.lastName);
					}
					if (signer.name.firstName) {
						fullName.push(signer.name.firstName);
					}
					if (signer.name.middleName) {
						fullName.push(signer.name.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILKPOSTNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			nameSeller: ulNameConverter,
			urAddress: function () {
				var addrData = !edi.utils.getObjectProperty(values, 'documentInfo.seller.legalAddress.addressRu')
					? dogPostData
					: values;
				return concatAddress(addrData);
			},
			phone: 'documentInfo.seller.contact.telephone',
			tinSeller: function (values) {
				var personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				return personName && 'object' == typeof personName
					? personName.innFl
					: edi.utils.getObjectProperty(values, 'documentInfo.seller.member.memberLegalEntity.inn');
			},
			email2: 'documentInfo.seller.contact.mail',
			bik: 'documentInfo.seller.bankInfo.bank.bik',
			okpo: 'documentInfo.seller.okpo',
			kpp: 'documentInfo.seller.member.memberLegalEntity.kpp',
			schet: 'documentInfo.seller.bankInfo.accountNumber',
			bankName: 'documentInfo.seller.bankInfo.bank.bankName',
			CorSchet: 'documentInfo.seller.bankInfo.bank.coreAccountNumber',
			ulName: function (values) {
				return ulNameConverter(values, 'documentInfo.seller.member.legalEntity.orgName');
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRIL06KTOV: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRILKTOVNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			taxRate: 'documentContent.textInfos.tax'
		},
		DP_PRIL07SHSVER: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRIL08FORMZVT: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRIL09FAOPTUZ: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRIL10TOSGPT: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRILUVIZMCENNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRILSHSVERNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRILFORMZVTNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRILFAOPTUZNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber'
		},
		DP_PRIL11ANTIKOR: {
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			signerFio: function () {
				return signerFio(values);
			},
			signerPosition: 'documentInfo.seller.signer.position',
			signerBase: 'documentInfo.seller.signer.baseAuthory'
		},
		DP_PRILANTIKORNE: {
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			},
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			signerFio: function () {
				return signerFio(values);
			},
			signerPosition: 'documentInfo.seller.signer.position',
			signerBase: 'documentInfo.seller.signer.baseAuthory'
		},
		DP_PRILPROTSOGL: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: ulNameConverter,
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		},
		DP_PRILPROTSOGLNE: {
			linkDocDate: 'documentInfo.documentDate',
			linkDocNumber: 'documentInfo.documentNumber',
			ulName: function (values) {
				return ulNameConverter(values, 'documentInfo.seller.member.legalEntity.orgName');
			},
			ipFio: function (values) {
				var fullName = [],
					personName = edi.utils.getObjectProperty(values, 'documentInfo.seller.member.name');
				if (personName && 'object' == typeof personName) {
					if (personName.lastName) {
						fullName.push(personName.lastName);
					}
					if (personName.firstName) {
						fullName.push(personName.firstName);
					}
					if (personName.middleName) {
						fullName.push(personName.middleName);
					}
				}
				return fullName.length ? fullName.join(' ') : null;
			}
		}
	};

	var isEditableField = function (key) {
		var fullKey = convertMap[dpAttachDocType][key];
		if (formFields[fullKey] !== undefined && formFields[fullKey]['el'] !== undefined) {
			return formFields[fullKey]['el'].isVisible();
		} else {
			return false;
		}
	};

	if (convertMap.hasOwnProperty(dpAttachDocType)) {
		for (var infPolKey in convertMap[dpAttachDocType]) {
			if (convertMap[dpAttachDocType].hasOwnProperty(infPolKey)) {
				var fieldVal;
				if ('string' == typeof convertMap[dpAttachDocType][infPolKey]) {
					fieldVal = edi.utils.getObjectProperty(values, convertMap[dpAttachDocType][infPolKey]);
					if (!fieldVal && !isEditableField(infPolKey)) {
						fieldVal = edi.utils.getObjectProperty(dogPostData, convertMap[dpAttachDocType][infPolKey]);
					}
				} else if ('function' == typeof convertMap[dpAttachDocType][infPolKey]) {
					fieldVal = convertMap[dpAttachDocType][infPolKey](values);
				}
				if (fieldVal) {
					textInfosObj[infPolKey] = fieldVal;
				}
			}
		}
	}
};

export {
	getDocumentForms,
	getDocumentFormsNE,
	getDocumentFormsDR,
	dpContainerSign,
	convertOrgToPackageDocumentsPartie,
	convertFieldValueToTextInfos
};
