import { createAddModulePanel, createForm, createFormForModule, createPanel } from '@Components/panels';
import { createFieldsForProductGrid, createNumberField, createTextField } from '@Components/fields';
import { moduleSettings, roseuInvitationsEvents } from './moduleCommons.js';
import { createCreateSaveButton } from '@Components/buttons';
import { createSimpleSelector } from '@Components/simple.selector';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { ROSEU_INVITATION_RECEIVER_COLUMNS_CONFIG_NAME } from './columns';
import { ROSEU_INVITATION_RECEIVER_MODEL_CONFIG_NAME } from './models';
import { createFieldBlock } from '@UIkit/components/panels';
import { createFileDropper } from '@Components/FileDropper/FileDropper';

Ext.namespace('edi.modulesCfg');
edi.modulesCfg['roseu_invitation.create'] = {
	title: 'roseu_invitation.create',
	modName: 'roseu_invitation.create',
	permissions: ['CREATE_ROSEU_INVITATION'],
	permissionsEdit: ['EDIT_ROSEU_INVITATION']
};

edi.constants.CREATE_MODULE_NAME_BY_TYPE[moduleSettings.TYPE] = 'roseu_invitation.create';

Ext.namespace('edi.modules');
edi.modules['roseu_invitation.create'] = function () {
	let moduleData,
		invitationHeader,
		invitationContent,
		isEdit,
		moduleForm,
		receiver,
		uploadForm,
		maxFileSize,
		fileValue,
		createSaveBtn;

	/**
	 * Module initialization
	 * @param	{Object}	data			Data from openModule
	 * @param	{Function}	initCallBack	After initialization callback
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		invitationHeader = moduleData.initData.data || {};
		isEdit = !!invitationHeader.id;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Uploads file if it is needed
	 * @param	{Function}	afterUploadCallback
	 */
	let uploadFile = function (afterUploadCallback) {
		let fail = function (resp) {
			let errorText = edi.utils.formatComplexServerError(
				resp,
				resp?.status ? `error.server.${resp.status}` : 'dsf.upload.failure'
			);
			edi.core.showError(errorText, () => moduleData.tab.setLoading(false));
		};

		let success = function (resp) {
			if (resp.data?.id) {
				afterUploadCallback(resp.data.id);
			} else {
				fail(resp);
			}
		};

		let fileCmp = uploadForm.getForm().findField('attachment');
		if (!fileCmp || !fileCmp.getValue()) {
			afterUploadCallback(null);
		} else {
			edi.core.submitUploadForm(
				uploadForm,
				moduleSettings.API.INVITATION.FILE.POST,
				'dsf.create.saving',
				success,
				fail
			);
		}
	};

	/**
	 * Saves invitation
	 */
	let save = function () {
		moduleData.tab.setLoading(true);

		uploadFile(function (fileId) {
			let fail = edi.document.actions.createSaveErrorHandler(isEdit, moduleData, moduleSettings.TYPE);

			let success = function (resp) {
				let header = isEdit ? invitationHeader : resp.data;
				roseuInvitationsEvents.fireEvent('change', { id: header.id });
				moduleData.tab.close();
				edi.core.openModule('roseu_invitation.details', header);
			};

			let currentOrg = edi.core.getUserData().org;
			let formValues = moduleForm.getValues();
			edi.utils.clearEmptyValues(formValues.receiver);
			formValues.sender = {
				orgName: currentOrg.name,
				id: currentOrg.fnsId,
				inn: currentOrg.inn,
				kpp: currentOrg.kpp
			};
			if (fileId) {
				formValues.file = fileId;
			}
			//костыли для валидации по xsd
			formValues.requestType = 'REQUEST';
			if (!isEdit) {
				formValues.sendingDate = '2022-01-01T00:00:01.123Z';
			}

			let values = isEdit
				? {
						UPDATE: true,
						UPDATE_PARAMS: { data: Ext.encode(formValues) }
				  }
				: {
						data: Ext.encode(formValues),
						number: '123', //бэк сам туда поставит UUID
						doctime: new Date().valueOf(),
						toOrgId: currentOrg.id,
						docType: moduleSettings.TYPE
				  };

			let url = !isEdit
				? moduleSettings.API.INVITATION.POST
				: edi.utils.formatString(moduleSettings.API.INVITATION.PUT, { id: invitationHeader.id });
			edi.rest.sendRequest(url, isEdit ? 'PUT' : 'POST', Ext.encode(values), success, fail);
		});
	};

	/**
	 * Checks module validity
	 * @returns	{Boolean}	validity
	 */
	let checkValid = function () {
		let receiverIsValid = receiver.isValid();
		let valid = moduleForm.isValid() && uploadForm.isValid() && receiverIsValid;

		createSaveBtn.setDisabled(!valid);
		return valid;
	};

	/**
	 * Opens modal for receiver selection
	 * @param	{Function}	callback
	 */
	let openSelectReceiverModal = function (callback) {
		let selectCallback = function (orgValues) {
			callback.call(receiver, {
				id: orgValues.fnsId,
				orgName: orgValues.name,
				inn: orgValues.inn,
				kpp: orgValues.kpp
			});
		};

		const formItemsMap = {
			name: createTextField({
				fieldLabel: edi.i18n.getMessage('organization'),
				name: 'name'
			}),
			inn: createNumberField({
				fieldLabel: edi.i18n.getMessage('company.inn.short'),
				name: 'inn'
			}),
			fnsId: createTextField({
				fieldLabel: edi.i18n.getMessage('company.fns.id'),
				name: 'fnsId'
			}),
			kpp: createTextField({
				fieldLabel: edi.i18n.getMessage('column.org.kpp'),
				name: 'kpp'
			})
		};

		const items = [
			createPanel({
				layout: {
					type: 'grid',
					area: [
						[6, 6],
						[6, 6]
					]
				},
				items: [formItemsMap.name, formItemsMap.inn, formItemsMap.fnsId, formItemsMap.kpp]
			})
		];

		createModalRemoteSelect(moduleSettings.API.ORGANIZATIONS.GET, selectCallback, {
			columns: ROSEU_INVITATION_RECEIVER_COLUMNS_CONFIG_NAME,
			model: ROSEU_INVITATION_RECEIVER_MODEL_CONFIG_NAME,
			createFormItemsMap: formItemsMap,
			createFilterFormItems: items,
			createArgs: (values) => values
		});
	};

	/**
	 * Creates receiver company selector
	 * @returns	{Object}	edi-simple-selector instance
	 */
	let createReceiverField = function () {
		receiver = createSimpleSelector({
			name: 'receiver',
			fieldValues: isEdit ? invitationContent.receiver : null,
			showSelectButton: true,
			showEditButton: true,
			isValidFunc: (values) => !!values?.id,
			callback: checkValid,
			getFormValuesFunc: (formPanel) => formPanel.getForm().getValues(),
			selectionModalFunc: openSelectReceiverModal,
			infoPanelComponentTpl: () =>
				new Ext.XTemplate(
					`<tpl if="!values || (!values.orgName && !values.id && !values.inn && !values.kpp)">
					<span class="edi-empty-label">&nbsp;</span>
				<tpl else>
					<div class="edi-company-row">
						<tpl if="orgName.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('column.org.name')}:</span>
							<span class="row-data"> {orgName} </span>
						</tpl>
					</div>
					<div class="edi-company-row">
						<tpl if="id.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.fns.id')}:</span>
							<span class="row-data"> {id} </span>
						</tpl>
					</div>
					<div class="edi-company-row">
						<tpl if="inn.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>
							<span class="row-data"> {inn} </span>
						</tpl>
						<tpl if="kpp.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>
							<span class="row-data"> {kpp} </span>
						</tpl>
					</div>
				</tpl>`
				),
			createFormPanel: (objectData, readOnly) =>
				createPanel({
					bodyPadding: '8 24 8 24',
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: createFieldsForProductGrid(
						[
							{
								title: 'column.org.name',
								type: 'text',
								name: 'orgName',
								allowBlank: true
							},
							{
								title: 'company.fns.id',
								type: 'text',
								name: 'id',
								allowBlank: false,
								maskRe: /[a-zA-Z0-9-]/,
								regex: edi.constants.VALIDATORS.FNS_ID,
								minLength: 4
							},
							{
								title: 'company.inn.short',
								type: 'text',
								name: 'inn',
								allowBlank: true,
								maskRe: /\d/i,
								regex: edi.constants.VALIDATORS.INN,
								minLength: 10,
								maxLength: 12
							},
							{
								title: 'company.kpp.short',
								type: 'text',
								name: 'kpp',
								allowBlank: true,
								maskRe: /\d/i,
								regex: edi.constants.VALIDATORS.KPP,
								regexText: edi.i18n.getMessage('company.kpp.error')
							}
						],
						objectData,
						{},
						{
							readOnly: readOnly ? readOnly : false
						}
					)
				})
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('documents.receiver'),
			cls: `edi-form-maxwidth`,
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [receiver]
		});
	};

	/**
	 * Creates form for file upload
	 * @returns	{Object}	Ext.form.Panel instance
	 */
	let createUploadForm = function () {
		const { fileName, fileSize, modifyDate } = invitationContent?.file ?? {};
		uploadForm = createForm({
			cls: `edi-form-maxwidth`,
			layout: {
				type: 'grid',
				area: [6]
			},
			submitEmptyText: false,
			items: [
				createFileDropper({
					name: 'attachment',
					itemId: 'fileDropper',
					allowBlank: true,
					maxFileSize,
					checkMaxFileSize: true,
					getAllowedFileTypesText: () =>
						edi.i18n.getMessage('document.dsf.upload.file.types.and.limit', {
							limit: maxFileSize
						}),
					fileData:
						isEdit && !!invitationContent?.file
							? {
									fileName,
									fileDate: edi.utils.formatDate(modifyDate, edi.constants.DATE_FORMAT.FNS),
									fileTime: edi.utils.formatDate(modifyDate, edi.constants.DATE_FORMAT.TIME),
									fileSize: edi.utils.formatFileSize(fileSize)
							  }
							: null
				})
			]
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('document.file'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: 0
			},
			items: uploadForm
		});
	};

	/**
	 * Creates module's form
	 * @returns	{Object}	Ext.form.Panel instance
	 */
	let createModuleForm = function () {
		const comment = createTextField({
			name: 'comment',
			isTextarea: true,
			height: 100,
			maxLength: 255,
			valueSrc: invitationContent
		});

		moduleForm = createFormForModule({
			cls: 'edi-details-panel',
			items: [
				createReceiverField(),
				createUploadForm(),
				createFieldBlock({
					title: edi.i18n.getMessage('roseu_invitation.comment'),
					cls: 'edi-form-maxwidth',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					layout: {
						type: 'grid',
						area: [6]
					},
					items: [comment]
				})
			],
			buttons: [
				(createSaveBtn = createCreateSaveButton(
					{
						handler: save
					},
					isEdit
				))
			]
		});

		moduleForm.on('validitychange', checkValid);
		uploadForm.on('validitychange', checkValid);
		checkValid();

		return moduleForm;
	};

	/**
	 * Get from backend maxFileSize setting
	 * @param	{Function}	callback
	 */
	let getMaxFileSizeSetting = function (callback) {
		const invitationLimit = 'LIMIT_SIZE_INVITATION';

		let failure = function (data) {
			edi.core.showError(
				edi.utils.formatComplexServerError(
					data,
					data?.status ? 'error.server.' + data.status : 'dsf.get.limit.file.size.failure'
				),
				function () {
					maxFileSize = 1;
					callback();
				}
			);
		};

		let success = function (resp) {
			const { data } = resp;
			maxFileSize = data ? parseInt(data) : undefined;

			if (Ext.isNumber(maxFileSize)) {
				maxFileSize = maxFileSize > 10 ? 10 : maxFileSize;
				callback();
			} else {
				failure();
			}
		};

		const url = edi.utils.formatString(edi.rest.services.SERVER.SETTING.GET, {
			configuration_code: invitationLimit
		});
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};

	/**
	 * Gets invitation's content from backend
	 * @param	{Function}	callback
	 */
	let getInvitationContent = function (callback) {
		if (isEdit) {
			let fail = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				edi.modulesHandler.removeModule(moduleData);
			});

			let success = function (resp) {
				invitationContent = Ext.clone(resp.data);
				fileValue = {
					id: edi.utils.getObjectProperty(invitationContent, 'file.id'),
					name: edi.utils.getObjectProperty(invitationContent, 'file.fileName')
				};
				callback();
			};

			let url = edi.utils.formatString(moduleSettings.API.INVITATION.GET_CONTENT, { id: invitationHeader.id });
			edi.rest.sendRequest(url, 'GET', {}, success, fail);
		} else {
			callback();
		}
	};

	/**
	 * Creates module's entrails
	 * @param	{Function}	[initCallBack]
	 */
	let renderData = function (initCallBack) {
		getMaxFileSizeSetting(function () {
			getInvitationContent(function () {
				moduleData.tab.removeAll();

				const modulePanel = createAddModulePanel();
				modulePanel.add(createModuleForm());
				moduleData.tab.add(modulePanel);

				if ('function' == typeof initCallBack) {
					initCallBack();
				}
			});
		});
	};

	/**
	 * Routine that must be done before module destroy
	 */
	let onDestroy = function () {};
};
