import {
	createModalForm,
	FIELD_BLOCK_CLS,
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createRangeContainer,
	createContainer
} from '@UIkit/components/panels';
import {
	createDateField,
	createTimeField,
	createLabelBlockForDetails,
	createLabelForDetails
} from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createDeliveryCoordination } from '@Edi/specialComponents/deliveryCoordination';
import { ORDRSP_LINE_MODEL, ORDRSP_SUMMARY_MODEL } from './models';
import { ORDRSP_APERAK_LINE_COLUMN, ORDRSP_LINE_COLUMN, ORDRSP_SUMMARY_COLUMN } from './columns';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS, createActionsButton, createButton } from '@UIkit/components/buttons';
import { orderRespSelectors } from './selectors';

/**
 * Class for ordrsp details
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.details.ordrsp'] = function () {
	var moduleData,
		productValues = {
			products: []
		},
		headerFields,
		productFields,
		productsGrid,
		customFieldsContainer,
		topPath = '//Document-OrderResponse/';

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};
	/**
	 * Creates order response form
	 * @returns {Object}
	 */
	var createDetailsPanel = function (ordrsp) {
		var parties = edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Parties');
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
		});

		const formatDate = function (date) {
			return edi.utils.formatDate(date, edi.constants.DATE_FORMAT.FNS);
		};

		const createMainDataBlock = function () {
			const nameCode = edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.DocumentNameCode');
			return createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.ordrsp.title'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						contents: [
							{
								title: edi.i18n.getMessage('order.resp.name.code'),
								text:
									nameCode && nameCode !== '231'
										? edi.i18n.getMessage('document.ordersp.name.code.' + nameCode)
										: ''
							},
							{
								title: edi.i18n.getMessage('documents.create.ordrsp.nr'),
								text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.OrderResponseNumber')
							},
							{
								title: edi.i18n.getMessage('date'),
								text: formatDate(
									edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.OrderResponseDate')
								)
							}
						]
					})
				]
			});
		};

		const createAdditionalDataBlock = function () {
			const orderNameCode = edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.Order.OrderNameCode');
			return createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.ordrsp.for.order'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						contents: [
							{
								title: edi.i18n.getMessage('document.ordrsp.ord.name'),
								text: orderNameCode
									? edi.i18n.getMessage('document.ordersp.order.name.code.' + orderNameCode)
									: ''
							},
							{
								title: edi.i18n.getMessage('documents.create.ordrsp.nr'),
								text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.Order.BuyerOrderNumber')
							},
							{
								title: edi.i18n.getMessage('date'),
								text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.Order.BuyerOrderDate')
							},
							{
								title: edi.i18n.getMessage('functional.document.code'),
								text: edi.i18n.getMessage(
									'functional.document.code.' +
										edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.DocumentFunctionCode')
								)
							},
							{
								title: edi.i18n.getMessage('ordrsp.currency'),
								text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.OrderResponseCurrency'),
								isNewLine: true
							}
						]
					})
				]
			});
		};

		const createPartiesBlock = function () {
			var createCS = function (selectorConfig) {
				Object.assign(selectorConfig, {
					showDetailsButton: true,
					valuesByMap: true,
					readOnly: true,
					fieldsMapOnly: true,
					is_valid: true,
					fieldValues: parties
				});

				return createOrgSelector(selectorConfig);
			};

			var buyer = createCS({
				selectedOrg: edi.utils.getOrg({
					orgId: moduleData.initData.data.toOrg.id
				}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				fieldsMap: orderRespSelectors.getBuyerFieldsMap(),
				modalConf: orderRespSelectors.getBuyerModalConf()
			});
			const buyerBlock = createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.buyer'),
				items: [buyer]
			});

			var seller = createCS({
				selectedOrg: edi.utils.getOrg({
					orgId: moduleData.initData.data.fromOrg.id
				}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				fieldsMap: orderRespSelectors.getSellerFieldsMap(),
				modalConf: orderRespSelectors.getSellerModalConf()
			});
			const sellerBlock = createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.seller'),
				items: [seller]
			});

			var shipFrom = createCS({
				fieldsMap: orderRespSelectors.getShipFromFieldsMap(),
				modalConf: orderRespSelectors.getShipFromModalConf()
			});
			const shipFromBlock = createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.ship.from'),
				items: [shipFrom]
			});

			var delivery = createCS({
				fieldsMap: orderRespSelectors.getDeliveryFieldsMap(),
				modalConf: orderRespSelectors.getDeliveryModalConf()
			});
			const deliveryBlock = createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.delivery'),
				items: [delivery]
			});

			return createFieldSetForDetails({
				title: edi.i18n.getMessage('document.ordrsp.section.parties'),
				collapsible: true,
				layout: {
					type: 'grid',
					area: [[6, 6], [6, 6], [6]]
				},
				items: [buyerBlock, sellerBlock, shipFromBlock, deliveryBlock]
			});
		};

		const createDeliveryInformationBlock = function () {
			const deliveryLabelText = [
				{
					title: edi.i18n.getMessage('document.ordersp.terms.of.delivery'),
					text: edi.utils.getObjectProperty(ordrsp, 'DetailsOfTransport.TermsOfDelivery')
						? edi.i18n.getMessage(
								'terms.of.delivery.' +
									edi.utils.getObjectProperty(ordrsp, 'DetailsOfTransport.TermsOfDelivery')
						  )
						: null
				},
				{
					title: edi.i18n.getMessage('document.ordersp.expected.delivery'),
					text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.ExpectedDeliveryDate')
				}
			];
			const deliveryData = createFieldBlockForDetails({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createLabelBlockForDetails({
						contents: deliveryLabelText
					})
				]
			});
			return createFieldSetForDetails({
				title: edi.i18n.getMessage('document.ordersp.section.delivery'),
				collapsible: true,
				layout: {
					type: 'grid',
					area: [12]
				},
				items: [deliveryData]
			});
		};

		const createAdditionalInformationBlock = function () {
			const orderCorrectionNumber = createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.ordersp.order.correction.number'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				layout: {
					type: 'grid',
					area: [3]
				},
				items: [
					createLabelForDetails({
						title: edi.i18n.getMessage('documents.create.ordrsp.nr'),
						text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.Order.OrderCorrectionNumber')
					})
				]
			});

			const agreement = createFieldBlockForDetails({
				title: edi.i18n.getMessage('document.ordersp.agreement'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				layout: {
					type: 'grid',
					area: [[3, 2]]
				},
				items: [
					createLabelBlockForDetails({
						contents: [
							{
								title: edi.i18n.getMessage('documents.create.ordrsp.nr'),
								text: edi.utils.getObjectProperty(
									ordrsp,
									'OrderResponse-Header.Reference.ContractNumber'
								)
							},
							{
								title: edi.i18n.getMessage('date'),
								text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.Reference.ContractDate')
							}
						]
					})
				]
			});

			//дополнительные поля
			var docHeaderFields = [],
				val;
			if (headerFields) {
				for (let i = 0; i < headerFields.length; i++) {
					val = edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.' + headerFields[i].field);
					if (headerFields[i].values && headerFields[i].values.length) {
						for (let j = 0; j < headerFields[i].values.length; j++) {
							if (headerFields[i].values[j].code == val) {
								val = headerFields[i].values[j].value;
								break;
							}
						}
					}
					docHeaderFields.push(
						createFieldBlockForDetails({
							title: edi.i18n.getMessage(
								'org.document.LEGACY_ORDER_RESP.part.docHeader.' + headerFields[i].field
							),
							userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
							items: [
								createLabelForDetails({
									text: val
								})
							]
						})
					);
				}
			}

			let additionalItems = [orderCorrectionNumber, agreement].concat(docHeaderFields);
			return createFieldSetForDetails({
				title: edi.i18n.getMessage('document.ordersp.section.additional'),
				collapsible: true,
				layout: {
					type: 'grid',
					area: [12, 12, 12, 12, 12, 12]
				},
				items: additionalItems
			});
		};

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		const ordrspRemarks = createFieldBlockForDetails({
			title: edi.i18n.getMessage('remarks'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.Remarks')
				})
			]
		});

		const specialInstructions = createFieldBlockForDetails({
			title: edi.i18n.getMessage('order.resp.special.instructions'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Header.SpecialInstructions')
				})
			]
		});

		const createProductsGrid = function () {
			var lines = edi.utils.getObjectProperty(ordrsp, 'OrderResponse-Lines.Line', true);
			if ('string' != typeof lines) {
				for (var i = 0; i < lines.length; i++) {
					var values = lines[i]['Line-Item'];
					values['Line-Reference'] = lines[i]['Line-Reference'];
					var lineReference = edi.utils.getObjectProperty(
						lines[i],
						'Line-Reference.Reference-Elements.Reference-Element',
						true
					);
					if (lineReference.length > 0) {
						lineReference.filter(function (item) {
							var referenceType = edi.utils.getObjectProperty(item, 'Reference-Type', false);
							return referenceType === 'spc';
						});
						edi.utils.setObjectProperty(
							values,
							'Line-Reference.Reference-Elements.Reference-Element',
							lineReference[0]
						);
					}

					values['TaxRate'] = edi.renderers.taxRateString(values['TaxRate']);
					if (productFields && productFields.length) {
						for (j = 0; j < productFields.length; j++) {
							if (values[productFields[j].field]) {
								if (productFields[j].values && productFields[j].values.length) {
									for (var k = 0; k < productFields[j].values.length; k++) {
										if (values[productFields[j].field] == productFields[j].values[k].code) {
											values[productFields[j].field] = productFields[j].values[k].value;
											break;
										}
									}
								}
							}
						}
					}
					values.BuyerItemDescription = lines[i]['Line-Order']?.BuyerItemDescription;
					const deliveryDate = lines[i]['Line-UltimateCustomer']?.DeliveryDate;
					if (deliveryDate) {
						const splitted = deliveryDate.split('T');
						values.DeliveryDateDate = splitted[0];
						values.DeliveryDateTime = splitted[1];
					}
					const deliveryPeriod = lines[i]['Line-UltimateCustomer']?.DeliveryPeriod;
					if (deliveryPeriod) {
						const splitted = deliveryPeriod.split('-');
						values.DeliveryPeriodFrom = splitted[0];
						values.DeliveryPeriodTo = splitted[1];
					}
					const stockReserve = lines[i]['Line-UltimateCustomer']?.StockReserveDateTime;
					if (stockReserve) {
						const splitted = stockReserve.split('T');
						values.StockReserveDate = splitted[0];
						values.StockReserveTime = splitted[1];
					}
					values.TermsOfDelivery = lines[i]['Line-UltimateCustomer']?.TermsOfDelivery;
					values.AddressText = lines[i]['Line-UltimateCustomer']?.AddressText;
					values.Floor = lines[i]['Line-UltimateCustomer']?.Floor;
					values.ModeOfTransport =
						lines[i]['Line-UltimateCustomer']?.['Line-Transport']?.[0]?.ModeOfTransport;
					values.VehicleHeight = lines[i]['Line-UltimateCustomer']?.['Line-Transport']?.[0]?.VehicleHeight;
					productValues.products.push(values);
				}
			}

			productsGrid = createProductGridBase({
				title: edi.i18n.getMessage('document.ordrsp.lines'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				gridModel: ORDRSP_LINE_MODEL,
				totalModel: ORDRSP_SUMMARY_MODEL,
				gridColumnConfig: ORDRSP_LINE_COLUMN,
				totalColumnConfig: ORDRSP_SUMMARY_COLUMN,
				data: productValues.products,
				readOnly: true,
				changeValuesBeforeEdit: function (values) {
					if (values.UnitOfMeasure) {
						const okeiStore = edi.stores.initLegacyOkeiStore();
						const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
						const rec = okeiStore.findRecordByName(val);
						if (rec) {
							values.UnitOfMeasure = rec.get('name_international');
						}
					}
				},
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
				docData: ordrsp,
				modalFormConfig: {
					title: 'document.ordrsp.line',
					modalFields: [
						{
							title: edi.i18n.getMessage('line.item.tab.product'),
							customFieldTab: 'PRODUCT',
							layout: {
								type: 'grid',
								gap: 16,
								area: [8, [4, 4, 5], 6, 8, 8, [3, 3], 8]
							},
							items: [
								{
									title: edi.i18n.getMessage('line.item.ean'),
									name: 'EAN'
								},
								{
									title: edi.i18n.getMessage('line.item.ordered.rsp.quantity'),
									name: 'OrderedQuantity',
									allowDecimals: true,
									type: 'number'
								},
								{
									title: edi.i18n.getMessage('line.item.ordered.allocatedDelivered'),
									name: 'AllocatedDelivered',
									allowDecimals: true,
									type: 'number'
								},
								{
									title: edi.i18n.getMessage('line.item.unit.of.measure'),
									type: 'okeiCode'
								},
								{
									title: edi.i18n.getMessage('line.item.item.type'),
									name: 'ItemType',
									type: 'combo',
									store: edi.stores.initItemTypeStore()
								},
								{
									title: edi.i18n.getMessage('line.item.product.description'),
									name: 'ItemDescription'
								},
								{
									title: edi.i18n.getMessage('line.item.product.buyer_description'),
									maxLength: 100,
									name: 'BuyerItemDescription'
								},
								{
									title: edi.i18n.getMessage('line.item.buyer.item.code'),
									name: 'BuyerItemCode'
								},
								{
									title: edi.i18n.getMessage('line.item.supplier.item.code'),
									name: 'SupplierItemCode'
								},
								{
									title: edi.i18n.getMessage('line.item.model'),
									name: 'Model'
								},
								{
									title: edi.i18n.getMessage('line.item.volume'),
									name: 'Volume'
								}
							]
						},
						{
							title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
							customFieldTab: 'PRICES',
							layout: {
								type: 'grid',
								gap: 16,
								area: [3, [3, 3], 3, [3, 3, 3]]
							},
							items: [
								{
									title: edi.i18n.getMessage('line.item.ordered.unit.net.price'),
									name: 'OrderedUnitNetPrice',
									allowDecimals: true,
									type: 'number'
								},
								{
									title: edi.i18n.getMessage('line.item.unit.gross.price'),
									name: 'OrderedUnitGrossPrice',
									allowDecimals: true,
									type: 'number'
								},
								{
									title: edi.i18n.getMessage('line.item.nds.rate'),
									name: 'TaxRate'
								},
								{
									title: edi.i18n.getMessage('line.item.net.amount'),
									name: 'NetAmount',
									allowDecimals: true,
									type: 'number'
								},
								{
									title: edi.i18n.getMessage('line.item.tax.amount'),
									name: 'TaxAmount',
									allowDecimals: true,
									type: 'number'
								},
								{
									title: edi.i18n.getMessage('line.item.gross.amount'),
									name: 'GrossAmount',
									allowDecimals: true,
									type: 'number'
								}
							]
						},
						{
							title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
							customFieldTab: 'PACKAGE_AND_DELIVERY',
							layout: {
								type: 'grid',
								gap: 16,
								area: [6, [4, 3], 4, [5, 4], 5]
							},
							items: [
								{
									title: edi.i18n.getMessage('delivery.terms'),
									name: 'TermsOfDelivery',
									type: 'combo',
									store: edi.stores.initTermsOfDeliveryStore()
								},
								{
									title: edi.i18n.getMessage('line.item.ordered.unit.packsize'),
									name: 'OrderedUnitPacksize',
									allowDecimals: true,
									type: 'number'
								},
								{
									title: edi.i18n.getMessage('line.item.expiration.date'),
									name: 'ExpirationDate',
									type: 'date'
								},
								{
									title: edi.i18n.getMessage('line.item.tab.package.ExpectedDeliveryDate'),
									name: 'ExpectedDeliveryDate',
									type: 'date'
								},
								{
									type: 'fieldblock',
									cls: FIELD_BLOCK_CLS.small,
									title: 'line.item.tab.package.DeliveryDate',
									layout: {
										type: 'grid',
										area: [[6, 6]]
									},
									createCustomItems: function (_conf, src, { readOnly, disabled }) {
										return [
											createDateField({
												name: 'DeliveryDateDate',
												format: edi.constants.DATE_FORMAT.FNS,
												submitFormat: edi.constants.DATE_FORMAT.CLIENT,
												flex: 1,
												valueSrc: src,
												readOnly,
												disabled
											}),
											createTimeField({
												name: 'DeliveryDateTime',
												format: 'H:i',
												submitFormat: 'H:i',
												flex: 1,
												valueSrc: src,
												readOnly,
												disabled
											})
										];
									}
								},
								{
									title: edi.i18n.getMessage('period.of.delivery'),
									type: function (_conf, src) {
										return createRangeContainer({
											fieldFrom: createTimeField({
												name: 'DeliveryPeriodFrom',
												valueSrc: src,
												readOnly: true,
												disabled: true,
												flex: 1,
												minWidth: 120
											}),
											fieldTo: createTimeField({
												name: 'DeliveryPeriodTo',
												valueSrc: src,
												readOnly: true,
												disabled: true,
												flex: 1,
												minWidth: 120
											})
										});
									}
								},
								{
									type: 'fieldblock',
									cls: FIELD_BLOCK_CLS.small,
									title: 'line.item.tab.package.StockReserveDateTime',
									layout: {
										type: 'grid',
										area: [[6, 6]]
									},
									createCustomItems: function (_conf, src, { readOnly, disabled }) {
										return [
											createDateField({
												name: 'StockReserveDate',
												format: edi.constants.DATE_FORMAT.FNS,
												submitFormat: edi.constants.DATE_FORMAT.CLIENT,
												flex: 1,
												valueSrc: src,
												readOnly,
												disabled
											}),
											createTimeField({
												name: 'StockReserveTime',
												format: 'H:i',
												submitFormat: 'H:i',
												flex: 1,
												valueSrc: src,
												readOnly,
												disabled
											})
										];
									}
								},
								{
									type: 'fieldset',
									title: '',
									layout: {
										type: 'grid',
										area: [[8, 4]]
									},
									items: [
										{
											title: 'line.item.tab.package.address_text',
											isTextarea: true,
											rowsHtmlAttributeValue: 2,
											name: 'AddressText'
										},
										{
											title: 'line.item.tab.package.floor',
											name: 'Floor'
										}
									]
								},
								{
									type: 'fieldset',
									title: '',
									layout: {
										type: 'grid',
										area: [[8, 4]]
									},
									items: [
										{
											title: 'mode.of.transport',
											name: 'ModeOfTransport'
										},
										{
											title: 'line.item.tab.package.VehicleHeight',
											name: 'VehicleHeight'
										}
									]
								},
								{
									title: edi.i18n.getMessage(
										'org.document.LEGACY_ORDER_RESP.part.docLines.ReasonCode'
									),
									isHidden: function () {
										var hidden = true,
											i;
										if (productFields && productFields.length) {
											for (i = 0; i < productFields.length; i++) {
												if (productFields[i].field == 'ReasonCode') {
													hidden = false;
													break;
												}
											}
										}
										return hidden;
									},
									name: 'ReasonCode'
								}
							]
						},
						{
							title: edi.i18n.getMessage('line.item.item.description.long'),
							customFieldTab: 'ADDITIONAL_DATA',
							layout: {
								type: 'grid',
								gap: 16,
								area: [6, 4]
							},
							items: [
								{
									title: edi.i18n.getMessage('column.reference.element.reference.id'),
									name: 'Line-Reference.Reference-Elements.Reference-Element.Reference-Id',
									type: 'text'
								},
								{
									title: edi.i18n.getMessage('column.reference.element.reference.date'),
									name: 'Line-Reference.Reference-Elements.Reference-Element.Reference-Date',
									type: 'date'
								}
							]
						}
					]
				}
			});
			return productsGrid;
		};

		var lineTransport = edi.utils.getObjectProperty(ordrsp, 'DetailsOfTransport.Line-Transport', true);
		var deliveryCoordination = lineTransport.length
			? createDeliveryCoordination(lineTransport, {
					readOnly: true
			  })
			: null;

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				headPanel,
				createMainDataBlock(),
				createAdditionalDataBlock(),
				createPartiesBlock(),
				createDeliveryInformationBlock(),
				createAdditionalInformationBlock(),
				ordrspRemarks,
				specialInstructions,
				deliveryCoordination,
				createCustomFieldsContainer(),
				createProductsGrid()
			]
		});
	};

	/**
	 * Creates action pane
	 */
	var createModuleActionsPanel = function (document) {
		let data = moduleData.initData.data;
		document.id = data.id;
		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const needSignatures = edi.document.actions.getSignCount(data);
		const needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.SUPPLIER);
		let signaturesCount = needSignatures + needSignatures2 - data.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero
		const actionsPanel = createActionsPanel();
		const customButtons = [];
		if (
			data.state === edi.constants.STATE.SENT &&
			direction === edi.constants.DIRECTIONS.INCOMING &&
			edi.permissions.hasPermission('CLIENT_LEGACY_ORDER_RESP_TO_APERAK')
		) {
			const confirmAperak = function () {
				let orderRespData = Ext.clone(productValues.products);
				let orderRespLinesGrid = createProductGridBase({
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					gridModel: ORDRSP_LINE_MODEL,
					gridColumnConfig: ORDRSP_APERAK_LINE_COLUMN,
					hasTotal: false,
					showSelectProductBtn: false,
					hideAddButton: true,
					hideDeleteButton: true,
					disableRemoveLines: true,
					data: orderRespData,
					callback: function (values) {
						orderRespData = values?.products || [];
					},
					modalFormConfig: {
						title: 'line.item.tab.product',
						modalFields: [
							{
								title: edi.i18n.getMessage('line.item.tab.product'),
								customFieldTab: 'PRODUCT',
								layout: {
									type: 'grid',
									gap: 16
								},
								items: [
									{
										title: edi.i18n.getMessage('line.item.product.description'),
										readOnly: true,
										name: 'ItemDescription'
									},
									{
										type: 'fieldblock',
										cls: FIELD_BLOCK_CLS.small,
										title: 'delivery.delivery.date',
										layout: {
											type: 'grid',
											area: [[6, 6]]
										},
										createCustomItems: function (_conf, src, { readOnly, disabled }) {
											return [
												createDateField({
													name: 'DeliveryDateDate',
													format: edi.constants.DATE_FORMAT.FNS,
													submitFormat: edi.constants.DATE_FORMAT.CLIENT,
													flex: 1,
													valueSrc: src,
													readOnly,
													disabled
												}),
												createTimeField({
													name: 'DeliveryDateTime',
													format: 'H:i',
													submitFormat: 'H:i',
													flex: 1,
													valueSrc: src,
													readOnly,
													disabled
												})
											];
										}
									},
									{
										title: edi.i18n.getMessage('period.of.delivery'),
										type: function (_conf, data) {
											return createRangeContainer({
												fieldFrom: createTimeField({
													name: 'DeliveryPeriodFrom',
													requiredFields: ['DeliveryPeriodTo'],
													valueSrc: data,
													increment: 30,
													minWidth: 50,
													flex: 2
												}),
												fieldTo: createTimeField({
													name: 'DeliveryPeriodTo',
													requiredFields: ['DeliveryPeriodFrom'],
													valueSrc: data,
													increment: 30,
													minWidth: 50,
													flex: 2
												})
											});
										}
									},
									{
										title: edi.i18n.getMessage('delivery.terms'),
										name: 'TermsOfDelivery',
										type: 'combo',
										store: edi.stores.initTermsOfDeliveryStore()
									}
								]
							}
						]
					}
				});

				let formPanel = createModalForm({
					submitEmptyText: false,
					items: [orderRespLinesGrid]
				});

				const createAperak = function () {
					const failure = function () {
						edi.core.showError('error.server', function () {
							moduleData.tab.setLoading(false);
						});
					};
					const success = function () {
						changeHandler(data);
						modal.close();
					};
					let orderRespResult = [];
					orderRespData.forEach((element) => {
						orderRespResult.push({
							LineItem: {
								LineNumber: element.LineNumber,
								ItemDescription: element.ItemDescription,
								ExpectedDeliveryDate: element.DeliveryDateDate || null,
								ExpectedDeliveryTime: element.DeliveryDateTime || null,
								DeliveryPeriod:
									element.DeliveryPeriodFrom && element.DeliveryPeriodTo
										? element.DeliveryPeriodFrom + '-' + element.DeliveryPeriodTo
										: element.DeliveryPeriodFrom
										? element.DeliveryPeriodFrom
										: null,
								TermsOfDelivery: element.TermsOfDelivery
							}
						});
					});
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.DOCUMENTS.APERAK.PUT, {
							documentId: data.id
						}),
						'PUT',
						Ext.encode({
							LINES: JSON.stringify(orderRespResult),
							BUSINESS_STATE: 'ACCEPTED'
						}),
						success,
						failure
					);
				};

				let modal = createModalPanel({
					title: edi.i18n.getMessage('document.confirm.document'),
					width: MODAL_SIZE.widthLarge,
					items: [formPanel],
					buttonsBefore: [
						createButton({
							cls: BUTTON_CLS.primary,
							text: edi.i18n.getMessage('form.btn.create.and.send'),
							handler: createAperak
						})
					]
				});
				modal.show();
			};

			const confirmBtn = createActionsButton({
				showInFirstHalf: true,
				order: 286,
				tooltip: edi.i18n.getMessage('document.confirm.document'),
				text: edi.i18n.getMessage('document.confirm.document'),
				glyph: edi.constants.ICONS.DONE,
				handler: confirmAperak
			});
			customButtons.push(confirmBtn);
		}

		const createDocConfig = [
			{
				permission: 'CREATE_LEGACY_DES_ADV',
				title: 'action.desadv',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
				config: {
					isFromTransformation: true,
					transformUri: edi.rest.services.DOCUMENTS.TRANSFORMATION_WITH_CUSTOM_FIELDS.POST,
					buyerOrg: moduleData.initData.data.toOrg
				}
			},
			{
				permission: 'CREATE_LEGACY_ALCDES',
				title: 'action.alcdes',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
				config: {
					isFromTransformation: true,
					buyerOrg: moduleData.initData.data.toOrg
				}
			},
			{
				permission: 'CREATE_LEGACY_INVOICE',
				title: 'action.invoice',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
				config: {
					isFromTransformation: true,
					buyerOrg: moduleData.initData.data.toOrg
				}
			}
		];

		const basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_FROM',
			data,
			moduleData,
			document,
			'ordrsp',
			createDocConfig
		);
		if (basedDocuments?.length) {
			customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.ordrsp'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					hideDefaultExport: true,
					addOriginalExport: true,
					addTransitExport: true
				},
				REJECT: {
					order: 285,
					success: () => changeHandler(data),
					methodAddOptions: {
						maxInputLength: 512,
						process: {
							url: edi.rest.services.DOCUMENTS.APERAK.PUT,
							properties: function (reasonText) {
								return {
									reason: reasonText,
									BUSINESS_STATE: 'REJECTED_BY_RECEIVER'
								};
							}
						}
					}
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				productValues = {
					products: []
				};
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (resp) {
				if (resp && resp.data) {
					var document = resp.data;
					var finish = function () {
						const detailsPanel = createDetailsPanel(document);
						modulePanel.add(detailsPanel);
						moduleData.tab.add(createModuleActionsPanel(document));
						moduleData.tab.add(modulePanel);
						if (data.toOrg?.id && data.fromOrg?.id) {
							edi.methods.custom_fields.initCustomFields({
								docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
								toOrgId: data.toOrg.id,
								fromOrgId: data.fromOrg.id,
								docId: document.id,
								container: customFieldsContainer,
								grid: productsGrid,
								topPath: topPath,
								readOnly: true
							});
						}
						if ('function' == typeof initCallBack) {
							initCallBack();
						} else {
							moduleData.tab.setLoading(false);
						}
					};
					edi.document.actions.getOrgDocumentPartFields(
						moduleData.initData.data.toOrg.id,
						edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
						function (organizationDocPartFields) {
							var docHeader =
									organizationDocPartFields.find(function (item) {
										return item.docPart == 'docHeader';
									}) || {},
								docLines =
									organizationDocPartFields.find(function (item) {
										return item.docPart == 'docLines';
									}) || {};
							headerFields = edi.utils.getObjectProperty(docHeader, 'fields', true);
							productFields = edi.utils.getObjectProperty(docLines, 'fields', true);
							finish();
						}
					);
				} else {
					failure(resp);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
