import { showApprovalModal } from '@Ediweb/modules/APPROVAL_DOCUMENTS/methods';
import { createActionsButton } from '@UIkit/components/buttons';
import { DocumentDetailsFns_ukd_5_01nModule } from '@Fns/modules/documents/EDI_FNS_UKD/document.details.fns_ukd_5_01n';

export class EWDocumentDetailsFns_ukd_5_01nModule extends DocumentDetailsFns_ukd_5_01nModule {
	constructor() {
		super();
	}

	isShowGroupSendBtn() {
		const me = this;

		const data = me.moduleData.initData.data;

		var approvalState = edi.utils.getAttributeByName(data.attributes, 'APPROVAL_STATE');
		if (
			approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED ||
			approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REVOKED
		) {
			return data.state === edi.constants.STATE.SENDER_REVIEW;
		}
		if (approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED) {
			return data.state === edi.constants.STATE.SENDER_REVIEW;
		}
		return data.state === edi.constants.STATE.DRAFT;
	}

	showGroupSendBtn() {
		const me = this;
		const data = me.moduleData.initData.data;

		return (
			Ext.Array.contains(edi.constants.UKD_WITH_APPROVE, me.bpName) &&
			me.isShowGroupSendBtn() &&
			edi.action.isAvailableExtended(edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS, data) &&
			edi.action.isAvailableExtended(edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_EMPLOYEES, data)
		);
	}

	addExcludeButtons() {
		const me = this;

		let excludeActions = {};

		if (me.showGroupSendBtn()) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.SIGN] = true;
		}
		return excludeActions;
	}
	addCustomButtons(options) {
		const me = this;
		const customButtons = super.addCustomButtons(options);
		const data = me.moduleData.initData.data;

		var approvalState = edi.utils.getAttributeByName(data.attributes, 'APPROVAL_STATE');

		data.direction = options.direction;
		data.docType = data.type;
		data.annulNotActive = true;

		if (me.showGroupSendBtn()) {
			const docOptions = edi.action.getDocumentData(
				edi.models.createInstance('DOCUMENT', data),
				me.moduleData.initData.data,
				{
					data: data,
					direction: options.direction,
					moduleData: me.moduleData,
					needSignatures: options.neededSignaturesCount,
					hasPart2: options.hasPart2
				}
			);

			let sendGroupAction = createActionsButton({
				order: 2,
				actionName: 'GROUP_SEND',
				text: edi.i18n.getMessage('document.sign.and.send.document'),
				glyph: edi.constants.ICONS.SEND,
				menu: {
					plain: true,
					hideMode: 'display',
					items: [
						approvalState !== edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED
							? edi.document.actions.createSignActionButton(
									data,
									me.moduleData.tab,
									null,
									null,
									null,
									'ediweb.send.and.sign.parnter.btn',
									docOptions,
									{
										contentId:
											edi.constants.DIRECTIONS.OUTGOING === options.direction
												? me.part1Head.id
												: me.part2Head
												? me.part2Head.id
												: null,
										useBeforeAction:
											data.state === edi.constants.STATE.DRAFT ||
											data.state === edi.constants.STATE.READ ||
											data.state === edi.constants.STATE.READ,
										buttonProps: {
											glyph: null,
											cls: 'edi-ediweb-left-align-action-button ui-button ui-button-light',
											textAlign: 'left'
										}
									}
							  )
							: null,
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.documents.btn'),
							handler() {
								console.log('data', data);
								let sendData = data;
								sendData.status = data.state;
								sendData.date = data.creationDate;
								sendData.bpName = me.bpName;
								sendData.versionId = '5.01-N';
								showApprovalModal(
									sendData,
									me.moduleData,
									null,
									edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS
								);
							}
						},
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.employees.btn'),
							handler() {
								let sendData = data;
								sendData.status = data.state;
								sendData.date = data.creationDate;
								sendData.bpName = me.bpName;
								sendData.versionId = '5.01-N';
								showApprovalModal(
									sendData,
									me.moduleData,
									null,
									edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES
								);
							}
						}
					]
				}
			});
			customButtons.push(sendGroupAction);
		}

		if (
			edi.action.isAvailable(
				edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS,
				edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data))
			) &&
			!me.showGroupSendBtn()
		) {
			let sendToApproveDocumentsBtn = createActionsButton({
				actionName: edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS,
				order: 2,
				text: edi.i18n.getMessage('document.send.to.approve.documents'),
				handler: function () {
					let sendData = data;
					sendData.status = data.state;
					sendData.date = data.creationDate;
					showApprovalModal(
						sendData,
						me.moduleData,
						null,
						edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS
					);
				}
			});
			let sendToApproveEmployeesBtn = createActionsButton({
				actionName: edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS,
				order: 2,
				text: edi.i18n.getMessage('document.send.to.approve.employees'),
				handler: function () {
					let sendData = data;
					sendData.status = data.state;
					sendData.date = data.creationDate;
					showApprovalModal(
						sendData,
						me.moduleData,
						null,
						edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES
					);
				}
			});
			customButtons.push(sendToApproveDocumentsBtn);
			customButtons.push(sendToApproveEmployeesBtn);
		}

		return customButtons;
	}
}
edi.modules['document.details.fns_ukd_5_01n'] = EWDocumentDetailsFns_ukd_5_01nModule;
