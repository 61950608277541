Ext.namespace('edi.selectors');

/**
 * Create DSF fields map
 * @param       fieldsMapPrefix     Fields map prefix
 * @return      {Object}
 */
edi.selectors.getDSFFieldsMap = function (fieldsMapPrefix) {
	var prefix = fieldsMapPrefix + '.',
		company = prefix + 'identification.legalEntity.',
		individual = prefix + 'identification.individualPerson.',
		rusAddress = prefix + 'address.russianAddress.',
		foreignAddr = prefix + 'address.foreginAddress.',
		contact = prefix + 'contact.',
		bank = prefix + 'bankInfo.',
		structural_subdivision = 'senderDocumentFields.' + prefix;

	return {
		company_inn: company + 'inn',
		company_name: company + 'name',
		company_iln: prefix + 'iln',
		company_kpp: company + 'kpp',
		company_okpo: company + 'okpo',

		individual_inn: individual + 'inn',
		individual_lastname: individual + 'name.lastName',
		individual_firstname: individual + 'name.firstName',
		individual_patronymicname: individual + 'name.middleName',

		addr_rus_zip: rusAddress + 'addrIndex',
		addr_rus_region: rusAddress + 'codeReg',
		addr_rus_county: rusAddress + 'county',
		addr_rus_city: rusAddress + 'city',
		addr_rus_street: rusAddress + 'street',
		addr_rus_community: rusAddress + 'community',
		addr_rus_home: rusAddress + 'home',
		addr_rus_block: rusAddress + 'addrBlock',
		addr_rus_flat: rusAddress + 'flat',

		addr_for_country: foreignAddr + 'countryCode',
		addr_for_text: foreignAddr + 'addrText',

		contact_phone: contact + 'tel',
		contact_fax: contact + 'fax',
		contact_email: contact + 'eMail',

		bank_acc: bank + 'accountNumber',
		bank_corr_acc: bank + 'bankAcc',
		bank_name: bank + 'name',
		bank_id: bank + 'bankBIC',

		fns_id: prefix + 'fnsId',
		address_type: true,
		addr_category: prefix + 'address.addr_category',
		structural_subdivision: structural_subdivision + 'structural_subdivision',
		type: true
	};
};

/**
 * Create Formalized Document fields map
 * @param       fieldsMapPrefix     Fields map prefix
 * @return      {Object}
 */
edi.selectors.getFormalizedDocumentFieldsMap = function (fieldsMapPrefix) {
	var prefix = fieldsMapPrefix + '.',
		company = prefix + 'identification.legalEntity.',
		individual = prefix + 'identification.individualPerson.',
		rusAddress = prefix + 'address.russianAddress.',
		foreignAddr = prefix + 'address.foreginAddress.',
		contact = prefix + 'contact.',
		bank = prefix + 'bankInfo.',
		structural_subdivision = 'senderDocumentFields.' + prefix;

	return {
		company_inn: company + 'inn',
		company_name: company + 'name',
		company_iln: prefix + 'iln',
		company_kpp: company + 'kpp',

		individual_inn: individual + 'inn',
		individual_lastname: individual + 'name.lastName',
		individual_firstname: individual + 'name.firstName',
		individual_patronymicname: individual + 'name.middleName',

		addr_rus_zip: rusAddress + 'addrIndex',
		addr_rus_region: rusAddress + 'codeReg',
		addr_rus_county: rusAddress + 'county',
		addr_rus_city: rusAddress + 'city',
		addr_rus_street: rusAddress + 'street',
		addr_rus_community: rusAddress + 'community',
		addr_rus_home: rusAddress + 'home',
		addr_rus_block: rusAddress + 'addrBlock',
		addr_rus_flat: rusAddress + 'flat',

		addr_for_country: foreignAddr + 'countryCode',
		addr_for_text: foreignAddr + 'addrText',

		contact_phone: contact + 'tel',
		contact_fax: contact + 'fax',
		contact_email: contact + 'eMail',

		bank_acc: bank + 'accountNumber',
		bank_corr_acc: bank + 'bankAcc',
		bank_name: bank + 'name',
		bank_id: bank + 'bankBIC',

		fns_id: prefix + 'fnsId',
		address_type: true,
		addr_category: prefix + 'address.addr_category',
		structural_subdivision: structural_subdivision + 'structural_subdivision',
		type: true
	};
};

/**
 * Create Order Invoice fields map
 * @param       fieldsMapPrefix     Fields map prefix
 * @param       additionalMap       Additional map
 * @return      {Object}
 */
edi.selectors.getOrderFieldsMap = function (fieldsMapPrefix, additionalMap, options) {
	additionalMap = additionalMap || {};
	options = options || {};

	Object.assign(additionalMap, {
		id: true,
		bank_acc: fieldsMapPrefix + '.AccountNumber'
	});

	var excludeFields = ['company_kpp', 'addr_area', 'addr_region_code', 'addr_region_name', 'addr_home', 'addr_flat'];

	return edi.selectors.utils.getLegacyFieldsMap(
		fieldsMapPrefix,
		Object.assign(
			{
				excludeFields: excludeFields,
				additionalMap: additionalMap
			},
			options
		)
	);
};

/**
 * Create RETANN parties fields map
 * @param       fieldsMapPrefix        Fields map prefix
 * @return      {Object}
 */
edi.selectors.getRetannPartiesFieldsMap = function (fieldsMapPrefix) {
	var prefix = fieldsMapPrefix ? fieldsMapPrefix + '.' : '';
	return {
		company_name: prefix + 'Name',
		company_iln: prefix + 'ILN',
		addr_country: prefix + 'Country',
		addr_zip: prefix + 'PostalCode',
		addr_region_name: prefix + 'State',
		addr_region_code: prefix + 'StateCode',
		addr_area: prefix + 'District',
		addr_city: prefix + 'CityName',
		addr_locality: prefix + 'Locality',
		addr_street: prefix + 'StreetAndNumber',
		addr_home: prefix + 'HouseNumber',
		addr_block: prefix + 'Housing',
		addr_flat: prefix + 'RoomNumber'
	};
};

/**
 * Create PRICAT EANCOM fields map
 * @param       fieldsMapPrefix     Fields map prefix
 * @return      {Object}
 */
edi.selectors.getPRICATEANCOMFieldsMap = function (fieldsMapPrefix) {
	return {
		company_inn: fieldsMapPrefix + '.inn',
		company_name: fieldsMapPrefix + '.company_name',
		company_iln: fieldsMapPrefix + '.iln',
		company_kpp: fieldsMapPrefix + '.kpp',
		contact_email: fieldsMapPrefix + '.contact_email',
		contact_person: fieldsMapPrefix + '.contact_person'
	};
};

edi.selectors.getPRICATEANCOMPartinFieldsMap = function (fieldsMapPrefix) {
	return {
		company_name: fieldsMapPrefix + 'name',
		company_iln: fieldsMapPrefix + 'iln'
	};
};
