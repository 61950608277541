const createAlcDes = function (config) {
	config = config ? config : {};
	var alcdesLines = [];
	var summary = {};
	if (config.productValues) {
		summary = {
			TotalPSequence: config.productValues.TotalPSequence,
			TotalLines: config.productValues.TotalLines,
			TotalGoodsDespatchedAmount: config.productValues.TotalGoodsDespatchedAmount,
			TotalNetAmount: config.productValues.TotalNetAmount,
			TotalGrossAmount: config.productValues.TotalGrossAmount,
			TotalTaxAmount: config.productValues.TotalTaxAmount,
			TotalWeight: config.productValues.TotalWeight
		};

		for (var i = 0; i < config.productValues.products.length; i++) {
			var product = config.productValues.products[i];
			var packageId, additionalInfo;

			if (product['Package-Identification']) {
				packageId = product['Package-Identification'];
				delete product['Package-Identification'];
			}

			if (product['Line-AdditionalInformation']) {
				additionalInfo = product['Line-AdditionalInformation'];
				delete product['Line-AdditionalInformation'];
			}

			var line = {};
			if (product[edi.constants.INTERNAL_LINE_ID]) {
				line[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}
			delete product[edi.constants.INTERNAL_LINE_ID];
			line['Line-Item'] = product;

			if (packageId) {
				line['Package-Identification'] = packageId;
			}

			if (additionalInfo) {
				line['Line-AdditionalInformation'] = additionalInfo;
			}

			var lineData;
			if (product['Line-License']) {
				lineData = product['Line-License'];
				delete product['Line-License'];
				line['Line-License'] = lineData;
			}
			if (product['Line-Parties']) {
				lineData = product['Line-Parties'];
				delete product['Line-Parties'];
				line['Line-Parties'] = lineData;
			}
			if (product['Line-Reference']) {
				lineData = product['Line-Reference'];
				delete product['Line-Reference'];
				line['Line-Reference'] = lineData;
			}
			if (product['Line-Certificate']) {
				lineData = product['Line-Certificate'];
				delete product['Line-Certificate'];
				line['Line-Certificate'] = lineData;
			}
			if (product['Package-Identification']) {
				lineData = product['Package-Identification'];
				delete product['Package-Identification'];
				line['Package-Identification'] = lineData;
			}
			if (product['Line-AdditionalInformation']) {
				lineData = product['Line-AdditionalInformation'];
				delete product['Line-AdditionalInformation'];
				line['Line-AdditionalInformation'] = lineData;
			}

			alcdesLines.push(line);
		}
	}

	return {
		'DespatchAdvice-Header': createAlcDesHeader(config['DespatchAdvice-Header']),
		'DespatchAdvice-Parties': config.parties || {},
		'DespatchAdvice-Transport': config['DespatchAdvice-Transport'] || {},
		'DespatchAdvice-Consignment': {
			'Packing-Sequence': {
				Line: alcdesLines
			}
		},
		'DespatchAdvice-Summary': summary
	};
};

const createAlcDesParties = function (config) {
	config = config ? config : {};

	return {
		Buyer: {
			ILN: edi.utils.getObjectProperty(config, 'Buyer.ILN'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'Buyer.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'Buyer.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'Buyer.CodeByCarrier'),
			TaxID: edi.utils.getObjectProperty(config, 'Buyer.TaxID'),
			UtilizationRegisterNumber: edi.utils.getObjectProperty(config, 'Buyer.UtilizationRegisterNumber'),
			Name: edi.utils.getObjectProperty(config, 'Buyer.Name'),

			Type: edi.utils.getObjectProperty(config, 'Buyer.Type'),
			CityName: edi.utils.getObjectProperty(config, 'Buyer.CityName'),
			Country: edi.utils.getObjectProperty(config, 'Buyer.Country'),
			District: edi.utils.getObjectProperty(config, 'Buyer.District'),
			HouseNumber: edi.utils.getObjectProperty(config, 'Buyer.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'Buyer.Housing'),
			Locality: edi.utils.getObjectProperty(config, 'Buyer.Locality'),
			PostalCode: edi.utils.getObjectProperty(config, 'Buyer.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'Buyer.RoomNumber'),
			State: edi.utils.getObjectProperty(config, 'Buyer.State'),
			StateCode: edi.utils.getObjectProperty(config, 'Buyer.StateCode'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'Buyer.StreetAndNumber'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'Buyer.AdditionalInformation')
		},
		Seller: {
			ILN: edi.utils.getObjectProperty(config, 'Seller.ILN'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'Seller.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'Seller.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'Seller.CodeByCarrier'),
			TaxID: edi.utils.getObjectProperty(config, 'Seller.TaxID'),
			UtilizationRegisterNumber: edi.utils.getObjectProperty(config, 'Seller.UtilizationRegisterNumber'),
			Name: edi.utils.getObjectProperty(config, 'Seller.Name'),

			Type: edi.utils.getObjectProperty(config, 'Seller.Type'),
			CityName: edi.utils.getObjectProperty(config, 'Seller.CityName'),
			Country: edi.utils.getObjectProperty(config, 'Seller.Country'),
			District: edi.utils.getObjectProperty(config, 'Seller.District'),
			HouseNumber: edi.utils.getObjectProperty(config, 'Seller.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'Seller.Housing'),
			Locality: edi.utils.getObjectProperty(config, 'Seller.Locality'),
			PostalCode: edi.utils.getObjectProperty(config, 'Seller.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'Seller.RoomNumber'),
			State: edi.utils.getObjectProperty(config, 'Seller.State'),
			StateCode: edi.utils.getObjectProperty(config, 'Seller.StateCode'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'Seller.StreetAndNumber'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'Seller.AdditionalInformation'),

			License: edi.utils.getObjectProperty(config, 'Seller.License')
		},
		DeliveryPoint: {
			ILN: edi.utils.getObjectProperty(config, 'DeliveryPoint.ILN'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeByCarrier'),
			Name: edi.utils.getObjectProperty(config, 'DeliveryPoint.Name'),

			Type: edi.utils.getObjectProperty(config, 'DeliveryPoint.Type'),
			CityName: edi.utils.getObjectProperty(config, 'DeliveryPoint.CityName'),
			Country: edi.utils.getObjectProperty(config, 'DeliveryPoint.Country'),
			District: edi.utils.getObjectProperty(config, 'DeliveryPoint.District'),
			HouseNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'DeliveryPoint.Housing'),
			Locality: edi.utils.getObjectProperty(config, 'DeliveryPoint.Locality'),
			PostalCode: edi.utils.getObjectProperty(config, 'DeliveryPoint.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.RoomNumber'),
			State: edi.utils.getObjectProperty(config, 'DeliveryPoint.State'),
			StateCode: edi.utils.getObjectProperty(config, 'DeliveryPoint.StateCode'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.StreetAndNumber'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'DeliveryPoint.AdditionalInformation')
		},
		UltimateCustomer: edi.utils.getObjectProperty(config, 'UltimateCustomer'),
		Carrier: edi.utils.getObjectProperty(config, 'Carrier'),
		ShipFrom: edi.utils.getObjectProperty(config, 'ShipFrom')
	};
};

const createAlcDesHeader = function (config) {
	config = config ? config : {};

	return {
		DespatchAdviceNumber: config.DespatchAdviceNumber,
		Reference: config.Reference,
		AdditionalData: config.AdditionalData,
		DespatchAdviceDate: config.DespatchAdviceDate,
		EstimatedDeliveryDate: config.EstimatedDeliveryDate,
		DespatchDate: config.DespatchDate,
		DespatchTime: config.DespatchTime,
		InvoiceNumber: config.InvoiceNumber,
		InvoiceDate: config.InvoiceDate,
		BuyerOrderNumber: config.BuyerOrderNumber,
		BuyerOrderDate: config.BuyerOrderDate,
		WZTransportDocNumber: config.WZTransportDocNumber,
		DocumentFunctionCode: config.DocumentFunctionCode,
		DocumentNameCode: config.DocumentNameCode,
		Remarks: config.Remarks,
		WaybillNumber: config.WaybillNumber,
		WaybillDate: config.WaybillDate,
		BillOfLadingNumber: config.BillOfLadingNumber,
		BillOfLadingDate: config.BillOfLadingDate,
		UTDnumber: config.UTDnumber,
		UTDDate: config.UTDDate,
		CodeFixEGAIS: config.CodeFixEGAIS,
		DateFixEGAIS: config.DateFixEGAIS,
		TTNRegId: config.TTNRegId,
		ReasonCode: config.ReasonCode,
		ReasonDelayCode: config.ReasonDelayCode,
		DespatchReferenceNumber: config.DespatchReferenceNumber,
		DespatchReferenceDate: config.DespatchReferenceDate
	};
};

export { createAlcDes, createAlcDesParties };
