import { powerOfAttorneyAPI } from '../rest';
import { ChainPoaPayload, PoaRequest } from '../difinitions';

export interface LoadPoaChainProps {
	retrustPoaData: ChainPoaPayload;
}
export type LoadPoaChainResult = Promise<{ data: PoaRequest; success: boolean }>;
export const loadPoaChain = function ({ retrustPoaData }: LoadPoaChainProps): LoadPoaChainResult {
	return edi.rest.asyncSendRequest({
		url: powerOfAttorneyAPI.CHAIN.POST,
		method: 'POST',
		params: Ext.encode(retrustPoaData)
	});
};
