import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createDeliveryCoordination } from '@Edi/specialComponents/deliveryCoordination';
import { DELJIT_LINE_COLUMN, DELJIT_LINE_SHIPMENT_CONDITIONS_COLUMN, DELJIT_SUMMARY_DETAIL_COLUMN } from './columns';
import {
	DELJIT_LINE_MODEL,
	DELJIT_ROW_SHIPMENT_CONDITION_MODEL,
	DELJIT_SHIPMENT_CONDITION_MODEL,
	DELJIT_SUMMARY_MODEL
} from './models';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { dateTimeRenderer } from './methods';

/**
 * Class for DELJIT details
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.modules');
edi.modules['document.details.deljit'] = function () {
	var moduleData, linesData;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};
	/**
	 * Creates order form
	 * @returns {Object}
	 */
	var createDetailsPanel = function (document) {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
		});
		var headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('documents.create.deljit.nr'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(document, 'Order-Header.OrderNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.25,
					text: edi.utils.getObjectProperty(document, 'Order-Header.OrderDate'),
					valueLabel: true
				})
			]
		});

		var orderCurrency = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('order.currency'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.renderers.currency(
							edi.utils.getObjectProperty(document, 'Order-Header.OrderCurrency')
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.75
					}
				})
			]
		});

		var orderRemarks = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('remarks'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(document, 'Order-Header.Remarks'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var agreementNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('order.agreement.number'),
					input: createLabel({
						columnWidth: 0.5,
						text: document
							? edi.utils.getObjectProperty(document, 'Order-Header.Reference.ContractNumber')
							: undefined,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var agreementDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('order.agreement.date'),
					input: createDateLabel({
						columnWidth: 0.5,
						text: document
							? edi.utils.getObjectProperty(document, 'Order-Header.Reference.ContractDate')
							: undefined,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var functionalDocumentCode = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('functional.document.code'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.i18n.getMessage(
							'functional.document.code.' +
								edi.utils.getObjectProperty(document, 'Order-Header.DocumentFunctionCode')
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var parties = edi.utils.getObjectProperty(document, 'Order-Parties');

		var createCS = function (selectorConfig) {
			Object.assign(selectorConfig, {
				fieldsMapOnly: true,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				fieldValues: parties
			});

			return createCompanySelector(selectorConfig);
		};

		var buyer = createCS({
			title: 'documents.order.buyer',
			fieldsMap: edi.selectors.getOrderFieldsMap('Buyer', {
				operator_name: 'Buyer.OperatorDetails.Name',
				operator_phone: 'Buyer.OperatorDetails.TelephoneNumber',
				operator_email: 'Buyer.OperatorDetails.E-mail'
			})
		});

		var seller = createCS({
			title: 'documents.order.seller',
			fieldsMap: edi.selectors.getOrderFieldsMap('Seller', {
				operator_name: 'Seller.OperatorDetails.Name',
				operator_phone: 'Seller.OperatorDetails.TelephoneNumber',
				operator_email: 'Seller.OperatorDetails.E-mail',
				representative_name: 'Seller.SalesRepresentative.Name',
				representative_phone: 'Seller.SalesRepresentative.TelephoneNumber',
				representative_email: 'Seller.SalesRepresentative.E-mail'
			})
		});

		var delivery = createCS({
			title: 'document.delivery',
			fieldsMap: edi.selectors.getOrderFieldsMap('DeliveryPoint', {
				contact_name: 'DeliveryPoint.ContactPerson',
				contact_phone: 'DeliveryPoint.PhoneNumber',
				contact_email: 'DeliveryPoint.E-mail',
				contact_web: 'DeliveryPoint.Web'
			})
		});

		var hasUltimateCustomer = !!edi.utils.getObjectProperty(parties, 'UltimateCustomer');
		var ultimateCustomer = hasUltimateCustomer
			? createCS({
					title: 'documents.ultimate.customer',
					fieldsMap: edi.selectors.getOrderFieldsMap('UltimateCustomer', {
						contact_name: 'UltimateCustomer.ContactPerson',
						contact_phone: 'UltimateCustomer.PhoneNumber',
						contact_email: 'UltimateCustomer.E-mail',
						contact_web: 'UltimateCustomer.Web'
					})
			  })
			: null;

		var hasDeliveryPeriod = !!edi.utils.getObjectProperty(document, 'Order-Header.LastExpectedDeliveryDate');
		var deliveryDate = hasDeliveryPeriod
			? null
			: createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createLabel({
							text: edi.i18n.getMessage('expected.delivery.period.DATE'),
							columnWidth: 0.2
						}),
						createDateLabel({
							columnWidth: 0.45,
							text: edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryDate'),
							valueLabel: true
						}),
						createLabel({
							margin: '0 0 0 5',
							text: edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryTime'),
							valueLabel: true,
							columnWidth: 0.1
						})
					]
			  });

		var startDate = edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryDate'),
			startTime = edi.utils.getObjectProperty(document, 'Order-Header.ExpectedDeliveryTime'),
			endDate = edi.utils.getObjectProperty(document, 'Order-Header.LastExpectedDeliveryDate'),
			endTime = edi.utils.getObjectProperty(document, 'Order-Header.LastExpectedDeliveryTime');

		var deliveryPeriod = !hasDeliveryPeriod
			? null
			: createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					items: [
						createLabel({
							text: edi.i18n.getMessage('expected.delivery.period.PERIOD'),
							columnWidth: 0.2
						}),
						createDateLabel({
							columnWidth: 0.17,
							text: startDate,
							valueLabel: true
						}),
						createLabel({
							margin: '0 0 0 5',
							text: startTime,
							valueLabel: true,
							columnWidth: 0.0775
						}),
						{
							xtype: 'component',
							html: '-',
							cls: 'legacyTaxIdDelimiter',
							margin: '7 0 0',
							columnWidth: 0.055
						},
						createDateLabel({
							columnWidth: 0.17,
							text: endDate,
							valueLabel: true
						}),
						createLabel({
							margin: '0 0 0 5',
							text: endTime,
							valueLabel: true,
							columnWidth: 0.0775
						})
					]
			  });

		var modeOfTransport = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('mode.of.transport'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.renderers.modeOfTransport(
							edi.utils.getObjectProperty(document, 'Order-Header.Order-Transport.ModeOfTransport')
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var lines = edi.utils.getObjectProperty(document, 'Order-Lines.Line', true);
		linesData = [];
		if ('string' != typeof lines) {
			for (var i = 0; i < lines.length; i++) {
				var values = lines[i]['Line-Item'];
				values['BuyerLocationCode'] = edi.utils.getObjectProperty(
					lines[i],
					'Line-Delivery.BuyerLocationCode',
					true
				);
				values['ExpectedDeliveryDate'] = edi.utils.getObjectProperty(
					lines[i],
					'Line-Delivery.ExpectedDeliveryDate',
					true
				);
				values['Shipment-Condition'] = edi.utils.getObjectProperty(
					lines[i],
					'Shipment-Conditions.Shipment-Condition',
					true
				);
				values['TaxRate'] = edi.renderers.taxRateString(values['TaxRate']);
				linesData.push(values);
			}
		}
		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.order.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			data: linesData,
			margin: '16 0 0',
			readOnly: true,
			gridModel: DELJIT_LINE_MODEL,
			totalModel: DELJIT_SUMMARY_MODEL,
			gridColumnConfig: DELJIT_LINE_COLUMN,
			totalColumnConfig: DELJIT_SUMMARY_DETAIL_COLUMN,
			lineNumberFieldName: 'LineNumber',
			subRowDataConfig: {
				columns: DELJIT_LINE_SHIPMENT_CONDITIONS_COLUMN,
				rowId: 'LineNumber',
				model: edi.models.getModel(DELJIT_ROW_SHIPMENT_CONDITION_MODEL),
				parentPropName: 'Shipment-Condition'
			},
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			docData: document,
			modalFormConfig: {
				title: 'document.order.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN'
							},
							{
								title: edi.i18n.getMessage('line.item.ordered.quantity'),
								name: 'OrderedQuantity',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.item.status.code'),
								name: 'ItemStatusCode',
								type: 'combo',
								store: edi.stores.initItemStatusCodeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.item.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								name: 'SupplierItemCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ordered.unit.net.price'),
								allowDecimals: true,
								name: 'OrderedUnitNetPrice',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.unit.net.price'),
								allowDecimals: true,
								name: 'SupplierUnitNetPrice',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.rate'),
								name: 'TaxRate'
							},
							{
								title: edi.i18n.getMessage('pricat.product.discount'),
								name: 'Discount'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
						items: [
							{
								title: edi.i18n.getMessage('line.delivery.buyer.location.code'),
								name: 'BuyerLocationCode'
							},
							{
								title: edi.i18n.getMessage('line.item.tab.package.ExpectedDeliveryDate'),
								name: 'ExpectedDeliveryDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.expiration.date'),
								name: 'ExpirationDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.max.expiration.date'),
								name: 'MaxExpirationDate',
								type: 'date'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.shipment.conditions'),
						bodyPadding: 0,
						items: [
							{
								type: 'editableGrid',
								name: 'Shipment-Condition',
								config: {
									fields: [
										{
											title: 'line.item.shipment.conditions.CumulativeQuantity',
											name: 'CumulativeQuantity'
										},
										{
											title: 'line.item.shipment.conditions.QuantityToBeDelivered',
											name: 'QuantityToBeDelivered'
										},
										{
											title: 'line.item.shipment.conditions.ExpectedDespatchDate',
											name: 'ExpectedDespatchDateTime',
											type: 'date',
											format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
											submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_NO_S,
											renderer: dateTimeRenderer
										},
										{
											title: 'line.item.shipment.conditions.RequestedDeliveryDate',
											name: 'RequestedDeliveryDateTime',
											type: 'date',
											format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
											submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_NO_S,
											renderer: dateTimeRenderer
										}
									],
									limit: 20,
									model: DELJIT_SHIPMENT_CONDITION_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										minHeight: 200,
										title: edi.i18n.getMessage('line.item.tab.shipment.conditions')
									}
								}
							}
						]
					}
				]
			}
		});

		var lineTransport = edi.utils.getObjectProperty(document, 'DetailsOfTransport.Line-Transport', true);
		var deliveryCoordination = lineTransport.length
			? createDeliveryCoordination(lineTransport, {
					creator: edi.utils.getObjectProperty(document, 'Document-Parties.Creator') || undefined,
					readOnly: true
			  })
			: null;

		var twoColumnsLayout = createTwoColumnsLayout(
			[
				headData,
				orderCurrency,
				deliveryDate,
				deliveryPeriod,
				orderRemarks,
				buyer,
				seller,
				delivery,
				ultimateCustomer
			],
			[modeOfTransport, deliveryCoordination, agreementNumber, agreementDate, functionalDocumentCode],
			0.625
		);

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, productsGrid]
		});
	};

	/**
	 * Creates action panel
	 */
	var createModuleActionsPanel = function (document) {
		var data = moduleData.initData.data;
		document.id = data.id;
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		var needSignatures = edi.document.actions.getSignCount(data);
		var needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.CONSUMER);
		var signaturesCount = needSignatures + needSignatures2 - data.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero
		var actionsPanel = createActionsPanel();

		const customButtons = [];
		const createDocConfig = [
			{
				permission: 'CREATE_LEGACY_DES_ADV',
				title: 'action.desadv',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
				config: {
					isFromTransformation: true,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			}
		];

		const basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_FROM',
			data,
			moduleData,
			document,
			'order',
			createDocConfig
		);

		if (basedDocuments?.length) {
			customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.deljit'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					hideDefaultExport: true,
					addOriginalExport: true,
					addTransitExport: true
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					var document = data.data;
					modulePanel.add(createDetailsPanel(document));
					moduleData.tab.add(createModuleActionsPanel(document));
					moduleData.tab.add(modulePanel);
					if ('function' == typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
