import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { MODAL_SIZE } from '@UIkit/components/modal';

Ext.namespace('edi.modules');
edi.modules['document.details.logset'] = function () {
	var moduleData,
		valueMap,
		tableValueMap,
		documentValues = {};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates invoice form
	 * @returns {Object}
	 */
	var createDetailsPanel = function (documentData) {
		var docHeaderConfig = {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSumm: true,
			noUsage: true,
			noPackage: true,
			noAperakStatus: true
		};
		var headPanel = createDocumentHeaderPanel(moduleData.initData, docHeaderConfig);

		var headData1 = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.details.logset.number'),
					input: createLabel({
						columnWidth: 0.7333,
						text: documentValues.number,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.5,
					text: documentValues.date,
					valueLabel: true,
					srcFormat: 'Ymd'
				})
			]
		});

		var headData2 = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.details.logset.origin'),
					input: createLabel({
						columnWidth: 0.7333,
						text: documentValues.originNumber,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.6
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.4,
					text: documentValues.originDate,
					valueLabel: true,
					srcFormat: 'Ymd'
				})
			]
		});

		var retail = documentValues.retail;
		switch (retail) {
			case '1':
				retail = edi.i18n.getMessage('document.details.logset.retail1');
				break;
			case '2':
				retail = edi.i18n.getMessage('document.details.logset.retail2');
				break;
			case '3':
				retail = edi.i18n.getMessage('document.details.logset.retail3');
				break;
		}
		var retailField = createField({
			title: edi.i18n.getMessage('document.details.logset.retail'),
			input: createLabel({
				columnWidth: 0.86,
				text: retail,
				valueLabel: true
			}),
			containerConfig: {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			}
		});

		var seller = createCompanySelector({
			title: 'document.seller',
			is_valid: true,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			fieldValues: moduleData.initData.data.toOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.toOrg)
				: null,
			readOnly: true
		});

		var buyer = createCompanySelector({
			title: 'document.buyer',
			is_valid: true,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			fieldValues: moduleData.initData.data.fromOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.fromOrg)
				: null,
			readOnly: true
		});

		var twoColumnsLayout = createTwoColumnsLayout([headData1, retailField, seller], [headData2, buyer], 0.5);

		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'DOCUMENT_LOGSET',
			gridColumnConfig: 'document_logset',
			data: documentValues.lines,
			margin: '16 0 0',
			readOnly: true,
			hasTotal: false,
			modalConf: {
				width: MODAL_SIZE.widthLarge,
				maxHeight: undefined
			},
			modalFormConfig: {
				title: 'terms.of.delivery',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.logset.delivery.point'),
						items: [
							{
								title: edi.i18n.getMessage('document.logset.delivery.point.iln'),
								name: 'deliveryPointIln'
							},
							{
								title: edi.i18n.getMessage('document.logset.branch'),
								name: 'branch'
							},
							{
								title: edi.i18n.getMessage('document.logset.condition.storage'),
								name: 'conditionStorage'
							},
							{
								title: edi.i18n.getMessage('document.logset.hierarchy.level'),
								name: 'hierarchyLevel'
							},
							{
								title: edi.i18n.getMessage('document.logset.shipment.point.iln'),
								name: 'shipmentPointIln'
							},
							{
								title: edi.i18n.getMessage('document.logset.multiplicity'),
								name: 'multiplicity',
								type: 'combo',
								store: edi.stores.createInlineStore([
									{
										id: '5',
										name: edi.i18n.getMessage('document.logset.multiplicity.5')
									},
									{
										id: '6',
										name: edi.i18n.getMessage('document.logset.multiplicity.6')
									}
								])
							},
							{
								title: edi.i18n.getMessage('document.logset.minimum.weight'),
								name: 'minimumWeight',
								type: 'combo',
								store: 'initWeightOfOrder'
							},
							{
								title: edi.i18n.getMessage('document.logset.delivery.time'),
								name: 'deliveryTime'
							},
							{
								title: edi.i18n.getMessage('document.logset.post.time'),
								name: 'postTime',
								type: 'combo',
								store: 'initDocumentLogsetPostTimeStore'
							},
							{
								title: 'document.logset.post.condition',
								name: 'conditionFormat'
							},
							{
								title: edi.i18n.getMessage('document.logset.delivery.type'),
								name: 'deliveryTypeFormat'
							},
							{
								title: edi.i18n.getMessage('document.logset.schedule'),
								name: 'schedule',
								type: 'combo',
								store: edi.stores.createInlineStore([
									{
										id: '18',
										name: edi.i18n.getMessage('document.logset.schedule.18')
									},
									{
										id: '19',
										name: edi.i18n.getMessage('document.logset.schedule.19')
									},
									{
										id: '99',
										name: edi.i18n.getMessage('document.logset.schedule.99')
									}
								])
							},
							{
								title: edi.i18n.getMessage('document.logset.min.pallets'),
								name: 'minPallets'
							},
							{
								title: edi.i18n.getMessage('document.logset.pallets'),
								name: 'pallets'
							},
							{
								title: edi.i18n.getMessage('document.logset.min.purchase'),
								name: 'minPurchase'
							},
							{
								title: edi.i18n.getMessage('document.logset.delivery.time.from.sending'),
								name: 'deliveryTimeFromSending'
							},
							{
								title: edi.i18n.getMessage('document.logset.frequency'),
								name: 'frequency'
							},
							{
								title: edi.i18n.getMessage('document.logset.confirmation'),
								name: 'confirmation'
							}
						]
					}
				]
			}
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, productsGrid]
		});
	};

	/**
	 * Creates action panel
	 */
	var createModuleActionsPanel = function () {
		var data = moduleData.initData.data;
		var actionsPanel = createActionsPanel();
		var excludeActions = {};

		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PACKAGING] = true;

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			moduleData: moduleData,
			excludeActions: excludeActions,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.logset'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				REJECT: {
					methodAddOptions: {
						isDisableRejectModal: true
					}
				},
				SIGN: {
					methodAddOptions: {
						beforeInit: function (success) {
							if (edi.constants.STATE.SENT === data.state) {
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: data.id
										},
										true
									),
									'PUT',
									Ext.encode({}),
									success,
									null,
									function () {
										changeHandler(data);
									}
								);
							} else {
								success();
							}
						}
					}
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					var documentData = data.data;
					// @formatter:off
					valueMap = {
						number: 'bgm.c106.e1004',
						date: 'dtm.c507[e2005==137]e2380',
						originNumber: 'sg1[rff.c506.e1153==LS]rff.c506.e1154',
						originDate: 'sg1[dtm.c507.e2005==171]dtm.c507.e2380',
						retail: 'sg1[rff.c506.e1153==PB]rff.c506.e1154',
						seller: 'sg2.nad[e3035==SU]c082',
						buyer: 'sg2.nad[e3035==BY]c082'
					};
					tableValueMap = {
						LineNumber: 'sg12.lin.e1082',
						multiplicity: 'sg12.mea[e6311==CT]c502.e6321',
						minimumWeight: 'sg12.mea[e6311==PD]c502.e6155',
						deliveryTime: 'sg12.dtm[c507.e2005==766]c507.e2380',
						postTime: 'sg12.dtm[c507.e2005==64]c507.e2380',
						condition: 'sg12.ftx[e4451==AAR]e4453',
						deliveryType: 'sg12.ftx[e4451==AAR]e4447',
						schedule: 'sg12.sg17.scc.c329.e2015',
						minPallets: 'sg12.sg17.sg18[qty.c186.e6063==53]qty.c186.e6060',
						pallets: 'sg12.sg17.sg18[qty.c186.e6063==267]qty.c186.e6060',
						minPurchase: 'sg12.sg17.sg18[qty.c186.e6063==26E]qty.c186.e6060',
						deliveryTimeFromSending: 'sg12.sg17.sg18[qty.c186.e6063==40]qty.c186.e6060',
						frequency: 'sg12.sg17.sg18[qty.c186.e6063==135]qty.c186.e6060',
						confirmation: 'sg12.sg17.sg18[qty.c186.e6063==55]qty.c186.e6060',
						deliveryPointIln: 'sg7.nad[e3035==DP]c082.e3039',
						branch: 'sg7.nad[e3035==DP]c819.e3228',
						hierarchyLevel: 'sg7.loc[e3227==7]c517.e3224',
						shipmentPointIln: 'sg12.sg22[nad.e3035==CZ]nad.c082.e3039',
						conditionStorage: 'sg7.loc[e3227==7]c517.e3225'
					};
					// @formatter:on
					Object.entries(valueMap).forEach(function ([name, path]) {
						documentValues[name] = edi.utils.getObjectPropertyEx(documentData, path);
					});
					documentValues.lines = [];
					var lines = edi.utils.getObjectProperty(documentData, 'sg6');
					lines?.forEach(function (line) {
						var lineValues = {};
						Object.entries(tableValueMap).forEach(function ([name, path]) {
							lineValues[name] = edi.utils.getObjectPropertyEx(line, path);
						});
						documentValues.lines.push(lineValues);
					});
					modulePanel.add(createDetailsPanel(documentData));

					moduleData.tab.add(createModuleActionsPanel());
					moduleData.tab.add(modulePanel);
					if ('function' == typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					failure(data);
				}
			},
			failure
		);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
