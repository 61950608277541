import { createField } from '@Components/fields';
import { createLabel } from '@UIkit/components/fields_old/Label';
import { createContainer, createPanel } from '@UIkit/components/panels';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createDetailsModulePanel } from '@Components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';

Ext.namespace('edi.modules');
edi.modules['settings'] = function () {
	var moduleData;
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	var createLayout = function () {
		var userData = edi.core.getUserData(),
			userOrg = userData.org;

		var fullnameField = createField({
			title: 'ФИО',
			input: createLabel({
				columnWidth: 0.7,
				text: edi.utils.formatString('{0} {1}', [userData.lastName, userData.firstName]),
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});
		var accessField = createField({
			title: 'Доступ',
			input: createLabel({
				columnWidth: 0.7,
				text: '',
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});
		var emailField = createField({
			title: 'Почта',
			input: createLabel({
				columnWidth: 0.7,
				text: edi.utils.getObjectProperty(userData, 'eMail'),
				valueLabel: true
			}),
			containerConfig: {
				columnWidth: 1
			}
		});
		var passwordField = createField({
			title: 'Пароль',
			input: createContainer({
				columnWidth: 0.7,
				layout: 'column',
				items: [
					createLabel({
						columnWidth: 0.7,
						text: '***********',
						valueLabel: true
					}),
					createButton({
						cls: BUTTON_CLS.primary,
						columnWidth: 0.3,
						text: 'Изменить пароль'
					})
				]
			}),
			containerConfig: {
				columnWidth: 1
			}
		});

		var twoColumnsLayout = createTwoColumnsLayout(
			[
				createPanel({
					title: 'Общая информация',
					bodyPadding: '10 20',
					items: [fullnameField, accessField, emailField, passwordField]
				}),
				createPanel({
					title: 'Уведомления',
					bodyPadding: '10 20',
					items: [
						createContainer({
							html: '-------',
							margin: '0 0 15 0'
						})
					]
				})
			],
			[],
			0.5,
			{
				margin: '15 0 0 0',
				items1Conf: {
					margin: '0 10 0 0'
				},
				items2Conf: {
					margin: '0 0 0 10'
				}
			}
		);

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [twoColumnsLayout]
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createDetailsModulePanel();

		modulePanel.add(createLayout());
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
