import './methods.js';
import './EDI_AGREEMENT_PACKAGE/document.create.package.js';
import './EDI_AGREEMENT_PACKAGE/document.details.package.js';
import './EDI_AGREEMENT_PACKAGE/package.document.form.js';
import './EDI_AGREEMENT_PACKAGE/actionRules.js';
import './EDI_AGREEMENT_PACKAGE_DR/document.create.package.dr.js';
import './EDI_AGREEMENT_PACKAGE_DR/document.details.package.dr.js';
import './EDI_AGREEMENT_PACKAGE_DR/package.dr.document.form.js';
import './EDI_AGREEMENT_PACKAGE_DR/actionRules.js';
import './EDI_AGREEMENT_PACKAGE_NE/document.create.package.ne.js';
import './EDI_AGREEMENT_PACKAGE_NE/document.details.package.ne.js';
import './EDI_AGREEMENT_PACKAGE_NE/package.ne.document.form.js';
import './EDI_AGREEMENT_PACKAGE_NE/actionRules.js';
