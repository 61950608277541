const __storages = {};

/**
 * Creates data storage with global access by storageId and debounced update
 * @param	{string}	storageId	unique ID for new storage
 * @param 	{Object}	params
 * @param	{Function}	params.updateFn					get new data action with callback
 * @param	{number}	params.updateDebounceTimeout	time interval in ms for debounce updates
 * @param	{boolean}	params.autoRefresh			if true store will invoke updateFn by timer
 * @param	{number}	params.autoRefreshTimeout	time interval in ms for autoRefresh
 * @param	{boolean}	params.autoDelete			if true store will check count of callbacks by timer. And if there is no callback storage will be deleted
 * @param	{number}	params.autoDeleteTimeout	time interval in ms for autoDelete
 * @return 	{boolean|string}	storageId if success
 */
const initStorage = function (storageId, params) {
	if (!storageId || isStorageExists(storageId)) {
		return false;
	}

	let props = Object.assign({}, params);
	const newStorage = {
		id: storageId,
		data: {},
		callbacks: new Set(),
		updateFn: typeof props.updateFn === 'function' ? props.updateFn : (success) => success({}),
		updateDebounceTimeout: props.updateDebounceTimeout || 200,
		autoRefresh: !!props.autoRefresh,
		autoRefreshTimeout: props.autoRefreshTimeout || 5000,
		autoDelete: props.autoDelete !== false,
		autoDeleteTimeout: props.autoDeleteTimeout || 5000,
		__debounceTimer: null,
		__autoRefreshTimer: null,
		__autoDeleteTimer: null,
		isDeleted: false,
		isFetching: true
	};

	if (newStorage.autoRefresh && newStorage.autoRefreshTimeout > 0) {
		let startAutoRefresh = function () {
			if (newStorage.__autoRefreshTimer) {
				clearTimeout(newStorage.__autoRefreshTimer);
			}
			newStorage.__autoRefreshTimer = setTimeout(function () {
				refreshStorage(storageId, startAutoRefresh, startAutoRefresh);
			}, newStorage.autoRefreshTimeout);
		};
		startAutoRefresh();
	}

	if (newStorage.autoDelete && newStorage.autoDeleteTimeout > 0) {
		let startAutoRemove = function () {
			if (newStorage.__autoDeleteTimer) {
				clearTimeout(newStorage.__autoDeleteTimer);
			}
			newStorage.__autoDeleteTimer = setTimeout(function () {
				let isDeleted = false;
				if (!newStorage.callbacks || newStorage.callbacks.size === 0) {
					isDeleted = removeStorage(storageId);
				}
				if (!isDeleted) {
					startAutoRemove();
				}
			}, newStorage.autoDeleteTimeout);
		};
		startAutoRemove();
	}

	__storages[storageId] = newStorage;
	return storageId;
};

/**
 * Return true if storage with storageId exists
 * @param	{string}	storageId
 * @return	{boolean}	isExists
 */
const isStorageExists = function (storageId) {
	return !!__storages[storageId];
};

/**
 * Registers callback to storage update event
 * @param	{string}	storageId	storage ID
 * @param 	{Function}	cb			will be invoked after storage update
 * @return 	{boolean}	true if success
 */
const subscribe = function (storageId, cb) {
	const storage = __storages[storageId];
	if (!storage || storage.isDeleted || !cb) {
		return false;
	}
	storage.callbacks.add(cb);
	return true;
};

/**
 * Unregisters callback from storage
 * @param	{string}	storageId	storage ID
 * @param 	{Function}	cb			callback to detach
 * @return 	{boolean}	true if success
 */
const unsubscribe = function (storageId, cb) {
	const storage = __storages[storageId];
	if (!storage || storage.isDeleted || !cb) {
		return false;
	}
	storage.callbacks.delete(cb);

	if (storage.autoDelete && (!storage.callbacks || storage.callbacks.size === 0)) {
		removeStorage(storageId);
	}

	return true;
};

/**
 * Refresh storage and invoke registered callbacks
 * @param	{string}	storageId	storage ID
 * @param 	{Function}	success		will be invoked after storage update and registered callbacks called
 * @param 	{Function}	failure		called after error accrued while fetching data
 * @return 	{boolean}	true if update process started
 */
const refreshStorage = function (storageId, success, failure) {
	const storage = __storages[storageId];
	if (!storage || storage.isDeleted || typeof storage.updateFn !== 'function') {
		return false;
	}

	const successFn = (newData) => {
		storage.data = newData;
		storage.isFetching = false;
		storage.callbacks.forEach((cb) => {
			if (typeof cb === 'function') {
				cb(storage.data);
			}
		});
		if (typeof success === 'function') {
			success(storage.data);
		}
	};

	const failureFn = (err) => {
		storage.isFetching = false;
		if (typeof failure === 'function') {
			failure(err);
		}
	};

	const update = () => {
		storage.isFetching = true;
		storage.updateFn(successFn, failureFn);
	};

	if (storage.updateDebounceTimeout > 0) {
		if (storage.__debounceTimer) {
			clearTimeout(storage.__debounceTimer);
		}
		storage.__debounceTimer = setTimeout(update, storage.updateDebounceTimeout);
	} else {
		update();
	}

	return true;
};

/**
 * Manually sets new data to storage and invoke registered callbacks same way like with normal refresh
 * @param	{string}	storageId	storage ID
 * @param	{Object}	newData		new data for storage
 * @param 	{Function}	callbackFn	will be invoked after storage update and registered callbacks called
 * @return 	{boolean}	true after update process finished
 */
const setDataToStorage = function (storageId, newData, callbackFn) {
	const storage = __storages[storageId];
	if (!storage || storage.isDeleted || typeof newData === 'undefined') {
		return false;
	}

	storage.data = newData;
	storage.isFetching = false;
	storage.callbacks.forEach((cb) => {
		if (typeof cb === 'function') {
			cb(storage.data);
		}
	});
	if (typeof callbackFn === 'function') {
		callbackFn(storage.data);
	}

	return true;
};

/**
 * Returns storage data if storage exists
 * @param	{string}	storageId
 * @param	{Function}	callback	callback which receives storage's data
 * @return	{boolean}	true if everything ok
 */
const getStorageData = function (storageId, callback) {
	const storage = __storages[storageId];
	if (!storage || typeof callback !== 'function') {
		callback();
		return false;
	}

	const checkAndResolve = () => {
		if (storage.isFetching === false) {
			callback(storage.data);
		} else {
			setTimeout(checkAndResolve, 100);
		}
	};
	checkAndResolve();

	return true;
};

/**
 * Removes storage and clear delayed events
 * @param	{string}	storageId	storage ID
 * @return 	{boolean}	true if success
 */
const removeStorage = function (storageId) {
	const storage = __storages[storageId];
	if (!storage) {
		return false;
	}
	clearTimeout(storage.__debounceTimer);
	clearTimeout(storage.__autoRefreshTimer);
	clearTimeout(storage.__autoDeleteTimer);
	storage.callbacks.clear();
	storage.isDeleted = true;
	delete __storages[storageId];
	return true;
};

Ext.namespace('edi.global_storage');
edi.global_storage.__storages = __storages;
edi.global_storage.initStorage = initStorage;
edi.global_storage.subscribe = subscribe;
edi.global_storage.unsubscribe = unsubscribe;
edi.global_storage.refreshStorage = refreshStorage;
edi.global_storage.setDataToStorage = setDataToStorage;
edi.global_storage.removeStorage = removeStorage;

export {
	__storages,
	initStorage,
	isStorageExists,
	subscribe,
	unsubscribe,
	refreshStorage,
	setDataToStorage,
	getStorageData,
	removeStorage
};
