import { moduleSettings, roseuInvitationsEvents } from './moduleCommons.js';
import { createModuleFilterForm } from '@Components/moduleFilterForm';
import { createCombo, createNumberField, createTextField } from '@Components/fields';
import { createModulePanel, createTabPanel } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { ROSEU_INVITATIONS_MODEL_CONFIG_NAME } from '@Edi/modules/roseu_invitations/models';
import { ROSEU_INVITATIONS_COLUMNS_CONFIG_NAME } from '@Edi/modules/roseu_invitations/columns';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';
import { createPanel } from '@UIkit/components/panels';

Ext.namespace('edi.modulesCfg');
edi.modulesCfg['roseu_invitations'] = {
	title: 'roseu_invitations',
	modName: 'roseu_invitations',
	permissions: ['CLIENT_ROSEU_INVITATION_LIST']
};

Ext.namespace('edi.modules');
edi.modules['roseu_invitations'] = function () {
	let moduleData,
		grids = {},
		filterObjects = {},
		filterForm;

	/**
	 * Module initialization
	 * @param	{Object}	data			Data from openModule
	 * @param	{Function}	initCallBack	After initialization callback
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		roseuInvitationsEvents.on('change', changeHandler);
	};

	let changeHandler = function () {
		fireSearch();
	};

	let fireSearch = function () {
		Object.keys(grids).forEach((name) => {
			if (!filterObjects[name]) {
				filterObjects[name] = edi.filters.createGridFilter(
					moduleSettings.API.LIST.GET,
					filterForm,
					grids[name],
					(values) => values,
					{ gridName: name }
				);
			}
			filterObjects[name].filter();
		});
	};

	let createFilterForm = function () {
		let formItemsMap = {
			state: createCombo({
				name: 'state',
				fieldLabel: edi.i18n.getMessage('column.status'),
				store: edi.stores.createSimpleInlineStore(
					moduleSettings.FILTER_STATES,
					function (state) {
						return edi.i18n.getMessage(
							state === edi.constants.STATE.SENT
								? 'documents.status.SENT.documents.filter'
								: `documents.status.${state}`
						);
					},
					true
				)
			}),
			fromOrgFnsId: createTextField({
				name: 'fromOrgFnsId',
				fieldLabel: edi.i18n.getMessage('roseu_invitations.from_org_fns_id')
			}),
			fromOrgName: createTextField({
				name: 'fromOrgName',
				fieldLabel: edi.i18n.getMessage('roseu_invitations.from_org_name')
			}),
			fromOrgInn: createNumberField({
				name: 'fromOrgInn',
				fieldLabel: edi.i18n.getMessage('roseu_invitations.from_org_inn')
			}),
			toOrgFnsId: createTextField({
				name: 'toOrgFnsId',
				fieldLabel: edi.i18n.getMessage('roseu_invitations.to_org_fns_id')
			}),
			toOrgName: createTextField({
				name: 'toOrgName',
				fieldLabel: edi.i18n.getMessage('roseu_invitations.to_org_name')
			}),
			toOrgInn: createNumberField({
				name: 'toOrgInn',
				fieldLabel: edi.i18n.getMessage('roseu_invitations.to_org_inn')
			})
		};

		filterForm = createModuleFilterForm(
			{
				saveFilter: {
					name: moduleData.modName
				},
				formItemsMap,
				items: [
					createPanel({
						layout: {
							type: 'grid',
							area: [[6, 6], [6, 6], [6, 6], 6]
						},
						items: [
							formItemsMap.fromOrgName,
							formItemsMap.toOrgName,
							formItemsMap.fromOrgFnsId,
							formItemsMap.toOrgFnsId,
							formItemsMap.fromOrgInn,
							formItemsMap.toOrgInn,
							formItemsMap.state
						]
					})
				]
			},
			fireSearch
		);

		return filterForm;
	};

	let createGridsTabPanel = function () {
		let gridsTabPanel = createTabPanel(undefined, true);

		['all', 'incoming', 'outgoing'].forEach((name, i) => {
			let columns = edi.columns.get(ROSEU_INVITATIONS_COLUMNS_CONFIG_NAME);
			columns.push(
				createActionsColumnConfig({
					items: [
						{
							glyph: edi.constants.ICONS.DETAILS,
							testCls: 'test-action-icon-details',
							handler: function (grid, rowIndex, colindex, actionItem, event, record) {
								edi.core.openModule('roseu_invitation.details', record.getData());
							}
						}
					]
				})
			);

			grids[name] = createGrid({
				proxyConfig: {
					url: moduleSettings.API.LIST.GET
				},
				storeConfig: {
					model: edi.models.getModel(ROSEU_INVITATIONS_MODEL_CONFIG_NAME)
				},
				gridConfig: {
					columns,
					listeners: {
						celldblclick: function (view, td, cellIndex, record) {
							edi.core.openModule('roseu_invitation.details', record.getData());
						}
					}
				}
			});

			initTabWithGrid(name, grids[name], i === 0, gridsTabPanel, {
				title: edi.i18n.getMessage(`roseu_invitations.tab.UI Platform Maven Webapp`)
			});
		});

		return gridsTabPanel;
	};

	let renderData = function (initCallBack) {
		moduleData.tab.removeAll();

		let modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});
		modulePanel.add(createFilterForm());
		modulePanel.add(createGridsTabPanel());
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}

		fireSearch();
	};

	/**
	 * Routine that must be done before module destroy
	 */
	let onDestroy = function () {
		roseuInvitationsEvents.un('change', changeHandler);
	};
};
