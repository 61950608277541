/**
 * Requests configuration for edi
 * @author Anatoly Deryshev
 */
Ext.namespace('edi.rest');
Object.assign(edi.rest.services, {
	USER_SELF_SIGNER_SETTINGS: {
		GET: edi.rest.prfx.c + 'signer',
		DELETE: edi.rest.prfx.c + 'signer/{id}',
		POST: edi.rest.prfx.c + 'signer',
		PUT: edi.rest.prfx.c + 'signer/{id}'
	},
	FACTORS: {
		GET: edi.rest.prfx.c + 'organization/factors'
	},
	REJECT_REASON: {
		UPD: edi.rest.prfx.c + 'utoch/text/upd/{id}',
		UKD: edi.rest.prfx.c + 'utoch/text/ukd/{id}'
	},
	ORG_MARKING: {
		HAS_PARTNER: {
			GET: edi.rest.prfx.c + 'organization/marking/has_partner_exclusions'
		},
		DISABLE_NOTIFICATION: {
			PUT: edi.rest.prfx.c + 'organization/marking/disable_notification'
		}
	},
	CHECK_CRPT_STATUS: {
		POST: edi.rest.prfx.c + 'documents/updCheckCRPT',
		GET: edi.rest.prfx.c + 'documents/updCheckCRPT/status/{taskId}',
		CANCEL: {
			GET: edi.rest.prfx.c + 'documents/updCheckCRPT/cancel/{taskId}'
		},
		MARKING_AUTH: {
			POST: edi.rest.prfx.c + 'marking/auth/{taskId}'
		},
		PRINT_ERROR_REPORT: {
			POST: edi.rest.prfx.c + 'marking/reports/{docId}'
		}
	}
});

edi.rest.services.DOCUMENTS.EXPORT.SIMPLE_WITH_SIGN = edi.rest.prfx.c + 'documents/{documentId}/export/simple';
edi.rest.services.DOCUMENTS.EXPORT.COMMON_ALL_DOCUMENT = edi.rest.prfx.c + 'documents/{documentId}/export/common';
