import { createProductGridBase } from '@Components/product.grid';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import { createCreateSaveButton } from '@Components/buttons';
import { createCombo, createDateField, createTextField } from '@Components/fields';
import { createModalPartInCatalogSelectForGrid } from '@Edi/specialComponents/modalPartInCatalogSelect';
import { createButtonContainer, createContainer, createFieldBlock } from '@UIkit/components/panels';
import { createLabel, createDateRangeField } from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { PRICAT_EANCOM_LINE_COLUMN } from '@Edi/modules/documents/PRICAT_EANCOM/columns';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

Ext.namespace('edi.modules');
edi.modules['document.create.pricateancom'] = function () {
	let moduleData,
		seller,
		buyer,
		parentId,
		isEdit = false,
		documentData = {},
		receiverId,
		form,
		productsGrid,
		buyerFNSid,
		guid,
		sellerFNSid,
		sellerId,
		isNumberAutoGenerated = false,
		deliveryPlaceValues,
		exceptionsPlaceValues,
		buyerOrg,
		doctype = edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
		userData = edi.core.getUserData(),
		typePriceNoticeField,
		productValues = {
			products: []
		};
	let isCopy = false;
	const orgConverter = edi.converters.convertPricatPartie;

	const valuesMap = {
		docNumber: 'bgm.c106.e1004',
		docName: 'bgm.c002.e1001',
		agreementDate: {
			path: 'sg1.0.dtm.c507.e2380',
			defaultValues: [
				{
					path: 'sg1.0.dtm.c507.e2005',
					value: '171'
				},
				{
					path: 'sg1.0.dtm.c507.e2379',
					value: '102'
				}
			]
		},
		agreementNumber: {
			path: 'sg1.0.rff.c506.e1154',
			defaultValues: [
				{
					path: 'sg1.0.rff.c506.e1153',
					value: 'CT'
				}
			]
		},
		dateDocument: {
			path: 'dtm.0.c507.e2380',
			defaultValues: [
				{
					path: 'dtm.0.c507.e2005',
					value: '137'
				},
				{
					path: 'dtm.0.c507.e2379',
					value: '102'
				}
			]
		},
		startingDateNewPrice: {
			path: 'dtm.1.c507.e2380',
			defaultValues: [
				{
					path: 'dtm.1.c507.e2005',
					value: '194'
				},
				{
					path: 'dtm.1.c507.e2379',
					value: '102'
				}
			]
		},
		endingDateNewPrice: {
			path: 'dtm.2.c507.e2380',
			defaultValues: [
				{
					path: 'dtm.2.c507.e2005',
					value: '206'
				},
				{
					path: 'dtm.2.c507.e2379',
					value: '102'
				}
			]
		},
		'Seller.iln': {
			path: 'sg2.0.nad.c082.e3039',
			defaultValues: [
				{
					path: 'sg2.0.nad.c082.e3055',
					value: 9
				},
				{
					path: 'sg2.0.nad.e3035',
					value: 'SU'
				}
			]
		},
		'Seller.company_name': 'sg2.0.nad.c080.e3036',
		'Seller.inn': {
			path: 'sg2.0.sg3.0.rff.c506.e1154',
			defaultValues: [
				{
					path: 'sg2.0.sg3.0.rff.c506.e1153',
					value: 'FC'
				}
			]
		},
		'Seller.kpp': {
			path: 'sg2.0.sg3.1.rff.c506.e1154',
			defaultValues: [
				{
					path: 'sg2.0.sg3.1.rff.c506.e1153',
					value: 'XA'
				}
			]
		},
		'Buyer.iln': {
			path: 'sg2.1.nad.c082.e3039',
			defaultValues: [
				{
					path: 'sg2.1.nad.c082.e3055',
					value: 9
				},
				{
					path: 'sg2.1.nad.e3035',
					value: 'BY'
				}
			]
		},
		'Buyer.company_name': 'sg2.1.nad.c080.e3036',
		'Buyer.inn': {
			path: 'sg2.1.sg3.0.rff.c506.e1154',
			defaultValues: [
				{
					path: 'sg2.1.sg3.0.rff.c506.e1153',
					value: 'FC'
				}
			]
		},
		'Buyer.contact_person': {
			path: 'sg2.1.sg4.0.cta.c056.e3412',
			defaultValues: [
				{
					path: 'sg2.1.sg4.0.cta.e3139',
					value: 'MGR'
				}
			]
		},
		'Buyer.contact_email': {
			path: 'sg2.1.sg4.0.com.c076.e3148',
			defaultValues: [
				{
					path: 'sg2.1.sg4.0.com.c076.e3155',
					value: 'EM'
				}
			]
		}
	};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		const isProductsValid = productValues.products.length && productsGrid.isValid(),
			isFormValid = form.isValid(),
			isValidCompanies = seller.isValid() && buyer.isValid();

		return isProductsValid && isFormValid && isValidCompanies;
	};

	const checkBuyer = function () {
		const org = buyer.selectedOrg;
		const orgValues = buyer.getValues();
		const orgId = org ? org.id : null;

		buyerFNSid = isEdit ? buyerFNSid : edi.utils.getObjectProperty(org, 'fnsId');

		if (productsGrid.getPartnerId() != orgId) {
			productsGrid.productsGrid.getStore().removeAll();
		}
		productsGrid.setPartnerId(orgId);

		if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
			buyerOrg = org;
		} else if (!orgId) {
			buyerOrg = null;
		}
		buyer.is_valid = !!orgValues.contact_person && !!orgValues.contact_email;
	};

	const createInvoiceTableItem = function (documentData) {
		const lines = edi.utils.getObjectProperty(documentData, 'sg17');

		for (let i = 0; i < lines.length; i++) {
			const product = lines[i];
			productValues.products.push(product);
		}
		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('pricat_eancom.lines.grid'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'EANCOM_LINE',
			gridColumnConfig: PRICAT_EANCOM_LINE_COLUMN,
			data: productValues.products,
			lineNumberFieldName: 'LineNumber',
			lockIfNoPartner: true,
			hasTotal: false,
			configModalButtonsBefore: function () {
				return [];
			},
			isValidRecord: function (record) {
				return (
					record.get('ItemCode') &&
					record.get('Heading') &&
					record.get('CurrentPrice') &&
					record.get('NewPrice')
				);
			},
			callback: function (products) {
				moduleData.isChanged = true;
				productValues = products;
				checkValid();
			},
			modalConf: {
				width: MODAL_SIZE.widthLarge,
				maxHeight: undefined
			},
			modalFormConfig: {
				title: 'document.upd.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								type: 'text',
								allowBlank: true,
								regex: edi.constants.VALIDATORS.EAN13,
								invalidText: edi.i18n.getMessage('invalid.field.ean.value.default')
							},
							{
								title: edi.i18n.getMessage('column.pricate.eancom.item.code'),
								name: 'ItemCode',
								type: 'text',
								allowBlank: false,
								maxLength: 13
							},
							{
								title: edi.i18n.getMessage('column.pricate.eancom.heading'),
								name: 'Heading',
								type: 'text',
								allowBlank: false,
								maxLength: 128
							},
							{
								title: edi.i18n.getMessage('column.pricate.eancom.current.price'),
								name: 'CurrentPrice',
								type: 'text',
								allowBlank: false,
								allowDecimals: true,
								regex: edi.constants.VALIDATORS.EANCOM_PRICE
							},
							{
								title: edi.i18n.getMessage('column.pricate.eancom.new.price'),
								name: 'NewPrice',
								type: 'text',
								allowBlank: false,
								allowDecimals: true,
								regex: edi.constants.VALIDATORS.EANCOM_PRICE
							}
						]
					}
				]
			}
		});
		productsGrid.setPartnerId(buyerOrg ? buyerOrg.id : null);
		return productsGrid;
	};

	const findOrgByValues = function (searchValues) {
		if (!searchValues) {
			return null;
		}
		let organizations = edi.relations.getRelations({ self: true });
		let orgData = organizations.find(function (o) {
			return o.iln === searchValues.iln;
		});
		return orgData || null;
	};

	/**
	 * Creates pricat eancom form
	 * @returns {Object}
	 */
	const createModuleForm = function (documentData) {
		documentData = documentData || {};

		const formatPricatDate = function (date) {
			if (!date) {
				return '';
			}
			const year = date.slice(0, 4);
			const month = date.slice(4, 6);
			const day = date.slice(-2);
			return day + '-' + month + '-' + year;
		};

		const title = createLabel({
			typography: 'heading_02',
			text: isEdit
				? edi.i18n.getMessage('document.create.pricateancom.edit') + documentData.docNumber
				: edi.i18n.getMessage('document.create.pricateancom')
		});

		const docName = createCombo({
			fieldLabel: edi.i18n.getMessage('pricat_eancom.document.type'),
			name: 'docName',
			store: edi.stores.createSimpleInlineStore(['9', '25E'], function (id) {
				return edi.i18n.getMessage('pricate_eancom.name.code.' + id);
			}),
			value: documentData.docName,
			allowBlank: false
		});

		const docNumber = createTextField({
			fieldLabel: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('pricat.nr'),
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			name: 'docNumber',
			minLength: 1,
			maxLength: 32,
			value: !edi.utils.isEmptyObject(documentData)
				? documentData.docNumber + (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
				: undefined
		});

		const dateDocument = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			name: 'dateDocument',
			submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM,
			value: isEdit && documentData ? formatPricatDate(documentData.dateDocument) : new Date()
		});

		const partiesSeller = edi.utils.getObjectProperty(documentData, 'Seller'),
			isSellerDefined = !!partiesSeller,
			sellerValues = isSellerDefined ? documentData : edi.converters.convertPricatPartie(userData.org);
		sellerId = isSellerDefined ? findOrgByValues(sellerValues.Seller)?.id : userData.org.id;
		const createOS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				valuesByMap: true,
				forceChangeFields: {
					company_inn: true,
					contact_email: true,
					contact_person: true
				},
				callback: checkValid,
				processValues: orgConverter
			});

			return createOrgSelector(selectorConfig);
		};

		sellerFNSid = edi.utils.getObjectProperty(edi.converters.convertPricatPartie(userData.org), 'fns_id');

		seller = createOS({
			itemId: 'seller',
			readOnly: isEdit,
			fieldValues: sellerValues,
			valuesByMap: isSellerDefined,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id,
				converter: orgConverter
			}),
			selectedRelationByMap: isSellerDefined,
			is_valid: isSellerDefined || !isEdit,
			selectedOrgValues: sellerValues,
			selectedOrg: userData.org,
			fieldsMap: edi.selectors.getPRICATEANCOMFieldsMap('Seller'),
			modalConf: {
				tabs: {
					main: {
						company_gln: edi.selectors.defaults.company_gln,
						company_name: edi.selectors.mergeConfigs('company_name', {
							title: 'company.name',
							allowBlank: false
						}),
						company_inn: edi.selectors.mergeConfigs('company_inn', {
							title: 'company.inn',
							allowBlank: false
						}),
						company_kpp: edi.selectors.mergeConfigs('company_kpp', {
							title: 'company.kpp'
						})
					}
				},
				title: edi.i18n.getMessage('documents.pricate_eancom.seller')
			},
			callback: function (_values, org) {
				const orgId = org ? org.id : null;
				if (productsGrid.getPartnerId() != orgId) {
					productsGrid.productsGrid.getStore().removeAll();
				}
				sellerId = orgId;
				productsGrid.setPartnerId(orgId);
				checkValid();
			}
		});

		const sellerBlock = createFieldBlock({
			title: edi.i18n.getMessage('documents.pricate_eancom.seller'),
			items: [seller]
		});

		const partiesBuyer = edi.utils.getObjectProperty(documentData, 'Buyer'),
			isBuyerDefined = !!partiesBuyer,
			buyerValues = isBuyerDefined ? documentData : undefined;

		buyer = createOS({
			itemId: 'buyer',
			relationsOnly: true,
			useHiddenFields: true,
			allowBlank: false,
			readOnly: false,
			is_valid: isBuyerDefined,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			valuesByMap: isBuyerDefined,
			relations: isBuyerDefined ? buyerOrg : edi.relations.getRelations(),
			allowReset: !isBuyerDefined,
			fieldValues: buyerValues,
			selectedOrgValues: buyerValues,
			selectedOrg: isBuyerDefined ? buyerOrg : null,
			selectedRelationByMap: isBuyerDefined,
			fieldsMap: edi.selectors.getPRICATEANCOMFieldsMap('Buyer'),
			modalConf: {
				tabs: {
					main: {
						company_gln: edi.selectors.defaults.company_gln,
						company_name: edi.selectors.mergeConfigs('company_name', {
							title: 'company.name',
							allowBlank: false
						}),
						company_inn: edi.selectors.mergeConfigs('company_inn', {
							title: 'company.inn',
							allowBlank: false
						}),
						contact_person: edi.selectors.mergeConfigs('contact_person', {
							title: 'company.contact.person',
							allowBlank: false
						}),
						contact_email: edi.selectors.mergeConfigs('contact_email', {
							title: 'company.contact.email',
							regex: edi.constants.VALIDATORS.EMAIL,
							invalidText: edi.i18n.getMessage('invalid.email.format'),
							allowBlank: false
						})
					}
				},
				title: edi.i18n.getMessage('documents.pricate_eancom.buyer')
			},
			callback: function () {
				checkBuyer();
				checkValid();
				selectFromPartinDeliveryPlaceButton.setDisabled(!buyerOrg?.id || !sellerId);
				selectFromPartinExceptionsPlaceButton.setDisabled(!buyerOrg?.id || !sellerId);
			}
		});

		const buyerBlock = createFieldBlock({
			title: edi.i18n.getMessage('documents.pricate_eancom.buyer'),
			items: [buyer]
		});

		const selectFromPartinDeliveryPlaceButton = createButton({
			cls: BUTTON_CLS.secondary,
			text: edi.i18n.getMessage('form.btn.select.product'),
			glyph: edi.constants.ICONS.SEARCH,
			margin: '0 0 0 8',
			disabled: !buyerOrg?.id || !sellerId,
			handler: function () {
				let options = {
					modalTitle: edi.i18n.getMessage('documents.pricate_eancom.select.storage')
				};
				let modal = createModalPartInCatalogSelectForGrid(
					buyerOrg.id,
					sellerId,
					function (data) {
						const store = deliveryPlaceGrid.productsGrid.getStore();
						if (-1 === store.find('iln', data.iln)) {
							deliveryPlaceGrid.setGridRow(data);
						}
						const exceptionsStore = exceptionsPlaceGrid.productsGrid.getStore();
						const record = exceptionsStore.findRecord('iln', data.iln);
						if (record) {
							exceptionsStore.remove(record);
							if ('function' == typeof exceptionsPlaceGrid.afterRowRemove) {
								exceptionsPlaceGrid.afterRowRemove(record);
							}
						}
					},
					options
				);
				modal.show();
			}
		});
		deliveryPlaceValues = edi.utils.getObjectProperty(documentData, 'storageInformation');
		const deliveryPlaceGrid = createProductGridBase({
			title: edi.i18n.getMessage('documents.pricate_eancom.storage'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'PARTIN_DELIVERY_PLACE',
			gridColumnConfig: 'partin_delivery_place',
			data: edi.utils.getObjectProperty(documentData, 'storageInformation'),
			lockIfNoPartner: false,
			showSelectProductBtn: false,
			hasTotal: false,
			modalConf: {
				width: MODAL_SIZE.widthSmall,
				maxHeight: undefined
			},
			checkValuesBeforeSave(values) {
				let allFieldsAreEmpty = Object.values(values || {})
					.map((v) => String(v).trim())
					.every((v) => v.length === 0);
				if (allFieldsAreEmpty === true) {
					edi.core.showError(edi.i18n.getMessage('documents.pricat.price.warning.empty.grid.values'));
					return false;
				}
				return true;
			},
			modalFormConfig: {
				title: 'documents.pricate_eancom.storage',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.partin.tab.localization.info'),
						items: [
							{
								title: 'column.name',
								name: 'name',
								maxLength: 128,
								allowBlank: false
							},
							{
								title: 'company.gln.short',
								maxLength: 100,
								name: 'iln',
								regex: edi.constants.VALIDATORS.GLN,
								allowBlank: false
							}
						]
					}
				]
			},
			addedTools: [selectFromPartinDeliveryPlaceButton],
			callback: function (products) {
				moduleData.isChanged = true;
				deliveryPlaceValues = products.products;
				checkValid();
			}
		});

		const selectFromPartinExceptionsPlaceButton = createButton({
			cls: BUTTON_CLS.secondary,
			text: edi.i18n.getMessage('form.btn.select.product'),
			glyph: edi.constants.ICONS.SEARCH,
			margin: '0 0 0 8',
			disabled: !buyerOrg?.id || !sellerId,
			handler: function () {
				let options = {
					modalTitle: edi.i18n.getMessage('documents.pricate_eancom.select.exception.from.storage')
				};
				let modal = createModalPartInCatalogSelectForGrid(
					buyerOrg.id,
					sellerId,
					function (data) {
						const store = exceptionsPlaceGrid.productsGrid.getStore();
						if (-1 === store.find('iln', data.iln)) {
							exceptionsPlaceGrid.setGridRow(data);
						}
						const deliveryStore = deliveryPlaceGrid.productsGrid.getStore();
						const record = deliveryStore.findRecord('iln', data.iln);
						if (record) {
							deliveryStore.remove(record);
							if ('function' == typeof deliveryPlaceGrid.afterRowRemove) {
								deliveryPlaceGrid.afterRowRemove(record);
							}
						}
					},
					options
				);
				modal.show();
			}
		});
		exceptionsPlaceValues = edi.utils.getObjectProperty(documentData, 'exceptionsFromStorage');
		const exceptionsPlaceGrid = createProductGridBase({
			title: edi.i18n.getMessage('documents.pricate_eancom.exception.from.storage'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'PARTIN_DELIVERY_PLACE',
			gridColumnConfig: 'partin_delivery_place',
			data: edi.utils.getObjectProperty(documentData, 'exceptionsFromStorage'),
			allowBlank: true,
			lockIfNoPartner: false,
			showSelectProductBtn: false,
			hasTotal: false,
			modalConf: {
				width: MODAL_SIZE.widthSmall,
				maxHeight: undefined
			},
			checkValuesBeforeSave(values) {
				let allFieldsAreEmpty = Object.values(values || {})
					.map((v) => String(v).trim())
					.every((v) => v.length === 0);
				if (allFieldsAreEmpty === true) {
					edi.core.showError(edi.i18n.getMessage('documents.pricat.price.warning.empty.grid.values'));
					return false;
				}
				return true;
			},
			modalFormConfig: {
				title: 'documents.pricate_eancom.exception.from.storage',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.partin.tab.localization.info'),
						items: [
							{
								title: 'column.name',
								name: 'name',
								maxLength: 128,
								allowBlank: false
							},
							{
								title: 'company.gln.short',
								maxLength: 100,
								name: 'iln',
								regex: edi.constants.VALIDATORS.GLN,
								allowBlank: false
							}
						]
					}
				]
			},
			addedTools: [selectFromPartinExceptionsPlaceButton],
			callback: function (products) {
				moduleData.isChanged = true;
				exceptionsPlaceValues = products.products;
				checkValid();
			}
		});

		let dateRangeNewPrice;
		const pricePeriod = createFieldBlock({
			title: edi.i18n.getMessage('pricat_eancom.new.price.period'),
			layout: {
				type: 'grid',
				gap: 8,
				area: [[4]]
			},
			items: [
				(dateRangeNewPrice = createDateRangeField({
					hideAllPeriods: true,
					fieldsConfig: {
						common: {
							submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM
						},
						from: {
							fieldLabel: '',
							allowBlank: false,
							name: 'startingDateNewPrice',
							value:
								(isEdit || isCopy) && documentData
									? formatPricatDate(documentData.startingDateNewPrice)
									: null
						},
						to: {
							fieldLabel: '',
							allowBlank: documentData?.typePriceNotice === 'ZAK',
							name: 'endingDateNewPrice',
							value:
								(isEdit || isCopy) && documentData
									? formatPricatDate(documentData.endingDateNewPrice)
									: null
						}
					}
				}))
			]
		});

		const deliveryContractNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('pricat_eancom.contract.details.number'),
			allowBlank: false,
			name: 'agreementNumber',
			minLength: 1,
			maxLength: 46,
			value: (isEdit || isCopy) && documentData ? documentData.agreementNumber : null
		});

		const dateDeliveryAgreement = createDateField({
			fieldLabel: edi.i18n.getMessage('pricat_eancom.contract.details.date'),
			allowBlank: false,
			name: 'agreementDate',
			submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM,
			value: (isEdit || isCopy) && documentData ? formatPricatDate(documentData.agreementDate) : null
		});

		const typePriceNoticeCombo = createCombo({
			fieldLabel: edi.i18n.getMessage('pricat_eancom.contract.details.price.type.notice'),
			name: 'typePriceNotice',
			value: documentData.typePriceNotice,
			store: edi.stores.createSimpleInlineStore(['ZAK', 'MAR'], function (id) {
				return edi.i18n.getMessage('pricate_eancom.type.price.notice.code.' + id);
			}),
			allowBlank: false,
			listeners: {
				change: function () {
					dateRangeNewPrice.getFields().dateTo.allowBlank = 'ZAK' === this.getValue();
					form.isValid();
				}
			}
		});

		const contractBlock = createFieldBlock({
			title: edi.i18n.getMessage('pricat_eancom.contract.details'),
			layout: {
				type: 'grid',
				gap: [8, 16],
				area: [[6, 6], 8]
			},
			items: [deliveryContractNumber, dateDeliveryAgreement, typePriceNoticeCombo]
		});

		const createBtn = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller, deliveryPlaceGrid],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					if (deliveryPlaceValues.length === 0 && exceptionsPlaceValues.length === 0) {
						return;
					}
					const values = edi.utils.collectFormValues(form);
					edi.utils.clearEmptyValues(values);
					save(values);
				}
			},
			isEdit
		);

		form = createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6], 12]
			},
			items: [
				title,
				createContainer({
					cls: 'edi-form-maxwidth',
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [[3, 4, 2], [6, 6], 12, 6, 6]
					},
					items: [docName, docNumber, dateDocument, sellerBlock, buyerBlock, pricePeriod, contractBlock]
				}),
				deliveryPlaceGrid,
				exceptionsPlaceGrid,
				createInvoiceTableItem(documentData)
			],
			buttons: createButtonContainer({
				items: [createBtn]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);

		checkValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		let defaultBuyer = null;
		let data = moduleData.initData.data;

		if (data) {
			receiverId = data.id;
			doctype = data.type;
		}

		let preloadTasks = [];

		if (data && data.id) {
			preloadTasks.push(
				new Promise((resolve, reject) => {
					isCopy = !!moduleData.initData.isCopy;
					isEdit = !isCopy;
					buyerOrg = data.toOrg;
					loadDocumentContent(data.id, resolve, reject);
				})
			);
		} else {
			data = {};

			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				preloadTasks.push(
					new Promise((resolve, reject) => {
						edi.rest.sendRequest(
							edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET,
							'GET',
							{},
							function (data) {
								if (data && data.items) {
									for (let i = 0; i < data.items.length; i++) {
										const numberingRule = data.items[i];
										if (numberingRule.typeDoc === doctype && 'ALLOW' === numberingRule.policy) {
											isNumberAutoGenerated = true;
											break;
										}
									}
									resolve();
								} else {
									reject(data);
								}
							},
							reject
						);
					})
				);
			}

			preloadTasks.push(
				(async () => {
					defaultBuyer = await getTanderOrg();
				})()
			);
		}

		Promise.allSettled(preloadTasks).then(
			() => {
				const modulePanel = createAddModulePanel({
					items: [createModuleForm(documentData)]
				});
				moduleData.tab.add(modulePanel);

				if (isEdit) {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
					moduleData.tab.updateLayout();
				} else {
					if (defaultBuyer) {
						buyer.setOrganization(defaultBuyer);

						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						buyer.presetFromRelation(function () {
							if ('function' == typeof initCallBack) {
								initCallBack();
							}
						});
					}
				}
			},
			edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				edi.modulesHandler.removeModule(moduleData);
			})
		);
	};

	/**
	 * Saves pricat eancom
	 * @param {Object}    values    values from form
	 */
	const save = function (values) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));

		const products = productValues.products.map(function (product) {
			const productObj = {
				lin: {
					e1082: product.LineNumber // Порядковый номер
				},
				pia: [
					{
						e4347: '1',
						c212: [
							{
								e7140: product.ItemCode, // Код товарной позиции
								e7143: 'IN'
							}
						]
					}
				],
				imd: {
					e7077: 'F',
					c273: {
						e7008: product.Heading // Наименование товарной позиции
					}
				},
				sg40: [
					{
						pri: {
							c509: {
								e5125: 'ABL',
								e5118: product.CurrentPrice // Действующая цена
							}
						}
					},
					{
						pri: {
							c509: {
								e5125: 'AAE',
								e5118: product.NewPrice // Новая цена
							}
						}
					}
				]
			};
			const c212 =
				product.EAN.length > 0
					? {
							e7140: product.EAN, // EAN
							e7143: 'SRV'
					  }
					: {};
			edi.utils.setObjectProperty(productObj, 'lin.c212', c212);
			return productObj;
		});

		const build = {
			unh: {
				s009: {
					e0065: 'PRICAT',
					e0052: 'D',
					e0054: '01B',
					e0051: 'UN',
					e0057: 'EAN008'
				}
			},
			bgm: {
				e1225: 9
			},
			sg9: [],
			sg17: {
				pgi: {
					e5379: 9
				},
				ftx: {
					e4451: 'AAK',
					c108: {
						e4440: values.typePriceNotice // Тип ценового уведомления
					}
				},
				sg36: products // Строки документа
			},
			unt: {
				e0074: 0 // Количество товарных позиций в документе.
			}
		};

		if (isEdit && guid) {
			edi.utils.setObjectProperty(build, 'unh.e0062', guid);
		}
		const setOrCreateObjectArrayValue = function (obj, propPath, value) {
			obj = obj && 'object' == typeof obj ? obj : {};

			const path = String(propPath).split('.');
			let tmp = obj;

			for (let i = 0; i < path.length; i++) {
				const propName = path[i];
				const isArrayItem = Ext.isNumber(+propName);
				const val = tmp[propName];
				if (undefined === val || null === val) {
					if (isArrayItem && undefined === val) {
						if (Array.isArray(tmp)) {
							tmp.push({});
						} else {
							tmp = [];
							edi.utils.setObjectProperty(obj, path.slice(0, i).join('.'), tmp);
						}
					}
					tmp[propName] = {};
				}
				if (i === path.length - 1) {
					tmp[propName] = value;
				} else if (typeof tmp[propName] != 'object') {
					tmp[propName] = {};
				}
				tmp = tmp[propName];
			}
			return obj;
		};
		Object.entries(valuesMap).forEach(function ([fieldName, valueConfig]) {
			const fieldValue = edi.utils.getObjectProperty(values, fieldName);
			if (fieldValue) {
				if ('string' == typeof valueConfig) {
					setOrCreateObjectArrayValue(build, valueConfig, fieldValue);
				} else if ('string' == typeof valueConfig.path) {
					setOrCreateObjectArrayValue(build, valueConfig.path, fieldValue);
					if (valueConfig.defaultValues && Array.isArray(valueConfig.defaultValues)) {
						for (let i = 0; i < valueConfig.defaultValues.length; i++) {
							setOrCreateObjectArrayValue(
								build,
								valueConfig.defaultValues[i].path,
								valueConfig.defaultValues[i].value
							);
						}
					}
				}
			}
		});

		if (sellerFNSid || buyerFNSid) {
			edi.utils.setObjectProperty(build.sg2[0], 'sg3.2.rff.c506', {
				e1153: 'YC1',
				e1154: sellerFNSid
			});
			edi.utils.setObjectProperty(build.sg2[1], 'sg3.1.rff.c506', {
				e1153: 'YC1',
				e1154: buyerFNSid
			});
		}
		let storage = [];
		if (Array.isArray(deliveryPlaceValues)) {
			storage = deliveryPlaceValues.map(function (product) {
				return {
					tod: {
						e4055: '6'
					},
					loc: {
						e3227: '7',
						c517: {
							e3225: product.iln,
							e3224: product.name
						}
					}
				};
			});
		}
		if (Array.isArray(exceptionsPlaceValues)) {
			const exceptionsPlaces = exceptionsPlaceValues.map(function (product) {
				return {
					tod: {
						e4055: '6'
					},
					loc: {
						e3227: '12',
						c517: {
							e3225: product.iln,
							e3224: product.name
						}
					}
				};
			});
			storage = storage.concat(exceptionsPlaces);
		}
		edi.utils.setObjectProperty(build, 'sg9', storage);

		const buildKeys = Object.keys(build);
		const numberOfSegmentsArray = buildKeys.filter(function (key) {
			return key !== 'unh' && key !== 'unt';
		});
		const numberOfSegments = numberOfSegmentsArray.length + 2;
		edi.utils.setObjectProperty(build, 'unt.e0074', numberOfSegments);

		edi.utils.clearEmptyValues(build);
		if (isNumberAutoGenerated) {
			edi.utils.setObjectProperty(build, 'bgm.c106', {});
		}
		const headerData = {
			data: Ext.encode(build)
		};

		if (isNumberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(build, 'bgm.c106.e1004');
		}
		headerData.doctime = values.docName;
		headerData.versionId = '1';

		const onSuccessSave = function (responseData) {
			const documentId = receiverId || responseData.data.id;

			moduleData.isChanged = false;
			moduleData.tab.setLoading(false);
			edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit);
			if (documentId) {
				edi.events.documents.fireEvent('change', {
					id: documentId
				});
			} else {
				edi.events.documents.fireEvent('create');
			}
		};
		const onFailureSave = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM
		);
		edi.document.actions.processDocument(
			buyerOrg.id,
			null,
			doctype,
			parentId,
			isEdit ? receiverId : undefined,
			onSuccessSave,
			onFailureSave,
			headerData
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};

	function getTanderOrg() {
		return new Promise((resolve, reject) => {
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.SERVER.SETTING.GET, {
					configuration_code: 'TANDER_ILN'
				}),
				'GET',
				{},
				(result) => {
					let tanderGln = result?.data;
					let tanderObj = null;

					if (tanderGln) {
						edi.relations.getRelations().forEach((item) => {
							if (item.iln === tanderGln) {
								tanderObj = item;
							}
						});
					}

					resolve(tanderObj);
				},
				reject
			);
		});
	}

	function loadDocumentContent(documentId, success, failure) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
				documentId
			}),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					documentData = data.data;
					const documentFormValues = {};

					// Данные для проверки (раскомментировать для воспроизведения) - позже удалить
					// var tmp = edi.utils.getObjectProperty(documentData, 'sg2.0.sg3.0');
					// edi.utils.setObjectProperty(documentData, 'sg2.0.sg3.0', edi.utils.getObjectProperty(documentData, 'sg2.0.sg3.2'));
					// edi.utils.setObjectProperty(documentData, 'sg2.0.sg3.2', tmp);

					/**
					 * Временное решение проблемы Когда ФНСID и INN поменяны местами, выстраиваем порядок на UI
					 */
					const checkOrder = function (parties, path) {
						if (parties) {
							let p1 = edi.utils.getObjectProperty(parties, 'sg3.0.rff.c506.e1153');
							let p2 = edi.utils.getObjectProperty(parties, 'sg3.2.rff.c506.e1153');
							if (p1 === 'YC1' && p2 === 'FC') {
								let tmp = edi.utils.getObjectProperty(parties, 'sg3.0');
								edi.utils.setObjectProperty(
									parties,
									'sg3.0',
									edi.utils.getObjectProperty(parties, 'sg3.2')
								);
								edi.utils.setObjectProperty(parties, 'sg3.2', tmp);
								edi.utils.setObjectPropertyEx(documentData, path, parties);
							}
							let t1 = edi.utils.getObjectProperty(parties, 'sg3.0.rff.c506.e1153');
							let t2 = edi.utils.getObjectProperty(parties, 'sg3.1.rff.c506.e1153');
							if (t1 === 'YC1' && t2 === 'FC') {
								let tmp = edi.utils.getObjectProperty(parties, 'sg3.0');
								edi.utils.setObjectProperty(
									parties,
									'sg3.0',
									edi.utils.getObjectProperty(parties, 'sg3.1')
								);
								edi.utils.setObjectProperty(parties, 'sg3.1', tmp);
								edi.utils.setObjectPropertyEx(documentData, path, parties);
							}
						}
					};
					let seller_parties = edi.utils.getObjectPropertyEx(documentData, 'sg2[nad.e3035==SU]');
					let buyer_parties = edi.utils.getObjectPropertyEx(documentData, 'sg2[nad.e3035==BY]');
					checkOrder(seller_parties, 'sg2[nad.e3035==SU]');
					checkOrder(buyer_parties, 'sg2[nad.e3035==BY]');

					Object.entries(valuesMap).forEach(function ([fieldName, valueConfig]) {
						const documentValuePath = 'string' == typeof valueConfig ? valueConfig : valueConfig.path;
						const fieldValue = edi.utils.getObjectProperty(documentData, documentValuePath);
						if (fieldValue) {
							edi.utils.setObjectProperty(documentFormValues, fieldName, fieldValue);
						}
					});

					guid = edi.utils.getObjectProperty(documentData, 'unh.e0062');
					sellerFNSid = edi.utils.getObjectProperty(documentData, 'sg2.0.sg3.2.rff.c506.e1154');
					buyerFNSid = edi.utils.getObjectProperty(documentData, 'sg2.1.sg3.1.rff.c506.e1154');
					typePriceNoticeField = edi.utils.getObjectProperty(documentData, 'sg17.ftx.c108.e4440');
					edi.utils.setObjectProperty(documentFormValues, 'typePriceNotice', typePriceNoticeField);

					for (let key in documentData) {
						if (documentData.hasOwnProperty(key) && key === 'sg9' && Array.isArray(documentData[key])) {
							documentData[key].forEach(function (item) {
								const itemType = edi.utils.getObjectProperty(item, 'loc.e3227');
								if (itemType == '7') {
									if (!documentFormValues.hasOwnProperty('storageInformation')) {
										documentFormValues.storageInformation = [];
									}
									documentFormValues.storageInformation.push({
										iln: item.loc.c517.e3225,
										name: item.loc.c517.e3224
									});
								} else if (itemType == '12') {
									if (!documentFormValues.hasOwnProperty('exceptionsFromStorage')) {
										documentFormValues.exceptionsFromStorage = [];
									}
									documentFormValues.exceptionsFromStorage.push({
										iln: item.loc.c517.e3225,
										name: item.loc.c517.e3224
									});
								}
							});
						}
					}
					documentFormValues.sg17 = documentData.sg17.sg36.map(function (product) {
						const sg40 = edi.utils.getObjectProperty(product, 'sg40');
						const productValues = {
							LineNumber: edi.utils.getObjectProperty(product, 'lin.e1082'),
							EAN: edi.utils.getObjectProperty(product, 'lin.c212.e7140'),
							ItemCode: edi.utils.getObjectProperty(product, 'pia.0.c212.0.e7140'),
							Heading: edi.utils.getObjectProperty(product, 'imd.c273.e7008')
						};
						sg40.forEach(function (item) {
							const c509 = edi.utils.getObjectProperty(item, 'pri.c509');
							if (c509.e5125 === 'ABL') {
								productValues.CurrentPrice = c509.e5118;
							} else if (c509.e5125 === 'AAE') {
								productValues.NewPrice = c509.e5118;
							}
						});
						return productValues;
					});
					documentData = documentFormValues;
					success();
				} else {
					failure(data);
				}
			},
			failure
		);
	}
};
