import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createField, createFieldSet, createLabel } from '@Components/fields';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createContainer, createTwoColumnsLayout, getTwoColumnsFilterOptions } from '@Components/miscComponents';
import { createActionsButton } from '@Components/buttons';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { MODAL_SIZE } from '@UIkit/components/modal';

/**
 * @author Vsevolod Liapin
 * @editor Daniil Tinyakov
 * Class for new upd_5.02 details
 */
Ext.namespace('edi.modules');
edi.modules['document.details.fns_upd_5_02'] = function () {
	var moduleData,
		part1,
		part2,
		part1Head,
		part2Head,
		bpName,
		utochReasonText = null,
		annulDoc,
		annulStatus,
		positionsStore = edi.stores.initPositionsStore(),
		areaStore = edi.stores.initAuthorityAreasStore(),
		signerStatusesStore = edi.stores.initSignerStatusesStore(),
		signerBuyerStatusesStore = edi.stores.initSignerBuyerStatusesStore(),
		updRoamingStatus6,
		infoColumnsConfig,
		rejectReasonText;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	var getPositionShortName = function (value) {
		var target = positionsStore.findRecord('id', value, 0, false, false, true);
		return target ? target.get('shortName') : value;
	};

	var createCS = function (selectorConfig) {
		Object.assign(selectorConfig, {
			is_valid: true,
			readOnly: true,
			margin: '5 0 10',
			valuesByMap: true,
			fieldValues: part1
		});

		return createCompanySelector(selectorConfig);
	};

	var createFieldSetFn = function (title, items, collapsible) {
		return createFieldSet({
			title: edi.i18n.getMessage(title),
			collapsible: !!collapsible,
			items: items
		});
	};

	var createTextInfosBlock = function () {
		var textInfos = edi.utils.getObjectProperty(part1, 'document.invoice.info.textInfos', true);

		if (!textInfos.length) {
			return null;
		}

		//Creates panel
		var createTextInfoPanel = function (identifier, value) {
			return createPanel({
				layout: 'column',
				margin: '0 0 5',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: identifier,
						input: createLabel({
							columnWidth: 0.7,
							text: value,
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 1
						}
					})
				]
			});
		};

		var panels = createTwoColumnsLayout(
			textInfos.map(function (info) {
				return createTextInfoPanel(info.identifier, info.value);
			}),
			[],
			0.5
		);

		return createFieldSetFn('documents.fns_upd.special_identifiers', panels, true);
	};

	/**
	 * Creates details panel of upd
	 * @returns {Object}
	 */
	var createDetailsPanel = function () {
		var mainData = Ext.clone(moduleData.initData.data);
		let attrs = moduleData.initData.data.attributes;
		var firstDueDate = edi.utils.getAttributeByName(attrs, 'firstDueDate'),
			fieldFirstDueDate = null,
			lastDueDate = edi.utils.getAttributeByName(attrs, 'lastDueDate'),
			fieldLastDueDate = null;
		mainData.modifyDate = part2Head ? part2Head.modifyDate : part1Head.modifyDate;
		mainData.countSignatures =
			(part2Head ? part2Head.countSignatures : 0) + (part1Head ? part1Head.countSignatures : 0);
		mainData.ids = [part1Head.id];
		if (part2Head) {
			mainData.ids.push(part2Head.id);
		}
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			objectData: mainData,
			utochReasonText: utochReasonText,
			rejectReasonText: rejectReasonText,
			isReceipts: !(moduleData.initData && moduleData.initData.isDocumentSharing),
			usingReport: true,
			isCrptStatus: true,
			showCRPTPrintBtn: edi.methods.checkAllowToShowCrptPrintBtn(attrs),
			defaultFormat: edi.constants.REPORT_FORMATS.XLS,
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing),
			costDecimal: edi.constants.UPD_PRICE_PRECISION
		});
		var headData = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.utils.getObjectProperty(part1, 'document.function'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(part1, 'document.invoice.invoiceNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createLabel({
					margin: '0 0 0 5',
					columnWidth: 0.25,
					text: edi.utils.getObjectProperty(part1, 'document.invoice.invoiceDate'),
					valueLabel: true
				})
			]
		});

		var correctUPdField = edi.utils.getObjectProperty(part1, 'document.invoice.corrections.Number')
			? createPanel({
					layout: 'column',
					margin: '0 0 5',
					cls: 'edi-create-field-line',
					items: [
						createField({
							title: edi.i18n.getMessage('documents.fns_upd.invoice.corrections'),
							input: createLabel({
								columnWidth: 0.7333,
								text: edi.utils.getObjectProperty(part1, 'document.invoice.corrections.Number'),
								valueLabel: true
							}),
							containerConfig: {
								columnWidth: 0.75
							}
						}),
						createLabel({
							margin: '0 0 0 5',
							columnWidth: 0.25,
							text: edi.utils.getObjectProperty(part1, 'document.invoice.corrections.Date'),
							valueLabel: true
						})
					]
			  })
			: null;

		var currency = edi.utils.getObjectProperty(part1, 'document.invoice.okv');
		if (currency) {
			var curObj = edi.utils.getOkv({
				code: currency
			});
			currency = curObj.charCode + ' - ' + curObj.codeStr + ' - ' + curObj.name;
		}
		var invoiceCurrency = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.i18n.getMessage('invoice.currency'),
					input: createLabel({
						columnWidth: 0.6,
						text: currency,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				})
			]
		});

		if (firstDueDate) {
			fieldFirstDueDate = createPanel({
				layout: 'column',
				margin: '0 0 5',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: edi.i18n.getMessage('upd.detail.field.firstDueDate'),
						input: createLabel({
							columnWidth: 0.6,
							text: edi.utils.formatDate(
								firstDueDate,
								edi.constants.DATE_FORMAT.FNS,
								edi.constants.DATE_FORMAT.SERVER
							),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 0.5
						}
					})
				]
			});
		}

		if (lastDueDate) {
			fieldLastDueDate = createPanel({
				layout: 'column',
				margin: '0 0 5',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: edi.i18n.getMessage('upd.detail.field.lastDueDate'),
						input: createLabel({
							columnWidth: 0.6,
							text: edi.utils.formatDate(
								lastDueDate,
								edi.constants.DATE_FORMAT.FNS,
								edi.constants.DATE_FORMAT.SERVER
							),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 0.5
						}
					})
				]
			});
		}

		var seller = createCS({
			title: 'document.seller',
			fieldsMap: edi.selectors.getUPDFieldsMap('document.invoice.sellerInfo', true)
		});

		var buyer = createCS({
			title: 'document.buyer',
			fieldsMap: edi.selectors.getUPDFieldsMap('document.invoice.buyerInfo', true)
		});

		var shipFieldsMap = edi.selectors.getUPDFieldsMap('document.invoice.cargoFrom.cargoSender', true, '5.02');
		var alsoKnownAs = edi.utils.getObjectProperty(part1, 'document.invoice.cargoFrom.alsoKnownAs');
		if (alsoKnownAs === 'он же') {
			shipFieldsMap = edi.selectors.getUPDFieldsMap('document.invoice.sellerInfo', true);
		}
		var ship = createCS({
			title: 'document.ship.from',
			fieldsMap: shipFieldsMap
		});

		var consignee = createCS({
			title: 'document.delivery',
			fieldsMap: edi.selectors.getUPDFieldsMap('document.invoice.cargoReceiver', true, '5.02')
		});

		var receiveTitleSection = null,
			acceptTitleSection = null,
			receiveDate = null,
			acceptBaseAuthority = null,
			proxyOrg = null;
		if (part2) {
			var signer = edi.utils.getObjectProperty(part2, 'buyerInfo.signer')[0];
			var legalEntityData = edi.utils.getObjectProperty(signer, 'legalEntity');
			var individualPersonData =
				edi.utils.getObjectProperty(signer, 'individualPerson') ||
				edi.utils.getObjectProperty(signer, 'individual');

			receiveTitleSection = createPanel({
				layout: 'column',
				margin: '10 0 5',
				bodyPadding: '5 0 0',
				title: edi.i18n.getMessage('document.fns_upd.secondary.data'),
				cls: 'edi-create-field-line section-part2',
				items: [
					!individualPersonData
						? createField({
								title: edi.i18n.getMessage('documents.create.torg.title.receive.description'),
								input: createLabel({
									columnWidth: 0.5,
									cls: 'field-signer-position',
									text: getPositionShortName(
										edi.utils.getObjectProperty(legalEntityData || individualPersonData, 'position')
									),
									valueLabel: true
								}),
								containerConfig: {
									columnWidth: 0.25
								}
						  })
						: createLabel({
								columnWidth: 0.125,
								text: edi.i18n.getMessage('documents.create.torg.title.receive.description')
						  }),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15 + !!individualPersonData * 0.125,
						cls: 'field-signer-lastName',
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'fullName.lastName'),
						valueLabel: true
					}),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15,
						cls: 'field-signer-firstName',
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.firstName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.firstName') ||
							edi.utils.getObjectProperty(individualPersonData, 'fullName.firstName'),
						valueLabel: true
					}),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15,
						cls: 'field-signer-middleName',
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.middleName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.middleName') ||
							edi.utils.getObjectProperty(individualPersonData, 'fullName.middleName'),
						valueLabel: true
					})
				]
			});
			receiveDate = createPanel({
				layout: 'column',
				margin: '10 0 5',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: edi.i18n.getMessage('upd.receive.date'),
						input: createLabel({
							columnWidth: 0.5,
							text: edi.utils.getObjectProperty(
								part2,
								'buyerInfo.contentFactsEconomicLife.infoGoodsReception.dateReceiptGoods'
							),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 0.25
						}
					})
				]
			});

			var authArea = areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(signer, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = signerBuyerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(signer, 'state'),
					0,
					false,
					false,
					true
				);

			var authorityArea = createPanel({
				layout: 'column',
				margin: '10 0 5',
				cls: 'edi-create-field-line section-part2',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
						input: createLabel({
							columnWidth: 0.687,
							valueLabel: true,
							cls: 'field-signer-authorityArea',
							text: authArea ? authArea.get('name') : edi.utils.getObjectProperty(signer, 'authorityArea')
						}),
						containerConfig: {
							columnWidth: 0.4
						}
					}),
					createLabel({
						margin: '0 0 0 5',
						cls: 'field-signer-state',
						text: signerState ? signerState.get('name') : edi.utils.getObjectProperty(signer, 'state'),
						columnWidth: 0.3,
						valueLabel: true
					})
				]
			});

			var baseAuthory = createPanel({
				layout: 'column',
				margin: '10 0 5',
				cls: 'edi-create-field-line section-part2',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
						input: createLabel({
							columnWidth: 0.687,
							valueLabel: true,
							cls: 'field-signer-baseAuthory',
							text: edi.utils.getObjectProperty(signer, 'baseAuthory')
						}),
						containerConfig: {
							columnWidth: 0.4
						}
					}),
					createLabel({
						columnWidth: 0.3,
						valueLabel: true,
						margin: '0 0 0 5',
						cls: 'field-signer-baseOrgAuthory',
						text: edi.utils.getObjectProperty(signer, 'baseOrgAuthory')
					})
				]
			});

			var certificateRegistrationIP = individualPersonData
				? createPanel({
						layout: 'column',
						margin: '10 0 5',
						cls: 'edi-create-field-line',
						items: [
							createField({
								title: edi.i18n.getMessage('individual.certificateRegistration'),
								input: createLabel({
									columnWidth: 0.687,
									valueLabel: true,
									text: edi.utils.getObjectProperty(individualPersonData, 'certificateRegistrationIP')
								}),
								containerConfig: {
									columnWidth: 0.4
								}
							})
						]
				  })
				: null;

			acceptTitleSection = createPanel({
				layout: 'column',
				margin: '10 0 5',
				cls: 'edi-create-field-line section-part2-goodsReception',
				items: [
					createField({
						title: edi.i18n.getMessage('upd.accept.tab.title'),
						input: createLabel({
							columnWidth: 0.5,
							cls: 'field-position',
							text: edi.utils.getObjectProperty(
								part2,
								'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.position'
							),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 0.25
						}
					}),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15,
						cls: 'field-lastName',
						text: edi.utils.getObjectProperty(
							part2,
							'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.lastName'
						),
						valueLabel: true
					}),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15,
						cls: 'field-firstName',
						text: edi.utils.getObjectProperty(
							part2,
							'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.firstName'
						),
						valueLabel: true
					}),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15,
						cls: 'field-middleName',
						text: edi.utils.getObjectProperty(
							part2,
							'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.middleName'
						),
						valueLabel: true
					})
				]
			});

			acceptBaseAuthority = createPanel({
				layout: 'column',
				margin: '10 0 5',
				cls: 'edi-create-field-line section-part2-goodsReception',
				items: [
					createField({
						title: 'documents.fns_upd.signer.baseAuthory',
						input: createLabel({
							columnWidth: 0.687,
							cls: 'field-authority',
							text: edi.utils.getObjectProperty(
								part2,
								'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.authority'
							),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 0.4
						}
					})
				]
			});
		}

		var productsValues = Ext.clone(part1.document.invoiceTable.productInfo);
		for (var i = 0; i < productsValues.length; i++) {
			if ('object' == typeof productsValues[i].excise) {
				productsValues[i].excise.sumExcise =
					productsValues[i].excise.sumExcise !== null
						? productsValues[i].excise.sumExcise
						: productsValues[i].excise.withoutExcise
						? productsValues[i].excise.withoutExcise
						: 'без акциза';
			}
		}
		if (infoColumnsConfig) {
			var newConfig = edi.methods.createInfoColumns(infoColumnsConfig);

			var valuesColumnConfigurator = function (config) {
				Ext.merge(config, newConfig);
				return config;
			};
			var columnsRegen = edi.columns.get('fns_upd_lines', function (config) {
				return valuesColumnConfigurator(config);
			});
		}
		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			gridModel: 'FNS_UPD_LINE',
			totalModel: 'FNS_UPD_SUMMARY',
			gridColumnConfig: infoColumnsConfig ? columnsRegen : 'fns_upd_lines',
			totalColumnConfig: 'fns_upd_summary',
			readOnly: true,
			data: productsValues,
			margin: '16 0 0',
			docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
			docData: part1.document,
			changeValuesBeforeEdit: function (values) {
				if (values.tax) {
					values.tax = values.tax.replace(/[%]*/g, '');
				}
			},
			modalConf: {
				width: MODAL_SIZE.widthLarge,
				height: edi.constants.DEFAULT.MODAL.HEIGHT_LARGER,
				minHeight: edi.constants.DEFAULT.MODAL.HEIGHT_LARGE
			},
			modalFormConfig: {
				title: 'document.upd.line.details',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.name'),
								name: 'productName'
							},
							{
								title: edi.i18n.getMessage('line.item.invoice.quantity'),
								allowDecimals: true,
								name: 'count',
								type: 'number'
							},
							{
								store: edi.stores.initOkeiStore(),
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								name: 'productOkei',
								type: 'combo',
								valueField: 'id',
								displayField: 'name_code'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('line.fns.invoice.unit.net.price'),
								allowDecimals: true,
								name: 'cost',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.fns.tax.rate'),
								name: 'tax',
								type: 'combo',
								store: edi.stores.initFNSnTaxRateStore()
							},
							{
								title: edi.i18n.getMessage('line.upd.excise'),
								allowDecimals: true,
								name: 'excise.sumExcise',
								showZero: true
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								allowDecimals: true,
								name: 'costWithoutNDS',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								allowDecimals: true,
								name: 'taxSum.taxSum',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								allowDecimals: true,
								name: 'info',
								type: 'number'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.additional.data'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.additional.productCode'),
								name: 'additionalProductInfo.productCode',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.additional.numberShouldRelease'),
								allowDecimals: true,
								name: 'additionalProductInfo.numberShouldRelease',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.additional.corAccDebet'),
								type: 'number',
								name: 'additionalProductInfo.corAccDebet'
							},
							{
								title: edi.i18n.getMessage('line.item.additional.corAccCredit'),
								type: 'number',
								name: 'additionalProductInfo.corAccCredit'
							},
							{
								store: edi.stores.createSimpleInlineStore(['1', '2', '3', '4', '5'], function (id) {
									return edi.i18n.getMessage('documents.fns_upd.product.type.' + id);
								}),
								displayField: 'name',
								valueField: 'id',
								type: 'combo',
								title: edi.i18n.getMessage('line.item.additional.feature'),
								name: 'additionalProductInfo.feature'
							},
							{
								title: edi.i18n.getMessage('line.item.additional.featureAdditionalInfo'),
								type: 'number',
								name: 'additionalProductInfo.featureAdditionalInfo'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.sv.td'),
						layout: 'fit',
						bodyPadding: 5,
						items: [
							{
								type: 'editableGrid',
								name: 'customsDeclarationInfo',
								config: {
									columnsConfig: 'upd_declaration_info',
									fields: [
										{
											title: 'column.kod.proiskh',
											name: 'countryOrigin',
											type: 'combo',
											store: edi.stores.initValidCountryFullStore(),
											valueField: 'iso_number_3',
											displayField: 'display_name'
										},
										{
											title: 'column.nomer.td',
											name: 'number',
											type: 'text'
										}
									],
									limit: undefined,
									model: 'UPD_DECLARATION_INFORMATION',
									modalWidth: MODAL_SIZE.widthLarge,
									gridConfig: {
										cls: 'modal-grid',
										title: edi.i18n.getMessage('line.item.tab.sv.td')
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
						layout: 'fit',
						bodyPadding: 5,
						items: [
							{
								type: 'editableGrid',
								name: 'textInfos',
								config: {
									fields: [
										{
											name: 'identifier',
											type: 'text'
										},
										{
											name: 'value',
											type: 'text'
										}
									],
									autoScroll: true,
									limit: 20,
									model: 'UPD_PRODUCT_TEXT_INFO',
									gridConfig: {
										cls: 'modal-grid',
										title: edi.i18n.getMessage('line.item.tab.inf.pol.str')
									}
								}
							}
						]
					}
				]
			}
		});

		var fields = [
			headData,
			correctUPdField,
			invoiceCurrency,
			fieldFirstDueDate,
			fieldLastDueDate,
			seller,
			buyer,
			ship,
			consignee
		];
		fields = fields.concat(createPaymentInformationField());
		fields.push(
			createBaseShipmentOfGoodsFieldSet(),
			createPersonHandingGoods(),
			createTransportAndCargo(),
			createTextInfosBlock()
		);
		var container = createContainer({
			layout: 'anchor',
			defaults: {
				anchor: '100%'
			},
			items: fields
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [
				headPanel,
				container,
				productsGrid,
				createSignerItems(),
				receiveTitleSection,
				receiveDate,
				authorityArea,
				baseAuthory,
				certificateRegistrationIP,
				acceptTitleSection,
				acceptBaseAuthority,
				proxyOrg
			]
		});
	};

	var createPaymentInformationField = function () {
		var items = [];
		var fieldData = edi.utils.getObjectProperty(part1, 'document.invoice.paymentInformation');
		for (var i = 0; i < fieldData.length; i++) {
			items.push(
				createPanel({
					layout: 'column',
					margin: '0 0 5',
					cls: 'edi-create-field-line',
					items: [
						createField({
							title: edi.i18n.getMessage('documents.fns_upd.paymentInformation'),
							input: createLabel({
								columnWidth: 0.6,
								text: edi.utils.getObjectProperty(fieldData[i], 'number'),
								valueLabel: true
							}),
							containerConfig: {
								columnWidth: 0.5
							}
						}),
						createLabel({
							margin: '0 0 0 5',
							columnWidth: 0.25,
							text: edi.utils.getObjectProperty(fieldData[i], 'date'),
							valueLabel: true
						})
					]
				})
			);
		}
		return items;
	};

	var createBaseShipmentOfGoodsFieldSet = function () {
		// Create base documents panels
		var createBaseShipmentOfGoodsFields = function (fields) {
			var items = [];

			for (var i = 0; i < fields.length; i++) {
				items.push(
					createPanel({
						layout: 'column',
						margin: '0 0 5',
						padding: 0,
						cls: 'edi-create-field-line',
						items: [
							createLabel({
								columnWidth: 0.3,
								text: edi.utils.getObjectProperty(fields[i], 'name'),
								valueLabel: true
							}),
							createLabel({
								margin: '0 0 0 5',
								columnWidth: 0.2,
								text: edi.utils.getObjectProperty(fields[i], 'number'),
								valueLabel: true
							}),
							createLabel({
								margin: '0 0 0 5',
								columnWidth: 0.2,
								text: edi.utils.getObjectProperty(fields[i], 'date'),
								valueLabel: true
							}),
							createLabel({
								margin: '0 0 0 5',
								columnWidth: 0.3,
								text: edi.utils.getObjectProperty(fields[i], 'additionalInfo'),
								valueLabel: true
							})
						]
					})
				);
			}
			return items;
		};

		var fieldData = edi.utils.getObjectProperty(
				part1,
				'document.receiptPartDoc.transferInformation.baseShipmentOfGoods',
				true
			),
			fields = createTwoColumnsLayout(createBaseShipmentOfGoodsFields(fieldData), [], 1);

		return fieldData.length ? createFieldSetFn('documents.fns_upd.transferInfo.baseShipmentOfGoods', fields) : null;
	};

	var createPersonHandingGoods = function () {
		var personHandingGoodsValues = edi.utils.getObjectProperty(
			part1,
			'document.receiptPartDoc.transferInformation.personHandingGoods'
		);
		var fieldData = {},
			handingGoodsType = 'sellerOrganizationWorker';
		if (personHandingGoodsValues) {
			if (edi.utils.getObjectProperty(personHandingGoodsValues, 'sellerOrganizationWorker')) {
				fieldData = edi.utils.getObjectProperty(personHandingGoodsValues, 'sellerOrganizationWorker');
				handingGoodsType = 'sellerOrganizationWorker';
			} else if (edi.utils.getObjectProperty(personHandingGoodsValues, 'anotherPerson.shippedGoodsWorker')) {
				fieldData = edi.utils.getObjectProperty(personHandingGoodsValues, 'anotherPerson.shippedGoodsWorker');
				handingGoodsType = 'shippedGoodsWorker';
			} else {
				fieldData = edi.utils.getObjectProperty(
					personHandingGoodsValues,
					'anotherPerson.individualPersonShipsGoogs'
				);
				handingGoodsType = 'individualPersonShipsGoogs';
			}
		}
		var personHandingGoods = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			items: [
				handingGoodsType !== 'individualPersonShipsGoogs'
					? createField({
							title: edi.i18n.getMessage('documents.fns_upd.' + handingGoodsType),
							input: createLabel({
								columnWidth: 0.5,
								text: getPositionShortName(edi.utils.getObjectProperty(fieldData, 'position')),
								valueLabel: true
							}),
							containerConfig: {
								columnWidth: 0.4
							}
					  })
					: createLabel({
							columnWidth: 0.4,
							text: edi.i18n.getMessage('documents.fns_upd.' + handingGoodsType),
							valueLabel: true
					  }),
				createLabel({
					margin: '0 0 0 5',
					columnWidth: 0.2,
					text: edi.utils.getObjectProperty(fieldData, 'fullName.firstName'),
					valueLabel: true
				}),
				createLabel({
					margin: '0 0 0 5',
					columnWidth: 0.2,
					text: edi.utils.getObjectProperty(fieldData, 'fullName.lastName'),
					valueLabel: true
				}),
				createLabel({
					margin: '0 0 0 5',
					columnWidth: 0.2,
					text: edi.utils.getObjectProperty(fieldData, 'fullName.middleName'),
					valueLabel: true
				})
			]
		});

		var baseAuthority = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			hidden: handingGoodsType === 'individualPersonShipsGoogs',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.sellerOrganizationWorker.baseAuthority'),
					input: createLabel({
						columnWidth: 0.8,
						text:
							handingGoodsType === 'shippedGoodsWorker'
								? edi.utils.getObjectProperty(fieldData, 'rightPresentResults')
								: edi.utils.getObjectProperty(fieldData, 'baseAuthority'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var additInfo = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.sellerOrganizationWorker.additInfo'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(
							fieldData,
							handingGoodsType === 'sellerOrganizationWorker' ? 'additInfo' : 'additionalInfo'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var authority = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			hidden: handingGoodsType === 'sellerOrganizationWorker',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.individualPersonShipsGoogs.authority'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(fieldData, 'authority'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var orgName = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			hidden: handingGoodsType !== 'shippedGoodsWorker',
			items: [
				createField({
					title: edi.i18n.getMessage('document.company.name'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(fieldData, 'orgName'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		return createFieldSetFn('documents.fns_upd.personHandingGoods', [
			personHandingGoods,
			additInfo,
			orgName,
			authority,
			baseAuthority
		]);
	};

	var createWayBillField = function () {
		var items = [];
		var fieldData = edi.utils.getObjectProperty(
			part1,
			'document.receiptPartDoc.transferInformation.transportAndCargo.wayBill'
		);
		for (var i = 0; i < fieldData.length; i++) {
			items.push(
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					margin: '0 0 5',
					items: [
						createField({
							title: edi.i18n.getMessage('documents.create.torg.trans.name'),
							input: createLabel({
								columnWidth: 0.547,
								text: edi.utils.getObjectProperty(fieldData[i], 'number'),
								valueLabel: true
							}),
							containerConfig: {
								columnWidth: 0.45
							}
						}),
						createLabel({
							columnWidth: 0.25,
							margin: '0 0 0 5',
							text: edi.utils.getObjectProperty(fieldData[i], 'date'),
							valueLabel: true
						})
					]
				})
			);
		}

		return items;
	};

	var createTransportAndCargo = function () {
		var carrier = createCS({
			title: 'document.carrier',
			fieldsMap: edi.selectors.getUPDFieldsMap(
				'document.receiptPartDoc.transferInformation.transportAndCargo.carrier',
				true
			)
		});

		var transportAndCargoColumns = createTwoColumnsLayout(
			createWayBillField(),
			[],
			1,
			getTwoColumnsFilterOptions()
		);

		var info = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.transportAndCargo.info'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(
							part1,
							'document.receiptPartDoc.transferInformation.transportAndCargo.info'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var thingTransferInfoInfo = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.transferInfo.info'),
					input: createLabel({
						columnWidth: 0.751,
						text: edi.utils.getObjectProperty(
							part1,
							'document.receiptPartDoc.transferInformation.thingTransferInfo.info'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.8
					}
				}),
				createLabel({
					columnWidth: 0.2,
					margin: '0 0 0 5',
					text: edi.utils.getObjectProperty(
						part1,
						'document.receiptPartDoc.transferInformation.thingTransferInfo.date'
					),
					valueLabel: true
				})
			]
		});

		var contentAction = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.transferInfo.contentAction'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(
							part1,
							'document.receiptPartDoc.transferInformation.contentAction'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var typeAction = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.transferInfo.typeAction'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(
							part1,
							'document.receiptPartDoc.transferInformation.typeAction'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var shippingDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_upd.transferInfo.shippingDate'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(
							part1,
							'document.receiptPartDoc.transferInformation.shippingDate'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.4
					}
				})
			]
		});

		return createFieldSetFn('documents.fns_upd.transferInformation', [
			carrier,
			transportAndCargoColumns,
			info,
			thingTransferInfoInfo,
			contentAction,
			typeAction,
			shippingDate
		]);
	};

	var createSignerItems = function () {
		var fieldData = edi.utils.getObjectProperty(part1, 'document.signer');
		if (!Array.isArray(fieldData)) {
			fieldData = [fieldData];
		}

		var createFields = function (fieldData) {
			var legalEntityData = edi.utils.getObjectProperty(fieldData, 'legalEntity');
			var individualPersonData =
				edi.utils.getObjectProperty(fieldData, 'individualPerson') ||
				edi.utils.getObjectProperty(fieldData, 'individual');
			var signerSection = createPanel({
				layout: 'column',
				margin: '10 10 5',
				cls: 'edi-create-field-line',
				items: [
					legalEntityData
						? createLabel({
								columnWidth: 0.3,
								margin: '0 0 0 5',
								cls: 'field-signer-position',
								text: getPositionShortName(edi.utils.getObjectProperty(legalEntityData, 'position')),
								valueLabel: true
						  })
						: null,
					createLabel({
						columnWidth: 0.2 + !!individualPersonData * 0.1,
						margin: '0 0 0 5',
						cls: 'field-signer-firstName',
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.firstName' : 'name.firstName'
						),
						valueLabel: true
					}),
					createLabel({
						columnWidth: 0.3 + !!individualPersonData * 0.1,
						margin: '0 0 0 5',
						cls: 'field-signer-lastName',
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.lastName' : 'name.lastName'
						),
						valueLabel: true
					}),
					createLabel({
						columnWidth: 0.2 + !!individualPersonData * 0.1,
						margin: '0 0 0 5',
						cls: 'field-signer-middleName',
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.middleName' : 'name.middleName'
						),
						valueLabel: true
					})
				]
			});

			var authArea = areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = signerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'state'),
					0,
					false,
					false,
					true
				);

			var authorityAreaAndStatus = createPanel({
				layout: 'column',
				margin: 10,
				cls: 'edi-create-field-line',
				items: [
					createLabel({
						columnWidth: 0.6,
						cls: 'field-signer-authorityArea',
						text: authArea ? authArea.get('name') : edi.utils.getObjectProperty(fieldData, 'authorityArea'),
						valueLabel: true
					}),
					createLabel({
						columnWidth: 0.4,
						margin: '0 0 0 5',
						cls: 'field-signer-state',
						text: signerState ? signerState.get('name') : edi.utils.getObjectProperty(fieldData, 'state'),
						valueLabel: true
					})
				]
			});

			var baseAuthory = createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
						input: createLabel({
							columnWidth: 0.7,
							cls: 'field-signer-baseAuthory',
							text: edi.utils.getObjectProperty(fieldData, 'baseAuthory'),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 1
						}
					})
				]
			});

			var baseOrgAuthory = createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
						input: createLabel({
							columnWidth: 0.7,
							cls: 'field-signer-baseOrgAuthory',
							text: edi.utils.getObjectProperty(fieldData, 'baseOrgAuthory'),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 1
						}
					})
				]
			});
			return [signerSection, authorityAreaAndStatus, baseAuthory, baseOrgAuthory];
		};

		var signerFields = [];
		for (var i = 0; i < fieldData.length; i++) {
			signerFields = signerFields.concat(createFields(fieldData[i]));
		}

		return createTwoColumnsLayout(signerFields, [], 0.625);
	};

	/**
	 * Creates action pane
	 */
	var createModuleActionsPanel = function () {
		var data = moduleData.initData.data;

		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg),
			isOutGoingDocument = edi.constants.DIRECTIONS.OUTGOING === direction;
		var hasPart2 = !!edi.utils.getAttributeByName(data.attributes, 'hasPart2') || !!part2Head;
		var totalSignaturesCount = edi.document.actions.getSignCount(data);

		var neededSignaturesCount = isOutGoingDocument
			? totalSignaturesCount - part1Head.countSignatures
			: totalSignaturesCount - (hasPart2 && part2Head ? part2Head.countSignatures : 0);
		var actionsPanel = createActionsPanel(),
			editCmp = null;

		var readCmp = null;
		if (edi.constants.DIRECTIONS.INCOMING === direction) {
			readCmp = createActionsButton({
				text: edi.i18n.getMessage('document.mark.read'),
				glyph: edi.constants.ICONS.READ,
				handler: function () {
					edi.core.confirm(
						edi.i18n.getMessage('document.mark.read'),
						edi.i18n.getMessage('document.mark.read.question'),
						function () {
							moduleData.tab.setLoading();
							var success = function () {
								moduleData.tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: data.id
								});
							};
							var failure = edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.mark.read'
							);
							var markRead = function () {
								var postData = {};
								postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID] = edi.constants.STATE.SENT;
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: data.id
										},
										true
									),
									'PUT',
									Ext.encode(postData),
									success,
									failure
								);
							};
							if (updRoamingStatus6) {
								edi.utils.sign(
									updRoamingStatus6,
									moduleData.tab,
									function (failed) {
										if (failed) {
											failure();
										} else {
											success();
										}
									},
									undefined,
									undefined,
									true,
									null,
									{
										signRefuse: () => moduleData.tab?.setLoading(false)
									}
								);
							} else {
								markRead();
							}
						}
					);
				}
			});
		}

		var excludeActions = {};
		if (
			edi.constants.DIRECTIONS.INCOMING === direction &&
			bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF
		) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.READ] = true;
		}

		if (
			edi.constants.DIRECTIONS.INCOMING === direction &&
			bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT &&
			data?.state === 'SENT'
		) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.READ] = true;
		}

		if (!editCmp) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.EDIT] = true;
		}

		const customButtons = [];
		if (!annulStatus || annulStatus !== edi.constants.STATE.ACTIVE) {
			let actionCreateUKD_5_01n;
			if (
				edi.action.isAvailable(
					'CREATE_UKD',
					edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data))
				)
			) {
				actionCreateUKD_5_01n = edi.permissions.hasPermission('CREATE_EDI_FNS_UKD')
					? edi.document.actions.createConfigForDocumentCreateButton(
							'action.ukd_5_01n',
							edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
							'fns_upd',
							part1,
							data,
							moduleData,
							{ versionId: '5.01-N' }
					  )
					: null;
			}
			if (actionCreateUKD_5_01n) {
				customButtons.push(
					createActionsButton({
						showInFirstHalf: true,
						order: 500,
						text: edi.i18n.getMessage('action.create'),
						glyph: edi.constants.ICONS.ADD,
						allowMultiClick: true,
						menu: {
							plain: true,
							hideMode: 'display',
							items: [actionCreateUKD_5_01n]
						}
					})
				);
			}
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: neededSignaturesCount,
			hasPart2: hasPart2,
			excludeActions: excludeActions,
			actionProps: {
				SIGN: {
					methodAddOptions: {
						contentId:
							edi.constants.DIRECTIONS.OUTGOING === direction
								? part1Head.id
								: part2Head
								? part2Head.id
								: null,
						useBeforeAction:
							data.state === edi.constants.STATE.DRAFT ||
							data.state === edi.constants.STATE.READ ||
							data.state === edi.constants.STATE.READ
					}
				},
				EDIT: {
					component: editCmp
				},
				READ: {
					component: readCmp
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				ANNUL: {
					methodAddOptions: annulDoc
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		if (
			data &&
			data.id &&
			(data.id == moduleData.initData.data.id ||
				(part1Head && data.id == part1Head.id) ||
				(part2Head && data.id == part2Head.id))
		) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				moduleData.tab.removeAll();
				renderData();
			}
		}
	};
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		var documentHeaderData = moduleData.initData.data;
		var direction = edi.utils.getDocumentDirection(documentHeaderData.toOrg, documentHeaderData.fromOrg);

		var getOrganizationAdditionalFields = function (orgId) {
			var failure = function (responseData) {
				var errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
				edi.core.showWarn(errorMsg, updateInfoFields);
			};
			edi.rest.sendRequest(
				edi.document.actions.formatDocDataUri(moduleData.initData, {
					docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
					orgId: orgId
				}),
				'GET',
				null,
				function (data) {
					if (data && data.items) {
						infoColumnsConfig = data.items.length ? data.items : null;
						continueWithRendering();
					} else {
						failure();
					}
				},
				failure
			);
		};

		var continueWithRendering = function () {
			bpName = edi.utils.getAttributeByName(documentHeaderData.attributes, 'bpName');

			var finishLoad = function () {
				modulePanel.add(createDetailsPanel());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);

				if (
					annulDoc &&
					annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
					annulDoc.toOrg.id == edi.core.getUserOrgID()
				) {
					edi.methods.documents.showActiveAnnulModal(annulDoc, moduleData);
				}

				if ('function' == typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}
			};

			annulStatus = edi.utils.getAttributeByName(documentHeaderData.attributes, 'ANNUL_STATUS');
			annulDoc = null;
			utochReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'UTOCH_REASON');
			var isAnnualUtoch =
				utochReasonText ||
				(annulStatus &&
					(annulStatus === edi.constants.STATE.CREATED || annulStatus === edi.constants.STATE.DRAFT));
			var isIncomingRoaming =
				bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING &&
				edi.constants.DIRECTIONS.INCOMING === direction &&
				documentHeaderData.state === edi.constants.STATE.SENT;
			if (isAnnualUtoch || isIncomingRoaming) {
				edi.rest.sendRequest(
					edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
						depth: edi.constants.DEFAULT.TREE_DEPTH
					}),
					'GET',
					{},
					function (responseData) {
						var i, utochId;
						if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
							for (i = 0; i < responseData.items.length; i++) {
								if (
									isAnnualUtoch &&
									responseData.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL &&
									edi.constants.STATE.WAIT_PARTNER_DECISION === responseData.items[i].state
								) {
									annulDoc = responseData.items[i];
								} else if (
									isAnnualUtoch &&
									responseData.items[i].type ===
										edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH &&
									responseData.items[i].state !== edi.constants.STATE.DELETED
								) {
									utochId = responseData.items[i].id;
								} else if (
									isIncomingRoaming &&
									responseData.items[i].type ===
										edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_6_DP_IZVPOL &&
									responseData.items[i].state === edi.constants.STATE.RECEIVER_UPD_RECEIVED_ACK
								) {
									updRoamingStatus6 = responseData.items[i];
								}
							}
						}

						if (!utochId) {
							finishLoad();
						} else {
							edi.rest.sendRequest(
								edi.document.actions.formatDetailsUri(moduleData.initData, utochId, true),
								'GET',
								{},
								function (responseData) {
									if (responseData.success && responseData.data) {
										utochReasonText = edi.utils.getObjectProperty(
											responseData.data,
											'Document.DataOfUvUtoch.TextNoticeUtoch'
										);
									}
									finishLoad();
								},
								failure
							);
						}
					},
					failure
				);
			} else {
				finishLoad();
			}
		};

		edi.document.actions.updateDocumentHeaderData(moduleData, function () {
			documentHeaderData = moduleData.initData.data;
			edi.rest.sendRequest(
				edi.document.actions.formatLinkedUri(moduleData.initData),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						var i,
							ids = [];
						for (i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL) {
								rejectReasonText = edi.utils.getObjectProperty(
									data.data.children[i],
									'attributes.REJECT_REASON.value'
								);
							}
						}
						for (i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
								ids.push(data.data.children[i].id);
							} else if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2) {
								ids.push(data.data.children[i].id);
							}
							if (ids.length === 2) {
								break;
							}
						}
						if (ids.length) {
							edi.rest.sendRequest(
								edi.document.actions.formatBatchHeaderUri(moduleData.initData),
								'POST',
								Ext.encode(ids),
								function (data) {
									part2Head = null;
									for (var i = 0; i < data.items.length; i++) {
										if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1) {
											part1Head = data.items[i];
											edi.document.actions.changeTabTitle(moduleData.tab, part1Head.number);
										} else if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P2) {
											part2Head = data.items[i];
										}
									}
									edi.rest.sendRequest(
										edi.document.actions.formatBatchContentUri(moduleData.initData),
										'POST',
										Ext.encode(ids),
										function (data) {
											part2 = null;
											if (data && data.items) {
												for (var i = 0; i < data.items.length; i++) {
													if (part1Head && part1Head.id == data.items[i].header) {
														part1 = data.items[i];
													} else if (part2Head && part2Head.id == data.items[i].header) {
														part2 = data.items[i];
													}
												}
												getOrganizationAdditionalFields(documentHeaderData.toOrg.id);
											} else {
												failure(data);
											}
										},
										failure
									);
								},
								failure
							);
						} else {
							failure();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		});
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
