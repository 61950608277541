/**
 * Change showcase events
 * @type {Ext.util.Observable}
 */

import { moduleMethods } from '@Edi/modules/power_of_attorney/POA_002/methods';

edi.events.modules.on('rendered', function () {
	if (edi.permissions.hasPermission('CLIENT_TARIFFS')) {
		edi.methods.tariffs.checkIsCanCreateDoc();
		edi.methods.tariffs.checkIsCanCreatePoa();
	}
	moduleMethods.getPOAForSigningAndShowNotification();
});
edi.events.organization = new Ext.util.Observable();
// edi.events.organization.addEvents({
// 	"change": true
// });

edi.events.invitations = new Ext.util.Observable();
// edi.events.invitations.addEvents({
// 	"change": true,
// 	"activatetab": true
// });

edi.events.massInvitation = new Ext.util.Observable();
// edi.events.massInvitation.addEvents({
// 	"change": true
// });

edi.events.archive = new Ext.util.Observable();

/**
 * Partners events
 */
edi.events.partners = new Ext.util.Observable();

edi.events.massInvitation = new Ext.util.Observable();
// edi.events.massInvitation.addEvents({
// 	"change": true
// });

edi.events.subdivisions = new Ext.util.Observable();
// edi.events.subdivisions.addEvents({
// 	"change": true
// });

edi.events.employees = new Ext.util.Observable();
// edi.events.employees.addEvents({
// 	"change": true
// });

edi.events.routes = new Ext.util.Observable();
// edi.events.routes.addEvents({
// 	"change": true
// });

edi.events.updateExternalStatus = new Ext.util.Observable();
// edi.events.updateExternalStatus.addEvents({
// 	"change": true
// });

edi.events.onAddExternalBtnHandler = new Ext.util.Observable();
// edi.events.onAddExternalBtnHandler.addEvents({
// 	"change": true
// });

edi.events.approval_documents = new Ext.util.Observable();
// edi.events.approval_documents.addEvents({
// 	"change": true,
// 	"delete": true
// });

edi.events.archive = new Ext.util.Observable();

edi.events.actual_tariffs = new Ext.util.Observable();
edi.events.actual_poa_tariffs = new Ext.util.Observable();
edi.events.all_tariffs = new Ext.util.Observable();

edi.events.org_profile = new Ext.util.Observable();

edi.events.application_to_fns = new Ext.util.Observable();

edi.events.certificates = new Ext.util.Observable();

edi.events.credentials = new Ext.util.Observable();

edi.events.control_center = new Ext.util.Observable();
