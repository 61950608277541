import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createAddModulePanel, createFormForModule } from '@Components/panels';
import { createCreateSaveButton } from '@Components/buttons';
import { COMMONPRICAT_LINE_MODEL, COMMONPRICAT_PRICE_REGIONS_MODEL } from './models';
import { COMMONPRICAT_LINE_COLUMN, COMMONPRICAT_PRICE_REGIONS_COLUMN } from './columns';
import { createCommonPricat, createCommonPricatDocumentParties, createCommonPricatParties } from './templates';
import { getCommonPricatFieldsMap, getCommonPricatModalConf } from './selectors';
import {
	createButtonContainer,
	createContainer,
	createFieldBlock,
	createMaxWidthContainer
} from '@UIkit/components/panels';
import {
	createLabel,
	createDateRangeField,
	createCombo,
	createDateField,
	createTextField
} from '@UIkit/components/fields';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { coreMethods } from '@Core/commons';

/**
 * Class for new common pricat creation
 * @author Anastasiya Zholudeva
 */
Ext.namespace('edi.modules');
edi.modules['document.create.commonpricat'] = function () {
	let moduleData,
		isCopy = false,
		id,
		isEdit = false,
		isBasedOnDoc = false,
		buyerId,
		buyerOrg,
		buyerOrgValues,
		form,
		initialData,
		parentId,
		bpName,
		buyer,
		seller,
		sellerData,
		afterSave,
		productsGrid,
		userData = edi.core.getUserData(),
		costDecimals,
		taxRatesStore = edi.stores.initTaxRatesWithFractStore(),
		availableTaxRates = [],
		compricat,
		productValues = {
			products: []
		},
		priceRegionsValues = [],
		customFieldsContainer,
		topPath = '//Document-PriceCatalogue/',
		customFieldsObj,
		priceRegionsGrid;
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		costDecimals = coreMethods.getUserOrgCostDecimals();

		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		sellerData = edi.converters.convertOrgToLegacyPartie(userData.org);
		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			compricat = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			initialData = compricat;
			delete initialData.fileId;
			buyerOrg = moduleData.initData.data.buyerOrg;
			isBasedOnDoc = true;
		}
		if (buyerOrg) {
			buyerOrgValues = edi.converters.convertOrgToPartie(buyerOrg);
			buyerId = buyerOrg.id;
		}
		taxRatesStore.each(function (record) {
			availableTaxRates.push({
				id: record.get('id'),
				name: record.get('name')
			});
		});
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const createMainDataBlock = function (document, header) {
		const bpName = createCombo({
			fieldLabel: edi.i18n.getMessage('common.pricat.bp.name'),
			name: 'PriceCatalogue-Header.bpName',
			store: edi.stores.createSimpleInlineStore([edi.constants.DOCUMENT_TYPES.PRICAT_WITH_SIGN], function (id) {
				return edi.i18n.getMessage('documents.doctype.bp.' + id);
			}),
			value: bpName ? edi.utils.getObjectProperty(bpName, '') : edi.constants.DOCUMENT_TYPES.PRICAT_WITH_SIGN,
			allowBlank: false
		});

		const licenseNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('company.license.number'),
			emptyText: edi.i18n.getMessage('nr'),
			allowBlank: false,
			maxLength: 35,
			value:
				document || compricat
					? edi.utils.getObjectProperty(header, 'PriceCatalogueNumber') +
					  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
					: undefined,
			name: 'PriceCatalogue-Header.PriceCatalogueNumber'
		});

		const priceCatalogueDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			emptyText: edi.i18n.getMessage('date'),
			allowBlank: false,
			value: edi.utils.getObjectProperty(header, 'PriceCatalogueDate'),
			name: 'PriceCatalogue-Header.PriceCatalogueDate'
		});

		return createMaxWidthContainer({
			layout: {
				type: 'grid',
				area: [[2, 4, 2]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [bpName, licenseNumber, priceCatalogueDate]
		});
	};

	const createOS = function (selectorConfig, parties) {
		Ext.applyIf(selectorConfig, {
			useHiddenFields: true,
			fieldsMapOnly: true,
			fieldValues: parties,
			valuesByMap: true,
			forceChangeFields: {
				company_iln: true
			},
			processValues: orgConverter
		});

		return createOrgSelector(selectorConfig);
	};

	const createPartiesBlock = function (parties) {
		const docHeader = moduleData.initData.data;
		const buyerGLN = edi.utils.getObjectProperty(parties, 'Buyer.ILN'),
			buyerHasData = !!buyerGLN,
			isBuyerDefined = !isEdit && buyerOrg;

		buyer = createOS(
			{
				itemId: 'buyer',
				relationsOnly: true,
				allowBlank: false,
				orgFromRelation: true,
				readOnly: isEdit,
				is_valid: buyerHasData,
				originalValues: orgSelectorMethods.getOrgCardValues({
					orgId: docHeader?.toOrg.id,
					converter: orgConverter
				}),
				relations: isBuyerDefined ? buyerOrg : edi.relations.getRelations(),
				allowReset: !buyerHasData,
				fieldValues: parties ? parties : undefined,
				selectedOrgValues: isBuyerDefined ? buyerOrgValues : buyerHasData ? parties : undefined,
				selectedOrg: isBuyerDefined ? buyerOrg : null,
				selectedRelationByMap: !isBuyerDefined,
				fieldsMap: getCommonPricatFieldsMap('Buyer'),
				modalConf: Ext.merge(getCommonPricatModalConf(), { title: edi.i18n.getMessage('document.buyer') }),
				callback: function (values, org) {
					const orgId = org ? org.id : null;
					const continueFn = function () {
						buyerId = orgId;
						if (productsGrid.getPartnerId() != orgId) {
							productsGrid.productsGrid.getStore().removeAll();
						}
						productsGrid.setPartnerId(orgId);
						priceRegionsGrid.setPartnerId(orgId);
						if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
							buyerOrg = org;
							buyerOrgValues = edi.converters.convertOrgToPartie(buyerOrg);
						} else if (!orgId) {
							buyerOrg = null;
							buyerOrgValues = null;
						}
						checkValid();
					};

					const sellerId = seller.getValues().id || seller.selectedOrg?.id;
					if (orgId && sellerId) {
						edi.methods.custom_fields.initCustomFields({
							customFieldsObj,
							docType: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
							toOrgId: orgId,
							fromOrgId: sellerId,
							container: customFieldsContainer,
							grid: productsGrid,
							topPath: topPath,
							finishCallback(obj) {
								customFieldsObj = obj;
								form.isValid();
								continueFn();
							},
							fail() {
								form.isValid();
								continueFn();
							}
						});
					} else {
						continueFn();
					}
				}
			},
			parties
		);

		const sellerGLN = edi.utils.getObjectProperty(parties, 'Seller.ILN'),
			isSellerDefined = !!sellerGLN;

		const sellerValues = isSellerDefined ? parties : edi.converters.convertOrgToLegacyPartie(userData.org);
		seller = createOS(
			{
				itemId: 'seller',
				valuesByMap: isSellerDefined,
				orgFromRelation: true,
				readOnly: false,
				selectedRelationByMap: isSellerDefined,
				is_valid: isSellerDefined || (!compricat && !document),
				selectedOrg: userData.org,
				fieldValues: parties ? parties : sellerValues,
				selectedOrgValues: parties ? sellerData : sellerValues,
				originalValues: orgSelectorMethods.getOrgCardValues({
					orgId: docHeader?.fromOrg ? docHeader.fromOrg.id : userData.org.id,
					converter: orgConverter
				}),
				fieldsMap: getCommonPricatFieldsMap('Seller', {
					license_type: null,
					license_name: null
				}),
				modalConf: Ext.merge(getCommonPricatModalConf(), { title: edi.i18n.getMessage('document.seller') }),
				callback: checkValid
			},
			parties
		);

		return createMaxWidthContainer({
			layout: {
				type: 'grid',
				area: [[6, 6]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('document.seller'),
					items: [seller]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('document.buyer'),
					items: [buyer]
				})
			]
		});
	};

	const createCatalogPeriodBlock = function (document) {
		return createFieldBlock({
			title: edi.i18n.getMessage('pricat.document.period'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: 8,
				area: [[4]]
			},
			items: [
				createDateRangeField({
					hideAllPeriods: true,
					fieldsConfig: {
						common: {
							allowBlank: true
						},
						from: {
							fieldLabel: '',
							valueSrc: document ? document : compricat,
							name: 'PriceCatalogue-Header.PeriodStartDate'
						},
						to: {
							fieldLabel: '',
							valueSrc: document ? document : compricat,
							name: 'PriceCatalogue-Header.PeriodEndDate'
						}
					}
				})
			]
		});
	};

	const createAdditionalInformationBlock = function (document, header) {
		const functionalDocumentCode = createCombo({
			fieldLabel: edi.i18n.getMessage('functional.document.code'),
			name: 'PriceCatalogue-Header.DocumentFunctionCode',
			store: edi.stores.createSimpleInlineStore(['9', '4', '29', '27', '11', '12'], function (id) {
				return edi.i18n.getMessage('common.pricat.functional.document.code.' + id);
			}),
			value: document ? edi.utils.getObjectProperty(header, 'DocumentFunctionCode') : '9',
			anyMatch: true,
			allowBlank: false
		});

		const currency = createCombo({
			fieldLabel: edi.i18n.getMessage('ordrsp.currency'),
			name: 'PriceCatalogue-Header.PriceCatalogueCurrency',
			valueSrc: document ? document : header,
			value: edi.utils.getObjectProperty(header, 'PriceCatalogueCurrency') ? undefined : 'RUB',
			store: edi.stores.createSimpleInlineStore(['RUB', 'EUR', 'USD'], function (id) {
				return id;
			}),
			allowBlank: false,
			anyMatch: true
		});

		const catalogType = createCombo({
			fieldLabel: edi.i18n.getMessage('column.catalog.type'),
			name: 'PriceCatalogue-Header.DocumentNameCode',
			store: edi.stores.createSimpleInlineStore(['9', '25E', '11I'], function (id) {
				return edi.i18n.getMessage('common.pricat.document.code.' + id);
			}),
			value: document ? edi.utils.getObjectProperty(header, 'DocumentNameCode') : '9',
			allowBlank: false
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('document.common.pricat.section.additional'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: 8,
				area: [[3, 3, 3]]
			},
			items: [catalogType, functionalDocumentCode, currency]
		});
	};

	/**
	 * Дополнительные поля
	 */
	const createCustomFieldsContainer = function () {
		return (customFieldsContainer = createContainer({}));
	};

	const createAgreementBlock = function (header) {
		const contractNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.create.common.pricat.nr'),
			value: document ? edi.utils.getObjectProperty(header, 'Reference.ContractNumber') : '',
			name: 'PriceCatalogue-Header.ContractNumber',
			maxLength: 35
		});

		const contractDate = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			value: document ? edi.utils.getObjectProperty(header, 'Reference.ContractDate') : '',
			name: 'PriceCatalogue-Header.Reference.ContractDate'
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('document.common.pricat.section.agreement'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				gap: 24,
				area: [[3, 3]]
			},
			items: [contractNumber, contractDate]
		});
	};

	const createPriceSegmentBlock = function (document, parties) {
		return createFieldBlock({
			title: edi.i18n.getMessage('common.pricat.price.segment'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				area: [[4]]
			},
			items: [
				createTextField({
					maxLength: 70,
					value: document ? edi.utils.getObjectProperty(parties, 'PriceRegions.PriceRegionText') : '',
					name: 'PriceRegionText'
				})
			]
		});
	};

	const createRemarkBlock = function (document, header) {
		return createFieldBlock({
			title: edi.i18n.getMessage('documents.column.remark'),
			cls: 'edi-form-maxwidth',
			layout: {
				type: 'grid',
				area: [[6]]
			},
			items: [
				createTextField({
					value: document ? edi.utils.getObjectProperty(header, 'Remarks') : '',
					name: 'PriceCatalogue-Header.Remarks',
					maxLength: 35,
					isTextarea: true
				})
			]
		});
	};

	const createPriceRegionsGrid = function (parties) {
		if (edi.utils.getObjectProperty(parties, 'PriceRegions.PriceRegionsList', true).length > 0) {
			priceRegionsValues = edi.utils.getObjectProperty(parties, 'PriceRegions.PriceRegionsList', true);
		}

		priceRegionsGrid = createProductGridBase({
			title: edi.i18n.getMessage('documents.pricat.price.priceRegions'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: COMMONPRICAT_PRICE_REGIONS_MODEL,
			gridColumnConfig: COMMONPRICAT_PRICE_REGIONS_COLUMN,
			allowBlank: true,
			data: priceRegionsValues,
			lockIfNoPartner: true,
			hasTotal: false,
			showSelectProductBtn: false,
			checkValuesBeforeSave(values) {
				let allFieldsAreEmpty = Object.values(values || {})
					.map((v) => String(v).trim())
					.every((v) => v.length === 0);
				if (allFieldsAreEmpty === true) {
					edi.core.showError(edi.i18n.getMessage('documents.pricat.price.warning.empty.grid.values'));
					return false;
				}
				return true;
			},
			callback: function (values) {
				moduleData.isChanged = true;
				priceRegionsValues = values.products;
				checkValid();
			},
			modalFormConfig: {
				title: 'documents.common.pricat.unloadingPoint',
				modalFields: [
					{
						title: edi.i18n.getMessage('documents.iftmbf.line.modal.tab.main.data'),
						items: [
							{
								title: edi.i18n.getMessage('column.code'),
								name: 'Code'
							},
							{
								title: edi.i18n.getMessage('column.name'),
								name: 'Name'
							},
							{
								title: edi.i18n.getMessage('documents.column.remark'),
								maxLength: 140,
								name: 'Remarks'
							}
						]
					}
				]
			}
		});
		if (buyerId) {
			priceRegionsGrid.setPartnerId(buyerId);
		}

		return priceRegionsGrid;
	};

	const createProductGrid = function (document) {
		let i;
		let lines = edi.utils.getObjectProperty(document ? document : compricat, 'PriceCatalogue-Lines.Line', true);
		if ('string' != typeof lines) {
			for (i = 0; i < lines.length; i++) {
				let values = Ext.clone(lines[i]['Line-Item']),
					priceRegions = Ext.clone(lines[i]['Line-PriceRegions']);
				if (priceRegions.PriceRegionText) {
					values['PriceRegionText'] = priceRegions.PriceRegionText;
				}
				edi.document.actions.setInternalLineId(lines[i], values);
				productValues.products.push(values);
			}
		}

		const getTaxValue = function (tax) {
			if ('10/110' === tax) {
				return edi.constants.TAX_RATES.ALL.part10of110.mathValue();
			} else if ('18/118' === tax) {
				return edi.constants.TAX_RATES.ALL.part18of118.mathValue();
			} else if ('20/120' === tax) {
				return edi.constants.TAX_RATES.ALL.part20of120.mathValue();
			} else {
				return Number(tax);
			}
		};

		const recalculateNetPrice = function (tax, grossPrice) {
			if (tax !== null && grossPrice !== null) {
				return Number(grossPrice) / (1 + getTaxValue(tax) / 100);
			}
		};

		const recalculateGrossPrice = function (tax, netPrice) {
			if (tax !== null && netPrice !== null) {
				return Number(netPrice) * (1 + getTaxValue(tax) / 100);
			}
		};

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.common.pricat.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: COMMONPRICAT_LINE_MODEL,
			gridColumnConfig: COMMONPRICAT_LINE_COLUMN,
			data: productValues.products,
			lines: lines,
			lockIfNoPartner: true,
			hasTotal: false,
			lineNumberFieldName: 'LineNumber',
			docType: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			totalsHandlerCallback: function (values) {
				if ('undefined' != typeof values) {
					Ext.Object.merge(productValues, values);
				}
				checkValid();
			},
			resetFormOnCatalogSelection: true,
			selectProductHandler: function (product) {
				let fields = this.getFormFields();
				const PackDetails = product.PackDetails ? product.PackDetails : {};

				fields.EAN ? fields.EAN.setValue(product.EAN) : null;
				fields.BuyerItemCode ? fields.BuyerItemCode.setValue(product.BuyerItemCode) : null;
				fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
				fields.SupplierItemCode ? fields.SupplierItemCode.setValue(product.SupplierItemCode) : null;
				fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;
				fields.Brand ? fields.Brand.setValue(product.Brand) : null;
				fields.PackDetails && fields.PackDetails.MinOrderedQuantity
					? fields.PackDetails.MinOrderedQuantity.setValue(PackDetails)
					: null;
				fields.Campaign ? fields.Campaign.setValue(product.Campaign) : null;
				fields.UnitGrossPrice ? fields.UnitGrossPrice.setValue(product.UnitGrossPrice) : null;
				fields.UnitOfMeasure ? fields.UnitOfMeasure.setValue(product.UnitOfMeasure) : null;
			},
			modalFormConfig: {
				title: 'grid.line.items',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						customFieldTab: 'BASIC',
						items: [
							{
								title: edi.i18n.getMessage('document.slsfct.column.column.barCode'),
								name: 'EAN',
								maskRe: /\d/i,
								regex: edi.constants.VALIDATORS.EAN13,
								invalidText: edi.i18n.getMessage('invalid.field.ean.value.default')
							},
							{
								title: edi.i18n.getMessage('common.pricat.productName'),
								maxLength: 512,
								allowBlank: false,
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('pricat.product.unitOfMeasure'),
								maxLength: 6,
								name: 'UnitOfMeasure'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								maxLength: 26,
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								maxLength: 150,
								allowBlank: false,
								name: 'SupplierItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.brand'),
								maxLength: 75,
								name: 'Brand'
							},
							{
								title: edi.i18n.getMessage('line.item.сampaign'),
								maxLength: 175,
								type: 'combo',
								store: edi.stores.initYesOrNoStore(),
								name: 'Campaign'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						customFieldTab: 'PRICES',
						items: [
							{
								title: edi.i18n.getMessage('common.pricat.price.segment'),
								name: 'PriceRegionText'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.net.price'),
								name: 'UnitNetPrice',
								allowBlank: false,
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								listeners: {
									change: function (cmp, val) {
										let fields = this.getFormFields();
										if (fields.TaxRate && !!fields.TaxRate.getValue()) {
											let tax = fields.TaxRate.getValue();
											fields.UnitGrossPrice.setValue(recalculateGrossPrice(tax, val));
										}
										if (fields.UnitGrossPrice) {
											fields.UnitGrossPrice.isValid();
										}
									}
								},
								validator: function (value) {
									let fields = this.getFormFields();
									if (!value && fields.UnitGrossPrice && fields.UnitGrossPrice.getValue()) {
										return edi.i18n.getMessage('error.value.notvalid');
									}
									return true;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'UnitGrossPrice',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals,
								listeners: {
									change: function (cmp, val) {
										let fields = this.getFormFields();
										if (fields.TaxRate && !!fields.TaxRate.getValue()) {
											let tax = fields.TaxRate.getValue();
											fields.UnitNetPrice.setValue(recalculateNetPrice(tax, val));
										}
										if (fields.UnitNetPrice) {
											fields.UnitNetPrice.isValid();
										}
									}
								},
								validator: function (value) {
									let fields = this.getFormFields();
									if (
										(!value && fields.UnitNetPrice && fields.UnitNetPrice.getValue()) ||
										Number(value) < Number(fields.UnitNetPrice.getValue())
									) {
										return edi.i18n.getMessage('error.value.notvalid');
									}
									return true;
								}
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate',
								type: 'combo',
								store: edi.stores.initTaxRatesWithFractStore(),
								listeners: {
									change: function (cmp, val) {
										let fields = this.getFormFields();
										if (fields.TaxRate && !!val) {
											fields.UnitGrossPrice.setValue(
												recalculateGrossPrice(val, fields.UnitNetPrice.getValue())
											);
										}
										if (fields.UnitNetPrice) {
											fields.UnitNetPrice.isValid();
										}
									}
								}
							},
							{
								title: edi.i18n.getMessage('line.item.discount'),
								name: 'Discount',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.data.packing'),
						customFieldTab: 'PACKAGING',
						items: [
							{
								title: edi.i18n.getMessage('line.item.min.ordered.quantity'),
								maxLength: 35,
								name: 'PackDetails.MinOrderedQuantity'
							},
							{
								title: edi.i18n.getMessage('pricat.product.weight'),
								name: 'PackDetails.Weight',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('pricat.product.length'),
								name: 'PackDetails.Length',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('pricat.product.width'),
								name: 'PackDetails.Width',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('pricat.product.height'),
								name: 'PackDetails.Height',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							},
							{
								title: edi.i18n.getMessage('pricat.product.unitPacksize'),
								name: 'PackDetails.UnitPacksize',
								type: 'number',
								allowDecimals: true,
								decimalPrecision: costDecimals
							}
						]
					}
				]
			}
		});

		if (buyerId) {
			productsGrid.setPartnerId(buyerId);
		}

		return productsGrid;
	};

	const createCreateButton = function () {
		return createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					const values = edi.utils.collectFormValues(form);
					save(values);
				}
			},
			isEdit
		);
	};

	const createCreateAndSendButton = function () {
		let createAndSendButton;
		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.COMMON_PRICAT
				)
			)
		) {
			createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					const values = edi.utils.collectFormValues(form);
					afterSave = function (documentId, callback) {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							Ext.encode({}),
							null,
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.in.sending.process'
							),
							callback
						);
					};
					save(values);
				},
				null,
				isEdit
			);
		}
		return createAndSendButton;
	};

	const createCloseButton = function () {
		return createButton({
			cls: BUTTON_CLS.secondary,
			text: edi.i18n.getMessage('form.close.and.not.save'),
			handler() {
				edi.modulesHandler.removeModule(moduleData);
			}
		});
	};

	/**
	 * Creates despatch advise form
	 * @returns {Object}
	 */
	const createModuleForm = function (document) {
		let header = edi.utils.getObjectProperty(document ? document : compricat, 'PriceCatalogue-Header');
		let parties = edi.utils.getObjectProperty(document ? document : compricat, 'PriceCatalogue-Parties');
		const licenceNumberValue =
			document || compricat
				? edi.utils.getObjectProperty(header, 'PriceCatalogueNumber') +
				  (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
				: undefined;

		form = createFormForModule({
			cls: 'edi-form document-add-form',
			items: [
				createLabel({
					typography: 'heading_02',
					text: moduleData.title
				}),
				createMainDataBlock(document, header, licenceNumberValue),
				createPartiesBlock(parties),
				createCatalogPeriodBlock(document),
				createAdditionalInformationBlock(document, header),
				createAgreementBlock(header),
				createPriceSegmentBlock(document, parties),
				createRemarkBlock(document, header),
				createCustomFieldsContainer(),
				createPriceRegionsGrid(parties),
				createProductGrid(document)
			],
			buttons: createButtonContainer({
				items: [createCreateAndSendButton(), createCreateButton(), createCloseButton()]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);
		return form;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		const validCompanies = validateCompanySelectors(),
			hasInvalid = form.hasInvalidField(),
			validGrid = productsGrid.isValid();

		return !hasInvalid && validCompanies && validGrid;
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	const validateCompanySelectors = function () {
		return buyer.isValid() && seller.isValid();
	};

	/**
	 * @param {Object}    formValues    values from form
	 */
	const save = function (formValues) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		let values = formValues || {},
			priceSegment = edi.utils.getObjectProperty(values, 'SegmentPrice'),
			contractNumber = edi.utils.getObjectProperty(values, 'PriceCatalogue-Header.ContractNumber');
		let clonedProductValues = Ext.clone(productsGrid.getValues());
		let customFieldsData = edi.methods.custom_fields.getCustomFieldsData(
			customFieldsObj,
			values,
			clonedProductValues.products,
			topPath
		);
		Object.keys(customFieldsData).forEach(function (key) {
			delete values[key];
		});
		clonedProductValues.products.forEach(function (line) {
			Object.keys(line).forEach(function (key) {
				if (key.match(topPath)) {
					delete line[key];
				}
			});
		});

		if (!isCopy) {
			edi.utils.setObjectProperty(
				values,
				'PriceCatalogue-Header.IDFile',
				edi.utils.getObjectProperty(initialData, 'PriceCatalogue-Header.IDFile')
			);
		}
		edi.utils.setObjectProperty(values, 'PriceCatalogue-Header.Reference.ContractNumber', contractNumber);
		edi.utils.setObjectProperty(values, 'PriceCatalogue-Header.Reference.Reference-Elements', [
			{
				'Reference-Id': priceSegment,
				'Reference-Type': 'SB'
			}
		]);
		let document = createCommonPricat({
			'PriceCatalogue-Header': values['PriceCatalogue-Header'],
			parties: createCommonPricatParties({
				Buyer: values['Buyer'],
				Seller: values['Seller'],
				PriceRegions: {
					PriceRegionText: values['PriceRegionText'],
					PriceRegions: priceRegionsValues
				}
			}),
			documentParties: createCommonPricatDocumentParties({
				Buyer: values['Buyer'],
				Seller: values['Seller']
			}),
			productValues: clonedProductValues
		});
		edi.utils.clearEmptyValues(document);
		const success = edi.document.actions.createSaveSuccessHandler(
			moduleData,
			isEdit ? id : undefined,
			afterSave,
			isEdit
		);
		const failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.COMMON_PRICAT
		);

		let docData = {
			data: Ext.encode(document),
			number: edi.utils.getObjectProperty(values, 'PriceCatalogue-Header.PriceCatalogueNumber'),
			bpName: edi.utils.getObjectProperty(values, 'PriceCatalogue-Header.bpName'),
			date: edi.utils.getObjectProperty(values, 'PriceCatalogue-Header.PriceCatalogueDate')
		};
		if (customFieldsData && Object.keys(customFieldsData).length > 0) {
			docData.customFields = customFieldsData;
		}

		edi.document.actions.processDocument(
			buyerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
			parentId,
			isEdit ? id : undefined,
			success,
			failure,
			docData
		);
	};

	/**
	 * Creates action pane above the data panel
	 */
	const createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Renders module
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const callback = function () {
			buyer.presetFromRelation(function () {
				'function' == typeof initCallBack ? initCallBack() : null;

				if (isBasedOnDoc && productValues.products.length) {
					edi.methods.taxRates.showInvalidProductsWarnIfNeeded(productValues.products, availableTaxRates);
				}
			});
		};
		let docHeader = moduleData.initData.data;
		let modulePanel = createAddModulePanel();
		if (docHeader && docHeader.id) {
			id = docHeader.id;
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			buyerOrg = docHeader.toOrg;
			buyerId = buyerOrg.id;
			const failure = edi.document.actions.defaultFailureHandler(
				moduleData.tab,
				'error.getting.data',
				function () {
					edi.modulesHandler.removeModule(moduleData);
				}
			);
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (resp) {
					if (resp && resp.data) {
						initialData = resp.data;
						modulePanel.add(createModuleForm(initialData));
						moduleData.tab.add(createModuleActionsPanel());
						moduleData.tab.add(modulePanel);
						if (docHeader.toOrg?.id && docHeader.fromOrg?.id) {
							edi.methods.custom_fields.initCustomFields({
								customFieldsObj,
								docType: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
								toOrgId: docHeader.toOrg.id,
								fromOrgId: docHeader.fromOrg.id,
								docId: id,
								container: customFieldsContainer,
								grid: productsGrid,
								topPath: topPath,
								finishCallback(obj) {
									customFieldsObj = obj;
									form.isValid();
									checkValid();
								},
								fail() {
									form.isValid();
									checkValid();
								}
							});
						}
						callback();
					} else {
						failure(resp);
					}
				},
				failure
			);
		} else {
			modulePanel.add(createModuleForm());
			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);
			callback();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
