import './deferred.reports.js';
//Данный модуль используется только в ФП, но кто знает, где ещё он может понадобиться
//со стороны бэка поддержка осталась в edi, поэтому просто отключим его из бандла
//import './notifications.settings.js';
import './patterns.generation.doc.num.js';
import './documentGrids/index.js';
import './catalog/catalog.create.js';
import './catalog/catalog.details.js';
import './catalog/catalogs.v2.js';
import './documents/index.js';
import './export/export.group.request.js';
import './invitations/invitation.details.js';
import './invitations/invitations.js';
import './organization_settings/email.notification.js';
import './organization_settings/rounding.settings.js';
import './partners/partner.details.js';
import './partners/partners.js';
import './external_link/external_link.js';
import './catalog/LEGACY_PARTIN/partin.create.js';
import './catalog/LEGACY_PARTIN/partin.details.js';
import './roseu_invitations/index.js';
import './power_of_attorney/index';
