export const PRICAT_EANCOM_V2_METHODS = {
	buildDocument: ({ documentData, values }) => ({
		unh: {
			s009: {
				e0065: 'PRICAT',
				e0052: 'D',
				e0054: '01B',
				e0051: 'UN',
				e0057: 'EAN008'
			}
		},
		bgm: {
			e1225: documentData.docType || 9
		},
		sg17: {
			pgi: {
				e5379: 9
			},
			ftx: [
				{
					e4451: 'AAK',
					c108: {
						e4440: values.typePriceNotice // Тип ценового уведомления
					}
				},
				{
					e4451: 'GEN',
					c108: {
						e4440: values.documentPurpose // Назначение документа
					}
				}
			]
		},
		unt: {
			e0074: 0 // Количество товарных позиций в документе.
		}
	}),
	getProductValues: ({ productValues }) =>
		productValues.products.map((product) => {
			const productLine = {
				lin: {
					// Порядковый номер
					e1082: product.LineNumber,
					c212: product.EAN.length
						? {
								// EAN
								e7140: product.EAN,
								e7143: 'SRV'
						  }
						: {}
				},
				pia: [
					{
						e4347: '1',
						c212: [
							{
								e7140: product.ItemCode,
								e7143: 'IN'
							}
						]
					}
				],
				imd: {
					e7077: 'F',
					c273: {
						// Наименование товарной позиции
						e7008: product.Heading
					}
				},
				dtm: [
					{
						c507: {
							e2005: 194,
							// Дата начала действия новой цены
							e2380: product.startingDateNewPrice,
							e2379: 102
						}
					},
					{
						c507: {
							e2005: 206,
							// Дата окончания действия новой цены
							e2380: product.endingDateNewPrice,
							e2379: 102
						}
					}
				],
				sg9: [
					// Место хранения
					...product.deliveryPlace.map(({ iln, name }) => ({
						tod: {
							e4055: 6
						},
						loc: {
							e3227: 7,
							c517: {
								e3225: iln,
								e3224: name
							}
						}
					})),
					// Исключения из мест хранения
					...(product.exceptionsPlace || []).map(({ iln, name }) => ({
						tod: {
							e4055: 6
						},
						loc: {
							e3227: 12,
							c517: {
								e3225: iln,
								e3224: name
							}
						}
					}))
				],
				//sg38: [
				//	{
				//		tax: {
				//			e5283: 7,
				//			c241: {
				//				e5153: 'VAT'
				//			},
				//			c243: {
				//				// Налоговая ставка, %
				//				// зависит от "Поставщик плательщик НДС или нет" (NDS_payer)
				//				// смешанный - для каждой позиции своя
				//				// плательщик НДС - общее для всех значение taxRate
				//				// не плательщик НДС - общее для всех = null
				//				e5278: supplierNDSPayerState === 'mixed' ? product.taxRate : values.taxRate || 'null'
				//			}
				//		}
				//	}
				//],
				sg40: [
					{
						pri: {
							c509: {
								e5125: 'AAE',
								e5118: product.NewPrice // Новая цена
							}
						}
					}
				]
			};

			if (product.volume) {
				productLine.mea = {
					e6311: 'AAE',
					c502: {
						e6313: 'ABJ'
					},
					c174: {
						e6411: 'MTQ',
						// Объем
						e6314: product.volume
					}
				};
			}

			edi.utils.clearEmptyValues(productLine);
			return productLine;
		}),
	getValuesMap: () => ({
		docNumber: 'bgm.c106.e1004',
		docName: 'bgm.c002.e1001',
		docType: 'bgm.e1225',
		agreementDate: {
			path: 'sg1.0.dtm.c507.e2380',
			defaultValues: [
				{
					path: 'sg1.0.dtm.c507.e2005',
					value: '171'
				},
				{
					path: 'sg1.0.dtm.c507.e2379',
					value: '102'
				}
			]
		},
		agreementNumber: {
			path: 'sg1.0.rff.c506.e1154',
			defaultValues: [
				{
					path: 'sg1.0.rff.c506.e1153',
					value: 'CT'
				}
			]
		},
		documentSRMid: {
			path: 'sg1.1.rff.c506.e1154',
			allowBlank: true,
			defaultValues: [
				{
					path: 'sg1.1.rff.c506.e1153',
					value: 'ACE'
				}
			]
		},
		dateDocument: {
			path: 'dtm.0.c507.e2380',
			defaultValues: [
				{
					path: 'dtm.0.c507.e2005',
					value: '137'
				},
				{
					path: 'dtm.0.c507.e2379',
					value: '102'
				}
			]
		},
		'Seller.iln': {
			path: 'sg2.0.nad.c082.e3039',
			defaultValues: [
				{
					path: 'sg2.0.nad.c082.e3055',
					value: 9
				},
				{
					path: 'sg2.0.nad.e3035',
					value: 'SU'
				}
			]
		},
		'Seller.company_name': 'sg2.0.nad.c080.e3036',
		'Seller.inn': {
			path: 'sg2.0.sg3.0.rff.c506.e1154',
			defaultValues: [
				{
					path: 'sg2.0.sg3.0.rff.c506.e1153',
					value: 'FC'
				}
			]
		},
		'Seller.kpp': {
			path: 'sg2.0.sg3.1.rff.c506.e1154',
			allowBlank: true,
			defaultValues: [
				{
					path: 'sg2.0.sg3.1.rff.c506.e1153',
					value: 'XA'
				}
			]
		},
		'Buyer.iln': {
			path: 'sg2.1.nad.c082.e3039',
			defaultValues: [
				{
					path: 'sg2.1.nad.c082.e3055',
					value: 9
				},
				{
					path: 'sg2.1.nad.e3035',
					value: 'BY'
				}
			]
		},
		'Buyer.company_name': 'sg2.1.nad.c080.e3036',
		'Buyer.inn': {
			path: 'sg2.1.sg3.0.rff.c506.e1154',
			defaultValues: [
				{
					path: 'sg2.1.sg3.0.rff.c506.e1153',
					value: 'FC'
				}
			]
		},
		'Buyer.contact_email': {
			path: 'sg2.1.sg4.0.com.c076.e3148',
			defaultValues: [
				{
					path: 'sg2.1.sg4.0.com.c076.e3155',
					value: 'EM'
				}
			]
		}
	}),
	getPricatLinesProperties: ({ pricatLines = [] }) => {
		return pricatLines.map((product) => ({
			LineNumber: edi.utils.getObjectProperty(product, 'lin.e1082'),
			EAN: edi.utils.getObjectProperty(product, 'lin.c212.e7140'),
			ItemCode: edi.utils.getObjectProperty(product, 'pia.0.c212.0.e7140'),
			Heading: edi.utils.getObjectProperty(product, 'imd.c273.e7008'),
			NewPrice: edi.utils.getObjectProperty(product, 'sg40.0.pri.c509.e5118'),
			startingDateNewPrice: edi.utils.getObjectPropertyEx(product, 'dtm[c507.e2005==194]c507.e2380'),
			endingDateNewPrice: edi.utils.getObjectPropertyEx(product, 'dtm[c507.e2005==206]c507.e2380'),
			volume: edi.utils.getObjectProperty(product, 'mea.0.c174.e6314'),
			...(product.sg9 || []).reduce(
				(places, storageInfo) => {
					const storageIdentifier = edi.utils.getObjectProperty(storageInfo, 'loc.e3227');
					const iln = edi.utils.getObjectProperty(storageInfo, 'loc.c517.e3225');
					const name = edi.utils.getObjectProperty(storageInfo, 'loc.c517.e3224');
					switch (storageIdentifier) {
						case '7': {
							places['deliveryPlace'].push({
								name,
								iln
							});
							break;
						}
						case '12': {
							places['exceptionsPlace'].push({
								name,
								iln
							});
							break;
						}
					}
					return places;
				},
				{
					deliveryPlace: [],
					exceptionsPlace: []
				}
			)
			//taxRate: edi.utils.getObjectProperty(product, 'sg38.0.tax.c243.e5278')
		}));
	},
	formatPricatDate: (date) => {
		return edi.utils.formatDate(
			date,
			edi.constants.DATE_FORMAT.FNS,
			edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM
		);
	}
};
