const ADDRESSES_COLUMN_CONFIG_NAME = 'container_common_pricat_addresses';
const CONTAINER_COMMONPRICAT_LINE_COLUMN = 'commonpricat_lines_container';

edi.columns.addColumns({
	[ADDRESSES_COLUMN_CONFIG_NAME]: {
		name: {
			text: 'document.details.container_common_pricat.addr_grid.name',
			dataIndex: 'name',
			flex: 1
		},
		address: {
			text: 'document.details.container_common_pricat.addr_grid.address',
			dataIndex: 'address',
			flex: 3
		}
	},
	[CONTAINER_COMMONPRICAT_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		ean: {
			text: 'document.slsfct.column.column.barCode',
			dataIndex: 'EAN',
			flex: 1
		},
		itemDescription: {
			text: 'common.pricat.productName',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 2
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'line.item.unit.shop.incoming.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitNetPrice',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 0.3
		},
		unitGrossPrice: {
			text: 'column.unit.gross.price',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'UnitGrossPrice',
			flex: 1
		},
		brand: {
			text: 'line.item.brand',
			hidden: true,
			dataIndex: 'Brand'
		},
		minOrderedQuantity: {
			text: 'line.item.min.ordered.quantity',
			hidden: true,
			dataIndex: 'PackDetails',
			renderer: function (value) {
				return edi.utils.getObjectProperty(value, 'MinOrderedQuantity');
			}
		},
		campaign: {
			text: 'line.item.сampaign',
			dataIndex: 'Campaign',
			renderer: function (value) {
				return '' === value
					? ''
					: 'false' === value
					? edi.i18n.getMessage('answer.no')
					: edi.i18n.getMessage('answer.yes');
			}
		},
		suggestedPrice: {
			text: 'line.item.unit.suggested.price',
			dataIndex: 'SuggestedPrice',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		specialPrice: {
			text: 'line.item.unit.special.price',
			dataIndex: 'SpecialPrice',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		}
	}
});

let getAddressesColumns = () => edi.columns.get(ADDRESSES_COLUMN_CONFIG_NAME);

export { getAddressesColumns, ADDRESSES_COLUMN_CONFIG_NAME, CONTAINER_COMMONPRICAT_LINE_COLUMN };
