/**
 * Create COMMON_PRICAT fields map
 * @param	{String}	prefix
 * @param	{Object}	[additionalConf]
 * @returns	{Object}	fields map
 */
const getCommonPricatFieldsMap = function (prefix, additionalConf) {
	prefix += '.';

	let defaultConf = {
		company_name: prefix + 'Name',
		company_iln: prefix + 'ILN',
		company_util_reg_nr: prefix + 'UtilizationRegisterNumber',
		company_inn: prefix + 'TaxID',
		company_kpp: prefix + 'TaxRegistrationReasonCode',
		fns_id: prefix + 'FnsID',

		addr_category: prefix + 'AddressType',
		addr_country: prefix + 'Country',
		addr_zip: prefix + 'PostalCode',
		addr_locality: prefix + 'Locality',
		addr_block: prefix + 'Housing',
		addr_addInf: prefix + 'AdditionalInformation',
		addr_area: prefix + 'District',
		addr_region_name: prefix + 'State',
		addr_region_code: prefix + 'StateCode',
		addr_city: prefix + 'CityName',
		addr_street: prefix + 'StreetAndNumber',
		addr_home: prefix + 'HouseNumber',
		addr_flat: prefix + 'RoomNumber',

		license_type: prefix + 'License.Type',
		license_name: prefix + 'License.Name',
		license_series: prefix + 'License.Series',
		license_number: prefix + 'License.Number',
		license_issuing_authority: prefix + 'License.IssuingAuthority',
		license_date_of_issue: prefix + 'License.DateOfIssue',
		license_expiration_date: prefix + 'License.ExpirationDate'
	};

	let result = Ext.merge(defaultConf, additionalConf);
	edi.utils.clearEmptyValues(result);

	return result;
};

/**
 * Create COMMON_PRICAT modal config
 * @param	{Object}	[additionalConf]
 * @returns	{Object}	fields map
 */
const getCommonPricatModalConf = function (additionalConf) {
	let defaults = edi.selectors.defaults;

	let defaultConf = edi.selectors.mergeModalCfgWithCommonOrgAddress(
		Ext.clone({
			tabs: {
				main: {
					company_name: defaults.company_name,
					company_gln: defaults.company_gln,
					company_reg_number: defaults.company_reg_nr,
					company_inn_kpp: Ext.merge(Ext.clone(defaults.company_inn_kpp), {
						fields: {
							kpp: {
								allowBlank: true
							}
						}
					}),
					fns_id: defaults.fns_id
				},
				address: {
					category: {
						fieldConfig: {
							listeners: {
								afterrender: function (combo) {
									//try to find selected organization
									let companySelector = combo.modalInstance;
									let selectedOrg = Ext.clone(companySelector?.selectedOrg);
									if (!selectedOrg && companySelector?.selectedOrgValues?.id) {
										selectedOrg = edi.utils.getOrg({ orgId: companySelector.selectedOrgValues.id });
									}
									//init cached values with values from organization card (if it exists)
									if (selectedOrg?.addresses?.length > 0) {
										[
											edi.constants.ADDRESS_TYPES.LEGAL,
											edi.constants.ADDRESS_TYPES.REAL,
											edi.constants.ADDRESS_TYPES.POSTAL
										].forEach((type) => combo.saveAddrToCache(selectedOrg, type));
									}

									let val = combo.getValue();
									//В оригинале там было setDisabled, но тогда при редактировании оно не сохраняется
									//т.к. дизейбленые поля не берутся в form.getValues()
									combo.setReadOnly(val === edi.constants.ADDRESS_TYPES.LEGAL);
								}
							}
						}
					},
					country: {
						fieldConfig: {
							mandatory: false,
							allowBlank: true
						}
					},
					addInf: null
				},
				bank: {}
			}
		})
	);

	return Ext.merge(defaultConf, additionalConf);
};

export { getCommonPricatFieldsMap, getCommonPricatModalConf };
