import { EWDocumentDetailsFns_ukd_5_01nModule } from '@Ediweb/modules/EDI_FNS_UKD/document.details.fns_ukd_5_01n';

export class ApprovalDocumentDetailsFns_ukd_5_01nModule extends EWDocumentDetailsFns_ukd_5_01nModule {
	constructor(part1, part2) {
		super();
		this.part1 = part1;
		this.part2 = part2;
		this.part2Head = {};
		this.part1Head = {};
	}

	createModuleHeadPanel() {}

	getPartsData(ids, documentHeaderData, initCallBack) {}
}
