const INVOICE_LINE_MODEL = 'INVOICE_LINE';
const INVOICE_SUMMARY_MODEL = 'INVOICE_SUMMARY';
const INVOICE_TAX_SUMMARY_MODEL = 'INVOICE_TAX_SUMMARY';

edi.models.setModels({
	[INVOICE_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'ItemType',
				type: 'string'
			},
			{
				name: 'InvoiceQuantity',
				type: 'string'
			},
			{
				name: 'InvoiceUnitNetPrice',
				type: 'string'
			},
			{
				name: 'InvoiceUnitGrossPrice',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'InvoicedUnitPackSize',
				type: 'string'
			},
			{
				name: 'PreviousTaxRate',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'TaxCategoryCode',
				type: 'string'
			},
			{
				name: 'TaxAmount',
				type: 'string'
			},
			{
				name: 'NetAmount',
				type: 'string'
			},
			{
				name: 'GrossAmount',
				type: 'string'
			},
			{
				name: 'account',
				type: 'string'
			},
			{
				name: 'selectedAccountId',
				type: 'string'
			}
		],
		idProperty: 'LineNumber'
	},
	[INVOICE_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'TotalLines',
				type: 'number'
			},
			{
				name: 'TotalInvoicedAmount',
				type: 'string'
			},
			{
				name: 'TotalNetAmount',
				type: 'float'
			},
			{
				name: 'TotalTaxAmount',
				type: 'float'
			},
			{
				name: 'TotalGrossAmount',
				type: 'float'
			}
		]
	},
	[INVOICE_TAX_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'TaxCategoryCode',
				type: 'string'
			},
			{
				name: 'TaxAmount',
				type: 'string'
			},
			{
				name: 'TaxableAmount',
				type: 'string'
			}
		],
		idProperty: 'TaxRate'
	},
	[INVOICE_LINE_MODEL]: {}
});

export { INVOICE_LINE_MODEL, INVOICE_SUMMARY_MODEL, INVOICE_TAX_SUMMARY_MODEL };
