import { createEWFileBlueButton } from '@Ediweb/components/components';
import { createTextField } from '@UIkit/components/fields';
import {
	createPanel,
	createFieldBlock,
	createFormContainer,
	createContainer,
	createForm
} from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createDetailsModulePanel } from '@Components/panels';

Ext.namespace('edi.modules');
edi.modules['organization'] = function () {
	var moduleData,
		userOrg,
		containerId,
		containerStore = edi.stores.initContainerForDocuments(),
		infoCreateDateField,
		childrenDocIdList = [];
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.organization.on('change', changeHandler);
	};

	var failure = function (data) {
		if (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
		} else {
			edi.core.showError('error');
		}
	};

	var uploadDocumentBlock = function (title, type) {
		var textInput, fileInput, docId, form;

		var deletedBtn = createButton({
			cls: BUTTON_CLS.outline,
			glyph: edi.constants.ICONS.DELETE,
			margin: '0 0 0 5',
			disabled: true,
			handler: function () {
				pushDocument(
					docId,
					{
						DELETE: true
					},
					function () {
						deletedBtn.setDisabled(true);
						textInput.setValue(null);
						form.isValid();
						childrenDocIdList = childrenDocIdList.filter(function (id) {
							return docId != id;
						});
					}
				);
			}
		});
		var uploadBtn = createButton({
			cls: BUTTON_CLS.outline,
			glyph: edi.constants.ICONS.FILE_DOWNLOAD,
			margin: '0 0 0 5',
			disabled: true,
			handler: function () {
				edi.rest.downloadFile(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.XML, {
						documentId: docId
					}),
					docId
				);
			}
		});

		form = createForm({
			padding: 0,
			items: [
				createFormContainer({
					items: [
						createFieldBlock({
							title: title,
							items: [
								createFormContainer({
									area: [[9, 6]],
									items: [
										(textInput = createTextField({
											readOnly: true,
											emptyText: 'Не выбран',
											value: ''
										})),
										//Старый компонент, модуль под замену, на данный момент модуль не используется
										(fileInput = createEWFileBlueButton(
											{},
											{
												name: 'attachment',
												allowBlank: false,
												listeners: {
													change: function (comp) {
														if (comp.getValue() != '') {
															textInput.allowBlank = true;
															edi.core.submitUploadForm(
																form,
																edi.rest.services.DSF.FILE.POST,
																'loading.text',
																function (responseData) {
																	if (responseData && responseData.data) {
																		var fileId = responseData.data.id;

																		addDocumentToContainer(
																			fileId,
																			containerId,
																			type,
																			function (data) {
																				docId = data.id;
																				uploadBtn.setDisabled(false);
																				deletedBtn.setDisabled(false);
																				textInput.setValue(
																					mapDocTypeText[type]
																				);
																				childrenDocIdList.push(docId);
																			}
																		);
																	} else {
																		failure();
																	}
																},
																failure
															);
														}
													}
												}
											},
											{
												disable: true
											}
										))
									]
								})
							]
						})
					]
				})
			]
		});

		fileInput.add(uploadBtn);
		fileInput.add(deletedBtn);

		form.setDocId = function (id) {
			docId = id;
		};
		form.setBtnDisabled = function (isDisabled) {
			uploadBtn.setDisabled(isDisabled);
			deletedBtn.setDisabled(isDisabled);
		};
		form.setFieldValue = function (text) {
			textInput.setValue(text);
		};
		form.getFieldValid = function () {
			var value = textInput.getValue();
			textInput.allowBlank = !!value;
			textInput.isValid();
			return !!value;
		};
		form.getSubType = function () {
			return type;
		};

		return form;
	};

	var mapDocTypeText = {
		USTDOKVEGRUL: 'Свидетельство о внесении записи в ЕГРЮЛ',
		USTDOKUCHR: 'Учредительные документы',
		USTDOKDOP: 'Изменения и/или дополнения в учредительных документах',
		USTDOKDOV: 'Доверенность с соответствующими полномочиями такого лица',
		USTDOKIEIO: 'Решение об избрании единоличного исполнительного органа'
	};

	var changeInfoConteiner = function (data) {
		containerId = data.id;
		infoCreateDateField.removeAll();
		infoCreateDateField.add(
			createContainer({
				padding: 15,
				style: {
					borderRadius: '4px',
					color: '#FFF',
					background: '#000',
					textAlign: 'center'
				},
				html: edi.renderers.dateFromMs(data.creationDate) + ' Создан'
			})
		);
		infoCreateDateField.setVisible(true);
	};

	var createContainerForDocuments = function (callback) {
		var generateUUID = new Ext.generateUUID();

		edi.rest.sendRequest(
			edi.rest.services.DOCUMENTS.POST,
			'POST',
			Ext.encode({
				data: Ext.encode({}),
				number: generateUUID.id,
				toOrgId: userOrg.id,
				docType: edi.constants.DOCUMENT_TYPES.ONBOARDING_CONTAINER,
				doctime: +new Date()
			}),
			function (responseData) {
				if (responseData && responseData.data) {
					if ('function' == typeof callback) {
						callback(responseData.data);
					}
				} else {
					failure();
				}
			},
			failure
		);
	};
	var getLinkedToContainer = function (callback) {
		if (containerStore.getCount()) {
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.SORTED.GET, {
					documentId: containerId
				}),
				'GET',
				undefined,
				function (responseData) {
					if (responseData && responseData.data) {
						if ('function' == typeof callback) {
							callback(responseData.data);
						}
					} else {
						failure();
					}
				},
				failure
			);
		}
	};
	var addDocumentToContainer = function (fileId, containerId, type, callback) {
		if (containerId) {
			var generateUUID = new Ext.generateUUID();
			var data = {
				data: Ext.encode({
					file: fileId
				}),
				number: generateUUID.id,
				doctime: +new Date(),
				toOrgId: userOrg.id,
				docType: edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
				parentId: containerId,
				bpName: 'SIMPLE',
				docAttributes: {
					subtype: type
				}
			};

			edi.rest.sendRequest(
				edi.rest.services.DOCUMENTS.POST,
				'POST',
				Ext.encode(data),
				function (responseData) {
					if (responseData && responseData.data) {
						if ('function' == typeof callback) {
							callback(responseData.data);
						}
					} else {
						failure();
					}
				},
				failure
			);
		} else {
			createContainerForDocuments(function (data) {
				containerStore.loadData([
					{
						id: data.id
					}
				]);

				changeInfoConteiner(data);
				addDocumentToContainer(fileId, data.id, type, callback);
			});
		}
	};
	var getContentFromDocument = function (id, callback) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.SIGN.GET, {
				documentId: id
			}),
			'GET',
			undefined,
			function (responseData) {
				if (responseData && responseData.data) {
					if ('function' == typeof callback) {
						callback(responseData.data);
					}
				} else {
					failure();
				}
			},
			failure
		);
	};
	var pushDocument = function (id, body, callback) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, {
				documentId: id
			}),
			'PUT',
			Ext.encode(body),
			function (responseData) {
				if (responseData && responseData.data) {
					if ('function' == typeof callback) {
						callback(responseData.data);
					}
				} else {
					failure();
				}
			},
			failure
		);
	};
	var setSignature = function (content, certData, docId, callback) {
		edi.sign.setSignature({
			content: content,
			certificate: certData.selected,
			callback: function (signObj) {
				if (signObj.data) {
					var signProcess = function () {
						var signature = {
							SIGNATURE: signObj.data
						};
						pushDocument(docId, signature, function (data) {
							if ('function' == typeof callback) {
								callback(data);
							}
						});
					};
					edi.sign.checkCertificate(signProcess, certData.obj);
				} else {
					failure(signObj.error);
				}
			}
		});
	};

	var createLayout = function () {
		var innInput, kppInput, ogrnInput, rcInput, kcInput, bikInput;

		var addressField = createFieldBlock({
			title: 'Юр. адрес:',
			items: [
				createTextField({
					readOnly: true,
					emptyText: edi.i18n.getMessage('-'),
					value: function () {
						var orgFieldsName = ['zip', 'area', 'city', 'locality', 'street', 'building'];
						var address = [];
						orgFieldsName.forEach(function (fieldName) {
							if (edi.utils.getObjectProperty(userOrg.addresses[0], fieldName) != '') {
								address.push(edi.utils.getObjectProperty(userOrg.addresses[0], fieldName));
							}
						});
						return address.join(', ');
					}
				})
			]
		});
		var personeAddressField = createFieldBlock({
			title: 'Физ. адрес:',
			items: [
				createTextField({
					readOnly: true,
					emptyText: edi.i18n.getMessage('-'),
					value: ''
				})
			]
		});
		var innField = createFieldBlock({
			title: 'ИНН:',
			items: [
				(innInput = createTextField({
					readOnly: true,
					emptyText: edi.i18n.getMessage('-'),
					value: ''
				}))
			]
		});
		var kppField = createFieldBlock({
			title: 'КПП:',
			items: [
				(kppInput = createTextField({
					readOnly: true,
					emptyText: edi.i18n.getMessage('-'),
					value: ''
				}))
			]
		});
		var ogrnField = createFieldBlock({
			title: 'ОГРН:',
			items: [
				(ogrnInput = createTextField({
					readOnly: true,
					emptyText: edi.i18n.getMessage('-'),
					value: '',
					inputMask: edi.constants.FIELD_MASK.OGRN
				}))
			]
		});
		var rcField = createFieldBlock({
			title: 'Р/С:',
			items: [
				(rcInput = createTextField({
					readOnly: true,
					emptyText: edi.i18n.getMessage('-'),
					value: ''
				}))
			]
		});
		var kcField = createFieldBlock({
			title: 'К/С:',
			items: [
				(kcInput = createTextField({
					readOnly: true,
					emptyText: edi.i18n.getMessage('-'),
					value: ''
				}))
			]
		});
		var bikField = createFieldBlock({
			title: 'БИК:',
			items: [
				(bikInput = createTextField({
					readOnly: true,
					emptyText: edi.i18n.getMessage('-'),
					value: ''
				}))
			]
		});

		var statementEGRUL,
			constituentDocuments,
			additionsToConstituentDocuments,
			powerOfAttorney,
			decisionElectionSoleExecutiveBody;

		var cancelSendBtn, clearContainerBtn, sendToCheckBtn;

		var twoColumnsLayout = createFormContainer({
			area: [[6, 6]],
			items: [
				createPanel({
					title: edi.utils.getObjectProperty(userOrg, 'name'),
					bodyPadding: '10 20',
					items: [
						createFormContainer({
							items: [
								createContainer({
									html: 'Реквизиты',
									margin: '0 0 30 0'
								}),
								addressField,
								personeAddressField,
								innField,
								kppField,
								ogrnField,
								rcField,
								kcField,
								bikField
							]
						})
					]
				}),
				createPanel({
					title: 'Документы',
					bodyPadding: '10 20',
					items: [
						createFormContainer({
							margin: '0 0 25 0',
							area: [[8, 4]],
							items: [
								createContainer({
									html: "Если документы изменились, обратитесь в <a href='#'>техническую поддержку</a>"
								}),
								(infoCreateDateField = createContainer({}))
							]
						}),
						createFormContainer({
							items: [
								(statementEGRUL = uploadDocumentBlock(
									'Свидетельство о внесении записи в Единый государственный реестр юридических лиц',
									'USTDOKVEGRUL'
								)),
								(constituentDocuments = uploadDocumentBlock('Учредительные документы', 'USTDOKUCHR')),
								(additionsToConstituentDocuments = uploadDocumentBlock(
									'Изменения и/или дополнения в учредительных документах',
									'USTDOKDOP'
								)),
								(powerOfAttorney = uploadDocumentBlock(
									'Доверенность с соответствующими полномочиями такого лица',
									'USTDOKDOV'
								)),
								(decisionElectionSoleExecutiveBody = uploadDocumentBlock(
									'Решение об избрании единоличного исполнительного органа',
									'USTDOKIEIO'
								))
							]
						})
					],
					buttons: [
						(cancelSendBtn = createButton({
							cls: BUTTON_CLS.primary,
							text: 'Отменить подписание',
							hidden: true,
							handler: function () {
								pushDocument(
									containerId,
									{
										CANCEL: true
									},
									function () {}
								);
							}
						})),
						(clearContainerBtn = createButton({
							cls: BUTTON_CLS.primary,
							text: 'Очистить',
							handler: function () {
								if (containerId) {
									console.log(containerId);

									pushDocument(
										containerId,
										{
											DELETE: true
										},
										function () {
											createContainerForDocuments(function (data) {
												containerStore.loadData([
													{
														id: data.id,
														creationDate: data.creationDate
													}
												]);

												changeInfoConteiner(data);
												childrenDocIdList = [];
												edi.events.organization.fireEvent('change');
											});
										}
									);
								}
							}
						})),
						(sendToCheckBtn = createButton({
							cls: BUTTON_CLS.primary,
							text: 'Отправить на проверку',
							handler: function (comp) {
								var requiredFields = [statementEGRUL, constituentDocuments];

								var formValid = true;
								requiredFields.forEach(function (field) {
									if (!field.getFieldValid()) {
										formValid = false;
									}
								});

								if (formValid) {
									edi.sign.selectCertificate(function (selectedCertificate, certObj) {
										pushDocument(containerId, {}, function () {
											var next = function (i) {
												if (childrenDocIdList.length > i) {
													var docId = childrenDocIdList[i];

													getContentFromDocument(docId, function (content) {
														setSignature(
															content,
															{
																selected: selectedCertificate,
																obj: certObj
															},
															docId,
															function (data) {
																next(i + 1);
															}
														);
													});
												} else {
													pushDocument(containerId, {}, function (data) {
														console.log(data);
													});

													clearContainerBtn.setVisible(false);
													sendToCheckBtn.setVisible(false);
													cancelSendBtn.setVisible(true);
												}
											};
											next(0);
										});
									});
								}
							}
						}))
					]
				})
			]
		});

		if (containerStore.getCount()) {
			changeInfoConteiner(containerStore.getAt(0).getData());
		}

		edi.rest.sendRequest(edi.rest.services.USER.ORGANIZATION.GET, 'GET', null, function (responsiveData) {
			if (responsiveData && responsiveData.data) {
				var orgData = responsiveData.data.orgData;

				innInput.setValue(orgData.inn).removeCls('edi-empty-label');
				kppInput.setValue(orgData.kpp).removeCls('edi-empty-label');
				ogrnInput.setValue(orgData.regNr).removeCls('edi-empty-label');
			}
		});

		getLinkedToContainer(function (data) {
			var fieldList = [
				statementEGRUL,
				constituentDocuments,
				additionsToConstituentDocuments,
				powerOfAttorney,
				decisionElectionSoleExecutiveBody
			];

			data.children?.forEach(function (doc) {
				childrenDocIdList.push(doc.id);

				var isDraft = true;
				if (doc.state == 'DRAFT') {
					isDraft = false;
				}
				clearContainerBtn.setVisible(!isDraft);
				sendToCheckBtn.setVisible(!isDraft);
				cancelSendBtn.setVisible(isDraft);

				var subtype = edi.utils.getAttributeByName(doc.attributes, 'subtype');
				var field = fieldList.filter(function (field) {
					return subtype == field.getSubType();
				});
				field[0].setDocId(doc.id);
				field[0].setBtnDisabled(false);
				field[0].setFieldValue(mapDocTypeText[subtype]);
			});
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [twoColumnsLayout]
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createDetailsModulePanel();

		var userData = edi.core.getUserData();
		userOrg = userData.org;

		if (containerStore.getCount()) {
			containerId = containerStore.getAt(0).get('id');
		}

		modulePanel.add(createLayout());
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};

	var changeHandler = function () {
		moduleData.tab.removeAll();
		renderData();
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.organization.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
