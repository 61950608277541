import {
	isPacking,
	isPackingRecord,
	isProduct,
	isProductRecord,
	Packing,
	PackingId,
	Product,
	ProductIdent,
	Total,
	Tree,
	TREE_MODE,
	TreeAction,
	TreeConfig,
	TreeMaps,
	TreeRecord,
	UnconvertedLine
} from './definitions';
import { TREE_COLUMNS_CONFIG_NAME, TREE_TOTAL_COLUMNS_CONFIG_NAME } from './columns';
import { showModalProductForm } from './productModal';
import { showPackingModal } from './packingModal';
import { moveProductLine, showMoveLineDialog, showMoveMultiLineDialog } from './moveModal';
import { recalculateProductValues } from './methods';
import { createTreeGrid } from '@Components/tree.grid';
import { createCheckboxSelectionModel, createGrid, createToolBar, ROW_COLOR_CLS } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { createContainer, createMenuItem } from '@Components/miscComponents';
import { TREE_LINE_MODEL, TREE_SUMMARY_MODEL } from './models';
// @ts-ignore
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
// @ts-ignore
import { createTab, createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
// @ts-ignore
import { createLabel } from '@UIkit/components/fields';
// @ts-ignore
import { createPagingBar } from '@UIkit/components/grid';
import './style.scss';

const PACKING_ID_FIELD_NAME = 'ID-Begin';
const AMOUNT_FIELD_NAME = 'QuantityDespatched';
const LINE_NUMBER_FIELD_NAME = 'LineNumber';
const PRODUCT_AMOUNT_DECIMAL_PRECISION = 3;

/**
 * Retrieve property value from tree record by its key
 */
const get = function <T, V extends keyof T>(rec: TreeRecord<T> | undefined, name: V): T[V] | '' {
	return rec?.data?.data?.[name] ?? '';
};

/**
 * Collects information from total grid
 */
const getTotalValues = function (this: Tree): Total | null {
	let tree = this;
	let totals: Total | null = null;
	let totalStore: ExtComponent = tree.productsTotalGrid.getStore();
	if (totalStore.getCount()) {
		totals = totalStore.getAt(0).getData();
	}
	return totals;
};

/**
 * Calculates values for totals grid
 */
const totalsHandler = function (this: Tree) {
	let tree = this;
	edi.total.process(edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV, tree, null, 'set_rows');
	if ('function' == typeof tree.totalsHandlerCallback) {
		tree.totalsHandlerCallback(tree.getTotalValues());
	}
	tree.productsTotalGrid.getView().refresh();
};

/**
 * Change values before open product modal
 */
const changeValuesBeforeEdit = function (this: Tree, values: Product) {
	if (values.UnitOfMeasure) {
		const okeiStore = edi.stores.initLegacyOkeiStore();
		const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
		const rec = okeiStore.findRecordByName(val);
		if (rec) {
			values.UnitOfMeasure = rec.get('name_international');
		}
	}
};

/**
 * Get tree values
 */
const getValues = function (this: Tree) {
	let tree = this;
	let maps = tree.maps;
	let values = tree.productsTotalGrid.getStore().count()
		? JSON.parse(JSON.stringify(tree.productsTotalGrid.getStore().getAt(0).getData()))
		: {};

	values.products = Object.values(maps.productsByPack).map((product) => {
		let prod = JSON.parse(JSON.stringify(product));
		prod.packingLevel = 0;

		if (prod.packingId && maps.secondLvlPacks[prod.packingId]) {
			prod.packing = JSON.parse(JSON.stringify(maps.secondLvlPacks[prod.packingId]));
			prod.packing.packingLevel = 1;

			if (prod.packing.packingId && maps.thirdLvlPacks[prod.packing.packingId]) {
				prod.packing.packing = JSON.parse(JSON.stringify(maps.thirdLvlPacks[prod.packing.packingId]));
				prod.packing.packing.packingLevel = 2;
				delete prod.packing.packing.children;
				delete prod.packing.packing.packingId;
			}

			delete prod.packing.children;
			delete prod.packing.packingId;
		}

		delete prod.children;
		delete prod.packingId;
		delete prod.secondLvlPackingIds;
		return prod;
	});

	return values;
};

/**
 * Check is data valid
 */
const isValid = function (this: Tree) {
	let tree = this;
	let productsAreOk = tree.maps?.productsByPack
		? Object.values(tree.maps.productsByPack).every((p) => tree.isValidProduct(p))
		: tree.allowBlank;
	let secondLvlPacksAreOk = tree.maps?.secondLvlPacks
		? Object.values(tree.maps.secondLvlPacks).every((p) => tree.isValidPack(p))
		: true;
	let thirdLvlPacksAreOk = tree.maps?.thirdLvlPacks
		? Object.values(tree.maps.thirdLvlPacks).every((p) => tree.isValidPack(p))
		: true;
	return productsAreOk && secondLvlPacksAreOk && thirdLvlPacksAreOk;
};
/**
 * Уменьшаем номера товарных позиций при удалении.
 * Например, есть товары с номерами 1, 2, 3, 4, 5 и при удалении 3-го товара должны быть пересчитаны номера для 4 и 5,
 * где для 4-го новое значение будет 3, а для 5-го - значение 4.
 */
const reduceLineNumbersAfterDelete = function (deletedIndex: number, treeMaps: TreeMaps) {
	const lineNumberReducer = (object: { [key: string]: Product }) => {
		Object.values(object).forEach((product) => {
			if (product.LineNumber > deletedIndex) {
				product.LineNumber--;
			}
		});
	};

	//обновлениe номера на вкладке "Упаковка"
	lineNumberReducer(treeMaps.productsByPack);
	//обновлениe номера на вкладке "Товар"
	lineNumberReducer(treeMaps.productsById);
};

/**
 * Removes product from tree
 */
const removeProduct = function (tree: Tree, productRecord: TreeRecord<Product>) {
	let recData = productRecord.data.data;
	let prodId = recData.productID;
	let packId = recData.packingId;

	//removing the whole product
	if (tree.viewMode === TREE_MODE.PRODUCT) {
		const removedProductLineNumber = tree.maps.productsById[prodId]?.LineNumber;
		Object.keys(tree.maps.productsByPack).forEach((prodIdent) => {
			if (prodIdent.indexOf(prodId) !== -1) {
				delete tree.maps.productsByPack[prodIdent];
			}
		});
		delete tree.maps.productsById[prodId];
		reduceLineNumbersAfterDelete(removedProductLineNumber, tree.maps);
	}
	//removed only part or whole product, need to be checked and recalculate everywhere
	else {
		let removeAmount = +recData[AMOUNT_FIELD_NAME];
		let newTotalAmount = +recData.ProductQuantityDespatched - removeAmount;

		//if there is no one piece of product left - delete all product values from tree
		if (newTotalAmount <= 0) {
			Object.keys(tree.maps.productsByPack)
				.filter((prodIdent) => prodIdent.indexOf(prodId) !== -1)
				.forEach((prodIdent) => delete tree.maps.productsByPack[prodIdent]);
			delete tree.maps.productsById[prodId];
		}
		//some product still stay in tree - update its quantities and packings
		else {
			let prodIdent = getProductIdent({
				productID: prodId,
				packingId: packId
			});
			delete tree.maps.productsByPack[prodIdent];

			let prodInMapById = tree.maps.productsById[prodId];
			let newSecondLvlPackingIds = Ext.clone(prodInMapById.secondLvlPackingIds) || [];
			let packIdIndex = packId ? newSecondLvlPackingIds.indexOf(packId) : -1;
			if (packIdIndex >= 0) {
				newSecondLvlPackingIds.splice(packIdIndex, 1);
			}

			Object.values(tree.maps.productsByPack)
				.filter((product) => product.productID === prodId)
				.forEach((product) => {
					product.ProductQuantityDespatched = String(newTotalAmount);
					product.secondLvlPackingIds = Ext.clone(newSecondLvlPackingIds);
					recalculateProductValues(tree, product);
				});

			prodInMapById[AMOUNT_FIELD_NAME] = String(newTotalAmount);
			prodInMapById.ProductQuantityDespatched = String(newTotalAmount);
			prodInMapById.secondLvlPackingIds = Ext.clone(newSecondLvlPackingIds);
			recalculateProductValues(tree, prodInMapById);
		}
	}
};

/**
 * Removes package from tree
 */
const removePack = function (tree: Tree, packRecord: TreeRecord<Packing>) {
	let packId = get(packRecord, PACKING_ID_FIELD_NAME);
	if (tree.maps.thirdLvlPacks[packId]) {
		Object.values(tree.maps.secondLvlPacks)
			.filter((p) => p.thirdLvlPackingId === packId)
			.forEach((p) => (p.thirdLvlPackingId = undefined));
		delete tree.maps.thirdLvlPacks[packId];
	} else if (tree.maps.secondLvlPacks[packId]) {
		//extract products from pack and remember which need to be updated
		let newSecondLvlPackIdsMap: { [key: Product['productID']]: Product['secondLvlPackingIds'] } = {};
		Object.entries(tree.maps.productsByPack)
			.filter(([ident, _]) => ident.indexOf(packId) !== -1)
			.forEach(([_, prod]) => {
				let prodId = prod.productID;
				let newSecondLvlPackIds = Ext.clone(prod.secondLvlPackingIds) || [];
				let indexForDelete = newSecondLvlPackIds.indexOf(packId);
				newSecondLvlPackIds.splice(indexForDelete, 1);
				newSecondLvlPackIdsMap[prodId] = newSecondLvlPackIds;

				let sameProdWithoutPackIdent = getProductIdent({
					productID: prodId,
					packingId: undefined
				});
				let sameProdWithoutPack = tree.maps.productsByPack[sameProdWithoutPackIdent];
				if (sameProdWithoutPack) {
					const sumSameProd =
						Number(sameProdWithoutPack[AMOUNT_FIELD_NAME]) + Number(prod[AMOUNT_FIELD_NAME]);
					sameProdWithoutPack[AMOUNT_FIELD_NAME] = String(sumSameProd);
					recalculateProductValues(tree, sameProdWithoutPack);
				} else {
					let newProd: Product = Ext.clone(prod);
					tree.maps.productsByPack[sameProdWithoutPackIdent] = newProd;
					recalculateProductValues(tree, newProd);
				}
			});

		//remove "old products in pack"
		Object.keys(tree.maps.productsByPack)
			.filter((ident) => ident.indexOf(packId) !== -1)
			.forEach((ident) => delete tree.maps.productsByPack[ident]);
		//update secondLvlPackingIds
		Object.values(tree.maps.productsById).forEach((prod) => {
			if (newSecondLvlPackIdsMap[prod.productID]) {
				prod.secondLvlPackingIds = Ext.clone(newSecondLvlPackIdsMap[prod.productID]);
			}
		});
		Object.values(tree.maps.productsByPack).forEach((prod) => {
			if (newSecondLvlPackIdsMap[prod.productID]) {
				prod.secondLvlPackingIds = Ext.clone(newSecondLvlPackIdsMap[prod.productID]);
			}
		});
		//remove pack
		delete tree.maps.secondLvlPacks[packId];

		//if parent loose it's last child, then it must be moved from 3d lvl to 2nd
		let parentPackId = get(packRecord, 'thirdLvlPackingId');
		if (parentPackId) {
			let packsInParent = Object.values(tree.maps.secondLvlPacks).filter(
				(pack) => pack.thirdLvlPackingId === parentPackId
			);
			if (packsInParent.length === 0) {
				tree.maps.secondLvlPacks[parentPackId] = tree.maps.thirdLvlPacks[parentPackId];
				tree.maps.secondLvlPacks[parentPackId].thirdLvlPackingId = undefined;
				tree.maps.secondLvlPacks[parentPackId].packingId = undefined;
				delete tree.maps.thirdLvlPacks[parentPackId];
			}
		}
	}
	tree.updateToolsButtons();
};

/**
 * Get lines amount
 * @returns {number}
 */
const getLinesAmount = function (this: Tree) {
	let tree = this;
	return tree.maps && tree.maps.productsById ? Object.values(tree.maps.productsById).length : 0;
};

/**
 * Update "add new product/pack" and "move" buttons availability
 */
const updateToolsButtons = function (this: Tree) {
	let tree = this;

	let isProductsGrid = tree.viewMode === TREE_MODE.PRODUCT;
	if (tree.addNewProductBtn && tree.lockIfNoPartner) {
		tree.addNewProductBtn.setDisabled(!tree.partnerId || !isProductsGrid);
	}
	if (tree.addNewPackBtn && tree.lockIfNoPartner) {
		tree.addNewPackBtn.setDisabled(!tree.partnerId || isProductsGrid);
	}

	if (tree.moveSelectedBtn) {
		let allowMultipleMove =
			!isProductsGrid &&
			tree.getSelectionModel().selected.items.length &&
			Object.values(tree.maps.secondLvlPacks).length;
		tree.moveSelectedBtn.setDisabled(!allowMultipleMove);
	}
};

/**
 * set partner id that will be used for getting prodcat
 */
const setPartnerId = function (this: Tree, partnerId: Tree['partnerId']) {
	let tree = this;
	tree.partnerId = partnerId;
	tree.updateToolsButtons();
};

/**
 * Switch between product and packing views
 */
const changeTreeViewMode = function (tree: Tree, mode?: TREE_MODE) {
	let newViewMode = mode || TREE_MODE.PRODUCT;
	if (tree.viewMode !== newViewMode) {
		tree.viewMode = newViewMode;
		tree.rebuildTree();
	}

	tree.updateToolsButtons();
};

/**
 * Remove children from every element
 */
const clearTreeStructure = function (maps: TreeMaps) {
	Object.values(maps.productsByPack).forEach((product) => delete product.children);
	Object.values(maps.productsById).forEach((product) => delete product.children);
	Object.values(maps.secondLvlPacks).forEach((secondLvlPack) => delete secondLvlPack.children);
	Object.values(maps.thirdLvlPacks).forEach((thirdLvlPack) => delete thirdLvlPack.children);
};

/**
 * Rebuild tree from existing maps
 */
const rebuildTree = function (this: Tree) {
	let tree = this;
	tree.getRootNode().removeAll();

	createTreeMaps(tree);
	clearTreeStructure(tree.maps);

	let treeData = tree.viewMode === TREE_MODE.PRODUCT ? buildProductsTree(tree.maps) : buildPackingTree(tree.maps);

	tree.loadData(treeData, true);
	tree.totalsHandler();
	tree.getView().refresh();
	tree.getStore().sort();
	tree.updateToolsButtons();
};

/**
 * Return product identifier in package
 */
const getProductIdent = function (row: { productID: Product['productID']; packingId?: PackingId }): ProductIdent {
	return `${row.packingId}_${row.productID}`;
};

/**
 * Build products tree structure
 */
const buildProductsTree = function (maps: TreeMaps): (Product | Packing)[] {
	let treeData: (Product | Packing)[] = [];
	let notEmptySecondLevelPacks: { [key: PackingId]: Packing } = {};
	//add products with theirs packs
	Object.entries(maps.productsById).forEach(([_, product]) => {
		treeData.push(product);
		if (Array.isArray(product.secondLvlPackingIds)) {
			product.children = product.secondLvlPackingIds
				.filter((id) => !!maps.secondLvlPacks[id])
				.map((id) => {
					notEmptySecondLevelPacks[id] = maps.secondLvlPacks[id];
					let secondLvlPackData = maps.secondLvlPacks[id];
					if (
						secondLvlPackData.thirdLvlPackingId &&
						maps.thirdLvlPacks[secondLvlPackData.thirdLvlPackingId]
					) {
						secondLvlPackData.children = [maps.thirdLvlPacks[secondLvlPackData.thirdLvlPackingId]];
					}
					return secondLvlPackData;
				});
			//In previously saved documents products could be stored on second
			// and third level packages at the same time. In this case we have id of "second"
			// level pack but in real this is a third level.
			//Nowadays, this behavior is prohibited, so we fall back to place product in the root
			// as like as without package
			if (!product.children.length) {
				product.packingId = undefined;
				product.secondLvlPackingIds = [];
			}
		}
	});
	//add packs without products inside
	treeData = treeData.concat(
		Object.entries(maps.secondLvlPacks)
			.filter(([secondLvlPackId, _]) => !notEmptySecondLevelPacks[secondLvlPackId])
			.map(([_, secondLvlPackData]) => {
				if (secondLvlPackData.thirdLvlPackingId && maps.thirdLvlPacks[secondLvlPackData.thirdLvlPackingId]) {
					secondLvlPackData.children = [maps.thirdLvlPacks[secondLvlPackData.thirdLvlPackingId]];
				}
				return secondLvlPackData;
			})
	);

	return treeData;
};

/**
 * Build packings tree structure
 */
const buildPackingTree = function (maps: TreeMaps): (Product | Packing)[] {
	let treeData: (Product | Packing)[] = [];
	let usedThirdLvlPacks: { [key: PackingId]: Packing } = {};
	let usedSecondLvlPacks: { [key: PackingId]: Packing } = {};
	let usedProducts: { [key: ProductIdent]: Product } = {};

	//add all 3rd level packs with their children(2nd level packs)
	Object.entries(maps.secondLvlPacks).forEach(([secondLvlPackId, secondLvlPackData]) => {
		let thirdLvlPackId = secondLvlPackData.thirdLvlPackingId;
		if (!thirdLvlPackId) {
			return;
		}
		let thirdLvlPackData = maps.thirdLvlPacks[thirdLvlPackId];
		if (!thirdLvlPackData) {
			return;
		}
		thirdLvlPackData.children = (thirdLvlPackData.children || []).concat(secondLvlPackData);
		usedSecondLvlPacks[secondLvlPackId] = secondLvlPackData;
		if (!usedThirdLvlPacks[thirdLvlPackId]) {
			treeData.push(thirdLvlPackData);
			usedThirdLvlPacks[thirdLvlPackId] = thirdLvlPackData;
		}
	});

	//add products to their packages (2nd level)
	Object.entries(maps.productsByPack).forEach(([_, productData]) => {
		let packingId = productData?.packingId ?? '';
		let secondLvlPack = maps.secondLvlPacks[packingId];
		if (secondLvlPack) {
			secondLvlPack.children = (secondLvlPack.children || []).concat(productData);
		}
		//In previously saved documents products could be stored on second
		// and third level packages at the same time. In this case we have id of "second"
		// level pack but in real this is a third level.
		//Nowadays this behavior is prohibited and we fallback to place product in the root
		// as like as without package
		else {
			productData.packingId = undefined;
			productData.secondLvlPackingIds = [];
			treeData.push(productData);
		}
		usedProducts[getProductIdent(productData)] = productData;
	});

	//add not added 2nd level packages
	Object.entries(maps.secondLvlPacks)
		.filter(([secondLvlPackId, _]) => !usedSecondLvlPacks[secondLvlPackId])
		.forEach(([_, secondLvlPackData]) => {
			treeData.push(secondLvlPackData);
		});

	//add not added products
	Object.entries(maps.productsByPack)
		.filter(([productIdent, _]) => !usedProducts[productIdent])
		.forEach(([_, productData]) => {
			treeData.push(productData);
		});

	return treeData;
};

/**
 * Creates empty tree maps
 */
const createTreeMaps = function (tree: Tree) {
	if (!tree.maps?.productsById) {
		tree.maps = {
			productsByPack: {}, //product data splitted by packages (despatched quantity)
			productsById: {}, //product full data (without splitting by packages)
			secondLvlPacks: {},
			thirdLvlPacks: {}
		};
	}
};

/**
 * Prepare hashMaps for each packing level for fast tree building
 */
const createMapsFromProducts = function (tree: Tree, productValues: UnconvertedLine[]) {
	createTreeMaps(tree);

	let putToMap = function (row: UnconvertedLine) {
		if (isProduct(row)) {
			tree.maps.productsByPack[getProductIdent(row)] = row;
			let prodInMapByPack = tree.maps.productsByPack[getProductIdent(row)];

			let rowData = Ext.clone(row);
			let prodId = rowData.productID;
			let prodInMapById = tree.maps.productsById[prodId];
			if (!prodInMapById) {
				prodInMapById = rowData;
				tree.maps.productsById[prodId] = prodInMapById;
				prodInMapById[AMOUNT_FIELD_NAME] = prodInMapById.ProductQuantityDespatched;
				prodInMapById.NetAmount = prodInMapById.ProductNetAmount;
				prodInMapById.TaxAmount = prodInMapById.ProductTaxAmount;
				prodInMapById.GrossAmount = prodInMapById.ProductGrossAmount;
				delete prodInMapById.packingId;
			}

			if (!Array.isArray(prodInMapById.secondLvlPackingIds) || !prodInMapById.secondLvlPackingIds.length) {
				prodInMapById.secondLvlPackingIds = rowData.secondLvlPackingIds || [];
			}
			if (!Array.isArray(prodInMapByPack.secondLvlPackingIds) || !prodInMapByPack.secondLvlPackingIds.length) {
				prodInMapByPack.secondLvlPackingIds = (prodInMapById && prodInMapById.secondLvlPackingIds) || [];
			}
		} else if (isPacking(row)) {
			let hasChildPack = (row.childItems || []).some((item) => !isProduct(item));
			if (hasChildPack) {
				tree.maps.thirdLvlPacks[row[PACKING_ID_FIELD_NAME]] = row;
			} else {
				tree.maps.secondLvlPacks[row[PACKING_ID_FIELD_NAME]] = row;
			}
		}

		if (Array.isArray(row.childItems)) {
			row.childItems.forEach((child) => putToMap(child));
			delete row.childItems;
		}
	};

	(productValues || []).forEach((row) => putToMap(row));
};

/**
 * Load raw products array into "level maps"
 */
const loadProducts = function (this: Tree, productValues: UnconvertedLine[]) {
	let tree = this;
	createMapsFromProducts(tree, productValues);
	tree.rebuildTree();
};

/**
 * Load values into totals grid
 */
const loadTotalValues = function (this: Tree, totalValues?: Total[]) {
	let tree = this;
	let totalsGrid = tree.productsTotalGrid;
	if (!totalsGrid || !Array.isArray(totalValues)) {
		return;
	}
	totalsGrid.getStore().loadData(totalValues, false);
	totalsGrid.getView().refresh();
};

/**
 * Creates row actions buttons
 */
const createRowActionsColumn = function (config?: { readOnly?: boolean; allowExportPackings?: boolean }): AnyObject[] {
	let rowActions: TreeAction[];
	if (!config?.readOnly) {
		rowActions = [
			{
				glyph: edi.constants.ICONS.IMPORT_EXPORT,
				testCls: 'test-action-column-export',
				tooltip: edi.i18n.getMessage('desadv.product.move.to.packing'),
				handler(treeView, rIndex, cIndex, item, e, record) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					if (tree) showMoveLineDialog(tree, record);
				},
				isHidden(treeView, rowIndex, colIndex, item, record) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					let packId = isPackingRecord(record) && get(record, PACKING_ID_FIELD_NAME);
					if (!tree) return false;

					let notEmptySecondLvlPackIds: { [key: PackingId]: PackingId } = {};
					Object.values(tree.maps.productsByPack).forEach((prod) => {
						if (prod.packingId) {
							notEmptySecondLvlPackIds[prod.packingId] = prod.packingId;
						}
					});
					let isEmptySecondLvlPack =
						packId && tree.maps.secondLvlPacks[packId] && !notEmptySecondLvlPackIds[packId];

					let isThirdLvlPack = packId && tree.maps.thirdLvlPacks[packId];

					return tree.viewMode === TREE_MODE.PRODUCT || !!isEmptySecondLvlPack || !!isThirdLvlPack;
				}
			},
			{
				glyph: edi.constants.ICONS.EDIT,
				testCls: 'test-action-column-edit',
				tooltip: edi.i18n.getMessage('form.btn.edit'),
				handler(treeView, rIndex, cIndex, item, e, record) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					if (!tree) return;

					if (isProductRecord(record)) {
						let productInMap = tree.maps.productsById[get(record, 'productID')];
						let packIds = (productInMap && productInMap.secondLvlPackingIds) || [];
						if (packIds.length > 0) {
							edi.core.showInfo(edi.i18n.getMessage('documents.desadv.edit.product.prohibited'));
							return;
						}
						showModalProductForm(tree, record, undefined, {
							isDetails: false,
							isEdit: true,
							isCreate: false
						});
					} else if (isPackingRecord(record)) {
						showPackingModal(tree, record, {
							isDetails: false,
							isEdit: true,
							isCreate: false
						});
					}
				},
				isHidden(treeView, rowIndex, colIndex, item, record) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					let isProduct = isProductRecord(record);
					let allowEdit =
						(tree?.viewMode === TREE_MODE.PACKING && !isProduct) ||
						(tree?.viewMode === TREE_MODE.PRODUCT && isProduct);
					return !allowEdit;
				}
			},
			{
				glyph: edi.constants.ICONS.ADD,
				testCls: 'test-action-column-add',
				tooltip: edi.i18n.getMessage('form.btn.add.product'),
				handler(treeView, rIndex, cIndex, item, e, record) {
					const tree = treeView.up<Tree>('edi-tree-grid');
					if (!tree) return;
					isPackingRecord(record) &&
						showModalProductForm(tree, undefined, record, {
							isDetails: false,
							isEdit: false,
							isCreate: true
						});
				},
				isHidden(treeView, rowIndex, colIndex, item, record) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					let packId = isPackingRecord(record) && get(record, PACKING_ID_FIELD_NAME);
					let isSecondLvlPack = !!packId && !!tree?.maps.secondLvlPacks[packId];
					let allowAddBtn = tree?.viewMode === TREE_MODE.PRODUCT && isSecondLvlPack;
					return !allowAddBtn;
				}
			},
			{
				glyph: edi.constants.ICONS.REMOVE,
				testCls: 'test-action-column-remove',
				handler(treeView, rIndex, cIndex, item, e, record) {
					edi.core.confirm(null, 'product.line.remove', function () {
						const tree = treeView.up<Tree>('edi-tree-grid');
						if (!tree) return;
						if (isProductRecord(record)) {
							if (tree.viewMode === TREE_MODE.PRODUCT) {
								removeProduct(tree, record);
							} else if (tree.viewMode === TREE_MODE.PACKING) {
								let amountToMove = +get(record, AMOUNT_FIELD_NAME) || 0;
								moveProductLine(tree, record.data.data, undefined, amountToMove);
							}
						} else if (isPackingRecord(record)) {
							removePack(tree, record);
						}

						tree.rebuildTree();

						if (typeof tree.callback === 'function') {
							tree.callback();
						}
						if (typeof tree.totalsHandler === 'function') {
							tree.totalsHandler();
						}
						tree.getStore().sort();
					});
				},
				isHidden(treeView, rowIndex, colIndex, item, record) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					let isProduct = isProductRecord(record);
					let isPack = !isProduct;
					let isPackView = tree?.viewMode === TREE_MODE.PACKING;
					let isProductView = tree?.viewMode === TREE_MODE.PRODUCT;
					let allowRemoveBtn =
						(isProductView && isProduct) ||
						(isPackView && isProduct && !!get(record, 'packingId')) ||
						(isPackView && isPack);

					return !allowRemoveBtn;
				},
				getTip(value, metadata, record, rIndex, cIndex, store, treeView) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					let isPack = !isProductRecord(record);
					return isPack
						? edi.i18n.getMessage('documents.desadv.remove.packing')
						: tree?.viewMode === TREE_MODE.PRODUCT
						? edi.i18n.getMessage('documents.desadv.remove.product')
						: edi.i18n.getMessage('documents.desadv.extract.product.from.pack');
				}
			}
		];
	} else {
		rowActions = [
			{
				glyph: edi.constants.ICONS.DETAILS,
				testCls: 'test-action-column-details',
				tooltip: edi.i18n.getMessage('form.btn.details'),
				handler(treeView, rIndex, cIndex, item, e, record) {
					const tree = treeView.up<Tree>('edi-tree-grid');
					if (!tree) return;
					if (isProductRecord(record)) {
						showModalProductForm(tree, record);
					} else if (isPackingRecord(record)) {
						showPackingModal(tree, record);
					}
				}
			}
		];

		if (edi.constants.ALLOW_EXPORT_DOCUMENTS && config?.allowExportPackings) {
			rowActions.push({
				glyph: edi.constants.ICONS.PRINT,
				testCls: 'test-action-column-print',
				handler(treeView, rIndex, cIndex, item, e, record: TreeRecord<Product | Packing>) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					let packingList = tree?.packingList;
					let packingId = isPackingRecord(record) && get(record, PACKING_ID_FIELD_NAME);
					if (!packingId || !packingList || !packingList.length) {
						return;
					}
					let data = [];
					for (let i = 0; i < packingList.length; i++) {
						if (packingList[i].number === packingId) {
							data.push(packingList[i].id);
							break;
						}
					}
					edi.document.actions.exportReportsGroup(data);
				},
				isHidden(treeView, rowIndex, colIndex, item, rec: TreeRecord<Product | Packing>) {
					let tree = treeView.up<Tree>('edi-tree-grid');
					let isPallete = isPackingRecord(rec) && get(rec, 'Type') === edi.constants.PACKAGE_TYPES.PALLET;
					let isSecondLvlPack =
						isPackingRecord(rec) && !!tree?.maps.secondLvlPacks[get(rec, PACKING_ID_FIELD_NAME)];
					return !(isPallete && isSecondLvlPack);
				}
			});
		}
	}

	return rowActions;
};

/**
 * Returns modal window fields
 */
const getFormFields = function (modal: ExtComponent): AnyObject {
	return Object.assign(edi.utils.getFormFields(modal.formPanel), modal.formGrids);
};

/**
 * Creates product tree with packs
 */
const createProductsTree = function (config?: TreeConfig): Tree {
	let conf = Ext.merge(
		{
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			totalsGridHeaderLabel: 'grid.title.total',
			showSettingsButton: true,
			viewMode: TREE_MODE.PRODUCT,
			gridModel: TREE_LINE_MODEL,
			gridColumnsConfig: TREE_COLUMNS_CONFIG_NAME,
			totalModel: TREE_SUMMARY_MODEL,
			totalColumnConfig: TREE_TOTAL_COLUMNS_CONFIG_NAME,
			lockIfNoPartner: false,
			disableColoringRows: false,
			productValues: [],
			allowBlankEditableGrids: [],
			isFocusOnInvalidField: true,
			modalFormConfig: {},
			loadProducts,
			loadTotalValues,
			setPartnerId,
			updateToolsButtons,
			getLinesAmount,
			isValid,
			isValidProduct: () => true,
			isValidPack: () => true,
			getValues,
			rebuildTree,
			changeValuesBeforeEdit,
			totalsHandler,
			totalsHandlerCallback: () => {},
			getTotalValues,
			viewConfig: {
				layout: 'fit',
				maxHeight: edi.constants.DEFAULT.FORM_GRID.SCROLLABLE_DESADV,
				scrollable: {
					x: 'auto',
					y: 'auto'
				},
				rowLines: true,
				emptyText: edi.i18n.getMessage('grid.empty')
			}
		},
		config
	);
	conf.gridModelConfig = conf.gridModel;

	conf.cls = (conf.cls || '') + ' edi-product-grid-container with-row-lines';
	if (!conf.allowBlank && !conf.readOnly) {
		conf.cls += '  edi-grid-mark-empty-red';
		conf.viewConfig.emptyText = edi.i18n.getMessage('grid.empty.mandatory');
	}

	if (conf.readOnly !== true) {
		conf.selModel = createCheckboxSelectionModel({
			showHeaderCheckbox: false,
			renderer(value: string, metaData: AnyObject, record: TreeRecord<Product | Packing>) {
				if (!tree || tree.viewMode !== TREE_MODE.PACKING) {
					return '';
				}
				let isProduct = isProductRecord(record);
				if (!isProduct || get(record, 'packingId')) {
					return '';
				}
				let baseCSSPrefix = Ext.baseCSSPrefix;
				metaData.tdCls = baseCSSPrefix + 'grid-cell-special ' + baseCSSPrefix + 'grid-cell-row-checker';
				return '<span class="x-grid-checkcolumn" role="presentation" aria-label="undefined"></span>';
			},
			listeners: {
				beforeselect: function (selectionModel: AnyObject, record: TreeRecord<Product | Packing>) {
					//Если мы возвращаем пустой html в renderer, т.е. чекбокс не отображается, то необходимо запретить выбор этой строки
					if (
						!tree ||
						tree.viewMode !== TREE_MODE.PACKING ||
						!isProductRecord(record) ||
						get(record, 'packingId')
					) {
						return false;
					}
				},
				selectionchange: () => tree.updateToolsButtons()
			}
		});
	}

	if (!conf.disableColoringRows) {
		Ext.merge(conf, {
			viewConfig: {
				getRowClass(record: TreeRecord<Product | Packing>) {
					let colorCell = ROW_COLOR_CLS.valid;

					if (!isProductRecord(record)) {
						if (tree.viewMode === TREE_MODE.PACKING) {
							//упаковка не может быть пустой
							let isValidPack = Array.isArray(record.childNodes) && !!record.childNodes.length;
							if (!isValidPack) {
								colorCell = ROW_COLOR_CLS.error;
							}
						}
					} else {
						let isNullQuantity = !get(record, AMOUNT_FIELD_NAME);
						let isValidProduct = tree.isValidProduct(record.data.data);
						if (tree.viewMode === TREE_MODE.PACKING) {
							let isProductWithoutPack = !get(record, 'packingId');
							if (!isValidProduct) {
								colorCell = ROW_COLOR_CLS.error;
							} else if (isNullQuantity) {
								colorCell = 'pay-attention-cell';
							} else if (isProductWithoutPack) {
								colorCell = ROW_COLOR_CLS.warning;
							}
						} else {
							if (!isValidProduct) {
								colorCell = ROW_COLOR_CLS.error;
							} else if (isNullQuantity) {
								colorCell = 'pay-attention-cell';
							}
						}
					}

					return colorCell;
				}
			}
		});
	}

	conf.rowActions = createRowActionsColumn(conf);

	if (!conf.listeners) {
		conf.listeners = {};
	}
	Ext.merge(conf.listeners, {
		render() {
			changeTreeViewMode(tree, tree.viewMode);
		}
	});

	let tree: Tree = createTreeGrid(conf) as Tree;

	if (conf.readOnly !== true) {
		tree.addNewProductBtn = createMenuItem({
			disabled: !conf.partnerId && !!conf.lockIfNoPartner,
			text: edi.i18n.getMessage('desadv.add.product'),
			handler() {
				showModalProductForm(tree, undefined, undefined, {
					isDetails: false,
					isEdit: false,
					isCreate: true
				});
			}
		}) as ExtComponent;
		tree.addNewPackBtn = createMenuItem({
			disabled: !conf.partnerId && !!conf.lockIfNoPartner,
			text: edi.i18n.getMessage('form.btn.add.pallet'),
			handler() {
				showPackingModal(tree, undefined, {
					isDetails: false,
					isEdit: false,
					isCreate: true,
					afterCreateCallback: () => tree.updateToolsButtons()
				});
			}
		}) as ExtComponent;
		tree.addNewItemBtn = createButton({
			cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
			glyph: edi.constants.ICONS.PLUS,
			text: edi.i18n.getMessage('uikit.action.add'),
			menu: [tree.addNewProductBtn, tree.addNewPackBtn]
		}) as ExtComponent;
		tree.moveSelectedBtn = createButton({
			cls: BUTTON_CLS.light,
			glyph: edi.constants.ICONS.IMPORT_EXPORT,
			text: edi.i18n.getMessage('desadv.product.move.to.packing'),
			disabled: true,
			handler() {
				showMoveMultiLineDialog(tree, tree.getSelectionModel().selected.items);
			}
		}) as ExtComponent;
	}

	tree.titleContainer = createContainer({
		layout: {
			type: 'hbox',
			align: 'stretch'
		},
		items: [
			createLabel({
				style: {
					alignItems: 'center'
				},
				text: edi.i18n.getMessage('document.des.adv.despatch.advice.items'),
				typography: 'heading_01'
			}),
			{ xtype: 'tbspacer', flex: 1 },
			tree.moveSelectedBtn
		]
	}) as ExtComponent;

	tree.modesTabPanel = createTabPanel({
		cls: [TAB_PANEL_CLS.simpleWithoutPadding, 'desadv-tree-tabpanel'],
		margin: '8 0',
		items: [
			createTab({
				title: edi.i18n.getMessage('form.btn.product'),
				itemId: TREE_MODE.PRODUCT,
				closable: false
			}),
			createTab({
				title: edi.i18n.getMessage('tab.title.packaging'),
				itemId: TREE_MODE.PACKING,
				closable: false
			})
		],
		listeners: {
			tabchange: function (cmp: ExtComponent, newCard: ExtComponent) {
				changeTreeViewMode(tree, newCard.itemId === TREE_MODE.PRODUCT ? TREE_MODE.PRODUCT : TREE_MODE.PACKING);
			}
		}
	}) as ExtComponent;

	if (!tree.readOnly) {
		tree.addDocked(
			createToolBar({
				cls: 'desadv-tree-actions-toolbar',
				padding: '0 0 0 4',
				dock: 'bottom',
				items: [tree.addNewItemBtn]
			})
		);
	} else {
		if (conf.pagingBarConfig?.items) {
			tree.addDocked(
				createPagingBar({
					hidePagingButtons: true,
					allowExport: false,
					isHideRefreshBtn: true,
					items: conf.pagingBarConfig.items
				})
			);
		}
	}

	tree.productsGrid = tree;

	tree.productsTotalGrid = createGrid({
		storeConfig: {
			proxy: createProxyConfig({
				type: 'memory'
			}),
			autoLoad: false,
			model: edi.models.getModel(conf.totalModel),
			remoteSort: false
		},
		gridConfig: {
			margin: '24 0 0 0',
			title: conf.totalsGridHeaderLabel ? edi.i18n.getMessage(conf.totalsGridHeaderLabel) : undefined,
			columns: edi.columns.get(conf.totalColumnConfig),
			listeners: {},
			viewConfig: {
				emptyText: ''
			},
			disablePaging: true,
			disableSelection: true,
			enableColumnMove: false,
			enableColumnResize: false,
			sortableColumns: false,
			plugins: conf.readOnly
				? undefined
				: [
						Ext.create('Ext.grid.plugin.CellEditing', {
							clicksToEdit: 1
						})
				  ]
		}
	}) as ExtComponent;

	tree.wrapper = createContainer({
		userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
		layout: {
			type: 'grid',
			gap: 0,
			area: [12, 12, 12, [6, 6]]
		},
		items: [
			tree.titleContainer,
			tree.modesTabPanel,
			tree.productsGrid,
			{ xtype: 'tbspacer' },
			tree.productsTotalGrid
		]
	}) as ExtComponent;

	return tree;
};

export {
	createProductsTree,
	getFormFields,
	get,
	getProductIdent,
	changeTreeViewMode,
	PACKING_ID_FIELD_NAME,
	AMOUNT_FIELD_NAME,
	LINE_NUMBER_FIELD_NAME,
	PRODUCT_AMOUNT_DECIMAL_PRECISION
};
