const INVRPT_LINE_COLUMN = 'inventory_report_lines';

edi.columns.addColumns({
	[INVRPT_LINE_COLUMN]: {
		lineNumber: {
			text: 'nr',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		itemDescription: {
			text: 'fhp.reports.line.itemDescription',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 1
		},
		buyerItemCode: {
			text: 'fhp.reports.line.plu.code',
			dataIndex: 'BuyerItemCode',
			flex: 1
		},
		itemGroupCode: {
			text: 'fhp.reports.line.fin.code',
			dataIndex: 'ItemGroupCode',
			flex: 1
		},
		itemGroup: {
			text: 'fhp.reports.line.item.group',
			dataIndex: 'ItemGroup',
			flex: 1
		},
		actualStock: {
			text: 'fhp.reports.line.actual.stock',
			dataIndex: 'ActualStock',
			flex: 1
		},
		transitQuantity: {
			text: 'fhp.reports.line.transit.quantity',
			dataIndex: 'TransitQuantity',
			flex: 1
		},
		writtenOffQuantity: {
			text: 'fhp.reports.line.written.off.quantity',
			dataIndex: 'Written-offQuantity',
			flex: 1
		},
		unitOfMeasure: {
			text: 'fhp.reports.line.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1
		}
	}
});

export { INVRPT_LINE_COLUMN };
