const ORDRSP_LINE_COLUMN = 'ordrsp_lines';
const ORDRSP_SUMMARY_COLUMN = 'ordrsp_summary';
const ORDRSP_APERAK_LINE_COLUMN = 'ordrsp_aperak_lines';
const ORDRSP_SUMMARY_EDITABLE_COLUMN = 'ordrsp_summary_editable';

edi.columns.addColumns({
	[ORDRSP_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 1
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 2
		},
		itemDescription: {
			text: 'line.item.product.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 4
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		quantityOrdered: {
			text: 'line.item.ordered.rsp.quantity',
			dataIndex: 'OrderedQuantity',
			flex: 1,
			order: 6,
			renderer: 'decimal'
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'column.ordered.unit.net.price',
			dataIndex: 'OrderedUnitNetPrice',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 1,
			hidden: false
		},
		unitGrossPrice: {
			text: 'column.ordered.unit.gross.price',
			dataIndex: 'OrderedUnitGrossPrice',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		unitPacksize: {
			text: 'column.ordered.unit.pack.size',
			dataIndex: 'OrderedUnitPacksize',
			flex: 1,
			hidden: true,
			renderer: 'decimal'
		},
		netAmount: {
			text: 'column.ordered.unit.net.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'NetAmount',
			flex: 1
		},
		taxAmount: {
			text: 'column.ordered.tax.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'TaxAmount',
			flex: 1
		},
		grossAmount: {
			text: 'column.gross.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'GrossAmount',
			flex: 1
		},
		allocatedDelivered: {
			text: 'column.ordered.allocatedDelivered',
			dataIndex: 'AllocatedDelivered',
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'numberfield',
				validator: 'validators.amount10WithZero', // used 10 instead of 12 to avoid convertation problems
				decimalPrecision: 3,
				allowDecimals: true,
				minValue: 0
			},
			renderer: 'decimalWithNull'
		}
	},
	[ORDRSP_SUMMARY_COLUMN]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'TotalLines',
			flex: 1,
			sortable: false,
			hideable: false,
			menuDisabled: true
		},
		totalAmount: {
			text: 'column.total.amount',
			dataIndex: 'TotalAmount',
			menuDisabled: true,
			order: 2,
			flex: 1,
			renderer: 'decimal'
		},
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		}
	},
	[ORDRSP_APERAK_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 1
		},
		itemDescription: {
			text: 'line.item.product.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			dataIndex: 'ItemDescription',
			flex: 2
		},
		ExpectedDeliveryDate: {
			text: 'delivery.delivery.date',
			dataIndex: 'DeliveryDateDate',
			flex: 1,
			renderer: function (value) {
				if (value) {
					value = edi.utils.formatDate(value, edi.constants.DATE_FORMAT.FNS);
				}
				return value || '';
			}
		},
		ExpectedDeliveryTime: {
			text: 'time.of.delivery',
			dataIndex: 'DeliveryDateTime',
			flex: 1,
			renderer: 'docTime'
		},
		DeliveryPeriod: {
			text: 'period.of.delivery',
			dataIndex: 'DeliveryPeriodFrom',
			renderer: function (_value, _meta, record) {
				const start = record.get('DeliveryPeriodFrom');
				const end = record.get('DeliveryPeriodTo');
				return start && end ? `${start} - ${end}` : start ? start : '';
			},
			flex: 1
		},
		TermsOfDelivery: {
			text: 'delivery.terms',
			dataIndex: 'TermsOfDelivery',
			flex: 1,
			renderer: function (value) {
				return value ? edi.i18n.getMessage(`terms.of.delivery.${value}`) : '';
			}
		}
	}
});

edi.columns.addColumns({
	[ORDRSP_SUMMARY_EDITABLE_COLUMN]: {
		parents: ORDRSP_SUMMARY_COLUMN,
		config: {
			totalNetAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalTaxAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalGrossAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			}
		}
	}
});

export { ORDRSP_LINE_COLUMN, ORDRSP_SUMMARY_COLUMN, ORDRSP_APERAK_LINE_COLUMN, ORDRSP_SUMMARY_EDITABLE_COLUMN };
