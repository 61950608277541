import { createModulePanel } from '@Components/panels';

Ext.namespace('edi.modules');
edi.modules['external_link'] = function () {
	var moduleData;

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		linkProcess(initCallBack);
		return onDestroy;
	};
	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var linkProcess = function (initCallBack) {
		var modulePanel = createModulePanel();
		moduleData.tab.add(modulePanel);
		var urlParams = edi.utils.getURLParams();
		var docid = urlParams.docid;
		var objId = urlParams.objectid;
		if (docid || objId) {
			var url = docid
				? edi.document.actions.formatHeaderUri({ id: docid })
				: edi.utils.formatString(
						edi.rest.services.OBJECT.HEADER.GET,
						{
							headerId: objId
						},
						true
				  );
			moduleData.tab.setLoading();
			var finish = function () {
				edi.modulesHandler.removeModule(moduleData);
			};
			var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				finish();
			});
			edi.rest.sendRequest(
				url,
				'GET',
				undefined,
				function (resp) {
					if (!resp || !resp.data) {
						failure(resp);
						return;
					}

					var docHeader = resp.data;
					var docType = objId ? docHeader.header.type : docHeader.type;
					var moduleHandler = function (type, headerData) {
						edi.document.actions.openDetailsModule(type, headerData);
					};

					if (
						edi.methods &&
						edi.methods.externalLinkHandler &&
						typeof edi.methods.externalLinkHandler.default === 'function'
					) {
						moduleHandler = edi.methods.externalLinkHandler.default;
					}

					if (
						edi.methods &&
						edi.methods.externalLinkHandler &&
						typeof edi.methods.externalLinkHandler[docType] === 'function'
					) {
						moduleHandler = edi.methods.externalLinkHandler[docType];
					}

					moduleHandler(docType, docHeader);
				},
				failure,
				finish
			);
		}
		if (typeof initCallBack === 'function') {
			initCallBack();
		}
	};
	/**
	 * Routine that must be done before module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name, null);
		return true;
	};
};
