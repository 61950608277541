const USER_CERTIFICATES_MODEL = 'EW_USER_CERTIFICATE';
const DUPLICATE_CERTIFICATES_MODEL = 'EW_USER_DUPLICATE_CERTIFICATE';

edi.models.setModels({
	[USER_CERTIFICATES_MODEL]: {
		fields: [
			{
				name: 'data'
				//type: 'object'
			},
			{
				name: 'IssuerName',
				mapping: 'data.issuerName',
				type: 'string'
			},
			{
				name: 'SerialNumber',
				mapping: 'data.serialNumber',
				type: 'string'
			},
			{
				name: 'SubjectName',
				mapping: 'data.subjectName',
				type: 'string'
			},
			{
				name: 'Thumbprint',
				mapping: 'data.thumbprint',
				type: 'string'
			},
			{
				name: 'validFromDate',
				mapping: 'data.validFromDate'
			},
			{
				name: 'validToDate',
				mapping: 'data.validToDate'
			},
			{
				name: 'Version',
				mapping: 'data.version',
				type: 'string'
			},
			{
				name: 'user',
				mapping: 'data.user'
				//type: 'object'
			},
			{
				name: 'header'
				//type: 'object'
			},
			{
				name: 'id',
				mapping: 'header.id',
				type: 'number'
			},
			{
				name: 'state',
				mapping: 'header.state',
				type: 'string'
			},
			{
				name: 'creationDate',
				mapping: 'header.creationDate',
				type: 'number'
			},
			{
				name: 'modifyDate',
				mapping: 'header.modifyDate',
				type: 'number'
			},
			{
				name: 'org',
				mapping: 'header.fromOrg'
				//type: 'object'
			},
			{
				name: 'algorithm',
				//type: "object",
				mapping: 'data.algorithm'
			},
			{
				name: 'availableDocTypesForSignature',
				//type: 'object',
				mapping: 'data.availableDocTypesForSignature'
			}
		]
	},
	[DUPLICATE_CERTIFICATES_MODEL]: {
		fields: [
			{
				name: 'SubjectName',
				type: 'string'
			},
			{
				name: 'validFromDate',
				type: 'number'
			},
			{
				name: 'validToDate',
				type: 'number'
			},
			{
				name: 'state',
				type: 'string'
			}
		]
	}
});

export { USER_CERTIFICATES_MODEL, DUPLICATE_CERTIFICATES_MODEL };
