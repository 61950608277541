import { createModulePanel, createTab, createTabPanel, TAB_PANEL_CLS } from '@Components/panels';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel
} from '@Components/grid';
import { createTextField, createField } from '@Components/fields';
import { createModuleFilterForm } from '@Components/moduleFilterForm';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { POA_ERROR_MODAL_COLUMNS, POA_LIST_COLUMNS } from './columns';
import { POA_LIST_MODEL } from './models';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, createModalForm, createPanel, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createToolBar } from '@UIkit/components/grid';
import { POA_CONST } from './configuration';
import { moduleMethods } from './POA_002/methods';
import { guidImportHandler, xmlUploadHandler } from './methods';
import {
	createConfidantFormItems,
	createFilterArgs,
	createPoAFilterArgs,
	createPoAFormItems,
	createPrincipalFormItems
} from './filters';
import { createLabel } from '@UIkit/components/fields';
import { documentsModal } from '@Edi/methods/methods';
import { isPrincipal, isConfidant } from './actionRules.js';
import { poaProcesses } from './processes';
import { CC_MAIN_TOUR_TARGET_6 } from '@Ediweb/modules/CONTROL_CENTER/control.center.module.panel';

Ext.namespace('edi.modules');
edi.modules['powers.of.attorney.list'] = function () {
	const moduleInstance = this;
	let moduleData;
	let filterForm;
	let mainGrid, principalGrid, confidantGrid;
	let menu;

	const setLoading = function (loading) {
		if (moduleData.tab && !moduleData.tab.isDestroyed) {
			moduleData.tab.setLoading(loading);
		}
	};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	moduleInstance.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * Inits module instance and returns panel with items for injection in any tab panel
	 * @param	{Object}	data			module data from modules handler
	 * @param	{Function}	initCallBack	callback that must be called on module initialization finish
	 * @return	{Object}	panel with grid and filters
	 */
	moduleInstance.createPanelForTab = function (data, initCallBack) {
		moduleData = data;

		const modulePanel = createModulePanel({
			layout: 'border',
			region: 'center',
			items: [],
			onDestroy: onDestroy
		});

		filterForm = createFilterForm();
		modulePanel.add(filterForm);

		mainGrid = createPowersList();
		modulePanel.add(mainGrid);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}

		return modulePanel;
	};

	moduleInstance.addBtnClickHandler = function () {};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	moduleInstance.onRender = function () {
		edi.events.powerOfAttorney.on('change', updateGrid);
		edi.events.powerOfAttorney.on('delete', updateGrid);
		edi.events.uploadDocument.on('poa_change', updateGrid);
		edi.events.uploadDocument.on('change', poaXmlUpload);
	};

	const fetchControlCenterUrl = function (callback) {
		if (!moduleMethods.controlCenterUrl) {
			edi.rest.sendRequest(
				edi.rest.services.POWER_OF_ATTORNEY.GET_CONTROL_CENTER_URL,
				'GET',
				null,
				(resp) => (moduleMethods.controlCenterUrl = resp.data || null),
				null,
				callback,
				{ suppressDefaultError: true }
			);
		} else {
			callback();
		}
	};

	moduleInstance.createPrincipalTab = (data) => {
		// EW begin
		if (data) {
			moduleData = data;
		}
		// EW end
		const url = edi.utils.compileURL(edi.rest.services.POWER_OF_ATTORNEY.LIST.GET, {
			gridName: 'principals'
		});

		principalGrid = createPowersList({ url });
		const filterForm = createFilterForm({
			url,
			grid: principalGrid,
			createFormItems: createPrincipalFormItems,
			createFilterArgs
		});

		return createPanel({
			bodyPadding: 0,
			layout: 'border',
			border: 0,
			autoScroll: false,
			items: [filterForm, principalGrid]
		});
	};

	moduleInstance.createConfidantTab = (data) => {
		// EW begin
		if (data) {
			moduleData = data;
		}
		// EW end

		const url = edi.utils.compileURL(edi.rest.services.POWER_OF_ATTORNEY.LIST.GET, {
			gridName: 'confidants'
		});

		confidantGrid = createPowersList({ url });
		const filterForm = createFilterForm({
			url,
			grid: confidantGrid,
			createFormItems: createConfidantFormItems,
			createFilterArgs
		});

		return createPanel({
			bodyPadding: 0,
			layout: 'border',
			border: 0,
			autoScroll: false,
			items: [filterForm, confidantGrid]
		});
	};

	const renderData = function (initCallBack) {
		fetchControlCenterUrl(function () {
			let modulePanel = createModulePanel({
				layout: 'border',
				region: 'center',
				items: [
					createTabPanel(
						{
							cls: [TAB_PANEL_CLS.simple, 'module-tabs'],
							items: [
								createTab({
									title: edi.i18n.getMessage('power.of.attorney.principal.info'),
									closable: false,
									items: [moduleInstance.createPrincipalTab()]
								}),
								createTab({
									title: edi.i18n.getMessage('power.of.attorney.confidants.column'),
									closable: false,
									items: [moduleInstance.createConfidantTab()]
								})
							]
						},
						true
					)
				]
			});
			moduleData.tab.add(modulePanel);

			if ('function' == typeof initCallBack) {
				initCallBack();
			}
		});
	};

	const createFilterForm = function ({ url, createFormItems, createFilterArgs, grid }) {
		let filterObject;
		const { formItemsMap, items } =
			typeof createFormItems === 'function' ? createFormItems() : createPoAFormItems();
		const fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(
					url ??
						edi.utils.compileURL(edi.rest.services.POWER_OF_ATTORNEY.LIST.GET, {
							gridName: 'notarchived'
						}),
					filterForm,
					grid || mainGrid,
					typeof createFormItems === 'function' ? createFilterArgs : createPoAFilterArgs
				);
			}
			filterObject.filter();
		};

		const filterForm = createModuleFilterForm(
			{
				saveFilter: {
					name: 'powers_of_attorney_list'
				},
				usePlaceholderWrapper: true,
				toggleAutoSearch: true,
				autosearchEnabled: false,
				searchOnFilterCollapse: true,
				bodyPadding: 0,
				formItemsMap,
				items
			},
			fireSearch
		);

		filterForm.fireSearch = fireSearch;
		return filterForm;
	};

	/**
	 * Checks permissions to open details module
	 * @param	{Object}	record
	 * @returns	{boolean}
	 */
	const isAllowToOpenDetails = function (record) {
		//Смотреть МЧД может только тот кто записан Доверителях или Доверенных лицах
		return (
			edi.permissions.hasPermission('READ_POWER_OF_ATTORNEY') &&
			(isPrincipal({ record }) || isConfidant({ record }))
		);
	};

	const createActionColumn = function () {
		let items = [];

		items.push({
			glyph: edi.constants.ICONS.DELETE,
			isActionDisabled: function (view, rowIndex, colIndex, item, record) {
				if (!edi.permissions.hasPermission('DELETE_POWER_OF_ATTORNEY')) {
					return true;
				}
				return !edi.action.isAvailable(
					edi.constants.DOCUMENT_ACTIONS.DELETE,
					edi.action.getDocumentData(record)
				);
			},
			handler: function (grid, rowIndex, colIndex, item, eOpts, record) {
				const deletePoa = function () {
					let recordData = record.getData();

					let postData = {};
					postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE] = true;
					let stringified = Ext.encode(postData);
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: recordData.docId
							},
							true
						),
						'PUT',
						stringified,
						updateGrid,
						undefined
					);
				};
				edi.core.confirm('values.delete', 'document.delete.question', deletePoa);
			}
		});

		items.push({
			glyph: edi.constants.ICONS.EDIT,
			isActionDisabled: function (view, rowIndex, colIndex, item, record) {
				if (!edi.permissions.hasPermission('EDIT_POWER_OF_ATTORNEY')) {
					return true;
				}
				return !edi.action.isAvailable(edi.constants.DOCUMENT_ACTIONS.EDIT, edi.action.getDocumentData(record));
			},
			handler: function (grid, rowIndex, colIndex, item, eOpts, record) {
				let recordData = record.getData();
				const titlePostfix = recordData.data?.internalPoaNumber;
				const versionId = record.get('versionId');
				const moduleName =
					edi.constants.CREATE_MODULE_NAME_BY_TYPE[
						edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY + `_${versionId}`
					];
				edi.core.openModule(moduleName, recordData, titlePostfix, true, recordData.docId);
			}
		});

		items.push({
			glyph: edi.constants.ICONS.DETAILS,
			isActionDisabled: function (view, rowIndex, colIndex, item, record) {
				//Смотреть МЧД может только тот кто записан Доверителях или Доверенных лицах
				return !isAllowToOpenDetails(record);
			},
			handler: function (grid, rowIndex, colIndex, item, eOpts, record) {
				let recordData = record.getData();
				const versionId = record.get('versionId');
				const titlePostfix = recordData.data?.internalPoaNumber ?? recordData.docId;
				const moduleName =
					edi.constants.DETAILS_MODULE_NAME_BY_TYPE[
						edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY + `_${versionId}`
					];
				edi.core.openModule(moduleName, recordData, titlePostfix, false, recordData.docId);
			}
		});

		return items.length > 0
			? createActionsColumnConfig({
					align: 'center',
					items: items
			  })
			: null;
	};

	const createPowersList = function ({ url }) {
		let grid;
		let columns = edi.columns.get(POA_LIST_COLUMNS);
		let actionColumn = createActionColumn();
		if (actionColumn) {
			columns.push(actionColumn);
		}

		let totalsLabel = createLabel({
			text: edi.i18n.getMessage('records.selected.totals', {
				selectedRows: 0
			}),
			cls: 'edi-tbar-total-label-right'
		});

		let groupActionItems = [];

		if (POA_CONST.ALLOW_ACTIONS_WITH_POA || edi.permissions.hasPermission('CREATE_POWER_OF_ATTORNEY')) {
			menu = new Ext.menu.Menu({
				items: [
					{
						text: edi.i18n.getMessage('power.of.attorney.create.from.scratch_002'),
						handler: function () {
							// EW override
							if (edi.constants.IS_CAN_CREATE_POA && !edi.constants.IS_EXPIRED_POA_TARIFF) {
								edi.core.openModule('power.of.attorney.create', null, '');
							} else {
								edi.methods.tariffs.isCanCreatePoaHandler();
							}
						}
					},
					{
						text: edi.i18n.getMessage('power.of.attorney.create.from.scratch_003'),
						handler: function () {
							// EW override
							if (edi.constants.IS_CAN_CREATE_POA && !edi.constants.IS_EXPIRED_POA_TARIFF) {
								edi.core.openModule('power.of.attorney.create_003', null, '');
							} else {
								edi.methods.tariffs.isCanCreatePoaHandler();
							}
						}
					},
					{
						text: edi.i18n.getMessage('power.of.attorney.upload.xml'),
						handler: () =>
							xmlUploadHandler({
								onSuccess: updateGrid
							})
					},
					{
						cls: 'power-of-attorney-import-from-fns-menu-separator',
						xtype: 'menuseparator',
						emptyText: '',
						text: null
					},
					createLabel({
						text: edi.i18n.getMessage('power.of.attorney.import.from.fns'),
						padding: '4 15',
						typography: 'caption_01',
						color: '--color-grey-50'
					}),
					{
						text: edi.i18n.getMessage('power.of.attorney.import.from.fns.by.number'),
						handler: guidImportHandler
					},
					{
						text: edi.i18n.getMessage('power.of.attorney.import.from.fns.chain'),
						handler: poaProcesses.chainImportHandler
					}
				]
			});
		}

		if (POA_CONST.ALLOW_ACTIONS_WITH_POA || edi.permissions.hasPermission('SEND_POWER_OF_ATTORNEY')) {
			groupActionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.SEND,
				name: edi.i18n.getMessage('power.of.attorney.action.send')
			});
		}

		if (POA_CONST.ALLOW_ACTIONS_WITH_POA || edi.permissions.hasPermission('SIGN_POWER_OF_ATTORNEY')) {
			groupActionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.SIGN,
				name: edi.i18n.getMessage('form.btn.sign')
			});
		}

		if (POA_CONST.ALLOW_ACTIONS_WITH_POA || edi.permissions.hasPermission('CREATE_REVOKE_POWER_OF_ATTORNEY')) {
			groupActionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.REVOKE,
				name: edi.i18n.getMessage('form.btn.revoke')
			});
		}

		if (POA_CONST.ALLOW_ACTIONS_WITH_POA || edi.permissions.hasPermission('REJECT_POWER_OF_ATTORNEY')) {
			groupActionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.REJECT,
				name: edi.i18n.getMessage('document.reject.document')
			});
		}

		const groupActionsItemClick = function (id) {
			const selected = grid.getSelectionModel().getSelection();
			switch (id) {
				case edi.constants.DOCUMENT_ACTIONS.REVOKE:
					groupRevocation({ selected });
					break;
				case edi.constants.DOCUMENT_ACTIONS.SIGN:
					groupSign({ selected });
					break;
				case edi.constants.DOCUMENT_ACTIONS.SEND:
					groupSend({ selected });
					break;
				case edi.constants.DOCUMENT_ACTIONS.REJECT:
					groupReject({ selected });
					break;
			}
		};

		let topBar = createGridActionBar({
			actionCfgs: groupActionItems,
			defaultHandler: groupActionsItemClick,
			cmpCfg: {
				visibleButtonsNumber: 2
			}
		});

		const createAddButton = function () {
			return POA_CONST.ALLOW_ACTIONS_WITH_POA || edi.permissions.hasPermission('CREATE_POWER_OF_ATTORNEY')
				? createButton({
						tooltip: edi.i18n.getMessage('form.btn.add'),
						text: edi.i18n.getMessage('form.btn.add'),
						cls: [BUTTON_CLS.primary],
						glyph: edi.constants.ICONS.PLUS,
						menu: menu,
						listeners: {
							click: function () {
								moduleInstance.addBtnClickHandler(...arguments);
							}
						}
				  })
				: null;
		};
		// EW start
		const createInviteEmployeeButton = function () {
			return edi.permissions.hasPermission('CLIENT_EMPLOYEES_READ')
				? createButton({
						tooltip: edi.i18n.getMessage('ediweb.invite.employee.description'),
						text: edi.i18n.getMessage('ediweb.invite.employee'),
						padding: '0 8 0 0',
						cls: [BUTTON_CLS.primary, CC_MAIN_TOUR_TARGET_6],
						glyph: edi.constants.ICONS.ADD_EMPLOYEE,
						handler: function () {
							edi.core.openModule(
								'org.profile',
								undefined,
								undefined,
								undefined,
								undefined,
								undefined,
								function () {
									edi.events.org_profile.fireEvent('activatetab', 'employees');
								}
							);
						}
				  })
				: null;
		};

		// EW end

		const createGoToCCButton = function () {
			return !POA_CONST.ALLOW_ACTIONS_WITH_POA
				? createButton({
						text: edi.i18n.getMessage('power.of.attorney.go.to.control.center'),
						cls: [BUTTON_CLS.primary],
						glyph: edi.constants.ICONS.EDIT,
						margin: '0 10 0 0',
						handler: () => {
							if (moduleMethods.controlCenterUrl) {
								let userData = edi.core.getUserData();
								const iln = userData?.org?.iln;
								window.location.href = edi.utils.compileURL(moduleMethods.controlCenterUrl, {
									userOrgIln: iln
								});
							} else {
								edi.core.showError('error.power.of.attorney.no.control.center.url');
							}
						}
				  })
				: null;
		};

		const poaGenerateEmptyTextContent = function (emptyTextTplOptions = {}) {
			const me = this;
			const tplBaseCls = `${me.baseCls}-empty-text-with-image`;
			return (
				`<div class="${tplBaseCls}-content">` +
				`<div>${emptyTextTplOptions.contentText ?? ''}</div>` +
				'<div>' +
				`<span>${emptyTextTplOptions.beforeButtonText ?? ''}</span>` +
				me.generateEmptyTextBeforeButton(
					emptyTextTplOptions.beforeButtonText1,
					emptyTextTplOptions.beforeButtonName
				) +
				`</br><span>${emptyTextTplOptions.beforeButtonText2 ?? ''}</span>` +
				me.generateEmptyTextButton(emptyTextTplOptions.buttonText, emptyTextTplOptions.buttonName) +
				'</div>' +
				'</div>'
			);
		};

		const beforeButtonClickHandler = function (comp) {
			const btnCls = `.ui-table-empty-text-before-btn-click`;
			if (!comp.isDestroyed && comp.rendered && comp.el?.dom) {
				setTimeout(function () {
					let buttons = comp.el.dom.querySelectorAll(btnCls);
					buttons.forEach((btn) =>
						btn.addEventListener(
							'click',
							function (event) {
								edi.core.openModule('user.profile');
							},
							false
						)
					);
				}, 1);
			}
		};

		grid = createGrid({
			saveSorters: true,
			savedSortersName: 'powers_of_attorney_list',
			proxyConfig: {
				type: 'ajax',
				url:
					url ??
					edi.utils.compileURL(edi.rest.services.POWER_OF_ATTORNEY.LIST.GET, {
						gridName: 'notarchived'
					})
			},
			storeConfig: {
				pageSize: edi.constants.ITEMS_PER_PAGE,
				model: edi.models.getModel(POA_LIST_MODEL),
				autoLoad: true,
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				listeners: {
					datachanged: function (store) {
						store.getRange().forEach((r) => {
							//Т.к. в гриде отображаются только мчд этой организации,
							//а по fromOrg определяется направление для actionRules, то добавим это в рекорд (с бэка не приходит).
							//Если не добавлять орг, то функция edi.utils.getDocumentDirection сделает direction: 'LOOP'
							//из-за древнего бага, когда undefined == undefined
							r.data.fromOrg = edi.core.getUserData().org;
						});
					}
				}
			},
			gridConfig: {
				cls: 'edi-documents-grid edi-grid-with-toolbar-buttons',
				columns: columns,
				region: 'center',
				border: 1,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						if (isAllowToOpenDetails(record)) {
							let recordData = record.getData();
							const titlePostfix = record.data.data?.internalPoaNumber ?? record.data.docId;
							const versionId = record.get('versionId');
							const moduleName =
								edi.constants.DETAILS_MODULE_NAME_BY_TYPE[
									edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY + `_${versionId}`
								];
							edi.core.openModule(moduleName, recordData, titlePostfix, false, recordData.docId);
						}
					}
				},
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: function (model, selected, actionItems) {
									edi.methods.gridSelModules(
										model,
										selected,
										actionItems,
										topBar,
										totalsLabel,
										function (buttonId, record) {
											let result = true;
											let processButtons = [
												edi.constants.DOCUMENT_ACTIONS.SEND,
												edi.constants.DOCUMENT_ACTIONS.SIGN,
												edi.constants.DOCUMENT_ACTIONS.REVOKE,
												edi.constants.DOCUMENT_ACTIONS.REJECT
											];
											if (buttonId && processButtons.indexOf(buttonId) !== -1) {
												result = edi.action.isAvailable(
													buttonId,
													edi.action.getDocumentData(record)
												);
											}
											return result;
										}
									);
								}
						  })
						: undefined,
				dockedItems: createToolBar({
					items: [createGoToCCButton(), createAddButton(), topBar, '->', createInviteEmployeeButton()]
				}),
				generateEmptyTextContent: poaGenerateEmptyTextContent,
				generateEmptyTextBeforeButton: function (text, name, cls) {
					const me = this;
					const btnCls = `${me.baseCls}-empty-text-with-image-btn`;
					const clickBtnCls = `${me.baseCls}-empty-text-before-btn-click`;

					return String(text ?? '').length > 0
						? `<button class="${btnCls} ${clickBtnCls} ${cls ?? ''}">${text}</button>`
						: '';
				}
			},
			viewConfig: {
				emptyTextTplOptions: {
					enabled: true,
					iconName: 'powers-attorney',
					title: edi.i18n.getMessage('powers.attorney.empty.text.tpl.title'),
					contentText: edi.i18n.getMessage('powers.attorney.empty.text.tpl.contentText'),
					beforeButtonText: edi.i18n.getMessage('powers.attorney.empty.text.tpl.beforeButtonText'),
					beforeButtonText1: edi.i18n.getMessage('powers.attorney.empty.text.tpl.beforeButtonText1'),
					beforeButtonText2: edi.i18n.getMessage('powers.attorney.empty.text.tpl.beforeButtonText2'),
					buttonText: edi.i18n.getMessage('powers.attorney.empty.text.tpl.buttonText'),
					buttonName: 'emptyTextButton',
					beforeButtonName: 'emptyTextBeforeButton',
					buttonClickHandler: function (e, btn, name) {
						const filterFrm = grid.prev('form') || grid.next('form') || filterForm;
						filterFrm?.toggleHandler();
					}
				},
				listeners: {
					viewready: beforeButtonClickHandler,
					refresh: beforeButtonClickHandler
				}
			}
		});

		return grid;
	};

	const groupActionProcess = async ({ selected, action, handler, documentsModalAction }) => {
		const notProcessedPoA = [];
		const processedPoA = [];

		setLoading(edi.i18n.getMessage('loading.text'));

		const processPoA = (poa) => {
			const poaRecord = Ext.clone(poa);
			const poaData = poaRecord.getData();

			const setError = ({ error }) => {
				poaRecord.set('error', error, { dirty: false });
				notProcessedPoA.push(poaRecord);
			};

			const isActionAvailable = edi.action.isAvailable(action, edi.action.getDocumentData(poaRecord));

			return new Promise((resolve) => {
				if (isActionAvailable) {
					if (typeof handler === 'function') {
						handler({ poaData, setError, callback: resolve });
					} else {
						resolve({ cancel: true });
					}
				} else {
					const error = edi.i18n.getMessage('action.is.not.available');
					setError({ error });
					resolve({ error: true });
				}
			});
		};

		for (const poaRecord of selected || []) {
			const { success, error, cancel } = await processPoA(poaRecord);
			if (cancel) {
				break;
			}
			if (success) {
				processedPoA.push(poaRecord);
			}
		}

		setLoading(false);
		updateGrid();
		if (notProcessedPoA.length) {
			documentsModal(
				{
					title: edi.i18n.getMessage('power.of.attorney.not.processed.title'),
					text: edi.utils.formatString(edi.i18n.getMessage('power.of.attorney.that.were.not.processed')),
					width: MODAL_SIZE.widthLarge,
					model: edi.models.getModel(POA_LIST_MODEL),
					columns: edi.columns.get(POA_ERROR_MODAL_COLUMNS)
				},
				notProcessedPoA,
				moduleData,
				documentsModalAction
			);
		}

		return {
			notProcessedPoA,
			processedPoA
		};
	};

	const groupSign = function ({ selected }) {
		const certificateHandler = {
			certificate: null,
			get: function () {
				return this.certificate;
			},
			set: function (cert) {
				this.certificate = cert;
			}
		};

		const handler = ({ poaData, setError, callback: signCallback }) => {
			poaData.id = poaData.docId;
			poaData.type = edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY;

			edi.utils.sign(
				poaData,
				moduleData?.tab,
				function (failed, response) {
					if (failed) {
						const error = edi.utils.formatComplexServerError(response, 'power.of.attorney.error.signing');
						setError({ error });
						signCallback({ error: true });
					} else {
						signCallback({ success: true });
					}
				},
				undefined,
				undefined,
				true,
				certificateHandler,
				{
					signContentUrl: edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.SIGN.GET, {
						documentId: poaData.docId
					}),
					signContentUrlMethod: 'PUT',
					signRefuse: () => setLoading(false),
					disabledPoa: true
				}
			);
		};

		groupActionProcess({
			selected,
			action: edi.constants.DOCUMENT_ACTIONS.SIGN,
			handler
		});
	};

	const groupSend = function ({ selected }) {
		const handler = ({ poaData, setError, callback: sendCallback }) => {
			const successSend = function (response) {
				if (response.success === false) {
					failureSend(response);
				} else {
					sendCallback({ success: true });
				}
			};

			const failureSend = function (response) {
				const error = edi.utils.formatComplexServerError(response, 'power.of.attorney.error.send');
				setError({ error });
				sendCallback({ error: true });
			};

			const postData = {};
			const stringified = Ext.encode(postData);
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.SEND.PUT,
					{
						documentId: poaData.docId
					},
					true
				),
				'PUT',
				stringified,
				successSend,
				failureSend
			);
		};

		groupActionProcess({
			selected,
			action: edi.constants.DOCUMENT_ACTIONS.SEND,
			handler
		});
	};

	const groupRevocation = async function ({ selected }) {
		let certificateHandler = {
			certificate: null,
			get: function () {
				return this.certificate;
			},
			set: function (cert) {
				this.certificate = cert;
			}
		};
		let revocationText = '';

		const handler = ({ poaData, setError, callback: revokeCallback }) => {
			const signRevocation = function (revocationDoc) {
				edi.utils.sign(
					revocationDoc,
					moduleData?.tab,
					function (failed, data) {
						if (failed) {
							const error = edi.utils.formatComplexServerError(
								data,
								'power.of.attorney.error.signing.revocation'
							);
							setError({ error });
							revokeCallback({ error: true });
						}
						revokeCallback({ success: true });
					},
					undefined,
					undefined,
					true,
					certificateHandler,
					{
						signContentUrl: edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.SIGN.GET, {
							documentId: revocationDoc.id
						}),
						signRefuse: () => setLoading(false)
					}
				);
			};

			const successRevokePoA = function (response) {
				if (response.success === true) {
					signRevocation(response.data);
				} else {
					failureRevokePoA();
				}
			};

			const failureRevokePoA = function (response) {
				const error = edi.utils.formatComplexServerError(response, 'power.of.attorney.error.revoking');
				setError({ error });
				revokeCallback({ error: true });
			};

			const revokePoA = function () {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.REVOKE.POST, {
						documentId: poaData.docId
					}),
					'POST',
					Ext.encode({
						REVOKE: true,
						REVOKE_REASON: revocationText
					}),
					successRevokePoA,
					failureRevokePoA,
					null
				);
			};

			if (revocationText) {
				revokePoA();
			} else {
				let revokeReason = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.revoke.reason.title'),
					items: [
						createTextField({
							isTextarea: true,
							name: 'reason',
							allowBlank: false
						})
					]
				});

				const revokeFormPanel = createModalForm({
					submitEmptyText: false,
					items: [revokeReason]
				});

				const revokeModal = createModalPanel({
					title: edi.i18n.getMessage('document.revoke.reason.field.title'),
					width: MODAL_SIZE.widthMedium,
					items: [revokeFormPanel],
					listeners: {
						close: () => {
							if (!revocationText) {
								revokeCallback({ cancel: true });
							}
						}
					},
					buttonsBefore: [
						createButton({
							text: edi.i18n.getMessage('form.btn.revoke'),
							glyph: edi.constants.ICONS.REJECT,
							cls: BUTTON_CLS.primary,
							handler: function () {
								const form = revokeFormPanel.getForm();
								if (!form.isValid()) {
									edi.core.showError('document.revoke.reason.fill.fields.error');
								} else {
									let values = edi.utils.collectFormValues(form);
									revocationText = values.reason;
									setLoading(edi.i18n.getMessage('loading.text'));
									revokePoA();
									revokeModal.close();
								}
							}
						})
					]
				});
				revokeFormPanel.isValid();
				revokeModal.show();
			}
		};
		const showRevokedPoAInfo = (revokedPoARecord) => {
			if (revokedPoARecord.length) {
				edi.core.showInfo(
					edi.i18n.getMessage('power.of.attorney.group.revoked.successfully', [
						revokedPoARecord.map((poaRecord) => poaRecord.get('internalPoaNumber')).join(', ')
					])
				);
			}
		};
		const { notProcessedPoA, processedPoA } = await groupActionProcess({
			selected,
			action: edi.constants.DOCUMENT_ACTIONS.REVOKE,
			handler,
			documentsModalAction: () => {
				showRevokedPoAInfo(processedPoA);
			}
		});
		if (processedPoA.length && !notProcessedPoA.length) {
			showRevokedPoAInfo(processedPoA);
		}
	};

	const groupReject = function ({ selected }) {
		let rejectText = '';

		const handler = ({ poaData, setError, callback: rejectCallback }) => {
			const success = function (response) {
				if (response.success === false) {
					failure(response);
				} else {
					rejectCallback({ success: true });
				}
			};

			const failure = function (response) {
				const error = edi.utils.formatComplexServerError(response, 'power.of.attorney.error.reject');
				setError({ error });
				rejectCallback({ error: true });
			};

			const rejectPoA = function () {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, {
						documentId: poaData.docId
					}),
					'PUT',
					Ext.encode({
						REJECT: true,
						REJECT_REASON: rejectText
					}),
					success,
					failure,
					null
				);
			};

			if (rejectText) {
				rejectPoA();
			} else {
				const rejectReason = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.reject.reason'),
					items: [
						createTextField({
							isTextarea: true,
							name: 'reason',
							allowBlank: false
						})
					]
				});

				const rejectFormPanel = createModalForm({
					submitEmptyText: false,
					items: [rejectReason]
				});

				const rejectModal = createModalPanel({
					title: edi.i18n.getMessage('document.reject'),
					width: MODAL_SIZE.widthMedium,
					items: [rejectFormPanel],
					listeners: {
						close: () => {
							if (!rejectText) {
								rejectCallback({ cancel: true });
							}
						}
					},
					buttonsBefore: [
						createButton({
							text: edi.i18n.getMessage('document.reject.document'),
							glyph: edi.constants.ICONS.REJECT,
							cls: BUTTON_CLS.primary,
							handler: function () {
								const form = rejectFormPanel.getForm();
								if (!form.isValid()) {
									edi.core.showError('document.reject.reason.fill.fields.error');
								} else {
									const values = edi.utils.collectFormValues(form);
									rejectText = values.reason;
									setLoading(edi.i18n.getMessage('loading.text'));
									rejectPoA();
									rejectModal.close();
								}
							}
						})
					]
				});
				rejectFormPanel.isValid();
				rejectModal.show();
			}
		};

		groupActionProcess({
			selected,
			action: edi.constants.DOCUMENT_ACTIONS.REJECT,
			handler
		});
	};

	const poaXmlUpload = function ({ data }) {
		const { Error, Success: result, type, number } = data?.content || {};
		const uploadResultModal = createModalPanel(
			{
				title: edi.i18n.getMessage('web.upload.document.processing.result'),
				width: MODAL_SIZE.widthMedium,
				items: [
					createModalForm({
						padding: '0 0 24 0',
						layout: 'column',
						items: [
							createField({
								title: edi.i18n.getMessage('column.result'),
								input: createLabel({
									columnWidth: 0.6,
									text: edi.i18n.getMessage(
										result ? 'file.processed.PROCESSED' : 'file.processed.PROCESSED_WITH_ERROR'
									)
								}),
								containerConfig: {
									columnWidth: 1
								}
							}),
							createField({
								title: edi.i18n.getMessage('column.error.message'),
								hidden: result && !Error,
								input: createLabel({
									columnWidth: 0.6,
									text: Error || ''
								}),
								containerConfig: {
									columnWidth: 1
								}
							}),
							createField({
								title: edi.i18n.getMessage('power.of.attorney.number'),
								hidden: !result,
								input: createLabel({
									columnWidth: 0.6,
									text: number || ''
								}),
								containerConfig: {
									columnWidth: 1
								}
							}),
							createField({
								title: edi.i18n.getMessage('document.type'),
								hidden: !result,
								input: createLabel({
									columnWidth: 0.6,
									text: edi.i18n.getMessage(`document.${type}`)
								}),
								containerConfig: {
									columnWidth: 1
								}
							})
						]
					})
				]
			},
			true
		);

		uploadResultModal.show();
	};

	const updateGrid = function () {
		[mainGrid, principalGrid, confidantGrid].forEach((grid) => {
			if (grid) {
				grid.getStore().reload();
			}
		});
	};

	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		edi.events.powerOfAttorney.un('change', updateGrid);
		edi.events.powerOfAttorney.un('delete', updateGrid);
		edi.events.uploadDocument.un('poa_change', updateGrid);
		edi.events.uploadDocument.un('change', poaXmlUpload);
	};
};
