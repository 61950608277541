import { createModalPanel } from '@UIkit/components/modal';
import { createTextField } from '@UIkit/components/fields';
import { createModalForm, createFieldBlock } from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createActionsColumnConfig } from '@UIkit/components/grid';
import { showErrorToast, showSuccessToast } from '@Ediweb/core';

const isErrorInvitationRaw = function (raw) {
	return raw?.errors ?? null;
};

const filterInvitationsErrorGrid = function (comp, value, massInvitationsGrid) {
	massInvitationsGrid.getStore().clearFilter(true);
	if (value) {
		massInvitationsGrid.getStore().filter([
			{
				filterFn: function (item) {
					return !!item.get('errors').length;
				}
			}
		]);
	} else {
		massInvitationsGrid.getStore().filter();
	}
	massInvitationsGrid.getStore().reload();
};

const getCountErrorInvitation = function (massInvitationsList = []) {
	let count = 0;
	massInvitationsList.forEach((invitation) => {
		if (invitation && !!invitation.errors.length) {
			count += 1;
		}
	});
	return count;
};

const renderCountError = function (count = [], isInit = false) {
	let countClassName = 'invitations-mass-error-report-init';
	if (!isInit) {
		countClassName = count ? 'invitations-mass-error-report-with-error' : 'invitations-mass-error-report-empty';
	}
	return edi.utils.formatString(
		"<div><span class='invitations-mass-error-report-label'>{0}</span>&nbsp<span class='invitations-mass-error-report-count {2}'>{1}</span></div>",
		[edi.i18n.getMessage('error.send.invitations', null, null, null), count, countClassName]
	);
};

const success = function (moduleData) {
	moduleData.isChanged = false;
	edi.events.invitations.fireEvent('change');
	edi.modulesHandler.removeModule(moduleData);
	edi.events.massInvitation.fireEvent('change', moduleData?.initData?.data?.id ?? null);
	moduleData.tab.setLoading(false);
};

const send = function (invId, moduleData, failure, isSend, invitationsCount, isDuplicate) {
	edi.rest.sendRequest(
		edi.utils.formatString(edi.rest.services.MASS_INVITATIONS.SENT, {
			id: invId
		}),
		'PUT',
		Ext.encode({}),
		function () {
			getInvitationDataCount(
				invId,
				function () {
					success(moduleData);
				},
				failure,
				isSend,
				invitationsCount,
				isDuplicate
			);
		},
		function (errorData) {
			const additionalData = errorData?.additionalData ?? [];
			if (additionalData.indexOf("Can't push by BP") > -1) {
				showResultModal(invitationsCount, invitationsCount, success, isSend, moduleData);
				return;
			}
			failure(errorData);
		}
	);
};

const getMassInvitation = function (id, success, failure) {
	const url = edi.utils.formatString(edi.rest.services.MASS_INVITATIONS.DATA, {
		id: id
	});
	edi.rest.sendRequest(
		url,
		'PUT',
		Ext.encode({}),
		function (dataInvitation) {
			const data = dataInvitation?.data ?? {};
			if (!data) {
				failure();
				return;
			}
			success(dataInvitation);
		},
		failure
	);
};

const getHeaderMassInvitation = function (id, success, failure) {
	const url = edi.utils.formatString(
		edi.rest.services.DOCUMENTS.HEADER.GET,
		{
			documentId: id
		},
		true
	);
	edi.rest.sendRequest(
		url,
		'GET',
		undefined,
		function (headerData) {
			const data = headerData?.data ?? {};
			if (!data) {
				failure();
				return;
			}
			success(data);
		},
		failure
	);
};

const getInvitationDataCount = function (id, success, failure, isSend, allInvitationsCount = 0, isDuplicate) {
	if (!id) {
		failure();
		return;
	}
	getMassInvitation(
		id,
		function (dataInvitation) {
			const data = dataInvitation?.data ?? {};
			if (!data) {
				failure();
				return;
			}
			const invitations = data.invitations || [];
			const errorCount = getCountErrorInvitation(invitations);
			const invitationsCount = invitations.length;

			showResultModal(
				errorCount,
				invitationsCount,
				success,
				isSend,
				dataInvitation,
				allInvitationsCount,
				isDuplicate
			);
		},
		failure
	);
};

const showResultModal = function (
	errorCount,
	invitationsCount,
	success,
	isSend,
	dataInvitation,
	allInvitationsCount = 0,
	isDuplication
) {
	let successCount = isSend ? invitationsCount - errorCount : invitationsCount;
	let message = '';

	if (isDuplication) {
		if (invitationsCount >= errorCount && invitationsCount > 0) {
			message = 'success.save.mass.invitation.earlier';
		} else {
			message = 'error.save.mass.invitation';
		}
	} else {
		if (isSend) {
			if (successCount !== 0) {
				message = 'success.send.mass.invitation';
			} else {
				message = 'error.send.mass.invitation';
			}
		} else {
			if (successCount >= 0) {
				message = 'success.save.mass.invitation.earlier';
			}
			if (successCount === allInvitationsCount) {
				message = 'success.save.mass.invitation';
			}
		}
	}

	var htmlMsg = edi.i18n.getMessage(message, { successCount, allInvitationsCount });
	if (successCount !== 0) {
		showSuccessToast(null, htmlMsg, null, function () {
			success(dataInvitation);
		});
	} else {
		showErrorToast(null, htmlMsg, null, function () {
			success(dataInvitation);
		});
	}
};

const updateInvitationInMassInvitation = function (record, id, moduleData, failure, changeHandler) {
	const path = 'parties.receiver';
	let rawRecord = record?.data ?? null;

	let nameOrganization = createFieldBlock({
		title: edi.i18n.getMessage('ediweb.invitations.receiver'),
		items: [
			createTextField({
				fieldLabel: edi.i18n.getMessage('ediweb.invitations.organization.name'),
				name: path + '.name',
				valueSrc: rawRecord,
				value: edi.utils.getObjectProperty(rawRecord, path + '.name'),
				allowBlank: false
			})
		]
	});

	const innValue = edi.utils.getObjectProperty(rawRecord, path + '.inn');
	let innOrganization = createTextField({
		regex: edi.constants.VALIDATORS.INN,
		fieldLabel: edi.i18n.getMessage('column.org.inn'),
		name: path + '.inn',
		value: innValue,
		allowBlank: false,
		listeners: {
			change: function (field, value) {
				kppOrganization.allowBlank = !!(value && value.length && value.length === 12);
				kppOrganization.isValid();
			}
		}
	});

	const innOrganizationLength = innValue?.length ?? 0;
	let kppOrganization = createTextField({
		regex: edi.constants.VALIDATORS.KPP,
		fieldLabel: edi.i18n.getMessage('column.org.kpp'),
		name: path + '.kpp',
		value: edi.utils.getObjectProperty(rawRecord, path + '.kpp'),
		allowBlank: innOrganizationLength === 12
	});
	let emailOrganization = createTextField({
		regex: edi.constants.VALIDATORS.EMAIL,
		regexText: edi.i18n.getMessage('invalid.email.format'),
		fieldLabel: edi.i18n.getMessage('email'),
		name: path + '.email',
		value: edi.utils.getObjectProperty(rawRecord, path + '.email'),
		allowBlank: false
	});
	let comment = createFieldBlock({
		title: edi.i18n.getMessage('invitation.mass.column.comment'),
		items: [
			createTextField({
				name: 'comments.invitationText',
				value: edi.utils.getObjectProperty(rawRecord, 'comments.invitationText'),
				isTextarea: true,
				height: 80
			})
		]
	});
	const formPanel = createModalForm({
		layout: {
			type: 'grid',
			gap: [24],
			area: [12, 12, 12, 12, 12]
		},
		items: [nameOrganization, innOrganization, kppOrganization, emailOrganization, comment]
	});
	const modal = createModalPanel({
		title: edi.i18n.getMessage('mass.invitation.receiver.edit'),
		items: [formPanel],
		buttonsBefore: [
			createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('form.btn.save'),
				handler: function () {
					if (!edi.utils.setFocusToDocumentsWithGrid(formPanel)) return;
					moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
					let values = edi.utils.collectFormValues(formPanel);
					modal.close();
					const url = edi.utils.formatString(
						edi.rest.services.MASS_INVITATIONS.UPDATE_PARTICIPANT_INVITATION,
						{
							invitationId: record.get('id')
						}
					);

					edi.utils.clearEmptyValues(values);

					if (
						!!(
							values.parties.receiver.inn &&
							values.parties.receiver.inn.length &&
							values.parties.receiver.inn.length === 12
						) &&
						!values.parties.receiver.kpp
					) {
						delete rawRecord.parties.receiver.kpp;
					}

					values = Ext.merge(
						{
							errors: rawRecord.errors,
							expectedReceiver: null,
							invitationType: rawRecord.invitationType,
							roleModel: rawRecord.roleModel,
							parties: rawRecord.parties
						},
						values
					);

					let invData = {
						UPDATE: true,
						UPDATE_PARAMS: {
							data: Ext.encode(values),
							docType: edi.constants.DOCUMENT_TYPES.INVITATION
						}
					};

					edi.rest.sendRequest(
						url,
						'PUT',
						Ext.encode(invData),
						function () {
							getMassInvitation(id, changeHandler, failure);
						},
						function (data) {
							failure(data);
						}
					);
				}
			})
		],
		closeButtonOptions: {
			text: edi.i18n.getMessage('form.btn.cancel')
		}
	});
	modal.show();
};

const getMassInvitationColumns = function (actionColumns, isEdit) {
	const actions = createActionsColumnConfig({
		items: actionColumns
	});
	let columns = edi.columns.get(isEdit ? 'mass_invitations_list_edit' : 'mass_invitations_list');
	actions ? columns.push(actions) : null;
	return columns;
};

const getDirectionInvitation = function (value) {
	const currentOrg = edi.core.getUserData()?.org ?? null;
	const sender = value?.parties?.sender ?? null;
	let isSender = false;
	let isReceiver = false;
	if (!currentOrg || !sender) return null;
	const innOrg = currentOrg?.inn ?? null;
	const kppOrg = currentOrg?.kpp ?? null;
	const nameOrg = currentOrg?.name ?? null;
	const innSender = sender?.inn ?? null;
	const kppSender = sender?.kpp ?? null;
	const nameSender = sender?.name ?? null;
	if (
		innOrg === innSender &&
		innSender &&
		innOrg &&
		kppOrg === kppSender &&
		kppSender &&
		kppOrg &&
		nameOrg === nameSender &&
		nameSender &&
		nameOrg
	)
		isSender = true;
	const receiver = value?.parties?.receiver ?? null;
	const innReceiver = receiver?.inn ?? null;
	const kppReceiver = receiver?.kpp ?? null;
	if (innOrg === innReceiver && innReceiver && innOrg && kppOrg === kppReceiver && kppReceiver && kppOrg)
		isReceiver = true;
	if (isSender && isReceiver) return edi.constants.DIRECTIONS.LOOP;
	if (isSender) return edi.constants.DIRECTIONS.OUTGOING;
	if (isReceiver) return edi.constants.DIRECTIONS.INCOMING;
};

const getTooltip = function (record) {
	const ERRORS_MAP = {
		'controller.invitation.partnership.existed.error': function (record) {
			const parties = record.get('parties');
			const roleModel = record.get('roleModel');
			const receiverName = parties?.receiver?.name;
			const productRole = roleModel?.productRole;
			return edi.i18n.getMessage('controller.invitation.partnership.existed.error', [receiverName, productRole]);
		}
	};
	let errors = record.get('errors');
	if (Array.isArray(errors) && errors.length) {
		errors = errors.map((item) => {
			const tplConst = `ediweb.error.${item}`;
			let tplText = edi.i18n.getMessage(tplConst);
			if (tplText === tplConst) {
				tplText = ERRORS_MAP.hasOwnProperty(item) ? ERRORS_MAP[item](record) : edi.i18n.getMessage(item);
			}
			return tplText;
		});
		errors = errors.join('<br/>');
	}
	if (!errors.length) return false;
	return errors;
};

export {
	showResultModal,
	send,
	getInvitationDataCount,
	isErrorInvitationRaw,
	filterInvitationsErrorGrid,
	renderCountError,
	getCountErrorInvitation,
	success,
	updateInvitationInMassInvitation,
	getMassInvitationColumns,
	getDirectionInvitation,
	getMassInvitation,
	getHeaderMassInvitation,
	getTooltip
};
