import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createField,
	createFieldSet,
	createNumberField,
	createTextField,
	createTimeField
} from '@Components/fields';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createButtonContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

/**
 * @author Darya Matyukha
 * Class for creation IFTMBF_APPROVAL document
 */
Ext.namespace('edi.modules');
edi.modules['document.create.iftmbf_approval'] = function () {
	var moduleData,
		isNumberAutoGenerated = false,
		parentId,
		id,
		userData = edi.core.getUserData(),
		isEdit = false,
		sellerOrg,
		routesValues = { products: [] },
		sellerOrgValues,
		buyer,
		seller,
		form,
		afterSave,
		documentData = {},
		currencyName,
		productsGrid;
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		renderData(initCallBack);

		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var hasAddedRoutes = routesValues.products && routesValues.products.length,
			sellerValid = seller.isValid(),
			buyerValid = buyer.isValid(),
			formValid = form.isValid(),
			isValid = !!(formValid && sellerValid && buyerValid && hasAddedRoutes);

		return isValid;
	};

	//Creates opanel with column layout
	var createPanelFn = function (items, panelConfig) {
		panelConfig = Object.assign(
			{
				columnWidth: 1,
				layout: 'column',
				cls: 'edi-create-field-line',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: items
			},
			panelConfig
		);

		return createPanel(panelConfig);
	};

	//Creates field with title & input
	var createFieldFn = function (title, input) {
		return createPanelFn([
			createField({
				title: title,
				input: input,
				containerConfig: {
					columnWidth: 1
				}
			})
		]);
	};

	var getDocumentFieldValue = function (fieldName, asArray) {
		return edi.utils.getObjectProperty(documentData, fieldName, !!asArray);
	};

	/**
	 * Create invoice form
	 */
	var createMainFormItems = function () {
		var documentNumber = createTextField({
			emptyText: isNumberAutoGenerated ? edi.i18n.getMessage('document.generation.numbers.text') : '',
			columnWidth: 0.5,
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			maxLength: 35,
			value: getDocumentFieldValue('offerNumber'),
			name: 'offerNumber'
		});

		var mainDataInputs = createPanelFn([
			createField({
				title: edi.i18n.getMessage('document.iftmbf.documentNumber'),
				input: documentNumber,
				containerConfig: {
					columnWidth: 0.6
				}
			}),
			createCombo({
				allowBlank: false,
				columnWidth: 0.4,
				margin: '0 0 0 10',
				emptyText: edi.i18n.getMessage('document.iftmbf_approval.offerType'),
				store: edi.stores.createSimpleInlineStore(['1', '2', '3', '4'], function (id) {
					return edi.i18n.getMessage('document.iftmbf_approval.offerType.' + id);
				}),
				value: getDocumentFieldValue('offerType'),
				name: 'offerType'
			})
		]);

		var startDateTime = createPanelFn([
			createField({
				title: 'document.iftmbf_approval.startDate',
				input: createDateField({
					allowBlank: false,
					columnWidth: 0.625,
					emptyText: edi.i18n.getMessage('column.date'),
					value: isEdit
						? edi.utils.formatDate(getDocumentFieldValue('startDate'), edi.constants.DATE_FORMAT.FNS)
						: new Date(),
					name: 'startDate'
				}),
				containerConfig: {
					columnWidth: 0.8
				}
			}),
			createTimeField({
				allowBlank: false,
				columnWidth: 0.2,
				format: 'H:i:s',
				margin: '0 0 0 5',
				emptyText: edi.i18n.getMessage('column.time'),
				value: getDocumentFieldValue('startTime'),
				name: 'startTime'
			})
		]);

		var orderNumber = createFieldFn(
			'document.iftmbf_approval.orderNumber',
			createTextField({
				columnWidth: 0.7,
				maxLength: 35,
				value: getDocumentFieldValue('orderNumber'),
				name: 'orderNumber',
				allowBlank: false
			})
		);

		var uniqueApplicationId = createFieldFn(
			'document.iftmbf.uuid',
			createTextField({
				columnWidth: 0.7,
				maxLength: 36,
				allowBlank: false,
				enforceMaxLength: true,
				value: getDocumentFieldValue('idTrnsrdr'),
				regex: /^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/,
				regexText: edi.i18n.getMessage('iftmbc.approval.offer.id.format'),
				maskRe: /[0-9a-zA-Z\-]/,
				name: 'idTrnsrdr'
			})
		);

		var autogenCheckbox = isNumberAutoGenerated
			? createPanelFn([
					createField({
						title: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
						input: createCheckbox({
							columnWidth: 0.8,
							inputValue: true,
							checked: isNumberAutoGenerated,
							name: 'numberAutoGenerated',
							listeners: {
								change: function (checkbox, isChecked) {
									documentNumber.setEmptyText(
										isChecked
											? edi.i18n.getMessage('document.generation.numbers.text')
											: edi.i18n.getMessage('nr')
									);
									documentNumber.setValue('');
									documentNumber.setDisabled(isChecked);
									documentNumber.allowBlank = isChecked;
									documentNumber.validate();
								}
							}
						}),
						containerConfig: {
							columnWidth: 1
						}
					})
			  ])
			: null;

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				labelConfig: {
					columnWidth: 0.3,
					title: selectorConfig.title
				},
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columnWidth: 0.7,
				selectedRelationByMap: true,
				valuesByMap: true,
				useHiddenFields: true,
				disableAutoValidation: true,
				processValues: orgConverter
			});

			delete selectorConfig.title;

			return createCompanySelector(selectorConfig);
		};

		var userOrganization = edi.converters.convertOrgToLegacyPartie(userData.org);
		userOrganization.address = edi.methods.organization.createFullAddressString(userData.org);

		var buyerInfo = getDocumentFieldValue('customer'),
			isBuyerDefined = !!buyerInfo,
			isValidBuyer = isEdit ? !!buyerInfo.gln : false;

		var createMandatoryFields = function (modalConfig) {
			var newConf = Ext.clone(modalConfig);
			Object.values(newConf).forEach(function (tabConfig) {
				Object.values(tabConfig).forEach(function (fieldConfig) {
					Object.assign(fieldConfig, {
						allowBlank: false
					});
				});
			});

			return newConf;
		};

		buyer = createCS({
			itemId: 'buyer',
			title: 'document.buyer',
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id || userData.org?.id,
				converter: orgConverter
			}),
			selectedRelationByMap: isEdit,
			useHiddenFields: true,
			fieldValues: isBuyerDefined ? documentData : userOrganization,
			valuesByMap: isBuyerDefined,
			selectedOrgValues: userOrganization,
			selectedOrg: userData.org,
			readOnly: isEdit,
			is_valid: isValidBuyer,
			disableAutoValidation: true,
			forceChangeFields: {
				company_org_form: true,
				company_okpo: true,
				company_okonh: true,
				bank_id: true,
				bank_corr_acc: true,
				bank_acc: true
			},
			fieldsMap: {
				company_org_form: 'companyDescription.opf',
				company_name: 'companyDescription.name',
				company_inn: 'companyDescription.inn',
				company_iln: 'companyDescription.gln',
				company_okpo: 'companyDescription.okpo',
				company_okonh: 'companyDescription.okonh',
				address: 'companyDescription.address',
				bank_id: 'companyDescription.bik',
				bank_acc: 'companyDescription.checkingAccount',
				bank_corr_acc: 'companyDescription.correspondentAccount',
				bank_address: 'companyDescription.bankAddress',
				contact_phone: 'companyDescription.phone'
			},
			modalConf: {
				tabs: createMandatoryFields({
					main: {
						company_name: edi.selectors.defaults.company_name,
						company_gln: edi.selectors.defaults.company_gln,
						company_inn: edi.selectors.defaults.company_inn,
						company_org_form: edi.selectors.defaults.company_org_form,
						company_okpo: edi.selectors.defaults.company_okpo,
						company_okonh: Ext.applyIf(
							{
								maskRe: /\d/i,
								maxLength: 255
							},
							edi.selectors.defaults.company_okonh
						)
					},
					address: {
						address: edi.selectors.defaults.full_address
					},
					bank: {
						bank_id: {
							title: 'company.bank.id',
							emptyText: 'company.bank.id.comment',
							name: 'bank_id',
							maskRe: /\d/i,
							invalidText: edi.i18n.getMessage('invalid.value.bank.id')
						},
						bank_acc: {
							name: 'bank_acc',
							maskRe: /\d/i,
							title: 'company.bank.account'
						},
						bank_corr_acc: {
							maskRe: /\d/i,
							name: 'bank_corr_acc',
							title: 'company.bank.account.corr'
						},
						bank_address: {
							name: 'bank_address',
							title: 'company.bank.address'
						}
					},
					contact: {
						contact_phone: Ext.applyIf({ allowBlank: false }, edi.selectors.defaults.contact_phone)
					}
				})
			},
			callback: function (values) {
				values = Object.assign({}, values);
				var isValid = true;

				for (var fieldName in buyer.fieldsMap) {
					if (buyer.fieldsMap.hasOwnProperty(fieldName)) {
						isValid = !!values[fieldName];

						if (!isValid) {
							break;
						}
					}
				}

				buyer.is_valid = isValid;
				checkValid();
			}
		});

		var sellerInfo = getDocumentFieldValue('shipper'),
			isSellerDefined = !!sellerInfo,
			isValidSeller = !!sellerInfo.gln;

		if (sellerOrg) {
			sellerOrgValues = edi.converters.convertOrgToLegacyPartie(sellerOrg);
		}

		seller = createCS({
			itemId: 'seller',
			title: 'document.seller',
			is_valid: isValidSeller,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			fieldValues: documentData,
			readOnly: isEdit,
			relations: !isEdit && sellerOrg ? sellerOrg : edi.relations.getRelations(),
			relationsOnly: true,
			disableAutoValidation: true,
			allowReset: !isEdit && !isSellerDefined,
			selectedOrg: !isEdit && sellerOrg ? sellerOrgValues : isSellerDefined ? sellerInfo : undefined,
			selectedRelationOrg: sellerOrg ? sellerOrg : null,
			selectedOrgValues: sellerOrgValues,
			selectedRelationByMap: isEdit || sellerOrg,
			callback: function (values, org) {
				var orgId = org ? org.id : null;
				if (orgId && (!sellerOrg || sellerOrg.id != orgId)) {
					sellerOrg = org;
				} else if (!orgId) {
					sellerOrg = null;
				}

				seller.is_valid = !!(values && values.company_iln);
				checkValid();
			},
			fieldsMap: {
				company_iln: 'shipper.gln'
			},
			modalConf: {
				title: 'document.seller',
				tabs: {
					main: {
						company_gln: edi.selectors.defaults.company_gln
					}
				}
			}
		});

		return [mainDataInputs, startDateTime, uniqueApplicationId, autogenCheckbox, orderNumber, seller, buyer];
	};

	var createTransportInfoItems = function () {
		var cargoName = createFieldFn(
			'document.iftmbf_approval.cargo.name',
			createTextField({
				columnWidth: 0.8,
				allowBlank: false,
				value: getDocumentFieldValue('cargo.name'),
				name: 'cargo.name'
			})
		);

		var cargoInfo = createPanelFn([
			createField({
				title: 'document.iftmbf_approval.unitOfMeasure',
				input: createCombo({
					allowBlank: false,
					columnWidth: 0.5,
					store: edi.stores.createSimpleInlineStore(['т.', 'кг.']),
					value: getDocumentFieldValue('cargo.unitOfMeasure'),
					name: 'cargo.unitOfMeasure'
				}),
				containerConfig: {
					columnWidth: 0.4
				}
			}),
			createField({
				title: 'document.iftmbf_approval.palletsCount',
				input: createNumberField({
					columnWidth: 0.6,
					allowBlank: false,
					value: getDocumentFieldValue('cargo.pallet'),
					name: 'cargo.pallet'
				}),
				containerConfig: {
					margin: '0 0 0 20',
					columnWidth: 0.6
				}
			})
		]);

		var weightAndTemperature = createPanelFn([
			createField({
				title: 'document.iftmbf_approval.weight',
				input: createNumberField({
					columnWidth: 0.5,
					allowBlank: false,
					allowDecimals: true,
					value: getDocumentFieldValue('cargo.weight'),
					name: 'cargo.weight'
				}),
				containerConfig: {
					columnWidth: 0.4
				}
			}),
			createField({
				title: 'document.iftmbf_approval.temperature',
				input: createPanel({
					layout: 'column',
					columnWidth: 0.6,
					items: [
						createNumberField({
							allowBlank: false,
							fieldLabel: edi.i18n.getMessage('document.iftmbf_approval.cargo.temperature.min'),
							columnWidth: 0.5,
							minValue: -1000,
							value: getDocumentFieldValue('cargo.temperatureConditions.min'),
							name: 'cargo.temperatureConditions.min'
						}),
						createNumberField({
							allowBlank: false,
							margin: '0 0 0 5',
							fieldLabel: edi.i18n.getMessage('document.iftmbf_approval.cargo.temperature.max'),
							columnWidth: 0.5,
							value: getDocumentFieldValue('cargo.temperatureConditions.max'),
							name: 'cargo.temperatureConditions.max'
						})
					]
				}),
				containerConfig: {
					margin: '0 0 0 20',
					columnWidth: 0.6
				}
			})
		]);

		var loadingType = createFieldFn(
			'document.iftmbf_approval.cargo.loadingType',
			createTextField({
				columnWidth: 0.8,
				allowBlank: false,
				value: getDocumentFieldValue('cargo.loadingType.name'),
				name: 'cargo.loadingType.name'
			})
		);

		var transportInfo = createPanelFn([
			createField({
				title: edi.i18n.getMessage('document.iftmbf_approval.cargo.mountingTools'),
				input: createCombo({
					allowBlank: false,
					columnWidth: 0.5,
					store: edi.stores.createSimpleInlineStore(['yes', 'no'], function (id) {
						return edi.i18n.getMessage('answer.' + id);
					}),
					value: !!getDocumentFieldValue('carRequirements.mountingTools') ? 'yes' : 'no',
					name: 'carRequirements.mountingTools'
				}),
				containerConfig: {
					columnWidth: 0.4
				}
			}),
			createField({
				title: edi.i18n.getMessage('document.iftmbf_approval.carRequirements.carType'),
				input: createTextField({
					columnWidth: 0.8,
					allowBlank: false,
					value: getDocumentFieldValue('carRequirements.truckType.name'),
					name: 'carRequirements.truckType.name'
				}),
				containerConfig: {
					margin: '0 0 0 20',
					columnWidth: 0.6
				}
			})
		]);

		var remarks = createPanelFn([
			createField({
				title: edi.i18n.getMessage('document.iftmbf.transportInfo.remarks'),
				input: createTextField({
					columnWidth: 0.8,
					allowBlank: false,
					isTextarea: true,
					maxLength: 3000,
					value: getDocumentFieldValue('specialConditions'),
					name: 'specialConditions'
				}),
				containerConfig: {
					columnWidth: 1,
					margin: '10 0 0 0'
				}
			})
		]);

		var createNeededClipItems = function () {
			var clipTypes = createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					createField({
						title: edi.i18n.getMessage('document.iftmbf.transportInfo.crossbarRequired'),
						input: createCheckbox({
							columnWidth: 0.2,
							inputValue: true,
							checked: !!getDocumentFieldValue(
								'carRequirements.requirementForEquipment.crossbarRequired.value'
							),
							name: 'carRequirements.requirementForEquipment.crossbarRequired.value'
						}),
						containerConfig: {
							columnWidth: 0.3
						}
					}),
					createField({
						title: edi.i18n.getMessage('document.iftmbf.transportInfo.beltsRequired'),
						input: createCheckbox({
							columnWidth: 0.2,
							inputValue: true,
							checked: !!getDocumentFieldValue(
								'carRequirements.requirementForEquipment.beltsRequired.value'
							),
							name: 'carRequirements.requirementForEquipment.beltsRequired.value'
						}),
						containerConfig: {
							margin: '0 0 0 5',
							columnWidth: 0.3
						}
					}),
					createField({
						title: edi.i18n.getMessage('document.iftmbf.transportInfo.fasteningDevicesRequired'),
						input: createCheckbox({
							columnWidth: 0.2,
							inputValue: true,
							checked: !!getDocumentFieldValue(
								'carRequirements.requirementForEquipment.fasteningDevicesRequired.value'
							),
							name: 'carRequirements.requirementForEquipment.fasteningDevicesRequired.value'
						}),
						containerConfig: {
							margin: '0 0 0 5',
							columnWidth: 0.4
						}
					})
				]
			});

			var otherClip = createFieldFn(
				null,
				createTextField({
					fieldLabel: edi.i18n.getMessage('document.iftmbf.transportInfo.otherRequired'),
					columnWidth: 1,
					value: getDocumentFieldValue('carRequirements.requirementForEquipment.otherRequired.text'),
					name: 'carRequirements.requirementForEquipment.otherRequired.text'
				})
			);

			return createFieldSet({
				margin: '10 0 0 0',
				title: edi.i18n.getMessage('document.iftmbf.transportInfo.neededClip'),
				items: [clipTypes, otherClip]
			});
		};

		return createFieldSet({
			title: edi.i18n.getMessage('document.iftmbf_approval.cargoInfo'),
			items: [
				cargoName,
				cargoInfo,
				weightAndTemperature,
				remarks,
				loadingType,
				transportInfo,
				createNeededClipItems()
			]
		});
	};

	var createRateInfoBlock = function () {
		var selectedCurrency = isEdit ? getDocumentFieldValue('rateInfo.currency.shortName') : null;
		currencyName = isEdit ? getDocumentFieldValue('rateInfo.currency.name') : null;
		var currency = createPanelFn([
			createField({
				title: edi.i18n.getMessage('document.iftmbf_approval.cargo.currency'),
				input: createCombo({
					allowBlank: false,
					columnWidth: 0.8,
					store: edi.stores.initCurrencyStore(),
					value: selectedCurrency,
					name: 'rateInfo.currency.shortName',
					anyMatch: true,
					listeners: {
						select: function (combo, record) {
							if (record && record.get) {
								currencyName = record.get('name');
							}
						}
					}
				}),
				containerConfig: {
					columnWidth: 1
				}
			})
		]);

		const availableTaxRates = edi.methods.taxRates.getRatesByCountry('RUS');
		availableTaxRates.splice(-1, 1); //убираем NoVAT

		var taxRates = createPanelFn([
			createField({
				title: edi.i18n.getMessage('document.iftmbf_approval.cargo.rate'),
				input: createNumberField({
					allowBlank: false,
					columnWidth: 0.5,
					maxLength: 10,
					value: getDocumentFieldValue('rateInfo.rate'),
					name: 'rateInfo.rate'
				}),
				containerConfig: {
					columnWidth: 0.4
				}
			}),
			createField({
				title: edi.i18n.getMessage('document.iftmbf_approval.cargo.taxRate'),
				input: createCombo({
					store: edi.stores.createInlineStore(availableTaxRates),
					columnWidth: 0.8,
					allowBlank: false,
					value: getDocumentFieldValue('rateInfo.taxRate'),
					name: 'rateInfo.taxRate'
				}),
				containerConfig: {
					margin: '0 0 0 20',
					columnWidth: 0.6
				}
			})
		]);

		return createFieldSet({
			title: edi.i18n.getMessage('document.iftmbf_approval.rate.block'),
			items: [currency, taxRates]
		});
	};

	var createRoutes = function () {
		var lines = getDocumentFieldValue('routes.route', true);
		if ('string' != typeof lines) {
			routesValues.products = lines;
		}

		return createProductGridBase({
			title: edi.i18n.getMessage('document.iftmbf.routes'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'IFTMBF_APPROVAL_ROUTE',
			gridColumnConfig: 'iftmbf_approval_route',
			data: routesValues.products,
			hasTotal: false,
			configModalButtonsBefore: function () {
				return [];
			},
			callback: function (values) {
				moduleData.isChanged = true;
				routesValues = values;

				checkValid();
			},
			modalFormConfig: {
				title: 'document.iftmbf.routes',
				modalFields: [
					{
						title: edi.i18n.getMessage('documents.iftmbf.line.modal.tab.main.data'),
						items: [
							{
								title: edi.i18n.getMessage('document.iftmbf.routes.dateFrom'),
								name: 'dateFrom',
								format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
								submitFormat: edi.constants.DATE_FORMAT.DOCUMENT,
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('document.iftmbf.routes.dateTo'),
								name: 'dateTo',
								format: edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
								submitFormat: edi.constants.DATE_FORMAT.DOCUMENT,
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('column.timing'),
								type: 'number',
								maxLength: 255,
								name: 'timing'
							},
							{
								title: edi.i18n.getMessage('column.point.ilnFrom'),
								name: 'routeFrom',
								allowBlank: false,
								regex: edi.constants.VALIDATORS.GLN,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.iftmbf.routes.addressFrom'),
								name: 'addressfrom'
							},
							{
								title: edi.i18n.getMessage('column.point.ilnTo'),
								name: 'routeTo',
								allowBlank: false,
								regex: edi.constants.VALIDATORS.GLN,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('document.iftmbf.routes.addressTo'),
								name: 'addressto'
							}
						]
					}
				]
			}
		});
	};

	/**
	 * Creates upd form
	 * @returns {Object}
	 */
	var createModuleForm = function () {
		var createButton = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller],
							productsGrid,
							!routesValues.products.length
						)
					) {
						return;
					}
					save();
				}
			},
			isEdit
		);

		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.EDI_IFTMBF
				)
			)
		) {
			var createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller],
							productsGrid,
							!routesValues.products.length
						)
					) {
						return;
					}
					afterSave = function (documentId, callback) {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							Ext.encode({}),
							null,
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.in.sending.process'
							),
							callback
						);
					};
					save();
				},
				null,
				isEdit
			);
		}

		var twoColumnsLayout = createTwoColumnsLayout(createMainFormItems(), [
			createTransportInfoItems(),
			createRateInfoBlock()
		]);

		productsGrid = createRoutes();

		form = createForm({
			bodyPadding: 10,
			autoScroll: true,
			cls: 'edi-form document-add-form',
			items: [twoColumnsLayout, productsGrid],
			buttons: createButtonContainer({
				items: [createAndSendButton, createButton]
			})
		});

		form.on('validitychange', checkValid);

		checkValid();
		edi.utils.processModuleFormChange(form, moduleData);
		return form;
	};

	var buildDocument = function (formValues) {
		edi.utils.setObjectProperty(formValues, 'rateInfo.currency.name', currencyName);
		var getFormValue = function (propertyName) {
			return edi.utils.getObjectProperty(formValues, propertyName);
		};

		var build = {
			idTrnsrdr: getFormValue('idTrnsrdr'),
			offerNumber: !!getFormValue('offerNumber') ? getFormValue('offerNumber') : null,
			orderNumber: getFormValue('orderNumber'),
			offerType: getFormValue('offerType'),
			numberAutoGenerated: getFormValue('numberAutoGenerated') ? getFormValue('numberAutoGenerated') : false,
			startDate: edi.utils.formatDate(getFormValue('startDate'), edi.constants.DATE_FORMAT.SERVER),
			startTime: getFormValue('startTime'),
			customer: {
				gln: getFormValue('companyDescription.gln')
			},
			shipper: {
				gln: getFormValue('shipper.gln')
			},
			cargo: {
				name: getFormValue('cargo.name'),
				pallet: getFormValue('cargo.pallet'),
				temperatureConditions: {
					min: getFormValue('cargo.temperatureConditions.min'),
					max: getFormValue('cargo.temperatureConditions.max')
				},
				unitOfMeasure: getFormValue('cargo.unitOfMeasure'),
				loadingType: {
					name: getFormValue('cargo.loadingType.name')
				},
				weight: getFormValue('cargo.weight')
			},
			rateInfo: {
				currency: {
					name: getFormValue('rateInfo.currency.name'),
					shortName: getFormValue('rateInfo.currency.shortName')
				},
				rate: getFormValue('rateInfo.rate'),
				taxRate: getFormValue('rateInfo.taxRate')
			},
			carRequirements: {
				mountingTools: getFormValue('carRequirements.mountingTools') == 'yes',
				truckType: {
					name: getFormValue('carRequirements.truckType.name')
				},
				requirementForEquipment: {
					crossbarRequired: {
						value: getFormValue('carRequirements.requirementForEquipment.crossbarRequired.value') || false
					},
					beltsRequired: {
						value: getFormValue('carRequirements.requirementForEquipment.beltsRequired.value') || false
					},
					fasteningDevicesRequired: {
						value:
							getFormValue('carRequirements.requirementForEquipment.fasteningDevicesRequired.value') ||
							false
					},
					otherRequired: {
						text: getFormValue('carRequirements.requirementForEquipment.otherRequired.text'),
						value: !!getFormValue('carRequirements.requirementForEquipment.otherRequired.text')
					}
				}
			},
			specialConditions: getFormValue('specialConditions')
		};

		delete formValues.companyDescription.gln;
		build.companyDescription = formValues.companyDescription;

		if (routesValues.products.length) {
			build.routes = {
				route: routesValues.products
			};
		}

		return build;
	};
	/**
	 * Saves fns upd
	 */
	var save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = buildDocument(edi.utils.collectFormValues(form));

		var numberAutoGenerated = values.numberAutoGenerated;
		delete values.numberAutoGenerated;

		edi.utils.clearEmptyValues(values);
		var headerData = {
			data: Ext.encode(values),
			date: edi.utils.getObjectProperty(values, 'startDate')
		};

		if (isNumberAutoGenerated && numberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(values, 'offerNumber');
		}

		var success = function (responseData) {
			var finishSaving = function () {
				moduleData.isChanged = false;
				moduleData.tab.setLoading(false);
				edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit);
				if (id) {
					edi.events.documents.fireEvent('change', {
						id: id
					});
				} else {
					edi.events.documents.fireEvent('create');
				}
			};

			var documentId = id || responseData.data.id;
			if (afterSave && documentId) {
				afterSave(documentId, function () {
					edi.document.actions.getDocumentHeaderData(documentId, function (headerData) {
						headerData ? (responseData.data = headerData) : null;
						finishSaving();
					});
				});
			} else {
				finishSaving();
			}
		};
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBF_APPROVAL
		);
		edi.document.actions.processDocument(
			sellerOrg.id,
			undefined,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBF_APPROVAL,
			parentId,
			id,
			success,
			failure,
			headerData
		);
	};
	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		if (data && data.id) {
			isEdit = true;
			id = data.id;
			parentId = data.id;
			sellerOrg = data.toOrg;

			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						documentData = data.data;
						var buyerGln = edi.utils.getObjectProperty(documentData, 'customer.gln');
						edi.utils.setObjectProperty(documentData, 'companyDescription.gln', buyerGln);
						var form = createModuleForm();
						modulePanel.add(form);
						moduleData.tab.add(createModuleActionsPanel());
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			var createModulePanel = function () {
				if (data) {
					sellerOrg = data.toOrg ? data.toOrg : null;
				}

				modulePanel.add(createModuleForm());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);
				seller.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			};
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.EDI_IFTMBF_APPROVAL
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' == docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
