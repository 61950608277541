/*
Список всех возможных статусов для FORMALIZED_DOCUMENT:
DRAFT,SENT,RECEIVER_REVIEW,WAIT_RECEIVER_DECISION,COMPLETED,REJECTED,DP_IZVPOL_RECEIVER,SENDER_REVIEW
*/

const actionRulesForFormalizedDocumentModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {},
	EXPORT_GROUP: {
		OUTGOING: {
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.COMPLETED, edi.constants.STATE.REJECTED]
		},
		INCOMING: {
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.COMPLETED, edi.constants.STATE.REJECTED]
		},
		INCOMING_FACTOR: {
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.COMPLETED, edi.constants.STATE.REJECTED]
		},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		OUTGOING: {
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT],
			SINGLE_APPLY: []
		},
		LOOP: {
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT],
			STATE: [
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SENT,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT],
				states: [edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.SENT],
				directions: [edi.constants.DIRECTIONS.INCOMING],
				allow: function (document) {
					let attrValue = edi.utils.getAttributeByName(document.attributes, 'RECEIVER_SIGNATURE_EXPECTED');
					return attrValue && attrValue == 'true';
				}
			}
		]
	},
	REJECT: {
		INCOMING: {
			STATE: [
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.SENT,
				edi.constants.STATE.WAIT_RECEIVER_DECISION,
				edi.constants.STATE.COMPLETED
			]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.SENT],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT],
				directions: [edi.constants.DIRECTIONS.INCOMING],
				states: [edi.constants.STATE.COMPLETED],
				allow: function (document) {
					var attrValue = edi.utils.getAttributeByName(document.attributes, 'RECEIVER_SIGNATURE_EXPECTED');
					return attrValue && attrValue == 'false';
				}
			}
		]
	},
	ROUTE_REJECT: {
		INCOMING: {
			STATE: []
		},
		OUTGOING: {
			STATE: []
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction === edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction === edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	ROUTE_CONFIRM: {
		INCOMING: {
			STATE: []
		},
		OUTGOING: {
			STATE: []
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction == edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction == edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	CLARIFY: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive;
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT, edi.constants.STATE.REJECTED]
		},
		LOOP: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.REJECTED],
				allow: false
			}
		]
	},
	EDIT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	COMPLETE: {
		INCOMING: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.RECEIVER_REVIEW]
		},
		LOOP: {
			STATE: [edi.constants.STATE.SENDER_REVIEW, edi.constants.STATE.DRAFT]
		},
		OUTGOING: {
			SINGLE_APPLY: [],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	READ: {
		INCOMING: {
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.DP_IZVPOL_RECEIVER,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT],
				states: [edi.constants.STATE.DP_IZVPOL_RECEIVER],
				allow: true
			}
		]
	},
	READ_AND_COMPLETE: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: []
	},
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {
			SINGLE_APPLY: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENDER_REVIEW],
				allow: false
			}
		]
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED, edi.constants.STATE.SENT]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.SENT],
				allow: false
			}
		]
	},
	CONVERT_TO_DRAFT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.REJECTED],
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	COPY: {
		OUTGOING: {
			DOCUMENTS: []
		},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					if (checkOptions.record.get('packageId')) {
						return edi.permissions.hasPermission('UNLINK_PACKAGE_OBJECT');
					} else {
						return (
							edi.permissions.hasPermission('LINK_PACKAGE_OBJECT') ||
							edi.permissions.hasPermission('CREATE_PACKAGE_OBJECT')
						);
					}
				}
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [
				edi.constants.STATE.SENT,
				edi.constants.STATE.RECEIVER_REVIEW,
				edi.constants.STATE.COMPLETED,
				edi.constants.STATE.WAIT_RECEIVER_DECISION
			]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					let annual = true;
					if (checkOptions.annulStatus) {
						annual = [
							edi.constants.STATE.CREATED,
							edi.constants.STATE.DELETED,
							edi.constants.STATE.DRAFT
						].some((it) => it === checkOptions.annulStatus);
					}
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive ? annual : false;
				}
			}
		],
		permissionChanging: []
	},
	CREATE_UKD: {
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	},
	RENOUNCEMENT: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.RECEIVER_REVIEW]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (data) {
					var annulStatus = edi.utils.getAttributeByName(data.attributes, 'ANNUL_STATUS'),
						utochReason = edi.utils.getAttributeByName(data.attributes, 'UTOCH_REASON'),
						rawBpName = edi.utils.getAttributeByName(data.attributes, 'bpName');

					return !(
						utochReason ||
						!edi.permissions.hasPermission('CREATE_REFUSAL_TO_SIGN') ||
						(annulStatus && annulStatus === 'ACTIVE') ||
						!['SCHF', 'SCHFDOP'].some((it) => it === edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[rawBpName])
					);
				}
			}
		]
	},
	CREATE_CUD: {
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.RECEIVER_REVIEW, edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	}
};

// http://confluence.jira.lan:8090/pages/viewpage.action?pageId=42863168
Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT]: actionRulesForFormalizedDocumentModule
});

export { actionRulesForFormalizedDocumentModule };
