import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createFieldSet, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createActionsButton } from '@Components/buttons';
import { createEditableGrid } from '@Core/specialComponents/editableGrid';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';

/**
 * Class for iftmbf details
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.modules');
edi.modules['document.details.iftmbf'] = function () {
	var moduleData,
		docData = null,
		productValues = {
			products: []
		};
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};
	/**
	 * Creates document form
	 * @returns {Object}
	 */
	var createDetailsPanel = function (documentData) {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSumm: true,
			noSignatures: true,
			noUsage: true
		});
		var headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.documentNumber'),
					input: createLabel({
						columnWidth: 0.71,
						text: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.documentNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.7
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.2,
					text: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.documentDate'),
					valueLabel: true
				}),
				createLabel({
					columnWidth: 0.1,
					text: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.documentTime'),
					valueLabel: true
				})
			]
		});

		var uniqueApplicationId = edi.utils.getObjectProperty(documentData, 'documentHeader.documentGuid'),
			uniqueApplicationIdField = uniqueApplicationId
				? createPanel({
						layout: 'column',
						cls: 'edi-create-field-line',
						items: [
							createField({
								title: 'document.iftmbf.uuid',
								input: createLabel({
									columnWidth: 0.8,
									text: uniqueApplicationId,
									valueLabel: true
								}),
								containerConfig: {
									columnWidth: 1
								}
							})
						]
				  })
				: null;

		var createReferenceDocumentsField = function () {
			return createEditableGrid(
				{
					fields: [
						{
							title: edi.i18n.getMessage('document.iftmbf.referenceDocument.orderReferenceNumber'),
							name: 'orderReferenceNumber',
							type: 'text',
							minLength: 1,
							maxLength: 35
						},
						{
							title: edi.i18n.getMessage('document.iftmbf.referenceDocument.forwardingOrder'),
							name: 'forwardingOrder',
							type: 'text',
							minLength: 1,
							maxLength: 20
						}
					],
					readOnly: true,
					limit: 20,
					autoScroll: true,
					model: 'IFTMBF_REF_DOCS',
					gridConfig: {
						margin: '0 5 0 5',
						cls: 'modal-grid',
						minHeight: 200,
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						title: ' '
					}
				},
				edi.utils.getObjectProperty(
					documentData,
					'bookingMessageHeader.bookingMessageTransport.referenceDocuments',
					true
				)
			);
		};

		var parties = edi.utils.getObjectProperty(documentData, 'bookingMessageParties');
		var organizationFieldsMap = {
			company_iln: 'iln',
			company_name: 'name',

			addr_category: 'type',
			addr_country: 'country',
			addr_zip: 'postalCode',
			addr_locality: 'locality',
			addr_block: 'housing',
			addr_addInf: 'additionalInformation',
			addr_area: 'district',
			addr_region_name: 'state',
			addr_region_code: 'stateCode',
			addr_city: 'cityName',
			addr_street: 'streetAndNumber',
			addr_flat: 'roomNumber',
			addr_home: 'houseNumber'
		};
		var getOrganizationFieldsMap = function (prefix) {
			var newOrganizationFieldsMap = {};
			for (var field in organizationFieldsMap) {
				if (organizationFieldsMap.hasOwnProperty(field)) {
					newOrganizationFieldsMap[field] = prefix + '.' + organizationFieldsMap[field];
				}
			}
			if (prefix == 'buyer') {
				newOrganizationFieldsMap.contact_phone = prefix + '.phone';
				newOrganizationFieldsMap.company_org_form = prefix + '.organizationForm';
			}
			return newOrganizationFieldsMap;
		};

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				fieldsMapOnly: true,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				fieldValues: parties
			});

			return createCompanySelector(selectorConfig);
		};

		var seller = createCS({
			title: 'document.seller',
			fieldsMap: getOrganizationFieldsMap('seller')
		});

		var buyer = createCS({
			title: 'document.carrier',
			fieldsMap: getOrganizationFieldsMap('buyer')
		});

		var shipFrom = createCS({
			title: 'document.iftmbf.ship.from',
			fieldsMap: getOrganizationFieldsMap('shipFrom')
		});

		var timeArrival = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.expectedDispatchDate'),
					input: createDateLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.expectedDispatchDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.4
					}
				}),
				createLabel({
					columnWidth: 0.2,
					margin: '0 0 0 5',
					text: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.expectedDispatchTime'),
					valueLabel: true
				})
			]
		});

		var poaDateValue = edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.poaDate');
		if (poaDateValue) {
			poaDateValue = Ext.Date.format(new Date(poaDateValue), edi.constants.DATE_FORMAT.DATE_TIME_FNS);
		}
		var poaDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.poaDate'),
					input: createLabel({
						columnWidth: 0.45,
						text: poaDateValue,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.6
					}
				})
			]
		});

		var driver = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.driver'),
					input: createLabel({
						columnWidth: 0.684,
						text: edi.utils.getObjectProperty(documentData, 'bookingMessageParties.carrier.name'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.7
					}
				}),
				createLabel({
					columnWidth: 0.3,
					margin: '0 0 0 5',
					text: edi.utils.getObjectProperty(documentData, 'bookingMessageParties.carrier.phone'),
					valueLabel: true
				})
			]
		});

		var driverLicense = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.driverLicense'),
					input: createLabel({
						columnWidth: 0.778,
						text: edi.utils.getObjectProperty(documentData, 'bookingMessageParties.carrier.license'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var transportNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.TransportNumber'),
					input: createLabel({
						columnWidth: 0.778,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageParties.carrier.transportNumber'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var TemperatureMode = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.TemperatureMode'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.temperatureMode'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.Tonnage'),
					input: createLabel({
						columnWidth: 0.7,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.tonnage'
						),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var transportType = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.Capacity'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.capacity'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.TransportType'),
					input: createLabel({
						columnWidth: 0.7,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.transportType'
						),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var transportName = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.name'),
					input: createLabel({
						columnWidth: 0.778,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.name'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var transportTotal = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.plannedQuantity'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.plannedQuantity'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.grossWeight'),
					input: createLabel({
						columnWidth: 0.6,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.grossWeight'
						),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var tailAndUnloadingType = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.tailLift'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.renderers.yesNo(
							edi.utils.getObjectProperty(
								documentData,
								'bookingMessageHeader.bookingMessageTransport.tailLift'
							) === 'Yes'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.unloadingType'),
					input: createLabel({
						columnWidth: 0.7,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.unloadingType'
						),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var lengthAndUnloadingHeight = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.lengthTransportType'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.lengthTransportType'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.unloadingHeight'),
					input: createLabel({
						columnWidth: 0.7,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.unloadingHeight'
						),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var TotalNetAmount = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.TotalNetAmount'),
					input: createLabel({
						columnWidth: 0.778,
						text: edi.utils.getObjectProperty(documentData, 'bookingMessageSummary.totalNetAmount'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var twoColumnsLayout = createTwoColumnsLayout(
			[headData, uniqueApplicationIdField, timeArrival, createReferenceDocumentsField(), seller, buyer, shipFrom],
			[
				createFieldSet({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo'),
					padding: '10 0 0 0',
					items: [poaDate, transportNumber, driver, driverLicense]
				}),
				createFieldSet({
					title: edi.i18n.getMessage('document.iftmbf.carParams'),
					items: [TemperatureMode, transportType, tailAndUnloadingType, lengthAndUnloadingHeight]
				}),
				createFieldSet({
					title: edi.i18n.getMessage('document.iftmbf.cargoParams'),
					items: [transportName, transportTotal, TotalNetAmount]
				})
			],
			0.5
		);

		var lines = edi.utils.getObjectProperty(documentData, 'bookingMessageLines.shipTos', true);
		if ('string' != typeof lines) {
			productValues.products = lines;
		}
		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('documents.iftmbf.unloadingPoints'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'IFTMBF_LINE',
			gridColumnConfig: 'iftmbf_lines',
			data: productValues.products,
			hasTotal: false,
			readOnly: true,
			totalsHandler: function (component) {
				component.callback(component.getValues());
			},
			modalFormConfig: {
				title: 'documents.iftmbf.unloadingPoint',
				modalFields: [
					{
						title: edi.i18n.getMessage('documents.iftmbf.line.modal.tab.main.data'),
						items: [
							{
								title: edi.i18n.getMessage('column.iln'),
								name: 'iln',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('column.name'),
								name: 'name'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbf.address'),
								name: 'streetAndNumber'
							},
							{
								title: edi.i18n.getMessage('column.city.name'),
								name: 'cityName'
							},
							{
								title: edi.i18n.getMessage('column.state'),
								name: 'state'
							},
							{
								title: edi.i18n.getMessage('column.state.code'),
								name: 'stateCode',
								type: 'combo',
								store: edi.stores.initRegionsStore(),
								valueField: 'id',
								displayField: 'display_name',
								anyMatch: true
							},
							{
								title: edi.i18n.getMessage('company.country.code'),
								name: 'country',
								type: 'combo',
								store: edi.stores.initValidCountryFullStore(),
								valueField: 'iso_2',
								value: 'RU',
								displayField: 'name'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbf.contact'),
								name: 'contact'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbc.estimatedDispatchDate'),
								name: 'estimatedDispatchDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbc.estimatedDispatchTime'),
								name: 'estimatedDispatchTime'
							}
						]
					}
				]
			}
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [
				headPanel,
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					items: [
						createField({
							input: createLabel({
								margin: '0 50 20 50',
								text: edi.i18n.getMessage('document.iftmbf.intro')
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				}),
				twoColumnsLayout,
				productsGrid
			]
		});
	};

	/**
	 * Creates action pane
	 */
	var createModuleActionsPanel = function (docData) {
		var data = moduleData.initData.data;
		docData.id = data.id;
		var actionsPanel = createActionsPanel();
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);

		var excludeActions = {};
		excludeActions[edi.constants.DOCUMENT_ACTIONS.SIGN] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;

		const customButtons = [];
		if (
			edi.action.isAvailable(
				'CREATE_FROM',
				edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data))
			)
		) {
			const createDocsByTransform = [];
			if (direction === edi.constants.DIRECTIONS.INCOMING) {
				createDocsByTransform.push(
					{
						permission: 'CREATE_EDI_IFTMBC',
						title: 'document.create.iftmbc',
						docType: edi.constants.DOCUMENT_TYPES.EDI_IFTMBC,
						isFromTransformation: true
					},
					{
						permission: 'CREATE_EDI_IFTMBC_APPROVAL',
						title: 'document.create.iftmbc_approval',
						docType: edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL,
						isFromTransformation: true
					}
				);
			}

			const actionCreateDoc = [];
			createDocsByTransform.forEach((action) => {
				if ('string' == typeof action.permission && edi.permissions.hasPermission(action.permission)) {
					actionCreateDoc.push(
						edi.document.actions.createConfigForDocumentCreateButton(
							action.title,
							action.docType,
							'recadv',
							document,
							data,
							moduleData,
							{
								isFromTransformation: action.isFromTransformation,
								buyerOrg: moduleData.initData.data.fromOrg
							}
						)
					);
				}
			});

			if (actionCreateDoc.length) {
				customButtons.push(
					createActionsButton({
						showInFirstHalf: true,
						order: 500,
						text: edi.i18n.getMessage('action.create'),
						glyph: edi.constants.ICONS.ADD,
						allowMultiClick: true,
						menu: {
							plain: true,
							hideMode: 'display',
							items: actionCreateDoc
						}
					})
				);
			}
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			excludeActions: excludeActions,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.iftmbf'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				productValues = {
					products: []
				};
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					docData = data.data;
					var continueLoading = function () {
						modulePanel.add(createDetailsPanel(docData));
						var actionsPanel = createModuleActionsPanel(docData);
						moduleData.tab.add(actionsPanel);
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						} else {
							moduleData.tab.setLoading(false);
						}
					};
					continueLoading();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
