import { AbstractPanel } from './abstract.panel.js';
import { createForm, createPanel } from '@Components/panels.js';
import { createLabel } from '@Components/fields';
import { createFieldBlock } from '@UIkit/components/panels/FieldBlock/FieldBlock.js';

class InvitationsPanel extends AbstractPanel {
	/**
	 * @type {Ext.panel.Panel}
	 * @private
	 */
	_panel;

	constructor() {
		super();

		let form = this.createForm();
		let saveButton = this.createSaveButton(form);

		this._panel = this.createPanel({
			form,
			saveButton
		});

		this.loadFormValuesFromServer();
		this.makePanelConfirmable(this._panel);
	}

	getPanel() {
		return this._panel;
	}

	createPanel({ form, saveButton }) {
		return createPanel({
			layout: 'fit',
			items: [form],
			bbar: {
				padding: '16 24',
				items: [saveButton]
			}
		});
	}

	createForm() {
		return (this._form = createForm({
			padding: 24,
			layout: 'hbox',
			autoScroll: true,
			items: [
				createFieldBlock({
					width: 600,
					title: edi.i18n.getMessage('user.profile.invitations.comment.title'),
					items: [
						this.createField({
							title: edi.i18n.getMessage('user.profile.invitations.comment'),
							isTextarea: true,
							value: null,
							name: 'comment',
							anyMatch: true,
							minHeight: 160
						})
					]
				})
			]
		}));
	}

	defineFieldStates() {
		return {};
	}

	async loadFormValuesFromServer() {
		try {
			this.showLoader();

			let { text } = await this.loadInvitations();

			this.setValues({
				comment: text
			});
		} catch (error) {
			//show error
		} finally {
			this.hideLoader();
		}
	}

	saveFormValues({ comment }) {
		return new Promise((resolve, reject) => {
			edi.rest.sendRequest(
				edi.rest.services.INVITATIONS.TEMPLATE.PUT,
				'PUT',
				Ext.encode({
					text: comment
				}),
				resolve,
				reject
			);
		});
	}

	loadInvitations() {
		return new Promise((resolve, reject) => {
			edi.rest.sendRequest(
				edi.rest.services.INVITATIONS.TEMPLATE.GET,
				'GET',
				null,
				({ data }) => {
					resolve(data);
				},
				reject
			);
		});
	}
}

export { InvitationsPanel };
