/*
Список всех возможных статусов для CONTAINER_COMMON_PRICAT:
DRAFT, RECEIVER_APPROVAL, PROCESSING_TITLES, REJECTED, DELETED, COMPLETED
*/

const actionRulesForContainerCommonPricatModule = {
	QUICK_ANSWER_CONFIRM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					let docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	QUICK_ANSWER_REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (documentData) {
					var docNameCode = edi.utils.getAttributeByName(documentData.attributes, 'docNameCode');
					return (
						!edi.constants.SKIP_QUICK_ANSWER &&
						!!edi.methods.quick_answer.checkDocument(documentData, docNameCode)
					);
				}
			}
		]
	},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: [
			{
				customMethod: function (checkOptions) {
					return !checkOptions.annulNotActive;
				}
			}
		]
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT],
			SINGLE_APPLY: []
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT],
			STATE: [edi.constants.STATE.RECEIVER_APPROVAL, edi.constants.STATE.PROCESSING_TITLES]
		},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT],
				states: [
					edi.constants.STATE.DRAFT,
					edi.constants.STATE.RECEIVER_APPROVAL,
					edi.constants.STATE.PROCESSING_TITLES
				],
				allow: function (documentData) {
					return documentData.direction === edi.constants.DIRECTIONS.INCOMING;
				}
			}
		]
	},
	REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.RECEIVER_APPROVAL, edi.constants.STATE.COMPLETED]
		},
		OUTGOING: {
			STATE: [],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.COMPLETED],
				allow: false
			},
			{
				documents: [edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT],
				states: [edi.constants.STATE.RECEIVER_APPROVAL],
				allow: () => true
			}
		]
	},
	ROUTE_REJECT: {
		INCOMING: {
			STATE: []
		},
		OUTGOING: {
			STATE: []
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction === edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction === edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	ROUTE_CONFIRM: {
		INCOMING: {
			STATE: []
		},
		OUTGOING: {
			STATE: []
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return (
						edi.permissions.hasPermission('ROUTE_DOCUMENTS_FLOW') &&
						((checkOptions.direction === edi.constants.DIRECTIONS.INCOMING &&
							edi.permissions.hasPermission('ROUTE_INBOX_CONFIRM')) ||
							(checkOptions.direction === edi.constants.DIRECTIONS.OUTGOING &&
								edi.permissions.hasPermission('ROUTE_OUTBOX_CONFIRM')))
					);
				}
			}
		]
	},
	CLARIFY: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive;
				}
			}
		]
	},
	DELETE: {
		OUTGOING: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.REJECTED]
		},
		LOOP: {
			SINGLE_APPLY: [],
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: [],
			STATE: []
		},
		permissionChanging: [],
		conditions: [
			{
				states: [edi.constants.STATE.REJECTED],
				allow: false
			}
		]
	},
	EDIT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		INCOMING: {
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	COMPLETE: {
		INCOMING: {
			SINGLE_APPLY: [],
			STATE: []
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		OUTGOING: {
			SINGLE_APPLY: [],
			DOCUMENTS: []
		},
		permissionChanging: [],
		conditions: []
	},
	READ: {
		INCOMING: {
			STATE: []
		},
		permissionChanging: [],
		conditions: []
	},
	READ_AND_COMPLETE: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: []
		},
		permissionChanging: [],
		conditions: []
	},
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			SINGLE_APPLY: []
		},
		permissionChanging: [],
		conditions: []
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {
		INCOMING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	},
	CONVERT_TO_DRAFT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.REJECTED],
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	COPY: {
		OUTGOING: {
			DOCUMENTS: []
		},
		INCOMING: {
			DOCUMENTS: []
		},
		LOOP: {
			DOCUMENTS: []
		},
		permissionChanging: []
	},
	PACKAGING: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				documents: [edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT],
				allow: false
			}
		]
	},
	ARCHIVE_RESTORE: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		permissionChanging: [],
		conditions: [
			{
				allow: function (checkOptions) {
					return checkOptions.record.get('archived');
				}
			}
		]
	},
	ANNUL: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.COMPLETED]
		},
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.COMPLETED]
		},
		conditions: [
			{
				allow: function (checkOptions) {
					let annual = true;
					if (checkOptions.annulStatus) {
						annual = [edi.constants.STATE.DELETED, edi.constants.STATE.DRAFT].some(
							(it) => it === checkOptions.annulStatus
						);
					}
					return !checkOptions.record.get('archived') && checkOptions.annulNotActive ? annual : false;
				}
			}
		],
		permissionChanging: []
	},
	CREATE_UKD: {
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	},
	RENOUNCEMENT: {
		INCOMING: {
			DOCUMENTS: [],
			STATE: []
		},
		permissionChanging: [],
		conditions: [
			{
				allow: function (data) {
					let annulStatus = edi.utils.getAttributeByName(data.attributes, 'ANNUL_STATUS'),
						utochReason = edi.utils.getAttributeByName(data.attributes, 'UTOCH_REASON'),
						rawBpName = edi.utils.getAttributeByName(data.attributes, 'bpName');

					return !(
						utochReason ||
						!edi.permissions.hasPermission('CREATE_REFUSAL_TO_SIGN') ||
						(annulStatus && annulStatus === 'ACTIVE') ||
						!['SCHF', 'SCHFDOP'].some((it) => it === edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[rawBpName])
					);
				}
			}
		]
	},
	CREATE_CUD: {
		OUTGOING: {
			DOCUMENTS: [],
			STATE: [edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT]: actionRulesForContainerCommonPricatModule
});

export { actionRulesForContainerCommonPricatModule };
