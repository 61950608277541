import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { certificatesAPI } from './rest';
import { createFieldBlock, createFieldSet, createModalForm } from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createDetailLabel, createLabel } from '@UIkit/components/fields';
import { LABEL_CLS } from '@UIkit/components/fields/Label/Label';
import { createDeleteButton } from '@Components/buttons';
import { moduleMethods } from '@Edi/modules/power_of_attorney/POA_002/methods';
import { moduleMethods_003 } from '@Edi/modules/power_of_attorney/POA_003/methods_003';

const createCertDetailsModal = function (certId, recordData, options) {
	let modal, certData, poaHeader;
	const poaData = recordData.poaData ?? options?.poaData;
	const poaInfo = recordData.poaInfo ?? options?.poaInfo;
	const poaHeaderId = poaData?.header;

	const createModalItems = function () {
		let statusKey = edi.i18n.getMessage('certificate.status.' + recordData.state);
		let statusValue = edi.renderers.baseStateRenderer(recordData.state, edi.i18n.getMessage(statusKey));

		let status = createLabel({
			cls: 'edi-ediweb-cert-status',
			html:
				'<span class="edi-ediweb-cert-status-label">' +
				edi.i18n.getMessage('document.status') +
				' — </span>' +
				'<span class="edi-ediweb-cert-status-container">' +
				statusValue +
				'</span>'
		});

		let serialNumber = createFieldBlock({
			cls: 'edi-ediweb-details-block-with-label',
			title: edi.i18n.getMessage('column.serial.number'),
			items: [
				createLabel({
					cls: LABEL_CLS.breakAll,
					text: certData.serialNumber
				})
			]
		});

		let thumbprint = createFieldBlock({
			cls: 'edi-ediweb-details-block-with-label',
			title: edi.i18n.getMessage('column.thumbprint'),
			items: [
				createLabel({
					cls: LABEL_CLS.breakAll,
					text: certData.thumbprint
				})
			]
		});

		let issuerName = createFieldBlock({
			cls: 'edi-ediweb-details-block-with-label',
			title: edi.i18n.getMessage('column.issuer.name'),
			items: [
				createLabel({
					cls: LABEL_CLS.breakAll,
					text: certData.issuerName
				})
			]
		});

		let subjectName = createFieldBlock({
			cls: 'edi-ediweb-details-block-with-label',
			title: edi.i18n.getMessage('column.subject.name'),
			items: [
				createLabel({
					cls: LABEL_CLS.breakAll,
					text: certData.subjectName
				})
			]
		});

		var validFrom = edi.renderers.docTime(certData.validFromDate);
		var validTo = edi.renderers.docTime(certData.validToDate);

		let active = createFieldBlock({
			cls: 'edi-ediweb-details-block-with-label',
			title: edi.i18n.getMessage('ediweb.certificates.validDate'),
			items: [
				createLabel({
					cls: 'edi-ediweb-fields-block-label',
					text: validFrom + ' — ' + validTo
				})
			]
		});

		let powerLists;
		if (poaInfo) {
			if (poaHeader?.versionFormat !== 'EMCHD_1') {
				const svPoln = Array.isArray(poaInfo.svPoln) ? poaInfo.svPoln : [poaInfo.svPoln];
				powerLists = { powerLists: svPoln };
			} else {
				powerLists = {
					svPolnLists: poaInfo.svPoln,
					mashPolnLists: poaInfo.svPoln?.mashPoln
				};
			}
		}

		const poaState = poaHeader?.state ?? poaInfo?.poaState;

		const poaFieldSet =
			poaData && (poaHeader || poaInfo)
				? createFieldSet({
						title: edi.i18n.getMessage('documents.doctype.POWER_OF_ATTORNEY'),
						collapsible: false,
						cls:
							poaState === edi.constants.STATE.REVOKED || poaState === edi.constants.STATE.EXPIRED
								? 'edi-ediweb-fieldset-error'
								: null,
						checkboxToggle: true,
						createCheckboxCmp: function () {
							return createLabel({
								margin: '0 0 0 8',
								html: edi.renderers.status(edi.renderers.powerOfAttorneyFixStatusCode(poaState))
							});
						},
						layout: {
							type: 'grid',
							gap: [16, 0]
						},
						items: [
							createFieldBlock({
								title: edi.i18n.getMessage('power.of.attorney.external.number'),
								items: [
									createDetailLabel({
										text: poaHeader?.poaNumber ?? poaInfo?.doverRegNumber
									})
								]
							}),
							createFieldBlock({
								title: edi.i18n.getMessage('power.of.attorney.valid'),
								items: [
									createDetailLabel({
										text:
											edi.renderers.dateFromMs(poaHeader?.validFrom ?? poaInfo?.dataVidDover) +
											' - ' +
											edi.renderers.dateFromMs(poaHeader?.validTo ?? poaInfo?.dataKonDover)
									})
								]
							}),
							poaHeader?.versionFormat === 'EMCHD_1'
								? moduleMethods_003.createPowerListSection(powerLists, true)
								: moduleMethods.createPowerListSection(powerLists, true)
						]
				  })
				: null;

		return createModalForm({
			layout: {
				type: 'grid',
				gap: [24]
			},
			items: [status, serialNumber, thumbprint, issuerName, subjectName, active, poaFieldSet]
		});
	};

	const createModalButtons = function () {
		let buttons = [];
		const pushPB = function (id) {
			modal.setLoading();
			const success = function () {
				modal.close();
				edi.events.certificates.fireEvent('change');
			};
			const failure = edi.document.actions.defaultFailureHandler(modal, 'error.server');
			const url = edi.utils.formatString(certificatesAPI.DEACTIVATE.PUT, { id: id });

			edi.rest.sendRequest(url, 'PUT', Ext.encode({}), success, failure);
		};

		const deleteCertificate = function (id) {
			modal.setLoading();
			const success = function () {
				modal.close();
				edi.events.certificates.fireEvent('change');
			};
			const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.SEND.PUT, { documentId: id });
			const failure = edi.document.actions.defaultFailureHandler(modal, 'document.error.in.delete.process');
			const postData = {
				[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE]: true
			};

			edi.rest.sendRequest(url, 'PUT', Ext.encode(postData), success, failure);
		};
		let deactivateBtn = createButton({
			text: edi.i18n.getMessage('form.btn.deactivate'),
			glyph: edi.constants.ICONS.ANNUL,
			handler: function () {
				edi.core.confirm(null, 'user.profile.certificate.deactivate.confirmation', function () {
					pushPB(recordData.id);
				});
			}
		});

		if (options?.showPoaButtons === true) {
			let poaAddBtn = createButton({
				text: edi.i18n.getMessage('add.power.of.attorney.btn'),
				handler: function () {
					edi.methods.poa.selectPoA(recordData.data, false, function (poa) {
						modal.setLoading();

						const fail = edi.rest.getErrorHandler(null, function () {
							if (modal && !modal.isDestroyed) {
								modal.setLoading(false);
							}
						});
						const success = function () {
							modal.close();
							edi.events.certificates.fireEvent('change');
						};
						let url = edi.utils.formatString(edi.rest.services.USER.POA.LINK_BY_CERT.POST, {
							id: recordData.header.id,
							poaId: poa.docId
						});
						edi.rest.sendRequest(url, 'POST', null, success, fail);
					});
				}
			});

			let poaDetachBtn = createButton({
				text: edi.i18n.getMessage('detach.power.of.attorney.btn'),
				handler: function () {
					modal.setLoading(true);

					const fail = edi.rest.getErrorHandler(null, function () {
						if (modal && !modal.isDestroyed) {
							modal.setLoading(false);
						}
					});
					const success = function () {
						modal.close();
						edi.events.certificates.fireEvent('change');
					};
					let url = edi.utils.formatString(edi.rest.services.USER.POA.DELETE, {
						id: recordData.header.id,
						poaId: poaHeaderId
					});
					edi.rest.sendRequest(url, 'DELETE', null, success, fail);
				}
			});

			if (poaHeaderId) {
				buttons.push(poaDetachBtn);
			}
			if (recordData.state === edi.constants.STATE.ACTIVE) {
				if (!poaHeaderId) {
					buttons.push(poaAddBtn);
				}
				buttons.push(deactivateBtn);
			}
		}

		buttons.forEach(function (btn, index) {
			if (index === 0) {
				btn.addCls(BUTTON_CLS.primary);
				btn.removeCls(BUTTON_CLS.secondary);
			} else {
				btn.removeCls(BUTTON_CLS.primary);
				btn.addCls(BUTTON_CLS.secondary);
			}
		});
		if (recordData.state === edi.constants.STATE.DRAFT) {
			buttons.push(
				createDeleteButton(
					function () {
						edi.core.confirm(null, 'user.profile.certificate.remove.confirmation', function () {
							deleteCertificate(recordData.id);
						});
					},
					{
						disabled: false,
						cls: BUTTON_CLS.primary
					}
				)
			);
		}

		return buttons;
	};

	const renderItems = function () {
		modal.add(createModalItems());
		setTimeout(function () {
			modal.center();
			modal.setLoading(false);
		}, 100);
	};

	const getPoaHeader = function () {
		if (!poaHeaderId) {
			renderItems();
			return;
		}

		const fail = edi.rest.getErrorHandler(null, function () {
			if (modal && !modal.isDestroyed) {
				modal.close();
			}
		});
		const success = function (resp) {
			let poas = resp.data.result;
			let linkedPoa = poas.find(
				(item) =>
					edi.utils.getAttributeByName(item.attributes, 'POA_HAS_LINK') === 'true' && !!item.poaHasLinked
			);
			poaHeader = linkedPoa ?? {};
			renderItems();
		};

		let url = edi.rest.services.POWER_OF_ATTORNEY.SEARCH_BY_CERT.POST;
		edi.rest.sendRequest(url, 'POST', Ext.encode(certData), success, fail);
	};

	const getCertData = function () {
		if (options?.certData) {
			certData = options.certData;
			getPoaHeader();
			return;
		}

		const fail = edi.rest.getErrorHandler(null, function () {
			if (modal && !modal.isDestroyed) {
				modal.close();
			}
		});
		const success = function (resp) {
			certData = resp.data;
			getPoaHeader();
		};
		const url = edi.utils.formatString(options?.certContentUrl ?? certificatesAPI.CONTENT.GET, { id: certId });
		edi.rest.sendRequest(url, 'GET', null, success, fail);
	};

	modal = createModalPanel({
		title: edi.i18n.getMessage('certificate.title'),
		width: MODAL_SIZE.widthMedium,
		minHeight: 250,
		items: [],
		buttons: createModalButtons(),
		listeners: {
			afterrender: getCertData
		}
	});
	modal.show();
	modal.setLoading(true);
};

export { createCertDetailsModal };
