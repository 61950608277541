import { createLabelBlockForDetails } from '@UIkit/components/fields';
import {
	createButtonContainer,
	createFieldBlock,
	createModalForm,
	FIELD_BLOCK_CLS,
	createContainer
} from '@UIkit/components/panels';
import { createActionsPanel, createAddModulePanel, createForm, createFormForModule } from '@Components/panels';
import { createCombo, createFile, createLabel } from '@Components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createActionsColumnConfig, createGrid, ROW_COLOR_CLS } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { getDocumentFormsNE, dpContainerSign } from '../methods';
import { getPackageAttachColumns, getPackageStatutoryColumns } from '../columns';
import { getPackageAttachModelName } from '../models';
import { agreementPackagesAPI } from '../rest';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createAddButton, createCreateButton, createSendButton } from '@Components/buttons';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';

/**
 * @author Vsevolod Liapin
 *
 */
Ext.namespace('edi.modules');
edi.modules['document.details.package.ne'] = function () {
	var moduleData,
		packageId,
		isNumberAutoGenerated = false,
		id,
		userData = edi.core.getUserData(),
		isEdit = false,
		isSent,
		isCreate = false,
		buyerOrg,
		seller,
		buyer,
		attachGrid,
		isCreateDigPost,
		form,
		signPackageButton,
		documentData,
		documentsFormValues,
		direction,
		attachDocuments,
		statutoryDocuments,
		statutoryGridStore,
		packageDocTypes,
		dogFileId,
		statutoryAddButton,
		selectedStatutoryDocType,
		statutoryDocTypeStore,
		utochDocsReason = {},
		sortedGridItems = [],
		docType,
		docNumberByType = null;
	const documentFormsNE = getDocumentFormsNE();

	var initVariables = function () {
		if (moduleData.initData.data && moduleData.initData.data.toOrg) {
			documentData = moduleData.initData.data;
			buyerOrg = moduleData.initData.data.toOrg;
			moduleData.initData.docExportUri = edi.utils.formatString(
				edi.rest.services.DOCUMENTS.EXPORT.COMMON,
				{
					documentId: moduleData.initData.id
				},
				true
			);
			isEdit = true;
			isCreate = true;
		}
		isNumberAutoGenerated = false;
		documentsFormValues = {};
		attachDocuments = [];
		packageDocTypes = [];
		statutoryDocTypeStore = edi.stores.createSimpleInlineStore([]);
		selectedStatutoryDocType = Ext.clone(edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES[userData.org.orgType]);
		direction = edi.utils.getDocumentDirection(documentData.toOrg, documentData.fromOrg);
	};

	var failureSign = function (data) {
		edi.document.actions.defaultFailureHandler(moduleData.tab, 'document.mark.sign.error.process')(data);
	};

	var signStatutoryDocuments = function (recordData, callback) {
		edi.utils.sign(
			recordData,
			moduleData.tab,
			function (failed, data) {
				if (failed) {
					failureSign(data);
				} else {
					pushContainerToBp(recordData.id, callback, failureSign);
				}
			},
			undefined,
			undefined,
			true,
			null,
			{
				signRefuse: () => moduleData.tab?.setLoading(false)
			}
		);
	};

	var pushContainerToBp = function (containerId, callback, failure) {
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SEND.PUT,
				{
					documentId: containerId
				},
				true
			),
			'PUT',
			Ext.encode({}),
			callback,
			failure
		);
	};

	var signDocumentAndPushContainer = function (docId, signDocMethod, callback, notCheckState) {
		notCheckState = notCheckState || false;
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.PARENTS.GET, {
				documentId: docId
			}),
			'GET',
			{},
			function (parents) {
				var transportContainer;
				if (parents && parents.items && Array.isArray(parents.items)) {
					transportContainer = parents.items.find(function (parent) {
						return (
							parent.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE &&
							packageId != parent.id &&
							(parent.state === edi.constants.STATE.DRAFT ||
								parent.state === edi.constants.STATE.SENDER_REVIEW ||
								notCheckState)
						);
					});
				}

				if (transportContainer) {
					pushContainerToBp(
						transportContainer.id,
						function () {
							signDocMethod(function () {
								pushContainerToBp(transportContainer.id, callback, failureSign);
							});
						},
						failureSign
					);
				}
			}
		);
	};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docType = moduleData.initData.docType;
		initVariables();
		//т.к. данные fromOrg/toOrg в общем гриде "Документы" и при запросе с бэка (кнопка "обновить данные") различаются
		//перезагрузим модуль для получения данных с бэка
		changeHandler(moduleData.initData.data, initCallBack);
		switch (docType) {
			case edi.constants.DOCUMENT_TYPES.DP_PRILUVIZMCENNE:
				docNumberByType = '3';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILSHSVERNE:
				docNumberByType = '9';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILFORMZVTNE:
				docNumberByType = '10';
				break;
			case edi.constants.DOCUMENT_TYPES.DP_PRILFAOPTUZNE:
				docNumberByType = '11';
				break;
		}
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documentsPackage.on('changeAttaches', changeAttachesHandler);
		edi.events.documentsPackage.on('change', changeHandler);
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isBuyerValid = buyer.isValid();
		var isSellerValid = seller.isValid();
		return isBuyerValid && isSellerValid;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		form.isValid();
		var validForm = !form.hasInvalidField();
		var validCompanies = validateCompanySelectors();
		var isValid = validForm && validCompanies;
		var validAttachDocs = !attachDocuments.some((item) => !item.isAttach);

		if (signPackageButton && !signPackageButton.isDestroyed) {
			signPackageButton.setDisabled(!isValid);
		}

		if (attachGrid) {
			attachGrid.getStore().reload();
		}

		if (statutoryAddButton) {
			if (isValid && packageId) {
				statutoryAddButton.enable();
			} else {
				statutoryAddButton.disable();
			}
		}
		return isValid && validAttachDocs;
	};

	/**
	 * Create invoice form
	 */
	var createMainDataBlock = function () {
		const headData = createContainer({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: 'column',
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text:
								isEdit && documentsFormValues.hasOwnProperty('documentInfo')
									? edi.utils.getObjectProperty(documentsFormValues, 'documentInfo.documentNumber')
									: edi.utils.getObjectProperty(documentData, 'number') || docNumberByType
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.utils.getObjectProperty(documentsFormValues, 'documentInfo.documentDate')
						}
					]
				})
			]
		});

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				selectedRelationByMap: true,
				useHiddenFields: true,
				disableAutoValidation: true,
				fieldsMapOnly: true,
				readOnly: true,
				allowBlank: true,
				is_valid: true
			});

			return createOrgSelector(selectorConfig);
		};

		var organizationFieldsMap = {
			company_iln: 'company_iln',
			company_name: 'company_name',
			contact_phone: 'contact_phone',
			contact_email: 'contact_email',
			contact_fax: 'contact_fax',
			addr_category: 'addr_category',
			addr_zip: 'addr_zip',
			addr_locality: 'addr_locality',
			addr_block: 'addr_block',
			addr_addInf: 'additionalInformation',
			addr_area: 'addr_area',
			addr_flat: 'addr_flat',
			addr_home: 'addr_home',
			addr_region_code: 'addr_region_code',
			addr_city: 'addr_city',
			addr_country: 'addr_country',
			addr_country_name: 'addr_country_name',
			addr_region_name: 'addr_region_name',
			addr_street: 'addr_street'
		};

		/**
		 * Checks company data validation
		 */
		var isValidOrganization = function (params) {
			params = params || {};

			var organization = params.organization;
			var useOrganizationMap = params.useOrganizationMap;

			function getPropertyName(keyName) {
				return useOrganizationMap ? organizationFieldsMap[keyName] : keyName;
			}

			return !!edi.utils.getObjectProperty(organization, getPropertyName('company_iln'));
		};

		var getOrganizationFieldsMap = function (prefix) {
			var newOrganizationFieldsMap = {};
			for (var field in organizationFieldsMap) {
				if (organizationFieldsMap.hasOwnProperty(field)) {
					newOrganizationFieldsMap[field] = prefix + '.' + organizationFieldsMap[field];
				}
			}
			return newOrganizationFieldsMap;
		};

		var modalConf = {
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_inn: edi.selectors.defaults.company_inn,
					company_register_number: edi.selectors.defaults.company_register_number,
					contact: edi.selectors.defaults.contact
				},
				address: {
					country: edi.selectors.defaults.iso2_country_combo,
					region: edi.selectors.defaults.region,
					city: edi.selectors.defaults.city,
					street: edi.selectors.defaults.street
				}
			}
		};

		const sellerOrg = edi.utils.getObjectProperty(documentData, 'fromOrg');
		const sellerValues = edi.converters.convertOrgToLegacyPartie(sellerOrg);

		seller = createCS({
			selectedRelationByMap: isEdit,
			useHiddenFields: true,
			fieldValues: sellerValues,
			selectedOrgValues: sellerValues,
			selectedOrg: sellerOrg,
			disableAutoValidation: true,
			fieldsMap: getOrganizationFieldsMap('bookingResponseParties.seller'),
			modalConf: Object.assign(
				{
					title: 'document.seller'
				},
				modalConf
			)
		});
		const sellerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.seller'),
			items: [seller]
		});

		var buyerInfo = edi.converters.convertOrgToLegacyPartie(buyerOrg),
			isBuyerDefined = !!buyerInfo;

		var isValidBuyer = isValidOrganization({
			organization: buyerInfo,
			useOrganizationMap: false,
			organizationType: 'buyer'
		});

		buyer = createCS({
			fieldValues: buyerInfo,
			selectedOrgValues: buyerInfo,
			selectedOrg: buyerOrg ? buyerOrg : null,
			fieldsMap: organizationFieldsMap,
			modalConf: Object.assign(
				{
					title: 'document.buyer'
				},
				modalConf
			)
		});
		const buyerBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.buyer'),
			items: [buyer]
		});

		return createContainer({
			layout: {
				type: 'grid',
				area: [12, [6, 6]]
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [headData, sellerBlock, buyerBlock]
		});
	};

	var createDocumentsAttachGrid = function () {
		var addedDocTypes = Ext.Array.difference(
			edi.constants.DOCUMENT_PACKAGES_NE_INCLUDES_DOC_TYPES,
			packageDocTypes
		);
		var gridItems = Ext.clone(attachDocuments);
		for (var i = 0; i < addedDocTypes.length; i++) {
			gridItems.push({
				id: null,
				type: addedDocTypes[i],
				isAttach: false
			});
		}

		var sortedDocTypes = [
			edi.constants.DOCUMENT_TYPES.DP_DOGPOSTNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILPROTSOGLNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILORGEDOCNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILUVIZMCENNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILOSTRBNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILCOMMUSLNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILSANKNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILKPOSTNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILKTOVNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILSHSVERNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILFORMZVTNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILFAOPTUZNE,
			edi.constants.DOCUMENT_TYPES.DP_PRILANTIKORNE
		];

		gridItems.forEach(function (item) {
			var index = sortedDocTypes.indexOf(item.type);
			sortedGridItems[index] = item;
		});

		var columns = getPackageAttachColumns();
		var actionColumnsConfig = [
			{
				text: edi.i18n.getMessage('form.btn.add'),
				tooltip: edi.i18n.getMessage('form.btn.add'),
				glyph: edi.constants.ICONS.ADD,
				isHidden: function (view, rowIndex, colIndex, item, record) {
					var recordData = record.getData();
					return (
						recordData.state != edi.constants.STATE.UTOCH ||
						edi.constants.DOCUMENT_TYPES.DP_DOGPOSTNE == recordData.type
					);
				},
				handler: function (grid, rowIndex) {
					var store = grid.getStore();
					var recordData = store.getAt(rowIndex).getData();
					var failure = edi.document.actions.createSaveErrorHandler(
						isEdit,
						moduleData,
						edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE
					);

					if (
						documentFormsNE.hasOwnProperty(recordData.type) &&
						recordData.state == edi.constants.STATE.UTOCH
					) {
						var getCreateUri = function (docType, isFormPdf) {
							if (
								docType === edi.constants.DOCUMENT_TYPES.DP_PRILSANKNE ||
								docType === edi.constants.DOCUMENT_TYPES.DP_PRILANTIKORNE ||
								docType === edi.constants.DOCUMENT_TYPES.DP_PRILORGEDOCNE
							) {
								return agreementPackagesAPI.UTOCH_WITH_CONTAINER.ON_CREATE;
							}
							return isFormPdf
								? agreementPackagesAPI.UTOCH_WITH_CONTAINER.PDF_POST
								: agreementPackagesAPI.UTOCH_WITH_CONTAINER.POST;
						};

						var isFormPdf =
							recordData.type === edi.constants.DOCUMENT_TYPES.DP_PRILUVIZMCENNE ||
							recordData.type === edi.constants.DOCUMENT_TYPES.DP_PRILSHSVERNE ||
							recordData.type === edi.constants.DOCUMENT_TYPES.DP_PRILFORMZVTNE ||
							recordData.type === edi.constants.DOCUMENT_TYPES.DP_PRILFAOPTUZNE ||
							recordData.type === edi.constants.DOCUMENT_TYPES.DP_PRILSANKNE ||
							recordData.type === edi.constants.DOCUMENT_TYPES.DP_PRILANTIKORNE ||
							recordData.type === edi.constants.DOCUMENT_TYPES.DP_PRILORGEDOCNE;
						var docType = isFormPdf ? recordData.type : recordData.type + '_P1';
						var url = getCreateUri(recordData.type, isFormPdf);
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.FOR_PARENT.GET, {
								parentId: isFormPdf ? packageId : recordData.id,
								docType: docType
							}),
							'GET',
							{},
							function (data) {
								if (data && data.data && data.data) {
									edi.core.openModule(
										'package.ne.document.form',
										undefined,
										undefined,
										false,
										'dp-attach-form-' + recordData.type + '-new',
										{
											title: edi.i18n.getMessage('documents.doctype.' + recordData.type),
											docType: recordData.type,
											formConfig: documentFormsNE[recordData.type],
											formValues: data.data,
											packageId: packageId,
											buyerOrg: buyerOrg,
											dogFileId: dogFileId,
											isCreateUtoch: true,
											createUri: edi.utils.formatString(url, {
												containerId: packageId,
												containerType: 'dp_container_ne'
											}),
											documentHasCreated: function (
												formValues,
												documentId,
												callback,
												finishSaving
											) {
												edi.events.documentsPackage.fireEvent('change', {
													id: packageId
												});
												finishSaving();
											}
										}
									);
								} else {
									failure(data);
								}
							},
							failure
						);
					}
				}
			},
			{
				glyph: edi.constants.ICONS.PRINT,
				testCls: 'test-action-column-print',
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return !record.get('id');
				},
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex),
						recordData = record.getData();
					edi.document.actions.createPrintModal(
						recordData,
						{
							usingReport: true
						},
						recordData.id
					);
				}
			},
			{
				glyph: edi.constants.ICONS.DETAILS,
				testCls: 'test-action-column-details',
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return !record.get('id');
				},
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex),
						recordData = record.getData();
					edi.core.openModule(
						'package.ne.document.form',
						recordData,
						edi.i18n.getMessage('documents.doctype.' + recordData.type),
						false,
						'dp-attach-form-' + recordData.type + '-' + recordData.id,
						{
							title: edi.i18n.getMessage('documents.doctype.' + recordData.type),
							docType: recordData.type,
							formConfig: documentFormsNE[recordData.type],
							packageId: packageId,
							buyerOrg: buyerOrg,
							dogFileId: dogFileId,
							containerNotDraft:
								moduleData.initData.data.state &&
								moduleData.initData.data.state != edi.constants.STATE.DRAFT,
							isReadOnly: true,
							dogPostData: documentsFormValues
						}
					);
				}
			}
		];
		columns.push(
			createActionsColumnConfig({
				align: 'right',
				items: actionColumnsConfig
			})
		);

		return createGrid({
			proxyConfig: createProxyConfig(),
			storeConfig: {
				model: getPackageAttachModelName(),
				data: {
					items: sortedGridItems
				}
			},
			gridConfig: {
				title: edi.i18n.getMessage('document.package.agreement.attachments'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columns: columns,
				region: 'center',
				disablePaging: true,
				listeners: {
					itemmouseenter: function (gridView, record, item) {
						var recordData = record.getData();
						if (recordData.state == edi.constants.STATE.UTOCH && utochDocsReason[recordData.type]) {
							Ext.fly(item).set({
								'data-qtip': utochDocsReason[recordData.type]
							});
						}
					}
				}
			},
			viewConfig: {
				getRowClass: function (record) {
					return !record.get('isAttach') ? ROW_COLOR_CLS.error : '';
				}
			}
		});
	};

	var createStatutoryDocumentsGrid = function () {
		var columns = getPackageStatutoryColumns();
		var actionButtonConfig = [
			{
				glyph: edi.constants.ICONS.CLOUD_DOWNLOAD,
				testCls: 'test-action-column-download',
				text: edi.i18n.getMessage('action.export.attachment'),
				handler: function (grid, rowIndex) {
					var store = grid.getStore();
					var recordData = store.getAt(rowIndex).getData();
					var url = edi.document.actions.formatExportAttachementUrl({
						id: recordData.id
					});
					edi.rest.downloadFile(url, recordData.id);
				}
			},
			{
				glyph: edi.constants.ICONS.PRINT,
				testCls: 'test-action-column-print',
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return !record.get('id') || record.get('type') !== edi.constants.DOCUMENT_TYPES.DP_PRAZN;
				},
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex),
						recordData = record.getData();
					edi.document.actions.createPrintModal(
						recordData,
						{
							usingReport: true
						},
						recordData.id
					);
				}
			}
		];

		if (direction == edi.constants.DIRECTIONS.OUTGOING) {
			actionButtonConfig.unshift({
				text: edi.i18n.getMessage('document.create.clarify'),
				getGlyph: function (value, metadata, record) {
					return record.get('state') == edi.constants.STATE.UTOCH
						? edi.constants.ICONS.ANNOUNCEMENT
						: edi.constants.ICONS.SIGN;
				},
				isHidden: function (view, rowIndex, colIndex, item, record) {
					var docState = record.get('state');
					return (
						!(
							docState === edi.constants.STATE.UTOCH ||
							(moduleData.initData.data.state !== edi.constants.STATE.DRAFT &&
								docState === edi.constants.STATE.DRAFT)
						) || edi.constants.DOCUMENT_TYPES.DP_PRAZN === record.get('type')
					);
				},
				handler: function (grid, rowIndex) {
					var store = grid.getStore();
					var recordData = store.getAt(rowIndex).getData();

					if (recordData.state === edi.constants.STATE.UTOCH) {
						var uploadForm = createModalForm({
							submitEmptyText: false,
							items: [
								createFieldBlock({
									cls: FIELD_BLOCK_CLS.small,
									title: edi.i18n.getMessage('document.file'),
									items: [
										createFile(
											{},
											{
												name: 'attachment',
												listeners: edi.fields.config.pdfFilefieldListeners
											}
										)
									]
								}),
								createLabel({
									text: edi.i18n.getMessage('document.package.upload.file.limit.text')
								})
							]
						});

						var modal = createModalPanel({
							title: edi.i18n.getMessage('document.package.agreement.statutory.document.upload'),
							width: MODAL_SIZE.widthLarge,
							items: [uploadForm],
							buttonsBefore: [
								createCreateButton(
									function () {
										var failure = function (data) {
											edi.core.logMessage(
												'Error uploading dsf file' +
													(data && data.status ? ' status - ' + data.status : ''),
												'warn'
											);
											edi.core.showError(
												edi.utils.formatComplexServerError(
													data,
													data && data.status
														? 'error.server.' + data.status
														: 'dsf.upload.failure'
												),
												function () {
													moduleData.tab.setLoading(false);
												}
											);
										};

										edi.core.submitUploadForm(
											uploadForm,
											edi.rest.services.DSF.FILE.POST,
											'dsf.create.saving',
											function (responseData) {
												if (responseData && responseData.data && responseData.data.id) {
													edi.document.actions.processDocument(
														buyerOrg.id,
														undefined,
														recordData.type,
														packageId,
														undefined,
														function (utochDoc) {
															edi.events.documentsPackage.fireEvent('changeAttaches', {
																id: packageId
															});
															modal.close();
														},
														failure,
														{
															data: Ext.encode({ file: responseData.data.id }),
															date: Ext.Date.format(new Date(), 'd-m-Y')
														},
														{
															createUri: edi.utils.formatString(
																agreementPackagesAPI.UTOCH.POST,
																{
																	containerId: packageId
																}
															)
														}
													);
												} else {
													failure(responseData);
												}
											},
											failure
										);
									},
									{
										scale: 'small',
										bindToForm: form,
										formBind: true
									}
								)
							]
						});
						modal.show();
					} else if (recordData.state == edi.constants.STATE.DRAFT) {
						signDocumentAndPushContainer(
							recordData.id,
							function (continueMethod) {
								edi.utils.sign(
									recordData,
									moduleData.tab,
									function (failed, data) {
										if (failed) {
											failureSign(data);
										} else {
											continueMethod();
										}
									},
									undefined,
									undefined,
									true,
									null,
									{
										signRefuse: () => moduleData.tab?.setLoading(false)
									}
								);
							},
							function () {
								edi.events.documentsPackage.fireEvent('change', {
									id: packageId
								});
							}
						);
					}
				}
			});
			actionButtonConfig.push({
				getGlyph: function (value, metadata, record) {
					var annulState = edi.utils.getAttributeByName(record.get('attributes'), 'ANNUL_STATUS');
					return !annulState ? edi.constants.ICONS.ANNOUNCEMENT : edi.constants.ICONS.SIGN;
				},
				text: edi.i18n.getMessage('document.mark.annul'),
				isHidden: function (view, rowIndex, colIndex, item, record) {
					var recordData = record.getData();
					if (edi.constants.DOCUMENT_TYPES.DP_PRAZN != recordData.type) {
						return true;
					} else {
						var annulState = edi.utils.getAttributeByName(recordData.attributes, 'ANNUL_STATUS');
						return (
							recordData.state != edi.constants.STATE.SENT ||
							(annulState && annulState != edi.constants.STATE.DRAFT)
						);
					}
				},
				handler: function (grid, rowIndex) {
					var store = grid.getStore();
					var recordData = store.getAt(rowIndex).getData();
					var annulState = edi.utils.getAttributeByName(recordData.attributes, 'ANNUL_STATUS');
					var mainSuccess = function () {
						if (modal) {
							modal.close();
						}
						edi.events.documentsPackage.fireEvent('change', {
							id: packageId
						});
					};

					var findAndSignAnnulDoc = function (annulContainerId) {
						var signAnnul = function (signData) {
							pushContainerToBp(
								annulContainerId,
								function () {
									edi.utils.sign(
										signData,
										moduleData.tab,
										function (failed, data) {
											if (failed) {
												failureSign(data);
											} else {
												pushContainerToBp(
													annulContainerId,
													function () {
														pushContainerToBp(annulContainerId, mainSuccess, failureSign);
													},
													failureSign
												);
											}
										},
										undefined,
										undefined,
										true,
										null,
										{
											signRefuse: () => moduleData.tab?.setLoading(false)
										}
									);
								},
								failureSign
							);
						};

						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
								documentId: annulContainerId
							}),
							'GET',
							undefined,
							function (annulDocData) {
								if (
									annulDocData.success &&
									annulDocData.data &&
									Array.isArray(annulDocData.data.children) &&
									annulDocData.data.children.length &&
									annulDocData.data.children[0].type ==
										edi.constants.DOCUMENT_TYPES.DP_CONTAINER_PRANNUL
								) {
									if (annulDocData.data.children[0].state == edi.constants.STATE.DRAFT) {
										pushContainerToBp(
											annulDocData.data.children[0].id,
											function () {
												signAnnul({
													id: annulDocData.data.children[0].id,
													type: annulDocData.data.children[0].type
												});
											},
											failureSign
										);
									} else if (annulDocData.data.children[0].state == 'SENDER_REVIEW') {
										signAnnul({
											id: annulDocData.data.children[0].id,
											type: annulDocData.data.children[0].type
										});
									}
								}
							},
							failureSign
						);
					};

					if (annulState && annulState == edi.constants.STATE.DRAFT) {
						findAndSignAnnulDoc(recordData.id);
					} else if (!annulState) {
						var modal = edi.methods.documents.showReasonModal(
							'document.mark.annul',
							function (reasonText) {
								edi.rest.sendRequest(
									edi.utils.formatString(agreementPackagesAPI.ANNUL.POST, {
										docId: recordData.id,
										containerType: 'dp_container_ne'
									}),
									'POST',
									Ext.encode({ reason: reasonText }),
									function (annulData) {
										if (annulData.data) {
											findAndSignAnnulDoc(annulData.data.id);
										}
									},
									failureSign
								);
							},
							{
								type: 'combo',
								valueField: 'name',
								store: edi.stores.createSimpleInlineStore(['wait', 'protocol'], function (id) {
									return edi.i18n.getMessage('document.package.agreement.pr.annulReason.' + id);
								})
							}
						);
					}
				}
			});

			if (
				moduleData.initData.data.state &&
				(moduleData.initData.data.state == edi.constants.STATE.SENT ||
					moduleData.initData.data.state == edi.constants.STATE.PROCESSING_TITLES)
			) {
				//add btn for adding new DP_PRAZN if previos one was deleted
				actionButtonConfig.push(
					createAddButton(
						function () {
							var statutoryDocTypeStore = edi.stores.createSimpleInlineStore([]);
							statutoryDocTypeStore.loadData([
								{
									id: edi.constants.DOCUMENT_TYPES.DP_PRAZN,
									name: edi.i18n.getMessage('documents.doctype.DP_PRAZN')
								}
							]);
							var statutoryDocType,
								uploadForm = createForm({
									submitEmptyText: false,
									items: [
										createFieldBlock({
											cls: FIELD_BLOCK_CLS.small,
											title: edi.i18n.getMessage('documents.form.type'),
											items: [
												(statutoryDocType = createCombo({
													name: 'statutoryDocType',
													store: statutoryDocTypeStore,
													displayField: 'name',
													allowBlank: false,
													forceSelection: true,
													value: edi.constants.DOCUMENT_TYPES.DP_PRAZN
												}))
											]
										}),
										createFieldBlock({
											cls: FIELD_BLOCK_CLS.small,
											title: edi.i18n.getMessage('document.file'),
											items: [
												createFile(
													{},
													{
														name: 'attachment',
														listeners: edi.fields.config.pdfFilefieldListeners
													}
												)
											]
										}),
										createLabel({
											text: edi.i18n.getMessage('document.package.upload.file.limit.text')
										})
									]
								});

							var modal = createModalPanel({
								title: edi.i18n.getMessage('document.package.agreement.statutory.document.upload'),
								width: MODAL_SIZE.widthLarge,
								items: [uploadForm],
								buttonsBefore: [
									createCreateButton(
										function () {
											var failure = function (data) {
												edi.core.logMessage(
													'Error uploading dsf file' +
														(data && data.status ? ' status - ' + data.status : ''),
													'warn'
												);
												edi.core.showError(
													edi.utils.formatComplexServerError(
														data,
														data && data.status
															? 'error.server.' + data.status
															: 'dsf.upload.failure'
													),
													function () {
														moduleData.tab.setLoading(false);
													}
												);
											};
											var statutoryDocTypeValue = statutoryDocType.getValue();
											edi.core.submitUploadForm(
												uploadForm,
												edi.rest.services.DSF.FILE.POST,
												'dsf.create.saving',
												function (responseData) {
													if (responseData && responseData.data && responseData.data.id) {
														edi.document.actions.processDocument(
															buyerOrg.id,
															undefined,
															statutoryDocTypeValue,
															packageId,
															undefined,
															function (containerData) {
																edi.events.documentsPackage.fireEvent(
																	'changeAttaches',
																	{
																		id: packageId
																	}
																);
																modal.close();
															},
															failure,
															{
																data: Ext.encode({ file: responseData.data.id }),
																date: Ext.Date.format(new Date(), 'd-m-Y')
															},
															{
																createUri: edi.utils.formatString(
																	agreementPackagesAPI.UTOCH.POST,
																	{
																		containerId: packageId
																	}
																)
															}
														);
													} else {
														failure(responseData);
													}
												},
												failure
											);
										},
										{
											scale: 'small',
											bindToForm: form,
											formBind: true
										}
									)
								]
							});
							modal.show();
						},
						{
							margin: '0 0 0 3',
							isHidden: function (view, rowIndex, colIndex, item, record) {
								var docState = record.get('state'),
									docType = record.get('type');
								return docType != 'DP_PRAZN' || docState != edi.constants.STATE.REJECTED;
							}
						}
					)
				);

				//sign btn for DP_PRANZ if he in DRAFT state and package in SETN state
				actionButtonConfig.push({
					glyph: edi.constants.ICONS.SIGN,
					text: edi.i18n.getMessage('form.btn.sign'),
					isHidden: function (view, rowIndex, colIndex, item, record) {
						var recordData = record.getData();
						if (edi.constants.DOCUMENT_TYPES.DP_PRAZN != recordData.type) {
							return true;
						} else {
							return recordData.state != edi.constants.STATE.DRAFT;
						}
					},
					handler: function (grid, rowIndex) {
						var store = grid.getStore();
						var recordData = store.getAt(rowIndex).getData();

						var callback = function () {
							edi.events.documentsPackage.fireEvent('change', {
								id: packageId
							});
						};
						var isStatutoryDocument = recordData.type === edi.constants.DOCUMENT_TYPES.DP_PRAZN;
						if (isStatutoryDocument) {
							signStatutoryDocuments(recordData, callback);
							return;
						}

						signDocumentAndPushContainer(
							recordData.id,
							function (continueMethod) {
								edi.utils.sign(
									recordData,
									moduleData.tab,
									function (failed, data) {
										if (failed) {
											failureSign(data);
										} else {
											continueMethod();
										}
									},
									undefined,
									undefined,
									true,
									null,
									{
										signRefuse: () => moduleData.tab?.setLoading(false)
									}
								);
							},
							callback,
							true
						);
					}
				});
			}
		}

		if (actionButtonConfig.length) {
			columns.push(
				createActionsColumnConfig({
					align: 'right',
					items: actionButtonConfig
				})
			);
		}

		if (statutoryDocTypeStore && Array.isArray(selectedStatutoryDocType)) {
			statutoryDocTypeStore.loadData(
				selectedStatutoryDocType.map(function (item) {
					return {
						id: item,
						name: edi.i18n.getMessage('documents.doctype.' + item)
					};
				})
			);
		}

		var gridConfig = {
			title: edi.i18n.getMessage('document.package.agreement.statutory.documents'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			columns: columns,
			region: 'center',
			disablePaging: true
		};

		return createGrid({
			proxyConfig: createProxyConfig(),
			storeConfig: {
				model: edi.models.getModel('DOCUMENT_BASE'),
				data: {
					items: statutoryDocuments
				}
			},
			gridConfig: gridConfig
		});
	};

	/**
	 * Creates upd form
	 * @returns {Object}
	 */
	var createModuleForm = function () {
		signPackageButton = createSendButton(
			function () {
				moduleData.tab.setLoading(true);
				dpContainerSign(documentData, moduleData.tab);
			},
			{
				text: edi.i18n.getMessage('document.sign.and.send.document')
			}
		);

		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSignatures: true,
			noUsage: true
		});

		var statutoryGrid = createStatutoryDocumentsGrid();
		statutoryGridStore = statutoryGrid.getStore();
		form = createFormForModule({
			cls: 'edi-details-panel',
			items: [headPanel, createMainDataBlock(), (attachGrid = createDocumentsAttachGrid()), statutoryGrid],
			buttons: createButtonContainer({
				items: [
					moduleData.initData.data.state === edi.constants.STATE.DRAFT ||
					moduleData.initData.data.state === edi.constants.STATE.SENDER_REVIEW
						? signPackageButton
						: undefined
				]
			})
		});

		form.on('validitychange', checkValid);
		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		var data = moduleData.initData.data;
		var actionsPanel = createActionsPanel();
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			excludeActions: {
				SIGN: true
			},
			actionProps: {
				EDIT: {
					moduleName: 'document.create.package.ne'
				},
				REFRESH: {
					handler: function () {
						changeHandler({
							id: packageId
						});
					}
				},
				DELETE: {
					success: function () {
						edi.events.documentsPackage.fireEvent('change', {
							id: packageId,
							deleted: true
						});
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					addExtendedExport: true,
					hideDefaultExport: true
				}
			}
		});
		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data, initCallBack) {
		if (data && data.id && !data.deleted) {
			if (!(documentData && documentData.id && data.id === documentData.id)) {
				return;
			}
			initVariables();
			var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				edi.modulesHandler.removeModule(moduleData);
			});
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.HEADER.GET,
					{
						documentId: data.id
					},
					true
				),
				'GET',
				undefined,
				function (data) {
					moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
					edi.document.actions.changeTabTitle(moduleData.tab, data.data.number);
					if (data && data.data) {
						moduleData.initData.data = data.data;
					}
					initVariables();
					renderData(initCallBack);
					edi.events.documents.fireEvent('change');
				},
				failure
			);
		} else if (data && data.deleted && data.id === moduleData.initData.id) {
			initVariables();
			edi.modulesHandler.removeModule(moduleData);
			edi.events.documents.fireEvent('change');
		} else {
			initVariables();
			moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
			renderData(initCallBack);
		}
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeAttachesHandler = function (data) {
		if (!(data && data.id && documentData && documentData.id && data.id === documentData.id)) {
			return;
		}
		getPackageLinkeds(data.id, function () {
			if (statutoryDocTypeStore && Array.isArray(selectedStatutoryDocType)) {
				statutoryDocTypeStore.loadData(
					selectedStatutoryDocType.map(function (item) {
						return {
							id: item,
							name: edi.i18n.getMessage('documents.doctype.' + item)
						};
					})
				);
			}
			if (statutoryGridStore && Array.isArray(statutoryDocuments)) {
				statutoryGridStore.loadData(statutoryDocuments);
			}
		});
	};

	var getPackageLinkeds = function (packageId, callback) {
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		statutoryDocuments = [];
		var lastCreatedDpPranz;

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
				documentId: packageId
			}),
			'GET',
			undefined,
			function (data) {
				if (data && data.data && data.data.children && data.data.children.length) {
					var idDogPost;
					for (var i = 0; i < data.data.children.length; i++) {
						var attach = data.data.children[i];
						if (
							edi.constants.DOCUMENT_PACKAGES_NE_INCLUDES_DOC_TYPES.some((it) => it === attach.type) &&
							attach.state !== edi.constants.STATE.DELETED
						) {
							attachDocuments.push(Object.assign(attach, { isAttach: true }));
							packageDocTypes.push(attach.type);
							if (attach.state === edi.constants.STATE.UTOCH) {
								var utochDoc = attach;
								edi.rest.sendRequest(
									edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
										documentId: utochDoc.id
									}),
									'GET',
									{},
									function (linkedData) {
										if (linkedData && linkedData.data && Array.isArray(linkedData.data.children)) {
											var utochContainer = linkedData.data.children.find(function (item) {
												return item.type === edi.constants.DOCUMENT_TYPES.DP_CONTAINER_UVUTOCH;
											});
											if (utochContainer) {
												edi.rest.sendRequest(
													edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
														documentId: utochContainer.id
													}),
													'GET',
													{},
													function (utochData) {
														if (utochData && utochData.data) {
															var textReason = edi.utils.getObjectProperty(
																utochData.data,
																'document.svUvedUtoch.utochText'
															);
															if (textReason) {
																utochDocsReason[utochDoc.type] = textReason;
															}
														}
													},
													failure
												);
											}
										}
									},
									failure
								);
							}

							if (attach.type === edi.constants.DOCUMENT_TYPES.DP_DOGPOSTNE) {
								idDogPost = attach.id;
							}
						} else if (
							edi.constants.DOCUMENT_PACKAGES_STATUTORY_DOC_TYPES[userData.org.orgType].some(
								(it) => it === attach.type
							) &&
							(attach.state !== edi.constants.STATE.DELETED ||
								(attach.state === edi.constants.STATE.DELETED &&
									attach.type === edi.constants.DOCUMENT_TYPES.DP_PRAZN))
						) {
							if (
								attach.type !== edi.constants.DOCUMENT_TYPES.DP_USTDOK_ADDITIONAL &&
								attach.type !== edi.constants.DOCUMENT_TYPES.DP_USTDOKADDITIONAL
							) {
								const itemIndex = selectedStatutoryDocType.indexOf(attach.type);
								if (itemIndex > -1) {
									selectedStatutoryDocType.splice(itemIndex, 1);
								}
							}
							if (attach.type === edi.constants.DOCUMENT_TYPES.DP_PRAZN) {
								if (!lastCreatedDpPranz) {
									lastCreatedDpPranz = attach;
								} else {
									if (lastCreatedDpPranz.creationDate < attach.creationDate) {
										lastCreatedDpPranz = attach;
									}
								}
							} else {
								statutoryDocuments.push(attach);
							}
						}
					}
					if (lastCreatedDpPranz) {
						statutoryDocuments.push(lastCreatedDpPranz);
					}
					callback({
						idDogPost: idDogPost
					});
				} else {
					callback({});
				}
			},
			failure
		);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		if (data && data.id) {
			isEdit = true;
			id = packageId = data.id;
			buyerOrg = data.toOrg;
			isSent = data.state == edi.constants.STATE.SENT;
			getPackageLinkeds(id, function (data) {
				var continueCreateForm = function () {
					var form = createModuleForm();
					modulePanel.add(form);
					moduleData.tab.removeAll();
					moduleData.tab.add(createModuleActionsPanel());
					moduleData.tab.add(modulePanel);
					if ('function' == typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}
				};

				if (data.idDogPost) {
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.FOR_PARENT.GET, {
							parentId: data.idDogPost,
							docType: edi.constants.DOCUMENT_TYPES.DP_DOGPOSTNE_P1
						}),
						'GET',
						{},
						function (data) {
							isCreateDigPost = true;
							if (data && data.data) {
								documentsFormValues = edi.utils.getObjectProperty(data.data, 'document');
								dogFileId = edi.utils.getObjectProperty(data.data, 'fileId');
							}
							continueCreateForm();
						},
						failure
					);
				} else {
					continueCreateForm();
				}
			});
		} else {
			failure();
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documentsPackage.un('changeAttaches', changeAttachesHandler);
		edi.events.documentsPackage.un('change', changeHandler);

		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
