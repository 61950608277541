const ADDRESSES_MODEL_CONFIG_NAME = 'CONTAINER_COMMON_PRICAT_ADDRESS';
const CONTAINER_COMMONPRICAT_LINE_MODEL = 'CONTAINER_COMMONPRICAT_LINE';

edi.models.setModels({
	[ADDRESSES_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'name',
				type: 'string'
			},
			{
				name: 'address',
				type: 'string'
			}
		]
	},
	[CONTAINER_COMMONPRICAT_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'Brand',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'PackDetails'
				//type: 'object'
			},
			{
				name: 'MinOrderedQuantity',
				type: 'string'
			},
			{
				name: 'Campaign',
				type: 'string'
			},
			{
				name: 'SegmentPrice',
				type: 'string'
			},
			{
				name: 'PriceRegionText',
				type: 'string'
			},
			{
				name: 'UnitNetPrice',
				type: 'string'
			},
			{
				name: 'UnitGrossPrice',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'Discount',
				type: 'string'
			},
			{
				name: 'Weight',
				type: 'string'
			},
			{
				name: 'Length',
				type: 'string'
			},
			{
				name: 'Width',
				type: 'string'
			},
			{
				name: 'Height',
				type: 'string'
			},
			{
				name: 'UnitPacksize',
				type: 'string'
			},
			{
				name: 'SuggestedPrice',
				type: 'string'
			},
			{
				name: 'SpecialPrice',
				type: 'string'
			}
		],
		idProperty: 'LineNumber'
	}
});

let getAddressesModelName = () => edi.models.getModel(ADDRESSES_MODEL_CONFIG_NAME);

export { getAddressesModelName, ADDRESSES_MODEL_CONFIG_NAME, CONTAINER_COMMONPRICAT_LINE_MODEL };
