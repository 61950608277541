import { CERTIFICATES_MODEL } from './models';
import { CERTIFICATES_COLUMN } from './columns';
import { createFormItems, createArgs } from './filters';
import { certificatesAPI } from './rest';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createCertDetailsModal } from './createCertDetailsModal';
import { createActionsColumnConfig, createGrid } from '@UIkit/components/grid';
import { createPanel } from '@UIkit/components/panels';

const createEmployeeCertificatesPanel = function (tabModuleData) {
	let filterObject, grid, filter;

	const createCertificateFilterForm = function () {
		let fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(certificatesAPI.GET, form, grid, createArgs, null, {
					persistence: {
						enabled: true,
						name: `${tabModuleData.modName}_cert_grid`
					},
					proxyConfig: {
						reader: {
							totalProperty: function (data) {
								return data?.data?.totalCount;
							},
							rootProperty: function (data) {
								return data?.data?.result;
							}
						}
					}
				});
			}
			filterObject.filter();
		};
		let { formItemsMap, items } = createFormItems();

		const form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${tabModuleData.modName}_grid_cert`
				},
				border: 1,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultValidDate = edi.utils.getUserDefaultSearchPeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
					formItemsMap.validDate?.setRange(defaultValidDate);
				}
			},
			fireSearch
		);

		return form;
	};

	const createActionColumn = function () {
		return createActionsColumnConfig({
			items: [
				{
					glyph: edi.constants.ICONS.DETAILS,
					handler: function (grid, rowIndex, colindex, actionItem, event, record) {
						var recordData = record.getData();
						const id = recordData.dataId;
						createCertDetailsModal(id, recordData);
					}
				}
			]
		});
	};

	const createCertificatesGrid = function () {
		let columns = edi.columns.get(CERTIFICATES_COLUMN);
		let actionColumn = createActionColumn();
		actionColumn ? columns.push(actionColumn) : null;
		return createGrid({
			proxyConfig: {
				type: 'ajax',
				url: certificatesAPI.GET,
				reader: {
					totalProperty: function (data) {
						return data?.data?.totalCount;
					},
					rootProperty: function (data) {
						return data?.data?.result;
					}
				}
			},
			storeConfig: {
				model: edi.models.getModel(CERTIFICATES_MODEL),
				autoLoad: true
			},
			gridConfig: {
				columns: columns,
				padding: '0 0 0 0',
				region: 'center',
				border: 1,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						const id = record.getData().dataId;
						createCertDetailsModal(id, record.getData());
					}
				}
			},
			viewConfig: {
				emptyTextTplOptions: {
					enabled: true,
					iconName: 'certificates',
					title: edi.i18n.getMessage('ediweb.employee.certificates.empty.text.tpl.title'),
					contentText: edi.i18n.getMessage('ediweb.employee.certificates.empty.text.tpl.contentText'),
					beforeButtonText: edi.i18n.getMessage(
						'ediweb.employee.certificates.empty.text.tpl.beforeButtonText'
					),
					buttonText: edi.i18n.getMessage('ediweb.employee.certificates.empty.text.tpl.buttonText'),
					buttonName: 'emptyTextButton',
					buttonClickHandler: function (e, btn, name) {
						filter.toggleHandler();
					}
				}
			}
		});
	};

	grid = createCertificatesGrid();
	filter = createCertificateFilterForm();

	const updateGrid = function () {
		grid.getStore().reload();
	};

	edi.events.certificates.on('change', updateGrid);

	return createPanel({
		layout: 'border',
		items: [filter, grid],
		onDestroy: function () {
			edi.events.certificates.un('change', updateGrid);
		}
	});
};

export { createEmployeeCertificatesPanel };
