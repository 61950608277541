import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import {
	createDateField,
	createField,
	createFieldSet,
	createLabel,
	createNumberField,
	createTextField
} from '@Components/fields';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createButtonContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

/**
 * @author Vsevolod Liapin
 * Class for creation IFTMBC document
 */
Ext.namespace('edi.modules');
edi.modules['document.create.iftmbc'] = function () {
	var moduleData,
		parentId,
		id,
		userData = edi.core.getUserData(),
		isEdit = false,
		isCreate = false,
		buyerOrg,
		productValues = { products: [] },
		productsGrid,
		buyerOrgValues,
		seller,
		buyer,
		form,
		afterSave,
		documentData = {};
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			documentData = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			buyerOrg = moduleData.initData.data.buyerOrg;
			isEdit = true;
			isCreate = true;
		}
		renderData(initCallBack);

		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isBuyerValid = buyer.isValid();
		var isSellerValid = seller.isValid();
		return isBuyerValid && isSellerValid;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		form.isValid();
		var validForm = !form.hasInvalidField();
		var validCompanies = validateCompanySelectors();
		var isValid = validForm && validCompanies && productValues.products.length;

		return isValid;
	};

	/**
	 * Create invoice form
	 */
	var createMainFormItems = function () {
		var numberDocument = createTextField({
			columnWidth: 0.7333,
			allowBlank: false,
			maxLength: 35,
			value: edi.utils.getObjectProperty(documentData, 'bookingResponseHeader.documentNumber'),
			name: 'bookingResponseHeader.documentNumber'
		});
		var documentTime = edi.utils.getObjectProperty(documentData, 'bookingResponseHeader.documentTime');
		var mainDataInputs = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.documentNumber'),
					input: numberDocument,
					mandatory: false,
					containerConfig: {
						columnWidth: documentTime ? 0.7 : 0.75
					}
				}),
				createDateField({
					emptyText: edi.i18n.getMessage('date'),
					margin: '0 0 0 5',
					columnWidth: documentTime ? 0.2 : 0.25,
					allowBlank: false,
					value:
						edi.utils.getObjectProperty(documentData, 'bookingResponseHeader.documentDate') || new Date(),
					name: 'bookingResponseHeader.documentDate'
				}),
				documentTime
					? createLabel({
							columnWidth: 0.1,
							text: documentTime,
							valueLabel: true
					  })
					: null
			]
		});

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				selectedRelationByMap: true,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				valuesByMap: true,
				useHiddenFields: true,
				disableAutoValidation: true,
				processValues: orgConverter
			});
			return createCompanySelector(selectorConfig);
		};

		var organizationFieldsMap = {
			company_iln: 'iln',
			company_name: 'name',
			company_inn: 'taxID',
			company_register_number: 'registerNumber',
			contact: 'contact',

			addr_category: 'type',
			addr_country: 'country',
			addr_zip: 'postalCode',
			addr_locality: 'locality',
			addr_block: 'housing',
			addr_addInf: 'additionalInformation',
			addr_area: 'district',
			addr_region_name: 'state',
			addr_region_code: 'stateCode',
			addr_city: 'cityName',
			addr_street: 'streetAndNumber',
			addr_flat: 'roomNumber',
			addr_home: 'houseNumber'
		};

		/**
		 * Checks company data validation
		 */
		var isValidOrganization = function (params) {
			params = params || {};

			var organization = params.organization;
			var useOrganizationMap = params.useOrganizationMap;

			function getPropertyName(keyName) {
				return useOrganizationMap ? organizationFieldsMap[keyName] : keyName;
			}

			return !!edi.utils.getObjectProperty(organization, getPropertyName('company_iln'));
		};

		var getOrganizationFieldsMap = function (prefix) {
			var newOrganizationFieldsMap = {};
			for (var field in organizationFieldsMap) {
				if (organizationFieldsMap.hasOwnProperty(field)) {
					newOrganizationFieldsMap[field] = prefix + '.' + organizationFieldsMap[field];
				}
			}
			return newOrganizationFieldsMap;
		};

		var modalConf = {
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_inn: edi.selectors.defaults.company_inn,
					company_register_number: edi.selectors.defaults.company_register_number,
					contact: edi.selectors.defaults.contact
				},
				address: edi.selectors.COMMON_ORGANIZATION_ADDRESS
			}
		};

		var userOrganization = edi.converters.convertOrgToLegacyPartie(userData.org);
		var sellerInfo = edi.utils.getObjectProperty(documentData, 'bookingResponseParties.seller');
		var isSellerDefined = !!sellerInfo;
		var isValidSeller = isValidOrganization({
			organization: sellerInfo || userOrganization,
			useOrganizationMap: isSellerDefined,
			organizationType: 'seller'
		});

		seller = createCS({
			itemId: 'seller',
			title: 'document.seller',
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id,
				converter: orgConverter
			}),
			selectedRelationByMap: isEdit,
			useHiddenFields: true,
			fieldValues: isSellerDefined ? documentData : userOrganization,
			valuesByMap: isSellerDefined,
			selectedOrgValues: userOrganization,
			selectedOrg: userData.org,
			readOnly: isEdit && !isCreate,
			is_valid: isValidSeller,
			disableAutoValidation: true,
			fieldsMap: getOrganizationFieldsMap('bookingResponseParties.seller'),
			modalConf: Object.assign(
				{
					title: 'document.seller'
				},
				modalConf
			),
			callback: function (values) {
				seller.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'seller'
				});
				checkValid();
			}
		});

		var buyerInfo = edi.utils.getObjectProperty(documentData, 'bookingResponseParties.buyer');
		var isBuyerDefined = !!buyerInfo;
		var isValidBuyer = isValidOrganization({
			organization: buyerInfo,
			useOrganizationMap: isBuyerDefined,
			organizationType: 'buyer'
		});

		buyerOrgValues = edi.converters.convertOrgToLegacyPartie(buyerOrg);
		buyer = createCS({
			itemId: 'buyer',
			title: 'document.carrier',
			is_valid: isValidBuyer,
			fieldValues: documentData,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			relations: !isCreate ? (!isEdit && buyerOrg ? buyerOrg : edi.relations.getRelations()) : undefined,
			relationsOnly: true,
			disableAutoValidation: true,
			readOnly: isEdit,
			allowReset: !isEdit && !isBuyerDefined,
			selectedOrgValues: buyerOrgValues,
			selectedRelationByMap: isEdit || buyerOrg,
			callback: function (values, org) {
				var orgId = org ? org.id : isCreate && buyerOrg ? buyerOrg.id : null;
				if (productsGrid.getPartnerId() != orgId) {
					productsGrid.productsGrid.getStore().removeAll();
				}
				productsGrid.setPartnerId(orgId);
				if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
					buyerOrg = org;
				} else if (!orgId) {
					buyerOrg = null;
				}

				buyer.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'buyer'
				});
				checkValid();
			},
			fieldsMap: getOrganizationFieldsMap('bookingResponseParties.buyer'),
			modalConf: Object.assign(
				{
					title: 'document.carrier'
				},
				modalConf
			)
		});

		var referenceBooking = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.referenceDocument'),
					input: createTextField({
						emptyText: edi.i18n.getMessage('document.generation.numbers.nr'),
						columnWidth: 0.7333,
						allowBlank: false,
						maxLength: 35,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseHeader.referenceBookingMessage.referenceBookingNumber'
						),
						name: 'bookingResponseHeader.referenceBookingMessage.referenceBookingNumber'
					}),
					mandatory: false,
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createDateField({
					emptyText: edi.i18n.getMessage('date'),
					margin: '0 0 0 5',
					columnWidth: 0.25,
					value: edi.utils.getObjectProperty(
						documentData,
						'bookingResponseHeader.referenceBookingMessage.referenceBookingDate'
					),
					name: 'bookingResponseHeader.referenceBookingMessage.referenceBookingDate'
				})
			]
		});

		return [mainDataInputs, seller, buyer, referenceBooking];
	};

	var createTransportInfoItems = function () {
		var driver = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.driver'),
					input: createTextField({
						columnWidth: 0.684,
						allowBlank: false,
						maxLength: 140,
						fieldLabel: edi.i18n.getMessage('full.name.abbr'),
						value: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.name'),
						name: 'bookingResponseParties.carrier.name'
					}),
					containerConfig: {
						columnWidth: 0.7
					}
				}),
				createTextField({
					columnWidth: 0.3,
					margin: '0 0 0 5',
					allowBlank: false,
					maxLength: 140,
					fieldLabel: edi.i18n.getMessage('document.iftmbc.transportInfo.driverContacts'),
					value: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.phone'),
					name: 'bookingResponseParties.carrier.phone'
				})
			]
		});

		var passportFields = [],
			onChangePassportField = function (cmp, newValue, oldValue) {
				if ((newValue && !oldValue) || (!newValue && oldValue)) {
					//allowBlank if we hadn't found a filled field
					var allowBlank = !passportFields.find(function (field) {
						return field.getValue();
					});

					passportFields.forEach(function (field) {
						field.allowBlank = allowBlank;
						field.isValid();
					});

					checkValid();
				}
			};

		var driverPassport = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.Passport'),
					input: (passportFields[0] = createTextField({
						columnWidth: 0.358,
						maxLength: 4,
						enforceMaxLength: true,
						fieldLabel: edi.i18n.getMessage('document.iftmbc.transportInfo.series'),
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.passport.series'
						),
						name: 'bookingResponseParties.carrier.passport.series',
						listeners: {
							change: onChangePassportField
						}
					})),
					containerConfig: {
						columnWidth: 0.35
					}
				}),
				createField({
					input: (passportFields[1] = createTextField({
						columnWidth: 1,
						maxLength: 6,
						enforceMaxLength: true,
						fieldLabel: edi.i18n.getMessage('document.iftmbc.transportInfo.number'),
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.passport.number'
						),
						name: 'bookingResponseParties.carrier.passport.number',
						listeners: {
							change: onChangePassportField
						}
					})),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.1
					}
				}),
				createField({
					input: (passportFields[2] = createTextField({
						columnWidth: 1,
						maxLength: 255,
						enforceMaxLength: true,
						fieldLabel: edi.i18n.getMessage('document.iftmbc.transportInfo.issuedBy'),
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.passport.issuedBy'
						),
						name: 'bookingResponseParties.carrier.passport.issuedBy',
						listeners: {
							change: onChangePassportField
						}
					})),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.35
					}
				}),
				createField({
					input: (passportFields[3] = createDateField({
						columnWidth: 1,
						fieldLabel: edi.i18n.getMessage('document.iftmbc.transportInfo.date'),
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.passport.date'
						),
						name: 'bookingResponseParties.carrier.passport.date',
						listeners: {
							change: onChangePassportField
						}
					})),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.2
					}
				})
			]
		});

		var driverLicense = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.license'),
					input: createTextField({
						columnWidth: 0.778,
						maxLength: 140,
						allowBlank: false,
						value: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.license'),
						name: 'bookingResponseParties.carrier.license'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var transportInfo = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo'),
					input: createTextField({
						columnWidth: 0.52,
						margin: '0 0 0 5',
						allowBlank: false,
						maxLength: 18,
						fieldLabel: edi.i18n.getMessage('document.iftmbf.transportInfo.TransportNumber'),
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.transportNumber'
						),
						name: 'bookingResponseParties.carrier.transportNumber'
					}),
					containerConfig: {
						columnWidth: 0.45
					}
				}),
				createField({
					input: createTextField({
						columnWidth: 1,
						maxLength: 255,
						fieldLabel: edi.i18n.getMessage('document.iftmbc.transportInfo.carBrand'),
						value: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.carBrand'),
						name: 'bookingResponseParties.carrier.carBrand'
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.3
					}
				}),
				createField({
					input: createTextField({
						columnWidth: 1,
						maxLength: 255,
						fieldLabel: edi.i18n.getMessage('document.iftmbc.transportInfo.trailerNumber'),
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.trailerNumber'
						),
						name: 'bookingResponseParties.carrier.trailerNumber'
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.25
					}
				})
			]
		});

		var transportType = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.TotalNetAmount'),
					input: createNumberField({
						columnWidth: 0.555,
						allowDecimals: true,
						maxLength: 12,
						enforceMaxLength: true,
						decimalPrecision: 3,
						value: edi.utils.getObjectProperty(documentData, 'bookingResponseSummary.totalNetAmount'),
						name: 'bookingResponseSummary.totalNetAmount'
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.loadedQuantity'),
					input: createTextField({
						columnWidth: 0.5,
						regex: edi.constants.VALIDATORS.FLOAT,
						maxLength: 5,
						value: edi.utils.getObjectProperty(documentData, 'bookingResponseSummary.loadedQuantity'),
						name: 'bookingResponseSummary.loadedQuantity'
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.iftmbf.transportInfo'),
				items: [driver, driverPassport, driverLicense, transportInfo, transportType]
			})
		];
	};

	var createUnloadingPoints = function () {
		var lines = edi.utils.getObjectProperty(documentData, 'bookingResponseLines.shipTos', true);
		if ('string' != typeof lines) {
			productValues.products = lines;
		}

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.iftmbc.unloadingPoints'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'IFTMBC_LINE',
			gridColumnConfig: 'iftmbc_lines',
			data: productValues.products,
			lockIfNoPartner: true,
			hasTotal: false,
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			configModalButtonsBefore: function () {
				return [];
			},
			modalFormConfig: {
				title: 'documents.iftmbf.unloadingPoint',
				modalFields: [
					{
						title: edi.i18n.getMessage('documents.iftmbf.line.modal.tab.main.data'),
						items: [
							{
								title: edi.i18n.getMessage('column.iln'),
								name: 'iln',
								allowBlank: false,
								regex: edi.constants.VALIDATORS.GLN,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbc.estimatedDispatchDate'),
								name: 'estimatedDispatchDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbc.estimatedDispatchTime'),
								name: 'estimatedDispatchTime',
								type: 'time'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbf.type'),
								name: 'type',
								store: edi.stores.createInlineStore([
									{
										id: 'Transit',
										name: edi.i18n.getMessage('Transit')
									},
									{
										id: 'Final',
										name: edi.i18n.getMessage('Final')
									}
								]),
								displayField: 'name',
								valueField: 'id',
								type: 'combo'
							},
							{
								title: edi.i18n.getMessage('column.name'),
								name: 'name'
							},
							{
								title: edi.i18n.getMessage('column.address'),
								maxLength: 140,
								name: 'streetAndNumber'
							},
							{
								title: edi.i18n.getMessage('column.city.name'),
								maxLength: 70,
								name: 'cityName'
							},
							{
								title: edi.i18n.getMessage('column.state'),
								maxLength: 35,
								name: 'state'
							},
							{
								title: edi.i18n.getMessage('column.state.code'),
								name: 'stateCode',
								type: 'combo',
								store: edi.stores.initRegionsStore(),
								valueField: 'id',
								displayField: 'display_name',
								anyMatch: true
							},
							{
								title: edi.i18n.getMessage('company.country.code'),
								name: 'country',
								type: 'combo',
								store: edi.stores.initValidCountryFullStore(),
								valueField: 'iso_2',
								value: 'RU',
								displayField: 'name'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbf.contact'),
								name: 'contact'
							}
						]
					}
				]
			}
		});
		productsGrid.setPartnerId(buyerOrg ? buyerOrg.id : null);

		return productsGrid;
	};

	/**
	 * Creates upd form
	 * @returns {Object}
	 */
	var createModuleForm = function () {
		var createButton = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					save();
				}
			},
			isEdit
		);

		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.EDI_IFTMBC
				)
			)
		) {
			var createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					afterSave = function (documentId, callback) {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							Ext.encode({}),
							null,
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.in.sending.process'
							),
							callback
						);
					};
					save();
				},
				null,
				isEdit
			);
		}

		var twoColumnsLayout = createTwoColumnsLayout(createMainFormItems(), createTransportInfoItems());

		form = createForm({
			cls: 'edi-form document-add-form',
			autoScroll: true,
			bodyPadding: 10,
			items: [twoColumnsLayout, createUnloadingPoints()],
			buttons: createButtonContainer({
				items: [createAndSendButton, createButton]
			})
		});

		form.on('validitychange', checkValid);
		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	var buildDocument = function (formValues) {
		var build = {
			documentParties: {
				sender: {
					iln: userData.org.iln,
					name: userData.org.name ? userData.org.name : undefined
				},
				receiver: {
					iln: buyerOrg.iln,
					name: buyerOrg.name ? buyerOrg.name : undefined
				}
			},
			bookingResponseHeader: {
				documentFunctionCode: 9
			}
		};
		if (productValues.products.length) {
			build.bookingResponseLines = {
				shipTos: productValues.products
			};
			build.bookingResponseSummary = {
				totalLines: productValues.products.length
			};
		}

		return Ext.Object.merge(formValues, build);
	};
	/**
	 * Saves fns upd
	 */
	var save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = buildDocument(edi.utils.collectFormValues(form));
		edi.utils.clearEmptyValues(values);

		edi.utils.setObjectProperty(values, 'bookingResponseHeader.documentTime', Ext.Date.format(new Date(), 'H:i'));
		var headerData = {
			data: Ext.encode(values),
			date: edi.utils.getObjectProperty(values, 'bookingResponseHeader.documentDate')
		};

		headerData.number = edi.utils.getObjectProperty(values, 'bookingResponseHeader.documentNumber');

		var success = function (responseData) {
			var finishSaving = function () {
				moduleData.isChanged = false;
				moduleData.tab.setLoading(false);
				edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit && !isCreate);
				if (id) {
					edi.events.documents.fireEvent('change', {
						id: id
					});
				} else {
					edi.events.documents.fireEvent('create');
				}
			};

			var documentId = id || responseData.data.id;
			if (afterSave && documentId) {
				afterSave(documentId, function () {
					edi.document.actions.getDocumentHeaderData(documentId, function (headerData) {
						headerData ? (responseData.data = headerData) : null;
						finishSaving();
					});
				});
			} else {
				finishSaving();
			}
		};
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBC
		);
		edi.document.actions.processDocument(
			buyerOrg.id,
			undefined,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBC,
			parentId,
			id,
			success,
			failure,
			headerData
		);
	};
	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		if (data && data.id) {
			isEdit = true;
			id = data.id;
			parentId = data.id;
			buyerOrg = data.toOrg;

			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						documentData = data.data;
						var form = createModuleForm();
						modulePanel.add(form);
						moduleData.tab.add(createModuleActionsPanel());
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			var createModulePanel = function () {
				if (data && !isCreate) {
					buyerOrg = data.toOrg ? data.toOrg : null;
				}

				modulePanel.add(createModuleForm());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);
				buyer.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			};

			createModulePanel();
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
