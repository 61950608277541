import { createGrid, createGridActionBar, createGridCheckboxSelectionModel } from '@UIkit/components/grid';
import { createLabel } from '@UIkit/components/fields_old/Label';
import { createModulePanel } from '@Components/panels';

Ext.namespace('edi.modules');
edi.modules['notifications'] = function () {
	var moduleData, grid;
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	var createGridCmp = function () {
		var columns = edi.columns.get('notifications'),
			actionItems = [],
			totalsLabel = createLabel({
				text: edi.i18n.getMessage('records.selected.totals', {
					selectedRows: 0
				}),
				cls: 'edi-tbar-total-label-right'
			});

		actionItems.push({
			id: edi.constants.DOCUMENT_ACTIONS.READ,
			name: 'Прочитать'
		});

		var topBar = undefined;
		if (actionItems.length) {
			var onMenuItemClick = function (id) {
				if (edi.constants.DOCUMENT_ACTIONS.READ === id) {
					console.log('READ');
				}
			};
			topBar = createGridActionBar({
				actionCfgs: actionItems,
				defaultHandler: onMenuItemClick,
				totalsLabel: totalsLabel
			});
		}

		var grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: edi.rest.services.NOTIFICATIONS.GET
			},
			storeConfig: {
				model: edi.models.getModel('NOTIFICATIONS')
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: function (model, selected, actionItems) {
									edi.methods.gridSelModules(model, selected, actionItems, topBar, totalsLabel);
								}
						  })
						: undefined,
				tbar: topBar
			},
			pagingBarConfig: {
				totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
			}
		});
		return grid;
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		grid = createGridCmp();
		modulePanel.add(grid);
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
