import './permissions.js';
import './configuration.js';
import './global.storage.js';
import './navigation.js';
import './modules.js';
import './rest.js';
import './converters.js';
import './total.handlers.js';
import './store.js';
import './fields.js';
import './specialComponents/index.js';
import '../notifications.js';
import './renderers.js';
import './methods.js';
import './methods/index.js';
import './events.js';
import './columns.js';
import './models.js';
import './filters.js';
import '../common.rules.js';
import './document.actions.js';
import './selectors.js';
import '../realtime_messages.js';
import './realtime_messages.js';

edi.core.loadLocalizationGroups.push('edi_core_client');
