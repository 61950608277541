import { createCombo, createNumberField, createTextField } from '@Components/fields';
import { createPanel } from '@Components/panels';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';

/**
 * Created by Дарья on 28.02.2017.
 */
Ext.namespace('edi.filters');
Object.assign(edi.filters.config, {
	documents: {
		createArgs: function (values) {
			if (values.ownDocs) {
				values.ownDocs = 'true';
			}
			if (values['includeBusinessStates']) {
				const businessStates = [];
				values['includeBusinessStates'].forEach(function (value) {
					businessStates.push({ businessState: value });
					switch (value) {
						//если в фильтре выбрано SIGNED_BY_CONSUMER добавить еще SIGNED
						case edi.constants.STATE.SIGNED_BY_CONSUMER:
							businessStates.push({ businessState: edi.constants.STATE.SIGNED });
							break;
						//если REJECTED добавить еще REJECTED_BY_RECEIVER
						case edi.constants.STATE.REJECTED:
							businessStates.push({ businessState: edi.constants.STATE.REJECTED_BY_RECEIVER });
							break;
						//если "Принят с комментарием" добавить еще ACCEPTED_WITH_COMMENT_34
						case edi.constants.STATE.ACCEPTED_WITH_COMMENT_13:
							businessStates.push({ businessState: edi.constants.STATE.ACCEPTED_WITH_COMMENT_34 });
							break;
						//если в фильтре выбрано UTOCH добавить еще UTOCH_COMPLETED и UTOCH_SIGNED_BY_CONSUMER
						case edi.constants.STATE.UTOCH:
							businessStates.push(
								{
									businessState: edi.constants.STATE.UTOCH_COMPLETED
								},
								{
									businessState: edi.constants.STATE.UTOCH_SIGNED_BY_CONSUMER
								}
							);
							break;
					}
				});
				delete values['includeBusinessStates'];

				if (businessStates.length) {
					values['businessStates'] = Ext.encode(businessStates);
				}
			}

			if (values['includeDocTypes']) {
				//Check if UPD or UKD business process was selected. If so, send selected doctypes & BP-s as separate filter arg
				var bpNamesUPD = Array.isArray(edi.constants.UPD_BP_NAMES_FOR_FILTER)
					? edi.constants.UPD_BP_NAMES_FOR_FILTER
					: [];
				var bpNamesUKD = Array.isArray(edi.constants.UKD_BP_NAMES_FOR_FILTER)
					? edi.constants.UKD_BP_NAMES_FOR_FILTER
					: [];
				var bpNamesPricat = Array.isArray(edi.constants.COMMON_PRICAT_BP_NAMES_FOR_FILTER)
					? edi.constants.COMMON_PRICAT_BP_NAMES_FOR_FILTER
					: [];

				var selectedBpNames = [];

				if (!['priceCatalogue', 'archived', 'aperak'].some((it) => it === values.gridName)) {
					var bpNamesFNS = [],
						simpleDocTypes = [];

					bpNamesFNS = bpNamesFNS.concat(bpNamesUPD);
					bpNamesFNS = bpNamesFNS.concat(bpNamesUKD);
					bpNamesFNS.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT);

					var isAllUPDTypes =
						values['includeDocTypes'].filter(function (elem) {
							return bpNamesUPD.indexOf(elem) > -1;
						}).length === bpNamesUPD.length;
					var isAllUKDTypes =
						values['includeDocTypes'].filter(function (elem) {
							return bpNamesUKD.indexOf(elem) > -1;
						}).length === bpNamesUKD.length;
					var isAllFNSType = bpNamesFNS.every(function (elem) {
						return values['includeDocTypes'].indexOf(elem) > -1;
					});
					var isAllPricatTypes = bpNamesPricat.every(function (elem) {
						return values['includeDocTypes'].indexOf(elem) > -1;
					});

					if (
						isAllFNSType ||
						values['includeDocTypes'].some(
							(it) => it === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
						)
					) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT
						});
					}
					if (isAllUPDTypes) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
						});
					}
					if (isAllUKDTypes) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
						});
					}
					if (isAllPricatTypes) {
						selectedBpNames.push({
							type: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT
						});
					}

					//Check for UPD and UKD BP-s in doctypes if no general UPD & UKD values are selected
					values['includeDocTypes'].forEach(function (doctype) {
						var isSimpleDocTypes =
							!bpNamesUPD.some((it) => it === doctype) &&
							!bpNamesUKD.some((it) => it === doctype) &&
							!bpNamesPricat.some((it) => it === doctype) &&
							doctype !== edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT;

						if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
							selectedBpNames.push({
								type: edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT,
								name: 'subDocType',
								value: edi.stores.data.formalizedDocumentSubTypes[doctype]
							});
						} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
							selectedBpNames.push({
								type: edi.constants.DOCUMENT_TYPES.DSF_CLIENT,
								name: 'subDocType',
								value: edi.stores.data.dsfClientDocumentSubTypes[doctype]
							});
						} else {
							if (bpNamesUPD.some((it) => it === doctype) && !isAllUPDTypes) {
								selectedBpNames.push({
									type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
									name: 'businessProcess',
									value: edi.i18n.getMessage('documents.fns_upd.type.' + doctype)
								});
							}
							if (bpNamesUKD.some((it) => it === doctype) && !isAllUKDTypes) {
								selectedBpNames.push({
									type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
									name: 'businessProcess',
									value: edi.i18n.getMessage('documents.fns_ukd.type.' + doctype)
								});
							}
							if (bpNamesPricat.some((it) => it === doctype) && !isAllPricatTypes) {
								selectedBpNames.push({
									type: edi.constants.DOCUMENT_TYPES.COMMON_PRICAT,
									name: 'bpName',
									value: doctype
								});
							}
							if (isSimpleDocTypes) {
								simpleDocTypes.push(doctype);
							}
						}
					});

					if (selectedBpNames.length) {
						selectedBpNames = selectedBpNames.concat(
							simpleDocTypes.map(function (doctype) {
								return {
									type: doctype
								};
							})
						);

						values['typesAndAttrs'] = Ext.encode(selectedBpNames);
						delete values['includeDocTypes'];
					} else {
						values['includeDocTypes'] = Ext.encode(values['includeDocTypes']);
					}
				} else {
					if (values.gridName === 'archived') {
						values['includeDocTypes'].forEach(function (doctype) {
							if (bpNamesUPD.some((it) => it === doctype)) {
								selectedBpNames.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD);
							} else if (bpNamesUKD.some((it) => it === doctype)) {
								selectedBpNames.push(edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD);
							} else if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
								selectedBpNames.push(edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT);
							} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
								selectedBpNames.push(edi.constants.DOCUMENT_TYPES.DSF_CLIENT);
							} else {
								selectedBpNames.push(doctype);
							}
						});

						values['includeDocTypes'] = Ext.encode([...new Set(selectedBpNames)]);
					} else {
						var tmp = [];
						values['includeDocTypes'].forEach(function (doctype) {
							if (edi.stores.data.formalizedDocumentSubTypes.hasOwnProperty(doctype)) {
								tmp.push(edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT);
							} else if (edi.stores.data.dsfClientDocumentSubTypes.hasOwnProperty(doctype)) {
								tmp.push(edi.constants.DOCUMENT_TYPES.DSF_CLIENT);
							} else {
								tmp.push(doctype);
							}
						});
						values['includeDocTypes'] = Ext.encode([...new Set(tmp)]);
					}
				}
			}

			if (values['includedFilials']) {
				values['includedFilials'] = Ext.encode(values['includedFilials']);
			}

			if (values['referenceDocType']) {
				values['includeReferenceDocType'] = Ext.encode(values['referenceDocType']);
				delete values['referenceDocType'];
			}
			if (
				values['includeReferenceDocCode'] &&
				values['includeReferenceDocCode'].length &&
				values['includeReferenceDocCode'].some((it) => it === String(edi.constants.APERAK_CODES.CODE_13)) &&
				!values['includeReferenceDocCode'].some((it) => it === String(edi.constants.APERAK_CODES.CODE_34))
			) {
				values['includeReferenceDocCode'].push(String(edi.constants.APERAK_CODES.CODE_34));
				values['includeReferenceDocCode'] = Ext.encode(values['includeReferenceDocCode']);
			}

			if ('aperak' === values.gridName) {
				delete values.gridName;
				delete values.includeStates;
				delete values.includeDocTypes;
				delete values.typesAndAttrs;
			} else {
				delete values.includeReferenceDocCode;
				delete values.includeReferenceDocType;
				delete values.referenceDocNum;
			}

			if ('priceCatalogue' !== values.gridName) {
				delete values.dsfType;
			} else {
				delete values.gridName;
			}

			values = edi.filters.config.generic.convertFieldName(values, 'number');
			if (!Ext.isEmpty(values['number'])) {
				let numbers = Array.isArray(values['number']) ? values['number'] : values['number'].split(',');
				values['includeNumbers'] = Ext.encode(numbers);
				delete values['number'];
			}

			values = edi.filters.config.generic.convertFieldName(values, 'deliveryGLN');
			values = edi.filters.config.generic.convertFieldName(values, 'referenceDocNum');
			if (Array.isArray(values['referenceDocNum'])) {
				values['includeReferenceDocNums'] = Ext.encode(values['referenceDocNum']);
				delete values['referenceDocNum'];
			}

			if (values['attrNumberVal']) {
				values['attrNumberVal'] = values['attrNumberVal'].replace('attrNumber', values.attribute);
				delete values.attribute;

				values = edi.filters.config.generic.convertFieldName(values, 'attrNumber');
			}

			values['lookupDocsByMembers'] = true;

			values = edi.filters.config.generic.convertFieldName(values, 'orderNumber');
			values = edi.filters.config.generic.convertFieldName(values, 'desadvNumber');
			values = edi.filters.config.generic.convertFieldName(values, 'contractNumber');

			values = edi.filters.config.generic.convertFieldName(values, 'INVOICE_NUMBER');
			values = edi.filters.config.generic.convertFieldName(values, 'transportDocNumber');
			values = edi.filters.config.generic.convertFieldName(values, 'factureNumber');
			values = edi.filters.config.generic.convertFieldName(values, 'RECEIVING_ADVICE_NUMBER');

			edi.utils.clearEmptyValues(values);
			return values;
		}
	},
	document_packages: {
		createFormItems: function () {
			const formItemsMap = {
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('column.creation.date'),
					fieldsConfig: {
						common: {
							invalidText: edi.i18n.getMessage('invalid.date.format.fns'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('column.creation.date') + ': ',
							fieldLabel: edi.i18n.getMessage('document.created.to')
						},
						to: {
							chipTitle: ' — '
						}
					}
				})
			};
			const items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [[6]]
					},
					items: [formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]]
				})
			];
			return {
				formItemsMap,
				items
			};
		},
		createArgs: function (values) {
			if (values.dateFrom) {
				values.dateFrom = edi.utils.getSearchTime(values.dateFrom, edi.constants.DATE_FORMAT.SERVER);
			}
			if (values.dateTo) {
				values.dateTo = edi.utils.getSearchTime(
					values.dateTo,
					edi.constants.DATE_FORMAT.SERVER,
					edi.constants.SEARCH_DAY_IN_MS
				);
			}
			return values;
		}
	},
	partners: {
		createFormItems: function () {
			const formItemsMap = {
				name: createTextField({
					fieldLabel: edi.i18n.getMessage('organization'),
					name: 'name'
				}),
				inn: createNumberField({
					fieldLabel: edi.i18n.getMessage('company.inn.short'),
					name: 'inn'
				}),
				fnsId: createTextField({
					fieldLabel: edi.i18n.getMessage('company.fns.id'),
					name: 'fnsId'
				}),
				regNr: createTextField({
					fieldLabel: edi.i18n.getMessage('column.org.regNr'),
					name: 'regNr'
				}),
				iln: createNumberField({
					fieldLabel: edi.i18n.getMessage('company.gln.short'),
					name: 'iln'
				})
			};
			const items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [[6, 6], [6, 6], [6]]
					},
					items: [
						formItemsMap.name,
						formItemsMap.regNr,
						formItemsMap.inn,
						formItemsMap.iln,
						formItemsMap.fnsId
					]
				})
			];
			return {
				formItemsMap,
				items
			};
		}
	},
	deferred_reports: {
		createFormItems: function () {
			let reportTypes = edi.constants.REPORT_REQUEST.TYPE;
			if (Array.isArray(edi.constants.REPORT_REQUEST.TYPE_RULES)) {
				let rules = edi.constants.REPORT_REQUEST.TYPE_RULES;
				rules.forEach((rule) => {
					if (Ext.isObject(rule) && Array.isArray(reportTypes)) {
						if (edi.permissions.hasPermission(rule.permission)) {
							reportTypes.push(rule.type);
						}
					}
				});
			}
			const formItemsMap = {
				state: createCombo({
					fieldLabel: edi.i18n.getMessage('column.deferred.report.state'),
					name: 'state',
					store: edi.stores.createSimpleInlineStore(
						[
							edi.constants.REPORT_REQUEST.STATE.DRAFT,
							edi.constants.REPORT_REQUEST.STATE.WAIT_TO_CREATE_DOCUMENT,
							edi.constants.REPORT_REQUEST.STATE.CHECK_REPORT_REQUEST,
							edi.constants.REPORT_REQUEST.STATE.WAITING_FOR_DECISION,
							edi.constants.REPORT_REQUEST.STATE.COMPLETED
						],
						function (id) {
							return edi.i18n.getMessage('deferred.report.state.' + id);
						},
						true
					)
				}),
				requestType: createCombo({
					fieldLabel: edi.i18n.getMessage('column.deferred.report.request.type'),
					name: 'requestType',
					store: edi.stores.createSimpleInlineStore(
						reportTypes,
						function (id) {
							return edi.i18n.getMessage('deferred.report.type.' + id);
						},
						true
					)
				}),
				[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]: createDateRangeField({
					chipsModalTitle: edi.i18n.getMessage('module.form.creation.date'),
					period: edi.utils.getUserDefaultSearchPeriod('today'),
					fieldsConfig: {
						common: {
							invalidText: edi.i18n.getMessage('invalid.date.format.fns'),
							emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
						},
						from: {
							chipTitle: edi.i18n.getMessage('module.form.creation.date') + ': ',
							fieldLabel: edi.i18n.getMessage('module.form.creation.date.from.alt'),
							name: 'creationDateFrom'
						},
						to: {
							chipTitle: ' — ',
							name: 'creationDateTo'
						}
					}
				})
			};
			const items = [
				createPanel({
					layout: {
						type: 'grid',
						area: [[6, 6], [6]]
					},
					items: [
						formItemsMap.state,
						formItemsMap.requestType,
						formItemsMap[edi.constants.DEFAULT.FILTER.FIELDS.CREATION_DATE]
					]
				})
			];
			return {
				formItemsMap,
				items
			};
		}
	}
});
