const REESTR_MODEL_CONFIG_NAME = 'REESTR_ROUTE_REESTR';

edi.models.setModels({
	[REESTR_MODEL_CONFIG_NAME]: {
		fields: [
			{
				name: 'POSITION_NUM',
				type: 'string'
			},
			{
				name: 'EBELN',
				type: 'string'
			},
			{
				name: 'TKNUMITM',
				type: 'string'
			},
			{
				name: 'TS_TYPE',
				type: 'string'
			},
			{
				name: 'POINT_FROM_NAME',
				type: 'string'
			},
			{
				name: 'POINT_TO_NAME',
				type: 'string'
			},
			{
				name: 'ROUTE',
				type: 'string'
			},
			{
				name: 'TXT_ROUTE',
				type: 'string'
			},
			{
				name: 'UNITOFMEASURE',
				type: 'string'
			},
			{
				name: 'ZZPALL_T_AMOUNT',
				type: 'string'
			},
			{
				name: 'SUM_ZKFP',
				type: 'string'
			},
			{
				name: 'ADDPTSAMNT',
				type: 'string'
			},
			{
				name: 'SUM_ZFKK',
				type: 'string'
			},
			{
				name: 'CATEGORY_NAME',
				type: 'string'
			},
			{
				name: 'SUM_PAY',
				type: 'string'
			},
			{
				name: 'SUM_TOTAL',
				type: 'string'
			},
			{
				name: 'DRIVER_FIO',
				type: 'string'
			},
			{
				name: 'CAR_NUMBR',
				type: 'string'
			},
			{
				name: 'TONNAGE',
				type: 'string'
			},
			{
				name: 'ZTO_TRN',
				type: 'string'
			},
			{
				name: 'ZTO_UPD',
				type: 'string'
			},
			{
				name: 'ZTO_PE',
				type: 'string'
			},
			{
				name: 'ZTO_REG_TMS',
				type: 'string'
			},
			{
				name: 'ZTO_ADDR_FROM',
				type: 'string'
			},
			{
				name: 'ZTO_ADDR_TO',
				type: 'string'
			},
			{
				name: 'ZTO_DATE_UP',
				type: 'string'
			},
			{
				name: 'DPTEN',
				type: 'string'
			},
			{
				name: 'ZTO_TTN_P',
				type: 'string'
			},
			{
				name: 'ZTO_ML',
				type: 'string'
			},
			{
				name: 'ZTO_TKNUM',
				type: 'string'
			},
			{
				name: 'ZTO_PO',
				type: 'string'
			},
			{
				name: 'ZTO_PR',
				type: 'string'
			},
			{
				name: 'ZTO_TN',
				type: 'string'
			},
			{
				name: 'STATUS_POS',
				type: 'string'
			},
			{
				name: 'STATUS_DESCR',
				type: 'string'
			}
		]
	}
});

let getReestrModelName = () => edi.models.getModel(REESTR_MODEL_CONFIG_NAME);

export { getReestrModelName, REESTR_MODEL_CONFIG_NAME };
