export const ediHelpChat = {
	async initChat(cb) {
		const me = this;

		const ediHelpUrl = await me.getEdiHelpUrl();
		if (ediHelpUrl) {
			const systemId = await me.getSystemId();
			if (systemId) {
				const hashEncode = me.getChatData(systemId);

				me.createChatScript(ediHelpUrl, hashEncode);
			}
		}
		cb();
	},

	getChatData(systemId) {
		const userData = edi.core.getUserData();
		const org = userData.org;
		const fullname = userData.patronymicName
			? `${userData.lastName} ${userData.firstName} ${userData.patronymicName}`
			: `${userData.firstName} ${userData.lastName}`;

		const hash = {
			company: {
				name: org.name,
				gln: org.iln
			},
			user: {
				fullname: fullname,
				email: userData.eMail,
				lang: userData.language
			},
			server_id: systemId
		};

		return edi.utils.base64.encode(JSON.stringify(hash));
	},

	createChatScript(ediHelpUrl, hashEncode) {
		const script = document.createElement('script');

		script.setAttribute('async', true);
		script.setAttribute('type', 'text/javascript');

		script.setAttribute('id', 'edi-support-js');

		script.setAttribute('src', `${ediHelpUrl}/integration/js/supportLoader?autoload=yes&hash=${hashEncode}`);

		document.body.appendChild(script);
	},

	async getSystemId() {
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.rest.services.SYSTEM_ID.GET,
			method: 'GET'
		});

		if (!success) {
			return;
		}

		return data.data;
	},

	async getEdiHelpUrl() {
		const { success, data } = await edi.rest.asyncSendRequest({
			url: edi.rest.services.EDI_HELP_URL.GET,
			method: 'GET'
		});
		if (!success) {
			return;
		}

		return data.data;
	}
};
