import { createModulePanel } from '@Components/panels';
import { createTabPanel } from '@UIkit/components/tab/index';
import { ProfilePanel } from './profile.panel.js';
import { SettingPanel } from './setting.panel.js';
import { InvitationsPanel } from './invitations.panel';
import { NotifiesPanel } from './notifies.panel';
import { CredentialsPanel } from './credentials.panel';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';

Ext.namespace('edi.modules');
edi.modules['user.profile'] = function () {
	var moduleData;
	var tabPanel = null,
		tabs;

	/**
	 * Коллекция AbstractPanel
	 * Используется для проверки несохранённых данных при закрытии вкладки
	 * Панели не перечисленные в этом наборе не будут прослушиватся на наличие изменей
	 * @type {Object}
	 */
	var panels = {};

	/**
	 * Обещание содержащие данные о пользователе,
	 * использовать @method loadUserProfile для получения данных
	 * @type {Promise}
	 */
	var userProfileDataPromise;

	this.onRender = function () {};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;

		let { tab } = data;
		let options = data?.initData?.data?.options || {};
		tab.setLoading(true);

		renderData(tab, data, async () => {
			await this.readOptions(options || {});
			tab.setLoading(false);
			'function' === typeof initCallBack && initCallBack();
		});

		return onDestroy;
	};

	this.readOptions = async function ({ startTab }) {
		if (startTab) {
			if (startTab === 'settings') {
				tabPanel.setActiveTab(1);
			}
		}
	};

	var renderData = function (panel, data, initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});
		tabPanel = createTabPanel({
			margin: '16 0 0 0'
		});

		defineSettingDefaults().then((defaults) => {
			tabs = {
				profile: createProfilePanel(),
				settings: createSettingPanel(defaults),
				invitations: createInvitationsPanel(),
				notifies: createNotifiesPanel(),
				credentials: createCredentialsPanel()
			};
			var isFirst = true;
			var activeTab = edi.utils.getObjectProperty(moduleData, 'initData.data.activeTab');
			if (activeTab && tabs.hasOwnProperty(activeTab)) {
				isFirst = false;
			}

			for (var i in tabs) {
				initTabWithGrid(
					i,
					tabs[i],
					isFirst || activeTab === i,
					tabPanel,
					{
						title: tabs[i]?.tabTitle ? tabs[i].tabTitle : `ediweb.user.${i}.title`
					},
					{
						hideIcon: true,
						badgeCount: false
					}
				);

				isFirst = false;
			}

			modulePanel.add(tabPanel);
			panel.add(modulePanel);

			listenPanels();

			if ('function' == typeof initCallBack) {
				initCallBack();
			}
		});
	};

	var createProfilePanel = function () {
		let profilePanel = new ProfilePanel();

		panels.profilePanel = profilePanel;

		loadUserProfile().then((userProfileData) => {
			let { lastName, firstName, patronymicName, eMail, emailConfirmed, phones, personalId, birthDate } =
				userProfileData.userData;
			let { inn, nationality } = userProfileData.additionalData;
			profilePanel.setValues({
				lastName,
				firstName,
				patronymicName,
				eMail,
				phones,
				personalId,
				birthDate,
				inn,
				nationality
			});

			profilePanel.setEmailConfirmed(emailConfirmed);
			profilePanel.setReadOnlyPersonalId(!!personalId?.length);
		});

		return profilePanel.getPanel();
	};

	var createSettingPanel = function (defaults) {
		let settingPanel = new SettingPanel(defaults);

		panels.settingPanel = settingPanel;

		loadUserProfile().then((userProfileData) => {
			let { language, authenticationChains, thumbprint } = userProfileData.userData;

			let { saveFilter, saveSort, defaultFilteringPeriod } = userProfileData.additionalData;

			settingPanel.setValues({
				language,
				defaultFilteringPeriod,
				saveFilter: isLikeBoolean(saveFilter),
				saveSort: isLikeBoolean(saveSort),
				authenticationChains,
				thumbprint
			});
		});

		return settingPanel.getPanel();
	};

	var createNotifiesPanel = function () {
		if (!edi.permissions.hasPermission('CLIENT_NOTIFICATION')) {
			return null;
		}

		let panel = new NotifiesPanel();

		panels.notifiesPanel = panel;

		loadUserProfile().then(() => {
			panel.setValues({
				comment: 'Test data'
			});
		});

		return panel.getPanel();
	};

	var createCredentialsPanel = function () {
		return new CredentialsPanel().getPanel();
	};

	var createInvitationsPanel = function () {
		return (panels.invitationsPanel = new InvitationsPanel()).getPanel();
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);

		return true;
	};

	var defineSettingDefaults = function () {
		return new Promise((resolve, reject) => {
			loadUserProfile().then((profile) => {
				resolve({
					defaultFilteringPeriod: profile.additionalData.defaultFilteringPeriod
				});
			}, reject);
		});
	};

	var loadUserProfile = function () {
		if (userProfileDataPromise) {
			return userProfileDataPromise;
		}

		return (userProfileDataPromise = new Promise((resolve, reject) => {
			let profile = {
				additionalData: {},
				organizationData: {},
				isMD: false,
				confirmationData: {},
				userData: {}
			};

			new Promise((resolve) => {
				edi.login.getUser(
					function () {
						if (profile.userData.userData) {
							profile.additionalData = profile.userData.userData.user || {};
							profile.organizationData = profile.userData.userData.organization || {};
						}

						var checkMD = function () {
							edi.rest.sendRequest(
								edi.rest.services.USER.SELF.CHECK_MD.GET,
								'GET',
								null,
								function (respData) {
									profile.isMD = respData.data;
								},
								null,
								() => {
									resolve(profile);
								}
							);
						};

						var nextAction = function () {
							edi.rest.sendRequest(
								edi.rest.services.USER.EMAIL_CONFIRMATION.GET,
								'GET',
								null,
								function (responseData) {
									profile.confirmationData = responseData.data;
								},
								null,
								checkMD
							);
						};

						if (edi.permissions.hasPermission('READ_USER_CREDENTIALS')) {
							edi.rest.sendRequest(
								edi.rest.services.USER.SELF.CRD.GET,
								'GET',
								null,
								function (crdData) {
									if (crdData.success && crdData.data) {
										profile.userData.thumbprint = crdData.data.thumbprint;
										profile.userData.authenticationChains = crdData.data.authenticationChains.map(
											function (item) {
												return item.method && Array.isArray(item.method) && item.method.length
													? item.method[0]
													: null;
											}
										);
									}
								},
								null,
								nextAction
							);
						} else {
							nextAction();
						}
					},
					true,
					function (data) {
						let selectedOrgId = edi.core.getUserData().org.id;

						for (let i = 0; i < data.orgs.length; i++) {
							if (data.orgs[i].id == selectedOrgId) {
								data.org = data.orgs[i];
								break;
							}
						}

						profile.userData = data;
					}
				);
			}).then((profile) => resolve(profile));
		}));
	};

	var isLikeBoolean = function (value) {
		if (value === 'true') {
			return true;
		}
		if (value === 'false') {
			return false;
		}

		return null;
	};

	var listenPanels = function () {
		Object.entries(panels).forEach(([key, panel]) => {
			panel.events.on('changeTo', (targetPanel) => tabPanel.setActiveTab(targetPanel));
			panel.events.on('change', (targetPanel) => definePanelsDirtyState());
			panel.events.on('saveData', (targetPanel) => definePanelsDirtyState());
		});
	};

	var definePanelsDirtyState = function () {
		let isChanged = false;

		Object.entries(panels).forEach(([key, panel]) => {
			isChanged = isChanged || panel.isDirty();
			return !isChanged;
		});

		moduleData.isChanged = isChanged;
	};
};
