import { createEmailField } from '@Components/fields';
import { createProgressBar } from '@Ediweb/components/components';
import { createFieldBlock, createContainer, createModalForm, createPanel } from '@UIkit/components/panels';
import { createModalPanel } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createLabel, createLabelBlock, createTextField } from '@UIkit/components/fields';
import { showInfoToast } from '@Ediweb/core';

const getClientTariffCard = function (title, data, isLastUsedUpTariff, isPoa) {
	let progressBarSizes = {
		width: 360,
		height: 8
	};
	let progressBarAmount = {
		remainder: data.documentsNumber - data.remainDoc,
		total: data.documentsNumber
	};

	let progressBar = createProgressBar(progressBarSizes, progressBarAmount);

	let cardContainerCls = 'edi-ediweb-tariffs-client-card-container';
	if (isLastUsedUpTariff) {
		cardContainerCls += ' edi-ediweb-tariffs-client-card-container-usedUp';
	}
	if (data.wasted || data.state === 'SPEND' || data.state === 'EXPIRED') {
		cardContainerCls += ' error';
	}
	if (!data.wasted && (data.state === 'ACTIVE' || data.state === 'FUTURE')) {
		cardContainerCls += ' active';
	}

	let tariffCard = createContainer({
		cls: cardContainerCls,
		layout: {
			type: 'vbox',
			align: 'stretch'
		},
		items: [
			createContainer({
				layout: {
					type: 'hbox',
					align: 'stretch'
				},
				margin: '0 0 16 0',
				items: [
					createLabelBlock({
						isBlock: true,
						flex: 2,
						contents: [
							{
								typography: 'heading_01',
								text: title
							}
						]
					}),
					data.state
						? createLabel({
								typography: 'heading_01',
								html: edi.renderers.baseStateRenderer(
									data.state.toUpperCase(),
									edi.i18n.getMessage('tariff.status.' + data.state)
								)
						  })
						: null
				]
			}),
			createContainer({
				layout: {
					type: 'hbox'
				},
				items: [
					createContainer({
						items: [progressBar]
					})
				]
			}),
			createContainer({
				margin: '16 0 0 0',
				layout: {
					type: 'grid',
					gap: [16]
				},
				items: [
					createLabelBlock({
						contents: [
							{
								title: edi.i18n.getMessage(
									isPoa
										? 'ediweb.tariffs.progress.bar.poa.title'
										: 'ediweb.tariffs.progress.bar.title'
								),
								typography: 'body-short_01',
								html: data.unlimited
									? edi.i18n.getMessage('ediweb.tariffs.amount.info.unlimited')
									: edi.i18n.getMessage('ediweb.tariffs.amount.info', [
											`<span class="body-short_02">${data.remainDoc}</span>`,
											data.documentsNumber
									  ])
							}
						]
					}),
					createLabelBlock({
						contents: [
							{
								title: edi.i18n.getMessage('ediweb.tariffs.progress.bar.right.title.validity'),
								typography: 'body-short_01',
								text: !data.duration
									? edi.i18n.getMessage('ediweb.tariffs.validity.unlimited')
									: `${edi.renderers.dateFromMs(
											data?.duration?.periodFrom
									  )} — ${edi.renderers.dateFromMs(data?.duration?.periodTo)}`
							}
						]
					})
				]
			})
		]
	});

	return createContainer({
		cls: 'edi-ediweb-tariffs-client-card',
		width: 394,
		layout: {
			type: 'vbox',
			align: 'stretch'
		},
		items: [
			tariffCard,
			createLabel({
				typography: 'caption_01',
				color: '--color-grey-50',
				text: edi.i18n.getMessage(
					isPoa ? 'ediweb.tariffs.price.for.poa' : 'ediweb.tariffs.price.for.document',
					[data.documentCost]
				),
				margin: '6 0 16 0'
			})
		]
	});
};

const createIndividualModal = function () {
	const userData = edi.core.getUserData();
	const fio = createFieldBlock({
		title: edi.i18n.getMessage('full.name.abbr'),
		items: [
			createTextField({
				name: 'initiator',
				allowBlank: false,
				value: `${userData.lastName || ''} ${userData.firstName || ''} ${userData.patronymicName || ''}`
			})
		]
	});

	const email = createFieldBlock({
		title: edi.i18n.getMessage('email'),
		items: [
			createEmailField({
				name: 'email',
				allowBlank: false,
				value: userData.eMail
			})
		]
	});

	const phone = createFieldBlock({
		title: edi.i18n.getMessage('general.phone'),
		items: [
			createTextField({
				name: 'phone',
				value: userData.phones,
				inputMask: edi.constants.FIELD_MASK.PHONE,
				regex: edi.constants.VALIDATORS.PHONE,
				invalidText: edi.i18n.getMessage('ediweb.invalid.phone.format')
			})
		]
	});

	const org = createFieldBlock({
		title: edi.i18n.getMessage('organization'),
		items: [
			createTextField({
				readOnly: true,
				name: 'org',
				value: userData.org.name
			})
		]
	});

	const comment = createFieldBlock({
		title: edi.i18n.getMessage('ediweb.invitations.comment'),
		items: [
			createTextField({
				name: 'comment',
				maxLength: 1000,
				isTextarea: true,
				rowsHtmlAttributeValue: 3,
				autoScroll: true
			})
		]
	});
	const modalForm = createModalForm({
		layout: {
			type: 'grid',
			gap: [24],
			area: [12]
		},
		items: [fio, email, phone, org, comment]
	});
	const modal = createModalPanel({
		title: edi.i18n.getMessage('ediweb.tariffs.individual.modal.title'),
		items: [modalForm],
		buttons: [
			createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('ediweb.tariffs.individual.modal.button'),
				handler() {
					let failure = function (data) {
						if (data) {
							edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
						} else {
							edi.core.showError('error');
						}
					};
					if (!edi.utils.setFocusToDocumentsWithGrid(modalForm, ['email'], [], null, false)) {
						return;
					}
					var modalValues = edi.utils.collectFormValues(modalForm);
					delete modalValues.org;
					edi.rest.sendRequest(
						edi.rest.services.TARIFFS.REQUEST.POST,
						'POST',
						Ext.encode(modalValues),
						function () {
							modal.close();
							showInfoToast(
								edi.i18n.getMessage('ediweb.tariffs.individual.toast.title'),
								edi.i18n.getMessage('ediweb.tariffs.individual.toast.content'),
								{ showCloseBtn: true }
							);
						},
						failure
					);
				}
			}),
			createButton({
				text: edi.i18n.getMessage('ediweb.cancel.btn'),
				cls: BUTTON_CLS.secondary,
				handler: function () {
					modal.close();
				}
			})
		]
	});
	modal.show();
};

const changeTariffEventHandler = function (msgData) {
	if (msgData.success) {
		let tariffData = edi.utils.getObjectProperty(msgData, 'data.content.orgTariffs');
		const isFuture = function (data) {
			return new Date(data?.duration?.periodFrom) > new Date();
		};
		if (tariffData.length > 1) {
			edi.constants.IS_CAN_CREATE_DOCUMENT = !isFuture(tariffData[0]);
			edi.constants.IS_EXPIRED_DOCUMENT_TARIFF = false;
		} else {
			edi.constants.IS_CAN_CREATE_DOCUMENT = false;
			edi.constants.IS_EXPIRED_DOCUMENT_TARIFF = true;
			if (tariffData.length === 1) {
				let tariff = tariffData[0].tariff;
				edi.constants.IS_CAN_CREATE_DOCUMENT =
					(tariffData[0].remainDoc > 0 || tariffData[0]?.tariff?.unlimited) && !isFuture(tariffData[0]);
				edi.constants.IS_EXPIRED_DOCUMENT_TARIFF =
					(tariffData[0].remainDoc === 0 && !tariff.unlimited) || tariff.expired || tariffData[0].wasted;
			}
		}
		edi.events.actual_tariffs.fireEvent('change', tariffData);
	}
};

const changePOATariffEventHandler = function (msgData) {
	if (msgData.success) {
		let tariffData = edi.utils.getObjectProperty(msgData, 'data.content.orgTariffs');
		const isFuture = function (data) {
			return new Date(data?.duration?.periodFrom) > new Date();
		};
		if (tariffData.length > 1) {
			edi.constants.IS_CAN_CREATE_POA = !isFuture(tariffData[0]);
			edi.constants.IS_EXPIRED_POA_TARIFF = false;
		} else {
			edi.constants.IS_CAN_CREATE_POA = false;
			edi.constants.IS_EXPIRED_POA_TARIFF = false;
			if (tariffData.length === 1) {
				let tariff = tariffData[0].tariff;
				edi.constants.IS_CAN_CREATE_POA =
					(tariffData[0].remainDoc > 0 || tariffData[0]?.tariff?.unlimited) && !isFuture(tariffData[0]);
				edi.constants.IS_EXPIRED_POA_TARIFF =
					(tariffData[0].remainDoc === 0 && !tariff.unlimited) || tariff.expired || tariffData[0].wasted;
			}
		}
		edi.events.actual_poa_tariffs.fireEvent('change', tariffData);
	}
};

export { getClientTariffCard, createIndividualModal, changeTariffEventHandler, changePOATariffEventHandler };
