// import {createCheckbox, createTextField} from "@Components/fields";
// import {createFieldsBlock} from "@Ediweb/components/components";
// import {createActionsButton} from "@Components/buttons";

import { createTextField, createCheckbox } from '@UIkit/components/fields';
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels/FieldBlock/FieldBlock';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';

class AbstractPanel {
	_originalValues = null;

	/**
	 * @type {Ext.panel.Form}
	 * @protected
	 */
	_form;

	/**
	 * @type {Ext.Button}
	 * @protected
	 */
	_saveButton;

	/**
	 * @type {Ext.form.field.Base[]}
	 * @protected
	 */
	_fields = [];

	/**
	 * @type {Ext.util.Observable}
	 */
	events;

	constructor() {
		this.events = new Ext.util.Observable();
	}

	showLoader() {
		this.getPanel().setLoading(true);
	}

	hideLoader() {
		this.getPanel().setLoading(false);
	}

	setValues(values) {
		if (this._form) {
			this.fillForm(values);
			this.updateFieldsState();
		}

		this._originalValues = this.getValues();
		this.events.fireEvent('change');
	}

	getValues() {
		if (this._form) {
			// для collectFormValues убрала useModelData т.к. этот флаг дату отображает как new Date()
			return edi.utils.collectFormValues(this._form);
		}

		return {};
	}

	/**
	 * @return {Ext.panel.Panel}
	 */
	getPanel() {
		console.error(this, 'Need getPanel method subclass implementation', this);
	}

	createField(config, builder) {
		let { title, fieldLabel, name, hidden } = config;

		title = title || fieldLabel;
		builder = builder || createTextField;

		delete config.title;
		delete config.hidden;
		delete config.fieldLabel;

		let field = builder(
			Object.assign(
				{
					width: config.width || '100%',
					scope: this
				},
				config
			)
		);

		let container = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title,
			hidden,
			items: [field]
		});

		if (name) {
			this.storeField(name, container, field);
		}

		return container;
	}

	createActionButton(config) {
		config = Ext.merge(
			{
				cls: [BUTTON_CLS.outline, BUTTON_CLS.small]
			},
			config
		);

		// return createActionsButton(config);
		return createButton(config);
	}

	createCheckboxField(config) {
		let { name, title } = config;

		config = Ext.merge(
			{
				boxLabel: title,
				inputValue: true,
				uncheckedValue: false
			},
			config
		);

		let field = createCheckbox(config);

		this.storeField(name, field);

		return field;
	}

	/**
	 * @return {Object} - {fieldName: {hidden, disabled}}
	 *
	 */
	defineFieldStates() {
		console.error('Need defineFieldStates method subclass implementation', this);

		return {};
	}

	updateFieldsState() {
		if (this._updateFieldDelay) {
			clearTimeout(this._updateFieldDelay);
		}

		this._updateFieldDelay = setTimeout(() => {
			let fieldsStates = this.defineFieldStates();

			this.eachFields((name, field) => {
				let properties = fieldsStates[name] || {};

				if (!properties.hasOwnProperty('hidden')) {
					properties.hidden = false;
				}

				Object.entries(properties).forEach(([key, value]) => {
					let method = 'set' + key.substring(0, 1).toUpperCase() + key.substring(1);

					field[method] && field[method](value);
				});
			});

			this._form.isValid();
		}, 50);
	}

	createSaveButton(form) {
		form.on('validitychange', () => this.checkSaveButtonAbility());

		return (this._saveButton = createButton({
			text: edi.i18n.getMessage('form.btn.save'),
			cls: [BUTTON_CLS.primary],
			handler: () => this.save(form.getValues())
		}));
	}

	checkSaveButtonAbility() {
		this.toggleSaveButton(this._form.isValid());
	}

	toggleSaveButton(ability) {
		this._saveButton.setDisabled(!ability);
	}

	storeField(name, container, field) {
		let getDataSource = () => field || container;

		getDataSource().on('change', () => this.events.fireEvent('change'));

		this._fields[name] = {
			getValue() {
				return getDataSource().getValue ? getDataSource().getValue() : null;
			},
			setValue(value) {
				getDataSource().setValue && getDataSource().setValue(value);
			},
			setMandatory(value) {
				getDataSource().setMandatory && getDataSource().setMandatory(value);
			},
			setDisabled(state) {
				getDataSource().setDisabled(state);
			},
			setHidden(state) {
				container.setVisible(!state);
			}
		};
	}

	getField(name) {
		return this._fields[name];
	}

	eachFields(fn) {
		Object.entries(this._fields).forEach((name, field) => fn(name[0], name[1]));
	}

	fillForm(formData) {
		this.eachFields((name, field) => {
			let value = formData[name];

			field.setValue && field.setValue(value);
		});
	}

	isDirty() {
		return this._originalValues !== null && !Ext.Object.equals(this._originalValues, this.getValues());
	}

	async save(formValues) {
		this.showLoader();

		try {
			let self = this;

			await this.saveFormValues(formValues).then(() => {
				edi.login.getUser(null, true, function (data) {
					self._originalValues = formValues;
					var langChanged =
						formValues.hasOwnProperty('language') &&
						formValues.language != edi.utils.getCookie(edi.constants.DEFAULT.LANGUAGE_COOKIE_NAME);
					var personalIdField = self._form?.down("[itemId ='personalId']");
					if (personalIdField) {
						personalIdField.setReadOnly(formValues.personalId);
					}
					if (typeof self.setEmailConfirmed === 'function') {
						self.setEmailConfirmed(data.emailConfirmed);
						self.verifiedButton.setDisabled(data.emailConfirmed);
					}
					if (langChanged) {
						edi.utils.setCookie(edi.constants.DEFAULT.LANGUAGE_COOKIE_NAME, formValues.language);
						document.location.reload();
					}
					self.events.fireEvent('saveData');
				});
			});
		} catch (error) {
			throw error;
		} finally {
			this.events.fireEvent('change');
			this.hideLoader();
		}
	}

	/**
	 * @unused
	 */
	saveChanged() {
		if (this.isDirty()) {
			let values = this.getValues();
			this.save(values);
		}
	}

	async saveFormValues(values) {
		//need subclass realisation
	}

	/**
	 * Смена панели будет остановлена если форма содержит изменения
	 * @param panel
	 */
	makePanelConfirmable(panel) {
		panel.on('beforedeactivate', (currentPanel, targetPanel) => {
			if (this.isDirty()) {
				this.confirmPanelDeactivation(currentPanel.title)
					.then(
						() => this.save(this.getValues()),
						() => this.resetFormChanges()
					)
					.then(
						() => this.events.fireEvent('changeTo', targetPanel),
						() => {
							edi.core.showError('user.profile.tab.change.message.error', () => {
								this.resetFormChanges();
								this.events.fireEvent('changeTo', targetPanel);
							});
						}
					);

				return false;
			}

			return true;
		});
	}

	confirmPanelDeactivation(panelTitle) {
		return new Promise((resolve, reject) => {
			edi.core.confirm(
				'user.profile.tab.change.message',
				edi.i18n.getMessage('user.profile.tab.change.message.content', [panelTitle]),
				resolve,
				reject
			);
		});
	}

	resetFormChanges() {
		this.setValues(this._originalValues);
	}
}

export { AbstractPanel };
