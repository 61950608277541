import { createActionsColumnConfig, createGrid } from '@UIkit/components/grid';
import { createTreePanel } from '@UIkit/components/treePanel';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createModalForm, createPanel } from '@UIkit/components/panels';
import { createProxyConfig } from '@Components/storeComponents';

Ext.namespace('edi.document.actions');

Ext.merge(edi.document.actions, {
	linkedDocumentsDialog: function (id, config, urls, depth) {
		config = 'object' == typeof config ? config : {};
		urls = 'object' == typeof urls ? urls : {};
		var modalConfig = 'object' == typeof config.modalConfig ? config.modalConfig : {};
		var treeUrl = !!urls.tree ? urls.tree : edi.rest.services.DOCUMENTS.LINKED.TREE.GET;
		var pathUrl = !!urls.path ? urls.path : edi.rest.services.DOCUMENTS.LINKED.PATH.GET;
		var contentUrl = !!urls.content ? urls.content : edi.rest.services.DOCUMENTS.CONTENT.GET;
		var docData = 'object' == typeof config.docData ? config.docData : null;
		if (id) {
			edi.rest.sendRequest(
				edi.utils.formatString(pathUrl, {
					documentId: id
				}),
				'GET',
				null,
				function (data) {
					if (data && data.items && data.items.length) {
						var path = data.items.reverse();
						var openMap = {};
						for (var i = 0; i < path.length; i++) {
							openMap[path[i]] = 'init';
						}

						var finishLoad = function (data, approvals) {
							var model = edi.models.getModel('DOCUMENT_LINKED');
							var modelClass = Ext.ClassManager.get(model);
							modelClass.addStatics({
								openMap: openMap,
								currentId: id
							});
							var filterLinkedDocuments = function (data, key) {
								recursiveForEach(data);

								function recursiveForEach(data) {
									if (Array.isArray(data)) {
										data.forEach(function (document) {
											if (!edi.constants.RECEIPTS.some((it) => it === document.type)) {
												if (document.hasOwnProperty(key)) {
													if (document[key] != null) {
														document[key] = document[key].filter(function (
															attachedDocument
														) {
															return (
																!edi.constants.RECEIPTS.some(
																	(it) => it === attachedDocument.type
																) && !attachedDocument.deleted
															);
														});
														recursiveForEach(document[key]);
													} else {
														document.leaf = true;
													}
												}
											}
										});
									}
								}

								return data;
							};
							var store = new Ext.data.TreeStore({
								proxy: {
									type: 'memory',
									data: filterLinkedDocuments(data.items, 'items')
								},
								root: { id: 0 },
								defaultRootProperty: 'items',
								model: model
							});

							var isAvailableForDetailsWindow = function (type) {
								return (
									type === edi.constants.DOCUMENT_TYPES.EDI_CONTRL ||
									type === edi.constants.DOCUMENT_TYPES.LEGACY_APERAK
								);
							};
							var columns = edi.columns.get('documents_all_linked');
							columns.push(
								createActionsColumnConfig({
									flex: 0.5,
									align: 'center',
									width: edi.utils.getActionColumnWidth(1),
									minWidth: edi.utils.getActionColumnWidth(1),
									maxWidth: edi.utils.getActionColumnWidth(1),
									items: [
										{
											glyph: edi.constants.ICONS.DETAILS,
											handler: function (grid, rowIndex, colindex, actionItem, event, record) {
												rowClick(record);
											},
											isActionDisabled: function (view, rowIndex, colIndex, item, record) {
												var type = edi.constants.DOCUMENT_TYPES[record.get('type')];
												var isAvailableDoc =
													edi.constants.DETAILS_MODULE_NAME_BY_TYPE.hasOwnProperty(type);
												var isAvailableForDetails = isAvailableForDetailsWindow(
													record.get('type')
												);
												return (
													record.get('id') == id ||
													record.get('archived') ||
													(!isAvailableDoc && !isAvailableForDetails)
												);
											}
										}
									],
									renderer: function (value, metaData, record) {
										if (record.get('archived')) {
											metaData.tdAttr =
												'data-qtip="' +
												edi.utils.replaceQuotes(edi.i18n.getMessage('document.in.archive')) +
												'"';
										} else if (!record.get('isCurrent')) {
											metaData.tdAttr =
												'data-qtip="' +
												edi.utils.replaceQuotes(
													edi.i18n.getMessage('document.open.linked.docs.details')
												) +
												'"';
										} else {
											metaData.tdAttr = null;
										}
										record.get('isCurrent')
											? (metaData.tdCls += ' edi-linked-current-element')
											: null;
										return value;
									}
								})
							);
							var tree = createTreePanel({
								header: {
									cls: 'heading_01',
									title: edi.i18n.getMessage('navigation.documents'),
									padding: '0 0 16 0'
								},
								cls: 'ui-table-with-shadow',
								border: 0,
								margin: '0 0 24 0',
								store: store,
								rootVisible: false,
								columns: columns,
								useArrows: true,
								disableSelection: true,
								listeners: {
									celldblclick: function (view, td, cellIndex, record) {
										if (cellIndex && !record.get('archived')) {
											rowClick(record);
										}
									}
								}
							});
							var rowClick = function (record) {
								var data = record.getData();
								if (id != data.id) {
									var type = record.get('type');
									if (isAvailableForDetailsWindow(type)) {
										edi.document.actions.openDetailsWindow(type, data, contentUrl);
									} else {
										modal.close();
										config && typeof config.openDetailsModule === 'function'
											? config.openDetailsModule(type, data)
											: edi.document.actions.openDetailsModule(type, data);
									}
								}
							};

							const openApprovalDocument = function (record) {
								const data = record.getData();
								const title = data.title || data.routeName;
								if (data?.route?.routeType === 'DYNAMIC') {
									edi.core.openModule('approval.employees.details', data, title);
								} else {
									edi.core.openModule('approval.documents.details', data, title);
								}
								modal.close();
							};

							var approvalsColumns = edi.columns.get('ew_approvals_modal');
							var approvalsActionButtonConfig = [
								{
									glyph: edi.constants.ICONS.DETAILS,
									testCls: 'detail_approval_documents-column-details',
									disabled: !edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_READ'),
									handler: function (grid, rowIndex) {
										const record = grid.getStore().getAt(rowIndex);
										openApprovalDocument(record);
									}
								}
							];

							approvalsColumns.push(
								createActionsColumnConfig({
									align: 'right',
									items: approvalsActionButtonConfig
								})
							);
							var approvalsGrid =
								approvals && approvals.children.length
									? createGrid({
											storeConfig: {
												model: edi.models.getModel('EW_APPROVAL_DOCUMENTS'),
												proxy: createProxyConfig({
													type: 'memory',
													data: {
														items: approvals.children
													}
												})
											},
											gridConfig: {
												header: {
													cls: 'heading_01',
													title: edi.i18n.getMessage('ew.documents.approvals.grid.title'),
													padding: '0 0 16 0'
												},
												listeners: {
													celldblclick: function (view, td, cellIndex, record) {
														openApprovalDocument(record);
													}
												},
												cls: 'ui-table-with-shadow',
												border: 0,
												margin: '0 0 32 0',
												columns: approvalsColumns,
												disablePaging: true,
												disableSelection: true
											}
									  })
									: null;

							var modal = createModalPanel(
								Ext.merge(
									{
										cls: 'edi-ediweb-modal-linked-documents',
										title: edi.i18n.getMessage('document.linked.docs'),
										width: MODAL_SIZE.widthLarge,
										height: approvalsGrid ? 646 : 373,
										isHideButtons: true,
										buttons: null,
										items: [
											createModalForm({
												layout: approvalsGrid
													? {
															type: 'vbox',
															align: 'stretch'
													  }
													: 'fit',
												items: approvalsGrid
													? [
															createPanel({
																layout: 'fit',
																padding: 0,
																margin: 0,
																flex: 1,
																items: [approvalsGrid]
															}),
															createPanel({
																layout: 'fit',
																padding: 0,
																margin: 0,
																flex: 1,
																items: [tree]
															})
													  ]
													: [tree]
											})
										]
									},
									modalConfig
								),
								true
							);
							modal.show();
						};
						edi.rest.sendRequest(
							edi.utils.formatString(treeUrl, {
								documentId: path[0],
								root: true,
								depth: !!depth ? depth : edi.constants.DEFAULT.TREE_DEPTH
							}),
							'GET',
							null,
							function (data) {
								var isHasApprovalDocument = docData
									? docData.attributes.hasOwnProperty('APPROVAL_STATE')
									: false;
								if (isHasApprovalDocument) {
									edi.rest.sendRequest(
										edi.utils.formatString(
											edi.rest.services.APPROVAL_DOCUMENTS.LINKED_APPROVALS.GET,
											{
												docId: id
											}
										),
										'GET',
										null,
										function (approvals) {
											if (approvals.success && approvals.data) {
												finishLoad(data, approvals.data);
											}
										},
										edi.rest.getErrorHandler()
									);
								} else {
									finishLoad(data);
								}
							}
						);
					}
				}
			);
		}
	}
});
