/**
 * UI permissions for Fns package
 * @author Anatoli Deryshev
 */
Ext.namespace('edi.permissions');
edi.permissions.setUiPermissions(
	{
		SIGN_EDI_FNS_UPD: {
			OR: [
				'SIGN_EDI_FNS_UPD_SUPPLIER', // "SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER"
				'SIGN_EDI_FNS_UPD_CONSUMER' // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_SCHF_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_DOP_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},

		/*
		SIGN_EDI_FNS_UPD_SUPPLIER: {   // SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER
			OR: [
				'SIGN_EDI_FNS_UPD_SUPPLIER' //'SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER',
				//'SIGN_EDI_FNS_UPD_SUPPLIER' // 'SIGN_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE'
			]
		},
		*/

		/*
		SIGN_EDI_FNS_UKD_SUPPLIER: {  // SIGN_EDI_FNS_UKD_KSCHF_DIS_SUPPLIER
			OR: [
				'SIGN_EDI_FNS_UKD_SUPPLIER' // 'SIGN_EDI_FNS_UKD_KSCHF_DIS_SUPPLIER'
				//'SIGN_EDI_FNS_UKD_SUPPLIER' // 'SIGN_UKD_KSCHF_DIS_SIMPLE'
			]
		},
		*/

		/*
		SIGN_EDI_FNS_UPD_CONSUMER: { // SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER
			OR: [
				//'SIGN_EDI_FNS_UPD_CONSUMER', //'SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER',
				'SIGN_EDI_FNS_UPD_CONSUMER' // 'SIGN_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE_CONSUMER'
			]
		},
		*/

		/*
		SIGN_EDI_FNS_UKD_KSCHF_SUPPLIER: 'SIGN_EDI_FNS_UKD_KSCHF',
		* После замены
		*    SIGN_EDI_FNS_UKD_KSCHF_SUPPLIER на SIGN_EDI_FNS_UKD_CONSUMER и
		*    SIGN_EDI_FNS_UKD_KSCHF на SIGN_EDI_FNS_UKD_SUPPLIER
		*    получаем
		*    SIGN_EDI_FNS_UKD_CONSUMER: "SIGN_EDI_FNS_UKD_SUPPLIER"
		*    что не имеет смысла, поэтому правило удалено
		*/

		/*
		SIGN_EDI_FNS_UKD_KSCHF_CONSUMER: {
			OR: [
				'SIGN_EDI_FNS_UKD_SUPPLIER' // 'SIGN_EDI_FNS_UKD_KSCHF'
			]
		},
		* Право SIGN_EDI_FNS_UKD_KSCHF_CONSUMER отсутствовало на беке,
		*    Также отключаю, как и правило выше не имеет смысла
		*/

		/*
		SIGN_EDI_FNS_UKD_CONSUMER: { // SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER
			OR: [
				'SIGN_EDI_FNS_UKD_CONSUMER', // 'SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER'
				//'SIGN_EDI_FNS_UKD_CONSUMER' // 'SIGN_UKD_KSCHF_DIS_SIMPLE_CONSUMER'
			]
		},
		*/

		SIGN_EDI_FNS_UKD: {
			OR: [
				'SIGN_EDI_FNS_UKD_SUPPLIER', // "SIGN_EDI_FNS_UKD_KSCHF_DIS_SUPPLIER"
				'SIGN_EDI_FNS_UKD_CONSUMER' // "SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER",
				//"SIGN_EDI_FNS_UKD_SUPPLIER", //"SIGN_EDI_FNS_UKD_KSCHF"
				//"SIGN_EDI_FNS_UKD_SUPPLIER", // "SIGN_EDI_FNS_UKD_DIS_SUPPLIER"
				//"SIGN_EDI_FNS_UKD_CONSUMER" // "SIGN_EDI_FNS_UKD_DIS_CONSUMER"
			]
		},
		SIGN_EDI_FNS_DP_IZVPOL: {
			OR: [
				'SIGN_EDI_FNS_UPD_SUPPLIER', // "SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER"
				'SIGN_EDI_FNS_UPD_CONSUMER' // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_SCHF_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_DOP_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},

		SIGN_EDI_FNS_UPD_STATUS_4_DP_IZVPOL: 'SIGN_EDI_FNS_UPD_SUPPLIER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_4_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_SUPPLIER" // "SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER"
				// "SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_SCHF_SUPPLIER"
				// "SIGN_EDI_FNS_UPD_SUPPLIER" // "SIGN_EDI_FNS_UPD_DOP_SUPPLIER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_STATUS_5_DP_IZVPOL: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*SIGN_EDI_FNS_UPD_STATUS_5_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER", // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHF_CONSUMER"
			]
		},*/

		SIGN_EDI_FNS_UPD_STATUS_5_DP_IZVPOL_DECREE_14: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_5_DP_IZVPOL_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER", // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHF_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_STATUS_6_DP_IZVPOL: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_6_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" //"SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_STATUS_8_DP_IZVPOL: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_8_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER"//"SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER",
				//"SIGN_EDI_FNS_UPD_CONSUMER", // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHF_CONSUMER"
			]
		},
		*/

		EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		EDI_FNS_UPD_STATUS_8_IOP_DP_PDOTPR_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER"//"SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER",
				//"SIGN_EDI_FNS_UPD_CONSUMER", //"SIGN_EDI_FNS_UPD_DOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" //"SIGN_EDI_FNS_UPD_SCHF_CONSUMER"
			]
		},
		*/

		CLARIFY_EDI_FNS_UPD: 'CREATE_EDI_FNS_UPD_STATUS_9_DP_UVUTOCH',
		ANNUL_EDI_FNS_UPD: 'CREATE_EDI_FNS_DP_PRANNUL',

		CLARIFY_EDI_FNS_UPD_SERVICE_AKT: 'CREATE_EDI_FNS_UPD_SERVICE_AKT_STATUS_9_DP_UVUTOCH',
		ANNUL_EDI_FNS_UPD_SERVICE_AKT: 'CREATE_EDI_FNS_DP_PRANNUL',

		SIGN_EDI_FNS_UPD_SERVICE_STATUS_2_DP_PDPOL: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_SERVICE_STATUS_2_DP_PDPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" //"SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER",
				//"SIGN_EDI_FNS_UPD_CONSUMER" // SIGN_EDI_FNS_UPD_DOP_CONSUMER
			]
		},
		*/

		SIGN_EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_2_DP_PDPOL_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER",
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_SERVICE_STATUS_3_DP_PDOTPR: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_SERVICE_STATUS_3_DP_PDOTPR: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" //"SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER",
				//"SIGN_EDI_FNS_UPD_CONSUMER"  //"SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_3_DP_PDOTPR_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" //"SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER",
				//"SIGN_EDI_FNS_UPD_CONSUMER" //"SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_SERVICE_STATUS_4_DP_IZVPOL: 'SIGN_EDI_FNS_UPD_SUPPLIER',
		/*
		SIGN_EDI_FNS_UPD_SERVICE_STATUS_4_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_SUPPLIER" // "SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_SCHF_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER" // "SIGN_EDI_FNS_UPD_DOP_SUPPLIER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14: 'SIGN_EDI_FNS_UPD_SUPPLIER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_4_DP_PDOTPR_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UPD_SUPPLIER" // SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_SCHF_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER" // "SIGN_EDI_FNS_UPD_DOP_SUPPLIER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL_DECREE_14: 'SIGN_EDI_FNS_UPD_SERVICE_AKT',
		/*
		SIGN_EDI_FNS_UPD_SERVICE_STATUS_5_DP_IZVPOL_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UPD_SERVICE_AKT"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_SERVICE_STATUS_6_DP_IZVPOL: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_SERVICE_STATUS_6_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_6_IOP_DP_PDPOL_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER"  // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_SERVICE_STATUS_7_DP_PDOTPR: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_SERVICE_STATUS_7_DP_PDOTPR: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_STATUS_7_IOP_DP_PDOTPR_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UPD_SERVICE_STATUS_8_DP_IZVPOL: 'SIGN_EDI_FNS_UPD_CONSUMER',
		/*
		SIGN_EDI_FNS_UPD_SERVICE_STATUS_8_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER" // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UKD_STATUS_4_DP_IZVPOL: 'SIGN_EDI_FNS_UKD_SUPPLIER',
		/*
		SIGN_EDI_FNS_UKD_STATUS_4_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UKD_SUPPLIER" //"SIGN_EDI_FNS_UKD_KSCHF_DIS_SUPPLIER"
				//"SIGN_EDI_FNS_UKD_SUPPLIER" //"SIGN_EDI_FNS_UKD_KSCHF"
				//"SIGN_EDI_FNS_UKD_SUPPLIER" //"SIGN_EDI_FNS_UKD_DIS_SUPPLIER"
			]
		},
		*/

		SIGN_EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14L: 'SIGN_EDI_FNS_UKD_SUPPLIER',
		/*SIGN_EDI_FNS_UKD_STATUS_4_DP_PDOTPR_DECREE_14L: {
			OR: [
				"SIGN_EDI_FNS_UKD_SUPPLIER" // "SIGN_EDI_FNS_UKD_KSCHF_DIS_SUPPLIER"
				//"SIGN_EDI_FNS_UKD_SUPPLIER" //"SIGN_EDI_FNS_UKD_KSCHF"
				//"SIGN_EDI_FNS_UKD_SUPPLIER" //"SIGN_EDI_FNS_UKD_DIS_SUPPLIER"
			]
		},*/

		SIGN_EDI_FNS_UKD_STATUS_5_DP_IZVPOL: 'SIGN_EDI_FNS_UKD_CONSUMER',
		/*
		SIGN_EDI_FNS_UKD_STATUS_5_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UKD_CONSUMER"//"SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER",
				//"SIGN_EDI_FNS_UKD_CONSUMER" //"SIGN_EDI_FNS_UKD_DIS_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14: 'SIGN_EDI_FNS_UKD_CONSUMER',
		/*
		SIGN_EDI_FNS_UKD_STATUS_5_DP_IZVPOL_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UKD_CONSUMER" //"SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER"
				//"SIGN_EDI_FNS_UKD_CONSUMER" // "SIGN_EDI_FNS_UKD_DIS_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UKD_STATUS_6_DP_IZVPOL: 'SIGN_EDI_FNS_UKD_CONSUMER',
		/*
		SIGN_EDI_FNS_UKD_STATUS_6_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UKD_CONSUMER" // "SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER"
				//"SIGN_EDI_FNS_UKD_CONSUMER" //"SIGN_EDI_FNS_UKD_DIS_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14: 'SIGN_EDI_FNS_UKD_CONSUMER',
		/*
		SIGN_EDI_FNS_UKD_STATUS_6_IOP_DP_PDPOL_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UKD_CONSUMER" // "SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER"
				//"SIGN_EDI_FNS_UKD_CONSUMER" //"SIGN_EDI_FNS_UKD_DIS_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_UKD_STATUS_8_DP_IZVPOL: 'SIGN_EDI_FNS_UKD_CONSUMER',
		/*SIGN_EDI_FNS_UKD_STATUS_8_DP_IZVPOL: {
			OR: [
				"SIGN_EDI_FNS_UKD_CONSUMER" // "SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER"
				//"SIGN_EDI_FNS_UKD_CONSUMER" //"SIGN_EDI_FNS_UKD_DIS_CONSUMER"
			]
		},*/

		SIGN_EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14: 'SIGN_EDI_FNS_UKD_CONSUMER',
		/*
		SIGN_EDI_FNS_UKD_STATUS_8_IOP_DP_PDOTPR_DECREE_14: {
			OR: [
				"SIGN_EDI_FNS_UKD_CONSUMER" // "SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER"
				//"SIGN_EDI_FNS_UKD_CONSUMER" //"SIGN_EDI_FNS_UKD_DIS_CONSUMER"
			]
		},
		*/

		SIGN_EDI_FNS_TORG2_DP_IZVPOL: 'SIGN_EDI_FNS_TORG2_IZVPOL',

		CLARIFY_EDI_FNS_UKD: 'CREATE_EDI_FNS_UKD_STATUS_9_DP_UVUTOCH',
		ANNUL_EDI_FNS_UKD: 'CREATE_EDI_FNS_DP_PRANNUL',

		CLARIFY_EDI_FNS_TORG2: 'CREATE_EDI_FNS_TORG2_DP_UVUTOCH',
		ANNUL_EDI_FNS_TORG2: 'CREATE_EDI_FNS_DP_PRANNUL',

		SIGN_EDI_PRICAT_EANCOM_DP_IZVPOL: 'SIGN_PRICAT_EANCOM',

		ACCEPT_EDI_FNS_UPD: 'SIGN_EDI_FNS_UPD_CONSUMER',
		//ACCEPT_EDI_FNS_UPD: "CREATE_EDI_FNS_UPD",
		/*
		ACCEPT_EDI_FNS_UPD: {
			OR: [
				"CREATE_EDI_FNS_UPD" // "CREATE_EDI_FNS_UPD_P2"
				//"CREATE_EDI_FNS_UPD" //"CREATE_EDI_FNS_UPD_SCHFDOP_P2"
				//"CREATE_EDI_FNS_UPD" //"CREATE_EDI_FNS_UPD_DOP_P2"
			]
		},
		*/

		/*
		COMPLETE_EDI_FNS_UPD: {
			OR: [
				"COMPLETE_EDI_FNS_UPD",
				//"COMPLETE_EDI_FNS_UPD", // "COMPLETE_EDI_FNS_UPD_SCHF"
				//"COMPLETE_EDI_FNS_UPD", //"COMPLETE_EDI_FNS_UPD_DOP"
				//"COMPLETE_EDI_FNS_UPD" // "COMPLETE_EDI_FNS_UPD_SCHFDOP"
			]
		},
		*/

		/*
		DELETE_EDI_FNS_UPD: {
			OR: [
				"DELETE_EDI_FNS_UPD",
				//"DELETE_EDI_FNS_UPD", // "DELETE_EDI_FNS_UPD_SCHFDOP"
				//"DELETE_EDI_FNS_UPD", // "DELETE_EDI_FNS_UPD_SCHF"
				//"DELETE_EDI_FNS_UPD" // "DELETE_EDI_FNS_UPD_DOP"
			]
		},
		*/

		/*
		DELETE_EDI_FNS_UKD: {
			OR: [
				"DELETE_EDI_FNS_UKD"
				//"DELETE_EDI_FNS_UKD", //"DELETE_EDI_FNS_UKD_KSCHF_DIS",
				//"DELETE_EDI_FNS_UKD" //"DELETE_EDI_FNS_UKD_KSCHF"
				//"DELETE_EDI_FNS_UKD" //"DELETE_EDI_FNS_UKD_DIS"
			]
		},
		*/

		/*
		COMPLETE_EDI_FNS_UKD: {
			OR: [
				"COMPLETE_EDI_FNS_UKD"
				//"COMPLETE_EDI_FNS_UKD", //"COMPLETE_EDI_FNS_UKD_KSCHF_DIS",
				//"COMPLETE_EDI_FNS_UKD", //"COMPLETE_EDI_FNS_UKD_KSCHF",
				//"COMPLETE_EDI_FNS_UKD" //"COMPLETE_EDI_FNS_UKD_DIS"
			]
		},
		*/

		ACCEPT_EDI_FNS_UKD: 'CREATE_EDI_FNS_UKD'
		/*
		ACCEPT_EDI_FNS_UKD: {
			OR: [
				"CREATE_EDI_FNS_UKD_P2",
				"CREATE_EDI_FNS_UKD" //"CREATE_EDI_FNS_UKD_KSCHF_DIS_P2",
				//"CREATE_EDI_FNS_UKD"//"CREATE_EDI_FNS_UKD_DIS_P2"
			]
		},
		* Право CREATE_EDI_FNS_UKD_P2 отсутствует на беке
		*/

		/*
		CREATE_EDI_FNS_UKD_P2: {
			OR: [
				"CREATE_EDI_FNS_UKD_P2",
				"CREATE_EDI_FNS_UKD" //"CREATE_EDI_FNS_UKD_KSCHF_DIS_P2",
				//"CREATE_EDI_FNS_UKD" //"CREATE_EDI_FNS_UKD_DIS_P2"
			]
		}
		* Право CREATE_EDI_FNS_UKD_P2 отсутствует на беке
		*/
	},
	{
		CREATE_DOCUMENTS: {
			OR: [
				'CREATE_EDI_FNS_UPD',
				'CREATE_EDI_FNS_UKD',
				'CREATE_DSF_EDI_FNS_INVOICE',
				'CREATE_DSF_EDI_FNS_ACT',
				'CREATE_DSF_EDI_KC2',
				'CREATE_DSF_EDI_KC3',
				'CREATE_DSF_EDI_KC11',
				'CREATE_DSF_EDI_KC14'
			]
		},
		READ_DOCUMENTS: {
			OR: [
				'READ_EDI_FNS_UPD',
				'READ_EDI_FNS_UKD',
				'READ_EDI_FNS_UPD_SERVICE_AKT',
				'READ_DSF_EDI_FNS_INVOICE',
				'READ_DSF_EDI_FNS_ACT',
				'READ_DSF_EDI_KC2',
				'READ_DSF_EDI_KC3',
				'READ_DSF_EDI_KC11',
				'READ_DSF_EDI_KC14'
			]
		},
		CREATE_DSF: {
			OR: [
				'CREATE_DSF_EDI_FNS_INVOICE',
				'CREATE_DSF_EDI_FNS_ACT',
				'CREATE_DSF_EDI_KC2',
				'CREATE_DSF_EDI_KC3',
				'CREATE_DSF_EDI_KC11',
				'CREATE_DSF_EDI_KC14'
			]
		},
		EDIT_DSF: {
			OR: [
				'EDIT_DSF_EDI_FNS_INVOICE',
				'EDIT_DSF_EDI_FNS_ACT',
				'EDIT_DSF_EDI_KC2',
				'EDIT_DSF_EDI_KC3',
				'EDIT_DSF_EDI_KC11',
				'EDIT_DSF_EDI_KC14'
			]
		},
		READ_DSF: {
			OR: [
				'READ_DSF_EDI_FNS_INVOICE',
				'READ_DSF_EDI_FNS_ACT',
				'READ_DSF_EDI_KC2',
				'READ_DSF_EDI_KC3',
				'READ_DSF_EDI_KC11',
				'READ_DSF_EDI_KC14'
			]
		},
		READ_EDI_FNS_UPD_SERVICE_AKT: {
			OR: ['CREATE_EDI_FNS_UPD_SERVICE_AKT', 'EDIT_EDI_FNS_UPD_SERVICE_AKT']
		},
		ACCEPT_EDI_FNS_UPD_SERVICE_AKT: {
			OR: ['READ_EDI_FNS_UPD_SERVICE_AKT_P2', 'CREATE_EDI_FNS_UPD_SERVICE_AKT_P2']
		},
		ACCEPT_UNSIGNED_NOTICES_GROUP: {
			AND: [
				'SIGN_EDI_FNS_UPD_SUPPLIER', // "SIGN_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE",
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_SCHFDOP_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_SCHFDOP_SCHFDOP_SIMPLE_CONSUMER"
				'SIGN_EDI_FNS_UPD_CONSUMER', // "SIGN_EDI_FNS_UPD_SCHFDOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_SCHF_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_SUPPLIER", // "SIGN_EDI_FNS_UPD_DOP_SUPPLIER"
				//"SIGN_EDI_FNS_UPD_CONSUMER", // "SIGN_EDI_FNS_UPD_DOP_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER", // "SIGN_EDI_FNS_UPD_SCHF_CONSUMER"
				'SIGN_EDI_FNS_UPD_STATUS_9_DP_UVUTOCH',
				'SIGN_EDI_FNS_TORG2_DP_UVUTOCH',
				'SIGN_EDI_FNS_UPD_STATUS_10_DP_IZVPOL',
				'SIGN_EDI_FNS_UKD_STATUS_9_DP_UVUTOCH',
				'SIGN_EDI_FNS_UKD_STATUS_10_DP_IZVPOL',
				'SIGN_EDI_FNS_UKD_SUPPLIER', // "SIGN_EDI_FNS_UKD_KSCHF_DIS_SUPPLIER"
				//"SIGN_EDI_FNS_UKD_SUPPLIER", // "SIGN_EDI_FNS_UKD_KSCHF"
				//"SIGN_EDI_FNS_UKD_SUPPLIER", // "SIGN_EDI_FNS_UKD_DIS_SUPPLIER"
				'SIGN_EDI_FNS_UKD_CONSUMER', //"SIGN_EDI_FNS_UKD_KSCHF_DIS_CONSUMER"
				//"SIGN_EDI_FNS_UKD_CONSUMER", //"SIGN_EDI_FNS_UKD_DIS_CONSUMER"
				//"SIGN_EDI_FNS_UPD_CONSUMER", // "SIGN_EDI_FNS_UPD_SCHF_ROAMING_IN_CONSUMER",
				//"SIGN_EDI_FNS_UPD_CONSUMER", // "SIGN_EDI_FNS_UPD_SCHFDOP_ROAMING_IN_CONSUMER",
				'SIGN_EDI_FNS_UPD_SERVICE_AKT',
				'SIGN_EDI_FNS_UPD_SERVICE_AKT_STATUS_10_DP_IZVPOL'
			]
		}
	}
);
