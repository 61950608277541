import './scss/invitations.mass.scss';

import { createPanel, createFieldBlock, createFormContainer, createContainer } from '@UIkit/components/panels';
import { createGrid } from '@UIkit/components/grid';
import { createActionsPanel, createDetailsModulePanel } from '@Components/panels';
import {
	getMassInvitationColumns,
	send,
	updateInvitationInMassInvitation,
	getMassInvitation,
	getHeaderMassInvitation,
	getTooltip
} from './utils';
import { getErrorReportPanel } from './renderers';
import { createActionsButton, createCreateAndSendButton } from '@Components/buttons';
import { createLabel } from '@UIkit/components/fields';

/**
 * Class for new invitations.person creation
 * @author Anastasiya Zholudeva
 */
Ext.namespace('edi.modules');
edi.modules['invitations.details.mass.person'] = function () {
	let moduleData, id, moduleActionsPanel;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.massInvitation.on('change', updateMassInvitation);
	};

	const changeHandler = function (data) {
		moduleData.isChanged = false;
		edi.events.invitations.fireEvent('change');
		moduleData.initData.data = data;
		moduleData.tab.removeAll();
		renderData();
		moduleData.tab.setLoading(false);
	};

	const updateMassInvitation = function (updatedId) {
		if (!id || !updatedId || updatedId !== id) return;

		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		getMassInvitation(
			id,
			function (dataInvitation) {
				let data = dataInvitation?.data ?? {};
				if (!data) {
					failure();
					return;
				}
				const headerId = data?.massInvitation?.header ?? null;
				data.id = headerId;
				let modulePanel = createDetailsModulePanel();

				moduleData.initData.data.massInvitation = data;
				const invitationData = moduleData?.initData?.data ?? {};
				id = invitationData?.id ?? null;
				getHeaderMassInvitation(
					id,
					function (headerData) {
						moduleData.initData.data.ediDocument = headerData;
						changeHandler(moduleData.initData.data);
					},
					failure
				);
			},
			failure
		);
	};

	/**
	 * Creates despatch advise form
	 * @returns {Object}
	 */
	let createDetailsPanel = function (invitationData) {
		let massInvitationName = createFieldBlock({
			title: edi.i18n.getMessage('invitation.mass.name', null, null, null),
			grid: {
				col: 12
			},
			items: [
				createLabel({
					text: edi.utils.getObjectProperty(invitationData, 'massInvitation.nameOfInvitationList')
				})
			]
		});

		let productRole = edi.utils.getObjectProperty(invitationData, 'massInvitation.roleModel.productRole');
		let product = createFieldBlock({
			title: edi.i18n.getMessage('invitations.column.product', null, null, null),
			items: [
				createLabel({
					html: edi.renderers.getProductLogo(productRole)
				})
			]
		});

		let receiver = createFieldBlock({
			title: edi.i18n.getMessage('invitation.mass.receivers'),
			layout: 'hbox',
			items: [
				Ext.create('Ext.Img', {
					cls: 'invitations-mass-attachment-icon',
					glyph: edi.constants.ICONS.CLIP
				}),
				createLabel({
					cls: 'invitations-mass-attachment-text',
					text: edi.utils.getObjectProperty(invitationData, 'massInvitation.file.fileName') || ''
				})
			]
		});

		const senderRoleValue = edi.utils.getObjectProperty(
			invitationData,
			'massInvitation.roleModel.senderRoles',
			true
		);
		const receiverRoleValue = edi.utils.getObjectProperty(
			invitationData,
			'massInvitation.roleModel.receiverRoles',
			true
		);

		const senderRoleValueMsgs = [];
		senderRoleValue.forEach(function (item) {
			senderRoleValueMsgs.push(edi.i18n.getMessage('ediweb.invitations.' + item, null, null, null));
		});

		const receiverRoleValueMsgs = [];
		receiverRoleValue.forEach(function (item) {
			receiverRoleValueMsgs.push(edi.i18n.getMessage('ediweb.invitations.' + item, null, null, null));
		});

		let senderRole = createFieldBlock({
			title: edi.i18n.getMessage('ediweb.invitations.your.role', null, null, null),
			items: [
				createLabel({
					text: senderRoleValueMsgs.length ? senderRoleValueMsgs.join(', ') : ''
				})
			]
		});

		let receiverRole = createFieldBlock({
			title: edi.i18n.getMessage('ediweb.invitations.receiverRole.list', null, null, null),
			items: [
				createLabel({
					text: receiverRoleValueMsgs.length ? receiverRoleValueMsgs.join(', ') : ''
				})
			]
		});

		const massInvitationsList = edi.utils.getObjectProperty(invitationData, 'massInvitation.invitations');

		const state = edi.utils.getObjectProperty(invitationData, 'ediDocument.state');
		const actionColumns =
			state === edi.constants.STATE.SENT
				? [
						{
							tooltip: edi.i18n.getMessage('ediweb.columns.action.edit', null, null, null),
							glyph: edi.constants.ICONS.EDIT,
							isActionDisabled: function (view, rowIndex, colIndex, item, record) {
								return record.get('state') !== edi.constants.STATE.DRAFT;
							},
							handler: function (grid, rowIndex) {
								const record = grid.getStore().getAt(rowIndex);
								updateInvitationInMassInvitation(
									record,
									id,
									moduleData,
									failure,
									function (dataInvitation) {
										const updateInvitationData = {
											ediDocument: invitationData.ediDocument,
											id: id,
											massInvitation: dataInvitation?.data ?? {}
										};
										changeHandler(updateInvitationData);
									}
								);
							}
						}
				  ]
				: [];

		const columns = getMassInvitationColumns(actionColumns, true);
		let massInvitationsGrid = createGrid({
			proxyConfig: {
				type: 'memory',
				data: massInvitationsList
			},
			storeConfig: {
				model: edi.models.getModel('MASS_INVITATIONS_LIST'),
				autoLoad: true
			},
			viewConfig: {
				emptyText: '    ',
				getRowClass: function (record) {
					return !!record.get('errors').length ? 'invitations-mass-grid-error-sending' : '';
				}
			},
			gridConfig: {
				cls: 'invitations-mass-grid',
				columns: columns,
				padding: '0 0 0 0',
				region: 'center',
				border: 1,
				disablePaging: true,
				listeners: {
					afterrender: function (element) {
						let gridView = element.getView();
						gridView.tip = Ext.create('Ext.tip.ToolTip', {
							target: gridView.el,
							delegate: gridView.itemSelector,
							trackMouse: true,
							renderTo: Ext.getBody(),
							listeners: {
								beforeshow: function updateTipBody(tip) {
									const triggerElement = tip?.triggerElement ?? null;
									if (!triggerElement) return;
									const record = gridView?.getRecord(triggerElement) ?? null;
									if (!record) return;

									const errors = getTooltip(record);
									tip.update(errors);
								}
							}
						});
					}
				}
			}
		});

		const errorReportPanel = getErrorReportPanel(massInvitationsList, false, massInvitationsGrid);

		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const createAndSendButton =
			state === edi.constants.STATE.SENT
				? createCreateAndSendButton(
						function () {
							moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
							send(id, moduleData, failure, true, massInvitationsList.length);
						},
						{
							cls: null,
							text: edi.i18n.getMessage('form.btn.send')
						},
						true
				  )
				: null;

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 24,
			autoScroll: true,
			items: [
				createFormContainer({
					cls: 'edi-ediweb-form-maxwidth',
					gap: [24],
					area: [7, [3, 5], [3, 3], 12],
					colOffset: 1,
					items: [massInvitationName, product, receiver, senderRole, receiverRole, errorReportPanel]
				}),
				createContainer({
					items: [massInvitationsGrid]
				})
			],
			buttonAlign: 'left',
			buttons: [createAndSendButton]
		});
	};

	/**
	 * Creates action pane above the data panel
	 */

	/**
	 * Renders module
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	let renderData = function (initCallBack) {
		let invitationData = moduleData.initData.data;
		id = invitationData.id;
		let modulePanel = createDetailsModulePanel();

		modulePanel.add(createDetailsPanel(invitationData));
		moduleActionsPanel = createMassInvitationActionsPanel(invitationData);
		moduleData.tab.add(moduleActionsPanel);
		moduleData.tab.add(modulePanel);
		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};

	const createMassInvitationActionsPanel = function (invitationData) {
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		let actionsPanel = createActionsPanel({
			cls: 'edi-module-actions',
			padding: '8 24 0 0',
			bodyPadding: 0,
			layout: {
				type: 'hbox',
				align: 'stretch'
			}
		});

		const title = createLabel({
			typography: 'heading_02',
			flex: 2,
			text: edi.i18n.getMessage('ediweb.invitations.to.exchange'),
			margin: '16 24 24 24'
		});

		actionsPanel.add(title);

		const buttonsContainer = createContainer({
			layout: {
				type: 'hbox',
				pack: 'end',
				align: 'center'
			},
			flex: 1,
			items: []
		});
		actionsPanel.add(buttonsContainer);

		const state = edi.utils.getObjectProperty(invitationData, 'ediDocument.state');
		if (state === edi.constants.STATE.DRAFT) {
			const nameOfInvitationList = invitationData?.invitationName ?? '';
			buttonsContainer.add(
				createActionsButton({
					text: edi.i18n.getMessage('form.btn.send'),
					glyph: edi.constants.ICONS.SEND,
					handler: function () {
						let massInvitationCount = invitationData.massInvitation.invitations.length;
						send(id, moduleData, failure, true, massInvitationCount);
					}
				})
			);
			buttonsContainer.add(
				edi.document.actions.createEditActionButton(
					'invitations.create.mass.person',
					invitationData,
					nameOfInvitationList,
					null,
					null,
					{}
				)
			);
			buttonsContainer.add(
				createActionsButton({
					text: edi.i18n.getMessage('document.delete.document', null, null, null),
					glyph: edi.constants.ICONS.DELETE,

					handler: function () {
						edi.core.confirm('confirmation.title', 'mass.invitation.delete.question', function () {
							moduleData.tab.setLoading();
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.MASS_INVITATIONS.DELETE,
									{
										id: invitationData?.massInvitation?.header ?? null
									},
									true
								),
								'DELETE',
								Ext.encode({}),
								function () {
									edi.events.invitations.fireEvent('change');
									edi.modulesHandler.removeModule(moduleData);
									moduleData.tab.setLoading(false);
								},
								failure
							);
						});
					}
				})
			);
		}

		return actionsPanel;
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	let onDestroy = function () {
		edi.events.massInvitation.un('change', updateMassInvitation);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
