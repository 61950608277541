export const dsfModuleMethods = {
	//Тип ДСФ по умолчанию для document.create.dsf.js
	DEFAULT_DSF: edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
	DECIMAL_PRECISION: 2,

	DOCUMENTS_WITH_CUSTOM_FIELDS: [
		edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
		edi.constants.DOCUMENT_TYPES.DSF_PRICAT
	],
	hasCustomFields: function (docType) {
		return dsfModuleMethods.DOCUMENTS_WITH_CUSTOM_FIELDS.some((type) => type === docType);
	},

	DOCUMENTS_WITHOUT_REGION_VALIDATION: [
		edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
		edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
		edi.constants.DOCUMENT_TYPES.DSF_INVOICE
	],
	isWithoutRegionValidation: function (docType) {
		return dsfModuleMethods.DOCUMENTS_WITHOUT_REGION_VALIDATION.some((type) => type === docType);
	},
	isEwDocWithoutSomeActions: function (docType) {
		return (edi.constants.EW_DOCUMENT_TYPES_NO_SOME_ACTIONS || []).some((it) => it === docType);
	}
};
