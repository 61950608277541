import { createContainer } from '@UIkit/components/panels/container/Container.js';
import './ComboPanelInsideList.scss';

/**
 * Плагин добавляет контейнер в компонент Boundlist
 * @param {String} position // top | bottom
 * @param {Array} items // Массив компонентов
 * @param {Function} getItem // получаем компонент внутри массива items, по itemId, данная функция прокидывается в каждый компонент массива items
 * @param {Function} getOwner // получаем компонент плагина, данная функция прокидывается в каждый компонент массива items
 */

const PTYPE_COMBO_PANEL_INSIDE_LIST = 'combo_panel_inside_list';

Ext.define('Ext.ux.plugin.ComboPanelInsideList', {
	extend: 'Ext.AbstractPlugin',
	alias: `plugin.${PTYPE_COMBO_PANEL_INSIDE_LIST}`,
	position: 'top',
	baseCls: 'ui-plugin-combo-panel-inside-list',

	init: function (cmp) {
		let me = this;
		let picker = cmp.getPicker();

		picker.addCls(me.baseCls);

		me.items.forEach((item) => {
			item.addCls(`${me.baseCls}-child`);

			item.getItem = me.getItem.bind(me);
			item.getOwner = me.getOwner.bind(me);
		});

		picker.on('viewready', function (__self) {
			let topWrap = createContainer({
				cls: `${me.baseCls}-wrap`,
				items: me.items
			});

			topWrap.render(__self.listWrap, me.position == 'top' ? 0 : undefined);
		});
	},
	getItem: function (itemId) {
		let me = this;

		return me.items.find((item) => {
			return item.itemId == itemId;
		});
	},
	getOwner: function (itemId) {
		let me = this;
		return me;
	}
});

export { PTYPE_COMBO_PANEL_INSIDE_LIST };
