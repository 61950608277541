const EDI_SLSRPT_LINE_COLUMN = 'edi_slsrpt_lines';

edi.columns.addColumns({
	[EDI_SLSRPT_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.5
		},
		itemDescription: {
			text: 'column.item.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 2
		},
		senderItemCode: {
			text: 'line.item.item.amount.written.off',
			dataIndex: 'SenderItemCode',
			flex: 1
		},
		seturnQuantity: {
			text: 'line.item.item.return.quantity',
			dataIndex: 'ReturnQuantity',
			flex: 1
		},
		statisticalSales: {
			text: 'line.item.item.plu.code',
			dataIndex: 'StatisticalSales',
			flex: 1
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1
		}
	}
});

export { EDI_SLSRPT_LINE_COLUMN };
