import {
	columnsChangeHandler,
	createActionsColumnConfig,
	createGrid,
	createGridSettingButton,
	ROW_COLOR_CLS
} from '@UIkit/components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { createContainer } from '@Components/miscComponents';
import { createLabel } from '@Components/fields';

import '@UIkit/components/treePanel';
import './ProductTreeGrid.scss';
import { createPanel } from '@UIkit/components/panels';

Ext.define('UI.components.ProductTreeGrid', {
	extend: 'UI.components.TreePanel',

	title: 'TreeGrid Title',
	useArrows: true,
	rootVisible: false,
	multiSelect: false,
	singleExpand: false,
	disableSelection: true,
	tools: [],
	wrapperCls: 'ui-product-tree-grid-container',
	cls: 'ui-product-tree-grid edi-product-grid-container',
	gridModel: 'SIMPLE',
	lineModel: 'SIMPLE',
	gridColumnConfig: 'simple',
	totalModel: 'SIMPLE',
	totalColumnConfig: 'simple',
	totalsGridHeaderLabel: 'TreeGrid Totals',
	totalData: {},
	totalsGridConfig: {
		margin: '24 0 4 0' //отступ внизу нужен что бы видно было тень
	},
	palletList: [],
	editableFields: ['Count', 'Cost', 'NDSCost', 'CostWithoutNDS', 'CostWithNDS'],
	editableDifferenceFields: ['NDSCost', 'CostWithoutNDS', 'CostWithNDS'],

	readOnly: false,
	allowBlank: false,
	ownCatalog: true,
	lineNumberFieldName: 'LineNumber',
	valuesCalculator: null,
	productDetailsHandler: null,

	/**
	 * Calculates values for totals grid
	 */
	totalsHandler: function () {
		var NDSIncrease = 0,
			NDSDecrease = 0,
			CostWithoutNDSIncrease = 0,
			CostWithoutNDSDecrease = 0,
			CostWithNDSIncrease = 0,
			CostWithNDSDecrease = 0,
			totalStore = this.productsTotalGrid.getStore();

		var totalIncreaseRecord = totalStore ? totalStore.getAt(0) : null;
		var totalDecreaseRecord = totalStore ? totalStore.getAt(1) : null;
		var rootNode = this.productsGrid.getStore().getRoot();
		rootNode.eachChild(function (node) {
			node.eachChild(function (child) {
				var increaseRow = child.get('State') === 'Increase' ? child : null,
					decreaseRow = child.get('State') === 'Decrease' ? child : null;
				if (increaseRow || decreaseRow) {
					NDSIncrease += increaseRow ? parseFloat(increaseRow.get('NDSCost')) : 0;
					NDSDecrease += decreaseRow ? parseFloat(decreaseRow.get('NDSCost')) : 0;
					CostWithoutNDSIncrease += increaseRow ? parseFloat(increaseRow.get('CostWithoutNDS')) : 0;
					CostWithoutNDSDecrease += decreaseRow ? parseFloat(decreaseRow.get('CostWithoutNDS')) : 0;
					CostWithNDSIncrease += increaseRow ? parseFloat(increaseRow.get('CostWithNDS')) : 0;
					CostWithNDSDecrease += decreaseRow ? parseFloat(decreaseRow.get('CostWithNDS')) : 0;
				}
			});
		});

		if (totalIncreaseRecord) {
			totalIncreaseRecord.beginEdit();
			totalIncreaseRecord.set('TotalWithoutNDS', edi.utils.roundTo(CostWithoutNDSIncrease, 2) || 0);
			totalIncreaseRecord.set('MustPaid', edi.utils.roundTo(NDSIncrease, 2) || 0);
			totalIncreaseRecord.set('TotalWithNDS', edi.utils.roundTo(CostWithNDSIncrease, 2) || 0);
			totalIncreaseRecord.endEdit();
			totalIncreaseRecord.commit();
		}
		if (totalDecreaseRecord) {
			totalDecreaseRecord.beginEdit();
			totalDecreaseRecord.set('TotalWithoutNDS', edi.utils.roundTo(CostWithoutNDSDecrease, 2) || 0);
			totalDecreaseRecord.set('MustPaid', edi.utils.roundTo(NDSDecrease, 2) || 0);
			totalDecreaseRecord.set('TotalWithNDS', edi.utils.roundTo(CostWithNDSDecrease, 2) || 0);
			totalDecreaseRecord.endEdit();
			totalDecreaseRecord.commit();
		}
	},

	/**
	 * Validate line in pallet
	 * @param line
	 * @return {Boolean}        is valid
	 */
	isValidRecord: function (line) {
		return line.childNodes.length > 1;
	},

	hasChangedProducts: function () {
		return !!this.getChangedProducts().products.length;
	},

	/**
	 * On values change callback
	 * @param values
	 */
	callback: function (values) {
		return values; // to be overwritten
	},

	/**
	 * Get lines amount
	 * @returns {number}
	 */
	getLinesAmount: function () {
		var __self = this;
		var store = __self.getStore();
		var rootNode = store.getRoot();
		var palletsAmount = rootNode.childNodes.length;
		var linesAmount = 0;
		for (var i = 0; i < palletsAmount; i++) {
			var pallet = rootNode.childNodes[i];
			linesAmount += pallet.childNodes.length;
		}
		return linesAmount;
	},

	isProductChanged: function (product) {
		var __self = this,
			i,
			changed = false;

		for (i = 0; i < __self.editableFields.length && !changed; i++) {
			var field = __self.editableFields[i];
			changed = product.hasOwnProperty('OriginalData') ? product.OriginalData[field] != product[field] : changed;
		}

		//проверяем коды на наличие изменений
		let before = product.identifyProductToolNumbersBefore || [];
		let after = product.identifyProductToolNumbersAfter || [];
		if (!changed) {
			changed = before.length !== after.length;
		}
		//если по количеству строк все одинаково, то проверим не поменялись ли коды внутри строк
		//будем искать строку до изменений, для которой не нашлось строки с такими же кодами в до изменений
		if (!changed && after.length) {
			changed = !!after.find((a) => {
				return !before.find((b) => String(b.numbers) === String(a.numbers));
			});
		}

		return changed;
	},

	/**
	 * Get values
	 */
	getValues: function (onlyChanged) {
		var __self = this,
			store = __self.getStore(),
			lines = [],
			rootNode = store.getRoot(),
			totalStore = this.productsTotalGrid.getStore();
		var values = {
			TotalIncrease: totalStore.count() ? totalStore.getAt(0).getData() : {},
			TotalDecrease: totalStore.count() > 1 ? totalStore.getAt(1).getData() : {}
		};

		if (__self.rendered) {
			for (var i = 0; i < rootNode.childNodes.length; i++) {
				var pallet = rootNode.childNodes[i];
				var palletData = edi.models.createInstance(__self.gridModel, pallet.data).getData();
				for (var j = 0; j < pallet.childNodes.length; j++) {
					var line = pallet.childNodes[j];
					var lineData = edi.models.createInstance(__self.lineModel, line.data).getData();
					palletData[lineData.State] = lineData;
				}

				if (!onlyChanged || __self.isProductChanged(palletData)) {
					palletData = Ext.merge(palletData.NewData, palletData);
					lines.push(palletData);
				}
			}
		}

		values.products = lines;
		return values;
	},

	getChangedProducts: function () {
		return this.getValues(true);
	},

	/**
	 * Remove all from root node
	 */
	clearRoot: function () {
		var __self = this;
		var store = __self.getStore();
		var rootNode = store.getRoot();
		rootNode.removeAll();
	},

	/**
	 * Update line
	 * @param     record
	 * @param     values
	 * @param     skipTotals
	 */
	updateLine: function (record, values, skipTotals) {
		var __self = this;
		record.beginEdit();
		for (var i in values) {
			if (values.hasOwnProperty(i)) {
				record.set(i, values[i]);
			}
		}
		record.endEdit();
		record.commit();
		!skipTotals ? this.totalsHandler(__self) : null;
	},

	/**
	 * Create line in pallet
	 * @param pallet
	 * @param lineValues
	 * @param skipTotals
	 * @returns {Object}
	 */
	addLine: function (pallet, lineValues, skipTotals) {
		var __self = this,
			line;
		line = pallet.appendChild({});
		lineValues.leaf = true;
		__self.updateLine(line, lineValues, true);
		line.commit();

		__self.getStore().sort();
		!skipTotals ? this.totalsHandler(__self) : null;
		return line;
	},

	/**
	 * Create and add new pallet
	 * @param    product
	 * @param    callback
	 */
	createPallet: function (product, callback) {
		var __self = this;
		__self.setLoading();
		var record = __self.addPallet(product, true);
		callback ? callback(record) : null;
	},

	/**
	 * Add pallet
	 * @param     productData     Product values
	 * @param     skipTotals
	 * @returns    {Object}
	 */
	addPallet: function (productData, skipTotals) {
		var __self = this,
			store = __self.getStore();
		var rootNode = store.getRoot();
		var rootRecord = rootNode.appendChild({});
		__self.rootRecord = rootRecord;
		productData.NewData = Ext.merge(productData.NewData, productData);
		__self.updateLine(rootRecord, productData.NewData, true);

		productData.OriginalData.lineNumber = '';
		productData.OriginalData.lineNumInvoice = '';

		__self.addLine(rootRecord, productData.OriginalData, true);
		__self.isAddIncrease = productData.Increase.NDSCost !== 0;
		__self.isAddDecrease = productData.Decrease.NDSCost !== 0;

		if (__self.isAddIncrease) {
			__self.addLine(rootRecord, productData.Increase, true);
		}
		if (__self.isAddDecrease) {
			__self.addLine(rootRecord, productData.Decrease, true);
		}

		store.sort();
		!skipTotals ? this.totalsHandler(__self) : null;
		return rootRecord;
	},

	/**
	 * Check is data valid
	 * @return {Boolean}        is valid
	 */
	isValid: function () {
		var __self = this,
			hasInvalidLine = false,
			store = __self.getStore();
		var rootNode = store.getRoot();
		for (var i = 0; i < rootNode.childNodes.length; i++) {
			var pallet = rootNode.childNodes[i];
			for (var j = 0; j < pallet.childNodes.length; j++) {
				var line = pallet.childNodes[j];
				if (!__self.isValidRecord(line)) {
					hasInvalidLine = true;
					break;
				}
			}
			if (hasInvalidLine) {
				break;
			}
		}
		return !hasInvalidLine;
	},

	/**
	 * Update increase and decrease rows
	 * @param     increaseRecord     Increase store record
	 * @param     decreaseRecord     Decrease store record
	 * @param     newData            Original data
	 * @param     originalData          New data
	 */
	calculateDifference: function (rootNode, prevNode, newData, originalData) {
		var __self = this,
			calculatedFields = ['Count', 'Cost', 'CostWithoutNDS', 'CostWithNDS'],
			diffNDS,
			excBefore,
			excAfter,
			diffExc;
		var increaseNode = null,
			decreaseNode = null;

		rootNode.eachChild(function (child) {
			if (child.get('State') === 'Increase') {
				increaseNode = child;
				__self.isAddIncrease = true;
			}
			if (child.get('State') === 'Decrease') {
				decreaseNode = child;
				__self.isAddDecrease = true;
			}
		});

		if (!increaseNode) {
			increaseNode = prevNode.copy();
			increaseNode.set('State', 'Increase');
		}
		if (!decreaseNode) {
			decreaseNode = prevNode.copy();
			decreaseNode.set('State', 'Decrease');
		}

		var checkIsRecordChanged = function (diff) {
			if (!__self.isAddIncrease) {
				__self.isAddIncrease = diff > 0;
			}
			if (!__self.isAddDecrease) {
				__self.isAddDecrease = diff < 0;
			}
		};

		var removeChildNode = function (node) {
			var isAllZeroValues = true;
			for (var i = 0; i < calculatedFields.length; i++) {
				var field = calculatedFields[i];
				if (node.get(field) != 0) {
					isAllZeroValues = false;
					break;
				}
			}
			return isAllZeroValues;
		};

		increaseNode.beginEdit();
		decreaseNode.beginEdit();

		for (var i = 0; i < calculatedFields.length; i++) {
			var field = calculatedFields[i];
			var diff = newData[field] - originalData[field];
			checkIsRecordChanged(diff);
			increaseNode.set(field, diff > 0 ? edi.utils.roundTo(diff, 2) : 0);
			decreaseNode.set(field, diff < 0 ? Math.abs(edi.utils.roundTo(diff, 2)) : 0);
		}

		diffNDS = originalData.NDSCost == 0 ? newData.NDSCost : newData.NDSCost - originalData.NDSCost;
		checkIsRecordChanged(diffNDS);

		increaseNode.set('NDSCost', diffNDS > 0 ? edi.utils.roundTo(diffNDS, 2) : 0);

		decreaseNode.set('NDSCost', diffNDS < 0 ? Math.abs(edi.utils.roundTo(diffNDS, 2)) : 0);

		excAfter = newData.ExciseCost == 0 || newData.ExciseCost === 'без акциза' ? 0 : newData.ExciseCost;
		excBefore =
			originalData.ExciseCost == 0 || originalData.ExciseCost === 'без акциза' ? 0 : originalData.ExciseCost;
		diffExc = excAfter - excBefore;
		checkIsRecordChanged(diffExc);

		increaseNode.set('ExciseCost', diffExc > 0 ? edi.utils.roundTo(diffExc, 2) : 0);
		decreaseNode.set('ExciseCost', diffExc < 0 ? Math.abs(edi.utils.roundTo(diffExc, 2)) : 0);

		increaseNode.endEdit();
		decreaseNode.endEdit();
		increaseNode.commit();
		decreaseNode.commit();

		if (__self.isAddIncrease) {
			if (removeChildNode(increaseNode)) {
				rootNode.removeChild(increaseNode);
				var increaseData = increaseNode.data;
				rootNode.set('Increase', increaseData);
			} else {
				rootNode.appendChild(increaseNode);
			}
		}
		if (__self.isAddDecrease) {
			if (removeChildNode(decreaseNode)) {
				rootNode.removeChild(decreaseNode);
				var decreaseData = decreaseNode.data;
				rootNode.set('Decrease', decreaseData);
			} else {
				rootNode.appendChild(decreaseNode);
			}
		}

		this.totalsHandler(__self);
	},

	/**
	 * Init component
	 */
	initComponent: function () {
		var __self = this,
			changeTimeout,
			columns = Ext.isArray(__self.gridColumnConfig)
				? __self.gridColumnConfig
				: edi.columns.get(__self.gridColumnConfig),
			totalIncreaseData = __self.totalData.hasOwnProperty('Increase')
				? __self.totalData.Increase
				: { State: 'Increase' },
			totalDecreaseData = __self.totalData.hasOwnProperty('Decrease')
				? __self.totalData.Decrease
				: { State: 'Decrease' };

		columns.push(
			createActionsColumnConfig({
				items: [
					{
						glyph: __self.readOnly ? edi.constants.ICONS.DETAILS : edi.constants.ICONS.EDIT,
						testCls: 'test-action-column-details',
						handler: function (grid, rowIndex, colindex, actionItem, event, record) {
							'function' === typeof __self.productDetailsHandler
								? __self.productDetailsHandler(record)
								: null;
						},
						isHidden: function (view, rowIndex, colIndex, item, record) {
							return record.get('State') !== 'NewData';
						}
					}
				]
			})
		);

		this.viewConfig = {
			getRowClass: function (record) {
				var res = ROW_COLOR_CLS.valid;

				var setTextBbar = function () {
					if (__self.hasOwnProperty('dockedItems')) {
						Ext.Array.forEach(__self.dockedItems.items, function (item) {
							var bbarLabel = item.getComponent('tree-grid-bottom-toolbar');
							if (bbarLabel) {
								bbarLabel.setText(
									__self.isValidRecord(record) || __self.readOnly
										? ''
										: edi.i18n.getMessage('documents.fns_ukd.change.grid.warn')
								);
							}
						});
					}
				};

				if (record.get('State') === 'NewData' && !__self.readOnly) {
					setTextBbar();
					if (record.isExpanded()) {
						res = ROW_COLOR_CLS.valid;
					}
					if (!__self.isValidRecord(record) && record.isExpanded()) {
						res = ROW_COLOR_CLS.error;
					}
					if (!__self.isValidRecord(record) && !record.isExpanded()) {
						res = ROW_COLOR_CLS.error;
					}
					if (__self.isValidRecord(record) && record.isExpanded()) {
						res = 'without-error';
					}
					if (__self.isValidRecord(record) && !record.isExpanded()) {
						res = ROW_COLOR_CLS.valid;
					}
					if (__self.isValidRecord(record) && record.isExpanded()) {
						res = record.childNodes.length === 2 ? 'without-error editing-record' : 'not-valid-record';
					}
				} else {
					res += ' child-node';
				}
				res += !__self.readOnly ? ' is-edit-editable-tree-grid' : ' read-only-editable-tree-grid';

				return res;
			}
		};

		Ext.apply(this, {
			store: new Ext.data.TreeStore({
				model: edi.models.getModel(this.gridModel),
				proxy: createProxyConfig({
					type: 'pagingmemory',
					data: {
						items: []
					}
				}),
				folderSort: true
			}),
			layout: 'auto',
			columns: columns,
			cls: 'x-grid-with-row-lines is-edit edi-grid-products',
			plugins: __self.readOnly
				? null
				: [
						Ext.create('Ext.grid.plugin.CellEditing', {
							clicksToEdit: 1,
							listeners: {
								beforeedit: function (editor, targetCell) {
									var record = targetCell.record,
										rowState = record.get('State');
									if (!rowState || rowState === 'OriginalData' || !record.isExpanded()) {
										return false;
									} else if (rowState === 'NewData') {
										return true;
									} else if (rowState === 'Increase' || rowState === 'Decrease') {
										var editableFields = __self.editableFields;
										var isEdit = false;
										for (var i = 0; i < editableFields.length; i++) {
											if (record.data[editableFields[i]] != 0) {
												isEdit = true;
											}
										}
										if (isEdit) {
											return __self.editableDifferenceFields.indexOf(targetCell.field) > -1;
										} else {
											return false;
										}
									}
								},
								edit: function (editor, e) {
									changeTimeout ? clearTimeout(changeTimeout) : null;
									changeTimeout = setTimeout(function () {
										var isUpdate,
											record = e.record,
											newValues = edi.models
												.createInstance(__self.lineModel, record.data)
												.getData(),
											changedRecord = record ? record.firstChild : null,
											prevValues = changedRecord
												? edi.models
														.createInstance(__self.lineModel, changedRecord.data)
														.getData()
												: undefined;
										var prevNode = record ? record.firstChild : null;

										//recalculate values only if we changed main fields, but not costs
										if ('function' == typeof __self.afterRowEdit) {
											__self.afterRowEdit(newValues, e.field);
											isUpdate = true;
										} else if (!Ext.Array.contains(__self.editableDifferenceFields, e.field)) {
											edi.methods.product.grid.setValuesCorrectedFnsInvoice(newValues);
											isUpdate = true;
										}

										if (isUpdate) {
											__self.updateLine(record, newValues, true);
											if (prevNode && prevValues) {
												__self.calculateDifference(record, prevNode, newValues, prevValues);
											}
										}

										'function' == typeof __self.callback
											? __self.callback(__self.getValues())
											: null;
									}, 500);
								}
							}
						})
				  ],

			bbar: {
				height: 41,
				border: 0,
				items: [
					createLabel({
						text: __self.readOnly ? '' : edi.i18n.getMessage('documents.fns_ukd.change.grid.warn'),
						itemId: 'tree-grid-bottom-toolbar',
						cls: 'error-description'
					})
				]
			},
			listeners: {
				afterrender: function (element) {
					let gridView = element.getView();

					gridView.tip = Ext.create('Ext.tip.ToolTip', {
						target: gridView.el,
						delegate: gridView.itemSelector,
						trackMouse: true,
						renderTo: Ext.getBody(),
						listeners: {
							beforeshow: function updateTipBody(tip) {
								const triggerElement = tip?.triggerElement ?? null;
								if (!triggerElement) return;
								const record = gridView?.getRecord(triggerElement) ?? null;
								if (!record) return;

								if (
									!__self.isValidRecord(record) ||
									(!__self.isProductChanged(record.data) && record.get('State') === 'NewData')
								) {
									tip.update(edi.i18n.getMessage('documents.fns_ukd.change.grid.tooltip'));
								} else {
									return false;
								}
							}
						}
					});
				}
			}
		});

		var columnsTotalConfig = edi.columns.get(__self.totalColumnConfig);
		var totalsGridConfig = Ext.merge(
			{
				columnWidth: 0.5,
				cls: !__self.readOnly ? 'edi-total-grid' : 'edi-total-grid read-only',
				columns: columnsTotalConfig,
				listeners: {},
				viewConfig: {
					emptyText: ''
				},
				disablePaging: true,
				disableSelection: true,
				enableColumnMove: false,
				enableColumnResize: false,
				sortableColumns: false,
				plugins: __self.readOnly
					? null
					: [
							Ext.create('Ext.grid.plugin.CellEditing', {
								clicksToEdit: 1,
								listeners: {
									edit: function (editor, e) {
										e.record.commit();
									}
								}
							})
					  ]
			},
			__self.totalsGridConfig
		);
		if (__self.totalsGridHeaderLabel) {
			totalsGridConfig.title = edi.i18n.getMessage(__self.totalsGridHeaderLabel);
		}

		var totalData = [
			edi.models.createInstance('FNS_UKD_SUMMARY', totalIncreaseData),
			edi.models.createInstance('FNS_UKD_SUMMARY', totalDecreaseData)
		];

		this.productsTotalGrid = createGrid({
			storeConfig: {
				proxy: createProxyConfig({
					type: 'memory',
					data: {
						items: totalData
					}
				}),
				model: edi.models.getModel(__self.totalModel),
				remoteSort: false
			},
			gridConfig: totalsGridConfig
		});
		// var me = this;
		// this.productsTotalGrid.getView().on('refresh', function () {
		// 	var row = me.productsTotalGrid.getView().getNode(0);
		// 	var height = 0;
		// 	if (row) {
		// 		height = Ext.get(row).getHeight();
		// 	}
		// 	else {
		// 		height = me.productsTotalGrid.getView().ownerCt.headerCt.el.dom.offsetHeight;
		// 	}
		// 	height = height + 30
		// 	me.productsTotalGrid.setHeight((me.productsTotalGrid.getStore().getCount() + 1) * height);
		// });
		this.productsGrid = this;

		if (this.pagingBarConfig) {
			this.productsGrid.bbar = new Ext.toolbar.Paging(this.pagingBarConfig);
		}

		this.wrapper = createContainer({
			cls: __self.wrapperCls,
			items: [
				this.productsGrid,
				createPanel({
					layout: 'column',
					margin: '10 0 5',
					items: [
						{
							xtype: 'component',
							columnWidth: 0.5,
							html: '<br/>'
						},
						this.productsTotalGrid
					]
				})
			]
		});

		this.on('afterrender', createGridSettingButton);

		this.on('render', columnsChangeHandler);

		this.callSuper(); //если вызвать callParent то сработает метод класса который мы оверрайдим, а так мы вызываем метод перед ним
	}
});

/**
 * Creates editable products tree grid
 * @param	{Object}	cfg		config for new product.EditableTreeGrid
 * @return	{Object}	UI.components.ProductTreeGrid instance
 */
const createEditableProductsTreeGrid = function (cfg) {
	return Ext.create('UI.components.ProductTreeGrid', cfg);
};

export { createEditableProductsTreeGrid };
