import { createProxyConfig, createSimpleStore, createStore } from '@Components/storeComponents';
import { confirm } from '@Ediweb/components/dialogs.js';
import { showErrorToast } from '@Ediweb/core';
import { createModalPanel, MODAL_CLS, MODAL_SIZE } from '@UIkit/components/modal';
import {
	createLabel,
	createTextField,
	createCombo,
	createCheckbox,
	createRadio,
	createHiddenField,
	createDetailLabel
} from '@UIkit/components/fields';
import {
	createFieldBlock,
	createForm,
	createPanel,
	createContainer,
	createFormContainer,
	createModalForm,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createMenuItem } from '@Components/miscComponents';
import { createRowsBlock } from '@Components/createRows/create.rows';
import { createComboTree } from '@UIkit/components/fields/Combobox/ComboboxTree';
import { createRowWithNestedRows } from '@Components/RowWithNestedRows/RowWithNestedRows';
import { createOrgAutocomplete } from '@App/js/ediweb/components/OrgAutocomplete/OrgAutocomplete';
import { createExceptionBlock } from '@UIkit/components/blocks/ExceptionBlock';

const createComboActionType = function (config) {
	const arr = [];
	if (edi.constants.ORG_PROFILE.EMPLOYEES.actionsTree) {
		let typesTree = edi.constants.ORG_PROFILE.EMPLOYEES.actionsTree;

		Object.entries(typesTree).forEach(function ([k, v]) {
			let item = {
				id: k,
				name: edi.i18n.getMessage(`ediweb.employees.type.category.${k}`)
			};

			if (v?.items && v.items.length) {
				let itemsArr = [];
				for (let i of v.items) {
					itemsArr.push({
						id: i,
						name: edi.i18n.getMessage(`ediweb.employees.type.${i}`)
					});
				}
				item.items = itemsArr;
			}

			arr.push(item);
		});
	}

	const store = edi.stores.createInlineStore(arr, 'SIMPLE', undefined, {
		addEmptyRecord: false
	});

	var defaults = {
		width: '100%',
		store,
		setLastValueAfterTextClear: true
	};
	Object.assign(config, defaults);

	return createComboTree(config);
};

var methods = {
	subdivisions: {
		createOrUpdate: function (objectData) {
			let formPanel;
			let subdivisionsArray = [];
			let isEdit = !!objectData;

			let addModalContent = function () {
				var name = createFieldBlock({
					title: edi.i18n.getMessage('org.profile.subdivision.name'),
					items: [
						createTextField({
							name: 'name',
							maxLength: 255,
							allowBlank: false,
							value: objectData ? objectData.get('name') : null
						})
					]
				});
				var store = createStore({
					proxy: createProxyConfig({
						type: 'memory',
						data: subdivisionsArray
					}),
					model: edi.models.getModel('EW_SUBDIVISIONS')
				});
				if (objectData) {
					store.filter({
						filterFn: function (item) {
							return item.get('id') !== objectData.get('id');
						}
					});
				}

				var headdivision = createFieldBlock({
					title: edi.i18n.getMessage('org.profile.subdivision.headdivision'),
					items: [
						createCombo({
							name: 'headdivision',
							value: objectData && objectData.parentNode ? objectData.parentNode.get('id') : null,
							store: store,
							queryMode: 'local',
							displayField: 'name',
							valueField: 'id',
							forceSelection: true,
							anyMatch: true,
							listeners: {
								change(comp, newValue) {
									if (comp.allowBlank && !newValue) {
										comp.setValue(null);
									}
								}
							}
						})
					]
				});
				formPanel = createModalForm({
					layout: {
						type: 'grid',
						gap: [24]
					},
					submitEmptyText: false,
					items: [name, headdivision]
				});

				modal.add(formPanel);
				modal.setLoading(false);
			};

			var modal = createModalPanel({
				title: edi.i18n.getMessage(isEdit ? 'subdivisions.edit' : 'subdivisions.new'),
				items: [],
				buttons: [
					createButton({
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.add'),
						glyph: isEdit ? null : edi.constants.ICONS.PLUS,
						handler: function () {
							if (!edi.utils.setFocusToDocumentsWithGrid(formPanel)) return;

							if (formPanel.isValid()) {
								modal.setLoading();
								var values = edi.utils.collectFormValues(formPanel);
								if (values.headdivision.length < 1) {
									values.headdivision = null;
								}

								var url = objectData
									? edi.utils.formatString(
											edi.rest.services.ORG_PROFILE.SUBDIVISIONS.PUT,
											{
												id: objectData.get('id')
											},
											false
									  )
									: edi.rest.services.ORG_PROFILE.SUBDIVISIONS.POST;
								var success = function () {
									modal.close();
									edi.events.subdivisions.fireEvent('change');
								};
								var failure = function (data) {
									edi.core.showError(
										edi.utils.formatComplexServerError(data, 'error.server'),
										function () {
											modal.setLoading(false);
										}
									);
								};
								edi.rest.sendRequest(
									url,
									objectData ? 'PUT' : 'POST',
									Ext.encode(values),
									success,
									failure,
									function () {
										modal.setLoading(false);
									}
								);
							}
						}
					}),
					createButton({
						text: edi.i18n.getMessage('ediweb.cancel.btn'),
						cls: [BUTTON_CLS.secondary],
						handler: function () {
							if (formPanel.isDirty()) {
								confirm(
									isEdit ? 'cancel.edit.subdivisions.title' : 'cancel.create.subdivisions.title',
									isEdit ? 'cancel.edit.subdivisions' : 'cancel.create.subdivisions',
									() => modal.close()
								);
							} else {
								modal.close();
							}
						}
					})
				]
			});

			modal.show();
			modal.setLoading(true);

			getSubdivisionsArray()
				.then((arr) => {
					subdivisionsArray = arr;
					addModalContent();
					modal.center();
				})
				.catch(edi.rest.getErrorHandler('error.etting.data', () => modal.close()));
		},
		delete: function (ids, moduleData, callback) {
			ids.forEach(function (id) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.ORG_PROFILE.SUBDIVISIONS.DELETE, {
						id: id
					}),
					'DELETE',
					Ext.encode({}),
					function () {
						moduleData.tab.setLoading(false);
						edi.events.subdivisions.fireEvent('change');
						if (typeof callback === 'function') {
							callback();
						}
					},
					function (data) {
						var additionalData = getValueByTypeError(data);
						var toastTitle = edi.i18n.getMessage('controller.subdivisions.error.title');
						let toastText = edi.i18n.getMessage(data.typeError, [additionalData]);

						showErrorToast(
							toastTitle,
							toastText,
							{
								showCloseBtn: true
							},
							function () {
								moduleData.tab.setLoading(false);
							}
						);
					}
				);
			});
		}
	},
	employees: {
		createOrUpdate: function (objectData, options) {
			let exceptionError, accessToProducts, DCForm, EWForm, menu;
			let subdivisionsArray = [];

			var isEdit = !!objectData,
				statusValue,
				btnText,
				btnIcon = null,
				btnUrl,
				formPanel,
				isShowBtnSubtitle = false,
				exception,
				isHasPermission;
			var actionStatuses = [
				edi.constants.ORG_PROFILE.EMPLOYEES.STATE.CONFIRMED,
				edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DEACTIVATED,
				edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DELETED
			];

			let isEmployeeMD = !!objectData && !!objectData.get('user');
			let user = objectData ? edi.renderers.getUserInfo(objectData) : null;

			var failure = function (data) {
				modal.setLoading(false);

				let textErr;
				if (data.typeError === 'employee.already.exist.with.this.email') {
					textErr = edi.i18n.getMessage('employee.already.exist.with.this.email', [data.additionalData[0]]);
				}
				if (data.typeError === 'user.already.exist.not.linked.with.md') {
					textErr = edi.i18n.getMessage('user.already.exist.not.linked.with.md');
				}
				if (data.typeError === 'controller.employee.has.not.user.error') {
					textErr = edi.i18n.getMessage('controller.employee.has.not.user.error');
				}
				if (data.typeError === 'org.already.exist.not.linked.with.md') {
					textErr = edi.i18n.getMessage('org.already.exist.not.linked.with.md');
				}

				if (data.typeError === 'controller.employee.modify.error.route.has.related.approvals') {
					data.additionalData = [`${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`];
				}

				if (!!textErr) {
					exceptionError.updateData({
						exceptionText: textErr
					});
					exception.setVisible(true);
				} else {
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
				}
			};

			let addModalContent = function () {
				var isHasActiveStatus = objectData
					? actionStatuses.some((it) => it === objectData.get('status'))
					: false;

				if (isHasActiveStatus) {
					let { state, statusKey } = edi.renderers.employeesStatus(objectData.getData());
					statusValue = edi.renderers.baseStateRenderer(state, edi.i18n.getMessage(statusKey));

					switch (objectData.get('status')) {
						case edi.constants.ORG_PROFILE.EMPLOYEES.STATE.CONFIRMED:
							btnText = edi.i18n.getMessage('ediweb.employees.bnt.lock');
							btnIcon = edi.constants.ICONS.LOCK;
							btnUrl = edi.rest.services.ORG_PROFILE.EMPLOYEES.DEACTIVATE.PUT;
							isHasPermission = edi.permissions.hasPermission('CLIENT_EMPLOYEES_DEACTIVATE');
							isShowBtnSubtitle = true;
							break;
						case edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DEACTIVATED:
							btnText = edi.i18n.getMessage('ediweb.employees.bnt.unlock');
							btnIcon = edi.constants.ICONS.UNLOCK;
							isHasPermission = edi.permissions.hasPermission('CLIENT_EMPLOYEES_ACTIVATE');
							btnUrl = edi.rest.services.ORG_PROFILE.EMPLOYEES.ACTIVATE.PUT;
							isShowBtnSubtitle = true;
							break;
						case edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DELETED:
							btnText = edi.i18n.getMessage('ediweb.employees.bnt.reestablish');
							isHasPermission = edi.permissions.hasPermission('CLIENT_EMPLOYEES_ACTIVATE');
							btnUrl = edi.rest.services.ORG_PROFILE.EMPLOYEES.ACTIVATE.PUT;
							break;
					}
				}

				exceptionError = createExceptionBlock({
					exceptionText: 'sss'
				});
				exception = createFieldBlock({
					hidden: true,
					items: [exceptionError]
				});

				var email = createFieldBlock({
					title: edi.i18n.getMessage('user.profile.email'),
					items: [
						createTextField({
							name: 'email',
							invalidText: edi.i18n.getMessage('invalid.email.format'),
							regex: edi.constants.VALIDATORS.EMAIL,
							allowBlank: false,
							value: user ? user.email : null,
							readOnly: isEmployeeMD,
							listeners: {
								change: function () {
									if (exception) {
										exception.setVisible(false);
									}
								}
							}
						})
					]
				});
				var lastName = createFieldBlock({
					title: edi.i18n.getMessage('user.profile.last.name'),
					items: [
						createTextField({
							name: 'lastName',
							allowBlank: false,
							value: user ? user.lastName : null,
							readOnly: isEmployeeMD
						})
					]
				});
				var firstName = createFieldBlock({
					title: edi.i18n.getMessage('user.profile.first.name'),
					items: [
						createTextField({
							name: 'firstName',
							allowBlank: false,
							value: user ? user.firstName : null,
							readOnly: isEmployeeMD
						})
					]
				});
				var middleName = createFieldBlock({
					title: edi.i18n.getMessage('user.profile.patronymic.name'),
					items: [
						createTextField({
							name: 'middleName',
							value: user ? user.middleName : null,
							readOnly: isEmployeeMD
						})
					]
				});

				var position = createFieldBlock({
					title: edi.i18n.getMessage('job.title'),
					items: [
						createTextField({
							name: 'position',
							allowBlank: false,
							value: objectData ? objectData.get('position') : null
						})
					]
				});

				var headdivision = createFieldBlock({
					title: edi.i18n.getMessage('org.profile.subdivision'),
					items: [
						createCombo({
							name: 'subdivision',
							value:
								objectData && objectData.get('subdivision') ? objectData.get('subdivision').id : null,
							store: createStore({
								proxy: createProxyConfig({
									type: 'memory',
									data: subdivisionsArray
								}),
								autoLoad: true,
								model: edi.models.getModel('EW_SUBDIVISIONS')
							}),
							forceSelection: true,
							queryMode: 'local',
							displayField: 'name',
							valueField: 'id',
							allowBlank: false,
							anyMatch: true,
							listeners: {
								afterrender(combo) {
									var value =
										objectData && objectData.get('subdivision')
											? objectData.get('subdivision').id
											: null;
									combo.setValue(value);
									combo.isValid();
								}
							}
						})
					]
				});

				var statusField = createLabel({
					cls: 'edi-ediweb-employees-status',
					html: '<span>' + edi.i18n.getMessage('document.status') + ' — </span>' + statusValue
				});

				var changeStatusBtn = createFieldBlock({
					items: [
						createButton({
							cls: [BUTTON_CLS.outline, BUTTON_CLS.small],
							text: btnText,
							glyph: btnIcon,
							handler: function () {
								var success = function () {
									edi.events.employees.fireEvent('change');
									modal.destroy();
								};
								edi.rest.sendRequest(
									edi.utils.formatString(btnUrl, {
										id: objectData.get('id')
									}),
									'PUT',
									Ext.encode({}),
									success,
									failure
								);
							}
						})
					]
				});

				var btnSubtitle = createFieldBlock({
					margin: '-10 0 0 0',
					items: [
						createLabel({
							typography: 'caption_01',
							color: '--color-grey-50',
							text: edi.i18n.getMessage('ediweb.employees.btn.subtitle')
						})
					]
				});

				let createEWFields = function (dataValues) {
					let productHidden = createHiddenField({
						name: 'productType',
						value: 'ediweb'
					});

					let actionsValues = null;
					if (dataValues.actions) {
						actionsValues = Array.isArray(dataValues?.actions)
							? dataValues?.actions
							: dataValues?.actions.split(',');
					}

					let actions = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('ediweb.employees.actions'),
						items: [
							createComboActionType({
								name: 'actions',
								value: actionsValues,
								allowBlank: false,
								listeners: {
									select: function (comp, records) {
										let values = records.map((rec) => rec.getId());
										const store = comp.getStore();
										const actionLength = Object.keys(
											edi.constants.ORG_PROFILE.EMPLOYEES.ACTION
										).length;

										if (actionLength === values.length) {
											comp.allSelected = true;
										}

										if (
											values.includes(
												edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.USER_ADMINISTRATION
											)
										) {
											if (!comp.allSelected) {
												comp.select(store.getRange());
												comp.allSelected = true;
											}
										} else if (actionLength - 1 === values.length) {
											if (!comp.allSelected) {
												comp.select(store.getRange());
												comp.allSelected = true;
											} else {
												comp.clearValue();
												comp.allSelected = false;
											}
										} else {
											let valuesWithoutAdmin = values.filter(
												(item) =>
													item !==
													edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.USER_ADMINISTRATION
											);
											comp.select(valuesWithoutAdmin);
											comp.allSelected = false;
										}

										if (
											actionLength - 1 === values.length &&
											values.includes(
												edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.USER_ADMINISTRATION
											)
										) {
											let valuesWithoutAdmin = values.filter(
												(item) =>
													item !==
													edi.constants.ORG_PROFILE.EMPLOYEES.ACTION.USER_ADMINISTRATION
											);
											comp.select(valuesWithoutAdmin);
											comp.allSelected = false;
										}
									}
								}
							})
						]
					});

					return createForm({
						cls: '',
						items: [productHidden, actions]
					});
				};
				let createDropcatFields = function (dataValues) {
					let dropcatRoles = Ext.clone(options.dropcatRoles),
						roles;

					let rolesStore = edi.stores.createSimpleInlineStore(dropcatRoles, function (id) {
						return edi.i18n.getMessage('ediweb.dropcat.role.' + id);
					});

					var createRolesField = function (isMainField, fieldData, props) {
						fieldData = fieldData || {};
						let roleInput, signTypeInput, signTypeInputField, rolesRow;

						let getSignTypeArray = function (role) {
							let defaultSignTypes = [
								edi.constants.SIGN_TYPES.SIGN_PEP,
								edi.constants.SIGN_TYPES.SIGN_UKEP,
								edi.constants.SIGN_TYPES.SIGN_PEP_UKEP,
								edi.constants.SIGN_TYPES.WITHOUT_SIGN
							];

							const signTypes = {
								ONBOARDING_TC: [
									edi.constants.SIGN_TYPES.SIGN_UKEP,
									edi.constants.SIGN_TYPES.WITHOUT_SIGN
								],
								ONBOARDING_MED_ORG: [
									edi.constants.SIGN_TYPES.SIGN_PEP_UKEP,
									edi.constants.SIGN_TYPES.WITHOUT_SIGN
								]
							};

							let store = signTypeInput.getStore();
							store.removeAll();
							let array = signTypes[role] ?? defaultSignTypes;

							let storeArray = array.map(function (role) {
								return {
									id: edi.constants.SIGN_TYPES[role],
									name: edi.i18n.getMessage('ediweb.signType.' + role)
								};
							});
							store.loadData(storeArray);
							store.commitChanges();
							signTypeInput.setValue(null);
						};

						let checkActualRoles = function (field) {
							let selectedRoles = [];
							let fields = roles.query('field');
							let roleFields = fields.filter(function (input) {
								return input.name === field.name;
							});
							roleFields.forEach(function (item) {
								selectedRoles.push(item.getValue());
							});
							roleFields.forEach(function (item) {
								let roleInputStore = item.getStore();
								roleInputStore.clearFilter();
								roleInputStore.filter((n) => {
									return !selectedRoles.includes(n.id);
								});
							});
						};

						roleInput = createFieldBlock({
							cls: FIELD_BLOCK_CLS.small,
							title: edi.i18n.getMessage('column.role'),
							items: [
								createCombo({
									forceSelection: false,
									allowBlank: false,
									store: rolesStore,
									name: 'role',
									value: edi.utils.getObjectProperty(fieldData, 'role'),
									listeners: {
										change: function (field, newValue, oldValue) {
											checkActualRoles(field);
											getSignTypeArray(newValue);
										},
										blur: function (comp) {
											const value = comp.getValue();
											const isValueFromStore = rolesStore.getById(value);

											if (!isValueFromStore) {
												comp.setValue(null);
												comp.isValid();
											}
										},
										afterrender(field) {
											let value = field.getValue();
											checkActualRoles(field);
											getSignTypeArray(value);
										}
									}
								})
							]
						});

						signTypeInputField = createFieldBlock({
							cls: FIELD_BLOCK_CLS.small,
							items: [
								(signTypeInput = createCombo({
									fieldLabel: edi.i18n.getMessage('ediweb.employee.access.to.products.signType'),
									allowBlank: false,
									forceSelection: false,
									name: 'signType',
									value: edi.utils.getObjectProperty(fieldData, 'signType'),
									store: edi.stores.createSimpleInlineStore([]),
									listeners: {
										blur: function (comp) {
											const value = comp.getValue();
											const store = comp.getStore();
											const isValueFromStore = store.getById(value);

											if (!isValueFromStore) {
												comp.setValue(null);
												comp.isValid();
											}
										}
									}
								}))
							]
						});

						rolesRow = createContainer({
							layout: {
								type: 'grid'
							},
							padding: '0 0 24 0',
							items: [roleInput, signTypeInputField]
						});

						rolesRow.roleInput = roleInput;
						rolesRow.signTypeInputField = signTypeInputField;

						rolesRow.onRemoveRow = function () {
							checkActualRoles(roleInput);
						};

						return rolesRow;
					};

					let productHidden = createHiddenField({
						name: 'productType',
						value: 'dropcat'
					});

					roles = createRowsBlock({
						createContentFieldsFn: createRolesField,
						initialData: Array.isArray(dataValues) ? convertDataForRoles(dataValues) : [],
						setExtraDataToRow(row) {
							row.role = row.contentFieldsPanel.role;
							row.signType = row.contentFieldsPanel.signType;
						},
						//textAddButton: edi.i18n.getMessage('ediweb.employee.access.to.products.add.role.btn'),
						watchingComboInputName: 'role',
						rowChange: function (me) {
							let rows = me.getRows();
							let isDisabled = rows.length >= dropcatRoles.length;
							rows.forEach(function (row) {
								row.addBtn.setDisabled(isDisabled);
							});
						}
					});
					return createForm({
						cls: 'ediweb-employee-dc-form',
						items: [productHidden, roles]
					});
				};

				let createProductsForm = function (formData = {}) {
					let form,
						items = [];
					let dataValues = formData.productInfos ? formData.productInfos : {};
					let product = Array.isArray(dataValues) ? dataValues[0]?.productType : formData.product;
					let value = Array.isArray(dataValues) ? dataValues : [dataValues];

					if (!edi.utils.isEmptyObject(dataValues) && !formData.product) {
						product = dataValues.productType;
						if (dataValues.role && dataValues.signType) {
							dataValues.role = dataValues.role.split(',');
							dataValues.signType = dataValues.signType.split(',');
						}
					}

					switch (product?.toUpperCase()) {
						case 'DROPCAT':
							DCForm = createDropcatFields(value);
							items = items.concat(DCForm);
							break;
						default:
							EWForm = createEWFields(value ? value[0] : null);
							items = items.concat(EWForm);
							break;
					}

					form = createForm({
						submitEmptyText: false,
						layout: {
							type: 'grid',
							gap: [16, 24]
						},
						items: items
					});
					return form;
				};

				let menuItems = [];
				var userProducts = edi.utils.getObjectProperty(edi.core.getUserData().org, 'attributes.products.value');
				let products = ['dropcat'];
				const allProducts = ['ediweb'].concat(products);

				products = allProducts.filter(function (item) {
					return userProducts.includes(item);
				});
				menu = new Ext.menu.Menu({
					width: 164,
					cls: 'ediweb-employee-products-menu',
					items: []
				});

				products.forEach(function (product) {
					let productInnerTpl = createProductTpl(product);
					let productTpl = new Ext.XTemplate('<tpl>' + productInnerTpl + '</tpl>');

					let menuItem = createMenuItem({
						renderTpl: productTpl,
						itemId: product.toUpperCase(),
						handler: function (comp) {
							let externalAddBtn = comp.up('button');
							externalAddBtn.buttonData = {
								productInfos: [
									{
										productType: product.toUpperCase()
									}
								],
								customInternalTitle: productInnerTpl
							};
							accessToProducts.internalAddBtnHandler(externalAddBtn);
						}
					});

					menuItems.push(menuItem);
				});

				menu.add(menuItems);
				let productInfosValues = {};

				const convertDataForProductForm = function () {
					const productTypes = getProductsType(objectData.get('productInfos'));
					const groupedByProductType = getProductsTypeName(objectData.get('productInfos'));
					let res = [];
					productTypes.forEach(function (item) {
						res.push({
							productInfos: groupedByProductType[item]
						});
					});

					return res;
				};
				if (isEdit) {
					productInfosValues.productInfos = convertDataForProductForm();
				} else {
					productInfosValues.productInfos = [
						{
							productInfos: [
								{
									productType: 'EDIWEB'
								}
							]
						}
					];
				}

				const createReadOnlyEWFields = function (values) {
					let actionContentArray = [];
					let actionsValue = Array.isArray(values.actions) ? values.actions : values.actions.split(',');
					actionsValue.forEach(function (item) {
						actionContentArray.push(edi.i18n.getMessage('ediweb.employees.type.' + item));
					});
					let actions = createDetailLabel({
						cls: 'ediweb-productInfos-read-only-form-detail-label',
						noWrapContent: true,
						contentTextArray: [
							{
								title: edi.i18n.getMessage('ediweb.employees.actions'),
								content: actionContentArray.join(', '),
								qtipContent: actionContentArray.join(', ')
							}
						]
					});

					let productHidden = createHiddenField({
						name: 'productType',
						value: 'ediweb'
					});

					let actionHidden = createHiddenField({
						name: 'actions',
						value: actionsValue
					});

					return createForm({
						cls: '',
						items: [productHidden, actionHidden, actions]
					});
				};

				const createReadOnlyDropcatFields = function (values) {
					let items = [];
					let role = Array.isArray(values.role) ? values.role : values.role.split(',');
					role.forEach(function (value, index) {
						let signTypeArray = Array.isArray(values.signType)
							? values.signType
							: values.signType.split(',');
						let signType = signTypeArray[index];
						let container = createContainer({
							layout: 'vbox',
							padding: index !== values.role.length - 1 ? '0 0 16 0' : 0,
							items: [
								createDetailLabel({
									padding: '0 0 8 0',
									contentTextArray: [
										{
											title: edi.i18n.getMessage('column.role'),
											content: edi.i18n.getMessage('ediweb.dropcat.role.' + value),
											qtipContent: edi.i18n.getMessage('ediweb.dropcat.role.' + value)
										}
									]
								}),
								createDetailLabel({
									contentTextArray: [
										{
											title: edi.i18n.getMessage('ediweb.employee.access.to.products.signType'),
											content: edi.i18n.getMessage('ediweb.signType.' + signType)
										}
									]
								})
							]
						});
						items.push(container);
					});

					let productHidden = createHiddenField({
						name: 'productType',
						value: 'dropcat'
					});

					let roleHidden = createHiddenField({
						name: 'role',
						value: edi.utils.getObjectProperty(values, 'role')
					});
					let signTypeHidden = createHiddenField({
						name: 'signType',
						value: edi.utils.getObjectProperty(values, 'signType')
					});
					items.push(productHidden, roleHidden, signTypeHidden);

					return createForm({
						cls: '',
						items: items
					});
				};

				const getReadOnlyInternalForm = function (fieldsValues) {
					let items = [];

					const line = createContainer({
						margin: '0 0 16 0',
						cls: 'ediweb-productInfos-read-only-form-line'
					});
					items.push(line);
					switch (fieldsValues.productType?.toUpperCase()) {
						case 'DROPCAT':
							DCForm = createReadOnlyDropcatFields(fieldsValues);
							items = items.concat(DCForm);
							break;
						default:
							EWForm = createReadOnlyEWFields(fieldsValues);
							items = items.concat(EWForm);
							break;
					}
					return createForm({
						submitEmptyText: false,
						items: items
					});
				};

				let config = {
					itemId: 'productsitems',
					externalTitle: edi.i18n.getMessage('ediweb.employee.access.to.products.externalTitle'),
					internalTitle:
						'<div class="edi-ediweb-project-item item">' + '<div class="logo ediweb"></div></div>',
					internalFormFn: createProductsForm,
					internalName: 'productInfos',
					internalInitialData: !edi.utils.isEmptyObject(productInfosValues) ? [productInfosValues] : null,
					showCount: false,
					isCreate: !isEdit,
					outerInternalAddBtnHandler: () => {},
					customReadOnlyInternalFormFn: getReadOnlyInternalForm,
					externalAddBtnConfig: {
						permanentlyHidden: true
					},
					internalAddBtnConfig: {
						permanentlyHidden: true
					},
					internalRemoveBtnConfig: {
						permanentlyDisabledInFirstRow: true
					},
					createExternalBlockAction: function () {
						return [
							createButton({
								glyph: edi.constants.ICONS.PLUS,
								name: 'item.add.button',
								itemId: 'externalActionAddBtn',
								cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
								text: edi.i18n.getMessage('ediweb.employee.access.to.products.internalAddBtn'),
								menu: menu
							})
						];
					},
					externalBlockChange: function (me, externalBlock) {
						let selectedProducts = externalBlock
							.query('[itemId="internalRow"]')
							?.map(function (internalRow) {
								return internalRow.rowData.productInfos[0]?.productType;
							});
						let externalActionAddBtn = externalBlock.down('[itemId="externalActionAddBtn"]');
						let itemsMenu = externalActionAddBtn.menu.items.items;
						let hasVisibleItems = false;
						itemsMenu.forEach(function (item) {
							let isHidden = selectedProducts.includes(item.itemId);
							item.setHidden(isHidden);
							if (!isHidden) {
								hasVisibleItems = true;
							}
						});
						externalActionAddBtn.setDisabled(!hasVisibleItems);
					}
				};

				accessToProducts = createRowWithNestedRows(config);

				accessToProducts.on('afterrender', function (comp) {
					if (isEdit) {
						let tpls = [];
						let productTypes = getProductsType(objectData.get('productInfos'));
						productTypes.forEach(function (productType) {
							var obj = edi.stores.initAllProductList().data.items?.find(function (item) {
								return item.get('name') === productType.toLowerCase();
							});

							tpls.push(createProductTpl(obj.get('name')));
						});
						comp.setInternalCustomTitles(tpls);
					}
				});

				var items = [exception, email, lastName, firstName, middleName, position, headdivision];

				if (isHasActiveStatus && isHasPermission) {
					var itemsContainer = [changeStatusBtn];
					var buttonContainer = createContainer({
						items: itemsContainer
					});
					items = items.concat([statusField, buttonContainer]);

					if (isShowBtnSubtitle) {
						items.push(btnSubtitle);
					}
				}

				items.push(accessToProducts);

				formPanel = createModalForm({
					layout: {
						type: 'grid',
						gap: [16, 24],
						area: [12, 12, [4, 4, 4], [6, 6], 12, 5, 12]
					},
					submitEmptyText: false,
					items: items
				});

				modal.add(formPanel);
				modal.setLoading(false);
			};

			let convertDataForRoles = function (dcValues) {
				if (Array.isArray(dcValues) && dcValues[0]?.hasOwnProperty('role') && Array.isArray(dcValues[0].role)) {
					let items = [];
					let role = Array.isArray(dcValues[0].role) ? dcValues[0].role : dcValues[0].role.split(',');
					role.forEach(function (value, index) {
						let signTypeArray = Array.isArray(dcValues[0].signType)
							? dcValues[0].signType
							: dcValues[0].signType.split(',');
						let signType = signTypeArray[index];
						let item = {
							productType: dcValues[0].productType,
							role: value,
							signType: signType
						};
						items.push(item);
					});
					return items;
				} else {
					return dcValues;
				}
			};

			//конвертация ДК формы при создании
			let convertDCValues = function () {
				let DCValues = edi.utils.collectFormValues(DCForm);
				let DCFormConverted =
					typeof DCValues.role === 'string'
						? [DCValues]
						: edi.methods.convertValuesFromMultipleFields(DCValues);
				delete DCFormConverted.productInfos;
				let res = DCFormConverted.map(function (item) {
					item.productType = 'DROPCAT';
					if (typeof item.role === 'string') {
						item.role = item.role.split(',');
						item.signType = item.signType.split(',');
					}
					item.role = item.role.length > 1 ? item.role : item.role[0];
					item.signType = item.signType.length > 1 ? item.signType : item.signType[0];

					return item;
				});
				return convertDataForRoles(res);
			};

			var modal = createModalPanel({
				title: edi.i18n.getMessage(isEdit ? 'employees.edit' : 'employees.new'),
				width: MODAL_SIZE.widthMedium,
				items: [],
				buttons: [
					createButton({
						cls: [BUTTON_CLS.primary],
						text: edi.i18n.getMessage('form.btn.save'),
						handler: function () {
							if (!edi.utils.setFocusToDocumentsWithGrid(formPanel)) return;
							if (formPanel.isValid()) {
								modal.setLoading();
								var values = edi.utils.collectFormValues(formPanel);
								delete values.actions;
								var EWValues = edi.utils.collectFormValues(EWForm);
								var productInfosArray = [];
								EWValues.actions = Array.isArray(EWValues.actions)
									? EWValues.actions
									: EWValues.actions.split(',');
								productInfosArray.push(EWValues);
								productInfosArray.forEach(function (item) {
									item.productType = item.productType.toUpperCase();
								});
								if (values.productType.includes('dropcat')) {
									let DCValues = convertDCValues();
									DCValues.forEach(function (item) {
										productInfosArray.push(item);
									});
								}

								values.productInfos = productInfosArray;

								delete values.productType;
								delete values.role;
								delete values.signType;

								if (values.subdivision.length < 1) {
									values.subdivision = null;
								}

								var url = objectData
									? edi.utils.formatString(
											edi.rest.services.ORG_PROFILE.EMPLOYEES.PUT,
											{
												id: objectData.get('id')
											},
											false
									  )
									: edi.rest.services.ORG_PROFILE.EMPLOYEES.POST;
								var success = function () {
									edi.events.employees.fireEvent('change');
									modal.setLoading(false);
									modal.close();
								};

								edi.rest.sendRequest(
									url,
									objectData ? 'PUT' : 'POST',
									Ext.encode(values),
									success,
									failure
								);
							}
						}
					}),
					createButton({
						cls: [BUTTON_CLS.secondary],
						text: edi.i18n.getMessage('ediweb.cancel.btn'),
						handler: function () {
							if (formPanel.isDirty()) {
								confirm(
									isEdit ? 'cancel.edit.subdivisions.title' : 'cancel.create.employees.title',
									isEdit ? 'cancel.edit.subdivisions' : 'cancel.create.employees',
									() => modal.close()
								);
							} else {
								modal.close();
							}
						}
					})
				]
			});

			modal.show();
			modal.setLoading(true);

			getSubdivisionsArray()
				.then((arr) => {
					subdivisionsArray = arr;
					addModalContent();
					modal.center();
				})
				.catch(edi.rest.getErrorHandler('error.etting.data', () => modal.close()));
		},
		delete: function (ids, moduleData, callback, createList = null, exceptionCreateList = null) {
			edi.methods.gridActionProcessWithToasts({
				moduleData,
				isManyDelete: createList && exceptionCreateList,
				groupedErrorTypes: [
					'controller.employee.delete.error.route.has.related.approvals',
					'controller.employee.has.not.user.error'
				],
				ids,
				processUrl: edi.rest.services.ORG_PROFILE.EMPLOYEES.DELETE,
				processMethod: 'DELETE',
				confirmTitle: 'delete.employees.title',
				confirmQuestion: 'delete.employees',
				confirmExceptionsTitle: 'delete.employees.exceptions',
				errorToastTitle: 'controller.employees.error.title',
				countSuccessToastTitle: 'toast.count.employees.deleted',
				confirmAction: true,
				createList,
				exceptionCreateList,
				successCallback: function () {
					edi.events.employees.fireEvent('change');
					edi.events.subdivisions.fireEvent('change');
					if (typeof callback === 'function') {
						callback();
					}
				}
			});
		}
	},
	routes: {
		createOrUpdate: function (objectData, options) {
			let formPanel;
			let subdivisionsArray = [];
			var routeName = options.routeType;
			var isEdit = !!objectData,
				stages;
			var isPrivate = routeName === edi.constants.ORG_PROFILE.ROUTES.ROUTE_TYPE.PRIVATE;
			var stagesValues = isEdit ? objectData.get('stages') : null;
			let statusCombo;

			var actionStatuses = [
				edi.constants.ORG_PROFILE.EMPLOYEES.STATE.NOT_CONFIRMED,
				edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DEACTIVATED,
				edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DELETED
			];

			let addModalContent = function () {
				var privateInfo = isPrivate
					? createLabel({
							typography: 'heading_01',
							padding: isEdit ? '2 0 0 0' : '0 0 0 0',
							test: edi.i18n.getMessage('org.profile.routes.private.info')
					  })
					: null;
				var infoTpl = new Ext.XTemplate(
					'<tpl>',
					'<div class="info-container">' +
						'<div class="icon"></div>' +
						'<div class="text">' +
						edi.i18n.getMessage('org.profile.routes.info') +
						'</div>' +
						'</div>',
					'</tpl>'
				);
				var info = createContainer({
					cls: 'edi-ediweb-route-info',
					html: !isEdit ? infoTpl : ''
				});
				var name = createFieldBlock({
					title: edi.i18n.getMessage('org.profile.routes.name'),
					items: [
						createTextField({
							name: 'name',
							allowBlank: false,
							value: objectData ? objectData.get('name') : null
						})
					]
				});

				let employeeValues = objectData?.get('responsibleEmployee');
				let employeeRec = employeeValues
					? Ext.create(edi.models.getModel('EW_EMPLOYEES'), employeeValues)
					: null;
				let responsibleEmployee = isPrivate
					? createFieldBlock({
							title: edi.i18n.getMessage('org.profile.routes.responsible.employee'),
							items: [
								createOrgAutocomplete(
									{
										name: 'responsibleEmployee',
										queryMode: 'remote',
										queryParam: 'name',
										displayField: 'displayname',
										valueField: 'id',
										forceSelection: true,
										anyMatch: true,
										allowBlank: false,
										listeners: {
											beforeQuery: function (plan) {
												plan.query = plan.query.trim();
											},
											afterrender: function (cmp) {
												if (employeeRec) {
													cmp.setValue(employeeRec);
													cmp.isValid();
												}

												const picker = cmp.getPicker();

												picker.on('viewlist', function (__self, record, itemEl) {
													const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

													var tpl = new Ext.XTemplate(
														'<tpl if="position">',
														'<span class="{[this.$comp.cls]}-text">{position}</span>',
														'</tpl>'
													);
													tpl.$comp = cmp;
													var el = tpl.append(wrapEl, record.getData());
												});
											}
										}
									},
									{
										sorters: [],
										proxy: createProxyConfig({
											type: 'ajax',
											url: edi.utils.compileURL(edi.rest.services.ORG_PROFILE.EMPLOYEES.GET, {
												gridName: 'active'
											})
										}),
										model: edi.models.getModel('EW_EMPLOYEES'),
										data: employeeRec ? [employeeRec] : undefined
									}
								)
							]
					  })
					: null;

				var partner = isPrivate
					? createFieldBlock({
							title: edi.i18n.getMessage('org.profile.routes.partner'),
							items: [
								createCombo({
									name: 'partner',
									allowBlank: false,
									value:
										objectData && objectData.get('partner') ? objectData.get('partner').id : null,
									store: edi.stores.initRelatedOrganizationsStore(),
									queryMode: 'local',
									displayField: 'name',
									valueField: 'id',
									forceSelection: true,
									anyMatch: true
								})
							]
					  })
					: null;

				const DSFState = [
					edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL,
					edi.constants.STATE.SENT
				].map((state) => ({
					id: state,
					name: edi.i18n.getMessage(`documents.status.${state}.INCOMING`)
				}));

				const UPD_UKD_state = [
					edi.constants.STATE.SENT,
					edi.constants.STATE.READ,
					edi.constants.STATE.RECEIVER_REVIEW
				].map((state) => ({
					id: state,
					name: edi.i18n.getMessage(`documents.status.${state}`)
				}));

				var docType = isPrivate
					? createFieldBlock({
							title: edi.i18n.getMessage('document.type'),
							items: [
								createCombo({
									name: 'docType',
									allowBlank: false,
									value: objectData ? objectData.get('docType') : null,
									store: edi.stores.createSimpleInlineStore(
										edi.constants.APPROVAL_DOCUMENTS_TYPES,
										function (id) {
											if (id.match(/^FNS_UPD/)) {
												return edi.i18n.getMessage('documents.doctype.EDI_FNS_UPD.' + id);
											}
											if (id.match(/^FNS_UKD/)) {
												return edi.i18n.getMessage('documents.doctype.EDI_FNS_UKD.' + id);
											}
											return edi.i18n.getMessage('documents.doctype.' + id);
										},
										false
									),
									forceSelection: true,
									anyMatch: true,
									listeners: {
										change: function (comp, newValue, oldValue) {
											const statusComboStore = statusCombo.getStore();
											if (
												edi.constants.DSF_WITH_APPROVE.includes(newValue) &&
												!edi.constants.DSF_WITH_APPROVE.includes(oldValue)
											) {
												statusCombo.setValue(null);
												statusComboStore.removeAll();
												statusComboStore.loadData(DSFState);
											}
											if (
												(edi.constants.UPD_WITH_APPROVE.includes(newValue) ||
													edi.constants.UKD_WITH_APPROVE.includes(newValue)) &&
												!(
													edi.constants.UPD_WITH_APPROVE.includes(oldValue) ||
													edi.constants.UKD_WITH_APPROVE.includes(oldValue)
												)
											) {
												statusCombo.setValue(null);
												statusComboStore.removeAll();
												statusComboStore.loadData(UPD_UKD_state);
											}
										}
									}
								})
							]
					  })
					: null;

				let stateStore = [];
				if (objectData) {
					if (edi.constants.DSF_WITH_APPROVE.includes(objectData.get('docType'))) {
						stateStore = DSFState;
					}
					if (
						edi.constants.UPD_WITH_APPROVE.includes(objectData.get('docType')) ||
						edi.constants.UKD_WITH_APPROVE.includes(objectData.get('docType'))
					) {
						stateStore = UPD_UKD_state;
					}
				}
				var status = isPrivate
					? createFieldBlock({
							title: edi.i18n.getMessage('documents.column.status'),
							items: [
								(statusCombo = createCombo({
									name: 'docState',
									allowBlank: false,
									value: objectData ? objectData.get('docState') : null,
									store: createSimpleStore({
										data: stateStore
									}),
									forceSelection: true,
									anyMatch: true
								}))
							]
					  })
					: null;

				var getEmployeesName = function (employee) {
					employee = edi.renderers.getUserInfo(employee);
					let first = employee.firstName;
					let patron = employee.middleName;
					return employee.lastName + (first ? ' ' + first : '') + (patron ? ' ' + patron : '');
				};

				var createStagesForm = function (values) {
					var employee,
						subdivision,
						agreementCheckbox,
						agreementCheckboxField,
						form,
						subdivisionField,
						employeeField,
						subdivisionRadio,
						employeeRadio,
						subdivisionName,
						employeeName,
						employeePosition,
						subdivisionEmployees,
						employeeObject;
					var switchType = function (type) {
						if (type === 'user') {
							employeeRadio.setValue(true);
							subdivisionRadio.setValue(false);
							employee.show();
							employeeField.allowBlank = false;
							employeeField.isValid();
							subdivisionField.allowBlank = true;
							subdivisionField.setValue(null);
							subdivisionField.isValid();
							subdivision.hide();
							agreementCheckbox.hide();
						}
						if (type === 'subdivision') {
							subdivisionRadio.setValue(true);
							employeeRadio.setValue(false);
							subdivision.show();
							subdivisionField.allowBlank = false;
							subdivisionField.isValid();
							agreementCheckbox.show();
							employeeField.allowBlank = true;
							employeeField.setValue(null);
							employeeField.isValid();
							employee.hide();
						}
					};
					var radio = createContainer({
						layout: 'hbox',
						items: [
							(employeeRadio = createRadio({
								boxLabel: edi.i18n.getMessage('column.by.user'),
								name: 'user',
								checked: !values || !!values.employee,
								value: values ? !!values.employee : null,
								inputValue: 'user',
								listeners: {
									change: function (comp, newValue) {
										if (newValue) {
											switchType('user');
										}
									},
									afterrender: function () {
										if (!values || !!values.employee) {
											switchType('user');
										} else {
											switchType('subdivision');
										}
									}
								}
							})),
							(subdivisionRadio = createRadio({
								margin: '0 0 0 16',
								boxLabel: edi.i18n.getMessage('org.profile.subdivision'),
								name: 'subdivision',
								value: values ? !!values.subdivision : null,
								checked: values ? !!values.subdivision : false,
								inputValue: 'subdivision',
								listeners: {
									change: function (comp, newValue) {
										if (newValue) {
											switchType('subdivision');
										}
									},
									afterrender: function () {
										if (!values || !!values.employee) {
											switchType('user');
										} else {
											switchType('subdivision');
										}
									}
								}
							}))
						]
					});
					subdivision = createFieldBlock({
						hidden: true,
						items: [
							(subdivisionField = createCombo({
								name: 'parties.subdivision',
								fieldLabel: edi.i18n.getMessage('org.profile.subdivision'),
								store: createStore({
									proxy: createProxyConfig({
										type: 'memory',
										data: subdivisionsArray
									}),
									autoLoad: true,
									model: edi.models.getModel('EW_SUBDIVISIONS')
								}),
								forceSelection: true,
								queryMode: 'local',
								displayField: 'name',
								valueField: 'id',
								allowBlank: false,
								value: values ? values?.subdivision : null,
								listeners: {
									change: function (combo) {
										subdivisionName.setValue(combo.rawValue);
										var record = combo.getStore().findRecord('id', combo.getValue());
										if (record) {
											var employees = record.get('employees');
											subdivisionEmployees.setValue(Ext.encode(employees));
											employeeObject.setValue(null);
										}
									}
								}
							}))
						]
					});

					subdivisionEmployees = createHiddenField({
						name: 'subdivisionEmployees',
						value: values ? Ext.encode(values?.subdivisionEmployees) : '',
						listeners: {
							afterrender: function (field) {
								let record = subdivisionField.getStore().findRecord('id', subdivisionField.getValue());
								if (record) {
									let employees = record.get('employees');
									field.setValue(Ext.encode(employees));
								}
							}
						}
					});

					var subdivisionObject = createHiddenField({
						name: 'subdivisionObject',
						value: values ? Ext.encode(values?.subdivision) : ''
					});

					subdivisionName = createHiddenField({
						name: 'subdivisionName',
						value: values ? values?.subdivision?.name : '',
						listeners: {
							afterrender: function (field) {
								let record = subdivisionField.getStore().findRecord('id', subdivisionField.getValue());
								if (record) {
									field.setValue(record.get('name'));
								}
							}
						}
					});

					let employeeValues =
						values?.employee && values?.employeeObject ? Ext.decode(values.employeeObject, true) : null;
					let employeeRec = employeeValues
						? Ext.create(edi.models.getModel('EW_EMPLOYEES'), employeeValues)
						: null;
					employee = createFieldBlock({
						items: [
							(employeeField = createOrgAutocomplete(
								{
									name: 'parties.employee',
									fieldLabel: edi.i18n.getMessage('org.profile.routes.employee'),
									allowBlank: false,
									displayField: 'displayname',
									queryMode: 'remote',
									queryParam: 'name',
									valueField: 'id',
									forceSelection: true,
									anyMatch: true,
									getParams: function (queryString) {
										let me = this;
										let fields = me.up('[itemId="routeitems"]')?.query('[name="parties.employee"]');
										let otherEmployeeIds = (fields || [])
											.filter((it) => it !== me && it?.value)
											.map((it) => it.value);
										return {
											[me.queryParam]: queryString,
											excludeIds: `[${otherEmployeeIds.join(',')}]`
										};
									},
									listeners: {
										beforeQuery: function (plan) {
											plan.query = plan.query.trim();
										},
										afterrender: function (cmp) {
											if (employeeRec) {
												cmp.setValue(employeeRec);
												cmp.isValid();
											}

											const picker = cmp.getPicker();

											picker.on('viewlist', function (__self, record, itemEl) {
												const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

												var tpl = new Ext.XTemplate(
													'<tpl if="position">',
													'<span class="{[this.$comp.cls]}-text">{position}</span>',
													'</tpl>'
												);
												tpl.$comp = cmp;
												var el = tpl.append(wrapEl, record.getData());
											});
										},
										change: function (combo, value) {
											employeeName.setValue(combo.rawValue);
											var store = combo.getStore();
											var selected = store.data.items.filter(function (item) {
												return item.get('id') === value;
											})[0];
											if (!!selected) {
												subdivisionEmployees.setValue(null);
												employeePosition.setValue(selected.get('position'));
												employeeObject.setValue(Ext.encode(selected.data));
												agreementCheckboxField.setValue(null);
											}
										}
									}
								},
								{
									sorters: [],
									proxy: createProxyConfig({
										type: 'ajax',
										url: edi.utils.compileURL(edi.rest.services.ORG_PROFILE.EMPLOYEES.GET, {
											gridName: 'active'
										})
									}),
									model: edi.models.getModel('EW_EMPLOYEES'),
									data: employeeRec ? [employeeRec] : undefined
								}
							))
						]
					});

					employeeName = createHiddenField({
						name: 'employeeName',
						value: Ext.isObject(values?.employee)
							? getEmployeesName(values.employee)
							: employeeValues
							? getEmployeesName(employeeValues)
							: ''
					});
					employeePosition = createHiddenField({
						name: 'employeePosition',
						value: values?.employee?.position || employeeValues?.position || ''
					});
					var employeeObjectValue = values ? Ext.encode(values?.employee) : '';
					var employeeStatusValue = values ? values?.employee?.status : '';

					if (typeof values?.employee !== 'object') {
						employeeObjectValue = values ? values?.employeeObject : '';
						employeeStatusValue = employeeObjectValue ? Ext.decode(employeeObjectValue)?.status : '';
					}

					var employeeStatus = createHiddenField({
						name: 'employeeStatus',
						value: employeeStatusValue
					});

					employeeObject = createHiddenField({
						name: 'employeeObject',
						value: employeeObjectValue
					});

					var actionsValues = null;
					if (values) {
						actionsValues = Array.isArray(values.actions) ? values.actions[0] : values.actions;
					}

					var actions = createFieldBlock({
						items: [
							createCombo({
								name: 'parties.actions',
								fieldLabel: edi.i18n.getMessage('ediweb.employees.actions'),
								allowBlank: false,
								value: actionsValues,
								store: edi.stores.createSimpleInlineStore(
									['APPROVAL_REFUSAL', 'SIGNING_REFUSAL'],
									function (id) {
										return edi.i18n.getMessage('org.profile.routes.actions.' + id);
									},
									false
								),
								forceSelection: true,
								anyMatch: true
							})
						]
					});

					agreementCheckbox = createFieldBlock({
						hidden: true,
						items: [
							(agreementCheckboxField = createCheckbox({
								boxLabel: edi.i18n.getMessage('org.profile.routes.agreement'),
								checked: values ? values.allEmployeesNeeded : false,
								name: 'parties.allEmployeesNeeded',
								itemId: 'agreementCheckbox',
								inputValue: true,
								uncheckedValue: false,
								listeners: {
									change: function () {
										edi.events.updateExternalStatus.fireEvent('change');
									}
								}
							}))
						]
					});
					form = createForm({
						cls: 'edi-ediweb-form',
						submitEmptyText: false,
						layout: {
							type: 'grid',
							gap: [16, 24]
						},
						items: [
							radio,
							employee,
							employeeName,
							employeePosition,
							employeeStatus,
							employeeObject,
							subdivision,
							subdivisionObject,
							subdivisionName,
							subdivisionEmployees,
							actions,
							agreementCheckbox
						]
					});
					return form;
				};

				var setExternalStatus = function (label, externalRowContent) {
					let agreementCheckbox = externalRowContent.down('[itemId="agreementCheckbox"]');
					label.setText(edi.i18n.getMessage('org.profile.routes.stage.PARALLEL'));
					let isVisible;
					if (externalRowContent.items.items.length > 1) {
						isVisible = true;
					} else {
						isVisible =
							agreementCheckbox && agreementCheckbox.getValue().length > 1
								? agreementCheckbox.getValue()
								: false;
					}

					label.setVisible(isVisible);
				};

				var setWarningBackground = function (formValues) {
					var decodeSubdivisionEmployees = Ext.decode(
						formValues.parties.subdivisionEmployeesObject
							? formValues.parties.subdivisionEmployeesObject
							: formValues.subdivisionEmployees,
						true
					);
					var isHasActiveStatus = formValues?.parties
						? actionStatuses.some((it) => it === formValues.parties.employeeStatus)
						: false;
					var isHasWarningStatus =
						Array.isArray(decodeSubdivisionEmployees) &&
						String(formValues?.parties.allEmployeesNeeded) === 'true'
							? decodeSubdivisionEmployees.filter(function (item) {
									return actionStatuses.some((it) => it === item.status);
							  })
							: [];

					var subdivisionObject = Ext.decode(formValues.subdivisionObject, true);

					return (
						isHasWarningStatus.length > 0 ||
						isHasActiveStatus ||
						actionStatuses.some((it) => it === formValues?.employeeStatus) ||
						(subdivisionObject && subdivisionObject.deleted === 'true')
					);
				};

				var setEmployeePositionWithStatus = function (employeesValue, isLast) {
					let subdivisionEmployeesStatusText =
						!!employeesValue.status && actionStatuses.some((it) => it === employeesValue.status)
							? employeesValue.status
							: null;
					let subdivisionEmployeesStatusStr = subdivisionEmployeesStatusText
						? Ext.String.format(
								'<b> —<b style="color: #eb180b"> {0}</b></b>',
								edi.i18n.getMessage('documents.status.employees.' + subdivisionEmployeesStatusText)
						  )
						: '';

					let subdivisionEmployeesName = getEmployeesName(employeesValue);

					var subdivisionEmployeePosition = createFormContainer({
						padding: '4 0 0 0',
						items: [
							createLabel({
								typography: 'caption_01',
								color: '--color-blue',
								name: 'employeePosition',
								text: employeesValue.hasOwnProperty('position')
									? employeesValue.position
									: employeesValue.employeePosition
							})
						]
					});

					return createContainer({
						padding: isLast ? 0 : '0 0 8 0',
						layout: 'fit',
						items: [
							createLabel({
								name: 'employeeName',
								cls: 'edi-ediweb-routes-employee-name',
								html: subdivisionEmployeesName + subdivisionEmployeesStatusStr
							}),
							subdivisionEmployeePosition
						]
					});
				};

				var getReadOnlyInternalForm = function (fieldsValues) {
					var subdivisionObject = Ext.decode(fieldsValues.subdivisionObject, true);
					var employeeObject = Ext.decode(fieldsValues.employeeObject, true);
					var activeTab = fieldsValues.user ? 'user' : 'subdivision';

					var isHasActiveStatus = fieldsValues.employeeStatus
						? actionStatuses.some((it) => it === fieldsValues.employeeStatus)
						: false;
					var employeeStatus = fieldsValues.employeeStatus ? fieldsValues.employeeStatus : '';
					if (employeeObject) {
						isHasActiveStatus = employeeObject.hasOwnProperty('status')
							? actionStatuses.some((it) => it === employeeObject.status)
							: false;
						employeeStatus = employeeObject.status;
					}

					let statusText =
						isHasActiveStatus && activeTab === 'user'
							? edi.i18n.getMessage('documents.status.employees.' + employeeStatus)
							: null;
					if (subdivisionObject && subdivisionObject.deleted === 'true') {
						statusText = edi.i18n.getMessage('documents.status.subdivision.deleted');
					}
					let statusStr = Ext.String.format('<b> —<b style="color: #eb180b"> {0}</b></b>', statusText);

					let subdivisionTitle =
						edi.i18n.getMessage('org.profile.subdivision.readOnly', [fieldsValues.subdivisionName]) +
						(statusText ? statusStr : '');
					let subdivisionSubTitle = null;

					if (
						!fieldsValues.parties.allEmployeesNeeded ||
						!fieldsValues.parties.hasOwnProperty('allEmployeesNeeded')
					) {
						subdivisionTitle = edi.i18n.getMessage('org.profile.subdivision.readOnly.allEmployeesNeeded');
						subdivisionSubTitle = fieldsValues.subdivisionName + (statusText ? statusStr : '');
					}

					var subdivision = createFieldBlock({
						cls: 'edi-ediweb-routes-field-title',
						title: subdivisionTitle,
						subTitle: subdivisionSubTitle,
						items: [
							createLabel({
								typography: 'caption_01',
								color: '--color-blue',
								name: 'subdivisionName',
								text:
									!fieldsValues.parties.allEmployeesNeeded ||
									!fieldsValues.parties.hasOwnProperty('allEmployeesNeeded')
										? edi.i18n.getMessage('org.profile.subdivision.readOnly.sublabel')
										: ''
							})
						]
					});

					var subdivisionId = fieldsValues.parties.subdivision ? fieldsValues.parties.subdivision : null;
					if (subdivisionObject && subdivisionObject.hasOwnProperty('id')) {
						subdivisionId = subdivisionObject.id;
					}

					var subdivisionHiddenField = createHiddenField({
						name: 'parties.subdivision',
						value: subdivisionId
					});

					var agreementCheckboxHiddenField = createHiddenField({
						name: 'parties.allEmployeesNeeded',
						itemId: 'agreementCheckbox',
						value:
							fieldsValues.parties.allEmployeesNeeded && !!subdivisionId
								? fieldsValues.parties.allEmployeesNeeded
								: null
					});

					var employee = createFieldBlock({
						cls: 'edi-ediweb-routes-field-title',
						title: edi.i18n.getMessage('org.profile.routes.employee'),
						items: [
							createLabel({
								name: 'employeeName',
								cls: 'edi-ediweb-routes-employee-name',
								html: fieldsValues.employeeName + (statusText ? statusStr : '')
							})
						]
					});

					var employeeId = fieldsValues.parties.employee ? fieldsValues.parties.employee : null;
					if (employeeObject && employeeObject.hasOwnProperty('id')) {
						employeeId = employeeObject.id;
					}

					var employeeHiddenField = createHiddenField({
						name: 'parties.employee',
						value: employeeId
					});

					var employeeObjectHiddenField = createHiddenField({
						name: 'parties.employeeObject',
						value: Ext.encode(employeeObject)
					});

					var employeeStatusHiddenField = createHiddenField({
						name: 'parties.employeeStatus',
						value: employeeObject?.status
					});

					var employeePosition = createLabel({
						typography: 'caption_01',
						color: '--color-blue',
						name: 'employeePosition',
						text: fieldsValues.employeePosition
					});
					var decodeSubdivisionEmployees = Ext.decode(fieldsValues.subdivisionEmployees, true);
					var subdivisionEmployeesArray = Array.isArray(decodeSubdivisionEmployees)
						? decodeSubdivisionEmployees
						: [];
					var subdivisionEmployeesItems = [];

					if (fieldsValues.parties.allEmployeesNeeded) {
						for (let i = 0; i < subdivisionEmployeesArray.length; i++) {
							let subdivisionEmployeesValue = subdivisionEmployeesArray[i];
							var isLast = i === subdivisionEmployeesArray.length - 1;
							subdivisionEmployeesItems.push(
								setEmployeePositionWithStatus(subdivisionEmployeesValue, isLast)
							);
						}
					}

					var notDeletedSubdivisionEmployeesArray = subdivisionEmployeesArray.filter(function (item) {
						return item.status !== edi.constants.ORG_PROFILE.EMPLOYEES.STATE.DELETED;
					});

					var subdivisionEmployeesIds = notDeletedSubdivisionEmployeesArray.map(function (item) {
						return item.id;
					});

					var subdivisionEmployees = createHiddenField({
						name: 'parties.subdivisionEmployees',
						value: subdivisionEmployeesIds
					});

					var subdivisionEmployeesObject = createHiddenField({
						name: 'parties.subdivisionEmployeesObject',
						value: Ext.encode(notDeletedSubdivisionEmployeesArray)
					});

					var actionsHiddenField = createHiddenField({
						name: 'parties.actions',
						value: fieldsValues.parties.actions
					});

					var actions = createFieldBlock({
						title: edi.i18n.getMessage('ediweb.employees.actions'),
						cls: 'edi-ediweb-employees-actions edi-ediweb-routes-field-title',
						items: [
							createLabel({
								cls: 'edi-ediweb-employees-actions-label',
								name: 'actions',
								text: edi.i18n.getMessage('org.profile.routes.actions.' + fieldsValues.parties.actions)
							})
						]
					});

					var items = [];
					if (!!fieldsValues.user) {
						items.push(employee);
						items.push(employeePosition);
					}
					if (!!fieldsValues.subdivision) {
						items.push(subdivision);
						items = items.concat(subdivisionEmployeesItems);
					}
					items.push(employeeHiddenField);
					items.push(employeeObjectHiddenField);
					items.push(employeeStatusHiddenField);
					items.push(subdivisionHiddenField);
					items.push(subdivisionEmployees);
					items.push(subdivisionEmployeesObject);
					items.push(actions);
					items.push(actionsHiddenField);
					items.push(agreementCheckboxHiddenField);

					return createForm({
						cls: 'edi-form ediweb-stages-form read-only',
						submitEmptyText: false,
						items: items
					});
				};

				var config = {
					itemId: 'routeitems',
					externalTitle: edi.i18n.getMessage('ediweb.stage'),
					internalTitle: edi.i18n.getMessage('ediweb.member'),
					internalFormFn: createStagesForm,
					internalInitialData: stagesValues,
					isCreate: !isEdit,
					internalName: 'parties',
					isShowExternalStatus: true,
					externalAddBtnConfig: {
						text: edi.i18n.getMessage('ediweb.add.stage')
					},
					internalAddBtnConfig: {
						text: edi.i18n.getMessage('ediweb.add.member')
					},
					setExternalStatusFn: setExternalStatus,
					setWarningBackgroundFn: setWarningBackground,
					customReadOnlyInternalFormFn: getReadOnlyInternalForm
				};

				stages = createRowWithNestedRows(config);

				formPanel = createModalForm({
					layout: {
						type: 'grid',
						gap: 24
					},
					autoScroll: true,
					submitEmptyText: false,
					items: [privateInfo, info, name, responsibleEmployee, partner, docType, status, stages]
				});

				modal.add(formPanel);
				modal.updateLayout();
				formPanel.isValid();
				modal.setLoading(false);
			};

			var getOrderOfAgreement = function (stagesArray) {
				var isPARALLEL = stagesArray.every(function (stage) {
					return stage['parties'].length > 1;
				});
				var isSEQUENTIAL = stagesArray.every(function (stage) {
					return stage['parties'].length === 1;
				});
				var isCOMPOSITE =
					stagesArray.length > 1 &&
					Ext.Array.some(stagesArray, function (stage) {
						return stage['parties'].length > 1;
					});
				if (stagesArray.length === 1) {
					if (stagesArray[0]['parties'].length === 1) {
						isPARALLEL = stagesArray[0]['parties'][0].allEmployeesNeeded === 'true';
					}
				}
				if (isSEQUENTIAL) {
					var isHasAllEmployeesNeededSubdivision =
						stagesArray.filter(function (item) {
							return item['parties'][0].allEmployeesNeeded === 'true';
						}).length > 0;
					if (stagesArray.length > 1) {
						isCOMPOSITE = isHasAllEmployeesNeededSubdivision;
						isSEQUENTIAL = !isHasAllEmployeesNeededSubdivision;
					}
				}

				if (isPARALLEL) {
					return edi.constants.ORG_PROFILE.ROUTES.ORDER_OF_AGREEMENT.PARALLEL;
				}
				if (isSEQUENTIAL) {
					return edi.constants.ORG_PROFILE.ROUTES.ORDER_OF_AGREEMENT.SEQUENTIAL;
				}
				if (isCOMPOSITE) {
					return edi.constants.ORG_PROFILE.ROUTES.ORDER_OF_AGREEMENT.COMPOSITE;
				}
			};

			var modalHeight = Math.floor(window.innerHeight * 0.9);
			var modal = createModalPanel({
				cls: MODAL_CLS.withShadows,
				title: edi.i18n.getMessage(isEdit ? 'routes.edit.' + routeName : 'routes.new.' + routeName),
				width: MODAL_SIZE.widthMedium,
				height: modalHeight,
				items: [],
				buttons: [
					createButton({
						cls: [BUTTON_CLS.primary],
						text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.routes.add'),
						glyph: isEdit ? null : edi.constants.ICONS.PLUS,
						handler: function () {
							if (!edi.utils.setFocusToDocumentsWithGrid(formPanel)) return;

							if (formPanel.isValid()) {
								edi.events.onAddExternalBtnHandler.fireEvent('change');
								modal.setLoading();
								var values = edi.utils.collectFormValues(formPanel);
								var stagesArray = stages.getValues('parties');
								stagesArray.forEach(function (stage, index) {
									stage['stageNumber'] = index + 1;
									stage['parties'].forEach(function (item, i) {
										item['partyNumber'] = i + 1;
										item.actions = [item.actions];
										delete item.employeeObject;
										delete item.subdivisionEmployeesObject;
										item.subdivisionEmployees = !!item.subdivisionEmployees
											? JSON.parse('[' + item.subdivisionEmployees + ']')
											: null;
									});
								});

								delete values.parties;

								values.stages = stagesArray;
								values.routeType = routeName;
								values.stagesCount = stagesArray.length;
								values.orderOfAgreement = getOrderOfAgreement(stagesArray);

								var url = objectData
									? edi.utils.formatString(
											edi.rest.services.ORG_PROFILE.ROUTES.PUT,
											{
												id: objectData.get('id')
											},
											false
									  )
									: edi.rest.services.ORG_PROFILE.ROUTES.POST;
								var success = function () {
									edi.events.routes.fireEvent('change');
									modal.setLoading(false);
									modal.close();
									stages.onDestroy();
								};
								var failure = function (data) {
									edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
									modal.setLoading(false);
									stages.onDestroy();
								};

								edi.rest.sendRequest(
									url,
									objectData ? 'PUT' : 'POST',
									Ext.encode(values),
									success,
									failure
								);
							}
						}
					}),
					createButton({
						cls: [BUTTON_CLS.secondary],
						text: edi.i18n.getMessage('ediweb.cancel.btn'),
						handler: function () {
							if (formPanel.isDirty()) {
								confirm(
									isEdit ? 'cancel.edit.routes.title' : 'cancel.create.routes.title',
									isEdit ? 'cancel.edit.routes' : 'cancel.create.routes',
									() => modal.close()
								);
							} else {
								modal.close();
							}
						}
					})
				]
			});

			modal.show();
			modal.setLoading(true);

			getSubdivisionsArray()
				.then((arr) => {
					subdivisionsArray = arr;
					addModalContent();
					modal.center();
				})
				.catch(function (err) {
					console.error(err);
					edi.rest.getErrorHandler('error.getting.data', () => modal.close())(err);
				});
		},
		delete: function (ids, moduleData, callback, createList = null, exceptionCreateList = null) {
			edi.methods.gridActionProcessWithToasts({
				moduleData,
				isManyDelete: createList && exceptionCreateList,
				groupedErrorTypes: ['controller.route.delete.error.has.related.approvals'],
				ids,
				processUrl: edi.rest.services.ORG_PROFILE.ROUTES.DELETE,
				processMethod: 'DELETE',
				confirmTitle: 'delete.routes.title',
				confirmQuestion: 'delete.routes',
				confirmExceptionsTitle: '',
				errorToastTitle: 'controller.route.error.title',
				countSuccessToastTitle: 'toast.count.routes.deleted',
				confirmAction: true,
				createList,
				exceptionCreateList,
				successCallback: function () {
					edi.events.routes.fireEvent('change');
				}
			});
		}
	}
};

const convertTreeArrayToFlat = function (array, childrenKey) {
	const flat = (arr, childrenKey) =>
		Array.isArray(arr) ? arr.flatMap((n) => [n, ...flat(n[childrenKey], childrenKey)]) : [];

	return flat(array, childrenKey).map(({ childern, ...n }) => n);
};

const getValueByTypeError = function (errorData) {
	var typeErrorWithDocNumber = [
		'controller.employee.delete.error.route.has.related.approvals',
		'controller.route.delete.error.has.related.approvals',
		'status',
		'controller.subdivision.delete.error.route.has.related.approvals'
	];
	if (typeErrorWithDocNumber.some((it) => it === errorData.typeError)) {
		return errorData.additionalData[0];
	}
	return errorData.additionalData;
};

/**
 * Gets subdivisions array from backend
 * @return {Promise<Object[]>}
 */
const getSubdivisionsArray = () =>
	new Promise((resolve, reject) => {
		let success = function (resp) {
			if (Array.isArray(resp.items)) {
				resolve(convertTreeArrayToFlat(resp.items, 'children'));
			} else {
				reject(resp);
			}
		};

		edi.rest.sendRequest(edi.rest.services.ORG_PROFILE.SUBDIVISIONS.GET, 'GET', null, success, reject);
	});

const getProductsType = function (data) {
	const groupedByProductType = getProductsTypeName(data);

	return Object.keys(groupedByProductType);
};
const getProductsTypeName = function (data) {
	const groupBy = (array, property) =>
		array.reduce(
			(grouped, element) => ({
				...grouped,
				[element[property]]: [...(grouped[element[property]] || []), element]
			}),
			{}
		);
	return groupBy(data, 'productType');
};

const createProductTpl = function (name) {
	return '<div class="edi-ediweb-project-item item">' + '<div class="logo ' + name + '"></div>' + '</div>';
};

export { methods, convertTreeArrayToFlat, getValueByTypeError, getProductsType, createProductTpl };
