import {
	SCHFDOP_BussinessProcess,
	documentFunctionsComboItems,
	getAuthorityAreasByBpName
} from '@Fns/modules/documents/EDI_FNS_UPD/methods';

SCHFDOP_BussinessProcess.push(edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP);

const allItems = documentFunctionsComboItems().filter((item) => {
	return (
		item !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14 &&
		item !== edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14
	);
});

documentFunctionsComboItems = () => allItems;

const oldGetAuthorityAreasByBpName = getAuthorityAreasByBpName;

getAuthorityAreasByBpName = function (bpName) {
	let areaIds = oldGetAuthorityAreasByBpName(bpName);
	switch (bpName) {
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DOP_DECREE_14_WITHOUT_OPERATOR: {
			areaIds = [0, 1, 2, 3, 4, 5, 6];
			break;
		}
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DECREE_14_WITHOUT_OPERATOR: {
			areaIds = [0, 4, 5, 6];
			break;
		}
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_DECREE_14_WITHOUT_OPERATOR: {
			areaIds = [1, 2, 3, 4, 5, 6];
			break;
		}
		default:
			break;
	}
	return areaIds;
};
