import { PoaFromChain, Poas } from '../difinitions';

export interface ConvertChainPoaDataProps {
	poas: Poas;
}
export interface ConvertChainPoaDataResult {
	poaList: PoaFromChain[];
	poaWithErrors: PoaFromChain[];
	poaWithoutErrors: PoaFromChain[];
}
export const convertChainPoaData = function ({ poas }: ConvertChainPoaDataProps): ConvertChainPoaDataResult {
	const poaList = Object.values(poas);
	const { poaWithErrors, poaWithoutErrors } = poaList.reduce(
		(result, poa) => {
			if (poa.state === edi.constants.STATE.ERROR) {
				result.poaWithErrors.push(poa);
			} else {
				result.poaWithoutErrors.push(poa);
			}
			return result;
		},
		{
			poaWithErrors: [] as PoaFromChain[],
			poaWithoutErrors: [] as PoaFromChain[]
		}
	);

	return { poaList, poaWithErrors, poaWithoutErrors };
};
