import { createFieldBlock } from '@UIkit/components/panels';
import { createComboLogo } from '@Ediweb/components/ComboLogo/ComboLogo';
import { createCombo } from '@UIkit/components/fields';
import { PTYPE_COMBO_CHECKED_ITEM, PTYPE_COMBO_ITEMS_DISABLEABLE } from '@UIkit/plugins';
import { getDCRoleDependence } from '@Ediweb/methods';

const getProductsAndRoles = function (invitationData, path, isEdit, tours = {}) {
	let receiverRoleInput, senderRoleInput;

	var storeData = [
		{
			id: 'participant_edo',
			product: 'ediweb',
			name: edi.i18n.getMessage('ediweb.invitations.participant_edo'),
			disabled: false
		},
		{
			id: 'client_by_te',
			product: 'dropcat',
			name: edi.i18n.getMessage('ediweb.invitations.client_by_te'),
			disabled: false
		},
		{
			id: 'forwarder',
			product: 'dropcat',
			name: edi.i18n.getMessage('ediweb.invitations.forwarder'),
			disabled: false
		},
		{
			id: 'person_from',
			product: 'dropcat',
			name: edi.i18n.getMessage('ediweb.invitations.person_from'),
			disabled: false
		},
		{
			id: 'consignee',
			product: 'dropcat',
			name: edi.i18n.getMessage('ediweb.invitations.consignee'),
			disabled: false
		},
		{
			id: 'carrier',
			product: 'dropcat',
			name: edi.i18n.getMessage('ediweb.invitations.carrier'),
			disabled: false
		},
		{
			id: 'consignor',
			product: 'dropcat',
			name: edi.i18n.getMessage('ediweb.invitations.consignor'),
			disabled: false
		},
		{
			id: 'medical_org',
			product: 'dropcat',
			name: edi.i18n.getMessage('ediweb.invitations.medical_org'),
			disabled: false
		}
	];

	var createStoreByProduct = function (role) {
		var filteredByProductStoreData = storeData.filter(function (item) {
			return item.product === role;
		});

		return edi.stores.createInlineStore(filteredByProductStoreData, 'EW_INVITATIONS_ROLE');
	};

	let product = createFieldBlock({
		cls: tours.product,
		title: edi.i18n.getMessage('invitations.column.product', null, null, null),
		items: [
			createComboLogo({
				name: 'roleModel.productRole',
				store: edi.stores.initProductList(),
				anyMatch: true,
				displayField: 'label',
				valueField: 'name',
				value: isEdit ? edi.utils.getObjectProperty(invitationData, `${path}.roleModel.productRole`) : null,
				allowBlank: false,
				listeners: {
					change: function (comboBox, newValue, oldValue) {
						senderRoleInput.bindStore(createStoreByProduct(newValue));
						receiverRoleInput.bindStore(createStoreByProduct(newValue));

						if (newValue === 'ediweb') {
							senderRoleInput.setValue('participant_edo');
							receiverRoleInput.setValue('participant_edo');
						} else {
							senderRoleInput.setValue(null);
							receiverRoleInput.setValue(null);
						}
						senderRoleInput.setReadOnly(newValue === 'ediweb');
						receiverRoleInput.setReadOnly(newValue === 'ediweb');
					},
					render: function (comboBox) {
						var newValue = comboBox.getValue();
						comboBox.inputEl.addCls(newValue);
						senderRoleInput.setReadOnly(newValue === 'ediweb');
						receiverRoleInput.setReadOnly(newValue === 'ediweb');
					}
				}
			})
		]
	});

	let dependenceRoles = function (field, values) {
		values = values || [];

		if (Array.isArray(values)) {
			let dependenceIds = getDCRoleDependence(values);

			let newValue = values.filter(function (val) {
				return !dependenceIds.includes(val);
			});
			field.setValue(newValue);

			field.getStore().each(function (record) {
				record.set(
					'disabled',
					dependenceIds.some((it) => it === record.get('id'))
				);
			});
		}
	};

	let senderRole = createFieldBlock({
		title: edi.i18n.getMessage('ediweb.invitations.your.role'),
		items: [
			(senderRoleInput = createCombo({
				name: 'roleModel.senderRoles',
				store: isEdit
					? createStoreByProduct(edi.utils.getObjectProperty(invitationData, `${path}.roleModel.productRole`))
					: null,
				anyMatch: false,
				forceSelection: true,
				multiSelect: true,
				value: isEdit ? edi.utils.getObjectProperty(invitationData, `${path}.roleModel.senderRoles`) : null,
				allowBlank: false,
				plugins: [PTYPE_COMBO_CHECKED_ITEM, PTYPE_COMBO_ITEMS_DISABLEABLE],
				listeners: {
					change: dependenceRoles,
					afterrender: function (comp) {
						comp.fireEvent('change', this, comp.getValue());
					}
				}
			}))
		]
	});

	let receiverRole = createFieldBlock({
		title: edi.i18n.getMessage('ediweb.invitations.receiverRole'),
		items: [
			(receiverRoleInput = createCombo({
				name: 'roleModel.receiverRoles',
				store: isEdit
					? createStoreByProduct(edi.utils.getObjectProperty(invitationData, `${path}.roleModel.productRole`))
					: null,
				anyMatch: false,
				forceSelection: true,
				multiSelect: true,
				value: isEdit ? edi.utils.getObjectProperty(invitationData, `${path}.roleModel.receiverRoles`) : null,
				allowBlank: false,
				plugins: [PTYPE_COMBO_CHECKED_ITEM, PTYPE_COMBO_ITEMS_DISABLEABLE],
				listeners: {
					change: dependenceRoles,
					afterrender: function (comp) {
						comp.fireEvent('change', this, comp.getValue());
					}
				}
			}))
		]
	});

	return [product, senderRole, receiverRole];
};

export { getProductsAndRoles };
