import './scss/invitations.person.scss';

import { createAddModulePanel } from '@Components/panels';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProxyConfig } from '@Components/storeComponents';
import { confirm } from '@Ediweb/components/dialogs.js';
import { tourEvents, tours } from '@Ediweb/components/tour';
import { createTextField, createCombo, createLabel } from '@UIkit/components/fields';

import {
	createFieldBlock,
	createForm,
	createPanel,
	createContainer,
	createFormContainer
} from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createOrgAutocomplete } from '@Ediweb/components/OrgAutocomplete/OrgAutocomplete';

import { PTYPE_COMBO_PANEL_INSIDE_LIST } from '@Ediweb/plugins/ComboPanelInsideList/ComboPanelInsideList';
import { getProductsAndRoles } from '@Ediweb/modules/INVITATIONS/methods';

const INVITATION_PERSON_MODULE_NAME = 'invitations.person';
const INVITATION_PERSON_TOUR_TARGET_1 = 'invitations-person-tour-target-1';
const INVITATION_PERSON_TOUR_TARGET_2 = 'invitations-person-tour-target-2';

Ext.namespace('edi.modules');
edi.modules[INVITATION_PERSON_MODULE_NAME] = function () {
	let moduleData,
		id,
		isEdit = false,
		form,
		receiverSelector,
		expectedReceiver,
		manualInputCompanyPanel,
		displayCompanyBlock,
		companyContainer,
		manualInputBlockFormContainer,
		senderRoleInput,
		receiverRoleInput,
		alertError,
		documentData,
		kpp,
		name,
		inn,
		checkTimer,
		notFoundLabel;
	let commentTemplateText = '';
	let seldonServiceEnabled = false;
	let fnsCheckServiceEnabled = false;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;

		documentData = moduleData.initData.data;
		id = documentData?.id;

		setIsEdit(!!id);

		fetchCommentTemplate().then(function () {
			fetchCheckServiceAvailability().then(function () {
				renderData(initCallBack);
			});
		});

		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Fetches Seldon and FNS_check services availability
	 * @returns {Promise<void>}
	 */
	let fetchCheckServiceAvailability = function () {
		return new Promise((resolve) => {
			let finish = function (resp) {
				seldonServiceEnabled = !!resp?.data?.seldonEnabled;
				fnsCheckServiceEnabled = !!resp?.data?.fnsCheckEnabled;
				resolve();
			};
			edi.rest.sendRequest(
				edi.rest.services.INVITATIONS.CHECK_SERVICE_AVAILABLE.GET,
				'GET',
				null,
				finish,
				finish,
				null,
				{ suppressDefaultError: true }
			);
		});
	};

	/**
	 * Fetches template text for comment from user profile
	 * @returns {Promise<void>}
	 */
	let fetchCommentTemplate = function () {
		return new Promise((resolve) => {
			if (isEdit) {
				resolve();
				return;
			}

			let finish = function (resp) {
				commentTemplateText = resp?.data?.text || '';
				resolve();
			};
			edi.rest.sendRequest(edi.rest.services.INVITATIONS.TEMPLATE.GET, 'GET', null, finish, finish, null, {
				suppressDefaultError: true
			});
		});
	};

	var createAlert = function (config, text) {
		config = 'object' == typeof config ? config : {};

		var label = createLabel({
			typography: 'body-short_02',
			text
		});

		var defaults = {
			cls: 'invitation-alert',
			items: [label]
		};
		Object.assign(config, defaults);

		var block = createContainer(config);
		block.setText = function (text) {
			if (!!text) {
				label.setText(text, false);
			}
		};

		return block;
	};

	/**
	 * Creates despatch advise form
	 * @returns {Object}
	 */
	var createModuleForm = function (documentData) {
		var receiverData = edi.utils.getObjectProperty(documentData, 'invitation.parties.receiver');

		var title = createContainer({
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage('ediweb.invitations.to.exchange')
				})
			]
		});

		alertError = createAlert({
			hidden: true
		});

		expectedReceiver = createOrgAutocomplete(
			{
				name: 'receiverCombo',
				plugins: [
					{
						ptype: PTYPE_COMBO_PANEL_INSIDE_LIST,
						items: [
							createLabel({
								cls: 'text-notfound',
								itemId: 'notfound',
								hidden: true,
								text: edi.i18n.getMessage('ediweb.invitations.org.undefined.on.ediweb')
							}),
							createLabel({
								cls: 'text-send',
								itemId: 'send',
								text: edi.i18n.getMessage('ediweb.invitations.send.to.email'),
								listeners: {
									afterrender: function (cmp) {
										cmp.on({
											click: function () {
												expectedReceiver.collapse();
												// receiverSelector && receiverSelector.reset();

												manualInputCompanyPanel.setVisible(true);
												manualInputBlockFormContainer.setDisabled(false);
												companyContainer.setVisible(false);
												companyContainer.setDisabled(true);
											},
											element: 'el',
											scope: cmp
										});
									}
								}
							})
						]
					}
				],
				hideTrigger: true,
				fieldLabel: edi.i18n.getMessage('ediweb.invitations.enten.org'),
				anyMatch: true,
				forceSelection: false,
				allowManualInput: true,
				listeners: {
					select: function (comp, record) {
						if (record) {
							displayCompanyBlock.setVisible(true);

							var fieldValues = edi.converters.convertOrgToPartie(record.data);
							emailField.setValue(fieldValues.contact_email);
							delete fieldValues.contact_email;
							receiverSelector.setValues(fieldValues);
						} else {
							displayCompanyBlock.setVisible(false);
						}
						expectedReceiver.setVisible(!record);
						expectedReceiver.setDisabled(!!record);
						comp.setVisible(!record);
						comp.setDisabled(!!record);
					},
					beforerender: function (comp) {
						if (isEdit) {
							comp.fireEvent('select', comp, {
								data: {
									name: receiverData.name,
									inn: receiverData.inn,
									kpp: receiverData.kpp,
									iln: receiverData.iln,
									email: receiverData.email
								}
							});

							expectedReceiver.setVisible(!receiverData);
							expectedReceiver.setDisabled(!!receiverData);
						}
					},
					beforeQuery: function (plan) {
						let me = this;
						plan.query = plan.query.trim();
						//при фокусе срабатывает onTriggerClick который дергает doQuery
						if (plan.query.length < me.minChars) {
							//принудительно развернем лист, т.к. expand дергался после doQuery
							//который отменился из-за ограничения по длине
							me.getPicker().refresh();
							me.expand();
							return false;
						}
					}
				},
				minChars: 3,
				queryParam: 'orgNameInnPattern',
				allowBlank: false
			},
			{
				sortOnLoad: false,
				sorters: [],
				autoLoad: false,
				proxy: createProxyConfig({
					type: 'ajax',
					url: edi.rest.services.ORGANIZATIONS.ALL.GET,
					pageParam: undefined,
					startParam: undefined,
					limitParam: undefined
				}),
				model: edi.models.getModel('ORGANIZATIONS'),
				listeners: {
					load: function (store, records) {
						let plugins = expectedReceiver.getPlugins(),
							targetPlugin = plugins.find((p) => p.ptype == 'combo_panel_inside_list');

						let inputValue = expectedReceiver.getValue() || '';
						let storeIsFilteredAndEmpty =
							inputValue.length >= expectedReceiver.minChars && !store.getCount();

						targetPlugin.getItem('notfound').setVisible(storeIsFilteredAndEmpty);
					}
				}
			}
		);

		var createOS = function (selectorConf) {
			Object.assign(selectorConf, {
				useHiddenFields: true,
				disableAutoValidation: true,
				forceChangeFields: {
					bank_id: true,
					bank_corr_acc: true,
					bank_name: true,
					bank_acc: true
				}
			});
			return createOrgSelector(selectorConf);
		};

		var createReceiver = function (data) {
			return createContainer({
				items: [
					(receiverSelector = createOS({
						margin: '8 0 0 0',
						alwaysShowSelect: false,
						allowReset: false,
						isHideDotsMenu: true,
						allowBlank: true,
						fieldValues: data,
						fieldsMap: {
							company_inn: 'parties.receiver.inn',
							company_name: 'parties.receiver.name',
							company_iln: 'parties.receiver.iln',
							company_kpp: 'parties.receiver.kpp'
						},
						createActions: function () {
							const commonProps = {
								cls: [BUTTON_CLS.light, BUTTON_CLS.small]
							};
							const resetBtn = createButton({
								...commonProps,
								glyph: edi.constants.ICONS.CLOSE,
								handler: function () {
									expectedReceiver.setValue(null);
									emailField.setValue(null);
									kpp.setValue(null);
									inn.setValue(null);
									name.setValue(null);
									email.setValue(null);

									expectedReceiver.setVisible(true);
									expectedReceiver.setDisabled(false);
									displayCompanyBlock.setVisible(false);
								}
							});
							const editBtn = createButton({
								...commonProps,
								glyph: edi.constants.ICONS.EDIT,
								handler: function () {
									manualInputCompanyPanel.setVisible(true);
									manualInputBlockFormContainer.setDisabled(false);
									companyContainer.setVisible(false);
									companyContainer.setDisabled(true);

									let values = receiverSelector.getValuesByMap();
									if (!!emailField.getValue() && emailField.isValid()) {
										values['parties.receiver.email'] = emailField.getValue();
									}
									manualInputBlockFormContainer.setDataValues(values);
								}
							});
							return createContainer({
								layout: {
									type: 'hbox',
									align: 'stretch',
									pack: 'center'
								},
								cls: ['invitation-orgselector-buttons'],
								items: [resetBtn, editBtn]
							});
						}
					}))
				]
			});
		};

		/**
		 * Конвектируем значение полей ручного ввода в согласно карте
		 * @param {obj} values
		 */
		let convertValueByFieldMap = function (values) {
			let obj = {};
			Object.entries(receiverSelector.fieldsMap).forEach(([k, v]) => {
				if (!!values[v]) {
					obj[k] = values[v];
				}
			});
			edi.utils.clearEmptyValues(obj);
			return obj;
		};

		displayCompanyBlock = createPanel({
			hidden: true,
			items: [createReceiver()]
		});

		let emailField = createTextField({
			fieldLabel: edi.i18n.getMessage('email'),
			invalidText: edi.i18n.getMessage('invalid.email.format'),
			regex: edi.constants.VALIDATORS.EMAIL,
			allowBlank: false,
			value: isEdit ? receiverData.email : '',
			name: 'parties.receiver.email'
		});

		companyContainer = createFormContainer({
			area: [[6, 6]],
			items: [
				createFieldBlock({
					title: edi.i18n.getMessage('document.receiver'),
					items: [expectedReceiver, displayCompanyBlock]
				}),
				createFieldBlock({
					title: edi.i18n.getMessage('ediweb.invitations.receiver_email'),
					items: [emailField]
				})
			]
		});

		var manualInputTitle = createContainer({
			padding: '0 0 24 0',
			items: [
				createLabel({
					typography: 'body-short_02',
					cls: 'invitation-receiver-title',
					text: edi.i18n.getMessage('ediweb.invitations.send.to.email')
				}),
				createButton({
					cls: [BUTTON_CLS.light, BUTTON_CLS.small, 'invitation-receiver-btn-close'],
					glyph: edi.constants.ICONS.CLOSE,
					handler: function () {
						manualInputCompanyPanel.setVisible(false);
						manualInputBlockFormContainer.setDisabled(true);

						companyContainer.setVisible(true);
						companyContainer.setDisabled(false);

						let values = manualInputBlockFormContainer.getDataValues();

						if (receiverSelector && receiverSelector.setValues) {
							receiverSelector.setValues(
								convertValueByFieldMap(Object.assign(receiverSelector.getValuesByMap(), values))
							);
						}

						emailField.setValue(values['parties.receiver.email']);
						manualInputBlockFormContainer.resetValues();
					}
				})
			]
		});

		let checkOrgByInnKpp = function (innChanged, kppChanged) {
			let innValue = inn.getValue() || '';
			let kppValue = kpp.getValue() || '';

			//для ЮЛ (10 символов) КПП обязателен, а для ИП (12 символов) - нет
			kpp.allowBlank = innValue.length >= 12;
			kpp.isValid();
			kpp.renderActiveError(); //что бы скрывать\показывать "обязательно для заполнения"
			name.isValid();

			//когда сервисы поиска и проверки отключены, то просто разрешим юзеру заполнить поля вручную
			if (!seldonServiceEnabled && !fnsCheckServiceEnabled) {
				kpp.setReadOnly(false);
				name.setReadOnly(false);
			}
			//если сервис поиска доступен, то будем искать по инн, что б юзер выбрал из списка
			else if (seldonServiceEnabled && innChanged && !kppChanged) {
				if (!inn.isValid()) {
					kpp.setValue(null);
					name.setValue(null);
				}

				let searchByInn = function () {
					let loadStoreData = function (items) {
						inn.getStore().loadData(items || []);
						if (manualInputCompanyPanel.isVisible()) {
							inn.collapse();
							inn.blur();
							inn.getPicker().refresh();
							inn.focus();
							inn.expand();
						}
					};

					let fail = function (resp) {
						const typeError = resp?.typeError;
						if (
							typeError === 'seldon.client.organization.service.error' ||
							typeError === 'seldon.client.organization.service.disabled' ||
							typeError === 'inn.check.service.not.available.error'
						) {
							//разрешим ручной ввод, если сервис поиска организаций не работает
							//стираем все из комбика (орги и ошибки) и восстанавливаем введенное руками значение
							loadStoreData([]);
							inn.setValue(innValue);
							manualInputBlockFormContainer.setManualInput(true);
						} else {
							//при обычных ошибках (не нашлось) покажем что не нашли ничего
							loadStoreData([
								{
									errorText: edi.i18n.getMessage(typeError)
								}
							]);
							manualInputBlockFormContainer.setManualInput(false);
						}
					};

					let success = function (resp) {
						let newStoreItems = (resp?.items || [])
							.filter((it) => it.name || it.inn)
							.map((it) => ({
								id: it.inn + '_' + it.name,
								name: it.name,
								inn: it.inn,
								kpp: it.kpp,
								isIP: it.type !== 'company',
								isUL: it.type === 'company'
							}));

						if (newStoreItems.length > 0) {
							loadStoreData(newStoreItems);
						} else {
							loadStoreData([
								{
									errorText: edi.i18n.getMessage(typeError)
								}
							]);
						}

						manualInputBlockFormContainer.setManualInput(false);
					};

					let url = edi.utils.formatString(edi.rest.services.INVITATIONS.BY_INN.GET, {
						inn: innValue
					});
					edi.rest.sendRequest(url, 'GET', undefined, success, fail);
				};

				if (inn.isValid() && inn.getValue()?.length > 0) {
					clearTimeout(checkTimer);
					checkTimer = setTimeout(searchByInn, 1000);
				} else {
					manualInputBlockFormContainer.setManualInput(false);
				}
			}
			//когда поиск недоступен, то хотя бы проверим, что это существующая орг
			//для ЮЛ инн+кпп, для ИП просто инн
			else if (!seldonServiceEnabled && fnsCheckServiceEnabled) {
				manualInputBlockFormContainer.setManualInput(true);

				let ulChanged = inn.allowBlank === false && kpp.allowBlank === false && (innChanged || kppChanged);
				let ipChanged = inn.allowBlank === false && kpp.allowBlank === true && innChanged;
				if (ulChanged || ipChanged) {
					notFoundLabel.setVisible(false);
				}

				let queryParams =
					innValue.length === 10 && kppValue.length === 9 && (innChanged || kppChanged)
						? { inn: innValue, kpp: kppValue } //для ЮЛ
						: innValue.length === 12 && innChanged
						? { inn: innValue } //для ИП
						: null;
				if (queryParams) {
					let fail = function () {
						notFoundLabel.setVisible(true);
					};

					let success = function (resp) {
						if (resp.data?.state === 2) {
							notFoundLabel.setVisible(false);
						} else {
							fail();
						}
					};

					let url = edi.utils.compileURL(edi.rest.services.INVITATIONS.FNS_CHECK.GET, queryParams);
					edi.rest.sendRequest(url, 'GET', undefined, success, fail, null, { suppressDefaultError: true });
				}
			}
		};

		const onOrgSelect = function (field, record) {
			let kppValue = null;
			let nameValue = null;

			if (!!record) {
				const errorText = record.get('errorText') ?? null;
				if (errorText) {
					field.setValue(null);
					return;
				}
				kppValue = record.get('kpp') ?? null;
				nameValue = record.get('name') ?? null;
				const isUL = record.data?.isUL ?? false;
				kpp.clearInvalid();
				kpp.allowBlank = !isUL;
			}
			if (kppValue) {
				kpp.setValue(kppValue);
			}
			kpp.isValid();

			if (nameValue) {
				name.setValue(nameValue);
			}
			name.isValid();
		};

		inn = createOrgAutocomplete(
			{
				fieldLabel: edi.i18n.getMessage('company.inn.short'),
				maskRe: /\d/i,
				regex: edi.constants.VALIDATORS.INN,
				name: 'parties.receiver.inn',
				multiSelect: false,
				allowBlank: false,
				displayField: 'inn',
				valueField: 'inn',
				itemField: 'name',
				forceSelection: false,
				allowManualInput: true,
				disabled: true,
				listConfig: {
					//это нужно что бы уже выбранный в листе рекорд (ввели вручную инн) отрабатывал селект
					isSelected: function () {
						return false;
					},
					listeners: {
						itemclick: function (comp, record, item) {
							onOrgSelect(inn, record);
						}
					}
				},
				listeners: {
					afterrender: function () {
						const me = this,
							picker = me.getPicker();

						picker.on('viewlist', function (__self, record, itemEl) {
							const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

							var tpl = new Ext.XTemplate('<tpl if="errorText">', '<span>{errorText}</span>', '</tpl>');
							tpl.$comp = me;
							var el = tpl.append(wrapEl, record.getData());
						});
					},
					change: () => checkOrgByInnKpp(true, false),
					select: onOrgSelect
				}
			},
			{
				sortOnLoad: false,
				sorters: [],
				autoLoad: false,
				proxy: createProxyConfig({
					type: 'memory',
					data: null
				}),
				model: edi.models.getModel('ORGANIZATIONS')
			}
		);
		if (isEdit && receiverData && receiverData.inn) {
			inn.setRawValue(receiverData.inn);
		}

		kpp = createTextField({
			fieldLabel: edi.i18n.getMessage('company.kpp.short'),
			regex: edi.constants.VALIDATORS.KPP,
			maskRe: /\d/i,
			readOnly: true,
			name: 'parties.receiver.kpp',
			value: isEdit ? receiverData.kpp : '',
			disabled: true,
			allowBlank: isEdit ? receiverData.inn?.length !== 10 : false,
			listeners: {
				change: () => checkOrgByInnKpp(false, true)
			}
		});

		name = createTextField({
			fieldLabel: edi.i18n.getMessage('column.name'),
			value: isEdit ? receiverData.name : '',
			readOnly: true,
			name: 'parties.receiver.name',
			allowBlank: false,
			disabled: true,
			listeners: {
				change: () => checkOrgByInnKpp(false, false)
			}
		});

		var email = createTextField({
			padding: '0 0 8 0',
			fieldLabel: edi.i18n.getMessage('email'),
			invalidText: edi.i18n.getMessage('invalid.email.format'),
			regex: edi.constants.VALIDATORS.EMAIL,
			allowBlank: false,
			value: isEdit ? receiverData.email : '',
			disabled: true,
			name: 'parties.receiver.email'
		});

		notFoundLabel = createLabel({
			color: '--color-error',
			text: edi.i18n.getMessage('controller.onboarding.token.fns.check.error'),
			hidden: true
		});

		let fields = [inn, kpp, name, email, notFoundLabel];

		var manualInputBlock = createFieldBlock({
			title: edi.i18n.getMessage('document.receiver'),
			listeners: {
				afterrender: checkOrgByInnKpp
			},
			items: [
				(manualInputBlockFormContainer = createFormContainer({
					gap: 24,
					area: [
						[4, 4, 4],
						[4, 8]
					],
					items: fields,
					setDataValues(data) {
						fields.forEach((f) => {
							if (!!data[f.name]) {
								f.setValue(data[f.name]);
								f.validate();
							}
						});
					},
					getDataValues() {
						let obj = {};
						fields.forEach((f) => {
							obj[f.name] = f.value;
						});
						edi.utils.clearEmptyValues(obj);
						return obj;
					},
					resetValues() {
						fields.forEach((f) => {
							if (!!f.value) {
								f.setValue(null);
							}
						});
					},
					setManualInput(allowManualInput) {
						kpp.setReadOnly(!allowManualInput);
						name.setReadOnly(!allowManualInput);
					},
					setDisabled(disabled) {
						fields.forEach((f) => {
							if (!!f.name) {
								f.setDisabled(disabled);
								f.isValid();
							}
						});
					}
				}))
			]
		});

		manualInputCompanyPanel = createPanel({
			cls: 'invitations-receiver-panel',
			hidden: true,
			bodyPadding: 24,
			items: [manualInputTitle, manualInputBlock]
		});

		let [product, senderRole, receiverRole] = getProductsAndRoles(documentData, 'invitation', isEdit, {
			product: INVITATION_PERSON_TOUR_TARGET_1
		});

		var comment = createFieldBlock({
			title: edi.i18n.getMessage('ediweb.invitations.comment'),
			items: [
				createTextField({
					name: 'comments.invitationText',
					maxLength: 255,
					isTextarea: true,
					height: 80,
					value: isEdit
						? edi.utils.getObjectProperty(documentData, 'invitation.comments.invitationText')
						: commentTemplateText
				})
			]
		});

		var createAndSendButton = createButton({
			cls: [BUTTON_CLS.primary],
			text: edi.i18n.getMessage(isEdit ? 'form.btn.save.and.send' : 'form.btn.send'),
			glyph: edi.constants.ICONS.SEND,
			handler: function () {
				if (!edi.utils.setFocusToDocumentsWithGrid(form) || notFoundLabel?.isVisible()) {
					return;
				}

				var values = edi.utils.collectFormValues(form);
				var send = function (invId, seccess, error) {
					var failure = function (data) {
						moduleData.tab.setLoading(false);
						if (
							data.typeError === 'controller.invitation.duplicate.error' ||
							data.typeError === 'controller.invitation.partnership.existed.error'
						) {
							let productStore = edi.stores.initProductList();
							let productId = edi.utils.getObjectProperty(values, 'roleModel.productRole');
							let product = productStore.findRecord('name', productId);
							let productName = product ? product.get('label') : productId;

							let textError = edi.i18n.getMessage(data.typeError, [
								edi.utils.getObjectProperty(values, 'receiver.identification.legalEntity.name'),
								productName
							]);

							alertError.setText(textError);
							alertError.setVisible(true);
							alertError.focus();
						} else if (data.typeError === 'controller.invitation.receiver.org.is.deleted') {
							let textError = edi.i18n.getMessage('ediweb.error.invitation.not.send.receiver.deleted');

							alertError.setText(textError);
							alertError.setVisible(true);
							alertError.focus();
						} else {
							edi.core.logMessage(
								'Error ' + (isEdit ? 'saving ' : 'creating ') + edi.constants.DOCUMENT_TYPES.INVITATION,
								'warn'
							);
							edi.core.showError(
								edi.utils.formatComplexServerError(
									data,
									edi.utils.formatString(
										edi.i18n.getMessage(
											isEdit ? 'documents.edit.failure' : 'documents.create.failure'
										),
										{
											type: edi.i18n.getMessage(
												'documents.doctype.' + edi.constants.DOCUMENT_TYPES.INVITATION
											)
										}
									)
								)
							);
						}
					};

					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.INVITATIONS.SENT.PUT, {
							id: invId
						}),
						'PUT',
						Ext.encode({ doctime: +new Date() }),
						function () {
							'function' === typeof seccess ? seccess() : null;
						},
						function (data) {
							failure(data);
							'function' === typeof error ? error() : null;
						}
					);
					edi.events.invitations.fireEvent('activatetab', 'outgoing');
				};
				save(values, send);
			}
		});

		var cancelButton = createButton({
			cls: [BUTTON_CLS.secondary],
			text: edi.i18n.getMessage('ediweb.cancel.btn'),
			handler: function () {
				if (!moduleData || !moduleData.isChanged) {
					edi.modulesHandler.removeModule(moduleData);
					return;
				}
				confirm(
					moduleData.closeTitle,
					moduleData.closeText,
					() => {
						moduleData.isChanged = false;
						edi.modulesHandler.removeModule(moduleData);
					},
					undefined,
					undefined,
					undefined,
					{
						width: 500
					}
				);
			}
		});

		var createSaveButton = createButton({
			cls: [BUTTON_CLS.secondary],
			text: edi.i18n.getMessage('ediweb.form.btn.save.to.draft'),
			handler: function () {
				if (!edi.utils.setFocusToDocumentsWithGrid(form) || notFoundLabel?.isVisible()) {
					return;
				}

				var values = edi.utils.collectFormValues(form);
				save(values);
				edi.events.invitations.fireEvent('activatetab', 'draft');
			}
		});

		const rolesContainer = createContainer({
			cls: INVITATION_PERSON_TOUR_TARGET_2,
			layout: {
				type: 'grid',
				gap: [0, 24],
				area: [[6, 6]]
			},
			items: [senderRole, receiverRole]
		});

		form = createForm({
			bodyPadding: '24',
			autoScroll: true,
			items: createFormContainer({
				width: 1200,
				gap: [16, 24],
				area: [12, 12, 12, 12, 6, 6, 6],
				items: [title, alertError, manualInputCompanyPanel, companyContainer, product, rolesContainer, comment]
			}),
			buttonAlign: 'left',
			buttons: [createAndSendButton, cancelButton, createSaveButton]
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);
		return form;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var hasInvalid = form.hasInvalidField();
		return !hasInvalid;
	};

	/**
	 * Saves invitation
	 * @param	{Object}	formValues
	 * @param	{Function}	callback
	 */
	var save = function (formValues, callback) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = formValues || {};
		delete values.receiverCombo;
		values.invitationDate = Ext.Date.format(new Date(), 'Y-m-d');
		values.invitationType = edi.constants.INVITATION_TYPE.REQUEST;
		edi.utils.clearEmptyValues(values);
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.INVITATION
		);

		//если перед сохранением бэк смог найти организацию по данным из ручного ввода
		//то поставим их, как будто сразу выбрали нормальную организацию
		var saveDoc = function () {
			var toOrgId;
			if (isEdit) {
				toOrgId = edi.utils.getObjectProperty(documentData, 'ediDocument.toOrg.id');
			} else {
				toOrgId = edi.core.getUserOrgID();
			}
			var docData = {
				data: Ext.encode(values),
				toOrgId: toOrgId,
				docType: edi.constants.DOCUMENT_TYPES.INVITATION,
				ADDITIONS_VARS: {
					STANDALONE: 'true'
				}
			};

			if (isEdit) {
				if (toOrgId !== edi.core.getUserOrgID()) {
					docData['STANDALONE'] = 'true';
					delete docData['ADDITIONS_VARS'];

					docData = {
						UPDATE: true,
						UPDATE_PARAMS: docData
					};
				} else {
					delete docData['ADDITIONS_VARS'];

					docData = {
						UPDATE: true,
						STANDALONE: 'true',
						UPDATE_PARAMS: docData
					};
				}
			}
			var stringified = Ext.encode(docData);

			var url = edi.rest.services.DOCUMENTS.POST;
			if (isEdit) {
				url = edi.utils.formatString(edi.rest.services.DOCUMENTS.PUT, { documentId: id });
			}
			edi.rest.sendRequest(
				url,
				isEdit ? 'PUT' : 'POST',
				stringified,
				function (resp) {
					if ('function' === typeof callback) {
						callback(
							isEdit ? id : resp.data.id,
							function () {
								moduleData.isChanged = false;
								edi.events.invitations.fireEvent('change');
								edi.modulesHandler.removeModule(moduleData);
								moduleData.tab.setLoading(false);
							},
							function () {
								if (!isEdit) {
									setIsEdit(true);
									id = resp.data.id;
									form.layout.redoLayout();
								}
							}
						);
					} else {
						moduleData.isChanged = false;
						edi.events.invitations.fireEvent('change');
						edi.modulesHandler.removeModule(moduleData);
						moduleData.tab.setLoading(false);
					}
				},
				function (data) {
					if (
						data.typeError === 'controller.invitation.duplicate.error' ||
						data.typeError === 'controller.invitation.partnership.existed.error'
					) {
						let productStore = edi.stores.initProductList();
						let productId = edi.utils.getObjectProperty(values, 'roleModel.productRole');
						let product = productStore.findRecord('name', productId);
						let productName = product ? product.get('label') : productId;

						var textError = edi.i18n.getMessage(data.typeError, [
							edi.utils.getObjectProperty(values, 'receiver.identification.legalEntity.name'),
							productName
						]);

						alertError.setText(textError);
						alertError.setVisible(true);
						alertError.focus();
						moduleData.tab.setLoading(false);
					} else {
						failure(data);
					}
				}
			);
		};
		saveDoc();
	};

	var setIsEdit = function (value) {
		isEdit = value;
		moduleData.closeText = 'entered.data.not.saved';
		moduleData.closeTitle = isEdit ? 'cancel.edit.invitation' : 'cancel.new.invitation';
	};

	/**
	 * Creates action pane above the data panel
	 */

	/**
	 * Renders module
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createAddModulePanel();
		modulePanel.add(createModuleForm(isEdit ? documentData : {}));
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
			if (edi.core.getExtraData('user.personalInvitationHints') !== 'true') {
				if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
					tourEvents.fireEvent('start_tour', INVITATION_PERSON_MODULE_NAME, moduleData);
				}
			}
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};

tours[INVITATION_PERSON_MODULE_NAME] = {
	getName: () => INVITATION_PERSON_MODULE_NAME,
	createSteps: () => [
		{
			getTitle: () => edi.i18n.getMessage('invitations.person.tour.step.1.title'),
			getContent: () => edi.i18n.getMessage('invitations.person.tour.step.1.content'),
			getTargetEl: (tour) => (tour?.queryRoot || document).querySelector(`.${INVITATION_PERSON_TOUR_TARGET_1}`),
			position: 'right'
		},
		{
			getTitle: () => edi.i18n.getMessage('invitations.person.tour.step.2.title'),
			getContent: () => edi.i18n.getMessage('invitations.person.tour.step.2.content'),
			getTargetEl: (tour) => (tour?.queryRoot || document).querySelector(`.${INVITATION_PERSON_TOUR_TARGET_2}`),
			position: 'right'
		}
	],
	onTourClosed: function () {
		const tour = this;
		//поставим флаг что юзер прошел все страницы тура
		if (tour.currentStepNum === tour.steps.length - 1) {
			edi.core.setExtraData('user.personalInvitationHints', 'true');
		}
	}
};
