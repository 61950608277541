const checkBeforeSignRules = function (type, state) {
	let isAllow = function (rule) {
		let typeIsOk =
			rule.DOCUMENT_TYPES === undefined ||
			(typeof rule.DOCUMENT_TYPES === 'string' && rule.DOCUMENT_TYPES === type) ||
			(Array.isArray(rule.DOCUMENT_TYPES) && rule.DOCUMENT_TYPES.some((it) => it === type));

		let stateIsOk =
			rule.STATES === undefined ||
			(typeof rule.STATES === 'string' && rule.STATES === state) ||
			(Array.isArray(rule.STATES) && rule.STATES.some((it) => it === state));

		return typeIsOk && stateIsOk;
	};

	let rules = edi.constants.USE_BEFORE_SIGN_RULES;
	if (Array.isArray(rules)) {
		return rules.some(isAllow);
	} else if ('object' == typeof rules) {
		return isAllow(rules);
	} else {
		return true;
	}
};

export { checkBeforeSignRules };
