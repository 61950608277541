const applicationAPI = {
	GET: edi.rest.prfx.c + 'certificate',
	DEACTIVATE: {
		PUT: edi.rest.prfx.c + 'certificates/deactivate/{id}'
	},
	APPLICATION_TO_FNS: {
		GET: edi.rest.prfx.c + 'application-to-fns'
	},
	TEMPLATES_AND_STATUSES: {
		GET: edi.rest.prfx.c + 'reports/extended/statuses/{docId}/{format}'
	}
};

export { applicationAPI };
