import { createCompanySelector } from '@Components/company.selector.js';
import {
	createActionsPanel,
	createDetailsModulePanel,
	createPanel,
	createTab,
	createTabPanel,
	TAB_PANEL_CLS
} from '@Components/panels';
import { createField, createLabel } from '@Components/fields';
import { createContainer, createTwoColumnsLayout, getTwoColumnsFilterOptions } from '@Components/miscComponents';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { INVRPT_LINE_COLUMN } from '@Edi/modules/documents/INVRPT/columns';
import { INVRPT_LINE_MODEL } from '@Edi/modules/documents/INVRPT/models';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createDetailLabel } from '@UIkit/components/fields';

/**
 * Class for fhp edi invrpt details
 * @author Senin Fedor
 */
Ext.namespace('edi.modules');
edi.modules['document.details.invrpt'] = function () {
	var moduleData, header, document;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		header = moduleData.initData.data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Creates invrpt details form
	 * @returns {Object}
	 */
	var createDetailsPanel = function () {
		var createPanelFn = function (params) {
			var defaultParams = {
				layout: 'column',
				margin: '0 0 5',
				cls: 'edi-create-field-line',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			};

			Ext.applyIf(params, defaultParams);
			return createPanel(params);
		};

		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSignatures: true,
			noLinked: true,
			noUsage: true,
			noModify: true
		});

		var reportNumberValue = edi.utils.getObjectProperty(document, 'InventoryReport-Header.InventoryReportNumber');
		var reportNumber = createPanelFn({
			items: [
				createField({
					title: edi.i18n.getMessage('column.inventory.report.number'),
					input: createLabel({
						columnWidth: 0.8,
						text: reportNumberValue,
						valueLabel: true
					}),
					containerConfig: { columnWidth: 1 }
				})
			]
		});

		var reportDateValue = edi.utils.getObjectProperty(document, 'InventoryReport-Header.InventoryReportDate');
		var reportDate = createPanelFn({
			items: [
				createField({
					title: edi.i18n.getMessage('column.inventory.report.date'),
					input: createLabel({
						columnWidth: 0.8,
						text:
							reportDateValue &&
							edi.utils.formatDate(
								reportDateValue,
								edi.constants.DATE_FORMAT.FNS,
								edi.constants.DATE_FORMAT.SERVER
							),
						valueLabel: true
					}),
					containerConfig: { columnWidth: 1 }
				})
			]
		});

		var periodStartDateValue =
			edi.utils.getObjectProperty(document, 'InventoryReport-Header.PeriodStartDate') &&
			edi.utils.formatDate(
				edi.utils.getObjectProperty(document, 'InventoryReport-Header.PeriodStartDate'),
				edi.constants.DATE_FORMAT.FNS,
				edi.constants.DATE_FORMAT.SERVER
			);
		var periodEndDateValue =
			edi.utils.getObjectProperty(document, 'InventoryReport-Header.PeriodEndDate') &&
			edi.utils.formatDate(
				edi.utils.getObjectProperty(document, 'InventoryReport-Header.PeriodEndDate'),
				edi.constants.DATE_FORMAT.FNS,
				edi.constants.DATE_FORMAT.SERVER
			);
		var periodSubscribeDate = createPanelFn({
			items: [
				createField({
					title: edi.i18n.getMessage('column.inventory.report.subscribe'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.i18n.getMessage('column.inventory.report.subscribe.value', {
							startDate: periodStartDateValue,
							endDate: periodEndDateValue
						}),
						valueLabel: true
					}),
					containerConfig: { columnWidth: 1 }
				})
			]
		});

		var remarksValue = edi.utils.getObjectProperty(document, 'InventoryReport-Header.Remarks');
		var remarks = createPanelFn({
			items: [
				createField({
					title: edi.i18n.getMessage('column.inventory.report.remarks'),
					input: createLabel({
						columnWidth: 0.8,
						text: remarksValue,
						valueLabel: true
					}),
					containerConfig: { columnWidth: 1 }
				})
			]
		});

		var seller = createCompanySelector({
			title: 'document.seller',
			is_valid: true,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			fieldValues: moduleData.initData.data.toOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.toOrg)
				: null,
			readOnly: true,
			margin: '5 0 10'
		});

		var buyer = createCompanySelector({
			title: 'document.buyer',
			is_valid: true,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			fieldValues: moduleData.initData.data.fromOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.fromOrg)
				: null,
			readOnly: true,
			margin: '5 0 10'
		});

		var container = createContainer({
			layout: 'anchor',
			defaults: {
				anchor: '100%'
			},
			items: [seller, buyer]
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [
				headPanel,
				createTwoColumnsLayout(
					[reportNumber, remarks, seller],
					[reportDate, periodSubscribeDate, buyer],
					0.5,
					getTwoColumnsFilterOptions({
						bodyPadding: 10
					})
				),
				container,
				createModuleGrid()
			]
		});
	};

	var createModuleGrid = function () {
		return createGrid({
			proxyConfig: {
				type: 'memory',
				data: (function () {
					var data = [],
						i;
					if (
						document &&
						document['InventoryReport-Lines'] &&
						document['InventoryReport-Lines'].Line &&
						document['InventoryReport-Lines'].Line.length
					) {
						for (i = 0; i < document['InventoryReport-Lines'].Line.length; i++) {
							data.push(
								edi.models.createInstance(INVRPT_LINE_MODEL, document['InventoryReport-Lines'].Line[i])
							);
						}
					}
					return data;
				})(),
				reader: {
					type: 'array'
				}
			},
			storeConfig: {
				model: edi.models.getModel(INVRPT_LINE_MODEL),
				remoteSort: false
			},
			gridConfig: {
				disablePaging: true,
				margin: '20 0 0 0',
				title: edi.i18n.getMessage('grid.line.items'),
				columns: createGridColumnConfig(),
				disableSelection: true,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						rowClick(record);
					}
				}
			}
		});
	};

	/**
	 * Creates column config for line grids
	 */
	var createGridColumnConfig = function () {
		let columnsConfig = edi.columns.get(INVRPT_LINE_COLUMN);
		columnsConfig.push(
			createActionsColumnConfig({
				items: [
					{
						glyph: edi.constants.ICONS.DETAILS,
						testCls: 'test-action-column-edit',
						handler: function (view, rowIndex) {
							rowClick(view.getStore().getAt(rowIndex));
						}
					}
				]
			})
		);
		return columnsConfig;
	};

	/**
	 * Grid row click handler
	 * @param    {Object}    record grid view data object
	 */
	var rowClick = function (record) {
		showModalLineSelector(record);
	};

	/**
	 * Modal window with line-item details
	 * @param record
	 */
	var showModalLineSelector = function (record) {
		var salePoint = [],
			additional = [];

		var SellerLocationCodeValue = edi.utils.getObjectProperty(record.get('Line-Delivery'), 'SellerLocationCode');
		salePoint.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('field.seller.location.code'),
				items: [
					createDetailLabel({
						text: SellerLocationCodeValue
					})
				]
			})
		);

		var BuyerDeliveryGroupValue = edi.utils.getObjectProperty(record.get('Line-Delivery'), 'BuyerDeliveryGroup');
		salePoint.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('field.buyer.delivery.group'),
				items: [
					createDetailLabel({
						text: BuyerDeliveryGroupValue
					})
				]
			})
		);

		var NameValue = edi.utils.getObjectProperty(record.get('Line-Delivery'), 'Name');
		salePoint.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('column.name'),
				items: [
					createDetailLabel({
						text: NameValue
					})
				]
			})
		);

		var BranchValue = edi.utils.getObjectProperty(record.get('Line-Delivery'), 'Branch');
		salePoint.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('field.branch'),
				items: [
					createDetailLabel({
						text: BranchValue
					})
				]
			})
		);

		var RegionValue = edi.utils.getObjectProperty(record.get('Line-Delivery'), 'Region');
		salePoint.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('company.region'),
				items: [
					createDetailLabel({
						text: RegionValue
					})
				]
			})
		);

		var NameOfLevel1Value = record.get('NameOfLevel1');
		additional.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('field.name.of.level1.value'),
				items: [
					createDetailLabel({
						text: NameOfLevel1Value
					})
				]
			})
		);

		var NameOfLevel2Value = record.get('NameOfLevel2');
		additional.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('field.name.of.level2.value'),
				items: [
					createDetailLabel({
						text: NameOfLevel2Value
					})
				]
			})
		);

		var NameOfLevel3Value = record.get('NameOfLevel3');
		additional.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('field.name.of.level3.value'),
				items: [
					createDetailLabel({
						text: NameOfLevel3Value
					})
				]
			})
		);

		var NameOfLevel4Value = record.get('NameOfLevel4');
		additional.push(
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('field.name.of.level4.value'),
				items: [
					createDetailLabel({
						text: NameOfLevel4Value
					})
				]
			})
		);

		var tabPanel = createTabPanel({
			cls: TAB_PANEL_CLS.simpleWithoutPadding,
			items: [
				createTab({
					title: edi.i18n.getMessage('tab.sale.point'),
					closable: false,
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: salePoint
				}),
				createTab({
					title: edi.i18n.getMessage('tab.additional'),
					closable: false,
					layout: {
						type: 'grid',
						gap: [24, 16]
					},
					items: additional
				})
			]
		});

		var modalConf = {
			width: MODAL_SIZE.widthLarge,
			layout: 'fit',
			items: [tabPanel]
		};
		var modal = createModalPanel(modalConf);
		modal.show();
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		var excludeActions = {};
		excludeActions[edi.constants.DOCUMENT_ACTIONS.SEND] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.DELETE] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.COMPLETE] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.EDIT] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.SIGN] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.REFRESH] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PACKAGING] = true;

		var actionsPanel = createActionsPanel();
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: header,
			moduleData: moduleData,
			excludeActions: excludeActions
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				header = moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					document = data.data;

					moduleData.tab.add(createModuleActionsPanel());
					var modulePanel = createDetailsModulePanel();
					modulePanel.add(createDetailsPanel());
					moduleData.tab.add(modulePanel);

					if ('function' === typeof initCallBack) {
						initCallBack();
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					failure(data);
				}
			},
			failure
		);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
