import { createCombo, createLabel } from '@UIkit/components/fields';
import { PTYPE_COMBO_PANEL_INSIDE_LIST } from '@UIkit/plugins';
import { createProxyConfig, createStore } from '@Components/storeComponents';
import '@UIkit/components/selectors/inputSelector/InputSelector';
import './InputOrgSelector.scss';

/**
 * Компонент представляет собой улучшенный OrgSelector
 * когда он пустой, то отображается в виде комбобокса с быстрыми действиями в списке
 */
Ext.define('UI.components.InputOrgSelector', {
	extend: 'UI.components.OrgSelector',
	alias: 'widget.input-org-selector',
	selfCls: 'ui-input-org-selector',
	inputSelectorCls: 'ui-input-selector',
	readOnly: false,
	allowBlank: true,
	disabled: false,
	showManualAdd: true,
	alwaysHideSelect: true,
	allowReset: true,
	emptyMessageText: 'uikit.value.not.specified',
	manualAddText: 'uikit.inputselector.manual.add',
	manualAddCls: 'manual-add',
	ownOrgSelectText: 'form.btn.own.org',
	ownOrgSelectCls: 'own-org-select',
	partnerOrgSelectText: 'form.btn.partner.org',
	partnerOrgSelectCls: 'partner-org-select',
	warningLabelSelectText: 'inputorgselector.select.partner.warning',
	warningLabelSelectCls: 'warning-label-select',
	emptyMessageCls: 'empty-message',
	comboCls: 'combo',
	listCls: 'list',
	testListCls: '',
	listActionCls: 'list-action',
	listWarningCls: 'list-warning',
	useListDescriptionAddress: false,
	emptyMessageConf: null,
	comboConf: null,
	manualAddCmpConf: null,
	ownOrgSelectConf: null,
	partnerOrgSelectConf: null,
	warningLabelSelectConf: null,

	/**
	 * @Override
	 */
	beforeInit: function () {
		const me = this;
		me.callParent();
		me.addCls(me.selfCls);
		me.addCls(me.inputSelectorCls);
		me.initCombo();
		me.initEmptyLabel();
	},

	/**
	 * @Override
	 */
	afterInit: function () {
		const me = this;
		me.callParent();
		me._originalSelectorCallback = me.callback || (() => {});
		me.callback = function () {
			me._originalSelectorCallback(...arguments);
			me.onSelectorValueChange(...arguments);
		};
		me.onSelectorValueChange(me.getValues(), {});
		me.setReadOnly(me.readOnly);
		me.setAllowBlank(me.allowBlank);
		me.setDisabled(me.disabled);
	},

	createWarningLabelCmp: function () {
		const me = this;
		return createLabel(
			Ext.merge(
				{
					cls: [
						`${me.inputSelectorCls}-${me.listActionCls}`,
						`${me.inputSelectorCls}-${me.listWarningCls}`,
						`${me.inputSelectorCls}-${me.warningLabelSelectCls}`
					],
					itemId: 'warningLabel',
					text: edi.i18n.getMessage(me.warningLabelSelectText),
					typography: 'caption_01',
					color: '--color-grey-50'
				},
				me.warningLabelSelectConf
			)
		);
	},

	onManualAddClick: function () {
		const me = this;
		me.showModalCompanyControl();
	},

	createManualAddCmp: function () {
		const me = this;
		return createLabel(
			Ext.merge(
				{
					cls: [`${me.inputSelectorCls}-${me.listActionCls}`, `${me.inputSelectorCls}-${me.manualAddCls}`],
					itemId: 'manualAdd',
					text: edi.i18n.getMessage(me.manualAddText),
					typography: 'body-short_01',
					color: '--color-blue',
					listeners: {
						afterrender: function (cmp) {
							cmp.on({
								click: function () {
									me.comboCmp.collapse();
									me.onManualAddClick(cmp, me);
								},
								element: 'el',
								scope: cmp
							});
						}
					}
				},
				me.manualAddCmpConf
			)
		);
	},

	selectOwnOrg: function () {
		const me = this;
		me.setOrganization(edi.core.getUserData().org, function () {
			me.ownBtn?.setDisabled(true);
		});
	},

	createOwnOrgCmp: function () {
		const me = this;
		return createLabel(
			Ext.merge(
				{
					cls: [`${me.inputSelectorCls}-${me.listActionCls}`, `${me.inputSelectorCls}-${me.ownOrgSelectCls}`],
					itemId: 'ownOrgSelect',
					text: edi.i18n.getMessage(me.ownOrgSelectText),
					typography: 'body-short_01',
					color: '--color-blue',
					listeners: {
						afterrender: function (cmp) {
							cmp.on({
								click: function () {
									me.comboCmp.collapse();
									me.selectOwnOrg(cmp, me);
								},
								element: 'el',
								scope: cmp
							});
						}
					}
				},
				me.ownOrgSelectConf
			)
		);
	},

	/**
	 * @Override
	 */
	setPartnerOrg: function () {
		const me = this;
		me.callParent(arguments);
		const allowPartnerSelect = !!me.partnerOrg;
		if (allowPartnerSelect) {
			const partnerOrgPlugin = me.comboCmp?.plugins?.find((p) => p.itemId === 'partnerOrg');
			partnerOrgPlugin?.setVisible(!!me.partnerOrgValues);
			const warningLabelPlugin = me.comboCmp?.plugins?.find((p) => p.itemId === 'warningLabel');
			warningLabelPlugin?.setVisible(!me.partnerOrgValues);
		}
	},

	selectPartnerOrg: function () {
		const me = this;
		me.setOrganization(me.partnerOrgValues, function () {
			me.partnerBtn?.setDisabled(true);
		});
	},

	createPartnerOrgCmp: function () {
		const me = this;
		return createLabel(
			Ext.merge(
				{
					cls: [
						`${me.inputSelectorCls}-${me.listActionCls}`,
						`${me.inputSelectorCls}-${me.partnerOrgSelectCls}`
					],
					itemId: 'partnerOrgSelect',
					text: edi.i18n.getMessage(me.partnerOrgText || me.partnerOrgSelectText),
					typography: 'body-short_01',
					color: '--color-blue',
					listeners: {
						afterrender: function (cmp) {
							cmp.on({
								click: function () {
									me.comboCmp.collapse();
									me.selectPartnerOrg(cmp, me);
								},
								element: 'el',
								scope: cmp
							});
						}
					}
				},
				me.partnerOrgSelectConf
			)
		);
	},

	onComboValueChange: function () {
		const me = this;
		const selection = me.comboCmp.getSelection();
		const selectedRecord = selection ? (Array.isArray(selection) ? selection[0] : selection) : null;
		me.setOrganization(Ext.clone(selectedRecord?.data ?? {}));
	},

	getComboItemTextMain: function (values) {
		const name = values?.name;
		return name ? `<div class="edi-company-name">UI Platform Maven Webapp</div>` : '';
	},

	getComboItemTextDescriptionInn: function (values) {
		const i18n = edi.i18n.getMessage;
		const inn = values?.inn;
		const kpp = values?.kpp;
		const gln = values?.iln || values?.gln;
		return (
			`<div class="edi-company-row">` +
			(inn ? `<span class="code">${i18n('company.inn.short')}:</span><span class="row-data">${inn}</span>` : '') +
			(kpp ? `<span class="code">${i18n('company.kpp.short')}:</span><span class="row-data">${kpp}</span>` : '') +
			(gln ? `<span class="code">${i18n('company.gln.short')}:</span><span class="row-data">${gln}</span>` : '') +
			'</div>'
		);
	},

	getItemAddress: function (values) {
		//TODO будем брать кусок шаблона из OrgSelector после его рефакторинга (разделения на строки)
		return '';
	},

	getComboItemTextDescriptionAddress: function (values) {
		const me = this;
		const i18n = edi.i18n.getMessage;
		const gln = values?.iln || values?.gln;
		const addr = me.getItemAddress(values);
		return (
			`<div class="edi-company-row">` +
			(gln ? `<span class="code">${i18n('company.gln.short')}:</span><span class="row-data">${gln}</span>` : '') +
			(addr ? `<span class="code">${i18n('company.address')}:</span><span class="row-data">${addr}</span>` : '') +
			'</div>'
		);
	},

	getComboItemText: function (values) {
		const me = this;
		return (
			me.getComboItemTextMain(values) +
			(me.useListDescriptionAddress
				? me.getComboItemTextDescriptionAddress(values)
				: me.getComboItemTextDescriptionInn(values))
		);
	},

	getDefaultComboConfig: function (customCfg) {
		const me = this;
		if (!me.testListCls && me.inputName) {
			me.testListCls = `test-selector-list-${me.inputName}`;
		}
		return Ext.merge(
			{
				cls: `${me.inputSelectorCls}-${me.comboCls}`,
				width: '100%',
				submitValue: false,
				queryDelay: 1000,
				//при отображении в списке порядок плагинов обратный
				plugins: [
					{
						ptype: PTYPE_COMBO_PANEL_INSIDE_LIST,
						itemId: 'manualAdd',
						hidden: me.showManualAdd !== true || me.relationsOnly || me.manualChangeDisabled,
						items: [me.createManualAddCmp()]
					},
					{
						ptype: PTYPE_COMBO_PANEL_INSIDE_LIST,
						hidden: me.ownOrg !== true,
						itemId: 'ownOrg',
						items: [me.createOwnOrgCmp()]
					},
					{
						ptype: PTYPE_COMBO_PANEL_INSIDE_LIST,
						itemId: 'partnerOrg',
						hidden: !me.partnerOrg || !me.partnerOrgValues,
						items: [me.createPartnerOrgCmp()]
					},
					{
						ptype: PTYPE_COMBO_PANEL_INSIDE_LIST,
						itemId: 'warningLabel',
						hidden: !me.partnerOrg || !!me.partnerOrgValues,
						items: [me.createWarningLabelCmp()]
					}
				],
				name: me.inputName,
				displayName: 'name',
				store: me.getRelationsStore(),
				listConfig: {
					cls: [`${me.inputSelectorCls}-${me.listCls}`, me.testListCls],
					tplOptions: {
						getItemText: me.getComboItemText.bind(me)
					}
				}
			},
			customCfg,
			me.comboConf
		);
	},

	getCatalogueProxyUrl: function (catType) {
		const me = this;
		let fromOrgId;
		if (me.ownCatalog) {
			fromOrgId = edi.core.getUserOrgID();
		} else {
			fromOrgId = catType === 'LOCCAT' ? me.relationsFromLoccatByOrgId : me.relationsFromDelcatByOrgId;
		}
		return fromOrgId
			? edi.utils.formatString(
					edi.rest.services.CATALOGS.V2.RECORDS.GET,
					{
						type: catType.toLowerCase(),
						fromOrgId: fromOrgId,
						toOrgId: fromOrgId
					},
					true
			  )
			: '';
	},

	getCatalogueStore: function (catType) {
		const me = this;
		return createStore({
			model: edi.models.getModel('SIMPLE'),
			proxy: createProxyConfig({
				type: 'ajax',
				url: me.getCatalogueProxyUrl(catType)
			})
		});
	},

	getCatalogueComboConfig: function (catType) {
		const me = this;
		return me.getDefaultComboConfig({
			queryMode: 'remote',
			triggerAction: 'query',
			minChars: 0,
			queryParam: 'name',
			store: me.getCatalogueStore(catType)
		});
	},

	getRemoteRelationsStore: function () {
		const me = this;
		return createStore({
			model: edi.models.getModel('SIMPLE'),
			proxy: createProxyConfig({
				type: 'ajax',
				url: me.relationsRemoteURL
			})
		});
	},

	getRemoteRelationsComboConfig: function () {
		const me = this;
		return me.getDefaultComboConfig({
			queryMode: 'remote',
			triggerAction: 'query',
			minChars: 0,
			queryParam: 'name',
			store: me.getRemoteRelationsStore()
		});
	},

	getRelationsStore: function () {
		const me = this;
		return edi.stores.createInlineStore(
			(me.relations || []).map((obj) => Ext.clone(obj)),
			'SIMPLE'
		);
	},

	geRelationsComboConfig: function () {
		const me = this;
		return me.getDefaultComboConfig({
			store: me.getRelationsStore()
		});
	},

	getComboConfig: function () {
		const me = this;
		if (me.relationsFromDelcatByOrgId) {
			return me.getCatalogueComboConfig('DELCAT');
		} else if (me.relationsFromLoccatByOrgId) {
			return me.getCatalogueComboConfig('LOCCAT');
		} else if (me.relationsRemoteURL) {
			return me.getRemoteRelationsComboConfig();
		} else {
			return me.geRelationsComboConfig();
		}
	},

	createComboFn: function () {
		const me = this;
		return createCombo(me.getComboConfig());
	},

	initCombo: function () {
		const me = this;
		me.comboCmp = me.createComboFn();
		me.comboCmp.on('change', function () {
			me.onComboValueChange();
		});
		//вставляем комбик перед панелью, т.к. перед ней есть еще тайтл и скрытые поля
		//делаем так чт бы комбо и панель рендерились максимально рядом, т.к. исполняют роль одного и того же компонента
		const panelIndex = me.itemsContainer.items.items.indexOf(me.panel);
		me.itemsContainer.insert(panelIndex, me.comboCmp);
		return me.comboCmp;
	},

	initEmptyLabel: function () {
		const me = this;
		me.emptyLabel = createLabel(
			Ext.merge(
				{
					cls: `${me.selfCls}-${me.emptyMessageCls}`,
					text: edi.i18n.getMessage(me.emptyMessageText),
					typography: 'body-short_01',
					color: '--color-grey-50'
				},
				me.emptyMessageConf
			)
		);
		//вставляем лэйбл перед панелью, т.к. перед ней есть еще тайтл и скрытые поля
		//делаем так чт бы лэйбл и панель рендерились максимально рядом, т.к. исполняют роль одного и того же компонента
		const panelIndex = me.itemsContainer.items.items.indexOf(me.panel);
		me.itemsContainer.insert(panelIndex, me.emptyLabel);
		return me.emptyLabel;
	},

	/**
	 * @Override
	 */
	setOrgIdForCatalog: function (_orgId, catalogType) {
		const me = this;
		me.callParent(arguments);
		const store = me.comboCmp?.getStore();
		const proxy = store?.getProxy();
		const newUrl = me.getCatalogueProxyUrl(catalogType);
		if (proxy) {
			if (newUrl) {
				me.comboCmp.queryMode = 'remote';
				store.setProxy(
					createProxyConfig({
						type: 'ajax',
						url: newUrl
					})
				);
				store.reload();
			} else {
				me.comboCmp.queryMode = 'local';
			}
		}
	},

	/**
	 * User's callback fires after values change
	 * @param	{Object}	me
	 * @param	{any}	newValues
	 */
	afterValuesChanged: function (me, newValues) {},

	onSelectorValueChange: function () {
		const me = this;
		const isEmpty = me.isEmptyValues();
		me[isEmpty ? 'addCls' : 'removeCls'](`${me.inputSelectorCls}-empty`);
		me.panel[isEmpty ? 'hide' : 'show']();
		me.comboCmp[isEmpty ? 'show' : 'hide']();
		me.comboCmp.setDisabled(!isEmpty);
		if (isEmpty) {
			me.comboCmp.setValue(null);
			me.comboCmp.setDisabled(me.disabled);
		}
		me.afterValuesChanged(me, me.getValues);
	},

	setReadOnly: function (readOnly) {
		const me = this;
		me.readOnly = !!readOnly;
		me.comboCmp.setReadOnly(me.readOnly);
		me.actionsMenuBtn?.setVisible(!me.readOnly);
		const isEmpty = me.isEmptyValues();
		me.emptyLabel.setVisible(me.readOnly && isEmpty);
		me.comboCmp.setVisible(!me.readOnly && isEmpty);
		me.panel.setVisible(!isEmpty);
		me.detailsBtn.setVisible(me.showDetailsButton && me.readOnly && !isEmpty);
		me.markReadOnly();
		me.markEmpty();
		me.markInvalid();
		me.updateLayout();
	},

	setAllowBlank: function (allowBlank) {
		const me = this;
		me.allowBlank = !!allowBlank;
		me.comboCmp.allowBlank = me.allowBlank;
		me.comboCmp.isValid();
		me.markEmpty();
		me.markInvalid();
		me.updateLayout();
	},

	setDisabled: function (disabled) {
		const me = this;
		me.disabled = !!disabled;
		me.comboCmp.setDisabled(me.disabled || !me.isEmptyValues());
		me.actionsMenuBtn?.setDisabled(me.disabled);
		Object.values(me._hiddenFields || {}).forEach((f) => f.setDisabled(me.disabled));
		me.comboCmp.isValid();
		me.markEmpty();
		me.markInvalid();
		me.updateLayout();
	}
});

const createInputOrgSelector = (cfg) => Ext.create('UI.components.InputOrgSelector', cfg);

export { createInputOrgSelector };
