import { createModalPanel, MODAL_CLS, MODAL_SIZE, showConfirmWithException } from '@UIkit/components/modal';
import { createLabel, createLabelBlock, createTextField } from '@UIkit/components/fields';
import { openModulesForDocuments } from './methods';
import { createCarousel } from '@Ediweb/components/carousel';
import {
	createPanel,
	createContainer,
	createModalForm,
	createFieldBlock,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createDetailsModulePanel } from '@Components/panels';
import { createCheckbox } from '@UIkit/components/fields/index.js';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { tourEvents, tours } from '@Ediweb/components/tour';
import { showInfo } from '@UIkit/components/modal/MessageBox';
import { createToast } from '@UIkit/components/toast';
import { getDCRoleDependence } from '@Ediweb/methods';

const MAIN_MODULE_NAME = 'main';
const MAIN_TOUR_FLAG_NAME = 'user.mainHints';
const MAIN_TOUR_TARGET_1 = 'main-tour-target-1';
const MAIN_TOUR_TARGET_2 = 'main-tour-target-2';
const MAIN_TOUR_TARGET_3 = 'edi-tour-manager-show-tips-button'; //эта кнопка не в модуле, а в навигации

const BANNER_WIDTH = 292;
const BANNER_HEIGHT = 140;

Ext.namespace('edi.modules');
edi.modules[MAIN_MODULE_NAME] = function () {
	let moduleData, mainPanel, EWDocsButton;
	let userData = edi.core.getUserData();

	let openDocumentModule = function () {
		edi.core.openModule('documents');
	};
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const createLayout = function (finishCallback) {
		let storeData = edi.stores.data.project_list,
			itemArr = [],
			cardArr = [];
		let sorterStoreData = [...storeData];
		sorterStoreData.sort(function (a, b) {
			return b.name === 'ediweb_docs' || b.connected - a.connected;
		});
		if (!edi.permissions.hasPermission('READ_DOCUMENTS_REST_SERVICES')) {
			sorterStoreData = sorterStoreData.filter((item) => item.name !== 'ediweb_docs');
		}
		const createItemBlock = function (data) {
			let item = Ext.create('Ext.Component', {
				xtype: 'button',
				cls: 'edi-ediweb-project-item item',
				tpl: Ext.create(
					'Ext.XTemplate',
					'<tpl for=".">',
					'<a href="{[this.getConnectedLink(values)]}" target="{[this.getTarget(values)]}" class="x-btn">',
					'<div data-qtip="{[this.getTooltipString(values)]}" class="logo {name} {[this.getConnectedCls(values)]}"></div>',
					'</a>',
					'</tpl>',
					{
						getTooltipString: function (values) {
							return !values.connected && values.name !== 'ediweb_docs'
								? edi.i18n.getMessage('ediweb.product.not.connected')
								: null;
						},
						getConnectedCls: function (values) {
							return !values.connected ? 'notconnected' : '';
						},
						getConnectedLink: function (values) {
							return !values.connected ? '#' : values.product_url;
						},
						getTarget: function (values) {
							return !values.connected ? '' : '_blank';
						}
					}
				),
				data: data,
				listeners: {
					afterrender(comp) {
						setTimeout(function () {
							EWDocsButton = comp.el.dom.querySelector('a.x-btn');
							if (EWDocsButton && comp.data.name === 'ediweb_docs') {
								EWDocsButton.addEventListener('click', openDocumentModule);
							}
						}, 1);
					}
				}
			});

			return item;
		};

		let welcomePanel = createPanel({
			cls: 'edi-ediweb-main-panel',
			items: [
				createContainer({
					padding: 16,
					items: [
						createLabelBlock({
							gap: 8,
							contents: [
								{
									isBlock: true,
									typography: 'heading_02',
									text: edi.i18n.getMessage('ediweb.main.welcome.header')
								},
								{
									typography: 'body-long_01',
									color: '--color-grey-50',
									text: edi.i18n.getMessage('ediweb.main.welcome.header.subtitle')
								}
							]
						})
					]
				})
			]
		});

		const failure = function (data) {
			if (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
			} else {
				edi.core.showError('error');
			}
			typeof finishCallback === 'function' && finishCallback();
		};

		let bannerUrl = edi.utils.compileURL(edi.rest.services.ADVERTISING_TAPE.LIST.GET, {
			active: true
		});

		edi.rest.sendRequest(
			bannerUrl,
			'GET',
			undefined,
			function (responseData) {
				if (responseData && responseData.items.length) {
					const finishLoad = function (cards) {
						let sortedCards = cards.sort(function (a, b) {
							let aContainer = a.getRefItems()?.[0];
							let bContainer = b.getRefItems()?.[0];
							return aContainer?.priority - bContainer?.priority;
						});
						let banner = createCarousel({
							padding: '24 0 0 4',
							cards: sortedCards
						});

						mainPanel.insert(2, banner);
						typeof finishCallback === 'function' && finishCallback();
					};

					let cards = [];

					if (responseData.items.length) {
						responseData.items?.forEach(function (item, index) {
							let clickTime;
							let card = createContainer({
								width: BANNER_WIDTH,
								height: BANNER_HEIGHT + 8,
								listeners: {
									element: 'el',
									click: function () {
										if (new Date() - clickTime < 150) {
											if (item.urlString) {
												window.open(item.urlString, '_blank');
											}
										}
									},
									mousedown: function (e) {
										e.preventDefault();
										clickTime = new Date();
									}
								}
							});
							let id = item.id,
								contentType = item.contentType,
								priority = item.priority;
							edi.methods.advertising_tape.getContent(id, contentType, function (data) {
								let i = new Image();
								i.src = data;
								i.onload = function () {
									card.add(
										createContainer({
											width: BANNER_WIDTH,
											height: BANNER_HEIGHT,
											priority,
											cls: 'edi-ediweb-banner-img',
											html: i.outerHTML
										})
									);

									cards.push(card);

									let isAllContentLoaded = cards.length === responseData.items.length;

									if (isAllContentLoaded) {
										finishLoad(cards);
										card.setLoading(false);
									}
								};
							});
							card.setLoading();
						});
					} else {
						moduleData.tab.setLoading(false);
					}
				} else {
					moduleData.tab.setLoading(false);
					typeof finishCallback === 'function' && finishCallback();
				}
			},
			failure
		);

		const createProductCard = function (data) {
			return Ext.create('Ext.Component', {
				xtype: 'container',
				cls: 'edi-ediweb-project-card card',
				tpl: Ext.create(
					'Ext.XTemplate',
					'<tpl for=".">',
					'<div class="logo {name}"></div>',
					'<div class="right">' +
						'<div class="title-container">' +
						'<div class="title typography heading_02">{label}</div>' +
						'<tpl if="!!connected">' +
						'<div class="edi-state-container"> ' +
						'<div class="edi-icon edi-icon-DONE"></div>' +
						'<div class="text typography body-short_01">{[edi.i18n.getMessage(\'ediweb.connected.state\')]}</div>' +
						'</div>' +
						'</tpl>' +
						'</div>' +
						'<div class="content typography body-short_01">{description}</div>' +
						'<div id="{[this.getLinkId()]}" class="buttons">' +
						'</div>' +
						'</div>' +
						'</tpl>',
					{
						getLinkId: function () {
							return data.name + '-connect-btn';
						}
					}
				),
				data: data,
				listeners: {
					afterrender: function (cmp) {
						let id = data.name + '-connect-btn';
						let connBtn;
						let isShowConnBtn = !data.connected;
						if (data.name === 'factorplat') {
							isShowConnBtn = !data.connected && edi.permissions.hasPermission('CLIENT_FP_ONBORDING');
						}
						if (isShowConnBtn) {
							connBtn = createButton({
								cls: [BUTTON_CLS.primary],
								text: edi.i18n.getMessage('ediweb.plug.btn'),
								handler: function () {
									showConnectingModal(data);
								},
								margin: '0 24 0 0'
							});
						}
						let link = createButton({
							cls: [BUTTON_CLS.secondary],
							text: edi.i18n.getMessage('ediweb.details.btn'),
							target: '_blank',
							href: data.url
						});

						createContainer({
							layout: {
								type: 'hbox',
								align: 'center',
								pack: 'start'
							},
							items: [connBtn, link],
							renderTo: id
						});
					}
				}
			});
		};

		const getProductUrl = function (product) {
			const productName = product.name.toUpperCase();
			const productKey = productName + '_URL';
			const productUrl = edi.stores.data.product_urls[productKey];
			return productUrl ? productUrl : '#';
		};

		let isFirst = true;
		for (let i in storeData) {
			if (storeData[i].name !== 'ediweb_docs') {
				let card = createProductCard(storeData[i]);
				if (isFirst) {
					isFirst = false;
					card.addCls(MAIN_TOUR_TARGET_1);
				}
				cardArr.push(card);
			}
		}
		let connectedProductCount = 0;
		for (let j in sorterStoreData) {
			sorterStoreData[j].product_url = getProductUrl(sorterStoreData[j]);
			connectedProductCount += sorterStoreData[j].connected ? 1 : 0;
			if (sorterStoreData[j].name !== 'premium_support' || sorterStoreData[j].name !== 'affiliate_program') {
				if (j < 6) {
					itemArr.push(createItemBlock(sorterStoreData[j]));
				}
				if (connectedProductCount > 6) {
					itemArr.push(createItemBlock(sorterStoreData[j]));
				}
			}
		}

		let projectPanel = createPanel({
			cls: 'edi-ediweb-project-itemlist',
			layout: 'hbox',
			items: [
				createContainer({
					layout: 'hbox',
					cls: MAIN_TOUR_TARGET_2,
					items: itemArr
				})
			]
		});

		let projectCardsPanel = createPanel({
			cls: 'edi-ediweb-project-cards typography heading_02',
			layout: 'column',
			title: edi.i18n.getMessage('ediweb.card.products'),
			items: cardArr
		});

		const showConnectingModal = function (product) {
			switch (product.name) {
				case 'dropcat':
					showConnectingDropcatModal();
					break;
				case 'factorplat':
					showConnectingFactorplatModal();
					break;
				default:
					showConnectingDefaultModal(product);
					break;
			}
		};

		const checkProductOrRole = function (product, putData, checkCallback) {
			const failure = function (data) {
				const productData = sorterStoreData.filter((item) => data.additionalData.includes(item.name));
				if (productData.length) {
					data.additionalData = productData.map((item) => item.label);
				}
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
			};

			const success = function (data) {
				if (data && data.data) {
					checkCallback(data.data);
				}
			};

			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.PRODUCT.CHECK.PUT, {
					product: product
				}),
				'PUT',
				Ext.encode(putData.roles || []),
				success,
				failure
			);
		};
		/**
		 * Сначала дергается рест CHECK, который проверяет отправлялся ли аперак на подключение. Если нет, то дергаем
		 * рест ACTIVE, чтобы подключить продукт. Иначе - показываем сообщение:
		 * о том что запрос уже отправлен, если documents пустые, а requests не пустые
		 * о том, что продукт уже подключен, если documents не пустые, а requests пустые.
		 *
		 * Для ДК можно дополнительно доподключать роли, которые до этого не были подключены. Проверяем поли по полям
		 * connectedRoles - подключенные роли
		 * unConnectedRoles - неподключенные поли
		 * **/

		const connectHandler = function (product, putData = {}, beforeActiveProductCb) {
			const activeProduct = function (activeProductSuccess) {
				const failure = function (data) {
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
				};

				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.PRODUCT.ACTIVE.PUT, {
						product: product
					}),
					'PUT',
					Ext.encode(putData),
					activeProductSuccess,
					failure
				);
			};

			const beforeActiveProduct = function (checkData) {
				if (typeof beforeActiveProductCb === 'function') {
					beforeActiveProductCb(checkData, activeProduct);
				} else {
					activeProduct();
				}
			};

			checkProductOrRole(product, putData, beforeActiveProduct);
		};

		const defaultBeforeActiveProduct = function (checkData, showConfirm, activeProductCb, productCb, productName) {
			let documents = checkData.documents || [];
			let requests = checkData.requests || [];

			// если documents пустой, а requests не пустой, то значит аперак отправлен, но еще не подтвержден
			//если documents не пуст, значит аперак подтвержден и продукт подключен
			if (!documents.length && !requests.length) {
				showConfirm(checkData, activeProductCb);
			} else if (!documents.length && requests.length) {
				const info = showInfo({
					title: edi.i18n.getMessage('ediweb.default.connecting.product.title', [productName]),
					msgText: edi.i18n.getMessage('ediweb.default.connecting.product.info.msgText'),
					okBtnConfig: {
						text: edi.i18n.getMessage('btn.understand'),
						handler: function () {
							if (typeof productCb === 'function') {
								productCb();
							}
							info.close();
						}
					},
					noBtnConfig: {
						text: edi.i18n.getMessage('form.btn.cancel')
					}
				});
				return info;
			} else if (documents.length && !requests.length) {
				const info = showInfo({
					title: edi.i18n.getMessage('ediweb.default.connecting.product.title', [productName]),
					msgText: edi.i18n.getMessage('ediweb.default.connecting.product.info.msgText.buy'),
					okBtnConfig: {
						text: edi.i18n.getMessage('ediweb.open.account.btn'),
						handler: function () {
							openModulesForDocuments(documents);
							if (typeof productCb === 'function') {
								productCb();
							}
							info.close();
						}
					}
				});
				return info;
			}
		};

		const showConnectingDefaultModal = function (product) {
			let connectingModal, connectingForm;

			let fio = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('ediweb.connecting.product.form.fio'),
				items: [
					createTextField({
						value: `${userData.firstName} ${userData.lastName}`,
						name: 'initiator'
					})
				]
			});

			let email = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('Email'),
				items: [
					createTextField({
						value: userData.eMail,
						name: 'email'
					})
				]
			});
			let phone = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('ediweb.connecting.product.form.phone'),
				items: [
					createTextField({
						value: userData.phones,
						name: 'telephone'
					})
				]
			});
			let company = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('ediweb.title.company'),
				items: [
					createTextField({
						value: userData?.org?.name,
						name: 'company'
					})
				]
			});

			let comment = createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('ediweb.invitations.comment'),
				items: [
					createTextField({
						isTextarea: true,
						name: 'comment'
					})
				]
			});

			connectingForm = createModalForm({
				layout: {
					type: 'grid',
					gap: [24]
				},
				items: [fio, email, phone, company, comment]
			});
			let connectBtn = createButton({
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('ediweb.lk.send.request'),
				handler: function () {
					let formValues = connectingForm.getValues();
					connectHandler(product.name, formValues, (checkData, activeProductCb) => {
						return defaultBeforeActiveProduct(
							checkData,
							function (checkData, activeProductCb) {
								connectingModal.close();
								return activeProductCb(() => {
									createToast({
										type: 'info',
										titleContent: edi.i18n.getMessage('ediweb.connecting.product.toast.title'),
										content: edi.i18n.getMessage('ediweb.connecting.product.toast.content')
									});
								});
							},
							activeProductCb,
							function () {
								connectingModal.close();
							},
							product.label
						);
					});
				}
			});
			connectingModal = createModalPanel({
				cls: [MODAL_CLS.withShadows],
				width: MODAL_SIZE.widthSmall,
				isTitleMove: true,
				title: edi.i18n.getMessage('ediweb.connecting.product.title', [product.label]),
				items: [connectingForm],
				buttons: [connectBtn]
			});
			connectingModal.show();
		};

		const showConnectingDropcatModal = function () {
			let rolesArray = [
				'client_by_te',
				'forwarder',
				'consignor',
				'consignee',
				'person_from',
				'carrier',
				'medical_org'
			];
			let rolesCheckboxArray = [],
				connectingModal,
				modal,
				connectBtn;
			rolesArray.forEach(function (role) {
				let checkbox = createCheckbox({
					name: role,
					boxLabel: Ext.String.format(
						'<b class="edi-ediweb-connecting-dropcat-modal-checkbox-title">{0}</b>' +
							'<div class="edi-ediweb-connecting-dropcat-modal-checkbox-subtitle">{1}</div>',
						edi.i18n.getMessage('ediweb.invitations.' + role),
						edi.i18n.getMessage('ediweb.invitations.subtitle.' + role)
					),
					inputValue: true,
					uncheckedValue: false,
					listeners: {
						change: function (comp, checked) {
							const isSomeChecked = rolesCheckboxArray.some((item) => item.checked);
							const checkedArray = rolesCheckboxArray.filter((item) => item.checked);
							let ids = checkedArray.map((item) => item.name);

							connectBtn.setDisabled(!isSomeChecked);
							let fields = connectingForm?.getForm()?.getFields()?.items;

							if (Array.isArray(ids)) {
								let dependenceIds = getDCRoleDependence(ids);

								fields.forEach((field) => {
									const isDisabled = dependenceIds.some((it) => it === field.getName());
									field.setDisabled(isDisabled);
								});
							}
						}
					}
				});
				rolesCheckboxArray.push(checkbox);
			});
			let modalItems = [
				createLabel({
					typography: 'body-short_03',
					html: edi.i18n.getMessage('ediweb.connecting.dropcat.label')
				})
			];
			modalItems = modalItems.concat(rolesCheckboxArray);

			const successModal = function (isOpenDSF, documents) {
				let modal, contents;
				let contractBtn = createButton({
					cls: [BUTTON_CLS.primary],
					text: edi.i18n.getMessage('ediweb.connecting.dropcat.contract.btn'),
					handler: function () {
						openModulesForDocuments(documents);
						modal.close();
					}
				});

				contents = createModalForm({
					layout: {
						type: 'grid',
						gap: [24]
					},
					items: []
				});

				if (isOpenDSF) {
					documents.forEach(function (doc) {
						let item = createLabel({
							typography: 'body-short_01',
							html: edi.i18n.getMessage('ediweb.connecting.dropcat.contract.item', [
								edi.i18n.getMessage('documents.doctype.' + doc.type)
							])
						});
						contents.add(item);
					});
					let contract = createLabel({
						typography: 'body-short_01',
						text: edi.i18n.getMessage('ediweb.connecting.dropcat.contract')
					});
					contents.add(contract);
				} else {
					let item = createLabel({
						typography: 'body-short_01',
						text: edi.i18n.getMessage('ediweb.connecting.dropcat.soon')
					});
					contents.add(item);
				}

				modal = createModalPanel({
					title: edi.i18n.getMessage('ediweb.connecting.dropcat.title'),
					items: contents,
					isTitleMove: true,
					closeButtonOptions: {
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('button.ok'),
						hidden: isOpenDSF,
						glyph: edi.constants.ICONS.DONE,
						handler: function () {
							modal.close();
							if (documents && documents.length) {
								successModal(true, documents);
							}
						}
					},
					buttonsBefore: [isOpenDSF ? contractBtn : null]
				});
				modal.show();
			};

			connectBtn = createButton({
				cls: BUTTON_CLS.primary,
				disabled: true,
				text: edi.i18n.getMessage('ediweb.plug.btn'),
				handler: function () {
					/* этот код потребуется в следующих версиях
					const showConfirm = function (connectedRoles, unConnectedRoles) {
						let exceptionText = null,
							msgText = null;

						if (!connectedRoles.length && unConnectedRoles.length) {
							connectingModal.close();
							return createToast({
								type: 'info',
								titleContent: edi.i18n.getMessage('ediweb.connecting.product.dropcat.toast.title'),
								content: edi.i18n.getMessage('ediweb.connecting.product.dropcat.toast.content')
							});
						}

						if (connectedRoles.length > 0) {
							const connectedRolesTextWord = edi.i18n.decline(connectedRoles.length, 'roleRodPadezh');
							let connectedRolesTranslateArray = connectedRoles.map((role) =>
								edi.i18n.getMessage(`ediweb.invitations.${role}`)
							);
							exceptionText = edi.i18n.customTranslation(
								'custom.ediweb.connecting.dropcat.exceptionText',
								{
									rolesWord: connectedRolesTextWord,
									roles: connectedRolesTranslateArray.join(', ')
								},
								edi.i18n.getMessage('ediweb.connecting.dropcat.exceptionText')
							);
						}

						if (unConnectedRoles.length > 0) {
							const unConnectedRolesTextWord = edi.i18n.decline(unConnectedRoles.length, 'roleRodPadezh');
							let unConnectedRolesTranslateArray = unConnectedRoles.map((role) =>
								edi.i18n.getMessage(`ediweb.invitations.${role}`)
							);

							msgText = edi.i18n.customTranslation(
								'custom.ediweb.connecting.dropcat.msgText',
								{
									rolesWord: unConnectedRolesTextWord,
									roles: unConnectedRolesTranslateArray.join(', ')
								},
								edi.i18n.getMessage('ediweb.connecting.dropcat.msgText')
							);
						}

						showConfirmWithException({
							title: edi.i18n.getMessage('ediweb.connecting.dropcat.title'),
							exceptionType: 'warning',
							exceptionText: exceptionText,
							msgText: msgText,
							yesBtnConfig: {
								text: edi.i18n.getMessage('btn.understand')
							},
							noBtnConfig: {
								hidden: true
							},
							success: () => {
								connectingModal.close();
							}
						});
					};
					 */

					let formValues = connectingForm.getValues();
					let data = [];
					Object.entries(formValues).forEach(function (key) {
						let value = key[1];
						if (value) {
							data.push(key[0]);
						}
					});
					const putData = {
						roles: data,
						initiator: `${userData.lastName} ${userData.firstName}`,
						email: userData.eMail,
						telephone: userData.phones,
						company: userData?.org?.name
					};

					/* этот код потребуется в следующих версиях
					const beforeActiveProduct = function (checkData, activeProductCb) {
						const roles = putData.roles;
						let connectedRoles = checkData.connectedRoles || [];
						let unConnectedRoles = checkData.unConnectedRoles || [];
						connectedRoles = connectedRoles.filter((role) => {
							return roles.includes(role);
						});
						unConnectedRoles = unConnectedRoles.filter((role) => {
							return roles.includes(role);
						});

						if (connectedRoles.length && !unConnectedRoles.length) {
							showConfirm(connectedRoles, unConnectedRoles);
						} else {
							return activeProductCb(() => {
								showConfirm(connectedRoles, unConnectedRoles);
							});
						}
					};
					*/

					const beforeActiveProduct = function (checkData, activeProductCb) {
						let documents = checkData.documents || [];
						let requests = checkData.requests || [];

						const showSuccessToast = function () {
							connectingModal.close();
							return createToast({
								type: 'info',
								titleContent: edi.i18n.getMessage('ediweb.connecting.product.dropcat.toast.title'),
								content: edi.i18n.getMessage('ediweb.connecting.product.dropcat.toast.content')
							});
						};

						// если documents пустой, а requests не пустой, то значит аперак отправлен, но еще не подтвержден
						//если documents не пуст, значит аперак подтвержден и продукт подключен
						if (!documents.length && !requests.length) {
							return activeProductCb(showSuccessToast);
						} else if (requests.length) {
							let confirm = showConfirmWithException({
								title: edi.i18n.getMessage('ediweb.connecting.dropcat.title'),
								exceptionType: 'warning',
								exceptionText: edi.i18n.getMessage('ediweb.connecting.dropcat.exceptionText.msg'),
								yesBtnConfig: {
									text: edi.i18n.getMessage('btn.wait')
								},
								noBtnConfig: {
									text: edi.i18n.getMessage('btn.reconnect')
								},
								success: () => {
									connectingModal.close();
								},
								failure: () => {
									const resendProductSuccess = function () {
										showSuccessToast();
										confirm.close();
									};
									edi.rest.sendRequest(
										edi.utils.formatString(edi.rest.services.PRODUCT.RESEND.PUT, {
											product: 'dropcat'
										}),
										'PUT',
										Ext.encode(putData),
										resendProductSuccess,
										failure
									);
									connectingModal.close();
								}
							});
						}
					};

					connectHandler('dropcat', putData, beforeActiveProduct);
				}
			});

			let connectingForm = createModalForm({
				layout: {
					type: 'grid',
					gap: [24]
				},
				items: modalItems
			});
			connectingModal = createModalPanel({
				cls: [MODAL_CLS.withShadows, 'edi-ediweb-connecting-dropcat-modal'],
				width: MODAL_SIZE.widthMedium,
				isTitleMove: true,
				title: edi.i18n.getMessage('ediweb.connecting.dropcat.title'),
				items: [connectingForm],
				buttons: [
					connectBtn,
					createButton({
						cls: BUTTON_CLS.secondary,
						text: edi.i18n.getMessage('ediweb.cancel.btn'),
						handler: function () {
							connectingModal.close();
						}
					})
				]
			});
			connectingModal.show();
		};

		const showConnectingFactorplatModal = function () {
			const showConfirm = function (checkData, activeProductCb) {
				let confirm;
				return (confirm = showConfirmWithException({
					title: edi.i18n.getMessage('ediweb.connecting.factorplat.title'),
					exceptionType: 'warning',
					exceptionText: edi.i18n.getMessage('ediweb.connecting.factorplat.exceptionText'),
					msgText: edi.i18n.getMessage('ediweb.connecting.factorplat.msgText'),
					yesBtnConfig: {
						text: edi.i18n.getMessage('ediweb.plug.btn')
					},
					noBtnConfig: {
						text: edi.i18n.getMessage('btn.cancel')
					},
					success: () => {
						return activeProductCb(() => {
							createToast({
								type: 'info',
								titleContent: edi.i18n.getMessage('ediweb.connecting.product.toast.title'),
								content: edi.i18n.getMessage('ediweb.connecting.product.toast.content')
							});
							confirm.close();
						});
					}
				}));
			};

			const putData = {
				roles: [],
				initiator: `${userData.lastName} ${userData.firstName}`,
				email: userData.eMail,
				telephone: userData.phones,
				company: userData?.org?.name
			};

			connectHandler('factorplat', putData, (checkData, activeProductFn) => {
				return defaultBeforeActiveProduct(checkData, showConfirm, activeProductFn, null, 'FactorPlat');
			});
		};

		mainPanel = createPanel({
			cls: 'edi-ediweb-gray-panel',
			bodyPadding: 20,
			autoScroll: true,
			items: [welcomePanel, projectPanel, projectCardsPanel]
		});

		return mainPanel;
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const finishCreation = function () {
			if ('function' == typeof initCallBack) {
				initCallBack();
				if (edi.core.getExtraData(MAIN_TOUR_FLAG_NAME) !== 'true') {
					setTimeout(() => {
						if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
							tourEvents.fireEvent('start_tour', MAIN_MODULE_NAME, moduleData);
						}
					}, 500);
				}
			}
		};

		const modulePanel = createDetailsModulePanel();
		modulePanel.add(createLayout(finishCreation));
		moduleData.tab.add(modulePanel);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		EWDocsButton.remove('click', openDocumentModule);
		return true;
	};
};

tours[MAIN_MODULE_NAME] = {
	getName: () => MAIN_MODULE_NAME,
	createSteps: () => [
		{
			getTitle: () => edi.i18n.getMessage('main.tour.step.1.title'),
			getContent: () => edi.i18n.getMessage('main.tour.step.1.content'),
			getTargetEl: (tour) => (tour?.queryRoot || document).querySelector(`.${MAIN_TOUR_TARGET_1}`),
			position: 'right'
		},
		{
			getTitle: () => edi.i18n.getMessage('main.tour.step.2.title'),
			getContent: () => edi.i18n.getMessage('main.tour.step.2.content'),
			getTargetEl: (tour) => (tour?.queryRoot || document).querySelector(`.${MAIN_TOUR_TARGET_2}`),
			position: 'right'
		},
		{
			getTitle: () => edi.i18n.getMessage('main.tour.step.3.title'),
			getContent: () => edi.i18n.getMessage('main.tour.step.3.content'),
			getTargetEl: (tour) => document.querySelector(`.${MAIN_TOUR_TARGET_3}`),
			position: 'right-top'
		}
	],
	onTourClosed: function () {
		const tour = this;
		//поставим флаг, что юзер прошел все страницы тура
		if (tour.currentStepNum === tour.steps.length - 1) {
			edi.core.setExtraData(MAIN_TOUR_FLAG_NAME, 'true');
		}
	}
};
