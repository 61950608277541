import { chainUploadHandler, chainUploadHandlerProps } from './chainUploadHandler';
import { showPoAChainError, showPoAChainErrorProps } from './showPoAChainError';

interface poaFeaturesProps {
	chainUploadHandler: (props: chainUploadHandlerProps) => Promise<void>;
	showPoAChainError: (props: showPoAChainErrorProps) => void;
}
export const poaFeatures: poaFeaturesProps = {
	chainUploadHandler,
	showPoAChainError
};
