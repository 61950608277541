edi.converters.setMap({
	PRODUCT_TO_INVOICE_LINE_ITEM: {
		EAN: 'EAN',
		BuyerItemCode: 'BuyerItemCode',
		SupplierItemCode: 'SupplierItemCode',
		ItemDescription: 'ItemDescription',
		ItemType: 'ItemType',
		InvoiceQuantity: 'PackDetails.MinOrderedQuantity',
		InvoiceUnitNetPrice: 'UnitNetPrice',
		Discount: 'Discount',
		InvoiceUnitGrossPrice: function (data) {
			var net = parseFloat(data.UnitNetPrice || 0);
			var tax = parseFloat(data.TaxRate || 0);
			var res = net;
			if (tax) {
				res = edi.utils.roundTo(nat + (net * tax) / 100, 4);
			}
			return res;
		},
		UnitOfMeasure: 'UnitOfMeasure',
		InvoicedUnitPackSize: 'PackDetails.UnitPacksize',
		PreviousTaxRate: 'TaxRate',
		TaxRate: 'TaxRate',
		TaxCategoryCode: 'TaxCategoryCode',
		TaxReference: function (data) {
			return {
				ReferenceType: edi.utils.getObjectProperty(data, 'TaxReference.ReferenceType'),
				ReferenceNumber: edi.utils.getObjectProperty(data, 'TaxReference.ReferenceNumber')
			};
		},
		TaxAmount: 0,
		NetAmount: 0,
		GrossAmount: 0,
		Remarks: 'Remarks'
	}
});
/**
 * convert org to INVOICE partie
 * @param    {Object}    src        org data
 * @returns    {Object}            Invoice partie
 */
const convertOrgToInvoicePartie = function (src) {
	var partie = edi.converters.convertOrgToLegacyPartie(src);

	return {
		ILN: partie.company_iln,
		TaxID: partie.company_inn,
		TaxRegistrationReasonCode: partie.company_kpp,
		AccountNumber: partie.bank_acc,
		UtilizationRegisterNumber: partie.company_util_reg_nr,
		Name: partie.company_name,
		AdditionalInformation: partie.addr_addInf,
		RoomNumber: partie.addr_flat,
		Locality: partie.addr_locality,
		Housing: partie.addr_block,
		HouseNumber: partie.addr_home,
		StreetAndNumber: partie.addr_street,
		CityName: partie.addr_city,
		District: partie.addr_area,
		State: partie.addr_region_name,
		StateCode: partie.addr_region_code,
		PostalCode: partie.addr_zip,
		Country: partie.addr_country ? edi.utils.getCountryFULLByISO(partie.addr_country, null).name : undefined,
		CountryCode: partie.addr_country,
		Individual: partie.type
			? {
					FirstName: partie.individual_firstname,
					LastName: partie.individual_lastname,
					PatronymicName: partie.individual_patronymicname
			  }
			: undefined
	};
};
/**
 * convert org to INVOICE delivery
 * @param    {Object}    src     org data
 * @returns  {Object}            Invoice delivery
 */
const convertOrgToInvoiceDelivery = function (src) {
	var res = convertOrgToInvoicePartie(src);
	res.DeliveryLocationNumber = res.ILN;
	delete res.ILN;
	return res;
};
/**
 * process invoice and load required data for organizations
 * @param    {Object}     data        document
 * @param    {Function}   callback
 */
const processInvoice = function (data, callback) {
	var buyer = edi.utils.getOrg({
		orgILN: edi.utils.getObjectProperty(data, 'Invoice-Parties.Buyer.ILN'),
		orgINN: edi.utils.getObjectProperty(data, 'Invoice-Parties.Buyer.TaxID')
	});
	var seller = edi.utils.getOrg({
		orgILN: edi.utils.getObjectProperty(data, 'Invoice-Parties.Seller.ILN'),
		orgINN: edi.utils.getObjectProperty(data, 'Invoice-Parties.Seller.TaxID')
	});
	var loadSeller = function () {
		edi.utils.loadPartie(
			data,
			'Invoice-Parties.Seller',
			'Invoice-Parties.Seller.ILN',
			'iln',
			convertOrgToInvoicePartie,
			function (data, sellerLoaded) {
				if (sellerLoaded) {
					loadBuyer();
				} else {
					edi.utils.loadPartie(
						data,
						'Invoice-Parties.Seller',
						'Invoice-Parties.Seller.TaxID',
						'inn',
						convertOrgToInvoicePartie,
						loadBuyer
					);
				}
			}
		);
	};
	var loadBuyer = function () {
		edi.utils.loadPartie(
			data,
			'Invoice-Parties.Buyer',
			'Invoice-Parties.Buyer.ILN',
			'iln',
			convertOrgToInvoicePartie,
			function (data, buyerLoaded) {
				if (buyerLoaded) {
					loadDelivery();
				} else {
					edi.utils.loadPartie(
						data,
						'Invoice-Parties.Buyer',
						'Invoice-Parties.Buyer.TaxID',
						'inn',
						convertOrgToInvoicePartie,
						loadDelivery
					);
				}
			}
		);
	};
	var loadDelivery = function () {
		var byILN = edi.utils.getObjectProperty(data, 'Invoice-Header.Delivery.DeliveryLocationNumber');
		if (byILN) {
			edi.utils.loadPartie(
				data,
				'Invoice-Header.Delivery',
				'Invoice-Header.Delivery.DeliveryLocationNumber',
				'iln',
				convertOrgToInvoiceDelivery,
				function () {
					loadShipFrom();
				}
			);
		} else {
			edi.utils.loadPartie(
				data,
				'Invoice-Header.Delivery',
				'Invoice-Header.Delivery.TaxID',
				'inn',
				convertOrgToInvoiceDelivery,
				loadShipFrom
			);
		}
	};
	var loadShipFrom = function () {
		var byILN = edi.utils.getObjectProperty(data, 'Invoice-Parties.ShipFrom.ILN');
		if (byILN) {
			edi.utils.loadPartie(
				data,
				'Invoice-Parties.ShipFrom',
				'Invoice-Parties.ShipFrom.ILN',
				'iln',
				convertOrgToInvoicePartie,
				function () {
					loadProducts();
				}
			);
		} else {
			edi.utils.loadPartie(
				data,
				'Invoice-Parties.ShipFrom',
				'Invoice-Parties.ShipFrom.TaxID',
				'inn',
				convertOrgToInvoicePartie,
				loadProducts
			);
		}
	};
	var loadProducts = function () {
		var products = edi.utils.getObjectProperty(data, 'Invoice-Lines.Line', true);
		for (var i = 0; i < products.length; i++) {
			var product = edi.utils.getObjectProperty(products[i], 'Line-Item');
			if (product) {
				var net = parseFloat(product.InvoiceUnitNetPrice || 0);
				var tax = parseFloat(product.TaxRate || 0);
				var amount = parseFloat(product.InvoiceQuantity || 0);
				var taxValue = (net * tax) / 100;
				product.InvoiceUnitGrossPrice = edi.utils.roundTo(net + taxValue, 4);
				product.NetAmount = edi.utils.roundTo(net * amount, 4);
				product.TaxAmount = edi.utils.roundTo(taxValue * amount, 4);
				product.GrossAmount = edi.utils.roundTo(net * amount + taxValue * amount, 4);
			}
		}
		callback ? callback() : null;
	};

	loadSeller();
};

const createInvoice = function (header, parties, lines, summary, signer) {
	return {
		'Invoice-Header': header,
		'Invoice-Parties': parties,
		'Invoice-Lines': lines,
		'Invoice-Summary': summary,
		Signer: signer
	};
};

const createInvoiceHeader = function (config) {
	config = config ? config : {};
	config.Delivery.id ? delete config.Delivery.id : null;

	return {
		InvoiceNumber: config.InvoiceNumber,
		InvoiceDate: config.InvoiceDate,
		InvoiceVersionNumber: config.InvoiceVersionNumber,
		InvoiceVersionDate: config.InvoiceVersionDate,
		SalesDate: config.SalesDate,
		InvoiceCurrency: config.InvoiceCurrency,
		InvoiceCurrencyLocal: config.InvoiceCurrencyLocal,
		InvoiceCurrencyCode: config.InvoiceCurrencyCode,
		InvoicePaymentDueDate: config.InvoicePaymentDueDate,
		InvoicePaymentTerms: config.InvoicePaymentTerms,
		InvoicePaymentMode: config.InvoicePaymentMode,
		InvoicePostDate: config.InvoicePostDate,
		DocumentFunctionCode: config.DocumentFunctionCode,
		FnsEnable: 'N',
		DocumentNameCode: config.DocumentNameCode,
		TransactionType: config.TransactionType,
		Remarks: config.Remarks,
		PaymentReferenceNumber: config.PaymentReferenceNumber,
		Order: config.Order,
		Reference: config.Reference,
		Factoring: config.Factoring,
		Delivery: config.Delivery
	};
};

const createInvoiceDelivery = function (config, deliveryExcludedFields) {
	config = config ? config : {};
	deliveryExcludedFields = deliveryExcludedFields ? deliveryExcludedFields : [];
	config.DeliveryLocationNumber = config.DeliveryLocationNumber || config.ILN;
	delete config.ILN;

	return createInvoicePartie(config, deliveryExcludedFields);
};

const createInvoiceParties = function (buyer, seller, shipFrom, payer, invoicee, payee) {
	buyer = buyer ? buyer : {};
	seller = seller ? seller : {};
	shipFrom = shipFrom ? shipFrom : {};
	payer = payer ? payer : {};
	invoicee = invoicee ? invoicee : {};
	payee = payee ? payee : {};

	return {
		Buyer: buyer,
		Payer: payer,
		Invoicee: invoicee,
		Seller: seller,
		Payee: payee,
		ShipFrom: shipFrom
	};
};

const createInvoiceLineItem = function (config) {
	config = config ? config : {};

	return {
		LineNumber: config.LineNumber,
		EAN: config.EAN,
		BuyerItemCode: config.BuyerItemCode,
		SupplierItemCode: config.SupplierItemCode,
		ItemDescription: config.ItemDescription,
		ItemType: config.ItemType,
		PreviousInvoiceQuantity: config.PreviousInvoiceQuantity,
		InvoiceQuantity: config.InvoiceQuantity,
		OrderedQuantity: config.OrderedQuantity,
		PreviousInvoiceUnitNetPrice: config.PreviousInvoiceUnitNetPrice,
		InvoiceUnitNetPrice: config.InvoiceUnitNetPrice,
		BuyerUnitNetPrice: config.BuyerUnitNetPrice,
		Discount: config.Discount,
		InvoiceUnitBasePrice: config.InvoiceUnitBasePrice,
		InvoiceUnitGrossPrice: config.InvoiceUnitGrossPrice,
		BuyerUnitGrossPrice: config.BuyerUnitGrossPrice,
		PreviousUnitOfMeasure: config.PreviousUnitOfMeasure,
		PreviousUnitOfMeasureCode: config.PreviousUnitOfMeasureCode,
		UnitOfMeasure: config.UnitOfMeasure,
		UnitOfMeasureCode: config.UnitOfMeasureCode,
		InvoicedUnitPackSize: config.InvoicedUnitPackSize,
		SupplierUnitPackSize: config.SupplierUnitPackSize,
		PreviousTaxRate: config.PreviousTaxRate,
		TaxRate: config.TaxRate,
		TaxCategoryCode: config.TaxCategoryCode,
		TaxReference: {
			ReferenceType: edi.utils.getObjectProperty(config, 'TaxReference.ReferenceType'),
			ReferenceNumber: edi.utils.getObjectProperty(config, 'TaxReference.ReferenceNumber')
		},
		TaxReferenceNumber: config.TaxReferenceNumber,
		PreviousTaxAmount: config.PreviousTaxAmount,
		TaxAmount: config.TaxAmount,
		DifferenceTaxAmount: config.DifferenceTaxAmount,
		PreviousExciseTax: config.PreviousExciseTax,
		ExciseTax: config.ExciseTax,
		DifferenceExciseTax: config.DifferenceExciseTax,
		PreviousNetAmount: config.PreviousNetAmount,
		NetAmount: config.NetAmount,
		DifferenceNetAmount: config.DifferenceNetAmount,
		PreviousGrossAmount: config.PreviousGrossAmount,
		GrossAmount: config.GrossAmount,
		DifferenceGrossAmount: config.DifferenceGrossAmount,
		BatchNumber: config.BatchNumber,
		SerialNumber: config.SerialNumber,
		ExpirationDate: config.ExpirationDate,
		Remarks: config.Remarks
	};
};

const createInvoiceLines = function (lines, delivery, deliveryExcludedFields) {
	lines = lines ? lines : [];
	deliveryExcludedFields = deliveryExcludedFields ? deliveryExcludedFields : [];
	var invoiceLines = [];
	edi.utils.each(lines, function (line) {
		var lineObj = {};
		if (line[edi.constants.INTERNAL_LINE_ID]) {
			lineObj[edi.constants.INTERNAL_LINE_ID] = line[edi.constants.INTERNAL_LINE_ID];
		}
		delete line[edi.constants.INTERNAL_LINE_ID];
		lineObj['Line-Item'] = createInvoiceLineItem(line);
		lineObj['Line-Delivery'] = createInvoiceDelivery(delivery, deliveryExcludedFields);
		invoiceLines.push(lineObj);
	});

	return {
		Line: invoiceLines
	};
};
const createInvoiceTaxSummaryLine = function (config) {
	config = config ? config : {};

	return {
		TaxRate: edi.utils.roundTo(config.TaxRate, 2) || 0,
		TaxCategoryCode: config.TaxCategoryCode,
		TaxAmount: edi.utils.roundTo(config.TaxAmount, 4) || 0,
		TaxableAmount: edi.utils.roundTo(config.NetAmount, 4) || 0
	};
};

const createInvoiceSummary = function (config, productValues) {
	config = config ? config : {};

	var totalLines = productValues.TotalLines,
		totalNetAmount = productValues.TotalNetAmount,
		totalTaxAmount = productValues.TotalTaxAmount,
		totalGrossAmount = productValues.TotalGrossAmount,
		TotalInvoicedAmount = productValues.TotalInvoicedAmount,
		taxSummaryLines = createInvoiceTaxSummaryLines(productValues);

	return {
		TotalLines: totalLines,
		TotalInvoicedAmount: TotalInvoicedAmount,
		TotalNetAmount: totalNetAmount,
		TotalTaxAmount: totalTaxAmount,
		TotalRounding: config.TotalRounding,
		TotalGrossAmount: totalGrossAmount,
		TotalAmountDue: config.TotalAmountDue,
		TotalNetWeight: config.TotalNetWeight,
		TotalGrossWeight: config.TotalGrossWeight,
		TotalDiscount: config.TotalDiscount,
		TotalDiscountAmount: config.TotalDiscountAmount,
		TotalBaseAmount: config.TotalBaseAmount,
		TotalNetAmountLC: config.TotalNetAmountLC,
		TotalTaxAmountLC: config.TotalTaxAmountLC,
		TotalGrossAmountLC: config.TotalGrossAmountLC,
		IncreaseTotalNetAmount: config.IncreaseTotalNetAmount,
		IncreaseTotalTaxAmount: config.IncreaseTotalTaxAmount,
		IncreaseTotalGrossAmount: config.IncreaseTotalGrossAmount,
		DecreaseTotalNetAmount: config.DecreaseTotalNetAmount,
		DecreaseTotalTaxAmount: config.DecreaseTotalTaxAmount,
		DecreaseTotalGrossAmount: config.DecreaseTotalGrossAmount,
		'Tax-Summary': {
			'Tax-Summary-Line': taxSummaryLines
		}
	};
};

const createInvoiceTaxSummaryLines = function (productValues) {
	var lines = [],
		findSame = function (line) {
			var same = null;
			edi.utils.each(lines, function (item) {
				if (item.TaxCategoryCode == line.TaxCategoryCode && item.TaxRate == line.TaxRate) {
					same = item;
					return true;
				}
			});
			return same;
		};

	edi.utils.each(productValues.products, function (line) {
		var summaryLine = createInvoiceTaxSummaryLine(line);
		var same = findSame(summaryLine);
		if (same) {
			!same.TaxAmount ? (same.TaxAmount = 0) : null;
			!same.TaxableAmount ? (same.TaxableAmount = 0) : null;
			same.TaxAmount = edi.utils.roundTo(same.TaxAmount + (summaryLine.TaxAmount || 0), 4);
			same.TaxableAmount = edi.utils.roundTo(same.TaxableAmount + (summaryLine.TaxableAmount || 0), 4);
		} else {
			lines.push(summaryLine);
		}
	});

	return lines;
};
const createInvoiceSigner = function (config) {
	config = config ? config : {};

	return {
		TaxID: config.TaxID,
		Requisites: config.Requisites,
		JobPosition: config.JobPosition,
		FirstName: config.FirstName,
		LastName: config.LastName,
		PatronymicName: config.PatronymicName
	};
};
/**
 * fields for all invoice parties and delivery
 */
const createInvoicePartie = function (config, deliveryExcludedFields) {
	config = config ? config : {};
	deliveryExcludedFields = deliveryExcludedFields ? deliveryExcludedFields : [];

	if (!config.StateCode && config.State) {
		config.StateCode = edi.utils.getRegion(null, config.State).id;
	}

	var fields = {
		ILN: config.ILN,
		DeliveryLocationNumber: config.DeliveryLocationNumber,
		CodeBySender: config.CodeBySender,
		CodeByReciever: config.CodeByReciever,
		TaxID: config.TaxID,
		TaxRegistrationReasonCode: config.TaxRegistrationReasonCode,
		AccountNumber: config.AccountNumber,
		UtilizationRegisterNumber: config.UtilizationRegisterNumber,
		CodeBySeller: config.CodeBySeller,
		CodeByBuyer: config.CodeByBuyer,
		Name: config.Name,
		RoomNumber: config.RoomNumber,
		Housing: config.Housing,
		HouseNumber: config.HouseNumber,
		StreetAndNumber: config.StreetAndNumber,
		CityName: config.CityName,
		AdditionalInformation: config.AdditionalInformation,
		Locality: config.Locality,
		District: config.District,
		State: config.State,
		Type: config.Type,
		StateCode: config.StateCode,
		PostalCode: config.PostalCode,
		Country: config.Country,
		CountryCode: config.CountryCode,
		Individual: {
			FirstName: edi.utils.getObjectProperty(config, 'Individual.FirstName'),
			LastName: edi.utils.getObjectProperty(config, 'Individual.LastName'),
			PatronymicName: edi.utils.getObjectProperty(config, 'Individual.PatronymicName')
		},
		DeliveryDate: config.DeliveryDate,
		DespatchNumber: config.DespatchNumber,
		BuyerLocationCode: config.BuyerLocationCode,
		SellerLocationCode: config.SellerLocationCode,
		SalesRepresentative: {
			SystemUniqueCode: edi.utils.getObjectProperty(config, 'SalesRepresentative.SystemUniqueCode'),
			Name: edi.utils.getObjectProperty(config, 'SalesRepresentative.Name'),
			TelephoneNumber: edi.utils.getObjectProperty(config, 'SalesRepresentative.TelephoneNumber'),
			'E-mail': edi.utils.getObjectProperty(config, 'SalesRepresentative.E-mail'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'SalesRepresentative.CodeBySeller')
		},
		OperatorDetails: {
			Name: edi.utils.getObjectProperty(config, 'OperatorDetails.Name'),
			TelephoneNumber: edi.utils.getObjectProperty(config, 'OperatorDetails.TelephoneNumber'),
			'E-mail': edi.utils.getObjectProperty(config, 'OperatorDetails.E-mail')
		},
		ContactInformation: {
			Contact: {
				ContactFunctionCode: edi.utils.getObjectProperty(
					config,
					'ContactInformation.Contact.ContactFunctionCode'
				),
				Name: edi.utils.getObjectProperty(config, 'ContactInformation.Contact.Name'),
				TelephoneNumber: edi.utils.getObjectProperty(config, 'ContactInformation.Contact.TelephoneNumber'),
				'E-mail': edi.utils.getObjectProperty(config, 'ContactInformation.Contact.E-mail'),
				CodeBySender: edi.utils.getObjectProperty(config, 'ContactInformation.Contact.CodeBySender')
			}
		}
	};

	deliveryExcludedFields.forEach(function (item) {
		delete fields[item];
	});

	return fields;
};

export {
	processInvoice,
	createInvoice,
	createInvoiceHeader,
	createInvoiceParties,
	createInvoiceLines,
	createInvoiceSummary,
	createInvoiceTaxSummaryLines,
	createInvoiceSigner,
	createInvoicePartie
};
