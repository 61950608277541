const ORDRSP_LINE_MODEL = 'ORDRSP_LINE';
const ORDRSP_SUMMARY_MODEL = 'ORDRSP_SUMMARY';

edi.models.setModels({
	[ORDRSP_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'SubstituteEAN',
				type: 'string'
			},
			{
				name: 'BuyerSubstituteItemCode',
				type: 'string'
			},
			{
				name: 'SupplierSubstituteItemCode',
				type: 'string'
			},
			{
				name: 'SubstituteItemDescription',
				type: 'string'
			},
			{
				name: 'ItemStatus',
				type: 'string'
			},
			{
				name: 'ItemType',
				type: 'string'
			},
			{
				name: 'OrderedQuantity',
				type: 'string',
				defaultValue: '0'
			},
			{
				name: 'AllocatedDelivered',
				type: 'string'
			},
			{
				name: 'RecommendedQuantity',
				type: 'string'
			},
			{
				name: 'QuantityToBeDelivered',
				type: 'string'
			},
			{
				name: 'QuantityDifference',
				type: 'string'
			},
			{
				name: 'OrderedUnitPacksize',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'OrderedUnitNetPrice',
				type: 'string'
			},
			{
				name: 'OrderedUnitGrossPrice',
				type: 'string'
			},
			{
				name: 'SuggestedPrice',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'Discount',
				type: 'string'
			},
			{
				name: 'NetAmount',
				type: 'string'
			},
			{
				name: 'TaxAmount',
				type: 'string'
			},
			{
				name: 'GrossAmount',
				type: 'string'
			},
			{
				name: 'ExpirationDate',
				type: 'string'
			},
			{
				name: 'ExpectedDeliveryDate',
				type: 'string'
			},
			{
				name: 'ExpectedDeliveryTime',
				type: 'string'
			},
			{
				name: 'SpecialConditionCode',
				type: 'string'
			},
			{
				name: 'Model',
				type: 'string'
			},
			{
				name: 'ReasonCode',
				type: 'string'
			},
			{
				name: 'Reference-Elements'
				//type: 'array'
			},
			{
				name: 'Line-Reference'
				//type: 'object'
			},
			{
				name: 'Reference-Element'
				//type: 'object'
			},
			{
				name: 'Reference-Date',
				type: 'string'
			},
			{
				name: 'Reference-Id',
				type: 'string'
			}
		],
		idProperty: 'LineNumber'
	},
	[ORDRSP_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'TotalLines',
				type: 'number'
			},
			{
				name: 'TotalAmount',
				type: 'string'
			},
			{
				name: 'TotalNetAmount',
				type: 'string'
			},
			{
				name: 'TotalTaxAmount',
				type: 'string'
			},
			{
				name: 'TotalGrossAmount',
				type: 'float'
			}
		]
	}
});

export { ORDRSP_LINE_MODEL, ORDRSP_SUMMARY_MODEL };
