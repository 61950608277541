import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createCreateSaveButton } from '@Components/buttons';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createButtonContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

/**
 * @author Anastasiya Zholudeva
 * Class for creation delfor document
 */
Ext.namespace('edi.modules');
edi.modules['document.create.delfor'] = function () {
	var moduleData,
		documentData,
		documentValues = {},
		form,
		productsGrid,
		isEdit = true,
		createButton,
		valueMap,
		tableValueMap,
		productValues,
		toOrgId;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	var createModuleForm = function () {
		var docHeaderConfig = {
			noSumm: true,
			noUsage: true,
			noPackage: true,
			noAperakStatus: true
		};
		var headPanel = createDocumentHeaderPanel(moduleData.initData, docHeaderConfig);

		var headData1 = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.details.logset.number'),
					input: createLabel({
						columnWidth: 0.7333,
						text: documentValues.number,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.5,
					text: documentValues.date,
					valueLabel: true,
					srcFormat: 'Ymd'
				})
			]
		});

		var headData2 = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.details.logset.origin'),
					input: createLabel({
						columnWidth: 0.7333,
						text: documentValues.originNumber,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.6
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.4,
					text: documentValues.originDate,
					valueLabel: true,
					srcFormat: 'Ymd'
				})
			]
		});

		var retail = documentValues.retail;
		switch (retail) {
			case '1':
				retail = edi.i18n.getMessage('document.details.logset.retail1');
				break;
			case '2':
				retail = edi.i18n.getMessage('document.details.logset.retail2');
				break;
			case '3':
				retail = edi.i18n.getMessage('document.details.logset.retail3');
				break;
		}
		var retailField = createField({
			title: edi.i18n.getMessage('document.details.logset.retail'),
			containerConfig: {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			},
			input: createLabel({
				columnWidth: 0.86,
				text: retail,
				valueLabel: true
			})
		});

		var seller = createCompanySelector({
			title: 'document.seller',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id
			}),
			is_valid: true,
			fieldValues: moduleData.initData.data.toOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.toOrg)
				: null,
			readOnly: true
		});

		var buyer = createCompanySelector({
			title: 'document.buyer',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id
			}),
			is_valid: true,
			fieldValues: moduleData.initData.data.fromOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.fromOrg)
				: null,
			readOnly: true
		});

		var twoColumnsLayout = createTwoColumnsLayout([headData1, retailField, seller], [headData2, buyer], 0.5);

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'EDI_DELFOR_LINE',
			gridColumnConfig: 'edi_delfor_lines',
			data: documentValues.lines,
			hasTotal: false,
			readOnly: false,
			disableRemoveLines: true,
			hideAddButton: true,
			configModalButtonsBefore: function () {
				return [];
			},
			isValidRecord: function (record) {
				return !!record.get('OrderCalenderCode');
			},
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			totalsHandler: function (component) {
				component.callback(component.getValues());
			},
			modalFormConfig: {
				title: 'document.delfor.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.delfor.settings.tab'),
						items: [
							{
								title: edi.i18n.getMessage('column.line.number'),
								name: 'LineNumber',
								readOnly: true
							},
							{
								title: edi.i18n.getMessage('column.artikul.buyer'),
								name: 'BuyerCode',
								readOnly: true
							},
							{
								title: edi.i18n.getMessage('column.supplier.comment'),
								name: 'SupplierComment'
							},
							{
								title: edi.i18n.getMessage('column.order.calendar.code'),
								name: 'OrderCalenderCode',
								allowBlank: false,
								type: 'combo',
								store: edi.stores.initDelforCalendarCodeStore(),
								valueField: 'Delshchedulecode',
								displayField: 'Delshchedulename'
							},
							{
								title: edi.i18n.getMessage('column.delivery.calendar.code'),
								name: 'DeliveryCalenderCode',
								type: 'combo',
								store: edi.stores.initDelforCalendarCodeStore(),
								valueField: 'Delshchedulecode',
								displayField: 'Delshchedulename',
								allowBlank: true
							},
							{
								title: edi.i18n.getMessage('column.exeption.calendar.code'),
								name: 'ExeptionCalenderCode',
								type: 'combo',
								store: edi.stores.initDelforCalendarCodeStore(),
								valueField: 'Delshchedulecode',
								displayField: 'Delshchedulename',
								allowBlank: true
							},
							{
								title: edi.i18n.getMessage('column.delivery.terms'),
								name: 'DeliveryTerms',
								allowBlank: false
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.delivery.point'),
						items: [
							{
								title: edi.i18n.getMessage('column.delivery.point.iln'),
								name: 'DeliveryPointIln',
								readOnly: true
							},
							{
								title: edi.i18n.getMessage('column.delivery.chain.name'),
								name: 'ChainName',
								readOnly: true
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.ship.from'),
						items: [
							{
								type: 'editableGrid',
								name: 'ShipmentPoint',
								config: {
									readOnly: true,
									columnsConfig: 'edi_delfor_shipmentpoint',
									limit: undefined,
									model: 'EDI_DELFOR_SHIPMENTPOINT',
									gridConfig: {
										cls: 'modal-grid',
										minHeight: 200
									}
								}
							}
						]
					}
				]
			}
		});

		createButton = createCreateSaveButton(
			{
				formBind: true,
				disabled: true,
				handler: function () {
					var form = this.up('form').getForm();
					if (form.isValid()) {
						save();
					}
				}
			},
			isEdit
		);

		form = createForm({
			cls: 'edi-form document-add-form',
			autoScroll: true,
			bodyPadding: 10,
			buttons: createButtonContainer({
				items: [createButton]
			}),
			items: [headPanel, twoColumnsLayout, productsGrid]
		});

		form.on('validitychange', checkValid);
		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var valid = false;
		var hasInvalid = form.hasInvalidField();
		var validGrid = productsGrid.isValid();
		if (!createButton.disabled && (hasInvalid || !validGrid)) {
			createButton.setDisabled(true);
		} else if (createButton.disabled && !hasInvalid && validGrid) {
			createButton.setDisabled(false);
		}
		if (!hasInvalid && validGrid) {
			valid = true;
		}
		return valid;
	};

	/**
	 * Saves document
	 */
	var save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var docData = Ext.clone(documentData);
		var userOrgId = edi.core.getUserOrgID();

		edi.utils.setObjectProperty(docData, 'bgm.e1225', 4);
		edi.utils.setObjectProperty(docData, 'bgm.c002.e1001', 291);

		var tableValueMapEdit = {
			// @formatter:off
			LineNumber: 'sg12.lin.e1082',
			SupplierCommentZZZ: 'sg12.ftx.e4451',
			SupplierComment: 'sg12.ftx.c108.e4440',
			OrderCalenderCode: 'sg12.sg13[rff.c506.e1153==AAJ]rff.c506.e1154',
			DeliveryCalenderCode: 'sg12.sg13[rff.c506.e1153==AAN]rff.c506.e1154',
			ExeptionCalenderCode: 'sg12.sg13[rff.c506.e1153==EXC]rff.c506.e1154',
			DeliveryTerms: 'sg12.sg17.sg18.qty.0.c186.e6060',
			DeliveryPointIln: 'sg7.nad.c082.e3039',
			ChainName: 'sg7.ftx.c108.e4440'
		};
		var clearEmptyNode = {
			SupplierComment: 'sg12.ftx',
			DeliveryCalenderCode: 'sg12.sg13[rff.c506.e1153==AAN]',
			ExeptionCalenderCode: 'sg12.sg13[rff.c506.e1153==EXC]'
		}; // @formatter:on

		if (userOrgId === toOrgId) {
			tableValueMap = tableValueMapEdit;
		}
		if (productValues) {
			productValues.products.forEach(function (item, index) {
				if (userOrgId === toOrgId && item.SupplierComment) {
					item.SupplierCommentZZZ = 'ZZZ';
				}
				Object.entries(tableValueMap).forEach(function ([name, path]) {
					edi.utils.setObjectPropertyEx(docData.sg6[index], path, item[name]);
					if (
						clearEmptyNode.hasOwnProperty(name) &&
						'' === edi.utils.getObjectPropertyEx(docData.sg6[index], path)
					) {
						docData.sg6[index] = edi.utils.deleteObjectPropertyEx(docData.sg6[index], clearEmptyNode[name]);
					}
				});
			});
		}
		var excludeValue = ['creationDate', 'header', 'id', 'modifyDate', 'version'];
		excludeValue.forEach(function (excludeItem) {
			delete docData[excludeItem];
		});

		var headerData = {
			data: Ext.encode(docData),
			number: edi.utils.getObjectProperty(docData, 'bgm.c106.e1004')
		};
		var success = function (data) {
			moduleData.isChanged = false;
			moduleData.tab.setLoading(false);
			edi.document.actions.documentCreateProcessing(moduleData, data, isEdit);
			if (isEdit) {
				edi.events.documents.fireEvent('change', {
					id: moduleData.initData.data.id
				});
			} else {
				edi.events.documents.fireEvent('create');
			}
		};
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.DELFOR
		);

		var updateData = function () {
			edi.document.actions.processDocument(
				moduleData.initData.data.toOrg.id,
				null,
				edi.constants.DOCUMENT_TYPES.DELFOR,
				null,
				moduleData.initData.id,
				success,
				failure,
				headerData,
				{
					editUri: edi.rest.services.DOCUMENTS.SEND.PUT
				}
			);
		};

		if (
			edi.constants.STATE.SENT === moduleData.initData.data.state ||
			edi.constants.STATE.RECEIVER_REVIEW === moduleData.initData.data.state
		) {
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.SEND.PUT,
					{
						documentId: moduleData.initData.id
					},
					true
				),
				'PUT',
				Ext.encode({
					UPDATE: true
				}),
				function (resp) {
					if (resp.data && resp.success) {
						updateData();
					}
				},
				failure
			);
		} else {
			updateData();
		}
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		if (data && data.toOrg && data.toOrg.id) {
			toOrgId = data.toOrg.id;
		}

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var continueLoading = function () {
			valueMap = {
				// @formatter:off
				number: 'bgm.c106.e1004',
				date: 'dtm.c507[e2005==137]e2380',
				originNumber: 'sg1[rff.c506.e1153==ALM]rff.c506.e1154',
				originDate: 'sg1[dtm.c507.e2005==171]dtm.c507.e2380',
				retail: 'sg1[rff.c506.e1153==PB]rff.c506.e1154',
				seller: 'sg2.nad[e3035==SU]c082',
				buyer: 'sg2.nad[e3035==BY]c082'
			};
			tableValueMap = {
				LineNumber: 'sg12.lin.e1082',
				BuyerCode: 'sg12.pia.c212.e7140',
				SupplierComment: 'sg12.ftx.c108.e4440',
				OrderCalenderCode: 'sg12.sg13[rff.c506.e1153==AAJ]rff.c506.e1154',
				DeliveryCalenderCode: 'sg12.sg13[rff.c506.e1153==AAN]rff.c506.e1154',
				ExeptionCalenderCode: 'sg12.sg13[rff.c506.e1153==EXC]rff.c506.e1154',
				DeliveryTerms: 'sg12.sg17.sg18.qty.0.c186.e6060',
				DeliveryPointIln: 'sg7.nad.c082.e3039',
				ChainName: 'sg7.ftx.c108.e4440',
				ShipmentPoint: 'sg12.sg22'
			}; // @formatter:on

			Object.entries(valueMap).forEach(function ([name, path]) {
				documentValues[name] = edi.utils.getObjectPropertyEx(documentData, path);
			});
			documentValues.lines = [];
			var lines = edi.utils.getObjectProperty(documentData, 'sg6');
			lines?.forEach(function (line) {
				var lineValues = {};
				Object.entries(tableValueMap).forEach(function ([name, path]) {
					lineValues[name] = edi.utils.getObjectPropertyEx(line, path);
				});
				documentValues.lines.push(lineValues);
			});

			var modulePanel = createAddModulePanel({
				items: [createModuleForm()]
			});

			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);

			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
				documentId: data.id
			}),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					documentData = data.data;
					continueLoading();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
