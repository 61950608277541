import { createModulePanel, createPanel } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { invitationsFilter } from './filter';

Ext.namespace('edi.modules');
/**
 * Class for invitations
 * @author Pavel Pirogov
 */
edi.modules['invitations'] = function () {
	var moduleData, fireSearch, filterForm, filterObject;
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.invitation.on('change', changeHandler);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'fit',
			region: 'center',
			items: [createLayout()]
		});
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};
	/**
	 * Create module grid
	 * @returns {Object}
	 */
	var createLayout = function () {
		var columns = edi.columns.get('invitations');
		var items = [];
		items.push({
			glyph: edi.constants.ICONS.DETAILS,
			handler: function (grid, rowIndex) {
				var recordData = grid.getStore().getAt(rowIndex).getData();
				edi.core.openModule('invitation.details', recordData, recordData.id, false);
			}
		});
		if (items.length) {
			columns = columns.concat(
				createActionsColumnConfig({
					align: 'center',
					items: items,
					width: edi.utils.getActionColumnWidth(items.length)
				})
			);
		}

		fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(
					edi.rest.services.INVITATION.FILTER.GET,
					filterForm,
					grid,
					invitationsFilter.createArgs
				);
			}
			filterObject.filter();
		};
		let { formItemsMap, items } = invitationsFilter.createFormItems();
		filterForm = createModuleFilterForm(
			{
				formItemsMap,
				items
			},
			fireSearch
		);

		var grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: edi.models.getModel('INVITATION'),
				autoLoad: false,
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: 'id',
					direction: 'DESC'
				}
			},
			gridConfig: {
				cls: 'tab-grid with-filter',
				columns: columns,
				region: 'center',
				border: 0,
				autoScroll: true,
				disableSelection: true,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						var recordData = record.getData();
						edi.core.openModule('invitation.details', recordData, recordData.id, false);
					},
					render: fireSearch
				}
			}
		});

		return createPanel({
			layout: 'border',
			items: [filterForm, grid]
		});
	};
	var changeHandler = function () {
		fireSearch();
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.events.invitation.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
