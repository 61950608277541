import { createActionsPanel, createDetailsModulePanel } from '@Components/panels';
import { createActionsButton } from '@Components/buttons';
import { createModalPanel } from '@UIkit/components/modal';
import { createFieldBlock, FIELD_BLOCK_CLS, createContainer, createModalForm } from '@UIkit/components/panels';
import {
	getPartItem,
	getStatusInSidebar,
	getExecutorInSigners,
	checkExecutorMatchesApprovalStage,
	getApprovalDocumentTitle,
	isCurrentEmployeeExecutor,
	getDSFDetailPanel,
	getUPDDetailPanel,
	getUKDDetailPanel,
	openApprovalPreviewModal
} from '../methods';
import { createSidebar } from '@UIkit/components/sidebar/Sidebar';
import { createTextField, createCombo } from '@UIkit/components/fields';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createOrgAutocomplete } from '@Ediweb/components/OrgAutocomplete/OrgAutocomplete';
import { createProxyConfig } from '@Components/storeComponents';
import { showConfirm } from '@UIkit/components/modal/MessageBox';

Ext.namespace('edi.modules');
edi.modules['approval.employees.details'] = function () {
	var moduleData,
		imagesData = [],
		isImagesLoaded,
		isImagesLoadedError,
		parentDocId,
		userData = edi.core.getUserData(),
		sidebar,
		parentDocData,
		selectedPoa,
		showCorrectBtn = false;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.approval_documents.on('change', changeHandler);
	};
	/**
	 * POA handlers object
	 * @type {{get: Function, set: Function}}
	 */
	var poaHandler = {
		get: function () {
			return selectedPoa;
		},
		set: function (poa) {
			selectedPoa = poa;
		}
	};
	/**
	 * Sets 'correction' text into title of tab
	 */
	let updateTabTitle = function () {
		if (edi.utils.getAttributeByName(moduleData.initData.data?.attributes, 'CORRECTION') === 'true') {
			let tabTitleMsg = `${edi.i18n.getMessage('ediweb.approval.correct.title')} - ${
				moduleData.initData.data.title
			}`;
			moduleData.tab.module.title = tabTitleMsg;
			moduleData.tab.setTitle(tabTitleMsg);
			moduleData.tab.tab.setTooltip(tabTitleMsg);
			moduleData.tab.tab.setText(tabTitleMsg);
		}
	};

	/**
	 * Creates detail panel
	 * @returns {Object}
	 */
	var createDetailsPanel = function (documentData) {
		const parentDocType =
			edi.utils.getAttributeByName(documentData.attributes, 'PARENT_DOC_TYPE') ?? documentData?.data?.docType;
		if (parentDocType.match(/^DSF_/)) {
			const options = {
				isImagesLoaded,
				imagesData,
				isImagesLoadedError
			};
			return getDSFDetailPanel(documentData, moduleData, parentDocData, openSideBar, options);
		} else if (parentDocType.match(/^EDI_FNS_UPD/)) {
			return getUPDDetailPanel(documentData, moduleData, parentDocData, openSideBar);
		} else if (parentDocType.match(/^EDI_FNS_UKD/)) {
			return getUKDDetailPanel(documentData, moduleData, parentDocData, openSideBar);
		}
	};

	var revokeModal = function (documentData, actionName, callback) {
		var revokeBtn;
		var reason = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			title: edi.i18n.getMessage(`ew.documents.approvals.${actionName}.title`),
			items: createTextField({
				isTextarea: true,
				emptyText: edi.i18n.getMessage(`ew.documents.approvals.${actionName}.emptyText`),
				name: 'reason',
				rowsHtmlAttributeValue: 3,
				autoScroll: true,
				allowBlank: false,
				listeners: {
					change: function (comp) {
						revokeBtn.setDisabled(!comp.isValid());
					}
				}
			})
		});

		var formPanel = createModalForm({
			submitEmptyText: false,
			items: [reason]
		});

		var modal = createModalPanel({
			title: edi.i18n.getMessage(`ew.documents.approvals.${actionName}.modal.title`),
			items: [formPanel],
			listeners: {
				close: function () {
					moduleData.tab.setLoading(false);
				}
			},
			buttonsBefore: [
				(revokeBtn = createButton({
					text: edi.i18n.getMessage(`ediweb.form.btn.${actionName}`),
					cls: BUTTON_CLS.primary,
					handler: function () {
						var failure = function (data) {
							edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
								moduleData.tab.setLoading(false);
							});
						};
						var form = formPanel.getForm();
						if (!form.isValid()) {
							edi.core.showError(`ew.documents.approvals.${actionName}.reason.fill.fields.error`);
						} else {
							var values = edi.utils.collectFormValues(form);
							modal.close();

							var upperCaseActionName = actionName.toUpperCase();
							var data = {};
							data[upperCaseActionName] = true;
							data[upperCaseActionName + '_REASON'] = values.reason;

							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS[upperCaseActionName].PUT, {
									id: documentData.id
								}),
								'PUT',
								Ext.encode(data),
								function () {
									moduleData.tab.setLoading(false);
									edi.events.approval_documents.fireEvent('change', {
										id: documentData.id
									});
									if (parentDocId) {
										edi.events.documents.fireEvent('change', { id: parentDocId });
									}
									if (typeof callback === 'function') {
										callback();
									}
								},
								failure,
								null
							);
						}
					}
				}))
			],
			closeButtonOptions: {
				text: edi.i18n.getMessage('button.cancel'),
				cls: 'edi-ediweb-button-secondary',
				handler: function () {
					modal.close();
				}
			}
		});
		formPanel.isValid();
		modal.show();
	};

	var reassignModal = function (documentData) {
		var employee = createFieldBlock({
			title: edi.i18n.getMessage('org.profile.routes.responsible.employee'),
			items: [
				createOrgAutocomplete(
					{
						name: 'employee',
						queryMode: 'remote',
						queryParam: 'name',
						displayField: 'displayname',
						valueField: 'id',
						forceSelection: true,
						anyMatch: true,
						allowBlank: false,
						listeners: {
							beforeQuery: function (plan) {
								plan.query = plan.query.trim();
							},
							afterrender: function (cmp) {
								const picker = cmp.getPicker();

								picker.on('viewlist', function (__self, record, itemEl) {
									const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

									var tpl = new Ext.XTemplate(
										'<tpl if="position">',
										'<span class="{[this.$comp.cls]}-text">{position}</span>',
										'</tpl>'
									);
									tpl.$comp = cmp;
									var el = tpl.append(wrapEl, record.getData());
								});
							}
						}
					},
					{
						sorters: [],
						proxy: createProxyConfig({
							type: 'ajax',
							url: edi.utils.compileURL(edi.rest.services.ORG_PROFILE.EMPLOYEES.GET, {
								gridName: 'active'
							})
						}),
						model: edi.models.getModel('EW_EMPLOYEES')
					}
				)
			]
		});

		var actions = createFieldBlock({
			items: [
				createCombo({
					name: 'actions',
					fieldLabel: edi.i18n.getMessage('ediweb.employees.actions'),
					allowBlank: false,
					store: edi.stores.createSimpleInlineStore(
						['APPROVAL_REFUSAL', 'SIGNING_REFUSAL'],
						function (id) {
							return edi.i18n.getMessage('org.profile.routes.actions.' + id);
						},
						false
					),
					forceSelection: true,
					anyMatch: true
				})
			]
		});

		var comment = createFieldBlock({
			cls: FIELD_BLOCK_CLS.small,
			items: [
				createTextField({
					fieldLabel: edi.i18n.getMessage('ew.documents.approvals.comment'),
					name: 'reasignComment',
					maxLength: 1000,
					rowsHtmlAttributeValue: 6,
					isTextarea: true
				})
			]
		});

		var formPanel = createModalForm({
			submitEmptyText: false,
			layout: {
				type: 'grid',
				gap: [24],
				area: [12]
			},
			items: [employee, actions, comment]
		});

		var modal = createModalPanel({
			title: edi.i18n.getMessage(`approval.document.APPROVAL_EMPLOYEES`),
			items: [formPanel],
			listeners: {
				close: function () {
					moduleData.tab.setLoading(false);
				}
			},
			buttonsBefore: [
				createButton({
					glyph: edi.constants.ICONS.SEND,
					text: edi.i18n.getMessage(`document.send.to.approve.document`),
					cls: BUTTON_CLS.primary,
					handler: function () {
						var failure = function (data) {
							edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
								moduleData.tab.setLoading(false);
							});
						};
						var form = formPanel.getForm();
						if (!form.isValid()) {
							return;
						} else {
							var modalValues = edi.utils.collectFormValues(form);
							if (modalValues.actions) {
								modalValues.actions = [modalValues.actions];
							}
							modal.close();

							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.REASSIGN.PUT, {
									id: documentData.id
								}),
								'PUT',
								Ext.encode(modalValues),
								function () {
									moduleData.tab.setLoading(false);
									edi.events.approval_documents.fireEvent('change', {
										id: documentData.id
									});
								},
								failure,
								null
							);
						}
					}
				})
			],
			closeButtonOptions: {
				text: edi.i18n.getMessage('button.cancel'),
				cls: BUTTON_CLS.secondary,
				handler: function () {
					modal.close();
				}
			}
		});
		formPanel.isValid();
		modal.show();
	};

	let createCompleteBtn = function (documentData) {
		return createActionsButton({
			order: 2,
			text: edi.i18n.getMessage('btn.complete'),
			glyph: edi.constants.ICONS.DONE_ALL,
			handler: function () {
				let failure = function (data) {
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
						moduleData.tab.setLoading(false);
					});
				};
				let confirm = showConfirm({
					title: 'approval.employees.complete.title',
					msgText: 'approval.employees.complete.msgText',
					buttons: [
						createButton({
							cls: BUTTON_CLS.primary,
							glyph: edi.constants.ICONS.DONE_ALL,
							text: edi.i18n.getMessage('btn.complete'),
							handler: function () {
								const url = edi.rest.services.APPROVAL_DOCUMENTS.COMPLETE.PUT;
								edi.rest.sendRequest(
									edi.utils.formatString(url, {
										id: documentData.id
									}),
									'PUT',
									Ext.encode({}),
									function () {
										edi.events.approval_documents.fireEvent('change', {
											id: documentData.id
										});
									},
									failure,
									function () {
										confirm.close();
									}
								);
							}
						}),
						createButton({
							cls: BUTTON_CLS.secondary,
							text: edi.i18n.getMessage('form.btn.cancel'),
							handler: function () {
								confirm.close();
							}
						})
					]
				});
			}
		});
	};

	let isCurrentOrgSender = function (documentData) {
		let isOutgoing = documentData.sender.userId === userData.id || documentData.sender.user?.id === userData.id;

		if (!isOutgoing && documentData.signers?.length) {
			isOutgoing = documentData.signers[0].id === userData.id;
		}
		return isOutgoing;
	};

	let getDirection = function (documentData) {
		return isCurrentOrgSender(documentData) ? edi.constants.DIRECTIONS.OUTGOING : edi.constants.DIRECTIONS.INCOMING;
	};
	/**
	 * Creates action panel above the data panel
	 */
	var createDocActionsPanel = function (documentData) {
		let document = moduleData.initData.data;
		let actionsPanel = createActionsPanel();
		let customButtons = [];
		let excludeActions = {
			PRINT: true,
			PACKAGING: true,
			EXPORT_DATASOURCE: true,
			ANNUL: true,
			CLARIFY: true,
			DELETE: true,
			RENOUNCEMENT: true,
			SEND: true,
			APPROVE: true,
			COMPLETE: true,
			READ_AND_COMPLETE: true,
			CONVERT_TO_DRAFT: true,
			COPY: true,
			ROUTE_REJECT: true,
			ROUTE_CONFIRM: true,
			QUICK_ANSWER_REJECT: true,
			QUICK_ANSWER_CONFIRM: true
		};
		var failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
				moduleData.tab.setLoading(false);
			});
		};

		if (
			edi.permissions.hasPermission('READ_REPORTS_REST_SERVICES') &&
			!edi.constants.DSF_WITH_APPROVE.includes(parentDocData?.type)
		) {
			var reportsBtn = createActionsButton({
				order: 0.1,
				actionName: 'REPORTS',
				text: edi.i18n.getMessage('document.preview'),
				handler: () => {
					return openApprovalPreviewModal(documentData, parentDocId);
				}
			});
			customButtons.push(reportsBtn);
		}

		var routeType = documentData?.route?.routeType ?? null;
		if (
			documentData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED &&
			documentData.linkedDoc &&
			routeType === edi.constants.ORG_PROFILE.ROUTES.ROUTE_TYPE.PRIVATE
		) {
			var currentUserId = edi.core.getUserData()?.id ?? null;
			var responsibleEmployee = documentData?.route?.responsibleEmployee ?? null;
			var getLastUser = function () {
				var countStages = documentData?.route?.stagesCount ?? 0;
				var stages = documentData?.route?.stages ?? [];
				var lastStage = stages.find((stage) => {
					return stage?.stageNumber === countStages;
				});
				var lastParty = lastStage.parties.find((party) => {
					return party?.employee?.user?.id === currentUserId;
				});
				return lastParty;
			};
			if (responsibleEmployee?.user?.id === currentUserId || getLastUser()) {
				var goToSignBtn = createActionsButton({
					order: 1,
					actionName: edi.constants.DOCUMENT_ACTIONS.GO_TO_SIGN,
					text: edi.i18n.getMessage('document.go.to.sign'),
					glyph: edi.constants.ICONS.FILE,
					handler: function () {
						edi.document.actions.openDetailsModule(documentData.linkedDoc.type, documentData.linkedDoc);
					}
				});
				customButtons.push(goToSignBtn);
			}
		}

		if (documentData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.DRAFT) {
			var sendToApproveBtn = createActionsButton({
				order: 2,
				actionName: 'SEND_APPROVE',
				text: edi.i18n.getMessage('document.send.to.approve.document'),
				glyph: edi.constants.ICONS.SEND,
				handler: function () {
					moduleData.tab.setLoading();
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.PUSH.PUT, {
							id: documentData.id
						}),
						'PUT',
						null,
						function () {
							moduleData.tab.setLoading(false);
							edi.events.approval_documents.fireEvent('change', {
								id: documentData.id
							});
						},
						failure,
						null
					);
				}
			});
			customButtons.push(sendToApproveBtn);

			if (edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_DELETE')) {
				var deleteBtn = createActionsButton({
					actionName: 'DELETE',
					text: edi.i18n.getMessage('document.delete.document'),
					glyph: edi.constants.ICONS.DELETE,
					handler: function () {
						moduleData.tab.setLoading();
						var title = documentData.title + ' №' + documentData.data.docNumber;
						var postData = {};
						postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE] = true;
						var stringified = Ext.encode(postData);
						edi.core.confirm(
							edi.i18n.getMessage('ew.documents.approvals.confirm.title'),
							edi.i18n.getMessage('ew.documents.approvals.confirm.content', [title]),
							function () {
								edi.rest.sendRequest(
									edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.DELETE.ONE.PUT, {
										id: documentData.id
									}),
									'PUT',
									stringified,
									function () {
										moduleData.tab.setLoading(false);
										edi.events.approval_documents.fireEvent('change', {
											id: documentData.id,
											deleted: true
										});
									},
									failure,
									null
								);
							},
							null,
							function () {
								moduleData.tab.setLoading(false);
							},
							function () {
								moduleData.tab.setLoading(false);
							}
						);
					}
				});
				customButtons.push(deleteBtn);
			}
		}

		if (
			documentData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED &&
			showCorrectBtn &&
			edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_CREATE') &&
			isCurrentOrgSender(documentData)
		) {
			let correctBtn = createActionsButton({
				order: 1,
				actionName: 'CORRECT',
				text: edi.i18n.getMessage('ew.documents.approvals.correct'),
				glyph: edi.constants.ICONS.ADD,
				handler: function () {
					edi.core.openModule('approval.employees.create', Ext.clone(documentData), null, null, null, {
						title: documentData.title,
						isNewCorrect: true
					});
				}
			});
			customButtons.push(correctBtn);
		}

		if (
			documentData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL &&
			checkExecutorMatchesApprovalStage(documentData, 'APPROVAL_REFUSAL') &&
			edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_APPROVE')
		) {
			var approveBtn = createActionsButton({
				order: 1,
				actionName: 'APPROVE',
				text: edi.i18n.getMessage('document.approve.document'),
				glyph: edi.constants.ICONS.DONE_ALL,
				handler: function () {
					moduleData.tab.setLoading();
					const sendApprove = function (data, callback) {
						let sendData = {
							APPROVE: true
						};
						if (data) {
							sendData = { ...data, ...sendData };
						}
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.APPROVE.PUT, {
								id: documentData.id
							}),
							'PUT',
							Ext.encode(sendData),
							function () {
								moduleData.tab.setLoading(false);
								edi.events.approval_documents.fireEvent('change', {
									id: documentData.id
								});
							},
							failure,
							callback
						);
					};
					if (documentData?.route?.routeType === 'DYNAMIC') {
						var comment = createFieldBlock({
							cls: FIELD_BLOCK_CLS.small,
							items: [
								createTextField({
									fieldLabel: edi.i18n.getMessage('confirm.approval.comment'),
									name: 'APPROVE_COMMENT',
									maxLength: 1000,
									rowsHtmlAttributeValue: 4,
									isTextarea: true
								})
							]
						});

						var formPanel = createModalForm({
							submitEmptyText: false,
							layout: {
								type: 'grid',
								gap: [24],
								area: [12]
							},
							items: [comment]
						});
						let modal = createModalPanel({
							title: edi.i18n.getMessage(`confirm.approval`),
							items: [formPanel],
							listeners: {
								close: function () {
									moduleData.tab.setLoading(false);
								}
							},
							buttonsBefore: [
								createButton({
									cls: BUTTON_CLS.primary,
									glyph: edi.constants.ICONS.DONE_ALL,
									text: edi.i18n.getMessage('document.approve.document'),
									handler: function () {
										var modalValues = edi.utils.collectFormValues(formPanel);
										edi.utils.clearEmptyValues(modalValues);
										sendApprove(modalValues, function () {
											modal.close();
										});
									}
								})
							],
							closeButtonOptions: {
								text: edi.i18n.getMessage('button.cancel'),
								cls: BUTTON_CLS.secondary,
								handler: function () {
									modal.close();
								}
							}
						});
						modal.show();
					} else {
						sendApprove();
					}
				}
			});
			customButtons.push(approveBtn);
		}
		if (
			documentData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL &&
			edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_SENT') &&
			isCurrentEmployeeExecutor(documentData.executors, documentData.signers)
		) {
			var reassignBtn = createActionsButton({
				order: 2,
				actionName: 'REASSIGN',
				text: edi.i18n.getMessage('approval.document.APPROVAL_EMPLOYEES'),
				glyph: edi.constants.ICONS.SEND,
				handler: function () {
					moduleData.tab.setLoading();
					reassignModal(documentData);
				}
			});
			customButtons.push(reassignBtn);
		}

		if (
			documentData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL &&
			edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_REVOKE') &&
			getDirection(documentData) === edi.constants.DIRECTIONS.OUTGOING
		) {
			var revokeBtn = createActionsButton({
				order: 1,
				actionName: 'REVOKE',
				text: edi.i18n.getMessage('ediweb.form.btn.revoke'),
				handler: function () {
					moduleData.tab.setLoading();
					revokeModal(documentData, 'revoke');
				}
			});
			customButtons.push(revokeBtn);
		}
		var processData = {
			url: edi.rest.services.APPROVAL_DOCUMENTS.REJECT.PUT,
			method: 'PUT',
			params: {
				id: documentData.id
			},
			callback: function () {
				edi.events.approval_documents.fireEvent('change', {
					id: documentData.id
				});
				if (parentDocId) {
					edi.events.documents.fireEvent('change', { id: parentDocId });
				}
			}
		};

		documentData.docType = edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES;
		documentData.type = edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES;
		documentData.number = documentData.title;
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: documentData,
			infoTitle: getApprovalDocumentTitle(documentData, documentData.title),
			docType: edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES,
			infoNumber: null,
			infoSubTitle: '',
			direction: getDirection(documentData),
			moduleData: moduleData,
			excludeActions: excludeActions,
			actionProps: {
				SIGN: {
					order: 1,
					methodAddOptions: {
						buttonProps: {
							glyph: edi.constants.ICONS.SIGN,
							text: edi.i18n.getMessage('document.sign.document')
						},
						signProperties: {
							dataChanged: function () {
								edi.events.approval_documents.fireEvent('change', {
									id: documentData.id
								});
							},
							signContentUrl: edi.utils.formatString(
								edi.rest.services.APPROVAL_DOCUMENTS.SIGN_CONTENT.PUT,
								{
									documentId: document.id
								}
							),
							signContentUrlMethod: 'PUT',
							signUrl: edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.SIGN.PUT, {
								id: document.id
							}),
							useBeforeAction: document.state === edi.constants.STATE.ON_APPROVAL,
							signUrlMethod: 'PUT',
							disabledPoa: true
						}
					}
				},
				REJECT: {
					order: 2,
					methodAddOptions: {
						process: processData,
						fieldConfig: {
							fieldTitle: edi.i18n.getMessage('ew.documents.approvals.reject.title'),
							emptyText: edi.i18n.getMessage('ew.documents.approvals.reject.emptyText'),
							modalTitle: edi.i18n.getMessage('ew.documents.approvals.reject.modal.title')
						}
					}
				},
				REFRESH: {
					order: 3,
					handler: function () {
						changeHandler(document);
					}
				},
				EDIT: {
					order: 1,
					moduleName:
						documentData?.route?.routeType === 'DYNAMIC'
							? 'approval.employees.create'
							: 'approval.documents.create'
				},
				EXPORT: {
					xmlExportBtnLabel: edi.i18n.getMessage('action.export.approval.document'),
					hideDefaultExport: true,
					addXmlExport: true,
					addXmlExportId: documentData.header.id,
					addExtendedExport: false,
					xmlExportBtnUrl: edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.EXPORT.COMMON.GET, {
						id: documentData.header.id
					})
				},
				//Заменила COMPLETE на READ чтобы не добавлять COMPLETE в edi.constants.FIRST_HALF_BUTTONS_ACTIONS
				READ: {
					order: 2,
					component: createCompleteBtn(documentData)
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		updateTabTitle();
		moduleData.tab.setLoading();
		var data = moduleData.initData.data;
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var finish = function (documentData) {
			modulePanel.add(createDetailsPanel(documentData));
			moduleData.tab.add(createDocActionsPanel(documentData));
			moduleData.tab.add(modulePanel);
			const parentDocType =
				edi.utils.getAttributeByName(documentData.attributes, 'PARENT_DOC_TYPE') ?? documentData?.data?.docType;

			if (
				edi.permissions.hasPermission('CLIENT_READ_ATTACHMENT_VIEW') &&
				edi.constants.DSF_WITH_APPROVE.includes(parentDocType)
			) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.ATTACHMENT.VIEW.POST, {
						documentId: documentData.id
					}),
					'POST',
					{},
					function (data) {
						if (data && data.data) {
							imagesData = data.data;
							isImagesLoaded = true;
						} else {
							isImagesLoadedError = true;
						}
					},
					function () {
						isImagesLoadedError = true;
					}
				);
			}

			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};

		var getApprovalChildren = function (documentData) {
			if (
				documentData.state !== edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED &&
				documentData.state !== edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED
			) {
				getDsfContent(documentData);
				return;
			}

			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.APPROVAL_DOCUMENTS.LINKED.GET,
					{
						id: documentData.header.id
					},
					true
				),
				'GET',
				{},
				function (links) {
					if (links && links.data) {
						switch (documentData.state) {
							case edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED:
								let children = links.data.children || [];
								let hasNotDeletedChild = children.some((child) => {
									return child.state !== edi.constants.APPROVAL_DOCUMENTS.STATE.DELETED;
								});
								showCorrectBtn = !hasNotDeletedChild;
								break;
							case edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED:
								documentData.linkedDoc = links.data?.doc ?? null;
								break;
						}
						getDsfContent(documentData);
					} else {
						failure(resp);
					}
				},
				failure
			);
		};

		var getDsfContent = function (documentData) {
			parentDocId = edi.utils.getAttributeByName(documentData.attributes, 'PARENT_DOC_ID');
			if (!parentDocId) {
				finish(documentData);
				return;
			}

			let success = function (resp) {
				if (resp && resp.items) {
					if (edi.constants.DSF_WITH_APPROVE.includes(resp.items?.[0]?.type)) {
						parentDocData = resp.items[0];
					} else {
						parentDocData = resp.items;
					}
					finish(documentData);
				} else {
					failure(resp);
				}
			};

			let url = edi.utils.formatString(
				edi.rest.services.APPROVAL_DOCUMENTS.GET_PARENT_DOC_CONTENT,
				{ id: parentDocId },
				true
			);
			edi.rest.sendRequest(url, 'GET', {}, success, failure);
		};

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.CONTENT.GET, {
				id: data.id
			}),
			'GET',
			{},
			function (data) {
				let documentData = data?.data;
				if (documentData) {
					moduleData.initData.data = documentData;
					updateTabTitle();
					getApprovalChildren(documentData);
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		var url = edi.rest.services.APPROVAL_DOCUMENTS.CONTENT.GET;
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData,
			null,
			{
				url: url,
				idParam: 'id',
				pathToDocNumber: 'title'
			}
		);
	};

	var openSideBar = function (record) {
		let recData = record || {};

		sidebar = createSidebar({
			enableBackdrop: true,
			layout: {
				type: 'vbox',
				align: 'stretch'
			},
			width: edi.constants.DEFAULT.COMPONENTS_SETTINGS.SIDEBAR.WIDTH,
			padding: 0,
			header: false,
			parent: edi.core.getViewPort(),
			close: function (callback) {
				sidebar.toggle(false, callback);
			}
		});

		sidebar.toggle(true);

		var update = function (recData) {
			sidebar.removeAll();

			var title = edi.utils.getObjectProperty(recData, 'route.name');
			let sidebarTitle = edi.i18n.getMessage('ew.employees.approvals.sidebar.title');
			sidebar.add(
				Ext.create('Ext.Component', {
					xtype: 'container',
					cls: 'edi-ediweb-sidebar-body-content',
					flex: 1,
					width: 'auto',
					autoScroll: true,
					tpl: Ext.create(
						'Ext.XTemplate',
						`<div class="edi-ediweb-sidebar-header-text fieldset fieldset-padding">${sidebarTitle}</div>`,
						'<tpl if="this.renderRevokeReason(values)">',
						'<div class="fieldset fieldset-padding edi-ediweb-notification-block">',
						'<div class="fieldset-label">{[edi.i18n.getMessage("ew.documents.approvals.revoke.reason")]}</div>',
						'<div class="fieldset-text">{[this.renderRevokeReason(values)]}</div>',
						'</div>',
						'</tpl>',
						// степпер
						'<div class="fieldset vertical-stepper">',
						'<div class="step-container">{[this.renderVerticalStepper(values)]}</div>',
						'</div>',
						{
							renderRevokeReason: function (value) {
								return edi.utils.getAttributeByName(value?.attributes, 'REVOKED_REASON');
							},
							renderVerticalStepper: function (data) {
								var tpl = new Ext.XTemplate(
									'<tpl for=".">',
									'<div class="step {[this.getStepStatus(values)]}">',
									'<div class="step-item-container">',
									'<span class="step_index">{#}</span>',
									'<div class="executor">',
									'<tpl if="this.renderExecutorComment(values)">',
									'<div class="edi-ediweb-notification-block executor-comment">',
									'<div class="fieldset-label">{[edi.i18n.getMessage("document.dsf.commentText")]}</div>',
									'<div class="fieldset-text">{[this.renderExecutorComment(values)]}</div>',
									'</div>',
									'</tpl>',
									'<tpl if="this.getDate(values)">',
									'<div class="step_name step_text_grey">{[this.getDate(values)]}</div>',
									'</tpl>',
									'<tpl if="this.getFullName(values)">',
									'<div class="step_name step_text_black">{[this.getFullName(values)]}</div>',
									'</tpl>',
									'<tpl if="this.getPosition(values)">',
									'<div class="step_name step_text_grey">{[this.getPosition(values)]}</div>',
									'</tpl>',
									'<tpl if="this.setAllEmployeesNeeded(values)">',
									'<div class="step_name step_text_grey">{[this.setAllEmployeesNeeded(values)]}</div>',
									'</tpl>',
									'<tpl if="this.setAllEmployees(values)">',
									'<div class="step_name step_all_employees">{[this.setAllEmployees(values)]}</div>',
									'</tpl>',
									'<tpl if="this.showStatus(values)">',
									'<div class="step_name step_status status-{[this.getStatus(values)]}">{[this.getStatusMsg(values)]}</div>',
									'</tpl>',
									'<tpl if="this.renderSignerComment(values)">',
									'<div class="edi-ediweb-notification-block signer-comment">',
									'<div class="fieldset-label">{[edi.i18n.getMessage("ew.employees.approvals.comment")]}</div>',
									'<div class="fieldset-text">{[this.renderSignerComment(values)]}</div>',
									'</div>',
									'</tpl>',
									'<tpl if="this.renderRejectReason(values)">',
									'<div class="fieldset-padding edi-ediweb-notification-block reject-reason">',
									'<div class="fieldset-label">{[edi.i18n.getMessage("ew.documents.approvals.reject.emptyText")]}</div>',
									'<div class="fieldset-text">{[this.renderRejectReason(values)]}</div>',
									'</div>',
									'</tpl>',
									'</div>',
									'</div>',
									'</div>',
									'</tpl>',
									{
										getStepStatus: function (values) {
											if (values.stageNumber < recData.currentStageNumber) {
												return 'step_status__finished';
											} else if (recData.currentStageNumber === 1) {
												return '';
											} else if (values.stageNumber === recData.currentStageNumber) {
												return recData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.REVOKED
													? 'step_status__revoked'
													: 'step_status__active';
											} else {
												return '';
											}
										},
										getDate: function (values) {
											let member = getPartItem(values);
											let signers = (data.signers || []).filter((s) => {
												return s.stage?.stageNumber === values.stageNumber;
											});
											let executor = getExecutorInSigners(member, signers);
											if (member.allEmployeesNeeded && member.subdivisionEmployees.length) {
												return;
											}
											return edi.utils.formatDate(
												edi.utils.getObjectProperty(executor, 'signingDate'),
												edi.constants.DATE_FORMAT.DATE_TIME_FNS,
												edi.constants.DATE_FORMAT.SERVER
											);
										},
										getFullName: function (values) {
											return getPartItem(values).fullName;
										},
										getPosition: function (values) {
											return getPartItem(values).position;
										},
										getStatus: function (values) {
											return getStatusInSidebar(values, data);
										},
										showStatus: function (values) {
											let member = getPartItem(values);
											let signers = (data.signers || []).filter((s) => {
												return s.stage?.stageNumber === values.stageNumber;
											});
											let executor = getExecutorInSigners(member, signers);

											if (member.employeesArray) {
												return false;
											}

											return member.subdivisionEmployees
												? !executor && !member.allEmployeesNeeded
												: true;
										},
										getStatusMsg: function (values) {
											return edi.i18n.getMessage(
												`ew.documents.approvals.sidebar.state.${this.getStatus(values)}`
											);
										},
										setAllEmployeesNeeded: function (values) {
											var member = getPartItem(values);
											if (
												member.hasOwnProperty('allEmployeesNeeded') &&
												!member.allEmployeesNeeded
											) {
												return edi.i18n.getMessage('org.profile.subdivision.readOnly.sublabel');
											}
											return false;
										},
										renderRejectReason: function (values) {
											var member = getPartItem(values);
											if (
												!member.hasOwnProperty('allEmployeesNeeded') &&
												getStatusInSidebar(values, data) ===
													edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED
											) {
												return edi.utils.getAttributeByName(data.attributes, 'REJECT_REASON');
											} else {
												return false;
											}
										},
										renderExecutorComment: function (values) {
											let member = getPartItem(values);
											return member?.reasignComment;
										},
										renderSignerComment: function (values) {
											let member = getPartItem(values);
											let signers = (data.signers || []).filter((s) => {
												return s.stage?.stageNumber === values.stageNumber;
											});
											let executor = getExecutorInSigners(member, signers);
											return executor?.approveComment;
										},
										setAllEmployees: function (val) {
											const subdivision = function (val) {
												let parentMember = getPartItem(val);
												let signers = (data.signers || []).filter((s) => {
													return s.stage?.stageNumber === val.stageNumber;
												});
												let executor = getExecutorInSigners(parentMember, signers);

												if (
													!executor &&
													!parentMember.allEmployeesNeeded &&
													!parentMember.employeesArray
												) {
													return false;
												}

												var subdivisionEmployees = parentMember.subdivisionEmployees;
												if (
													executor &&
													subdivisionEmployees &&
													!parentMember.allEmployeesNeeded
												) {
													subdivisionEmployees = subdivisionEmployees.filter(function (item) {
														return item.id === executor.employee.id;
													});
												}
												if (parentMember.hasOwnProperty('employeesArray')) {
													subdivisionEmployees = parentMember.employeesArray;
												}

												var subdivisionTpl = Ext.create(
													'Ext.XTemplate',
													'<tpl for=".">',
													'<div class="executor {[this.setOffset(values)]}">',
													'<tpl if="this.renderExecutorComment(values)">',
													'<div class="edi-ediweb-notification-block executor-comment">',
													'<div class="fieldset-label">{[edi.i18n.getMessage("document.dsf.commentText")]}</div>',
													'<div class="fieldset-text">{[this.renderExecutorComment(values)]}</div>',
													'</div>',
													'</tpl>',
													'<tpl if="this.getDate(values)">',
													'<div class="step_text_grey">{[this.getDate(values)]}</div>',
													'</tpl>',
													'<div class="step_text_black">{[this.getFullName(values)]}</div>',
													'<div class="step_text_grey">{[this.getPosition(values)]}</div>',
													'<tpl if="this.setAllEmployeesNeeded(values)">',
													'<div class="step_text_grey">{[this.setAllEmployeesNeeded(values)]}</div>',
													'</tpl>',
													'<tpl if="this.setAllEmployees(values)">',
													'<div class="step_all_employees child">{[this.setAllEmployees(values)]}</div>',
													'<tpl else>',
													'<div class="step_status status-{[this.getStatus(values)]}">{[this.getStatusMsg(values)]}</div>',
													'<tpl if="this.renderSignerComment(values)">',
													'<div class="edi-ediweb-notification-block signer-comment">',
													'<div class="fieldset-label">{[edi.i18n.getMessage("ew.employees.approvals.comment")]}</div>',
													'<div class="fieldset-text">{[this.renderSignerComment(values)]}</div>',
													'</div>',
													'</tpl>',
													'<tpl if="this.renderRejectReason(values)">',
													'<div class="fieldset-padding edi-ediweb-notification-block reject-reason">',
													'<div class="fieldset-label">{[edi.i18n.getMessage("ew.documents.approvals.reject.emptyText")]}</div>',
													'<div class="fieldset-text">{[this.renderRejectReason(values)]}</div>',
													'</div>',
													'</tpl>',
													'</tpl>',
													'</div>',
													'</tpl>',
													{
														setOffset: function () {
															return parentMember.employeesArray ? '' : 'top-offset';
														},
														getDate: function (values) {
															let member = getPartItem(values);
															let signers = (data.signers || []).filter((s) => {
																return s.stage?.stageNumber === val.stageNumber;
															});
															let executor = getExecutorInSigners(member, signers);
															if (
																executor &&
																!parentMember.allEmployeesNeeded &&
																!parentMember.hasOwnProperty('employeesArray')
															) {
																return;
															}
															return edi.utils.formatDate(
																edi.utils.getObjectProperty(executor, 'signingDate'),
																edi.constants.DATE_FORMAT.DATE_TIME_FNS,
																edi.constants.DATE_FORMAT.SERVER
															);
														},
														getFullName: function (values) {
															return getPartItem(values).fullName;
														},
														getPosition: function (values) {
															return getPartItem(values).position;
														},
														setAllEmployeesNeeded: function (values) {
															var member = getPartItem(values);
															if (
																member.hasOwnProperty('allEmployeesNeeded') &&
																!member.allEmployeesNeeded
															) {
																return edi.i18n.getMessage(
																	'org.profile.subdivision.readOnly.sublabel'
																);
															}
															return false;
														},
														setAllEmployees: function (values) {
															let employee = Ext.merge(
																{
																	stageNumber: val.stageNumber
																},
																values
															);
															if (Array.isArray(values.subdivisionEmployees)) {
																values.subdivisionEmployees.forEach((e) => {
																	e.actions = values.actions;
																	e.stageNumber = val.stageNumber;
																});
															}

															return subdivision(employee);
														},
														getStatus: function (values) {
															let employee = Ext.merge(
																{
																	stageNumber: val.stageNumber
																},
																values
															);
															return getStatusInSidebar(employee, data, parentMember);
														},
														getStatusMsg: function (values) {
															return edi.i18n.getMessage(
																`ew.documents.approvals.sidebar.state.${this.getStatus(
																	values
																)}`
															);
														},
														renderExecutorComment: function (values) {
															let member = getPartItem(values);
															return member?.reasignComment;
														},
														renderSignerComment: function (values) {
															let member = getPartItem(values);
															let signers = (data.signers || []).filter((s) => {
																return s.stage?.stageNumber === values.stageNumber;
															});
															console.log('signers', signers);
															let executor = getExecutorInSigners(member, signers);
															console.log('executor', executor);
															return executor?.approveComment;
														},
														renderRejectReason: function (values) {
															values = Ext.merge(
																{
																	stageNumber: val.stageNumber
																},
																values
															);
															if (Array.isArray(values.subdivisionEmployees)) {
																values.subdivisionEmployees.forEach((e) => {
																	e.actions = values.actions;
																	e.stageNumber = val.stageNumber;
																});
															}
															if (
																getStatusInSidebar(values, data) ===
																edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED
															) {
																return edi.utils.getAttributeByName(
																	data.attributes,
																	'REJECT_REASON'
																);
															} else {
																return false;
															}
														}
													}
												);

												return subdivisionTpl.apply(subdivisionEmployees);
											};

											return subdivision(val);
										}
									}
								);
								return tpl.apply(data.route.stages);
							}
						}
					),
					data: recData
				})
			);

			var failure = function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
					moduleData.tab.setLoading(false);
				});
			};

			var sendToApproveBtn = createButton({
				margin: '0 24 0 0',
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('document.send.to.approve.document'),
				glyph: edi.constants.ICONS.SEND,
				handler: function () {
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.PUSH.PUT, {
							id: recData.id
						}),
						'PUT',
						null,
						function () {
							closeSidebar();

							edi.events.approval_documents.fireEvent('change', {
								id: recData.id
							});
						},
						failure,
						null
					);
				}
			});

			var signBtn = createButton({
				margin: '0 24 0 0',
				cls: BUTTON_CLS.primary,
				glyph: edi.constants.ICONS.SIGN,
				text: edi.i18n.getMessage('document.sign.document'),
				handler: function () {
					var signCallback = function () {
						edi.events.approval_documents.fireEvent('change', {
							id: recData.id
						});
						closeSidebar();
					};
					if (edi.sign.isAvailable()) {
						edi.sign.getCertificates({
							callback: function () {
								edi.utils.sign(
									recData,
									moduleData.tab,
									signCallback,
									null,
									undefined,
									false,
									null,
									{
										signContentUrl: edi.utils.formatString(
											edi.rest.services.APPROVAL_DOCUMENTS.SIGN_CONTENT.PUT,
											{
												documentId: recData.id
											}
										),
										signContentUrlMethod: 'PUT',
										signUrl: edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.SIGN.PUT, {
											id: recData.id
										}),
										useBeforeAction: recData.state === edi.constants.STATE.ON_APPROVAL,
										signUrlMethod: 'PUT',
										disabledPoa: true
									},
									poaHandler
								);
							}
						});
					} else {
						edi.sign.displayNotAvailableMessage();
					}
				}
			});

			var approveBtn = createButton({
				margin: '0 24 0 0',
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('document.approve.document'),
				glyph: edi.constants.ICONS.DONE_ALL,
				handler: function () {
					const sendApprove = function (data, callback) {
						let sendData = {
							APPROVE: true
						};
						if (data) {
							sendData = { ...data, ...sendData };
						}
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.APPROVE.PUT, {
								id: recData.id
							}),
							'PUT',
							Ext.encode(sendData),
							function () {
								closeSidebar();
								edi.events.approval_documents.fireEvent('change', {
									id: recData.id
								});
							},
							failure,
							callback
						);
					};

					var comment = createFieldBlock({
						cls: FIELD_BLOCK_CLS.small,
						items: [
							createTextField({
								fieldLabel: edi.i18n.getMessage('confirm.approval.comment'),
								name: 'APPROVE_COMMENT',
								maxLength: 1000,
								rowsHtmlAttributeValue: 4,
								isTextarea: true
							})
						]
					});

					var formPanel = createModalForm({
						submitEmptyText: false,
						layout: {
							type: 'grid',
							gap: [24],
							area: [12]
						},
						items: [comment]
					});
					let modal = createModalPanel({
						title: edi.i18n.getMessage(`confirm.approval`),
						items: [formPanel],
						listeners: {
							close: function () {
								moduleData.tab.setLoading(false);
							}
						},
						buttonsBefore: [
							createButton({
								cls: BUTTON_CLS.primary,
								glyph: edi.constants.ICONS.DONE_ALL,
								text: edi.i18n.getMessage('document.approve.document'),
								handler: function () {
									var modalValues = edi.utils.collectFormValues(formPanel);
									edi.utils.clearEmptyValues(modalValues);
									sendApprove(modalValues, function () {
										modal.close();
									});
								}
							})
						],
						closeButtonOptions: {
							text: edi.i18n.getMessage('button.cancel'),
							cls: BUTTON_CLS.secondary,
							handler: function () {
								modal.close();
							}
						}
					});
					modal.show();
				}
			});

			var rejectBtn = createButton({
				margin: '0 24 0 0',
				cls: BUTTON_CLS.secondary,
				text: edi.i18n.getMessage('document.reject.document'),
				glyph: edi.constants.ICONS.ANNUL,
				handler: function () {
					revokeModal(recData, 'reject', function () {
						closeSidebar();
					});
				}
			});

			var correctBtn = createButton({
				margin: '0 24 0 0',
				cls: BUTTON_CLS.primary,
				text: edi.i18n.getMessage('ew.documents.approvals.correct'),
				glyph: edi.constants.ICONS.ADD,
				handler: function () {
					closeSidebar();
					edi.core.openModule('approval.employees.create', Ext.clone(recData), null, null, null, {
						title: recData.title,
						isNewCorrect: true
					});
				}
			});

			let isAvailableSign =
				recData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL &&
				checkExecutorMatchesApprovalStage(recData, 'SIGNING_REFUSAL');
			let isAvailableApprove =
				recData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL &&
				checkExecutorMatchesApprovalStage(recData, 'APPROVAL_REFUSAL');
			let buttons = [];

			if (recData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.DRAFT) {
				buttons.push(sendToApproveBtn);
			}

			if (isAvailableSign && edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_SIGN')) {
				buttons.push(signBtn);
			}

			if (isAvailableApprove && edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_APPROVE')) {
				buttons.push(approveBtn);
			}

			let isSender = recData.sender?.userId === userData.id || recData.sender?.user?.id === userData.id;
			if (
				recData.state === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED &&
				showCorrectBtn &&
				edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_CREATE') &&
				isSender
			) {
				buttons.push(correctBtn);
			}

			if (
				(isAvailableSign || isAvailableApprove) &&
				edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_REJECT')
			) {
				buttons.push(rejectBtn);
			}

			if (buttons.length > 0) {
				sidebar.add(
					createContainer({
						layout: 'hbox',
						cls: 'edi-ediweb-sidebar-buttons',
						items: buttons
					})
				);
			}
		};

		update(recData);
		edi.events.module.on('moduleActivated', closeSidebar);
	};

	var closeSidebar = function () {
		if (sidebar) {
			sidebar.close();
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		if (sidebar) {
			sidebar.close(function () {
				edi.events.module.un('moduleActivated', closeSidebar);
				sidebar.destroy();
			});
		}
		edi.events.approval_documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
