const createRecadvHeader = function (config) {
	config = config ? config : {};
	return {
		ReceivingAdviceNumber: config.ReceivingAdviceNumber,
		ReceivingAdviceDate: config.ReceivingAdviceDate,
		VersionNumber: config.VersionNumber,
		VersionDate: config.VersionDate,
		GoodsReceiptDate: config.GoodsReceiptDate,
		BuyerOrderNumber: config.BuyerOrderNumber,
		BuyerOrderDate: config.BuyerOrderDate,
		DespatchNumber: config.DespatchNumber,
		DespatchDate: config.DespatchDate,
		InvoiceNumber: config.InvoiceNumber,
		InvoiceDate: config.InvoiceDate,
		DocumentFunctionCode: config.DocumentFunctionCode,
		DocumentNameCode: config.DocumentNameCode,
		Remarks: config.Remarks,
		Reference: config.Reference
	};
};

const createRecadv = function (config) {
	config = config ? config : {};
	var recadvLines = [],
		summary = {};

	if (config.productValues) {
		summary = {
			TotalLines: config.productValues.TotalLines,
			TotalGoodsReceiptAmount: config.productValues.TotalGoodsReceiptAmount,
			TotalTaxAmount: config.productValues.TotalTaxAmount,
			TotalNetAmount: config.productValues.TotalNetAmount,
			TotalGrossAmount: config.productValues.TotalGrossAmount
		};
		for (var i = 0; i < config.productValues.products.length; i++) {
			var line = {},
				product = config.productValues.products[i];
			if (product[edi.constants.INTERNAL_LINE_ID]) {
				line[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}
			delete product[edi.constants.INTERNAL_LINE_ID];
			line['Line-Item'] = product;
			recadvLines.push(line);
		}
	}

	return {
		'ReceivingAdvice-Header': createRecadvHeader(config['ReceivingAdvice-Header']),
		'ReceivingAdvice-Parties': config.parties || {},
		'ReceivingAdvice-Lines': {
			Line: recadvLines
		},
		'ReceivingAdvice-Summary': summary
	};
};
const createRecadvParties = function (config) {
	config = config ? config : {};
	return {
		Buyer: {
			ILN: edi.utils.getObjectProperty(config, 'Buyer.ILN'),
			CodeBySender: edi.utils.getObjectProperty(config, 'Buyer.CodeBySender'),
			CodeByReceiver: edi.utils.getObjectProperty(config, 'Buyer.CodeByReceiver'),
			CodeBySupplier: edi.utils.getObjectProperty(config, 'Buyer.CodeBySupplier'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'Buyer.CodeByBuyer'),
			Name: edi.utils.getObjectProperty(config, 'Buyer.Name'),
			TaxID: edi.utils.getObjectProperty(config, 'Buyer.TaxID'),
			PostalCode: edi.utils.getObjectProperty(config, 'Buyer.PostalCode'),
			Country: edi.utils.getObjectProperty(config, 'Buyer.Country'),
			CityName: edi.utils.getObjectProperty(config, 'Buyer.CityName'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'Buyer.StreetAndNumber'),
			Type: edi.utils.getObjectProperty(config, 'Buyer.Type'),
			Locality: edi.utils.getObjectProperty(config, 'Buyer.Locality'),
			Housing: edi.utils.getObjectProperty(config, 'Buyer.Housing'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'Buyer.AdditionalInformation'),
			District: edi.utils.getObjectProperty(config, 'Buyer.District'),
			State: edi.utils.getObjectProperty(config, 'Buyer.State'),
			StateCode: edi.utils.getObjectProperty(config, 'Buyer.StateCode'),
			HouseNumber: edi.utils.getObjectProperty(config, 'Buyer.HouseNumber'),
			RoomNumber: edi.utils.getObjectProperty(config, 'Buyer.RoomNumber')
		},
		Seller: {
			ILN: edi.utils.getObjectProperty(config, 'Seller.ILN'),
			CodeBySender: edi.utils.getObjectProperty(config, 'Seller.CodeBySender'),
			CodeByReceiver: edi.utils.getObjectProperty(config, 'Seller.CodeByReceiver'),
			CodeBySupplier: edi.utils.getObjectProperty(config, 'Seller.CodeBySupplier'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'Seller.CodeByBuyer'),
			Name: edi.utils.getObjectProperty(config, 'Seller.Name'),
			TaxID: edi.utils.getObjectProperty(config, 'Seller.TaxID'),
			Type: edi.utils.getObjectProperty(config, 'Seller.Type'),
			PostalCode: edi.utils.getObjectProperty(config, 'Seller.PostalCode'),
			Country: edi.utils.getObjectProperty(config, 'Seller.Country'),
			CityName: edi.utils.getObjectProperty(config, 'Seller.CityName'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'Seller.StreetAndNumber'),
			Locality: edi.utils.getObjectProperty(config, 'Seller.Locality'),
			Housing: edi.utils.getObjectProperty(config, 'Seller.Housing'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'Seller.AdditionalInformation'),
			District: edi.utils.getObjectProperty(config, 'Seller.District'),
			State: edi.utils.getObjectProperty(config, 'Seller.State'),
			StateCode: edi.utils.getObjectProperty(config, 'Seller.StateCode'),
			HouseNumber: edi.utils.getObjectProperty(config, 'Seller.HouseNumber'),
			RoomNumber: edi.utils.getObjectProperty(config, 'Seller.RoomNumber')
		},
		DeliveryPoint: {
			ILN: edi.utils.getObjectProperty(config, 'DeliveryPoint.ILN'),
			CodeBySender: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeBySender'),
			CodeByReceiver: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeByReceiver'),
			CodeBySupplier: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeBySupplier'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeByBuyer'),
			Name: edi.utils.getObjectProperty(config, 'DeliveryPoint.Name'),
			TaxID: edi.utils.getObjectProperty(config, 'DeliveryPoint.TaxID'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.StreetAndNumber'),
			CityName: edi.utils.getObjectProperty(config, 'DeliveryPoint.CityName'),
			Type: edi.utils.getObjectProperty(config, 'DeliveryPoint.Type'),
			District: edi.utils.getObjectProperty(config, 'DeliveryPoint.District'),
			State: edi.utils.getObjectProperty(config, 'DeliveryPoint.State'),
			Locality: edi.utils.getObjectProperty(config, 'DeliveryPoint.Locality'),
			StateCode: edi.utils.getObjectProperty(config, 'DeliveryPoint.StateCode'),
			PostalCode: edi.utils.getObjectProperty(config, 'DeliveryPoint.PostalCode'),
			Country: edi.utils.getObjectProperty(config, 'DeliveryPoint.Country'),
			Housing: edi.utils.getObjectProperty(config, 'DeliveryPoint.Housing'),
			HouseNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.HouseNumber'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'DeliveryPoint.AdditionalInformation'),
			RoomNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.RoomNumber')
		},
		UltimateCustomer: edi.utils.getObjectProperty(config, 'UltimateCustomer'),
		ShipFrom: edi.utils.getObjectProperty(config, 'ShipFrom')
	};
};
export { createRecadv, createRecadvParties, createRecadvHeader };
