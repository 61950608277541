export const orderRespSelectors = {
	getBuyerFieldsMap: function () {
		const fieldsMapPrefix = 'Buyer';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			company_kpp: `${fieldsMapPrefix}.TaxRegistrationReasonCode`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`
		};
	},
	getBuyerModalConf: function () {
		return {
			title: edi.i18n.getMessage('document.buyer'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn_kpp: Object.assign({}, edi.selectors.defaults.company_inn_kpp, {
						fields: {
							inn: Object.assign({}, edi.selectors.defaults.company_inn_kpp.fields.inn, {
								allowBlank: true
							}),
							kpp: Object.assign({}, edi.selectors.defaults.company_inn_kpp.fields.kpp, {
								allowBlank: true,
								maxLength: 35,
								regex: undefined,
								maskRe: undefined
							})
						}
					})
				},
				address: {
					category: edi.selectors.defaults.categoryNew,
					otherFields: {
						type: 'fieldset',
						panelConf: {
							layout: {
								type: 'grid',
								ignoreHiddenElements: true,
								area: [8, 4, 8, [6, 6], [6, 2, 2, 2], 10, 10]
							},
							margin: 0
						},
						fields: {
							country: {
								type: 'panel',
								panelConf: {
									layout: 'grid'
								},
								fields: {
									country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
										maxLength: 140,
										fieldConfig: Object.assign(
											{},
											edi.selectors.defaults.iso2_country_combo.fieldConfig,
											{
												mandatory: false
											}
										)
									}),
									region: Object.assign({}, edi.selectors.defaults.region, { maxLength: 140 })
								}
							},
							zip: Object.assign({}, edi.selectors.defaults.zip, {
								maxLength: 9,
								regex: edi.constants.VALIDATORS.ZIP_XSD
							}),
							area: Object.assign({}, edi.selectors.defaults.area, {
								name: 'addr_area',
								maxLength: 140
							}),
							city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
							locality: Object.assign({}, edi.selectors.defaults.locality, {
								name: 'addr_locality',
								maxLength: 140
							}),
							street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
							home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
							block: Object.assign({}, edi.selectors.defaults.block, {
								name: 'addr_block',
								maxLength: 20
							}),
							flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
							addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
						}
					}
				}
			}
		};
	},
	getSellerFieldsMap: function () {
		const fieldsMapPrefix = 'Seller';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			company_kpp: `${fieldsMapPrefix}.TaxRegistrationReasonCode`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			operator_name: `${fieldsMapPrefix}.OperatorDetails.Name`,
			operator_phone: `${fieldsMapPrefix}.OperatorDetails.TelephoneNumber`,
			operator_email: `${fieldsMapPrefix}.OperatorDetails.E-mail`,
			code_by_buyer: `${fieldsMapPrefix}.CodeByBuyer`,
			bank_acc: `${fieldsMapPrefix}.AccountInfo.AccountNumber`,
			bank_corr_acc: `${fieldsMapPrefix}.AccountInfo.CorrespondentAccount`,
			bank_id: `${fieldsMapPrefix}.AccountInfo.BIC`,
			bank_name: `${fieldsMapPrefix}.AccountInfo.BankName`,
			bank_city: `${fieldsMapPrefix}.AccountInfo.BankCity`,
			bank_vat_number: `${fieldsMapPrefix}.AccountInfo.BankVATNumber`,
			payment_text: `${fieldsMapPrefix}.AccountInfo.PaymentText`
		};
	},
	getSellerModalConf: function () {
		return {
			title: edi.i18n.getMessage('document.seller'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn_kpp: Object.assign({}, edi.selectors.defaults.company_inn_kpp, {
						fields: {
							inn: Object.assign({}, edi.selectors.defaults.company_inn_kpp.fields.inn, {
								allowBlank: true
							}),
							kpp: Object.assign({}, edi.selectors.defaults.company_inn_kpp.fields.kpp, {
								allowBlank: true,
								maxLength: 35,
								regex: undefined,
								maskRe: undefined
							})
						}
					})
				},
				address: {
					category: edi.selectors.defaults.categoryNew,
					otherFields: {
						type: 'fieldset',
						panelConf: {
							layout: {
								type: 'grid',
								ignoreHiddenElements: true,
								area: [8, 4, 8, [6, 6], [6, 2, 2, 2], 10, 10]
							},
							margin: 0
						},
						fields: {
							country: {
								type: 'panel',
								panelConf: {
									layout: 'grid'
								},
								fields: {
									country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
										maxLength: 140,
										fieldConfig: Object.assign(
											{},
											edi.selectors.defaults.iso2_country_combo.fieldConfig,
											{
												mandatory: false
											}
										)
									}),
									region: Object.assign({}, edi.selectors.defaults.region, { maxLength: 140 })
								}
							},
							zip: Object.assign({}, edi.selectors.defaults.zip, {
								maxLength: 9,
								regex: edi.constants.VALIDATORS.ZIP_XSD
							}),
							area: Object.assign({}, edi.selectors.defaults.area, {
								name: 'addr_area',
								maxLength: 140
							}),
							city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
							locality: Object.assign({}, edi.selectors.defaults.locality, {
								name: 'addr_locality',
								maxLength: 140
							}),
							street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
							home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
							block: Object.assign({}, edi.selectors.defaults.block, {
								name: 'addr_block',
								maxLength: 20
							}),
							flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
							addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
						}
					}
				},
				bank: {
					bank_acc: {
						name: 'bank_acc',
						title: 'column.number',
						allowBlank: true,
						maxLength: 35
					},
					bank_corr_acc: {
						name: 'bank_corr_acc',
						title: 'company.bank.account.corr',
						allowBlank: true,
						requiredFields: ['bank_acc'],
						maxLength: 35
					},
					bank_id: {
						name: 'bank_id',
						title: 'company.bank.bank_id',
						allowBlank: true,
						requiredFields: ['bank_acc'],
						maxLength: 12
					},
					bank_name: {
						name: 'bank_name',
						title: 'company.bank.bank_name',
						allowBlank: true,
						requiredFields: ['bank_acc'],
						maxLength: 175
					},
					bank_city: {
						name: 'bank_city',
						title: 'company.bank.bank_city',
						allowBlank: true,
						requiredFields: ['bank_acc'],
						maxLength: 140
					},
					bank_vat_number: {
						name: 'bank_vat_number',
						title: 'company.bank.bank_vat_number',
						allowBlank: true,
						requiredFields: ['bank_acc'],
						maxLength: 35
					},
					payment_text: {
						name: 'payment_text',
						title: 'company.bank.payment_text',
						allowBlank: true,
						requiredFields: ['bank_acc'],
						maxLength: 140
					}
				},
				contact: {
					operator_name: edi.selectors.defaults.operator_name,
					operator_phone: edi.selectors.defaults.operator_phone,
					operator_email: edi.selectors.defaults.operator_email
				},
				tabConfig: {
					title: edi.i18n.getMessage('product.tab.additional.data'),
					code_by_buyer: {
						title: 'column.code',
						name: 'code_by_buyer',
						emptyText: 'column.code',
						maxLength: 35
					}
				}
			}
		};
	},
	getShipFromFieldsMap: function () {
		const fieldsMapPrefix = 'ShipFrom';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			address_text: `${fieldsMapPrefix}.AddressText`
		};
	},
	getShipFromModalConf: function () {
		return {
			title: edi.i18n.getMessage('document.ship.from'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: Object.assign({}, edi.selectors.defaults.company_gln, { allowBlank: true }),
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
						regex: null,
						maxLength: 35
					})
				},
				address: {
					category: edi.selectors.defaults.categoryNew,
					otherFields: {
						type: 'fieldset',
						panelConf: {
							layout: {
								type: 'grid',
								ignoreHiddenElements: true,
								area: [8, 4, 8, [6, 6], [6, 2, 2, 2], 10, 10]
							},
							margin: 0
						},
						fields: {
							country: {
								type: 'panel',
								panelConf: {
									layout: 'grid'
								},
								fields: {
									country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
										maxLength: 140,
										fieldConfig: Object.assign(
											{},
											edi.selectors.defaults.iso2_country_combo.fieldConfig,
											{
												mandatory: false,
												allowBlank: true,
												listeners: null
											}
										)
									}),
									region: Object.assign({}, edi.selectors.defaults.region, { maxLength: 140 })
								}
							},
							zip: edi.selectors.defaults.zip,
							area: Object.assign({}, edi.selectors.defaults.area, {
								name: 'addr_area',
								maxLength: 140
							}),
							city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
							locality: Object.assign({}, edi.selectors.defaults.locality, {
								name: 'addr_locality',
								maxLength: 140
							}),
							street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
							home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
							block: Object.assign({}, edi.selectors.defaults.block, {
								name: 'addr_block',
								maxLength: 20
							}),
							flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
							addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 }),
							address_text: {
								title: 'document.org.address_text',
								name: 'address_text',
								maxLength: 1000,
								order: 10
							}
						}
					}
				}
			}
		};
	},
	getDeliveryFieldsMap: function () {
		const fieldsMapPrefix = 'DeliveryPoint';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.Country`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			address_text: `${fieldsMapPrefix}.AddressText`
		};
	},
	getDeliveryModalConf: function () {
		return {
			title: edi.i18n.getMessage('document.delivery'),
			tabs: {
				main: {
					company_name: edi.selectors.defaults.company_name,
					company_gln: edi.selectors.defaults.company_gln,
					company_reg_number: edi.selectors.defaults.company_reg_nr,
					company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
						regex: null,
						maxLength: 35
					})
				},
				address: {
					category: edi.selectors.defaults.categoryNew,
					otherFields: {
						type: 'fieldset',
						panelConf: {
							layout: {
								type: 'grid',
								ignoreHiddenElements: true,
								area: [8, 4, 8, [6, 6], [6, 2, 2, 2], 10, 10]
							},
							margin: 0
						},
						fields: {
							country: {
								type: 'panel',
								panelConf: {
									layout: 'grid'
								},
								fields: {
									country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
										maxLength: 140,
										fieldConfig: Object.assign(
											{},
											edi.selectors.defaults.iso2_country_combo.fieldConfig,
											{
												mandatory: false,
												allowBlank: true,
												listeners: null
											}
										)
									}),
									region: Object.assign({}, edi.selectors.defaults.region, { maxLength: 140 })
								}
							},
							zip: edi.selectors.defaults.zip,
							area: Object.assign({}, edi.selectors.defaults.area, {
								name: 'addr_area',
								maxLength: 140
							}),
							city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
							locality: Object.assign({}, edi.selectors.defaults.locality, {
								name: 'addr_locality',
								maxLength: 140
							}),
							street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
							home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
							block: Object.assign({}, edi.selectors.defaults.block, {
								name: 'addr_block',
								maxLength: 20
							}),
							flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
							addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 }),
							address_text: {
								title: 'document.org.address_text',
								name: 'address_text',
								maxLength: 1000,
								order: 10
							}
						}
					}
				}
			}
		};
	}
};
