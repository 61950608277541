export const desadvSelectors = {
	getBuyerFieldsMap: function () {
		const prefix = 'Buyer';
		const fieldsMap = edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'company_util_reg_nr', 'company_inn'].concat(
				edi.selectors.COMMON_FIELDS_MAP
			)
		});
		fieldsMap['code_by_buyer'] = `${prefix}.CodeByBuyer`;
		fieldsMap['code_by_seller'] = `${prefix}.CodeBySeller`;
		fieldsMap['code_by_carrier'] = `${prefix}.CodeByCarrier`;
		return fieldsMap;
	},
	getBuyerModalConf: function () {
		return Ext.merge(
			edi.selectors.mergeModalCfgWithCommonOrgAddress(
				edi.selectors.getLegacyModalConf({
					main: {
						company_inn: edi.selectors.defaults.company_inn
					},
					contact: null
				})
			),
			{ title: edi.i18n.getMessage('document.buyer') }
		);
	},
	getSellerFieldsMap: function () {
		const prefix = 'Seller';
		const fieldsMap = edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'company_util_reg_nr', 'company_inn'].concat(
				edi.selectors.COMMON_FIELDS_MAP
			)
		});
		fieldsMap['code_by_buyer'] = `${prefix}.CodeByBuyer`;
		fieldsMap['code_by_seller'] = `${prefix}.CodeBySeller`;
		fieldsMap['code_by_carrier'] = `${prefix}.CodeByCarrier`;
		return fieldsMap;
	},
	getSellerModalConf: function () {
		return Ext.merge(
			edi.selectors.mergeModalCfgWithCommonOrgAddress(
				edi.selectors.getLegacyModalConf({
					main: {
						company_inn: edi.selectors.defaults.company_inn
					},
					contact: null
				})
			),
			{ title: edi.i18n.getMessage('document.seller') }
		);
	},
	getShipFromFieldsMap: function () {
		const prefix = 'ShipFrom';
		const fieldsMap = edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'company_inn'].concat(edi.selectors.COMMON_FIELDS_MAP)
		});
		fieldsMap['code_by_buyer'] = `${prefix}.CodeByBuyer`;
		fieldsMap['code_by_seller'] = `${prefix}.CodeBySeller`;
		fieldsMap['code_by_carrier'] = `${prefix}.CodeByCarrier`;
		return fieldsMap;
	},
	getShipFromModalConf: function () {
		return Ext.merge(
			edi.selectors.mergeModalCfgWithCommonOrgAddress(
				edi.selectors.getLegacyModalConf({
					main: {
						company_reg_number: null,
						company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
							regex: null,
							maxLength: 35
						})
					},
					contact: null
				})
			),
			{ title: edi.i18n.getMessage('document.ship.from') }
		);
	},
	getDeliveryFieldsMap: function () {
		const prefix = 'DeliveryPoint';
		const fieldsMap = edi.selectors.utils.getLegacyFieldsMap(prefix, {
			includeFields: ['company_name', 'company_iln', 'company_inn'].concat(edi.selectors.COMMON_FIELDS_MAP)
		});
		fieldsMap['code_by_buyer'] = `${prefix}.CodeByBuyer`;
		fieldsMap['code_by_seller'] = `${prefix}.CodeBySeller`;
		fieldsMap['code_by_carrier'] = `${prefix}.CodeByCarrier`;
		return fieldsMap;
	},
	getDeliveryModalConf: function () {
		return Ext.merge(
			edi.selectors.mergeModalCfgWithCommonOrgAddress(
				edi.selectors.getLegacyModalConf({
					main: {
						company_reg_number: null,
						company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
							regex: null,
							maxLength: 35
						})
					},
					contact: null
				})
			),
			{ title: edi.i18n.getMessage('document.delivery') }
		);
	},
	getUltimateCustomerFieldsMap: function () {
		return edi.selectors.utils.getLegacyFieldsMap('UltimateCustomer', {
			includeFields: ['company_name', 'company_iln'].concat(edi.selectors.COMMON_FIELDS_MAP)
		});
	},
	getUltimateCustomerModalConf: function () {
		return Ext.merge(
			edi.selectors.mergeModalCfgWithCommonOrgAddress(
				edi.selectors.getLegacyModalConf({
					main: {
						company_reg_number: null
					},
					contact: null
				})
			),
			{ title: edi.i18n.getMessage('documents.ultimate.customer') }
		);
	},
	getCarrierFieldsMap: function () {
		return edi.selectors.utils.getLegacyFieldsMap('Carrier', {
			includeFields: ['company_name', 'company_iln']
		});
	},
	getCarrierModalConf: function () {
		return Ext.merge(
			edi.selectors.getLegacyModalConf({
				main: {
					company_reg_number: null
				},
				address: null,
				contact: null
			}),
			{ title: edi.i18n.getMessage('document.carrier') }
		);
	}
};
