const INVRPT_LINE_MODEL = 'INVENTORY_REPORT_LINE';

edi.models.setModels({
	[INVRPT_LINE_MODEL]: {
		fields: [
			{
				name: 'Line-Delivery'
				//type: 'object'
			},
			{
				name: 'Line-Item'
				//type: 'object'
			},
			{
				name: 'Line-Reference'
				//type: 'object'
			},
			{
				name: 'LineNumber',
				type: 'number',
				convert: function (value, record) {
					return record.get('Line-Item').LineNumber;
				}
			},
			{
				name: 'ItemDescription',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Item').ItemDescription;
				}
			},
			{
				name: 'BuyerItemCode',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Item').BuyerItemCode;
				}
			},
			{
				name: 'ItemGroupCode',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Item').ItemGroupCode;
				}
			},
			{
				name: 'ItemGroup',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Item').ItemGroup;
				}
			},
			{
				name: 'ActualStock',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Item').ActualStock;
				}
			},
			{
				name: 'TransitQuantity',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Item').TransitQuantity;
				}
			},
			{
				name: 'Written-offQuantity',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Item')['Written-offQuantity'];
				}
			},
			{
				name: 'UnitOfMeasure',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Item').UnitOfMeasure;
				}
			},
			{
				name: 'SellerLocationCode',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Delivery').SellerLocationCode;
				}
			},

			{
				name: 'BuyerDeliveryGroup',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Delivery').BuyerDeliveryGroup;
				}
			},
			{
				name: 'Name',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Delivery').Name;
				}
			},
			{
				name: 'SellerLocationCode',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Delivery').Branch;
				}
			},
			{
				name: 'Region',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Delivery').Region;
				}
			},
			{
				name: 'Region',
				type: 'string',
				convert: function (value, record) {
					return record.get('Line-Delivery').Region;
				}
			},
			{
				name: 'NameOfLevel1',
				type: 'string',
				convert: function (value, record) {
					var refs = edi.utils.getObjectProperty(
						record.get('Line-Reference'),
						'Reference-Elements.Reference-Element'
					);
					for (var i in refs) {
						if (refs[i]['Reference-Type'] == 'IG1') {
							return refs[i]['Reference-Id'];
						}
					}
					return '';
				}
			},
			{
				name: 'NameOfLevel2',
				type: 'string',
				convert: function (value, record) {
					var refs = edi.utils.getObjectProperty(
						record.get('Line-Reference'),
						'Reference-Elements.Reference-Element'
					);
					for (var i in refs) {
						if (refs[i]['Reference-Type'] == 'IG2') {
							return refs[i]['Reference-Id'];
						}
					}
					return '';
				}
			},
			{
				name: 'NameOfLevel3',
				type: 'string',
				convert: function (value, record) {
					var refs = edi.utils.getObjectProperty(
						record.get('Line-Reference'),
						'Reference-Elements.Reference-Element'
					);
					for (var i in refs) {
						if (refs[i]['Reference-Type'] == 'IG3') {
							return refs[i]['Reference-Id'];
						}
					}
					return '';
				}
			},
			{
				name: 'NameOfLevel4',
				type: 'string',
				convert: function (value, record) {
					var refs = edi.utils.getObjectProperty(
						record.get('Line-Reference'),
						'Reference-Elements.Reference-Element'
					);
					for (var i in refs) {
						if (refs[i]['Reference-Type'] == 'IG4') {
							return refs[i]['Reference-Id'];
						}
					}
					return '';
				}
			}
		]
	}
});

export { INVRPT_LINE_MODEL };
