Ext.namespace('edi.rest');
Ext.merge(edi.rest.services, {
	USER: {
		ORGANIZATION: {
			DETAILS: {
				PUT: edi.rest.prfx.c + 'organization/details'
			}
		},
		NOTIFICATION_SETTING: {
			GET: edi.rest.prfx.c + 'notification/invitation/permissions',
			PUT: edi.rest.prfx.c + 'notification/invitation/permissions'
		},
		SELF: {
			CONTACTS: {
				PUT: edi.rest.prfx.c + 'users/self/name/contacts'
			},
			ATTRIBUTES: {
				PUT: edi.rest.prfx.c + 'users/self/language/attributes'
			}
		}
	},
	NOTIFICATIONS: {
		GET: edi.rest.prfx.c + 'notification/ediweb',
		CONFIG: {
			GET: edi.rest.prfx.c + 'notification/config',
			PUT: edi.rest.prfx.c + 'notification/config'
		},
		DISABLED: {
			TYPES: {
				GET: edi.rest.prfx.c + 'notification/disabled/types',
				PUT: edi.rest.prfx.c + 'notification/disabled/types'
			}
		}
	},
	PROJECT: {
		LIST: {
			GET: 'json/project_list.json'
		}
	},
	ADVERTISING_TAPE: {
		LIST: {
			GET: edi.rest.prfx.c + 'advertising/tape'
		},
		CONTENT: {
			GET: edi.rest.prfx.c + 'advertising/tape/{id}/content'
		}
	},
	INVITATIONS: {
		GET: edi.rest.prfx.c + 'invitations',
		POST: edi.rest.prfx.c + 'invitations',
		PUT: edi.rest.prfx.c + 'invitations/{id}',
		DELETE: edi.rest.prfx.c + 'documents/{id}',
		RESEND: {
			PUT: edi.rest.prfx.c + 'invitations/{id}/resend-unchanged'
		},
		COPY: {
			PUT: edi.rest.prfx.c + 'invitations/{id}/copy'
		},
		REJECT: {
			PUT: edi.rest.prfx.c + 'documents/{id}/push'
		},
		REVOKE: {
			PUT: edi.rest.prfx.c + 'documents/{id}/push'
		},
		SENT: {
			PUT: edi.rest.prfx.c + 'documents/{id}/push'
		},
		PUSH: {
			PUT: edi.rest.prfx.c + 'documents/{id}/push'
		},
		FIND: {
			PUT: edi.rest.prfx.c + 'invitations/find-organization'
		},
		RECEIVERS: {
			GET: edi.rest.prfx.c + 'invitations/receivers'
		},
		SENDERS: {
			GET: edi.rest.prfx.c + 'invitations/senders'
		},
		CHECK_SERVICE_AVAILABLE: {
			GET: edi.rest.prfx.c + 'usr/organizations/check/services/available'
		},
		BY_INN: {
			GET: edi.rest.prfx.c + 'usr/organizations/by_inn/{inn}'
		},
		FNS_CHECK: {
			GET: edi.rest.prfx.c + 'usr/organizations/fns'
		},
		TEMPLATE: {
			GET: edi.rest.prfx.c + 'invitations/template',
			PUT: edi.rest.prfx.c + 'invitations/template'
		},
		DSF_DATA: {
			GET: edi.rest.prfx.c + 'invitations/{id}/not_signed_dsf'
		}
	},
	ORGANIZATIONS: {
		ALL: {
			GET: edi.rest.prfx.c + 'organization/find'
		}
	},
	MASS_INVITATIONS: {
		POST: edi.rest.prfx.c + 'mass-invitations',
		GET: edi.rest.prfx.c + 'mass-invitations',
		DELETE: edi.rest.prfx.c + 'documents/{id}',
		SENT: edi.rest.prfx.c + 'documents/{id}/push',
		PUT: edi.rest.prfx.c + 'mass-invitations/{id}',
		DATA: edi.rest.prfx.c + 'mass-invitations/data/{id}',
		UPDATE_PARTICIPANT_INVITATION: edi.rest.prfx.c + 'documents/{invitationId}',
		DOWNLOAD_ATTACHMENT: edi.rest.prfx.c + 'mass-invitations/template/download'
	},
	INTRO_PAGES: {
		GET: 'json/intro_pages.json'
	},
	PRODUCT: {
		ACTIVE: {
			PUT: edi.rest.prfx.c + 'organization/activate/product/{product}'
		},
		URLS: {
			GET: edi.rest.prfx.c + 'products/urls'
		},
		CHECK: {
			PUT: edi.rest.prfx.c + 'organization/activate/product/{product}/check'
		},
		RESEND: {
			PUT: edi.rest.prfx.c + 'organization/activate/product/{product}/resend'
		}
	},
	ORG_PROFILE: {
		SUBDIVISIONS: {
			GET: edi.rest.prfx.c + 'subdivision',
			POST: edi.rest.prfx.c + 'subdivision',
			DELETE: edi.rest.prfx.c + 'subdivision/{id}',
			PUT: edi.rest.prfx.c + 'subdivision/{id}',
			CHECK: {
				GET: edi.rest.prfx.c + 'subdivision/check/{subdivisionId}'
			}
		},
		EMPLOYEES: {
			GET: edi.rest.prfx.c + 'employee',
			POST: edi.rest.prfx.c + 'employee',
			PUT: edi.rest.prfx.c + 'employee/{id}',
			DELETE: edi.rest.prfx.c + 'employee/{id}',
			EMPLOYEE: {
				GET: edi.rest.prfx.c + 'employee/{id}',
				PUT: edi.rest.prfx.c + 'employee/{id}'
			},
			ACTIVATE: {
				PUT: edi.rest.prfx.c + 'employee/{id}/activate'
			},
			DEACTIVATE: {
				PUT: edi.rest.prfx.c + 'employee/{id}/deactivate'
			},
			CONFIRM: {
				PUT: edi.rest.prfx.c + 'employee/user/{id}/confirm'
			},
			FIND: {
				GET: edi.rest.prfx.c + 'employee/find/{email}'
			},
			GROUP: {
				ADD: {
					PUT: edi.rest.prfx.c + 'employee/{id}/add_group/{groupName}'
				},
				REMOVE: {
					PUT: edi.rest.prfx.c + 'employee/{id}/remove_group/{groupName}'
				}
			},
			CHECK: {
				GET: edi.rest.prfx.c + 'employee/check/{id}'
			},
			DROPCAT_ROLES: {
				GET: edi.rest.prfx.c + 'employee/find/dropcat-roles'
			}
		},
		ROUTES: {
			GET: edi.rest.prfx.c + 'routes',
			POST: edi.rest.prfx.c + 'routes',
			PUT: edi.rest.prfx.c + 'routes/{id}',
			DELETE: edi.rest.prfx.c + 'routes/{id}',
			ROUTE: {
				GET: edi.rest.prfx.c + 'routes/{id}'
			},
			GLOBAL: {
				GET: edi.rest.prfx.c + 'routes/global'
			},
			PRIVATE: {
				GET: edi.rest.prfx.c + 'routes/private'
			}
		}
	},
	APPROVAL_DOCUMENTS: {
		GET: edi.rest.prfx.c + 'approvals',
		GET_PARENT_DOC_CONTENT: edi.rest.prfx.c + 'approvals/doc/{id}/content',
		RESTORE: {
			GET: edi.rest.prfx.c + 'approvals/archive/{id}/restoring'
		},
		CONTENT: {
			GET: edi.rest.prfx.c + 'approvals/{id}'
		},
		LINKED: {
			GET: edi.rest.prfx.c + 'approvals/{id}/linked'
		},
		LINKED_APPROVALS: {
			GET: edi.rest.prfx.c + 'approvals/{docId}/linkedApprovals'
		},
		SIGN_CONTENT: {
			PUT: edi.rest.prfx.c + 'approvals/{documentId}/signContent'
		},
		EXPORT: {
			GET: edi.rest.prfx.c + 'approvals/archive/{id}/export',
			COMMON: {
				GET: edi.rest.prfx.c + 'approvals/{id}/export/common'
			},
			ATTACHMENT: edi.rest.prfx.c + 'approvals/attachment/download/{id}'
		},
		POST: edi.rest.prfx.c + 'approvals',
		ATTACHMENT: {
			VIEW: {
				POST: edi.rest.prfx.c + 'approvals/attachment/view/{documentId}'
			}
		},
		REPORTS: {
			VIEW: {
				POST: edi.rest.prfx.c + 'reports/doc/view/{id}'
			}
		},
		BASED_ON_DOCUMENT: {
			POST: edi.rest.prfx.c + 'approvals/{docId}/create'
		},
		CORRECT: {
			POST: edi.rest.prfx.c + 'approvals/{id}/correct'
		},
		PUT: edi.rest.prfx.c + 'approvals/{id}/update',
		PUSH: {
			PUT: edi.rest.prfx.c + 'approvals/{id}/push'
		},
		DELETE: {
			ONE: {
				PUT: edi.rest.prfx.c + 'approvals/{id}/delete'
			},
			PUT: edi.rest.prfx.c + 'approvals/delete'
		},
		SIGN: {
			PUT: edi.rest.prfx.c + 'approvals/{id}/sign'
		},
		APPROVE: {
			PUT: edi.rest.prfx.c + 'approvals/{id}/approve'
		},
		REJECT: {
			PUT: edi.rest.prfx.c + 'approvals/{id}/reject'
		},
		REVOKE: {
			PUT: edi.rest.prfx.c + 'approvals/{id}/revoke'
		},
		REASSIGN: {
			PUT: edi.rest.prfx.c + 'approvals/{id}/reassign'
		},
		COMPLETE: {
			PUT: edi.rest.prfx.c + 'approvals/{id}/complete'
		},
		CHECK_PERMISSIONS: {
			GET: edi.rest.prfx.c + 'approvals/{id}/check/permissions/'
		}
	},
	TARIFFS: {
		GET: edi.rest.prfx.c + 'tariff',
		DETAILS: {
			GET: edi.rest.prfx.c + 'tariff/{id}'
		},
		PUT: edi.rest.prfx.c + 'tariff/{id}/select/{orgId}',
		REQUEST: {
			GET: edi.rest.prfx.c + 'tariff/request',
			POST: edi.rest.prfx.c + 'tariff/request'
		},
		ACTUAL: {
			GET: edi.rest.prfx.c + 'tariff/actual',
			POA: {
				GET: edi.rest.prfx.c + 'tariff/poa/actual'
			}
		}
	},
	ACCEPTANCE: {
		GET: edi.rest.prfx.c + 'onboarding/find/acceptance',
		SIGN: {
			PUT: edi.rest.prfx.c + 'onboarding/sign-acceptance/{docId}',
			GET: edi.rest.prfx.c + 'onboarding/sign-content/{docId}'
		}
	},
	PERSONAL_DATA_CONSENT: {
		GET: edi.rest.prfx.c + 'message/templates'
	},
	SYSTEM_ID: {
		GET: edi.rest.prfx.c + 'server/setting/SYSTEMID'
	},
	EDI_HELP_URL: {
		GET: edi.rest.prfx.c + 'server/setting/EDI_HELP_URL'
	}
});

Object.assign(edi.rest.services.DOCUMENTS, {
	GET_LINK: {
		POST: edi.rest.prfx.c + 'documents/external_links'
	}
});
