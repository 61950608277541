const agreementPackagesAPI = {
	ANNUL: {
		POST: edi.rest.prfx.c + 'documents/{containerType}/annul/{docId}'
	},
	UTOCH_WITH_CONTAINER: {
		POST: edi.rest.prfx.c + 'documents/{containerType}/withContainer/{containerId}',
		PDF_POST: edi.rest.prfx.c + 'documents/{containerType}/withContainer/pdfTemplate/{containerId}',
		ON_CREATE: edi.rest.prfx.c + 'documents/{containerType}/withContainer/onCreate/{containerId}'
	},
	UTOCH: {
		POST: edi.rest.prfx.c + 'documents/dp_container/{containerId}'
	},
	LOAD_ATTAH: {
		POST: edi.rest.prfx.c + 'documents/{containerType}/attachment/{type}/parse'
	},
	SIGNER_DATA_UPDATE: {
		PUT: edi.rest.prfx.c + 'documents/{containerType}/signer/data/update/{docId}'
	},
	TEMPLATE_DOWNLOAD: edi.rest.prfx.c + 'documents/dp_container/{docType}/template'
};

Ext.namespace('edi.rest.services.DOCUMENTS.DP_CONTAINER');
edi.rest.services.DOCUMENTS.DP_CONTAINER = agreementPackagesAPI;

export { agreementPackagesAPI };
