import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createFieldSet, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';

/**
 * Class for iftmbc details
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.modules');
edi.modules['document.details.iftmbc'] = function () {
	var moduleData,
		docData = null,
		productValues = {
			products: []
		};
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};
	/**
	 * Creates document form
	 * @returns {Object}
	 */
	var createDetailsPanel = function (documentData) {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSumm: true,
			noSignatures: true,
			noUsage: true
		});
		var headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.documentNumber'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(documentData, 'bookingResponseHeader.documentNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.7
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.2,
					text: edi.utils.getObjectProperty(documentData, 'bookingResponseHeader.documentDate'),
					valueLabel: true
				}),
				createLabel({
					columnWidth: 0.1,
					text: edi.utils.getObjectProperty(documentData, 'bookingResponseHeader.documentTime'),
					valueLabel: true
				})
			]
		});

		var parties = edi.utils.getObjectProperty(documentData, 'bookingResponseParties');
		var organizationFieldsMap = {
			company_iln: 'iln',
			company_name: 'name',
			company_inn: 'taxID',
			company_register_number: 'registerNumber',
			contact: 'contact',

			addr_category: 'type',
			addr_country: 'country',
			addr_zip: 'postalCode',
			addr_locality: 'locality',
			addr_block: 'housing',
			addr_addInf: 'additionalInformation',
			addr_area: 'district',
			addr_region_name: 'state',
			addr_region_code: 'stateCode',
			addr_city: 'cityName',
			addr_street: 'streetAndNumber',
			addr_flat: 'roomNumber',
			addr_home: 'houseNumber'
		};
		var getOrganizationFieldsMap = function (prefix) {
			var newOrganizationFieldsMap = {};
			for (var field in organizationFieldsMap) {
				if (organizationFieldsMap.hasOwnProperty(field)) {
					newOrganizationFieldsMap[field] = prefix + '.' + organizationFieldsMap[field];
				}
			}
			return newOrganizationFieldsMap;
		};

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				fieldsMapOnly: true,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				fieldValues: parties
			});

			return createCompanySelector(selectorConfig);
		};

		var buyer = createCS({
			title: 'document.carrier',
			fieldsMap: getOrganizationFieldsMap('buyer')
		});

		var seller = createCS({
			title: 'document.seller',
			fieldsMap: getOrganizationFieldsMap('seller')
		});

		var referenceDocument = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.referenceDocument'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseHeader.referenceBookingMessage.referenceBookingNumber'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.25,
					text: edi.utils.getObjectProperty(
						documentData,
						'bookingResponseHeader.referenceBookingMessage.referenceBookingDate'
					),
					valueLabel: true
				})
			]
		});

		var driver = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.driver'),
					input: createLabel({
						columnWidth: 0.684,
						text: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.name'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.7
					}
				}),
				createLabel({
					columnWidth: 0.3,
					margin: '0 0 0 5',
					text: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.phone'),
					valueLabel: true
				})
			]
		});

		var driverPassport = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.Passport'),
					input: createLabel({
						columnWidth: 0.358,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.passport.series'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.35
					}
				}),
				createField({
					input: createLabel({
						columnWidth: 1,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.passport.number'
						),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 10',
						columnWidth: 0.1
					}
				}),
				createField({
					input: createLabel({
						columnWidth: 1,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.passport.issuedBy'
						),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 10',
						columnWidth: 0.35
					}
				}),
				createField({
					input: createDateLabel({
						columnWidth: 1,
						text: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.passport.date'),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 10',
						columnWidth: 0.2
					}
				})
			]
		});

		var driverLicense = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.license'),
					input: createLabel({
						columnWidth: 0.778,
						text: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.license'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var transportInfo = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo'),
					input: createLabel({
						columnWidth: 0.52,
						text: edi.utils.getObjectProperty(
							documentData,
							'bookingResponseParties.carrier.transportNumber'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.45
					}
				}),
				createField({
					input: createLabel({
						columnWidth: 1,
						text: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.carBrand'),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.3
					}
				}),
				createField({
					input: createLabel({
						columnWidth: 1,
						text: edi.utils.getObjectProperty(documentData, 'bookingResponseParties.carrier.trailerNumber'),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.25
					}
				})
			]
		});

		var transportType = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.TotalNetAmount'),
					input: createLabel({
						columnWidth: 0.555,
						text: edi.utils.getObjectProperty(documentData, 'bookingResponseSummary.totalNetAmount'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbc.transportInfo.loadedQuantity'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(documentData, 'bookingResponseSummary.loadedQuantity'),
						valueLabel: true
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var twoColumnsLayout = createTwoColumnsLayout(
			[headData, seller, buyer, referenceDocument],
			[
				createFieldSet({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo'),
					items: [driver, driverPassport, driverLicense, transportInfo, transportType]
				})
			],
			0.5
		);

		var lines = edi.utils.getObjectProperty(documentData, 'bookingResponseLines.shipTos', true);
		if ('string' != typeof lines) {
			productValues.products = lines;
		}
		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.iftmbc.unloadingPoints'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'IFTMBC_LINE',
			gridColumnConfig: 'iftmbc_lines',
			data: productValues.products,
			hasTotal: false,
			readOnly: true,
			totalsHandler: function (component) {
				component.callback(component.getValues());
			},
			modalFormConfig: {
				title: 'documents.iftmbf.unloadingPoint',
				modalFields: [
					{
						title: edi.i18n.getMessage('documents.iftmbf.line.modal.tab.main.data'),
						items: [
							{
								title: edi.i18n.getMessage('column.iln'),
								name: 'iln',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbc.estimatedDispatchDate'),
								name: 'estimatedDispatchDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbc.estimatedDispatchTime'),
								name: 'estimatedDispatchTime',
								type: 'time'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbf.type'),
								name: 'type',
								store: edi.stores.createInlineStore([
									{
										id: 'Transit',
										name: edi.i18n.getMessage('Transit')
									},
									{
										id: 'Final',
										name: edi.i18n.getMessage('Final')
									}
								]),
								displayField: 'name',
								valueField: 'id',
								type: 'combo'
							},
							{
								title: edi.i18n.getMessage('column.name'),
								name: 'name'
							},
							{
								title: edi.i18n.getMessage('column.address'),
								name: 'streetAndNumber'
							},
							{
								title: edi.i18n.getMessage('column.city.name'),
								name: 'cityName'
							},
							{
								title: edi.i18n.getMessage('column.state'),
								name: 'state'
							},
							{
								title: edi.i18n.getMessage('column.state.code'),
								name: 'stateCode',
								type: 'combo',
								store: edi.stores.initRegionsStore(),
								valueField: 'id',
								displayField: 'display_name',
								anyMatch: true
							},
							{
								title: edi.i18n.getMessage('company.country.code'),
								name: 'country',
								type: 'combo',
								store: edi.stores.initValidCountryFullStore(),
								valueField: 'iso_2',
								value: 'RU',
								displayField: 'name'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbf.contact'),
								name: 'contact'
							}
						]
					}
				]
			}
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, productsGrid]
		});
	};

	/**
	 * Creates action pane
	 */
	var createModuleActionsPanel = function (docData) {
		var data = moduleData.initData.data;
		docData.id = data.id;
		var actionsPanel = createActionsPanel();
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);

		var excludeActions = {};
		excludeActions[edi.constants.DOCUMENT_ACTIONS.SIGN] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			excludeActions: excludeActions,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.iftmbc'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				productValues = {
					products: []
				};
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					docData = data.data;
					var continueLoading = function () {
						modulePanel.add(createDetailsPanel(docData));
						var actionsPanel = createModuleActionsPanel(docData);
						moduleData.tab.add(actionsPanel);
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						} else {
							moduleData.tab.setLoading(false);
						}
					};
					continueLoading();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
