const ALCDES_LINE_MODEL = 'ALCDES_LINE';
const ALCDES_SUMMARY_DETAIL_MODEL = 'ALCDES_SUMMARY';
const ALCDES_LINE_PARTY_MODEL = 'ALCDES_LINE_PARTY';
const ALCDES_LINE_REFERENCE_MODEL = 'ALCDES_LINE_REFERENCE';
const ALCDES_LINE_CERTIFICATE_MODEL = 'ALCDES_LINE_CERTIFICATE';

edi.models.setModels({
	[ALCDES_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'OrderedQuantity',
				type: 'string'
			},
			{
				name: 'QuantityDespatched',
				type: 'string'
			},
			{
				name: 'UnitPacksize',
				type: 'string'
			},
			{
				name: 'UnitVolume',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'ExpirationDate',
				type: 'string'
			},
			{
				name: 'BatchNumber',
				type: 'string'
			},
			{
				name: 'ItemStatusCode',
				type: 'string'
			},
			{
				name: 'ColourCode',
				type: 'string'
			},
			{
				name: 'ItemSize',
				type: 'string'
			},
			{
				name: 'ItemType',
				type: 'string'
			},
			{
				name: 'SupplierPackCode',
				type: 'string'
			},
			{
				name: 'UnitNetPrice',
				type: 'string'
			},
			{
				name: 'UnitGrossPrice',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'SuggestedPrice',
				type: 'string'
			},
			{
				name: 'MaxRetailPrice',
				type: 'string'
			},
			{
				name: 'NetAmount',
				type: 'string'
			},
			{
				name: 'TaxAmount',
				type: 'string'
			},
			{
				name: 'GrossAmount',
				type: 'string'
			},
			{
				name: 'Remarks',
				type: 'string'
			},
			{
				name: 'AlcoholCode',
				type: 'string'
			},
			{
				name: 'PackagingDate',
				type: 'string'
			},
			{
				name: 'Line-License'
			},
			{
				name: 'Line-Parties'
			},
			{
				name: 'Line-Reference'
			},
			{
				name: 'Line-Certificate'
			},
			{
				name: 'Line-AdditionalInformation'
			}
		],
		idProperty: 'LineNumber'
	},
	[ALCDES_SUMMARY_DETAIL_MODEL]: {
		fields: [
			{
				name: 'TotalPSequence',
				type: 'number'
			},
			{
				name: 'TotalLines',
				type: 'number'
			},
			{
				name: 'TotalGoodsDespatchedAmount',
				type: 'string'
			},
			{
				name: 'TotalNetAmount',
				type: 'float'
			},
			{
				name: 'TotalGrossAmount',
				type: 'float'
			},
			{
				name: 'TotalTaxAmount',
				type: 'string'
			},
			{
				name: 'TotalWeight',
				type: 'string'
			}
		]
	},
	[ALCDES_LINE_PARTY_MODEL]: {
		fields: [
			{
				name: 'PartyType',
				type: 'string'
			},
			{
				name: 'ILN',
				type: 'string'
			},
			{
				name: 'TaxID',
				type: 'string'
			},
			{
				name: 'TaxRegistrationReasonCode',
				type: 'string'
			},
			{
				name: 'Name',
				type: 'string'
			}
		]
	},
	[ALCDES_LINE_REFERENCE_MODEL]: {
		fields: [
			{
				name: 'Reference-Type',
				type: 'string'
			},
			{
				name: 'Reference-Id',
				type: 'string'
			}
		]
	},
	[ALCDES_LINE_CERTIFICATE_MODEL]: {
		fields: [
			{
				name: 'Number',
				type: 'string'
			},
			{
				name: 'IssuingAuthority',
				type: 'string'
			},
			{
				name: 'DateOfIssue',
				type: 'string'
			},
			{
				name: 'ExpirationDate',
				type: 'string'
			}
		]
	}
});

export {
	ALCDES_LINE_MODEL,
	ALCDES_SUMMARY_DETAIL_MODEL,
	ALCDES_LINE_PARTY_MODEL,
	ALCDES_LINE_REFERENCE_MODEL,
	ALCDES_LINE_CERTIFICATE_MODEL
};
