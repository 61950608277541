const RECADV_LINE_MODEL = 'RECADV_LINE';
const RECADV_SUMMARY_MODEL = 'RECADV_SUMMARY';

edi.models.setModels({
	[RECADV_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'CountryOfOrigin',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'ItemType',
				type: 'string'
			},
			{
				name: 'QuantityOrdered',
				type: 'string'
			},
			{
				name: 'QuantityReceived',
				type: 'string'
			},
			{
				name: 'QuantityAccepted',
				type: 'string'
			},
			{
				name: 'QuantityNotAccepted',
				type: 'string'
			},
			{
				name: 'QuantityDelivered',
				type: 'string'
			},
			{
				name: 'QuantityDamaged',
				type: 'string'
			},
			{
				name: 'QuantityOverOrdered',
				type: 'string'
			},
			{
				name: 'QuantityUndelivered',
				type: 'string'
			},
			{
				name: 'ReasonCode',
				type: 'string'
			},
			{
				name: 'UnitPacksize',
				type: 'string'
			},
			{
				name: 'UnitBasePrice',
				type: 'string'
			},
			{
				name: 'UnitGrossPrice',
				type: 'string'
			},
			{
				name: 'UnitNetPrice',
				type: 'string'
			},
			{
				name: 'NetAmount',
				type: 'string'
			},
			{
				name: 'TaxAmount',
				type: 'string'
			},
			{
				name: 'GrossAmount',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'ExpirationDate',
				type: 'date',
				dateFormat: 'Y-m-d'
			},
			{
				name: 'BatchNumber',
				type: 'string'
			},
			{
				name: 'SerialNumber',
				type: 'string'
			},
			{
				name: 'Remarks',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'CountryOfOrigin',
				type: 'string'
			}
		],
		idProperty: 'LineNumber'
	},
	[RECADV_SUMMARY_MODEL]: {
		fields: [
			{
				name: 'TotalLines',
				type: 'number'
			},
			{
				name: 'TotalGoodsReceiptAmount',
				type: 'string'
			},
			{
				name: 'TotalNetAmount',
				type: 'float'
			},
			{
				name: 'TotalGrossAmount',
				type: 'string'
			},
			{
				name: 'TotalTaxAmount',
				type: 'string'
			}
		]
	}
});

export { RECADV_LINE_MODEL, RECADV_SUMMARY_MODEL };
