import { createArgs, createFormItems } from '@Ediweb/modules/CONTROL_CENTER/tabs/EMPLOYEE_CERTIFICATES/filters';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createActionsColumnConfig, createGrid } from '@UIkit/components/grid';
import { createCertDetailsModal } from '@Ediweb/modules/CONTROL_CENTER/tabs/EMPLOYEE_CERTIFICATES/createCertDetailsModal';
import { createPanel } from '@UIkit/components/panels';
import { USER_CERTIFICATES_MODEL } from '@Ediweb/modules/user_profile/models';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { certificatesAPI } from '@Ediweb/modules/CONTROL_CENTER/tabs/EMPLOYEE_CERTIFICATES/rest';
import { userCertificatesAPI } from '@Ediweb/modules/CONTROL_CENTER/tabs/CERTIFICATES/rest';

const createUserCertificatesPanel = function (tabModuleData, hints) {
	let filterObject, grid, filter;
	let certificatesURL = edi.rest.services.USER.SELF.CERTIFICATE.LIST.GET;

	const selectCertificate = function () {
		if (!edi.sign.isAvailable()) {
			edi.sign.displayNotAvailableMessage();
		} else {
			edi.methods.application_to_fns.signApplicationAndCert(
				null,
				true,
				function (failed, data, selectedCertificate, poa, silent) {
					var failure = function (data) {
						if (silent) {
							tabModuleData.tab.setLoading(false);
						} else {
							edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
								tabModuleData.tab.setLoading(false);
								edi.events.certificates.fireEvent('change');
							});
						}
					};
					if (failed) {
						failure(data);
					} else {
						tabModuleData.tab.setLoading(false);
						edi.events.certificates.fireEvent('change');
					}
				},
				{
					signUrl: certificatesAPI.POST,
					loadFromUserProfile: true,
					disabledPoa: true
				}
			);
		}
	};

	const createAddCertificateButton = function () {
		return createButton({
			tooltip: edi.i18n.getMessage('form.btn.add'),
			text: edi.i18n.getMessage('form.btn.add'),
			cls: [BUTTON_CLS.primary],
			glyph: edi.constants.ICONS.PLUS,
			handler: selectCertificate
		});
	};

	const getCertificateButton = function () {
		return createButton({
			tooltip: edi.i18n.getMessage('get.certificate.btn'),
			text: edi.i18n.getMessage('get.certificate.btn'),
			cls: [BUTTON_CLS.outline, hints?.hint1Cls],
			glyph: edi.constants.ICONS.NOTE_ADD,
			handler: () => {
				let tab = window.open('https://order.taxcom.ru/agents/?ai1=89E45-31D3-4B8&RegType=QSERTFL', '_blank');
				tab.focus();
			}
		});
	};

	const createCertificateFilterForm = function () {
		let fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(certificatesURL, form, grid, createArgs, null, {
					persistence: {
						enabled: true,
						name: `${tabModuleData.modName}_cert_grid`
					}
				});
			}
			filterObject.filter();
		};
		let { formItemsMap, items } = createFormItems({
			fullNameUrl: userCertificatesAPI.FIND_BY_NAME
		});

		const form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${tabModuleData.modName}_grid_cert`
				},
				border: 1,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultValidDate = edi.utils.getUserDefaultSearchPeriod(
						edi.constants.DEFAULT.FILTER.PERIODS.WEEK
					);
					formItemsMap.validDate?.setRange(defaultValidDate);
				}
			},
			fireSearch
		);

		return form;
	};

	const createActionColumn = function () {
		return createActionsColumnConfig({
			items: [
				{
					glyph: edi.constants.ICONS.DETAILS,
					handler: function (grid, rowIndex, colindex, actionItem, event, record) {
						var recordData = record.getData();
						const id = recordData.data.id;
						createCertDetailsModal(id, recordData, {
							showPoaButtons: true,
							certData: recordData.data,
							poaData: recordData.poaData
						});
					}
				}
			]
		});
	};

	const createCertificatesGrid = function () {
		let columns = edi.columns.get('ew_certificates');
		let actionColumn = createActionColumn();
		actionColumn ? columns.push(actionColumn) : null;
		return createGrid({
			proxyConfig: {
				type: 'ajax',
				url: certificatesURL
			},
			storeConfig: {
				model: edi.models.getModel(USER_CERTIFICATES_MODEL),
				autoLoad: true,
				sortOnLoad: true,
				sorters: {
					property: 'creationDate',
					direction: 'DESC'
				}
			},
			gridConfig: {
				columns: columns,
				padding: '0 0 0 0',
				region: 'center',
				border: 1,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						const id = record.getData().data.id;
						createCertDetailsModal(id, record.getData(), {
							showPoaButtons: true,
							certData: record.data.data,
							poaData: record.data.poaData
						});
					}
				},
				tbar: {
					items: [createAddCertificateButton(), '->', getCertificateButton()]
				}
			},
			viewConfig: {
				emptyTextTplOptions: {
					enabled: true,
					iconName: 'certificates',
					title: edi.i18n.getMessage('ediweb.certificates.empty.text.tpl.title'),
					contentText: edi.i18n.getMessage('ediweb.certificates.empty.text.tpl.contentText'),
					beforeButtonText: edi.i18n.getMessage('ediweb.certificates.empty.text.tpl.beforeButtonText'),
					buttonText: edi.i18n.getMessage('ediweb.certificates.empty.text.tpl.buttonText'),
					buttonName: 'emptyTextButton',
					buttonClickHandler: function (e, btn, name) {
						filter.toggleHandler();
					}
				}
			}
		});
	};

	grid = createCertificatesGrid();
	filter = createCertificateFilterForm();

	const updateGrid = function () {
		grid.getStore().reload();
		edi.events.application_to_fns.fireEvent('change');
	};

	edi.events.certificates.on('change', updateGrid);

	return createPanel({
		layout: 'border',
		items: [filter, grid],
		onDestroy: function () {
			edi.events.certificates.un('change', updateGrid);
		}
	});
};

export { createUserCertificatesPanel };
