import { createActionsColumnConfig, createGrid, createGridActionBar } from '@Components/grid';
import { createNotificationDialog } from './notification.dialog';
import { createProxyConfig } from '@Components/storeComponents';
import { createPanel } from '@Components/panels';
import { createCombo, createFieldContainer, createTagTextField } from '@Components/fields';
import { createModuleFilterForm } from '@Components/moduleFilterForm';

Ext.namespace('edi.modules');
/**
 * @module	email.notifications	email notification about new incoming documents
 * @author	Konstantin Starikov
 */
edi.modules['email.notification'] = function () {
	var moduleData, partners, notificationsGrid, filterForm, fireSearch, filterObject;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called after initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		partners = edi.relations.getRelations();
		/**
		 * 		 partners.push(edi.core.getUserData().org);
		 * Данная строка нужна только для ЭТРН. Для всех остальных документов она должна быть удалена
		 */
		partners = partners.reduce(function (obj, partner) {
			obj[partner.id] = {
				id: partner.id,
				name: partner.name
			};
			return obj;
		}, {});

		renderData(initCallBack);

		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		notificationsGrid = createNotificationsGrid();
		filterForm = createFilterForm(notificationsGrid);
		moduleData.tab.add(notificationsGrid);
		moduleData.tab.add(filterForm);
		updateNotificationsGrid();

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
	};

	/**
	 * Creates notifications grid
	 * @return	{Object}	notificationGrid
	 */
	var createNotificationsGrid = function () {
		var columns = edi.columns.get('user_email_notifications');
		var actions = [
			{
				glyph: edi.constants.ICONS.EDIT,
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					openModalWindow(record);
				}
			},
			{
				glyph: edi.constants.ICONS.REMOVE,
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					edi.core.confirm(
						'user_email_notification.delete.confirm.title',
						'user_email_notification.delete.confirm',
						function () {
							deleteNotification(record.get('id'));
						}
					);
				}
			}
		];
		columns.push(
			createActionsColumnConfig({
				items: actions,
				width: edi.utils.getActionColumnWidth(actions.length)
			})
		);

		return createGrid({
			proxyConfig: {
				type: 'ajax',
				url: edi.rest.services.USER_EMAIL_NOTIFICATIONS_V2.LIST.GET
			},
			storeConfig: {
				model: edi.models.getModel('USER_EMAIL_NOTIFICATION'),
				autoLoad: false
			},
			gridConfig: {
				cls: 'tab-grid with-filter',
				columns: columns,
				region: 'center',
				border: 0,
				autoScroll: true,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						openModalWindow(record);
					}
				},
				tbar: createGridToolbar()
			}
		});
	};

	var createFilterForm = function (grid) {
		fireSearch = function () {
			if (!filterObject) {
				filterObject = edi.filters.createGridFilter(
					edi.rest.services.USER_EMAIL_NOTIFICATIONS_V2.LIST.GET,
					filterForm,
					grid,
					(values) => {
						if (Array.isArray(values.notificationEmails) && values.notificationEmails.length === 0) {
							delete values.notificationEmails;
						}
						return values;
					}
				);
			}
			filterObject.filter();
		};

		let formItemsMap = {
			email: createTagTextField({
				fieldLabel: edi.i18n.getMessage('user_email_notification.emailList'),
				regex: edi.constants.VALIDATORS.EMAIL,
				name: 'notificationEmails'
			}),
			partner: createCombo({
				fieldLabel: edi.i18n.getMessage('email.notification.filter.partner'),
				emptyText: '',
				store: edi.stores.initRelatedOrganizationsFirstEmptyStore(),
				anyMatch: true,
				autoValue: true,
				name: 'orgPartners',
				showQtips: true,
				multiSelect: true,
				listeners: {
					select: function (combo, records) {
						var data = records && records.length ? records[0].getData() : null;
						var filiationsCombo = formItemsMap.includedFilials;

						if (data && filiationsCombo) {
							filiationsCombo.setVisible(data.hasFilials);
							if (!data.hasFilials) {
								filiationsCombo.setValue(null);
							}

							if (data.hasFilials && filiationsCombo && filiationsCombo.getStore()) {
								filiationsCombo.getStore().setProxy(
									createProxyConfig({
										type: 'ajax',
										url: edi.utils.compileURL(edi.rest.services.FILIATIONS.GET, {
											headOrgId: data.id
										})
									})
								);

								filiationsCombo.getStore().load();
							}
						}
					}
				}
			})
		};

		var form = createModuleFilterForm(
			{
				saveFilter: {
					name: `${moduleData.modName}_email_notifications`
				},
				bodyPadding: 0,
				formItemsMap,
				items: [
					createPanel({
						layout: {
							type: 'grid',
							area: [[6, 6]]
						},
						padding: '0 16',
						items: [formItemsMap.partner, formItemsMap.email]
					})
				]
			},
			fireSearch
		);

		form.fireSearch = fireSearch;
		return form;
	};

	/**
	 * Creates notifications grid's toolbar
	 * @return	{Object}	toolbar
	 */
	var createGridToolbar = function () {
		return createGridActionBar({
			actionCfgs: [
				{
					id: edi.constants.DOCUMENT_ACTIONS.ADD,
					name: edi.i18n.getMessage('form.btn.add'),
					notDisabled: true
				}
			],
			defaultHandler: function (id) {
				if (edi.constants.DOCUMENT_ACTIONS.ADD === id) {
					openModalWindow(null);
				}
			}
		});
	};

	/**
	 * Open create/edit modal window for notification
	 * @param	{Object}	record    clicked row record or new record
	 */
	var openModalWindow = function (record) {
		createNotificationDialog(record, updateNotificationsGrid).show();
	};

	/**
	 * проверка на изменённые данные формы (isDirty почему-то всегда возвращает true)
	 *  при редактировании пользователь может менять 4 параметра
	 * @param userValues	данные формы
	 * @param sourceData	исходные данные оповещения
	 * @returns {boolean}
	 */
	var noChangesDetected = function (userValues, sourceData) {
		var result = true;
		var pureSourceEmails = Array.isArray(sourceData.emailList) ? sourceData.emailList : [sourceData.emailList];

		if (userValues.direction !== sourceData.direction) {
			result = false;
		} else if (userValues.states !== sourceData.state) {
			// да, это разные свойства
			result = false;
		} else if (!Ext.Array.equals(userValues.emailList.sort(), pureSourceEmails.sort())) {
			result = false;
		} else if (!sourceData.allPartners) {
			var pureOrgPartners = [];
			userValues.orgPartners?.forEach(function (elem) {
				pureOrgPartners.push(parseInt(elem, 10));
			});
			if (!Ext.Array.equals(pureOrgPartners.sort(), sourceData.orgPartners.sort())) {
				result = false;
			}
		}
		return result;
	};

	/**
	 * Delete notification record
	 * @param	{string}	recordId	record's id for delete
	 */
	var deleteNotification = function (recordId) {
		moduleData.tab.setLoading(true);
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.USER_EMAIL_NOTIFICATIONS_V2.DELETE, { notificationId: recordId }),
			'DELETE',
			null,
			function () {
				updateNotificationsGrid();
			},
			function (data) {
				edi.core.showError(edi.utils.formatComplexServerError(data, 'user_email_notification.error.delete'));
			},
			function () {
				moduleData.tab.setLoading(false);
			}
		);
	};

	/**
	 * Updates grid after data was changed
	 */
	var updateNotificationsGrid = function () {
		notificationsGrid.getStore().load();
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}		false to stop module destroy
	 */
	var onDestroy = function () {
		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};

	var createFieldContainerFn = function (fieldLabel, input, hidden) {
		return createFieldContainer({
			fieldLabel: edi.i18n.getMessage(fieldLabel),
			layout: 'hbox',
			hidden: hidden,
			margin: '0 12 0 17',
			defaults: {
				layout: '100%'
			},
			fieldDefaults: {
				msgTarget: 'under',
				labelAlign: 'top'
			},
			columnWidth: 0.25,
			items: [input],
			cls: 'edi-form-field'
		});
	};
};
