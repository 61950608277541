import { createForm, createPanel, createContainer } from '@UIkit/components/panels';
import { createTab, createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import {
	createGrid,
	createActionsColumnConfig,
	ROW_COLOR_CLS,
	createCheckboxSelectionModel,
	createToolBar
} from '@UIkit/components/grid';
import { createPanelWithModalSelect } from '@Core/specialComponents/miscComponents';
import { createEditableGrid } from '@Components/editableGrid/EditableGrid';
import { createTreeGrid } from '@Components/tree.grid';
import { createSimpleSelector } from '@UIkit/components/selectors/simpleSelector/SimpleSelector';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createModalProductSelect } from '@Edi/specialComponents/modalProductSelect';
import { createProxyConfig } from '@Components/storeComponents';
import { createFieldsForProductGrid } from '@Components/fields';
import { createRowsBlock } from '@Components/createRows/create.rows';

import './ProductGrid.scss';
import { showConfirm } from '@UIkit/components/modal/MessageBox';

Ext.define('UI.components.ProductGrid', {
	extend: 'Ext.panel.Panel',

	cls: 'edi-product-grid-container',
	border: 0,
	focusable: true,
	tabIndex: 2,
	productsGrid: null,
	readOnly: false,
	showSelectProductBtn: true,
	showDetailRowButton: true,
	hideDeleteButton: true,
	deleteButton: null,
	modalConf: {},
	columnsConf: {},
	fieldsConf: {},
	data: null,

	/**
	 * Allows to pass default values for filling product modal fields
	 */
	defaultValuesGetter: function () {
		// to be overwritten
	},
	totalsGridConfig: {
		margin: '24 0 4 0' //отступ внизу нужен что бы видно было тень
	},
	totalsGridHeaderLabel: 'grid.title.total',
	gridModel: 'PRODUCT_LINE',
	docType: null,
	docData: null,
	totalsHandlerCallback: null,
	totalModel: 'PRODUCT_TOTAL',
	gridColumnConfig: 'product_extended',
	totalColumnConfig: 'product_extended_total',
	lineNumberFieldName: 'number',
	clearFieldsOnSet: ['vat_display'],
	hideAddButton: false,
	customAddModal: false,
	partnerId: null,
	lockIfNoPartner: false,
	allowBlank: false,
	ownCatalog: true,
	hasTotal: true,
	addButton: null,
	allowBlankEditableGrids: [], // массив editableGrid, которые должны быть обязательны чтобы фокус был на нем,
	// если в нем есть незаполенные обязательные поля. Формат данных [{
	//				'textInfos': ['identifier', 'value']
	//			}], где в объекте ключ - это имя editableGrid
	//							  значения - массив обязательных полей грида
	isFocusOnInvalidField: true,
	modalFormConfig: undefined,
	resetFormOnCatalogSelection: false,
	/**
	 * Функция возвращает набор полей который добавляется (formPanel.add) в форму модального окна
	 * @param    {object}    recordData
	 * @returns    {Ext.Component[] | Ext.Component}
	 */
	createModalForm: undefined,
	initialConfig: undefined,
	initialActionColumnConfig: undefined,
	gridConfigCls: '',
	gridConfig: {},
	addedTools: null,

	formRecord: null,
	formMethods: {},
	formFields: null,
	formPanel: null,
	formPanelCfg: undefined,
	formGrids: {},
	tabPanel: null,

	/**
	 * set partner id that will be used for getting prodcat
	 * @param    {String}    partnerId
	 */
	setPartnerId: function (partnerId) {
		this.partnerId = partnerId;
		if (this.addButton) {
			if (!this.partnerId && this.lockIfNoPartner) {
				this.addButton.setDisabled(true);
			} else {
				this.addButton.setDisabled(false);
			}
		}
	},

	/**
	 * get partner id
	 * @returns    {String}
	 */
	getPartnerId: function () {
		return String(this.partnerId);
	},

	initComponent: function () {
		this.setOwnConfig();
		this.callParent();
	},

	/**
	 * Check is data in grid valid.
	 * @returns {boolean}
	 */
	isValid: function () {
		var res = this.allowBlank;
		var store = this.productsGrid.getStore(),
			i,
			record,
			records;
		if (store.getCount()) {
			res = true;
			records = store.getRange();
			for (i = 0; i < records.length; i++) {
				if (!records[i].isExternalValid) {
					res = false;
					break;
				}
			}
		}
		if (res && this.hasTotal) {
			store = this.productsTotalGrid.getStore();
			if (store.getCount()) {
				for (i = 0; i < store.getCount(); i++) {
					record = store.getAt(i);
					if (!this.isValidTotal(record)) {
						res = false;
						break;
					}
				}
			}
		}
		return res;
	},

	/**
	 * Check is record valid. To be overwritten.
	 * @param    {Object}    record
	 * @returns {boolean}
	 */
	isValidRecord: function (record) {
		return !!record;
	},

	/**
	 * Check is total valid. To be overwritten.
	 * @param    {Object}    record
	 * @returns {boolean}
	 */
	isValidTotal: function (record) {
		return !!record;
	},

	/**
	 * Definition of internal components children
	 */
	setOwnConfig: function () {
		var __self = this,
			detailsArrowBtnConfig;
		var selectedRecords = [];
		var isModalConfig =
			(this.modalFormConfig &&
				'object' == typeof this.modalFormConfig &&
				!Ext.Object.isEmpty(this.modalFormConfig)) ||
			typeof this.createModalForm === 'function';
		if (!this.ownConfigSet) {
			__self.addButton =
				isModalConfig && !this.hideAddButton
					? createButton({
							disabled: !__self.partnerId && __self.lockIfNoPartner,
							cls: `${BUTTON_CLS.secondary} ${BUTTON_CLS.small} edi-row-buttons__add-button`,
							glyph: edi.constants.ICONS.PLUS,
							text: edi.i18n.getMessage('form.btn.add'),
							handler: function () {
								var defaultRecord = undefined;
								if ('function' == typeof __self.defaultValuesGetter) {
									defaultRecord = __self.defaultValuesGetter();
								}
								'function' == typeof __self.customAddModal
									? __self.customAddModal.call(__self, undefined, defaultRecord)
									: __self.showModalProductForm(undefined, defaultRecord);
							}
					  })
					: null;

			if (isModalConfig && !this.hideDeleteButton) {
				__self.deleteButton = createButton({
					disabled: true,
					cls: `${BUTTON_CLS.secondary} ${BUTTON_CLS.small} edi-row-buttons__delete-button`,
					glyph: edi.constants.ICONS.DELETE,
					text: edi.i18n.getMessage('form.btn.delete'),
					handler: function () {
						var itemsIds = selectedRecords.map(function (item) {
							return item.LineNumber - 1;
						});
						edi.core.confirm(null, 'product.line.remove', function () {
							__self.productsGrid.getStore().remove(itemsIds);
						});
					}
				});
			}
			//end
			var columnsConfig = Ext.isArray(__self.gridColumnConfig)
				? __self.gridColumnConfig
				: edi.columns.get(__self.gridColumnConfig);
			if (!this.readOnly || this.showDetailRowButton) {
				var rowButtons = [];
				if (isModalConfig) {
					if (__self.readOnly && __self.gridConfig && __self.gridConfig.detailsArrowBtnConfig) {
						detailsArrowBtnConfig =
							'object' === typeof __self.gridConfig.detailsArrowBtnConfig
								? __self.gridConfig.detailsArrowBtnConfig
								: {};
						if (!detailsArrowBtnConfig.hasOwnProperty('handler')) {
							detailsArrowBtnConfig.handler = function (grid, rowIndex) {
								var record = grid.getStore().getAt(rowIndex);
								if (record) {
									__self.showModalProductForm(record, undefined);
								}
							};
						}
					} else {
						rowButtons.push({
							glyph: __self.readOnly ? edi.constants.ICONS.DETAILS : edi.constants.ICONS.EDIT,
							cls: __self.readOnly ? 'edi-action-column-details' : 'edi-action-column-edit',
							testCls: __self.readOnly ? 'test-action-column-details' : 'test-action-column-edit',
							handler: function (grid, rowIndex) {
								var record = grid.getStore().getAt(rowIndex);
								if (record) {
									__self.showModalProductForm(record, undefined);
								}
							}
						});
					}
				}
				if (!this.readOnly && !this.disableRemoveLines) {
					rowButtons.push({
						glyph: edi.constants.ICONS.DELETE,
						testCls: 'test-action-column-remove',
						handler: function (grid, rowIndex) {
							edi.core.confirm(null, 'product.line.remove', function () {
								var record = grid.getStore().getAt(rowIndex);
								grid.getStore().removeAt(rowIndex);
								if ('function' == typeof __self.afterRowRemove) {
									__self.afterRowRemove(record);
								}
							});
						}
					});
				}
				__self.initialActionColumnConfig = createActionsColumnConfig({
					items: rowButtons
				});
				columnsConfig.push(__self.initialActionColumnConfig);
			}
			var isLineStoreLoaded = false,
				isTotalStoreLoaded = false,
				isDataSet = __self.readOnly;

			var setData = function () {
				let initialData = __self.data || __self.config?.data;
				if (initialData?.length) {
					__self.batchSetGridRows(initialData);
				}
			};

			var setTotals = function () {
				if (__self.hasTotal && isLineStoreLoaded && isTotalStoreLoaded) {
					__self.totalsHandler(__self, 'load_rows');
				}
			};

			let finishProductGridCreation = function () {
				if (isLineStoreLoaded) {
					if ((isTotalStoreLoaded || !__self.hasTotal) && !isDataSet && __self?.productsGrid) {
						isDataSet = true;
						setData();
					}
				}
			};

			var listeners = {
				load: {
					fn: function () {
						isLineStoreLoaded = true;
						finishProductGridCreation();
					},
					options: {
						single: true
					}
				},
				remove: function (curStore) {
					// первый цикл - чтобы не было накладок с дублирующимися номерами строк
					curStore.each(function (record, index) {
						record.beginEdit();
						record.set(__self.lineNumberFieldName, -(index + 1));
						record.commit(true);
						record.endEdit();
						return true;
					});
					curStore.each(function (record, index) {
						record.beginEdit();
						record.set(__self.lineNumberFieldName, index + 1);
						record.commit(true);
						record.endEdit();
						return true;
					});
					'function' == typeof __self.callback ? __self.callback(__self.getValues()) : null;
					__self.totalsHandler(__self, 'itemremove');
					//по невыясненной причине синхронный вызов обновления грида не срабатывает корректно
					setTimeout(function () {
						__self.productsGrid.getView().refresh();
					}, 10);
				},
				scope: this
			};
			if (!this.hasTotal) {
				listeners.update = function (store, record, operation) {
					if (operation == Ext.data.Model.COMMIT) {
						'function' == typeof this.callback ? this.callback(this.getValues()) : null;
					}
				};
			}

			var plugins = [];
			var editingPlugin;
			if (!__self.readOnly) {
				plugins.push(
					(editingPlugin = Ext.create('Ext.grid.plugin.CellEditing', {
						clicksToEdit: 1,
						listeners: {
							edit: function (editor, context) {
								var record = context.record;
								var values = record.data;
								__self.afterRowEdit(values, context.field);
								__self.changeValuesAfterEdit(values);
								__self.setGridRow(values, record);
							},
							canceledit(editor, context) {
								var record = context.record;
								var values = record.data;
								__self.afterRowEdit(values, context.field);
								__self.changeValuesAfterEdit(values);
								__self.setGridRow(values, record);
							}
						}
					}))
				);
			}
			if (__self.subRowDataConfig && __self.subRowDataConfig.columns) {
				__self.subRowDataConfig.columns = Ext.isArray(__self.subRowDataConfig.columns)
					? __self.subRowDataConfig.columns
					: edi.columns.get(__self.subRowDataConfig.columns);
				plugins.push(
					Ext.apply(
						{
							ptype: 'rowsubgrid'
						},
						__self.subRowDataConfig
					)
				);
			}

			let buttons = [];
			if (!this.readOnly && isModalConfig) {
				if (!this.hideDeleteButton) {
					buttons.push(__self.deleteButton);
				}
				if (!this.hideAddButton) {
					buttons.push(__self.addButton);
				}
			}

			const toolbarItems =
				!this.readOnly && isModalConfig ? buttons.concat(this.addedTools || []) : this.addedTools || [];

			var config = __self.readOnly
				? {
						storeConfig: {
							proxy: createProxyConfig({
								type: 'pagingmemory'
							}),
							data: __self.data || __self.config?.data || [],
							model: edi.models.getModel(__self.gridModel),
							remoteSort: true,
							listeners: {
								load: function () {
									isLineStoreLoaded = true;
									setTotals();
								}
							}
						},
						viewConfig: {
							minHeight: 40
						},
						gridConfig: Object.assign(
							{
								columns: columnsConfig,
								plugins: plugins,
								layout: 'auto',
								detailsArrowBtnConfig: detailsArrowBtnConfig ? detailsArrowBtnConfig : undefined,
								listeners: {},
								cls: 'edi-grid-products read-only ' + __self.gridConfigCls,
								disableSelection: true
							},
							__self.gridConfig
						)
				  }
				: {
						storeConfig: {
							proxy: createProxyConfig({
								type: 'memory',
								data: {
									items: []
								}
							}),
							model: edi.models.getModel(__self.gridModel),
							remoteSort: false,
							listeners: listeners
						},
						viewConfig: {
							emptyText: !__self.allowBlank ? edi.i18n.getMessage('grid.empty.mandatory') : '',
							enableTextSelection: true,
							minHeight: 40,
							getRowClass: function (record) {
								var res = ROW_COLOR_CLS.valid;
								if (!record.isExternalValid) {
									res = ROW_COLOR_CLS.error;
								}
								return res;
							}
						},
						gridConfig: Object.assign(
							{
								selModel: !this.hideDeleteButton
									? createCheckboxSelectionModel({
											listeners: {
												selectionchange: function (model, selected) {
													selectedRecords = selected.map(function (record) {
														return record.getData();
													});

													__self.deleteButton.setDisabled(!selected.length);
												}
											}
									  })
									: undefined,
								columns: columnsConfig,
								plugins: plugins,
								layout: 'auto',
								emptyText: !__self.allowBlank ? edi.i18n.getMessage('grid.empty.mandatory') : '',
								cls:
									'edi-grid-products is-edit ' +
									(!__self.allowBlank ? 'edi-grid-mark-empty-red ' : ' ') +
									__self.gridConfigCls,
								disablePaging: true,
								disableSelection: false,
								dockedItems: toolbarItems.length
									? [
											createToolBar(
												Ext.merge(
													{
														cls: 'edi-product-grid-toolbar',
														dock: 'bottom',
														items: toolbarItems
													},
													__self.gridToolbarCfg
												)
											)
									  ]
									: undefined,
								listeners: {
									afterrender: function () {
										finishProductGridCreation();
										finishTotalsGridCreation();
									}
								}
							},
							__self.gridConfig
						)
				  };

			config.pagingBarConfig = Object.assign(
				{
					paggingBarLabel: edi.i18n.getMessage('pagingbar.lines.per.page'),
					isHideRefreshBtn: true
				},
				this.pagingBarConfig
			);
			__self.initialConfig = config;
			this.productsGrid = createGrid(config);

			if (editingPlugin) {
				// фикс для редактируемых гридов: в методе initFieldAccessors плагина Editing
				//  инициализируются только редакторы видимых столбцов, но не скрытых
				this.productsGrid.headerCt.on('columnshow', function (cmp, column) {
					if (!column.getEditor) {
						column.getEditor = function (record, defaultField) {
							return editingPlugin.getColumnField(this, defaultField);
						};
					}
					if (!column.hasEditor) {
						column.hasEditor = function () {
							return editingPlugin.hasColumnField(this);
						};
					}
					if (!column.setEditor) {
						column.setEditor = function (field) {
							editingPlugin.setColumnField(this, field);
						};
					}
				});
			}
			var finishTotalsGridCreation = function () {
				if (isTotalStoreLoaded && __self.productsTotalGrid) {
					setTotals();
					if (isLineStoreLoaded && !isDataSet && __self?.productsGrid) {
						isDataSet = true;
						setData();
					}
				}
			};

			if (this.hasTotal) {
				var columnsTotalConfig = edi.columns.get(__self.totalColumnConfig);
				var totalsGridConfig = Ext.merge(
					{
						columnWidth: 0.5,
						cls: !__self.readOnly ? 'edi-total-grid' : 'edi-total-grid read-only',
						columns: columnsTotalConfig,
						listeners: {
							afterrender: function () {
								finishProductGridCreation();
								finishTotalsGridCreation();
							}
						},
						viewConfig: {
							enableTextSelection: true,
							emptyText: '&nbsp;',
							getRowClass: function (record) {
								var res = ROW_COLOR_CLS.valid;
								if (!__self.isValidTotal(record)) {
									res = ROW_COLOR_CLS.error;
								}
								return res;
							}
						},
						disablePaging: true,
						disableSelection: true,
						enableColumnMove: false,
						enableColumnResize: false,
						sortableColumns: false,
						plugins: !__self.readOnly
							? [
									Ext.create('Ext.grid.plugin.CellEditing', {
										clicksToEdit: 1
									})
							  ]
							: undefined
					},
					__self.totalsGridConfig
				);
				if (__self.totalsGridHeaderLabel) {
					totalsGridConfig.title = edi.i18n.getMessage(__self.totalsGridHeaderLabel);
				}
				this.productsTotalGrid = createGrid({
					storeConfig: {
						proxy: createProxyConfig({
							type: 'memory',
							data: {
								items: []
							}
						}),
						model: edi.models.getModel(__self.totalModel),
						remoteSort: false,
						listeners: {
							load: {
								fn: function () {
									isTotalStoreLoaded = true;
									finishTotalsGridCreation();
								},
								options: {
									single: true
								}
							},
							update: function (store, record, operation) {
								if (operation == Ext.data.Model.COMMIT || operation == Ext.data.Model.EDIT) {
									'function' == typeof this.callback ? this.callback(this.getValues()) : null;
								}
							},
							scope: this
						}
					},
					gridConfig: totalsGridConfig
				});
			}
			this.items = [
				this.productsGrid,
				this.hasTotal
					? createPanel({
							layout: 'column',
							margin: '10 0 5',
							items: [
								this.leftTotalComponent ?? {
									xtype: 'component',
									columnWidth: 0.5,
									html: '<br/>'
								},
								this.productsTotalGrid
							]
					  })
					: null
			];
			this.ownConfigSet = true;
		}
	},

	/**
	 * Returns current control values
	 */
	getValues: function () {
		var store = this.productsGrid.getStore();
		var values = {
			products: []
		};
		if (store.getCount()) {
			if (this.hasTotal) {
				var totalStore = this.productsTotalGrid.getStore();
				if (totalStore.getCount()) {
					values = totalStore.getAt(0).getData();
					values.products = [];
				}
			}
			store.each(function (record) {
				values.products.push(record.data);
				return true;
			});
		}
		return values;
	},

	/**
	 * Collects information from total grid
	 * @returns {*}
	 */
	getTotalValues: function () {
		var totals;
		if (this.hasTotal) {
			var totalStore = this.productsTotalGrid.getStore();
			if (totalStore.getCount()) {
				totals = totalStore.getAt(0).getData();
			}
		}
		return totals;
	},

	/**
	 * Calculates values for totals grid
	 */
	totalsHandler: function (component, actionName) {
		var __self = this;
		if (__self.docType) {
			var docData = null;
			if (
				__self.docData &&
				actionName &&
				edi.constants.PRODUCT_TOTALS &&
				edi.constants.PRODUCT_TOTALS.USE_DOCDATA &&
				edi.constants.PRODUCT_TOTALS.USE_DOCDATA[actionName] &&
				edi.constants.PRODUCT_TOTALS.USE_DOCDATA[actionName].length
			) {
				var docTypes = edi.constants.PRODUCT_TOTALS.USE_DOCDATA[actionName];
				if (Ext.Array.contains(docTypes, __self.docType)) {
					docData = Ext.clone(__self.docData);
				}
			}
			edi.total.process(__self.docType, component, docData, actionName);
			'function' == typeof __self.totalsHandlerCallback
				? __self.totalsHandlerCallback(__self.getTotalValues())
				: null;
		}
	},

	/**
	 * Change values before edit data record in grid
	 *
	 * @param    {Object}    values     object record values
	 */
	changeValuesBeforeEdit: function (values) {
		// to be overwritten
	},

	/**
	 * Change values after add or edit data record in grid
	 *
	 * @param    {Object}    values     object record values
	 */
	changeValuesAfterEdit: function (values) {
		// to be overwritten
	},

	/**
	 * Change values after add or edit data record in grid
	 *
	 * @param    {Object}    values     object record values
	 */
	afterRowEdit: function (values) {
		// to be overwritten
	},

	/**
	 * Rebuilding productsGrid with new Columns settings
	 *
	 * @param    {Object}    newConfig     object with config additional columns
	 */
	reconfigColumns: function (newConfig) {
		if (!!newConfig || 'object' !== typeof newConfig) {
			return;
		}
		var __self = this,
			newCols,
			oldColumns = __self.gridColumnConfig,
			grid = __self.productsGrid;
		if (Ext.isArray(__self.gridColumnConfig)) {
			throw new TypeError();
		}

		newCols = edi.columns.get(oldColumns, function (config) {
			return Ext.merge(config, newConfig);
		});
		newCols.push(__self.initialActionColumnConfig);

		Ext.suspendLayouts();
		grid.reconfigure(grid.getStore(), newCols);
		Ext.resumeLayouts(true);
	},

	/**
	 * Changes or adds new row in the grid
	 * @param    {Object}    values        values collected from modal form
	 * @param    {Object}    record        record used for row editing or undefined for new row creation
	 * @param    {Boolean}   skipTotals    true to skip totals calculations, use din bulk records set
	 */
	setGridRow: function (values, record, skipTotals) {
		var __self = this,
			i;
		var store = __self.productsGrid.getStore();
		if (record) {
			record.beginEdit();
			for (i in values) {
				if (values.hasOwnProperty(i)) {
					record.set(i, values[i]);
				}
			}
			record.isExternalValid = this.isValidRecord(record);
			record.endEdit();
		} else {
			var vals = Ext.clone(values);
			vals[__self.lineNumberFieldName] = store.getCount() + 1;
			record = edi.models.createInstance(__self.gridModel, vals);
			edi.utils.each(__self.clearFieldsOnSet, function (fieldName) {
				record.set(fieldName, '');
			});
			record.isExternalValid = this.isValidRecord(record);
			record.commit(true);
			store.add(record);
		}
		store.commitChanges();
		if (!skipTotals) {
			this.totalsHandler(__self, 'setrow');
		}
		'function' == typeof __self.callback ? __self.callback(__self.getValues()) : null;
	},

	/**
	 * Fills grid rows on data load
	 * @param data
	 * @param skipTotals
	 */
	batchSetGridRows: function (data, skipTotals) {
		var __self = this,
			j,
			store = this.productsGrid.getStore(),
			rowNumber = store.getCount(),
			records = [];
		if (data && data.length) {
			for (j = 0; j < data.length; j++) {
				var values = Ext.clone(data[j]);
				values[__self.lineNumberFieldName] = ++rowNumber;
				var record = edi.models.createInstance(__self.gridModel, values);
				edi.utils.each(__self.clearFieldsOnSet, function (fieldName) {
					record.set(fieldName, '');
				});
				record.isExternalValid = this.isValidRecord(record);
				record.commit(true);
				records.push(record);
			}
			store.loadData(records);
		}
		if (!skipTotals) {
			this.totalsHandler(__self, 'set_rows');
		}
	},

	/**
	 * Create buttons add in modal create/edit grid line
	 * @param    {Function}    getModal    funcrion return modal object
	 * @param    {Boolean}     isEdit      true - is edit record, false - add new record
	 * @param    {Function}    callback
	 * @returns  {Object}    array buttons components
	 */
	createModalButtons: function (getModal, isEdit, callback) {
		var buttons = [];
		var closeModal = function () {
			getModal().close();
		};
		if (!this.readOnly) {
			buttons.push(
				createButton(
					Ext.applyIf(this.configModalSaveButton(callback), {
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage(isEdit ? 'form.btn.save' : 'form.btn.add'),
						scope: this
					})
				)
			);
			var buttonsBefore = this.configModalButtonsBefore(getModal, isEdit);
			for (var i = 0; i < buttonsBefore.length; i++) {
				buttons.push(
					createButton(
						Ext.applyIf(buttonsBefore[i], {
							scope: this
						})
					)
				);
			}
		} else {
			buttons.push(
				createButton(
					Ext.applyIf(this.configModalCancelButton(), {
						cls: BUTTON_CLS.primary,
						text: edi.i18n.getMessage('btn.cancel'),
						handler: closeModal,
						scope: this
					})
				)
			);
		}

		return buttons;
	},

	/**
	 * Displays modal product data form
	 * @param    {Object}    record           record from existent product row in grid, used for editing values
	 * @param    {Object}    defaultRecord    default record data product row in grid, used for editing values
	 */
	showModalProductForm: function (record, defaultRecord) {
		var __self = this,
			modal,
			recordData = record ? record.data : !Ext.Object.isEmpty(defaultRecord) ? defaultRecord : undefined;
		if (recordData !== undefined) {
			__self.changeValuesBeforeEdit(recordData);
		}
		typeof __self.createModalForm === 'function'
			? __self.constructNewModalForm(__self.createModalForm(recordData))
			: __self.constructModalForm(__self.modalFormConfig, recordData);

		var title = edi.i18n.getMessage('modal.product.data');
		if (this.modalFormConfig.hasOwnProperty('title')) {
			var titleKey = this.modalFormConfig.title + (!record ? '.add.title' : '.edit.title');
			if (edi.i18n.getMessage(titleKey) != titleKey) {
				title = edi.i18n.getMessage(titleKey);
			} else if (edi.i18n.getMessage(this.modalFormConfig.title) != this.modalFormConfig.title) {
				title = edi.i18n.getMessage(this.modalFormConfig.title);
			}
		}
		var defaults = {
			cls: 'edi-modal-product-data-selector',
			title: title,
			width: MODAL_SIZE.widthLarge
		};

		var getModal = function () {
			return modal;
		};

		var modalConf = {
			layout: 'fit',
			items: [__self.formPanel],
			buttons: __self.createModalButtons(getModal, !!record, function () {
				var values = __self.getFormValues();
				__self.changeValuesAfterEdit(values);
				__self.setGridRow(values, record);
				modal.close();
			})
		};
		Ext.apply(modalConf, this.modalConf, defaults);
		modal = createModalPanel(modalConf);
		modal.show();
	},

	/**
	 * Replace validators name functions on methods in fields config
	 * @param    {Object}    config    fields config
	 * @returns    {Object}
	 */
	replaceNameOnMethod: function (config) {
		for (var key in config) {
			if (config.hasOwnProperty(key)) {
				if (
					key == 'validator' &&
					'string' == typeof config[key] &&
					this.formMethods.hasOwnProperty(config[key]) &&
					'function' == typeof this.formMethods[config[key]]
				) {
					config[key] = this.formMethods[config[key]];
				}
			}
		}
	},

	/**
	 * Convert form fields config before create form
	 * @param    {Object}    fieldsConfig    fields config
	 * @returns    {Object}
	 */
	convertFieldsConfig: function (fieldsConfig) {
		for (var i = 0; i < fieldsConfig.length; i++) {
			if (fieldsConfig[i].hasOwnProperty('items')) {
				for (var j = 0; j < fieldsConfig[i].items.length; j++) {
					this.replaceNameOnMethod(fieldsConfig[i].items[j]);
				}
			}
		}
		return fieldsConfig;
	},

	/**
	 * Return form fields components
	 * @returns    {Object}
	 */
	getFormFields: function () {
		return Ext.apply(edi.utils.getFormFields(this.formPanel), this.formGrids);
	},

	/**
	 * Return form values
	 * @returns    {Object}
	 */
	getFormValues: function () {
		var values = edi.utils.collectFormValues(this.formPanel);
		for (var key in this.formGrids) {
			if (this.formGrids.hasOwnProperty(key)) {
				edi.utils.setObjectProperty(values, key, edi.utils.getDataFromGrid(this.formGrids[key]));
			}
		}
		return values;
	},

	isValidEditableGrids: function () {
		if (this.allowBlankEditableGrids.length > 0) {
			for (var j = 0; j < this.allowBlankEditableGrids.length > 0; j++) {
				var gridItem = this.allowBlankEditableGrids[j];
				var gridName = Ext.Object.getKeys(gridItem)[0];
				var editableGrid = this.formGrids[gridName];
				var isNotValidRecord = false;

				if (!!editableGrid) {
					var editableGridStore = editableGrid.getStore();
					isNotValidRecord =
						Ext.Array.filter(editableGridStore.data.items, function (record) {
							var isHasEmptyValue = false;
							for (var k = 0; k < Ext.Object.getValues(gridItem)[0].length; k++) {
								var value = Ext.Object.getValues(gridItem)[0][k];

								if (record.get(value).length === 0) {
									isHasEmptyValue = record.get(value).length === 0;
									break;
								}
							}
							return isHasEmptyValue;
						}).length > 0;
					isNotValidRecord = isNotValidRecord || editableGrid.getStore().getCount() === 0;
				}
				if (isNotValidRecord) {
					const parentTab = this.getParentTab(editableGrid);
					const tabPanel = parentTab?.up('tabpanel');
					if (tabPanel) {
						tabPanel.setActiveTab(parentTab);
					}
					return false;
				}
			}
		}
		return true;
	},

	getParentTab: function (field) {
		if (!field) return;
		const tab = field.up('panel');
		return !tab?.hasOwnProperty('tab') ? this.getParentTab(tab) : tab;
	},

	isValidRowsEdit: function () {
		let valid = true;
		let __self = this;
		for (let key in this.formRowsEdit) {
			let fields = this.formRowsEdit[key].query('[itemId="' + key + '"]');
			fields.forEach((field) => {
				if ('function' == typeof field.isValid) {
					if (!field.isValid()) {
						let tab = __self.getParentTab(field);
						this.tabPanel.setActiveTab(tab);
						valid = false;
						return false;
					}
				}
			});
		}
		return valid;
	},

	focusOnInvalidField: function () {
		var __self = this;
		var fields = __self.formPanel.getForm().getFields().items;
		var isValid = true;
		for (var i = 0, len = fields.length; i < len; i++) {
			var field = fields[i];
			if (!!field) {
				if (!field.isValid()) {
					isValid = false;
					const parentTab = __self.getParentTab(field);
					const tabPanel = parentTab?.up('tabpanel');
					if (tabPanel) {
						tabPanel.setActiveTab(parentTab);
					}
					setTimeout(function () {
						field.focus();
					}, 0);
					break;
				}
			}
		}
		if (isValid && this.allowBlankEditableGrids.length > 0) {
			for (var j = 0; j < this.allowBlankEditableGrids.length > 0; j++) {
				var gridItem = this.allowBlankEditableGrids[j];
				var gridName = Ext.Object.getKeys(gridItem)[0];
				var editableGrid = this.formGrids[gridName];
				var isNotValidRecord = false;

				if (!!editableGrid) {
					var editableGridStore = editableGrid.getStore();
					isNotValidRecord =
						Ext.Array.filter(editableGridStore.data.items, function (record) {
							var isHasEmptyValue = false;
							for (var k = 0; k < Ext.Object.getValues(gridItem)[0].length; k++) {
								var value = Ext.Object.getValues(gridItem)[0][k];

								if (record.get(value).length === 0) {
									isHasEmptyValue = record.get(value).length === 0;
									break;
								}
							}
							return isHasEmptyValue;
						}).length > 0;
					isNotValidRecord = isNotValidRecord || editableGrid.getStore().getCount() === 0;
				}
				if (isNotValidRecord) {
					const parentTab = this.getParentTab(editableGrid);
					const tabPanel = parentTab?.up('tabpanel');
					if (tabPanel) {
						tabPanel.setActiveTab(parentTab);
					}
					break;
				}
			}
		}
	},

	findCompositeFields: function () {
		const me = this;

		me.formGrids = {};
		me.formRowsEdit = {};
		me.formSC = {};

		const formItems = me.formPanel.getQueryRoot().getRefItems(true);
		formItems.forEach((comp) => {
			if (UI.components.Grid && comp instanceof UI.components.Grid) {
				const name = comp.name || comp.initialConfig.name || comp.initialConfig.options?.name;
				if (name) {
					me.formGrids[name] = comp;
				}
			}

			if (UI.components.SimpleSelector && comp instanceof UI.components.SimpleSelector) {
				const name = comp.name || comp.initialConfig.name;
				if (name) {
					me.formSC[name] = comp;
				}
			}

			if (
				(edi.components.RowsBlock && comp instanceof edi.components.RowsBlock) ||
				(edi.components.RowsBlockV2 && comp instanceof edi.components.RowsBlockV2)
			) {
				const name = comp.name || comp.initialConfig.name;
				if (name) {
					me.formRowsEdit[name] = comp;
				}
			}
		});
	},

	constructNewModalForm: function (items) {
		const me = this;

		me.formPanel = createForm(
			Object.assign(
				{
					layout: 'fit',
					submitEmptyText: false,
					gridInstance: me
				},
				me.formPanelCfg
			)
		);
		me.formPanel.add(items);

		me.findCompositeFields();

		if (me.isFocusOnInvalidField) {
			setTimeout(function () {
				me.focusOnInvalidField();
			}, 100);
		}
		me.formPanel.isValid();
	},

	/**
	 * Main method construct modal form
	 * @param    {Object}    configForm    fields config
	 * @param    {Object}    record    record data
	 */
	constructModalForm: function (configForm, record) {
		if (configForm.formMethods && 'object' == typeof configForm.formMethods) {
			this.formMethods = configForm.formMethods;
		}

		var __self = this;
		configForm = this.convertFieldsConfig(configForm.modalFields);

		var tabs = [],
			defaultTabConfig = {
				title: 'Untitled',
				closable: false,
				autoScroll: true,
				items: []
			};

		__self.formPanel = createForm(
			Object.assign(
				{
					layout: 'fit',
					submitEmptyText: false,
					gridInstance: __self
				},
				__self.formPanelCfg
			)
		);

		var inputConfig = {
			formPanel: this.formPanel,
			formRecord: this.formRecord,
			getFormFields: this.getFormFields,
			getFormValues: this.getFormValues,
			readOnly: this.readOnly
		};

		for (var methodName in this.formMethods) {
			if (this.formMethods.hasOwnProperty(methodName) && 'function' == typeof this.formMethods[methodName]) {
				inputConfig[methodName] = this.formMethods[methodName];
			}
		}

		var getRowClassFn = function (tabConfig) {
			var fields = tabConfig.config.fields,
				gridConfig = tabConfig.config;
			return function (record) {
				var res = ROW_COLOR_CLS.valid;
				if ('function' == typeof gridConfig.getRowClass) {
					res = gridConfig.getRowClass(record, fields);
				} else {
					for (var j = 0; j < fields.length; j++) {
						if (
							fields[j].hasOwnProperty('allowBlank') &&
							!fields[j].allowBlank &&
							!record.get(fields[j].name)
						) {
							res = ROW_COLOR_CLS.error;
						}
					}
				}

				return res;
			};
		};

		__self.formGrids = {};
		__self.formRowsEdit = {};
		__self.formSC = {};
		for (var i = 0; i < configForm.length; i++) {
			var tabConfig = Ext.clone(configForm[i]);
			if (Ext.isArray(tabConfig.items) && tabConfig.items.length) {
				var fields = [],
					simpleFieldConfigs = [],
					haveSimpleField = false;
				Ext.Array.forEach(tabConfig.items, function (fieldConfig) {
					if (!fieldConfig) {
						return;
					}

					if (fieldConfig.type === 'panelWithModalSelect') {
						//create simle fields, add them to tab fields and create an editable grid
						fields = fields.concat(
							createFieldsForProductGrid(simpleFieldConfigs, record, null, inputConfig)
						);
						simpleFieldConfigs = [];
						__self.formMethods[fieldConfig.name] = createPanelWithModalSelect(
							Ext.mergeIf(fieldConfig.config, {
								value: edi.utils.getObjectProperty(
									record,
									fieldConfig.name + '.' + fieldConfig.config.valueField
								)
									? edi.utils.getObjectProperty(
											record,
											fieldConfig.name + '.' + fieldConfig.config.valueField
									  )
									: edi.utils.getObjectProperty(record, fieldConfig.name)
							})
						);
						fields.push(__self.formMethods[fieldConfig.name]);
					}
					//Save simple fields while meet an editable grid
					else if (fieldConfig.type === 'editableGrid') {
						//create simle fields, add them to tab fields and create an editable grid
						fields = fields.concat(
							createFieldsForProductGrid(simpleFieldConfigs, record, null, inputConfig)
						);
						simpleFieldConfigs = [];
						__self.formGrids[fieldConfig.name] = createEditableGrid(
							Ext.mergeIf(fieldConfig.config, {
								readOnly: __self.readOnly,
								viewConfig: {
									getRowClass: getRowClassFn(fieldConfig)
								}
							}),
							edi.utils.getObjectProperty(record, fieldConfig.name, true)
						);

						fields.push(__self.formGrids[fieldConfig.name]);
					} else if (fieldConfig.type === 'treegrid') {
						fields = fields.concat(
							createFieldsForProductGrid(simpleFieldConfigs, record, null, inputConfig)
						);
						simpleFieldConfigs = [];
						var conf = Ext.mergeIf(fieldConfig.config, {
							readOnly: __self.readOnly,
							gridValues: edi.utils.getObjectProperty(record, fieldConfig.name, true)
						});
						__self.formGrids[fieldConfig.name] = createTreeGrid(conf);
						fields.push(__self.formGrids[fieldConfig.name]);
					} else if (fieldConfig.type === 'simpleselector') {
						//create simple selector
						fields = fields.concat(
							createFieldsForProductGrid(simpleFieldConfigs, record, null, inputConfig)
						);
						simpleFieldConfigs = [];
						__self.formSC[fieldConfig.name] = createSimpleSelector(
							Ext.mergeIf(fieldConfig.config, {
								readOnly: __self.readOnly
							}),
							edi.utils.getObjectProperty(record, fieldConfig.name)
						);
						fields.push(__self.formSC[fieldConfig.name]);
					} else if (fieldConfig.type === 'companyselector') {
						//create company selector
						fields = fields.concat(
							createFieldsForProductGrid(simpleFieldConfigs, record, null, inputConfig)
						);
						simpleFieldConfigs = [];
						__self.formSC[fieldConfig.name] = createOrgSelector(
							Ext.mergeIf(fieldConfig.config, {
								readOnly: __self.readOnly,
								fieldValues: record,
								selectedOrg: edi.utils.getObjectProperty(record, fieldConfig.name)
							})
						);
						fields.push(__self.formSC[fieldConfig.name]);
					} else if (fieldConfig.type === 'rows_editing_block') {
						__self.formRowsEdit[fieldConfig.name] = createRowsBlock({
							contentCols: 6,
							buttonsCols: 6,
							createContentFieldsFn: fieldConfig.createRow,
							initialData: edi.utils.getObjectProperty(record, fieldConfig.name, true),
							isReadOnly: __self.readOnly,
							onAddRow() {
								if (typeof fieldConfig.checkModuleValid === 'function') {
									fieldConfig.checkModuleValid();
								}
							},
							onRemoveRow() {
								if (typeof fieldConfig.checkModuleValid === 'function') {
									fieldConfig.checkModuleValid();
								}
							}
						});
						fields.push(__self.formRowsEdit[fieldConfig.name]);
					} else {
						haveSimpleField = true;
						simpleFieldConfigs.push(fieldConfig);
					}
				});

				if (haveSimpleField) {
					let layout = tabConfig?.layout || {
						type: 'grid',
						gap: 16
					};
					delete tabConfig.layout;

					Ext.apply(tabConfig, {
						padding: 0,
						layout: 'auto',
						items: createContainer({
							padding: 24,
							layout,
							items: fields.concat(
								createFieldsForProductGrid(simpleFieldConfigs, record, null, inputConfig)
							)
						})
					});
				} else {
					Ext.apply(tabConfig, {
						padding: 0,
						layout: 'auto',
						items: fields
					});
				}
			}

			Ext.applyIf(tabConfig, defaultTabConfig);
			tabs.push(createTab(tabConfig));
		}
		var tabPanel = createTabPanel({
			cls: TAB_PANEL_CLS.simpleWithoutPadding,
			activeTab: 0,
			items: tabs
		});
		this.formPanel.add(tabPanel);
		__self.tabPanel = tabPanel;

		if (this.isFocusOnInvalidField) {
			setTimeout(function () {
				__self.focusOnInvalidField();
			}, 100);
		}
		this.formPanel.isValid();
	},

	/**
	 * Callback to convert data for modal product selection dialog
	 *
	 * @param   {Object}   product object data
	 */
	selectProductHandler: function (product) {
		// to be overwritten
	},

	isFieldAllowToReset: function ({ field }) {
		return !(field.isDisabled() || field.readOnly);
	},

	isGridAllowToReset: ({ grid }) => true,

	isSimpleSelectorAllowToReset: ({ simpleSelector }) => true,

	isRowsBlockAllowToReset: ({ rowsblock }) => true,

	resetFormFields: function () {
		const __self = this;
		const fields = __self.formPanel.form.getFields().items;
		fields.forEach((field) => {
			if (field && !field.isDestroyed && __self.isFieldAllowToReset({ field })) field.setValue(null);
		});
		Object.values(__self.formGrids).forEach((grid) => {
			if (grid && !grid.isDestroyed && __self.isGridAllowToReset({ grid })) {
				const store = grid.getStore();
				store.removeAll();
			}
		});
		Object.values(__self.formSC).forEach((simpleSelector) => {
			if (
				simpleSelector &&
				!simpleSelector.isDestroyed &&
				__self.isSimpleSelectorAllowToReset({ simpleSelector })
			) {
				simpleSelector.reset();
			}
		});
		Object.values(__self.formRowsEdit).forEach((rowsblock) => {
			if (rowsblock && !rowsblock.isDestroyed && __self.isRowsBlockAllowToReset({ rowsblock })) {
				rowsblock.removeAllRows();
			}
		});
	},

	isFormFieldsChanged: function () {
		const __self = this;
		const isFieldChanged = __self.formPanel.isDirty();
		const isGridsFill = Object.values(__self.formGrids).some((grid) => {
			if (grid && !grid.isDestroyed) return !!grid.getStore().getCount();
		});
		const isSCFilled = Object.values(__self.formSC).some((simpleSelector) => {
			if (simpleSelector && !simpleSelector.isDestroyed) return !!simpleSelector.selectedOrg;
		});
		return isFieldChanged || isGridsFill || isSCFilled;
	},

	/**
	 * Return additional buttons to insert before Buttons "Save" and "Cancel"
	 * @param    {Function}    getModal    function to return modal object
	 * @param    {Boolean}     isEdit      true - is edit record, false - add new record
	 * @returns    {Array}    buttons config
	 */
	configModalButtonsBefore: function (getModal, isEdit) {
		const __self = this;
		return [
			{
				cls: BUTTON_CLS.secondary,
				text: edi.i18n.getMessage('form.btn.select.product'),
				glyph: edi.constants.ICONS.SEARCH,
				hidden: !__self.showSelectProductBtn,
				handler: function () {
					if (this.partnerId) {
						createModalProductSelect(
							getModal(),
							{
								toOrg: this.ownCatalog ? this.partnerId : edi.core.getUserOrgID(),
								fromOrg: this.ownCatalog ? edi.core.getUserOrgID() : this.partnerId
							},
							(...args) => {
								if (__self.resetFormOnCatalogSelection && (isEdit || __self.isFormFieldsChanged())) {
									showConfirm({
										msgText: edi.i18n.getMessage('product.select.confirm.message'),
										yesBtnConfig: {
											text: edi.i18n.getMessage('product.select.confirm.merge')
										},
										noBtnConfig: {
											text: edi.i18n.getMessage('product.select.confirm.replace')
										},
										failure: () => __self.resetFormFields(),
										callback: () => __self.selectProductHandler(...args)
									});
								} else {
									__self.selectProductHandler(...args);
								}
							}
						);
					} else {
						edi.core.showInfo(edi.i18n.getMessage('info.no.seller'));
					}
				}
			}
		];
	},

	/**
	 * Return config save button
	 * @returns    {Object}    button config
	 */
	configModalSaveButton: function (callback) {
		return {
			handler: function () {
				if (
					(this.isValidRowsEdit() && this.isValidEditableGrids() && this.formPanel.isValid()) ||
					edi.constants.ALLOW_INVALID_LINES_CREATION
				) {
					if (
						this.hasOwnProperty('checkValuesBeforeSave') &&
						'function' == typeof this.checkValuesBeforeSave
					) {
						if (this.checkValuesBeforeSave(this.getFormValues())) {
							callback();
						}
					} else {
						callback();
					}
				} else if (this.isFocusOnInvalidField) {
					this.focusOnInvalidField();
				} else {
					edi.core.showError(
						this.docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
							? 'product.error.fill.all.fields.fns'
							: 'product.error.fill.all.fields'
					);
				}
			}
		};
	},

	/**
	 * Return config close button
	 * @returns    {Object}    button config
	 */
	configModalCancelButton: function () {
		return {};
	}
});

export const createProductGridBase = function (cfg) {
	return Ext.create('UI.components.ProductGrid', cfg);
};
