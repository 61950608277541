// import {createActionsButton, createButton} from "@Components/buttons";
// import {createBadgeButton} from "@Ediweb/components/badgeButton.js";
import { createContainer } from '@Components/miscComponents';
import { createProxyConfig, createStore } from '@Components/storeComponents';
import { confirm } from '@Ediweb/components/dialogs.js';

import { createSidebar } from '@UIkit/components/sidebar/Sidebar';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { BADGE_CLS, BADGE_PRESETS, BADGE_ALIGN, PTYPE_BADGE } from '@UIkit/components/badge/Badge';
import { createLabel } from '@UIkit/components/fields';

Object.assign(BUTTON_CLS, {
	notifications_button: 'ui-button-notifications'
});

Ext.override(edi.notifications, {
	unreadNotificationsLimit: 5,
	toastAutoCloseTimeout: 20000,
	__markingAsReadInProcess: {},
	__lastShownNotificationDate: new Date().valueOf(),
	__previousCnt: null,

	itemTpl: Ext.create('Ext.XTemplate', [
		`<div class="edi-ediweb-notification {[this.getNotificationIdCls(values)]} {[this.getReadCls(values)]}">
			<div class="edi-ediweb-notification-wrap {[this.getIconCls(values)]} {[this.getColorCls(values)]}">
				<span class="edi-ediweb-notification-date">{[this.renderDate(values.creationDate)]}</span>
				<span class="edi-ediweb-notification-title">{[this.getTitleText(values)]}</span>
				<span class="edi-ediweb-notification-text">{[this.getBodyText(values)]}</span>
				{[this.setButtons(values)]}
			</div>
		</div>`,
		{
			getNotificationIdCls(values) {
				return values?.gridNotificationId ? `edi-ediweb-notification-grid-id-${values.gridNotificationId}` : '';
			},
			getReadCls(values) {
				return values.read ? 'edi-ediweb-notification-read' : 'edi-ediweb-notification-unread';
			},
			getIconCls(values) {
				let notificationType = values?.action?.type;
				let actionId = values?.action?.actionId;

				if (notificationType) {
					if (
						actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.expires' ||
						actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.access.closed' ||
						actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.tariff.expires'
					) {
						return `edi-ediweb-notification-icon-DSF_ONBOARDING`;
					}
					if (actionId === 'com.edisoft.notification.tariff.expired') {
						return `edi-ediweb-notification-icon-TARIFF_EXPIRED_NOTIFY`;
					}
					return `edi-ediweb-notification-icon-${notificationType}`;
				}
				return `edi-ediweb-notification-icon-${edi.constants.NOTIFICATION_TYPE.INFORMATION}`;
			},
			getColorCls(values) {
				let cls;
				let actionId = values?.action?.actionId;
				if (edi.notifications.isInvitation(values)) {
					if (
						actionId === 'com.edisoft.notification.document.onboarding.invitation.reject' ||
						actionId === 'com.edisoft.notification.document.onboarding.invitation.revoke' ||
						actionId === 'com.edisoft.notification.document.onboarding.invitation.expired'
					) {
						cls = 'edi-ediweb-notification-color-RED';
					} else {
						cls = 'edi-ediweb-notification-color-GREEN';
					}
				} else if (edi.notifications.isApprovalDocuments(values)) {
					if (
						actionId === 'com.edisoft.notification.client.approval.on.approval' ||
						actionId === 'com.edisoft.notification.client.approval.approved'
					) {
						cls = 'edi-ediweb-notification-color-GREEN';
					} else if (
						actionId === 'com.edisoft.notification.client.approval.rejected' ||
						actionId === 'com.edisoft.notification.client.approval.revoked'
					) {
						cls = 'edi-ediweb-notification-color-RED';
					} else {
						cls = 'edi-ediweb-notification-color-BLUE';
					}
				} else if (edi.notifications.isApprovalEmployees(values)) {
					if (
						actionId === 'com.edisoft.notification.client.approval.employee.on.approval' ||
						actionId === 'com.edisoft.notification.client.approval.employee.completed'
					) {
						cls = 'edi-ediweb-notification-color-GREEN';
					} else if (
						actionId === 'com.edisoft.notification.client.approval.employee.rejected' ||
						actionId === 'com.edisoft.notification.client.approval.employee.revoked'
					) {
						cls = 'edi-ediweb-notification-color-RED';
					} else {
						cls = 'edi-ediweb-notification-color-BLUE';
					}
				} else if (edi.notifications.isDocument(values)) {
					if (
						actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.before.expire' ||
						actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.expired' ||
						actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.annul'
					) {
						cls = 'edi-ediweb-notification-color-RED';
					} else {
						cls = 'edi-ediweb-notification-color-GREEN';
					}
				} else if (edi.notifications.isNewConnectedProduct(values)) {
					cls = 'edi-ediweb-notification-color-BLUE';
				} else if (edi.notifications.isPartnership(values)) {
					cls = 'edi-ediweb-notification-color-BLUE';
				} else if (edi.notifications.isTariffs(values)) {
					if (actionId === 'com.edisoft.notification.new.paid.tariff.enable') {
						cls = 'edi-ediweb-notification-color-BLUE';
					} else {
						let percentForNotifications = edi.utils.getAttributeByName(
							values.attributes,
							'percentForNotifications'
						);
						if (percentForNotifications === '50') {
							cls = 'edi-ediweb-notification-color-GREEN';
						} else if (percentForNotifications === '20') {
							cls = 'edi-ediweb-notification-color-RED';
						}
					}
				} else if (edi.notifications.isRegisrationAcceptance(values)) {
					cls = 'edi-ediweb-notification-color-GREEN';
				} else if (edi.notifications.isPOA(values)) {
					if (
						actionId === 'com.edisoft.notification.document.edi.poa.rejected' ||
						actionId === 'com.edisoft.notification.document.edi.poa.revoked'
					) {
						cls = 'edi-ediweb-notification-color-RED';
					} else if (actionId === 'com.edisoft.notification.document.edi.poa.expired.notify') {
						cls = 'edi-ediweb-notification-color-ORANGE';
					} else {
						cls = 'edi-ediweb-notification-color-GREEN';
					}
				} else if (actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.access.closed') {
					cls = 'edi-ediweb-notification-color-RED';
				} else {
					cls = 'edi-ediweb-notification-color-ORANGE';
				}

				return cls;
			},
			renderDate: function (date) {
				return edi.renderers.dateTimeFromMs(+date);
			},
			getTitleText(values) {
				let notificationType = values?.action?.type;
				let actionId = values?.action?.actionId;
				let classification = edi.utils.getAttributeByName(values.attributes, 'classification');

				if (actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.before.expire') {
					notificationType = 'DSF_ONBOARDING_EXPIRING';
				} else if (actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.expired') {
					notificationType = 'DSF_ONBOARDING_EXPIRED';
				} else if (actionId === 'com.edisoft.notification.document.onboarding.invitation.expired') {
					notificationType = 'INVITATION_EXPIRED';
				} else if (actionId === 'com.edisoft.notification.document.onboarding.invitation.expiring') {
					notificationType = 'INVITATION_EXPIRING';
				} else if (actionId === 'com.edisoft.notification.tarrifs.enable') {
					let percentForNotifications = edi.utils.getAttributeByName(
						values.attributes,
						'percentForNotifications'
					);
					notificationType = `${classification}_TARIFFS.${percentForNotifications}`;
				} else if (actionId === 'com.edisoft.notification.new.paid.tariff.enable') {
					let classification = edi.utils.getAttributeByName(values.attributes, 'classification');
					notificationType = `${classification}_TARIFFS_BOUGHT`;
				} else if (actionId === 'com.edisoft.notification.document.edi.poa.rejected') {
					notificationType = 'POA_REJECTED';
				} else if (actionId === 'com.edisoft.notification.document.edi.poa.revoked') {
					notificationType = 'POA_REVOKED';
				} else if (actionId === 'com.edisoft.notification.document.edi.poa.active') {
					notificationType = 'POA_ACTIVE';
				} else if (actionId === 'com.edisoft.notification.document.edi.poa.registered') {
					notificationType = 'POA_REGISTERED';
				} else if (actionId === 'com.edisoft.notification.document.edi.poa.for.signing') {
					notificationType = 'POA_WAIT_SIGNING';
				} else if (actionId === 'com.edisoft.notification.document.edi.poa.expired.notify') {
					notificationType = 'POA_EXPIRED_NOTIFY';
				} else if (actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.annul') {
					notificationType = 'DSF_ONBOARDING_FP_ANNUL';
				} else if (actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.expires') {
					notificationType = 'DSF_ONBOARDING_FP_EXPIRES';
				} else if (actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.tariff.expires') {
					notificationType = 'DSF_ONBOARDING_FP_TARIFF_EXPIRES';
				} else if (actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.access.closed') {
					notificationType = 'DSF_ONBOARDING_FP_ACCESS_CLOSED';
				} else if (actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.receive') {
					notificationType = 'DSF_ONBOARDING_FP_RECEIVE';
				} else if (actionId === 'com.edisoft.notification.tariff.expired') {
					notificationType = 'TARIFF_EXPIRED_NOTIFY';
					if (classification === edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY) {
						notificationType = 'POA_TARIFF_EXPIRED_NOTIFY';
					}
				}
				let titleKey = `ediweb.notifications.title.${notificationType}`;
				let titleText = edi.i18n.getMessage(titleKey);
				if (titleText === titleKey) {
					titleText = edi.i18n.getMessage('ediweb.notifications.title.default');
				}
				return titleText;
			},
			getProduct(values) {
				let productName =
					edi.utils.getAttributeByName(values.attributes, 'productRole') ||
					edi.utils.getAttributeByName(values.attributes, 'product');
				let productRec = edi.stores.initAllProductList().findRecord('name', productName);
				return productRec ? productRec.get('label') : productName;
			},
			getInvitationStatus(values) {
				let { statusKey } = edi.renderers._invitationStatus({
					state: values.info,
					ediDocument: {
						toOrg: values.toOrg,
						fromOrg: values.fromOrg
					}
				});
				return edi.i18n.getMessage(statusKey);
			},
			getDocumentStatus(values) {
				return edi.renderers.state({
					type: values?.objectType,
					state: values?.info,
					fromOrg: values?.fromOrg,
					toOrg: values?.toOrg
				});
			},
			getBodyText(values) {
				let __tpl = this;
				let notificationType = values?.action?.type;
				let classification = edi.utils.getAttributeByName(values.attributes, 'classification');

				let tplKey = `ediweb.notifications.body.${notificationType}`;
				let params = {};

				if (notificationType === edi.constants.NOTIFICATION_TYPE.NEW_DOCUMENT) {
					params = {
						partner: values.fromOrg?.name,
						doctype: edi.i18n.getMessage(`documents.doctype.${values.objectType}`),
						number: values.docNumber
					};
					if (values.action.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.receive') {
						tplKey = 'ediweb.notifications.body.DSF_ONBOARDING_FP_RECEIVE';
					}
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.NEW_STATUS_OF_DOCUMENT) {
					params = {
						doctype: edi.i18n.getMessage(`documents.doctype.${values.objectType}`),
						number: values.docNumber,
						state: __tpl.getDocumentStatus(values)
					};

					if (
						values.action.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.before.expire'
					) {
						tplKey = 'ediweb.notifications.body.DSF_ONBOARDING_EXPIRING';
						const daysNumber = edi.utils.getAttributeByName(values.attributes, 'EXPIRE_DAYS_LEFT');
						const days = edi.i18n.decline(+daysNumber, 'dayVariants');
						params = {
							days: ` ${daysNumber} ${days}`,
							number: values.docNumber,
							product: __tpl.getProduct(values)
						};
					} else if (
						values.action.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.expired'
					) {
						tplKey = 'ediweb.notifications.body.DSF_ONBOARDING_EXPIRED';
						params = {
							number: values.docNumber,
							product: __tpl.getProduct(values)
						};
					} else if (
						values.action.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.annul'
					) {
						tplKey = 'ediweb.notifications.body.DSF_ONBOARDING_FP_ANNUL';
					}
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.NEW_INVITATION) {
					params = { partner: values.fromOrg?.name };
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.NEW_STATUS_OF_INVITATION) {
					params = {
						partner: values.fromOrg?.name,
						product: __tpl.getProduct(values),
						state: __tpl.getInvitationStatus(values)
					};

					if (values.action.actionId === 'com.edisoft.notification.document.onboarding.invitation.expiring') {
						tplKey = 'ediweb.notifications.body.INVITATION_EXPIRING';
						const daysNumber = edi.utils.getAttributeByName(values.attributes, 'expiredDate');
						const days = edi.i18n.decline(+daysNumber, 'dayVariants');
						params = {
							days: `${daysNumber} ${days}`,
							partner: values.fromOrg?.name,
							product: __tpl.getProduct(values)
						};
					}
					if (values.action.actionId === 'com.edisoft.notification.document.onboarding.invitation.expired') {
						tplKey = 'ediweb.notifications.body.INVITATION_EXPIRED';
						params = {
							partner: values.fromOrg?.name,
							product: __tpl.getProduct(values)
						};
					}
					if (values.action.actionId === 'com.edisoft.notification.document.onboarding.invitation.reject') {
						params = {
							partner: values.toOrg?.name,
							product: __tpl.getProduct(values),
							state: __tpl.getInvitationStatus(values)
						};
					}
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.NEW_CONNECTED_PRODUCT) {
					let product = __tpl.getProduct(values);
					if (product.toLowerCase() === 'dropcat') {
						tplKey = 'ediweb.notifications.body.NEW_CONNECTED_PRODUCT_DROPCAT';
					}
					if (values.action.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.paid') {
						tplKey = 'ediweb.notifications.body.NEW_CONNECTED_PRODUCT_FACTORPLAT';
					}
					params = { product };
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.PARTNERSHIP) {
					params = {
						partner:
							edi.core.getUserData().org.id === values.fromOrg.id
								? values.toOrg?.name
								: values.fromOrg?.name,
						product: __tpl.getProduct(values)
					};
				} else if (edi.constants.NOTIFICATION_TYPE.APPROVAL_DOCUMENTS[notificationType]) {
					var fullName = edi.utils.getAttributeByName(values.attributes, 'changedEmployee');
					let initials = fullName.replace(/(.+) (.).+ (.).+/, '$1 $2. $3.');
					params = {
						approvalName: edi.utils.getAttributeByName(values.attributes, 'approvalName'),
						routeName: edi.utils.getAttributeByName(values.attributes, 'routeName'),
						changedEmployee: initials
					};
				} else if (edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES[notificationType]) {
					let nextEmployee = edi.utils.getAttributeByName(values.attributes, 'nextEmployee');
					let changedEmployee = edi.utils.getAttributeByName(values.attributes, 'changedEmployee');
					let initiatorEmployee = edi.utils.getAttributeByName(values.attributes, 'initiatorEmployee');
					const getInitials = function (fullName) {
						return fullName.replace(/(.+) (.).+ (.).+/, '$1 $2. $3.');
					};
					params = {
						approvalName: edi.utils.getAttributeByName(values.attributes, 'approvalName'),
						routeName: edi.utils.getAttributeByName(values.attributes, 'routeName'),
						changedEmployee: getInitials(changedEmployee),
						nextEmployee: getInitials(nextEmployee),
						initiatorEmployee: getInitials(initiatorEmployee)
					};
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.TARIFFS) {
					if (values?.action?.actionId === 'com.edisoft.notification.new.paid.tariff.enable') {
						tplKey = `ediweb.notifications.body.${classification}_TARIFFS_BOUGHT`;
						let availableDocuments = edi.utils.getAttributeByName(values.attributes, 'availableDocuments');
						let unlimited = edi.utils.getAttributeByName(values.attributes, 'unlimited');
						params = {
							availableDocuments:
								unlimited === 'true'
									? edi.i18n.getMessage('ediweb.tariffs.amount.info.unlimited')
									: availableDocuments
						};
					} else {
						let percentForNotifications = edi.utils.getAttributeByName(
							values.attributes,
							'percentForNotifications'
						);
						let documentsNumber = edi.utils.getAttributeByName(values.attributes, 'documentsNumber');
						let numToNotify = edi.utils.getAttributeByName(values.attributes, 'numToNotify');

						params = {
							percent: percentForNotifications,
							documentsNumber: documentsNumber,
							numToNotify: numToNotify
						};
						tplKey = `ediweb.notifications.body.${classification}_${notificationType}`;
					}
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.REGISTRATION_ACCEPTANCE) {
					var contractNumber = edi.utils.getAttributeByName(values.attributes, 'contractNumber');
					var contractDate = edi.utils.getAttributeByName(values.attributes, 'contractDate');
					const dateArray = contractDate.split('.');
					const month = edi.i18n.getMonthWord(dateArray[1], 'monthRodPadezh');
					const date = edi.i18n.customTranslation(
						'custom.date.with.month.word',
						{ day: dateArray[0], month, year: dateArray[2] },
						contractDate
					);

					params = {
						number: contractNumber,
						date: date
					};
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.POWER_OF_ATTORNEY) {
					var validFrom = edi.utils.getAttributeByName(values.attributes, 'validFrom');
					var validTo = edi.utils.getAttributeByName(values.attributes, 'validTo');
					var internalPoaNumber = edi.utils.getAttributeByName(values.attributes, 'internalPoaNumber');
					params = {
						validFrom: edi.renderers.docTime(validFrom),
						validTo: edi.renderers.docTime(validTo),
						internalPoaNumber: internalPoaNumber
					};
					if (values?.action?.actionId === 'com.edisoft.notification.document.edi.poa.rejected') {
						tplKey = 'ediweb.notifications.body.POA_REJECTED';
					}
					if (values?.action?.actionId === 'com.edisoft.notification.document.edi.poa.revoked') {
						tplKey = 'ediweb.notifications.body.POA_REVOKED';
					}
					if (values?.action?.actionId === 'com.edisoft.notification.document.edi.poa.active') {
						tplKey = 'ediweb.notifications.body.POA_ACTIVE';
					}
					if (values?.action?.actionId === 'com.edisoft.notification.document.edi.poa.registered') {
						tplKey = 'ediweb.notifications.body.POA_REGISTERED';
					}
					if (values?.action?.actionId === 'com.edisoft.notification.document.edi.poa.for.signing') {
						tplKey = 'ediweb.notifications.body.POA_WAIT_SIGNING';
					}
					if (values?.action?.actionId === 'com.edisoft.notification.document.edi.poa.expired.notify') {
						tplKey = 'ediweb.notifications.body.POA_EXPIRED_NOTIFY';
					}
				} else if (notificationType === edi.constants.NOTIFICATION_TYPE.INFORMATION) {
					if (
						values?.action?.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.expires'
					) {
						tplKey = 'ediweb.notifications.body.DSF_ONBOARDING_FP_EXPIRES';
					}
					if (
						values?.action?.actionId ===
						'com.edisoft.notification.document.edi.dsf.onboarding.fp.tariff.expires'
					) {
						tplKey = 'ediweb.notifications.body.DSF_ONBOARDING_FP_TARIFF_EXPIRES';
					}
					if (
						values?.action?.actionId ===
						'com.edisoft.notification.document.edi.dsf.onboarding.fp.access.closed'
					) {
						tplKey = 'ediweb.notifications.body.DSF_ONBOARDING_FP_ACCESS_CLOSED';
					}
					if (values?.action?.actionId === 'com.edisoft.notification.tariff.expired') {
						var periodTo = edi.utils.getAttributeByName(values.attributes, 'periodTo');
						if (periodTo) {
							params = {
								date: edi.renderers.fnsDateFromClient(new Date(periodTo))
							};
						}

						tplKey = 'ediweb.notifications.body.TARIFF_EXPIRED_NOTIFY';
						if (classification === edi.constants.DOCUMENT_TYPES.POWER_OF_ATTORNEY) {
							tplKey = 'ediweb.notifications.body.POA_TARIFF_EXPIRED_NOTIFY';
						}
					}
				}

				let bodyTextTpl = edi.i18n.getMessage(tplKey);
				if (!values || bodyTextTpl === tplKey) {
					return edi.i18n.getMessage('ediweb.notifications.body.default');
				}

				return edi.utils.formatString(bodyTextTpl, params);
			},
			setButtons: function (values) {
				if (
					values?.action?.type !== edi.constants.NOTIFICATION_TYPE.POA_FOR_SIGNING &&
					(edi.notifications.isInvitation(values) ||
						edi.notifications.isDocument(values) ||
						edi.notifications.isNewConnectedProduct(values) ||
						edi.notifications.isApprovalDocuments(values) ||
						edi.notifications.isApprovalEmployees(values) ||
						edi.notifications.isTariffs(values) ||
						edi.notifications.isRegisrationAcceptance(values) ||
						edi.notifications.isPOA(values) ||
						values.action.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.expires' ||
						values.action.actionId ===
							'com.edisoft.notification.document.edi.dsf.onboarding.fp.tariff.expires' ||
						values.action.actionId ===
							'com.edisoft.notification.document.edi.dsf.onboarding.fp.access.closed' ||
						values.action.actionId === 'com.edisoft.notification.tariff.expired')
				) {
					return `<div class="edi-ediweb-notification-links">
						<a class="x-btn x-unselectable x-btn-default-small test-button-button" role="button" tabindex="0">
							<span role="presentation" class="x-btn-wrap x-btn-wrap-default-small ">
								<span role="presentation" class="x-btn-button x-btn-button-default-small x-btn-text x-btn-button-center">
									<span class="x-btn-inner x-btn-inner-default-small">
										${edi.i18n.getMessage('ediweb.notifications.view')}
									</span>
								</span>
							</span>
						</a>
					</div>`;
				}
			}
		}
	]),
	addBtnClickHandler: function (toast, values) {
		const btn = toast.querySelector('.edi-ediweb-notification-links a.x-btn');
		if (btn) {
			btn.addEventListener('click', function (e) {
				e.stopPropagation(); //предотвращает событие клика на тосте

				//если мы рендерим шаблон во всплывающем уведомлении, то у
				//него есть метод закрытия, который будем потом вызывать при клике
				if (typeof toast.closeToast === 'function') {
					btn.closeToast = toast.closeToast;
				}
				btn.actionId = 'DETAILS';
				edi.notifications.notificationButtonHandler(btn, values);
			});
		}
	},
	isDocument(notification) {
		let type = notification?.action?.type;
		return (
			type === edi.constants.NOTIFICATION_TYPE.NEW_DOCUMENT ||
			type === edi.constants.NOTIFICATION_TYPE.NEW_STATUS_OF_DOCUMENT
		);
	},
	isInvitation(notification) {
		let type = notification?.action?.type;
		return (
			type === edi.constants.NOTIFICATION_TYPE.NEW_INVITATION ||
			type === edi.constants.NOTIFICATION_TYPE.NEW_STATUS_OF_INVITATION
		);
	},
	isApprovalDocuments(notification) {
		let type = notification?.action?.type;
		return (
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_DOCUMENTS.ON_APPROVAL ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_DOCUMENTS.REJECTED ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_DOCUMENTS.REVOKED ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_DOCUMENTS.APPROVE_NEXT_STAGE ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_DOCUMENTS.SIGN_NEXT_STAGE ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_DOCUMENTS.APPROVED
		);
	},
	isApprovalEmployees(notification) {
		let type = notification?.action?.type;
		return (
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.REASSIGN_NEXT_STAGE ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.EMPLOYEE_ON_APPROVAL ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.EMPLOYEE_APPROVE_NEXT_STAGE ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.EMPLOYEE_SIGN_NEXT_STAGE ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.EMPLOYEE_APPROVED ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.EMPLOYEE_SIGNED ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.EMPLOYEE_COMPLETED ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.EMPLOYEE_REJECTED ||
			type === edi.constants.NOTIFICATION_TYPE.APPROVAL_EMPLOYEES.EMPLOYEE_REVOKED
		);
	},
	isNewConnectedProduct(notification) {
		return notification?.action?.type === edi.constants.NOTIFICATION_TYPE.NEW_CONNECTED_PRODUCT;
	},
	isPartnership(notification) {
		return notification?.action?.type === edi.constants.NOTIFICATION_TYPE.PARTNERSHIP;
	},
	isTariffs(notification) {
		return notification?.action?.type === edi.constants.NOTIFICATION_TYPE.TARIFFS;
	},
	isRegisrationAcceptance(notification) {
		return notification?.action?.type === edi.constants.NOTIFICATION_TYPE.REGISTRATION_ACCEPTANCE;
	},
	isPOA(notification) {
		return (
			notification?.action?.type === edi.constants.NOTIFICATION_TYPE.POA_FOR_SIGNING ||
			notification?.action?.type === edi.constants.NOTIFICATION_TYPE.POWER_OF_ATTORNEY
		);
	},
	/**
	 * Opens module by notification type
	 * @param	{Object}	notification
	 */
	detailsActionHandler(notification) {
		let me = this;
		const openDocument = function () {
			var docType = notification?.objectType ?? null;
			var documentData = {
				fromOrg: notification?.fromOrg ?? {},
				id: notification?.objectId ?? null,
				type: docType,
				state: notification?.info ?? null,
				number: notification?.docNumber ?? null,
				toOrg: notification?.toOrg ?? {},
				versionId:
					docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
					docType === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
						? '5.01-N'
						: null
			};
			edi.document.actions.openDetailsModule(documentData.type, documentData, undefined, function () {
				edi.events.documents.fireEvent('change', {
					id: documentData.id
				});
			});
		};
		if (me.isDocument(notification)) {
			openDocument();
		} else if (me.isInvitation(notification)) {
			let currentOrg = edi.core.getUserData().org;
			if (notification.info === edi.constants.INVITATIONS.STATE.DRAFT) {
				edi.core.openModule(
					'invitations',
					{
						activeTab: 'draft'
					},
					undefined,
					undefined,
					undefined,
					undefined,
					function () {
						edi.events.invitations.fireEvent('activatetab', 'draft');
					}
				);
			} else if (notification.fromOrg.id && notification.fromOrg.id !== currentOrg.id) {
				edi.core.openModule(
					'invitations',
					{
						activeTab: 'incoming'
					},
					undefined,
					undefined,
					undefined,
					undefined,
					function () {
						edi.events.invitations.fireEvent('activatetab', 'incoming');
					}
				);
			} else {
				edi.core.openModule(
					'invitations',
					{
						activeTab: 'outgoing'
					},
					undefined,
					undefined,
					undefined,
					undefined,
					function () {
						edi.events.invitations.fireEvent('activatetab', 'outgoing');
					}
				);
			}
		} else if (me.isNewConnectedProduct(notification)) {
			edi.core.openModule('main');
		} else if (me.isApprovalDocuments(notification)) {
			const title = edi.utils.getAttributeByName(notification.attributes, 'approvalName');
			const id = edi.utils.getAttributeByName(notification.attributes, 'approvalId');
			edi.core.openModule(
				'approval.documents.details',
				{ id: id },
				null,
				null,
				null,
				{
					title: title
				},
				function () {
					edi.events.approval_documents.fireEvent('change', {
						id: id
					});
				}
			);
		} else if (me.isApprovalEmployees(notification)) {
			const title = edi.utils.getAttributeByName(notification.attributes, 'approvalName');
			const id = edi.utils.getAttributeByName(notification.attributes, 'approvalId');
			edi.core.openModule(
				'approval.employees.details',
				{ id: id },
				null,
				null,
				null,
				{
					title: title
				},
				function () {
					edi.events.approval_documents.fireEvent('change', {
						id: id
					});
				}
			);
		} else if (me.isTariffs(notification)) {
			edi.core.openModule(
				'org.profile',
				{
					activeTab: 'tariffs'
				},
				undefined,
				undefined,
				undefined,
				undefined,
				function () {
					edi.events.org_profile.fireEvent('activatetab', 'tariffs');
				}
			);
		} else if (me.isRegisrationAcceptance(notification)) {
			const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
				documentId: notification?.objectId
			});
			edi.core.openModule('documents');
			edi.methods.beforeLoadModules.showAcceptanceWindow(null, url, true);
		} else if (me.isPOA(notification)) {
			edi.core.openModule(
				'control.center.module.panel',
				{
					activeTab: 'powersOfAttorney'
				},
				undefined,
				undefined,
				undefined,
				undefined,
				function () {
					edi.events.control_center.fireEvent('activatetab', 'powersOfAttorney');
				}
			);
		} else if (notification.action?.actionId === 'com.edisoft.notification.tariff.expired') {
			edi.core.openModule(
				'org.profile',
				{
					activeTab: 'tariffs'
				},
				undefined,
				undefined,
				undefined,
				undefined,
				function () {
					edi.events.org_profile.fireEvent('activatetab', 'tariffs');
				}
			);
		} else if (
			notification.action?.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.access.closed'
		) {
			edi.core.openModule('main');
		} else if (
			notification.action?.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.paid' ||
			notification.action?.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.expires'
		) {
			openDocument();
		} else if (
			notification.action?.actionId === 'com.edisoft.notification.document.edi.dsf.onboarding.fp.tariff.expires'
		) {
			edi.core.openModule('documents');
		}
	},
	/**
	 * Handler for buttons in notification
	 * @param	{Object}	btn
	 * @param	{Object}	notification
	 */
	notificationButtonHandler(btn, notification) {
		let me = this;
		if (!btn || !notification) {
			return;
		}

		if (me.sidebar?.isToggle() === true) {
			me.toggleSidebar();
		}

		if (typeof btn.closeToast === 'function') {
			btn.closeToast();
		}

		switch (btn.actionId) {
			case 'DETAILS':
				me.detailsActionHandler(notification);
				break;
		}

		me.markAsRead(notification);
	},
	/**
	 * Creates columns array for notifications grid
	 * @returns {Object[]}	array of columns
	 */
	createNotificationsColumns() {
		let me = this;

		return [
			{
				sortable: false,
				dataIndex: 'objectId',
				htmlEncode: true,
				flex: 1,
				renderer(value, meta, record) {
					record.data.gridNotificationId = Ext.id();
					setTimeout(function () {
						let wrapper = me.sidebarGrid
							?.getView()
							?.el?.dom?.querySelector(
								`.edi-ediweb-notification-grid-id-${record.data.gridNotificationId}`
							);
						if (wrapper) {
							me.addBtnClickHandler(wrapper, record.data);
						}
					}, 1);
					return me.itemTpl.apply(record.getData());
				}
			}
		];
	},
	/**
	 * Cleans and reload notifications grid
	 */
	reloadNotificationsGrid() {
		let me = this;
		let notificationsGridStore = me.sidebarGrid?.getStore();
		if (notificationsGridStore) {
			notificationsGridStore.data.clear();
			notificationsGridStore.reload();
		}
		if (me.floatingUpdateButton && !me.floatingUpdateButton.isDestroyed) {
			me.floatingUpdateButton.hide();
		}
	},
	/**
	 * Creates store for notifications grid
	 * @returns	{Object}	Ext.data.Store instance
	 */
	createNotificationsStore() {
		let me = this;

		me.notificationsStore = createStore({
			model: edi.models.getModel('NOTIFICATIONS'),
			buffered: true,
			pageSize: 25,
			proxy: createProxyConfig({
				type: 'ajax',
				url: edi.rest.services.NOTIFICATIONS.GET,
				extraParams: {
					ignoreRead: true //позволяет получать как прочитанные так и не прочитанные
				}
			}),
			listeners: {
				load: function (store) {
					me.sidebar.fireEvent('configureemptytext', store.getCount() === 0);
				}
			}
		});

		return me.notificationsStore;
	},
	/**
	 * Creates list of notifications for sidebar with infinite scroll
	 * @returns	{Object}	Ext.DataView instance
	 */
	createNotificationsGrid() {
		let me = this;

		me.sidebarGrid = Ext.create('Ext.grid.Panel', {
			flex: 1,
			width: '100%',
			hideHeaders: true,
			disableSelection: true,
			selModel: {
				pruneRemoved: false
			},
			plugins: {
				ptype: 'bufferedrenderer',
				numFromEdge: 3, // Колличество записей в видемой зоне, после которых происходит подгрузка
				trailingBufferZone: 25, // Колличество записей сохраннёных в кэше
				leadingBufferZone: 1 // Коллечество записей изначально загруженных в кэш
			},
			store: me.createNotificationsStore(),
			columns: me.createNotificationsColumns(),
			listeners: {
				itemclick(view, record, item, index, e) {
					me.gridItemClickHandler(view, record, item, index, e);
				}
			}
		});

		return me.sidebarGrid;
	},
	/**
	 * Grid's item click handler
	 * @param	{Object}	view
	 * @param	{Object}	record
	 * @param	{Object}	item
	 * @param	{Number}	index
	 * @param	{Object}	e
	 */
	gridItemClickHandler(view, record, item, index, e) {
		let me = this;
		me.markAsRead(record.getData());
	},
	/**
	 * Marks notification as read
	 * @param	{Object}	notification
	 */
	markAsRead(notification) {
		let me = this;
		if (
			!notification?.id ||
			me.__markingAsReadInProcess[notification.id] === true ||
			notification?.read === true ||
			!edi.permissions.hasPermission('READ_CLIENT_NOTIFICATIONS')
		) {
			return;
		}

		let onSuccess = function () {
			let notificationsGridStore = me.sidebarGrid?.getStore();
			if (notificationsGridStore) {
				let notificationRecord = notificationsGridStore.data.findBy((rec) => {
					return rec.get('id') === notification.id;
				});
				if (notificationRecord) {
					notificationRecord.set({ read: true });
					notificationRecord.endEdit();
					notificationRecord.commit();
				}

				//me.itemTpl.apply(notificationRecord.getData());
				me.sidebarGrid.getView().refresh();
			}
		};
		let always = function () {
			delete me.__markingAsReadInProcess[notification.id];
		};
		let url = edi.utils.formatString(edi.rest.services.NOTIFICATIONS.READ.PUT, {
			id: notification.id
		});

		me.__markingAsReadInProcess[notification.id] = true; //не дает дергать рест при кликах в нотификацию пока идет процесс
		edi.rest.sendRequest(url, 'PUT', Ext.encode({}), onSuccess, null, always);
	},
	/**
	 * Marks all notifications as read
	 */
	markAllAsRead() {
		let me = this;
		let confirmText = edi.utils.formatString(edi.i18n.getMessage('ediweb.notification.read.question'), {
			total: me.countUnread
		});
		confirm(
			undefined,
			confirmText,
			() =>
				edi.rest.sendRequest(
					edi.rest.services.NOTIFICATIONS.READ.ALL.PUT,
					'PUT',
					Ext.encode({}),
					function () {
						me.countUnread = 0;
						me.setUnreadCount();
						me.reloadNotificationsGrid();
					},
					function (resp) {
						edi.document.actions.defaultFailureHandler(
							undefined,
							'ediweb.notification.error.setting.read'
						)(resp);
					}
				),
			undefined,
			function () {
				//нужно для обновления z-index у бэкдропа, т.к. ExtJS меняет индекс
				//у сайдбара при показе/скрытии модалок
				if (me.sidebar.isToggle() === true) {
					me.sidebar.setBackdropVisible(true);
				}
			},
			undefined,
			{
				icon: null
			}
		);
	},
	/**
	 * Creates "mark all as read" button for sidebar
	 * @returns	{Object}	Ext.button.Button instance
	 */
	createMarkAllAsReadBtn() {
		let me = this;

		me.checkAllReadBtn = createButton({
			cls: [BUTTON_CLS.secondary],
			glyph: edi.constants.ICONS.DONE_ALL,
			text: edi.i18n.getMessage('ediweb.notifications.action.mark.all.as.read'),
			handler() {
				me.markAllAsRead();
			}
		});

		return me.checkAllReadBtn;
	},
	/**
	 * Creates toolbar for sidebar
	 * @returns	{Object}	Ext.container.Container instance
	 */
	createSidebarToolbar() {
		let me = this;

		me.sidebarToolbar = createContainer({
			cls: 'edi-ediweb-sidebar-buttons',
			layout: {
				type: 'hbox',
				pack: 'center'
			},
			padding: '8 24',
			items: [me.createMarkAllAsReadBtn()]
		});

		return me.sidebarToolbar;
	},
	/**
	 * Creates floating update button on the top of grid
	 * @returns	{Object}	Ext.container.Container instance
	 */
	createFloatingUpdateButton() {
		let me = this;
		me.floatingUpdateButton = createButton({
			cls: [BUTTON_CLS.secondary],
			glyph: edi.constants.ICONS.ARROW_UPWARD,
			text: edi.i18n.getMessage('ediweb.notifications.action.show.new'),
			// height: 40,
			handler() {
				me.floatingUpdateButton.hide();
				me.reloadNotificationsGrid();
			}
		});

		return me.floatingUpdateButton;
	},
	/**
	 * Creates sidebar's header with close button
	 * @returns	{Object}	Ext.container.Container instance
	 */
	createHeader() {
		let me = this;

		me.sidebarHeader = createContainer({
			cls: 'edi-ediweb-sidebar-header',
			layout: {
				type: 'hbox',
				align: 'middle'
			},
			height: 80,
			margin: '0 0 2 0', //тут отображается тень от хедера
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage('ediweb.notifications.header.title'),
					padding: 24
				})
				// createButton({
				// 	cls: [BUTTON_CLS.light, BUTTON_CLS.rounded],
				// 	glyph: edi.constants.ICONS.CLOSE,
				// 	handler() {
				// 		me.sidebar.toggle(false);
				// 	}
				// })
			]
		});

		return me.sidebarHeader;
	},
	/**
	 * Creates notifications sidebar
	 * @returns	{Object}	Ext.panel.SideBar instance
	 */
	createNotificationsSidebar() {
		let me = this;

		me.sidebar = createSidebar({
			showCloseBtn: true,
			cls: 'edi-ediweb-sidebar-notifications',
			layout: {
				type: 'vbox',
				align: 'stretch'
			},
			width: edi.constants.DEFAULT.COMPONENTS_SETTINGS.SIDEBAR.WIDTH,
			parent: edi.core.getViewPort(),
			enableBackdrop: true,
			backdropCls: 'edi-ediweb-sidebar-notifications-backdrop',
			// padding: 0,
			items: [
				me.createHeader(),
				me.createNotificationsGrid(),
				me.createSidebarToolbar(),
				me.createFloatingUpdateButton()
			],
			emptyTextTplOptions: {
				title: edi.i18n.getMessage('ediweb.notification.empty.text.tpl.title'),
				contentText: edi.i18n.getMessage('ediweb.notification.empty.text.tpl.contentText')
			},
			checkIsEmpty: function () {
				return me.createNotificationsStore().getCount() === 0;
			},
			close: function () {
				if (this.isToggle()) {
					this.destroy();
				}
			}
		});

		return me.sidebar;
	},
	/**
	 * Toggle Opened/Closed sidebar's state (and creates it if needed)
	 */
	toggleSidebar() {
		let me = this;

		if (!me.sidebar || me.sidebar.destroyed) {
			me.createNotificationsSidebar();
			me.sidebar.toggle(true);
			me.setUnreadCount();
		} else {
			if (!me.sidebar.isToggle()) {
				me.sidebar.toggle(true);
				me.reloadNotificationsGrid();
			} else {
				me.sidebar.destroy();
			}
		}
	},
	/**
	 * Creates notifications header action button
	 */
	createActionButton() {
		let me = this;
		me.actionButton = createButton({
			cls: [BUTTON_CLS.light, BUTTON_CLS.rounded, BUTTON_CLS.notifications_button],
			plugins: [
				{
					ptype: PTYPE_BADGE,
					cls: BADGE_PRESETS.buttonCounter,
					fixed: true,
					align: BADGE_ALIGN.trc
				}
			],
			margin: '0 16 0 0',
			glyph: edi.constants.ICONS.NOTIFICATIONS,
			hidden: !(edi.permissions.isLoaded() && edi.permissions.hasPermission('READ_CLIENT_NOTIFICATIONS')),
			handler() {
				edi.toast && edi.toast.clearAll();
				me.toggleSidebar();
			}
		});
	},
	/**
	 * Updates text on notifications button and visibility of markAllAsReadBtn
	 */
	setUnreadCount() {
		let me = this;
		let cnt = +me.countUnread;
		if (me.actionButton && !me.actionButton.isDestroyed) {
			if (cnt <= 0) {
				me.actionButton.badgeCmp.setText(null);
			} else {
				me.actionButton.badgeCmp.setText(cnt >= 100 ? '99+' : cnt);
			}
		}
		if (me.sidebarToolbar && !me.sidebarToolbar.isDestroyed) {
			me.sidebarToolbar.setVisible(me.countUnread > 0);
		}
		if (cnt > 0 && edi.permissions.hasPermission('CLIENT_NOTIFICATION')) {
			me.showNewNotificationsInToasts();
		}

		//при появлении новых уведомлений (счетчик растет) и открытом сайдбаре отображаем кнопку "новые уведомления"
		//а если счетчик уменьшается или не меняется, значит новый уведомлений нет и стоит её спрятать
		if (me.floatingUpdateButton && !me.floatingUpdateButton.isDestroyed) {
			if (me.__previousCnt !== null && cnt > me.__previousCnt) {
				me.floatingUpdateButton.show();
			} else {
				me.floatingUpdateButton.hide();
			}
		}
		me.__previousCnt = cnt;
	},
	/**
	 * Check for new notifications and show them in toasts
	 */
	async showNewNotificationsInToasts() {
		let me = this;
		if (!edi.toast || me.sidebar?.isToggle()) {
			return;
		}

		let configNotifies = await me.getUnreadNotificationsConfig();
		if (!!configNotifies) {
			let displayNotificationsTime = configNotifies.displayNotificationsTime
				? configNotifies.displayNotificationsTime
				: edi.constants.DEFAULT.DISPLAY_NOTIFICATIONS_TIME;
			me.unreadNotificationsLimit =
				configNotifies.maxNotificationsCount || edi.constants.DEFAULT.MAX_NOTIFICATIONS_COUNT;
			me.toastAutoCloseTimeout = displayNotificationsTime * 1000;
		}

		let unreadNotifications = await me.getUnreadNotifications();
		if (!Array.isArray(unreadNotifications) || unreadNotifications.length < 1) {
			return;
		}

		unreadNotifications
			.filter((notification) => notification.creationDate >= me.__lastShownNotificationDate)
			.sort((a, b) => (a.creationDate >= b.creationDate ? 0 : -1))
			.forEach((notification, index) => {
				if (!!me.toastAutoCloseTimeout) {
					edi.toast.showToast({
						values: notification,
						content: me.itemTpl.apply(notification),
						delayBeforeShow: index * 300,
						autoCloseTimeout: me.toastAutoCloseTimeout,
						isAllowed() {
							return !me.sidebar || !me.sidebar.isToggle();
						},
						clickHandler() {
							if (!me.sidebar || !me.sidebar.isToggle()) {
								edi.toast.clearAll();
								me.toggleSidebar();
							}
						},
						afterAppend: function (newToast, toastValues) {
							edi.notifications.addBtnClickHandler(newToast, toastValues);
						}
					});
				}
			});
		me.__lastShownNotificationDate = new Date().valueOf();
	},
	/**
	 * Get unread notifications from backend
	 * @returns	{Promise<Object[]>}
	 */
	getUnreadNotifications() {
		let me = this;

		return new Promise((resolve) => {
			let fail = function () {
				resolve([]);
			};
			let success = function (resp) {
				resolve(Array.isArray(resp?.items) ? resp.items : []);
			};

			let url = edi.utils.compileURL(edi.rest.services.NOTIFICATIONS.GET, {
				start: 0,
				page: 1,
				limit: me.unreadNotificationsLimit
			});
			edi.rest.sendRequest(url, 'GET', '', success, fail);
		});
	},
	getUnreadNotificationsConfig() {
		return new Promise((resolve, reject) => {
			let fail = function () {
				resolve();
			};
			let success = function (resp) {
				resolve(resp?.data);
			};
			edi.rest.sendRequest(edi.rest.services.NOTIFICATIONS.CONFIG.GET, 'GET', '', success, fail);
		});
	}
});
