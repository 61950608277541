import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createContainer, createFieldBlockForDetails, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createCheckbox, createLabelForDetails, createLabelBlockForDetails, LABEL_CLS } from '@UIkit/components/fields';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { isDSFV3 } from '@Edi/methods/methods';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { dsfModuleMethods } from '@Edi/modules/documents/DSF/methods';
import createPreviewPanel from '@Components/previewPanel/previewPanel';
import { showApprovalModal } from '@Ediweb/modules/APPROVAL_DOCUMENTS/methods';
import { createActionsButton } from '@UIkit/components/buttons';
import { showInfoToast } from '@Ediweb/core';
import { MODAL_SIZE } from '@UIkit/components/modal';

/**
 * Class for new free format document details
 */
Ext.namespace('edi.modules');
edi.modules['document.details.dsf'] = function () {
	let moduleData,
		doctype,
		fields = {},
		fieldsmap = {},
		contract,
		receiverPrefix,
		senderPrefix,
		annulDoc,
		utochDoc = null,
		noticeDoc = null,
		formLayout,
		topPath = '//DocumentFreeFormat/',
		izvPolSignModal,
		customFieldsContainer;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		createMaps();
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates dsf panel
	 * @returns {Object}
	 */
	const createDetailsPanel = function (documentData, config) {
		const meta = moduleData.initData.data;
		const headPanel = createDocumentHeaderPanel(moduleData.initData, config);

		if (doctype === edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT) {
			let tmpValue;

			documentData.number = edi.utils.getObjectProperty(documentData, 'bgm.c106.e1004');
			documentData.order = {
				number: edi.utils.getObjectPropertyEx(documentData, 'sg1[rff.c506.e1153==ON]rff.c506.e1154')
			};
			documentData.boxState = edi.utils.getObjectProperty(documentData, 'ftx.0.c108.e4440.0');
			documentData.despatchReferenceNumber = edi.utils.getObjectPropertyEx(
				documentData,
				'sg1[rff.c506.e1153==AAK]rff.c506.e1154'
			);
			documentData.utdNumber = edi.utils.getObjectPropertyEx(
				documentData,
				'sg1[rff.c506.e1153==IV]rff.c506.e1154'
			);
			documentData.deliverPoint = edi.utils.getObjectPropertyEx(documentData, 'sg4[nad.e3035==DP]nad.c082.e3039');
			documentData.shipFrom = edi.utils.getObjectPropertyEx(documentData, 'sg4[nad.e3035==CZ]nad.c082.e3039');
			documentData.inspectionLocation = edi.utils.getObjectPropertyEx(
				documentData,
				'sg4[nad.e3035==CZ]nad.c080.e3036'
			);
			tmpValue = edi.utils.getObjectPropertyEx(documentData, 'sg4[nad.e3035==CZ]nad.c059.e3042');
			documentData.inspectionLocationAddress = tmpValue && tmpValue.join ? tmpValue.join() : '';
			tmpValue = edi.utils.getObjectProperty(documentData, 'sg10.tdt.c222');
			documentData.vehicleNumber = tmpValue.e8213 + ', ' + tmpValue.e8212;
			documentData.sender = edi.utils.getObjectPropertyEx(documentData, 'sg4[nad.e3035==BY]nad.c082.e3039');
			documentData.receiver = edi.utils.getObjectPropertyEx(documentData, 'sg4[nad.e3035==SU]nad.c082.e3039');
		}

		let filePanel = null;
		if (documentData.file) {
			const fileName = documentData.file.fileName;
			const previewIsAllowed = edi.permissions.hasPermission('CLIENT_READ_ATTACHMENT_VIEW');
			const fileDropper = createFileDropper({
				cls: previewIsAllowed ? 'edi-component-clickable drag-file' : 'drag-file',
				readOnly: true,
				fileData: {
					fileName: documentData.file.fileName,
					fileDate: edi.utils.formatDate(documentData.file.modifyDate, edi.constants.DATE_FORMAT.FNS),
					fileTime: edi.utils.formatDate(documentData.file.modifyDate, edi.constants.DATE_FORMAT.TIME),
					fileSize: edi.utils.formatFileSize(documentData.file.fileSize)
				},
				listeners: {
					afterrender: function (cmp) {
						if (previewIsAllowed) {
							cmp.on({
								click: function () {
									moduleData.tab.setLoading();

									edi.rest.sendRequest(
										edi.utils.formatString(edi.rest.services.DOCUMENTS.DSF_ATTACHMENT.VIEW.POST, {
											documentId: moduleData.initData.id
										}),
										'POST',
										{},
										function (responseData) {
											const attachData = responseData?.data;
											if (attachData) {
												createPreviewPanel({
													fileId: moduleData.initData.id,
													fileName,
													imagesBase64Data: attachData.data?.map(({ data }) => data)
												});
											} else {
												edi.core.showInfo('document.dsf.load.preview.waiting');
											}
										},
										function (err) {
											edi.core.showInfo('document.dsf.load.preview.error');
										},
										function () {
											moduleData.tab.setLoading(false);
										}
									);
								},
								element: 'el',
								scope: cmp
							});
						}
					}
				}
			});

			filePanel = createFieldBlockForDetails({
				cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				title: edi.i18n.getMessage('document.file'),
				layout: {
					type: 'grid',
					area: [6]
				},
				items: [fileDropper]
			});
		}

		let headerLabelText = [
			{
				title: edi.i18n.getMessage('documents.form.type'),
				text: edi.i18n.getMessage('documents.doctype.' + meta.type)
			},
			{
				title: edi.i18n.getMessage('document.form.number'),
				cls: LABEL_CLS.breakAll,
				text: documentData.number
			}
		];
		if (doctype !== edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT) {
			headerLabelText.push({
				title: edi.i18n.getMessage('documents.column.date'),
				date: edi.renderers.fnsDateFromClient(edi.utils.getObjectProperty(documentData, 'date'))
			});
		}

		const headData = createFieldBlockForDetails({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: headerLabelText
				})
			]
		});

		fields.baseData = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.base'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('document.type'),
							text: edi.utils.getObjectProperty(documentData, 'reason.baseName')
						},
						{
							title: edi.i18n.getMessage('document.dsf.base.name.nr'),
							text: edi.utils.getObjectProperty(documentData, 'reason.baseNumber')
						},
						{
							title: edi.i18n.getMessage('date'),
							date: edi.utils.getObjectProperty(documentData, 'reason.baseDate')
						}
					]
				})
			]
		});

		fields.contractData = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('document.select.contract'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('document.dsf.contract.number'),
							text: edi.utils.getObjectProperty(documentData, 'contract.number')
						},
						{
							title: edi.i18n.getMessage('document.dsf.contract.date'),
							date: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(documentData, 'contract.date')
							)
						},
						{
							title: edi.i18n.getMessage('document.dsf.supplementary.agreement.number'),
							text: edi.utils.getObjectProperty(documentData, 'contract.supplementaryContract')
						}
					]
				})
			]
		});

		fields.promo = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('document.dsf.supplementary.agreement.promo.name'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'stock')
				})
			]
		});

		fields.summ = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.summ'),
			items: [
				createLabelForDetails({
					text: edi.utils.displayCurrencyDecimals(edi.utils.getObjectProperty(documentData, 'totalSumm'))
				})
			]
		});

		let currCode = edi.stores.initOkvCurrencyStore(),
			curDisplay;
		let currencyCode = edi.utils.getObjectProperty(documentData, 'payment.currencyCode');
		if (currencyCode) {
			let record = currCode.findRecord(Ext.isString(currencyCode) ? 'codeStr' : 'code', currencyCode);
			if (record) {
				curDisplay = record.get('displayName');
			}
		}

		fields.paymentInfo = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.dsf.paymentInformation'),
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: [8]
			},
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('invoice.currency'),
							text: curDisplay
						},
						{
							title: edi.i18n.getMessage('total.net.amount'),
							text: edi.utils.getObjectProperty(documentData, 'payment.netAmount')
						},
						{
							title: edi.i18n.getMessage('total.tax.amount'),
							text: edi.utils.getObjectProperty(documentData, 'payment.taxAmount')
						},
						{
							title: edi.i18n.getMessage('total.gross.amount'),
							text: edi.utils.getObjectProperty(documentData, 'payment.grossAmount')
						}
					]
				}),
				createCheckbox({
					boxLabel: edi.i18n.getMessage('document.dsf.prepayment'),
					checked: edi.utils.getObjectProperty(documentData, 'payment.prepayment'),
					disabled: true,
					uncheckedValue: false
				})
			]
		});

		fields.date = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.price.catalogue.start.date'),
			items: [
				createLabelForDetails({
					date: [
						edi.utils.getObjectProperty(documentData, 'startDate'),
						edi.utils.getObjectProperty(documentData, 'endDate')
					]
				})
			]
		});

		fields.priceCatalogueType = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.price.catalogue.type'),
			items: [
				createLabelForDetails({
					text: edi.i18n.getMessage(
						'document.dsf.price.catalogue.type.' + edi.utils.getObjectProperty(documentData, 'subType')
					)
				})
			]
		});

		fields.acceptanceDateCmp = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.receipt.date'),
			items: [
				createLabelForDetails({
					date: edi.renderers.fnsDateFromClient(edi.utils.getObjectProperty(documentData, 'acceptanceDate'))
				})
			]
		});

		fields.shipmentDateCmp = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.shipment.date'),
			items: [
				createLabelForDetails({
					date: edi.renderers.fnsDateFromClient(edi.utils.getObjectProperty(documentData, 'shipmentDate'))
				})
			]
		});

		fields.orderNumber = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.order'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('order.number'),
							text: edi.utils.getObjectProperty(documentData, 'order.number')
						},
						{
							title: edi.i18n.getMessage('order.date'),
							date: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(documentData, 'order.date')
							)
						}
					]
				})
			]
		});

		fields.performDateCmp = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.akt.acting.date'),
			items: [
				createLabelForDetails({
					date: edi.renderers.fnsDateFromClient(edi.utils.getObjectProperty(documentData, 'performDate'))
				})
			]
		});

		fields.comment = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('remarks'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'comment')
				})
			]
		});

		fields.receiverSignatureField = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				createCheckbox({
					boxLabel: edi.i18n.getMessage('column.expected.recipients.signature.under.document'),
					checked:
						edi.utils.getAttributeByName(
							moduleData.initData.data.attributes,
							'RECEIVER_SIGNATURE_EXPECTED'
						) === 'true',
					disabled: true,
					uncheckedValue: false
				})
			]
		});

		const createOS = function (selectorConf) {
			Ext.applyIf(selectorConf, {
				cls: '',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				fieldValues: documentData,
				disableCommonAddressRenderer: true
			});

			return createOrgSelector(selectorConf);
		};

		fields.sender = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('document.dsf.sender.' + doctype),
			items: [
				createOS({
					fieldsMap: edi.selectors.getDSFFieldsMap(senderPrefix)
				})
			]
		});

		fields.receiver = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('document.dsf.receiver.' + doctype),
			items: [
				createOS({
					fieldsMap: edi.selectors.getDSFFieldsMap(receiverPrefix)
				})
			]
		});

		const isFNS_InvoiceType = doctype === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE;

		fields.ship = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage(isFNS_InvoiceType ? 'document.seller' : 'document.ship.from'),
			items: [
				createOS({
					fieldsMap: edi.selectors.getDSFFieldsMap(isFNS_InvoiceType ? 'supplier' : 'consignor')
				})
			]
		});

		fields.consignee = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage(isFNS_InvoiceType ? 'document.buyer' : 'documents.torg.consignee'),
			items: [
				createOS({
					fieldsMap: edi.selectors.getDSFFieldsMap(isFNS_InvoiceType ? 'payer' : 'consignee')
				})
			]
		});

		fields.commentText = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.commentText'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'commentText')
				})
			]
		});

		fields.boxState = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.boxState'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'boxState')
				})
			]
		});

		fields.despatchReferenceNumber = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('despatch.advice.number'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'despatchReferenceNumber')
				})
			]
		});

		fields.utdNumber = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('utd.number'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'utdNumber')
				})
			]
		});

		fields.deliverPoint = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('delfor.delivery'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'deliverPoint')
				})
			]
		});

		fields.shipFrom = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.ship.from'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'shipFrom')
				})
			]
		});

		fields.inspectionLocation = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.inspectionLocation'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'inspectionLocation')
				})
			]
		});

		fields.inspectionLocationAddress = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.inspectionLocationAddress'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'inspectionLocationAddress')
				})
			]
		});

		fields.vehicleNumber = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.vehicleNumber'),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'vehicleNumber')
				})
			]
		});

		fields.sender_dsf_recadv_diff_act = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.sender.' + doctype),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'sender')
				})
			]
		});

		fields.receiver_dsf_recadv_diff_act = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.dsf.receiver.' + doctype),
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(documentData, 'receiver')
				})
			]
		});

		const organizationsTwoColumnsLayout = createTwoColumnsLayout(
			[fields.sender, fields.sender_dsf_recadv_diff_act, fields.ship],
			[fields.receiver, fields.receiver_dsf_recadv_diff_act, fields.consignee],
			0.5,
			{
				//margin: '17 0 0 0',
				items2Conf: {
					margin: '0 0 0 10'
				}
			}
		);

		formLayout = [
			fields.contractData,
			fields.promo,
			fields.priceCatalogueType,
			fields.date,
			fields.despatchReferenceNumber,
			fields.deliverPoint,
			fields.inspectionLocation,
			fields.boxState,
			fields.summ,
			fields.paymentInfo,
			fields.shipmentDateCmp,
			fields.acceptanceDateCmp,
			fields.performDateCmp,
			fields.orderNumber,
			filePanel,
			fields.comment,
			fields.commentText,
			fields.receiverSignatureField,
			fields.utdNumber,
			fields.shipFrom,
			fields.inspectionLocation,
			fields.inspectionLocationAddress,
			fields.vehicleNumber
		];

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		let detailsPanelItems = [headData, organizationsTwoColumnsLayout, fields.baseData];
		detailsPanelItems = detailsPanelItems.concat(formLayout);

		if (doctype === edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT) {
			const productsGrid = createProductGridBase({
				title: edi.i18n.getMessage('document.upd.lines'),
				gridModel: 'DOCUMENT_DSF_RECADV_DIFF_ACT',
				gridColumnConfig: 'docunent_dsf_recadv_diff_act',
				data: edi.utils.getObjectProperty(documentData, 'sg16.0.sg22'),
				margin: '16 0 0',
				readOnly: true,
				hasTotal: false,
				modalConf: {
					width: MODAL_SIZE.widthLarge,
					maxHeight: undefined
				},
				modalFormConfig: {
					title: 'pricat.product.modal.title',
					modalFields: [
						{
							title: edi.i18n.getMessage('line.item.tab.product'),
							items: [
								{
									title: 'document.dsf.plu',
									name: 'plu'
								},
								{
									title: 'line.item.product.description',
									name: 'name'
								},
								{
									title: 'document.dsf.acceptedAmount',
									name: 'acceptedAmount'
								},
								{
									title: 'product.unit.name',
									name: 'unitOfMeasure'
								},
								{
									title: 'document.dsf.inadequateQuality',
									name: 'inadequateQuality'
								},
								{
									title: 'document.dsf.expired',
									name: 'expired'
								},
								{
									title: 'document.dsf.damage',
									name: 'damage'
								},
								{
									title: 'document.dsf.overSpecified',
									name: 'overSpecified'
								},
								{
									title: 'document.dsf.discrepanciesReason',
									name: 'discrepanciesReason'
								},
								{
									title: 'document.dsf.deficit',
									name: 'deficit'
								}
							]
						}
					]
				}
			});

			detailsPanelItems.push(productsGrid);
		}

		return createFormForModule({
			autoScroll: true,
			items: [
				headPanel,
				createContainer({
					cls: 'edi-form-maxwidth',
					items: detailsPanelItems
				}),
				createCustomFieldsContainer()
			]
		});
	};

	const createSignIzvPol = function (success, document, modal) {
		if (modal) {
			modal.close();
		}
		edi.utils.sign(
			document,
			moduleData.tab,
			function (failed, responseData, certificate, poa, silent, poaConfirmChecked) {
				if (failed) {
					if (responseData.typeError !== 'certificate.data.not.matched.with.organization') {
						edi.core.showError(edi.utils.formatComplexServerError(responseData));
					}
				} else {
					success(certificate, poa, poaConfirmChecked);
				}
			},
			undefined,
			undefined,
			undefined,
			undefined,
			{
				signContentUrl: edi.utils.formatString(
					'AB' === edi.login.getAuthType()
						? edi.rest.services.DOCUMENTS.SIGN_AB.GET
						: edi.rest.services.DOCUMENTS.SIGN.GET,
					{
						documentId: noticeDoc.id
					}
				),
				signContentUrlMethod: 'PUT'
			}
		);
	};

	/**
	 * Creates action panel above the data panel
	 */
	const createModuleActionsPanel = function (documentData) {
		let document = moduleData.initData.data,
			actionsPanel = createActionsPanel(),
			direction = edi.utils.getDocumentDirection(document.toOrg, document.fromOrg),
			needSignatures = edi.document.actions.getSignCount(document),
			side = edi.document.actions.getSignSide(document.type, direction),
			side2 = edi.constants.CONTRACT_SIDES.CONSUMER,
			isIncomingDoc = edi.constants.DIRECTIONS.INCOMING === direction,
			isOutgoingDoc = edi.constants.DIRECTIONS.OUTGOING === direction;

		if (side === edi.constants.CONTRACT_SIDES.CONSUMER) {
			side2 = edi.constants.CONTRACT_SIDES.SUPPLIER;
		}
		const needSignatures2 = isOutgoingDoc ? 0 : edi.document.actions.getSignCount(document, side2);
		let signaturesCount = needSignatures + needSignatures2 - document.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero

		let useBeforeSignAction =
			document.state === edi.constants.STATE.DRAFT ||
			document.state === edi.constants.STATE.READ ||
			document.state === edi.constants.STATE.SENT ||
			document.state === edi.constants.STATE.WAIT_RECEIVER_DECISION;

		const bpName = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'bpName');
		if (bpName === edi.constants.DOCUMENT_BP_NAMES.DSF_ALL.DSF_UNKNOWN_INTEGRATION) {
			useBeforeSignAction = false;
		}

		const needSignIzvPol =
			(document.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER && isIncomingDoc) ||
			(document.state === edi.constants.STATE.DSF_DP_IZVPOL_SENDER && isOutgoingDoc) ||
			(document.state === edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL && isIncomingDoc);

		let customButtons = [],
			excludeActions = {},
			dsfTypesFrom = edi.constants.DSF_TYPES_FROV.filter(function (item) {
				return (
					item !== edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT &&
					item !== edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT
				);
			});
		if (dsfTypesFrom.some((it) => it === document.type)) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.CLARIFY] = true;
		}

		const signContentUrl = edi.utils.formatString(
			'AB' === edi.login.getAuthType()
				? edi.rest.services.DOCUMENTS.SIGN_AB.GET
				: edi.rest.services.DOCUMENTS.SIGN.GET,
			{
				documentId: document.id //needSignIzvPol && noticeDoc ? noticeDoc.id : document.id
			}
		);

		if (
			documentData.file &&
			documentData.file.id &&
			edi.constants.ALLOW_EXPORT_DOCUMENTS &&
			dsfModuleMethods.isEwDocWithoutSomeActions(document.type)
		) {
			var exportAttachmentButton = edi.document.actions.createExportAttachmentActionButton(
				moduleData.initData,
				moduleData.initData.id
			);
			const businessState = edi.utils.getObjectProperty(document, 'businessState');
			exportAttachmentButton.actionName = edi.constants.DOCUMENT_ACTIONS.EXPORT_ATTACHMENT;
			if (businessState === edi.constants.STATE.ACTIVE) {
				exportAttachmentButton.order = 129;
			}
			customButtons.push(exportAttachmentButton);
		}

		if (edi.constants.ACTUAL_FREE_DOCUMENT_TYPES.indexOf(document.type) < 0) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.EDIT] = true;
		}

		// EDIWEB begin
		var approvalState = edi.utils.getAttributeByName(document.attributes, 'APPROVAL_STATE');

		let isShowGroupSendBtn = function () {
			if (
				approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.APPROVED ||
				approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REVOKED
			) {
				return document.state === edi.constants.STATE.SENDER_REVIEW;
			}
			if (approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED) {
				return document.state === edi.constants.STATE.SENDER_REVIEW;
			}
			return document.state === edi.constants.STATE.DRAFT;
		};

		let showGroupSendBtn =
			Ext.Array.contains(edi.constants.DSF_WITH_APPROVE, document.type) && isShowGroupSendBtn() && isOutgoingDoc;

		if (showGroupSendBtn) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.SIGN] = true;

			let sendGroupAction = createActionsButton({
				order: 2,
				actionName: 'GROUP_SEND',
				text: edi.i18n.getMessage('document.sign.and.send.document'),
				glyph: edi.constants.ICONS.SEND,
				menu: {
					plain: true,
					hideMode: 'display',
					items: [
						approvalState !== edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED
							? edi.document.actions.createSignActionButton(
									document,
									moduleData.tab,
									null,
									null,
									null,
									'ediweb.send.and.sign.parnter.btn',
									null,
									{
										useBeforeAction: needSignIzvPol && noticeDoc ? true : useBeforeSignAction,
										signProperties: {
											signContentUrl: signContentUrl
										},
										beforeInit: function (success) {
											if (needSignIzvPol && noticeDoc) {
												createSignIzvPol(function (selectedCertificate) {
													callBeforeInit(success, selectedCertificate);
												}, document);
											} else {
												callBeforeInit(success);
											}
										},
										buttonProps: {
											glyph: null,
											cls: 'edi-ediweb-left-align-action-button ui-button ui-button-light',
											textAlign: 'left'
										}
									}
							  )
							: null,
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.documents.btn'),
							handler() {
								let sendData = document;
								sendData.status = document.state;
								sendData.date = document.creationDate;
								showApprovalModal(
									sendData,
									moduleData,
									null,
									edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS
								);
							}
						},
						{
							text: edi.i18n.getMessage('ediweb.send.to.approval.employees.btn'),
							handler() {
								let sendData = document;
								sendData.status = document.state;
								sendData.date = document.creationDate;
								showApprovalModal(
									sendData,
									moduleData,
									null,
									edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES
								);
							}
						}
					]
				}
			});
			customButtons.push(sendGroupAction);
		}

		if (
			edi.action.isAvailable(
				edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS,
				edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', document))
			) &&
			!showGroupSendBtn
		) {
			let sendToApproveDocumentsBtn = createActionsButton({
				actionName: edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS,
				order: 2,
				text: edi.i18n.getMessage('document.send.to.approve.documents'),
				handler: function () {
					let sendData = document;
					sendData.status = document.state;
					sendData.date = document.creationDate;
					showApprovalModal(
						sendData,
						moduleData,
						null,
						edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_DOCUMENTS
					);
				}
			});
			let sendToApproveEmployeesBtn = createActionsButton({
				actionName: edi.constants.DOCUMENT_ACTIONS.SEND_TO_APPROVE_DOCUMENTS,
				order: 2,
				text: edi.i18n.getMessage('document.send.to.approve.employees'),
				handler: function () {
					let sendData = document;
					sendData.status = document.state;
					sendData.date = document.creationDate;
					showApprovalModal(
						sendData,
						moduleData,
						null,
						edi.constants.APPROVAL_DOCUMENTS.TYPES.APPROVAL_EMPLOYEES
					);
				}
			});
			customButtons.push(sendToApproveDocumentsBtn);
			customButtons.push(sendToApproveEmployeesBtn);
		}

		const onboardingFPDSTTypes = [edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02];

		if (
			onboardingFPDSTTypes.includes(document.type) &&
			isIncomingDoc &&
			document.businessState === edi.constants.STATE.SENT &&
			edi.permissions.hasPermission('REJECT_DSF_ONBOARDING_FP')
		) {
			const annulButton = createActionsButton({
				actionName: edi.constants.DOCUMENT_ACTIONS.ANNUL,
				order: 1,
				text: edi.i18n.getMessage('ediweb.document.mark.annul'),
				glyph: edi.constants.ICONS.CANCEL,
				handler: function () {
					edi.core.confirm(null, 'ediweb.onboarding.fp.reject.confirmation', function () {
						const failure = edi.document.actions.defaultFailureHandler(
							moduleData.tab,
							'error.getting.data',
							function () {
								edi.modulesHandler.removeModule(moduleData);
							}
						);
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: document.id
								},
								true
							),
							'PUT',
							Ext.encode({ ANNUL: true }),
							function () {
								edi.events.documents.fireEvent('change', {
									id: document.id
								});
								moduleData.tab.setLoading(false);
							},
							failure
						);
					});
				}
			});

			customButtons.push(annulButton);
		}
		// EDIWEB end

		const callBeforeInit = function (success, certificate, poa, poaConfirmChecked) {
			if (
				'object' == typeof edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES &&
				edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.DOCUMENT_TYPES.some((it) => it === document.type) &&
				edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.STATES.some((it) => it === document.state)
			) {
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.SEND.PUT,
						{
							documentId: document.id
						},
						true
					),
					'PUT',
					Ext.encode({}),
					function () {
						success(certificate, poa);
					}
				);
			} else {
				'function' == typeof success ? success(certificate, poa, poaConfirmChecked) : null;
			}
		};
		if (needSignIzvPol && noticeDoc) {
			if (!annulDoc) {
				annulDoc = {};
			}
			//калбак, который будет вызван сразу после аннулирования для подписания извещения о получении
			annulDoc.beforeAnnul =
				needSignIzvPol && noticeDoc
					? function (success, modal) {
							createSignIzvPol(
								function (selectedCertificate, selectedPoa, poaConfirmChecked) {
									success(selectedCertificate, selectedPoa, poaConfirmChecked);
								},
								document,
								modal
							);
					  }
					: null;
		}

		let processData = {};
		if (isDSFV3(document)) {
			if (
				document.state === edi.constants.STATE.SENDER_REVIEW &&
				edi.constants.APPROVAL_DOCUMENTS?.STATE?.REJECTED &&
				approvalState === edi.constants.APPROVAL_DOCUMENTS.STATE.REJECTED
			) {
				processData = {
					url: edi.rest.services.DOCUMENTS.SEND.PUT,
					method: 'PUT',
					params: {
						documentId: document.id
					},
					properties: function (reasonText) {
						var postData = {};
						postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT] = true;
						postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT_REASON] = reasonText;
						return postData;
					},
					callback: function () {
						edi.events.documents.fireEvent('change', {
							id: document.id
						});
						moduleData.tab.setLoading(false);
					}
				};
			} else {
				processData = {
					url: edi.rest.services.DOCUMENTS.DSF_REJECT.POST,
					method: 'POST',
					params: {
						parentId: document.id
					},
					properties: function (reasonText) {
						return {
							reason: reasonText
						};
					},
					callback: function (signData, success, failed, selectedCertificate, poa, poaConfirmChecked) {
						edi.document.actions.signUtochDocument(
							signData.data,
							document,
							success,
							failed,
							moduleData.tab,
							selectedCertificate,
							undefined,
							undefined,
							poaConfirmChecked
						);
					}
				};
			}
		}

		const needChangeCountSignature = edi.constants.DSF_FOR_MULTY_SIGN.some((it) => it === document.type);
		let canMultiSign = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'canMultiSign');
		canMultiSign = canMultiSign === true || canMultiSign === 'true';
		const isMultiSign =
			canMultiSign &&
			needChangeCountSignature &&
			edi.permissions.hasPermission('SIGN_' + document.type + '_BUTTON');
		side = edi.document.actions.getSignSide(document.type, direction);
		const attrName = 'countSignature_' + side;
		const attr = {
			countSignature: parseInt(edi.utils.getAttributeByName(moduleData.initData.data.attributes, attrName), 10)
		};
		const changeCountSignatureFail = (data) => {
			if (!data.success) {
				edi.rest.getErrorHandler(null, function () {
					if (moduleData.tab && !moduleData.tab.isDestroyed) {
						moduleData.tab.setLoading(false);
					}
				})(data);
			}
		};
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: document,
			direction: direction,
			moduleData: moduleData,
			excludeActions: excludeActions,
			needSignatures: signaturesCount,
			actionProps: {
				SIGN: {
					label: isMultiSign
						? 'document.sign.document'
						: doctype === edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT &&
						  edi.constants.DIRECTIONS.OUTGOING === direction
						? 'document.sign.and.complete.document'
						: null,
					methodAddOptions: {
						useBeforeAction: needSignIzvPol && noticeDoc ? true : useBeforeSignAction,
						beforeInit: function (success) {
							const afterChangeCountSignature = function () {
								if (needSignIzvPol && noticeDoc) {
									createSignIzvPol(function (selectedCertificate, selectedPoa, poaConfirmChecked) {
										callBeforeInit(success, selectedCertificate, selectedPoa, poaConfirmChecked);
									}, document);
								} else {
									callBeforeInit(success);
								}
							};
							//если есть атрибут и документ из списка DSF_FOR_MULTY_SIGN и есть право
							if (isMultiSign) {
								//изменить атрибуты и вызвать afterChangeCountSignature
								edi.methods.documents.changeCountSignatureAttribute(
									1,
									attr,
									document.type,
									document.id,
									direction,
									afterChangeCountSignature
								);
							} else {
								afterChangeCountSignature();
							}
						},
						afterFailure: function () {
							if (isMultiSign) {
								edi.methods.documents.changeCountSignatureAttribute(
									-1,
									attr,
									document.type,
									document.id,
									direction,
									changeCountSignatureFail
								);
							}
						},
						signProperties: {
							signContentUrl: signContentUrl,
							signRefuse: function () {
								moduleData.tab?.setLoading(false);
								if (isMultiSign) {
									edi.methods.documents.changeCountSignatureAttribute(
										-1,
										attr,
										document.type,
										document.id,
										direction,
										changeCountSignatureFail
									);
								}
							},
							// EDIWEB BEGIN
							dataChanged: function () {
								if (edi.constants.DSF_ONBOARDING_TYPES.includes(document.type)) {
									showInfoToast(
										edi.i18n.getMessage('ediweb.connecting.new.service.toast.title'),
										edi.i18n.getMessage('ediweb.connecting.new.service.toast.content'),
										{ showCloseBtn: true }
									);
								}

								edi.events.documents.fireEvent('change', {
									id: document.id
								});
							}
							// EDIWEB END
						}
					}
				},
				SEND:
					canMultiSign &&
					needChangeCountSignature &&
					edi.permissions.hasPermission('SEND_' + document.type + '_BUTTON')
						? {
								failure: function () {
									edi.methods.documents.changeCountSignatureAttribute(
										1,
										attr,
										document.type,
										document.id,
										direction,
										changeCountSignatureFail
									);
									edi.document.actions.defaultFailureHandler(
										moduleData.tab,
										'document.error.in.sending.process'
									);
								},
								beforeAction: function (success) {
									let newValue = document.countSignatures;
									edi.methods.documents.changeCountSignatureAttribute(
										null,
										attr,
										document.type,
										document.id,
										direction,
										success,
										newValue
									);
								}
						  }
						: {},
				COMPLETE:
					canMultiSign &&
					needChangeCountSignature &&
					edi.permissions.hasPermission('COMPLETE_' + document.type + '_BUTTON')
						? {
								failure: function () {
									edi.methods.documents.changeCountSignatureAttribute(
										1,
										attr,
										document.type,
										document.id,
										direction,
										changeCountSignatureFail
									);
									edi.document.actions.defaultFailureHandler(
										moduleData.tab,
										'document.error.in.sending.process'
									);
								},
								beforeAction: function (success) {
									let newValue =
										document.countSignatures -
										parseInt(
											edi.utils.getAttributeByName(
												moduleData.initData.data.attributes,
												'countSignature_' + edi.constants.CONTRACT_SIDES.SUPPLIER
											),
											10
										);
									edi.methods.documents.changeCountSignatureAttribute(
										null,
										attr,
										document.type,
										document.id,
										direction,
										success,
										newValue
									);
								}
						  }
						: {},
				EDIT: {
					moduleName: 'document.create.dsf'
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					addExtendedExport: true,
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE, {
						documentId: moduleData.initData.id
					}),
					extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: moduleData.initData.id
					})
				},
				REFRESH: {
					handler: function () {
						changeHandler(document);
					}
				},
				REJECT: {
					beforeAction:
						needSignIzvPol && noticeDoc
							? function (success) {
									createSignIzvPol(function (selectedCertificate, selectedPoa, poaConfirmChecked) {
										success(selectedCertificate, selectedPoa, poaConfirmChecked);
									}, document);
							  }
							: null,
					methodAddOptions: {
						process: processData,
						maxInputLength: 255
					}
				},
				ANNUL: {
					methodAddOptions: Ext.merge(annulDoc || {}, { options: { maxInputLength: 255 } })
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Create field maps visibility by document type
	 */
	const createMaps = function () {
		let i,
			type,
			hideContractBaseFields = {};

		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02] = true; // EW override
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP] = true; // EW override
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_NDA] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_CURRENCY_CONTROL] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_INVOICE] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT] = true;
		hideContractBaseFields[edi.constants.DOCUMENT_TYPES.DSF_PRICAT] = true;

		for (i = 0; i < edi.constants.FREE_DOCUMENT_TYPES.length; i++) {
			type = edi.constants.FREE_DOCUMENT_TYPES[i];
			fieldsmap[type] = {
				sender: true,
				receiver: true
			};
			if (!hideContractBaseFields[type]) {
				fieldsmap[type].baseData = true;
				fieldsmap[type].summ = true;
			}
			if (
				type === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE ||
				type === edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP02 || // EW override
				type === edi.constants.DOCUMENT_TYPES.DSF_ONBOARDING_FP || // EW override
				type === edi.constants.DOCUMENT_TYPES.DSF_INVOICE
			) {
				fieldsmap[type].orderDate = true;
				fieldsmap[type].orderNumber = true;
				fieldsmap[type].paymentInfo = true;
				fieldsmap[type].contractData = true;
				fieldsmap[type].promo = true;

				if (type === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE) {
					fieldsmap[type].ship = true;
					fieldsmap[type].consignee = true;
				}
			} else if (
				type === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT ||
				type === edi.constants.DOCUMENT_TYPES.DSF_AKTPRM
			) {
				fieldsmap[type].performDateCmp = true;
			} else if (
				type === edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT ||
				type === edi.constants.DOCUMENT_TYPES.DSF_SUPAGREEMENT_ANNUL
			) {
				fieldsmap[type].contractData = true;
				fieldsmap[type].promo = true;
			}
			if (edi.constants.DSF_TYPES_FROV.some((it) => it === type)) {
				fieldsmap[type].commentText = true;
			} else {
				fieldsmap[type].comment = true;
				fieldsmap[type].receiverSignatureField = true;
			}
			if (type === edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT) {
				fieldsmap[type].boxState = true;
				fieldsmap[type].despatchReferenceNumber = true;
				fieldsmap[type].utdNumber = true;
				fieldsmap[type].orderNumber = true;
				fieldsmap[type].deliverPoint = true;
				fieldsmap[type].shipFrom = true;
				fieldsmap[type].inspectionLocation = true;
				fieldsmap[type].inspectionLocationAddress = true;
				fieldsmap[type].vehicleNumber = true;
				fieldsmap[type].sender_dsf_recadv_diff_act = true;
				fieldsmap[type].receiver_dsf_recadv_diff_act = true;
				fieldsmap[type].sender = false;
				fieldsmap[type].receiver = false;
				fieldsmap[type].comment = false;
				fieldsmap[type].receiverSignatureField = false;
			}
			if (type === edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT) {
				fieldsmap[type].comment = false;
				fieldsmap[type].commentText = false;
				fieldsmap[type].receiverSignatureField = false;
			}
		}
	};
	/**
	 * Hides/shows controls according to selected DSF type
	 */
	const showHideControls = function () {
		let map = fieldsmap[doctype],
			i;
		for (i in fields) {
			if (fields.hasOwnProperty(i)) {
				switchControl(fields[i], !map[i]);
			}
		}
	};

	/**
	 * Helper function for hiding controls
	 * @param    {Object}    control    control to process
	 * @param    {Boolean}   hide       true to hide
	 */
	const switchControl = function (control, hide) {
		hide = !!hide;
		if (control && 'function' == typeof control.setVisible) {
			control.setVisible(!hide);
		}
	};
	/**
	 * Returns controls prefixes map
	 * @param    {String}    doctype    document type for witch we calculate prefixes
	 */
	const getPrefixes = function (doctype) {
		let map;
		switch (doctype) {
			case edi.constants.DOCUMENT_TYPES.DSF_EDI_KC2:
			case edi.constants.DOCUMENT_TYPES.DSF_EDI_KC3:
			case edi.constants.DOCUMENT_TYPES.DSF_EDI_KC11:
			case edi.constants.DOCUMENT_TYPES.DSF_EDI_KC14:
			case edi.constants.DOCUMENT_TYPES.DSF_AKTPRM:
			case edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT: {
				map = {
					sender: 'performer',
					receiver: 'customer'
				};
				break;
			}
			case edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE: {
				map = {
					sender: 'sender',
					receiver: 'receiver',
					ship: 'supplier',
					consignee: 'payer'
				};
				break;
			}
			default: {
				map = {
					sender: 'sender',
					receiver: 'receiver'
				};
				break;
			}
		}
		return map;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};

	var showIzvPolSignModal = function (document) {
		izvPolSignModal = edi.core.confirm('dsf.need.sign.izv.pol.title', 'dsf.need.sign.izv.pol.text', function () {
			createSignIzvPol(function () {
				edi.events.documents.fireEvent('change', {
					id: document.id
				});
			}, document);
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		const data = moduleData.initData.data;
		const modulePanel = createDetailsModulePanel();

		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const continueWithRendering = function (documentData) {
			contract = null;

			let senderDocumentFields = documentData.senderDocumentFields,
				senderSubdivision = '',
				receiverSubdivision = '';

			if (senderDocumentFields?.length) {
				senderDocumentFields.forEach(function (item) {
					switch (item.code) {
						case 'senderSubdivision':
							senderSubdivision = item.value;
							break;
						case 'receiverSubdivision':
							receiverSubdivision = item.value;
							break;
					}
				});
			}

			documentData.senderDocumentFields =
				edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_ACT === documentData.type ||
				edi.constants.DOCUMENT_TYPES.DSF_AKTPRM === documentData.type
					? {
							performer: {
								structural_subdivision: senderSubdivision
							},
							customer: {
								structural_subdivision: receiverSubdivision
							}
					  }
					: {
							sender: {
								structural_subdivision: senderSubdivision
							},
							receiver: {
								structural_subdivision: receiverSubdivision
							}
					  };
			const businessState = edi.utils.getObjectProperty(data, 'businessState');
			const noSomeActions = dsfModuleMethods.isEwDocWithoutSomeActions(documentData.type);
			var docHeaderConfig = {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				showAnnulLog: true,
				isClarify: false,
				noLog: noSomeActions,
				noUsage: noSomeActions
			};
			if (data.type === edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT) {
				Ext.merge(docHeaderConfig, {
					noModify: true,
					noSumm: true,
					noSignatures: true,
					noUsage: true,
					noPackage: true,
					noAperakStatus: true
				});
			}
			if (data.type === edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT) {
				Ext.merge(docHeaderConfig, {
					noUsage: true
				});
			}

			const finishLoad = function () {
				if (moduleData.tab.isDestroyed) {
					return;
				}
				const data = moduleData.initData.data;
				doctype = data.type;
				const documentDirection = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg),
					prefixes = getPrefixes(doctype);

				receiverPrefix = prefixes['receiver'];
				senderPrefix = prefixes['sender'];

				if (documentData?.reference) {
					documentData[senderPrefix].address.addr_category = documentData?.reference?.addressTypeSender;
					documentData[receiverPrefix].address.addr_category = documentData?.reference?.addressTypeReceiver;
					if (documentData.type === edi.constants.DOCUMENT_TYPES.DSF_EDI_FNS_INVOICE) {
						documentData[prefixes['ship']].address.addr_category = documentData?.reference?.addressTypeShip;
						documentData[prefixes['consignee']].address.addr_category =
							documentData?.reference?.addressTypeConsignee;
					}
				}

				modulePanel.add(createDetailsPanel(documentData, docHeaderConfig));
				showHideControls();
				moduleData.tab.removeAll();
				moduleData.tab.add(createModuleActionsPanel(documentData));
				moduleData.tab.add(modulePanel);

				const showNeedSignIzvPol =
					(data.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER &&
						edi.constants.DIRECTIONS.INCOMING === documentDirection &&
						edi.utils.getAttributeByName(data.attributes, 'RECEIVER_SIGNATURE_EXPECTED') === 'false') ||
					(data.state === edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL &&
						edi.constants.DIRECTIONS.INCOMING === documentDirection) ||
					(data.state === edi.constants.STATE.DSF_DP_IZVPOL_SENDER &&
						edi.constants.DIRECTIONS.OUTGOING === documentDirection);

				const isBlocked =
					doctype === edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED &&
					edi.utils.getAttributeByName(data.attributes, 'isBlocked', undefined, true);

				if (!isBlocked) {
					if (
						annulDoc &&
						annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
						annulDoc.toOrg.id == edi.core.getUserOrgID()
					) {
						edi.methods.documents.showActiveAnnulModal(annulDoc, moduleData);
					} else {
						if (showNeedSignIzvPol && noticeDoc) {
							showIzvPolSignModal(data);
						} else if (izvPolSignModal && !izvPolSignModal.isDestroyed) {
							izvPolSignModal.close();
						}
					}
				}

				if (data.toOrg.id && data.fromOrg.id) {
					edi.methods.custom_fields.initCustomFields({
						docType: doctype,
						toOrgId: data.toOrg.id,
						fromOrgId: data.fromOrg.id,
						docId: documentData.header,
						container: customFieldsContainer,
						topPath: topPath,
						readOnly: true
					});
				}

				if ('function' == typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}
			};
			annulDoc = null;
			const utochReasonText = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'UTOCH_REASON'),
				hasUnfinishedAnnul =
					businessState === edi.constants.STATE.ON_ANNUL || businessState === edi.constants.STATE.ANNUL_SIGN,
				hasIzvPol =
					data.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER ||
					data.state === edi.constants.STATE.DSF_DP_IZVPOL_SENDER ||
					data.state === edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL;

			if (utochReasonText || hasUnfinishedAnnul || hasIzvPol) {
				edi.rest.sendRequest(
					edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
						depth: edi.constants.DEFAULT.TREE_DEPTH
					}),
					'GET',
					{},
					function (responseData) {
						if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
							const linkedDocs = responseData.items || [];
							const annulActiveStates = [
								edi.constants.STATE.WAIT_PARTNER_DECISION,
								edi.constants.STATE.DRAFT
							];
							linkedDocs.forEach((doc) => {
								if (
									doc.type === edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_PRANNUL &&
									annulActiveStates.some((it) => it === doc.state) &&
									!doc.deleted
								) {
									annulDoc = doc;
								} else if (doc.type === edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_UVUTOCH) {
									utochDoc = doc;
								} else if (
									doc.type === edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_IZVPOL ||
									doc.type === edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL
								) {
									if (
										data.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER ||
										data.state === edi.constants.STATE.DSF_UNFORMALIZED_STATUS_4_DP_IZVPOL ||
										(data.state === edi.constants.STATE.DSF_DP_IZVPOL_SENDER &&
											doc.fromOrg.id == edi.core.getUserOrgID())
									) {
										noticeDoc = doc;
									}
								}
							});
						}
						finishLoad();
					},
					failure
				);
			} else {
				finishLoad();
			}
		};

		const url = edi.document.actions.formatHeaderUri(moduleData.initData);
		edi.rest.sendRequest(
			url,
			'GET',
			undefined,
			function (data) {
				if (data && data.data) {
					moduleData.initData.data = data.data;
				}
			},
			failure,
			function () {
				edi.rest.sendRequest(
					edi.document.actions.formatDetailsUri(moduleData.initData),
					'GET',
					{},
					function (data) {
						if (data && data.data) {
							const documentData = data.data;
							continueWithRendering(documentData);
						} else {
							failure(data);
						}
					},
					failure
				);
			}
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
