/**
 * @author Anatoly Deryshev
 * Shared stores configuration for edi
 */
Ext.namespace('edi.stores');
new (function () {
	var onBeforeInit = edi.stores.onBeforeInit;
	edi.stores.onBeforeInit = function () {
		'function' == typeof onBeforeInit ? onBeforeInit() : null;
		Object.assign(edi.stores.data, {
			quick_answer_settings: [],
			fnsTaxRates: [
				{
					//0 | 10 | 18 | 20 | 10/110 | 18/118 | 20/120 | без НДС
					id: '0',
					name: '0'
				},
				{
					id: '10',
					name: '10'
				},
				{
					id: '18',
					name: '18'
				},
				{
					id: '20',
					name: '20'
				},
				{
					id: '10/110',
					name: '10/110'
				},
				{
					id: '18/118',
					name: '18/118'
				},
				{
					id: '20/120',
					name: '20/120'
				},
				{
					id: edi.constants.taxRates.taxNoVat,
					name: edi.i18n.getMessage('tax.no.vat')
				}
			],
			fnsNTaxRates: [
				{
					//0 | 10 | 18 | 20 | 10/110 | 18/118 | 20/120 | без НДС
					id: '0',
					name: '0'
				},
				{
					id: '10',
					name: '10'
				},
				{
					id: '18',
					name: '18'
				},
				{
					id: '20',
					name: '20'
				},
				{
					id: '10/110',
					name: '10/110'
				},
				{
					id: '18/118',
					name: '18/118'
				},
				{
					id: '20/120',
					name: '20/120'
				},
				{
					id: edi.constants.taxRates.taxNoVat,
					name: edi.i18n.getMessage('tax.no.vat')
				},
				{
					id: edi.constants.taxRates.taxVatByTaxAgent,
					name: edi.i18n.getMessage('tax.vat.by.tax.agent')
				}
			],
			vat: [
				{
					vat: ''
				},
				{
					vat: '0',
					value: '0'
				},
				{
					vat: '9',
					value: '0.09'
				},
				{
					vat: '10',
					value: '0.1'
				},
				{
					vat: '18',
					value: '0.18'
				},
				{
					vat: '20',
					value: '0.2'
				}
			],
			itemTypes: [
				{
					id: '',
					name: edi.i18n.getMessage('form.combo.not.selected')
				},
				{
					id: 'CU',
					name: edi.i18n.getMessage('item.type.commercial.unit')
				},
				{
					id: 'RC',
					name: edi.i18n.getMessage('item.type.return.package')
				}
			],
			termsOfDelivery: [
				{
					id: null,
					name: edi.i18n.getMessage('form.combo.not.selected')
				},
				{
					id: 'FCA',
					name: edi.i18n.getMessage('terms.of.delivery.FCA')
				},
				{
					id: 'CPT',
					name: edi.i18n.getMessage('terms.of.delivery.CPT')
				},
				{
					id: 'JIT',
					name: edi.i18n.getMessage('terms.of.delivery.JIT')
				},
				{
					id: 'EXP',
					name: edi.i18n.getMessage('terms.of.delivery.EXP')
				},
				{
					id: 'DAP',
					name: edi.i18n.getMessage('terms.of.delivery.DAP')
				}
			],
			itemStatusCode: [
				{
					id: '',
					name: edi.i18n.getMessage('form.combo.not.selected')
				},
				{
					id: '39',
					name: edi.i18n.getMessage('item.status.code.39')
				}
			],
			transportMode: [
				{
					id: 0,
					name: edi.i18n.getMessage('form.combo.not.selected')
				},
				{
					id: 10,
					name: edi.i18n.getMessage('transport.mode.sea')
				},
				{
					id: 20,
					name: edi.i18n.getMessage('transport.mode.train')
				},
				{
					id: 30,
					name: edi.i18n.getMessage('transport.mode.car')
				},
				{
					id: 40,
					name: edi.i18n.getMessage('transport.mode.aero')
				},
				{
					id: 60,
					name: edi.i18n.getMessage('transport.mode.combined')
				}
			],
			categories: [
				{
					id: 'CONTRACTOR_WORKS',
					name: edi.i18n.getMessage('category.contractor.works')
				},
				{
					id: 'CONSUMER_EXPENSES',
					name: edi.i18n.getMessage('category.consumer.expenses')
				}
			],
			taxRates: [
				{
					id: '0',
					name: '0%'
				},
				{
					id: '9',
					name: '9%'
				},
				{
					id: '10',
					name: '10%'
				},
				{
					id: '18',
					name: '18%'
				},
				{
					id: '20',
					name: '20%'
				}
			],
			formalizedDocumentSubTypes: {},
			dsfClientDocumentSubTypes: {},
			fractTaxRates: [
				{
					id: '10/110',
					name: '10/110'
				},
				{
					id: '18/118',
					name: '18/118'
				},
				{
					id: '20/120',
					name: '20/120'
				}
			],
			dbAttachDocsFields: {
				inn: 'document.package.agreement.textInfos.key.inn',
				kpp: 'document.package.agreement.textInfos.key.kpp',
				urAddress: 'document.package.agreement.textInfos.key.urAddress',
				email: 'document.package.agreement.textInfos.key.email',
				email2: 'document.package.agreement.textInfos.key.email2',
				emailSeller: 'document.package.agreement.textInfos.key.emailSeller',
				phone: 'document.package.agreement.textInfos.key.phone',
				bik: 'document.package.agreement.textInfos.key.bik',
				address: 'document.package.agreement.textInfos.key.address',
				otsrochka: 'document.package.agreement.textInfos.key.otsrochka',
				addConds: 'document.package.agreement.textInfos.key.addConds',
				okpo: 'document.package.agreement.textInfos.key.okpo',
				okonh: 'document.package.agreement.textInfos.key.okonh',
				okved: 'document.package.agreement.textInfos.key.okved',
				schet: 'document.package.agreement.textInfos.key.schet',
				bankName: 'document.package.agreement.textInfos.key.bankName',
				CorSchet: 'document.package.agreement.textInfos.key.CorSchet',
				fioAccounter: 'document.package.agreement.textInfos.key.fioAccounter',
				emailSRM1: 'document.package.agreement.textInfos.key.emailSRM1',
				emailSRM2: 'document.package.agreement.textInfos.key.emailSRM2',
				byerNds: 'document.package.agreement.textInfos.key.byerNds',
				addressEntrance: 'document.package.agreement.textInfos.key.addressEntrance',
				addressReturn: 'document.package.agreement.textInfos.key.addressReturn',
				amountProcurements: 'document.package.agreement.textInfos.key.amountProcurements',
				percentBonusRC: 'document.package.agreement.textInfos.key.percentBonusRC',
				percentBonusGM: 'document.package.agreement.textInfos.key.percentBonusGM',
				percentBonusMM: 'document.package.agreement.textInfos.key.percentBonusMM',
				amountPurchaseProduct: 'document.package.agreement.textInfos.key.amountPurchaseProduct',
				kppAffiliate: 'document.package.agreement.textInfos.key.kppAffiliate',
				glnAffiliate: 'document.package.agreement.textInfos.key.glnAffiliate',
				gln: 'document.package.agreement.textInfos.key.gln',
				positionCode: 'document.package.agreement.textInfos.key.positionCode',
				productionCode: 'document.package.agreement.textInfos.key.productionCode',
				productionName: 'document.package.agreement.textInfos.key.productionName',
				productName: 'document.package.agreement.textInfos.key.productName',
				trademark: 'document.package.agreement.textInfos.key.trademark',
				tax: 'document.package.agreement.textInfos.key.tax',
				unitOfMeasureMeter: 'document.package.agreement.textInfos.key.unitOfMeasureMeter',
				itemWeight: 'document.package.agreement.textInfos.key.itemWeight',
				weightOfUnit: 'document.package.agreement.textInfos.key.weightOfUnit',
				vendorCode: 'document.package.agreement.textInfos.key.vendorCode',
				manufacturer: 'document.package.agreement.textInfos.key.manufacturer',
				countryManufacturer: 'Страна_производитель',
				unitOfMeasure: 'document.package.agreement.textInfos.key.unitOfMeasure',
				batch: 'document.package.agreement.textInfos.key.batch',
				shelfLife: 'document.package.agreement.textInfos.key.shelfLife',
				things: 'document.package.agreement.textInfos.key.things',
				boxes: 'document.package.agreement.textInfos.key.boxes',
				block: 'document.package.agreement.textInfos.key.block',
				storageTemperature: 'document.package.agreement.textInfos.key.storageTemperature',
				bulk: 'document.package.agreement.textInfos.key.bulk',
				props: 'document.package.agreement.textInfos.key.props',
				ulName: 'document.package.agreement.textInfos.key.ulName',
				ipFio: 'document.package.agreement.textInfos.key.ipFio',
				delay: 'document.package.agreement.textInfos.key.delay',
				buyerNds: 'document.package.agreement.textInfos.key.buyerNds',
				fio: 'document.package.agreement.textInfos.key.fio',
				linkDocNumber: 'document.package.agreement.textInfos.key.linkDocNumber',
				linkDocDate: 'document.package.agreement.textInfos.key.linkDocDate',
				signerPosition: 'document.package.agreement.textInfos.key.signerPosition',
				signerFio: 'document.package.agreement.textInfos.key.signerFio',
				signerBase: 'document.package.agreement.textInfos.key.signerBase',
				daysCount1: 'document.package.agreement.textInfos.key.daysCount1',
				daysCount2: 'document.package.agreement.textInfos.key.daysCount2',
				daysCount3: 'document.package.agreement.textInfos.key.daysCount3',
				daysCount4: 'document.package.agreement.textInfos.key.daysCount4',
				daysCount5: 'document.package.agreement.textInfos.key.daysCount5',
				daysCount6: 'document.package.agreement.textInfos.key.daysCount6',
				daysCount7: 'document.package.agreement.textInfos.key.daysCount7',
				daysCount8: 'document.package.agreement.textInfos.key.daysCount8',
				daysCount9: 'document.package.agreement.textInfos.key.daysCount9',
				itemNumber: 'document.package.agreement.textInfos.key.itemNumber',
				returnType: 'document.package.agreement.textInfos.key.returnType',
				hours: 'document.package.agreement.textInfos.key.hours',
				reviseEmail1: 'document.package.agreement.textInfos.key.reviseEmail1',
				city: 'document.package.agreement.textInfos.key.city',
				reviseEmail2: 'document.package.agreement.textInfos.key.reviseEmail2',
				okato: 'document.package.agreement.textInfos.key.okato',
				ogrn: 'document.package.agreement.textInfos.key.ogrn',
				price: 'document.package.agreement.textInfos.key.price',
				productOfName: 'document.package.agreement.textInfos.key.productOfName',
				premium: 'document.package.agreement.textInfos.key.premium',
				productOfCode: 'document.package.agreement.textInfos.key.productOfCode',
				serialNumber: 'document.package.agreement.textInfos.key.serialNumber',
				date: 'document.package.agreement.textInfos.key.date',
				commodityItems: 'document.package.agreement.textInfos.key.commodityItems',
				unitsCommodityItems: 'document.package.agreement.textInfos.key.unitsCommodityItems',
				oneTimePremium: 'document.package.agreement.textInfos.key.oneTimePremium',
				amountDays: 'document.package.agreement.textInfos.key.amountDays',
				amountMonth: 'document.package.agreement.textInfos.key.amountMonth',
				nameSeller: 'document.package.agreement.textInfos.key.nameSeller',
				taxRate: 'document.package.agreement.textInfos.key.taxRate',
				fioDirector: 'document.package.agreement.textInfos.key.fioDirector',
				deliverPoint: 'document.package.agreement.textInfos.key.deliverPoint'
			},
			docTypesTree: {
				EDI: {
					order: 100,
					items: [
						edi.constants.DOCUMENT_TYPES.LEGACY_ORDER,
						edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
						edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV,
						edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
						edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
						edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
						edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT,
						edi.constants.DOCUMENT_TYPES.EDI_INVRPT
					]
				},
				DSF: {
					order: 200,
					items: [
						edi.constants.DOCUMENT_TYPES.DSF_UNKNOWN,
						edi.constants.DOCUMENT_TYPES.DSF_UNFORMALIZED,
						edi.constants.DOCUMENT_TYPES.DSF_AGREEMENT,
						edi.constants.DOCUMENT_TYPES.DSF_SUPPLEMENTARY_AGREEMENT,
						edi.constants.DOCUMENT_TYPES.DSF_INVOICE,
						edi.constants.DOCUMENT_TYPES.DSF_FROV_DFCT_RPT,
						edi.constants.DOCUMENT_TYPES.DSF_FROV_FINE_ACT,
						edi.constants.DOCUMENT_TYPES.DSF_FROV_SCHET,
						edi.constants.DOCUMENT_TYPES.DSF_FROV_SGL_SO_ACT,
						edi.constants.DOCUMENT_TYPES.DSF_FROV_SETOFF_ACT,
						edi.constants.DOCUMENT_TYPES.DSF_RECADV_DIFF_ACT,
						edi.constants.DOCUMENT_TYPES.DSF_ACT_DIFFRNT,
						edi.constants.DOCUMENT_TYPES.DSF_PRICAT,
						edi.constants.DOCUMENT_TYPES.DSF_REESTR_ROUTE
					]
				},
				IFT: {
					order: 300,
					items: [edi.constants.DOCUMENT_TYPES.EDI_IFTMBF, edi.constants.DOCUMENT_TYPES.EDI_IFTMBC]
				},
				PACKAGES: {
					order: 400,
					items: [
						edi.constants.DOCUMENT_TYPES.DP_CONTAINER,
						edi.constants.DOCUMENT_TYPES.DP_CONTAINER_NE,
						edi.constants.DOCUMENT_TYPES.DP_CONTAINER_DR,
						edi.constants.DOCUMENT_TYPES.ON_SERVICE_CONTAINER
					]
				},
				COMMON_PRICAT: {
					order: 500,
					items: [
						edi.constants.DOCUMENT_TYPES.PRICAT_APPROVAL,
						edi.constants.DOCUMENT_TYPES.PRICAT_SIGN_APPROVAL,
						edi.constants.DOCUMENT_TYPES.PRICAT_SIMPLE,
						edi.constants.DOCUMENT_TYPES.PRICAT_WITH_SIGN
					]
				},
				OTHER: {
					order: 500,
					items: [
						edi.constants.DOCUMENT_TYPES.FROV_SIG_SO_ACT2_FROM_X5,
						edi.constants.DOCUMENT_TYPES.OVZ_FROM_X5
					]
				}
			},
			documentLogsetPostTime: [
				// @formatter:off
				{ id: '0600', name: '6:00' },
				{ id: '0700', name: '7:00' },
				{ id: '0730', name: '7:30' },
				{ id: '0800', name: '8:00' },
				{ id: '0830', name: '8:30' },
				{ id: '0900', name: '9:00' },
				{ id: '0930', name: '9:30' },
				{ id: '0945', name: '9:45' },
				{ id: '1000', name: '10:00' },
				{ id: '1030', name: '10:30' },
				{ id: '1045', name: '10:45' },
				{ id: '1100', name: '11:00' },
				{ id: '1130', name: '11:30' },
				{ id: '1145', name: '11:45' },
				{ id: '1200', name: '12:00' },
				{ id: '1230', name: '12:30' },
				{ id: '1300', name: '13:00' },
				{ id: '1330', name: '13:30' },
				{ id: '1340', name: '13:40' },
				{ id: '1400', name: '14:00' },
				{ id: '1430', name: '14:30' },
				{ id: '1500', name: '15:00' },
				{ id: '1530', name: '15:30' },
				{ id: '1600', name: '16:00' },
				{ id: '1630', name: '16:30' },
				{ id: '1700', name: '17:00' },
				{ id: '1800', name: '18:00' },
				{ id: '1900', name: '19:00' },
				{ id: '2000', name: '20:00' },
				{ id: '2030', name: '20:30' },
				{ id: '2100', name: '21:00' },
				{ id: '2359', name: '23:59' }
			] // @formatter:on
		});
	};
	Object.assign(edi.stores.dataLoaders, {
		quick_answer_settings: function (callback) {
			if (edi.permissions.hasPermission('CLIENT_GET_QUICK_ANSWERS') && !edi.constants.SKIP_QUICK_ANSWER) {
				var success = function (data) {
					edi.stores.data.quick_answer_settings = data.items;
					edi.core.logMessage('Quick answer settings loaded', 'info');
				};
				var failure = function () {
					edi.core.handleException('Quick answer settings did not loaded properly');
				};
				edi.rest.sendRequest(
					edi.rest.services.QUICK_ANSWER.CONFIG.GET,
					'GET',
					null,
					success,
					failure,
					callback
				);
			} else {
				callback();
			}
		},
		okv_currencies: function (callback) {
			var success = function (data) {
				var lang =
					edi.utils.getObjectProperty(edi.core.getUserData(), 'language', false) ||
					edi.constants.DEFAULT.LANGUAGE;
				lang = edi.constants.LANGUAGES[lang].CODE;
				var okv_currencies = [],
					i;
				for (i = 0; i < data.items.length; i++) {
					var cur = data.items[i];
					okv_currencies.push({
						name: cur['name_' + lang] ? cur['name_' + lang] : cur['name_en'],
						countries: cur['countries_' + lang] ? cur['countries_' + lang] : cur['countries_en'],
						charCode: cur.charCode,
						code: cur.code,
						codeStr: cur.code
					});
				}
				edi.stores.data.okv_currencies = okv_currencies;
				var currencies = [];
				for (i = 0; i < data.items.length; i++) {
					currencies.push({
						id: data.items[i].charCode,
						name:
							data.items[i].charCode +
							' - ' +
							(data.items[i]['name_' + lang] ? data.items[i]['name_' + lang] : data.items[i]['name_en'])
					});
				}
				edi.stores.data.currencies = currencies;
				edi.core.logMessage('Currencies loaded', 'info');
			};
			var failure = function () {
				edi.core.handleException('OKV currencies did not loaded properly');
			};
			edi.rest.sendRequest(edi.rest.services.OKV_CURRENCIES.GET, 'GET', null, success, failure, callback);
		},
		okei_codes: function (callback) {
			var success = function (data) {
				edi.stores.data.okei_codes = data.items;
				edi.core.logMessage('Okei codes loaded', 'info');
			};
			var failure = function () {
				edi.core.handleException('Okei codes did not loaded properly');
			};
			edi.rest.sendRequest(edi.rest.services.OKEI_CODES.GET, 'GET', null, success, failure, callback);
		},
		delforCalendarCodes: function (callback) {
			var success = function (data) {
				edi.stores.data.delforCalendarCodes = data.items;
				edi.core.logMessage('Delfor Calendar codes loaded', 'info');
			};
			var failure = function () {
				edi.core.handleException('Delfor Calendar codes did not loaded properly');
			};
			edi.rest.sendRequest(edi.rest.services.DELFOR_CALENDAR_CODES.GET, 'GET', null, success, failure, callback);
		},
		weightOfOrder: function (callback) {
			var success = function (data) {
				edi.stores.data.weightOfOrder = data.items;
				edi.core.logMessage('weightOfOrder loaded', 'info');
			};
			var failure = function () {
				edi.core.handleException('weightOfOrder did not loaded properly');
			};
			edi.rest.sendRequest(edi.rest.services.WEIGHT_OF_ORDER.GET, 'GET', null, success, failure, callback);
		},
		modesOfTransport: function (callback) {
			var success = function (data) {
				edi.stores.data.modesOfTransport = data.items;
				edi.core.logMessage('Modes of transport loaded', 'info');
			};
			var failure = function () {
				edi.core.handleException('Modes of transport did not loaded properly');
			};
			edi.rest.sendRequest(edi.rest.services.MODE_OF_TRANSPORT.GET, 'GET', null, success, failure, callback);
		},
		packagingUnit: function (callback) {
			var success = function (data) {
				edi.stores.data.packagingUnit = data.items;
				edi.core.logMessage('PackagingUnit loaded', 'info');
			};
			var failure = function () {
				edi.core.handleException('PackagingUnit did not loaded properly');
			};
			edi.rest.sendRequest(edi.rest.services.PACKAGING_UNIT.GET, 'GET', null, success, failure, callback);
		},
		docsWithSubtype: function (callback) {
			if (edi.permissions.hasPermission('CLIENT_READ_DOCUMENT_SUBTYPE')) {
				let success = function (resp) {
					let subTypes = Array.isArray(resp.items) ? resp.items : [];
					let formalizedItems = [];
					let dsfClientItems = [];
					subTypes.forEach((subType) => {
						if (subType.doctype === edi.constants.DOCUMENT_TYPES.FORMALIZED_DOCUMENT) {
							formalizedItems.push({
								name:
									edi.i18n.getMessage('documents.subdoctype.' + subType.subtype) +
									' ' +
									edi.i18n.getMessage('document.formalized.abbr'),
								id: subType.id
							});
							edi.stores.data.formalizedDocumentSubTypes[subType.id] = subType.subtype;
						} else if (subType.doctype === edi.constants.DOCUMENT_TYPES.DSF_CLIENT) {
							dsfClientItems.push({
								name:
									edi.i18n.getMessage('documents.subdoctype.' + subType.subtype) +
									' ' +
									edi.i18n.getMessage('document.dsf_client.abbr'),
								id: subType.id
							});
							edi.stores.data.dsfClientDocumentSubTypes[subType.id] = subType.subtype;
						}
					});

					if (formalizedItems.length > 0) {
						Ext.merge(edi.stores.data.docTypesTree, {
							FORMALIZED: {
								order: 600,
								items: formalizedItems
							}
						});
					}
					if (dsfClientItems.length > 0) {
						Ext.merge(edi.stores.data.docTypesTree, {
							DSF_CLIENT: {
								order: 601,
								items: dsfClientItems
							}
						});
					}
				};
				let failure = function () {
					edi.core.handleException('Subtypes for Formalized documents did not loaded properly');
				};
				edi.rest.sendRequest(
					edi.rest.services.DOCUMENTS.SUB_DOC_TYPES.GET,
					'GET',
					null,
					success,
					failure,
					callback
				);
			} else {
				callback();
			}
		}
	});
	edi.stores.createInitStoreMethods([
		{
			methodName: 'initQuickAnswerSettingsStore',
			storeName: 'quickAnswerSettingsStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(
					edi.stores.data.quick_answer_settings,
					'QUICK_ANSWER_SETTINGS',
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initPositionsStore',
			storeName: 'positionsStore',
			constructor: function (onload) {
				var data = edi.core.getPositions();
				var lines = [];
				if (data && data.length) {
					for (var i = 0; i < data.length; i++) {
						lines.push({
							id: data[i].permanentPosition.name,
							name: data[i].permanentPosition.name,
							shortName: data[i].permanentPosition.shortName
						});
					}
				}
				return edi.stores.createInlineStore(lines, 'POSITION_SHORT_NAME', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initCategoriesStore',
			storeName: 'categoriesStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.categories, 'SIMPLE', onload);
			}
		},
		{
			methodName: 'initVatStore',
			storeName: 'vatStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.vat, 'VAT', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initModeOfTransportStore',
			storeName: 'modeOfTransportStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.transportMode, 'SIMPLE', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initCurrencyStore',
			storeName: 'currencyStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.currencies, 'SIMPLE', onload, {
					addEmptyRecord: true
				});
			},
			autoInit: true
		},
		{
			methodName: 'initOkvCurrencyStore',
			storeName: 'okvCurrencyStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.okv_currencies, 'OKV_CURRENCY', onload, {
					addEmptyRecord: true
				});
			},
			autoInit: true
		},
		{
			methodName: 'initFNSTaxRateStore',
			storeName: 'fnsTaxStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.fnsTaxRates, 'SIMPLE', onload, {
					addEmptyRecord: true
				});
			},
			autoInit: true
		},
		{
			methodName: 'initFNSnTaxRateStore',
			storeName: 'fnsNTaxStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.fnsNTaxRates, 'SIMPLE', onload, {
					addEmptyRecord: true
				});
			},
			autoInit: true
		},
		{
			methodName: 'initValidCountryFullStore',
			storeName: 'countryValidFullStore',
			constructor: function (onload) {
				var validCountries = [];
				for (var i = 0; i < edi.stores.data.countries_full.length; i++) {
					var country = edi.stores.data.countries_full[i];
					if (String(country.iso_number_3).length == 3) {
						validCountries.push(country);
					}
				}
				return edi.stores.createInlineStore(validCountries, 'COUNTRY', onload, {
					addEmptyRecord: true
				});
			},
			autoInit: true
		},
		{
			methodName: 'initItemTypeStore',
			storeName: 'itemTypeStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.itemTypes, 'SIMPLE', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initItemStatusCodeStore',
			storeName: 'initItemStatusCode',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.itemStatusCode, 'SIMPLE', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initTermsOfDeliveryStore',
			storeName: 'initTermsOfDelivery',
			constructor: function (onload) {
				const store = edi.stores.createInlineStore(edi.stores.data.termsOfDelivery, 'SIMPLE', onload);
				return store;
			},
			autoInit: true
		},
		{
			methodName: 'initDelforCalendarCodeStore',
			storeName: 'initDelforCalendarCode',
			constructor: function (onload) {
				return edi.stores.createInlineStore(
					edi.stores.data.delforCalendarCodes,
					'DOCUMENT_DELFOR_CALENDAR',
					onload,
					{
						addObjEmptyRecord: {
							Delshchedulecode: '',
							Delshchedulename: edi.i18n.getMessage('form.combo.not.selected')
						}
					}
				);
			},
			autoInit: false
		},
		{
			methodName: 'initOkeiStore',
			storeName: 'okeiTypeStore',
			constructor: function (onload) {
				var validOkei = [];
				for (var i = 0; i < edi.stores.data.okei_codes.length; i++) {
					var okeiItem = edi.stores.data.okei_codes[i];
					if (okeiItem.name) {
						validOkei.push(okeiItem);
					}
				}
				return edi.stores.createInlineStore(validOkei, 'OKEI_CODES', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initOkeiStoreForN',
			storeName: 'okeiTypeStoreForN',
			constructor: function (onload) {
				var validOkei = [];
				validOkei.push({
					id: '0000',
					name: edi.i18n.getMessage('line.item.unit.of.measure.inputManual.manual')
				});
				for (var i = 0; i < edi.stores.data.okei_codes.length; i++) {
					var okeiItem = edi.stores.data.okei_codes[i];
					if (okeiItem.name) {
						validOkei.push(okeiItem);
					}
				}
				return edi.stores.createInlineStore(validOkei, 'OKEI_CODES', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initWeightOfOrder',
			storeName: 'weightOfOrderStore',
			constructor: function (onload) {
				var valid = [];
				for (var i = 0; i < edi.stores.data.weightOfOrder.length; i++) {
					var item = edi.stores.data.weightOfOrder[i];
					if (item.name) {
						valid.push(item);
					}
				}
				return edi.stores.createInlineStore(valid, null, onload);
			},
			autoInit: true
		},
		{
			methodName: 'initDocumentLogsetPostTimeStore',
			storeName: 'documentLogsetPostTimeStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.documentLogsetPostTime, 'SIMPLE', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initLegacyOkeiStore',
			storeName: 'okeiLegacyTypeStore',
			constructor: function (onload) {
				var validLegacyOkei = [];
				for (var i = 0; i < edi.stores.data.okei_codes.length; i++) {
					var okeiItem = edi.stores.data.okei_codes[i];
					if (okeiItem.name) {
						if (okeiItem.name_international) {
							validLegacyOkei.push(okeiItem);
						} else if (String(okeiItem.name_national_code).length <= 6) {
							validLegacyOkei.push(okeiItem);
						}
					}
				}
				return edi.stores.createInlineStore(validLegacyOkei, 'OKEI_CODES', onload, {
					findRecordByName: function (name) {
						const store = this;
						const num = store.findBy(function (record) {
							const row = record.getData();
							const val = name.toUpperCase();
							return row.name_international.toUpperCase() === val || row.name.toUpperCase() === val;
						});
						return num >= 0 ? store.getAt(num) : null;
					}
				});
			},
			autoInit: true
		},
		{
			methodName: 'initTaxRatesStore',
			storeName: 'taxRatesStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(edi.stores.data.taxRates, 'SIMPLE', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initTaxRatesWithFractStore',
			storeName: 'taxRatesWithFractStore',
			constructor: function (onload) {
				return edi.stores.createInlineStore(
					edi.stores.data.taxRates.concat(edi.stores.data.fractTaxRates),
					'SIMPLE',
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initTaxRatesWithNoVatStore',
			storeName: 'taxRatesStoreWithNoVat',
			constructor: function (onload) {
				return edi.stores.createInlineStore(
					edi.stores.data.taxRates.concat({
						id: edi.constants.taxRates.taxNoVat,
						name: edi.i18n.getMessage('tax.no.vat')
					}),
					'SIMPLE',
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initCompanyTypeStore',
			storeName: 'companyTypesStore',
			constructor: function (onload) {
				return edi.stores.createDisplayingStore(
					['individual', 'company'],
					'COMPANY_TYPE',
					'type',
					function (id) {
						return edi.i18n.getMessage('company.type.' + id);
					},
					null,
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initCompany3TypeStore',
			storeName: 'company3TypesStore',
			constructor: function (onload) {
				return edi.stores.createDisplayingStore(
					['individual', 'company', 'legalForeigner'],
					'COMPANY_TYPE',
					'type',
					function (id) {
						return edi.i18n.getMessage('company.type.' + id);
					},
					null,
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initCompany4TypeStore',
			storeName: 'compan4yTypesStore',
			constructor: function (onload) {
				return edi.stores.createDisplayingStore(
					['individual', 'company', 'legalForeigner', 'individualParticipant'],
					'COMPANY_TYPE',
					'type',
					function (id) {
						return edi.i18n.getMessage('company.type.' + id);
					},
					null,
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initCompany5TypeStore',
			storeName: 'company5TypesStore',
			constructor: function (onload) {
				return edi.stores.createDisplayingStore(
					['individual', 'company', 'legalForeigner', 'companyNotRegistered'],
					'COMPANY_TYPE',
					'type',
					function (id) {
						return edi.i18n.getMessage('company.type.' + id);
					},
					null,
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initAddressTypeStore',
			storeName: 'addressTypesStore',
			constructor: function (onload) {
				return edi.stores.createDisplayingStore(
					[0, 1],
					'ADDRESS_TYPE',
					'type',
					function (id) {
						return edi.i18n.getMessage('company.address.type.' + (id ? 'foreign' : 'rus'));
					},
					null,
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initAddressCategoryStore',
			storeName: 'addressCategoryStore',
			constructor: function (onload) {
				return edi.stores.createSimpleInlineStore(
					[
						edi.constants.ADDRESS_TYPES.LEGAL,
						edi.constants.ADDRESS_TYPES.REAL,
						edi.constants.ADDRESS_TYPES.POSTAL
					],
					function (id) {
						return edi.i18n.getMessage('address.type.' + id);
					}
				);
			},
			autoInit: true
		},
		{
			methodName: 'initNewUPDAddressTypeStore',
			storeName: 'addressTypesStore',
			constructor: function (onload) {
				return edi.stores.createDisplayingStore(
					['0', '1', '2'],
					'ADDRESS_TYPE',
					'type',
					function (id) {
						var types = {
							0: 'rus',
							1: 'foreign',
							2: 'codeStateAddressRegister'
						};
						return edi.i18n.getMessage('company.address.type.' + types[id]);
					},
					null,
					onload
				);
			},
			autoInit: true
		},
		{
			methodName: 'initDpAttachDocBuyerNds',
			storeName: 'dpAttachDocBuyerNds',
			constructor: function () {
				return edi.stores.createSimpleInlineStore(
					[
						edi.i18n.getMessage('document.package.agreement.textInfos.title.buyerNds.value.buyer'),
						edi.i18n.getMessage('document.package.agreement.textInfos.title.buyerNds.value.notBuyer')
					],
					function (id) {
						return id;
					}
				);
			},
			autoInit: true
		},
		{
			methodName: 'initDpAttachDocIsTax',
			storeName: 'dpAttachDocIsTax',
			constructor: function () {
				return edi.stores.createSimpleInlineStore(['with', 'without'], function (id) {
					return edi.i18n.getMessage('document.package.agreement.textInfos.title.isTax.value.' + id);
				});
			},
			autoInit: true
		},
		{
			methodName: 'initDpAttachDocReturnType',
			storeName: 'dpAttachDocReturnType',
			constructor: function () {
				return edi.stores.createSimpleInlineStore(
					[
						edi.i18n.getMessage('document.package.agreement.textInfos.title.returnType.value.returned'),
						edi.i18n.getMessage('document.package.agreement.textInfos.title.returnType.value.notReturned')
					],
					function (id) {
						return id;
					}
				);
			},
			autoInit: true
		},
		{
			methodName: 'initDpAttachDocsFields',
			storeName: 'dpAttachDocsFieldsStore',
			constructor: function (onload) {
				var data = [];
				for (var key in edi.stores.data.dbAttachDocsFields) {
					if (edi.stores.data.dbAttachDocsFields.hasOwnProperty(key)) {
						data.push({
							id: key,
							name: edi.i18n.getMessage(edi.stores.data.dbAttachDocsFields[key])
						});
					}
				}
				return edi.stores.createInlineStore(data, 'SIMPLE', onload);
			},
			autoInit: true
		},
		{
			methodName: 'initPackagingUnit',
			storeName: 'packagingUnitStore',
			constructor: function (onload) {
				var valid = [];
				for (var i = 0; i < edi.stores.data.packagingUnit.length; i++) {
					var item = edi.stores.data.packagingUnit[i];
					if (item.name) {
						valid.push(item);
					}
				}
				return edi.stores.createInlineStore(valid, null, onload);
			},
			autoInit: true
		}
	]);
})();
