import { createProductGridBase } from '@Components/product.grid';
import { createAddModulePanel, createFormForModule } from '@Components/panels';
import { createCreateSaveButton } from '@Components/buttons';
import { createModalPartInCatalogSelectForGrid } from '@Edi/specialComponents/modalPartInCatalogSelect';
import {
	createButtonContainer,
	createContainer,
	createFieldBlock,
	createMaxWidthContainer
} from '@UIkit/components/panels';
import { PRICAT_EANCOM_V2_DOC_FLOW_TYPES } from './configuration';
import {
	createCombo,
	createDateField,
	createLabel,
	createNumberField,
	createTextField
} from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { PRICAT_EANCOM_V2_LINE_COLUMN } from './columns';
import { PRICAT_EANCOM_V2_METHODS } from './methods';
import { createTab, createTabPanel, TAB_PANEL_CLS } from '@UIkit/components/tab';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

Ext.namespace('edi.modules');
edi.modules['document.create.pricateancom_v2'] = function () {
	let moduleData,
		seller,
		buyer,
		parentId,
		docFlowType,
		isEdit = false,
		isBasedOnDoc = false,
		documentData = {},
		receiverId,
		form,
		productsGrid,
		deliveryPlaceGrid,
		exceptionsPlaceGrid,
		documentPurposeValue,
		typePriceNoticeValue,
		buyerFNSid,
		guid,
		sellerFNSid,
		sellerId,
		isNumberAutoGenerated = false,
		buyerOrg,
		doctype = edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM,
		userData = edi.core.getUserData(),
		productValues = {
			products: []
		};
	let isCopy = false;
	let supplierNDSPayerState;
	const taxRateStore = edi.stores.createInlineStore(
		[
			{
				id: '10',
				name: '10'
			},
			{
				id: '20',
				name: '20'
			},
			{
				id: 'null',
				name: edi.i18n.getMessage('tax.no.vat')
			}
		],
		'SIMPLE'
	);
	let isPricatCorrection = false;
	let isPricatCancellation = false;
	const orgConverter = edi.converters.convertPricatPartie;

	const valuesMap = PRICAT_EANCOM_V2_METHODS.getValuesMap();
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docFlowType =
			moduleData.initData.docFlowType ||
			edi.utils.getAttributeByName(moduleData.initData?.data?.attributes, 'doc_flow_type');
		isPricatCorrection = docFlowType === PRICAT_EANCOM_V2_DOC_FLOW_TYPES.CORRECTION;
		isPricatCancellation = docFlowType === PRICAT_EANCOM_V2_DOC_FLOW_TYPES.CANCELLATION;
		parentId = moduleData.initData.parentId ?? null;
		isBasedOnDoc = !!parentId;
		if (moduleData.initData.data?.id) {
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
		}
		if (isPricatCorrection || isPricatCancellation) {
			const title = getDocTitle({ docNumber: moduleData.initData.data?.number });
			moduleData.tab.setTitle(title);
			moduleData.tab.tab.setTooltip(title);
			if (moduleData.initData.meta?.id) {
				// При открытии модуля PRICAT_EANCOM_V2_CORRECTION/CANCELLATION к meta.id добавляется приставка _CORRECTION/CANCELLATION
				// нужно для openModule, чтобы можно было отличить эти модули друг от друга, здесь возвращаем id в нужный формат
				moduleData.initData.meta.id = parseInt(moduleData.initData.meta.id);
			}
		}
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};
	/**
	 * Checks validity, and enables/disables create button
	 */
	const checkValid = function () {
		const isProductsValid = productValues.products.length && productsGrid.isValid(),
			isFormValid = form.isValid(),
			isValidSeller = seller.isValid(),
			isValidBuyer = buyer.isValid();

		return isProductsValid && isFormValid && isValidSeller && isValidBuyer;
	};

	const getDocTitle = function ({ docNumber }) {
		let titleKey = isEdit ? 'document.create.pricateancom_v2.edit' : 'document.create.pricateancom_v2';
		if (isPricatCorrection || isPricatCancellation) {
			titleKey = isEdit ? `documents.doctype.${docFlowType}.edit` : `documents.doctype.${docFlowType}`;
		}

		return isEdit ? edi.i18n.getMessage(titleKey) + docNumber : edi.i18n.getMessage(titleKey);
	};

	const checkBuyer = function () {
		const org = buyer.selectedOrg;
		const orgValues = buyer.getValues();
		const orgId = org ? org.id : null;

		buyerFNSid = isEdit ? buyerFNSid : edi.utils.getObjectProperty(org, 'fnsId');

		if (productsGrid.getPartnerId() != orgId) {
			productsGrid.productsGrid.getStore().removeAll();
		}
		productsGrid.setPartnerId(orgId);

		if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
			buyerOrg = org;
		} else if (!orgId) {
			buyerOrg = null;
		}
		buyer.is_valid = !!orgValues.contact_email;
	};

	const checkProductGridValuesBeforeSave = (values) => {
		let allFieldsAreEmpty = !Object.values(values || {})
			.map((v) => String(v).trim())
			.some((v) => v.length !== 0);
		if (allFieldsAreEmpty === true) {
			edi.core.showError(edi.i18n.getMessage('documents.pricat.price.warning.empty.grid.values'));
			return false;
		}
		return true;
	};

	const createPlaceGrid = ({ values, config }) => {
		return createProductGridBase(
			Object.assign(
				{
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					gridModel: 'PARTIN_DELIVERY_PLACE',
					gridColumnConfig: 'partin_delivery_place',
					data: values,
					lockIfNoPartner: false,
					showSelectProductBtn: false,
					hasTotal: false,
					checkValuesBeforeSave: checkProductGridValuesBeforeSave,
					modalConf: {
						width: MODAL_SIZE.widthSmall
					},
					createModalForm: (recordData) =>
						createContainer({
							padding: '0 24 24 24',
							layout: {
								type: 'grid',
								gap: 24
							},
							items: [
								createFieldBlock({
									title: edi.i18n.getMessage('column.name'),
									items: createTextField({
										name: 'name',
										maxLength: 128,
										valueSrc: recordData,
										requiredFields: ['iln']
									})
								}),
								createFieldBlock({
									title: edi.i18n.getMessage('company.gln.short'),
									items: createTextField({
										maxLength: 100,
										name: 'iln',
										valueSrc: recordData,
										regex: edi.constants.VALIDATORS.GLN,
										requiredFields: ['name']
									})
								})
							]
						}),
					modalFormConfig: {
						title: 'documents.pricate_eancom.storage'
					}
				},
				config
			)
		);
	};

	const createDeliveryPlaceGrid = ({ recordData }) => {
		const deliveryPlaceValues = edi.utils.getObjectProperty(recordData, 'deliveryPlace');
		const selectFromPartinDeliveryPlaceButton = createButton({
			cls: BUTTON_CLS.secondary,
			text: edi.i18n.getMessage('form.btn.select.product'),
			glyph: edi.constants.ICONS.SEARCH,
			margin: '0 0 0 8',
			disabled: !buyerOrg?.id || !sellerId,
			handler: function () {
				let options = {
					modalTitle: edi.i18n.getMessage('documents.pricate_eancom.select.storage')
				};
				let modal = createModalPartInCatalogSelectForGrid(
					buyerOrg.id,
					sellerId,
					function (data) {
						const store = deliveryPlaceGrid.productsGrid.getStore();
						if (-1 === store.find('iln', data.iln)) {
							deliveryPlaceGrid.setGridRow(data);
						}
						const exceptionsStore = exceptionsPlaceGrid.productsGrid.getStore();
						const record = exceptionsStore.findRecord('iln', data.iln);
						if (record) {
							exceptionsStore.remove(record);
							if ('function' == typeof exceptionsPlaceGrid.afterRowRemove) {
								exceptionsPlaceGrid.afterRowRemove(record);
							}
						}
					},
					options
				);
				modal.show();
			}
		});
		return (deliveryPlaceGrid = createPlaceGrid({
			values: deliveryPlaceValues,
			config: {
				addedTools: [selectFromPartinDeliveryPlaceButton]
			}
		}));
	};

	const createExceptionsPlaceGrid = ({ recordData }) => {
		const exceptionsPlaceValues = edi.utils.getObjectProperty(recordData, 'exceptionsPlace');
		const selectFromPartinExceptionsPlaceButton = createButton({
			cls: BUTTON_CLS.secondary,
			text: edi.i18n.getMessage('form.btn.select.product'),
			glyph: edi.constants.ICONS.SEARCH,
			margin: '0 0 0 8',
			disabled: !buyerOrg?.id || !sellerId,
			handler: function () {
				let options = {
					modalTitle: edi.i18n.getMessage('documents.pricate_eancom.select.exception.from.storage')
				};
				let modal = createModalPartInCatalogSelectForGrid(
					buyerOrg.id,
					sellerId,
					function (data) {
						const store = exceptionsPlaceGrid.productsGrid.getStore();
						if (-1 === store.find('iln', data.iln)) {
							exceptionsPlaceGrid.setGridRow(data);
						}
						const deliveryStore = deliveryPlaceGrid.productsGrid.getStore();
						const record = deliveryStore.findRecord('iln', data.iln);
						if (record) {
							deliveryStore.remove(record);
							if ('function' == typeof deliveryPlaceGrid.afterRowRemove) {
								deliveryPlaceGrid.afterRowRemove(record);
							}
						}
					},
					options
				);
				modal.show();
			}
		});
		return (exceptionsPlaceGrid = createPlaceGrid({
			values: exceptionsPlaceValues,
			config: {
				allowBlank: true,
				addedTools: [selectFromPartinExceptionsPlaceButton]
			}
		}));
	};

	const validateGridValues = function () {
		if (!productsGrid.productsGrid) return;

		const productsGridRecords = productsGrid.productsGrid.getStore().getRange() || [];
		productsGridRecords.forEach((record) => {
			record.isExternalValid = productsGrid.isValidRecord(record);
		});
		productsGrid.productsGrid.getView().refresh();
	};
	const isEndingDateNewPriceDisabled = () =>
		documentPurposeValue === edi.i18n.getMessage('pricat_eancom_v2.document_purpose.code.1');
	const getEndingDateNewPriceAllowBlank = () => isEndingDateNewPriceDisabled() || typePriceNoticeValue !== 'MAR';
	const isVolumeDisabled = () =>
		typePriceNoticeValue !== 'ZAK' ||
		documentPurposeValue !== edi.i18n.getMessage('pricat_eancom_v2.document_purpose.code.3');

	const createProductInfoFields = ({ recordData }) => {
		const ean = createFieldBlock({
			title: edi.i18n.getMessage('line.item.ean'),
			items: createTextField({
				name: 'EAN',
				allowBlank: true,
				regex: edi.constants.VALIDATORS.EAN13,
				invalidText: edi.i18n.getMessage('invalid.field.ean.value.default'),
				valueSrc: recordData
			})
		});
		const itemCode = createFieldBlock({
			title: edi.i18n.getMessage('column.pricate.eancom.item.code'),
			items: createNumberField({
				name: 'ItemCode',
				valueSrc: recordData,
				allowBlank: false,
				maxLength: 13
			})
		});
		const heading = createFieldBlock({
			title: edi.i18n.getMessage('column.pricate.eancom.heading'),
			items: createTextField({
				name: 'Heading',
				allowBlank: false,
				valueSrc: recordData,
				maxLength: 128
			})
		});
		const newPrice = createFieldBlock({
			title: edi.i18n.getMessage('column.pricate.eancom.new.price'),
			items: createTextField({
				name: 'NewPrice',
				allowBlank: false,
				allowDecimals: true,
				valueSrc: recordData,
				regex: edi.constants.VALIDATORS.EANCOM_PRICE
			})
		});
		const taxRate = createFieldBlock({
			title: edi.i18n.getMessage('documents.pricate_eancom_v2.tax_rate'),
			//Если Поставщик плательщик НДС = смешанный, то у каждой позиции может быть свой НДС
			hidden: supplierNDSPayerState !== 'mixed',
			items: createCombo({
				store: taxRateStore,
				name: 'taxRate',
				valueSrc: recordData,
				valueField: 'id',
				displayField: 'name'
			})
		});

		const startingDateNewPrice = createDateField({
			emptyText: edi.i18n.getMessage('uikit.datefield.date.from'),
			allowBlank: false,
			name: 'startingDateNewPrice',
			submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM,
			value: PRICAT_EANCOM_V2_METHODS.formatPricatDate(recordData?.startingDateNewPrice),
			listeners: {
				change: function () {
					if (endingDateNewPrice.getValue()) {
						endingDateNewPrice.isValid();
					}
				}
			}
		});

		const endingDateNewPrice = createDateField({
			emptyText: edi.i18n.getMessage('uikit.datefield.date.to'),
			name: 'endingDateNewPrice',
			disabled: isEndingDateNewPriceDisabled(),
			allowBlank: getEndingDateNewPriceAllowBlank(),
			minValue: startingDateNewPrice.getValue(),
			validator: function () {
				const startDateNewPriceValue = startingDateNewPrice.getValue();
				const endDateNewPriceValue = endingDateNewPrice.getValue();
				if (endDateNewPriceValue?.getTime() < startDateNewPriceValue?.getTime()) {
					return edi.i18n.getMessage('err.date.must.be.more.or.equal');
				}
				return true;
			},
			submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM,
			value: PRICAT_EANCOM_V2_METHODS.formatPricatDate(recordData?.endingDateNewPrice)
		});

		const pricePeriod = createFieldBlock({
			title: edi.i18n.getMessage('pricat_eancom.new.price.period'),
			layout: {
				type: 'grid',
				area: [[6, 6]]
			},
			items: [startingDateNewPrice, endingDateNewPrice]
		});

		const volume = createFieldBlock({
			title: edi.i18n.getMessage('documents.pricate_eancom_v2.volume'),
			items: createNumberField({
				allowBlank: true,
				allowDecimals: true,
				decimalPrecision: 2,
				name: 'volume',
				disabled: isVolumeDisabled(),
				value: edi.utils.getObjectProperty(recordData, 'volume')
			})
		});

		return [ean, itemCode, heading, volume, taxRate, newPrice, !isPricatCancellation ? pricePeriod : null].filter(
			Boolean
		);
	};

	const createInvoiceTableItem = function (documentData) {
		const lines = edi.utils.getObjectProperty(documentData, 'sg17', true);

		for (let i = 0; i < lines.length; i++) {
			const product = lines[i];
			productValues.products.push(product);
		}

		const createModalForm = (recordData) =>
			createTabPanel({
				cls: TAB_PANEL_CLS.simpleWithoutPadding,
				items: [
					createTab({
						title: edi.i18n.getMessage('pricat_eancom.lines.grid'),
						padding: '24',
						closable: false,
						autoScroll: true,
						layout: {
							type: 'grid',
							gap: 24
						},
						items: createProductInfoFields({ recordData })
					}),
					createTab({
						title: edi.i18n.getMessage('documents.pricate_eancom.storage'),
						padding: '24',
						closable: false,
						autoScroll: true,
						items: createDeliveryPlaceGrid({ recordData })
					}),
					createTab({
						title: edi.i18n.getMessage('documents.pricate_eancom.exception.from.storage'),
						padding: '24',
						closable: false,
						autoScroll: true,
						items: createExceptionsPlaceGrid({ recordData })
					})
				]
			});

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('pricat_eancom.lines.grid'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'EANCOM_LINE',
			gridColumnConfig: PRICAT_EANCOM_V2_LINE_COLUMN,
			data: productValues.products,
			lineNumberFieldName: 'LineNumber',
			lockIfNoPartner: true,
			hasTotal: false,
			gridConfig: {
				settingsBtnConf: {
					panelMinWidth: 720
				}
			},
			configModalButtonsBefore: function () {
				return [];
			},
			findCompositeFields: function () {
				const me = this;
				me.formGrids = {
					deliveryPlace: deliveryPlaceGrid.productsGrid,
					exceptionsPlace: exceptionsPlaceGrid.productsGrid
				};
			},
			allowBlankEditableGrids: [
				{
					deliveryPlace: ['name', 'iln']
				}
			],
			isValidRecord: function (record) {
				const headingValue = record.get('Heading');
				const newPriceValue = record.get('NewPrice');
				const startingDateNewPriceValue = record.get('startingDateNewPrice');
				const endingDateNewPriceValue = getEndingDateNewPriceAllowBlank() || record.get('endingDateNewPrice');
				const deliveryPlaceValues = record.get('deliveryPlace')?.length;
				return !!(
					headingValue &&
					newPriceValue &&
					startingDateNewPriceValue &&
					endingDateNewPriceValue &&
					deliveryPlaceValues
				);
			},
			callback: function (products) {
				moduleData.isChanged = true;
				productValues = products;
				checkValid();
			},
			modalConf: {
				width: MODAL_SIZE.widthMedium
			},
			createModalForm,
			modalFormConfig: {
				title: 'document.upd.line'
			}
		});
		productsGrid.setPartnerId(buyerOrg ? buyerOrg.id : null);
		return productsGrid;
	};

	const findOrgByValues = function (searchValues) {
		if (!searchValues) {
			return null;
		}
		let organizations = edi.relations.getRelations({ self: true });
		let orgData = organizations.find(function (o) {
			return o.iln === searchValues.iln;
		});
		return orgData || null;
	};

	/**
	 * Creates pricat eancom form
	 * @returns {Object}
	 */
	const createModuleForm = function (documentData) {
		documentData = documentData || {};
		let title = createLabel({
			typography: 'heading_02',
			text: getDocTitle({ docNumber: documentData.docNumber })
		});

		const docName = createCombo({
			fieldLabel: edi.i18n.getMessage('pricat_eancom.document.type'),
			name: 'docName',
			store: edi.stores.createSimpleInlineStore(['9'], function (id) {
				return edi.i18n.getMessage('pricate_eancom.name.code.' + id);
			}),
			value: documentData.docName || '9',
			allowBlank: false
		});
		const docNumber = createTextField({
			fieldLabel: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('pricat.nr'),
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			name: 'docNumber',
			minLength: 1,
			maxLength: 32,
			value: !edi.utils.isEmptyObject(documentData)
				? documentData.docNumber + (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
				: undefined
		});
		const dateDocument = createDateField({
			fieldLabel: edi.i18n.getMessage('date'),
			allowBlank: false,
			name: 'dateDocument',
			submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM,
			value:
				(isEdit || isBasedOnDoc) && documentData
					? PRICAT_EANCOM_V2_METHODS.formatPricatDate(documentData.dateDocument)
					: new Date()
		});

		const partiesSeller = edi.utils.getObjectProperty(documentData, 'Seller');
		const isSellerDefined = !!partiesSeller;
		const sellerValues = isSellerDefined ? documentData : edi.converters.convertPricatPartie(userData.org);
		sellerId = isSellerDefined ? findOrgByValues(sellerValues.Seller)?.id : userData.org.id;
		const createOS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				valuesByMap: true,
				notReturnedVoid: true,
				forceChangeFields: {
					company_inn: true,
					contact_email: true
				},
				callback: checkValid,
				processValues: orgConverter
			});

			return createOrgSelector(selectorConfig);
		};

		sellerFNSid = edi.utils.getObjectProperty(edi.converters.convertPricatPartie(userData.org), 'fns_id');

		seller = createOS({
			itemId: 'seller',
			readOnly: isEdit || isPricatCorrection || isPricatCancellation,
			fieldValues: sellerValues,
			valuesByMap: isSellerDefined,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id,
				converter: orgConverter
			}),
			selectedRelationByMap: isSellerDefined,
			is_valid: isSellerDefined || !isEdit,
			selectedOrgValues: sellerValues,
			selectedOrg: userData.org,
			fieldsMap: edi.selectors.getPRICATEANCOMFieldsMap('Seller'),
			modalConf: {
				tabs: {
					main: {
						company_gln: edi.selectors.defaults.company_gln,
						company_name: edi.selectors.mergeConfigs('company_name', {
							title: 'company.name',
							allowBlank: false
						}),
						company_inn: edi.selectors.mergeConfigs('company_inn', {
							title: 'company.inn',
							allowBlank: false
						}),
						company_kpp: edi.selectors.mergeConfigs('company_kpp', {
							title: 'company.kpp'
						})
					}
				},
				title: edi.i18n.getMessage('documents.pricate_eancom.seller')
			},
			callback: function (_values, org) {
				const orgId = org ? org.id : null;
				if (productsGrid.getPartnerId() != orgId) {
					productsGrid.productsGrid.getStore().removeAll();
				}
				sellerId = orgId;
				productsGrid.setPartnerId(orgId);
				checkValid();
			}
		});

		const sellerBlock = createFieldBlock({
			title: edi.i18n.getMessage('documents.pricate_eancom.seller'),
			items: [seller]
		});

		const partiesBuyer = edi.utils.getObjectProperty(documentData, 'Buyer');
		const isBuyerDefined = !!partiesBuyer;
		const buyerValues = isBuyerDefined ? documentData : undefined;

		buyer = createOS({
			itemId: 'buyer',
			relationsOnly: true,
			useHiddenFields: true,
			allowBlank: false,
			readOnly: isPricatCorrection || isPricatCancellation,
			is_valid: isBuyerDefined,
			valuesByMap: isBuyerDefined,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			relations: isBuyerDefined ? buyerOrg : edi.relations.getRelations(),
			allowReset: !isBuyerDefined,
			fieldValues: buyerValues,
			selectedOrgValues: buyerValues,
			selectedOrg: isBuyerDefined ? buyerOrg : null,
			selectedRelationByMap: isBuyerDefined,
			fieldsMap: edi.selectors.getPRICATEANCOMFieldsMap('Buyer'),
			modalConf: {
				tabs: {
					main: {
						company_gln: edi.selectors.defaults.company_gln,
						company_name: edi.selectors.mergeConfigs('company_name', {
							title: 'company.name',
							allowBlank: false
						}),
						company_inn: edi.selectors.mergeConfigs('company_inn', {
							title: 'company.inn',
							allowBlank: false
						}),
						contact_email: edi.selectors.mergeConfigs('contact_email', {
							title: 'company.contact.email',
							regex: edi.constants.VALIDATORS.EMAIL,
							invalidText: edi.i18n.getMessage('invalid.email.format'),
							allowBlank: false
						})
					}
				},
				title: edi.i18n.getMessage('documents.pricate_eancom.buyer')
			},
			callback: function () {
				checkBuyer();
				checkValid();
			}
		});

		const buyerBlock = createFieldBlock({
			title: edi.i18n.getMessage('documents.pricate_eancom.buyer'),
			items: [buyer]
		});

		const documentPurpose = createCombo({
			fieldLabel: edi.i18n.getMessage('documents.pricat_eancom_v2.document_purpose'),
			name: 'documentPurpose',
			store: edi.stores.createSimpleInlineStore(['1', '2', '3'], function (id) {
				return edi.i18n.getMessage('pricat_eancom_v2.document_purpose.code.' + id);
			}),
			valueField: 'name',
			value: edi.utils.getObjectProperty(documentData, 'documentPurpose'),
			allowBlank: false,
			listeners: {
				change: function (combo, value) {
					documentPurposeValue = value;
					validateGridValues();
				}
			}
		});

		const documentSRMid = createTextField({
			fieldLabel: edi.i18n.getMessage('documents.pricate_eancom_v2.SRM_id'),
			allowBlank: true,
			readOnly: isPricatCorrection,
			name: 'documentSRMid',
			value: edi.utils.getObjectProperty(documentData, 'documentSRMid')
		});

		const documentDataBlock = createFieldBlock({
			title: edi.i18n.getMessage('catalog.partin.documentData'),
			layout: {
				type: 'grid',
				gap: [8, 16],
				area: [
					[6, 6],
					[6, 6]
				]
			},
			items: [documentPurpose, documentSRMid]
		});

		const agreementNumber =
			isPricatCorrection || isPricatCancellation
				? documentData?.origin?.sg1?.find((sg1) => sg1?.rff?.c506?.e1153 === 'CT')?.rff?.c506?.e1154
				: documentData?.agreementNumber;
		const deliveryContractNumber = createTextField({
			fieldLabel: edi.i18n.getMessage('pricat_eancom.contract.details.number'),
			allowBlank: false,
			name: 'agreementNumber',
			minLength: 1,
			maxLength: 46,
			value: agreementNumber
		});

		const dateDeliveryAgreement = createDateField({
			fieldLabel: edi.i18n.getMessage('pricat_eancom.contract.details.date'),
			allowBlank: false,
			name: 'agreementDate',
			submitFormat: edi.constants.DATE_FORMAT.DATE_TIME_PRICAT_EANCOM,
			value: PRICAT_EANCOM_V2_METHODS.formatPricatDate(documentData?.agreementDate)
		});

		const typePriceNoticeCombo = createCombo({
			fieldLabel: edi.i18n.getMessage('pricat_eancom.contract.details.price.type.notice'),
			name: 'typePriceNotice',
			value: documentData.typePriceNotice,
			store: edi.stores.createSimpleInlineStore(['ZAK', 'MAR'], function (id) {
				return edi.i18n.getMessage('pricate_eancom.type.price.notice.code.' + id);
			}),
			allowBlank: false,
			listeners: {
				change: function (combo, value) {
					typePriceNoticeValue = value;
					validateGridValues();
					form.isValid();
				}
			}
		});

		const contractBlock = createFieldBlock({
			title: edi.i18n.getMessage('pricat_eancom.contract.details'),
			layout: {
				type: 'grid',
				gap: [8, 16],
				area: [[6, 6], 8]
			},
			items: [deliveryContractNumber, dateDeliveryAgreement, typePriceNoticeCombo]
		});

		const createBtn = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller'],
							[buyer, seller],
							isPricatCancellation ? undefined : productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					const values = edi.utils.collectFormValues(form);
					edi.utils.clearEmptyValues(values);
					save(values);
				}
			},
			isEdit
		);

		form = createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, 12]
			},
			items: [
				title,
				createMaxWidthContainer({
					layout: {
						type: 'grid',
						gap: [24, 16],
						area: [[3, 4, 2], [6, 6], 6]
					},
					items: [docName, docNumber, dateDocument, sellerBlock, buyerBlock, contractBlock, documentDataBlock]
				}),
				!isPricatCancellation ? createInvoiceTableItem(documentData) : null
			],
			buttons: createButtonContainer({
				items: [createBtn]
			}),
			listeners: {
				afterrender: function (form) {
					if (isPricatCancellation) {
						const allowEditFieldNames = ['docNumber', 'dateDocument'];
						const fields =
							form
								.getForm()
								?.getFields()
								?.items?.filter((field) => field.isVisible()) || [];
						fields.forEach((field) => {
							field.setReadOnly(!allowEditFieldNames.includes(field.name));
						});
					}
				}
			}
		});

		form.isValid();
		form.on('validitychange', checkValid);

		checkValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		let defaultBuyer = null;
		let data = moduleData.initData.data;
		supplierNDSPayerState = edi.utils.getAttributeByName(data?.attributes, 'NDS_payer');

		if (data) {
			receiverId = data.id;
			doctype = data.type;
		}

		let preloadTasks = [];

		if (data && data.id) {
			preloadTasks.push(
				new Promise((resolve, reject) => {
					buyerOrg = data.toOrg;
					loadDocumentContent(data.id, resolve, reject);
				})
			);
		} else if ((isPricatCorrection || isPricatCancellation) && data?.data) {
			documentData = fillDocumentData(data.data);
			doctype ||= edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM;
			buyerOrg = data.parties?.toOrg;
		} else {
			data = {};

			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				preloadTasks.push(
					new Promise((resolve, reject) => {
						edi.rest.sendRequest(
							edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET,
							'GET',
							{},
							function (data) {
								if (data && data.items) {
									for (let i = 0; i < data.items.length; i++) {
										const numberingRule = data.items[i];
										if (numberingRule.typeDoc === doctype && 'ALLOW' === numberingRule.policy) {
											isNumberAutoGenerated = true;
											break;
										}
									}
									resolve();
								} else {
									reject(data);
								}
							},
							reject
						);
					})
				);
			}

			preloadTasks.push(
				(async () => {
					defaultBuyer = await getTanderOrg();
				})()
			);
		}

		Promise.allSettled(preloadTasks).then(
			() => {
				const modulePanel = createAddModulePanel({
					items: [createModuleForm(documentData)]
				});

				moduleData.tab.add(modulePanel);

				if (isEdit) {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
					moduleData.tab.updateLayout();
				} else {
					if (defaultBuyer) {
						buyer.setOrganization(defaultBuyer);

						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						buyer.presetFromRelation(function () {
							if ('function' == typeof initCallBack) {
								initCallBack();
							}
						});
					}
				}
			},
			edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				edi.modulesHandler.removeModule(moduleData);
			})
		);
	};

	/**
	 * Saves pricat eancom
	 * @param {Object}    values    values from form
	 */
	const save = function (values) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		const products = PRICAT_EANCOM_V2_METHODS.getProductValues({
			productValues
		});
		const build = PRICAT_EANCOM_V2_METHODS.buildDocument({ documentData, values });

		if (!isPricatCancellation) {
			build.sg17.sg36 = products; // Строки документа
		}

		if (isEdit && guid && !(isPricatCancellation || isPricatCorrection)) {
			edi.utils.setObjectProperty(build, 'unh.e0062', guid);
		}
		const setOrCreateObjectArrayValue = function (obj, propPath, value) {
			obj = obj && 'object' == typeof obj ? obj : {};

			const path = String(propPath).split('.');
			let tmp = obj;

			for (let i = 0; i < path.length; i++) {
				const propName = path[i];
				const isArrayItem = Ext.isNumber(+propName);
				const val = tmp[propName];
				if (undefined === val || null === val) {
					if (isArrayItem && undefined === val) {
						if (Array.isArray(tmp)) {
							tmp.push({});
						} else {
							tmp = [];
							edi.utils.setObjectProperty(obj, path.slice(0, i).join('.'), tmp);
						}
					}
					tmp[propName] = {};
				}
				if (i === path.length - 1) {
					tmp[propName] = value;
				} else if (typeof tmp[propName] != 'object') {
					tmp[propName] = {};
				}
				tmp = tmp[propName];
			}
			return obj;
		};

		Object.entries(valuesMap).forEach(function ([fieldName, valueConfig]) {
			const fieldValue = edi.utils.getObjectProperty(values, fieldName);
			if (fieldValue || valueConfig.allowBlank) {
				if ('string' == typeof valueConfig) {
					setOrCreateObjectArrayValue(build, valueConfig, fieldValue);
				} else if ('string' == typeof valueConfig.path) {
					setOrCreateObjectArrayValue(build, valueConfig.path, fieldValue);
					if (valueConfig.defaultValues && Array.isArray(valueConfig.defaultValues)) {
						for (let i = 0; i < valueConfig.defaultValues.length; i++) {
							setOrCreateObjectArrayValue(
								build,
								valueConfig.defaultValues[i].path,
								valueConfig.defaultValues[i].value
							);
						}
					}
				}
			}
		});

		const canceledPricatUniqueID =
			isPricatCancellation || isPricatCorrection
				? documentData?.origin?.sg1?.find((sg1) => sg1?.rff?.c506?.e1153 === 'PL')
				: null;
		if (canceledPricatUniqueID) {
			build.sg1.push(canceledPricatUniqueID);
		}

		if (sellerFNSid || buyerFNSid) {
			edi.utils.setObjectProperty(build.sg2[0], 'sg3.2.rff.c506', {
				e1153: 'YC1',
				e1154: sellerFNSid
			});
			edi.utils.setObjectProperty(build.sg2[1], 'sg3.1.rff.c506', {
				e1153: 'YC1',
				e1154: buyerFNSid
			});
		}

		const buildKeys = Object.keys(build);
		const numberOfSegmentsArray = buildKeys.filter(function (key) {
			return key !== 'unh' && key !== 'unt';
		});
		const numberOfSegments = numberOfSegmentsArray.length + 2;
		edi.utils.setObjectProperty(build, 'unt.e0074', numberOfSegments);

		if (isNumberAutoGenerated) {
			edi.utils.setObjectProperty(build, 'bgm.c106', {});
		}

		const headerData = {
			data: Ext.encode(build)
		};

		if (isNumberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(build, 'bgm.c106.e1004');
		}
		headerData.doctime = values.docName;
		headerData.versionId = '2';

		if (docFlowType) {
			headerData.docFlowType = docFlowType;
		}

		const isNewDocument = !isEdit;
		const onSuccessSave = function (responseData) {
			const documentId = receiverId || responseData.data.id;

			moduleData.isChanged = false;
			moduleData.tab.setLoading(false);
			edi.document.actions.documentCreateProcessing(moduleData, responseData, !isNewDocument);
			if (documentId) {
				edi.events.documents.fireEvent('change', {
					id: documentId
				});
			} else {
				edi.events.documents.fireEvent('create');
			}
		};
		const onFailureSave = edi.document.actions.createSaveErrorHandler(
			!isNewDocument,
			moduleData,
			edi.constants.DOCUMENT_TYPES.PRICAT_EANCOM
		);
		edi.document.actions.processDocument(
			buyerOrg.id,
			null,
			doctype,
			parentId,
			!isNewDocument ? receiverId : undefined,
			onSuccessSave,
			onFailureSave,
			headerData
		);
	};

	const getTanderOrg = function () {
		return new Promise((resolve, reject) => {
			edi.rest.sendRequest(
				edi.utils.formatString(edi.rest.services.SERVER.SETTING.GET, {
					configuration_code: 'TANDER_ILN'
				}),
				'GET',
				{},
				(result) => {
					let tanderGln = result?.data;
					let tanderObj = null;

					if (tanderGln) {
						edi.relations.getRelations().forEach((item) => {
							if (item.iln === tanderGln) {
								tanderObj = item;
							}
						});
					}

					resolve(tanderObj);
				},
				reject
			);
		});
	};

	const fillDocumentData = function (documentData) {
		const documentFormValues = {};
		documentFormValues.origin = Ext.clone(documentData);

		/**
		 * Временное решение проблемы Когда ФНСID и INN поменяны местами, выстраиваем порядок на UI
		 */
		const checkOrder = function (parties, path) {
			if (parties) {
				let p1 = edi.utils.getObjectProperty(parties, 'sg3.0.rff.c506.e1153');
				let p2 = edi.utils.getObjectProperty(parties, 'sg3.2.rff.c506.e1153');
				if (p1 === 'YC1' && p2 === 'FC') {
					let tmp = edi.utils.getObjectProperty(parties, 'sg3.0');
					edi.utils.setObjectProperty(parties, 'sg3.0', edi.utils.getObjectProperty(parties, 'sg3.2'));
					edi.utils.setObjectProperty(parties, 'sg3.2', tmp);
					edi.utils.setObjectPropertyEx(documentData, path, parties);
				}
				let t1 = edi.utils.getObjectProperty(parties, 'sg3.0.rff.c506.e1153');
				let t2 = edi.utils.getObjectProperty(parties, 'sg3.1.rff.c506.e1153');
				if (t1 === 'YC1' && t2 === 'FC') {
					let tmp = edi.utils.getObjectProperty(parties, 'sg3.0');
					edi.utils.setObjectProperty(parties, 'sg3.0', edi.utils.getObjectProperty(parties, 'sg3.1'));
					edi.utils.setObjectProperty(parties, 'sg3.1', tmp);
					edi.utils.setObjectPropertyEx(documentData, path, parties);
				}
			}
		};
		let seller_parties = edi.utils.getObjectPropertyEx(documentData, 'sg2[nad.e3035==SU]');
		let buyer_parties = edi.utils.getObjectPropertyEx(documentData, 'sg2[nad.e3035==BY]');
		checkOrder(seller_parties, 'sg2[nad.e3035==SU]');
		checkOrder(buyer_parties, 'sg2[nad.e3035==BY]');

		Object.entries(valuesMap).forEach(function ([fieldName, valueConfig]) {
			const documentValuePath = 'string' == typeof valueConfig ? valueConfig : valueConfig.path;
			const valuesByСonditionalPathMap = {
				'Seller.inn': {
					path: 'sg3[rff.c506.e1153==FC]rff.c506.e1154',
					data: seller_parties
				},
				'Seller.kpp': {
					path: 'sg3[rff.c506.e1153==XA]rff.c506.e1154',
					data: seller_parties
				},
				'Buyer.inn': {
					path: 'sg3[rff.c506.e1153==FC]rff.c506.e1154',
					data: buyer_parties
				},
				agreementDate: {
					path: 'sg1[rff.c506.e1153==CT]dtm.c507.e2380',
					data: documentData
				},
				agreementNumber: {
					path: 'sg1[rff.c506.e1153==CT]rff.c506.e1154',
					data: documentData
				},
				documentSRMid: {
					path: 'sg1[rff.c506.e1153==ACE]rff.c506.e1154',
					data: documentData
				}
			};
			const fieldValue = valuesByСonditionalPathMap[fieldName]
				? edi.utils.getObjectPropertyEx(
						valuesByСonditionalPathMap[fieldName].data,
						valuesByСonditionalPathMap[fieldName].path
				  )
				: edi.utils.getObjectProperty(documentData, documentValuePath);
			if (fieldValue) {
				edi.utils.setObjectProperty(documentFormValues, fieldName, fieldValue);
			}
		});

		guid = edi.utils.getObjectProperty(documentData, 'unh.e0062');
		sellerFNSid = edi.utils.getObjectProperty(documentData, 'sg2.0.sg3.2.rff.c506.e1154');
		buyerFNSid = edi.utils.getObjectProperty(documentData, 'sg2.1.sg3.1.rff.c506.e1154');
		typePriceNoticeValue = edi.utils.getObjectProperty(documentData, 'sg17.ftx.0.c108.e4440');
		edi.utils.setObjectProperty(documentFormValues, 'typePriceNotice', typePriceNoticeValue);
		documentPurposeValue = edi.utils.getObjectProperty(documentData, 'sg17.ftx.1.c108.e4440');
		edi.utils.setObjectProperty(documentFormValues, 'documentPurpose', documentPurposeValue);

		const products = documentData.sg17.sg36 || [];
		documentFormValues.sg17 = PRICAT_EANCOM_V2_METHODS.getPricatLinesProperties({ pricatLines: products });
		return documentFormValues;
	};

	const loadDocumentContent = function (documentId, success, failure) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
				documentId
			}),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					documentData = fillDocumentData(data.data);
					success();
				} else {
					failure(data);
				}
			},
			failure
		);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
