import tpl from './LabelWithIcon.ext.tpl';
import './LabelWithIcon.scss';
import '@UIkit/components/fields/Label/Label';

const baseCls = 'ui-label-with-icon';
Ext.define('UI.components.LabelWithIcon', {
	extend: 'UI.components.Label',
	baseCls,

	glyph: edi.constants.ICONS.INFO,
	typography: 'body-short_01',
	color: '--color-dark',
	iconColor: '--color-blue',

	renderTpl: tpl,
	renderSelectors: {
		textEl: `span.${baseCls}-text`,
		iconEl: `span.${baseCls}-icon`
	},

	beforeInit: function () {},

	initComponent: function () {
		this.beforeInit();
		this.callParent(arguments);
		this.afterInit();
	},

	afterInit: function () {},

	afterRender: function () {
		const me = this;
		me.callParent();
		me.setText(me.text);
		me.setGlyph(me.glyph);
	},

	getText: function () {
		const me = this;
		return me.textEl.getText();
	},

	setText: function (text = '') {
		const me = this;
		me.textEl.setText(edi.i18n.getMessage(text));
	},

	setGlyph: function (glyph) {
		const me = this;
		const { character, fontFamily } = new Ext.Glyph(glyph);
		me.iconEl.setStyle({
			fontFamily,
			color: `var(${me.iconColor})`
		});
		me.iconEl.setHtml(character);
	}
});

const createLabelWithIcon = (config) => new UI.components.LabelWithIcon(config);

export { createLabelWithIcon };
