import { applicationAPI } from '@Ediweb/modules/CONTROL_CENTER/tabs/APPLICATION_TO_FNS/rest';

/**
 * create preview modal panel
 */
var openPreviewModal = function (recordData, callback) {
	let checkReportTimer;

	let setFileDataToModal = function (reportId) {
		let url =
			edi.utils.formatString(edi.rest.services.DOCUMENTS.FD.PRINT_FORM.GET, {
				reportId: reportId
			}) + '?authType=EDI';
		let tab = window.open(url, '_blank');
		tab?.focus();
		if (typeof callback === 'function') {
			callback();
		}
	};

	var checkReportStatus = function () {
		var failure = function (resp) {
			if (typeof callback === 'function') {
				callback();
			}
			edi.rest.getErrorHandler('error.getting.data')(resp);
		};
		var success = function (resp) {
			if (!resp || !resp.items || !resp.items[0]) {
				failure(resp);
				return;
			}
			if (resp.items[0].reportStatus === 'READY') {
				setFileDataToModal(resp.items[0].requestId);
			} else {
				let payload = {
					reportSourceDoc: recordData.id,
					jasperTemplate: resp.items[0].templateId,
					format: edi.constants.REPORT_FORMATS.PDF
				};

				payload = Ext.encode(payload);

				let successReport = function () {
					if (checkReportTimer) {
						clearTimeout(checkReportTimer);
					}
					checkReportTimer = setTimeout(function () {
						checkReportStatus();
					}, 1000);
				};
				edi.rest.sendRequest(edi.rest.services.REPORT.POST, 'POST', payload, successReport, failure);
			}
		};
		var url = edi.utils.formatString(applicationAPI.TEMPLATES_AND_STATUSES.GET, {
			docId: recordData.id,
			format: 'PDF'
		});
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};

	checkReportStatus();
};

export { openPreviewModal };
