/**
 * @author Pavel Pirogov
 * Total handler methods
 */
Ext.namespace('edi.total');

edi.total = new (function () {
	/**
	 * Process totals
	 * @param    {String}    documentType
	 * @param    {Object}    component
	 * @param    {Object}    documentData
	 * @param    {String}    action
	 */
	this.process = function (documentType, component, documentData, action) {
		var __self = this;
		if ('function' == typeof this[documentType]) {
			var store = component.productsGrid.getStore();
			var totalsStore = component.productsTotalGrid.getStore();
			var rootNode = store.getRootNode ? store.getRootNode() : null;
			var lines = 0;
			var continueProcessing = function () {
				if (lines) {
					var totalRecord = totalsStore.getCount()
						? totalsStore.getAt(0)
						: edi.models.createInstance(component.totalModel, null);
					totalRecord.beginEdit();
					__self[documentType](lines, store, totalRecord, documentData);
					totalRecord.endEdit();
					if (action && 'set_rows' == action) {
						totalRecord.commit(true); //First commit, then add if not in the store
						!totalsStore.getCount() ? totalsStore.add(totalRecord) : null;
					} else {
						!totalsStore.getCount() ? totalsStore.add(totalRecord) : null; //First add if not in the store, then commit store
						totalsStore.commitChanges();
					}
				} else if (totalsStore.getCount()) {
					totalsStore.removeAt(0);
					totalsStore.commitChanges();
				}
			};

			if (rootNode) {
				var items = component.getValues().products;
				lines = items.length;
				store = edi.stores.createInlineStore(items, component.gridModel);
			} else {
				var len = store.proxy.data
					? store.proxy.data.items
						? store.proxy.data.items.length
						: store.proxy.data.length
					: 0;
				lines = len || store.getCount();
			}
			continueProcessing();
		}
	};
	/**
	 * DESADV products total handler
	 * @param    {Number}    lines
	 * @param    {Object}    store
	 * @param    {Object}    totalRecord
	 * @param    {Object}    documentData
	 */
	this[edi.constants.DOCUMENT_TYPES.LEGACY_DES_ADV] = function (lines, store, totalRecord, documentData) {
		if (documentData) {
			totalRecord.set(
				'TotalPSequence',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalPSequence', false)
			);
			totalRecord.set(
				'TotalLines',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalLines', false)
			);
			totalRecord.set(
				'TotalNetAmount',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalNetAmount', false)
			);
			totalRecord.set(
				'TotalTaxAmount',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalTaxAmount', false)
			);
			totalRecord.set(
				'TotalGrossAmount',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalGrossAmount', false)
			);
			totalRecord.set(
				'TotalGoodsDespatchedAmount',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalGoodsDespatchedAmount', false)
			);
		} else {
			var TotalNetAmount = 0,
				TotalTaxAmount = 0,
				TotalGrossAmount = 0,
				TotalGoodsDespatchedAmount = 0,
				productsCounted = {},
				packages = 0;
			lines = 0;
			store.each(function (record) {
				var productID = record.get('productID');
				if (productID && !productsCounted[productID]) {
					lines++;
					productsCounted[productID] = true;
					var netAmount = record.get('ProductNetAmount'),
						taxAmount = record.get('ProductTaxAmount'),
						grossAmount = record.get('ProductGrossAmount'),
						quantityDespatched = record.get('ProductQuantityDespatched');

					if ('' != netAmount) {
						'' == TotalNetAmount ? (TotalNetAmount = 0) : null;
						TotalNetAmount += parseFloat(netAmount) || 0;
					}

					if ('' != taxAmount) {
						'' == TotalTaxAmount ? (TotalTaxAmount = 0) : null;
						TotalTaxAmount += parseFloat(taxAmount) || 0;
					}
					if ('' != grossAmount) {
						'' == TotalGrossAmount ? (TotalGrossAmount = 0) : null;
						TotalGrossAmount += parseFloat(grossAmount) || 0;
					}
					if ('' != quantityDespatched) {
						'' == TotalGoodsDespatchedAmount ? (TotalGoodsDespatchedAmount = 0) : null;
						TotalGoodsDespatchedAmount += parseFloat(quantityDespatched) || 0;
					}
				} else if (!productID) {
					//Packages do not have productID
					packages++;
				}
			});

			totalRecord.set('TotalPSequence', 1);
			totalRecord.set('TotalLines', lines);
			totalRecord.set('TotalNetAmount', edi.utils.roundTo(TotalNetAmount, 4) || '');
			totalRecord.set('TotalTaxAmount', edi.utils.roundTo(TotalTaxAmount, 4) || '');
			totalRecord.set('TotalGrossAmount', edi.utils.roundTo(TotalGrossAmount, 4) || '');
			totalRecord.set('TotalGoodsDespatchedAmount', edi.utils.roundTo(TotalGoodsDespatchedAmount, 3) || '');
		}
	};
	/**
	 * ALCDES products total handler
	 * @param    {Number}    lines
	 * @param    {Object}    store
	 * @param    {Object}    totalRecord
	 * @param    {Object}    documentData
	 */
	this[edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES] = function (lines, store, totalRecord, documentData) {
		if (documentData) {
			totalRecord.set(
				'TotalPSequence',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalPSequence', false)
			);
			totalRecord.set(
				'TotalLines',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalLines', false)
			);
			totalRecord.set(
				'TotalNetAmount',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalNetAmount', false)
			);
			totalRecord.set(
				'TotalTaxAmount',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalTaxAmount', false)
			);
			totalRecord.set(
				'TotalGrossAmount',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalGrossAmount', false)
			);
			totalRecord.set(
				'TotalGoodsDespatchedAmount',
				edi.utils.getObjectProperty(documentData, 'DespatchAdvice-Summary.TotalGoodsDespatchedAmount', false)
			);
		} else {
			var TotalNetAmount = 0;
			var TotalTaxAmount = 0;
			var TotalGrossAmount = 0;
			var TotalGoodsDespatchedAmount = 0;
			var uniquePallets = {};
			var amountOfPallets = 0;
			store.each(function (record) {
				if ('' != record.get('NetAmount')) {
					'' == TotalNetAmount ? (TotalNetAmount = 0) : null;
					TotalNetAmount += parseFloat(record.get('NetAmount')) || 0;
				}
				if ('' != record.get('TaxAmount')) {
					'' == TotalTaxAmount ? (TotalTaxAmount = 0) : null;
					TotalTaxAmount += parseFloat(record.get('TaxAmount')) || 0;
				}
				if ('' != record.get('NetAmount') && '' != record.get('TaxAmount')) {
					'' == TotalGrossAmount ? (TotalGrossAmount = 0) : null;
					TotalGrossAmount += parseFloat(record.get('NetAmount')) + parseFloat(record.get('TaxAmount')) || 0;
				}
				if ('' != record.get('TotalGoodsDespatchedAmount')) {
					'' == TotalGoodsDespatchedAmount ? (TotalGoodsDespatchedAmount = 0) : null;
					TotalGoodsDespatchedAmount += parseFloat(record.get('QuantityDespatched')) || 0;
				}
				var pallets = edi.utils.getObjectProperty(record.get('Package-Identification'), 'Goods-Identity', true);
				for (var i = 0; i < pallets.length; i++) {
					var palletId = edi.utils.getObjectProperty(pallets[i], 'Range.ID-Begin');
					if (!uniquePallets[palletId]) {
						uniquePallets[palletId] = true;
						amountOfPallets++;
					}
				}
			});

			totalRecord.set('TotalPSequence', 1);
			totalRecord.set('TotalLines', lines);
			totalRecord.set('TotalNetAmount', edi.utils.roundTo(TotalNetAmount, 4) || '');
			totalRecord.set('TotalTaxAmount', edi.utils.roundTo(TotalTaxAmount, 4) || '');
			totalRecord.set('TotalGrossAmount', edi.utils.roundTo(TotalGrossAmount, 4) || '');
			totalRecord.set('TotalGoodsDespatchedAmount', edi.utils.roundTo(TotalGoodsDespatchedAmount, 3) || '');
		}
	};
	/**
	 * INVOICE products total handler
	 * @param    {Number}    lines
	 * @param    {Object}    store
	 * @param    {Object}    totalRecord
	 * @param    {Object}    documentData
	 */
	this[edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE] = function (lines, store, totalRecord, documentData) {
		if (documentData) {
			totalRecord.set('TotalLines', lines);
			totalRecord.set(
				'TotalInvoicedAmount',
				edi.utils.getObjectProperty(documentData, 'Invoice-Summary.TotalInvoicedAmount', false) || 0
			);
			totalRecord.set(
				'TotalNetAmount',
				edi.utils.getObjectProperty(documentData, 'Invoice-Summary.TotalNetAmount', false) || 0
			);
			totalRecord.set(
				'TotalTaxAmount',
				edi.utils.getObjectProperty(documentData, 'Invoice-Summary.TotalTaxAmount', false) || 0
			);
			totalRecord.set(
				'TotalGrossAmount',
				edi.utils.getObjectProperty(documentData, 'Invoice-Summary.TotalGrossAmount', false) || 0
			);
		} else {
			var TotalNetAmount = 0;
			var TotalTaxAmount = 0;
			var TotalGrossAmount = 0;
			var TotalInvoicedAmount = 0;
			store.each(function (record) {
				if ('' != record.get('NetAmount')) {
					'' == TotalNetAmount ? (TotalNetAmount = 0) : null;
					TotalNetAmount += parseFloat(record.get('NetAmount')) || 0;
				}
				if ('' != record.get('TaxAmount')) {
					'' == TotalTaxAmount ? (TotalTaxAmount = 0) : null;
					TotalTaxAmount += parseFloat(record.get('TaxAmount')) || 0;
				}
				if ('' != record.get('GrossAmount')) {
					'' == TotalGrossAmount ? (TotalGrossAmount = 0) : null;
					TotalGrossAmount += parseFloat(record.get('GrossAmount')) || 0;
				}
				if ('' != record.get('InvoiceQuantity')) {
					'' == TotalInvoicedAmount ? (TotalInvoicedAmount = 0) : null;
					TotalInvoicedAmount += parseFloat(record.get('InvoiceQuantity')) || 0;
				}
			});
			totalRecord.set('TotalLines', lines);
			totalRecord.set('TotalInvoicedAmount', edi.utils.roundTo(TotalInvoicedAmount, 3) || 0);
			totalRecord.set('TotalNetAmount', edi.utils.roundTo(TotalNetAmount, 4) || 0);
			totalRecord.set('TotalTaxAmount', edi.utils.roundTo(TotalTaxAmount, 4) || 0);
			totalRecord.set('TotalGrossAmount', edi.utils.roundTo(TotalGrossAmount, 4) || 0);
		}
	};
	/**
	 * ORDRSP products total handler
	 * @param    {Number}    lines
	 * @param    {Object}    store
	 * @param    {Object}    totalRecord
	 * @param    {Object}    documentData
	 */
	this[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP] = function (lines, store, totalRecord, documentData) {
		if (documentData) {
			totalRecord.set('TotalLines', lines);
			totalRecord.set(
				'TotalAmount',
				edi.utils.getObjectProperty(documentData, 'OrderResponse-Summary.TotalAmount', false) || ''
			);
			totalRecord.set(
				'TotalNetAmount',
				edi.utils.getObjectProperty(documentData, 'OrderResponse-Summary.TotalNetAmount', false) || ''
			);
			totalRecord.set(
				'TotalTaxAmount',
				edi.utils.getObjectProperty(documentData, 'OrderResponse-Summary.TotalTaxAmount', false) || ''
			);
			totalRecord.set(
				'TotalGrossAmount',
				edi.utils.getObjectProperty(documentData, 'OrderResponse-Summary.TotalGrossAmount', false) || ''
			);
		} else {
			var TotalAmount = 0;
			var TotalNetAmount = 0;
			var TotalTaxAmount = 0;
			var TotalGrossAmount = 0;
			store.each(function (record) {
				if ('' != record.get('AllocatedDelivered')) {
					'' == TotalAmount ? (TotalAmount = 0) : null;
					TotalAmount += parseFloat(record.get('AllocatedDelivered')) || 0;
				}
				if ('' != record.get('NetAmount')) {
					'' == TotalNetAmount ? (TotalNetAmount = 0) : null;
					TotalNetAmount += parseFloat(record.get('NetAmount')) || 0;
				}
				if ('' != record.get('TaxAmount')) {
					'' == TotalTaxAmount ? (TotalTaxAmount = 0) : null;
					TotalTaxAmount += parseFloat(record.get('TaxAmount')) || 0;
				}
				if ('' != record.get('GrossAmount')) {
					'' == TotalGrossAmount ? (TotalGrossAmount = 0) : null;
					TotalGrossAmount += parseFloat(record.get('GrossAmount')) || 0;
				} else if ('' != record.get('NetAmount')) {
					'' == TotalGrossAmount ? (TotalGrossAmount = 0) : null;
					TotalGrossAmount += parseFloat(record.get('NetAmount')) || 0;
				}
			});
			totalRecord.set('TotalLines', lines);
			totalRecord.set('TotalAmount', edi.utils.roundTo(TotalAmount, 4) || '');
			totalRecord.set('TotalNetAmount', edi.utils.roundTo(TotalNetAmount, 4) || '');
			totalRecord.set('TotalTaxAmount', edi.utils.roundTo(TotalTaxAmount, 4) || '');
			totalRecord.set('TotalGrossAmount', edi.utils.roundTo(TotalGrossAmount, 4) || '');
		}
	};
	/**
	 * RECADV products total handler
	 * @param    {Number}    lines
	 * @param    {Object}    store
	 * @param    {Object}    totalRecord
	 * @param    {Object}    documentData
	 */
	this[edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV] = function (lines, store, totalRecord, documentData) {
		if (documentData) {
			totalRecord.set(
				'TotalLines',
				edi.utils.getObjectProperty(documentData, 'ReceivingAdvice-Summary.TotalLines', false)
			);
			totalRecord.set(
				'TotalNetAmount',
				edi.utils.getObjectProperty(documentData, 'ReceivingAdvice-Summary.TotalNetAmount', false)
			);
			totalRecord.set(
				'TotalGrossAmount',
				edi.utils.getObjectProperty(documentData, 'ReceivingAdvice-Summary.TotalGrossAmount', false)
			);
			totalRecord.set(
				'TotalTaxAmount',
				edi.utils.getObjectProperty(documentData, 'ReceivingAdvice-Summary.TotalTaxAmount', false)
			);
			totalRecord.set(
				'TotalGoodsReceiptAmount',
				edi.utils.getObjectProperty(documentData, 'ReceivingAdvice-Summary.TotalGoodsReceiptAmount', false)
			);
		} else {
			var TotalNetAmount = 0,
				TotalGrossAmount = 0,
				TotalTaxAmount = 0;
			var TotalGoodsReceiptAmount = 0;
			store.each(function (record) {
				if ('' != record.get('QuantityReceived')) {
					TotalGoodsReceiptAmount += parseFloat(record.get('QuantityReceived')) || 0;
				}
				if ('' != record.get('NetAmount')) {
					TotalNetAmount += parseFloat(record.get('NetAmount')) || 0;
				}
				if ('' != record.get('GrossAmount')) {
					TotalGrossAmount += parseFloat(record.get('GrossAmount')) || 0;
				}
				if ('' != record.get('TaxAmount')) {
					TotalTaxAmount += parseFloat(record.get('TaxAmount')) || 0;
				}
			});
			totalRecord.set('TotalLines', lines);
			totalRecord.set('TotalNetAmount', edi.utils.roundTo(TotalNetAmount, 4) || '');
			totalRecord.set('TotalGrossAmount', edi.utils.roundTo(TotalGrossAmount, 4) || '');
			totalRecord.set('TotalTaxAmount', edi.utils.roundTo(TotalTaxAmount, 4) || '');
			totalRecord.set('TotalGoodsReceiptAmount', edi.utils.roundTo(TotalGoodsReceiptAmount, 3) || '');
		}
	};
	/**
	 * RETANN products total handler
	 * @param    {Number}    lines
	 * @param    {Object}    store
	 * @param    {Object}    totalRecord
	 * @param    {Object}    documentData
	 */
	this[edi.constants.DOCUMENT_TYPES.LEGACY_RETANN] = function (lines, store, totalRecord, documentData) {
		if (documentData) {
			totalRecord.set(
				'TotalReceivedAmount',
				edi.utils.getObjectProperty(documentData, 'ReturnAnnouncement-Summary.TotalReceivedAmount', false)
			);
			totalRecord.set(
				'TotalReturnedAmount',
				edi.utils.getObjectProperty(documentData, 'ReturnAnnouncement-Summary.TotalReturnedAmount', false)
			);
			totalRecord.set(
				'TotalNetAmount',
				edi.utils.getObjectProperty(documentData, 'ReturnAnnouncement-Summary.TotalNetAmount', false)
			);
			totalRecord.set(
				'TotalGrossAmount',
				edi.utils.getObjectProperty(documentData, 'ReturnAnnouncement-Summary.TotalGrossAmount', false)
			);
			totalRecord.set(
				'TotalTaxAmount',
				edi.utils.getObjectProperty(documentData, 'ReturnAnnouncement-Summary.TotalTaxAmount', false)
			);
		} else {
			var TotalNetAmount = 0,
				TotalGrossAmount = 0,
				TotalTaxAmount = 0;
			var TotalReceivedAmount = 0,
				TotalReturnedAmount = 0;
			store.each(function (record) {
				if ('' != record.get('ReceivedQuantity')) {
					TotalReceivedAmount += parseFloat(record.get('ReceivedQuantity')) || 0;
				}
				if ('' != record.get('ReturnedQuantity')) {
					TotalReturnedAmount += parseFloat(record.get('ReturnedQuantity')) || 0;
				}
				if ('' != record.get('NetAmount')) {
					TotalNetAmount += parseFloat(record.get('NetAmount')) || 0;
				}
				if ('' != record.get('GrossAmount')) {
					TotalGrossAmount += parseFloat(record.get('GrossAmount')) || 0;
				}
				if ('' != record.get('TaxAmount')) {
					TotalTaxAmount += parseFloat(record.get('TaxAmount')) || 0;
				}
			});
			totalRecord.set('TotalReceivedAmount', edi.utils.roundTo(TotalReceivedAmount, 3) || '');
			totalRecord.set('TotalReturnedAmount', edi.utils.roundTo(TotalReturnedAmount, 3) || '');
			totalRecord.set('TotalNetAmount', edi.utils.roundTo(TotalNetAmount, 4) || '');
			totalRecord.set('TotalGrossAmount', edi.utils.roundTo(TotalGrossAmount, 4) || '');
			totalRecord.set('TotalTaxAmount', edi.utils.roundTo(TotalTaxAmount, 4) || '');
		}
	};
	/**
	 * ORDER products total handler
	 * @param    {Number}    lines
	 * @param    {Object}    store
	 * @param    {Object}    totalRecord
	 * @param    {Object}    documentData
	 */
	this[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER] = function (lines, store, totalRecord, documentData) {
		if (documentData) {
			totalRecord.set('TotalLines', lines);
			totalRecord.set(
				'TotalOrderedAmount',
				edi.utils.getObjectProperty(documentData, 'Order-Summary.TotalOrderedAmount', false) || ''
			);
			totalRecord.set(
				'TotalNetAmount',
				edi.utils.getObjectProperty(documentData, 'Order-Summary.TotalNetAmount', false) || ''
			);
			totalRecord.set(
				'TotalTaxAmount',
				edi.utils.getObjectProperty(documentData, 'Order-Summary.TotalTaxAmount', false) || ''
			);
			totalRecord.set(
				'TotalGrossAmount',
				edi.utils.getObjectProperty(documentData, 'Order-Summary.TotalGrossAmount', false) || ''
			);
		} else {
			var TotalOrderedAmount = 0;
			var TotalNetAmount = 0;
			var TotalTaxAmount = 0;
			var TotalGrossAmount = 0;
			store.each(function (record) {
				if ('' != record.get('OrderedQuantity')) {
					'' == TotalOrderedAmount ? (TotalOrderedAmount = 0) : null;
					TotalOrderedAmount += parseFloat(record.get('OrderedQuantity')) || 0;
				}
				if ('' != record.get('OrderedNetAmount')) {
					'' == TotalNetAmount ? (TotalNetAmount = 0) : null;
					TotalNetAmount += parseFloat(record.get('OrderedNetAmount')) || 0;
				}
				if ('' != record.get('OrderedTaxAmount')) {
					'' == TotalTaxAmount ? (TotalTaxAmount = 0) : null;
					TotalTaxAmount += parseFloat(record.get('OrderedTaxAmount')) || 0;
				}
				if ('' != record.get('OrderedGrossAmount')) {
					'' == TotalGrossAmount ? (TotalGrossAmount = 0) : null;
					TotalGrossAmount += parseFloat(record.get('OrderedGrossAmount')) || 0;
				} else if ('' != record.get('OrderedNetAmount')) {
					'' == TotalGrossAmount ? (TotalGrossAmount = 0) : null;
					TotalGrossAmount += parseFloat(record.get('OrderedNetAmount')) || 0;
				}
			});
			totalRecord.set('TotalLines', lines);
			totalRecord.set('TotalOrderedAmount', edi.utils.roundTo(TotalOrderedAmount, 4) || '');
			totalRecord.set('TotalNetAmount', edi.utils.roundTo(TotalNetAmount, 4) || '');
			totalRecord.set('TotalTaxAmount', edi.utils.roundTo(TotalTaxAmount, 4) || '');
			totalRecord.set('TotalGrossAmount', edi.utils.roundTo(TotalGrossAmount, 4) || '');
		}
	};
	this[edi.constants.DOCUMENT_TYPES.LEGACY_DELJIT] = this[edi.constants.DOCUMENT_TYPES.LEGACY_ORDER];
})();
