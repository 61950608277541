import { moduleSettings } from './moduleCommons';

const actionRulesForRoseuInvitation = {
	QUICK_ANSWER_CONFIRM: {},
	QUICK_ANSWER_REJECT: {},
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: []
	},
	EXPORT_GROUP_XML: {},
	EXPORT_GROUP: {},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: []
	},
	SIGN: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		permissionChanging: [{ change: () => 'SIGN_ROSEU_INVITATION' }],
		conditions: []
	},
	REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.COMPLETED]
		},
		OUTGOING: {
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.COMPLETED]
		},
		permissionChanging: [],
		conditions: []
	},
	ROUTE_REJECT: {},
	ROUTE_CONFIRM: {},
	CLARIFY: {},
	DELETE: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [],
		conditions: []
	},
	EDIT: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT]
		},
		permissionChanging: [],
		conditions: []
	},
	COMPLETE: {
		INCOMING: {
			STATE: [edi.constants.STATE.SENT]
		},
		LOOP: {
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [{ change: () => 'COMPLETE_ROSEU_INVITATION' }],
		conditions: []
	},
	READ: {},
	SEND: {
		OUTGOING: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		LOOP: {
			STATE: [edi.constants.STATE.DRAFT, edi.constants.STATE.SENDER_REVIEW]
		},
		permissionChanging: [{ change: () => 'CREATE_ROSEU_INVITATION' }],
		conditions: []
	},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {},
	CONVERT_TO_DRAFT: {},
	COPY: {},
	PACKAGING: {},
	ARCHIVE_RESTORE: {},
	ANNUL: {},
	CREATE_UKD: {},
	RENOUNCEMENT: {},
	CREATE_CUD: {}
};

Ext.merge(edi.action.rulesByDocType, {
	[moduleSettings.TYPE]: actionRulesForRoseuInvitation
});

export { actionRulesForRoseuInvitation };
