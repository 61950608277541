const COMMONPRICAT_PRICE_REGIONS_MODEL = 'COMMONPRICAT_PRICE_REGIONS';
const COMMONPRICAT_LINE_MODEL = 'COMMONPRICAT_LINE';

edi.models.setModels({
	[COMMONPRICAT_PRICE_REGIONS_MODEL]: {
		fields: [
			{
				name: 'Code',
				type: 'string'
			},
			{
				name: 'Name',
				type: 'string'
			},
			{
				name: 'Remarks',
				type: 'string'
			}
		],
		idProperty: 'lineNumber'
	},
	[COMMONPRICAT_LINE_MODEL]: {
		fields: [
			{
				name: edi.constants.INTERNAL_LINE_ID,
				type: 'string'
			},
			{
				name: 'LineNumber',
				type: 'number'
			},
			{
				name: 'EAN',
				type: 'string'
			},
			{
				name: 'BuyerItemCode',
				type: 'string'
			},
			{
				name: 'SupplierItemCode',
				type: 'string'
			},
			{
				name: 'ItemDescription',
				type: 'string'
			},
			{
				name: 'Brand',
				type: 'string'
			},
			{
				name: 'UnitOfMeasure',
				type: 'string'
			},
			{
				name: 'PackDetails'
				//type: 'object'
			},
			{
				name: 'MinOrderedQuantity',
				type: 'string'
			},
			{
				name: 'Campaign',
				type: 'string'
			},
			{
				name: 'SegmentPrice',
				type: 'string'
			},
			{
				name: 'PriceRegionText',
				type: 'string'
			},
			{
				name: 'UnitNetPrice',
				type: 'string'
			},
			{
				name: 'UnitGrossPrice',
				type: 'string'
			},
			{
				name: 'TaxRate',
				type: 'string'
			},
			{
				name: 'Discount',
				type: 'string'
			},
			{
				name: 'Weight',
				type: 'string'
			},
			{
				name: 'Length',
				type: 'string'
			},
			{
				name: 'Width',
				type: 'string'
			},
			{
				name: 'Height',
				type: 'string'
			},
			{
				name: 'UnitPacksize',
				type: 'string'
			},
			{
				name: 'SuggestedPrice',
				type: 'string'
			},
			{
				name: 'SpecialPrice',
				type: 'string'
			}
		],
		idProperty: 'LineNumber'
	}
});

export { COMMONPRICAT_PRICE_REGIONS_MODEL, COMMONPRICAT_LINE_MODEL };
