import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@UIkit/components/grid';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createProxyConfig } from '@Components/storeComponents';
import { createFieldBlockForDetails } from '@UIkit/components/panels';
import { createLabelBlockForDetails } from '@UIkit/components/fields';

Ext.namespace('edi.modules');
edi.modules['document.details.on.service.container'] = function () {
	let moduleData,
		rejectReasonText,
		attachmentsStoreData = [],
		updUkdData,
		isHasUtoch;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	const createHeadPanel = function () {
		const mainData = Ext.clone(moduleData.initData.data);
		return createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			rejectReasonText: rejectReasonText,
			noLinked: false,
			linkedUpdUkdData: updUkdData
		});
	};

	const createMainDataBlock = function (docContent) {
		return createFieldBlockForDetails({
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(docContent, 'number')
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.renderers.fnsDateFromClient(edi.utils.getObjectProperty(docContent, 'doctime'))
						}
					]
				})
			]
		});
	};

	const createSellerBlock = function () {
		const seller = createOrgSelector({
			is_valid: true,
			fieldValues: moduleData.initData.data.fromOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.fromOrg)
				: null,
			readOnly: true
		});
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.seller'),
			items: [seller]
		});
	};

	const createBuyerBlock = function () {
		const buyer = createOrgSelector({
			is_valid: true,
			fieldValues: moduleData.initData.data.toOrg
				? edi.converters.convertOrgToPartie(moduleData.initData.data.toOrg)
				: null,
			readOnly: true
		});
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.buyer'),
			items: [buyer]
		});
	};

	const createAttachmentsGrid = function () {
		const columns = edi.columns.get('on_service_container');
		const actionButtonConfig = [
			{
				glyph: edi.constants.ICONS.PRINT,
				testCls: 'test-action-column-print',
				handler: function (grid, rowIndex) {
					const record = grid.getStore().getAt(rowIndex),
						recordData = record.getData();
					edi.document.actions.createPrintModal(
						recordData,
						{
							usingReport: true
						},
						recordData.id
					);
				}
			}
		];

		columns.push(
			createActionsColumnConfig({
				align: 'right',
				items: actionButtonConfig
			})
		);

		return createGrid({
			proxy: createProxyConfig({
				type: 'pagingmemory',
				data: attachmentsStoreData
			}),
			storeConfig: {
				pageSize: edi.constants.MAX_PAGE_SIZE,
				model: edi.models.getModel('DOCUMENT_ON_SERVICE'),
				remoteFilter: true,
				sortOnLoad: false,
				sorters: {
					property: 'name',
					direction: 'ASC'
				}
			},
			gridConfig: {
				title: edi.i18n.getMessage('document.on.service.container.lines'),
				columns: columns,
				autoScroll: true,
				disablePaging: false,
				disableSelection: false
			}
		});
	};

	/**
	 * Creates details panel of upd
	 * @returns {Object}
	 */
	const createDetailsPanel = function (docContent) {
		return createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6]]
			},
			items: [
				createHeadPanel(docContent),
				createMainDataBlock(docContent),
				createSellerBlock(docContent),
				createBuyerBlock(docContent),
				createAttachmentsGrid(docContent)
			]
		});
	};

	/**
	 * Creates action pane
	 */
	const createModuleActionsPanel = function () {
		const data = moduleData.initData.data;
		const actionsPanel = createActionsPanel();

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			moduleData: moduleData,
			excludeActions: {
				PRINT: true
			},
			actionProps: {
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				REJECT: {
					methodAddOptions: {
						isDisableRejectModal: true
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					addExtendedExport: true,
					hideDefaultExport: true
				}
			}
		});
		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		const data = moduleData.initData.data;

		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.CHILDREN.GET, {
			documentId: data.id
		});
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.HEADER.GET,
				{
					documentId: data.id
				},
				true
			),
			'GET',
			undefined,
			function (respData) {
				moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
				if (respData && respData.data) {
					edi.rest.sendRequest(
						url,
						'GET',
						{},
						function (responseData) {
							if (respData && respData.data && respData.data.id) {
								moduleData.initData.data = respData.data;
								attachmentsStoreData = (responseData && responseData.items) || [];
								const getRejectReason = function (failure) {
									const initData = {};
									initData.data = updUkdData;
									edi.rest.sendRequest(
										edi.document.actions.formatRejectReasonUri(initData),
										'GET',
										null,
										function (data) {
											rejectReasonText =
												data && data.data && data.data.TextNoticeUtoch
													? data.data.TextNoticeUtoch
													: null;
											continueWithRendering();
										},
										failure
									);
								};
								const continueWithRendering = function () {
									moduleData.tab.removeAll();
									const modulePanel = createDetailsModulePanel();
									modulePanel.add(createDetailsPanel(respData.data));
									moduleData.tab.add(createModuleActionsPanel(respData.data));
									moduleData.tab.add(modulePanel);
									if ('function' == typeof initCallBack) {
										initCallBack();
									} else {
										moduleData.tab.setLoading(false);
									}
								};
								if (attachmentsStoreData.length > 0) {
									let count = 0;
									attachmentsStoreData.forEach(function (content, index) {
										if (
											content.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD ||
											content.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD
										) {
											isHasUtoch =
												edi.utils.getObjectProperty(
													content,
													'attributes.utochCreated.value'
												) === 'true';
										}
										edi.rest.sendRequest(
											edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
												documentId: content.id
											}),
											'GET',
											{},
											function (data) {
												if (data && data.data) {
													let senderSignatureExpected = edi.utils.getObjectProperty(
														data.data,
														'reference.senderSignatureExpected'
													);
													const condition =
														content.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
														content.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD;
													if (condition) {
														senderSignatureExpected = 'true';
														updUkdData = content;
														edi.utils.setObjectProperty(
															attachmentsStoreData[index],
															'senderSignatureExpected',
															senderSignatureExpected === 'true'
														);
													} else {
														const file = edi.utils.getObjectProperty(data.data, 'file');
														if (file) {
															edi.utils.setObjectProperty(content, 'file', file);
														}
														edi.utils.setObjectProperty(
															content,
															'senderSignatureExpected',
															senderSignatureExpected === 'true'
														);
													}
													count += 1;
													if (count === attachmentsStoreData.length) {
														if (isHasUtoch) {
															getRejectReason(failure);
														} else {
															continueWithRendering();
														}
													}
												}
											},
											failure
										);
									});
								} else {
									continueWithRendering();
								}
							}
						},
						failure
					);
				}
				edi.events.documents.fireEvent('change');
			},
			failure
		);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
