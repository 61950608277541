import { createModulePanel } from '@Components/panels';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';
import { createLabel } from '@Components/fields';
import {
	createGrid,
	createActionsColumnConfig,
	createGridActionBar,
	createGridCheckboxSelectionModel
} from '@UIkit/components/grid';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { checkExecutorMatchesApprovalStage } from './methods';

import { createTabPanel } from '@UIkit/components/tab/index';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { MODAL_SIZE } from '@UIkit/components/modal';

Ext.namespace('edi.modules');
edi.modules['approval.documents'] = function () {
	var moduleData,
		tabPanel,
		grids,
		filterForm,
		fireSearch,
		filterObjects = {},
		currentUser;

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		currentUser = edi.core.getUserData();
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.approval_documents.on('change', gridDataChangedHandler);
		edi.events.approval_documents.on('delete', gridDataChangedHandler);
	};

	/**
	 * Initialization of module filter panel
	 */
	var createFilterForm = function () {
		fireSearch = function () {
			for (var i in grids) {
				if (grids.hasOwnProperty(i)) {
					if (!filterObjects[i]) {
						filterObjects[i] = edi.filters.createGridFilter(
							edi.rest.services.APPROVAL_DOCUMENTS.GET,
							filterForm,
							grids[i],
							edi.filters.config.ew_approvals.createArgs,
							{ gridName: i }
						);
					}
					filterObjects[i].filter();
				}
			}
		};
		let { formItemsMap, items } = edi.filters.config.ew_approvals.createFormItems();
		return createModuleFilterForm(
			{
				saveFilter: {
					name: `${moduleData.modName}_grid`
				},
				border: 1,
				formItemsMap,
				items,
				setFormDefaults: function () {
					let defaultModifyDateRange = edi.utils.getUserDefaultSearchPeriod('week');
					formItemsMap.creationDateRange.activatePeriod(defaultModifyDateRange);
				}
			},
			fireSearch
		);
	};

	/**
	 * Downloads archived document
	 * @param    {Object}    data    header data
	 */
	var downloadArchive = function (data) {
		edi.rest.downloadFile(
			edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.EXPORT.GET, {
				id: data.headerId
			}),
			data.headerId
		);
	};

	var openModuleHandler = function (recordData) {
		if (recordData?.route?.routeType === 'DYNAMIC') {
			edi.core.openModule('approval.employees.details', recordData, recordData.title);
		} else {
			edi.core.openModule('approval.documents.details', recordData, recordData.title);
		}
	};

	/**
	 * Downloads documents selected in grid
	 * @param	{Object}	grid
	 * @param	{String}	exportType
	 */
	let exportGroupHandler = function (grid, exportType) {
		let percent,
			progress,
			packId,
			userStopMe = false;

		let success = function (resp) {
			if (!userStopMe) {
				if (resp && resp.data && resp.data.hasOwnProperty('percentageOfReadiness')) {
					percent = resp.data.percentageOfReadiness;
				}
				if (progress) {
					progress.updateProgress(percent / 100, percent + ' %');
					if (percent === 100) {
						if (
							resp.data.hasOwnProperty('state') &&
							(edi.constants.EXPORT_GROUP_REQUEST.STATE.READY === resp.data.state ||
								edi.constants.EXPORT_GROUP_REQUEST.STATE.PROCESSED_WITH_ERROR === resp.data.state)
						) {
							let fileUrl = edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.EXPORT.GET, {
								id: resp.data.id
							});
							edi.rest.downloadFile(fileUrl);

							progress.hide();
							progress = null;
						} else {
							setTimeout(getExportedDocStatus, 1000);
						}
					} else {
						setTimeout(getExportedDocStatus, 1000);
					}
				}
			} else {
				if (progress) {
					progress.hide();
					progress = null;
				}
				let closeMsg = Ext.Msg.show({
					closable: false,
					title: edi.i18n.getMessage('export.group.request.progress.title'),
					msg: edi.i18n.getMessage('export.group.request.progress.close'),
					width: MODAL_SIZE.widthMedium,
					buttons: Ext.MessageBox.YESNO,
					buttonText: {
						no: edi.i18n.getMessage('form.btn.stop'),
						yes: edi.i18n.getMessage('form.btn.download.later')
					},
					fn(val) {
						if ('yes' === val) {
							closeMsg.close();
						} else if ('no' === val) {
							let cancelUrl = edi.utils.formatString(
								edi.rest.services.EXPORT.GROUP_REQUEST.PUT,
								{ id: packId },
								true
							);
							edi.rest.sendRequest(cancelUrl, 'PUT', Ext.encode({ REJECT: true }));
						}
					}
				});
			}
		};

		let failure = function (data) {
			if (progress && userStopMe) {
				progress.hide();
				progress = null;
			}
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.accept.registry.find'));
			moduleData.tab.setLoading(false);
		};

		let getExportedDocStatus = function () {
			let url = edi.utils.formatString(edi.rest.services.EXPORT.GROUP_REQUEST.GET, { id: packId });
			edi.rest.sendRequest(url, 'GET', null, success, failure);
		};

		let startExportDoc = function () {
			percent = 0;
			progress = Ext.Msg.show({
				closable: false,
				title: edi.i18n.getMessage('export.group.request.progress.title'),
				msg: edi.i18n.getMessage('export.group.request.progress.msg'),
				progress: true,
				width: MODAL_SIZE.widthMedium,
				progressText: percent + ' %',
				buttons: Ext.MessageBox.NO,
				buttonText: {
					no: edi.i18n.getMessage('form.btn.close')
				},
				fn: function (val) {
					if ('no' === val) {
						userStopMe = true;
					}
				}
			});
			getExportedDocStatus();
		};

		edi.document.actions.documentGridActionProcess({
			grid: grid,
			moduleData: moduleData,
			groupProcessedUrl: edi.rest.services.EXPORT.GROUP_REQUEST.POST,
			action: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP,
			processMethod: 'POST',
			genParamsMethod(records) {
				let allowedDocs = records.map((rec) => rec.getData());
				return {
					objectIds: allowedDocs.map((doc) => edi.utils.getObjectProperty(doc, 'header.id'))
				};
			},
			actionCheckCallback: function () {
				return true;
			},
			completeEvent: false,
			completeCallback(docs, resp) {
				if (resp && resp.data && resp.data.id) {
					userStopMe = false;
					packId = resp.data.id;
					startExportDoc();
				} else {
					edi.core.showInfo('accept.registry.nothing.to.search');
					moduleData.tab.setLoading(false);
				}
			}
		});
	};

	var createActionColumn = function (gridName) {
		let actionColumns = [];
		var failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
				moduleData.tab.setLoading(false);
			});
		};
		actionColumns = actionColumns.concat(
			{
				tooltip: edi.i18n.getMessage('document.send.to.approve.document'),
				glyph: edi.constants.ICONS.SEND,
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					edi.rest.sendRequest(
						edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.PUSH.PUT, {
							id: record.get('id')
						}),
						'PUT',
						null,
						function () {
							moduleData.tab.setLoading(false);
							edi.events.approval_documents.fireEvent('change');
						},
						failure,
						null
					);
				},
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return record.get('state') !== edi.constants.APPROVAL_DOCUMENTS.STATE.DRAFT;
				}
			},
			{
				tooltip: edi.i18n.getMessage('ediweb.columns.action.delete'),
				glyph: edi.constants.ICONS.DELETE,
				handler: function (grid, rowIndex) {
					moduleData.tab.setLoading();
					const record = grid.getStore().getAt(rowIndex);
					var title = record.get('title') + ' №' + record.get('docNumber');
					var postData = {};
					postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE] = true;
					var stringified = Ext.encode(postData);
					var removeLoading = function () {
						moduleData.tab.setLoading(false);
					};
					edi.core.confirm(
						edi.i18n.getMessage('ew.documents.approvals.confirm.title'),
						edi.i18n.getMessage('ew.documents.approvals.confirm.content', [title]),
						function () {
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.APPROVAL_DOCUMENTS.DELETE.ONE.PUT, {
									id: record.get('id')
								}),
								'PUT',
								stringified,
								function () {
									moduleData.tab.setLoading(false);
									edi.events.approval_documents.fireEvent('change');
								},
								failure
							);
						},
						removeLoading,
						undefined,
						removeLoading
					);
				},
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return (
						record.get('state') !== edi.constants.APPROVAL_DOCUMENTS.STATE.DRAFT ||
						!edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_DELETE')
					);
				}
			},
			{
				tooltip: edi.i18n.getMessage('ediweb.columns.action.edit'),
				glyph: edi.constants.ICONS.EDIT,
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					var recordData = record.getData();
					if (recordData?.route?.routeType === 'DYNAMIC') {
						edi.core.openModule('approval.employees.create', recordData, null, true, null, {
							title: recordData.title
						});
					} else {
						edi.core.openModule('approval.documents.create', recordData, null, true, null, {
							title: recordData.title
						});
					}
				},
				isHidden: function (view, rowIndex, colIndex, item, record) {
					const parentDocType =
						edi.utils.getAttributeByName(record.get('attributes'), 'PARENT_DOC_TYPE') ??
						record.get('data')?.docType;

					return (
						record.get('state') !== edi.constants.APPROVAL_DOCUMENTS.STATE.DRAFT ||
						parentDocType.match(/^EDI_FNS_UPD/) ||
						parentDocType.match(/^EDI_FNS_UKD/)
					);
				}
			},
			{
				tooltip: edi.i18n.getMessage('ediweb.columns.action.details'),
				glyph: edi.constants.ICONS.DETAILS,
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					var recordData = record.getData();
					openModuleHandler(recordData);
				}
			}
		);

		return createActionsColumnConfig({
			items: actionColumns,
			align: 'right'
		});
	};

	let updateTotalsLabel = function (grid, selected) {
		if (!grid || !grid.totalsLabel || !Array.isArray(selected)) {
			return;
		}

		grid.totalsLabel.setText(
			edi.i18n.getMessage('documents.selected.totals', {
				selectedRows: selected.length
			})
		);
	};

	/**
	 * Checkbox selection model processor linked with grid top bar action items
	 * @param grid
	 * @param model
	 * @param selected
	 * @param actionItems
	 * @param isProcessible
	 */
	let selectionProcessor = function (grid, model, selected, actionItems, isProcessible) {
		let i, groupActionBtn, actionConf;
		for (i = 0; i < actionItems.length; i++) {
			groupActionBtn = actionItems[i];
			actionConf = groupActionBtn.rulesData;
			if (isProcessible(actionConf)) {
				var allowedDocs,
					allowFromGridConf = edi.document.actions.availableFromGrid[actionConf.id] || {},
					isAvailableFromGrid = true;

				allowedDocs = selected.filter(function (docRecord) {
					isAvailableFromGrid = true;
					var document = docRecord.getData();

					if (!Ext.isEmpty(allowFromGridConf) && Array.isArray(allowFromGridConf)) {
						Ext.each(allowFromGridConf, function (config) {
							if ('function' == typeof config.allow) {
								//Check action for allow performing from grid if doctypes  aren't specified in alloFromGrid conf
								//Or if  doctype exists in specified doctypes
								if (
									!config.documents ||
									(Array.isArray(config.documents) && config.documents.indexOf(document.type) > -1)
								) {
									isAvailableFromGrid = config.allow(document);
								}
							}
						});
					}
					docRecord.set('docType', edi.constants.APPROVAL_DOCUMENTS.TYPE);
					docRecord.set('type', edi.constants.APPROVAL_DOCUMENTS.TYPE);
					return (
						isAvailableFromGrid &&
						edi.action.isAvailable(actionConf.id, edi.action.getDocumentData(docRecord))
					);
				});
				// if (edi.constants.DOCUMENT_ACTIONS.DELETE === actionConf.id && allowedDocs.length > 1) {
				// 	groupActionBtn.setDisabled(true);
				// }
				// else
				if (!actionConf.notDisabled) {
					groupActionBtn.setDisabled(!allowedDocs.length);
				}
				if (edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP === actionConf.id) {
					var canFullExport = true;
					let buttonModify = (btn, callback) => {
						if (btn.rulesData.itemId === edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON) {
							callback(btn, canFullExport);
						}
					};

					if (!!groupActionBtn.menu) {
						groupActionBtn.menu.items.each((item) => {
							buttonModify(item, (btn, flag) => {
								btn.setDisabled(!flag);
							});
						});
					} else {
						buttonModify(groupActionBtn, (btn, flag) => {
							btn.setVisible(flag);
						});
					}
				}
			}
		}
		updateTotalsLabel(grid, selected);
	};

	var createGridCmp = function (gridName, columns) {
		columns = columns ? columns : [];
		var actions = createActionColumn(gridName),
			tabArray = [];

		var actionItems = [],
			topBar,
			totalsLabel = createLabel({
				text: edi.i18n.getMessage('documents.selected.totals', {
					selectedRows: 0
				}),
				cls: 'edi-tbar-total-label-right'
			});

		if (actions !== null) {
			columns.push(actions);
		}

		if (edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_DELETE')) {
			actionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.DELETE,
				name: edi.i18n.getMessage('ediweb.columns.action.delete'),
				isAvailableFunc: function (record) {
					return record.get('state') === edi.constants.APPROVAL_DOCUMENTS.STATE.DRAFT;
				}
			});
		}
		actionItems.push({
			id: edi.constants.DOCUMENT_ACTIONS.EXPORT_GROUP,
			name: edi.i18n.getMessage('export.group.request.menu.tooltip'),
			menu: [
				{
					name: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					itemId: edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON,
					handler: function () {
						exportGroupHandler(grid, edi.constants.EXPORT_GROUP_REQUEST.TYPE.COMMON);
					}
				}
			]
		});

		var createBtn = createButton({
			text: edi.i18n.getMessage('form.btn.create'),
			cls: BUTTON_CLS.primary,
			glyph: edi.constants.ICONS.PLUS,
			menu: new Ext.menu.Menu({
				plain: true,
				hideMode: 'display',
				items: [
					{
						text: edi.i18n.getMessage('approval.employees'),
						handler: function () {
							edi.core.openModule('approval.employees.create');
						}
					},
					{
						text: edi.i18n.getMessage('approval.documents.route'),
						handler: function () {
							edi.core.openModule('approval.documents.create');
						}
					}
				]
			})
		});

		if (actionItems.length > 0) {
			var groupActionsItemClick = function (id) {
				var options = {
					grid: grid,
					moduleData: moduleData,
					action: id
				};
				if (edi.constants.DOCUMENT_ACTIONS.DELETE === id) {
					Object.assign(options, {
						processProperties: (function () {
							var props = {};
							props[edi.constants.BUSINESS_PROCESS_PROPERTIES.DELETE] = true;
							return props;
						})(),
						processUrlParamCollector: function (record) {
							return {
								id: record.get('id')
							};
						},
						processUrl: edi.rest.services.APPROVAL_DOCUMENTS.DELETE.ONE.PUT,
						processMethod: 'PUT',
						processEvent: {
							object: 'approval_documents',
							event: 'delete'
						},
						actionCheckCallback: function (record) {
							return record.get('state') === edi.constants.STATE.DRAFT;
						},
						model: edi.models.getModel('EW_APPROVAL_DOCUMENTS'),
						modalWidth: 864,
						columns: edi.columns.get('ew_approvals_modal'),
						loadingMessage: 'ew.documents.approvals.delete.started',
						confirmTitle: 'ew.documents.approvals.delete.confirm.title',
						confirmQuestion: 'ew.documents.approvals.delete.confirm.question',
						notReadyTitle: 'document.delete.notready.title',
						notReadyMsg: 'ew.documents.approvals.delete.notready.text',
						confirmAction: true
					});
				}
				edi.document.actions.documentGridActionProcess(options);
			};
			topBar = createGridActionBar({
				actionCfgs: actionItems,
				defaultHandler: groupActionsItemClick,
				totalsLabel: totalsLabel
			});
			tabArray = [topBar];
			if (edi.permissions.hasPermission('CLIENT_APPROVAL_DOCUMENTS_CREATE')) {
				tabArray.unshift(createBtn);
			}
		}

		var grid = createGrid({
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: edi.models.getModel('EW_APPROVAL_DOCUMENTS'),
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: false
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				cls: 'edi-documents-grid edi-grid-with-toolbar-buttons edi-ediweb-approvals-grid edi-ediweb-grid-without-tools',
				region: 'center',
				selModel:
					topBar && topBar.menuActionItemsCnt
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor(model, selected, actionItems, isProcessible) {
									selectionProcessor(grid, model, selected, actionItems, isProcessible);
								}
						  })
						: undefined,
				tbar: tabArray,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						var recordData = record.getData();
						openModuleHandler(recordData);
					}
				}
			},
			viewConfig: {
				getRowClass: function (rec) {
					if (rec.get('state') === edi.constants.APPROVAL_DOCUMENTS.STATE.ON_APPROVAL) {
						return checkExecutorMatchesApprovalStage(rec.getData(), 'APPROVAL_REFUSAL') ||
							checkExecutorMatchesApprovalStage(rec.getData(), 'SIGNING_REFUSAL')
							? 'edi-ediweb-approvals-grid-executor-self'
							: '';
					}
				},
				emptyTextTplOptions: {
					enabled: true,
					iconName: 'approval-documents',
					title: edi.i18n.getMessage('ediweb.approval.documents.empty.text.tpl.title'),
					contentText: edi.i18n.getMessage('ediweb.approval.documents.empty.text.tpl.contentText'),
					beforeButtonText: edi.i18n.getMessage('ediweb.approval.documents.empty.text.tpl.beforeButtonText'),
					buttonText: edi.i18n.getMessage('ediweb.approval.documents.empty.text.tpl.buttonText'),
					buttonName: 'emptyTextButton',
					buttonClickHandler: function (e, btn, name) {
						filterForm.toggleHandler();
					}
				}
			},
			pagingBarConfig: {
				allowExport: false,
				itemsPerPage: edi.constants.ITEMS_PER_PAGE,
				totalsLabel: topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined
			}
		});
		grid.totalsLabel = topBar && topBar.menuActionItemsCnt ? totalsLabel : undefined;
		updateTotalsLabel(grid, []);
		return grid;
	};

	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});

		tabPanel = createTabPanel({
			margin: '16 0 0 0'
		});

		grids = {
			part: createGridCmp('part', edi.columns.get('ew_approvals')),
			self: createGridCmp('self', edi.columns.get('ew_approvals')),
			all: createGridCmp('all', edi.columns.get('ew_approvals'))
		};

		var isFirst = true;
		var activeTab = edi.utils.getObjectProperty(moduleData, 'initData.data.activeTab');
		if (activeTab && grids.hasOwnProperty(activeTab)) {
			isFirst = false;
		}
		for (var i in grids) {
			if (grids.hasOwnProperty(i)) {
				initTabWithGrid(
					i,
					grids[i],
					isFirst || activeTab === i,
					tabPanel,
					{
						title: 'ediweb.approvals.tab.' + i,
						callback: () => {}
					},
					{
						badgeCount: true,
						hideIcon: true
					}
				);

				isFirst = false;
			}
		}
		filterForm = createFilterForm();
		modulePanel.add(filterForm);

		modulePanel.add(tabPanel);
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
		}
		filterForm.fireSearch();
	};

	var gridChangeHandler = function (grid) {
		var store = grid.getStore();
		var gridSelModel = grid.getSelectionModel();
		if (gridSelModel) {
			gridSelModel.deselectAll();
		}
		store.reload();
	};

	/**
	 * Reloads grid and resets filter form
	 */
	var gridDataChangedHandler = function () {
		for (var i in grids) {
			if (grids.hasOwnProperty(i)) {
				gridChangeHandler(grids[i]);
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.approval_documents.un('change', gridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name, null);
		return true;
	};
};
