Ext.namespace('edi.navigation');

edi.navigation.addNavigationConfig(
	(function () {
		var navConfigItems = {
			documents_create: {
				order: 100,
				title: 'navigation.documents.create',
				glyph: edi.constants.ICONS.LIBRARY_ADD,
				children: {
					order_create: {
						title: 'document.order',
						modName: 'document.create.order',
						order: 100
					},
					deljit_create: {
						title: 'document.deljit',
						modName: 'document.create.deljit',
						order: 100
					},
					torg2: {
						title: 'document.torg2',
						modName: 'document.create.torg_2',
						order: 100
					},
					recadv_create: {
						title: 'document.recadv',
						modName: 'document.create.recadv',
						order: 200
					},
					retann_create: {
						title: 'document.retann',
						modName: 'document.create.retann',
						order: 201
					},
					desadv_create: {
						title: 'document.desadv',
						modName: 'document.create.desadv',
						order: 300
					},
					alcdes_create: {
						title: 'document.alcdes',
						modName: 'document.create.alcdes',
						order: 400
					},
					invoice_create: {
						title: 'document.invoice',
						modName: 'document.create.invoice',
						order: 500
					},
					dsf_create: {
						title: 'document.dsf',
						modName: 'document.create.dsf',
						order: 600
					},
					dsf_client_create: {
						title: 'document.dsf_client',
						modName: 'document.create.dsf_client',
						order: 610
					},
					formalized_create: {
						title: 'document.formalized',
						modName: 'document.create.formalized',
						order: 650
					},
					iftmbf_create: {
						title: 'document.iftmbf',
						modName: 'document.create.iftmbf',
						order: 900
					},
					iftmbc_create: {
						title: 'document.iftmbc',
						modName: 'document.create.iftmbc',
						order: 1000
					},
					pricateancom_create: {
						title: 'document.pricateancom',
						action: 'methods.openPricatCreateModule',
						permissions: ['CREATE_PRICAT_EANCOM'],
						order: 1000
					},
					commonpricat_create: {
						title: 'document.commonpricat',
						modName: 'document.create.commonpricat',
						order: 1100
					},
					documents_package: {
						title: 'document.package.navigation.category',
						permissions: ['CREATE_DP_CONTAINER'],
						order: 1100,
						children: {
							user_profile: {
								title: 'document.package.navigation.item',
								modName: 'document.create.package',
								order: 100
							},
							package_ne: {
								title: 'document.package.ne.navigation.item',
								modName: 'document.create.package.ne',
								order: 200
							},
							on_service_container: {
								title: 'document.on.service.container.navigation.item',
								modName: 'document.create.on.service.container',
								order: 300
							},
							package_dp: {
								title: 'document.package.dr.navigation.item',
								modName: 'document.create.package.dr',
								order: 200
							}
						}
					},
					upload_file: {
						title: 'navigation.document.web.upload.file',
						action: 'methods.showUploadDocumentDialog',
						permissions: ['WEB_UPLOAD_EDI_FILES'],
						order: 10000
					}
				}
			},
			documents: {
				order: 200,
				title: 'navigation.documents.all',
				modName: 'documents',
				glyph: edi.constants.ICONS.LIBRARY_BOOKS
			},
			document_packages: {
				order: 230,
				glyph: edi.constants.ICONS.BOOK_OPEN,
				title: 'navigation.document.packages',
				modName: 'document.packages'
			},
			archive: {
				order: 240,
				glyph: edi.constants.ICONS.ANIMATION,
				title: 'navigation.archive',
				modName: 'document.archive'
			},
			control_panel: {
				delimiter: true,
				order: 400,
				title: 'navigation.control.panel',
				glyph: edi.constants.ICONS.SETTINGS,
				children: {
					user_profile: {
						title: 'navigation.user.profile',
						modName: 'user.profile',
						order: 100
					},
					organization_partners: {
						title: 'org.partners',
						modName: 'partners',
						order: 200
					},
					catalogs_v2: {
						title: 'navigation.catalogs',
						order: 300,
						children: {
							catalogs_v2: {
								title: 'navigation.catalogs',
								modName: 'catalogs.v2',
								order: 100
							},
							partin_create: {
								title: 'navigation.catalog.partin.create',
								modName: 'partin.create',
								order: 200
							}
						}
					},
					deferred_reports: {
						order: 400,
						title: 'navigation.deferred.reports.grid',
						modName: 'deferred.reports'
					},
					export_group_request: {
						title: 'navigation.export.group.request',
						modName: 'export.group.request',
						order: 500,
						permissions: ['READ_EDI_GROUP_EXPORT_REQUEST']
					},
					organizations_settings: {
						title: 'organization.settings',
						order: 600,
						children: {
							rounding_settings: {
								title: 'rounding.settings',
								modName: 'rounding.settings',
								order: 100
							},
							email_notification: {
								title: 'email.notification',
								modName: 'email.notification',
								order: 200
							},
							generate_numbers: {
								title: 'document.generation.numbers',
								modName: 'patterns.generation.doc.num',
								permissions: ['CLIENT_NUMBERATION_SETTINGS'],
								order: 300
							}
						}
					},
					other_settings: {
						title: 'navigation.other.settings',
						order: 800,
						children: {
							invitations: {
								title: 'navigation.invitations',
								modName: 'invitations',
								order: 100
							},
							invitations_create: {
								title: 'navigation.invitation.create',
								action: 'methods.invitations.create',
								permissions: ['CREATE_INVITATION_TO_COOPERATION'],
								order: 200
							},
							roseuInvitations: {
								title: 'navigation.roseu_invitations',
								modName: 'roseu_invitations',
								order: 210
							},
							roseuInvitationCreate: {
								title: 'navigation.roseu_invitation.create',
								modName: 'roseu_invitation.create',
								order: 211
							},
							file_storage: {
								title: 'file.storage',
								modName: 'user.file.storage',
								order: 300
							}
						}
					}
				}
			},
			help_desk: {
				order: 500,
				title: 'navigation.help.desk',
				url: 'https://support.ediweb.com',
				glyph: edi.constants.ICONS.HELP
			}
		};

		return {
			name: 'edi',
			items: navConfigItems
		};
	})()
);
