import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { EDI_SLSRPT_LINE_COLUMN } from './columns';
import { EDI_SLSRPT_LINE_MODEL, EDI_SLSRPT_REFERENCE_ELEMENTS_MODEL } from './models';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';

/**
 * Class for new free format document details
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.details.slsrpt'] = function () {
	var moduleData,
		docData,
		fields = {},
		sellerOrg,
		buyerOrg,
		productValues = {
			products: []
		};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates dsf panel
	 * @returns {Object}
	 */
	var createDetailsPanel = function (documentData) {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSumm: true,
			noSignatures: true,
			noUsage: true
		});

		var headData = createPanel({
			layout: 'column',
			margin: '10 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('payments.factory.reports.form.number'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(documentData, 'SalesReport-Header.SalesReportNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createField({
					title: edi.i18n.getMessage('fhp.reports.summary.reportDate'),
					input: createDateLabel({
						margin: '0 0 0 5',
						columnWidth: 0.65,
						text: edi.utils.getObjectProperty(documentData, 'SalesReport-Header.SalesReportDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.25
					}
				})
			]
		});

		fields.periodDate = createPanel({
			layout: 'column',
			margin: '10 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.slsrpt.period.start.date'),
					input: createDateLabel({
						columnWidth: 0.6,
						text: edi.utils.getObjectProperty(documentData, 'SalesReport-Header.PeriodStartDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('payments.factory.report.to.date'),
					input: createDateLabel({
						columnWidth: 0.75,
						text: edi.utils.getObjectProperty(documentData, 'SalesReport-Header.PeriodEndDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				})
			]
		});

		fields.commercialNetwork = createPanel({
			layout: 'column',
			margin: '10 0 5',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.slsrpt.commertial.networks'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(documentData, 'SalesReport-Header.Remarks'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var fieldsMap = {
			company_name: 'name',
			company_iln: 'iln',
			company_kpp: 'kpp',
			company_inn: 'inn'
		};

		var seller = createCompanySelector({
			title: 'document.seller',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			margin: '5 0 10',
			fieldsMapOnly: true,
			is_valid: true,
			readOnly: true,
			valuesByMap: true,
			fieldValues: sellerOrg,
			fieldsMap: fieldsMap
		});

		var buyer = createCompanySelector({
			title: 'document.buyer',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			margin: '5 0 10',
			fieldsMapOnly: true,
			is_valid: true,
			fieldsMap: fieldsMap,
			readOnly: true,
			fieldValues: buyerOrg,
			valuesByMap: true
		});

		var twoColumnsLayout = createTwoColumnsLayout(
			[headData, seller, buyer, fields.periodDate, fields.commercialNetwork],
			[],
			0.6,
			{
				items2Conf: {
					margin: '0 0 0 10'
				}
			}
		);

		var lines = edi.utils.getObjectProperty(documentData, 'SalesReport-Lines', true);
		var linesData = [];
		if ('string' != typeof lines) {
			var lineNumber = 0;
			for (var i = 0; i < lines.length; i++) {
				var values = lines[i]['Line'],
					line = {};
				values.forEach(function (item) {
					edi.utils.getObjectProperty(item, 'Line-Location.Line-Items', true).forEach(function (lineItems) {
						edi.utils.getObjectProperty(lineItems, 'Line-Item', true).forEach(function (lineItem) {
							lineNumber = ++lineNumber;
							line = lineItem;
							line['LineNumber'] = lineNumber;
							line['LocationParty'] = edi.utils.getObjectProperty(item, 'Line-Location.LocationParty');
							line['LineLocation-Summary'] = edi.utils.getObjectProperty(
								item,
								'Line-Location.LineLocation-Summary'
							);
							linesData.push(line);
						});
					});
				});
			}
		}

		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: EDI_SLSRPT_LINE_MODEL,
			gridColumnConfig: EDI_SLSRPT_LINE_COLUMN,
			data: linesData,
			hasTotal: false,
			readOnly: true,
			totalsHandler: function (component) {
				component.callback(component.getValues());
			},
			modalFormConfig: {
				title: 'document.order.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.item.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.item.amount.written.off'),
								name: 'SenderItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.item.return.quantity'),
								name: 'ReturnQuantity'
							},
							{
								title: edi.i18n.getMessage('line.item.item.plu.code'),
								name: 'StatisticalSales'
							},
							{
								title: edi.i18n.getMessage('product.unit.name'),
								name: 'UnitOfMeasure'
							},
							{
								type: 'editableGrid',
								name: 'Line-Reference.Reference-Elements.Reference-Element',
								config: {
									fields: [
										{
											title: edi.i18n.getMessage('documents.list.document.type'),
											name: 'Reference-Type'
										},
										{
											title: edi.i18n.getMessage('collections.cleaner.condition.value'),
											name: 'Reference-Id'
										}
									],
									autoScroll: true,
									limit: 20,
									model: EDI_SLSRPT_REFERENCE_ELEMENTS_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										minHeight: 200,
										title: edi.i18n.getMessage('document.iftmbf_approval.cargo.name')
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.location.party'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.location.party.iln'),
								name: 'LocationParty.ILN',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('column.addresses.type'),
								name: 'LocationParty.LocationPartyType'
							},
							{
								title: edi.i18n.getMessage('column.additional.attribute.name'),
								name: 'LocationParty.Name'
							},
							{
								title: edi.i18n.getMessage('line.item.item.description'),
								name: 'LocationParty.Branch'
							},
							{
								title: edi.i18n.getMessage('line.item.location.party.region'),
								name: 'LocationParty.Region'
							}
						]
					}
				]
			}
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, productsGrid]
		});
	};
	/**
	 * Creates action panel above the data panel
	 */
	var createModuleActionsPanel = function (docData) {
		var data = moduleData.initData.data;
		docData.id = data.id;
		var actionsPanel = createActionsPanel();
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);

		var excludeActions = {};
		excludeActions[edi.constants.DOCUMENT_ACTIONS.SIGN] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			excludeActions: excludeActions,
			actionProps: {
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				productValues = {
					products: []
				};
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel(),
			data = moduleData.initData.data;

		sellerOrg = data.toOrg;
		buyerOrg = data.fromOrg;

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					docData = data.data;
					var continueLoading = function () {
						modulePanel.add(createDetailsPanel(docData));
						var actionsPanel = createModuleActionsPanel(docData);
						moduleData.tab.add(actionsPanel);
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						} else {
							moduleData.tab.setLoading(false);
						}
					};
					continueLoading();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
