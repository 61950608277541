import { AMOUNT_FIELD_NAME, PACKING_ID_FIELD_NAME } from './createTree';
import { isPacking, isProduct, Packing, PackingId, Product, Tree, UnconvertedLine } from './definitions';

/**
 * Checks if additional processing is needed for prices
 */
const isAdditionalProcessingNeeded = function (values: Product, taxCalculationMethod: string): boolean {
	return (
		((!Ext.isNumeric(values.UnitNetPrice) &&
			(taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.NET_PRICE ||
				taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.WITHOUT)) ||
			(!Ext.isNumeric(values.UnitGrossPrice) &&
				taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.GROSS_PRICE)) &&
		(Ext.isNumeric(values.ProductGrossAmount) ||
			Ext.isNumeric(values.ProductNetAmount) ||
			Ext.isNumeric(values.ProductTaxAmount))
	);
};

/**
 * Convert line into flatter structure
 */
const flattenLineValues = function (line: AnyObject): AnyObject {
	let flattedLine: AnyObject = line['Line-Item'];
	flattedLine['Package-Identification'] = line['Package-Identification'];
	flattedLine['Line-AdditionalInformation'] = line['Line-AdditionalInformation'];

	let lineParties = edi.utils.getObjectProperty(line, 'Line-Parties.Line-Party', true);
	if (lineParties.length) {
		flattedLine['LinePartyName'] = lineParties[0].Name;
	}

	let certificates = edi.utils.getObjectProperty(line, 'Line-Certificate.Certificates.certificate', true);
	if (certificates.length) {
		flattedLine['CertificateNumber'] = certificates[0].Number;
		flattedLine['CertificateDateOfIssue'] = certificates[0].DateOfIssue;
	}

	flattedLine['Line-Reference'] = line['Line-Reference'];
	let refs: AnyObject[] = edi.utils.getObjectProperty(
		flattedLine,
		'Line-Reference.Reference-Elements.Reference-Element'
	);
	if (Array.isArray(refs) && refs.length > 0) {
		let country = edi.utils.getCountryFULLByISO(
			edi.utils.getObjectProperty(line, 'Line-AdditionalInformation.CountryOfOrigin')
		);
		country = (country && country.display_name) || '';
		let nonABTRefs = refs.filter((r) => r['Reference-Type'] !== 'ABT');
		let customRefs = refs
			.filter((r) => r['Reference-Type'] === 'ABT')
			.map((r) => ({
				'Reference-Id': r['Reference-Id'],
				countryOrigin: country
			}));
		edi.utils.setObjectProperty(flattedLine, 'Line-Reference.Reference-Elements.Reference-Element', nonABTRefs);
		if (customRefs.length > 0) {
			edi.utils.setObjectProperty(line, 'Line-Reference.Reference-Elements.Customs-Declarations', customRefs);
		}
	}

	edi.document.actions.setInternalLineId(line, flattedLine);
	return flattedLine;
};

/**
 * Recalculate (mutate) product taxes and prices based on amount
 */
const recalculateProductValues = function (tree: Tree, productValues: Product | AnyObject) {
	if ('function' == typeof tree.valuesCalculator) {
		productValues = tree.valuesCalculator(productValues, undefined, false, true);
	}
	if ('function' == typeof tree.postProcessValues) {
		productValues = tree.postProcessValues(productValues);
	}
	return productValues;
};

/**
 * Separate customFields from products tree and form values
 * @param    {Object}    customFieldsObj
 * @param    {Object}    values
 * @param    {Object[]}    products
 * @param    {string}    topPath
 * @return    {{
 * 		customFieldsData: Object,
 * 		clearValues: {Object},
 * 		clearProducts: Object[]
 * }}
 */
const separateCustomAndNormalFields = function (
	customFieldsObj: AnyObject,
	values: AnyObject,
	products: AnyObject[],
	topPath: string
) {
	let customFieldsData: { [key: string]: string } = {};

	let clearValues = Ext.clone(values || {});
	let formKeysToRemove: string[] = [];
	Object.entries(clearValues)
		.filter(([key, _]) => key.match(topPath))
		.forEach(([key, val]) => {
			let field = edi.methods.custom_fields.getFieldType(customFieldsObj, 'header', key);
			if (
				field &&
				field.fieldType === edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX &&
				typeof val === 'boolean' &&
				val.toString
			) {
				customFieldsData[key] = val.toString();
			} else {
				customFieldsData[key] = val;
			}

			formKeysToRemove.push(key);
		});

	formKeysToRemove.forEach((key) => delete clearValues[key]);

	let productIds: Product['productID'][] = [];
	let packagesCount: { [key: Product['productID']]: number } = {};
	let clearProducts = Ext.clone(products || []);
	clearProducts.forEach((prod) => {
		let prodId = prod.productID;
		let prodNumber = productIds.indexOf(prodId);
		if (prodNumber === -1) {
			productIds.push(prodId);
			prodNumber = productIds.length; //нумерация товаров идет с 1 EDICORE-6602
		} else {
			prodNumber += 1; //нумерация товаров идет с 1 EDICORE-6602
		}

		let prodKeysToRemove: string[] = [];
		Object.entries(prod)
			.filter(([prodKey, _]) => prodKey.match(topPath))
			.forEach(([prodKey, prodVal]) => {
				let customProdKey = customFieldsObj.xPathMap[prodKey];
				let field = edi.methods.custom_fields.getFieldType(customFieldsObj, 'grid', customProdKey);
				customProdKey = customProdKey.replace('@', prodNumber);

				if (
					field &&
					field.fieldType === edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX &&
					typeof prodVal === 'boolean' &&
					prodVal.toString
				) {
					customFieldsData[customProdKey] = prodVal.toString();
				} else {
					customFieldsData[customProdKey] = prodVal;
				}
				prodKeysToRemove.push(prodKey);
			});

		prodKeysToRemove.forEach((key) => delete prod[key]);

		let secondLvlPack = prod.packing;
		if (secondLvlPack) {
			let secondLvlPackNumber = Ext.isNumber(packagesCount[prodId]) ? packagesCount[prodId] + 1 : 0;
			packagesCount[prodId] = secondLvlPackNumber;

			let separatePackKeys = function (pack: AnyObject) {
				let keysToRemove: string[] = [];
				Object.entries(pack)
					.filter(([packKey, _]) => packKey.match(topPath))
					.forEach(([packKey, packVal]) => {
						let customPackKey = customFieldsObj.xPathMap[packKey];
						let field = edi.methods.custom_fields.getFieldType(customFieldsObj, 'grid', customPackKey);

						customPackKey = customPackKey.replace('@', prodNumber).replace('@', secondLvlPackNumber);

						if (
							field &&
							field.fieldType === edi.constants.CUSTOM_FIELDS_TYPES.CHECKBOX &&
							typeof packVal === 'boolean' &&
							packVal.toString
						) {
							customFieldsData[customPackKey] = packVal.toString();
						} else {
							customFieldsData[customPackKey] = packVal;
						}
						keysToRemove.push(packKey);
					});

				keysToRemove.forEach((key) => delete pack[key]);
			};

			separatePackKeys(secondLvlPack);

			let thirdLvlPack = secondLvlPack.packing;
			if (thirdLvlPack) {
				separatePackKeys(thirdLvlPack);
			}
		}
	});

	return {
		customFieldsData,
		clearValues,
		clearProducts
	};
};

const convertDesadvProductsToDesadvCrossProducts = function (
	values: AnyObject[],
	taxCalculationMethod: string,
	precision: number,
	tree: Tree
) {
	let simpleProducts: Product[] = [],
		packings: { [key: PackingId]: Packing } = {},
		uniquePackingsIds: { [key: PackingId]: PackingId } = {},
		convertationSuccessfull = true,
		qntInAllPackages: { [key: Product['productID']]: number } = {};

	precision = typeof precision === 'undefined' ? 2 : precision;

	let recalculateSplitData = function (product: Product) {
		if (isAdditionalProcessingNeeded(product, taxCalculationMethod)) {
			if ('function' == typeof tree.postProcessValues) {
				product = tree.postProcessValues(product, taxCalculationMethod);
			}
		} else {
			let rate = product.TaxRate;
			rate = edi.methods.calculateTaxRate(rate === edi.constants.taxRates.taxNoVat ? 0 : rate);
			let calcData = edi.methods.product.price.recalculation(
				{
					taxRate: +rate / 100,
					amount: product[AMOUNT_FIELD_NAME],
					netPrice: product.UnitNetPrice,
					grossPrice: product.UnitGrossPrice,
					netSum: product.NetAmount,
					taxSum: product.TaxAmount,
					grossSum: product.GrossAmount
				},
				taxCalculationMethod || edi.constants.TAX_CALCULATION_TYPES.NET_PRICE,
				false,
				false,
				precision
			);
			product.GrossAmount = Ext.isNumeric(calcData.grossSum)
				? edi.utils.roundTo(calcData.grossSum, precision)
				: '';
			product.TaxAmount = Ext.isNumeric(calcData.taxSum) ? edi.utils.roundTo(calcData.taxSum, precision) : '';
			product.NetAmount = Ext.isNumeric(calcData.netSum) ? edi.utils.roundTo(calcData.netSum, precision) : '';
		}
		return product;
	};

	let proccessPackageMeasurements = function (pack: AnyObject) {
		let sizes = ['UnitOfMeasure', 'Height', 'Length', 'Width'];
		let weights = ['UnitOfMeasure', 'BoxWeight', 'GrossWeight', 'NetWeight', 'PalletWeight', 'UnitWeight'];
		let measurements = edi.utils.getObjectProperty(pack, 'Package-Measurements');
		if (measurements) {
			sizes.forEach((s) => {
				pack['Size' + s] = edi.utils.getObjectProperty(measurements, 'Size.' + s);
			});
			weights.forEach((w) => {
				pack['Weight' + w] = edi.utils.getObjectProperty(measurements, 'Weight.' + w);
			});
			delete pack['Package-Measurements'];
		}
	};

	let processPacking = function (packingData: Packing, product: Product, allPackingIds: PackingId[]) {
		let packingId = edi.utils.getObjectProperty(packingData, 'Range.ID-Begin'),
			productQuantityInPack = edi.utils.getObjectProperty(packingData, 'QuantityPerPack'),
			productData = Ext.clone(product);
		Ext.merge(productData, product); //что бы не потерялись кастомные поля

		let thirdLevelPacking: Packing = packingData['ThirdLevelPackaging']
			? packingData['ThirdLevelPackaging']
			: ({} as Packing);
		let thirdLevelPackingId = edi.utils.getObjectProperty(thirdLevelPacking, 'Range.ID-Begin') || null;
		delete packingData['ThirdLevelPackaging'];

		productData.ProductQuantityDespatched = product[AMOUNT_FIELD_NAME];
		productData.ProductNetAmount = product.NetAmount;
		productData.ProductTaxAmount = product.TaxAmount;
		productData.ProductGrossAmount = product.GrossAmount;
		productData[AMOUNT_FIELD_NAME] = String(parseFloat(productQuantityInPack) || 0);
		productData.OrderedQuantity = product.OrderedQuantity;
		productData = recalculateSplitData(productData);

		if (!qntInAllPackages[productData.productID]) {
			qntInAllPackages[productData.productID] = 0;
		}
		qntInAllPackages[productData.productID] += productQuantityInPack;
		packingData.packingLevel = 1;
		packingData.childItems = [productData];
		packingData[PACKING_ID_FIELD_NAME] = packingId;
		productData.packingId = packingId;
		productData.secondLvlPackingIds = allPackingIds || [];

		proccessPackageMeasurements(packingData);

		//Save packing and parentId to map to check forewards
		if (!uniquePackingsIds.hasOwnProperty(packingId)) {
			uniquePackingsIds[packingId] = thirdLevelPackingId || null;
		}
		//If packing parent isn't equal to parent we have added in map earlier
		//Means that packing structure was broken
		else if (uniquePackingsIds[packingId] !== thirdLevelPackingId) {
			return false;
		}

		if (thirdLevelPackingId) {
			packingData.packingId = thirdLevelPackingId;
			packingData.thirdLvlPackingId = thirdLevelPackingId;
			if (packings[thirdLevelPackingId]) {
				let parentPacking = packings[thirdLevelPackingId];
				let existedPacking = parentPacking.childItems?.find(function (item: UnconvertedLine) {
					return isPacking(item) && item[PACKING_ID_FIELD_NAME] === packingId;
				});
				if (!!existedPacking) {
					existedPacking.childItems?.push(productData);
				} else {
					parentPacking.childItems?.push(packingData);
				}
			} else {
				thirdLevelPacking.packingLevel = 2;
				thirdLevelPacking.childItems = [packingData];
				thirdLevelPacking[PACKING_ID_FIELD_NAME] = thirdLevelPackingId;
				packings[thirdLevelPackingId] = thirdLevelPacking;
			}
			proccessPackageMeasurements(thirdLevelPacking);
		} else {
			if (packings[packingId]) {
				packings[packingId].childItems?.push(productData);
			} else {
				packings[packingId] = packingData;
			}
		}

		return true;
	};

	//If convertationResult= false, the iteration will be immediately stopped
	values.forEach(function (product) {
		let packs: Packing[] = edi.utils.getObjectProperty(product, 'Package-Identification.Goods-Identity', true);
		delete product['Package-Identification'];
		product.productID = edi.core.getId();

		let productPackingIds = packs.map((p) => edi.utils.getObjectProperty(p, 'Range.ID-Begin'));
		if (packs.length) {
			packs.forEach((packingData) => {
				convertationSuccessfull = processPacking(packingData, product as Product, productPackingIds);
			});
		}

		if (!packs.length || +product[AMOUNT_FIELD_NAME] > qntInAllPackages[product.productID]) {
			let addedProduct = Ext.clone(product);
			addedProduct.ProductQuantityDespatched = product[AMOUNT_FIELD_NAME];
			if (+product[AMOUNT_FIELD_NAME] > qntInAllPackages[product.productID]) {
				addedProduct[AMOUNT_FIELD_NAME] = String(
					+product[AMOUNT_FIELD_NAME] - qntInAllPackages[product.productID]
				);
			}
			addedProduct.ProductNetAmount = product.NetAmount;
			addedProduct.ProductTaxAmount = product.TaxAmount;
			addedProduct.ProductGrossAmount = product.GrossAmount;
			addedProduct.packingId = undefined;
			addedProduct.secondLvlPackingIds = packs.length ? Ext.clone(productPackingIds) : [];
			simpleProducts.push(addedProduct as Product);
		}

		return convertationSuccessfull;
	});

	let processed: (Product | Packing)[];
	if (convertationSuccessfull) {
		simpleProducts.forEach((item) => {
			if (isProduct(item) && qntInAllPackages[item.productID]) {
				item[AMOUNT_FIELD_NAME] = String(+item.ProductQuantityDespatched - qntInAllPackages[item.productID]);
				recalculateSplitData(item);
			}
		});
		processed = Object.values(packings);
		processed = processed.concat(simpleProducts);
	} else {
		//Just return all products without packings as usual product lines
		processed = values.map(function (product) {
			delete product['Package-Identification'];
			return product as Product;
		});
	}

	return {
		result: convertationSuccessfull,
		products: processed
	};
};

const templateCreateDesadvProductLines = function (products: Product[], ultimateCustomerData: AnyObject) {
	let productsInline: AnyObject[] = [];
	let result: AnyObject[] = [];
	let fieldsToSaveOutsideOfProductLine = [
		'Line-License',
		'Line-Parties',
		'Line-Reference',
		'Line-Certificate',
		'Line-AdditionalInformation',
		'Line-Order'
	];
	//Finds same product line in an array of converted products
	let findProduct = function (products: AnyObject[], targetProduct: AnyObject) {
		return products.find(function (convertedProduct) {
			return targetProduct['productID'] === convertedProduct['Line-Item']['productID'];
		});
	};

	let convertProductData = function (product: AnyObject) {
		let productValues = Ext.clone(product);

		delete productValues.packingId;
		delete productValues.items;
		delete productValues.packingLevel;
		delete productValues.packing;

		if (productValues.LinePartyName) {
			productValues['Line-Parties'] = {
				'Line-Party': [
					{
						'Party-Type': 'abc',
						Name: productValues.LinePartyName
					}
				]
			};

			delete productValues.LinePartyName;
		}

		if (productValues.CertificateNumber || productValues.CertificateDateOfIssue) {
			let certificateObj = {
				Number: productValues.CertificateNumber,
				DateOfIssue: productValues.CertificateDateOfIssue
			};

			edi.utils.clearEmptyValues(certificateObj);

			productValues['Line-Certificate'] = {
				Certificates: {
					certificate: [certificateObj]
				}
			};

			delete productValues.CertificateNumber;
			delete productValues.CertificateDateOfIssue;
		}

		if (Array.isArray(productValues['Reference-Elements']) && productValues['Reference-Elements'].length) {
			productValues['Line-Reference'] = {
				'Reference-Elements': {
					'Reference-Element': productValues['Reference-Elements']
				}
			};

			delete productValues['Reference-Elements'];
		}
		edi.utils.setObjectProperty(productValues, AMOUNT_FIELD_NAME, productValues.ProductQuantityDespatched);
		delete productValues.ProductQuantityDespatched;

		edi.utils.setObjectProperty(productValues, 'NetAmount', productValues.ProductNetAmount);
		delete productValues.ProductNetAmount;
		edi.utils.setObjectProperty(productValues, 'TaxAmount', productValues.ProductTaxAmount);
		delete productValues.ProductTaxAmount;
		edi.utils.setObjectProperty(productValues, 'GrossAmount', productValues.ProductGrossAmount);
		delete productValues.ProductGrossAmount;

		let line: AnyObject = {
			'Package-Identification': null //Prevent merge of old package data after moving line from package
		};
		delete productValues[edi.constants.INTERNAL_LINE_ID];
		line['Line-Item'] = productValues;

		fieldsToSaveOutsideOfProductLine.forEach(function (fieldName) {
			let lineData;
			if (productValues[fieldName]) {
				lineData = productValues[fieldName];
				delete productValues[fieldName];
				line[fieldName] = lineData;
			}
		});

		return line;
	};

	let convertPackingData = function (packingValues: AnyObject, productAmount?: number) {
		const res: AnyObject = {
			Type: packingValues.Type,
			Range: {
				[PACKING_ID_FIELD_NAME]: packingValues[PACKING_ID_FIELD_NAME]
			},
			PackagingUnitUniversalCode: packingValues.PackagingUnitUniversalCode,
			PackagingUnitName: packingValues.PackagingUnitName,
			PackagingUnitBuyerCode: packingValues.PackagingUnitBuyerCode,
			PackagingUnitSupplierCode: packingValues.PackagingUnitSupplierCode,
			VSDNumber: packingValues.VSDNumber,
			BatchNumber: packingValues.BatchNumber,
			PackProductionDate: packingValues.PackProductionDate,
			PackExpirationDate: packingValues.PackExpirationDate,
			PackagingUnit: packingValues.PackagingUnit,
			'Package-Measurements': {
				Size: {
					UnitOfMeasure: packingValues.SizeUnitOfMeasure,
					Length: packingValues.SizeLength,
					Width: packingValues.SizeWidth,
					Height: packingValues.SizeHeight
				},
				Weight: {
					UnitOfMeasure: packingValues.WeightUnitOfMeasure,
					NetWeight: packingValues.WeightNetWeight,
					GrossWeight: packingValues.WeightGrossWeight,
					UnitWeight: packingValues.WeightUnitWeight,
					BoxWeight: packingValues.WeightBoxWeight,
					PalletWeight: packingValues.WeightPalletWeight
				}
			}
		};

		if (packingValues.Type === edi.constants.PACKAGE_TYPES.PALLET && ultimateCustomerData) {
			if (ultimateCustomerData.ILN) {
				edi.utils.setObjectProperty(res, 'Destination.ILN', ultimateCustomerData.ILN);
			}

			if (ultimateCustomerData.Name) {
				edi.utils.setObjectProperty(res, 'Destination.Name', ultimateCustomerData.Name);
			}
		}

		if (productAmount) {
			res.QuantityPerPack = productAmount;
		}

		if (Ext.isObject(packingValues.packing) && packingValues.packing.hasOwnProperty(PACKING_ID_FIELD_NAME)) {
			res['ThirdLevelPackaging'] = convertPackingData(packingValues.packing);
		}

		return res;
	};

	let productLineProcessor = function (product: AnyObject) {
		let line = convertProductData(product),
			alreadyAddedProduct = findProduct(result, product);
		//If product have packing, check if this product was already added with some another packing
		if (product.packing && product.packing.hasOwnProperty(PACKING_ID_FIELD_NAME)) {
			let convertedPacking = convertPackingData(product.packing, product[AMOUNT_FIELD_NAME]);
			//Add packing info for already added product
			if (alreadyAddedProduct) {
				if (
					!alreadyAddedProduct['Package-Identification'] ||
					!alreadyAddedProduct['Package-Identification']['Goods-Identity']
				) {
					edi.utils.setObjectProperty(alreadyAddedProduct, 'Package-Identification.Goods-Identity', [
						convertedPacking
					]);
				} else {
					alreadyAddedProduct['Package-Identification']['Goods-Identity'].push(convertedPacking);
				}
			} else if (!product.Type) {
				edi.utils.setObjectProperty(line, 'Package-Identification.Goods-Identity', [convertedPacking]);
				result.push(line);
			}
		} else if (line['Line-Item'].productID && !alreadyAddedProduct) {
			//We skip adding of package lines. We do not add product, if it was already added with packages, as this is just amount that is over than sum of all packages amounts
			result.push(line);
		}
	};

	let counted: { [key: Product['productID']]: boolean } = {};
	let packageLineProcessor = function (packingLine: AnyObject) {
		let packing = Ext.clone(packingLine);
		delete packing.childItems;
		packingLine?.childItems?.forEach((prod: AnyObject) => {
			if (prod.childItems) {
				packingLine.packing = packing;
				productsInline.push(packingLine);
				packageLineProcessor(prod);
			} else {
				prod.packing = packing;
				if (prod.productID && !counted[prod.productID]) {
					counted[prod.productID] = true;
				}
				productsInline.push(prod);
			}
		});
	};
	products.forEach(function (product) {
		if (product.childItems) {
			packageLineProcessor(product);
		} else {
			if (product.productID && !counted[product.productID]) {
				counted[product.productID] = true;
			}
			productsInline.push(product);
		}
	});
	productsInline.forEach(productLineProcessor);
	result.forEach(function (line) {
		delete line['Line-Item'].productID;
	});
	return result;
};

const templateCreateDesadv = function (config: AnyObject) {
	config = config ? config : {};
	let desadvLines: AnyObject[] = [];
	let summary = {};

	if (config.productValues) {
		summary = {
			TotalPSequence: config.productValues.TotalPSequence,
			TotalLines: config.productValues.TotalLines,
			TotalGoodsDespatchedAmount: config.productValues.TotalGoodsDespatchedAmount,
			TotalNetAmount: config.productValues.TotalNetAmount,
			TotalGrossAmount: config.productValues.TotalGrossAmount,
			TotalTaxAmount: config.productValues.TotalTaxAmount,
			TotalWeight: config.productValues.TotalWeight
		};

		let ultimateCustomerData = edi.utils.getObjectProperty(config, 'parties.UltimateCustomer');
		desadvLines = templateCreateDesadvProductLines(config.productValues.products, ultimateCustomerData);
	}

	return {
		'DespatchAdvice-Header': templateCreateDesadvHeader(config['DespatchAdvice-Header']),
		'DespatchAdvice-Parties': config.parties || {},
		'DespatchAdvice-Transport': config['DespatchAdvice-Transport'] || {},
		'DespatchAdvice-Consignment': {
			'Packing-Sequence': {
				Line: desadvLines
			}
		},
		'DespatchAdvice-Summary': summary
	};
};

const templateCreateDesadvHeader = function (config: AnyObject) {
	config = config ? config : {};

	return {
		DespatchAdviceNumber: config.DespatchAdviceNumber,
		Reference: config.Reference,
		AdditionalData: config.AdditionalData,
		DespatchAdviceDate: config.DespatchAdviceDate,
		EstimatedDeliveryDate: config.EstimatedDeliveryDate,
		DespatchDate: config.DespatchDate,
		DespatchTime: config.DespatchTime,
		InvoiceNumber: config.InvoiceNumber,
		InvoiceDate: config.InvoiceDate,
		BuyerOrderNumber: config.BuyerOrderNumber,
		BuyerOrderDate: config.BuyerOrderDate,
		WZTransportDocNumber: config.WZTransportDocNumber,
		DocumentFunctionCode: config.DocumentFunctionCode,
		DocumentNameCode: config.DocumentNameCode,
		Remarks: config.Remarks,
		WaybillNumber: config.WaybillNumber,
		WaybillDate: config.WaybillDate,
		BillOfLadingNumber: config.BillOfLadingNumber,
		BillOfLadingDate: config.BillOfLadingDate,
		UTDnumber: config.UTDnumber,
		UTDDate: config.UTDDate,
		CodeFixEGAIS: config.CodeFixEGAIS,
		DateFixEGAIS: config.DateFixEGAIS,
		TTNRegId: config.TTNRegId,
		ReasonCode: config.ReasonCode,
		ReasonDelayCode: config.ReasonDelayCode,
		DespatchReferenceNumber: config.DespatchReferenceNumber,
		DespatchReferenceDate: config.DespatchReferenceDate
	};
};

const templateCreateDesadvParties = function (config: AnyObject) {
	config = config ? config : {};

	return {
		Buyer: {
			ILN: edi.utils.getObjectProperty(config, 'Buyer.ILN'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'Buyer.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'Buyer.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'Buyer.CodeByCarrier'),
			TaxID: edi.utils.getObjectProperty(config, 'Buyer.TaxID'),
			UtilizationRegisterNumber: edi.utils.getObjectProperty(config, 'Buyer.UtilizationRegisterNumber'),
			Name: edi.utils.getObjectProperty(config, 'Buyer.Name'),

			Type: edi.utils.getObjectProperty(config, 'Buyer.Type'),
			CityName: edi.utils.getObjectProperty(config, 'Buyer.CityName'),
			Country: edi.utils.getObjectProperty(config, 'Buyer.Country'),
			District: edi.utils.getObjectProperty(config, 'Buyer.District'),
			HouseNumber: edi.utils.getObjectProperty(config, 'Buyer.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'Buyer.Housing'),
			Locality: edi.utils.getObjectProperty(config, 'Buyer.Locality'),
			PostalCode: edi.utils.getObjectProperty(config, 'Buyer.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'Buyer.RoomNumber'),
			State: edi.utils.getObjectProperty(config, 'Buyer.State'),
			StateCode: edi.utils.getObjectProperty(config, 'Buyer.StateCode'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'Buyer.StreetAndNumber'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'Buyer.AdditionalInformation')
		},
		Seller: {
			ILN: edi.utils.getObjectProperty(config, 'Seller.ILN'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'Seller.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'Seller.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'Seller.CodeByCarrier'),
			TaxID: edi.utils.getObjectProperty(config, 'Seller.TaxID'),
			UtilizationRegisterNumber: edi.utils.getObjectProperty(config, 'Seller.UtilizationRegisterNumber'),
			Name: edi.utils.getObjectProperty(config, 'Seller.Name'),

			Type: edi.utils.getObjectProperty(config, 'Seller.Type'),
			CityName: edi.utils.getObjectProperty(config, 'Seller.CityName'),
			Country: edi.utils.getObjectProperty(config, 'Seller.Country'),
			District: edi.utils.getObjectProperty(config, 'Seller.District'),
			HouseNumber: edi.utils.getObjectProperty(config, 'Seller.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'Seller.Housing'),
			Locality: edi.utils.getObjectProperty(config, 'Seller.Locality'),
			PostalCode: edi.utils.getObjectProperty(config, 'Seller.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'Seller.RoomNumber'),
			State: edi.utils.getObjectProperty(config, 'Seller.State'),
			StateCode: edi.utils.getObjectProperty(config, 'Seller.StateCode'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'Seller.StreetAndNumber'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'Seller.AdditionalInformation'),

			License: edi.utils.getObjectProperty(config, 'Seller.License')
		},
		DeliveryPoint: {
			ILN: edi.utils.getObjectProperty(config, 'DeliveryPoint.ILN'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'DeliveryPoint.CodeByCarrier'),
			TaxID: edi.utils.getObjectProperty(config, 'DeliveryPoint.TaxID'),
			Name: edi.utils.getObjectProperty(config, 'DeliveryPoint.Name'),

			Type: edi.utils.getObjectProperty(config, 'DeliveryPoint.Type'),
			CityName: edi.utils.getObjectProperty(config, 'DeliveryPoint.CityName'),
			Country: edi.utils.getObjectProperty(config, 'DeliveryPoint.Country'),
			District: edi.utils.getObjectProperty(config, 'DeliveryPoint.District'),
			HouseNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'DeliveryPoint.Housing'),
			Locality: edi.utils.getObjectProperty(config, 'DeliveryPoint.Locality'),
			PostalCode: edi.utils.getObjectProperty(config, 'DeliveryPoint.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.RoomNumber'),
			State: edi.utils.getObjectProperty(config, 'DeliveryPoint.State'),
			StateCode: edi.utils.getObjectProperty(config, 'DeliveryPoint.StateCode'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'DeliveryPoint.StreetAndNumber'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'DeliveryPoint.AdditionalInformation')
		},
		ShipFrom: {
			ILN: edi.utils.getObjectProperty(config, 'ShipFrom.ILN'),
			CodeByBuyer: edi.utils.getObjectProperty(config, 'ShipFrom.CodeByBuyer'),
			CodeBySeller: edi.utils.getObjectProperty(config, 'ShipFrom.CodeBySeller'),
			CodeByCarrier: edi.utils.getObjectProperty(config, 'ShipFrom.CodeByCarrier'),
			TaxID: edi.utils.getObjectProperty(config, 'ShipFrom.TaxID'),
			Name: edi.utils.getObjectProperty(config, 'ShipFrom.Name'),

			Type: edi.utils.getObjectProperty(config, 'ShipFrom.Type'),
			CityName: edi.utils.getObjectProperty(config, 'ShipFrom.CityName'),
			Country: edi.utils.getObjectProperty(config, 'ShipFrom.Country'),
			District: edi.utils.getObjectProperty(config, 'ShipFrom.District'),
			HouseNumber: edi.utils.getObjectProperty(config, 'ShipFrom.HouseNumber'),
			Housing: edi.utils.getObjectProperty(config, 'ShipFrom.Housing'),
			Locality: edi.utils.getObjectProperty(config, 'ShipFrom.Locality'),
			PostalCode: edi.utils.getObjectProperty(config, 'ShipFrom.PostalCode'),
			RoomNumber: edi.utils.getObjectProperty(config, 'ShipFrom.RoomNumber'),
			State: edi.utils.getObjectProperty(config, 'ShipFrom.State'),
			StateCode: edi.utils.getObjectProperty(config, 'ShipFrom.StateCode'),
			StreetAndNumber: edi.utils.getObjectProperty(config, 'ShipFrom.StreetAndNumber'),
			AdditionalInformation: edi.utils.getObjectProperty(config, 'ShipFrom.AdditionalInformation')
		},
		UltimateCustomer: edi.utils.getObjectProperty(config, 'UltimateCustomer'),
		Carrier: edi.utils.getObjectProperty(config, 'Carrier')
	};
};

export {
	isAdditionalProcessingNeeded,
	flattenLineValues,
	recalculateProductValues,
	separateCustomAndNormalFields,
	convertDesadvProductsToDesadvCrossProducts,
	templateCreateDesadv,
	templateCreateDesadvParties
};
