const inSequence = function (tasks) {
	return tasks.reduce((p, task) => p.then(task), Promise.resolve());
};

const restoreDocument = (document, onSuccess) => {
	return new Promise((resolve, reject) => {
		const documentId = document?.id ?? null;
		edi.rest.sendRequest(
			edi.utils.formatString(
				edi.rest.services.DOCUMENTS.ARCHIVE.RESTORE.GET,
				{
					archivedObjectId: documentId
				},
				true
			),
			'GET',
			undefined,
			function () {
				onSuccess(document);
			},
			reject
		);
	});
};

const openModulesForDocuments = (documents) => {
	const onSuccess = (doc) => {
		edi.document.actions.openDetailsModule(doc.type, doc);
	};
	let tasks = documents.map((document) => {
		let archived = document.archived ?? false;
		if (archived) {
			return restoreDocument(document, onSuccess);
		}
		return new Promise((resolve) => {
			onSuccess(document);
			resolve();
		});
	});
	return inSequence(tasks);
};

export { openModulesForDocuments };
