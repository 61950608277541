import '@UIkit/components/fields/Autocomplete/Autocomplete';

import infoTpl from './OrgAutocompleteList.ext.tpl';
import './OrgAutocomplete.scss';

const fieldCls = 'ediweb-orgautocomplete';

Ext.define('Ediweb.components.OrgAutocomplete', {
	extend: 'UI.components.AutocompleteField',
	cls: fieldCls,

	forceSelection: false,
	anyMatch: true,

	afterRender: function () {
		const me = this,
			picker = me.getPicker();

		me.callParent();

		picker.on('viewlist', function (__self, record, itemEl) {
			const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

			var tpl = new Ext.XTemplate(infoTpl);
			tpl.$comp = me;
			var el = tpl.append(wrapEl, record.getData());
		});
	}
});

export const createOrgAutocomplete = (config, storeConfig) =>
	new Ediweb.components.OrgAutocomplete(config, storeConfig);
