import { createTab, createTabPanel } from '@Components/panels';
import { createCheckbox, createCombo, createTagTextField } from '@Components/fields';
import { createActionsColumnConfig, createGrid, createToolBar } from '@Components/grid';
import { createStore } from '@Components/storeComponents';
import { createModalRelationSelect } from '@Core/specialComponents/modals';
import { createContainer } from '@Components/miscComponents';
import { createAddButton, createSaveButton } from '@Components/buttons';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { BUTTON_CLS } from '@UIkit/components/buttons';

/**
 * @param {Object} notification - USER_EMAIL_NOTIFICATION model
 * @param {function} callback - save callback
 * @return {*}
 */
function createNotificationDialog(notification, callback) {
	let notificationId, form;

	function saveForm() {
		if (!form.isValid()) {
			return;
		}
		//noinspection JSUnresolvedFunction
		let values = form.getValues();

		modal.setLoading(true);

		saveNotification(notificationId, values).then(
			() => {
				modal.setLoading(false);
				callback && callback();
				modal.close();
			},
			(errorData) => {
				edi.core.showError(edi.utils.formatComplexServerError(errorData, 'error.server'), function () {
					modal.setLoading(false);
				});
			}
		);
	}

	let modal = createModalPanel({
		title: edi.i18n.getMessage(notification ? 'user_email_notification.edit' : 'user_email_notification.create'),
		width: MODAL_SIZE.widthLarge,
		items: [],
		buttonsBefore: [
			createSaveButton(saveForm, {
				cls: BUTTON_CLS.primary,
				disabled: false,
				bindToForm: form,
				formBind: true
			})
		]
	});

	modal.setLoading(true);

	getDocTypeStatesMap().then(() => {
		let notificationData = notification ? notification.getData() : {};
		notificationId = notification ? notification.get('id') : null;

		form = createNotificationForm(notificationData);

		modal.add(form);
		modal.center();
		modal.setLoading(false);
	});

	return modal;
}

/**
 * @param {Number} notificationId
 * @param {String[]} emails
 * @param {Object<incoming: Object[], outgoing: Object[], allIncomingDocTypes: Boolean, allOutgoingDocTypes: Boolean>} docTypes
 * 	- Objects Structure {Object<docType: String, notificationStates: String[], allStates: boolean>[]}
 *
 * @param {Object<partners: String[], allPartners: Boolean>} partnersSetting
 * @returns	{Promise<Object>}	response from ajax request
 */
function saveNotification(notificationId, { emails, docTypes, partnersSetting }) {
	return new Promise((resolve, reject) => {
		let { partners, allPartners } = partnersSetting;

		let payload = {
			notificationEmails: emails,
			allIncomingDocTypes: !!docTypes.allIncomingDocTypes,
			allOutgoingDocTypes: !!docTypes.allOutgoingDocTypes,
			docTypeDirections: []
		};

		allPartners ? (payload.allPartners = true) : (payload.orgPartners = partners);

		let docTypeDirections = {};
		let docTypesMerged = [
			...new Set([
				...docTypes.incoming.map((item) => ({
					...item,
					direction: 'INCOMING'
				})),
				...docTypes.outgoing.map((item) => ({
					...item,
					direction: 'OUTGOING'
				}))
			])
		];
		docTypesMerged.forEach(({ docType, direction, allStates, notificationStates, linkInNotification }) => {
			let key = docType + '_' + direction;

			docTypeDirections[key] = docTypeDirections[key] || {
				docType,
				allStates: !!allStates,
				direction,
				docTypeStates: [],
				linkInNotification
			};

			if (docTypeDirections[key].allStates || allStates) {
				delete docTypeDirections[key].docTypeStates;
			} else {
				docTypeDirections[key].docTypeStates = docTypeDirections[key].docTypeStates.concat(notificationStates);
			}
		});

		payload.docTypeDirections = Object.values(docTypeDirections);

		edi.rest.sendRequest(
			notificationId
				? edi.utils.formatString(edi.rest.services.USER_EMAIL_NOTIFICATIONS_V2.PUT, { notificationId })
				: edi.rest.services.USER_EMAIL_NOTIFICATIONS_V2.POST,
			notificationId ? 'PUT' : 'POST',
			Ext.encode(payload),
			resolve,
			reject
		);
	});
}

/**
 * @param {Object} notification
 * @return {Object}
 */
function createNotificationForm(notification) {
	let form, emails, directions, partners;
	let emailList = notification.notificationEmails;
	let partnersSetting = {
		allPartners: notification.allPartners || false,
		partners: (notification.orgPartners || []).map((item) => item.id)
	};

	form = createModalForm({
		items: [
			createFieldBlock({
				cls: FIELD_BLOCK_CLS.small,
				title: edi.i18n.getMessage('user_email_notification.emailList'),
				items: [
					(emails = createTagTextField({
						allowBlank: false,
						regex: edi.constants.VALIDATORS.EMAIL,
						value: emailList,
						name: 'emailList'
					}))
				]
			}),
			(directions = createNotificationDirectionPanel(notification)),
			(partners = createPartnersGrid(partnersSetting))
		]
	});

	form.getValues = () => ({
		emails: emails.getValue(),
		docTypes: directions.getValues(),
		partnersSetting: partners.getValues()
	});

	let isValid = form.isValid;
	form.isValid = () => isValid.apply(form, arguments) && directions.isValid() && partners.isValid();

	return form;
}

/**
 * @param	{Object}	notification
 * @return	{Object}	panel with incoming\outgoing tabs
 */
function createNotificationDirectionPanel(notification) {
	let outgoingList = [],
		incomingList = [];
	(notification?.docTypeDirections || []).forEach(
		({ allStates, direction, docType, docTypeStates, linkInNotification }) => {
			let item = {
				docType,
				linkInNotification,
				notificationStates: []
			};
			if (allStates) {
				item.allStates = true;
			} else {
				item.notificationStates = docTypeStates;
			}

			let target = direction === edi.constants.DIRECTIONS.OUTGOING ? outgoingList : incomingList;
			target.push(item);
		}
	);

	let outgoingGrid, incomingGrid;
	return createTabPanel({
		height: 250,
		items: [
			(outgoingGrid = createDocumentTypeGrid(
				edi.i18n.getMessage('documents.direction.outgoing'),
				edi.constants.DIRECTIONS.OUTGOING,
				outgoingList,
				!!notification?.allOutgoingDocTypes,
				{
					card: {
						tabName: 'outgoing'
					}
				}
			)),
			(incomingGrid = createDocumentTypeGrid(
				edi.i18n.getMessage('documents.direction.incoming'),
				edi.constants.DIRECTIONS.INCOMING,
				incomingList,
				!!notification?.allIncomingDocTypes,
				{
					card: {
						tabName: 'incoming'
					}
				}
			))
		],
		getValues: function () {
			let outgoingValues = outgoingGrid.getValues();
			let incomingValues = incomingGrid.getValues();
			return {
				allOutgoingDocTypes: outgoingValues.allDoctypes,
				outgoing: outgoingValues.items,
				allIncomingDocTypes: incomingValues.allDoctypes,
				incoming: incomingValues.items
			};
		},
		isValid: () => outgoingGrid.isValid() || incomingGrid.isValid()
	});
}

/**
 * @param	{String}	title
 * @param	{String}	direction
 * @param	{Object<docType: String, notificationStates: String[], allStates: boolean>[]}	docTypeList
 * @param	{Boolean}	allIDoctypes
 * @returns	{Object}	tab with grid
 */
function createDocumentTypeGrid(title, direction, docTypeList, allIDoctypes, tabConfig = {}) {
	/**
	 * Записо с allStates удалит все с таким же docType
	 * при повторении docType notificationStates будут объеденены
	 * @param store
	 */
	function storeOptimization(store) {
		/*
		 * Первая запись для каждого docType будет выбрана как идеальная
		 * последующие записи будут смерджены в ней.
		 * В случае если попадётся запись с allStates
		 * идеальная запись для данныго типа документов будет проигнорирована.
		 */
		let perfect = {};
		//записи для удаления
		let redundant = [];
		// маркер обработки предотвращает повторную обработку записи,
		// плоский массив для оптимизации проверки обработки
		// дублирует данные из redundant, имеет лишь вспомогательное назначение
		let prepared = [];

		function drop(item) {
			redundant.push(item);
			prepared.push(item.id);
		}

		store.each((record) => {
			if (!!~prepared.indexOf(record.id)) {
				return;
			}

			perfect[record.get('docType')] = record;

			store.each((item) => {
				if (item.id !== record.id) {
					if (item.get('docType') === record.get('docType')) {
						if (item.get('allStates')) {
							drop(record);
							return false;
						}

						if (!record.get('allStates')) {
							let target = perfect[item.get('docType')];

							if (target) {
								let itemStates = item.get('notificationStates') || null;

								if (itemStates) {
									let targetStates = target.get('notificationStates') || [];

									if (!Array.isArray(targetStates)) {
										targetStates = [targetStates];
									}

									targetStates = targetStates.concat(itemStates);
									targetStates = [...new Set(targetStates)];

									target.set('notificationStates', targetStates, { silent: true });
								}
							}

							drop(item);
						}
					}
				}
			});
		});

		if (redundant.length) {
			store.remove(redundant);
		}

		grid?.getView().refresh();
	}

	let store = createStore({
		fields: [
			{
				name: 'docType'
			},
			{
				name: 'notificationStates'
			},
			{
				name: 'allStates'
			}
		],
		data: docTypeList || [],
		listeners: {
			datachanged: (store) => storeOptimization(store)
		}
	});

	let grid = createGrid({
		gridConfig: {
			disablePaging: true,
			columns: [
				{
					text: edi.i18n.getMessage('documents.column.type'),
					dataIndex: 'docType',
					renderer: function (value) {
						return edi.i18n.getMessage('documents.doctype.' + value);
					},
					order: 10,
					flex: 1.5
				},
				{
					text: edi.i18n.getMessage('documents.column.status'),
					dataIndex: 'notificationStates',
					renderer(value, meta, record) {
						if (record.get('allStates')) {
							return edi.i18n.getMessage('email.notifications.states.all');
						}
						if (!Array.isArray(value)) {
							value = [value];
						}
						let label = [];
						value.forEach((v) => {
							let statusKey = 'documents.status.' + v;
							if (v === edi.constants.STATE.SENT && direction === edi.constants.DIRECTIONS.INCOMING) {
								statusKey += `.${direction}`;
							}
							label.push((v && edi.i18n.getMessage(statusKey)) || '');
						});
						return label.join(', ');
					},
					order: 20,
					flex: 1.5
				},
				createActionsColumnConfig({
					items: [
						{
							glyph: edi.constants.ICONS.EDIT,
							handler: function (grid, rowIndex, index, btn, event, record) {
								showDocumentStatusDialog({
									docTypeData: record.getData(),
									direction: direction,
									handler: ({ notificationStates, allStates, linkInNotification }) => {
										record.set('notificationStates', allStates ? [] : notificationStates);
										record.set('allStates', allStates);
										record.set('linkInNotification', linkInNotification);
									}
								});
							}
						},
						{
							glyph: edi.constants.ICONS.REMOVE,
							handler: function (grid, rowIndex) {
								grid.getStore().removeAt(rowIndex);
							}
						}
					]
				})
			],
			region: 'center',
			cls: 'edi-create-field-line-separated edi-grid-mark-empty-red',
			store
		},
		viewConfig: {
			emptyText: edi.i18n.getMessage('grid.empty.mandatory')
		}
	});

	/**
	 * @param {Object=} docTypeData
	 * @param {String} direction
	 * @param {function} handler получит объект с выбранными параметрами
	 */
	function showDocumentStatusDialog({ docTypeData, direction, handler }) {
		let isEdit = !!docTypeData;
		docTypeData = docTypeData || {};

		let statusesField, statusesFieldInput, allStatusesFieldInput;
		let docTypeMap = {};

		function updateStatesList(docType) {
			let selectedStatus = statusesFieldInput.getValue();
			let statuses = (docTypeMap[docType] || {})[direction] || [];
			let isVisible = Array.isArray(statuses) && statuses.length !== 0;

			statusesFieldInput.allowBlank = !isVisible;
			statusesFieldInput.setDisabled(!isVisible);
			statusesField.setVisible(isVisible);

			if (!isVisible) {
				return;
			}

			statusesFieldInput.getStore().loadData(
				statuses.map((s) => {
					//noinspection JSUnresolvedVariable
					if (typeof s?.customTranslation === 'function') {
						//noinspection JSUnresolvedFunction
						return {
							id: s.state,
							name: s.customTranslation(docType, direction)
						};
					} else {
						let state = s?.state || s || 'UNKNOWN';
						let translationKey = 'documents.status.' + state;
						if (direction === edi.constants.DIRECTIONS.INCOMING && state === edi.constants.STATE.SENT) {
							translationKey += `.${edi.constants.DIRECTIONS.INCOMING}`;
						}
						return {
							id: state,
							name: edi.i18n.getMessage(translationKey)
						};
					}
				})
			);

			statusesFieldInput.setReadOnly(false);
			statusesFieldInput.setValue(selectedStatus);

			form.isValid();
		}

		function updateStateCombobox(checkbox) {
			let value = checkbox.getValue();
			let filled = value ? 'all' : 'many';
			let emptyText = {
				all: edi.i18n.getMessage('email.notifications.states.all'),
				many: edi.i18n.getMessage('form.combo.not.selected')
			}[filled];

			statusesFieldInput.setEmptyText(emptyText);
			statusesFieldInput.allowBlank = value;
			statusesFieldInput.setReadOnly(value);

			value && statusesFieldInput.setValue([]);

			form.isValid();
		}

		let form = createModalForm({
			items: []
		});

		let modal = createModalPanel({
			title: edi.i18n.getMessage(
				isEdit ? 'user_email_notification.doctype.edit' : 'user_email_notification.doctype.create'
			),
			width: MODAL_SIZE.widthMedium,
			items: [form],
			buttonsBefore: [
				createSaveButton(
					() => {
						//noinspection JSUnresolvedFunction
						handler(edi.utils.collectFormValues(form));
						modal.close();
					},
					{
						cls: BUTTON_CLS.primary,
						disabled: false,
						bindToForm: form,
						formBind: true
					}
				)
			]
		});

		modal.setLoading(true);

		getDocTypeStatesMap().then((items) => {
			docTypeMap = items;

			//noinspection JSUnresolvedFunction
			form.add([
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.type'),
					items: [
						createCombo({
							name: 'docType',
							store: defineDocTypeStore(direction, docTypeMap),
							allowBlank: false,
							valueSrc: docTypeData,
							disabled: isEdit,
							listeners: {
								change: (field, value) => updateStatesList(value)
							}
						})
					]
				}),
				(statusesField = createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('document.state'),
					items: [
						(statusesFieldInput = createCombo({
							name: 'notificationStates',
							store: edi.stores.createSimpleInlineStore([]),
							allowBlank: false,
							multiSelect: true,
							disabled: !docTypeData.direction,
							valueSrc: docTypeData
						}))
					]
				})),
				(allStatusesFieldInput = createCheckbox({
					boxLabel: edi.i18n.getMessage('user_email_notification.all.available.states'),
					inputValue: true,
					name: 'allStates',
					checked: docTypeData?.allStates === true,
					valueSrc: docTypeData,
					qtipText: edi.i18n.getMessage('user_email_notification.all.available.states.in.the.moment'),
					listeners: {
						change: updateStateCombobox
					}
				})),
				createCheckbox({
					boxLabel: edi.i18n.getMessage('user_email_notification.link_in_notification'),
					inputValue: true,
					checked: docTypeData?.linkInNotification === true,
					name: 'linkInNotification',
					valueSrc: docTypeData
				})
			]);

			updateStateCombobox(allStatusesFieldInput);
			modal.center();
			modal.setLoading(false);
		});

		modal.show();
	}

	let setGridVisibility = function (visible) {
		grid.setVisible(visible);
		addDoctypeBtn.setVisible(visible);
	};

	let allDoctypesCheckbox, addDoctypeBtn;
	return createTab(
		Object.assign(
			{
				title,
				closable: false,
				layout: 'border',
				items: [
					createToolBar({
						region: 'north',
						items: [
							(addDoctypeBtn = createAddButton(
								() =>
									showDocumentStatusDialog({
										direction,
										handler: (documentStatusObject) => store.add(documentStatusObject)
									}),
								{
									margin: '0 16 0 0',
									disabled: false
								}
							)),
							(allDoctypesCheckbox = createCheckbox({
								boxLabel: edi.i18n.getMessage('email.notifications.doctypes.all'),
								inputValue: true,
								checked: !!allIDoctypes,
								listeners: {
									change: (checkbox, value) => {
										setGridVisibility(!value);
									}
								}
							}))
						]
					}),
					grid
				],
				listeners: {
					afterrender() {
						setGridVisibility(!allDoctypesCheckbox.getValue());
					}
				},
				getValues: () => ({
					items: store.getRange().map((r) => r.getData()),
					allDoctypes: allDoctypesCheckbox.getValue() === true
				}),
				isValid: function () {
					return allDoctypesCheckbox.getValue() === true || store.count() !== 0;
				}
			},
			tabConfig
		)
	);
}

/**
 * @param {String[]} partners
 * @param {boolean} allPartners
 * @return {Object<partners: String[], allPartners: Boolean>}
 */
function createPartnersGrid({ partners, allPartners }) {
	//noinspection JSUnresolvedFunction
	let relations = edi.relations.getRelations();
	let allPartnersStore = createAllPartnersStore();
	let store = createStore({
		model: edi.models.getModel('ORGANIZATION'),
		data: []
	});
	let allPartnersCheckbox,
		partnersGrid,
		addPartnerButton,
		partnersGridVisibility = true;

	if (allPartners) {
		partnersGridVisibility = false;
	} else {
		(partners || []).forEach((partnerId) => {
			//noinspection JSCheckFunctionSignatures
			let model = allPartnersStore.findRecord('id', partnerId);

			if (model) {
				store.add(model);
			}
		});
	}

	//noinspection JSCheckFunctionSignatures
	let panel = createTabPanel({
		height: 200,
		items: [
			createTab({
				title: edi.i18n.getMessage('email.notifications.partners.grid.title'),
				closable: false,
				card: {
					tabName: 'partners'
				},
				layout: 'border',
				items: [
					createToolBar({
						region: 'north',
						items: [
							(addPartnerButton = createAddButton(
								() =>
									createModalRelationSelect(
										relations.filter(({ id }) => !~store.find('id', id)),
										(org) => store.add(org)
									),
								{
									margin: '0 16 0 0',
									hidden: !partnersGridVisibility
								}
							)),
							(allPartnersCheckbox = createCheckbox({
								boxLabel: edi.i18n.getMessage('email.notifications.partners.all'),
								inputValue: true,
								checked: allPartners,
								name: 'allPartners',
								listeners: {
									change: (checkbox, value) => {
										value ? partnersGrid.hide() : partnersGrid.show();
										value ? addPartnerButton.hide() : addPartnerButton.show();
									}
								}
							}))
						]
					}),
					(partnersGrid = createGrid({
						gridConfig: {
							hidden: !partnersGridVisibility,
							disablePaging: true,
							columns: [
								...edi.columns.get('organization_with_filiations'),
								createActionsColumnConfig({
									items: [
										{
											glyph: edi.constants.ICONS.REMOVE,
											handler: function (grid, rowIndex) {
												grid.getStore().removeAt(rowIndex);
											}
										}
									]
								})
							],
							region: 'center',
							cls: 'edi-create-field-line-separated edi-grid-mark-empty-red',
							store
						},
						viewConfig: {
							emptyText: edi.i18n.getMessage('grid.empty.mandatory')
						}
					})),
					createContainer() //без пустого контейнера таба не рендерится при скрытом гриде
				]
			})
		]
	});

	panel.getValues = () => {
		let partners = [];
		let allPartners = allPartnersCheckbox.getValue();

		if (!allPartners) {
			store.each((record) => partners.push(record.getId()));
		}

		return {
			partners,
			allPartners
		};
	};

	panel.isValid = () => allPartnersCheckbox.getValue() || store.count() !== 0;

	return panel;
}

let docTypeStatesPromise;

function getDocTypeStatesMap() {
	if (docTypeStatesPromise) {
		return docTypeStatesPromise;
	}

	return (docTypeStatesPromise = new Promise((resolve, reject) => {
		edi.rest.sendRequest(
			edi.rest.services.USER_EMAIL_NOTIFICATIONS_V2.STATES.GET,
			'GET',
			null,
			function ({ items }) {
				let docStatesMap = {};

				Array.isArray(items) &&
					items.forEach(({ docType, incoming, outgoing }) => {
						docStatesMap[docType] = {
							INCOMING: Array.isArray(incoming) ? incoming : [],
							OUTGOING: Array.isArray(outgoing) ? outgoing : []
						};
					});

				resolve(docStatesMap);
			},
			reject
		);
	}));
}

/**
 * @param {string} direction
 * @param {object} docTypeMap - результат метода getDocTypeStatesMap
 */
function defineDocTypeStore(direction, docTypeMap) {
	let types = [];

	edi.constants.EMAIL_NOTIFICATION_DOC_TYPES?.forEach(
		(type) => ((docTypeMap[type] || {})[direction] || []).length && types.push(type)
	);

	//noinspection JSUnresolvedFunction
	return edi.stores.createSimpleInlineStore(types, (docType) => edi.i18n.getMessage('documents.doctype.' + docType));
}

function createAllPartnersStore() {
	let data = [];

	//noinspection JSUnresolvedFunction
	edi.relations.getRelations().forEach((org) => {
		//noinspection JSUnresolvedFunction
		data.push(edi.models.createInstance('ORGANIZATION', org));
	});

	//noinspection JSCheckFunctionSignatures
	return new Ext.data.Store({
		model: edi.models.getModel('ORGANIZATION'),
		data
	});
}

export { createNotificationDialog };
