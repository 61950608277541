const ORDER_LINE_COLUMN = 'order_lines';
const ORDER_SUMMARY_EDITABLE_COLUMN = 'order_summary_editable';
const ORDER_SUMMARY_DETAIL_COLUMN = 'order_summary';

edi.columns.addColumns({
	[ORDER_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 1
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 2
		},
		itemDescription: {
			text: 'line.item.product.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 4
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: 'UnitOfMeasure'
		},
		unitNetPrice: {
			text: 'column.ordered.unit.net.price',
			dataIndex: 'OrderedUnitNetPrice',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 1
		},
		unitGrossPrice: {
			text: 'column.ordered.unit.gross.price',
			dataIndex: 'OrderedUnitGrossPrice',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		unitPacksize: {
			text: 'column.ordered.unit.pack.size',
			dataIndex: 'OrderedUnitPacksize',
			flex: 1,
			hidden: true,
			renderer: 'decimal'
		},
		netAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'OrderedNetAmount',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		taxAmount: {
			text: 'column.ordered.tax.amount',
			dataIndex: 'OrderedTaxAmount',
			renderer: 'displayCurrencyDecimals',
			flex: 1
		},
		grossAmount: {
			text: 'column.gross.amount',
			renderer: 'displayCurrencyDecimals',
			dataIndex: 'OrderedGrossAmount',
			flex: 1
		},
		orderedQuantity: {
			text: 'column.ordered.quantity',
			dataIndex: 'OrderedQuantity',
			flex: 1,
			order: 6,
			tdCls: 'editable',
			editor: {
				xtype: 'numberfield',
				validator: 'validators.amount10',
				decimalPrecision: 3,
				allowDecimals: true,
				allowBlank: false,
				minValue: 1
			},
			renderer: 'decimal'
		},

		discount: {
			text: 'line.item.discount',
			dataIndex: 'Discount',
			hidden: true
		},
		expectedDespatchDate: {
			text: 'line.item.tab.package.ExpectedDespatchDate',
			dataIndex: 'ExpectedDespatchDate'
		},
		requestedDeliveryDate: {
			text: 'line.item.tab.package.RequestedDeliveryDate',
			dataIndex: 'RequestedDeliveryDate'
		},
		model: {
			text: 'line.item.model',
			dataIndex: 'Model',
			hidden: true
		}
	},
	[ORDER_SUMMARY_DETAIL_COLUMN]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'TotalLines',
			flex: 1,
			sortable: false,
			hideable: false
		},
		TotalOrderedAmount: {
			text: 'column.total.ordered.amount',
			dataIndex: 'TotalOrderedAmount',
			menuDisabled: true,
			flex: 1,
			order: 2,
			renderer: 'decimal'
		},
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			menuDisabled: true
		}
	}
});
edi.columns.addColumns({
	[ORDER_SUMMARY_EDITABLE_COLUMN]: {
		parents: ORDER_SUMMARY_DETAIL_COLUMN,
		config: {
			totalNetAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalTaxAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			},
			totalGrossAmount: {
				tdCls: 'editable',
				editor: {
					xtype: 'numberfield',
					validator: 'validators.amount14',
					decimalPrecision: 2,
					allowDecimals: true,
					allowBlank: false,
					minValue: 0
				}
			}
		}
	}
});

export { ORDER_LINE_COLUMN, ORDER_SUMMARY_DETAIL_COLUMN, ORDER_SUMMARY_EDITABLE_COLUMN };
