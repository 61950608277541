/**
 * Fields config for forms
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.fields');
edi.fields = new (function () {
	this.config = {};
})();

Object.assign(edi.fields.config, {
	pdfFilefieldListeners: {
		afterrender: function (cmp) {
			cmp.fileInputEl.set({
				accept: 'application/pdf'
			});
		},
		reset: function (cmp) {
			cmp.fileInputEl.set({
				accept: 'application/pdf'
			});
		},
		change: function (cmp) {
			var indexOfPeriod = cmp.getValue().lastIndexOf('.'),
				uploadedExtension = cmp.getValue().substr(indexOfPeriod + 1, cmp.getValue().length - indexOfPeriod);
			const [file] = cmp.fileInputEl.dom.files;
			if (file && !file.size) {
				edi.core.showWarn(edi.i18n.getMessage('document.upload.empty.file.error'));
				cmp.reset();
			} else if (uploadedExtension && 'pdf' !== uploadedExtension.toLowerCase()) {
				edi.core.showWarn(edi.i18n.getMessage('document.package.upload.file.extension.text'));
				cmp.reset();
			}
		}
	}
});
