const actionRulesForFrovSigSoAct2FromX5 = {
	EXPORT: {
		OUTGOING: {},
		INCOMING: {},
		LOOP: {},
		UNKNOWN: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	EXPORT_DOCUMENTS: {},
	EXPORT_GROUP_XML: {},
	EXPORT_GROUP: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		permissionChanging: [],
		conditions: []
	},
	PRINT: {
		OUTGOING: {},
		INCOMING: {},
		INCOMING_FACTOR: {},
		LOOP: {},
		UNKNOWN: {},
		permissionChanging: [],
		conditions: []
	},
	SIGN: {
		INCOMING: {
			STATE: [edi.constants.STATE.SENT, edi.constants.STATE.RECEIVER_REVIEW]
		},
		permissionChanging: [
			{
				change: function (actionPermission, checkOptions) {
					let isIncoming = checkOptions.direction === edi.constants.DIRECTIONS.INCOMING;
					return actionPermission + (isIncoming ? '_RECEIVER' : '_SENDER');
				}
			}
		],
		conditions: []
	},
	READ: {
		INCOMING: {
			STATE: [edi.constants.STATE.RECEIVER_REVIEW]
		}
	},
	REJECT: {
		INCOMING: {
			STATE: [edi.constants.STATE.SENT]
		},
		permissionChanging: [],
		conditions: []
	},
	QUICK_ANSWER_CONFIRM: {},
	QUICK_ANSWER_REJECT: {},
	ROUTE_REJECT: {},
	ROUTE_CONFIRM: {},
	CLARIFY: {},
	DELETE: {},
	EDIT: {},
	COMPLETE: {},
	READ_AND_COMPLETE: {},
	SEND: {},
	SEND_TO_APPROVE: {},
	APPROVE: {},
	CREATE_FROM: {},
	CONVERT_TO_DRAFT: {},
	COPY: {},
	PACKAGING: {},
	ARCHIVE_RESTORE: {},
	ANNUL: {},
	CREATE_UKD: {},
	RENOUNCEMENT: {},
	CREATE_CUD: {}
};

Ext.merge(edi.action.rulesByDocType, {
	[edi.constants.DOCUMENT_TYPES.FROV_SIG_SO_ACT2_FROM_X5]: actionRulesForFrovSigSoAct2FromX5
});

export { actionRulesForFrovSigSoAct2FromX5 };
