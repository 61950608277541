export const serviceActSelectors = {
	getCommonModalConf: function () {
		return edi.selectors.getFNSModalConf({
			main: {
				company_gln: {
					allowBlank: true
				},
				individual_gln: {
					allowBlank: true
				},
				individual_inn: {
					allowBlank: false
				}
			},
			address: {
				block: {
					maxLength: 20
				}
			},
			bank: {
				bank_acc: {
					allowBlank: true
				},
				bank_corr_acc: {
					maxLength: 20
				},
				bank_id: {
					regex: edi.constants.VALIDATORS.BIK,
					invalidText: edi.i18n.getMessage('invalid.value.bank.id')
				},
				bank_name: {
					maxLength: 1000
				}
			},
			contact: {
				fax: null
			}
		});
	},
	getCommonFieldsMap: function (fieldsMapPrefix) {
		fieldsMapPrefix = fieldsMapPrefix + '.';

		var company = fieldsMapPrefix + 'identification.legalEntity.',
			individual = fieldsMapPrefix + 'identification.individualPerson.',
			rusAddress = fieldsMapPrefix + 'address.addressRu.',
			foreignAddr = fieldsMapPrefix + 'address.addressInfo.',
			contact = fieldsMapPrefix + 'contact.',
			bank = fieldsMapPrefix + 'bankInfo.',
			foreigner = fieldsMapPrefix + 'identification.informationAboutLegalForeigner.';

		var map = {
			company_name: company + 'orgName',
			company_inn: company + 'tin',
			company_kpp: company + 'kpp',
			okpo: fieldsMapPrefix + 'okpo',

			individual_inn: individual + 'tin',
			individual_lastname: individual + 'name.lastName',
			individual_firstname: individual + 'name.firstName',
			individual_patronymicname: individual + 'name.middleName',
			individual_certificateRegistrationIP:
				fieldsMapPrefix + 'identification.individualPerson.certificateRegistrationIP',

			foreign_name: foreigner + 'name',
			foreign_info: foreigner + 'additionalInfo',

			addr_rus_zip: rusAddress + 'index',
			addr_rus_region: rusAddress + 'regionCode',
			addr_rus_county: rusAddress + 'area',
			addr_rus_city: rusAddress + 'city',
			addr_rus_street: rusAddress + 'street',
			addr_rus_community: rusAddress + 'settlement',
			addr_rus_home: rusAddress + 'house',
			addr_rus_block: rusAddress + 'housing',
			addr_rus_flat: rusAddress + 'flat',

			addr_for_country: foreignAddr + 'codeOfCountry',
			addr_for_text: foreignAddr + 'addressText',

			contact_phone: contact + 'phoneNumber',
			contact_email: contact + 'email',

			bank_corr_acc: bank + 'bank.coreAccountNumber',
			bank_acc: bank + 'accountNumber',
			bank_name: bank + 'bank.bankName',
			bank_id: bank + 'bank.bankCode',

			address_type: true,
			type: true
		};

		return map;
	},
	getSellerFieldsMap: function (prefix) {
		return serviceActSelectors.getCommonFieldsMap(prefix);
	},
	getSellerModalConf: function () {
		return serviceActSelectors.getCommonModalConf();
	},
	getBuyerFieldsMap: function (prefix) {
		return serviceActSelectors.getCommonFieldsMap(prefix);
	},
	getBuyerModalCong: function () {
		return serviceActSelectors.getCommonModalConf();
	}
};
