import { createForm } from '@Components/panels';
import { createSaveButton } from '@Components/buttons';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { BUTTON_CLS } from '@UIkit/components/buttons';

/**
 * Opens modal for UKD line
 * @param	{Object}	tabPanel
 * @param	{Object}	record
 * @param	{Object}	dataFormGridConfig
 * @param	{Boolean}	[isEdit]
 * @param	{Function}	[callback]
 */
const createUKDLineModalForm = function (tabPanel, record, dataFormGridConfig, isEdit, callback) {
	var modal, saveBtn;

	var form = createForm({
		submitEmptyText: false,
		layout: 'fit',
		items: [tabPanel]
	});

	saveBtn = isEdit
		? createSaveButton(
				function () {
					var values = edi.utils.collectFormValues(form);

					Object.entries(dataFormGridConfig).forEach(function ([key, value]) {
						edi.utils.setObjectProperty(values, key, edi.utils.getDataFromGrid(value));
					});

					'function' == typeof callback ? callback(values) : null;
					modal.close();
				},
				{
					cls: BUTTON_CLS.primary,
					disabled: false
				}
		  )
		: null;

	modal = createModalPanel({
		cls: 'edi-modal-product-data-selector',
		title: edi.i18n.getMessage('modal.product.data'),
		width: MODAL_SIZE.widthLarge,
		layout: 'fit',
		items: [form],
		buttonsBefore: [saveBtn]
	});

	modal.show();
};

export { createUKDLineModalForm };
