import { PRICAT_EANCOM_V2_METHODS } from './methods';

const PRICAT_EANCOM_LINE_COLUMN = 'eancom_lines';
const PRICAT_EANCOM_V2_LINE_COLUMN = 'eancom_v2_lines';
const joinRenderer = ({ valueArr = [], name, metaData }) => {
	const value = valueArr.map((v) => v[name]).join(', ');
	return edi.renderers.valueAsToolTip(value, metaData);
};

edi.columns.addColumns({
	[PRICAT_EANCOM_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.2
		},
		ean: {
			text: 'line.item.ean',
			dataIndex: 'EAN',
			allowBlank: true,
			flex: 1
		},
		itemCode: {
			text: 'column.pricate.eancom.item.code',
			dataIndex: 'ItemCode',
			allowBlank: false,
			flex: 1
		},
		heading: {
			text: 'column.pricate.eancom.heading',
			dataIndex: 'Heading',
			allowBlank: false,
			flex: 1
		},
		currentPrice: {
			text: 'column.pricate.eancom.current.price',
			dataIndex: 'CurrentPrice',
			allowBlank: false,
			flex: 1
		},
		newPrice: {
			text: 'column.pricate.eancom.new.price',
			dataIndex: 'NewPrice',
			allowBlank: false,
			flex: 1
		}
	},
	[PRICAT_EANCOM_V2_LINE_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.2
		},
		ean: {
			text: 'line.item.ean',
			dataIndex: 'EAN',
			allowBlank: true,
			flex: 1
		},
		itemCode: {
			text: 'column.pricate.eancom.item.code',
			dataIndex: 'ItemCode',
			allowBlank: false,
			flex: 1
		},
		heading: {
			text: 'column.pricate.eancom.heading',
			dataIndex: 'Heading',
			allowBlank: false,
			flex: 1
		},
		newPrice: {
			text: 'column.pricate.eancom.new.price',
			dataIndex: 'NewPrice',
			allowBlank: false,
			flex: 1
		},
		startingDateNewPrice: {
			text: 'document.starting.date.new.price',
			dataIndex: 'startingDateNewPrice',
			renderer: PRICAT_EANCOM_V2_METHODS.formatPricatDate,
			flex: 1
		},
		endingDateNewPrice: {
			text: 'document.ending.date.new.price',
			dataIndex: 'endingDateNewPrice',
			renderer: PRICAT_EANCOM_V2_METHODS.formatPricatDate,
			flex: 1
		},
		volume: {
			text: 'documents.pricate_eancom_v2.volume',
			dataIndex: 'volume',
			flex: 1
		},
		deliveryPlaceNames: {
			text: 'document.delivery.place.names',
			dataIndex: 'deliveryPlace',
			flex: 1,
			renderer: (value, meta) => joinRenderer({ valueArr: value, name: 'name', metaData: meta })
		},
		deliveryPlaceIlns: {
			text: 'document.delivery.place.ilns',
			dataIndex: 'deliveryPlace',
			flex: 1,
			renderer: (value, meta) => joinRenderer({ valueArr: value, name: 'iln', metaData: meta })
		},
		exceptionsPlaceNames: {
			text: 'document.exceptions.place.names',
			dataIndex: 'exceptionsPlace',
			flex: 1,
			renderer: (value, meta) => joinRenderer({ valueArr: value, name: 'name', metaData: meta })
		},
		exceptionsPlaceIlns: {
			text: 'document.exceptions.place.ilns',
			dataIndex: 'exceptionsPlace',
			flex: 1,
			renderer: (value, meta) => joinRenderer({ valueArr: value, name: 'iln', metaData: meta })
		}
	}
});

export { PRICAT_EANCOM_LINE_COLUMN, PRICAT_EANCOM_V2_LINE_COLUMN };
