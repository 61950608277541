const createDeljitHeader = function (config) {
	config = config ? config : {};
	var returnObject = {
		OrderNumber: config.OrderNumber,
		OrderDate: config.OrderDate,
		OrderCurrency: config.OrderCurrency,
		Discount: config.Discount,
		ExpectedDeliveryDate: config.ExpectedDeliveryDate,
		ExpectedDeliveryTime: config.ExpectedDeliveryTime,
		LastExpectedDeliveryDate: config.LastExpectedDeliveryDate,
		LastExpectedDeliveryTime: config.LastExpectedDeliveryTime,
		PromotionReference: config.PromotionReference,
		DocumentFunctionCode: config.DocumentFunctionCode,
		Remarks: config.Remarks,
		SpecialInstructions: config.SpecialInstructions,
		'Order-Transport': config['Order-Transport'],
		Reference: config.Reference
	};
	if (config.DocumentNameCode) {
		returnObject['DocumentNameCode'] = config.DocumentNameCode;
	}
	return returnObject;
};

const createDeljit = function (config) {
	config = config ? config : {};
	var orderLines = [];
	var summary = {};
	if (config.productValues) {
		summary = {
			TotalLines: config.productValues.TotalLines,
			TotalOrderedAmount: config.productValues.TotalOrderedAmount,
			TotalNetAmount: config.productValues.TotalNetAmount,
			TotalTaxAmount: config.productValues.TotalTaxAmount,
			TotalGrossAmount: config.productValues.TotalGrossAmount
		};

		var checkDateTime = function (condition, propName) {
			if (condition.hasOwnProperty(propName + 'DateTime')) {
				var dateTime = condition[propName + 'DateTime'].split(' ');
				if (dateTime.length == 2) {
					condition[propName + 'Date'] = dateTime[0];
					condition[propName + 'Time'] = dateTime[1];
					delete condition[propName + 'DateTime'];
				}
			}
		};

		for (var i = 0; i < config.productValues.products.length; i++) {
			var product = config.productValues.products[i],
				BuyerLocationCode = product.BuyerLocationCode,
				ExpectedDeliveryDate = product.ExpectedDeliveryDate,
				ShipmentCondition = product['Shipment-Condition'];

			delete product['Shipment-Condition'];
			delete product['BuyerLocationCode'];
			delete product['ExpectedDeliveryDate'];

			for (var j = 0; j < ShipmentCondition.length; j++) {
				checkDateTime(ShipmentCondition[j], 'ExpectedDespatch');
				checkDateTime(ShipmentCondition[j], 'RequestedDelivery');
			}

			var line = {
				'Line-Delivery': {
					BuyerLocationCode: BuyerLocationCode,
					ExpectedDeliveryDate: ExpectedDeliveryDate
				},
				'Shipment-Conditions': {
					'Shipment-Condition': ShipmentCondition
				}
			};
			if (product[edi.constants.INTERNAL_LINE_ID]) {
				line[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}
			delete product[edi.constants.INTERNAL_LINE_ID];
			line['Line-Item'] = product;
			orderLines.push(line);
		}
	}

	var parties = config.parties || {};
	if (parties) {
		parties && parties.Buyer && parties.Buyer.Type ? delete parties.Buyer.Type : null;
		parties && parties.Seller && parties.Seller.Type ? delete parties.Seller.Type : null;
		parties && parties.DeliveryPoint && parties.DeliveryPoint.Type ? delete parties.DeliveryPoint.Type : null;
		parties && parties.UltimateCustomer && parties.UltimateCustomer.Type
			? delete parties.UltimateCustomer.Type
			: null;
	}
	return {
		'Order-Header': createDeljitHeader(config['Order-Header']),
		'Order-Parties': parties,
		'Order-Lines': {
			Line: orderLines
		},
		'Order-Summary': summary
	};
};

const dateTimeRenderer = function (value) {
	if (value) {
		value = edi.utils.formatDate(
			value,
			edi.constants.DATE_FORMAT.DATE_TIME_FNS_NO_S,
			edi.constants.DATE_FORMAT.DATE_TIME_NO_S
		);
	}
	return value || '';
};

export { createDeljit, createDeljitHeader, dateTimeRenderer };
