import { createPasswordField } from '@UIkit/components/fields';
import { createModalForm, createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createModalPanel } from '@UIkit/components/modal';

function createPasswordBlock(config) {
	let { title } = config;
	const defaults = {
		validator: edi.methods.validators.password
	};
	Ext.applyIf(config, defaults);

	return createFieldBlock({
		cls: FIELD_BLOCK_CLS.small,
		title,
		items: [createPasswordField(config)]
	});
}

function createPasswordDialog() {
	let modal;

	function closeModal() {
		modal.hide();
	}

	let form = createModalForm({
		layout: {
			type: 'grid',
			gap: [24]
		},
		items: [
			createPasswordBlock({
				title: edi.i18n.getMessage('column.password.old'),
				allowBlank: false,
				name: 'oldPassword'
			}),
			createPasswordBlock({
				title: edi.i18n.getMessage('column.password.new'),
				allowBlank: false,
				name: 'newPassword'
			}),
			createPasswordBlock({
				title: edi.i18n.getMessage('column.password.new.confirm'),
				allowBlank: false,
				nam: 'confirmPassword',
				validator: function (value) {
					return value === edi.utils.getObjectProperty(edi.utils.collectFormValues(form), 'newPassword')
						? true
						: edi.i18n.getMessage('password.is.not.same.error');
				}
			})
		]
	});

	return (modal = createModalPanel(
		{
			title: edi.i18n.getMessage('user.profile.password.change.dialog.title'),
			items: [form],
			buttons: [
				createButton({
					text: edi.i18n.getMessage('form.btn.save'),
					cls: BUTTON_CLS.primary,
					bindToForm: form,
					disabled: true,
					handler: () => {
						modal.setLoading(true);
						savePassword(form.getValues()).then(
							() => {
								modal.setLoading(false);
								closeModal();
							},
							(data) => {
								edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
								modal.setLoading(false);
							}
						);
					}
				}),
				createButton({
					text: edi.i18n.getMessage('ediweb.cancel.btn'),
					cls: [BUTTON_CLS.secondary],
					handler: closeModal
				})
			]
		},
		true
	));
}

function savePassword(values) {
	let { oldPassword, newPassword } = values;

	return new Promise((resolve, reject) => {
		edi.rest.sendRequest(
			edi.rest.services.USER.SELF.CRD.PUT,
			'PUT',
			Ext.encode({
				oldPassword,
				newPassword
			}),
			() => {
				edi.core.logMessage('User password saved', 'info');
				resolve();
			},
			(data) => {
				edi.core.handleException('User password did not saved properly');
				reject(data);
			}
		);
	});
}

export { createPasswordDialog };
