export const invoiceSelectors = {
	getSellerFieldsMap: () => {
		const fieldsMapPrefix = 'Seller';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.CountryCode`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			representative_name: `${fieldsMapPrefix}.SalesRepresentative.Name`,
			representative_phone: `${fieldsMapPrefix}.SalesRepresentative.TelephoneNumber`,
			representative_email: `${fieldsMapPrefix}.SalesRepresentative.E-mail`,
			operator_name: `${fieldsMapPrefix}.OperatorDetails.Name`,
			operator_phone: `${fieldsMapPrefix}.OperatorDetails.TelephoneNumber`,
			operator_email: `${fieldsMapPrefix}.OperatorDetails.E-mail`,
			bank_acc: `${fieldsMapPrefix}.AccountNumber`,
			code_by_sender: `${fieldsMapPrefix}.CodeBySender`,
			code_by_receiver: `${fieldsMapPrefix}.CodeByReciever`,
			code_by_seller: `${fieldsMapPrefix}.CodeBySeller`,
			code_by_buyer: `${fieldsMapPrefix}.CodeByBuyer`,
			id: true
		};
	},
	getSellerModalConf: () => ({
		title: edi.i18n.getMessage('document.seller'),
		tabs: {
			main: {
				company_name: edi.selectors.defaults.company_name,
				company_gln: edi.selectors.defaults.company_gln,
				company_reg_number: edi.selectors.defaults.company_reg_nr,
				company_inn: edi.selectors.defaults.company_inn
			},
			address: {
				category: edi.selectors.defaults.category,
				country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
					maxLength: 140,
					fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
						mandatory: false,
						allowBlank: true
					})
				}),
				area: Object.assign({}, edi.selectors.defaults.area, {
					name: 'addr_area',
					maxLength: 140
				}),
				region: Object.assign({}, edi.selectors.defaults.region, {
					maxLength: 140
				}),
				city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
				zip: edi.selectors.defaults.zip,
				street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
				locality: Object.assign({}, edi.selectors.defaults.locality, {
					name: 'addr_locality',
					maxLength: 140
				}),
				home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
				block: Object.assign({}, edi.selectors.defaults.block, {
					name: 'addr_block',
					maxLength: 20
				}),
				flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
				addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
			},
			bank: {
				bank_acc: edi.selectors.defaults.bank_acc_select
			},
			contact: {
				operator_name: edi.selectors.defaults.operator_name,
				operator_phone: edi.selectors.defaults.operator_phone,
				operator_email: edi.selectors.defaults.operator_email,
				representative_name: edi.selectors.defaults.representative_name,
				representative_phone: edi.selectors.defaults.representative_phone,
				representative_email: edi.selectors.defaults.representative_email
			}
		}
	}),
	getBuyerFieldsMap: () => {
		const fieldsMapPrefix = 'Buyer';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.CountryCode`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			code_by_sender: `${fieldsMapPrefix}.CodeBySender`,
			code_by_receiver: `${fieldsMapPrefix}.CodeByReciever`,
			code_by_seller: `${fieldsMapPrefix}.CodeBySeller`,
			code_by_buyer: `${fieldsMapPrefix}.CodeByBuyer`,
			id: true
		};
	},
	getBuyerModalConf: () => ({
		title: edi.i18n.getMessage('document.buyer'),
		tabs: {
			main: {
				company_name: edi.selectors.defaults.company_name,
				company_gln: edi.selectors.defaults.company_gln,
				company_reg_number: edi.selectors.defaults.company_reg_nr,
				company_inn: edi.selectors.defaults.company_inn
			},
			address: {
				category: edi.selectors.defaults.category,
				country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
					maxLength: 140,
					fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
						mandatory: false,
						allowBlank: true
					})
				}),
				area: Object.assign({}, edi.selectors.defaults.area, {
					name: 'addr_area',
					maxLength: 140
				}),
				region: Object.assign({}, edi.selectors.defaults.region, {
					maxLength: 140
				}),
				city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
				zip: edi.selectors.defaults.zip,
				street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
				locality: Object.assign({}, edi.selectors.defaults.locality, {
					name: 'addr_locality',
					maxLength: 140
				}),
				home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
				block: Object.assign({}, edi.selectors.defaults.block, {
					name: 'addr_block',
					maxLength: 20
				}),
				flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
				addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
			}
		}
	}),
	getDeliveryFieldsMap: () => {
		const fieldsMapPrefix = 'Invoice-Header.Delivery';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.DeliveryLocationNumber`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.CountryCode`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			code_by_sender: `${fieldsMapPrefix}.CodeBySender`,
			code_by_receiver: `${fieldsMapPrefix}.CodeByReciever`,
			code_by_seller: `${fieldsMapPrefix}.CodeBySeller`,
			code_by_buyer: `${fieldsMapPrefix}.CodeByBuyer`,
			id: true
		};
	},
	getDeliveryModalConf: () => ({
		title: edi.i18n.getMessage('document.delivery'),
		tabs: {
			main: {
				company_name: edi.selectors.defaults.company_name,
				company_gln: edi.selectors.defaults.company_gln,
				company_reg_number: edi.selectors.defaults.company_reg_nr,
				company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
					regex: null,
					maxLength: 35
				})
			},
			address: {
				category: edi.selectors.defaults.category,
				country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
					maxLength: 140,
					fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
						mandatory: false,
						allowBlank: true
					})
				}),
				area: Object.assign({}, edi.selectors.defaults.area, {
					name: 'addr_area',
					maxLength: 140
				}),
				region: Object.assign({}, edi.selectors.defaults.region, {
					maxLength: 140
				}),
				city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
				zip: edi.selectors.defaults.zip,
				street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
				locality: Object.assign({}, edi.selectors.defaults.locality, {
					name: 'addr_locality',
					maxLength: 140
				}),
				home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
				block: Object.assign({}, edi.selectors.defaults.block, {
					name: 'addr_block',
					maxLength: 20
				}),
				flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
				addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
			}
		}
	}),
	getShipFromFieldsMap: () => {
		const fieldsMapPrefix = 'ShipFrom';
		return {
			company_name: `${fieldsMapPrefix}.Name`,
			company_iln: `${fieldsMapPrefix}.ILN`,
			company_util_reg_nr: `${fieldsMapPrefix}.UtilizationRegisterNumber`,
			company_inn: `${fieldsMapPrefix}.TaxID`,
			addr_category: `${fieldsMapPrefix}.Type`,
			addr_country: `${fieldsMapPrefix}.CountryCode`,
			addr_zip: `${fieldsMapPrefix}.PostalCode`,
			addr_locality: `${fieldsMapPrefix}.Locality`,
			addr_block: `${fieldsMapPrefix}.Housing`,
			addr_addInf: `${fieldsMapPrefix}.AdditionalInformation`,
			addr_area: `${fieldsMapPrefix}.District`,
			addr_region_name: `${fieldsMapPrefix}.State`,
			addr_region_code: `${fieldsMapPrefix}.StateCode`,
			addr_city: `${fieldsMapPrefix}.CityName`,
			addr_street: `${fieldsMapPrefix}.StreetAndNumber`,
			addr_home: `${fieldsMapPrefix}.HouseNumber`,
			addr_flat: `${fieldsMapPrefix}.RoomNumber`,
			code_by_sender: `${fieldsMapPrefix}.CodeBySender`,
			code_by_receiver: `${fieldsMapPrefix}.CodeByReciever`,
			code_by_seller: `${fieldsMapPrefix}.CodeBySeller`,
			code_by_buyer: `${fieldsMapPrefix}.CodeByBuyer`,
			id: true
		};
	},
	getShipFromModalConf: () => ({
		title: edi.i18n.getMessage('document.ship.from'),
		tabs: {
			main: {
				company_name: edi.selectors.defaults.company_name,
				company_gln: edi.selectors.defaults.company_gln,
				company_reg_number: edi.selectors.defaults.company_reg_nr,
				company_inn: Object.assign({}, edi.selectors.defaults.company_inn, {
					regex: null,
					maxLength: 35
				})
			},
			address: {
				category: edi.selectors.defaults.category,
				country: Object.assign({}, edi.selectors.defaults.iso2_country_combo, {
					maxLength: 140,
					fieldConfig: Object.assign({}, edi.selectors.defaults.iso2_country_combo.fieldConfig, {
						mandatory: false,
						allowBlank: true
					})
				}),
				area: Object.assign({}, edi.selectors.defaults.area, {
					name: 'addr_area',
					maxLength: 140
				}),
				region: Object.assign({}, edi.selectors.defaults.region, {
					maxLength: 140
				}),
				city: Object.assign({}, edi.selectors.defaults.city, { maxLength: 140 }),
				zip: edi.selectors.defaults.zip,
				street: Object.assign({}, edi.selectors.defaults.street, { maxLength: 255 }),
				locality: Object.assign({}, edi.selectors.defaults.locality, {
					name: 'addr_locality',
					maxLength: 140
				}),
				home: Object.assign({}, edi.selectors.defaults.home, { maxLength: 20 }),
				block: Object.assign({}, edi.selectors.defaults.block, {
					name: 'addr_block',
					maxLength: 20
				}),
				flat: Object.assign({}, edi.selectors.defaults.flat, { maxLength: 20 }),
				addInf: Object.assign({}, edi.selectors.defaults.addInf, { maxLength: 128 })
			}
		}
	})
};
