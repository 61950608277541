import { AbstractPanel } from './abstract.panel';
import { createCombo, createEmailField, createPhoneField } from '@Components/fields';
import { createLabel } from '@UIkit/components/fields_old/Label';
import { createModalPanel } from '@UIkit/components/modal';
import { createFormContainer, createForm, createPanel, createModalForm } from '@UIkit/components/panels';
import { createButton, BUTTON_CLS } from '@UIkit/components/buttons';
import { createDateField } from '@UIkit/components/fields';

class ProfilePanel extends AbstractPanel {
	/**
	 * @type {Ext.panel.Panel}
	 * @private
	 */
	_panel;
	emailConfirmed = false;

	constructor() {
		super();

		let form = this.createForm();
		let saveButton = this.createSaveButton(form);

		this._panel = this.createPanel({
			form,
			saveButton
		});

		this.makePanelConfirmable(this._panel);
	}

	getPanel() {
		return this._panel;
	}

	createPanel({ form, saveButton }) {
		return createPanel({
			layout: 'fit',
			items: [form],
			bbar: {
				padding: '16 24',
				items: [saveButton]
			}
		});
	}

	createForm() {
		let countriesStore = edi.stores.initValidCountryFullStore();
		countriesStore.removeAt(0);
		countriesStore.insert(0, {
			iso_2: 'without',
			name: edi.i18n.getMessage('person.citizenship.without')
		});
		return (this._form = createForm({
			padding: 24,
			layout: 'hbox',
			autoScroll: true,
			items: [
				createFormContainer({
					width: 300,
					margin: '0 120 0 0',
					padding: 0,
					gap: 24,
					area: [12, 12, 12, 8, 12],
					items: [
						this.createField({
							fieldLabel: edi.i18n.getMessage('user.profile.last.name'),
							name: 'lastName',
							readOnly: true
						}),
						this.createField({
							fieldLabel: edi.i18n.getMessage('user.profile.first.name'),
							name: 'firstName',
							readOnly: true
						}),
						this.createField({
							fieldLabel: edi.i18n.getMessage('user.profile.patronymic.name'),
							name: 'patronymicName',
							readOnly: true
						}),
						this.createField(
							{
								fieldLabel: edi.i18n.getMessage('birth.date'),
								name: 'birthDate'
							},
							createDateField
						),
						this.createField(
							{
								fieldLabel: edi.i18n.getMessage('person.citizenship'),
								name: 'nationality',
								store: countriesStore,
								valueField: 'iso_2',
								displayField: 'name'
							},
							createCombo
						)
					]
				}),
				createFormContainer({
					width: 500,
					padding: 0,
					gap: 24,
					area: [8, [8, 6], 8, 8],
					items: [
						this.createField(
							{
								fieldLabel: edi.i18n.getMessage('user.profile.phone'),
								name: 'phones'
							},
							createPhoneField
						),
						this.createVerifiedEmailField({
							title: edi.i18n.getMessage('email'),
							name: 'eMail',
							allowBlank: false,
							listeners: {
								change: function (cmp, newValue) {
									const scope = this.scope,
										originalValues = scope._originalValues,
										fields = scope._fields;
									if (originalValues) {
										const oldEmail = originalValues.eMail,
											isDiff = oldEmail !== newValue;
										scope.verifiedButton.setDisabled(isDiff);
										fields.eMail.setConfirmed(isDiff);
									}
								}
							}
						}),
						(this.verifiedButton = this.createActionButton({
							margin: '26 0 0 0',
							text: edi.i18n.getMessage('action.confirm.email'),
							handler: () => this.showConfirmEmailModal()
						})),
						this.createField({
							fieldLabel: edi.i18n.getMessage('company.inn.short'),
							name: 'inn',
							regex: edi.constants.VALIDATORS.INN
						}),
						(this.personalIdField = this.createField({
							fieldLabel: edi.i18n.getMessage('snils.code'),
							itemId: 'personalId',
							regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
							regexText: edi.i18n.getMessage('invalid.snils.format'),
							inputMask: edi.constants.FIELD_MASK.SNILS,
							name: 'personalId' // в прибалтике нет СНИЛС, поэтому personalId
						}))
					]
				})
			]
		}));
	}

	createVerifiedEmailField(props) {
		let text = props.title;
		let field = this.createField(props, createEmailField);

		this._fields[props.name].setConfirmed = function (flag) {
			if (!flag) {
				field.setTitle(
					`${text} <span style="color: #ff0000">(${edi.i18n.getMessage('email.not.confirmed')})</span>`,
					true
				);
			} else {
				field.setTitle(`${text}`);
			}
		};

		return field;
	}

	setEmailConfirmed(flag) {
		this.emailConfirmed = flag;
		this.updateFieldsState();
	}

	setReadOnlyPersonalId(isReadOnly) {
		let field = Array.isArray(this.personalIdField.getItems()) ? this.personalIdField.getItems()[0] : null;
		if (field && isReadOnly) {
			field.setReadOnly(isReadOnly);
			field.toolTip = Ext.create('Ext.tip.ToolTip', {
				target: field.getEl()?.id,
				header: false,
				trackMouse: true,
				html: edi.i18n.getMessage('profile.snils.tooltip')
			});
		}
	}

	showConfirmEmailModal = function () {
		const values = this._form.getForm().getValues();

		const failure = function (data) {
			const errTxt = edi.utils.formatComplexServerError(data, 'email.confirm.error');
			edi.core.showError(errTxt, function () {
				if (modal && !modal.isDestroyed) {
					modal.close();
				}
			});
		};

		edi.rest.sendRequest(
			edi.rest.services.USER.SELF.RESEND_CONFIRMATION_EMAIL.PUT,
			'PUT',
			undefined,
			() => {},
			failure
		);

		const modal = createModalPanel(
			{
				title: edi.i18n.getMessage('action.confirm.email'),
				items: [
					createModalForm({
						items: [
							createLabel({
								cls: 'body-short_01',
								html: edi.i18n.getMessage('onboarding.email.confirmation.send', [values.eMail])
							})
						]
					})
				],
				buttonAlign: 'left',
				buttons: [
					createButton({
						text: edi.i18n.getMessage('button.ok'),
						cls: [BUTTON_CLS.primary],
						glyph: edi.constants.ICONS.DONE,
						handler: function () {
							modal.close();
						}
					})
				]
			},
			true
		);
		modal.show();
	};

	updateFieldsState() {
		super.updateFieldsState();
		this.verifiedButton.setVisible(!this.emailConfirmed);
	}

	defineFieldStates() {
		let editProfileAbility =
			edi.permissions.hasPermission('EDIT_USER_PROFILE') &&
			edi.permissions.hasPermission('EDIT_EDI_USER_PROFILE');

		return {
			lastName: {
				hidden: false,
				disabled: !editProfileAbility,
				mandatory: editProfileAbility
			},
			firstName: {
				hidden: false,
				disabled: !editProfileAbility,
				mandatory: editProfileAbility
			},
			patronymicName: {
				hidden: false,
				disabled: !editProfileAbility,
				mandatory: editProfileAbility
			},
			phones: {
				hidden: false,
				disabled: !editProfileAbility,
				mandatory: editProfileAbility
			},
			eMail: {
				confirmed: this.emailConfirmed,
				disabled: !editProfileAbility,
				mandatory: false
			},
			personalId: {
				hidden: false,
				disabled: !editProfileAbility,
				mandatory: editProfileAbility
			},
			inn: {
				hidden: false,
				disabled: !editProfileAbility,
				mandatory: editProfileAbility
			},
			birthDate: {
				hidden: false,
				disabled: !editProfileAbility,
				mandatory: editProfileAbility
			},
			nationality: {
				hidden: false,
				disabled: !editProfileAbility,
				mandatory: editProfileAbility
			}
		};
	}

	async saveFormValues(values) {
		let { firstName, lastName, patronymicName, phones, eMail, personalId, inn, birthDate, nationality } = values;
		const originalValues = this._originalValues;
		let modal,
			me = this;

		return new Promise((resolve, reject) => {
			let saveValues = function () {
				edi.rest.sendRequest(
					edi.rest.services.USER.SELF.CONTACTS.PUT,
					'PUT',
					Ext.encode({
						firstName,
						lastName,
						patronymicName,
						phones,
						eMail,
						personalId,
						inn,
						birthDate,
						nationality
					}),
					resolve,
					reject,
					function () {
						modal?.close();
					}
				);
			};

			if (
				values.personalId.length > 0 &&
				originalValues.hasOwnProperty('personalId') &&
				originalValues.personalId.length === 0
			) {
				modal = createModalPanel({
					title: edi.i18n.getMessage('profile.panel.confirm.title'),
					bodyPadding: '8 24',
					items: [
						createLabel({
							cls: 'typography body-short_01',
							html: edi.i18n.getMessage('profile.panel.confirm.msg')
						})
					],
					buttonsBefore: [
						createButton({
							cls: [BUTTON_CLS.primary],
							text: edi.i18n.getMessage('form.btn.save'),
							handler: saveValues
						})
					],
					listeners: {
						close() {
							me.hideLoader();
						}
					}
				});
				modal.show();
			} else {
				saveValues();
			}
		});
	}
}

export { ProfilePanel };
