import '@UIkit/components/fields/Combobox/Combobox';

// import fieldTpl from './ComboLogoField.ext.tpl';
// import listTpl from './ComboLogoList.ext.tpl';
import './ComboLogo.scss';

const fieldCls = 'ediweb-combologo';
const boundlistCls = 'ediweb-combologo-list';

Ext.define('Ediweb.components.ComboboxLogo', {
	extend: 'UI.components.ComboboxField',
	cls: fieldCls,

	afterRender: function () {
		const me = this,
			picker = me.getPicker();

		me.callParent();

		me.logoEl = Ext.DomHelper.insertBefore(me.inputWrap, {
			id: `${fieldCls}-field-${Ext.id()}`,
			tag: 'div',
			class: `${fieldCls}-field`,
			hidden: true
		});
		me.logoEl.addEventListener('click', function () {
			if (!me.readOnly && !me.isDisabled() && !me.isExpanded) {
				me.onTriggerClick();
			}
		});

		picker.addCls(boundlistCls);

		me.on('change', function (__self, newValue, oldValue) {
			const logo = Ext.fly(me.logoEl);
			logo.setVisible(!!newValue);
			logo.removeCls(`ediweb-combologo-list-${oldValue}`);
			logo.addCls(`ediweb-combologo-list-${newValue}`);
			//логотип ЭВ уже и есть текст, поэтому скроем инпут что бы не было два раза написано Эдивеб
			//setVisible тут не применим иначе без инпута не будут работать события кликов и потери фокуса
			me.inputWrap.el.dom.style.opacity = newValue === 'ediweb' ? 0 : 1;
		});

		picker.on('viewlist', function (__self, record, itemEl) {
			const wrapEl = itemEl.select(`[data-ref="wrapEl"]`).first();

			if (wrapEl) {
				wrapEl.addCls(`${boundlistCls}-item`);
				wrapEl.addCls(`${boundlistCls}-${record.get('name')}`);
			}
		});
	}
});

export const createComboLogo = (config) => new Ediweb.components.ComboboxLogo(config);
