/**
 * Shows confirmation dialog
 * @param  {String}         title      The title bar text
 * @param  {String}         msg        The message box body text
 * @param  {Function}       success    Invoked when answer is yes
 * @param  {Function}       failure    Invoked when answer is no
 * @param  {Function}       callback   Invoked after the message box is closed
 * @param  {Function}       cancel     Invoked when answer is cancel
 * @param  {Object}         [config]   optional config for window
 */
const confirm = function (title, msg, success, failure, callback, cancel, config) {
	let conf = Ext.merge(
		{
			autoScroll: true,
			icon: null
		},
		config
	);
	return Ext.Msg.confirm(
		edi.i18n.getMessage(title ? title : 'confirmation.title'),
		edi.i18n.getMessage(msg) + edi.utils.msgWidthCorrection(),
		function (answer) {
			if ('yes' === answer) {
				'function' == typeof success ? success() : null;
			} else if ('no' === answer) {
				'function' == typeof failure ? failure() : null;
			} else if ('cancel' === answer) {
				'function' == typeof cancel ? cancel() : null;
			}
			if ('cancel' !== answer) {
				'function' == typeof callback ? callback() : null;
			}
		},
		undefined,
		conf
	);
};

export { confirm };
