import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createActionsButton } from '@Components/buttons';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import {
	createContainer,
	createFieldBlockForDetails,
	createMaxWidthContainerForDetail,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import { serviceActSelectors } from './selectors';

Ext.namespace('edi.modules');
edi.modules['document.details.service_act'] = function () {
	let moduleData,
		part1,
		part2,
		part1Head,
		part2Head,
		utochReasonText = null,
		annulDoc,
		rejectReasonText,
		positionsStore = edi.stores.initPositionsStore(),
		areaStore = edi.stores.initAuthorityAreasStore(),
		signerStatusesStore = edi.stores.initSignerStatusesStore(),
		signerBuyerStatusesStore = edi.stores.initSignerBuyerStatusesStore();

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	const getPositionShortName = function (value) {
		const target = positionsStore.findRecord('id', value, 0, false, false, true);
		return target ? target.get('shortName') : value;
	};

	const createOS = function (selectorConfig) {
		Object.assign(selectorConfig, {
			showDetailsButton: false,
			readOnly: true,
			is_valid: true,
			valuesByMap: true,
			disableCommonAddressRenderer: true,
			disableCommonAddressTitleRenderer: true,
			fieldValues: part1
		});

		return createOrgSelector(selectorConfig);
	};

	/**
	 * Creates details panel of upd
	 * @returns {Object}
	 */
	const createDetailsPanel = function () {
		const mainData = Ext.clone(moduleData.initData.data);
		mainData.modifyDate = part2Head ? part2Head.modifyDate : part1Head.modifyDate;
		mainData.countSignatures =
			(part2Head ? part2Head.countSignatures : 0) + (part1Head ? part1Head.countSignatures : 0);
		mainData.ids = [part1Head.id];
		if (part2Head) {
			mainData.ids.push(part2Head.id);
		}
		const headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			showAnnulLog: true,
			utochReasonText: utochReasonText,
			isReceipts: !(moduleData.initData && moduleData.initData.isDocumentSharing)
		});

		const numberDate = createFieldBlockForDetails({
			cls: 'edi-form-maxwidth',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(part1, 'document.actInfo.actNumber')
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.utils.getObjectProperty(part1, 'document.actInfo.actDate')
						}
					]
				})
			]
		});

		const seller = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.seller'),
			cls: 'edi-form-maxwidth',
			items: [
				createOS({
					fieldsMap: serviceActSelectors.getSellerFieldsMap('document.actInfo.seller'),
					modalConf: serviceActSelectors.getSellerModalConf()
				})
			]
		});

		const buyer = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.buyer'),
			cls: 'edi-form-maxwidth',
			items: [
				createOS({
					fieldsMap: serviceActSelectors.getBuyerFieldsMap('document.actInfo.buyer'),
					modalConf: serviceActSelectors.getBuyerModalCong()
				})
			]
		});

		const currency = edi.utils.getObjectProperty(part1, 'document.actInfo.okv');
		let currencyValue = '';
		if (currency) {
			const { charCode, codeStr, name } = edi.utils.getOkv({
				code: currency
			});
			currencyValue = `${charCode} - ${codeStr} - UI Platform Maven Webapp`;
		}
		const invoiceCurrency = createFieldBlockForDetails({
			cls: 'edi-form-maxwidth',
			title: edi.i18n.getMessage('invoice.currency'),
			items: [
				createLabelForDetails({
					text: currencyValue
				})
			]
		});

		const contractualRelationship = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.service.act.field.supply.contract'),
			cls: 'edi-form-maxwidth',
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(part1, 'document.contractualRelationship.contractNumber')
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.utils.getObjectProperty(part1, 'document.contractualRelationship.contractDate')
						}
					]
				})
			]
		});

		const additionalContract = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.service.act.field.additional.contract'),
			cls: 'edi-form-maxwidth',
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(
								part1,
								'document.contractualRelationship.additionalContractNumber'
							)
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.utils.getObjectProperty(
								part1,
								'document.contractualRelationship.additionalContractDate'
							)
						}
					]
				})
			]
		});

		const isCorrection = !!edi.utils.getObjectProperty(part1, 'document.actInfo.correctionNumber');
		const correctionPanel = isCorrection
			? createFieldBlockForDetails({
					title: edi.i18n.getMessage('documents.service.act.field.correction'),
					cls: 'edi-form-maxwidth',
					items: [
						createLabelBlockForDetails({
							contents: [
								{
									title: edi.i18n.getMessage('field.name.number'),
									text: edi.utils.getObjectProperty(part1, 'document.actInfo.correctionNumber')
								},
								{
									title: edi.i18n.getMessage('date'),
									text: edi.utils.getObjectProperty(part1, 'document.actInfo.correctionDate')
								}
							]
						})
					]
			  })
			: null;

		let secondSignerItems = null;
		if (part2) {
			const signer = edi.utils.getObjectProperty(part2, 'buyerInfo.signer')[0];
			const legalEntityData = edi.utils.getObjectProperty(signer, 'legalEntity');
			const individualPersonData =
				edi.utils.getObjectProperty(signer, 'individualPerson') ||
				edi.utils.getObjectProperty(signer, 'individual') ||
				edi.utils.getObjectProperty(signer, 'individualInfo');

			let secondSignerData = legalEntityData || individualPersonData;

			const authArea = areaStore.findRecord(
				'id',
				edi.utils.getObjectProperty(signer, 'authorityArea'),
				0,
				false,
				false,
				true
			);
			const signerState = signerBuyerStatusesStore.findRecord(
				'id',
				edi.utils.getObjectProperty(signer, 'state'),
				0,
				false,
				false,
				true
			);

			const signerTestClassPrefix = 'signer';
			const acceptTestClassPrefix = 'accept';
			secondSignerItems = createFieldBlockForDetails({
				cls: FIELD_BLOCK_CLS.titleMarginMedium,
				title: edi.i18n.getMessage('document.fns_upd.secondary.data'),
				layout: {
					type: 'grid',
					gap: 16
				},
				items: [
					createFieldBlockForDetails({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('documents.create.torg.title.receive.description'),
						items: [
							createLabelBlockForDetails({
								gap: [16, 16],
								contents: [
									{
										title: edi.i18n.getMessage('column.position'),
										textCls: `${signerTestClassPrefix}-position`,
										text:
											!individualPersonData &&
											getPositionShortName(
												edi.utils.getObjectProperty(secondSignerData, 'position')
											)
									},
									{
										title: edi.i18n.getMessage('last.name'),
										textCls: `${signerTestClassPrefix}-lastName`,
										text: secondSignerData?.fullName?.lastName || secondSignerData?.name?.lastName
									},
									{
										title: edi.i18n.getMessage('first.name'),
										textCls: `${signerTestClassPrefix}-firstName`,
										text: secondSignerData?.fullName?.firstName || secondSignerData?.name?.firstName
									},
									{
										title: edi.i18n.getMessage('patronymic.name'),
										textCls: `${signerTestClassPrefix}-middleName`,
										text:
											secondSignerData?.fullName?.middleName || secondSignerData?.name?.middleName
									},
									{
										isNewLine: true,
										title: edi.i18n.getMessage('upd.receive.date'),
										text: edi.utils.getObjectProperty(
											part2,
											'buyerInfo.contentFactsEconomicLife.infoGoodsReception.dateReceiptGoods'
										)
									},
									{
										title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
										textCls: `${signerTestClassPrefix}-authorityArea`,
										isNewLine: true,
										text: authArea
											? authArea.get('name')
											: edi.utils.getObjectProperty(signer, 'authorityArea')
									},
									{
										title: edi.i18n.getMessage('documents.fns_upd.signer.status'),
										textCls: `${signerTestClassPrefix}-state`,
										isNewLine: true,
										text: signerState
											? signerState.get('name')
											: edi.utils.getObjectProperty(signer, 'state')
									},
									{
										isNewLine: true,
										title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
										textCls: `${signerTestClassPrefix}-baseAuthory`,
										text: edi.utils.getObjectProperty(signer, 'baseAuthory')
									},
									{
										title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
										textCls: `${signerTestClassPrefix}-baseOrgAuthory`,
										text: edi.utils.getObjectProperty(signer, 'baseOrgAuthory')
									},
									{
										isNewLine: true,
										title: edi.i18n.getMessage('individual.certificateRegistration'),
										text: edi.utils.getObjectProperty(
											individualPersonData,
											'certificateRegistrationIP'
										)
									}
								]
							})
						]
					}),
					createFieldBlockForDetails({
						cls: FIELD_BLOCK_CLS.small,
						title: edi.i18n.getMessage('upd.accept.tab.title'),
						items: [
							createLabelBlockForDetails({
								gap: [16, 16],
								contents: [
									{
										title: edi.i18n.getMessage('job.title'),
										textCls: `${acceptTestClassPrefix}-position`,
										text: edi.utils.getObjectProperty(
											part2,
											'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.position'
										)
									},
									{
										title: edi.i18n.getMessage('last.name'),
										textCls: `${acceptTestClassPrefix}-lastName`,
										text: edi.utils.getObjectProperty(
											part2,
											'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.lastName'
										)
									},
									{
										title: edi.i18n.getMessage('first.name'),
										textCls: `${acceptTestClassPrefix}-firstName`,
										text: edi.utils.getObjectProperty(
											part2,
											'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.firstName'
										)
									},
									{
										title: edi.i18n.getMessage('patronymic.name'),
										textCls: `${acceptTestClassPrefix}-middleName`,
										text: edi.utils.getObjectProperty(
											part2,
											'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.fullName.middleName'
										)
									},
									{
										isNewLine: true,
										title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
										textCls: `${acceptTestClassPrefix}-authority`,
										text: edi.utils.getObjectProperty(
											part2,
											'buyerInfo.contentFactsEconomicLife.infoGoodsReception.workerReceiptGoods.workerInOrg.authority'
										)
									}
								]
							})
						]
					})
				]
			});
		}

		const lines = edi.utils.getObjectProperty(part1, 'document.actServiceBonusType.BounsType', true);
		let i = 1,
			productLineItemStoreData = [];
		while (i <= 23) {
			productLineItemStoreData.push(i++);
		}
		const productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'FNS_UPD_SERVICE_AKT_LINE',
			totalModel: 'FNS_UPD_SERVICE_AKT_SUMMARY',
			gridColumnConfig: 'fns_upd_service_act_lines',
			totalColumnConfig: 'fns_upd_service_act_summary',
			readOnly: true,
			data: lines,
			lineNumberFieldName: 'lineNumber',
			docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT,
			docData: part1.document,
			modalFormConfig: {
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('service_act.product.line.item.name'),
								forceSelection: false,
								valueInitialize: true,
								name: 'name',
								type: 'combo',
								store: edi.stores.createSimpleInlineStore(productLineItemStoreData, function (id) {
									return edi.i18n.getMessage('service_act.product.line.item.name.item.' + id);
								}),
								valueField: 'name'
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.period'),
								name: 'period',
								type: 'combo',
								forceSelection: false,
								valueInitialize: true,
								store: edi.stores.createSimpleInlineStore([1, 2], function (id) {
									return edi.i18n.getMessage('service_act.product.line.item.period.item.' + id);
								}),
								valueField: 'name'
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.currency'),
								name: 'currency'
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.planingSum'),
								name: 'planingSum'
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.factSum'),
								name: 'factSum'
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.sumWithoutNDS'),
								name: 'sumWithoutNDS'
							},
							{
								title: edi.i18n.getMessage('service_act.product.line.item.bonusAmountTotal'),
								name: 'bonusAmountTotal'
							}
						]
					}
				]
			}
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6]]
			},
			items: [
				headPanel,
				numberDate,
				seller,
				buyer,
				invoiceCurrency,
				contractualRelationship,
				additionalContract,
				correctionPanel,
				productsGrid,
				createSignerItems(),
				secondSignerItems
			]
		});
	};

	const createSignerItems = function () {
		let fieldData = edi.utils.getObjectProperty(part1, 'document.signers');
		if (!Array.isArray(fieldData)) {
			fieldData = [fieldData];
		}

		const createFields = function (fieldData) {
			const legalEntityData = edi.utils.getObjectProperty(fieldData, 'legalEntity');
			const individualPersonData =
				edi.utils.getObjectProperty(fieldData, 'individualPerson') ||
				edi.utils.getObjectProperty(fieldData, 'individual');
			const authArea = areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = signerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'state'),
					0,
					false,
					false,
					true
				);

			const signerTestClassPrefix = 'signer';
			return createLabelBlockForDetails({
				gap: [16, 16],
				margin: '8 0 0 0',
				contents: [
					{
						title: edi.i18n.getMessage('column.position'),
						hidden: !legalEntityData,
						textCls: `${signerTestClassPrefix}-position`,
						text: getPositionShortName(edi.utils.getObjectProperty(legalEntityData, 'position'))
					},
					{
						title: edi.i18n.getMessage('last.name'),
						textCls: `${signerTestClassPrefix}-lastName`,
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.lastName' : 'name.lastName'
						)
					},
					{
						title: edi.i18n.getMessage('first.name'),
						textCls: `${signerTestClassPrefix}-firstName`,
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.firstName' : 'name.firstName'
						)
					},
					{
						title: edi.i18n.getMessage('patronymic.name'),
						textCls: `${signerTestClassPrefix}-middleName`,
						text: edi.utils.getObjectProperty(
							legalEntityData || individualPersonData,
							legalEntityData ? 'fullName.middleName' : 'name.middleName'
						)
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
						textCls: `${signerTestClassPrefix}-authorityArea`,
						isBlock: true,
						text: authArea ? authArea.get('name') : edi.utils.getObjectProperty(fieldData, 'authorityArea')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.status'),
						textCls: `${signerTestClassPrefix}-state`,
						isBlock: true,
						text: signerState ? signerState.get('name') : edi.utils.getObjectProperty(fieldData, 'state')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
						textCls: `${signerTestClassPrefix}-baseAuthory`,
						text: edi.utils.getObjectProperty(fieldData, 'baseAuthory')
					},
					{
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
						textCls: `${signerTestClassPrefix}-baseOrgAuthory`,
						text: edi.utils.getObjectProperty(fieldData, 'baseOrgAuthory')
					}
				]
			});
		};

		return createMaxWidthContainerForDetail({
			items: fieldData.map((signer, i) =>
				createFieldBlockForDetails({
					cls: [FIELD_BLOCK_CLS.default, FIELD_BLOCK_CLS.detailsBlock],
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					title:
						fieldData.length > 1 && i > 0
							? `${edi.i18n.getMessage('company.signer')} ${++i}`
							: edi.i18n.getMessage('company.signer'),
					items: [createFields(signer)]
				})
			)
		});
	};

	/**
	 * Creates action pane
	 */
	const createModuleActionsPanel = function () {
		const data = moduleData.initData.data;

		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const isOutGoingDocument = edi.constants.DIRECTIONS.OUTGOING === direction;
		const hasPart2 = !!edi.utils.getAttributeByName(data.attributes, 'hasPart2') || !!part2Head;
		const totalSignaturesCount = edi.document.actions.getSignCount(data);

		const neededSignaturesCount = isOutGoingDocument
			? totalSignaturesCount - part1Head.countSignatures
			: totalSignaturesCount - (hasPart2 && part2Head ? part2Head.countSignatures : 0);
		const actionsPanel = createActionsPanel();
		let editCmp = null;

		if (
			isOutGoingDocument &&
			edi.action.isAvailable(
				edi.constants.DOCUMENT_ACTIONS.EDIT,
				edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data))
			)
		) {
			editCmp = edi.document.actions.createEditActionButton('document.create.service_act', data, data.number);
		}

		let readCmp = null;
		if (edi.constants.DIRECTIONS.INCOMING === direction) {
			readCmp = createActionsButton({
				text: edi.i18n.getMessage('document.mark.read'),
				glyph: edi.constants.ICONS.READ,
				handler: function () {
					edi.core.confirm(
						edi.i18n.getMessage('document.mark.read'),
						edi.i18n.getMessage('document.mark.read.question'),
						function () {
							moduleData.tab.setLoading();
							const success = function () {
								moduleData.tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: data.id
								});
							};
							const failure = edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.mark.read'
							);
							const markRead = function () {
								const postData = {};
								postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID] = edi.constants.STATE.SENT;
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: data.id
										},
										true
									),
									'PUT',
									Ext.encode(postData),
									success,
									failure
								);
							};
							markRead();
						}
					);
				}
			});
		}

		const excludeActions = {};
		if (!editCmp) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.EDIT] = true;
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: neededSignaturesCount,
			hasPart2: hasPart2,
			excludeActions: excludeActions,
			actionProps: {
				SIGN: {
					methodAddOptions: {
						contentId:
							edi.constants.DIRECTIONS.OUTGOING === direction
								? part1Head.id
								: part2Head
								? part2Head.id
								: null,
						useBeforeAction:
							data.state === edi.constants.STATE.DRAFT || data.state === edi.constants.STATE.READ
					}
				},
				EDIT: {
					component: editCmp
				},
				READ: {
					component: readCmp
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				ANNUL: {
					methodAddOptions: annulDoc
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE_WITH_SIGN, {
						documentId: data.id
					}),
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					extendedExportBtnUrl: edi.utils.formatString(
						edi.rest.services.DOCUMENTS.EXPORT.COMMON_ALL_DOCUMENT,
						{
							documentId: data.id
						}
					),
					addExtendedExport: true,
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		if (
			data &&
			data.id &&
			(data.id === moduleData.initData.data.id ||
				(part1Head && data.id === part1Head.id) ||
				(part2Head && data.id === part2Head.id))
		) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				moduleData.tab.removeAll();
				renderData();
			}
		}
	};
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		const modulePanel = createDetailsModulePanel();
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		let documentHeaderData = moduleData.initData.data;

		const continueWithRendering = function () {
			const businessState = edi.utils.getObjectProperty(documentHeaderData, 'businessState');

			const continueLoadAgreement = function () {
				modulePanel.add(createDetailsPanel());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);

				if (
					annulDoc &&
					annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
					annulDoc.toOrg.id === edi.core.getUserOrgID()
				) {
					edi.methods.documents.showActiveAnnulModal(annulDoc, moduleData);
				}

				if ('function' == typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}
			};

			annulDoc = null;
			utochReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'UTOCH_REASON');
			const isAnnualUtoch =
				utochReasonText ||
				businessState === edi.constants.STATE.ON_ANNUL ||
				businessState === edi.constants.STATE.ANNUL_SIGN;
			if (isAnnualUtoch) {
				edi.rest.sendRequest(
					edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
						depth: edi.constants.DEFAULT.TREE_DEPTH
					}),
					'GET',
					{},
					function (responseData) {
						let utochId;
						if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
							for (let i = 0; i < responseData.items.length; i++) {
								if (
									isAnnualUtoch &&
									responseData.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL &&
									edi.constants.STATE.WAIT_PARTNER_DECISION === responseData.items[i].state
								) {
									annulDoc = responseData.items[i];
								} else if (
									isAnnualUtoch &&
									responseData.items[i].type ===
										edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_STATUS_9_DP_UVUTOCH
								) {
									utochId = responseData.items[i].id;
								}
							}
						}

						if (!utochId) {
							continueLoadAgreement();
						} else {
							edi.rest.sendRequest(
								edi.document.actions.formatDetailsUri(moduleData.initData, utochId, true),
								'GET',
								{},
								function (responseData) {
									if (responseData.success && responseData.data) {
										utochReasonText = edi.utils.getObjectProperty(
											responseData.data,
											'Document.DataOfUvUtoch.TextNoticeUtoch'
										);
									}
									continueLoadAgreement();
								},
								failure
							);
						}
					},
					failure
				);
			} else {
				continueLoadAgreement();
			}
		};

		edi.document.actions.updateDocumentHeaderData(moduleData, function () {
			documentHeaderData = moduleData.initData.data;
			edi.rest.sendRequest(
				edi.document.actions.formatLinkedUri(moduleData.initData),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						const ids = [];
						for (let i = 0; i < data.data.children.length; i++) {
							if (
								data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P1
							) {
								ids.push(data.data.children[i].id);
							} else if (
								data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P2
							) {
								ids.push(data.data.children[i].id);
							}
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL) {
								rejectReasonText = edi.utils.getObjectProperty(
									data.data.children[i],
									'attributes.REJECT_REASON.value'
								);
							}
							if (ids.length === 2) {
								break;
							}
						}

						const latestAnnulRejectReason = data.data.children
							.filter((child) => child.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL)
							.reduce((latestAnnulRejectReason, child) => {
								return latestAnnulRejectReason?.modifyDate > child.modifyDate
									? latestAnnulRejectReason
									: child;
							}, null);

						if (ids.length) {
							edi.rest.sendRequest(
								edi.document.actions.formatBatchHeaderUri(moduleData.initData),
								'POST',
								Ext.encode(ids),
								function (data) {
									part2Head = null;
									for (let i = 0; i < data.items.length; i++) {
										if (
											data.items[i].type ===
											edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P1
										) {
											part1Head = data.items[i];
											edi.document.actions.changeTabTitle(moduleData.tab, part1Head.number);
										} else if (
											data.items[i].type ===
											edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_SERVICE_AKT_P2
										) {
											part2Head = data.items[i];
										}
									}
									edi.rest.sendRequest(
										edi.document.actions.formatBatchContentUri(moduleData.initData),
										'POST',
										Ext.encode(ids),
										function (data) {
											part2 = null;
											if (data && data.items) {
												for (let i = 0; i < data.items.length; i++) {
													if (part1Head.id === data.items[i].header) {
														part1 = data.items[i];
													} else if (part2Head && part2Head.id === data.items[i].header) {
														part2 = data.items[i];
													}
												}
												continueWithRendering();
											} else {
												failure(data);
											}
										},
										failure
									);
								},
								failure
							);
						} else {
							failure();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		});
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
