import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createProxyConfig } from '@Components/storeComponents';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createContainer, createFieldBlockForDetails } from '@UIkit/components/panels';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createLabelBlockForDetails } from '@UIkit/components/fields';
import { getFileNameAttachDocumentsColumns } from './columns';
import { getFileNameAttachModelName } from './models';

/**
 * Class for on_avz details
 * @author Anatoli Deryshev
 */
Ext.namespace('edi.modules');
edi.modules['document.details.on_avz'] = function () {
	let moduleData,
		part1,
		header,
		sellerData,
		attachItems = [],
		noticeDoc = null,
		isOutgoingDirection = false,
		annulDoc,
		utochReasonText = null,
		annulReasonText = null,
		latestAnnulRejectReasonText;
	const userData = edi.core.getUserData();

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	const createOS = function (selectorConfig) {
		Ext.applyIf(selectorConfig, {
			is_valid: true,
			readOnly: true,
			disableCommonAddressRenderer: true,
			disableCommonAddressTitleRenderer: true,
			valuesByMap: false
		});

		return createOrgSelector(selectorConfig);
	};

	/**
	 * Стороны
	 */
	const createPartiesBlock = function () {
		let buyerData;
		if (isOutgoingDirection) {
			const buyerOrg = edi.utils.getObjectProperty(part1, 'register.buyer');
			const buyerOrgKpp = edi.utils.getObjectProperty(buyerOrg, 'identification.legalEntity.kpp');
			const buyerOrgTin = edi.utils.getObjectProperty(buyerOrg, 'identification.legalEntity.tin');
			buyerData = searchOrganization(buyerOrgKpp, buyerOrgTin);
		} else {
			buyerData = userData.org;
		}
		const userOrganization = edi.converters.convertOrgToPartie(buyerData);
		const buyer = createOS({
			fieldValues: userOrganization,
			selectedOrgValues: userOrganization
		});

		const sellerOrganization = edi.converters.convertOrgToPartie(sellerData);
		const seller = createOS({
			fieldValues: sellerOrganization,
			selectedOrgValues: sellerOrganization
		});

		return createContainer({
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[6, 6]]
			},
			items: [
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('document.sender'),
					items: [seller]
				}),
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('documents.receiver'),
					items: [buyer]
				})
			]
		});
	};

	const createHeaderInfoItems = function () {
		const headerLines = [
			{
				title: edi.i18n.getMessage('document.form.number'),
				text: edi.utils.getObjectProperty(part1, 'register.document.documentNumber')
			},
			{
				title: edi.i18n.getMessage('documents.column.date'),
				text: edi.utils.getObjectProperty(part1, 'register.document.documentDate')
			},
			{
				title: edi.i18n.getMessage('document.docId'),
				text: edi.utils.getObjectProperty(part1, 'register.document.documentId')
			}
		];

		return createFieldBlockForDetails({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					gap: [8, 16],
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: headerLines
				})
			]
		});
	};

	const createAttachGridInfoItems = function () {
		const columns = getFileNameAttachDocumentsColumns();
		const actionButtonConfig = [
			{
				glyph: edi.constants.ICONS.CLOUD_DOWNLOAD,
				testCls: 'test-action-column-download',
				text: edi.i18n.getMessage('action.export.attachment'),
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return !record.get('FILE_ID');
				},
				handler: function (grid, rowIndex) {
					const store = grid.getStore();
					const recordData = store.getAt(rowIndex).getData();
					const url = edi.document.actions.formatExportAttachementUrl({
						id: recordData.id
					});
					edi.rest.downloadFile(url, recordData.id);
				}
			},
			{
				glyph: edi.constants.ICONS.PRINT,
				testCls: 'test-action-column-print',
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return !record.get('FILE_ID');
				},
				handler: function (grid, rowIndex) {
					const record = grid.getStore().getAt(rowIndex),
						recordData = record.getData();
					edi.document.actions.createPrintModal(
						recordData,
						{
							usingReport: true
						},
						recordData.id
					);
				}
			}
		];

		columns.push(
			createActionsColumnConfig({
				align: 'right',
				items: actionButtonConfig
			})
		);

		return createGrid({
			proxyConfig: createProxyConfig(),
			storeConfig: {
				model: getFileNameAttachModelName(),
				data: {
					items: attachItems
				}
			},
			gridConfig: {
				title: edi.i18n.getMessage('document.attachments'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				columns,
				region: 'center',
				border: 0,
				disablePaging: true
			}
		});
	};

	/**
	 * Creates details panel of on_avz
	 * @returns {Object}
	 */
	const createDetailsPanel = function () {
		const mainData = Ext.clone(moduleData.initData.data);

		const headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			objectData: mainData,
			utochReasonText: utochReasonText,
			latestAnnulRejectReasonText: latestAnnulRejectReasonText,
			isReceipts: !(moduleData.initData && moduleData.initData.isDocumentSharing),
			usingReport: true,
			isCrptStatus: true,
			showCRPTPrintBtn: false,
			showAnnulLog: true,
			annulLogTitle: edi.i18n.getMessage('document.mark.annul.reject.reason.value'),
			defaultFormat: edi.constants.REPORT_FORMATS.XLS,
			noUsage: true,
			noLog: true
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [headPanel, createHeaderInfoItems(), createPartiesBlock(), createAttachGridInfoItems()]
		});
	};

	/**
	 * Creates action pane
	 */
	const createModuleActionsPanel = function () {
		const actionsPanel = createActionsPanel();
		const data = moduleData.initData.data;

		const excludedActions = {
			PRINT: true,
			PACKAGING: true
		};

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			excludeActions: excludedActions,
			moduleData: moduleData,
			actionProps: {
				ANNUL: {
					methodAddOptions: annulDoc
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				}
			}
		});
		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		if (data && data.id && data.id == moduleData.initData.data.id) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				moduleData.tab.removeAll();
				renderData();
			}
		}
	};

	const showIzvPolSignModal = function (document) {
		const signIzvPolModal = edi.core.showWarn('dsf.need.sign.izv.pol', undefined, undefined, {
			okBtnConfig: {
				handler: function () {
					signIzvPolModal.close();
					edi.utils.sign(
						document,
						moduleData.tab,
						function (failed, responseData) {
							if (failed) {
								edi.core.showError(edi.utils.formatComplexServerError(responseData));
							} else {
								edi.events.documents.fireEvent('change', {
									id: document.id
								});
							}
						},
						undefined,
						undefined,
						undefined,
						undefined,
						{
							signContentUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.SIGN.GET, {
								documentId: noticeDoc.id
							}),
							signContentUrlMethod: 'PUT'
						}
					);
				}
			}
		});
	};

	const rollbackAnnulSign = function () {
		const success = function () {
			changeHandler(moduleData.initData.data);
		};
		const fail = edi.rest.getErrorHandler('error.getting.data');
		const url = edi.utils.formatString(
			edi.rest.services.DOCUMENTS.SEND.PUT,
			{
				documentId: annulDoc?.id
			},
			true
		);
		//на бэке проверяется наличие или подписи или REJECT: true, по факту это будет обычный пуш
		// просто отправим реджект что бы бэк не ругался
		const putData = {};
		putData[edi.constants.BUSINESS_PROCESS_PROPERTIES.REJECT] = true;
		edi.rest.sendRequest(url, 'PUT', Ext.encode(putData), success, fail);
	};

	const getTreeLinkedDocuments = function (callback) {
		const hasIzvPol = header.state === edi.constants.STATE.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE;
		annulDoc = null;
		const documentHeaderData = moduleData.initData.data;
		annulReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'ANNUL_REASON');
		utochReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'UTOCH_REASON');
		const businessState = edi.utils.getObjectProperty(documentHeaderData, 'businessState');
		const isAnnual =
			annulReasonText ||
			businessState === edi.constants.STATE.ON_ANNUL ||
			businessState === edi.constants.STATE.ANNUL_SIGN;
		if (hasIzvPol || isAnnual) {
			const failure = edi.document.actions.defaultFailureHandler(
				moduleData.tab,
				'error.getting.data',
				function () {
					edi.modulesHandler.removeModule(moduleData);
				}
			);
			edi.rest.sendRequest(
				edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
					depth: edi.constants.DEFAULT.TREE_DEPTH
				}),
				'GET',
				{},
				function (responseData) {
					if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
						let i,
							linkedDocs = responseData.items;
						for (i = 0; i < linkedDocs.length; i++) {
							if (
								linkedDocs[i].type == edi.constants.DOCUMENT_TYPES.DP_IZVPOL_AVZ &&
								header.state == edi.constants.STATE.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE &&
								!isOutgoingDirection
							) {
								//For DP_IZVPOL_AVZ dsf state save first founded IZV_POL (cause it's the first and only IZV_POL)
								noticeDoc = linkedDocs[i];
							}
							if (
								isAnnual &&
								responseData.items[i].type === edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL &&
								[
									edi.constants.STATE.WAIT_PARTNER_DECISION,
									edi.constants.STATE.DRAFT,
									edi.constants.STATE.WAIT_UTOCH_SIGN
								].some((it) => it === responseData.items[i].state)
							) {
								annulDoc = responseData.items[i];
							}
						}
					}
					callback();
				},
				failure
			);
		} else {
			callback();
		}
	};

	const getLinkedsDocuments = function (packageId, callback) {
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		attachItems = [];

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
				documentId: packageId
			}),
			'GET',
			undefined,
			function (data) {
				if (data && data.data && data.data.children && data.data.children.length) {
					attachItems = [];
					let i;
					for (i = 0; i < data.data.children.length; i++) {
						if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.ON_AVZ_ATTACHMENT) {
							attachItems.push(data.data.children[i]);
						} else if (data.data.children[i].type == edi.constants.DOCUMENT_TYPES.ON_AVZ_PRANNUL) {
							latestAnnulRejectReasonText = edi.utils.getObjectProperty(
								data.data.children[i],
								'attributes.REJECT_REASON.value'
							);
						}
					}
					attachItems = attachItems.filter(function (item) {
						return item.type === edi.constants.DOCUMENT_TYPES.ON_AVZ_ATTACHMENT;
					});
					callback();
				} else {
					callback();
				}
			},
			failure
		);
	};

	const isCurrentOrganization = function (sellerOrgKpp, sellerOrgTin) {
		const currentOrg = userData.org;
		const currentOrgInn = currentOrg.inn ? currentOrg.inn : '';
		const currentOrgKpp = currentOrg.kpp ? currentOrg.kpp : '';
		return (!currentOrgKpp || currentOrgKpp === sellerOrgKpp) && (!currentOrgInn || currentOrgInn === sellerOrgTin);
	};

	const searchOrganization = function (sellerOrgKpp, sellerOrgTin) {
		return edi.relations.getRelations().find(function (org) {
			const orgInn = org.inn ? org.inn : '';
			const orgKpp = org.kpp ? org.kpp : '';
			return (!orgKpp || orgKpp === sellerOrgKpp) && (!orgInn || orgInn === sellerOrgTin);
		});
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const continueWithRendering = function () {
			modulePanel.add(createDetailsPanel());
			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);
			const needSignIzvPol = header.state === edi.constants.STATE.DP_IZVPOL_TO_ON_AVZ_AWAITING_SIGNATURE;

			if (annulDoc?.state === edi.constants.STATE.WAIT_UTOCH_SIGN) {
				//Если документ находится в этом статусе, значит мы начали процесс отмены аннулирования,
				//но по каким-то причинам сделали всё кроме подписания отмены и процесс завис.
				//Сделаем откат обратно в состояние WAIT_PARTNER_DECISION, и юзер сможет повторно выбрать что он хочет
				//"Подтвердить" или "Отклонить"
				rollbackAnnulSign();
			} else if (needSignIzvPol && noticeDoc) {
				showIzvPolSignModal(header);
			} else if (
				annulDoc &&
				annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
				annulDoc.toOrg.id == edi.core.getUserOrgID()
			) {
				edi.methods.documents.showActiveAnnulModal(annulDoc, moduleData);
			}
			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};

		moduleData.tab.setLoading();
		const modulePanel = createDetailsModulePanel();
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		edi.document.actions.updateDocumentHeaderData(moduleData, function () {
			const ids = moduleData.initData.id ? [moduleData.initData.id] : [];
			header = moduleData.initData.data;
			edi.rest.sendRequest(
				edi.document.actions.formatBatchContentUri(moduleData.initData),
				'POST',
				Ext.encode(ids),
				function (data) {
					if (data && data.items && data.items && data.items[0]) {
						part1 = data.items[0];
						edi.document.actions.changeTabTitle(
							moduleData.tab,
							edi.utils.getObjectProperty(part1, 'register.document.documentNumber')
						);
						const sellerOrg = edi.utils.getObjectProperty(part1, 'register.seller');
						const sellerOrgKpp = edi.utils.getObjectProperty(sellerOrg, 'identification.legalEntity.kpp');
						const sellerOrgTin = edi.utils.getObjectProperty(sellerOrg, 'identification.legalEntity.tin');
						isOutgoingDirection = isCurrentOrganization(sellerOrgKpp, sellerOrgTin);
						if (isOutgoingDirection) {
							sellerData = userData.org;
						} else {
							sellerData = searchOrganization(sellerOrgKpp, sellerOrgTin);
						}
						edi.rest.sendRequest(
							edi.utils.formatString(edi.rest.services.USER.BANK_ACCOUNTS.GET, { orgId: sellerData.id }),
							'GET',
							undefined,
							function (data) {
								if (data && data.items) {
									sellerData.accounts = data.items;
								}
							},
							undefined,
							function () {
								getLinkedsDocuments(part1.header, function () {
									getTreeLinkedDocuments(function () {
										continueWithRendering();
									});
								});
							}
						);
					}
				},
				failure
			);
		});
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
