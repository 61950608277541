import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createAddModulePanel, createForm, createFormForModule } from '@Components/panels';
import { createCheckbox, createCombo, createDateField, createTextField } from '@Components/fields';
import { createActionsButton, createSendButton } from '@Components/buttons';
import {
	createActionsColumnConfig,
	createCheckboxSelectionModel,
	createGrid,
	createToolBar
} from '@UIkit/components/grid';
import { createModalRemoteSelect } from '@Core/specialComponents/modals';
import { createButtonContainer, createFieldBlock, createModalForm, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createProxyConfig } from '@Components/storeComponents';
import { createMenuItem } from '@Components/miscComponents';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { createLabel } from '@UIkit/components/fields';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { filterMethods } from './filter';

Ext.namespace('edi.modules');
edi.modules['document.create.on.service.container'] = function () {
	let moduleData,
		addButton,
		deleteButton,
		userData = edi.core.getUserData(),
		docContent = {},
		isEdit = false,
		initialData,
		isCreate = false,
		buyerOrg,
		buyerOrgValues,
		seller,
		buyer,
		isCreateContainer = false,
		productsGrid,
		containerForm,
		buyerId,
		containerId,
		containerData,
		attachmentsStoreData = [],
		docNumberField,
		sendButton,
		uploadModal,
		addUpdUkdBtn,
		addAttachmentBtn;
	let maxFileSize;

	const modalAdditionalConf = {
		main: {
			company_gln: {
				allowBlank: true
			},
			individual_gln: {
				allowBlank: true
			},
			individual_inn: {
				allowBlank: false
			}
		},
		address: {
			block: {
				maxLength: 20
			}
		},
		bank: {
			bank_acc: {
				allowBlank: true
			},
			bank_corr_acc: {
				maxLength: 20
			},
			bank_id: {
				regex: edi.constants.VALIDATORS.BIK,
				invalidText: edi.i18n.getMessage('invalid.value.bank.id')
			},
			bank_name: {
				maxLength: 1000
			}
		},
		contact: {
			fax: null
		}
	};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		if (moduleData.initData.data) {
			initialData = moduleData.initData.data;
			buyerOrg = moduleData.initData.data.toOrg;
			isEdit = true;
			isCreate = true;
		}

		renderData(initCallBack);

		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	const isValidOrganization = function (params) {
		params = params || {};

		const organization = params.organization;
		const organizationType = params.organizationType;
		const useOrganizationMap = params.useOrganizationMap;

		const getFieldValue = function (mapKey) {
			const organizationFieldsMap = {
				company_name: 'identification.legalEntity.orgName',
				company_kpp: 'identification.legalEntity.kpp',
				company_inn: 'identification.legalEntity.tin',

				individual_lastname: 'identification.individualPerson.name.lastName',
				individual_firstname: 'identification.individualPerson.name.firstName',
				individual_inn: 'identification.individualPerson.tin',

				addr_rus_region: 'address.addressRu.regionCode',
				addr_for_country: 'address.addressInfo.codeOfCountry',
				addr_for_text: 'address.addressInfo.addressText'
			};
			const fieldName = useOrganizationMap ? organizationFieldsMap[mapKey] : mapKey;

			return edi.utils.getObjectProperty(organization, fieldName);
		};

		let valid = getFieldValue('company_name');
		valid = valid || (getFieldValue('individual_lastname') && getFieldValue('individual_firstname'));

		if ('seller' === organizationType || 'buyer' === organizationType) {
			valid = valid && (getFieldValue('company_inn') || getFieldValue('individual_inn'));
			valid = valid && (!getFieldValue('company_inn') || getFieldValue('company_kpp'));
		}

		valid =
			valid &&
			(getFieldValue('addr_rus_region') || (getFieldValue('addr_for_country') && getFieldValue('addr_for_text')));
		return !!valid;
	};

	//Checks company controls, and marks them, if not valid
	const validateCompanySelectors = function () {
		const buyerIsValid = buyer.isValid();
		const sellerIsValid = seller.isValid();
		return buyerIsValid && sellerIsValid;
	};

	const deleteAttachment = function (id, params, success, failure) {
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.UNLINK.PUT, {
				id: id
			}),
			'PUT',
			params,
			success,
			failure ||
				function (data) {
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
				}
		);
	};

	const signAddedDocuments = function (callback) {
		const containerDocuments = productsGrid?.getStore()?.getRange();
		let countReadyDocuments = 0;

		const onEndSign = function () {
			if (countReadyDocuments !== containerDocuments.length) {
				return;
			}
			if ('function' == typeof callback) {
				callback();
			}
		};

		const signDocument = function (record, documentId, beforeSign) {
			edi.utils.sign(
				record.data,
				moduleData.tab,
				function (failed, data) {
					countReadyDocuments++;
					if (failed) {
						edi.document.actions.defaultFailureHandler(
							moduleData.tab,
							'document.error.in.sign.process'
						)(data);
					}
					onEndSign();
				},
				documentId,
				beforeSign,
				true,
				null,
				{
					signRefuse: () => moduleData.tab?.setLoading(false)
				}
			);
		};

		containerDocuments.forEach((record) => {
			const type = record.get('type');
			if (
				type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
				type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
			) {
				const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.SORTED.GET, {
					documentId: record.get('id')
				});
				edi.rest.sendRequest(
					url,
					'GET',
					null,
					function (data) {
						let part1Head = null;
						for (let i = 0; i < data.data.children.length; i++) {
							if (
								data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD_P1 ||
								data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1
							) {
								part1Head = data.data.children[i];
							}
						}
						if (!part1Head) {
							countReadyDocuments++;
							onEndSign();
							edi.core.showError('currency.operation.certificate.attachment.sign.failure');
							return;
						}
						const pushDocument = function (success, failure) {
							let postData = {};
							const stringified = Ext.encode(postData);
							edi.rest.sendRequest(
								edi.utils.formatString(
									edi.rest.services.DOCUMENTS.SEND.PUT,
									{
										documentId: record.get('id')
									},
									true
								),
								'PUT',
								stringified,
								function () {
									'function' == typeof success ? success() : null;
								},
								function (data) {
									'function' == typeof failure ? failure(data) : null;
								}
							);
						};
						const useBeforeAction = record.get('state') === edi.constants.STATE.DRAFT;
						signDocument(record, part1Head.id, useBeforeAction ? pushDocument : undefined);
					},
					function () {
						edi.core.showError('currency.operation.certificate.attachment.sign.failure');
					}
				);
			} else {
				const isAttachmentNeedSign =
					record.get('type') === edi.constants.DOCUMENT_TYPES.ON_SERVICE_ATTACHMENT &&
					record.get('senderSignatureExpected');
				const recordId = record.get('id');

				if (isAttachmentNeedSign) {
					signDocument(record, recordId, undefined);
				} else {
					countReadyDocuments++;
					onEndSign();
				}
			}
		});
	};

	//Checks document validity and enables/disables create button
	const checkValid = function () {
		containerForm.isValid();
		const validForm = !containerForm.hasInvalidField();
		const validCompanies = validateCompanySelectors();
		const containerDocuments = productsGrid?.getStore()?.getRange() || [];

		const isHasUpdUkdDoc = !!containerDocuments.find((document) => {
			return (
				document?.getData()?.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
				document?.getData()?.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
			);
		});

		const isValid = validForm && validCompanies && isHasUpdUkdDoc;
		sendButton.setDisabled(!isValid);
	};

	const createOS = function (selectorConfig) {
		Ext.applyIf(selectorConfig, {
			selectedRelationByMap: true,
			valuesByMap: true,
			useHiddenFields: true,
			disableAutoValidation: true
		});

		return createOrgSelector(selectorConfig);
	};

	const createTitle = function () {
		return createLabel({
			typography: 'heading_02',
			text: edi.i18n.getMessage('documents.doctype.ON_SERVICE_CONTAINER')
		});
	};

	const createMainDataBlock = function () {
		return createFieldBlock({
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[3, 2]]
			},
			items: [
				(docNumberField = createTextField({
					fieldLabel: edi.i18n.getMessage('field.name.number'),
					allowBlank: false,
					maxLength: 45,
					value: isEdit ? edi.utils.getObjectProperty(docContent, 'number') : undefined,
					name: 'number',
					listeners: {
						change: () => toggleMenuItems()
					}
				})),
				createDateField({
					fieldLabel: edi.i18n.getMessage('date'),
					allowBlank: false,
					submitFormat: edi.constants.DATE_FORMAT.FNS,
					value: isEdit
						? edi.renderers.fnsDateFromClient(edi.utils.getObjectProperty(docContent, 'doctime'))
						: new Date(),
					name: 'doctime'
				})
			]
		});
	};

	const createSellerBlock = function () {
		const userOrganization = edi.converters.convertOrgToPartie(userData.org);
		const getOrganizationFieldsMap = function (fieldsMapPrefix) {
			fieldsMapPrefix = fieldsMapPrefix ? fieldsMapPrefix + '.' : '';

			const company = fieldsMapPrefix + 'identification.legalEntity.',
				individual = fieldsMapPrefix + 'identification.individualPerson.',
				rusAddress = fieldsMapPrefix + 'legalAddress.addressRu.',
				rusAddressPost = fieldsMapPrefix + 'address.addressRu.',
				contact = fieldsMapPrefix + 'contact.',
				bank = fieldsMapPrefix + 'bankInfo.';

			const docPostMaps = {
				company_okpo: fieldsMapPrefix + 'okpo',
				company_okato: fieldsMapPrefix + 'okato',

				addr_rus_zip: rusAddress + 'index',
				addr_rus_region: rusAddress + 'regionCode',
				addr_rus_county: rusAddress + 'area',
				addr_rus_city: rusAddress + 'city',
				addr_rus_street: rusAddress + 'street',
				addr_rus_community: rusAddress + 'settlement',
				addr_rus_home: rusAddress + 'house',
				addr_rus_block: rusAddress + 'housing',
				addr_rus_flat: rusAddress + 'flat',

				addr_rus_zip_post: rusAddressPost + 'index',
				addr_rus_region_post: rusAddressPost + 'regionCode',
				addr_rus_county_post: rusAddressPost + 'area',
				addr_rus_city_post: rusAddressPost + 'city',
				addr_rus_street_post: rusAddressPost + 'street',
				addr_rus_community_post: rusAddressPost + 'settlement',
				addr_rus_home_post: rusAddressPost + 'house',
				addr_rus_block_post: rusAddressPost + 'housing',
				addr_rus_flat_post: rusAddressPost + 'flat',

				contact_phone: contact + 'telephone',
				contact_email: contact + 'mail',

				bank_corr_acc: bank + 'bank.coreAccountNumber',
				bank_acc: bank + 'accountNumber',
				bank_name: bank + 'bank.bankName',
				bank_id: bank + 'bank.bik'
			};

			return Object.assign(docPostMaps, {
				company_name: company + 'orgName',
				company_inn: company + 'tin',
				company_kpp: company + 'kpp',
				company_register_number: company + 'ogrn',

				individual_lastname: individual + 'name.lastName',
				individual_firstname: individual + 'name.firstName',
				individual_patronymicname: individual + 'name.middleName',
				individual_inn: individual + 'tin',

				address_type: true,
				type: true
			});
		};

		const sellerInfo = edi.utils.getObjectProperty(docContent, 'document.seller');
		const isSellerDefined = !!sellerInfo;
		const isValidSeller = isValidOrganization({
			organization: isSellerDefined ? sellerInfo : userOrganization,
			useOrganizationMap: isSellerDefined,
			organizationType: 'seller'
		});

		seller = createOS({
			selectedRelationByMap: false,
			fieldValues: isSellerDefined ? edi.utils.getObjectProperty(docContent, 'document') : userOrganization,
			valuesByMap: isSellerDefined,
			selectedOrgValues: userOrganization,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id ?? userData.org.id
			}),
			selectedOrg: userData.org,
			readOnly: isEdit,
			is_valid: isValidSeller || isEdit,
			disableAutoValidation: true,
			fieldsMap: getOrganizationFieldsMap('seller'),
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf),
			callback: function (values) {
				seller.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'seller'
				});

				checkValid();
			}
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('document.seller'),
			items: [seller]
		});
	};

	const createBuyerBlock = function () {
		const getOrganizationFieldsMap = function (fieldsMapPrefix) {
			fieldsMapPrefix = fieldsMapPrefix ? fieldsMapPrefix + '.' : '';

			const company = fieldsMapPrefix + 'identification.legalEntity.',
				individual = fieldsMapPrefix + 'identification.individualPerson.',
				rusAddress = fieldsMapPrefix + 'legalAddress.addressRu.',
				rusAddressPost = fieldsMapPrefix + 'address.addressRu.',
				contact = fieldsMapPrefix + 'contact.',
				bank = fieldsMapPrefix + 'bankInfo.';

			const docPostMaps = {
				company_okpo: fieldsMapPrefix + 'okpo',
				company_okato: fieldsMapPrefix + 'okato',

				addr_rus_zip: rusAddress + 'index',
				addr_rus_region: rusAddress + 'regionCode',
				addr_rus_county: rusAddress + 'area',
				addr_rus_city: rusAddress + 'city',
				addr_rus_street: rusAddress + 'street',
				addr_rus_community: rusAddress + 'settlement',
				addr_rus_home: rusAddress + 'house',
				addr_rus_block: rusAddress + 'housing',
				addr_rus_flat: rusAddress + 'flat',

				addr_rus_zip_post: rusAddressPost + 'index',
				addr_rus_region_post: rusAddressPost + 'regionCode',
				addr_rus_county_post: rusAddressPost + 'area',
				addr_rus_city_post: rusAddressPost + 'city',
				addr_rus_street_post: rusAddressPost + 'street',
				addr_rus_community_post: rusAddressPost + 'settlement',
				addr_rus_home_post: rusAddressPost + 'house',
				addr_rus_block_post: rusAddressPost + 'housing',
				addr_rus_flat_post: rusAddressPost + 'flat',

				contact_phone: contact + 'telephone',
				contact_email: contact + 'mail',

				bank_corr_acc: bank + 'bank.coreAccountNumber',
				bank_acc: bank + 'accountNumber',
				bank_name: bank + 'bank.bankName',
				bank_id: bank + 'bank.bik'
			};

			return Object.assign(docPostMaps, {
				company_name: company + 'orgName',
				company_inn: company + 'tin',
				company_kpp: company + 'kpp',
				company_register_number: company + 'ogrn',

				individual_lastname: individual + 'name.lastName',
				individual_firstname: individual + 'name.firstName',
				individual_patronymicname: individual + 'name.middleName',
				individual_inn: individual + 'tin',

				address_type: true,
				type: true
			});
		};

		const buyerInfoData = edi.utils.getObjectProperty(docContent, 'document.buyer'),
			isBuyerDefined = !!buyerInfoData;
		const isValidBuyer = isValidOrganization({
			organization: buyerInfoData,
			useOrganizationMap: isBuyerDefined,
			organizationType: 'buyer'
		});

		buyer = createOS({
			relationsOnly: true,
			allowBlank: false,
			is_valid: isValidBuyer,
			relations: !isEdit && buyerOrg ? buyerOrg : edi.relations.getRelations(),
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id
			}),
			fieldValues: isBuyerDefined ? edi.utils.getObjectProperty(docContent, 'document') : buyerOrgValues,
			readOnly: isEdit && !isCreate,
			selectedOrgValues: !isEdit && buyerOrg ? buyerOrgValues : isBuyerDefined ? docContent : undefined,
			selectedOrg: buyerOrg ? buyerOrg : null,
			selectedRelationByMap: isEdit || buyerOrg,
			callback: function (values, org) {
				const orgId = org ? org.id : isCreate && buyerOrg ? buyerOrg.id : null,
					sameOrgSelected = buyerOrg && buyerOrg.id === orgId;
				initialData ? edi.utils.setObjectProperty(initialData, 'document.toOrg', null) : null;
				if (orgId && !sameOrgSelected) {
					buyerOrg = org;
				} else if (!orgId) {
					buyerOrg = null;
				}

				buyer.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'buyer'
				});
				toggleMenuItems();
				checkValid();
			},
			fieldsMap: getOrganizationFieldsMap('buyer'),
			modalConf: edi.selectors.getFNSModalConf(modalAdditionalConf)
		});

		return createFieldBlock({
			title: edi.i18n.getMessage('document.buyer'),
			items: [buyer]
		});
	};

	const toggleMenuItems = function (store = productsGrid?.getStore()) {
		const isDocNumberFilled = !!docNumberField.getValue();
		const isBuyerFilled = !!buyerOrg?.id;
		const containerDocuments = store?.getRange() || [];

		const isAddedUpdUkd = !!containerDocuments.find((item) => {
			return (
				item?.getData()?.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
				item?.getData()?.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
			);
		});
		const isAddedAttachment = !!containerDocuments.find(
			(item) => item?.getData()?.type === edi.constants.DOCUMENT_TYPES.ON_SERVICE_ATTACHMENT
		);

		addUpdUkdBtn?.setVisible(!isAddedUpdUkd);
		addAttachmentBtn?.setVisible(!isAddedAttachment);
		addButton?.setDisabled(!(isDocNumberFilled && isBuyerFilled) || (isAddedUpdUkd && isAddedAttachment));
	};

	const showUploadDocument = function (recordData, isEditPkg) {
		let uploadForm, saveBtn, panel;
		const fileType = edi.utils.getObjectProperty(recordData, 'file.fileType');

		const onChangeField = function () {
			const isValid = uploadForm.isValid();
			saveBtn.setDisabled(!isValid);
		};

		uploadForm = createForm({
			submitEmptyText: false,
			items: [
				createFieldBlock({
					cls: FIELD_BLOCK_CLS.small,
					title: edi.i18n.getMessage('file.attachment'),
					items: [
						createFileDropper({
							allowBlank: false,
							name: 'attachment',
							getAllowedFileTypesText: () =>
								edi.i18n.getMessage('document.dsf.upload.file.types.and.limit', {
									limit: maxFileSize
								}),
							fileData: isEditPkg
								? {
										fileName: recordData.file.fileName,
										fileDate: edi.utils.formatDate(
											recordData.file.modifyDate,
											edi.constants.DATE_FORMAT.FNS
										),
										fileTime: edi.utils.formatDate(
											recordData.file.modifyDate,
											edi.constants.DATE_FORMAT.TIME
										),
										fileSize: edi.utils.formatFileSize(recordData.file.fileSize)
								  }
								: {},
							listeners: {
								change: onChangeField,
								afterrender: function (cmp) {
									cmp._fileField.fileInputEl.set({
										accept: fileType
									});
								},
								reset: function (cmp) {
									cmp._fileField.fileInputEl.set({
										accept: fileType
									});
								}
							}
						})
					]
				})
			]
		});

		panel = createModalForm({
			items: [
				uploadForm,
				createCheckbox({
					boxLabel: edi.i18n.getMessage('document.create.on.service.container.need.signatures'),
					allowBlank: false,
					inputValue: true,
					checked:
						recordData && recordData.senderSignatureExpected ? recordData.senderSignatureExpected : false,
					name: 'reference.senderSignatureExpected',
					listeners: {
						change: onChangeField
					}
				}),
				createCombo({
					name: 'docType',
					store: edi.stores.createSimpleInlineStore(
						[edi.constants.DOCUMENT_TYPES.ON_SERVICE_ATTACHMENT],
						function (id) {
							return edi.i18n.getMessage('documents.doctype.' + id);
						}
					),
					readOnly: true,
					hidden: true,
					value: edi.constants.DOCUMENT_TYPES.ON_SERVICE_ATTACHMENT
				})
			]
		});

		uploadModal = createModalPanel({
			title: edi.i18n.getMessage('document.create.on.service.container.upload.attachment.title'),
			width: MODAL_SIZE.widthMedium,
			items: [panel],
			buttonsBefore: [
				(saveBtn = createButton({
					cls: BUTTON_CLS.primary,
					text: edi.i18n.getMessage('uikit.action.add'),
					handler: function () {
						const failure = function (data) {
							edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
								uploadModal.close();
							});
						};
						const values = edi.utils.collectFormValues(panel);
						const hasAttachment = !!values['filename'];
						const onSuccessCreateAttachment = function (fileId, responseData) {
							const values = edi.utils.collectFormValues(panel);
							values.file = fileId;
							delete values.filename;
							if (!isEditPkg) {
								edi.rest.sendRequest(
									edi.rest.services.DOCUMENTS.POST,
									'POST',
									Ext.encode({
										data: Ext.encode(values),
										number: responseData.data.number,
										doctime: edi.utils.getUTCTimeZoneFree(
											responseData.data.doctime,
											edi.constants.DATE_FORMAT.SERVER,
											undefined,
											edi.constants.DATE_FORMAT.CLIENT
										),
										toOrgId: buyerOrg.id,
										parentId: containerId,
										docType: edi.constants.DOCUMENT_TYPES.ON_SERVICE_ATTACHMENT
									}),
									function (respData) {
										if (respData && respData.data && respData.data.id) {
											edi.rest.sendRequest(
												edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
													documentId: respData.data.id
												}),
												'GET',
												{},
												function (data) {
													if (data && data.data) {
														const attachData = initAttachmentStore(
															data.data,
															respData.data
														);
														attachmentsStoreData.push(attachData);
														productsGrid.getStore().getProxy().data = attachmentsStoreData;
														productsGrid.getStore().reload();

														edi.events.documents.fireEvent('change', {
															id: containerId
														});
														uploadModal.close();
													} else {
														failure(data);
													}
												},
												failure
											);
										}
									},
									failure
								);
							} else {
								const updatedValues = {
									UPDATE: true,
									UPDATE_PARAMS: {
										data: Ext.encode(values)
									}
								};
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: recordData.id
										},
										true
									),
									'PUT',
									Ext.encode(updatedValues),
									function () {
										edi.rest.sendRequest(
											edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
												documentId: recordData.id
											}),
											'GET',
											{},
											function (data) {
												if (data && data.data) {
													const index = edi.utils.findObjectInArray(
														attachmentsStoreData,
														'id',
														recordData.id,
														true
													);
													attachmentsStoreData[index] = initAttachmentStore(
														data.data,
														attachmentsStoreData[index]
													);
													productsGrid.getStore().getProxy().data = attachmentsStoreData;
													productsGrid.getStore().reload();
													edi.events.documents.fireEvent('change', {
														id: containerId
													});
												}
												uploadModal.close();
											}
										);
									},
									failure
								);
							}
						};
						if (!containerId) {
							return;
						}
						if (hasAttachment) {
							edi.core.submitUploadForm(
								uploadForm,
								edi.rest.services.DSF.FILE.POST,
								'document.file.upload.attachment.in.progress',
								function (responseData) {
									if (responseData && responseData.data && responseData.data.id) {
										const fileId = edi.utils.getObjectProperty(responseData, 'data.id');

										onSuccessCreateAttachment(fileId, responseData);
									}
								},
								failure
							);
						} else {
							const fileId = edi.utils.getObjectProperty(recordData, 'file.id');
							onSuccessCreateAttachment(fileId);
						}
					}
				}))
			]
		});
		uploadModal.show();
	};

	const createOnServiceContainer = function () {
		const failure = function (resp) {
			edi.core.showError(edi.utils.formatComplexServerError(resp, 'error.server'), function () {
				uploadModal.close();
			});
		};
		const containerValues = edi.utils.collectFormValues(containerForm);
		const orgsData = {
			document: {
				buyer: containerValues.buyer,
				seller: containerValues.seller
			}
		};
		edi.rest.sendRequest(
			edi.rest.services.DOCUMENTS.POST,
			'POST',
			Ext.encode({
				data: Ext.encode(orgsData),
				number: containerValues.number,
				doctime: edi.utils.getUTCTimeZoneFree(
					containerValues.doctime,
					edi.constants.DATE_FORMAT.SERVER,
					undefined,
					edi.constants.DATE_FORMAT.CLIENT
				),
				toOrgId: buyerOrg.id,
				docType: edi.constants.DOCUMENT_TYPES.ON_SERVICE_CONTAINER
			}),
			function (data) {
				if (data && data.data && data.data.id) {
					isCreateContainer = true;
					containerId = data.data.id;
					containerData = data.data;
				} else {
					failure(data);
				}
			},
			failure
		);
	};

	//Сохранение документа не подразумевалось изначально
	//при попытке это сделать бэк ругается на отсутствие прав
	//const saveOnServiceContainer = function () {
	//	const id = moduleData.initData.data?.id;
	//	const doctype = edi.constants.DOCUMENT_TYPES.ON_SERVICE_CONTAINER;
	//
	//	const success = edi.document.actions.createSaveSuccessHandler(
	//		moduleData,
	//		isEdit ? id : undefined,
	//		undefined,
	//		isEdit
	//	);
	//
	//	const failure = edi.document.actions.createSaveErrorHandler(isEdit, moduleData, doctype);
	//
	//	edi.document.actions.processDocument(
	//		buyerOrg.id,
	//		null,
	//		doctype,
	//		undefined,
	//		isEdit ? id : undefined,
	//		success,
	//		failure,
	//		moduleData.initData.data
	//	);
	//};

	const createAddAttachmentBtn = function () {
		addAttachmentBtn = createMenuItem({
			text: edi.i18n.getMessage('document.create.on.service.container.upload.attachment'),
			itemId: 'addAttachment',
			handler: function () {
				showUploadDocument();
			}
		});
		return addAttachmentBtn;
	};

	const showPickUpdUkdModal = function () {
		const { formItemsMap, items } = filterMethods.createFormItems();
		const modal = createModalRemoteSelect(
			edi.rest.services.DOCUMENTS.GET,
			function (record) {
				const url = edi.utils.formatString(
					edi.rest.services.DOCUMENTS.LINK.PUT,
					{
						parentId: containerId,
						id: record.id
					},
					true
				);
				const failure = function (data) {
					edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'), function () {
						modal.close();
					});
				};
				const store = productsGrid.getStore();
				const storeData = edi.utils.getObjectProperty(store, 'data.items', true);
				let indexPath = -1;
				const isHasUpdUkdDoc = storeData.find(function (item, index) {
					const condition =
						item.get('type') === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
						item.get('type') === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD;
					if (condition) {
						indexPath = index;
					}
					return condition;
				});
				if (!!isHasUpdUkdDoc) {
					edi.core.confirm(
						null,
						'document.create.on.service.container.upd.ukd.warn',
						function () {
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.DOCUMENTS.UNLINK.PUT, {
									id: containerId
								}),
								'PUT',
								Ext.encode({
									UPDATE: true,
									UNLINK_DOCUMENT: store.getAt(indexPath).get('id')
								}),
								function (data) {
									if (data && data.data) {
										edi.rest.sendRequest(
											url,
											'PUT',
											Ext.encode({}),
											function (data) {
												if (data && data.data) {
													edi.utils.setObjectProperty(
														record,
														'senderSignatureExpected',
														true
													);
													attachmentsStoreData[indexPath] = initAttachmentStore(
														data.data,
														record
													);
													productsGrid.getStore().getProxy().data = attachmentsStoreData;
													productsGrid.getStore().reload();
													edi.events.documents.fireEvent('change', {
														id: containerId
													});
												}
											},
											failure
										);
									}
								},
								failure
							);
						},
						function () {
							modal.close();
						}
					);
				} else {
					edi.rest.sendRequest(
						url,
						'PUT',
						Ext.encode({}),
						function (data) {
							if (data && data.data) {
								edi.utils.setObjectProperty(record, 'senderSignatureExpected', true);
								const attachData = initAttachmentStore(data.data, record);
								attachmentsStoreData.push(attachData);
								productsGrid.getStore().getProxy().data = attachmentsStoreData;
								productsGrid.getStore().reload();
								edi.events.documents.fireEvent('change', {
									id: containerId
								});
							}
						},
						failure
					);
				}
			},
			{
				title: 'documents.package.add.documents',
				width: MODAL_SIZE.widthLarge,
				hideHeaderCheckbox: true,
				checkboxes: false,
				disableSelection: false,
				groupOperation: true,
				model: 'DOCUMENT',
				columns: 'documents_all',
				storeConfig: {
					autoLoad: false
				},
				proxyConfig: {
					extraParams: {
						CONTAINER_TYPENotEqual: 'onServiceContainer',
						typesAndAttrs: Ext.encode([
							{ type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD },
							{ type: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD }
						]),
						toOrg: isEdit ? buyerId : buyerOrg.id,
						state: edi.constants.STATE.DRAFT
					}
				},
				createFilterFormItems: items,
				createFormItemsMap: formItemsMap,
				createArgs: filterMethods.createArgs,
				callback: function () {
					modal.close();
				}
			}
		);
		modal.show();
	};

	const createAddUpdUkdBtn = function () {
		addUpdUkdBtn = createMenuItem({
			text: edi.i18n.getMessage('document.create.on.service.container.add.upd.ukd'),
			itemId: 'addUpdUkd',
			handler: function () {
				showPickUpdUkdModal();
			}
		});
		return addUpdUkdBtn;
	};

	const createAddGridItemBtn = function () {
		addButton = createActionsButton({
			text: edi.i18n.getMessage('form.btn.add'),
			glyph: edi.constants.ICONS.ADD,
			allowMultiClick: false,
			menu: [createAddUpdUkdBtn(), createAddAttachmentBtn()],
			handler: function () {
				//что бы добавить вложение, надо сначала создать контейнер (его айди нужен для вложений)
				if (!isEdit && !isCreateContainer) {
					createOnServiceContainer();
				} else {
					isCreateContainer = true;
				}
			}
		});
		return addButton;
	};

	const createDeleteGridItemBtn = function () {
		deleteButton = createActionsButton({
			text: edi.i18n.getMessage('form.btn.delete'),
			glyph: edi.constants.ICONS.REMOVE,
			disabled: true,
			testCls: 'test-action-column-remove',
			handler: function () {
				edi.core.confirm(null, 'document.create.on.service.container.delete.confirm', function () {
					const selected = productsGrid.getSelectionModel().getSelection();
					let count = 0;
					const deletedElements = [];
					const deleteEnd = function () {
						deletedElements.forEach(function (id) {
							const index = edi.utils.findObjectInArray(attachmentsStoreData, 'id', id, true);
							attachmentsStoreData.splice(index, 1);
						});
						productsGrid.getStore().getProxy().data = attachmentsStoreData;
						productsGrid.getStore().reload();
						edi.events.documents.fireEvent('change', {
							id: containerId
						});
					};
					const successDeleteAttach = function (id) {
						deletedElements.push(id);
						count += 1;
						if (count !== selected.length) {
							return;
						}
						deleteEnd();
					};
					const failureDeleteAttach = function (data) {
						count += 1;
						edi.core.showError(edi.utils.formatComplexServerError(data, 'error.getting.data'));
						if (count !== selected.length) {
							return;
						}
						deleteEnd();
					};
					selected.forEach(function (content) {
						const id = content.get('id');
						const isUPDorUKD =
							content.get('type') === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
							content.get('type') === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD;

						if (isUPDorUKD) {
							deleteAttachment(
								containerId,
								Ext.encode({
									UPDATE: true,
									UNLINK_DOCUMENT: id
								}),
								function () {
									successDeleteAttach(id);
								},
								failureDeleteAttach
							);
						} else {
							deleteAttachment(
								id,
								Ext.encode({ DELETE: true }),
								function () {
									successDeleteAttach(id);
								},
								failureDeleteAttach
							);
						}
					});
				});
			}
		});
		return deleteButton;
	};

	const createActionColumn = function () {
		const items = [];
		items.push(
			createActionsColumnConfig({
				align: 'center',
				items: [
					{
						glyph: edi.constants.ICONS.EDIT,
						handler: function (grid, rowIndex) {
							const store = grid.getStore();
							const recordData = store.getAt(rowIndex).getData();
							if (
								recordData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
								recordData.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD
							) {
								let moduleName = edi.constants.CREATE_MODULE_NAME_BY_TYPE[recordData.type];
								if (recordData.versionId) {
									moduleName =
										edi.constants.CREATE_MODULE_NAME_BY_TYPE[
											[recordData.type, recordData.versionId].join('_')
										];
								}
								edi.core.openModule(moduleName, recordData, recordData.number, true);
							} else {
								showUploadDocument(recordData, true);
							}
						},
						isActionDisabled: function (_view, _rowIndex, _colIndex, _item, record) {
							return (
								!record.getId() ||
								((record.get('type') !== edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
									record.get('type') !== edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD) &&
									record.get('state') !== edi.constants.STATE.DRAFT)
							);
						}
					},
					{
						glyph: edi.constants.ICONS.CLOUD_DOWNLOAD,
						testCls: 'test-action-column-download',
						text: edi.i18n.getMessage('action.export.attachment'),
						handler: function (grid, rowIndex) {
							const store = grid.getStore();
							const recordData = store.getAt(rowIndex).getData();
							const url = edi.document.actions.formatExportUrl(recordData);
							edi.rest.downloadFile(url, recordData.id);
						}
					},
					{
						glyph: edi.constants.ICONS.REMOVE,
						testCls: 'test-action-column-remove',
						handler: function (grid, rowIndex) {
							edi.core.confirm(null, 'document.create.on.service.container.delete.confirm', function () {
								const store = grid.getStore();
								const record = store.getAt(rowIndex);
								const isUPDorUKD =
									record.get('type') === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
									record.get('type') === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD;

								const success = function (id) {
									const index = edi.utils.findObjectInArray(attachmentsStoreData, 'id', id, true);
									attachmentsStoreData.splice(index, 1);
									productsGrid.getStore().getProxy().data = attachmentsStoreData;
									productsGrid.getStore().reload();
									edi.events.documents.fireEvent('change', {
										id: containerId
									});
								};

								const id = record.getId();
								if (isUPDorUKD) {
									deleteAttachment(
										containerId,
										Ext.encode({
											UPDATE: true,
											UNLINK_DOCUMENT: id
										}),
										function () {
											success(id);
										},
										null
									);
								} else {
									deleteAttachment(
										id,
										Ext.encode({ DELETE: true }),
										function () {
											success(id);
										},
										null
									);
								}
							});
						}
					},
					{
						glyph: edi.constants.ICONS.PRINT,
						handler: function (grid, rowIndex) {
							const record = grid.getStore().getAt(rowIndex),
								recordData = record.getData();
							edi.document.actions.createPrintModal(
								recordData,
								{
									usingReport: true
								},
								recordData.id
							);
						}
					}
				]
			})
		);
		return items;
	};

	const createServiceGrid = function () {
		let columns = edi.columns.get('on_service_container');
		columns = columns.concat(createActionColumn());

		productsGrid = createGrid({
			proxy: createProxyConfig({
				type: 'pagingmemory',
				data: attachmentsStoreData
			}),
			storeConfig: {
				pageSize: edi.constants.MAX_PAGE_SIZE,
				model: edi.models.getModel('DOCUMENT_ON_SERVICE'),
				remoteFilter: true,
				sortOnLoad: false,
				sorters: {
					property: 'name',
					direction: 'ASC'
				},
				listeners: {
					datachanged: toggleMenuItems
				}
			},
			gridConfig: {
				selModel: createCheckboxSelectionModel({
					listeners: {
						selectionchange: function (_model, selected) {
							containerForm.isValid();
							const validForm = !containerForm.hasInvalidField();
							const validCompanies = validateCompanySelectors();
							deleteButton.setDisabled(!(validForm && validCompanies && selected.length > 0));
						}
					}
				}),
				title: edi.i18n.getMessage('document.on.service.container.lines'),
				columns: columns,
				dockedItems: [
					createToolBar({
						items: [createAddGridItemBtn(), createDeleteGridItemBtn()]
					})
				],
				autoScroll: true,
				disablePaging: false,
				disableSelection: false
			}
		});

		return productsGrid;
	};

	const createSendBtn = function () {
		return (sendButton = createSendButton(
			function () {
				signAddedDocuments(() => {
					const failure = function (resp) {
						edi.document.actions.defaultFailureHandler(
							moduleData.tab,
							'document.mark.sign.error.process'
						)(resp);
					};
					edi.rest.sendRequest(
						edi.utils.formatString(
							edi.rest.services.DOCUMENTS.SEND.PUT,
							{
								documentId: containerId
							},
							true
						),
						'PUT',
						Ext.encode({}),
						function () {
							moduleData.isChanged = false;
							moduleData.tab.close();
							edi.events.documents.fireEvent('change', {
								id: containerId
							});
							let modData = isEdit ? docContent : containerData;
							edi.core.openModule(
								'document.details.on.service.container',
								modData,
								modData?.number || ''
							);
						},
						failure
					);
				});
			},
			{
				disabled: true,
				text: edi.i18n.getMessage('document.sign.and.send.document')
			}
		));
	};

	const createModuleForm = function () {
		containerForm = createFormForModule({
			cls: 'document-add-form',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, [6, 6], 12]
			},
			items: [createTitle(), createMainDataBlock(), createSellerBlock(), createBuyerBlock(), createServiceGrid()],
			buttons: createButtonContainer({
				items: [
					createSendBtn()
					//Сохранение документа не подразумевалось изначально
					//при попытке это сделать бэк ругается на отсутствие прав
					//createAddSaveButton(
					//	{
					//		handler: saveOnServiceContainer
					//	},
					//	isEdit
					//)
				]
			})
		});

		containerForm.on('validitychange', checkValid);
		productsGrid.getStore().on('load', checkValid);
		productsGrid.getStore().on('datachanged', checkValid);
		containerForm.isValid();
		edi.utils.processModuleFormChange(containerForm, moduleData);

		return containerForm;
	};

	const initAttachmentStore = function (data, storeElement) {
		let senderSignatureExpected = edi.utils.getObjectProperty(data, 'reference.senderSignatureExpected');
		const condition =
			storeElement.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD ||
			storeElement.type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD;
		if (condition) {
			senderSignatureExpected = 'true';
		}
		edi.utils.setObjectProperty(storeElement, 'senderSignatureExpected', senderSignatureExpected === 'true');
		const file = edi.utils.getObjectProperty(data, 'file');
		if (file) {
			edi.utils.setObjectProperty(storeElement, 'file', file);
		}
		return storeElement;
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.removeAll();
		const modulePanel = createAddModulePanel();
		const data = moduleData.initData.data;

		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		const finishRender = function () {
			if (data?.id) {
				buyerOrg = data.toOrg;
				buyerId = buyerOrg.id;

				const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.CHILDREN.GET, {
					documentId: data.id
				});
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.HEADER.GET,
						{
							documentId: data.id
						},
						true
					),
					'GET',
					undefined,
					function (respData) {
						moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
						if (respData && respData.data) {
							containerId = respData.data.id;
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
									documentId: containerId
								}),
								'GET',
								{},
								function (contentData) {
									if (respData && respData.data) {
										docContent = contentData.data;
										Ext.merge(docContent, respData.data);
										edi.rest.sendRequest(
											url,
											'GET',
											{},
											function (responseData) {
												attachmentsStoreData = (responseData && responseData.items) || [];
												if (isEdit) {
													if (attachmentsStoreData.length > 0) {
														let count = 0;
														attachmentsStoreData.forEach(function (content, index) {
															edi.rest.sendRequest(
																edi.utils.formatString(
																	edi.rest.services.DOCUMENTS.CONTENT.GET,
																	{
																		documentId: content.id
																	}
																),
																'GET',
																{},
																function (data) {
																	if (data && data.data) {
																		attachmentsStoreData[index] =
																			initAttachmentStore(
																				data.data,
																				attachmentsStoreData[index]
																			);

																		count += 1;
																		if (count === attachmentsStoreData.length) {
																			modulePanel.add(createModuleForm());
																			moduleData.tab.add(modulePanel);
																			if ('function' == typeof initCallBack) {
																				initCallBack();
																			} else {
																				moduleData.tab.setLoading(false);
																			}
																		}
																	} else {
																		failure(data);
																	}
																},
																failure
															);
														});
													} else {
														modulePanel.add(createModuleForm());
														moduleData.tab.add(modulePanel);
														if ('function' == typeof initCallBack) {
															initCallBack();
														} else {
															moduleData.tab.setLoading(false);
														}
													}
												} else {
													modulePanel.add(createModuleForm());
													moduleData.tab.add(modulePanel);
													if ('function' == typeof initCallBack) {
														initCallBack();
													} else {
														moduleData.tab.setLoading(false);
													}
												}
											},
											failure
										);
									}
								},
								failure
							);
						}
					},
					failure
				);
			} else {
				modulePanel.add(createModuleForm());
				moduleData.tab.add(modulePanel);
				buyer.presetFromRelation(function () {
					'function' == typeof initCallBack ? initCallBack() : null;
				});
			}
		};

		if (!maxFileSize) {
			edi.methods.loadSettingsAndContinue(function (maxSize) {
				maxFileSize = maxSize;
				finishRender();
			}, failure);
		} else {
			finishRender();
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
