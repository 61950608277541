import { createCombo, createNumberField, createTextField } from '@Components/fields';
import { createFormContainer } from '@UIkit/components/panels';
import { createDateRangeField } from '@UIkit/components/fields/Date/DateRangeNew';

const statusList = [
	edi.constants.STATE.DRAFT,
	edi.constants.STATE.GET_NUMBER,
	edi.constants.STATE.FOR_SIGNING,
	edi.constants.STATE.FNS_REGISTRATION,
	edi.constants.STATE.WAIT_FNS_RESPONSE,
	edi.constants.STATE.ACTIVE,
	edi.constants.STATE.ERROR,
	edi.constants.STATE.EXPIRED,
	edi.constants.STATE.REJECTED,
	edi.constants.STATE.REVOKED,
	edi.constants.STATE.REGISTERED,
	edi.constants.STATE.NOT_DEFINED
];

const getStatuses = function () {
	return Object.values(statusList).map((val) => ({
		id: val,
		name: edi.i18n.getMessage(`documents.status.${edi.renderers.powerOfAttorneyFixStatusCode(val)}`)
	}));
};

const createPoAFormItems = function () {
	const formItemsMap = {
		principalType: createCombo({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.type'),
			emptyText: '',
			name: 'tipDover',
			store: edi.stores.createInlineStore(
				[
					{
						id: 'ЮЛ',
						name: edi.i18n.getMessage('company.type.company')
					},
					{
						id: 'ИП',
						name: edi.i18n.getMessage('company.type.individual')
					}
				],
				'SIMPLE',
				undefined,
				{
					addEmptyRecord: true
				}
			),
			setLastValueAfterTextClear: true,
			anyMatch: true
		}),
		innernumber: createTextField({
			name: 'vnNomDover',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.internal.number')
		}),
		externalnumber: createTextField({
			name: 'doverRegNumber',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.external.number')
		}),
		creationdate: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('document.date.create'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'creationDateFrom',
					chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
					fieldLabel: edi.i18n.getMessage('document.created.from')
				},
				to: {
					name: 'creationDateTo',
					chipTitle: ' — '
				}
			}
		}),
		validfrom: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('power.of.attorney.startDate.from'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'dataVidDoverFrom',
					chipTitle: edi.i18n.getMessage('power.of.attorney.startDate.from') + ': ',
					fieldLabel: edi.i18n.getMessage('power.of.attorney.startDate.from')
				},
				to: {
					name: 'dataVidDoverTo',
					chipTitle: ' — '
				}
			}
		}),
		validto: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('power.of.attorney.startDate.to'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'dataKonDoverFrom',
					chipTitle: edi.i18n.getMessage('power.of.attorney.startDate.to') + ': ',
					fieldLabel: edi.i18n.getMessage('power.of.attorney.startDate.to')
				},
				to: {
					name: 'dataKonDoverTo',
					chipTitle: ' — '
				}
			}
		}),
		lastName: createTextField({
			name: 'LastName',
			labelPostfix: 'surname',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.surname')
		}),
		firstName: createTextField({
			name: 'FirstName',
			labelPostfix: 'first.name',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.first.name')
		}),
		middleName: createTextField({
			name: 'MiddleName',
			labelPostfix: 'middle.name',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.middle.name')
		}),
		state: createCombo({
			fieldLabel: edi.i18n.getMessage('documents.column.status'),
			emptyText: '',
			name: 'state',
			store: edi.stores.createInlineStore(getStatuses(), 'SIMPLE', undefined, {
				addEmptyRecord: true
			}),
			multiSelect: true,
			setLastValueAfterTextClear: true,
			anyMatch: true
		}),
		inn: createNumberField({
			name: 'Inn',
			labelPostfix: 'inn',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.inn')
		}),
		snils: createTextField({
			name: 'Snils',
			labelPostfix: 'snils',
			regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
			regexText: edi.i18n.getMessage('invalid.snils.format'),
			inputMask: edi.constants.FIELD_MASK.SNILS,
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.snils')
		})
	};

	const role = createCombo({
		fieldLabel: edi.i18n.getMessage('column.role'),
		emptyText: '',
		name: 'role',
		value: 'confidant',
		store: edi.stores.createInlineStore(
			[
				{
					id: 'confidant',
					name: edi.i18n.getMessage('power.of.attorney.principals.column')
				},
				{
					id: 'principal',
					name: edi.i18n.getMessage('power.of.attorney.confidants.column')
				}
			],
			'SIMPLE'
		),
		editable: false,
		listeners: {
			change: (combo, newValue) => {
				//отображаем лэйблы в зависимости от роли "{field} доверителя"/"{field} доверенного лица"
				[
					formItemsMap.lastName,
					formItemsMap.firstName,
					formItemsMap.middleName,
					formItemsMap.inn,
					formItemsMap.snils
				].forEach((formItem) => {
					const newLabel = edi.i18n.getMessage(`power.of.attorney.${newValue}.${formItem.labelPostfix}`);
					formItem.setFieldLabel(newLabel);
					formItem.chipTitle = newLabel;
				});
			}
		}
	});

	const items = createFormContainer({
		gap: [32, 16],
		area: [[4, 4, 4], [4, 4, 4], [4, 4], [4, 4], [4, 4, 4], 4],
		items: [
			role,
			formItemsMap.principalType,
			formItemsMap.state,
			formItemsMap.lastName,
			formItemsMap.firstName,
			formItemsMap.middleName,
			formItemsMap.inn,
			formItemsMap.snils,
			formItemsMap.innernumber,
			formItemsMap.externalnumber,
			formItemsMap.creationdate,
			formItemsMap.validfrom,
			formItemsMap.validto
		]
	});

	return {
		formItemsMap,
		items
	};
};

const createPrincipalFormItems = () => {
	const formItemsMap = {
		principalType: createCombo({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.type'),
			emptyText: '',
			name: 'tipDover',
			store: edi.stores.createInlineStore(
				[
					{
						id: 'ЮЛ',
						name: edi.i18n.getMessage('company.type.company')
					},
					{
						id: 'ИП',
						name: edi.i18n.getMessage('company.type.individual')
					}
				],
				'SIMPLE',
				undefined,
				{
					addEmptyRecord: true
				}
			),
			setLastValueAfterTextClear: true,
			anyMatch: true
		}),
		innernumber: createTextField({
			name: 'vnNomDover',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.internal.number')
		}),
		externalnumber: createTextField({
			name: 'doverRegNumber',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.external.number')
		}),
		creationdate: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('document.date.create'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'creationDateFrom',
					chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
					fieldLabel: edi.i18n.getMessage('document.created.from')
				},
				to: {
					name: 'creationDateTo',
					chipTitle: ' — '
				}
			}
		}),
		validfrom: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('power.of.attorney.startDate.from'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'dataVidDoverFrom',
					chipTitle: edi.i18n.getMessage('power.of.attorney.startDate.from') + ': ',
					fieldLabel: edi.i18n.getMessage('power.of.attorney.startDate.from')
				},
				to: {
					name: 'dataVidDoverTo',
					chipTitle: ' — '
				}
			}
		}),
		validto: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('power.of.attorney.startDate.to'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'dataKonDoverFrom',
					chipTitle: edi.i18n.getMessage('power.of.attorney.startDate.to') + ': ',
					fieldLabel: edi.i18n.getMessage('power.of.attorney.startDate.to')
				},
				to: {
					name: 'dataKonDoverTo',
					chipTitle: ' — '
				}
			}
		}),
		lastName: createTextField({
			name: 'principalLastName',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.surname')
		}),
		firstName: createTextField({
			name: 'principalFirstName',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.first.name')
		}),
		middleName: createTextField({
			name: 'principalMiddleName',
			labelPostfix: 'middle.name',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.middle.name')
		}),
		state: createCombo({
			fieldLabel: edi.i18n.getMessage('documents.column.status'),
			emptyText: '',
			name: 'state',
			store: edi.stores.createInlineStore(getStatuses(), 'SIMPLE', undefined, {
				addEmptyRecord: true
			}),
			multiSelect: true,
			setLastValueAfterTextClear: true,
			anyMatch: true
		}),
		inn: createNumberField({
			name: 'principalInn',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.inn')
		}),
		snils: createTextField({
			name: 'principalSnils',
			regex: edi.constants.VALIDATORS_MULTI.RU.SNILS,
			regexText: edi.i18n.getMessage('invalid.snils.format'),
			inputMask: edi.constants.FIELD_MASK.SNILS,
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.snils')
		})
	};

	const items = createFormContainer({
		gap: 16,
		area: [
			[4, 4, 4],
			[4, 4, 4],
			[4, 4, 4],
			[4, 4, 4]
		],
		items: [
			formItemsMap.principalType,
			formItemsMap.state,
			formItemsMap.innernumber,
			formItemsMap.lastName,
			formItemsMap.firstName,
			formItemsMap.middleName,
			formItemsMap.inn,
			formItemsMap.snils,
			formItemsMap.externalnumber,
			formItemsMap.creationdate,
			formItemsMap.validfrom,
			formItemsMap.validto
		]
	});

	return {
		formItemsMap,
		items
	};
};

const createConfidantFormItems = () => {
	const formItemsMap = {
		principalType: createCombo({
			fieldLabel: edi.i18n.getMessage('power.of.attorney.principal.type'),
			emptyText: '',
			name: 'tipDover',
			store: edi.stores.createInlineStore(
				[
					{
						id: 'ЮЛ',
						name: edi.i18n.getMessage('company.type.company')
					},
					{
						id: 'ИП',
						name: edi.i18n.getMessage('company.type.individual')
					}
				],
				'SIMPLE',
				undefined,
				{
					addEmptyRecord: true
				}
			),
			setLastValueAfterTextClear: true,
			anyMatch: true
		}),
		innernumber: createTextField({
			name: 'vnNomDover',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.internal.number')
		}),
		externalnumber: createTextField({
			name: 'doverRegNumber',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.external.number')
		}),
		creationdate: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('document.date.create'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'creationDateFrom',
					chipTitle: edi.i18n.getMessage('document.date.create') + ': ',
					fieldLabel: edi.i18n.getMessage('document.created.from')
				},
				to: {
					name: 'creationDateTo',
					chipTitle: ' — '
				}
			}
		}),
		validfrom: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('power.of.attorney.startDate.from'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'dataVidDoverFrom',
					chipTitle: edi.i18n.getMessage('power.of.attorney.startDate.from') + ': ',
					fieldLabel: edi.i18n.getMessage('power.of.attorney.startDate.from')
				},
				to: {
					name: 'dataVidDoverTo',
					chipTitle: ' — '
				}
			}
		}),
		validto: createDateRangeField({
			chipsModalTitle: edi.i18n.getMessage('power.of.attorney.startDate.to'),
			fieldsConfig: {
				common: {
					submitFormat: edi.constants.DATE_FORMAT.MILLISECONDS,
					enabledUTC: true,
					invalidText: edi.i18n.getMessage('invalid.date.format.fns.datetime'),
					emptyText: edi.i18n.getMessage('document.date.placeholder.dd.mm.yyyy')
				},
				from: {
					name: 'dataKonDoverFrom',
					chipTitle: edi.i18n.getMessage('power.of.attorney.startDate.to') + ': ',
					fieldLabel: edi.i18n.getMessage('power.of.attorney.startDate.to')
				},
				to: {
					name: 'dataKonDoverTo',
					chipTitle: ' — '
				}
			}
		}),
		lastName: createTextField({
			name: 'confidantLastName',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.surname')
		}),
		firstName: createTextField({
			name: 'confidantFirstName',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.first.name')
		}),
		middleName: createTextField({
			name: 'confidantMiddleName',
			labelPostfix: 'middle.name',
			fieldLabel: edi.i18n.getMessage('power.of.attorney.confidant.middle.name')
		}),
		state: createCombo({
			fieldLabel: edi.i18n.getMessage('documents.column.status'),
			emptyText: '',
			name: 'state',
			store: edi.stores.createInlineStore(getStatuses(), 'SIMPLE', undefined, {
				addEmptyRecord: true
			}),
			multiSelect: true,
			setLastValueAfterTextClear: true,
			anyMatch: true
		})
	};

	const items = createFormContainer({
		gap: 16,
		area: [[4, 4], [4, 4, 4], [4, 4], [4, 4, 4], 4],
		items: [
			formItemsMap.principalType,
			formItemsMap.state,
			formItemsMap.lastName,
			formItemsMap.firstName,
			formItemsMap.middleName,
			formItemsMap.innernumber,
			formItemsMap.externalnumber,
			formItemsMap.creationdate,
			formItemsMap.validfrom,
			formItemsMap.validto
		]
	});

	return {
		formItemsMap,
		items
	};
};

const createFilterArgs = function (values) {
	const result = Ext.clone(values);

	if (Array.isArray(result.state)) {
		result.state = Ext.encode(result.state);
	}

	return result;
};

const createPoAFilterArgs = function (values) {
	let result = Ext.clone(values);
	result.Snils = result.Snils.replace(/[- ]/g, '');

	//В зависимости от роли "Доверитель"/"Доверенное лицо" добавляем префикс principal/confidant к данным физ лица
	let confidantOrPrincipalPrefix = result.role || '';
	delete result.role;

	['LastName', 'FirstName', 'MiddleName', 'Inn', 'Snils'].forEach((field) => {
		if (result[field]) {
			result[confidantOrPrincipalPrefix + field] = result[field];
			delete result[field];
		}
	});

	if (Array.isArray(result.state)) {
		result.state = Ext.encode(result.state);
	}

	return result;
};

export {
	createPoAFormItems,
	createPoAFilterArgs,
	createPrincipalFormItems,
	createFilterArgs,
	createConfidantFormItems
};
