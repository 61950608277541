import { createCompanySelector } from '@Components/company.selector.js';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import {
	createDateField,
	createField,
	createFields,
	createFieldSet,
	createLabel,
	createTextField
} from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createCreateSaveButton } from '@Components/buttons';
import { createButtonContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { createButtonContainer, createFieldBlock, createMaxWidthContainer } from '@UIkit/components/panels';

/**
 * @author Darya Matyukha
 * Class for creation IFTMBC_APPROVAL document
 * Creates only from IFTMBF via transformation
 */
Ext.namespace('edi.modules');
edi.modules['document.create.iftmbc_approval'] = function () {
	var moduleData,
		parentId,
		id,
		sellerOrg = edi.core.getUserData().org,
		isEdit = false,
		isCreate = false,
		buyerOrg,
		seller,
		buyer,
		form,
		afterSave,
		documentData = {};
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			documentData = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			buyerOrg = moduleData.initData.data.buyerOrg;
			isEdit = true;
			isCreate = true;
		}

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var isValidCompanySelectors = function () {
		return buyer.isValid() && seller.isValid();
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var isValid = form.isValid() && isValidCompanySelectors();

		return isValid;
	};

	var createFieldsPanel = function (fields, panelConf) {
		return createPanel(
			Object.assign(
				{
					layout: 'column',
					cls: 'edi-create-field-line',
					items: fields
				},
				panelConf
			)
		);
	};

	//Creates block with driver info
	var createDriverBlock = function () {
		var mainInfo = createFields(
			[
				{
					title: 'iftmbc.approval.driver.fio',
					maxLength: 255,
					allowBlank: false,
					columnWidth: 0.8,
					name: 'drivers.driver.fio'
				},
				{
					type: 'number',
					title: 'iftmbc.approval.driver.licence',
					columnWidth: 0.8,
					enforceMaxLength: true,
					allowBlank: false,
					name: 'drivers.driver.driverLicense',
					maxLength: 10,
					maskRe: /\d/i
				},
				{
					title: 'iftmbc.approval.driver.phone',
					maxLength: 255,
					allowBlank: false,
					columnWidth: 0.8,
					name: 'drivers.driver.phone'
				}
			],
			documentData || {},
			{
				containerConfig: {
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
				}
			}
		);

		var passportFields = [
			createFieldsPanel(
				[
					createTextField({
						columnWidth: 0.4,
						allowBlank: false,
						enforceMaxLength: true,
						regex: /^\d{4}$/,
						maskRe: /\d/i,
						fieldLabel: edi.i18n.getMessage('iftmbc.approval.driver.passport.serial'),
						name: 'drivers.driver.pasport.series',
						value: edi.utils.getObjectProperty(documentData, 'drivers.driver.pasport.series')
					}),
					createTextField({
						regex: /^\d{6}$/,
						maskRe: /\d/i,
						columnWidth: 0.6,
						enforceMaxLength: true,
						allowBlank: false,
						margin: '0 0 0 10',
						fieldLabel: edi.i18n.getMessage('iftmbc.approval.driver.passport.number'),
						name: 'drivers.driver.pasport.number',
						value: edi.utils.getObjectProperty(documentData, 'drivers.driver.pasport.number')
					})
				],
				{ margin: '0 0 5 0' }
			),
			createFieldsPanel(
				[
					createTextField({
						maxLength: 255,
						allowBlank: false,
						columnWidth: 0.7,
						fieldLabel: edi.i18n.getMessage('iftmbc.approval.driver.passport.issuedBy'),
						name: 'drivers.driver.pasport.issuedBy',
						value: edi.utils.getObjectProperty(documentData, 'drivers.driver.pasport.issuedBy')
					}),
					createDateField({
						columnWidth: 0.3,
						margin: '0 0 0 10',
						allowBlank: false,
						fieldLabel: edi.i18n.getMessage('iftmbc.approval.driver.passport.issueDate'),
						name: 'drivers.driver.pasport.date',
						value: edi.utils.getObjectProperty(documentData, 'drivers.driver.pasport.date')
					})
				],
				{ margin: '0 0 5 0' }
			)
		];

		return createFieldSet({
			collapsible: false,
			title: edi.i18n.getMessage('iftmbc.approval.driver.info'),
			items: mainInfo.concat([
				createTwoColumnsLayout(
					[
						createLabel({
							html: edi.i18n.getMessage('iftmbc.approval.driver.passport')
						})
					],
					passportFields,
					0.2,
					{ userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS }
				)
			])
		});
	};

	/**
	 * Create invoice form
	 */
	var createMainFormItems = function () {
		var mainDataInputs = createFieldsPanel([
			createField({
				title: edi.i18n.getMessage('document.iftmbc.documentNumber'),
				input: createTextField({
					columnWidth: 0.7,
					allowBlank: false,
					maxLength: 35,
					value: edi.utils.getObjectProperty(documentData, 'offerNumber'),
					name: 'offerNumber'
				}),
				containerConfig: {
					columnWidth: 1,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
				}
			})
		]);

		var offerIdentifier = edi.utils.getObjectProperty(documentData, 'idOffer');
		var offerId = createFieldsPanel([
			createField({
				title: edi.i18n.getMessage('document.iftmbc.offerId'),
				input: createTextField({
					columnWidth: 0.7,
					allowBlank: false,
					readOnly: !!offerIdentifier,
					regex: /^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/,
					regexText: edi.i18n.getMessage('iftmbc.approval.offer.id.format'),
					maskRe: /[0-9a-zA-Z\-]/,
					value: offerIdentifier,
					name: 'idOffer'
				}),
				containerConfig: {
					columnWidth: 1,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
				}
			})
		]);

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				columnWidth: 0.7,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				selectedRelationByMap: isEdit,
				valuesByMap: false,
				useHiddenFields: true,
				disableAutoValidation: true,
				processValues: orgConverter
			});

			return createCompanySelector(selectorConfig);
		};

		var isValidOrganization = function (values) {
			values = values || {};
			return !!values.company_iln;
		};

		var sellerOrgValues = edi.converters.convertOrgToLegacyPartie(sellerOrg),
			isValidSeller = isValidOrganization(sellerOrgValues);

		seller = createCS({
			itemId: 'seller',
			labelConfig: {
				title: 'document.seller',
				columnWidth: 0.3
			},
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: sellerOrg.id,
				converter: orgConverter
			}),
			fieldValues: sellerOrgValues,
			selectedOrgValues: sellerOrgValues,
			selectedOrg: sellerOrg,
			readOnly: isEdit && !isCreate,
			is_valid: isValidSeller,
			disableAutoValidation: true,
			modalConf: {
				tabs: {
					main: {
						company_name: edi.selectors.defaults.company_name,
						company_gln: edi.selectors.defaults.company_gln
					}
				}
			},
			callback: function (values) {
				seller.is_valid = isValidOrganization(values);
				checkValid();
			}
		});

		var buyerInfo = edi.converters.convertOrgToLegacyPartie(buyerOrg || {}),
			isValidBuyer = isValidOrganization(buyerInfo);

		buyer = createCS({
			itemId: 'buyer',
			labelConfig: {
				title: 'document.buyer',
				columnWidth: 0.3
			},
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: buyerOrg?.id,
				converter: orgConverter
			}),
			is_valid: isValidBuyer,
			fieldValues: buyerInfo,
			relations: !isCreate ? (!isEdit && buyerOrg ? buyerOrg : edi.relations.getRelations()) : undefined,
			relationsOnly: true,
			disableAutoValidation: true,
			readOnly: isEdit,
			allowReset: !isEdit,
			selectedOrgValues: buyerInfo,
			selectedOrg: buyerOrg,
			callback: function (values, org) {
				var orgId = org ? org.id : isCreate && buyerOrg ? buyerOrg.id : null;
				if (orgId && (!buyerOrg || buyerOrg.id != orgId)) {
					buyerOrg = org;
				} else if (!orgId) {
					buyerOrg = null;
				}

				buyer.is_valid = isValidOrganization(values);
				checkValid();
			},
			modalConf: {
				tabs: {
					main: {
						company_name: edi.selectors.defaults.company_name,
						company_gln: edi.selectors.defaults.company_gln
					}
				}
			}
		});

		return [mainDataInputs, offerId, seller, buyer, createDriverBlock()];
	};

	var createTransportBlock = function () {
		var transportNumber = createFieldsPanel([
			createField({
				title: edi.i18n.getMessage('iftmbc.approval.car.number'),
				input: createTextField({
					columnWidth: 0.75,
					margin: '0 0 0 5',
					allowBlank: false,
					maxLength: 18,
					value: edi.utils.getObjectProperty(documentData, 'carInfo.number'),
					name: 'carInfo.number'
				}),
				containerConfig: {
					columnWidth: 1,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
				}
			})
		]);

		var transportBrand = createFieldsPanel([
			createField({
				title: edi.i18n.getMessage('iftmbc.approval.car.brand'),
				input: createTextField({
					columnWidth: 0.75,
					margin: '0 0 0 5',
					allowBlank: false,
					maxLength: 255,
					value: edi.utils.getObjectProperty(documentData, 'carInfo.carBrand'),
					name: 'carInfo.carBrand'
				}),
				containerConfig: {
					columnWidth: 1,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
				}
			})
		]);

		var trailerNumber = createFieldsPanel([
			createField({
				title: edi.i18n.getMessage('iftmbc.approval.trailer.number'),
				input: createTextField({
					columnWidth: 0.75,
					margin: '0 0 0 5',
					allowBlank: false,
					maxLength: 18,
					value: edi.utils.getObjectProperty(documentData, 'carInfo.trailerNumber'),
					name: 'carInfo.trailerNumber'
				}),
				containerConfig: {
					columnWidth: 1,
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
				}
			})
		]);

		return [
			createFieldSet({
				title: edi.i18n.getMessage('iftmbc.car.info'),
				items: [transportBrand, transportNumber, trailerNumber]
			})
		];
	};

	/**
	 * Creates upd form
	 * @returns {Object}
	 */
	var createModuleForm = function () {
		var createButton = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(form, ['buyer', 'seller'], [buyer, seller], null, false)
					) {
						return;
					}
					save();
				}
			},
			isEdit
		);

		var twoColumnsLayout = createTwoColumnsLayout(createMainFormItems(), createTransportBlock());

		form = createForm({
			cls: 'edi-form document-add-form',
			autoScroll: true,
			bodyPadding: 10,
			items: [twoColumnsLayout],
			buttons: createButtonContainer({
				items: [createButton]
			})
		});

		form.on('validitychange', checkValid);

		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	/**
	 * Saves fns upd
	 */
	var save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = edi.utils.collectFormValues(form);
		edi.utils.setObjectProperty(values, 'type', '1');

		edi.utils.clearEmptyValues(values);

		var headerData = {
			data: Ext.encode(values)
		};

		headerData.number = edi.utils.getObjectProperty(values, 'offerNumber');

		var success = function (responseData) {
			var finishSaving = function () {
				moduleData.isChanged = false;
				moduleData.tab.setLoading(false);
				edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit && !isCreate);
				if (id) {
					edi.events.documents.fireEvent('change', {
						id: id
					});
				} else {
					edi.events.documents.fireEvent('create');
				}
			};

			var documentId = id || responseData.data.id;
			if (afterSave && documentId) {
				afterSave(documentId, function () {
					edi.document.actions.getDocumentHeaderData(documentId, function (headerData) {
						headerData ? (responseData.data = headerData) : null;
						finishSaving();
					});
				});
			} else {
				finishSaving();
			}
		};
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL
		);
		edi.document.actions.processDocument(
			buyerOrg.id,
			undefined,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBC_APPROVAL,
			parentId,
			id,
			success,
			failure,
			headerData
		);
	};
	/**
	 * Creates action panel above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */

	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		if (data && data.id) {
			isEdit = true;
			id = data.id;
			buyerOrg = data.toOrg;
			sellerOrg = data.fromOrg;

			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						documentData = data.data;
						var form = createModuleForm();
						modulePanel.add(form);
						moduleData.tab.add(createModuleActionsPanel());
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			var createModulePanel = function () {
				if (data && !isCreate) {
					buyerOrg = data.toOrg ? data.toOrg : null;
				}

				modulePanel.add(createModuleForm());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);
				buyer.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			};

			createModulePanel();
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
