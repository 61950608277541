import { showPackingModal } from './packingModal';
import {
	AMOUNT_FIELD_NAME,
	get,
	getProductIdent,
	PACKING_ID_FIELD_NAME,
	PRODUCT_AMOUNT_DECIMAL_PRECISION
} from './createTree';
import { recalculateProductValues } from './methods';
import { createFieldsForProductGrid } from '@Components/fields';
import { createSaveButton } from '@Components/buttons';
import { isPackingRecord, isProductRecord, Packing, PackingId, Product, Tree, TreeRecord } from './definitions';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createModalForm } from '@UIkit/components/panels';

/**
 * Close modal if it exists
 */
const closeModal = function (modal: ExtComponent) {
	if (modal && !modal.isDestroyed) {
		modal.close();
	}
};

/**
 * Moves 2nd lvl pack from/into packs (updates tree's maps)
 */
const movePackingLine = function (tree: Tree, packData: Packing, targetPackingId: PackingId) {
	let oldParentId = packData.thirdLvlPackingId;
	let packInMaps = tree.maps.secondLvlPacks[packData[PACKING_ID_FIELD_NAME]];
	packInMaps.packingId = targetPackingId;
	packInMaps.thirdLvlPackingId = targetPackingId;

	//target 2nd lvl pack become 3d lvl
	if (tree.maps.secondLvlPacks[targetPackingId]) {
		tree.maps.thirdLvlPacks[targetPackingId] = tree.maps.secondLvlPacks[targetPackingId];
		delete tree.maps.thirdLvlPacks[targetPackingId].thirdLvlPackingId;
		delete tree.maps.thirdLvlPacks[targetPackingId].packingId;
		delete tree.maps.secondLvlPacks[targetPackingId];
	}

	//if old parent loose it's last child, then it must be moved from 3d lvl to 2nd
	if (oldParentId) {
		let packsInOldParent = Object.values(tree.maps.secondLvlPacks).filter(
			(pack) => pack.thirdLvlPackingId === oldParentId
		);
		if (packsInOldParent.length === 0) {
			tree.maps.secondLvlPacks[oldParentId] = tree.maps.thirdLvlPacks[oldParentId];
			tree.maps.secondLvlPacks[oldParentId].thirdLvlPackingId = undefined;
			tree.maps.secondLvlPacks[oldParentId].packingId = undefined;
			delete tree.maps.thirdLvlPacks[oldParentId];
		}
	}
};

/**
 * Moves products from/into packs (updates tree's maps)
 */
const moveProductLine = function (
	tree: Tree,
	productData: Product,
	targetPackingId: PackingId | undefined,
	amountToMove: number
) {
	let prodId = productData.productID;
	let oldPackingId = productData.packingId || undefined;

	let oldProductIdent = getProductIdent({
		productID: prodId,
		packingId: oldPackingId
	});
	let oldProductInMapByPack = tree.maps.productsByPack[oldProductIdent];

	let newProductIdent = getProductIdent({
		productID: prodId,
		packingId: targetPackingId
	});
	let existingProductInMapByPack = tree.maps.productsByPack[newProductIdent];

	if (existingProductInMapByPack) {
		//update existing product
		existingProductInMapByPack[AMOUNT_FIELD_NAME] = String(
			+existingProductInMapByPack[AMOUNT_FIELD_NAME] + amountToMove
		);
		recalculateProductValues(tree, existingProductInMapByPack);
	} else {
		//add new product in map
		let newProdData: Product = Ext.clone(productData);
		newProdData[AMOUNT_FIELD_NAME] = String(amountToMove);
		newProdData.packingId = targetPackingId;
		recalculateProductValues(tree, newProdData);
		tree.maps.productsByPack[newProductIdent] = newProdData;
	}

	//update source product amount
	oldProductInMapByPack[AMOUNT_FIELD_NAME] = String(+oldProductInMapByPack[AMOUNT_FIELD_NAME] - amountToMove);
	if (+oldProductInMapByPack[AMOUNT_FIELD_NAME] <= 0) {
		delete tree.maps.productsByPack[oldProductIdent];
	} else {
		recalculateProductValues(tree, oldProductInMapByPack);
	}

	//let's update secondLvlPackingIds in every product with current productID in tree's maps
	let productInMapById = tree.maps.productsById[prodId];
	recalculateProductValues(tree, productInMapById);
	let newSecondLvlPackIds = Ext.clone(productInMapById.secondLvlPackingIds) || [];
	//delete unused packId (because product with 0 amount has been deleted previously)
	if (!tree.maps.productsByPack[oldProductIdent]) {
		let oldPackIdIndex = oldPackingId ? newSecondLvlPackIds.indexOf(oldPackingId) : -1;
		if (oldPackIdIndex >= 0) {
			newSecondLvlPackIds.splice(oldPackIdIndex, 1);
		}
	}
	//add new packingId if needed (id can be added earlier or is undefined(extracting from pack))
	if (targetPackingId && !newSecondLvlPackIds.some((it) => it === targetPackingId)) {
		newSecondLvlPackIds.push(targetPackingId);
	}

	productInMapById.secondLvlPackingIds = newSecondLvlPackIds;
	productInMapById.secondLvlPackingIds.forEach((packId) => {
		let ident = getProductIdent({
			productID: prodId,
			packingId: packId
		});
		tree.maps.productsByPack[ident].secondLvlPackingIds = Ext.clone(newSecondLvlPackIds);
		tree.maps.productsByPack[ident].packingId = packId;
	});
};

/**
 * Creates new pack in tree
 */
const createNewPack = function (tree: Tree, afterCreate: (newPackNode: TreeRecord<Packing>) => void) {
	showPackingModal(tree, undefined, {
		isDetails: false,
		isEdit: false,
		isCreate: true,
		afterCreateCallback: afterCreate
	});
};

/**
 * Returns list of available targets to move into
 */
const getAvailableTargets = function (tree: Tree, recordToMove: TreeRecord<Product | Packing>) {
	let currentPackingId = isProductRecord(recordToMove)
		? get(recordToMove, 'packingId')
		: isPackingRecord(recordToMove) && get(recordToMove, 'thirdLvlPackingId');

	let availableTargets: { id: string; name: string }[] = [];
	if (currentPackingId) {
		availableTargets.push({
			id: 'removeProductLineFromPacking',
			name: edi.i18n.getMessage('desadv.product.delete.from.packing.option')
		});
	}

	let getTargetFromPack = function (pack: Packing) {
		let typeName =
			pack.Type === edi.constants.PACKAGE_TYPES.OTHER
				? edi.i18n.getMessage('desadv.packing.type.NUL')
				: edi.i18n.getMessage('desadv.packing.type.BJ');
		return {
			id: pack[PACKING_ID_FIELD_NAME],
			name: `${pack.PackagingUnitName || typeName} (${pack[PACKING_ID_FIELD_NAME]})`
		};
	};

	if (isProductRecord(recordToMove)) {
		Object.values(tree.maps.secondLvlPacks)
			.filter((pack) => pack[PACKING_ID_FIELD_NAME] !== currentPackingId)
			.forEach((pack) => availableTargets.push(getTargetFromPack(pack)));
	} else if (isPackingRecord(recordToMove)) {
		Object.values(tree.maps.thirdLvlPacks)
			.filter((pack) => pack[PACKING_ID_FIELD_NAME] !== currentPackingId)
			.forEach((pack) => availableTargets.push(getTargetFromPack(pack)));

		let secondLvlPackWithProducts: { [key: PackingId]: PackingId } = {};
		Object.keys(tree.maps.productsByPack).forEach((productIdent) => {
			let packId = productIdent.split('_')[0];
			secondLvlPackWithProducts[packId] = packId;
		});
		const currentPackId = get(recordToMove, PACKING_ID_FIELD_NAME) || '';
		Object.values(tree.maps.secondLvlPacks)
			.filter((pack) => {
				return (
					currentPackId !== pack[PACKING_ID_FIELD_NAME] &&
					!secondLvlPackWithProducts[pack[PACKING_ID_FIELD_NAME]]
				);
			})
			.forEach((pack) => availableTargets.push(getTargetFromPack(pack)));
	}

	return availableTargets;
};

/**
 * Opens move many lines dialog window
 */
const showPackingSelectModal = function (
	tree: Tree,
	recordToMove: TreeRecord<Product | Packing>,
	preselectedTarget?: TreeRecord<Packing>
) {
	let currentPackingId = get(recordToMove, 'packingId');
	let availablePacks = getAvailableTargets(tree, recordToMove);
	let isMovingPack = isPackingRecord(recordToMove);
	let amount = isPackingRecord(recordToMove)
		? 1
		: (isProductRecord(recordToMove) && +get(recordToMove, AMOUNT_FIELD_NAME)) || 0;

	let formItemConfigs: AnyObject[] = [
		{
			name: 'fromPallet',
			type: 'detailLabel',
			text: currentPackingId
		},
		{
			name: 'packingId',
			title: edi.i18n.getMessage('column.pallet'),
			allowBlank: false,
			type: 'combo',
			store: edi.stores.createMemoryStore(availablePacks, 'SIMPLE'),
			autoValue: (preselectedTarget && get(preselectedTarget, PACKING_ID_FIELD_NAME)) || null,
			forceSelection: true
		}
	];

	if (!isMovingPack) {
		formItemConfigs.push({
			name: 'amount',
			allowBlank: false,
			type: 'number',
			minValue: 0.001,
			maxValue: amount,
			allowDecimals: true,
			decimalPrecision: PRODUCT_AMOUNT_DECIMAL_PRECISION,
			value: amount
		});
	}

	let form: ExtComponent = createModalForm({
		items: createFieldsForProductGrid(formItemConfigs)
	});

	let packTypeName: string =
		isPackingRecord(recordToMove) && get(recordToMove, 'Type') === edi.constants.PACKAGE_TYPES.OTHER
			? edi.i18n.getMessage('desadv.packing.type.NUL')
			: edi.i18n.getMessage('desadv.packing.type.BJ');
	let nameAndNumber: string = isPackingRecord(recordToMove)
		? `${get(recordToMove, 'PackagingUnitName') || packTypeName} (${get(recordToMove, PACKING_ID_FIELD_NAME)})`
		: (isProductRecord(recordToMove) && (get(recordToMove, 'ItemDescription') || get(recordToMove, 'EAN'))) || '';
	let modalTitle: string = edi.i18n.getMessage(
		'desadv.' + (isMovingPack ? 'package' : 'product') + '.cross.move.title',
		[nameAndNumber]
	);

	let createNewPackBtn = createSaveButton(
		function () {
			closeModal(modal);
			createNewPack(tree, function (createdPackingRecord) {
				showPackingSelectModal(tree, recordToMove, createdPackingRecord);
			});
		},
		{
			disabled: false,
			glyph: edi.constants.ICONS.LIBRARY_ADD,
			text: edi.i18n.getMessage('desadv.packing.lvl2.create')
		}
	) as ExtComponent;

	let moveBtn = createSaveButton(
		function () {
			let values = edi.utils.collectFormValues(form);
			let targetPackingId = values.packingId;
			if (targetPackingId === 'removeProductLineFromPacking') {
				targetPackingId = undefined;
			}

			if (isPackingRecord(recordToMove)) {
				movePackingLine(tree, recordToMove.data.data, targetPackingId);
			} else if (isProductRecord(recordToMove)) {
				let amountToMove = +values.amount || 0;
				moveProductLine(tree, recordToMove.data.data, targetPackingId, amountToMove);
			}
			closeModal(modal);
			tree.rebuildTree();
		},
		{
			bindToForm: form,
			text: edi.i18n.getMessage('form.btn.move')
		}
	) as ExtComponent;

	let modal = createModalPanel({
		title: modalTitle,
		width: MODAL_SIZE.widthMedium,
		items: [form],
		buttonsBefore: [createNewPackBtn, moveBtn]
	}) as ExtComponent;

	tree.openedModal = modal;

	modal.show();
};

/**
 * Opens move many lines dialog window
 */
const showMoveMultiLineDialog = function (
	tree: Tree,
	recordsToMove: TreeRecord<Product>[],
	preselectedTarget?: TreeRecord<Packing>
) {
	let availablePacks = Object.values(tree.maps.secondLvlPacks).map((pack: AnyObject) => {
		let packName =
			pack.Type === edi.constants.PACKAGE_TYPES.PALLET
				? edi.i18n.getMessage('desadv.packing.type.BJ')
				: pack.PackagingUnitName || edi.i18n.getMessage('desadv.packing.type.NUL');
		return {
			id: pack[PACKING_ID_FIELD_NAME],
			name: `${packName} (${pack[PACKING_ID_FIELD_NAME]})`
		};
	});

	let form = createModalForm({
		items: createFieldsForProductGrid([
			{
				name: 'packingId',
				title: edi.i18n.getMessage('column.pallet'),
				allowBlank: false,
				type: 'combo',
				autoValue: (preselectedTarget && get(preselectedTarget, PACKING_ID_FIELD_NAME)) || null,
				store: edi.stores.createMemoryStore(availablePacks, 'SIMPLE'),
				forceSelection: true
			}
		])
	}) as ExtComponent;

	let createNewPackBtn = createSaveButton(
		function () {
			closeModal(modal);
			createNewPack(tree, function (createdPackingRecord) {
				showMoveMultiLineDialog(tree, recordsToMove, createdPackingRecord);
			});
		},
		{
			disabled: false,
			glyph: edi.constants.ICONS.LIBRARY_ADD,
			text: edi.i18n.getMessage('desadv.packing.lvl2.create')
		}
	) as ExtComponent;

	let moveBtn = createSaveButton(
		function () {
			let values = edi.utils.collectFormValues(form);
			let targetPackingId = values.packingId;
			recordsToMove.forEach((rec) => {
				let recData = rec.data.data;
				let amountToMove = +recData[AMOUNT_FIELD_NAME] || 0;
				moveProductLine(tree, recData, targetPackingId, amountToMove);
			});
			closeModal(modal);
			tree.rebuildTree();
		},
		{
			bindToForm: form,
			text: edi.i18n.getMessage('form.btn.move')
		}
	) as ExtComponent;

	let modal: ExtComponent = createModalPanel({
		title: edi.i18n.getMessage('desadv.product.many.move.title', [`(${recordsToMove.length})`]),
		width: MODAL_SIZE.widthMedium,
		items: [form],
		buttonsBefore: [createNewPackBtn, moveBtn]
	});

	tree.openedModal = modal;

	modal.show();
};

/**
 * Opens modal to move product/package
 */
const showMoveLineDialog = function (tree: Tree, record: TreeRecord<Product | Packing>) {
	let availableTargets = getAvailableTargets(tree, record);

	//Force show modal for create new packing, cause there is no targets to move into
	if (!availableTargets.length) {
		createNewPack(tree, function (createdPackingRecord) {
			showPackingSelectModal(tree, record, createdPackingRecord);
		});
	} else {
		showPackingSelectModal(tree, record);
	}
};

export { showMoveLineDialog, showMoveMultiLineDialog, moveProductLine };
