import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { applicationAPI } from './rest';
import { APPLICATION_COLUMN } from './columns';
import { APPLICATION_MODEL } from './models';
import { openPreviewModal } from '@Ediweb/modules/CONTROL_CENTER/tabs/APPLICATION_TO_FNS/methods';

let tabModuleData;

const openDetail = function (recordData) {
	tabModuleData.tab.setLoading(true);
	let callback = function () {
		tabModuleData.tab.setLoading(false);
	};

	openPreviewModal(recordData, callback);
};

const createActionColumn = function () {
	return createActionsColumnConfig({
		items: [
			{
				glyph: edi.constants.ICONS.DETAILS,
				handler: function (grid, rowIndex, colindex, actionItem, event, record) {
					var recordData = record.getData();
					openDetail(recordData);
				}
			},
			{
				glyph: edi.constants.ICONS.FILE_DOWNLOAD,
				handler: function (grid, rowIndex, colindex, actionItem, event, record) {
					var recordData = record.getData();
					var url = edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: recordData.id
					});
					edi.rest.downloadFile(url, recordData.id);
				}
			}
		]
	});
};

const createApplicationGrid = function (moduleData) {
	tabModuleData = moduleData;
	let columns = edi.columns.get(APPLICATION_COLUMN);
	let actionColumn = createActionColumn();
	actionColumn ? columns.push(actionColumn) : null;
	return createGrid({
		proxyConfig: {
			type: 'ajax',
			url: applicationAPI.APPLICATION_TO_FNS.GET,
			reader: {
				totalProperty: function (data) {
					return data?.data?.totalCount;
				},
				rootProperty: function (data) {
					return data?.data?.result;
				}
			}
		},
		storeConfig: {
			model: edi.models.getModel(APPLICATION_MODEL),
			autoLoad: true
		},
		gridConfig: {
			columns: columns,
			padding: '0 0 0 0',
			region: 'center',
			border: 1,
			listeners: {
				celldblclick: function (view, td, cellIndex, record) {
					var recordData = record.getData();
					openDetail(recordData);
				}
			}
		},
		viewConfig: {
			emptyTextTplOptions: {
				enabled: true,
				iconName: 'application-fns',
				title: edi.i18n.getMessage('ediweb.application.fns.empty.text.tpl.title'),
				contentText: edi.i18n.getMessage('ediweb.application.fns.empty.text.tpl.contentText'),
				beforeButtonText: edi.i18n.getMessage('ediweb.application.fns.empty.text.tpl.beforeButtonText')
			}
		}
	});
};

export { createApplicationGrid };
