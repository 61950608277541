import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createContainer, createFieldBlockForDetails } from '@UIkit/components/panels';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';

Ext.namespace('edi.modules');
edi.modules['document.details.frov_sig_so_act2_from_x5'] = function () {
	let moduleData, docHeader, docContent;
	const relations = edi.relations.getRelations({ self: true }) || [];

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docHeader = moduleData.initData.data;
		docContent = {};
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	let changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				docHeader = headerData.data;
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};

	/**
	 * Creates action panel
	 * @returns	{Object}	Ext.Panel instance
	 */
	let createModuleActionsPanel = function () {
		let direction = edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg);
		let needSignatures = edi.document.actions.getSignCount(docHeader);
		let needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(docHeader, edi.constants.CONTRACT_SIDES.CONSUMER);
		let signaturesCount = needSignatures + needSignatures2 - docHeader.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount;

		let actionsPanel = createActionsPanel();
		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: docHeader,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				REFRESH: {
					handler() {
						changeHandler(docHeader);
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					addExtendedExport: edi.permissions.hasPermission('EXPORT_EDI_DSF_EXTENDED'),
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE, {
						documentId: moduleData.initData.id
					}),
					extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: moduleData.initData.id
					})
				},
				SIGN: {
					methodAddOptions: {
						useBeforeAction: docHeader.state === edi.constants.STATE.SENT
					}
				}
			}
		});

		return actionsPanel;
	};

	const createOS = (conf) =>
		createOrgSelector(
			Ext.merge(
				{
					is_valid: true,
					readOnly: true,
					valuesByMap: false
				},
				conf
			)
		);

	const findOrgById = function (orgId) {
		return relations.find((r) => r.id === orgId) || null;
	};

	const createPartiesBlock = function () {
		const sender = createOS({
			fieldValues: edi.converters.convertOrgToPartie(findOrgById(docHeader.fromOrg.id))
		});
		const receiver = createOS({
			fieldValues: edi.converters.convertOrgToPartie(findOrgById(docHeader.toOrg.id))
		});

		return createContainer({
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [[6, 6]]
			},
			items: [
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('documents.column.document.sender'),
					items: [sender]
				}),
				createFieldBlockForDetails({
					title: edi.i18n.getMessage('documents.column.document.recipient'),
					items: [receiver]
				})
			]
		});
	};

	const createMainDataBlock = function () {
		const contents = [
			{
				title: edi.i18n.getMessage('documents.column.number'),
				text: docContent.docNumber
			},
			{
				title: edi.i18n.getMessage('documents.column.date'),
				text: edi.utils.formatDate(docContent.docDate, edi.constants.DATE_FORMAT.FNS)
			}
		];

		return createFieldBlockForDetails({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					gap: [8, 16],
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents
				})
			]
		});
	};

	const createFileBlock = () => {
		const fileInfo = docContent.file;
		const file = fileInfo
			? createFileDropper({
					cls: 'drag-file',
					readOnly: true,
					fileData: {
						fileName: fileInfo.fileName,
						fileDate: edi.utils.formatDate(fileInfo.modifyDate, edi.constants.DATE_FORMAT.FNS),
						fileTime: edi.utils.formatDate(fileInfo.modifyDate, edi.constants.DATE_FORMAT.TIME),
						fileSize: edi.utils.formatFileSize(fileInfo.fileSize)
					}
			  })
			: createLabelForDetails({
					text: docContent.fileName
			  });

		return createFieldBlockForDetails({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			title: edi.i18n.getMessage('document.file'),
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [file]
		});
	};

	const createRemarkBlock = function (document) {
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('frov_sig_so_act2_from_x5.comment'),
			layout: {
				type: 'grid',
				area: [[8]]
			},
			items: [
				createLabelForDetails({
					text: docContent.comment
				})
			]
		});
	};

	/**
	 * Creates document details panel
	 * @returns	{Object}	Ext.Panel instance
	 */
	let createDetailsPanel = function () {
		let headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			rejectReasonText: docContent.rejectReason
		});

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [headPanel, createMainDataBlock(), createPartiesBlock(), createFileBlock(), createRemarkBlock()]
		});
	};

	/**
	 * Gets document's content
	 * @param	{Function}	callback
	 */
	let getDocContent = function (callback) {
		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		let success = function (resp) {
			if (resp && resp.data) {
				docContent = resp.data;
				callback();
			} else {
				failure(resp);
			}
		};
		let url = edi.document.actions.formatDetailsUri(moduleData.initData);
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	let renderData = function (initCallBack) {
		moduleData.tab.setLoading(true);
		getDocContent(function () {
			moduleData.tab.removeAll();
			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(
				createDetailsModulePanel({
					items: [createDetailsPanel()]
				})
			);

			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		});
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	let onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		return true;
	};
};
