import {
	createButtonContainer,
	createContainer,
	createFieldBlock,
	createFieldSet,
	createRangeContainer,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createLabel, createNumberField } from '@UIkit/components/fields';
import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createAddModulePanel, createFormForModule } from '@Components/panels';
import {
	createCombo,
	createDateField,
	createHiddenField,
	createOkeiField,
	createTextField,
	createTimeField
} from '@Components/fields';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { createDeliveryCoordination } from '@Edi/specialComponents/deliveryCoordination';
import { ORDRSP_LINE_MODEL, ORDRSP_SUMMARY_MODEL } from './models';
import { ORDRSP_LINE_COLUMN, ORDRSP_SUMMARY_EDITABLE_COLUMN } from './columns';
import { createOrderResponse } from './methods';
import { orderRespSelectors } from './selectors';
import { orgSelectorMethods } from '@Components/orgSelector/methods';
import { coreMethods } from '@Core/commons';

/**
 * Class for new ordrsp creation
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.create.ordrsp'] = function () {
	let moduleData,
		productValues = { products: [] },
		afterSave,
		form,
		ordrsp,
		isCreateFromOrder = false,
		buyer,
		seller,
		shipFrom,
		delivery,
		id,
		parentId,
		isEdit = false,
		isCopy = false,
		productsGrid,
		buyerOrg,
		buyerId,
		buyerCountry = 'RUS',
		buyerOrgValues,
		userData = edi.core.getUserData(),
		deliveryCoordination = null,
		taxRatesStore,
		availableTaxRates = [],
		initialData,
		taxCalculationMethod = edi.constants.TAX_CALCULATION_TYPES.NET_PRICE,
		costDecimals,
		documentPartFieldContainers = {},
		documentPartFields = {},
		documentPartFieldValues = {},
		documentLinePartFields = {
			ReasonCode: {
				title: edi.i18n.getMessage('org.document.LEGACY_ORDER_RESP.part.docLines.ReasonCode'),
				isHidden: true,
				name: 'ReasonCode'
			}
		},
		customFieldsContainer,
		customFieldsObj,
		customFieldsFromTransformation;
	const defaultCurrency = userData.org.country === 'BLR' ? 'BYN' : edi.constants.DEFAULT.CURRENCY;
	let topPath = '//Document-OrderResponse/';
	const orgConverter = edi.converters.convertOrgToLegacyPartie;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		costDecimals = coreMethods.getUserOrgCostDecimals();

		customFieldsFromTransformation = moduleData.initData?.data?.data?.customFields;

		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		if (moduleData.initData.data && moduleData.initData.data.buyerOrg) {
			ordrsp = edi.utils.getObjectProperty(moduleData.initData.data, 'data');
			initialData = ordrsp;
			delete initialData.fileId;
			buyerOrg = moduleData.initData.data.buyerOrg;
			buyerOrgValues = edi.converters.convertOrgToLegacyPartie(buyerOrg);
			if (!buyerOrg.hasOwnProperty('attributes')) {
				buyerOrg =
					edi.utils.getOrg({
						orgId: buyerOrg.id
					}) || buyerOrg;
			}
			taxCalculationMethod = edi.utils.getTaxCalculationMethod(buyerOrg.attributes);
			buyerId = buyerOrg.id;
			buyerCountry = buyerOrg.country;
			isCreateFromOrder = true;
		}
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Creates order response form
	 * @returns {Object}	 *
	 */
	var createModuleForm = function (document) {
		var header = edi.utils.getObjectProperty(document ? document : ordrsp, 'OrderResponse-Header');
		var parties = edi.utils.getObjectProperty(document ? document : ordrsp, 'OrderResponse-Parties');

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				useHiddenFields: true,
				fieldsMapOnly: true,
				is_valid: true,
				processValues: orgConverter,
				selectedRelationByMap: true,
				valuesByMap: true,
				forceChangeFields: {
					company_iln: true
				},
				fieldValues: parties,
				callback: checkValid
			});

			return createOrgSelector(selectorConfig);
		};

		const createMainDataBlock = function () {
			const nameCodeInput = createCombo({
				fieldLabel: edi.i18n.getMessage('order.resp.name.code'),
				name: 'OrderResponse-Header.DocumentNameCode',
				valueSrc: document ? document : ordrsp,
				value: edi.utils.getObjectProperty(header, 'DocumentNameCode') || 220,
				store: edi.stores.createSimpleInlineStore(
					[220, 105, 788],
					function (id) {
						return edi.i18n.getMessage('document.ordersp.name.code.' + id);
					},
					true
				),
				anyMatch: true
			});
			const numberDocumentValue =
				document || ordrsp
					? edi.utils.getObjectProperty(
							document ? document : ordrsp,
							'OrderResponse-Header.OrderResponseNumber'
					  ) + (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
					: undefined;
			const numberDocument = createTextField({
				fieldLabel: edi.i18n.getMessage('documents.create.ordrsp.nr'),
				emptyText: edi.i18n.getMessage('nr'),
				columnWidth: 0.7333,
				allowBlank: false,
				maxLength: 35,
				value: numberDocumentValue,
				name: 'OrderResponse-Header.OrderResponseNumber'
			});
			const documentDate = createDateField({
				fieldLabel: edi.i18n.getMessage('date'),
				allowBlank: false,
				value: header && !isCopy ? edi.utils.getObjectProperty(header, 'OrderResponseDate') : new Date(),
				name: 'OrderResponse-Header.OrderResponseDate'
			});
			const labelOrder = createLabel({
				typography: 'heading_01',
				text: edi.i18n.getMessage('document.ordrsp.for.order')
			});
			const orderNameCodeInput = createCombo({
				fieldLabel: edi.i18n.getMessage('document.ordrsp.ord.name'),
				hidden: !edi.utils.getObjectProperty(header, 'Order.OrderNameCode'),
				name: 'OrderResponse-Header.Order.OrderNameCode',
				valueSrc: document ? document : ordrsp,
				readOnly: true,
				value: edi.utils.getObjectProperty(header, 'Order.OrderNameCode'),
				store: edi.stores.createSimpleInlineStore(
					[105, 788, '22E', 222, 220],
					function (id) {
						return edi.i18n.getMessage('document.ordersp.order.name.code.' + id);
					},
					true
				),
				anyMatch: true
			});
			const hiddenOrderNumber = createHiddenField({
				value: edi.utils.getObjectProperty(header, 'Order.BuyerOrderNumber'),
				name: 'OrderResponse-Header.Order.BuyerOrderNumber'
			});
			const orderNumber = createTextField({
				fieldLabel: edi.i18n.getMessage('documents.create.ordrsp.nr'),
				value: edi.utils.getObjectProperty(header, 'Order.BuyerOrderNumber'),
				readOnly: true
			});
			const hiddenOrderDate = createHiddenField({
				value: edi.utils.getObjectProperty(header, 'Order.BuyerOrderDate'),
				name: 'OrderResponse-Header.Order.BuyerOrderDate'
			});
			const orderDate = createDateField({
				fieldLabel: edi.i18n.getMessage('date'),
				value: edi.utils.getObjectProperty(header, 'Order.BuyerOrderDate'),
				readOnly: true
			});
			const ordrspCurrency = createCombo({
				fieldLabel: edi.i18n.getMessage('ordrsp.currency'),
				name: 'OrderResponse-Header.OrderResponseCurrency',
				valueSrc: document ? document : ordrsp,
				value: edi.utils.getObjectProperty(header, 'OrderResponseCurrency') ? undefined : defaultCurrency,
				store: edi.stores.initCurrencyStore(),
				anyMatch: true
			});

			const documentFunctionCodeValue = edi.utils.getObjectProperty(
				document,
				'OrderResponse-Header.DocumentFunctionCode'
			);
			const functionalDocumentCode = createCombo({
				fieldLabel: edi.i18n.getMessage('functional.document.code'),
				name: 'OrderResponse-Header.DocumentFunctionCode',
				store: edi.stores.createSimpleInlineStore(['9', '4', '3'], function (id) {
					return edi.i18n.getMessage('functional.document.code.' + id);
				}),
				value: document && documentFunctionCodeValue !== 'O' ? documentFunctionCodeValue : '9',
				anyMatch: true,
				allowBlank: false
			});
			return createContainer({
				layout: {
					type: 'grid',
					area: [[3, 4, 2], [12], [3, 4, 2, 1, 1], [4, 3]]
				},
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				items: [
					nameCodeInput,
					numberDocument,
					documentDate,

					labelOrder,

					orderNameCodeInput,
					orderNumber,
					orderDate,
					hiddenOrderNumber,
					hiddenOrderDate,

					ordrspCurrency,
					functionalDocumentCode
				]
			});
		};

		const createPartiesBlock = function () {
			const buyerID = moduleData.initData.data?.toOrg?.id ?? moduleData.initData.data?.parties?.toOrg?.id;
			buyer = createCS({
				itemId: 'buyer',
				readOnly: isEdit,
				selectedOrgValues: buyerOrgValues,
				orgFromRelation: true,
				originalValues: orgSelectorMethods.getOrgCardValues({
					orgId: buyerID,
					converter: orgConverter
				}),
				selectedOrg:
					buyerOrg ||
					edi.utils.getOrg({
						orgId: moduleData.initData.data?.toOrg?.id
					}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				fieldsMap: orderRespSelectors.getBuyerFieldsMap(),
				modalConf: orderRespSelectors.getBuyerModalConf()
			});
			const buyerBlock = createFieldBlock({
				title: edi.i18n.getMessage('document.buyer'),
				items: [buyer]
			});

			var sellerILN = edi.utils.getObjectProperty(parties, 'Seller.ILN');
			var sellerOrg = edi.utils.getOrg({ orgILN: sellerILN }) || userData.org;
			var sellerData = edi.converters.convertOrgToLegacyPartie(sellerOrg);
			const sellerID =
				moduleData.initData.data?.fromOrg?.id ??
				moduleData.initData.data?.parties?.fromOrg?.id ??
				userData.org.id;
			seller = createCS({
				itemId: 'seller',
				readOnly: isEdit,
				selectedOrgValues: sellerData,
				selectedOrg: sellerOrg,
				orgFromRelation: true,
				originalValues: orgSelectorMethods.getOrgCardValues({
					orgId: sellerID,
					converter: orgConverter
				}),
				onFormCreate: edi.selectors.fieldControls.updateInnField,
				fieldsMap: orderRespSelectors.getSellerFieldsMap(),
				modalConf: orderRespSelectors.getSellerModalConf(),
				callback: function (values, org) {
					var orgId = org ? org.id : null;
					var continueFn = function () {
						validateGridRowsWithCustomFields();
						checkValid();
						if (shipFrom.getOrgIdForDelcat() != orgId) {
							shipFrom.setOrgIdForLoccat(orgId);
						}
					};

					let buyerId = buyer.getValues().id || buyer.selectedOrg?.id;
					if (orgId && buyerId) {
						edi.methods.custom_fields.initCustomFields({
							customFieldsObj,
							initialCustomFieldsData: customFieldsFromTransformation,
							docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
							toOrgId: orgId,
							fromOrgId: buyerId,
							container: customFieldsContainer,
							grid: productsGrid,
							topPath: topPath,
							finishCallback(obj) {
								customFieldsObj = obj;
								form.isValid();
								continueFn();
							},
							fail() {
								form.isValid();
								continueFn();
							}
						});
					} else {
						continueFn();
					}
				}
			});
			const sellerBlock = createFieldBlock({
				title: edi.i18n.getMessage('document.seller'),
				items: [seller]
			});

			shipFrom = createCS({
				itemId: 'shipFrom',
				tooltip: 'document.ship.from.tooltip',
				relationsFromLoccatByOrgId: sellerData.id,
				ownCatalog: false,
				allowBlank: true,
				callback: function () {
					initialData
						? edi.utils.setObjectProperty(initialData, 'OrderResponse-Parties.ShipFrom', null)
						: null;
					checkValid();
				},
				fieldsMap: orderRespSelectors.getShipFromFieldsMap(),
				modalConf: orderRespSelectors.getShipFromModalConf()
			});
			const shipFromBlock = createFieldBlock({
				title: edi.i18n.getMessage('document.ship.from'),
				items: [shipFrom]
			});

			delivery = createCS({
				itemId: 'delivery',
				tooltip: 'document.delivery.tooltip',
				ownCatalog: false,
				relationsFromDelcatByOrgId: buyerId,
				is_valid: !!edi.utils.getObjectProperty(parties, 'DeliveryPoint.ILN'),
				callback: function () {
					initialData
						? edi.utils.setObjectProperty(initialData, 'OrderResponse-Parties.DeliveryPoint', null)
						: null;
					checkValid();
				},
				fieldsMap: orderRespSelectors.getDeliveryFieldsMap(),
				modalConf: orderRespSelectors.getDeliveryModalConf()
			});
			const deliveryBlock = createFieldBlock({
				title: edi.i18n.getMessage('document.delivery'),
				items: [delivery]
			});

			return createFieldSet({
				title: edi.i18n.getMessage('document.ordrsp.section.parties'),
				collapsible: true,
				layout: {
					type: 'grid',
					area: [[6, 6], [6, 6], [6]]
				},
				items: [buyerBlock, sellerBlock, shipFromBlock, deliveryBlock]
			});
		};

		const createDeliveryInformationBlock = function () {
			var termsOfDelivery = createCombo({
				fieldLabel: edi.i18n.getMessage('document.ordersp.terms.of.delivery'),
				name: 'DetailsOfTransport.TermsOfDelivery',
				value: edi.utils.getObjectProperty(document ? document : ordrsp, 'DetailsOfTransport.TermsOfDelivery'),
				store: edi.stores.createSimpleInlineStore(
					[4, 7, '10E'],
					function (id) {
						return edi.i18n.getMessage('terms.of.delivery.' + id);
					},
					true
				)
			});
			let deliveryDate;
			const deliveryPeriodPanel = createFieldBlock({
				title: edi.i18n.getMessage('document.ordersp.expected.delivery'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				layout: {
					type: 'grid',
					area: [[2]]
				},
				items: [
					(deliveryDate = createDateField({
						fieldLabel: edi.i18n.getMessage('date'),
						name: 'OrderResponse-Header.ExpectedDeliveryDate',
						valueSrc: document ? document : ordrsp
					}))
				]
			});
			return createFieldSet({
				title: edi.i18n.getMessage('document.ordersp.section.delivery'),
				collapsible: true,
				layout: {
					type: 'grid',
					area: [[4], [12]]
				},
				items: [termsOfDelivery, deliveryPeriodPanel]
			});
		};

		const createAdditionalInformationBlock = function () {
			const orderCorrectionNumber = createFieldBlock({
				title: edi.i18n.getMessage('document.ordersp.order.correction.number'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				layout: {
					type: 'grid',
					area: [3]
				},
				items: [
					createTextField({
						fieldLabel: edi.i18n.getMessage('documents.create.ordrsp.nr'),
						maxLength: 35,
						valueSrc: document ? document : ordrsp,
						name: 'OrderResponse-Header.Order.OrderCorrectionNumber'
					})
				]
			});
			const agreement = createFieldBlock({
				title: edi.i18n.getMessage('document.ordersp.agreement'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				layout: {
					type: 'grid',
					area: [[3, 2]]
				},
				items: [
					createTextField({
						fieldLabel: edi.i18n.getMessage('documents.create.ordrsp.nr'),
						maxLength: 175,
						valueSrc: document ? document : ordrsp,
						name: 'OrderResponse-Header.Reference.ContractNumber'
					}),
					createDateField({
						fieldLabel: edi.i18n.getMessage('date'),
						name: 'OrderResponse-Header.Reference.ContractDate',
						value: edi.utils.getObjectProperty(header, 'Reference.ContractDate')
					})
				]
			});
			//дополнительные поля
			documentPartFieldValues.ReasonCode = edi.utils.getObjectProperty(header, 'ReasonCode');
			documentPartFieldContainers.ReasonCodeContainer = createContainer({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				hidden: true,
				items: []
			});

			documentPartFieldValues.ReasonDelayCode = edi.utils.getObjectProperty(header, 'ReasonDelayCode');
			documentPartFieldContainers.ReasonDelayCodeContainer = createContainer({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				hidden: true,
				items: []
			});

			return createFieldSet({
				title: edi.i18n.getMessage('document.ordersp.section.additional'),
				collapsible: true,
				layout: {
					type: 'grid',
					area: [12, 12, 12, 12, 12]
				},
				items: [
					orderCorrectionNumber,
					agreement,
					documentPartFieldContainers.ReasonCodeContainer,
					documentPartFieldContainers.ReasonDelayCodeContainer
				]
			});
		};

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		const ordrspRemarks = createFieldBlock({
			title: edi.i18n.getMessage('remarks'),
			items: [
				createTextField({
					name: 'OrderResponse-Header.Remarks',
					maxLength: 500,
					valueSrc: document ? document : ordrsp,
					isTextarea: true
				})
			]
		});

		const specialInstructions = createFieldBlock({
			title: edi.i18n.getMessage('order.resp.special.instructions'),
			items: [
				createTextField({
					name: 'OrderResponse-Header.SpecialInstructions',
					maxLength: 128,
					valueSrc: document ? document : ordrsp,
					isTextarea: true
				})
			]
		});

		const createProductsGrid = function () {
			availableTaxRates = edi.methods.taxRates.getRatesByCountry(buyerCountry);
			taxRatesStore = edi.stores.createInlineStore(availableTaxRates);

			/**
			 * Calculates price and tax summary according to tax recalculation method
			 * @param    {Object}     values              product data
			 * @param    {Object}     calcFieldsData      object with additional data that could override/extend values returned by gatherValuesRow
			 * @param    {Boolean}    roundResults        true to round calculated values
			 * @param    {Boolean}    isAmountZero        true to amount can be zero
			 * @returns {*}
			 */
			var calculateValues = function (values, calcFieldsData, roundResults, isAmountZero) {
				var data = edi.methods.product.grid.gatherValuesRow(
					values,
					edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP
				);
				if (calcFieldsData && 'object' == typeof calcFieldsData) {
					Ext.merge(data, calcFieldsData);
				}
				var calcData = edi.methods.product.price.recalculation(
					{
						taxRate: data.rateVal,
						amount: data.amount,
						netPrice: data.price,
						grossPrice: data.grossPrice,
						netSum: values.NetAmount,
						taxSum: values.TaxAmount,
						grossSum: values.GrossAmount
					},
					taxCalculationMethod,
					undefined,
					isAmountZero,
					costDecimals
				);
				values.GrossAmount = calcData.grossSum;
				values.TaxAmount = calcData.taxSum;
				values.NetAmount = calcData.netSum;
				values.OrderedUnitGrossPrice = calcData.grossPrice;
				values.OrderedUnitNetPrice = calcData.netPrice || data.price;
				roundResults
					? (values = edi.methods.product.grid.roundValues(
							values,
							['GrossAmount', 'TaxAmount', 'NetAmount', 'OrderedUnitGrossPrice', 'OrderedUnitNetPrice'],
							costDecimals
					  ))
					: null;
				return values;
			};

			var processRecordValues = function (values) {
				var calculated = Ext.clone(values);
				calculateValues(calculated, undefined, true);
				return values;
			};

			var lines = edi.utils.getObjectProperty(document ? document : ordrsp, 'OrderResponse-Lines.Line', true);
			if ('string' != typeof lines) {
				for (var i = 0; i < lines.length; i++) {
					var values = Ext.clone(lines[i]['Line-Item']);
					values['Line-Reference'] = lines[i]['Line-Reference'];
					var lineReference = edi.utils.getObjectProperty(
						lines[i],
						'Line-Reference.Reference-Elements.Reference-Element',
						true
					);
					if (lineReference.length > 0) {
						lineReference.filter(function (item) {
							var referenceType = edi.utils.getObjectProperty(item, 'Reference-Type', false);
							return referenceType === 'spc';
						});
						edi.utils.setObjectProperty(
							values,
							'Line-Reference.Reference-Elements.Reference-Element',
							lineReference[0]
						);
					}
					if (!!ordrsp) {
						values.AllocatedDelivered = values.OrderedQuantity;
					}
					values.BuyerItemDescription = lines[i]['Line-Order']?.BuyerItemDescription;
					const deliveryDate = lines[i]['Line-UltimateCustomer']?.DeliveryDate;
					if (deliveryDate) {
						const splitted = deliveryDate.split('T');
						values.DeliveryDateDate = splitted[0];
						values.DeliveryDateTime = splitted[1];
					}
					const deliveryPeriod = lines[i]['Line-UltimateCustomer']?.DeliveryPeriod;
					if (deliveryPeriod) {
						const splitted = deliveryPeriod.split('-');
						values.DeliveryPeriodFrom = splitted[0];
						values.DeliveryPeriodTo = splitted[1];
					}
					const stockReserve = lines[i]['Line-UltimateCustomer']?.StockReserveDateTime;
					if (stockReserve) {
						const splitted = stockReserve.split('T');
						values.StockReserveDate = splitted[0];
						values.StockReserveTime = splitted[1];
					}
					values.TermsOfDelivery = lines[i]['Line-UltimateCustomer']?.TermsOfDelivery;
					values.AddressText = lines[i]['Line-UltimateCustomer']?.AddressText;
					values.Floor = lines[i]['Line-UltimateCustomer']?.Floor;
					values.ModeOfTransport =
						lines[i]['Line-UltimateCustomer']?.['Line-Transport']?.[0]?.ModeOfTransport;
					values.VehicleHeight = lines[i]['Line-UltimateCustomer']?.['Line-Transport']?.[0]?.VehicleHeight;
					edi.document.actions.setInternalLineId(lines[i], values);
					productValues.products.push(processRecordValues(values));
				}
			}

			productsGrid = createProductGridBase({
				title: edi.i18n.getMessage('document.ordrsp.lines'),
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				gridModel: ORDRSP_LINE_MODEL,
				totalModel: ORDRSP_SUMMARY_MODEL,
				gridColumnConfig: ORDRSP_LINE_COLUMN,
				totalColumnConfig: ORDRSP_SUMMARY_EDITABLE_COLUMN,
				data: productValues.products,
				hideAddButton: false,
				lockIfNoPartner: true,
				margin: '16 0 0',
				lineNumberFieldName: 'LineNumber',
				isValidRecord: function (record) {
					return !!record.get('OrderedQuantity');
				},
				defaultValuesGetter: function () {
					return {
						OrderedQuantity: '0'
					};
				},
				afterRowEdit: function (values, field) {
					if (field === 'AllocatedDelivered') {
						calculateValues(
							values,
							{
								amount: values.AllocatedDelivered
							},
							true,
							true
						);
					}
				},
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
				docData: document,
				changeValuesBeforeEdit: function (values) {
					if (values.UnitOfMeasure) {
						const okeiStore = edi.stores.initLegacyOkeiStore();
						const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
						const rec = okeiStore.findRecordByName(val);
						if (rec) {
							values.UnitOfMeasure = rec.get('name_international');
						}
					}
				},
				callback: function (values) {
					if ('string' != typeof values.products) {
						for (var j = 0; j < values.products.length; j++) {
							var line = values.products[j];
							if (line.OrderedQuantity == line.AllocatedDelivered) {
								line.ItemStatus = 5;
							} else if (line.AllocatedDelivered == 0) {
								line.ItemStatus = 7;
							} else if (line.OrderedQuantity !== line.AllocatedDelivered) {
								line.ItemStatus = 3;
							}
						}
					}
					moduleData.isChanged = true;
					productValues = values;
					checkValid();
				},
				totalsHandlerCallback: function (values) {
					if ('undefined' != typeof values) {
						Ext.Object.merge(productValues, values);
					}
					checkValid();
				},
				resetFormOnCatalogSelection: true,
				selectProductHandler: function (product) {
					var fields = this.getFormFields();
					var PackDetails = product.PackDetails ? product.PackDetails : {};

					fields.BuyerItemCode?.setValue(product.BuyerItemCode);
					fields.EAN ? fields.EAN.setValue(product.EAN) : null;
					fields.OrderedQuantity && fields.OrderedQuantity.getValue() < PackDetails.MinOrderedQuantity
						? fields.OrderedQuantity.setValue(PackDetails.MinOrderedQuantity) &&
						  fields.AllocatedDelivered.setValue(PackDetails.MinOrderedQuantity)
						: null;
					fields.UnitOfMeasure ? fields.UnitOfMeasure.setValue(product.UnitOfMeasure) : null;
					fields.ItemType ? fields.ItemType.setValue(product.ItemType) : null;
					fields.ItemDescription ? fields.ItemDescription.setValue(product.ItemDescription) : null;
					fields.SupplierItemCode ? fields.SupplierItemCode.setValue(product.SupplierItemCode) : null;
					fields.OrderedUnitNetPrice ? fields.OrderedUnitNetPrice.setValue(product.UnitNetPrice) : null;
					fields.OrderedUnitPacksize ? fields.OrderedUnitPacksize.setValue(PackDetails.UnitPacksize) : null;
					fields.ExpirationDate ? fields.ExpirationDate.setValue(product.ExpirationDate) : null;
					fields.TaxRate ? fields.TaxRate.setValue(String(product.TaxRate)) : null;
				},
				modalFormConfig: {
					title: 'document.ordrsp.line',
					formMethods: {
						setFieldsOrder: function () {
							var values = this.getFormValues();
							var fields = this.getFormFields();

							values = calculateValues(
								values,
								{
									amount: values.AllocatedDelivered
								},
								true,
								true
							);
							fields.NetAmount ? fields.NetAmount.setValue(values.NetAmount) : null;
							fields.TaxAmount ? fields.TaxAmount.setValue(values.TaxAmount) : null;
							fields.GrossAmount ? fields.GrossAmount.setValue(values.GrossAmount) : null;
							fields.OrderedUnitNetPrice
								? fields.OrderedUnitNetPrice.setValue(values.OrderedUnitNetPrice)
								: null;
							fields.OrderedUnitGrossPrice
								? fields.OrderedUnitGrossPrice.setValue(values.OrderedUnitGrossPrice)
								: null;
						}
					},
					modalFields: [
						{
							title: edi.i18n.getMessage('line.item.tab.product'),
							customFieldTab: 'PRODUCT',
							layout: {
								type: 'grid',
								gap: 16,
								area: [5, [6, 3, 3], 12, [3, 3], 8]
							},
							items: [
								{
									title: edi.i18n.getMessage('line.item.item.type'),
									name: 'ItemType',
									type: 'combo',
									value: 'CU',
									store: edi.stores.initItemTypeStore()
								},
								{
									title: edi.i18n.getMessage('line.item.product.description'),
									maxLength: 512,
									name: 'ItemDescription'
								},
								{
									title: edi.i18n.getMessage('line.item.ean'),
									name: 'EAN',
									maskRe: /\d/i,
									validator: edi.methods.validators.ean
								},
								{
									title: edi.i18n.getMessage('line.item.model'),
									maxLength: 100,
									name: 'Model'
								},
								{
									type: 'fieldblock',
									cls: FIELD_BLOCK_CLS.small,
									title: 'line.item.goods.quantity',
									layout: {
										type: 'grid',
										area: [[3, 3, 3, 3]]
									},
									createCustomItems: function (_conf, src, { readOnly, disabled }) {
										return [
											createNumberField({
												fieldLabel: edi.i18n.getMessage('line.item.ordered.goods.quantity'),
												name: 'OrderedQuantity',
												type: 'number',
												allowDecimals: true,
												readOnly: true,
												maxLength: 10,
												decimalPrecision: costDecimals,
												valueSrc: src,
												disabled
											}),
											createNumberField({
												fieldLabel: edi.i18n.getMessage('line.item.confirmed.goods.quantity'),
												name: 'AllocatedDelivered',
												type: 'number',
												allowDecimals: true,
												maxLength: 10,
												decimalPrecision: costDecimals,
												valueSrc: src,
												disabled,
												listeners: {
													change: function () {
														_conf.setFieldsOrder();
													}
												}
											}),
											createOkeiField({
												fieldLabel: edi.i18n.getMessage('line.item.unit.of.measure'),
												valueSrc: src,
												disabled
											}),
											createNumberField({
												fieldLabel: edi.i18n.getMessage('line.item.volume'),
												vtype: 'numberN12_3',
												decimalPrecision: 3,
												allowDecimals: true,
												name: 'Volume',
												valueSrc: src,
												disabled
											})
										];
									}
								},
								{
									title: edi.i18n.getMessage('line.item.buyer.item.code'),
									maxLength: 35,
									name: 'BuyerItemCode'
								},
								{
									title: edi.i18n.getMessage('line.item.supplier.item.code'),
									maxLength: 175,
									name: 'SupplierItemCode'
								},
								{
									title: edi.i18n.getMessage('line.item.product.buyer_description'),
									maxLength: 100,
									name: 'BuyerItemDescription'
								}
							]
						},
						{
							title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
							customFieldTab: 'PRICES',
							layout: {
								type: 'grid',
								gap: 16,
								area: [3, [3, 3], 3, [3, 3, 3]]
							},
							items: [
								{
									title: edi.i18n.getMessage('organization.taxCalculationMethod'),
									readOnly: true,
									listeners: {
										afterrender: function (field) {
											let value = taxCalculationMethod
												? edi.i18n.getMessage(
														'organization.taxCalculationMethod.' + taxCalculationMethod
												  )
												: '';
											field.setValue ? field.setValue(value) : field.setText(value);
										}
									}
								},
								{
									title: edi.i18n.getMessage('line.item.ordered.unit.net.price'),
									name: 'OrderedUnitNetPrice',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals,
									maxLength: 12,
									listeners: {
										change(field, newVal, oldVal) {
											if (+newVal === +oldVal) {
												return;
											}
											taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.NET_PRICE ||
											taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.WITHOUT
												? this.setFieldsOrder()
												: null;
										}
									},
									validator: function (value) {
										var valid = true;
										var fields = this.getFormFields();
										if (fields.OrderedUnitGrossPrice) {
											if (!value && fields.OrderedUnitGrossPrice.getValue()) {
												valid = edi.i18n.getMessage('error.value.notvalid');
											}
										}
										return valid;
									}
								},
								{
									title: edi.i18n.getMessage('line.item.unit.gross.price'),
									name: 'OrderedUnitGrossPrice',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals,
									maxLength: 10,
									listeners: {
										change(field, newVal, oldVal) {
											if (+newVal === +oldVal) {
												return;
											}
											taxCalculationMethod === edi.constants.TAX_CALCULATION_TYPES.GROSS_PRICE
												? this.setFieldsOrder()
												: null;
										}
									},
									validator: function (value) {
										var valid = true;
										var fields = this.getFormFields();
										if (fields.OrderedUnitNetPrice) {
											if (
												(!value && fields.OrderedUnitNetPrice.getValue()) ||
												Number(value) < Number(fields.OrderedUnitNetPrice.getValue())
											) {
												valid = edi.i18n.getMessage('error.value.notvalid');
											}
										}
										return valid;
									}
								},
								{
									title: edi.i18n.getMessage('line.item.nds.rate'),
									name: 'TaxRate',
									type: 'combo',
									store: taxRatesStore,
									forceSelection: !isEdit,
									listeners: {
										change: function () {
											this.setFieldsOrder();
										}
									}
								},
								{
									title: edi.i18n.getMessage('line.item.net.amount'),
									name: 'NetAmount',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals,
									value: 0
								},
								{
									title: edi.i18n.getMessage('line.item.tax.amount'),
									name: 'TaxAmount',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals
								},
								{
									title: edi.i18n.getMessage('line.item.gross.amount'),
									name: 'GrossAmount',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals
								}
							]
						},
						{
							title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
							customFieldTab: 'PACKAGE_AND_DELIVERY',
							layout: {
								type: 'grid',
								gap: 16,
								area: [6, [4, 3], 4, [5, 4], 5]
							},
							items: [
								{
									title: edi.i18n.getMessage('delivery.terms'),
									name: 'TermsOfDelivery',
									type: 'combo',
									store: edi.stores.initTermsOfDeliveryStore()
								},
								{
									title: edi.i18n.getMessage('line.item.ordered.unit.packsize'),
									name: 'OrderedUnitPacksize',
									type: 'number',
									allowDecimals: true,
									decimalPrecision: costDecimals,
									maxLength: 12
								},
								{
									title: edi.i18n.getMessage('line.item.expiration.date'),
									name: 'ExpirationDate',
									type: 'date'
								},
								{
									title: edi.i18n.getMessage('line.item.tab.package.ExpectedDeliveryDate'),
									name: 'ExpectedDeliveryDate',
									type: 'date'
								},
								{
									type: 'fieldblock',
									cls: FIELD_BLOCK_CLS.small,
									title: 'line.item.tab.package.DeliveryDate',
									layout: {
										type: 'grid',
										area: [[7, 5]]
									},
									createCustomItems: function (_conf, src, { readOnly, disabled }) {
										return [
											createDateField({
												name: 'DeliveryDateDate',
												format: edi.constants.DATE_FORMAT.FNS,
												submitFormat: edi.constants.DATE_FORMAT.CLIENT,
												flex: 1,
												valueSrc: src,
												readOnly,
												disabled
											}),
											createTimeField({
												name: 'DeliveryDateTime',
												format: 'H:i',
												submitFormat: 'H:i',
												flex: 1,
												valueSrc: src,
												readOnly,
												disabled
											})
										];
									}
								},
								{
									title: edi.i18n.getMessage('period.of.delivery'),
									type: function (_conf, src) {
										return createRangeContainer({
											fieldFrom: createTimeField({
												name: 'DeliveryPeriodFrom',
												valueSrc: src,
												flex: 1,
												minWidth: 120
											}),
											fieldTo: createTimeField({
												name: 'DeliveryPeriodTo',
												valueSrc: src,
												flex: 1,
												minWidth: 120
											})
										});
									}
								},
								{
									type: 'fieldblock',
									cls: FIELD_BLOCK_CLS.small,
									title: 'line.item.tab.package.StockReserveDateTime',
									layout: {
										type: 'grid',
										area: [[7, 5]]
									},
									createCustomItems: function (_conf, src, { readOnly, disabled }) {
										return [
											createDateField({
												name: 'StockReserveDate',
												format: edi.constants.DATE_FORMAT.FNS,
												submitFormat: edi.constants.DATE_FORMAT.CLIENT,
												flex: 1,
												valueSrc: src,
												readOnly,
												disabled
											}),
											createTimeField({
												name: 'StockReserveTime',
												format: 'H:i',
												submitFormat: 'H:i',
												flex: 1,
												valueSrc: src,
												readOnly,
												disabled
											})
										];
									}
								},
								{
									type: 'fieldset',
									title: '',
									layout: {
										type: 'grid',
										area: [[8, 4]]
									},
									items: [
										{
											title: 'line.item.tab.package.address_text',
											isTextarea: true,
											rowsHtmlAttributeValue: 2,
											name: 'AddressText',
											maxLength: 1000
										},
										{
											title: 'line.item.tab.package.floor',
											name: 'Floor',
											maxLength: 35
										}
									]
								},
								{
									type: 'fieldset',
									title: '',
									layout: {
										type: 'grid',
										area: [[8, 4]]
									},
									items: [
										{
											title: 'mode.of.transport',
											name: 'ModeOfTransport',
											maxLength: 175
										},
										{
											title: 'line.item.tab.package.VehicleHeight',
											name: 'VehicleHeight',
											type: 'number',
											decimalPrecision: 3,
											allowDecimals: true,
											vtype: 'numberN12_3'
										}
									]
								},
								documentLinePartFields.ReasonCode
							]
						},
						{
							title: edi.i18n.getMessage('line.item.item.description.long'),
							customFieldTab: 'ADDITIONAL_DATA',
							layout: {
								type: 'grid',
								gap: 16,
								area: [6, 4]
							},
							items: [
								{
									title: edi.i18n.getMessage('column.reference.element.reference.id'),
									name: 'Line-Reference.Reference-Elements.Reference-Element.Reference-Id',
									requiredFields: [
										'Line-Reference.Reference-Elements.Reference-Element.Reference-Date'
									],
									maxLength: 150
								},
								{
									title: edi.i18n.getMessage('column.reference.element.reference.date'),
									name: 'Line-Reference.Reference-Elements.Reference-Element.Reference-Date',
									type: 'date'
								}
							]
						}
					]
				}
			});

			productsGrid.setPartnerId(buyerId);

			if (productsGrid.addButton) {
				productsGrid.addButton.setConfig('tooltip', edi.i18n.getMessage('form.btn.add.product'));
			}

			return productsGrid;
		};

		var createButton = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom', 'delivery'],
							[buyer, seller, delivery],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					save(values);
				}
			},
			isEdit
		);

		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP
				)
			)
		) {
			var createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom', 'delivery'],
							[buyer, seller, delivery],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					var values = edi.utils.collectFormValues(form);
					afterSave = function (documentId, callback) {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							Ext.encode({}),
							null,
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.in.sending.process'
							),
							callback
						);
					};
					save(values);
				},
				null,
				isEdit
			);
		}

		var lineTransport = edi.utils.getObjectProperty(
			document ? document : ordrsp,
			'DetailsOfTransport.Line-Transport',
			true
		);
		if (lineTransport.length) {
			deliveryCoordination = createDeliveryCoordination(lineTransport, {
				deliveryDate:
					edi.utils.getObjectProperty(
						document ? document : ordrsp,
						'OrderResponse-Header.ExpectedDeliveryDate'
					) || undefined,
				readOnly: false
			});
		}

		form = createFormForModule({
			cls: 'edi-form document-add-form',
			layout: {
				type: 'grid',
				gap: [24, 16],
				area: [12, 12, 12, 12, 12, [6, 6], 12, 12]
			},
			items: [
				createLabel({
					typography: 'heading_02',
					text: edi.i18n.getMessage(isEdit ? 'document.ordrsp.title' : 'document.add.ordrsp.title')
				}),
				createMainDataBlock(),
				createPartiesBlock(),
				createDeliveryInformationBlock(),
				createAdditionalInformationBlock(),
				ordrspRemarks,
				specialInstructions,
				deliveryCoordination,
				createCustomFieldsContainer(),
				createProductsGrid()
			],
			buttons: createButtonContainer({
				items: [createAndSendButton, createButton]
			})
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};
	/**
	 * Updates additional info fields
	 * @param    {Object}    organizationDocPartFields
	 */
	var updateDocPartFields = function (organizationDocPartFields) {
		var docHeader =
				organizationDocPartFields.find(function (item) {
					return item.docPart === 'docHeader';
				}) || {},
			docLines =
				organizationDocPartFields.find(function (item) {
					return item.docPart === 'docLines';
				}) || {},
			headerFields = edi.utils.getObjectProperty(docHeader, 'fields', true),
			container,
			productFields = edi.utils.getObjectProperty(docLines, 'fields', true),
			i,
			currentValue;

		var hasValue = function (values, value) {
			var i,
				found = false;
			for (i = 0; i < values.length; i++) {
				if (values[i].code == value) {
					found = true;
					break;
				}
			}
			return found;
		};
		var renderHeaderField = function (fValues, value) {
			var values = fValues.values;
			if (values.length) {
				if (value && values.length > 1) {
					value = hasValue(values, value) ? value : null;
				}
				if (1 === values.length) {
					value = values[0].code;
				}
			}
			documentPartFields[fValues.field] = values.length
				? createCombo({
						filedLabel: edi.i18n.getMessage(
							'org.document.LEGACY_ORDER_RESP.part.docHeader.' + fValues.field
						),
						name: 'OrderResponse-Header.' + fValues.field,
						store: edi.stores.createInlineStore(values, 'ORG_DOCUMENT_DATA_FIELD_LINE_VALUES'),
						value: value,
						displayField: 'value',
						valueField: 'code',
						anyMatch: true,
						allowBlank: !fValues.required,
						forceSelection: true
				  })
				: createTextField({
						filedLabel: edi.i18n.getMessage(
							'org.document.LEGACY_ORDER_RESP.part.docHeader.' + fValues.field
						),
						name: 'OrderResponse-Header.' + fValues.field,
						maxLength: 20,
						allowBlank: !fValues.required,
						value: value
				  });
			return documentPartFields[fValues.field];
		};
		var setProductFieldConfig = function (fValues) {
			var comboConf = {
					title: edi.i18n.getMessage('org.document.LEGACY_ORDER_RESP.part.docLines.' + fValues.field),
					type: 'combo',
					isHidden: false,
					name: fValues.field,
					displayField: 'value',
					valueField: 'code',
					anyMatch: true,
					forceSelection: true,
					allowBlank: !fValues.required,
					store: edi.stores.createInlineStore(fValues.values, 'ORG_DOCUMENT_DATA_FIELD_LINE_VALUES')
				},
				textConf = {
					title: edi.i18n.getMessage('org.document.LEGACY_ORDER_RESP.part.docLines.' + fValues.field),
					isHidden: false,
					maxLength: 20,
					type: 'text',
					name: fValues.field,
					allowBlank: !fValues.required
				},
				conf,
				i;
			if (documentLinePartFields[fValues.field]) {
				conf = fValues.values && fValues.values.length ? comboConf : textConf;
				for (i in documentLinePartFields[fValues.field]) {
					if (documentLinePartFields[fValues.field].hasOwnProperty(i) && !conf.hasOwnProperty(i)) {
						documentLinePartFields[fValues.field][i] = undefined;
						delete documentLinePartFields[fValues.field][i];
					}
				}
				for (i in conf) {
					if (conf.hasOwnProperty(i)) {
						documentLinePartFields[fValues.field][i] = conf[i];
					}
				}
			}
		};
		for (i in documentPartFieldContainers) {
			if (documentPartFieldContainers.hasOwnProperty(i)) {
				documentPartFieldContainers[i].setVisible(false);
			}
		}
		for (i = 0; i < headerFields.length; i++) {
			container = documentPartFieldContainers[headerFields[i].field + 'Container'];
			if (container) {
				if (documentPartFields[headerFields[i].field]) {
					currentValue = documentPartFields[headerFields[i].field].getInput().getValue();
					container.removeAll();
				} else {
					currentValue = documentPartFieldValues[headerFields[i].field];
				}
				container.add(renderHeaderField(headerFields[i], currentValue));
				container.setVisible(true);
			}
		}
		for (i in documentLinePartFields) {
			if (documentLinePartFields.hasOwnProperty(i)) {
				documentLinePartFields[i].isHidden = true;
			}
		}
		for (i = 0; i < productFields.length; i++) {
			setProductFieldConfig(productFields[i]);
		}
		form.isValid();
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var valid = false;
		var total = productValues.products.length;
		var validCompanies = validateCompanySelectors();
		var hasInvalid = form.hasInvalidField();
		var validGrid = productsGrid.isValid();
		if (total && !hasInvalid && validCompanies && validGrid) {
			valid = true;
		}
		return valid;
	};
	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isBuyerValid = buyer.isValid(),
			isSellerValid = seller.isValid(),
			isDeliveryValid = delivery.isValid();
		return isBuyerValid && isSellerValid && isDeliveryValid;
	};
	/**
	 * Saves document
	 * @param {Object}    formValues    values from form
	 */
	var save = function (formValues) {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = formValues || {};

		let clonedProductValues = Ext.clone(productsGrid.getValues());
		var customFieldsData = edi.methods.custom_fields.getCustomFieldsData(
			customFieldsObj,
			values,
			clonedProductValues.products,
			topPath
		);
		Object.values(customFieldsData).forEach(function (key) {
			delete values[key];
		});
		clonedProductValues.products.forEach(function (line) {
			Object.keys(line).forEach(function (key) {
				if (key.match(topPath)) {
					delete line[key];
				}
			});
		});
		for (var j = 0; j < clonedProductValues.products.length; j++) {
			var referenceElementArray = [];
			var lineReference = edi.utils.getObjectProperty(
				clonedProductValues.products[j],
				'Line-Reference.Reference-Elements.Reference-Element',
				true
			);
			lineReference.forEach(function (item) {
				edi.utils.setObjectProperty(item, 'Reference-Type', 'spc');
				referenceElementArray.push(item);
			});
			edi.utils.setObjectProperty(
				clonedProductValues.products[j],
				'Line-Reference.Reference-Elements.Reference-Element',
				referenceElementArray
			);
		}

		var document = createOrderResponse({
			DetailsOfTransport: {
				'Line-Transport': deliveryCoordination ? edi.utils.getDataFromGrid(deliveryCoordination.grid) : null,
				TermsOfDelivery: edi.utils.getObjectProperty(values, 'DetailsOfTransport.TermsOfDelivery')
					? edi.utils.getObjectProperty(values, 'DetailsOfTransport.TermsOfDelivery')
					: null
			},
			'OrderResponse-Header': values['OrderResponse-Header'],
			parties: {
				Buyer: values['Buyer'],
				Seller: values['Seller'],
				ShipFrom: values['ShipFrom'],
				DeliveryPoint: values['DeliveryPoint']
			},
			productValues: clonedProductValues
		});
		document = edi.document.actions.mergeDataBeforeSave(initialData, document, [
			{
				path: 'OrderResponse-Lines.Line'
			}
		]);
		edi.utils.clearEmptyValues(document);

		var stringified = Ext.encode(document);

		var success = function (responseData) {
			var finishSaving = function () {
				moduleData.isChanged = false;
				moduleData.tab.setLoading(false);
				edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit);
				if (id) {
					edi.events.documents.fireEvent('change', {
						id: id
					});
				} else {
					edi.events.documents.fireEvent('create');
				}
			};
			const documentId = isEdit && id ? id : responseData.data.id;
			const isWarning = edi.utils.getAttributeByName(responseData.data?.attributes, 'isWarning') === 'true';
			const isBlocked = edi.utils.getAttributeByName(responseData.data?.attributes, 'isBlocked') === 'true';
			if (!isWarning && !isBlocked && afterSave && documentId) {
				afterSave(documentId, function () {
					edi.document.actions.getDocumentHeaderData(documentId, function (headerData) {
						headerData ? (responseData.data = headerData) : null;
						finishSaving();
					});
				});
			} else {
				finishSaving();
			}
		};

		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP
		);

		var docData = {
			data: stringified,
			number: edi.utils.getObjectProperty(values, 'OrderResponse-Header.OrderResponseNumber'),
			date: edi.utils.getObjectProperty(values, 'OrderResponse-Header.OrderResponseDate')
		};
		if (customFieldsData && Object.keys(customFieldsData).length > 0) {
			docData.customFields = customFieldsData;
		}

		edi.document.actions.processDocument(
			buyerId,
			undefined,
			edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
			parentId,
			isEdit ? id : undefined,
			success,
			failure,
			docData
		);
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Validates custom fields in grid's records
	 */
	let validateGridRowsWithCustomFields = function () {
		let records = productsGrid.productsGrid.getStore().getRange();
		records.forEach((rec) => {
			//проверять будем только валидные, т.к. невалидные все равно надо открыть и исправить
			if (rec.isExternalValid === true) {
				rec.isExternalValid = edi.methods.custom_fields.checkGridRow(customFieldsObj, rec.data);
			}
		});
		productsGrid.productsGrid.getView().refresh();
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();

		var createModulePanel = function (docData) {
			var form = createModuleForm(docData);
			modulePanel.add(form);

			//moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);

			if ((isCreateFromOrder || isEdit) && productValues.products.length) {
				if (productValues.products.length) {
					edi.methods.taxRates.showInvalidProductsWarnIfNeeded(productValues.products, availableTaxRates, {
						allowBlankRate: true
					});
				}
			}
			if ('function' == typeof initCallBack) {
				initCallBack();
			}
			if (buyer.selectedOrg || buyerId) {
				edi.document.actions.getOrgDocumentPartFields(
					buyer.selectedOrg ? buyer.selectedOrg.id : buyerId,
					edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
					updateDocPartFields
				);
			}
		};

		let initializeCustomFields = function () {
			let toOrgId = data?.toOrg?.id || data?.parties?.fromOrg?.id; //стоороны в parties меняются, т.к. создаем на основании
			let fromOrgId = data?.fromOrg?.id || data?.parties?.toOrg?.id; //стоороны в parties меняются, т.к. создаем на основании
			if (toOrgId && fromOrgId) {
				edi.methods.custom_fields.initCustomFields({
					customFieldsObj,
					initialCustomFieldsData: customFieldsFromTransformation,
					docType: edi.constants.DOCUMENT_TYPES.LEGACY_ORDER_RESP,
					toOrgId,
					fromOrgId,
					docId: data?.id,
					container: customFieldsContainer,
					grid: productsGrid,
					topPath: topPath,
					finishCallback(obj) {
						customFieldsObj = obj;
						form.isValid();
						validateGridRowsWithCustomFields();
						checkValid();
					},
					fail() {
						form.isValid();
						validateGridRowsWithCustomFields();
						checkValid();
					}
				});
			}
		};

		if (data && data.id) {
			id = data.id;
			isCopy = !!moduleData.initData.isCopy;
			isEdit = !isCopy;
			buyerId = data.toOrg.id;
			buyerCountry = edi.utils.getOrg({ orgId: data.toOrg.id })?.country;
			taxCalculationMethod = edi.utils.getTaxCalculationMethod(data.toOrg.attributes);
			var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
				edi.modulesHandler.removeModule(moduleData);
			});
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (resp) {
					if (resp && resp.data) {
						initialData = resp.data;
						createModulePanel(resp.data);
						initializeCustomFields();
					} else {
						failure(resp);
					}
				},
				failure
			);
		} else {
			createModulePanel();
			initializeCustomFields();
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
