import { createCompanySelector } from '@Components/company.selector.js';
import { createEditableProductsTreeGrid } from '@Components/product.tree.grid.editable';
import {
	createActionsPanel,
	createDetailsModulePanel,
	createPanel,
	createTab,
	createTabPanel,
	TAB_PANEL_CLS
} from '@Components/panels';
import { createField, createFieldSet, createLabel, createNumberField } from '@Components/fields';
import { createContainer, createTwoColumnsLayout } from '@Components/miscComponents';
import { createActionsButton } from '@Components/buttons';
import { createEditableGrid } from '@Core/specialComponents/editableGrid';
import { createUKDLineModalForm } from '@Fns/specialComponents/ukdLineModalForm';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';

/**
 * Class for fns_invoice details
 * @author Vsevolod Liapin
 */
Ext.namespace('edi.modules');
edi.modules['document.details.fns_ukd'] = function () {
	var moduleData,
		part1,
		part2,
		part1Head,
		part2Head,
		bpName,
		utochReasonText = null,
		annulDoc,
		annulStatus,
		signer,
		positionsStore = edi.stores.initPositionsStore(),
		areaStore = edi.stores.initAuthorityAreasStore(),
		signerStatusesStore = edi.stores.initSignerStatusesStore(),
		signerBuyerStatusesStore = edi.stores.initSignerBuyerStatusesStore(),
		ukdRoamingStatus6,
		isVersion2,
		infoColumnsConfig,
		rejectReasonText,
		rejectReasonTextValue;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;

		let versionId =
			edi.utils.getObjectProperty(moduleData, 'initData.meta.versionId') ||
			edi.utils.getObjectProperty(moduleData, 'initData.data.versionId');
		if (versionId) {
			isVersion2 = versionId === '5.02';
		} else {
			isVersion2 =
				edi.utils.getObjectProperty(moduleData, 'initData.fns_upd.formVersion') === '5.02' ||
				edi.utils.getObjectProperty(moduleData, 'initData.data.versionId') === '5.02';
		}

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
		edi.events.documents.on('sign', signHandler);
	};

	var getPositionShortName = function (value) {
		var target = positionsStore.findRecord('id', value, 0, false, false, true);
		return target ? target.get('shortName') : value;
	};

	var createProductsGrid = function () {
		var lines = edi.utils.getObjectProperty(part1, 'document.cinvoiceTable'),
			productValues = edi.converters.convertUKDProductToUKDGridValues(lines, undefined, '');

		var gridTotalData = {
			Increase: productValues.totalIncrease,
			Decrease: productValues.totalDecrease
		};

		if (infoColumnsConfig) {
			var renderer = function (value, metaData, record) {
				var arrInf = record.get('InfPolStr');
				if (Array.isArray(arrInf) && arrInf.length) {
					var i, elm;
					for (i = 0; i < arrInf.length; i++) {
						elm = arrInf[i];
						if (elm.Identif && elm.Identif === metaData.column.infoId) {
							return elm.Znachen;
						}
					}
				}
				return '';
			};
			var newConfig = edi.methods.createInfoColumns(infoColumnsConfig, renderer);

			var valuesColumnConfigurator = function (config) {
				Ext.merge(config, newConfig);
				return config;
			};
			var columnsRegen = edi.columns.get(
				'corrected_fns_invoice_lines' + (isVersion2 ? '_5_02' : ''),
				function (config) {
					return valuesColumnConfigurator(config);
				}
			);
		}

		var grid = createEditableProductsTreeGrid({
			title: edi.i18n.getMessage('document.upd.lines'),
			totalsGridHeaderLabel: 'grid.title.total',
			gridModel: 'FNS_UKD_PRODUCT',
			lineModel: 'FNS_UKD_PRODUCT',
			gridColumnConfig: infoColumnsConfig
				? columnsRegen
				: 'corrected_fns_invoice_lines' + (isVersion2 ? '_5_02' : ''),
			totalModel: 'FNS_UKD_SUMMARY',
			margin: '20 0 20 0',
			readOnly: true,
			totalColumnConfig: 'corrected_fns_invoice_summary',
			totalData: gridTotalData,
			lineNumberFieldName: 'lineNum',
			isValidRecord: function (record) {
				var state = record.get('State');
				return state === 'Increase' || state === 'Decrease' || record.get('Count') >= 0;
			},
			productDetailsHandler: function (record) {
				var infoGrid,
					lineModalFormConfig = [
						createField({
							title: edi.i18n.getMessage('line.item.additional.corAccDebet'),
							input: createNumberField({
								minLength: 9,
								maxLength: 9,
								columnWidth: 0.7,
								name: 'corAccDebet',
								value: record.get('corAccDebet'),
								readOnly: true
							})
						}),
						createField({
							title: edi.i18n.getMessage('line.item.additional.corAccCredit'),
							input: createNumberField({
								minLength: 9,
								maxLength: 9,
								columnWidth: 0.7,
								name: 'corAccCredit',
								value: record.get('corAccCredit'),
								readOnly: true
							})
						})
					];

				var tabPanel = createTabPanel({
					cls: TAB_PANEL_CLS.simpleWithoutPadding,
					items: [
						createTab({
							title: edi.i18n.getMessage('line.item.tab.additional.data'),
							closable: false,
							bodyPadding: 10,
							autoScroll: true,
							items: lineModalFormConfig
						}),
						createTab({
							title: edi.i18n.getMessage('line.item.tab.inf.pol.str'),
							closable: false,
							layout: 'fit',
							items: [
								(infoGrid = createEditableGrid(
									{
										fields: [
											{
												name: 'Identif',
												type: 'text'
											},
											{
												name: 'Znachen',
												type: 'text'
											}
										],
										limit: 20,
										readOnly: true,
										model: 'FIELD_LINE_INFORMATION',
										gridConfig: {
											cls: 'modal-grid',
											autoScroll: true,
											title: edi.i18n.getMessage('line.item.tab.inf.pol.str')
										}
									},
									record.get('InfPolStr')
								))
							]
						})
					]
				});
				var dataFormGridConfig = {
					InfPolStr: infoGrid
				};
				createUKDLineModalForm(tabPanel, record, dataFormGridConfig, false);
			}
		});

		if (productValues.products.length) {
			for (var i = 0; i < productValues.products.length; i++) {
				var product = productValues.products[i];
				if (product && product.OriginalData && product.NewData && product.OriginalData.InfPolStr) {
					product.NewData.InfPolStr = product.OriginalData.InfPolStr;
				}
				grid.createPallet(product, false);
			}
		}
		return grid;
	};

	/**
	 * Creates details panel of upd
	 * @returns {Object}
	 */
	var createDetailsPanel = function () {
		var mainData = Ext.clone(moduleData.initData.data);
		mainData.modifyDate = part2Head ? part2Head.modifyDate : part1Head.modifyDate;
		mainData.countSignatures =
			(part2Head ? part2Head.countSignatures : 0) + (part1Head ? part1Head.countSignatures : 0);
		mainData.ids = [part1Head.id];
		var aperakSellerId = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'APERAK_CRPT_ID_SELLER'),
			aperakBuyerId = edi.utils.getAttributeByName(moduleData.initData.data.attributes, 'APERAK_CRPT_ID_BUYER');

		if (part2Head) {
			mainData.ids.push(part2Head.id);
		}
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			objectData: mainData,
			utochReasonText: utochReasonText,
			rejectReasonText: rejectReasonText,
			summTitle: 'document.ukd.summ',
			isReceipts: !(moduleData.initData && moduleData.initData.isDocumentSharing),
			usingReport: true,
			isCrptStatus: true,
			showCRPTPrintBtn: aperakSellerId || aperakBuyerId,
			defaultFormat: edi.constants.REPORT_FORMATS.XLS,
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing),
			costDecimal: edi.constants.UKD_PRICE_PRECISION
		});
		var headData = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.utils.getObjectProperty(part1, 'document.function'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(
							part1,
							'document.invoiceCorrectionInfo.invoiceCorrectionNumber'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createLabel({
					margin: '0 0 0 5',
					columnWidth: 0.25,
					text: edi.utils.getObjectProperty(part1, 'document.invoiceCorrectionInfo.invoiceCorrectionDate'),
					valueLabel: true
				})
			]
		});

		var correctUkdField = edi.utils.getObjectProperty(
			part1,
			'document.invoiceCorrectionInfo.editedInvoiceCorrectionInfo'
		)
			? createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					margin: '0 0 5',
					items: [
						createField({
							title: edi.i18n.getMessage('documents.fns_ukd.editedInvoiceCorrectionInfo'),
							input: createLabel({
								columnWidth: 0.7333,
								text: edi.utils.getObjectProperty(
									part1,
									'document.invoiceCorrectionInfo.editedInvoiceCorrectionInfo.numberEditedInvoiceCorrectionInfo'
								),
								valueLabel: true
							}),
							containerConfig: {
								columnWidth: 0.75
							}
						}),
						createLabel({
							columnWidth: 0.25,
							margin: '0 0 0 5',
							text: edi.utils.getObjectProperty(
								part1,
								'document.invoiceCorrectionInfo.editedInvoiceCorrectionInfo.dateEditedInvoiceCorrectionInfo'
							),
							valueLabel: true
						})
					]
			  })
			: null;

		var invoiceInfoData = edi.utils.getObjectProperty(part1, 'document.invoiceCorrectionInfo.invoiceInfo') || [];
		var invoiceInfoToUpd = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			margin: '0 0 5',
			items: [
				createField({
					title: edi.i18n.getMessage('documents.fns_ukd.invoiceInfoToUpd'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(invoiceInfoData[0], 'number'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createLabel({
					columnWidth: 0.25,
					margin: '0 0 0 5',
					text: edi.utils.getObjectProperty(invoiceInfoData[0], 'date'),
					valueLabel: true
				})
			]
		});

		var toCorrectedUpd = function (correctInfo) {
			var items = [];
			correctInfo = edi.utils.getObjectProperty(correctInfo[0], 'invoicesCorrectInfo');
			if (correctInfo && Array.isArray(correctInfo) && correctInfo.length > 0) {
				for (var i = 0; i < correctInfo.length; i++) {
					items.push(
						createPanel({
							layout: 'column',
							cls: 'edi-create-field-line',
							margin: '0 0 5',
							items: [
								createField({
									title: edi.i18n.getMessage('documents.fns_ukd.invoicesCorrectInfo'),
									input: createLabel({
										columnWidth: 0.7333,
										text: edi.utils.getObjectProperty(correctInfo[i], 'number'),
										valueLabel: true
									}),
									containerConfig: {
										columnWidth: 0.75
									}
								}),
								createLabel({
									columnWidth: 0.25,
									margin: '0 0 0 5',
									text: edi.utils.getObjectProperty(correctInfo[i], 'date'),
									valueLabel: true
								})
							]
						})
					);
				}
			}
			return items;
		};

		var currency = edi.utils.getObjectProperty(part1, 'document.invoiceCorrectionInfo.okv');
		if (currency) {
			var curObj = edi.utils.getOkv({
				code: currency
			});
			currency = curObj.charCode + ' - ' + curObj.codeStr + ' - ' + curObj.name;
		}
		var invoiceCurrency = createPanel({
			layout: 'column',
			margin: '0 0 5',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.i18n.getMessage('invoice.currency'),
					input: createLabel({
						columnWidth: 0.6,
						text: currency,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				})
			]
		});

		var createCS = function (selectorConfig) {
			Ext.merge(selectorConfig, {
				is_valid: true,
				readOnly: true,
				margin: '5 0 10',
				valuesByMap: true,
				fieldValues: part1
			});

			return createCompanySelector(selectorConfig);
		};

		var seller = createCS({
			title: 'document.seller',
			fieldsMap: edi.selectors.getUPDFieldsMap('document.invoiceCorrectionInfo.sellerInfo', true)
		});

		var buyer = createCS({
			title: 'document.buyer',
			fieldsMap: edi.selectors.getUPDFieldsMap('document.invoiceCorrectionInfo.buyerInfo', true)
		});

		var receiveTitleSection = null,
			receiveDate = null,
			authorityArea = null,
			baseAuthory = null;
		if (part2) {
			signer = edi.utils.getObjectProperty(part2, 'buyerInfo.signers')[0];
			var legalEntityData = edi.utils.getObjectProperty(signer, 'legalEntity');
			var individualPersonData =
				edi.utils.getObjectProperty(signer, 'individualPerson') ||
				edi.utils.getObjectProperty(signer, 'individual') ||
				edi.utils.getObjectProperty(signer, 'individualInfo');
			receiveTitleSection = createPanel({
				layout: 'column',
				margin: '10 0 5',
				bodyPadding: '5 0 0',
				title: edi.i18n.getMessage('document.fns_upd.secondary.data'),
				cls: 'edi-create-field-line section-part2',
				items: [
					!individualPersonData
						? createField({
								title: edi.i18n.getMessage('documents.create.torg.title.receive.description'),
								input: createLabel({
									columnWidth: 0.5,
									text: getPositionShortName(
										edi.utils.getObjectProperty(legalEntityData || individualPersonData, 'position')
									),
									valueLabel: true
								}),
								containerConfig: {
									columnWidth: 0.25
								}
						  })
						: createLabel({
								columnWidth: 0.125,
								text: edi.i18n.getMessage('documents.create.torg.title.receive.description')
						  }),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15 + !!individualPersonData * 0.125,
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'fullName.lastName'),
						valueLabel: true
					}),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15,
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.firstName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.firstName') ||
							edi.utils.getObjectProperty(individualPersonData, 'fullName.firstName'),
						valueLabel: true
					}),
					createLabel({
						margin: '0 0 0 5',
						columnWidth: 0.15,
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.middleName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.middleName') ||
							edi.utils.getObjectProperty(individualPersonData, 'fullName.middleName'),
						valueLabel: true
					})
				]
			});
			receiveDate = createPanel({
				layout: 'column',
				margin: '10 0 5',
				cls: 'edi-create-field-line section-part2',
				items: [
					createField({
						title: edi.i18n.getMessage('upd.receive.date'),
						input: createLabel({
							columnWidth: 0.5,
							text: edi.utils.getObjectProperty(
								part2,
								'buyerInfo.contentFactsEconomicLife.agreementInfo.dateAgreement'
							),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 0.25
						}
					})
				]
			});

			var authArea = areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(signer, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = signerBuyerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(signer, 'state'),
					0,
					false,
					false,
					true
				);

			authorityArea = createPanel({
				layout: 'column',
				margin: '10 0 5',
				cls: 'edi-create-field-line section-part2',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_upd.signer.authorityArea'),
						input: createLabel({
							columnWidth: 0.687,
							valueLabel: true,
							cls: 'field-signer-authorityArea',
							text: authArea ? authArea.get('name') : edi.utils.getObjectProperty(signer, 'authorityArea')
						}),
						containerConfig: {
							columnWidth: 0.4
						}
					}),
					createLabel({
						margin: '0 0 0 5',
						cls: 'field-signer-state',
						text: signerState ? signerState.get('name') : edi.utils.getObjectProperty(signer, 'state'),
						columnWidth: 0.3,
						valueLabel: true
					})
				]
			});

			baseAuthory = createPanel({
				layout: 'column',
				margin: '10 0 5',
				cls: 'edi-create-field-line section-part2',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
						input: createLabel({
							columnWidth: 0.687,
							valueLabel: true,
							cls: 'field-signer-baseAuthory',
							text: edi.utils.getObjectProperty(signer, 'baseAuthory')
						}),
						containerConfig: {
							columnWidth: 0.4
						}
					}),
					createLabel({
						columnWidth: 0.3,
						valueLabel: true,
						margin: '0 0 0 5',
						cls: 'field-signer-baseOrgAuthory',
						text: edi.utils.getObjectProperty(signer, 'baseOrgAuthory')
					})
				]
			});
		}

		var productsGrid = createProductsGrid();

		var fields = [headData, correctUkdField, invoiceInfoToUpd];
		fields = fields.concat(
			toCorrectedUpd(edi.utils.getObjectProperty(part1, 'document.invoiceCorrectionInfo.invoiceInfo')),
			invoiceCurrency,
			seller,
			buyer,
			createInvoiceTextInfosFieldSet(),
			createBaseShipmentOfGoodsFieldSet()
		);
		var container = createContainer({
			layout: 'anchor',
			defaults: {
				anchor: '100%'
			},
			items: fields
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [
				headPanel,
				container,
				productsGrid.wrapper,
				createSignerItems(),
				receiveTitleSection,
				receiveDate,
				authorityArea,
				baseAuthory
			]
		});
	};

	var createInvoiceTextInfosFieldSet = function () {
		return createFieldSet({
			title: edi.i18n.getMessage('documents.fns_ukd.special_identifiers'),
			collapsible: false,
			margin: '10 0',
			padding: 0,
			items: [createTwoColumnsLayout(createInvoiceTextInfosField(), [], 1)]
		});
	};

	var createInvoiceTextInfosField = function () {
		var items = [];
		var fieldData = edi.utils.getObjectProperty(part1, 'document.invoiceCorrectionInfo.info.textInfos');
		for (var i = 0; i < fieldData.length; i++) {
			items.push(
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					margin: '5 0 5 5',
					items: [
						createField({
							title: edi.utils.getObjectProperty(fieldData[i], 'identifier'),
							input: createLabel({
								columnWidth: 0.8,
								text: edi.utils.getObjectProperty(fieldData[i], 'value'),
								valueLabel: true
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				})
			);
		}

		return items;
	};

	var createBaseShipmentOfGoodsFieldSet = function () {
		return createFieldSet({
			title: edi.i18n.getMessage('documents.fns_ukd.informationOnCoordination'),
			collapsible: false,
			margin: '10 0',
			padding: 0,
			items: [createTwoColumnsLayout(createBaseOfCorrectionsFields(), [], 1)]
		});
	};

	var createBaseOfCorrectionsFields = function () {
		var items = [];
		var fieldData = edi.utils.getObjectProperty(part1, 'document.informationOnCoordination.baseOfCorrections');
		for (var i = 0; i < fieldData.length; i++) {
			items.push(
				createPanel({
					layout: 'column',
					margin: '5 0 5 5',
					padding: 0,
					cls: 'edi-create-field-line',
					items: [
						createField({
							title: edi.i18n.getMessage('documents.fns_ukd.baseOfCorrections.name'),
							input: createLabel({
								columnWidth: 0.5,
								text: edi.utils.getObjectProperty(fieldData[i], 'name'),
								valueLabel: true
							}),
							containerConfig: {
								columnWidth: 0.4
							}
						}),
						createLabel({
							margin: '0 0 0 5',
							columnWidth: 0.12,
							text: edi.utils.getObjectProperty(fieldData[i], 'number'),
							valueLabel: true
						}),
						createLabel({
							margin: '0 0 0 5',
							columnWidth: 0.1,
							text: edi.utils.getObjectProperty(fieldData[i], 'date'),
							valueLabel: true
						}),
						createLabel({
							margin: '0 0 0 5',
							columnWidth: 0.38,
							text: edi.utils.getObjectProperty(fieldData[i], 'info'),
							valueLabel: true
						})
					]
				})
			);
		}

		items.push(
			createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				margin: '5 0 5 5',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_ukd.transferDoc'),
						input: createLabel({
							columnWidth: 0.7333,
							text: edi.utils.getObjectProperty(part1, 'document.informationOnCoordination.transferDoc'),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 0.75
						}
					}),
					createLabel({
						columnWidth: 0.25,
						margin: '0 0 0 5',
						text: edi.utils.getObjectProperty(part1, 'document.informationOnCoordination.transmitDate'),
						valueLabel: true
					})
				]
			})
		);

		items.push(
			createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				margin: '5 0 5 5',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_ukd.operContent'),
						input: createLabel({
							columnWidth: 0.8,
							text: edi.utils.getObjectProperty(part1, 'document.informationOnCoordination.operContent'),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 1
						}
					})
				]
			})
		);

		return items;
	};

	var createSignerItems = function () {
		var fieldData = edi.utils.getObjectProperty(part1, 'document.signers');
		if (!Array.isArray(fieldData)) {
			fieldData = [fieldData];
		}

		var createFields = function (fieldData) {
			var legalEntityData = edi.utils.getObjectProperty(fieldData, 'legalEntity');
			var individualPersonData =
				edi.utils.getObjectProperty(fieldData, 'individualPerson') ||
				edi.utils.getObjectProperty(fieldData, 'individual');
			var signerSection = createPanel({
				layout: 'column',
				margin: '10 10 5',
				cls: 'edi-create-field-line',
				items: [
					legalEntityData
						? createLabel({
								columnWidth: 0.3,
								margin: '0 0 0 5',
								cls: 'field-signer-position',
								text: getPositionShortName(edi.utils.getObjectProperty(legalEntityData, 'position')),
								valueLabel: true
						  })
						: null,
					createLabel({
						columnWidth: 0.2 + !!individualPersonData * 0.1,
						margin: '0 0 0 5',
						cls: 'field-signer-firstName',
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.firstName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.firstName') ||
							edi.utils.getObjectProperty(individualPersonData, 'nameType.firstName'),
						valueLabel: true
					}),
					createLabel({
						columnWidth: 0.3 + !!individualPersonData * 0.1,
						margin: '0 0 0 5',
						cls: 'field-signer-lastName',
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'nameType.lastName'),
						valueLabel: true
					}),
					createLabel({
						columnWidth: 0.2 + !!individualPersonData * 0.1,
						margin: '0 0 0 5',
						cls: 'field-signer-middleName',
						text:
							edi.utils.getObjectProperty(legalEntityData, 'fullName.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'name.lastName') ||
							edi.utils.getObjectProperty(individualPersonData, 'nameType.lastName'),
						valueLabel: true
					})
				]
			});

			var authArea = areaStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'authorityArea'),
					0,
					false,
					false,
					true
				),
				signerState = signerStatusesStore.findRecord(
					'id',
					edi.utils.getObjectProperty(fieldData, 'state'),
					0,
					false,
					false,
					true
				);

			var authorityAreaAndStatus = createPanel({
				layout: 'column',
				margin: 10,
				cls: 'edi-create-field-line',
				items: [
					createLabel({
						columnWidth: 0.6,
						cls: 'field-signer-authorityArea',
						text: authArea ? authArea.get('name') : edi.utils.getObjectProperty(signer, 'authorityArea'),
						valueLabel: true
					}),
					createLabel({
						columnWidth: 0.4,
						margin: '0 0 0 5',
						cls: 'field-signer-state',
						text: signerState ? signerState.get('name') : edi.utils.getObjectProperty(signer, 'state'),
						valueLabel: true
					})
				]
			});

			var baseAuthory = createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseAuthory'),
						input: createLabel({
							columnWidth: 0.7,
							cls: 'field-signer-baseAuthory',
							text: edi.utils.getObjectProperty(fieldData, 'baseAuthory'),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 1
						}
					})
				]
			});

			var baseOrgAuthory = createPanel({
				layout: 'column',
				cls: 'edi-create-field-line',
				items: [
					createField({
						title: edi.i18n.getMessage('documents.fns_upd.signer.baseOrgAuthory'),
						input: createLabel({
							columnWidth: 0.7,
							cls: 'field-signer-baseOrgAuthory',
							text: edi.utils.getObjectProperty(fieldData, 'baseOrgAuthory'),
							valueLabel: true
						}),
						containerConfig: {
							columnWidth: 1
						}
					})
				]
			});

			return [signerSection, authorityAreaAndStatus, baseAuthory, baseOrgAuthory];
		};

		var signerFields = [];
		for (var i = 0; i < fieldData.length; i++) {
			signerFields = signerFields.concat(createFields(fieldData[i]));
		}
		return createTwoColumnsLayout(signerFields, [], 0.625);
	};

	/**
	 * Creates action pane
	 */
	var createModuleActionsPanel = function () {
		var data = moduleData.initData.data;

		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg),
			isOutGoingDocument = edi.constants.DIRECTIONS.OUTGOING === direction;
		var hasPart2 = !!edi.utils.getAttributeByName(data.attributes, 'hasPart2') || !!part2Head;
		var totalSignaturesCount = edi.document.actions.getSignCount(data);

		var neededSignaturesCount = isOutGoingDocument
			? totalSignaturesCount - part1Head.countSignatures
			: totalSignaturesCount - (hasPart2 && part2Head ? part2Head.countSignatures : 0);
		var actionsPanel = createActionsPanel(),
			editCmp = null;

		var readCmp = null;
		if (edi.constants.DIRECTIONS.INCOMING === direction) {
			readCmp = createActionsButton({
				text: edi.i18n.getMessage('document.mark.read'),
				glyph: edi.constants.ICONS.READ,
				handler: function () {
					edi.core.confirm(
						edi.i18n.getMessage('document.mark.read'),
						edi.i18n.getMessage('document.mark.read.question'),
						function () {
							moduleData.tab.setLoading();
							var success = function () {
								moduleData.tab.setLoading(false);
								edi.events.documents.fireEvent('change', {
									id: data.id
								});
							};
							var failure = edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.mark.read'
							);
							var markRead = function () {
								var postData = {};
								postData[edi.constants.BUSINESS_PROCESS_PROPERTIES.TASK_ID] = edi.constants.STATE.SENT;
								edi.rest.sendRequest(
									edi.utils.formatString(
										edi.rest.services.DOCUMENTS.SEND.PUT,
										{
											documentId: data.id
										},
										true
									),
									'PUT',
									Ext.encode(postData),
									success,
									failure
								);
							};
							if (ukdRoamingStatus6) {
								edi.utils.sign(
									ukdRoamingStatus6,
									moduleData.tab,
									function (failed) {
										if (failed) {
											failure();
										} else {
											markRead();
										}
									},
									undefined,
									undefined,
									true,
									null,
									{
										signRefuse: () => moduleData.tab?.setLoading(false)
									}
								);
							} else {
								markRead();
							}
						}
					);
				}
			});
		}

		var excludeActions = {};
		if (
			edi.constants.DIRECTIONS.INCOMING === direction &&
			bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.SCHFDOP_KSCHF
		) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.READ] = true;
		}
		if (!editCmp) {
			excludeActions[edi.constants.DOCUMENT_ACTIONS.EDIT] = true;
		}

		const customButtons = [];
		if (!annulStatus || annulStatus !== edi.constants.STATE.ACTIVE) {
			let checkOptions = edi.action.getDocumentData(edi.models.createInstance('DOCUMENT', data));
			if (
				edi.action.isAvailable('CREATE_CUD', checkOptions) &&
				edi.permissions.hasPermission('CREATE_EDI_FNS_UKD')
			) {
				let actionCreateCUD = edi.document.actions.createConfigForDocumentCreateButton(
					'action.cukd',
					edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
					undefined,
					undefined,
					{ id: part1Head?.id },
					moduleData,
					{
						isFromTransformation: true,
						postData: {
							docType: 'EDI_FNS_UKD_P1',
							docFlowType: 'CORRECTION_UKD',
							version: '5.01-N'
						},
						addData: {
							meta: Ext.clone(data),
							versionId: '5.01-N',
							isCorrect: true
						}
					}
				);

				customButtons.push(
					createActionsButton({
						showInFirstHalf: true,
						order: 500,
						text: edi.i18n.getMessage('action.create'),
						glyph: edi.constants.ICONS.ADD,
						allowMultiClick: true,
						menu: {
							plain: true,
							hideMode: 'display',
							items: [actionCreateCUD]
						}
					})
				);
			}
		}

		var actionProps = {
			SIGN: {
				methodAddOptions: {
					contentId:
						edi.constants.DIRECTIONS.OUTGOING === direction || edi.constants.DIRECTIONS.LOOP === direction
							? part1Head.id
							: part2Head
							? part2Head.id
							: null,
					useBeforeAction:
						data.state === edi.constants.STATE.DRAFT ||
						data.state === edi.constants.STATE.READ ||
						data.state === edi.constants.STATE.READ
				}
			},
			EDIT: {
				component: editCmp
			},
			READ: {
				component: readCmp
			},
			REFRESH: {
				handler: function () {
					changeHandler(data);
				}
			},
			ANNUL: {
				methodAddOptions: annulDoc
			},
			EXPORT: {
				label: edi.i18n.getMessage('action.export.document'),
				exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
				exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE_WITH_SIGN, {
					documentId: data.id
				}),
				extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
				extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON_ALL_DOCUMENT, {
					documentId: data.id
				}),
				addExtendedExport: true,
				xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
				addXmlExport: true
			},
			CUSTOM_BUTTONS: {
				buttons: customButtons
			}
		};

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: neededSignaturesCount,
			hasPart2: hasPart2,
			excludeActions: excludeActions,
			actionProps: actionProps
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		if (
			data &&
			data.id &&
			(data.id == moduleData.initData.data.id ||
				(part1Head && data.id == part1Head.id) ||
				(part2Head && data.id == part2Head.id))
		) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				moduleData.tab.removeAll();
				renderData();
			}
		}
	};
	var signHandler = function (data) {
		if (data && data.id && data.id == moduleData.initData.data.id) {
			var identifiers = ['cis', 'cis_до', 'sscc', 'sscc_до'];
			var markNotification =
					edi.utils.getObjectProperty(edi.core.getUserData(), 'org.attributes.markNotification.value') ===
					'true',
				productInfo = edi.utils.getObjectProperty(part1, 'document.cinvoiceTable.productInfo'),
				condition = false;
			if (markNotification) {
				if (!Ext.isEmpty(productInfo)) {
					productInfo?.forEach(function (product) {
						product.textInfos?.forEach(function (item) {
							condition = condition || identifiers.indexOf(item.identifier) !== -1;
						});
					});
				}
				if (condition) {
					edi.methods.checkMarkingPartners();
				}
			}
		}
	};
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		var documentHeaderData = moduleData.initData.data;
		var direction = edi.utils.getDocumentDirection(documentHeaderData.toOrg, documentHeaderData.fromOrg);

		var getOrganizationAdditionalFields = function (orgId) {
			var failure = function (responseData) {
				var errorMsg = edi.utils.formatComplexServerError(responseData, 'org.document.data.load.failed');
				edi.core.showWarn(errorMsg, continueWithRendering);
			};

			var getRejectReason = function () {
				edi.rest.sendRequest(
					edi.document.actions.formatRejectReasonUri(moduleData.initData),
					'GET',
					null,
					function (data) {
						rejectReasonText =
							data && data.data && data.data.TextNoticeUtoch ? data.data.TextNoticeUtoch : null;
						continueWithRendering();
					},
					continueWithRendering
				);
			};

			edi.rest.sendRequest(
				edi.document.actions.formatDocDataUri(moduleData.initData, {
					docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
					orgId: orgId
				}),
				'GET',
				null,
				function (data) {
					if (data && data.items) {
						infoColumnsConfig = data.items.length ? data.items : null;
						if (rejectReasonTextValue) {
							getRejectReason();
						} else {
							continueWithRendering();
						}
					} else {
						failure();
					}
				},
				failure
			);
		};

		var continueWithRendering = function () {
			bpName = edi.utils.getAttributeByName(documentHeaderData.attributes, 'bpName');

			var finishLoad = function () {
				modulePanel.add(createDetailsPanel());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);

				if (
					annulDoc &&
					annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
					annulDoc.toOrg.id == edi.core.getUserOrgID()
				) {
					edi.methods.documents.showActiveAnnulModal(annulDoc, moduleData);
				}

				if ('function' == typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}
			};

			annulStatus = edi.utils.getAttributeByName(documentHeaderData.attributes, 'ANNUL_STATUS');
			annulDoc = null;
			utochReasonText = edi.utils.getAttributeByName(documentHeaderData.attributes, 'UTOCH_REASON');
			var isAnnualUtoch =
				utochReasonText ||
				(annulStatus &&
					(annulStatus === edi.constants.STATE.CREATED || annulStatus === edi.constants.STATE.DRAFT));
			rejectReasonTextValue =
				edi.utils.getObjectProperty(documentHeaderData.attributes, 'utochCreated').value === 'true';
			var isIncomingRoaming =
				bpName === edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UKD.DIS_ROAMING &&
				edi.constants.DIRECTIONS.INCOMING === direction &&
				documentHeaderData.state === edi.constants.STATE.SENT;
			ukdRoamingStatus6 = null;
			if (isAnnualUtoch || isIncomingRoaming) {
				edi.rest.sendRequest(
					edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
						depth: edi.constants.DEFAULT.TREE_DEPTH
					}),
					'GET',
					{},
					function (responseData) {
						if (responseData && Array.isArray(responseData.items) && responseData.items.length) {
							var i, utochId;
							if (Array.isArray(responseData.items) && responseData.items.length) {
								for (i = 0; i < responseData.items.length; i++) {
									if (
										isAnnualUtoch &&
										responseData.items[i].type ===
											edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL &&
										edi.constants.STATE.WAIT_PARTNER_DECISION === responseData.items[i].state
									) {
										annulDoc = responseData.items[i];
									} else if (
										isAnnualUtoch &&
										responseData.items[i].type ===
											edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_9_DP_UVUTOCH
									) {
										utochId = responseData.items[i].id;
									} else if (
										isIncomingRoaming &&
										responseData.items[i].type ===
											edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_STATUS_6_DP_IZVPOL &&
										responseData.items[i].state === edi.constants.STATE.RECEIVER_UKD_RECEIVED_ACK
									) {
										ukdRoamingStatus6 = responseData.items[i];
									}
								}
							}
							if (!utochId) {
								finishLoad();
							} else {
								edi.rest.sendRequest(
									edi.document.actions.formatDetailsUri(moduleData.initData, utochId, true),
									'GET',
									{},
									function (responseData) {
										if (responseData.success && responseData.data) {
											utochReasonText = edi.utils.getObjectProperty(
												responseData.data,
												'Document.DataOfUvUtoch.TextNoticeUtoch'
											);
										}
										finishLoad();
									},
									failure
								);
							}
						}
					},
					failure
				);
			} else {
				finishLoad();
			}
		};

		edi.document.actions.updateDocumentHeaderData(moduleData, function () {
			documentHeaderData = moduleData.initData.data;
			rejectReasonTextValue =
				edi.utils.getObjectProperty(documentHeaderData.attributes, 'utochCreated').value === 'true';

			edi.rest.sendRequest(
				edi.document.actions.formatLinkedUri(moduleData.initData),
				'GET',
				undefined,
				function (data) {
					if (data && data.data && data.data.children && data.data.children.length) {
						var i,
							ids = [];
						for (i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_DP_PRANNUL) {
								rejectReasonText = edi.utils.getObjectProperty(
									data.data.children[i],
									'attributes.REJECT_REASON.value'
								);
							}
						}
						for (i = 0; i < data.data.children.length; i++) {
							if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1) {
								ids.push(data.data.children[i].id);
							} else if (data.data.children[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P2) {
								ids.push(data.data.children[i].id);
							}
							if (ids.length === 2) {
								break;
							}
						}
						if (ids.length) {
							edi.rest.sendRequest(
								edi.document.actions.formatBatchHeaderUri(moduleData.initData),
								'POST',
								Ext.encode(ids),
								function (data) {
									part2Head = null;
									for (var i = 0; i < data.items.length; i++) {
										if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P1) {
											part1Head = data.items[i];
											var tab = moduleData.tab;
											var module = edi.core.getModule(tab.module.modName);
											var tabTitleMsg = isVersion2
												? 'document.details.fns_ukd_5_02'
												: module.title;
											tab.module.title =
												edi.i18n.getMessage(tabTitleMsg) + ' ' + part1Head.number;
											tab.breadCrumbItem && 'function' == typeof tab.breadCrumbItem.setText
												? tab.breadCrumbItem.setText(tab.module.title)
												: null;
											tab.tab && 'function' == typeof tab.tab.setText
												? tab.tab.setText(tab.module.title)
												: null;
										} else if (data.items[i].type === edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD_P2) {
											part2Head = data.items[i];
										}
									}
									edi.rest.sendRequest(
										edi.document.actions.formatBatchContentUri(moduleData.initData),
										'POST',
										Ext.encode(ids),
										function (data) {
											part2 = null;
											if (data && data.items) {
												for (var i = 0; i < data.items.length; i++) {
													if (part1Head.id == data.items[i].header) {
														part1 = data.items[i];
													} else if (part2Head && part2Head.id == data.items[i].header) {
														part2 = data.items[i];
													}
												}
												getOrganizationAdditionalFields(documentHeaderData.toOrg.id);
											} else {
												failure(data);
											}
										},
										failure
									);
								},
								failure
							);
						} else {
							failure();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		});
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.events.documents.un('sign', signHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
