/**
 * Show certificate selection modal
 * @param	{{get: Function, set: Function}}	certHandler
 * @param	{string}	docType
 * @param	{{get: Function, set: Function}}	poaHandler
 * @return {Promise<{ok: boolean}>}
 */
const selectCertificate = function (certHandler, docType, poaHandler) {
	return new Promise((resolve) => {
		edi.sign.selectCertificate(
			function (cert, certObj, isLocalCert, poa) {
				certHandler.set(cert);
				poaHandler.set(poa);
				resolve({ selectionCanceled: false });
			},
			function (isCloseAfterSelection) {
				if (!isCloseAfterSelection) {
					certHandler.set(null);
					poaHandler.set(null);
					poaHandler.setPoaConfirmCheck(false);
					resolve({ selectionCanceled: true });
				}
			},
			undefined,
			docType,
			true
		);
	});
};

/**
 * Retrieve children documents which is ready for sign
 * @param	{number}	containerId
 * @return	{Promise<Object[]>}
 */
const getDocsForSign = function (containerId) {
	return new Promise((resolve, reject) => {
		let success = function (response) {
			if (response.data && response.data.children && response.data.children.length) {
				let documentsForSign = response.data.children.filter(
					(doc) =>
						doc.state === edi.constants.STATE.RECEIVER_APPROVAL ||
						doc.state === edi.constants.STATE.RECEIVER_REVIEW
				);
				resolve(documentsForSign);
			} else {
				reject(response);
			}
		};
		let url = edi.utils.formatString(edi.rest.services.DOCUMENTS.LINKED.GET, {
			documentId: containerId
		});
		edi.rest.sendRequest(url, 'GET', undefined, success, reject);
	});
};

/**
 * Creates html table with errors
 * @param	{Object[]}	docs
 * @returns	{string}	html table with errors
 */
const createErrorDocumentsList = function (docs) {
	return `<table class="documents-list">
		<thead>
			<th>${edi.i18n.getMessage('documents.list.document.number')}</th>
			<th>${edi.i18n.getMessage('documents.list.document.type')}</th>
			<th colspan='2'>${edi.i18n.getMessage('documents.list.document.error')}</th>
		</thead>
		<tbody>
			${docs
				.map(
					(d) => `<tr>
				<td>${d.number}</td>
				<td>${edi.renderers.doctype(d.type)}</td>
				<td colspan='2'>${d.errorData}</td>
			</tr>`
				)
				.join('\n')}
		</tbody>
	</table>`;
};

/**
 * Push container to move it through BP
 * @return {Promise<void>}
 */
let pushContainer = function (containerId) {
	return new Promise((resolve, _) => {
		let success = function (response) {
			edi.events.documents.fireEvent('change', {
				id: containerId
			});
			resolve({
				ok: true,
				response
			});
		};
		let fail = function (response) {
			resolve({
				ok: false,
				response
			});
		};
		let url = edi.utils.formatString(
			edi.rest.services.DOCUMENTS.SEND.PUT,
			{
				documentId: containerId
			},
			true
		);
		edi.rest.sendRequest(url, 'PUT', Ext.encode({}), success, fail);
	});
};

/**
 * Sign one catalog
 * @param	{Object}							docHeader
 * @param	{{get: Function, set: Function}}	certHandlers
 * @param	{{get: Function, set: Function}}	poaHandlers
 * @return	{Promise<{ok: boolean, doc: Object, errorText: string}>}
 */
const signDoc = function (docHeader, certHandlers, poaHandlers) {
	return new Promise((resolve) => {
		let sign = () => {
			edi.utils.sign(
				{
					id: docHeader.id,
					type: docHeader.type
				},
				undefined,
				function (failure, resultData) {
					resolve({
						ok: !failure,
						doc: docHeader,
						errorText: failure
							? edi.utils.formatComplexServerError(resultData, 'accept.registry.error.document.sign')
							: undefined
					});
				},
				undefined,
				undefined,
				true,
				certHandlers,
				{
					signUrlMethod: 'PUT',
					signRefuse() {
						resolve({
							ok: false,
							doc: docHeader
						});
					}
				},
				poaHandlers
			);
		};

		//Push before sign if needed
		if (docHeader.state === edi.constants.STATE.RECEIVER_APPROVAL) {
			let fail = function (response) {
				resolve({
					ok: false,
					doc: docHeader,
					errorText: edi.utils.formatComplexServerError(response, 'accept.registry.error.document.sign')
				});
			};

			let url = edi.utils.formatString(
				edi.rest.services.DOCUMENTS.SEND.PUT,
				{
					documentId: docHeader.id
				},
				true
			);
			edi.rest.sendRequest(url, 'PUT', Ext.encode({}), sign, fail);
		} else {
			sign();
		}
	});
};

/**
 * Sign all children catalogues
 * @param	{Object[]}	docsForSign
 * @param	{Object}	[certificate]
 * @param	{Number}	[poa]
 * @return	{Promise<{processed: number, signed: number, errors: Object[]}>}
 */
const signCatalogues = async function (docsForSign, certificate, poa) {
	let errors = [];
	let processed = 0;
	let signed = 0;

	if (!Array.isArray(docsForSign) || docsForSign.length === 0) {
		return {
			processed,
			signed,
			errors
		};
	}

	let certHandlers = {
		_selectedCertificate: certificate,
		get: function () {
			return this._selectedCertificate;
		},
		set: function (cert) {
			this._selectedCertificate = cert;
		}
	};
	let poaHandlers = {
		_selectedPoa: poa,
		get: function () {
			return this._selectedPoa;
		},
		set: function (poa) {
			this._selectedPoa = poa;
		},
		_poaConfirmChecked: false,
		setPoaConfirmCheck: function (poaConfirmChecked) {
			const handler = this;
			handler._poaConfirmChecked = poaConfirmChecked;
		},
		getPoaConfirmCheck: function () {
			const handler = this;
			return handler._poaConfirmChecked;
		}
	};

	if (!certHandlers.get()) {
		let { selectionCanceled } = await selectCertificate(
			certHandlers,
			edi.constants.DOCUMENT_TYPES.CONTAINER_COMMON_PRICAT,
			poaHandlers
		);
		if (selectionCanceled === true) {
			return {
				processed,
				signed,
				errors
			};
		}
	}

	let progress;
	let updateProcess = function ({ ok, doc, errorText }) {
		processed++;
		if (ok) {
			signed++;
		} else {
			errors.push({
				number: doc.number,
				type: doc.type,
				date: doc.date,
				amount: doc.amount,
				errorData: errorText,
				header: doc.header,
				isMain: doc.isMain
			});
		}

		let percent = Math.round((processed / docsForSign.length) * 100);
		let progressText = edi.utils.formatString(edi.i18n.getMessage('progress.sign.text'), {
			current: processed,
			total: docsForSign.length,
			percent: percent
		});
		let docTitle = `${edi.i18n.getMessage('documents.doctype.' + doc.type)}  № ${doc.number}`;

		if (!progress) {
			progress = Ext.MessageBox.show({
				closable: false,
				title: edi.i18n.getMessage('progress.sign.documents'),
				msg: docTitle,
				progress: true,
				progressText: progressText
			});
		} else {
			progress.updateProgress(percent / 100, progressText, docTitle);
		}
	};

	//signing catalogues one by one and update progress after each
	for (let i = 0; i < docsForSign.length; i++) {
		await signDoc(docsForSign[i], certHandlers, poaHandlers).then(updateProcess);
	}

	if (progress) {
		progress.hide();
	}

	return {
		processed,
		signed,
		errors
	};
};

/**
 * Sign container's children
 * @param	{Object}	containerData
 * @param	{Object}	tab
 * @param	{Object}	[certificate]
 * @return	{Promise<void>}
 */
const containerCommonPricatSign = async function (containerData, tab, certificate, poa) {
	let containerId = containerData.id;
	let failure = edi.document.actions.defaultFailureHandler(tab, 'error.getting.data');

	tab.setLoading(true);

	let docsForSign = [];
	try {
		docsForSign = await getDocsForSign(containerId);
	} catch (response) {
		failure(response);
		return;
	}

	//Sign catalogues if needed
	let { signed, processed, errors } = await signCatalogues(docsForSign, certificate, poa);
	if (errors.length) {
		edi.core.showWarn(
			edi.utils.formatString(edi.i18n.getMessage('progress.sign.warn'), {
				amount: signed,
				total: docsForSign.length,
				errors: createErrorDocumentsList(errors)
			})
		);
		tab.setLoading(false);
		return;
	}

	//Push container after signing all children if it stuck in PROCESSING_TITLES
	let pushState;
	if (processed === 0 && containerData.state === edi.constants.STATE.PROCESSING_TITLES) {
		pushState = await pushContainer(containerId);
		if (pushState.ok !== true) {
			failure(pushState.response);
			return;
		}
	}

	//update container everywhere if something happened with it
	if (signed > 0 || (pushState && pushState.ok === true)) {
		edi.events.documents.fireEvent('change', {
			id: containerId
		});
	}

	edi.core.showInfo(
		edi.utils.formatString(edi.i18n.getMessage('progress.sign.success'), {
			amount: signed,
			total: docsForSign.length
		})
	);

	tab.setLoading(false);
};

export { containerCommonPricatSign };
