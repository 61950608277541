const MODAL_CERTIFICATE_COLUMN = 'ew_user_profile_modal_certificate_column';
const DUPLICATE_MODAL_CERTIFICATE_COLUMN = 'ew_user_profile_duplicate_modal_certificate_column';

edi.columns.addColumns({
	[MODAL_CERTIFICATE_COLUMN]: {
		subjectName: {
			text: 'full.name.abbr',
			sortable: false,
			flex: 2,
			dataIndex: 'SubjectName',
			renderer: function (value) {
				const subject = edi.utils.fullStringCertParse(value);
				return [subject.lastname, subject.firstname, subject.middlename].filter((it) => !!it).join(' ');
			}
		},
		thumbprint: {
			text: 'ediweb.application_to_fns.modal.thumbprint',
			sortable: false,
			flex: 2,
			dataIndex: 'Thumbprint'
		},
		validFromDate: {
			text: 'column.valid.from.date',
			dataIndex: 'ValidFromDate',
			flex: 1,
			sortable: true,
			renderer: 'docTime'
		},
		validToDate: {
			text: 'column.valid.to.date',
			dataIndex: 'ValidToDate',
			flex: 1,
			sortable: true,
			renderer: 'docTime'
		}
	},
	[DUPLICATE_MODAL_CERTIFICATE_COLUMN]: {
		subjectName: {
			text: 'certificate.title',
			sortable: false,
			flex: 2,
			dataIndex: 'SubjectName',
			renderer: function (value) {
				const subject = edi.utils.fullStringCertParse(value);
				return [subject.lastname, subject.firstname, subject.middlename].filter((it) => !!it).join(' ');
			}
		},
		validFromDate: {
			text: 'column.valid.from.date',
			dataIndex: 'ValidFromDate',
			flex: 1,
			sortable: true,
			renderer: 'docTime'
		},
		validToDate: {
			text: 'column.valid.to.date',
			dataIndex: 'ValidToDate',
			flex: 1,
			sortable: true,
			renderer: 'docTime'
		},
		state: {
			text: 'document.state',
			dataIndex: 'state',
			renderer(value, meta) {
				let text = edi.i18n.getMessage('certificate.status.' + value);
				return edi.renderers.baseStateRenderer(value, text, meta);
			},
			flex: 1.5,
			htmlEncode: true
		}
	}
});

export { MODAL_CERTIFICATE_COLUMN, DUPLICATE_MODAL_CERTIFICATE_COLUMN };
