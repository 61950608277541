import { createProductGridBase } from '@Components/product.grid';
import { createField, createFieldSet, createTextField } from '@Components/fields';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createCreateSaveButton } from '@Components/buttons';
import { createButtonContainer } from '@UIkit/components/panels';
import { MODAL_SIZE } from '@UIkit/components/modal';

Ext.namespace('edi.modules');
/**
 * Class for new KC3 creation
 * @author Aleksandr Egorov
 */
edi.modules['document.create.logset'] = function () {
	var moduleData,
		documentData,
		documentValues = {},
		form,
		productsGrid,
		isEdit = true,
		createButton,
		valueSupMap,
		tableValueMap,
		productValues;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	var createModuleForm = function () {
		var responsible = createFieldSet({
			title: edi.i18n.getMessage('document.logset.responsible'),
			collapsible: true,
			items: [
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createField({
							title: edi.i18n.getMessage('last.name'),
							input: createTextField({
								columnWidth: 0.8,
								maxLength: 255,
								allowBlank: false,
								valueSrc: documentValues,
								name: 'lastName',
								validator: function (value) {
									return !(value.indexOf(' ') >= 0) || edi.i18n.getMessage('invalid.value.format');
								}
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				}),
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createField({
							title: edi.i18n.getMessage('first.name'),
							input: createTextField({
								columnWidth: 0.8,
								maxLength: 255,
								allowBlank: false,
								valueSrc: documentValues,
								name: 'firstName',
								validator: function (value) {
									return !(value.indexOf(' ') >= 0) || edi.i18n.getMessage('invalid.value.format');
								}
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				}),
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createField({
							title: edi.i18n.getMessage('patronymic.name'),
							input: createTextField({
								columnWidth: 0.8,
								maxLength: 255,
								allowBlank: true,
								valueSrc: documentValues,
								name: 'patronymicName'
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				}),
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createField({
							title: edi.i18n.getMessage('email'),
							input: createTextField({
								columnWidth: 0.8,
								maxLength: 255,
								allowBlank: false,
								valueSrc: documentValues,
								name: 'email'
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				}),
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createField({
							title: edi.i18n.getMessage('general.phone'),
							input: createTextField({
								columnWidth: 0.8,
								maxLength: 255,
								allowBlank: false,
								valueSrc: documentValues,
								name: 'phone'
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				})
			]
		});

		var twoColumnsLayout = createTwoColumnsLayout([responsible], [], 0.4);
		var columns = edi.columns.get('document_logset').filter(function (col) {
			return ['lineNumber', 'multiplicity', 'condition', 'deliveryType', 'schedule'].indexOf(col.cName) === -1;
		});

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('document.upd.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'DOCUMENT_LOGSET',
			gridColumnConfig: columns,
			data: documentValues.lines,
			margin: '16 0 0',
			readOnly: false,
			hasTotal: false,
			docType: edi.constants.DOCUMENT_TYPES.LOGSET,
			configModalButtonsBefore: function () {
				return [];
			},
			isValidRecord: function (record) {
				return !!(
					record.get('postTime') &&
					record.get('frequency') &&
					record.get('confirmation') &&
					record.get('shipmentPointAddress') &&
					record.get('shipmentPointLatitude') &&
					record.get('shipmentPointLongitude')
				);
			},
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			modalConf: {
				width: MODAL_SIZE.widthLarge,
				maxHeight: undefined
			},
			modalFormConfig: {
				title: 'document.logset.modal.edit.title',
				modalFields: [
					{
						title: edi.i18n.getMessage('document.logset.modal.edit.title'),
						items: [
							{
								title: edi.i18n.getMessage('document.logset.minimum.weight'),
								name: 'minimumWeight',
								type: 'combo',
								valueField: 'id',
								displayField: 'name',
								forceSelection: true,
								anyMatch: true,
								queryMode: 'local',
								store: 'initWeightOfOrder'
							},
							{
								title: edi.i18n.getMessage('document.logset.delivery.time'),
								name: 'deliveryTime',
								regex: /^\d{6}$/,
								maxLength: 6
							},
							{
								title: edi.i18n.getMessage('document.logset.post.time'),
								name: 'postTime',
								type: 'combo',
								store: 'initDocumentLogsetPostTimeStore',
								allowBlank: false
							},
							{
								title: edi.i18n.getMessage('document.logset.min.pallets'),
								name: 'minPallets',
								maxLength: 35
							},
							{
								title: edi.i18n.getMessage('document.logset.pallets'),
								name: 'pallets',
								maxLength: 35
							},
							{
								title: edi.i18n.getMessage('document.logset.min.purchase'),
								name: 'minPurchase',
								maxLength: 35
							},
							{
								title: edi.i18n.getMessage('document.logset.delivery.time.from.sending'),
								name: 'deliveryTimeFromSending',
								maxLength: 3
							},
							{
								title: edi.i18n.getMessage('document.logset.frequency'),
								name: 'frequency',
								allowBlank: false,
								maxLength: 2
							},
							{
								title: edi.i18n.getMessage('document.logset.confirmation'),
								name: 'confirmation',
								allowBlank: false,
								maxLength: 2
							},
							{
								title: edi.i18n.getMessage('document.logset.supplier.comment'),
								name: 'supplierComment'
							}
						]
					},
					{
						title: edi.i18n.getMessage('document.logset.shipment.point'),
						items: [
							{
								title: edi.i18n.getMessage('document.logset.shipment.point.iln'),
								name: 'shipmentPointIln',
								type: 'label'
							},
							{
								title: edi.i18n.getMessage('document.logset.shipment.point.address'),
								name: 'shipmentPointAddress',
								allowBlank: false,
								maxLength: 80
							},
							{
								title: edi.i18n.getMessage('document.logset.shipment.point.latitude'),
								name: 'shipmentPointLatitude',
								allowBlank: false,
								maxLength: 17,
								regex: edi.constants.VALIDATORS.INT
							},
							{
								title: edi.i18n.getMessage('document.logset.shipment.point.longitude'),
								name: 'shipmentPointLongitude',
								allowBlank: false,
								maxLength: 17,
								regex: edi.constants.VALIDATORS.INT
							}
						]
					}
				]
			}
		});

		createButton = createCreateSaveButton(
			{
				formBind: true,
				disabled: true,
				handler: function () {
					var form = this.up('form').getForm();
					if (form.isValid()) {
						save();
					}
				}
			},
			isEdit
		);

		form = createForm({
			cls: 'edi-form document-add-form',
			autoScroll: true,
			bodyPadding: 10,
			buttons: createButtonContainer({
				items: [createButton]
			}),
			items: [twoColumnsLayout, productsGrid]
		});

		form.on('validitychange', checkValid);
		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		var hasInvalid = form.hasInvalidField();
		var validGrid = productsGrid.isValid();
		if (!createButton.disabled && (hasInvalid || !validGrid)) {
			createButton.setDisabled(true);
		} else if (createButton.disabled && !hasInvalid && validGrid) {
			createButton.setDisabled(false);
		}
		return !hasInvalid && validGrid;
	};

	/**
	 * Saves document
	 */
	var save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var document = edi.utils.collectFormValues(form),
			docData = Ext.clone(documentData),
			supplierPath = 'sg2[nad.e3035==SU]',
			supplier = edi.utils.getObjectPropertyEx(docData, supplierPath);

		edi.utils.setObjectProperty(docData, 'bgm.e1225', 4);
		edi.utils.setObjectProperty(docData, 'bgm.c002.e1001', 291);

		if (supplier) {
			if (document['lastName']) {
				document['famialyName'] = document['lastName'];
				if (document['firstName']) {
					document['famialyName'] += ' ' + document['firstName'];
				}
			}
			Object.entries(valueSupMap).forEach(function ([name, path]) {
				edi.utils.setObjectPropertyEx(supplier, path, document[name]);
			});
			edi.utils.setObjectPropertyEx(supplier, 'sg4.cta.e3139', 'DL');
			edi.utils.setObjectPropertyEx(docData, supplierPath, supplier);
		}
		delete tableValueMap['shipmentPointIln'];

		if (productValues) {
			productValues.products.forEach(function (item, index) {
				Object.entries(tableValueMap).forEach(function (name, path) {
					edi.utils.setObjectPropertyEx(docData.sg6[index], path, item[name]);
				});
				edi.utils.setObjectPropertyEx(docData.sg6[index], 'sg12.dtm[c507.e2005==766]c507.e2379', 306);
				edi.utils.setObjectPropertyEx(docData.sg6[index], 'sg12.sg22[nad.e3035==CZ]loc.c519.e3223', 'LATDEC');
				edi.utils.setObjectPropertyEx(docData.sg6[index], 'sg12.sg22[nad.e3035==CZ]loc.c553.e3233', 'LONDEC');
				edi.utils.setObjectPropertyEx(docData.sg6[index], 'sg12.sg22[nad.e3035==CZ]loc.e3227', 7);
			});
		}
		var excludeValue = ['creationDate', 'header', 'id', 'modifyDate', 'version'];
		excludeValue.forEach(function (excludeItem) {
			delete docData[excludeItem];
		});

		var headerData = {
			data: Ext.encode(docData),
			number: edi.utils.getObjectProperty(docData, 'bgm.c106.e1004')
		};
		var success = function (data) {
			moduleData.isChanged = false;
			moduleData.tab.setLoading(false);
			edi.document.actions.documentCreateProcessing(moduleData, data, isEdit);
			if (isEdit) {
				edi.events.documents.fireEvent('change', {
					id: moduleData.initData.data.id
				});
			} else {
				edi.events.documents.fireEvent('create');
			}
		};
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.LOGSET
		);

		var updateData = function () {
			edi.document.actions.processDocument(
				moduleData.initData.data.toOrg.id,
				null,
				edi.constants.DOCUMENT_TYPES.LOGSET,
				null,
				moduleData.initData.id,
				success,
				failure,
				headerData,
				{
					editUri: edi.rest.services.DOCUMENTS.SEND.PUT
				}
			);
		};

		if (
			edi.constants.STATE.SENT === moduleData.initData.data.state ||
			edi.constants.STATE.RECEIVER_REVIEW === moduleData.initData.data.state
		) {
			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.SEND.PUT,
					{
						documentId: moduleData.initData.id
					},
					true
				),
				'PUT',
				Ext.encode({
					UPDATE: true
				}),
				function (resp) {
					if (resp.data && resp.success) {
						updateData();
					}
				},
				failure
			);
		} else {
			updateData();
		}
	};

	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		var continueLoading = function () {
			// @formatter:off
			valueSupMap = {
				famialyName: 'sg4.cta.c056.e3412',
				patronymicName: 'sg4.cta.c056.e3413',
				email: 'sg4.com[c076.e3155==EM]c076.e3148',
				phone: 'sg4.com[c076.e3155==TE]c076.e3148'
			};
			tableValueMap = {
				minimumWeight: 'sg12.mea[e6311==PD]c502.e6155',
				deliveryTime: 'sg12.dtm[c507.e2005==766]c507.e2380',
				postTime: 'sg12.dtm[c507.e2005==64]c507.e2380',
				minPallets: 'sg12.sg17.sg18[qty.c186.e6063==53]qty.c186.e6060',
				pallets: 'sg12.sg17.sg18[qty.c186.e6063==267]qty.c186.e6060',
				minPurchase: 'sg12.sg17.sg18[qty.c186.e6063==26E]qty.c186.e6060',
				deliveryTimeFromSending: 'sg12.sg17.sg18[qty.c186.e6063==40]qty.c186.e6060',
				frequency: 'sg12.sg17.sg18[qty.c186.e6063==135]qty.c186.e6060',
				confirmation: 'sg12.sg17.sg18[qty.c186.e6063==55]qty.c186.e6060',
				shipmentPointIln: 'sg12.sg22[nad.e3035==CZ]nad.c082.e3039',
				shipmentPointAddress: 'sg12.sg22[nad.e3035==CZ]nad.c059.e3042',
				shipmentPointLatitude: 'sg12.sg22[nad.e3035==CZ]loc.c519.e1131',
				shipmentPointLongitude: 'sg12.sg22[nad.e3035==CZ]loc.c553.e1131',
				supplierComment: 'sg12.ftx[e4451==ZZZ]c108.e4440'
			};
			// @formatter:on

			var documentSupplier = edi.utils.getObjectPropertyEx(documentData, 'sg2[nad.e3035==SU]');
			if (documentSupplier) {
				Object.entries(valueSupMap).forEach(function ([name, path]) {
					documentValues[name] = edi.utils.getObjectPropertyEx(documentSupplier, path);
				});

				if (documentValues['famialyName']) {
					var suplData = documentValues['famialyName'].split(' ');
					if (suplData[0]) {
						documentValues['lastName'] = suplData[0];
					}
					if (suplData[1]) {
						documentValues['firstName'] = suplData[1];
					}
				}
			}
			documentValues.lines = [];
			var lines = edi.utils.getObjectProperty(documentData, 'sg6');
			lines?.forEach(function (line) {
				var lineValues = {};
				Object.entries(tableValueMap).forEach(function ([name, path]) {
					lineValues[name] = edi.utils.getObjectPropertyEx(line, path);
				});
				documentValues.lines.push(lineValues);
			});

			var modulePanel = createAddModulePanel({
				items: [createModuleForm()]
			});

			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);

			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};

		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
				documentId: data.id
			}),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					documentData = data.data;
					continueLoading();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
