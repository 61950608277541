import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { moduleSettings, roseuInvitationsEvents, stateRenderer } from './moduleCommons.js';
import { createTextField } from '@Components/fields';
import { createActionsButton } from '@Components/buttons';
import { createSimpleSelector } from '@Components/simple.selector';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import {
	createFieldBlock,
	createFieldBlockForDetails,
	createMaxWidthContainerForDetail,
	createModalForm,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createLabelForDetails } from '@UIkit/components/fields';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import createPreviewPanel from '@Components/previewPanel/previewPanel';

Ext.namespace('edi.modulesCfg');
edi.modulesCfg['roseu_invitation.details'] = {
	title: 'roseu_invitation.details',
	modName: 'roseu_invitation.details',
	highlightMenuId: 'roseu_invitations',
	permissions: ['CLIENT_ROSEU_INVITATION_READ']
};

edi.constants.DETAILS_MODULE_NAME_BY_TYPE[moduleSettings.TYPE] = 'roseu_invitation.details';

Ext.namespace('edi.modules');
edi.modules['roseu_invitation.details'] = function () {
	let moduleData, invitationHeader, invitationContent;

	/**
	 * Module initialization
	 * @param	{Object}	data			Data from openModule
	 * @param	{Function}	initCallBack	After initialization callback
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		invitationHeader = moduleData.initData.data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		roseuInvitationsEvents.on('change', changeHandler);
	};

	/**
	 * Change event handler (refresh)
	 * @param	{Object}	eventData
	 */
	let changeHandler = function (eventData) {
		if (!moduleData.tab.isDestroyed && eventData?.id === invitationHeader.id) {
			renderData();
		}
	};

	let createCompleteBtn = function () {
		return createActionsButton({
			text: edi.i18n.getMessage('form.btn.accept.invitation'),
			glyph: edi.constants.ICONS.DONE,
			handler: function () {
				let sendAccept = function () {
					modal.setLoading(true);
					let success = function () {
						modal.close();
						roseuInvitationsEvents.fireEvent('change', { id: invitationHeader.id });
					};
					let fail = edi.document.actions.defaultFailureHandler(modal, 'document.error.in.complete.process');
					let url = edi.utils.formatString(
						edi.rest.services.DOCUMENTS.SEND.PUT,
						{ documentId: invitationHeader.id },
						true
					);
					let payload = Ext.encode({ RECIPIENT_COMMENT: commentInput.getValue() });
					edi.rest.sendRequest(url, 'PUT', payload, success, fail);
				};

				let commentInput = createTextField({
					name: 'COMMENT',
					allowBlank: true,
					isTextarea: true
				});

				let modalForm = createModalForm({
					items: [
						createFieldBlock({
							cls: FIELD_BLOCK_CLS.small,
							title: edi.i18n.getMessage('roseu_invitation.accept.modal.comment'),
							items: [commentInput]
						})
					]
				});

				let modal = createModalPanel({
					title: edi.i18n.getMessage('roseu_invitation.accept.modal.comment'),
					width: MODAL_SIZE.widthLarge,
					items: [modalForm],
					buttonsBefore: [
						createButton({
							cls: BUTTON_CLS.primary,
							glyph: edi.constants.ICONS.DONE,
							text: edi.i18n.getMessage('form.btn.accept'),
							handler: sendAccept,
							bindToForm: modalForm
						})
					]
				});

				modal.show();
			}
		});
	};

	/**
	 * Creates panel with actions on the top of module
	 * @returns	{Object}	Ext.Panel instance
	 */
	let createModuleActionsPanel = function () {
		let actionsPanel = createActionsPanel();
		let currentOrg = edi.core.getUserData().org;

		//если файл есть, то надо подписывать, если нет, то просто отправляем
		let excludeActions = {};
		if (invitationContent.file?.id) {
			excludeActions['SEND'] = true;
		} else {
			excludeActions['SIGN'] = true;
		}

		let pushBeforeSign = invitationHeader.state === edi.constants.STATE.DRAFT && !!invitationContent.file?.id;

		excludeActions[edi.constants.DOCUMENT_ACTIONS.PRINT] = true;
		excludeActions[edi.constants.DOCUMENT_ACTIONS.EXPORT] =
			!invitationContent?.file && !invitationContent?.file?.id;

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: invitationHeader,
			direction:
				currentOrg.fnsId === edi.utils.getAttributeByName(invitationHeader.attributes, 'RECEIVER_FNSID')
					? edi.constants.DIRECTIONS.INCOMING
					: edi.constants.DIRECTIONS.OUTGOING,
			moduleData: moduleData,
			excludeActions,
			needSignatures: 0,
			actionProps: {
				REFRESH: {
					handler: renderData
				},
				SEND: {
					success: () => roseuInvitationsEvents.fireEvent('change', { id: invitationHeader.id })
				},
				SIGN: {
					methodAddOptions: {
						useBeforeAction: pushBeforeSign,
						afterFailure: () =>
							pushBeforeSign && roseuInvitationsEvents.fireEvent('change', { id: invitationHeader.id }),
						signProperties: {
							dataChanged: () => roseuInvitationsEvents.fireEvent('change', { id: invitationHeader.id })
						}
					}
				},
				COMPLETE: {
					component: createCompleteBtn()
				},
				REJECT: {
					success: () => roseuInvitationsEvents.fireEvent('change', { id: invitationHeader.id }),
					methodAddOptions: {
						addReasonCfg: {
							allowBlank: true,
							maxLength: 255,
							listeners: {
								change: function (comp) {
									let rejectBtn = comp.up('window')?.down('[itemId="reject-button"]');
									rejectBtn?.setDisabled(!comp.isValid());
								}
							}
						},
						// getValidationError: function() {
						// 	//null блокирует показ окна ошибки, если форма не валидная
						// 	//ну или можно кастомную ошибку выкинуть
						// 	return null;
						// },
						process:
							invitationHeader.state === edi.constants.STATE.COMPLETED
								? {
										url: edi.utils.formatString(moduleSettings.API.INVITATION.REJECT_COMPLETED, {
											id: invitationHeader.id
										}),
										method: 'POST'
								  }
								: undefined
					}
				},
				DELETE: {
					success: function () {
						moduleData.tab.close();
						roseuInvitationsEvents.fireEvent('change', { id: invitationHeader.id });
					}
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Creates sender company selector
	 * @returns	{Object}	edi-simple-selector instance
	 */
	let createSenderField = function () {
		const sender = createSimpleSelector({
			name: 'sender',
			fieldValues: invitationContent.sender,
			showResetButton: false,
			showSelectButton: false,
			showEditButton: false,
			isValidFunc: () => true,
			infoPanelComponentTpl: () =>
				new Ext.XTemplate(
					`<tpl if="!values || (!values.orgName && !values.id && !values.inn && !values.kpp)">
					<span class="edi-empty-label">&nbsp;</span>
				<tpl else>
					<div class="edi-company-row">
						<tpl if="orgName.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('column.org.name')}:</span>
							<span class="row-data"> {orgName} </span>
						</tpl>
					</div>
					<div class="edi-company-row">
						<tpl if="id.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.fns.id')}:</span>
							<span class="row-data"> {id} </span>
						</tpl>
					</div>
					<div class="edi-company-row">
						<tpl if="inn.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>
							<span class="row-data"> {inn} </span>
						</tpl>
						<tpl if="kpp.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>
							<span class="row-data"> {kpp} </span>
						</tpl>
					</div>
				</tpl>`
				)
		});
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.sender'),
			items: [sender]
		});
	};

	/**
	 * Creates receiver company selector
	 * @returns	{Object}	edi-simple-selector instance
	 */
	let createReceiverField = function () {
		const receiver = createSimpleSelector({
			name: 'receiver',
			fieldValues: invitationContent.receiver,
			showResetButton: false,
			showSelectButton: false,
			showEditButton: false,
			isValidFunc: () => true,
			infoPanelComponentTpl: () =>
				new Ext.XTemplate(
					`<tpl if="!values || (!values.orgName && !values.id && !values.inn && !values.kpp)">
					<span class="edi-empty-label">&nbsp;</span>
				<tpl else>
					<div class="edi-company-row">
						<tpl if="orgName.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('column.org.name')}:</span>
							<span class="row-data"> {orgName} </span>
						</tpl>
					</div>
					<div class="edi-company-row">
						<tpl if="id.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.fns.id')}:</span>
							<span class="row-data"> {id} </span>
						</tpl>
					</div>
					<div class="edi-company-row">
						<tpl if="inn.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.inn.short')}:</span>
							<span class="row-data"> {inn} </span>
						</tpl>
						<tpl if="kpp.length &gt; 0">
							<span class="code">${edi.i18n.getMessage('company.kpp.short')}:</span>
							<span class="row-data"> {kpp} </span>
						</tpl>
					</div>
				</tpl>`
				)
		});
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.receiver'),
			items: [receiver]
		});
	};

	/**
	 * Creates panel with file
	 * @returns {Object|null}
	 */
	let createFilePanel = function () {
		let filePanel = null;
		if (invitationContent.file) {
			const { fileName, fileSize, modifyDate } = invitationContent.file;
			const id = moduleData.initData.id;
			const previewIsAllowed = edi.permissions.hasPermission('CLIENT_READ_ATTACHMENT_VIEW');
			const previewClickHandler = () => {
				moduleData.tab.setLoading();
				const url = edi.utils.formatString(moduleSettings.API.INVITATION.ATTACHMENT_VIEW.POST, {
					id
				});
				const success = (responseData) => {
					const attachData = responseData?.data;
					if (attachData) {
						createPreviewPanel({
							fileId: id,
							fileName,
							imagesBase64Data: attachData.data?.map(({ data }) => data)
						});
					} else {
						edi.core.showInfo('document.dsf.load.preview.waiting');
					}
				};
				const failure = () => edi.core.showInfo('document.dsf.load.preview.error');
				const callback = () => moduleData.tab.setLoading(false);
				edi.rest.sendRequest(url, 'POST', {}, success, failure, callback);
			};

			const fileDropper = createFileDropper({
				cls: previewIsAllowed ? 'edi-component-clickable drag-file' : 'drag-file',
				readOnly: true,
				fileData: {
					fileName: fileName,
					fileDate: edi.utils.formatDate(modifyDate, edi.constants.DATE_FORMAT.FNS),
					fileTime: edi.utils.formatDate(modifyDate, edi.constants.DATE_FORMAT.TIME),
					fileSize: edi.utils.formatFileSize(fileSize)
				},
				listeners: {
					afterrender: function (cmp) {
						if (previewIsAllowed) {
							cmp.on({
								click: previewClickHandler,
								element: 'el',
								scope: cmp
							});
						}
					}
				}
			});
			filePanel = createFieldBlockForDetails({
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				title: edi.i18n.getMessage('document.file'),
				cls: 'edi-form-maxwidth',
				layout: {
					type: 'grid',
					area: [6]
				},
				items: [fileDropper]
			});
		}
		return filePanel;
	};

	/**
	 * Creates module's form
	 * @returns	{Object}	Ext.form.Panel instance
	 */
	let createModuleForm = function () {
		let headPanel = createDocumentHeaderPanel(moduleData.initData, {
			stateRenderer: stateRenderer,
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
		});

		const comment = createFieldBlockForDetails({
			title: edi.i18n.getMessage('roseu_invitation.comment'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			cls: 'edi-form-maxwidth',
			items: createLabelForDetails({
				text: edi.utils.getObjectProperty(invitationContent, 'comment')
			})
		});

		let recipientCommentText = edi.utils.getAttributeByName(invitationHeader.attributes, 'RECIPIENT_COMMENT');
		let recipientComment = recipientCommentText
			? createFieldBlockForDetails({
					title: edi.i18n.getMessage('roseu_invitation.accept.modal.comment'),
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					cls: 'edi-form-maxwidth',
					items: createLabelForDetails({
						text: recipientCommentText
					})
			  })
			: null;

		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				headPanel,
				createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						area: [[6, 6]]
					},
					items: [createSenderField(), createReceiverField()]
				}),
				createFilePanel(),
				comment,
				recipientComment
			]
		});
	};

	/**
	 * Gets invitation's content from backend
	 * @param	{Function}	success
	 * @param	{Function}	fail
	 */
	let getInvitationContent = function (success, fail) {
		let successFn = function (resp) {
			invitationContent = Ext.clone(resp.data);
			success();
		};

		let url = edi.utils.formatString(moduleSettings.API.INVITATION.GET_CONTENT, { id: invitationHeader.id });
		edi.rest.sendRequest(url, 'GET', {}, successFn, fail);
	};

	/**
	 * Gets invitation's header from backend
	 * @param	{Function}	success
	 * @param	{Function}	fail
	 */
	let getInvitationHeader = function (success, fail) {
		let successFn = function (resp) {
			invitationHeader = Ext.clone(resp.data);
			moduleData.initData.data = Ext.clone(invitationHeader);
			success();
		};

		let url = edi.utils.formatString(moduleSettings.API.INVITATION.GET, { id: invitationHeader.id });
		edi.rest.sendRequest(url, 'GET', {}, successFn, fail);
	};

	/**
	 * Creates module's entrails
	 * @param	{Function}	[initCallBack]
	 */
	let renderData = function (initCallBack) {
		let fail = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		moduleData.tab.setLoading(true);
		getInvitationHeader(function () {
			getInvitationContent(function () {
				moduleData.tab.removeAll();

				const modulePanel = createDetailsModulePanel();
				modulePanel.add(createModuleForm());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);

				if ('function' == typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}
				moduleData.tab.updateLayout();
			}, fail);
		}, fail);
	};

	/**
	 * Routine that must be done before module destroy
	 */
	let onDestroy = function () {
		roseuInvitationsEvents.un('change', changeHandler);
	};
};
