const CERTIFICATES_MODEL = 'EW_ORG_PROFILE_CERTIFICATES';

edi.models.setModels({
	[CERTIFICATES_MODEL]: {
		fields: [
			{
				name: 'fullName',
				type: 'string'
			},
			{
				name: 'validFromDate',
				type: 'number'
			},
			{
				name: 'validToDate',
				type: 'number'
			},
			{
				name: 'state',
				type: 'string'
			},
			{
				name: 'id',
				type: 'number'
			},
			{
				name: 'dataId',
				type: 'number'
			}
		]
	}
});

export { CERTIFICATES_MODEL };
