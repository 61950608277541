import { createModulePanel } from '@Components/panels';
import { createTabPanel } from '@UIkit/components/tab';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';
import { createApplicationGrid } from '@Ediweb/modules/CONTROL_CENTER/tabs/APPLICATION_TO_FNS/application_to_fns';
import { createEmployeeCertificatesPanel } from '@Ediweb/modules/CONTROL_CENTER/tabs/EMPLOYEE_CERTIFICATES/employee.certificates';
import { createUserCertificatesPanel } from '@Ediweb/modules/CONTROL_CENTER/tabs/CERTIFICATES/certificates';
import { createContainer } from '@UIkit/components/panels';
import { createLabel } from '@UIkit/components/fields';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import '@Edi/modules/power_of_attorney/powers.of.attorney.list';
import { tourEvents, tours } from '@Ediweb/components/tour';
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
import { createHelpElementPlugin } from '@Core/methods';

const CC_MODULE_NAME = 'control.center.module.panel';
const CC_MAIN_TOUR_FLAG_NAME = 'user.controlCenterMainHints';
const CC_CERTIFICATES_TOUR_FLAG_NAME = 'user.controlCenterCertificatesHints';
const CC_MAIN_TOUR_TARGET_1 = 'control-center-main-tour-target-1';
const CC_MAIN_TOUR_TARGET_2 = 'control-center-main-tour-target-2';
const CC_MAIN_TOUR_TARGET_3 = 'control-center-main-tour-target-3';
const CC_MAIN_TOUR_TARGET_4 = 'control-center-main-tour-target-4';
const CC_MAIN_TOUR_TARGET_5 = 'control-center-main-tour-target-5';
export const CC_MAIN_TOUR_TARGET_6 = 'control-center-main-tour-target-6';
const CC_CERTIFICATES_TOUR_TARGET_1 = 'control-center-certificates-tour-target-1';

Ext.namespace('edi.modules');
edi.modules[CC_MODULE_NAME] = function () {
	var moduleData,
		tabPanel = null,
		gridNameTab,
		grids = {},
		options = {};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		moduleData.getTourOpts = () => ({
			getGridName: () => gridNameTab,
			getGrid: () => grids[gridNameTab]
		});
		renderData(initCallBack);
		return onDestroy;
	};

	var gridDataChangedHandler = function () {
		for (var i in grids) {
			//в grids лежит еще и таб Реквизиты, а он совсем не грид, поэтому смотрим есть ли у него стор
			if (grids.hasOwnProperty(i) && grids[i].getStore) {
				var store = grids[i].getStore();
				var gridSelModel = grids[i].getSelectionModel();
				if (gridSelModel) {
					gridSelModel.deselectAll();
				}
				store.reload();
			}
		}
	};

	var poaGridDataChangedHandler = function () {
		if (grids?.powersOfAttorney?.tab) {
			const poaGrid = grids.powersOfAttorney.tab.down('grid');
			poaGrid.getStore().reload();
		}
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.application_to_fns.on('change', gridDataChangedHandler);
		edi.events.powerOfAttorney.on('change', poaGridDataChangedHandler);
		edi.events.control_center.on('activatetab', activateTab);
	};

	const tryToStartTour = function () {
		//т.к. модули инициализируются лениво, то юзер мог переключить модуль
		//поэтому запустим тур если мы сейчас на том же модуле, что и инициализировал запуск тура
		if (gridNameTab === 'powersOfAttorney' && edi.core.getExtraData(CC_MAIN_TOUR_FLAG_NAME) !== 'true') {
			setTimeout(() => {
				if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
					tourEvents.fireEvent('start_tour', CC_MODULE_NAME, moduleData);
				}
			}, 500);
		} else if (gridNameTab === 'certificates' && edi.core.getExtraData(CC_CERTIFICATES_TOUR_FLAG_NAME) !== 'true') {
			setTimeout(() => {
				if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
					tourEvents.fireEvent('start_tour', CC_MODULE_NAME, moduleData);
				}
			}, 500);
		}
	};

	const showPoaPersonalDataConsent = function () {
		const showWindow = function (htmlContent) {
			const htmlContainer = createContainer({
				html: htmlContent
			});

			const confirmBtn = createButton({
				text: edi.i18n.getMessage('agreement_personal_data.confirm'),
				cls: BUTTON_CLS.primary,
				glyph: edi.constants.ICONS.DONE,
				handler: function () {
					edi.core.setExtraData('user.personalDataConsentPoa', true, function () {
						modal.close();
					});
				}
			});

			const modal = createModalPanel(
				{
					title: edi.i18n.getMessage('agreement_personal_data.title'),
					draggable: false,
					width: MODAL_SIZE.widthMedium,
					verticalMargin: 80,
					bodyPadding: '16 24',
					layout: 'fit',
					items: [htmlContainer],
					buttons: [confirmBtn]
				},
				true
			);
			modal.show();
		};

		const fail = edi.rest.getErrorHandler('ediweb.personal.consent.error');

		const success = function (resp) {
			if (resp.data?.templateText) {
				showWindow(resp.data?.templateText);
			} else {
				fail(resp);
			}
		};

		const url = edi.utils.compileURL(edi.rest.services.PERSONAL_DATA_CONSENT.GET, {
			language: 'ru',
			messageType: 'POA_CONSENT_MESSAGE'
		});
		edi.rest.sendRequest(url, 'GET', null, success, fail);
	};

	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});
		tabPanel = createTabPanel({
			margin: '71 0 0 0'
		});

		let titleContainer = createContainer({
			layout: {
				type: 'hbox',
				align: 'stretch'
			},
			padding: 24,
			items: [
				createLabel({
					typography: 'heading_02',
					margin: '0 18 0 0',
					html: edi.i18n.getMessage('control.center.title'),
					plugins: [
						createHelpElementPlugin({
							url: 'https://ediweb.com/ru-ru/support/kb/1858#mcd',
							tooltip: edi.i18n.getMessage('control.center.title.tooltip')
						})
					]
				})
			]
		});

		modulePanel.add(titleContainer);

		let tabs = {};
		grids = {};
		options = {
			stores: {}
		};

		let poaModule = new edi.modules['powers.of.attorney.list']();
		grids.powersOfAttorney = poaModule.createPrincipalTab(moduleData);
		grids.powersOfAttorney.hintCls = CC_MAIN_TOUR_TARGET_1;
		grids.powersOfAttorneyConfidant = poaModule.createConfidantTab(moduleData);
		grids.powersOfAttorneyConfidant.hintCls = CC_MAIN_TOUR_TARGET_2;
		poaModule.addBtnClickHandler = function (btn) {
			//для проверки работы просто раскоментируй строчку ниже
			//edi.core.setExtraData('user.personalDataConsentPoa', 'false');
			if (edi.core.getExtraData('user.personalDataConsentPoa') !== 'true') {
				btn.hideMenu();
				showPoaPersonalDataConsent();
			}
		};

		grids.certificates = createUserCertificatesPanel(moduleData, { hint1Cls: CC_CERTIFICATES_TOUR_TARGET_1 });
		grids.certificates.hintCls = CC_MAIN_TOUR_TARGET_3;

		if (edi.permissions.hasPermission('CLIENT_FNS_APPLICATIONS_READ')) {
			grids.application_to_fns = createApplicationGrid(moduleData);
			grids.application_to_fns.hintCls = CC_MAIN_TOUR_TARGET_4;
		}
		if (edi.permissions.hasPermission('CLIENT_USER_ADMIN_CERTIFICATES_READ')) {
			grids.certificates_employees = createEmployeeCertificatesPanel(moduleData);
			grids.certificates_employees.hintCls = CC_MAIN_TOUR_TARGET_5;
		}

		tabs = Object.assign(tabs, grids);

		var isFirst = true;
		var activeTab = edi.utils.getObjectProperty(moduleData, 'initData.data.activeTab');
		if (activeTab && tabs.hasOwnProperty(activeTab)) {
			isFirst = false;
		}

		for (var i in tabs) {
			if (grids.hasOwnProperty(i)) {
				initTabWithGrid(
					i,
					grids[i],
					isFirst || activeTab === i,
					tabPanel,
					{
						title: tabs[i]?.tabTitle ? tabs[i].tabTitle : `ediweb.org.profile.tab.${i}`,
						callback: (tabName) => {
							gridNameTab = tabName;
							tryToStartTour();
						},
						cls: grids[i].hintCls
					},
					{
						hideIcon: true
					}
				);
				isFirst = false;
			}
		}

		modulePanel.add(tabPanel);
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
			tryToStartTour();
		}
		moduleData.tab.on('activate', tryToStartTour);
	};

	var isActiveTab = function (tab) {
		var tabId = tab && tab.getId ? tab.getId() : null;
		var activeTab = tabPanel && tabPanel.getActiveTab ? tabPanel.getActiveTab() : null;
		var tabPanelId = activeTab && activeTab.getId ? activeTab.getId() : null;
		return tabId && tabPanelId && tabId === tabPanelId;
	};

	/**
	 * Activates module's tab by it's name
	 * @param	{String}	newTabName
	 */
	var activateTab = function (newTabName) {
		let grid = grids[newTabName] || grids['powersOfAttorney'];
		let tab = grid?.tab;
		if (tab && !tab.isDestroyed && tabPanel && !tabPanel.isDestroyed) {
			if (isActiveTab(tab)) {
				gridDataChangedHandler(grid);
				return;
			}
			tabPanel.setActiveTab(tab);
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.application_to_fns.un('change', gridDataChangedHandler);
		edi.events.powerOfAttorney.un('change', poaGridDataChangedHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};

tours[CC_MODULE_NAME] = {
	getName: () => CC_MODULE_NAME,
	createSteps: function () {
		const tour = this;
		if (tour.externalOpts?.getGridName() !== 'certificates') {
			const steps = [];

			const step1 = document.querySelector(`.${CC_MAIN_TOUR_TARGET_1}`);
			step1 &&
				steps.push({
					getTitle: () => edi.i18n.getMessage('control.center.main.tour.step.1.title'),
					getContent: () => edi.i18n.getMessage('control.center.main.tour.step.1.content'),
					getTargetEl: () => step1,
					position: 'right'
				});

			const step2 = document.querySelector(`.${CC_MAIN_TOUR_TARGET_2}`);
			step2 &&
				steps.push({
					getTitle: () => edi.i18n.getMessage('control.center.main.tour.step.2.title'),
					getContent: () => edi.i18n.getMessage('control.center.main.tour.step.2.content'),
					getTargetEl: () => step2,
					position: 'right'
				});

			const step3 = document.querySelector(`.${CC_MAIN_TOUR_TARGET_3}`);
			step3 &&
				steps.push({
					getTitle: () => edi.i18n.getMessage('control.center.main.tour.step.3.title'),
					getContent: () => edi.i18n.getMessage('control.center.main.tour.step.3.content'),
					getTargetEl: () => step3,
					position: 'right'
				});

			const step4 = document.querySelector(`.${CC_MAIN_TOUR_TARGET_4}`);
			step4 &&
				steps.push({
					getTitle: () => edi.i18n.getMessage('control.center.main.tour.step.4.title'),
					getContent: () => edi.i18n.getMessage('control.center.main.tour.step.4.content'),
					getTargetEl: () => step4,
					position: 'right'
				});

			const step5 = document.querySelector(`.${CC_MAIN_TOUR_TARGET_5}`);
			step5 &&
				steps.push({
					getTitle: () => edi.i18n.getMessage('control.center.main.tour.step.5.title'),
					getContent: () => edi.i18n.getMessage('control.center.main.tour.step.5.content'),
					getTargetEl: () => step5,
					position: 'left'
				});

			let step6 = null;
			if (tour.externalOpts?.getGridName() === 'powersOfAttorney') {
				step6 = document.querySelectorAll(`.${CC_MAIN_TOUR_TARGET_6}`)[0];
			}
			if (tour.externalOpts?.getGridName() === 'powersOfAttorneyConfidant') {
				step6 = document.querySelectorAll(`.${CC_MAIN_TOUR_TARGET_6}`)[1];
			}
			step6 &&
				steps.push({
					getTitle: () => edi.i18n.getMessage('control.center.main.tour.step.6.title'),
					getContent: () => edi.i18n.getMessage('control.center.main.tour.step.6.content'),
					getTargetEl: () => step6,
					position: 'left'
				});

			return steps;
		} else if (tour.externalOpts?.getGridName() === 'certificates') {
			const steps = [];

			const step1 = document.querySelector(`.${CC_CERTIFICATES_TOUR_TARGET_1}`);
			step1 &&
				steps.push({
					getTitle: () => edi.i18n.getMessage('control.center.certificates.tour.step.1.title'),
					getContent: () => edi.i18n.getMessage('control.center.certificates.tour.step.1.content'),
					getTargetEl: () => step1,
					position: 'left'
				});

			return steps;
		}
	},
	onTourClosed: function () {
		const tour = this;
		//поставим флаг, что юзер прошел все страницы тура
		if (tour.currentStepNum === tour.steps.length - 1) {
			if (tour.externalOpts?.getGridName() !== 'certificates') {
				edi.core.setExtraData(CC_MAIN_TOUR_FLAG_NAME, 'true');
			} else if (tour.externalOpts?.getGridName() === 'certificates') {
				edi.core.setExtraData(CC_CERTIFICATES_TOUR_FLAG_NAME, 'true');
			}
		}
	}
};
