const createOrderResponse = function (config) {
	config = config ? config : {};
	var orderLines = [],
		summary = {};

	if (config.productValues) {
		summary = {
			TotalLines: config.productValues.TotalLines,
			TotalAmount: config.productValues.TotalAmount,
			TotalNetAmount: config.productValues.TotalNetAmount,
			TotalTaxAmount: config.productValues.TotalTaxAmount,
			TotalGrossAmount: config.productValues.TotalGrossAmount
		};

		for (var i = 0; i < config.productValues.products.length; i++) {
			var product = config.productValues.products[i];
			delete product['BuyerLocationCode'];
			var line = {};
			if (product[edi.constants.INTERNAL_LINE_ID]) {
				line[edi.constants.INTERNAL_LINE_ID] = product[edi.constants.INTERNAL_LINE_ID];
			}
			var LineReference = product['Line-Reference'];
			line['Line-Reference'] = LineReference;
			delete product['Line-Reference'];
			delete product[edi.constants.INTERNAL_LINE_ID];
			line['Line-Item'] = product;

			line['Line-Order'] = {
				BuyerItemDescription: product.BuyerItemDescription || null
			};
			delete product.BuyerItemDescription;

			const deliveryDate =
				product.DeliveryDateDate && product.DeliveryDateTime
					? `${product.DeliveryDateDate}T${product.DeliveryDateTime}`
					: null;
			delete product.DeliveryDateDate;
			delete product.DeliveryDateTime;

			const deliveryPeriod =
				product.DeliveryPeriodFrom && product.DeliveryPeriodTo
					? `${product.DeliveryPeriodFrom}-${product.DeliveryPeriodTo}`
					: null;
			delete product.DeliveryPeriodFrom;
			delete product.DeliveryPeriodTo;

			const stockReserve =
				product.StockReserveDate && product.StockReserveTime
					? `${product.StockReserveDate}T${product.StockReserveTime}`
					: null;
			delete product.StockReserveDate;
			delete product.StockReserveTime;

			line['Line-UltimateCustomer'] = {
				DeliveryDate: deliveryDate,
				DeliveryPeriod: deliveryPeriod,
				StockReserveDateTime: stockReserve,
				TermsOfDelivery: product.TermsOfDelivery || null,
				AddressText: product.AddressText || null,
				Floor: product.Floor || null,
				'Line-Transport': {
					ModeOfTransport: product.ModeOfTransport || null,
					VehicleHeight: product.VehicleHeight || null
				}
			};
			delete product.DeliveryDate;
			delete product.DeliveryPeriod;
			delete product.StockReserveDateTime;
			delete product.TermsOfDelivery;
			delete product.AddressText;
			delete product.Floor;
			delete product.ModeOfTransport;
			delete product.VehicleHeight;

			orderLines.push(line);
		}
	}

	return {
		DetailsOfTransport: config['DetailsOfTransport'],
		'OrderResponse-Header': config['OrderResponse-Header'],
		'OrderResponse-Parties': config.parties || {},
		'OrderResponse-Lines': {
			Line: orderLines
		},
		'OrderResponse-Summary': summary
	};
};

export { createOrderResponse };
