import { createModulePanel } from '@Components/panels';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';

Ext.namespace('edi.modules');
/**
 * Partners handling
 */
edi.modules['partners'] = function () {
	var moduleData, orgsGrid, filterForm, filterObject;

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});
		orgsGrid = createModuleGrid(edi.columns.get('partnersGrid'));
		filterForm = createFilterForm();
		modulePanel.add(filterForm);
		modulePanel.add(orgsGrid);
		moduleData.tab.add(modulePanel);
		if (typeof initCallBack === 'function') {
			initCallBack();
		}
	};
	/**
	 * Creates form for grid filtering
	 * @returns {Object}                Instance of form
	 */
	var createFilterForm = function () {
		const { formItemsMap, items } = edi.filters.config.partners.createFormItems();
		return createModuleFilterForm(
			{
				formItemsMap,
				items
			},
			fireSearch
		);
	};

	/**
	 * Initializes grid
	 * @param {Object} columns    Configuration of grid's columns
	 * @returns {Object}        Instance of grid
	 */
	var createModuleGrid = function (columns) {
		columns = columns ? columns : [];
		var actionColumn = createActionColumn();
		actionColumn ? columns.push(actionColumn) : null;

		return createGrid({
			proxyConfig: {
				type: 'ajax',
				url: String(edi.rest.services.USER.PARTNERS.GET)
			},
			storeConfig: {
				model: edi.models.getModel('ORGANIZATIONS'),
				sortOnLoad: true,
				sorters: {
					property: 'name',
					direction: 'ASC'
				},
				listeners: {
					load: function (store, records) {
						var sortedData =
							records && records.length ? edi.methods.filiations.sortOrgsByFiliations(records, true) : [];
						store.loadData(sortedData);
					}
				}
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				border: 1,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {
						var data = record.getData();
						edi.core.openModule('partner.details', data, record.get('name'));
					}
				}
			}
		});
	};

	/**
	 * Creates action column of the grid
	 * @returns {Object}    Config options for action column
	 */
	var createActionColumn = function () {
		var items = [];
		items.push({
			glyph: edi.constants.ICONS.DETAILS,
			handler: function (grid, rowIndex) {
				var recordData = grid.getStore().getAt(rowIndex).getData();
				edi.core.openModule('partner.details', recordData, recordData.name);
			}
		});
		return items.length !== 0
			? createActionsColumnConfig({
					items: items
			  })
			: null;
	};

	var fireSearch = function () {
		if (!filterObject) {
			filterObject = edi.filters.createGridFilter(
				edi.rest.services.USER.PARTNERS.GET,
				filterForm,
				orgsGrid,
				edi.filters.config.generic.createArgs
			);
		}
		filterObject.filter();
	};

	/**
	 * Routine that must be done before module destroy
	 */
	var onDestroy = function () {
		if (filterObject && filterObject.searchTimeout) {
			clearTimeout(filterObject.searchTimeout);
		}
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name, null);
		return true;
	};
};
