const moduleSettings = {
	TYPE: 'ROSEU_INVITATION',
	API: {
		LIST: {
			GET: edi.rest.prfx.c + 'roseu/invitations'
		},
		INVITATION: {
			GET: edi.rest.prfx.c + 'documents/{id}',
			GET_CONTENT: edi.rest.prfx.c + 'documents/{id}/content',
			POST: edi.rest.prfx.c + 'documents',
			PUT: edi.rest.prfx.c + 'documents/{id}',
			DELETE: edi.rest.prfx.c + 'documents/{id}',
			FILE_UPLOAD: edi.rest.prfx.c + 'files/upload',
			REJECT_COMPLETED: edi.rest.prfx.c + 'organization/roseu/rejectinvitation/{id}',
			ATTACHMENT_VIEW: {
				POST: edi.rest.prfx.c + 'documents/attachment/view/{id}'
			},
			FILE: {
				POST: edi.rest.prfx.c + 'roseu/invitations/files/upload'
			}
		},
		ORGANIZATIONS: {
			GET: edi.rest.prfx.c + 'organization/roseu'
		}
	},
	FILTER_STATES: [
		edi.constants.STATE.DRAFT,
		edi.constants.STATE.SENT,
		edi.constants.STATE.COMPLETED,
		edi.constants.STATE.REJECTED
	]
};

const roseuInvitationsEvents = new Ext.util.Observable();

const stateRenderer = function (value, meta, record) {
	//это копия рендерера статусов, но направление определяется по атрибутам, а не from\to org
	let getData = function (name, isNull) {
		let returnValue = isNull ? null : {};
		if (record?.get) {
			returnValue = record.get(name);
		} else if ('object' == typeof record && record[name]) {
			returnValue = record[name];
		}
		return returnValue;
	};
	let stateMap = edi.i18n.trMap.STATE;
	let doctype = moduleSettings.TYPE;
	let currentOrg = edi.core.getUserData().org;
	let attributes = record?.get
		? record.get('attributes')
		: record.hasOwnProperty('attributes')
		? record.attributes
		: {};
	let direction =
		currentOrg.fnsId === edi.utils.getAttributeByName(attributes, 'RECEIVER_FNSID')
			? edi.constants.DIRECTIONS.INCOMING
			: edi.constants.DIRECTIONS.OUTGOING;
	let values = {
		retValue: 'object' == typeof value && value.state ? value.state : value || '',
		addition: '',
		styleSfx: ''
	};
	if (!values.retValue === null) {
		values.retValue = 'NULL';
	}

	for (let i = 0; i < edi.renderers.utils.modifyStateTitleKeys.length; i++) {
		if ('function' == typeof edi.renderers.utils.modifyStateTitleKeys[i]) {
			edi.renderers.utils.modifyStateTitleKeys[i](getData, direction, doctype, attributes, values);
		}
	}

	let msgSuffixes = [direction, doctype, direction + '.' + doctype],
		msgKey,
		msg;
	if (stateMap && stateMap[values.retValue]) {
		let path = [values.retValue, direction, doctype];
		if (values.addition) {
			path.push(values.addition);
		}
		msgKey = edi.i18n.findKey(stateMap, path);
		if (msgKey) {
			msg = edi.i18n.getMessage(msgKey);
		}
	}
	if (!msg) {
		// If no map value found, use old logic for search
		do {
			let msgKeyWords = ['documents.status.' + values.retValue];
			if (msgSuffixes.length) {
				msgKeyWords.push(msgSuffixes[msgSuffixes.length - 1]);
			}
			if (values.addition) {
				msgKeyWords.push(values.addition);
			}
			msgKey = msgKeyWords.join('.');
			msg = edi.i18n.getMessage(msgKey);
		} while (msgSuffixes.pop() && msg === msgKey);
	}
	return values.retValue ? edi.renderers.baseStateRenderer(values.styleSfx + values.retValue, msg, meta) : '';
};

export { moduleSettings, roseuInvitationsEvents, stateRenderer };
