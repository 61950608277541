import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import {
	createCheckbox,
	createCombo,
	createDateField,
	createField,
	createFieldSet,
	createLabel,
	createNumberField,
	createTextField,
	createTimeField
} from '@Components/fields';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createCreateAndSendButton, createCreateSaveButton } from '@Components/buttons';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createModalCatalogSelect } from '@Edi/specialComponents/modalCatalogSelect';
import { createEditableGrid } from '@Core/specialComponents/editableGrid';
import { createButtonContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

/**
 * @author Vsevolod Liapin
 * Class for creation IFTMBF document
 */
Ext.namespace('edi.modules');
edi.modules['document.create.iftmbf'] = function () {
	var moduleData,
		isNumberAutoGenerated = false,
		parentId,
		id,
		userData = edi.core.getUserData(),
		isEdit = false,
		sellerOrg,
		productValues = { products: [] },
		productsGrid,
		sellerOrgValues,
		buyer,
		seller,
		organizationFieldsMap,
		referenceDocuments,
		shipFrom,
		form,
		afterSave,
		documentData = {};
	const orgConverter = edi.converters.convertOrgToLegacyPartie;
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		parentId = moduleData.initData.meta ? moduleData.initData.meta.id : null;
		renderData(initCallBack);

		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	/**
	 * Checks company data validation
	 */
	var isValidOrganization = function (params) {
		params = params || {};

		var organization = params.organization;
		var useOrganizationMap = params.useOrganizationMap;

		function getPropertyName(keyName) {
			return useOrganizationMap ? organizationFieldsMap[keyName] : keyName;
		}

		return !!edi.utils.getObjectProperty(organization, getPropertyName('company_iln'));
	};

	/**
	 * Checks company controls, and marks them, if not valid
	 */
	var validateCompanySelectors = function () {
		var isBuyerValid = seller.isValid();
		var isSellerValid = buyer.isValid();
		var isShipFromValid = shipFrom.isValid();
		return isBuyerValid && isSellerValid && isShipFromValid;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	var checkValid = function () {
		form.isValid();
		var validForm = !form.hasInvalidField();
		var validCompanies = validateCompanySelectors();
		var isValid = validForm && validCompanies && productValues.products.length;

		return isValid;
	};

	/**
	 * Create invoice form
	 */
	var createMainFormItems = function () {
		var numberDocument = createTextField({
			emptyText: isNumberAutoGenerated ? edi.i18n.getMessage('document.generation.numbers.text') : '',
			columnWidth: 0.7333,
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			maxLength: 35,
			value: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.documentNumber'),
			name: 'bookingMessageHeader.documentNumber'
		});

		var documentTime = edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.documentTime');
		var mainDataInputs = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.documentNumber'),
					input: numberDocument,
					mandatory: false,
					containerConfig: {
						columnWidth: documentTime ? 0.7 : 0.75
					}
				}),
				createDateField({
					emptyText: edi.i18n.getMessage('date'),
					margin: '0 0 0 5',
					columnWidth: documentTime ? 0.2 : 0.25,
					allowBlank: false,
					value: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.documentDate') || new Date(),
					name: 'bookingMessageHeader.documentDate'
				}),
				documentTime
					? createLabel({
							columnWidth: 0.1,
							text: documentTime,
							valueLabel: true
					  })
					: null
			]
		});

		var timeArrival = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.expectedDispatchDate'),
					input: createDateField({
						columnWidth: 0.5,
						allowBlank: false,
						emptyText: edi.i18n.getMessage('column.date'),
						value: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.expectedDispatchDate'),
						name: 'bookingMessageHeader.expectedDispatchDate'
					}),
					containerConfig: {
						columnWidth: 0.4
					}
				}),
				createTimeField({
					columnWidth: 0.2,
					margin: '0 0 0 5',
					allowBlank: true,
					emptyText: edi.i18n.getMessage('column.time'),
					value: edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.expectedDispatchTime'),
					name: 'bookingMessageHeader.expectedDispatchTime'
				})
			]
		});

		var autogenCheckbox = isNumberAutoGenerated
			? createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					items: [
						createField({
							title: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
							input: createCheckbox({
								columnWidth: 0.8,
								inputValue: true,
								checked: isNumberAutoGenerated,
								name: 'numberAutoGenerated',
								listeners: {
									change: function (checkbox, isChecked) {
										numberDocument.setEmptyText(
											isChecked
												? edi.i18n.getMessage('document.generation.numbers.text')
												: edi.i18n.getMessage('nr')
										);
										numberDocument.setValue('');
										numberDocument.setDisabled(isChecked);
										numberDocument.allowBlank = isChecked;
										numberDocument.validate();
									}
								}
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
			  })
			: null;

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				selectedRelationByMap: true,
				valuesByMap: true,
				useHiddenFields: true,
				disableAutoValidation: true,
				processValues: orgConverter
			});

			return createCompanySelector(selectorConfig);
		};

		organizationFieldsMap = {
			company_iln: 'iln',
			company_name: 'name',

			addr_category: 'type',
			addr_country: 'country',
			addr_zip: 'postalCode',
			addr_locality: 'locality',
			addr_block: 'housing',
			addr_addInf: 'additionalInformation',
			addr_area: 'district',
			addr_region_name: 'state',
			addr_region_code: 'stateCode',
			addr_city: 'cityName',
			addr_street: 'streetAndNumber',
			addr_flat: 'roomNumber',
			addr_home: 'houseNumber'
		};

		var getOrganizationFieldsMap = function (prefix) {
			var newOrganizationFieldsMap = {};
			for (var field in organizationFieldsMap) {
				if (organizationFieldsMap.hasOwnProperty(field)) {
					newOrganizationFieldsMap[field] = prefix + '.' + organizationFieldsMap[field];
				}
			}
			if (prefix == 'bookingMessageParties.buyer') {
				newOrganizationFieldsMap.contact_phone = prefix + '.phone';
				newOrganizationFieldsMap.company_inn = prefix + '.taxID';
			}

			return newOrganizationFieldsMap;
		};

		var createModalConf = function (selectorName) {
			var customFields = {},
				modalConf = {
					tabs: {
						main: {
							company_name: edi.selectors.defaults.company_name,
							company_gln: edi.selectors.defaults.company_gln
						},
						address: edi.selectors.COMMON_ORGANIZATION_ADDRESS
					}
				};

			if (selectorName == 'buyer') {
				customFields = {
					tabs: {
						main: {
							company_inn: edi.selectors.defaults.company_inn
						},
						contact: {
							contact_phone: edi.selectors.defaults.contact_phone
						}
					}
				};
			}

			return Ext.merge(modalConf, customFields);
		};

		var userOrganization = edi.converters.convertOrgToLegacyPartie(userData.org);
		var buyerInfo = edi.utils.getObjectProperty(documentData, 'bookingMessageParties.buyer');
		var isBuyerDefined = !!buyerInfo;
		var isValidBuyer = isValidOrganization({
			organization: buyerInfo || userOrganization,
			useOrganizationMap: isBuyerDefined,
			organizationType: 'buyer'
		});

		buyer = createCS({
			itemId: 'buyer',
			title: 'document.carrier',
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.fromOrg?.id || userData.org?.id,
				converter: orgConverter
			}),
			selectedRelationByMap: isEdit,
			useHiddenFields: true,
			fieldValues: isBuyerDefined ? documentData : userOrganization,
			valuesByMap: isBuyerDefined,
			selectedOrgValues: userOrganization,
			selectedOrg: userData.org,
			readOnly: isEdit,
			is_valid: isValidBuyer,
			disableAutoValidation: true,
			fieldsMap: getOrganizationFieldsMap('bookingMessageParties.buyer'),
			modalConf: Object.assign(
				{
					title: 'document.carrier'
				},
				createModalConf('buyer')
			),
			callback: function (values) {
				buyer.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'buyer'
				});
				checkValid();
			}
		});

		var sellerInfo = edi.utils.getObjectProperty(documentData, 'bookingMessageParties.seller');
		var isSellerDefined = !!sellerInfo;
		var isValidSeller = isValidOrganization({
			organization: sellerInfo,
			useOrganizationMap: isSellerDefined,
			organizationType: 'seller'
		});
		if (sellerOrg) {
			sellerOrgValues = edi.converters.convertOrgToLegacyPartie(sellerOrg);
		}

		seller = createCS({
			itemId: 'seller',
			title: 'document.seller',
			is_valid: isValidSeller,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: moduleData.initData.data?.toOrg?.id,
				converter: orgConverter
			}),
			fieldValues: documentData,
			readOnly: isEdit,
			relations: !isEdit && sellerOrg ? sellerOrg : edi.relations.getRelations(),
			relationsOnly: true,
			disableAutoValidation: true,
			allowReset: !isEdit && !isSellerDefined,
			selectedOrg: !isEdit && sellerOrg ? sellerOrgValues : isSellerDefined ? sellerInfo : undefined,
			selectedRelationOrg: sellerOrg ? sellerOrg : null,
			selectedOrgValues: sellerOrgValues,
			selectedRelationByMap: isEdit || sellerOrg,
			callback: function (values, org) {
				var orgId = org ? org.id : null;
				if (shipFrom.getOrgIdForLoccat() != orgId) {
					shipFrom.reset();
					shipFrom.setOrgIdForLoccat(orgId);
				}
				if (productsGrid.getPartnerId() != orgId) {
					productsGrid.productsGrid.getStore().removeAll();
				}
				productsGrid.setPartnerId(orgId);
				if (orgId && (!sellerOrg || sellerOrg.id != orgId)) {
					sellerOrg = org;
				} else if (!orgId) {
					sellerOrg = null;
				}

				seller.is_valid = isValidOrganization({
					organization: values,
					organizationType: 'seller'
				});
				checkValid();
			},
			fieldsMap: getOrganizationFieldsMap('bookingMessageParties.seller'),
			modalConf: Object.assign(
				{
					title: 'document.seller'
				},
				createModalConf('seller')
			)
		});

		var shipFromInfo = edi.utils.getObjectProperty(documentData, 'bookingMessageParties.shipFrom'),
			isShipFromDefined = !!shipFromInfo;

		shipFrom = createCS({
			itemId: 'shipFrom',
			title: 'document.iftmbf.ship.from',
			useHiddenFields: true,
			alwaysShowSelect: isEdit || !isShipFromDefined,
			allowReset: isEdit || !isShipFromDefined,
			allowBlank: true,
			ownOrg: isEdit || !isShipFromDefined,
			ownCatalog: true,

			fieldValues: isShipFromDefined ? documentData : undefined,
			valuesByMap: true,
			relationsFromLoccatByOrgId: !isEdit && isShipFromDefined ? undefined : sellerOrg ? sellerOrg.id : undefined,
			selectedOrg: isShipFromDefined ? shipFromInfo : undefined,
			selectedRelationByMap: true,
			is_valid: true,
			disableAutoValidation: true,
			fieldsMap: getOrganizationFieldsMap('bookingMessageParties.shipFrom'),
			modalConf: Object.assign(
				{
					title: 'document.iftmbf.ship.from'
				},
				createModalConf('shipFrom')
			),
			callback: function (values) {
				shipFrom.is_valid =
					isValidOrganization({
						organization: values,
						organizationType: 'cargoSender'
					}) || shipFrom.isEmptyValues();
				checkValid();
			}
		});

		var createReferenceDocumentsField = function () {
			return (referenceDocuments = createEditableGrid(
				{
					fields: [
						{
							title: edi.i18n.getMessage('document.iftmbf.referenceDocument.orderReferenceNumber'),
							name: 'orderReferenceNumber',
							type: 'text',
							minLength: 1,
							maxLength: 35
						},
						{
							title: edi.i18n.getMessage('document.iftmbf.referenceDocument.forwardingOrder'),
							name: 'forwardingOrder',
							type: 'text',
							minLength: 1,
							maxLength: 20
						}
					],
					limit: 20,
					autoScroll: true,
					model: 'IFTMBF_REF_DOCS',
					gridConfig: {
						margin: '0 5 0 5',
						cls: 'modal-grid',
						minHeight: 200,
						userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
						title: ' '
					}
				},
				edi.utils.getObjectProperty(
					documentData,
					'bookingMessageHeader.bookingMessageTransport.referenceDocuments',
					true
				)
			));
		};

		return [mainDataInputs, timeArrival, autogenCheckbox, createReferenceDocumentsField(), seller, buyer, shipFrom];
	};

	var createTransportInfoItems = function () {
		var poaDateValue = edi.utils.getObjectProperty(documentData, 'bookingMessageHeader.poaDate');
		if (poaDateValue) {
			poaDateValue = Ext.Date.format(new Date(poaDateValue), edi.constants.DATE_FORMAT.DATE_TIME_FNS);
		}
		var poaDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.poaDate'),
					input: createDateField({
						columnWidth: 0.45,
						format: edi.constants.DATE_FORMAT.DATE_TIME_FNS,
						submitFormat: edi.constants.DATE_FORMAT.DATE_TIME,
						emptyText: edi.i18n.getMessage('column.date'),
						value: poaDateValue,
						name: 'bookingMessageHeader.poaDate'
					}),
					containerConfig: {
						columnWidth: 0.6
					}
				})
			]
		});

		var driver = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.driver'),
					input: createTextField({
						columnWidth: 0.684,
						maxLength: 140,
						emptyText: edi.i18n.getMessage('full.name.abbr'),
						value: edi.utils.getObjectProperty(documentData, 'bookingMessageParties.carrier.name'),
						name: 'bookingMessageParties.carrier.name'
					}),
					containerConfig: {
						columnWidth: 0.7
					}
				}),
				createTextField({
					columnWidth: 0.3,
					margin: '0 0 0 5',
					maxLength: 140,
					emptyText: edi.i18n.getMessage('document.iftmbf.transportInfo.driverContacts'),
					value: edi.utils.getObjectProperty(documentData, 'bookingMessageParties.carrier.phone'),
					name: 'bookingMessageParties.carrier.phone'
				})
			]
		});

		var driverLicense = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.driverLicense'),
					input: createTextField({
						columnWidth: 0.778,
						maxLength: 140,
						value: edi.utils.getObjectProperty(documentData, 'bookingMessageParties.carrier.license'),
						name: 'bookingMessageParties.carrier.license'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var transportNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.TransportNumber'),
					input: createTextField({
						columnWidth: 0.778,
						maxLength: 18,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageParties.carrier.transportNumber'
						),
						name: 'bookingMessageParties.carrier.transportNumber'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var TemperatureMode = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.TemperatureMode'),
					input: createTextField({
						columnWidth: 0.5,
						maxLength: 70,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.temperatureMode'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.temperatureMode'
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.Tonnage'),
					input: createNumberField({
						columnWidth: 0.7,
						allowDecimals: true,
						regex: edi.utils.regExpDecimal3(12),
						regexText: edi.renderers.getCustomDecimalTranslation(9, 3),
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.tonnage'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.tonnage'
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var transportType = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.Capacity'),
					input: createNumberField({
						columnWidth: 0.5,
						maxLength: 5,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.capacity'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.capacity'
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.TransportType'),
					input: createCombo({
						columnWidth: 0.7,
						store: edi.stores.createInlineStore(
							[
								{
									id: 'РЭФ',
									name: edi.i18n.getMessage('document.iftmbf.transportInfo.TransportType.ref')
								},
								{
									id: 'ИЗОТЕРМ',
									name: edi.i18n.getMessage('document.iftmbf.transportInfo.TransportType.isoterm')
								}
							],
							'SIMPLE',
							undefined,
							{
								addEmptyRecord: true
							}
						),
						value:
							edi.utils.getObjectProperty(
								documentData,
								'bookingMessageHeader.bookingMessageTransport.transportType'
							) || '',
						name: 'bookingMessageHeader.bookingMessageTransport.transportType'
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var transportName = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.name'),
					input: createTextField({
						columnWidth: 0.778,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.name'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.name'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var transportTotal = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.plannedQuantity'),
					input: createNumberField({
						columnWidth: 0.5,
						maxLength: 5,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.plannedQuantity'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.plannedQuantity'
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.grossWeight'),
					input: createNumberField({
						columnWidth: 0.6,
						allowDecimals: true,
						regex: edi.utils.regExpDecimal3(12),
						regexText: edi.renderers.getCustomDecimalTranslation(9, 3),
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.grossWeight'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.grossWeight'
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var tailAndUnloadingType = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.tailLift'),
					input: createCombo({
						store: edi.stores.createInlineStore([
							{
								id: 'Yes',
								name: edi.i18n.getMessage('answer.yes')
							},
							{
								id: 'No',
								name: edi.i18n.getMessage('answer.no')
							}
						]),
						columnWidth: 0.5,
						value:
							edi.utils.getObjectProperty(
								documentData,
								'bookingMessageHeader.bookingMessageTransport.tailLift'
							) || 'No',
						name: 'bookingMessageHeader.bookingMessageTransport.tailLift'
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.unloadingType'),
					input: createTextField({
						columnWidth: 0.6,
						maxLength: 70,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.unloadingType'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.unloadingType'
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var lengthAndUnloadingHeight = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.lengthTransportType'),
					input: createTextField({
						columnWidth: 0.5,
						maxLength: 70,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.lengthTransportType'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.lengthTransportType'
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.unloadingHeight'),
					input: createNumberField({
						columnWidth: 0.6,
						allowDecimals: true,
						regex: edi.utils.regExpDecimal3(12),
						regexText: edi.renderers.getCustomDecimalTranslation(9, 3),
						maxLength: 70,
						value: edi.utils.getObjectProperty(
							documentData,
							'bookingMessageHeader.bookingMessageTransport.unloadingHeight'
						),
						name: 'bookingMessageHeader.bookingMessageTransport.unloadingHeight'
					}),
					containerConfig: {
						margin: '0 0 0 5',
						columnWidth: 0.5
					}
				})
			]
		});

		var TotalNetAmount = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.iftmbf.transportInfo.TotalNetAmount'),
					input: createNumberField({
						columnWidth: 0.778,
						allowBlank: false,
						allowDecimals: true,
						regex: edi.utils.regExpDecimal3(12),
						regexText: edi.renderers.getCustomDecimalTranslation(9, 3),
						value: edi.utils.getObjectProperty(documentData, 'bookingMessageSummary.totalNetAmount'),
						name: 'bookingMessageSummary.totalNetAmount'
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		return [
			createFieldSet({
				title: edi.i18n.getMessage('document.iftmbf.transportInfo'),
				padding: '10 0 0 0',
				items: [poaDate, transportNumber, driver, driverLicense]
			}),
			createFieldSet({
				title: edi.i18n.getMessage('document.iftmbf.carParams'),
				items: [TemperatureMode, transportType, tailAndUnloadingType, lengthAndUnloadingHeight]
			}),
			createFieldSet({
				title: edi.i18n.getMessage('document.iftmbf.cargoParams'),
				items: [transportName, transportTotal, TotalNetAmount]
			})
		];
	};

	var createUnloadingPoints = function () {
		var lines = edi.utils.getObjectProperty(documentData, 'bookingMessageLines.shipTos', true);
		if ('string' != typeof lines) {
			productValues.products = lines;
		}

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('documents.iftmbf.unloadingPoints'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: 'IFTMBF_LINE',
			gridColumnConfig: 'iftmbf_lines',
			data: productValues.products,
			lockIfNoPartner: true,
			hasTotal: false,
			callback: function (values) {
				moduleData.isChanged = true;
				productValues = values;
				checkValid();
			},
			configModalButtonsBefore: function () {
				return [
					{
						text: edi.i18n.getMessage('form.btn.select.product'),
						glyph: edi.constants.ICONS.SEARCH,
						handler: function () {
							if (sellerOrg) {
								createModalCatalogSelect(
									sellerOrg.id,
									Ext.bind(this.selectProductHandler, this),
									'delcat',
									true
								);
							} else {
								edi.core.showInfo(edi.i18n.getMessage('info.no.seller'));
							}
						}
					}
				];
			},
			selectProductHandler: function (product) {
				var fields = this.getFormFields();
				var address = Array.isArray(product.addresses) && product.addresses.length ? product.addresses[0] : {};

				fields.iln ? fields.iln.setValue(product.iln) : null;
				fields.name ? fields.name.setValue(String(product.name)) : null;
				fields.streetAndNumber ? fields.streetAndNumber.setValue(address.street) : null;
				fields.cityName ? fields.cityName.setValue(address.city) : null;
				fields.state ? fields.state.setValue(product.state) : null;
				fields.stateCode ? fields.stateCode.setValue(address.regionCode) : null;
				fields.country ? fields.country.setValue(address.countryCode) : null;
				fields.contact ? fields.contact.setValue(product.contactPerson) : null;
			},
			modalFormConfig: {
				title: 'documents.iftmbf.unloadingPoint',
				modalFields: [
					{
						title: edi.i18n.getMessage('documents.iftmbf.line.modal.tab.main.data'),
						items: [
							{
								title: edi.i18n.getMessage('column.iln'),
								name: 'iln',
								allowBlank: false,
								regex: edi.constants.VALIDATORS.GLN,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('column.name'),
								name: 'name'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbf.address'),
								maxLength: 140,
								name: 'streetAndNumber'
							},
							{
								title: edi.i18n.getMessage('column.city.name'),
								maxLength: 70,
								name: 'cityName'
							},
							{
								title: edi.i18n.getMessage('column.state'),
								maxLength: 35,
								name: 'state'
							},
							{
								title: edi.i18n.getMessage('column.state.code'),
								name: 'stateCode',
								type: 'combo',
								store: edi.stores.initRegionsStore(),
								valueField: 'id',
								displayField: 'display_name',
								anyMatch: true
							},
							{
								title: edi.i18n.getMessage('company.country.code'),
								name: 'country',
								type: 'combo',
								store: edi.stores.initValidCountryFullStore(),
								valueField: 'iso_2',
								value: 'RU',
								displayField: 'name'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbf.contact'),
								name: 'contact'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbc.estimatedDispatchDate'),
								name: 'estimatedDispatchDate',
								type: 'date'
							},
							{
								title: edi.i18n.getMessage('line.item.iftmbc.estimatedDispatchTime'),
								name: 'estimatedDispatchTime',
								type: 'time'
							}
						]
					}
				]
			}
		});
		productsGrid.setPartnerId(sellerOrg ? sellerOrg.id : null);

		return productsGrid;
	};

	/**
	 * Creates upd form
	 * @returns {Object}
	 */
	var createModuleForm = function () {
		var createButton = createCreateSaveButton(
			{
				handler: function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom'],
							[buyer, seller, shipFrom],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					save();
				}
			},
			isEdit
		);

		if (
			edi.permissions.hasPermission(
				edi.document.actions.getActionPermission(
					edi.constants.DOCUMENT_ACTIONS.SEND,
					edi.constants.DOCUMENT_TYPES.EDI_IFTMBF
				)
			)
		) {
			var createAndSendButton = createCreateAndSendButton(
				function () {
					if (
						!edi.utils.setFocusToDocumentsWithGrid(
							form,
							['buyer', 'seller', 'shipFrom'],
							[buyer, seller, shipFrom],
							productsGrid,
							!productValues.products.length
						)
					) {
						return;
					}
					afterSave = function (documentId, callback) {
						edi.rest.sendRequest(
							edi.utils.formatString(
								edi.rest.services.DOCUMENTS.SEND.PUT,
								{
									documentId: documentId
								},
								true
							),
							'PUT',
							Ext.encode({}),
							null,
							edi.document.actions.defaultFailureHandler(
								moduleData.tab,
								'document.error.in.sending.process'
							),
							callback
						);
					};
					save();
				},
				null,
				isEdit
			);
		}

		var twoColumnsLayout = createTwoColumnsLayout(createMainFormItems(), createTransportInfoItems());

		form = createForm({
			bodyPadding: 10,
			autoScroll: true,
			cls: 'edi-form document-add-form',
			items: [
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					items: [
						createField({
							input: createLabel({
								margin: '0 50 20 50',
								text: edi.i18n.getMessage('document.iftmbf.intro')
							}),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				}),
				twoColumnsLayout,
				createUnloadingPoints()
			],
			buttons: createButtonContainer({
				items: [createAndSendButton, createButton]
			})
		});

		form.on('validitychange', checkValid);
		form.isValid();
		edi.utils.processModuleFormChange(form, moduleData);

		return form;
	};

	var buildDocument = function (formValues) {
		var creatorUserName = userData.lastName ? userData.lastName : '';
		creatorUserName += userData.firstName ? ' ' + userData.firstName : '';
		creatorUserName += userData.patronymicName ? ' ' + userData.patronymicName : '';
		var referenceDocumentsRange = referenceDocuments.getStore().getRange();
		var refDocsData = [];
		if (referenceDocumentsRange.length) {
			for (var i = 0; i < referenceDocumentsRange.length; i++) {
				refDocsData.push(referenceDocumentsRange[i].data);
			}
		}
		var build = {
			documentHeader: {
				documentGuid: edi.utils.getObjectProperty(formValues, 'documentHeader.documentGuid')
			},
			documentParties: {
				sender: {
					iln: userData.org.iln,
					name: userData.org.name ? userData.org.name : undefined
				},
				receiver: {
					iln: sellerOrg.iln,
					name: sellerOrg.name ? sellerOrg.name : undefined
				},
				creator:
					creatorUserName || userData.eMail
						? {
								name: creatorUserName ? creatorUserName : undefined,
								email: userData.eMail ? userData.eMail : undefined
						  }
						: undefined
			},
			bookingMessageHeader: {
				documentFunctionCode: 9,
				poaDate: edi.utils.getObjectProperty(formValues, 'bookingMessageHeader.poaDate'),
				bookingMessageTransport: {
					tailLift: edi.utils.getObjectProperty(
						formValues,
						'bookingMessageHeader.bookingMessageTransport.tailLift'
					),
					crossbarRequired: edi.utils.getObjectProperty(
						formValues,
						'bookingMessageHeader.bookingMessageTransport.crossbarRequired'
					)
						? 'Yes'
						: 'No',
					beltsRequired: edi.utils.getObjectProperty(
						formValues,
						'bookingMessageHeader.bookingMessageTransport.beltsRequired'
					)
						? 'Yes'
						: 'No',
					fasteningDevicesRequired: edi.utils.getObjectProperty(
						formValues,
						'bookingMessageHeader.bookingMessageTransport.fasteningDevicesRequired'
					)
						? 'Yes'
						: 'No',
					otherRequired: edi.utils.getObjectProperty(
						formValues,
						'bookingMessageHeader.bookingMessageTransport.otherRequired'
					)
				}
			}
		};
		if (refDocsData.length) {
			build.bookingMessageHeader.bookingMessageTransport.referenceDocuments = refDocsData;
		}
		var poaDateValue = edi.utils.getObjectProperty(formValues, 'bookingMessageHeader.poaDate');
		if (poaDateValue) {
			build.bookingMessageHeader.poaDate = poaDateValue.replace(' ', 'T');
		}
		if (productValues.products.length) {
			build.bookingMessageLines = {
				shipTos: productValues.products
			};
			build.bookingMessageSummary = {
				totalLines: productValues.products.length
			};
		}

		delete formValues.bookingMessageHeader.bookingMessageTransport.referenceDocuments;

		return Ext.Object.merge(formValues, build);
	};
	/**
	 * Saves fns upd
	 */
	var save = function () {
		moduleData.tab.setLoading(edi.i18n.getMessage('loading.text'));
		var values = buildDocument(edi.utils.collectFormValues(form));
		edi.utils.clearEmptyValues(values);

		var numberAutoGenerated = values.numberAutoGenerated;
		delete values.numberAutoGenerated;

		edi.utils.setObjectProperty(values, 'bookingMessageHeader.documentTime', Ext.Date.format(new Date(), 'H:i'));

		var headerData = {
			data: Ext.encode(values),
			date: edi.utils.getObjectProperty(values, 'bookingMessageHeader.documentDate')
		};

		if (isNumberAutoGenerated && numberAutoGenerated) {
			headerData.autogen = true;
		} else {
			headerData.number = edi.utils.getObjectProperty(values, 'bookingMessageHeader.documentNumber');
		}

		var success = function (responseData) {
			var finishSaving = function () {
				moduleData.isChanged = false;
				moduleData.tab.setLoading(false);
				edi.document.actions.documentCreateProcessing(moduleData, responseData, isEdit);
				if (id) {
					edi.events.documents.fireEvent('change', {
						id: id
					});
				} else {
					edi.events.documents.fireEvent('create');
				}
			};

			var documentId = id || responseData.data.id;
			if (afterSave && documentId) {
				afterSave(documentId, function () {
					edi.document.actions.getDocumentHeaderData(documentId, function (headerData) {
						headerData ? (responseData.data = headerData) : null;
						finishSaving();
					});
				});
			} else {
				finishSaving();
			}
		};
		var failure = edi.document.actions.createSaveErrorHandler(
			isEdit,
			moduleData,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBF
		);
		edi.document.actions.processDocument(
			sellerOrg.id,
			undefined,
			edi.constants.DOCUMENT_TYPES.EDI_IFTMBF,
			parentId,
			id,
			success,
			failure,
			headerData
		);
	};
	/**
	 * Creates action pane above the data panel
	 */
	var createModuleActionsPanel = function () {
		return createActionsPanel();
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		var data = moduleData.initData.data;
		var modulePanel = createAddModulePanel();
		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		if (data && data.id) {
			isEdit = true;
			id = data.id;
			parentId = data.id;
			sellerOrg = data.toOrg;

			edi.rest.sendRequest(
				edi.utils.formatString(
					edi.rest.services.DOCUMENTS.CONTENT.GET,
					{
						documentId: id
					},
					true
				),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						documentData = data.data;
						var form = createModuleForm();
						modulePanel.add(form);
						moduleData.tab.add(createModuleActionsPanel());
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		} else {
			var createModulePanel = function () {
				if (data) {
					sellerOrg = data.toOrg ? data.toOrg : null;
				}

				modulePanel.add(createModuleForm());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);
				seller.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			};
			if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
				edi.rest.sendRequest(
					edi.utils.formatString(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET_BY_TYPE.GET, {
						doctype: edi.constants.DOCUMENT_TYPES.EDI_IFTMBF
					}),
					'GET',
					undefined,
					function (docNumberSettings) {
						if (docNumberSettings && docNumberSettings.data) {
							isNumberAutoGenerated =
								docNumberSettings.data &&
								docNumberSettings.data.policy &&
								'ALLOW' == docNumberSettings.data.policy;
						}
						createModulePanel();
					},
					failure
				);
			} else {
				createModulePanel();
			}
		}
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
