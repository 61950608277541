/**
 * Подмножества edi.constants.SIGN_DOCUMENTS.AUTHORITY_AREAS_FOR_DOC_TYPES для исходящих УПД
 *
 * @param	{String}	bpName
 * @returns	{Number[]}	array of authority areas ids
 */
let getAuthorityAreasByBpName = function (bpName) {
	let areaIds = [];
	switch (bpName) {
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14:
		//case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DECREE_14_WITHOUT_OPERATOR:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14: {
			areaIds = [0, 4, 5, 6];
			break;
		}
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_IN_BPV4:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_OUT_BPV4:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_BPV4: {
			areaIds = [1, 2, 3, 4, 5, 6];
			break;
		}
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_IN:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_IN_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT:
		//case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_DOP_DECREE_14_WITHOUT_OPERATOR:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_SCHFDOP_BPV4: {
			areaIds = [0, 1, 2, 3, 4, 5, 6];
			break;
		}
		default:
			break;
	}
	return areaIds;
};
const getInfoInvoicesByBpName = function (bpName) {
	let invoiceIds = [];
	switch (bpName) {
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14: {
			invoiceIds = [1, 2, 3];
			break;
		}
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_IN:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_IN_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT_DECREE_14:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_IN_BPV4:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_OUT_BPV4:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_SCHFDOP_BPV4:
		case edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_BPV4: {
			invoiceIds = [4, 5, 6, 7, 8];
			break;
		}
		default:
			break;
	}
	return invoiceIds;
};

const PURPOSES_TYPES = {
	VID_OBOBOROTA: 'VID_OBOBROTA',
	SV_VIBITIYA: 'SV_VIBITIYA'
};

const purposeTypesValueMap = {
	[PURPOSES_TYPES.VID_OBOBOROTA]: 'ВидОборотаМАРК',
	[PURPOSES_TYPES.SV_VIBITIYA]: 'СвВыбытияМАРК'
};

const purposesMap = {
	[PURPOSES_TYPES.VID_OBOBOROTA]: [
		{
			docBpNames: [
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_SCHFDOP_BPV4
			],
			purposes: ['4', '5', '6', '7']
		},
		{
			docBpNames: [
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_BPV4,
				edi.constants.DOCUMENT_BP_NAMES.UPD_DOP_ROAMING_IN_BPV4,
				edi.constants.DOCUMENT_BP_NAMES.UPD_DOP_ROAMING_OUT_BPV4
			],
			purposes: ['4', '5', '6', '7', '8', '9']
		}
	],
	[PURPOSES_TYPES.SV_VIBITIYA]: [
		{
			docBpNames: [
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_SCHFDOP_BPV4,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_IN_BPV4,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_OUT_BPV4,
				edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_BPV4
			],
			purposes: ['1', '2']
		}
	]
};

/**
 * Returns type of purpose by value from backend
 * @param	{String}	value
 * @returns	{string}	type from map or value itself
 */
const getPurposeTypeFromValue = function (value) {
	return (
		Object.entries(purposeTypesValueMap)
			.filter(([_, val]) => val === value)
			.map(([key, _]) => key)[0] || value
	);
};

/**
 * Returns value of type by map for sending to backend
 * @param	{String}	type
 * @returns	{string}	value of type or type itself if it doesn't present in map
 */
const getPurposeTypeValue = function (type) {
	return purposeTypesValueMap[type] || type;
};

/**
 * Creates array of "purposes types" with translations
 * @returns	{{name: String, id: String}[]}	array of types
 */
const getPurposesTypes = function () {
	return [
		{
			id: PURPOSES_TYPES.VID_OBOBOROTA,
			name: getPurposeTypeTranslation(PURPOSES_TYPES.VID_OBOBOROTA)
		},
		{
			id: PURPOSES_TYPES.SV_VIBITIYA,
			name: getPurposeTypeTranslation(PURPOSES_TYPES.SV_VIBITIYA)
		}
	];
};

const getPurposeTypeTranslation = function (type) {
	return edi.i18n.getMessage(`documents.fns_upd.purpose_type.${type}`);
};

/**
 * Creates array of "purposes" by type for each docFunction by map
 * @param	{String}	type
 * @param	{String}	bpName
 * @returns	{{name: String, id: String}[]}	array of purposes
 */
const getPurposesByTypeAndFunction = function (type, bpName) {
	let rulesByType = purposesMap[type] || [];
	let rule = rulesByType.filter((r) => r.docBpNames.some((n) => n === bpName))[0] || {};
	return (rule.purposes || []).map((p) => ({
		id: p,
		name: getPurposeTranslation(p)
	}));
};

const getPurposeTranslation = function (value) {
	let translationKey = `documents.fns_upd.purpose.${value}`;
	let translation = edi.i18n.getMessage(translationKey);
	return translationKey === translation ? value : translation;
};

let documentFunctionsComboItems = () => {
	return [
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
		edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14
	];
};

const SCHF_BusinessProcess = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHF_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_IN_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHF_ROAMING_OUT_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVRK_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SVZK_DECREE_14
];
const isSCHF_BusinessProcess = function (businessProcessName) {
	return SCHF_BusinessProcess.some((it) => it === businessProcessName);
};
const DOP_BusinessProcess = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_DOP,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_BPV4,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_IN_BPV4,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_DOP_ROAMING_OUT_BPV4,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.DOP_ROAMING_OUT_DECREE_14
];
const isDOP_BusinessProcess = function (businessProcessName) {
	return DOP_BusinessProcess.some((it) => it === businessProcessName);
};

const SCHFDOP_BussinessProcess = [
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_IN,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_SCHFDOP_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_IN_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.SCHFDOP_ROAMING_OUT_DECREE_14,
	edi.constants.DOCUMENT_BP_NAMES.EDI_FNS_UPD.UPD_SCHFDOP_BPV4
];
const isSCHFDOP_BussinessProcess = function (businessProcessName) {
	return SCHFDOP_BussinessProcess.some((it) => it === businessProcessName);
};

const hasSchfInBpName = function (rawBpName) {
	return ['SCHFDOP', 'SCHF'].some((it) => it === edi.constants.FNS_BPNAMES_PERMISSIONS_MAP[rawBpName]);
};

const isEwDocWithoutSomeActions = function (docType) {
	return (edi.constants.EW_DOCUMENT_TYPES_NO_SOME_ACTIONS || []).some((it) => it === docType);
};

export {
	PURPOSES_TYPES,
	getAuthorityAreasByBpName,
	getInfoInvoicesByBpName,
	getPurposeTypeFromValue,
	getPurposesTypes,
	getPurposeTypeTranslation,
	getPurposeTypeValue,
	getPurposesByTypeAndFunction,
	getPurposeTranslation,
	documentFunctionsComboItems,
	SCHF_BusinessProcess,
	isSCHF_BusinessProcess,
	DOP_BusinessProcess,
	isDOP_BusinessProcess,
	SCHFDOP_BussinessProcess,
	isSCHFDOP_BussinessProcess,
	hasSchfInBpName,
	isEwDocWithoutSomeActions
};
