import { createCompanySelector } from '@Components/company.selector.js';
import { createField, createFieldSet, createLabel } from '@Components/fields';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';

/**
 * @author Darya Matyukha
 * Class for IFTMBC_APPROVAL details
 */
Ext.namespace('edi.modules');
edi.modules['document.details.iftmbc_approval'] = function () {
	var moduleData,
		buyerOrg,
		sellerOrg,
		documentData = {};

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		buyerOrg = moduleData.initData.data.toOrg;
		sellerOrg = moduleData.initData.data.fromOrg;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	var createLabelFn = function (labelConf, containerColumnWidth) {
		var title = labelConf.title,
			fieldPath = labelConf.valuePath;

		delete labelConf.title;
		delete labelConf.valuePath;

		Ext.applyIf(labelConf, {
			valueLabel: true,
			columnWidth: 0.75,
			text: edi.utils.getObjectProperty(documentData || {}, fieldPath)
		});

		return createField({
			title: edi.i18n.getMessage(title),
			input: createLabel(labelConf),
			containerConfig: {
				columnWidth: containerColumnWidth || 1,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
			}
		});
	};

	var createDriverBlock = function () {
		var mainInfo = [
			createLabelFn({
				title: 'iftmbc.approval.driver.fio',
				valuePath: 'drivers.driver.fio'
			}),
			createLabelFn({
				title: 'iftmbc.approval.driver.licence',
				valuePath: 'drivers.driver.driverLicense'
			}),
			createLabelFn({
				title: 'iftmbc.approval.driver.phone',
				valuePath: 'drivers.driver.phone'
			})
		];

		var passportInfo = [
			createLabelFn({
				title: 'iftmbc.approval.driver.passport.serial',
				valuePath: 'drivers.driver.pasport.series'
			}),
			createLabelFn({
				title: 'iftmbc.approval.driver.passport.number',
				valuePath: 'drivers.driver.pasport.number'
			}),
			createLabelFn({
				title: 'iftmbc.approval.driver.passport.issuedBy',
				valuePath: 'drivers.driver.pasport.issuedBy'
			}),
			createLabelFn({
				title: 'iftmbc.approval.driver.passport.issueDate',
				valuePath: 'drivers.driver.pasport.date'
			})
		];

		return [
			createFieldSet({
				collapsible: false,
				padding: '5 0',
				title: edi.i18n.getMessage('iftmbc.approval.driver.info'),
				items: mainInfo
			}),
			createFieldSet({
				padding: '5 0',
				collapsible: false,
				title: edi.i18n.getMessage('iftmbc.approval.driver.passport'),
				items: passportInfo
			})
		];
	};

	var createTransportBlock = function () {
		var transportNumber = createLabelFn({
			title: 'iftmbc.approval.car.number',
			valuePath: 'carInfo.number'
		});

		var transportBrand = createLabelFn({
			title: 'iftmbc.approval.car.brand',
			valuePath: 'carInfo.carBrand'
		});

		var trailerNumber = createLabelFn({
			title: 'iftmbc.approval.trailer.number',
			valuePath: 'carInfo.trailerNumber'
		});

		return createFieldSet({
			padding: '5 0',
			title: edi.i18n.getMessage('iftmbc.car.info'),
			items: [transportBrand, transportNumber, trailerNumber]
		});
	};

	var createDetailsPanel = function () {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noSumm: true,
			noUsage: true
		});

		var documentNumber = createLabelFn(
			{
				title: 'document.iftmbc.documentNumber',
				valuePath: 'offerNumber'
			},
			0.6
		);

		var offerId = createLabelFn(
			{
				title: 'document.iftmbc.offerId',
				valuePath: 'idOffer'
			},
			0.6
		);

		var seller = createCompanySelector({
			labelConfig: {
				title: 'document.seller',
				columnWidth: 0.25
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			columnWidth: 0.75,
			margin: '0 10 5 0',
			is_valid: true,
			readOnly: true,
			fieldValues: edi.converters.convertOrgToLegacyPartie(sellerOrg)
		});

		var buyer = createCompanySelector({
			labelConfig: {
				title: 'document.buyer',
				columnWidth: 0.25
			},
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			columnWidth: 0.75,
			margin: '0 10 5 0',
			is_valid: true,
			readOnly: true,
			fieldValues: edi.converters.convertOrgToLegacyPartie(buyerOrg)
		});

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [
				headPanel,
				createTwoColumnsLayout(
					[documentNumber, offerId, seller, buyer].concat(createDriverBlock()),
					[createTransportBlock()],
					0.5,
					{
						items1Conf: {
							margin: '0 10 0 10'
						},
						items2Conf: {
							margin: '0 10 0 10'
						}
					}
				)
			]
		});
	};

	var createModuleActionsPanel = function () {
		var data = moduleData.initData.data;
		documentData.id = data.id;
		var actionsPanel = createActionsPanel();
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);

		var excludeActions = {};
		excludeActions[edi.constants.DOCUMENT_ACTIONS.PACKAGING] = true;

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			excludeActions: excludeActions,
			actionProps: {
				SIGN: {
					methodAddOptions: {
						useBeforeAction: data.state == edi.constants.STATE.DRAFT
					}
				},
				EDIT: {
					moduleName: 'document.create.iftmbc_approval'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				REJECT: {
					success: function () {
						edi.modulesHandler.removeModule(moduleData);
						edi.events.documents.fireEvent('change');
					}
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					documentData = data.data;
					var continueLoading = function () {
						modulePanel.add(createDetailsPanel());
						var actionsPanel = createModuleActionsPanel();
						moduleData.tab.add(actionsPanel);
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						} else {
							moduleData.tab.setLoading(false);
						}
					};
					continueLoading();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
