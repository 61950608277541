import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { RECADV_LINE_MODEL, RECADV_SUMMARY_MODEL } from './models';
import { RECADV_LINE_DETAIL_COLUMN, RECADV_SUMMARY_DETAIL_COLUMN } from './columns';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import {
	createContainer,
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createMaxWidthContainerForDetail
} from '@UIkit/components/panels';
import { recadvSelectors } from '@Edi/modules/documents/LEGACY_REC_ADV/selectors';
import { isPacking } from '@Edi/modules/documents/LEGACY_DES_ADV/definitions';

Ext.namespace('edi.modules');
edi.modules['document.details.recadv'] = function () {
	let moduleData,
		docContent,
		productValues = {
			products: []
		},
		productsGrid,
		customFieldsContainer,
		topPath = '//Document-ReceivingAdvice/';
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	const createHeadPanel = function () {
		return createDocumentHeaderPanel(moduleData.initData, {
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
		});
	};

	const createMainDataBlock = function (content) {
		return createFieldBlockForDetails({
			cls: 'edi-form-maxwidth',
			items: [
				createLabelBlockForDetails({
					gap: [8, 16],
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					contents: [
						{
							title: edi.i18n.getMessage('recadv.number'),
							text: edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.ReceivingAdviceNumber')
						},
						{
							title: edi.i18n.getMessage('recadv.date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.ReceivingAdviceDate')
							)
						},
						{
							title: edi.i18n.getMessage('goods.receipt.date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.GoodsReceiptDate')
							)
						},
						{
							isNewLine: true,
							title: edi.i18n.getMessage('functional.document.code'),
							text: edi.i18n.getMessage(
								'functional.document.code.' +
									edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.DocumentFunctionCode')
							)
						}
					]
				})
			]
		});
	};

	const createOrderNumberBlock = function (content) {
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('to.order'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('buyer.order.number'),
							text: edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.BuyerOrderNumber')
						},
						{
							title: edi.i18n.getMessage('order.date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.BuyerOrderDate')
							)
						}
					]
				})
			]
		});
	};

	const createPartiesBlock = function (content) {
		const parties = edi.utils.getObjectProperty(content, 'ReceivingAdvice-Parties');
		const createOS = function (selectorConfig) {
			Object.assign(selectorConfig, {
				showDetailsButton: true,
				useHiddenFields: true,
				fieldsMapOnly: true,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				fieldValues: parties
			});

			return createOrgSelector(selectorConfig);
		};

		const buyer = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.recadv.buyer'),
			items: [
				createOS({
					selectedOrg: edi.utils.getOrg({
						orgId: moduleData.initData.data.fromOrg.id
					}),
					onFormCreate: edi.selectors.fieldControls.updateInnField,
					fieldsMap: recadvSelectors.getCommonDetailsFieldsMap('Buyer'),
					modalConf: recadvSelectors.getBuyerModalCong()
				})
			]
		});

		const seller = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.recadv.seller'),
			items: [
				createOS({
					selectedOrg: edi.utils.getOrg({
						orgId: moduleData.initData.data.toOrg.id
					}),
					onFormCreate: edi.selectors.fieldControls.updateInnField,
					fieldsMap: recadvSelectors.getCommonDetailsFieldsMap('Seller'),
					modalConf: recadvSelectors.getSellerModalConf()
				})
			]
		});

		const delivery = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.delivery'),
			items: [
				createOS({
					fieldsMap: recadvSelectors.getCommonDetailsFieldsMap('DeliveryPoint'),
					modalConf: recadvSelectors.getDeliveryModalConf()
				})
			]
		});

		const shipFrom = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.ship.from'),
			items: [
				createOS({
					fieldsMap: recadvSelectors.getShipFromFieldsMap('ShipFrom'),
					modalConf: recadvSelectors.getShipFromModalConf()
				})
			]
		});

		const ultimateCustomer = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.ultimate.customer'),
			items: [
				createOS({
					fieldsMap: recadvSelectors.getUltimateCustomerFieldsMap('UltimateCustomer'),
					modalConf: recadvSelectors.getUltimateCustomerModalConf()
				})
			]
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('document.section.parties'),
			collapsible: true,
			items: [
				createMaxWidthContainerForDetail({
					layout: {
						type: 'grid',
						area: [[6, 6], [6, 6], [6]]
					},
					items: [buyer, seller, delivery, shipFrom, ultimateCustomer]
				})
			]
		});
	};

	const createAdditionalInformationBlock = function (content) {
		const referenceData = edi.utils.getObjectProperty(
			content,
			'ReceivingAdvice-Header.Reference.Reference-Elements.Reference-Element',
			true
		);
		let contractNumberValue;
		let referenceIdValue;
		let referenceTypeValue;
		let sapCode;
		referenceData?.forEach((ref) => {
			const type = ref?.['Reference-Type'];
			const id = ref?.['Reference-Id'];
			if (type === 'CN') {
				contractNumberValue = id;
			}
			if (!referenceIdValue && (type === 'FOR' || type === 'FRC')) {
				referenceTypeValue = type;
				referenceIdValue = id;
			}
			if (!sapCode && type === 'DM') {
				sapCode = id;
			}
		});

		const contractNumber = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.select.contract'),
			items: [
				createLabelForDetails({
					title: edi.i18n.getMessage('field.name.number'),
					text: contractNumberValue
				})
			],
			containerConfig: {
				columnWidth: 1
			}
		});

		const invoiceNumberDate = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.recadv.invoice'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.InvoiceNumber')
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.InvoiceDate')
							)
						}
					]
				})
			]
		});

		const despatchNumberDate = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.recadv.waybill'),
			cls: 'edi-form-maxwidth',
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.DespatchNumber')
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.DespatchDate')
							)
						}
					]
				})
			]
		});

		const goodIssueNumber = edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.GoodsIssueNumber');
		const goodIssueDate = edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.GoodsIssueDate');
		const reasonCode = edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.ReasonCode');
		const goodsBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('recadv.goods.label'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('recadv.goods.issue.number'),
							text: goodIssueNumber
						},
						{
							title: edi.i18n.getMessage('recadv.goods.issue.date'),
							text: goodIssueDate
						},
						{
							isNewLine: true,
							title: edi.i18n.getMessage('recadv.goods.reason.code'),
							text: reasonCode
						},
						{
							isNewLine: true,
							title: edi.i18n.getMessage('documents.recadv.refference.type.' + referenceTypeValue),
							text: referenceIdValue
						},
						{
							isNewLine: true,
							title: edi.i18n.getMessage('document.external.id.label'),
							text: sapCode
						}
					]
				})
			]
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.special.identifiers'),
			collapsible: true,
			layout: {
				type: 'grid',
				gap: [24, 16]
			},
			items: [contractNumber, invoiceNumberDate, despatchNumberDate, goodsBlock]
		});
	};

	/**
	 * Дополнительные поля
	 */
	const createCustomFieldsContainer = function () {
		return (customFieldsContainer = createContainer({}));
	};

	const createRemarks = function (content) {
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.remark'),
			layout: {
				type: 'grid',
				area: [[6]]
			},
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(content, 'ReceivingAdvice-Header.Remarks')
				})
			]
		});
	};

	const createProductsGrid = function (content) {
		const lines = edi.utils.getObjectProperty(content, 'ReceivingAdvice-Lines.Line', true);
		if ('string' != typeof lines) {
			for (let i = 0; i < lines.length; i++) {
				const values = lines[i]['Line-Item'];
				values['TaxRate'] = edi.renderers.taxRateString(values['TaxRate']);
				productValues.products.push(values);
			}
		}

		productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('receiving.advice.items'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: RECADV_LINE_MODEL,
			totalModel: RECADV_SUMMARY_MODEL,
			gridColumnConfig: RECADV_LINE_DETAIL_COLUMN,
			totalColumnConfig: RECADV_SUMMARY_DETAIL_COLUMN,
			data: productValues.products,
			readOnly: true,
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
			docData: content,
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			modalFormConfig: {
				title: 'document.recadv.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						customFieldTab: 'PRODUCT',
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN'
							},
							{
								title: edi.i18n.getMessage('column.quantity.received'),
								name: 'QuantityReceived',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('column.quantity.accepted'),
								name: 'QuantityAccepted',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.not.accepted'),
								name: 'QuantityNotAccepted',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.delivered'),
								name: 'QuantityDelivered',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.damaged'),
								name: 'QuantityDamaged',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.over.ordered'),
								name: 'QuantityOverOrdered',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.undelivered'),
								name: 'QuantityUndelivered',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.not.accept.reason'),
								name: 'ReasonCode',
								type: 'string'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.product.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								name: 'SupplierItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.country.of.origin'),
								name: 'CountryOfOrigin',
								type: 'combo',
								store: edi.stores.initOrgCountryStoreISO2()
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						customFieldTab: 'PRICES',
						items: [
							{
								title: edi.i18n.getMessage('line.item.unit.net.price'),
								name: 'UnitNetPrice',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.fns.tax.rate'),
								name: 'TaxRate'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								name: 'UnitGrossPrice',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								allowDecimals: true,
								name: 'NetAmount',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								allowDecimals: true,
								name: 'TaxAmount',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								allowDecimals: true,
								name: 'GrossAmount',
								type: 'number'
							}
						]
					}
				]
			}
		});

		return productsGrid;
	};

	/**
	 * Creates receiving advice form
	 * @returns {Object}
	 */
	const createDetailsPanel = function (content) {
		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				createHeadPanel(content),
				createMainDataBlock(content),
				createOrderNumberBlock(content),
				createPartiesBlock(content),
				createAdditionalInformationBlock(content),
				createRemarks(content),
				createCustomFieldsContainer(),
				createProductsGrid(content)
			]
		});
	};

	/**
	 * Creates action pane
	 */
	const createModuleActionsPanel = function (content) {
		const header = moduleData.initData.data;
		content.id = header.id;
		const actionsPanel = createActionsPanel();
		const direction = edi.utils.getDocumentDirection(header.toOrg, header.fromOrg);
		const needSignatures = edi.document.actions.getSignCount(header);
		const needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(header, edi.constants.CONTRACT_SIDES.CONSUMER);
		let signaturesCount = needSignatures + needSignatures2 - header.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero

		const createDocConfig = [
			{
				permission: 'CREATE_LEGACY_INVOICE',
				title: 'action.invoice',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
				config: {
					isFromTransformation: true,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			},
			{
				permission: 'CREATE_EDI_FNS_UPD',
				title: 'action.upd',
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UPD,
				createCustomMethod: edi.document.actions.methodCreateUPDfromDocuments(
					header.id,
					direction,
					productValues.products,
					'UnitGrossPrice',
					moduleData
				),
				config: {
					isFromTransformation: false,
					buyerOrg: moduleData.initData.data.fromOrg
				}
			}
		];

		const customButtons = [];
		const basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_FROM',
			header,
			moduleData,
			content,
			'recadv',
			createDocConfig
		);
		if (basedDocuments?.length) {
			customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: header,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.recadv'
				},
				REFRESH: {
					handler: function () {
						changeHandler(header);
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					hideDefaultExport: true,
					addOriginalExport: true,
					addTransitExport: true
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
				productValues = {
					products: []
				};
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		const data = moduleData.initData.data;
		moduleData.tab.setLoading();

		const continueWithRendering = function () {
			moduleData.tab.removeAll();
			const modulePanel = createDetailsModulePanel();
			modulePanel.add(createDetailsPanel(docContent));
			moduleData.tab.add(createModuleActionsPanel(docContent));
			moduleData.tab.add(modulePanel);
			if (data.toOrg?.id && data.fromOrg?.id) {
				edi.methods.custom_fields.initCustomFields({
					docType: edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
					toOrgId: data.toOrg.id,
					fromOrgId: data.fromOrg.id,
					docId: docContent.id,
					container: customFieldsContainer,
					grid: productsGrid,
					topPath: topPath,
					readOnly: true
				});
			}
			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};
		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					docContent = data.data;
					continueWithRendering();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
