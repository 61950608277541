const FILE_NAME_ATTACH_DOCUMENTS_COLUMN_CONFIG_NAME = 'file_name_attach_documents';

edi.columns.addColumns({
	[FILE_NAME_ATTACH_DOCUMENTS_COLUMN_CONFIG_NAME]: {
		file_name: {
			text: 'receipt.file.name',
			dataIndex: 'FILE_ID',
			flex: 1
		}
	}
});

const getFileNameAttachDocumentsColumns = () => edi.columns.get(FILE_NAME_ATTACH_DOCUMENTS_COLUMN_CONFIG_NAME);

export { getFileNameAttachDocumentsColumns, FILE_NAME_ATTACH_DOCUMENTS_COLUMN_CONFIG_NAME };
