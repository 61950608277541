import { createOrgSelector } from '@Components/orgSelector/OrgSelector';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createFormForModule } from '@Components/panels';
import { RETANN_LINE_COLUMN, RETANN_SUMMARY_COLUMN } from './columns';
import { RETANN_LINE_MODEL, RETANN_SUMMARY_MODEL } from './models';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import {
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createFieldBlock,
	createMaxWidthContainerForDetail
} from '@UIkit/components/panels';
import { createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';

Ext.namespace('edi.modules');
edi.modules['document.details.retann'] = function () {
	let moduleData, linesData;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}  initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	const createHeadPanel = function () {
		return createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			noUsage: !!(moduleData.initData && moduleData.initData.isDocumentSharing)
		});
	};

	const createMainDataBlock = function (docContent) {
		return createFieldBlockForDetails({
			cls: 'edi-form-maxwidth',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('retann.number'),
							text: edi.utils.getObjectProperty(
								docContent,
								'ReturnAnnouncement-Header.ReturnAnnouncementNumber'
							)
						},
						{
							title: edi.i18n.getMessage('retann.date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(
									docContent,
									'ReturnAnnouncement-Header.ReturnAnnouncementDate'
								)
							)
						},
						{
							title: edi.i18n.getMessage('retann.despatch.date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.DespatchDate')
							)
						},
						{
							title: edi.i18n.getMessage('retann.despatch.time'),
							text: edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.DespatchTime')
						},
						{
							isNewLine: true,
							title: edi.i18n.getMessage('functional.document.code'),
							text: edi.i18n.getMessage(
								'functional.document.code.' +
									edi.utils.getObjectProperty(
										docContent,
										'ReturnAnnouncement-Header.DocumentFunctionCode'
									)
							)
						}
					]
				})
			]
		});
	};

	const createPartiesBlock = function (docContent) {
		const parties = edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Parties');

		const createOS = function (selectorConfig) {
			Object.assign(selectorConfig, {
				fieldsMapOnly: true,
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				is_valid: true,
				readOnly: true,
				valuesByMap: true
			});

			return createOrgSelector(selectorConfig);
		};

		const buyer = createOS({
			fieldValues: parties,
			fieldsMap: edi.selectors.getRetannPartiesFieldsMap('Buyer')
		});
		const buyerBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.order.buyer'),
			items: [buyer]
		});

		const seller = createOS({
			fieldValues: parties,
			fieldsMap: edi.selectors.getRetannPartiesFieldsMap('Seller')
		});
		const sellerBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.order.seller'),
			items: [seller]
		});

		const shipFrom = createOS({
			fieldValues: docContent,
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('ReturnAnnouncement-Header.Location', {
				includeFields: [
					'company_name',
					'company_iln',
					'addr_zip',
					'addr_country',
					'addr_city',
					'addr_street',
					'addr_region_code'
				]
			})
		});
		const shipFromBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('document.ship.from'),
			items: [shipFrom]
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('document.section.parties'),
			collapsible: true,
			items: [
				createMaxWidthContainerForDetail({
					items: [buyerBlock, sellerBlock, shipFromBlock],
					layout: {
						type: 'grid',
						area: [
							[6, 6],
							[6, 6]
						]
					}
				})
			]
		});
	};

	const createAdditionalInformationBlock = function (docContent) {
		const waybillBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('reference.waybill'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.WaybillNumber')
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.WaybillDate')
							)
						}
					]
				})
			]
		});

		const orderBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('reference.order'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(
								docContent,
								'ReturnAnnouncement-Header.Reference.OrderReferenceNumber'
							)
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(
									docContent,
									'ReturnAnnouncement-Header.Reference.OrderReferenceDate'
								)
							)
						}
					]
				})
			]
		});

		const invoiceBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('reference.invoice'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(
								docContent,
								'ReturnAnnouncement-Header.Reference.InvoiceReferenceNumber'
							)
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(
									docContent,
									'ReturnAnnouncement-Header.Reference.InvoiceReferenceDate'
								)
							)
						}
					]
				})
			]
		});

		const recadvBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('reference.recadv'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(
								docContent,
								'ReturnAnnouncement-Header.Reference.ReceivingAdviceReferenceNumber'
							)
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(
									docContent,
									'ReturnAnnouncement-Header.Reference.ReceivingAdviceReferenceDate'
								)
							)
						}
					]
				})
			]
		});

		const contractBlock = createFieldBlockForDetails({
			title: edi.i18n.getMessage('reference.return.contract'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('field.name.number'),
							text: edi.utils.getObjectProperty(
								docContent,
								'ReturnAnnouncement-Header.Reference.ContractNumber'
							)
						},
						{
							title: edi.i18n.getMessage('date'),
							text: edi.renderers.fnsDateFromClient(
								edi.utils.getObjectProperty(
									docContent,
									'ReturnAnnouncement-Header.Reference.ContractDate'
								)
							)
						}
					]
				})
			]
		});

		return createFieldSetForDetails({
			title: edi.i18n.getMessage('documents.special.identifiers'),
			collapsible: true,
			items: createMaxWidthContainerForDetail({
				layout: {
					type: 'grid',
					gap: [24, 16]
				},
				items: [waybillBlock, orderBlock, invoiceBlock, recadvBlock, contractBlock]
			})
		});
	};

	const createRemarks = function (docContent) {
		return createFieldBlockForDetails({
			title: edi.i18n.getMessage('documents.column.remark'),
			cls: 'edi-from-maxwidth',
			layout: {
				type: 'grid',
				area: [6]
			},
			items: [
				createLabelForDetails({
					text: edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Header.Remarks')
				})
			]
		});
	};

	const createProductsGrid = function (docContent) {
		const lines = edi.utils.getObjectProperty(docContent, 'ReturnAnnouncement-Lines.Line', true);
		linesData = [];
		if ('string' != typeof lines) {
			for (let i = 0; i < lines.length; i++) {
				const values = lines[i]['Line-Item'];
				values['TaxRate'] = edi.renderers.taxRateString(values['TaxRate']);
				linesData.push(values);
			}
		}
		return createProductGridBase({
			title: edi.i18n.getMessage('document.retann.lines'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			data: linesData,
			readOnly: true,
			gridModel: RETANN_LINE_MODEL,
			totalModel: RETANN_SUMMARY_MODEL,
			gridColumnConfig: RETANN_LINE_COLUMN,
			totalColumnConfig: RETANN_SUMMARY_COLUMN,
			lineNumberFieldName: 'LineNumber',
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_RETANN,
			docData: docContent,
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			modalFormConfig: {
				title: 'document.retann.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.product.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('column.quantity.received'),
								allowDecimals: true,
								name: 'ReceivedQuantity',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('column.quantity.returned'),
								allowDecimals: true,
								name: 'ReturnedQuantity',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								name: 'SupplierItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.product.remarks'),
								name: 'Remarks'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ordered.unit.net.price'),
								allowDecimals: true,
								name: 'UnitNetPrice',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.gross.price'),
								allowDecimals: true,
								name: 'UnitGrossPrice',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.nds.rate'),
								name: 'TaxRate',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.net.amount'),
								allowDecimals: true,
								name: 'NetAmount',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.amount'),
								allowDecimals: true,
								name: 'TaxAmount',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.gross.amount'),
								allowDecimals: true,
								name: 'GrossAmount',
								type: 'number'
							}
						]
					}
				]
			}
		});
	};
	/**
	 * Creates retann details form
	 * @returns {Object}
	 */
	const createDetailsPanel = function (docContent) {
		return createFormForModule({
			cls: 'edi-details-panel',
			items: [
				createHeadPanel(docContent),
				createMainDataBlock(docContent),
				createPartiesBlock(docContent),
				createAdditionalInformationBlock(docContent),
				createRemarks(docContent),
				createProductsGrid(docContent)
			]
		});
	};

	/**
	 * Creates action panel
	 */
	const createModuleActionsPanel = function (document) {
		const data = moduleData.initData.data;
		document.id = data.id;
		const direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		const needSignatures = edi.document.actions.getSignCount(data);
		const needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.CONSUMER);
		let signaturesCount = needSignatures + needSignatures2 - data.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount;
		const actionsPanel = createActionsPanel();

		const customButtons = [];
		let modData = Ext.clone(moduleData);
		let initialFromOrg = modData.initData.data.fromOrg;
		modData.initData.data.fromOrg = modData.initData.data.toOrg;
		modData.initData.data.toOrg = initialFromOrg;
		let createDocConfig = [
			{
				permission: 'CREATE_EDI_FNS_UKD',
				title: edi.i18n.getMessage('documents.doctype.EDI_FNS_UKD.SCHFDOP_DIS'),
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				createCustomMethod: edi.document.actions.methodCreateUKDfromDocuments(
					document.id,
					direction,
					linesData,
					'UnitGrossPrice',
					modData,
					{
						version: '5.01-N',
						UKDFunction: 'DIS',
						addData: {
							isBasedOnRetann: true
						}
					}
				),
				config: {
					isFromTransformation: false,
					buyerOrg: initialFromOrg
				}
			},
			{
				permission: 'CREATE_EDI_FNS_UKD',
				title: edi.i18n.getMessage('documents.doctype.EDI_FNS_UKD.SCHFDOP_KSCHF'),
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				createCustomMethod: edi.document.actions.methodCreateUKDfromDocuments(
					document.id,
					direction,
					linesData,
					'UnitGrossPrice',
					modData,
					{
						version: '5.01-N',
						UKDFunction: 'KSCHF',
						addData: {
							isBasedOnRetann: true
						}
					}
				),
				config: {
					isFromTransformation: false,
					buyerOrg: initialFromOrg
				}
			},
			{
				permission: 'CREATE_EDI_FNS_UKD',
				title: edi.i18n.getMessage('documents.doctype.EDI_FNS_UKD.SCHFDOP_KSCHFDIS'),
				docType: edi.constants.DOCUMENT_TYPES.EDI_FNS_UKD,
				createCustomMethod: edi.document.actions.methodCreateUKDfromDocuments(
					document.id,
					direction,
					linesData,
					'UnitGrossPrice',
					modData,
					{
						version: '5.01-N',
						UKDFunction: 'KSCHF_DIS',
						addData: {
							isBasedOnRetann: true
						}
					}
				),
				config: {
					isFromTransformation: false,
					buyerOrg: initialFromOrg
				}
			}
		];

		let basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_FROM',
			data,
			moduleData,
			document,
			null,
			createDocConfig
		);
		if (basedDocuments?.length) {
			customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.retann'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					hideDefaultExport: true,
					addOriginalExport: true,
					addTransitExport: true
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	const changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	const renderData = function (initCallBack) {
		moduleData.tab.setLoading();

		const failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});

		// обновление хедера нужно для подтягивания полных данных организаций (toOrg, fromOrg)
		edi.document.actions.updateDocumentHeaderData(moduleData, function () {
			edi.rest.sendRequest(
				edi.document.actions.formatDetailsUri(moduleData.initData),
				'GET',
				{},
				function (data) {
					if (data && data.data) {
						const document = data.data;
						moduleData.tab.removeAll();
						const modulePanel = createDetailsModulePanel();
						modulePanel.add(createDetailsPanel(document));
						moduleData.tab.add(createModuleActionsPanel(document));
						moduleData.tab.add(modulePanel);
						if ('function' == typeof initCallBack) {
							initCallBack();
						} else {
							moduleData.tab.setLoading(false);
						}
					} else {
						failure(data);
					}
				},
				failure
			);
		});
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	const onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
