const EDI_SLSFCT_COLUMN = 'edi_slsfct_lines';
const EDI_SLSFCT_DELIVERY_COLUMN = 'edi_slsfct_delivery';

edi.columns.addColumns({
	[EDI_SLSFCT_COLUMN]: {
		lineNumber: {
			text: 'column.line.number',
			dataIndex: 'LineNumber',
			flex: 0.2
		},
		ean: {
			text: 'column.ean',
			dataIndex: 'EAN',
			flex: 1
		},
		itemDescription: {
			text: 'document.slsfct.column.column.itemDescription',
			tdCls: 'x-grid-cell-inner-portable-row',
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 2
		},
		buyerItemCode: {
			text: 'document.slsfct.column.column.BuyerItemCode',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 1,
			hidden: true
		},
		financialCode: {
			text: 'document.slsfct.column.column.FinancialCode',
			dataIndex: 'FinancialCode',
			flex: 1
		},
		financialName: {
			text: 'document.slsfct.column.column.FinancialName',
			dataIndex: 'FinancialName',
			flex: 1
		}
	},
	[EDI_SLSFCT_DELIVERY_COLUMN]: {
		unitOfMeasure: {
			text: 'document.slsfct.column.column.UnitOfMeasure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: false,
			renderer: 'UnitOfMeasure'
		},
		tradeFormat: {
			text: 'document.slsfct.TradeFormat',
			dataIndex: 'TradeFormat',
			flex: 1
		},
		deliveryType: {
			text: 'document.slsfct.DeliveryType',
			dataIndex: 'DeliveryType',
			flex: 1
		},
		ILN: {
			text: 'document.slsfct.ILN',
			dataIndex: 'ILN',
			flex: 1
		},
		name: {
			text: 'document.slsfct.Name',
			dataIndex: 'Name',
			flex: 1
		},
		branch: {
			text: 'document.slsfct.Branch',
			dataIndex: 'Branch',
			flex: 1
		},
		region: {
			text: 'document.slsfct.Region',
			dataIndex: 'Region',
			flex: 1
		},
		quantity: {
			text: 'document.slsfct.column.column.Quantity',
			dataIndex: 'Quantity',
			flex: 1
		},
		date: {
			text: 'document.slsfct.column.column.Date',
			dataIndex: 'Date',
			flex: 1,
			renderer: function (value) {
				return edi.utils.formatDate(value, 'd.m.Y');
			}
		}
	}
});

export { EDI_SLSFCT_COLUMN, EDI_SLSFCT_DELIVERY_COLUMN };
