import {
	isPackingRecord,
	isProductRecord,
	Packing,
	Product,
	Tree,
	TREE_MODE,
	TreeColumnConfig,
	TreeRecord
} from './definitions';
import { AMOUNT_FIELD_NAME, get, getProductIdent, LINE_NUMBER_FIELD_NAME, PACKING_ID_FIELD_NAME } from './createTree';

const TREE_COLUMNS_CONFIG_NAME = 'desadv_line_new';
const TREE_TOTAL_COLUMNS_CONFIG_NAME = 'desadv_summary_new';
const PRODUCT_REFERENCE_COLUMNS_CONFIG_NAME = 'desadv_reference_new';
const PRODUCT_REFERENCE_ELEMENT_COLUMNS_CONFIG_NAME = 'desadv_reference_elements_new';

type ColumnsConf = {
	[key: string]: {
		[key: string]: TreeColumnConfig;
	};
};

edi.columns.addColumns({
	[TREE_COLUMNS_CONFIG_NAME]: {
		itemDescription: {
			xtype: 'treecolumn',
			text: 'line.item.product.description',
			tdCls: 'x-grid-cell-inner-portable-row',
			order: 0,
			movable: false,
			minWidth: 350,
			maxWidth: 500,
			dataIndex: 'ItemDescription',
			flex: 2,
			htmlEncode: true,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				let res = '';
				if (isProductRecord(rec)) {
					res = get(rec, 'ItemDescription');
				} else if (isPackingRecord(rec)) {
					const recordType = get(rec, 'Type');

					if (recordType === edi.constants.PACKAGE_TYPES.PALLET) {
						res = edi.i18n.getMessage('desadv.packing.type.BJ');
					} else if (recordType === edi.constants.PACKAGE_TYPES.OTHER) {
						const packingName = get(rec, 'PackagingUnitName');
						res = packingName || edi.i18n.getMessage('desadv.packing.type.NUL');
					}
				}

				return res || ' ';
			}
		},
		lineNumber: {
			text: 'column.line.number',
			dataIndex: LINE_NUMBER_FIELD_NAME,
			flex: 0.5,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && get(rec, LINE_NUMBER_FIELD_NAME)) || '';
			}
		},
		ean: {
			text: 'column.ean',
			order: 2,
			dataIndex: 'EAN',
			flex: 1,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && get(rec, 'EAN')) || '';
			}
		},
		buyerItemCode: {
			text: 'column.buyer.item.code',
			dataIndex: 'BuyerItemCode',
			flex: 1,
			hidden: true,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && get(rec, 'BuyerItemCode')) || '';
			}
		},
		supplierItemCode: {
			text: 'column.supplier.item.code',
			dataIndex: 'SupplierItemCode',
			flex: 2,
			hidden: true,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && get(rec, 'SupplierItemCode')) || '';
			}
		},
		quantityDespatched: {
			text: 'column.quantity.despatched',
			dataIndex: AMOUNT_FIELD_NAME,
			flex: 1,
			renderer(val, meta, rec: TreeRecord<Product | Packing>, rIndex, cIndex, store, treeView) {
				let tree = treeView.up<Tree>('edi-tree-grid');
				if (tree?.viewMode === TREE_MODE.PRODUCT) {
					if (isProductRecord(rec)) {
						return get(rec, AMOUNT_FIELD_NAME);
					} else if (isPackingRecord(rec)) {
						let packingId = get(rec, PACKING_ID_FIELD_NAME);
						let isSecondLvlPack = !!tree.maps.secondLvlPacks[packingId];
						if (!isSecondLvlPack) {
							return '';
						}

						let productID = get(rec.parentNode as TreeRecord<Product>, 'productID');
						let ident = getProductIdent({
							productID,
							packingId
						});
						let productInPackData = tree.maps.productsByPack[ident];
						return productInPackData ? productInPackData[AMOUNT_FIELD_NAME] : '';
					}
				} else {
					return (isProductRecord(rec) && get(rec, AMOUNT_FIELD_NAME)) || '';
				}
			}
		},
		unitOfMeasure: {
			text: 'column.unit.of.measure',
			dataIndex: 'UnitOfMeasure',
			flex: 1,
			hidden: true,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && edi.utils.getUnitOfMeasureName(get(rec, 'UnitOfMeasure'))) || '';
			}
		},
		unitNetPrice: {
			text: 'column.unit.net.price',
			dataIndex: 'UnitNetPrice',
			flex: 1,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && edi.renderers.displayCurrencyDecimals(get(rec, 'UnitNetPrice'))) || '';
			}
		},
		taxRate: {
			text: 'column.fns.tax.rate',
			dataIndex: 'TaxRate',
			flex: 1,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && get(rec, 'TaxRate')) || '';
			}
		},
		unitGrossPrice: {
			text: 'column.unit.gross.price',
			dataIndex: 'UnitGrossPrice',
			flex: 1,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (
					(isProductRecord(rec) && edi.renderers.displayCurrencyDecimals(get(rec, 'UnitGrossPrice'))) || ''
				);
			}
		},
		unitPacksize: {
			text: 'column.unit.packsize',
			dataIndex: 'UnitPacksize',
			flex: 1,
			hidden: true,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				if (isPackingRecord(rec)) {
					let l = get(rec, 'SizeLength');
					let w = get(rec, 'SizeWidth');
					let h = get(rec, 'SizeHeight');
					let unitsCode = get(rec, 'SizeUnitOfMeasure') || '';
					let units = edi.stores.data.okei_codes.find(
						(item: { id: string; name: string }) => item.id === unitsCode
					);
					return l && w && h ? `${l} x ${w} x ${h} ${units?.name || ''}` : '';
				} else {
					return '';
				}
			}
		},
		netAmount: {
			text: 'column.net.amount',
			dataIndex: 'ProductNetAmount',
			flex: 1,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				let retVal = '';
				if (isProductRecord(rec)) {
					let productID = get(rec, 'productID');
					let productNetAmount = get(rec, 'ProductNetAmount');
					if (productID && Ext.isNumeric(productNetAmount)) {
						retVal = edi.renderers.displayCurrencyDecimals(productNetAmount);
						let netAmount = get(rec, 'NetAmount');
						if (Ext.isNumeric(netAmount)) {
							retVal += ` (${edi.renderers.displayCurrencyDecimals(netAmount)})`;
						}
					}
				}

				return retVal;
			}
		},
		taxAmount: {
			text: 'column.tax.amount',
			dataIndex: 'ProductTaxAmount',
			flex: 1,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				let retVal = '';
				if (isProductRecord(rec)) {
					let productID = get(rec, 'productID');
					let productTaxAmount = get(rec, 'ProductTaxAmount');
					if (productID) {
						retVal = edi.renderers.displayCurrencyDecimals(productTaxAmount);
						let taxAmount = get(rec, 'TaxAmount');
						retVal += ` (${edi.renderers.displayCurrencyDecimals(taxAmount)})`;
					}
				}

				return retVal;
			}
		},
		grossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'ProductGrossAmount',
			flex: 1,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				let retVal = '';
				if (isProductRecord(rec)) {
					let productID = get(rec, 'productID');
					let productGrossAmount = get(rec, 'ProductGrossAmount');
					if (productID && Ext.isNumeric(productGrossAmount)) {
						retVal = edi.renderers.displayCurrencyDecimals(productGrossAmount);
						let grossAmount = get(rec, 'GrossAmount');
						if (Ext.isNumeric(grossAmount)) {
							retVal += ` (${edi.renderers.displayCurrencyDecimals(grossAmount)})`;
						}
					}
				}
				return retVal;
			}
		},
		presenceGDT: {
			text: 'column.fns.presence.gdt',
			dataIndex: 'Line-Reference',
			order: 7,
			flex: 0.75,
			hidden: true,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				let customRefs = edi.utils.getObjectProperty(
					rec,
					'data.data.Line-Reference.Reference-Elements.Customs-Declarations'
				) as AnyObject[];
				if (customRefs && customRefs.length > 0) {
					let numbersGTD = customRefs.map((ref) => ref['Reference-Id']);
					meta.tdAttr = `data-qtip="${numbersGTD.join('<br/>')}"`;
					return edi.i18n.getMessage('answer.exist');
				} else {
					return edi.i18n.getMessage('answer.no');
				}
			}
		},
		orderedQuantity: {
			text: 'line.item.quantity.orders',
			dataIndex: 'OrderedQuantity',
			hidden: true,
			flex: 1,
			order: 5,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && get(rec, 'OrderedQuantity')) || '';
			}
		},
		VSDNumber: {
			text: 'column.desadv.product.id',
			dataIndex: 'VSDNumber',
			flex: 1,
			hidden: true,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isProductRecord(rec) && get(rec, 'VSDNumber')) || '';
			}
		},
		sscc: {
			text: 'desadv.packing.sscc',
			dataIndex: 'Package-Identification',
			flex: 1.5,
			order: 3,
			renderer: function (val, meta, rec: TreeRecord<Product | Packing>) {
				return (isPackingRecord(rec) && get(rec, PACKING_ID_FIELD_NAME)) || '';
			}
		}
	},
	[TREE_TOTAL_COLUMNS_CONFIG_NAME]: {
		totalLines: {
			text: 'column.total.lines',
			dataIndex: 'TotalLines',
			flex: 1,
			sortable: false,
			hideable: false
		},
		totalNetAmount: {
			text: 'column.ordered.unit.net.amount',
			dataIndex: 'TotalNetAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			tdCls: 'editable',
			editor: {
				xtype: 'numberfield',
				validator: 'validators.amount14',
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0
			}
		},
		totalTaxAmount: {
			text: 'line.item.tax.amount',
			dataIndex: 'TotalTaxAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			tdCls: 'editable',
			editor: {
				xtype: 'numberfield',
				validator: 'validators.amount14',
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0
			}
		},
		totalGrossAmount: {
			text: 'column.gross.amount',
			dataIndex: 'TotalGrossAmount',
			flex: 1,
			hideable: false,
			renderer: 'displayCurrencyDecimals',
			tdCls: 'editable',
			editor: {
				xtype: 'numberfield',
				validator: 'validators.amount14',
				decimalPrecision: 2,
				allowDecimals: true,
				allowBlank: false,
				minValue: 0
			}
		},
		totalGoodsDespatchedAmount: {
			text: 'column.total.goods.despatched.amount',
			dataIndex: 'TotalGoodsDespatchedAmount',
			menuDisabled: true,
			order: 3,
			flex: 1
		}
	},
	[PRODUCT_REFERENCE_COLUMNS_CONFIG_NAME]: {
		type: {
			text: 'column.kod.proiskh',
			dataIndex: 'countryOrigin',
			menuDisabled: true,
			flex: 1,
			renderer: function (value) {
				var countryStore = edi.stores.initValidCountryFullStore(),
					country = countryStore.findRecord('iso_number_3', value, 0, false, true, true);

				return country ? country.get('display_name') : value;
			}
		},
		id: {
			text: 'column.nomer.td',
			dataIndex: 'Reference-Id',
			menuDisabled: true,
			flex: 1
		}
	},
	[PRODUCT_REFERENCE_ELEMENT_COLUMNS_CONFIG_NAME]: {
		type: {
			text: 'desadv.reference.column.type',
			dataIndex: 'Reference-Type',
			menuDisabled: true,
			flex: 1
		},
		id: {
			text: 'desadv.reference.column.id',
			dataIndex: 'Reference-Id',
			menuDisabled: true,
			flex: 1
		}
	}
} as ColumnsConf);

export {
	TREE_COLUMNS_CONFIG_NAME,
	TREE_TOTAL_COLUMNS_CONFIG_NAME,
	PRODUCT_REFERENCE_COLUMNS_CONFIG_NAME,
	PRODUCT_REFERENCE_ELEMENT_COLUMNS_CONFIG_NAME
};
