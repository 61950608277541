import { DATE_DISPLAY_FORMAT, DATE_SRC_FORMAT } from './document.details.dsf_reestr_route';

const REESTR_COLUMN_CONFIG_NAME = 'reestr_route_reestr';

edi.columns.addColumns({
	[REESTR_COLUMN_CONFIG_NAME]: {
		number: {
			text: 'dsf_reestr_route.position.number',
			dataIndex: 'POSITION_NUM',
			flex: 1
		},
		ebeln: {
			text: 'dsf_reestr_route.position.ebeln',
			dataIndex: 'EBELN',
			flex: 1
		},
		tknumitm: {
			text: 'dsf_reestr_route.position.tknumitm',
			dataIndex: 'TKNUMITM',
			flex: 1
		},
		ts_type: {
			text: 'dsf_reestr_route.position.ts_type',
			dataIndex: 'TS_TYPE',
			flex: 1
		},
		from_name: {
			text: 'dsf_reestr_route.position.from_name',
			dataIndex: 'POINT_FROM_NAME',
			flex: 1
		},
		to_name: {
			text: 'dsf_reestr_route.position.to_name',
			dataIndex: 'POINT_TO_NAME',
			flex: 1
		},
		route: {
			text: 'dsf_reestr_route.position.route',
			dataIndex: 'ROUTE',
			hidden: true,
			flex: 1
		},
		route_name: {
			text: 'dsf_reestr_route.position.route_name',
			dataIndex: 'TXT_ROUTE',
			hidden: true,
			flex: 1
		},
		units: {
			text: 'dsf_reestr_route.position.units',
			dataIndex: 'UNITOFMEASURE',
			hidden: true,
			flex: 1
		},
		pallets_amount: {
			text: 'dsf_reestr_route.position.pallets_amount',
			dataIndex: 'ZZPALL_T_AMOUNT',
			hidden: true,
			flex: 1
		},
		price_per_unit: {
			text: 'dsf_reestr_route.position.price_per_unit',
			dataIndex: 'SUM_ZKFP',
			hidden: true,
			flex: 1
		},
		extra_points: {
			text: 'dsf_reestr_route.position.extra_points',
			dataIndex: 'ADDPTSAMNT',
			hidden: true,
			flex: 1
		},
		extra_points_summ: {
			text: 'dsf_reestr_route.position.extra_points_summ',
			dataIndex: 'SUM_ZFKK',
			hidden: true,
			flex: 1
		},
		category: {
			text: 'dsf_reestr_route.position.category',
			dataIndex: 'CATEGORY_NAME',
			flex: 1
		},
		total_wo_tax: {
			text: 'dsf_reestr_route.position.total_wo_tax',
			dataIndex: 'SUM_PAY',
			flex: 1
		},
		total_with_tax: {
			text: 'dsf_reestr_route.position.total_with_tax',
			dataIndex: 'SUM_TOTAL',
			flex: 1
		},
		driver: {
			text: 'dsf_reestr_route.position.driver',
			dataIndex: 'DRIVER_FIO',
			hidden: true,
			flex: 1
		},
		car_number: {
			text: 'dsf_reestr_route.position.car_number',
			dataIndex: 'CAR_NUMBR',
			hidden: true,
			flex: 1
		},
		tonnage: {
			text: 'dsf_reestr_route.position.tonnage',
			dataIndex: 'TONNAGE',
			hidden: true,
			flex: 1
		},
		trn_number: {
			text: 'dsf_reestr_route.position.trn_number',
			dataIndex: 'ZTO_TRN',
			hidden: true,
			flex: 1
		},
		upd_number: {
			text: 'dsf_reestr_route.position.upd_number',
			dataIndex: 'ZTO_UPD',
			hidden: true,
			flex: 1
		},
		pe_number: {
			text: 'dsf_reestr_route.position.pe_number',
			dataIndex: 'ZTO_PE',
			hidden: true,
			flex: 1
		},
		tms_reg_number: {
			text: 'dsf_reestr_route.position.tms_reg_number',
			dataIndex: 'ZTO_REG_TMS',
			hidden: true,
			flex: 1
		},
		from_address: {
			text: 'dsf_reestr_route.position.from_address',
			dataIndex: 'ZTO_ADDR_FROM',
			hidden: true,
			flex: 1
		},
		to_address: {
			text: 'dsf_reestr_route.position.to_address',
			dataIndex: 'ZTO_ADDR_TO',
			hidden: true,
			flex: 1
		},
		date_up: {
			text: 'dsf_reestr_route.position.date_up',
			dataIndex: 'ZTO_DATE_UP',
			hidden: true,
			flex: 1,
			renderer(val) {
				return edi.utils.formatDate(val, DATE_DISPLAY_FORMAT, DATE_SRC_FORMAT);
			}
		},
		date_end: {
			text: 'dsf_reestr_route.position.date_end',
			dataIndex: 'DPTEN',
			hidden: true,
			flex: 1,
			renderer(val) {
				return edi.utils.formatDate(val, DATE_DISPLAY_FORMAT, DATE_DISPLAY_FORMAT);
			}
		},
		route_number_from_trn: {
			text: 'dsf_reestr_route.position.route_number_from_trn',
			dataIndex: 'ZTO_TTN_P',
			hidden: true,
			flex: 1
		},
		ml_number: {
			text: 'dsf_reestr_route.position.ml_number',
			dataIndex: 'ZTO_ML',
			hidden: true,
			flex: 1
		},
		zts_number: {
			text: 'dsf_reestr_route.position.zts_number',
			dataIndex: 'ZTO_TKNUM',
			hidden: true,
			flex: 1
		},
		order_doc_number: {
			text: 'dsf_reestr_route.position.order_doc_number',
			dataIndex: 'ZTO_PO',
			hidden: true,
			flex: 1
		},
		request_number: {
			text: 'dsf_reestr_route.position.request_number',
			dataIndex: 'ZTO_PR',
			hidden: true,
			flex: 1
		},
		tn_number: {
			text: 'dsf_reestr_route.position.tn_number',
			dataIndex: 'ZTO_TN',
			hidden: true,
			flex: 1
		},
		acceptance_status: {
			text: 'dsf_reestr_route.position.acceptance_status',
			dataIndex: 'STATUS_POS',
			flex: 1
		},
		status_description: {
			text: 'dsf_reestr_route.position.status_description',
			dataIndex: 'STATUS_DESCR',
			flex: 1
		}
	}
});

let getReestrColumns = () => edi.columns.get(REESTR_COLUMN_CONFIG_NAME);

export { getReestrColumns, REESTR_COLUMN_CONFIG_NAME };
