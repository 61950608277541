import { createCompanySelector } from '@Components/company.selector.js';
import { createCheckbox, createCombo, createDateField, createField, createTextField } from '@Components/fields';
import { createActionsPanel, createAddModulePanel, createForm, createPanel } from '@Components/panels';
import { createCreateSaveButton } from '@Components/buttons';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { dsfClientMethods } from '@Edi/modules/documents/DSF_CLIENT/methods';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { createButtonContainer, createContainer } from '@UIkit/components/panels';
import { orgSelectorMethods } from '@Components/orgSelector/methods';

Ext.namespace('edi.modules');
edi.modules['document.create.dsf_client'] = function () {
	let moduleData,
		docHeader,
		docContent,
		fields = {},
		uploadForm,
		createSaveBtn,
		parentId,
		docId,
		isEdit,
		isCopy,
		fileValue,
		numberGenerationPatterns,
		receiverId,
		form,
		initialData,
		isNumberAutoGenerated = false,
		maxFileSize,
		customFieldsContainer,
		customFieldsObj,
		fileDropper;
	const docType = edi.constants.DOCUMENT_TYPES.DSF_CLIENT;
	let subTypes = [];
	let userData = edi.core.getUserData();
	const topPath = '//DocumentFreeFormat/';

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docHeader = moduleData.initData.data || {};
		docId = docHeader.id;
		receiverId = docHeader.toOrg?.id;
		isCopy = !!moduleData.initData.isCopy;
		isEdit = !!docId && !isCopy;

		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	let checkOrganizationValidity = function (cs) {
		cs.checkOrgDataValid();
		return cs.is_valid;
	};

	/**
	 * Creates dsf form
	 * @returns	{Object}	Ext.form.Panel instance
	 */
	let createModuleForm = function () {
		let processAutoGenerationStateChange = function (checkbox, isChecked) {
			numberDocument.emptyText = isChecked
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('nr');
			numberDocument.applyEmptyText();
			numberDocument.setValue('');
			numberDocument.setDisabled(isChecked);
			numberDocument.allowBlank = isChecked;
			numberDocument.validate();
		};

		let numberDocument = createTextField({
			emptyText: isNumberAutoGenerated
				? edi.i18n.getMessage('document.generation.numbers.text')
				: edi.i18n.getMessage('nr'),
			margin: '0 0 0 5',
			columnWidth: 0.2,
			allowBlank: isNumberAutoGenerated,
			disabled: isNumberAutoGenerated,
			value: docHeader?.number
				? docHeader.number + (isCopy ? '-' + edi.i18n.getMessage('document.number.copied') : '')
				: undefined,
			name: 'number'
		});

		let autogenCheckboxContainer = isEdit
			? null
			: createField({
					title: edi.i18n.getMessage('document.generation.numbers.text.checkbox'),
					input: createCheckbox({
						columnWidth: 0.8,
						inputValue: true,
						checked: isNumberAutoGenerated,
						name: 'numberAutoGenerated',
						listeners: {
							change: processAutoGenerationStateChange
						}
					}),
					containerConfig: {
						columnWidth: 1
					}
			  });
		if (autogenCheckboxContainer && !isNumberAutoGenerated) {
			autogenCheckboxContainer.hide();
		}

		let headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.data'),
					input: (fields.subTypeCombo = createCombo({
						emptyText: edi.i18n.getMessage('document.type'),
						columnWidth: 0.67,
						allowBlank: false,
						name: 'reference.subDocType',
						store: edi.stores.createSimpleInlineStore(subTypes, function (subType) {
							return edi.i18n.getMessage('documents.subdoctype.' + subType);
						}),
						readOnly: isEdit,
						valueSrc: docContent,
						autoSelect: isEdit || isCopy,
						listeners: isEdit
							? undefined
							: {
									change: function () {
										initCustomFields(function () {
											checkValid();
										});
									}
							  }
					})),
					containerConfig: {
						columnWidth: 0.6
					}
				}),
				numberDocument,
				createDateField({
					emptyText: edi.i18n.getMessage('date'),
					margin: '0 0 0 5',
					columnWidth: 0.2,
					allowBlank: false,
					name: 'date',
					value:
						docHeader?.doctime && !isCopy
							? edi.utils.formatDate(
									docHeader.doctime,
									edi.constants.DATE_FORMAT.FNS,
									edi.constants.DATE_FORMAT.SERVER
							  )
							: new Date()
				})
			]
		});

		//If editing document with mandatory attachment, mark field as mandatory, if no file was uploaded
		let isAttachmentMandatory = true;
		if (isEdit) {
			isAttachmentMandatory = !docContent || !docContent.file || !docContent.file.id;
		}
		uploadForm = createForm({
			submitEmptyText: false,
			items: [
				createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createField({
							title: edi.i18n.getMessage('document.file'),
							input: (fileDropper = createFileDropper({
								columnWidth: 0.7,
								name: 'attachment',
								itemId: 'fileDropper',
								allowBlank: false,
								fileData:
									isEdit && !!docContent.file
										? {
												fileName: docContent?.file?.fileName,
												fileDate: edi.utils.formatDate(
													docContent?.file?.modifyDate,
													edi.constants.DATE_FORMAT.FNS
												),
												fileTime: edi.utils.formatDate(
													docContent?.file?.modifyDate,
													edi.constants.DATE_FORMAT.TIME
												),
												fileSize: edi.utils.formatFileSize(docContent?.file?.fileSize)
										  }
										: null
							})),
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				})
			]
		});

		let createCS = function (selectorConf) {
			Ext.applyIf(selectorConf, {
				margin: '5 0 10 10',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				allowBlank: false,
				readOnly: isEdit,
				useHiddenFields: true,
				disableAutoValidation: true,
				fieldValues: isEdit || isCopy ? docContent : null,
				selectedOrgValues: isEdit || isCopy ? docContent : null,
				disableCommonAddressRenderer: true,
				forceChangeFields: {
					bank_id: true,
					bank_corr_acc: true,
					bank_name: true,
					bank_acc: true
				},
				modalConf: dsfClientMethods.getCompanyModalConf()
			});
			return createCompanySelector(selectorConf);
		};

		if (isEdit || isCopy) {
			Ext.merge(docContent, {
				sender: {
					address: {
						addr_category: docContent?.reference?.addressTypeSender
					}
				},
				receiver: {
					address: {
						addr_category: docContent?.reference?.addressTypeReceiver
					}
				}
			});
		}

		let senderValues = edi.converters.convertOrgToPartie(userData.org);
		fields.sender = createCS({
			itemId: 'sender',
			title: 'documents.sender',
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: docHeader?.fromOrg?.id ?? userData.org.id
			}),
			fieldValues: isEdit || isCopy ? docContent : senderValues,
			selectedOrgValues: isEdit || isCopy ? docContent : senderValues,
			valuesByMap: isEdit || isCopy,
			callback: checkValid,
			fieldsMap: dsfClientMethods.getCompanyFieldsMap('sender')
		});

		fields.receiver = createCS({
			itemId: 'receiver',
			title: 'documents.receiver',
			relationsOnly: true,
			valuesByMap: true,
			orgFromRelation: true,
			originalValues: orgSelectorMethods.getOrgCardValues({
				orgId: docHeader?.toOrg?.id
			}),
			relations: edi.relations.getRelations(),
			callback: function (values, org) {
				receiverId = org?.id;
				initCustomFields(function () {
					checkValid();
				});
			},
			fieldsMap: dsfClientMethods.getCompanyFieldsMap('receiver')
		});

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		form = createForm({
			cls: 'edi-details-panel',
			submitEmptyText: false,
			bodyPadding: 10,
			autoScroll: true,
			items: [
				createTwoColumnsLayout(
					[headData, autogenCheckboxContainer, fields.sender, fields.receiver],
					[uploadForm],
					0.5,
					{
						items2Conf: {
							margin: '0 0 0 10'
						}
					}
				),
				createCustomFieldsContainer()
			],
			buttons: createButtonContainer({
				items: [
					(createSaveBtn = createCreateSaveButton(
						{
							handler: function () {
								let formIsValid = edi.utils.setFocusToDocumentsWithGrid(
									form,
									['sender', 'receiver', 'fileDropper'],
									[fields.sender, fields.receiver, fileDropper],
									null,
									false
								);
								if (!formIsValid) {
									return;
								}
								save();
							}
						},
						isEdit
					))
				]
			})
		});

		form.on('validitychange', checkValid);
		uploadForm.on('validitychange', checkValid);
		checkValid();

		edi.utils.processModuleFormChange(form, moduleData);
		edi.utils.processModuleFormChange(uploadForm, moduleData);

		return form;
	};

	/**
	 * Checks validity, and enables/disables create button
	 */
	let checkValid = function () {
		let senderIsValid = checkOrganizationValidity(fields.sender);
		let receiverIsValid = checkOrganizationValidity(fields.receiver);
		let valid = form.isValid() && uploadForm.isValid() && senderIsValid && receiverIsValid;
		createSaveBtn.setDisabled(!valid);
		return valid;
	};

	let buildDocument = function (formValues, fileId) {
		if (fileId) {
			formValues['file'] = fileId;
		} else if (fileValue) {
			formValues['file'] = fileValue.id;
		}

		if (formValues['startDate']) {
			formValues['startDate'] = edi.utils.getUTCTimeZoneFree(
				formValues['startDate'],
				edi.constants.DATE_FORMAT.SERVER,
				undefined,
				edi.constants.DATE_FORMAT.FNS
			);
		}
		if (formValues['endDate']) {
			formValues['endDate'] = edi.utils.getUTCTimeZoneFree(
				formValues['endDate'],
				edi.constants.DATE_FORMAT.SERVER,
				undefined,
				edi.constants.DATE_FORMAT.FNS
			);
		}
		if (formValues['payment']) {
			if (formValues['payment']['currencyCode']) {
				formValues['payment']['currencyLocal'] = edi.utils.getOkv({
					code: formValues['payment']['currencyCode']
				}).charCode;
			}

			if (formValues['payment']['grossAmount']) {
				formValues['totalSumm'] = formValues['payment']['grossAmount'];
			}
		}

		edi.utils.clearEmptyValues(formValues);

		return formValues;
	};

	/**
	 * Saves dsf_client
	 */
	let save = function () {
		let formValues = edi.utils.collectFormValues(form);
		edi.utils.clearEmptyValues(formValues);
		if (!formValues.filename && fileValue) {
			formValues.filename = edi.utils.base64.encode(fileValue.name);
		}

		let customFieldsData = edi.methods.custom_fields.getCustomFieldsData(
			customFieldsObj,
			formValues,
			null,
			topPath
		);
		Object.keys(customFieldsData).forEach(function (key) {
			delete formValues[key];
		});

		if (!uploadForm.isValid()) {
			edi.core.showError('document.upload.error.select.file');
		} else {
			let onUploaded = function (fileId) {
				moduleData.tab.setLoading(edi.i18n.getMessage('dsf.create.saving'));

				formValues.reference = {
					subDocType: fields.subTypeCombo.getValue(),
					addressTypeSender: edi.utils.getObjectProperty(formValues, 'sender.address.addr_category'),
					addressTypeReceiver: edi.utils.getObjectProperty(formValues, 'receiver.address.addr_category')
				};
				if (formValues.sender?.address?.addr_category) {
					delete formValues.sender.address.addr_category;
				}
				if (formValues.receiver?.address?.addr_category) {
					delete formValues.receiver.address.addr_category;
				}

				let newDocContent = buildDocument(formValues, fileId);
				delete newDocContent['true'];
				newDocContent = edi.document.actions.mergeDataBeforeSave(initialData, newDocContent);

				let numberAutoGenerated = newDocContent.numberAutoGenerated;
				delete newDocContent.numberAutoGenerated;

				let headerData = {
					data: Ext.encode(newDocContent),
					date: newDocContent.date,
					dateFormat: edi.constants.DATE_FORMAT.CLIENT
				};

				if (customFieldsData && Object.keys(customFieldsData).length > 0) {
					headerData.customFields = customFieldsData;
				}

				if (isNumberAutoGenerated && numberAutoGenerated) {
					headerData.autogen = true;
				} else {
					headerData.number = formValues.number;
				}

				let success = edi.document.actions.createSaveSuccessHandler(
					moduleData,
					isEdit ? docId : undefined,
					undefined,
					isEdit
				);
				let failure = edi.document.actions.createSaveErrorHandler(isEdit, moduleData, docType);
				edi.document.actions.processDocument(
					receiverId,
					null,
					docType,
					parentId,
					isEdit ? docId : undefined,
					success,
					failure,
					headerData
				);
			};

			let failure = function (data) {
				edi.core.showError(
					edi.utils.formatComplexServerError(
						data,
						data && data.status ? 'error.server.' + data.status : 'dsf.upload.failure'
					),
					function () {
						moduleData.tab.setLoading(false);
					}
				);
			};

			let fileCmp = uploadForm.getForm().findField('attachment');
			if (!fileCmp || !fileCmp.getValue()) {
				onUploaded(null);
			} else {
				edi.core.submitUploadForm(
					uploadForm,
					edi.rest.services.DSF.FILE.POST,
					'dsf.create.saving',
					function (responseData) {
						if (responseData && responseData.data && responseData.data.id) {
							onUploaded(responseData.data.id);
						} else {
							failure(responseData);
						}
					},
					failure
				);
			}
		}
	};

	/**
	 * Creates action pane above the data panel
	 */
	let createModuleActionsPanel = function () {
		return createActionsPanel();
	};

	/**
	 * Get from backend maxFileSize setting
	 * @param	{Function}	callback
	 */
	let getMaxFileSizeSetting = function (callback) {
		let failure = function (data) {
			edi.core.showError(
				edi.utils.formatComplexServerError(
					data,
					data?.status ? 'error.server.' + data.status : 'dsf.get.limit.file.size.failure'
				),
				function () {
					maxFileSize = 1;
					callback();
				}
			);
		};

		let success = function (resp) {
			const { data } = resp;
			maxFileSize = data ? parseInt(data) : undefined;

			if (Ext.isNumber(maxFileSize)) {
				maxFileSize = maxFileSize > 10 ? 10 : maxFileSize;
				callback();
			} else {
				failure();
			}
		};

		const url = edi.utils.formatString(edi.rest.services.SERVER.SETTING.GET, {
			configuration_code: 'LIMIT_SIZE_DSF'
		});
		edi.rest.sendRequest(url, 'GET', {}, success, failure);
	};

	/**
	 * Get from backend numberation setting
	 * @param	{Function}	success
	 * @param	{Function}	fail
	 */
	let getNumberationSettings = function (success, fail) {
		let successFn = function (resp) {
			if (resp && resp.items) {
				numberGenerationPatterns = resp;
				for (let i = 0; i < resp.items.length; i++) {
					let numberingRule = resp.items[i];
					if (numberingRule.typeDoc === docType && 'ALLOW' === numberingRule.policy) {
						isNumberAutoGenerated = true;
						break;
					}
				}
				success(resp);
			} else {
				fail(resp);
			}
		};

		edi.rest.sendRequest(edi.rest.services.DOCUMENTS.GEN_DOCUMENT_NUMBER.GET, 'GET', {}, successFn, fail);
	};

	/**
	 * Get from backend subtypes for dsf_client
	 */
	let getSubTypes = function (success, fail) {
		let successFn = function (resp) {
			if (Array.isArray(resp.items) && resp.items.length) {
				subTypes = resp.items.map((it) => it.subtype);
				success(resp);
			} else {
				fail(resp);
			}
		};
		if (edi.permissions.hasPermission('CLIENT_READ_DOCUMENT_SUBTYPE')) {
			edi.rest.sendRequest(
				edi.rest.services.DOCUMENTS.SUB_DOC_TYPES.GET,
				'GET',
				{ doctype: docType },
				successFn,
				fail
			);
		} else {
			success();
		}
	};

	/**
	 * Get from backend document's content
	 * @param	{Function}	success
	 * @param	{Function}	fail
	 */
	let getDocContent = function (success, fail) {
		let successFn = function (resp) {
			if (resp?.data) {
				initialData = Ext.clone(resp.data);
				docContent = Ext.clone(resp.data);
				fileValue = {
					id: edi.utils.getObjectProperty(docContent, 'file.id'),
					name: edi.utils.getObjectProperty(docContent, 'file.realName')
				};

				success(resp);
			} else {
				fail(resp);
			}
		};

		let contentUrl = edi.utils.formatString(edi.rest.services.DOCUMENTS.CONTENT.GET, {
			documentId: docId
		});
		edi.rest.sendRequest(contentUrl, 'GET', {}, successFn, fail);
	};

	/**
	 * initialize custom fields
	 * @param	{Function}	callback
	 */
	let initCustomFields = function (callback) {
		let senderId = fields.sender.getValues().id || fields.sender.selectedOrg?.id || docHeader.fromOrg?.id;
		let subDocType = fields.subTypeCombo.getValue();
		if (receiverId && senderId && !!subDocType) {
			edi.methods.custom_fields.initCustomFields({
				customFieldsObj,
				docType,
				subDocType,
				toOrgId: receiverId,
				fromOrgId: senderId,
				docId: docContent ? docContent.header : null,
				container: customFieldsContainer,
				topPath: topPath,
				finishCallback(obj) {
					customFieldsObj = obj;
					callback();
				},
				fail: callback
			});
		} else {
			callback();
		}
	};

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	let renderData = function (initCallBack) {
		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', () =>
			edi.modulesHandler.removeModule(moduleData)
		);

		let finishRender = function () {
			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(
				createAddModulePanel({
					items: [createModuleForm()]
				})
			);

			if (isEdit || isCopy) {
				if ('function' == typeof initCallBack) {
					initCallBack();
				}
			} else {
				fields.receiver.presetFromRelation(function () {
					if ('function' == typeof initCallBack) {
						initCallBack();
					}
				});
			}

			initCustomFields(function () {
				form.isValid();
				checkValid();
			});
		};

		getSubTypes(function () {
			if (isEdit || isCopy) {
				getDocContent(() => getMaxFileSizeSetting(finishRender), failure);
			} else {
				if (edi.permissions.hasPermission('CLIENT_NUMBERATION_SETTINGS')) {
					getNumberationSettings(() => getMaxFileSizeSetting(finishRender), failure);
				} else {
					getMaxFileSizeSetting(finishRender);
				}
			}
		}, failure);
	};

	/**
	 * Routine that must be done before module destroy
	 * @returns	{Boolean}	false to stop module destroy
	 */
	let onDestroy = function () {
		return true;
	};
};
