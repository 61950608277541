import { createCompanySelector } from '@Components/company.selector.js';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createActionsButton } from '@Components/buttons';

Ext.namespace('edi.modules');
edi.modules['invitation.details'] = function () {
	var moduleData, objectData;

	/**
	 * Module initialization
	 * @param {Object} data                Data from modules handler
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		objectData = moduleData.initData.data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.invitation.on('change', changeHandler);
	};
	/**
	 * Creates details panel
	 * @returns {Object}
	 */
	var createDetailsPanel = function () {
		var number = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.i18n.getMessage('column.id'),
					input: createLabel({
						columnWidth: 0.6,
						text: objectData.id,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createLabel({
					text: '',
					margin: '3 10 0 15',
					columnWidth: 0.06
				}),
				createLabel({
					columnWidth: 0.275,
					html: edi.renderers.invitationStatus(objectData.status),
					valueLabel: true
				})
			]
		});

		var initiator = createCompanySelector({
			title: 'column.initiator.org',
			is_valid: true,
			readOnly: true,
			fieldValues: objectData.initiator ? edi.converters.convertOrgToPartie(objectData.initiator) : null
		});

		var acceptor = createCompanySelector({
			title: 'column.acceptor.org',
			is_valid: true,
			readOnly: true,
			fieldValues: objectData.acceptor ? edi.converters.convertOrgToPartie(objectData.acceptor) : null
		});

		var rejectReason = createLabel({
			columnWidth: 1,
			text: objectData.rejectReason || ''
		});

		var creationDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.i18n.getMessage('column.creation.date'),
					input: createDateLabel({
						columnWidth: 0.6,
						text: objectData.creationDate,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var modifyDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			items: [
				createField({
					title: edi.i18n.getMessage('column.modify.date'),
					input: createDateLabel({
						columnWidth: 0.6,
						text: objectData.modifyDate,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var layout = createTwoColumnsLayout([number, initiator, acceptor], [creationDate, modifyDate], 0.625);

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [layout, rejectReason]
		});
	};
	/**
	 * Renders page layout
	 * @param {Function} initCallBack    Callback that must be called after module initialization
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'invitation.get.failure'), function () {
				edi.modulesHandler.removeModule(moduleData);
			});
		};
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.INVITATION.DETAILS.GET, {
				id: objectData.id
			}),
			'GET',
			null,
			function (data) {
				objectData = data.data;
				edi.document.actions.changeTabTitle(moduleData.tab, objectData.id, false);
				var modulePanel = createDetailsModulePanel();
				modulePanel.add(createDetailsPanel());
				moduleData.tab.add(createModuleActionsPanel());
				moduleData.tab.add(modulePanel);

				if ('function' == typeof initCallBack) {
					initCallBack();
				} else {
					moduleData.tab.setLoading(false);
				}
			},
			failure
		);
	};
	var acceptOrReject = function (isAccepted) {
		moduleData.tab.setLoading();
		var failure = function (data) {
			edi.core.showError(edi.utils.formatComplexServerError(data, 'invitation.put.failure'), function () {
				moduleData.tab.setLoading(false);
			});
		};
		edi.rest.sendRequest(
			edi.utils.formatString(edi.rest.services.INVITATION.PUT, {
				id: objectData.id
			}),
			'PUT',
			Ext.encode({
				ANSWER: isAccepted ? 'ACCEPTED' : 'REJECTED'
			}),
			function () {
				edi.events.invitation.fireEvent('change', objectData);
			},
			failure
		);
	};
	/**
	 * Creates actions panel
	 */
	var createModuleActionsPanel = function () {
		var rejectBtn, acceptBtn;
		if (
			edi.permissions.hasPermission('CREATE_INVITATION_TO_COOPERATION') &&
			objectData.acceptor.id == edi.core.getUserOrgID() &&
			(objectData.status == edi.constants.STATE.DRAFT || objectData.status == edi.constants.STATE.SENT)
		) {
			rejectBtn = createActionsButton({
				text: edi.i18n.getMessage('document.reject.document'),
				glyph: edi.constants.ICONS.REJECT,
				handler: function () {
					edi.core.confirm(null, 'invitation.reject.confirm', function () {
						acceptOrReject(false);
					});
				}
			});
			acceptBtn = createActionsButton({
				text: edi.i18n.getMessage('document.accept.document'),
				glyph: edi.constants.ICONS.DONE,
				handler: function () {
					edi.core.confirm(null, 'invitation.accept.confirm', function () {
						acceptOrReject(true);
					});
				}
			});
		}
		return createActionsPanel({
			items: [rejectBtn, acceptBtn]
		});
	};

	var changeHandler = function (eventData) {
		if (eventData.id == objectData.id) {
			if (eventData.deleted) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				moduleData.tab.removeAll();
				renderData();
			}
		}
	};

	/**
	 * Routine that must be done before module destroy
	 */
	var onDestroy = function () {
		edi.events.invitation.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
