import { AbstractPanel } from './abstract.panel.js';
import { createPasswordDialog } from './changePassword.dialog';
import { createForm, createPanel, createFormContainer, createContainer } from '@UIkit/components/panels';
import { createCombo } from '@UIkit/components/fields';
import { createComboCerts } from '../../components/ComboboxCerts/ComboboxCerts';
import { FIELD_CLS } from '@UIkit/components/fields/Base/Base';

class SettingPanel extends AbstractPanel {
	_panel;

	/**
	 * По идее нужно будет выкинуть, но пока без этого не работает
	 * @type {[]}
	 * @private
	 */
	_authenticationChains = [];

	/**
	 * @param {{defaultFilteringPeriod:String}} defaults
	 */
	constructor(defaults) {
		super();

		let form = this.createForm(defaults);
		let saveButton = this.createSaveButton(form);

		this._panel = this.createPanel({
			form,
			saveButton
		});

		this._originalValues = defaults;

		this.makePanelConfirmable(this._panel);
		this.updateFieldsState();
	}

	setValues(values) {
		this._authenticationChains = values.authenticationChains || [];

		return super.setValues(values);
	}

	getPanel() {
		return this._panel;
	}

	getValues() {
		return this._form.getValues();
	}

	createPanel({ form, saveButton }) {
		return createPanel({
			layout: 'fit',
			items: [form],
			bbar: {
				padding: '16 24',
				items: [saveButton]
			}
		});
	}

	createForm(defaults) {
		let { defaultFilteringPeriod } = defaults;

		return (this._form = createForm({
			layout: 'hbox',
			padding: 24,
			autoScroll: true,
			items: [
				createFormContainer({
					width: 294,
					padding: 0,
					margin: '0 120 0 0',
					gap: 24,
					items: [
						this.createField(
							{
								fieldLabel: edi.i18n.getMessage('user.profile.language'),
								allowBlank: false,
								forceSelection: true,
								queryMode: 'local',
								store: edi.stores.initLanguagesStore(),
								name: 'language'
							},
							createCombo
						),
						this.createPeriodCombobox({
							name: 'defaultFilteringPeriod',
							defaultFilteringPeriod
						}),
						this.createField(
							{
								cls: [FIELD_CLS.big],
								fieldLabel: edi.i18n.getMessage('user.profile.settings.login.cert'),
								name: 'thumbprint'
							},
							() => createComboCerts()
						),
						createContainer({
							items: [
								this.createActionButton({
									width: 150,
									text: edi.i18n.getMessage('user.profile.settings.password.change'),
									handler: () => this.showChangePasswordDialog()
								})
							]
						})
					]
				}),
				createFormContainer({
					width: 294,
					padding: 0,
					gap: [32, 0],
					margin: '16 0 0 0',
					items: [
						this.createCheckboxField({
							title: edi.i18n.getMessage('ediweb.user.profile.saveFilter'),
							name: 'saveFilter'
						}),
						this.createCheckboxField({
							title: edi.i18n.getMessage('ediweb.user.profile.saveSort'),
							name: 'saveSort'
						}),
						createContainer({
							items: [
								this.createActionButton({
									width: '100%',
									margin: '0 0 24 0',
									text: edi.i18n.getMessage('action.clear.filters.settings'),
									handler: () => this.showClearSortSettingDialog()
								}),
								this.createActionButton({
									width: 188,
									text: edi.i18n.getMessage('action.clear.column.settings'),
									handler: () => this.showClearColumnSettingDialog()
								})
							]
						})
					]
				})
			]
		}));
	}

	createPeriodCombobox({ name }) {
		let periodTypes = [
			{
				id: null,
				name: edi.i18n.getMessage('form.combo.not.selected')
			}
		];

		for (let i in edi.constants.DEFAULT.FILTER.PERIODS) {
			if (edi.constants.DEFAULT.FILTER.PERIODS.hasOwnProperty(i)) {
				periodTypes.push({
					id: edi.constants.DEFAULT.FILTER.PERIODS[i],
					name: edi.i18n.getMessage('module.form.date.' + edi.constants.DEFAULT.FILTER.PERIODS[i])
				});
			}
		}

		/*
		 * Если не инициализировать загрузку хранилища,
		 * то значение не будет установлено.
		 */
		let store = edi.stores.createMemoryStore(periodTypes, 'SIMPLE');

		return this.createField(
			{
				title: edi.i18n.getMessage('user.profile.filter.time'),
				forceSelection: true,
				queryMode: 'local',
				store,
				name
			},
			createCombo
		);
	}

	defineFieldStates() {
		let isEditAllowed =
			edi.permissions.hasPermission('EDIT_USER_PROFILE') &&
			edi.permissions.hasPermission('EDIT_EDI_USER_PROFILE');

		return {
			language: {
				mandatory: isEditAllowed,
				disabled: !isEditAllowed
			},
			defaultFilteringPeriod: {
				disabled: !isEditAllowed
			},
			saveFilter: {
				hidden: !isEditAllowed
			},
			saveSort: {
				hidden: !isEditAllowed
			},
			thumbprint: {
				hidden: !edi.permissions.hasPermission('ENABLE_CERTIFICATE_AUTHORIZATION')
			}
		};
	}

	showChangePasswordDialog() {
		createPasswordDialog().show();
	}

	showClearSortSettingDialog() {
		edi.core.confirm(null, 'filters.settings.restore.confirmation', function () {
			edi.utils.setData('filters', '');
			edi.utils.setData('sorters', '');
			edi.core.showInfo('filters.settings.restore.success');
		});
	}

	showClearColumnSettingDialog() {
		edi.core.confirm(null, 'columns.settings.restore.confirmation', function () {
			edi.utils.setData('c', '');
			edi.core.showInfo('columns.settings.restore.success');
		});
	}

	async saveFormValues(values) {
		let { language, saveFilter, saveSort, defaultFilteringPeriod, cert } = values;

		await this.saveSettings({
			language,
			saveFilter,
			saveSort,
			defaultFilteringPeriod
		});

		//это нужно что бы настройки по фильтрам применились без перезагрузки
		//(в КП сохраняется весь объект user, но тут у нас раздельное сохранение по табам)
		edi.core.setExtraData('user.saveFilter', saveFilter);
		edi.core.setExtraData('user.saveSort', saveSort);
		edi.core.setExtraData('user.defaultFilteringPeriod', defaultFilteringPeriod);

		if (cert) {
			await this.saveThumbprint({ thumbprint: cert });
		}
	}

	async saveSettings({ language, saveFilter, saveSort, defaultFilteringPeriod }) {
		let me = this;
		return new Promise((resolve, reject) => {
			var saveAttributes = function () {
				edi.rest.sendRequest(
					edi.rest.services.USER.SELF.ATTRIBUTES.PUT,
					'PUT',
					Ext.encode({
						language,
						attributes: {
							saveFilter,
							saveSort,
							defaultFilteringPeriod
						}
					}),
					resolve,
					reject
				);
			};

			var langChanged = language != edi.utils.getCookie(edi.constants.DEFAULT.LANGUAGE_COOKIE_NAME);
			if (langChanged) {
				edi.core.confirm(
					'user.profile.save.warning.title',
					'user.profile.save.warning',
					saveAttributes,
					reject,
					undefined,
					function () {
						me._panel.setLoading(false);
					}
				);
			} else {
				saveAttributes();
			}
		});
	}

	async saveThumbprint({ thumbprint, authenticationChains }) {
		return new Promise((resolve, reject) => {
			let userAuthSettings = {
				thumbprint,
				authenticationChains: []
			};

			authenticationChains = authenticationChains || this._authenticationChains;

			if (Array.isArray(authenticationChains)) {
				authenticationChains?.map(function (item) {
					userAuthSettings.authenticationChains.push({
						method: [item]
					});
				});
			}

			edi.rest.sendRequest(
				edi.rest.services.USER.SELF.CRD.AUTH.PUT,
				'PUT',
				Ext.encode(userAuthSettings),
				resolve,
				reject
			);
		});
	}
}

export { SettingPanel };
