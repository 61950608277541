import {
	createFieldBlockForDetails,
	createFieldSetForDetails,
	createFormContainer,
	FIELD_BLOCK_CLS
} from '@UIkit/components/panels';
import { createLabel, createLabelBlockForDetails, createLabelForDetails } from '@UIkit/components/fields';
import { createActionsPanel, createAddModulePanel, createPanel } from '@Components/panels';
import { createContainer } from '@Components/miscComponents';
import { moduleMethods_003 } from './methods_003';
import { createSimpleSelector } from '@UIkit/components/selectors';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { POA_SIGN_INFO_COLUMNS } from '../columns';
import { moduleMethods } from '../POA_002/methods';
import { flattenLinkedPoA, getLinkedPoA } from '../methods';

/**
 * Класс для просмотра машиночитаемых доверенностей (МЧД 003)
 */
Ext.namespace('edi.modules');
edi.modules['power.of.attorney.details_003'] = function () {
	let moduleData,
		docId,
		docHeader,
		docContent,
		isPeredov,
		linkedPoaList = [];

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		//когда открываем из грида, то там своя кастомная вьюха и айди лежит в docId
		//а когда открываем после создания, то там уже нормальный хэдер
		docId = moduleData.initData.data.docId || moduleData.initData.data.id;
		moduleData.initData.data.id = docId;
		renderData(initCallBack);
		return onDestroy;
	};

	this.onRender = function () {
		edi.events.powerOfAttorney.on('change', outsideChangeHandler);
	};

	const createPrincipalFieldSet = function () {
		let principalData =
			(isPeredov ? docContent.document?.peredov?.svPeredPoln : docContent.document?.dover?.svDoverit) || null;
		const doveritData = isPeredov ? principalData?.[0]?.peredPoln : principalData?.[0]?.doverit;
		let principalType = principalData?.[0]?.tipDoverit || moduleMethods_003.defPrincipalType.COMPANY;
		let result,
			litsoBezDov = [];

		if (principalType === moduleMethods_003.defPrincipalType.COMPANY) {
			// блок данных доверителя-ЮЛ
			const rosOrgData = doveritData?.[isPeredov ? 'rosOrgPerPoln' : 'rosOrgDover'];
			let principalOrgType = createLabelForDetails({
				typography: 'heading_01',
				text: edi.i18n.getMessage('company.type.company')
			});

			const legalEntitySelector = createSimpleSelector(
				{
					cls: 'ui-org-selector',
					readOnly: true,
					infoPanelComponentTpl: moduleMethods_003.getLegalEntityTpl('svRosOrg')
				},
				edi.utils.flattenObject(rosOrgData || {})
			);

			let litsoBezDovFlData = rosOrgData?.litsoBezDov?.[0]?.svFL || {};
			let litsoBezDovIpData = rosOrgData?.litsoBezDov?.[0]?.svIP || {};
			let litsoBezDovUkData = rosOrgData?.litsoBezDov?.[0]?.svyul || {};

			if (!Ext.Object.isEmpty(litsoBezDovFlData)) {
				let principalPersonColumn = createLabelForDetails({
					margin: '16 0 0 0',
					typography: 'heading_01',
					text: edi.i18n.getMessage('power.of.attorney.principal.person.column')
				});

				const litsoBezDovFlSelector = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods_003.getLitsoBezDovFlTpl('svFL')
					},
					edi.utils.flattenObject(rosOrgData?.litsoBezDov?.[0] || {})
				);

				litsoBezDov = [principalPersonColumn, litsoBezDovFlSelector];
			}

			if (!Ext.Object.isEmpty(litsoBezDovIpData)) {
				let principalPersonColumn = createLabelForDetails({
					margin: '16 0 0 0',
					typography: 'heading_01',
					text: edi.i18n.getMessage('power.of.attorney.svIP.column')
				});

				const litsoBezDovIpSelector = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods_003.getLitsoBezDovIpTpl('svIP')
					},
					edi.utils.flattenObject(litsoBezDovIpData)
				);

				litsoBezDov = [principalPersonColumn, litsoBezDovIpSelector];
			}

			if (!Ext.Object.isEmpty(litsoBezDovUkData)) {
				let principalPersonColumn = createLabelForDetails({
					margin: '16 0 0 0',
					typography: 'heading_01',
					text: edi.i18n.getMessage('power.of.attorney.managementCompany.column')
				});

				const litsoBezDovUkSelector = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods_003.getLitsoBezDovUkTpl('svFL')
					},
					edi.utils.flattenObject(litsoBezDovUkData)
				);

				litsoBezDov = [principalPersonColumn, litsoBezDovUkSelector];
			}

			result = createFieldSetForDetails({
				title: edi.i18n.getMessage('power.of.attorney.principal.block'),
				collapsible: true,
				layout: {
					type: 'grid',
					gap: [8, 0],
					area: [12, 12, 12]
				},
				items: [
					createFieldSetForDetails({
						cls: 'fieldset-without-header',
						collapsible: false,
						layout: {
							type: 'grid',
							gap: [8, 0]
						},
						items: [principalOrgType, legalEntitySelector].concat(litsoBezDov)
					})
				]
			});
		} else if (principalType === moduleMethods_003.defPrincipalType.INDIV_ENTREPRENEUR) {
			let principalOrgType = createLabelForDetails({
				typography: 'heading_01',
				text: edi.i18n.getMessage('company.type.individual')
			});
			const ipDoverData = doveritData?.[isPeredov ? 'ipPerPoln' : 'ipDover'] || {};
			const legalEntitySelector = createSimpleSelector(
				{
					cls: 'ui-org-selector',
					readOnly: true,
					infoPanelComponentTpl: moduleMethods_003.getIpDoverTpl('')
				},
				edi.utils.flattenObject(ipDoverData)
			);

			result = createFieldSetForDetails({
				title: edi.i18n.getMessage('power.of.attorney.principal.block'),
				collapsible: true,
				layout: {
					type: 'grid',
					gap: [8, 0],
					area: [12, 12]
				},
				items: [
					createFieldSetForDetails({
						cls: 'fieldset-without-header',
						collapsible: false,
						layout: {
							type: 'grid',
							gap: [8, 0]
						},
						items: [principalOrgType, legalEntitySelector]
					})
				]
			});
		}

		return result;
	};

	const getConfidantFieldSet = function () {
		const getConfidantFieldBlock = function (isFirstRow, recordData) {
			let confTypeName = moduleMethods_003.getUpPredSideTypes().filter((item) => {
				return item.id === recordData?.tipPred;
			})[0];

			let currentType = recordData?.tipPred || '';

			let confidantType = createLabelForDetails({
				typography: 'heading_01',
				text: confTypeName?.name
			});

			let confidantSection = [];

			if (currentType === moduleMethods_003.defConfidantType.INDIVIDUAL) {
				const confidantIndividualSection = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods_003.getSvedFizLTpl('')
					},
					edi.utils.flattenObject(recordData?.pred?.svedFizL || {})
				);
				confidantSection = [confidantIndividualSection];
			} else if (currentType === moduleMethods_003.defConfidantType.COMPANY) {
				let svUpPredCompanySection = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods.getSvUpPredTpl('')
					},
					edi.utils.flattenObject(recordData?.svOrg?.svOrgTip || recordData?.pred?.svedOrg || {})
				);
				let individualDataColumn = createLabelForDetails({
					margin: '16 0 0 0',
					typography: 'heading_01',
					text: edi.i18n.getMessage('power.of.attorney.individual.data')
				});

				let svUpPredFlSection = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods.getSvUpPredFlTpl('')
					},
					edi.utils.flattenObject(recordData?.svOrg?.svFL?.[0] || recordData?.pred?.svedFizL || {})
				);

				confidantSection = [svUpPredCompanySection, individualDataColumn, svUpPredFlSection];
			} else if (currentType === moduleMethods_003.defConfidantType.INDIV_ENTREPRENEUR) {
				let svUpPredFlSection = createSimpleSelector(
					{
						cls: 'ui-org-selector',
						readOnly: true,
						infoPanelComponentTpl: moduleMethods_003.getIpDoverTpl('')
					},
					edi.utils.flattenObject(recordData?.pred?.svedIP || {})
				);

				confidantSection = [svUpPredFlSection];
			}

			return createContainer({
				margin: '8 0',
				layout: {
					type: 'grid',
					gap: [8, 0],
					area: [12, 12, 12, 12]
				},
				items: [
					createFieldSetForDetails({
						cls: 'fieldset-without-header',
						collapsible: false,
						layout: {
							type: 'grid',
							gap: [8, 0]
						},
						items: [confidantType, ...confidantSection]
					})
				]
			});
		};

		const confidantPath = isPeredov ? 'document.peredov.svPoluchPoln' : 'document.dover.svUpPred';
		return createFieldSetForDetails({
			title: edi.i18n.getMessage('power.of.attorney.confidant.info'),
			collapsible: true,
			items: [moduleMethods.createRowsBlock(getConfidantFieldBlock, docContent, confidantPath)]
		});
	};

	const createPoaInformationSection = function () {
		const doverData = docContent.document?.[isPeredov ? 'peredov' : 'dover'];
		const svDovData = doverData?.[isPeredov ? 'svPereDover' : 'svDov'];
		const svPervDover = docContent.document?.peredov?.svPervDover;
		const doveritPerv = svPervDover?.svDoverPerv?.[0]?.doveritPerv;
		const parentalPoaData = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('parental.power.of.attorney.data'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('main.power.of.attorney.number'),
							text: svPervDover?.nomDoverPerv
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.principal.inn'),
							text:
								doveritPerv?.rosOrgDover?.innyul ||
								doveritPerv?.ipDover?.innfl ||
								doveritPerv?.flDover?.innfl
						},
						{
							isNewLine: true,
							title: edi.i18n.getMessage('parental.power.of.attorney.number'),
							//на текущий момент по схеме данные о родительской доверенности должны лежать в nomDoverN
							//если это доверенность 3го уровня, а если она 2го, то родительская и основная совпадают и данных тут нет
							//бэк вынес номер родительской в хэдер и сам разруливает эту логику
							//text: docContent.document?.peredov?.svPeredov?.nomDoverN
							text: docHeader.parentPoaNumber
						}
					]
				})
			]
		});

		const poaData = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('power.of.attorney.data'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('power.of.attorney.format'),
							text: edi.i18n.getMessage(`power.of.attorney.format.${docContent?.versForm}`)
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.application.area'),
							text: moduleMethods_003.calculateApplicationArea(docContent?.prElForm)
						},
						{
							isNewLine: true,
							title: edi.i18n.getMessage('power.of.attorney.internal.number'),
							text: svDovData?.vnNomDover
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.external.number'),
							text: svDovData?.nomDover
						}
					]
				})
			]
		});

		let validLabelText = edi.renderers.getTextDiffBetweenTwoDate(
			new Date(docContent.document?.dover?.svDov?.dataVidDover),
			new Date(docContent.document?.dover?.svDov?.srokDeyst)
		);
		if (validLabelText) {
			validLabelText = `(${edi.i18n.getMessage('power.of.attorney.valid.text')} ${validLabelText})`;
		}

		const dateValid = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('power.of.attorney.date.validity'),
			items: [
				createLabelForDetails({
					date: [svDovData?.dataVidDover, svDovData?.srokDeyst],
					dateFormat: edi.constants.DATE_FORMAT.FNS
				}),
				createLabel({
					typography: 'body-short_01',
					color: '--color-grey-50',
					padding: '14 0 0 8',
					hidden: !validLabelText,
					text: validLabelText
				})
			]
		});

		const additionConditions = createFieldBlockForDetails({
			cls: `${FIELD_BLOCK_CLS.default} ${FIELD_BLOCK_CLS.detailsBlock}`,
			title: edi.i18n.getMessage('power.of.attorney.addition.conditions'),
			items: [
				createLabelBlockForDetails({
					contents: [
						{
							title: edi.i18n.getMessage('power.of.attorney.reassignment'),
							text: edi.i18n.getMessage('power.of.attorney.reassignment.003.' + svDovData?.prPeredov)
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.loss.of.authority'),
							text:
								docContent.document?.dover?.svPoln?.prUtrPoln &&
								edi.i18n.getMessage(
									'power.of.attorney.loss.of.authority.' + docContent.document.dover.svPoln.prUtrPoln
								)
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.power.select'),
							text:
								svDovData?.prSovmPoln &&
								edi.i18n.getMessage('power.of.attorney.power.code.' + svDovData.prSovmPoln)
						},
						{
							title: edi.i18n.getMessage('power.of.attorney.revoke.possibility'),
							text: edi.i18n.getMessage('power.of.attorney.revoke.mark.' + (svDovData?.vidDover ?? '1'))
						}
					]
				})
			]
		});

		const items = [parentalPoaData, poaData, dateValid, additionConditions];

		return createContainer({
			layout: 'anchor',
			defaults: {
				anchor: '100%'
			},
			items: items.filter((it) => !!it)
		});
	};

	const createТotarialСertificationInfoBlock = function () {
		const notarialData = isPeredov ? docContent.document?.peredov?.svNotUd : docContent.document?.dover?.svNotUd;

		const createNotaryInfoBlock = function ({ title, notaryData, notaryFioData }) {
			if (!notaryData) return;

			const notaryFio = [notaryFioData?.familiya, notaryFioData?.imya, notaryFioData?.otchestvo]
				.filter(Boolean)
				.join(' ');
			const notaryInfo = createLabelBlockForDetails({
				contents: [
					{
						text: notaryFio,
						typography: 'body-short_02'
					},
					{
						title: edi.i18n.getMessage('column.position'),
						text: notaryData?.dolzhnost,
						isNewLine: true
					},
					{
						title: edi.i18n.getMessage('power.of.attorney.registration.number.minjust'),
						text: notaryData?.regNomNot,
						isNewLine: true
					}
				]
			});
			return createFieldSetForDetails({
				cls: 'fieldset-without-header',
				layout: {
					type: 'grid',
					gap: [8, 0]
				},
				items: [
					createLabelForDetails({
						typography: 'heading_01',
						text: title
					}),
					notaryInfo
				]
			});
		};
		const placeOfOccurrence = createLabelForDetails({
			title: edi.i18n.getMessage('power.of.attorney.place.of.occurrence'),
			text: notarialData?.mestoDover
		});
		return createFieldSetForDetails({
			title: edi.i18n.getMessage('power.of.attorney.notarial.certification.info'),
			collapsible: true,
			layout: {
				type: 'grid'
			},
			items: [
				createNotaryInfoBlock({
					title: edi.i18n.getMessage('power.of.attorney.notary.info'),
					notaryData: notarialData?.svNotDeystv,
					notaryFioData: notarialData?.svNotDeystv?.fioNotDeystv
				}),
				createNotaryInfoBlock({
					title: edi.i18n.getMessage('power.of.attorney.acting.notary.info'),
					notaryData: notarialData?.vrioNot,
					notaryFioData: notarialData?.vrioNot?.fioVrioNot
				}),
				placeOfOccurrence
			]
		});
	};

	const createModuleForm = function () {
		const config = {
			noUsage: true,
			noLinked: true,
			noLog: true,
			noCountSignatures: true,
			CountSignaturesColumn: POA_SIGN_INFO_COLUMNS,
			rejectReasonText: edi.utils.getAttributeByName(docHeader.attributes, 'REVOKE_REASON'),
			revokeReason: true,
			signaturesTitle: edi.i18n.getMessage('power.of.attorney.signature'),
			getStateField: function () {
				return moduleMethods.getStateField({ docHeader });
			},
			panelConfig: {
				margin: '22 0 0 0'
			}
		};
		moduleData.initData.data.errorMessage = docContent.errorMessage;
		const headPanel = createDocumentHeaderPanel(moduleData.initData, config);

		return createPanel({
			cls: 'edi-details-panel',
			autoScroll: true,
			bodyPadding: '0 0 24 24',
			items: [
				headPanel,
				createFormContainer({
					gap: [24, 0],
					items: [
						createPoaInformationSection(),
						createPrincipalFieldSet(),
						getConfidantFieldSet(),
						createТotarialСertificationInfoBlock(),
						moduleMethods_003.createPowerListSection({
							svPolnLists: docContent?.document?.[isPeredov ? 'peredov' : 'dover']?.svPoln,
							mashPolnLists: docContent?.document?.[isPeredov ? 'peredov' : 'dover']?.svPoln?.mashPoln
						})
					]
				})
			]
		});
	};

	const createModuleActionsPanel = function () {
		let actionsPanel = createActionsPanel();

		const clone = Ext.clone(docHeader);
		clone.data = docContent; // нужно при копировании, чтобы не качать данные с бэка

		const moduleDataClone = Ext.clone(moduleData);
		moduleDataClone.initData.id = moduleDataClone.initData.data.id; // для корректного экспорта
		clone.data.fromSignature = moduleDataClone.initData.fromSignature || false;
		let businessState = docHeader.businessState;

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			infoTitle: edi.i18n.getMessage('power.of.attorney.title'),
			infoNumber: docContent.document?.dover?.svDov?.vnNomDover,
			docNumberTitle: '',
			data: clone,
			direction: edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg),
			moduleData: moduleDataClone,
			needSignatures: edi.document.actions.getSignCount(docHeader),
			actionProps: {
				EDIT: {
					moduleName: 'power.of.attorney.create_003',
					documentNumber: docContent.document?.dover?.svDov?.vnNomDover,
					success: () => edi.events.powerOfAttorney.fireEvent('change', { id: docId })
				},
				DELETE: {
					success: () => {
						moduleData.tab.close();
						edi.events.powerOfAttorney.fireEvent('delete');
					}
				},
				SIGN: {
					label: edi.i18n.getMessage('document.sign.document'),
					methodAddOptions: {
						signUrl: edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.SIGN.GET, {
							documentId: docId
						}),
						signProperties: {
							disabledPoa: true
						},
						beforeInit: function (callback) {
							const confidantRegion = edi.utils.getObjectProperty(
								docContent,
								'document.dover.svUpPred.0.pred.svedFizL.svedFL.adrMZH.region'
							);
							const confidantDocType = edi.utils.getObjectProperty(
								docContent,
								'document.dover.svUpPred.0.pred.svedFizL.svedFL.udLichnFL.kodVidDok'
							);
							const isCorrectConfidantRegion =
								!confidantRegion || edi.stores.initRegionsStore().findRecord('id', confidantRegion);
							const isCorrectConfidantDocType =
								!confidantDocType ||
								moduleMethods_003
									.getPersonIdentityCardTypes()
									.some((docType) => docType.id === confidantDocType);
							if (!isCorrectConfidantRegion) {
								edi.core.showError('power.of.attorney.invalid.confidant.region.error');
							} else if (!isCorrectConfidantDocType) {
								edi.core.showError('power.of.attorney.invalid.confidant.docType.error');
							} else {
								callback();
							}
						}
					},
					success: changeHandler,
					failure: function (response, silent = false) {
						if (!silent) {
							edi.core.showError(
								edi.utils.formatComplexServerError(response, 'power.of.attorney.general.sign.error')
							);
						}

						return changeHandler();
					}
				},
				SEND: {
					label: edi.i18n.getMessage('power.of.attorney.action.send'),
					success: () => edi.events.powerOfAttorney.fireEvent('change', { id: docId })
				},
				REFRESH: {
					handler: changeHandler
				},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					xmlExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.xml'),
					addXmlExport: true,
					exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE, {
						documentId: docId
					}),
					addExtendedExport:
						businessState === edi.constants.STATE.REVOKED || businessState === edi.constants.STATE.ACTIVE,
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: docId
					})
				},
				COPY: {
					showInFirstHalf: true,
					order: 185,
					methodAddOptions: {
						glyph: edi.constants.ICONS.REFRESH
					},
					label: edi.i18n.getMessage('form.btn.reissue')
				},
				REVOKE: {
					label: edi.i18n.getMessage('form.btn.revoke'),
					failure: function (errorData) {
						edi.core.showError(
							edi.utils.formatComplexServerError(errorData, 'power.of.attorney.error.revoking')
						);
						moduleData.tab.setLoading(false);
					},
					methodAddOptions: {
						maxInputLength: 500,
						modalCfg: {
							modalTitle: edi.i18n.getMessage('document.revoke.reason.field.title'),
							reasonText: edi.i18n.getMessage('document.revoke.reason.title'),
							buttonText: edi.i18n.getMessage('form.btn.revoke')
						},
						fieldConfig: {
							fieldTitle: edi.i18n.getMessage('ew.document.revoke.reason.title'),
							emptyText: edi.i18n.getMessage('document.revoke.reason.title'),
							modalTitle: edi.i18n.getMessage('ew.document.revoke.reason.field.title'),
							buttonText: edi.i18n.getMessage('form.btn.revoke')
						},
						getValidationError: function () {
							return 'document.revoke.reason.fill.fields.error';
						},
						process: {
							url: edi.utils.formatString(edi.rest.services.POWER_OF_ATTORNEY.REVOKE.POST, {
								documentId: docId
							}),
							method: 'POST',
							properties: function (reasonText) {
								return {
									REVOKE: true,
									REVOKE_REASON: reasonText
								};
							},
							//после отправки отклонения его нужно еще и подписать
							callback: function (response, success, failure) {
								moduleData.tab.setLoading(false);
								if (response.success === true) {
									edi.utils.sign(
										response.data,
										moduleData.tab,
										function (failed, responseData) {
											moduleData.tab.setLoading(false);
											if (failed) {
												failure(responseData);
											} else {
												moduleData.tab.setLoading(true);
												renderData(function () {
													edi.core.showInfo(
														edi.i18n.getMessage(
															'power.of.attorney.error.revoked.successfully'
														)
													);
												});
											}
										},
										undefined,
										undefined,
										undefined,
										undefined,
										{
											signContentUrl: edi.utils.formatString(
												edi.rest.services.POWER_OF_ATTORNEY.SIGN.GET,
												{
													documentId: response.data.id
												}
											),
											disabledPoa: true
										}
									);
								} else {
									failure(response);
								}
							}
						}
					}
				},
				REJECT: {
					success: () => edi.events.powerOfAttorney.fireEvent('change', { id: docId })
				}
			}
		});

		return actionsPanel;
	};

	const renderData = function (initCallback) {
		const continueLoading = function () {
			const modulePanel = createAddModulePanel();
			modulePanel.add(createModuleForm());

			const vnNomDover = isPeredov
				? docContent?.document?.peredov?.svPereDover?.vnNomDover
				: docContent?.document?.dover?.svDov?.vnNomDover;
			if (vnNomDover) {
				const title = edi.i18n.getMessage('power.of.attorney.details_003') + ' ' + vnNomDover;
				moduleData.tab.setTitle(title);
			}

			moduleData.tab.removeAll();
			moduleData.tab.add(createModuleActionsPanel());
			moduleData.tab.add(modulePanel);

			moduleData.tab.setLoading(false);
			if (typeof initCallback === 'function') {
				initCallback();
			}
		};
		moduleMethods
			.fetchData({
				poaId: docId,
				fromSignature: moduleData.initData.fromSignature,
				docId: moduleData.initData.signedWithPoaDocId
			})
			.then(function ({ header, content }) {
				docHeader = header;
				docHeader.id = docId;
				docContent = content;
				moduleData.initData.data = docHeader;
				isPeredov = !Ext.Object.isEmpty(docContent?.document?.peredov);
				if (isPeredov) {
					getLinkedPoA({ poaId: docId })
						.then((items) => {
							linkedPoaList = flattenLinkedPoA(items);
						})
						.finally((items) => {
							continueLoading();
						});
				} else {
					continueLoading();
				}
			})
			.catch(function (resp) {
				moduleData.tab.close();
				edi.rest.getErrorHandler()(resp);
			});
	};

	const changeHandler = function () {
		moduleData.tab.setLoading(true);
		renderData();
	};

	const outsideChangeHandler = function (data) {
		if (data?.id === moduleData.initData.id) {
			changeHandler();
		}
	};

	const onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		edi.events.powerOfAttorney.un('change', outsideChangeHandler);
		return true;
	};
};
