import { createCompanySelector } from '@Components/company.selector.js';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { dsfClientMethods } from '@Edi/modules/documents/DSF_CLIENT/methods';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import createPreviewPanel from '@Components/previewPanel/previewPanel';
import { createContainer } from '@UIkit/components/panels';

Ext.namespace('edi.modules');
edi.modules['document.details.dsf_client'] = function () {
	let moduleData,
		docHeader,
		docContent,
		fields = {},
		utochDoc = null,
		noticeDoc = null,
		annulDoc = null,
		latestAnnulRejectReasonText,
		extension,
		customFieldsContainer,
		izvPolSignModal;

	const topPath = '//DocumentFreeFormat/';

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		docHeader = moduleData.initData.data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};

	/**
	 * Creates dsf panel
	 * @returns {Object}
	 */
	let createDetailsPanel = function () {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			latestAnnulRejectReasonText: latestAnnulRejectReasonText,
			panelConfig: {
				bodyPadding: '0 5 15 10'
			}
		});

		var filePanel = null;
		if (docContent.file) {
			var fileName = docContent.file.fileName;

			const fileDropper = createFileDropper({
				columnWidth: 0.7,
				readOnly: true,
				fileData: {
					fileName: docContent?.file?.fileName,
					fileDate: edi.utils.formatDate(docContent?.file?.modifyDate, edi.constants.DATE_FORMAT.FNS),
					fileTime: edi.utils.formatDate(docContent?.file?.modifyDate, edi.constants.DATE_FORMAT.TIME),
					fileSize: edi.utils.formatFileSize(docContent?.file?.fileSize)
				}
			});

			if (edi.permissions.hasPermission('CLIENT_READ_ATTACHMENT_VIEW')) {
				var glyphs = {
					docx: edi.constants.ICONS.DOCX,
					pdf: edi.constants.ICONS.PDF,
					xls: edi.constants.ICONS.XLS,
					jpg: edi.constants.ICONS.PNG,
					png: edi.constants.ICONS.PNG
				};
				extension = Ext.isString(docContent.file.extension) ? docContent.file.extension.toLowerCase() : null;
				filePanel = createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
					items: [
						createLabel({
							columnWidth: 0.3,
							cls: 'edi-fieldset-label',
							html: edi.i18n.getMessage('document.file')
						}),
						createButton({
							cls: [BUTTON_CLS.light, BUTTON_CLS.small],
							tooltip: fileName,
							glyph: extension && glyphs[extension] ? glyphs[extension] : edi.constants.ICONS.DOCX,
							handler: function () {
								moduleData.tab.setLoading();

								edi.rest.sendRequest(
									edi.utils.formatString(edi.rest.services.DOCUMENTS.DSF_ATTACHMENT.VIEW.POST, {
										documentId: moduleData.initData.id
									}),
									'POST',
									{},
									function (responseData) {
										const attachData = responseData?.data;
										if (attachData) {
											createPreviewPanel({
												fileId: moduleData.initData.id,
												fileName,
												imagesBase64Data: attachData.data?.map(({ data }) => data)
											});
										} else {
											edi.core.showInfo('document.dsf.load.preview.waiting');
										}
									},
									function (err) {
										edi.core.showInfo('document.dsf.load.preview.error');
									},
									function () {
										moduleData.tab.setLoading(false);
									}
								);
							}
						}),
						fileDropper
					]
				});
			} else {
				filePanel = createPanel({
					layout: 'column',
					cls: 'edi-create-field-line',
					items: [
						createField({
							title: edi.i18n.getMessage('document.file'),
							input: fileDropper,
							containerConfig: {
								columnWidth: 1
							}
						})
					]
				});
			}
		}

		let headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.data'),
					input: createLabel({
						columnWidth: 0.67,
						text: edi.i18n.getMessage('documents.subdoctype.' + docContent.reference.subDocType),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.6
					}
				}),
				createLabel({
					emptyText: edi.i18n.getMessage('nr'),
					margin: '0 0 0 5',
					columnWidth: 0.2,
					text: docHeader.number,
					valueLabel: true
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.2,
					text: docHeader.doctime,
					dateFormat: edi.constants.DATE_FORMAT.FNS,
					srcFormat: edi.constants.DATE_FORMAT.SERVER,
					valueLabel: true
				})
			]
		});

		var createCS = function (selectorConf) {
			Ext.applyIf(selectorConf, {
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				fieldValues: docContent,
				disableCommonAddressRenderer: true,
				margin: '5 0 10 10',
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				modalConf: dsfClientMethods.getCompanyModalConf()
			});

			return createCompanySelector(selectorConf);
		};

		fields.sender = createCS({
			title: 'documents.sender',
			fieldsMap: dsfClientMethods.getCompanyFieldsMap('sender')
		});

		fields.receiver = createCS({
			title: 'documents.receiver',
			fieldsMap: dsfClientMethods.getCompanyFieldsMap('receiver')
		});

		/**
		 * Дополнительные поля
		 */
		const createCustomFieldsContainer = function () {
			return (customFieldsContainer = createContainer({}));
		};

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [
				headPanel,
				createTwoColumnsLayout([headData, fields.sender, fields.receiver], [filePanel], 0.5, {
					items2Conf: {
						margin: '0 0 0 10'
					}
				}),
				createCustomFieldsContainer()
			]
		});
	};

	var createSignIzvPol = function (success, document, modal) {
		if (modal) {
			modal.close();
		}
		edi.utils.sign(
			document,
			moduleData.tab,
			function (failed, responseData, certificate, poa) {
				if (failed) {
					if (responseData.typeError !== 'certificate.data.not.matched.with.organization') {
						edi.core.showError(edi.utils.formatComplexServerError(responseData));
					}
				} else {
					success(certificate, poa);
				}
			},
			undefined,
			undefined,
			undefined,
			undefined,
			{
				signContentUrl: edi.utils.formatString(
					'AB' === edi.login.getAuthType()
						? edi.rest.services.DOCUMENTS.SIGN_AB.GET
						: edi.rest.services.DOCUMENTS.SIGN.GET,
					{
						documentId: noticeDoc.id
					}
				),
				signContentUrlMethod: 'PUT'
			}
		);
	};

	/**
	 * Creates action panel above the data panel
	 */
	let createModuleActionsPanel = function () {
		let document = moduleData.initData.data,
			actionsPanel = createActionsPanel();
		let direction = edi.utils.getDocumentDirection(document.toOrg, document.fromOrg);
		let side = edi.document.actions.getSignSide(document.type, direction);
		let isIncomingDoc = edi.constants.DIRECTIONS.INCOMING === direction;
		let isOutgoingDoc = edi.constants.DIRECTIONS.OUTGOING === direction;
		let useBeforeSignAction =
			document.state === edi.constants.STATE.DRAFT ||
			document.state === edi.constants.STATE.READ ||
			document.state === edi.constants.STATE.SENT ||
			document.state === edi.constants.STATE.WAIT_RECEIVER_DECISION;

		let needSignIzvPol =
			(document.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER && isIncomingDoc) ||
			(document.state === edi.constants.STATE.DSF_DP_IZVPOL_SENDER && isOutgoingDoc);

		let callBeforeInit = function (success, certificate, poa) {
			if (
				'object' == typeof edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES &&
				edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.DOCUMENT_TYPES.some((it) => it === document.type) &&
				edi.constants.PUSH_TO_READ_BEFORE_SIGN_RULES.STATES.some((it) => it === document.state)
			) {
				edi.rest.sendRequest(
					edi.utils.formatString(
						edi.rest.services.DOCUMENTS.SEND.PUT,
						{
							documentId: document.id
						},
						true
					),
					'PUT',
					Ext.encode({}),
					function () {
						success(certificate, poa);
					}
				);
			} else {
				'function' == typeof success ? success(certificate, poa) : null;
			}
		};

		if (needSignIzvPol && noticeDoc) {
			if (!annulDoc) {
				annulDoc = {};
			}
			//будет вызван сразу после аннулирования для подписания извещения о получении
			annulDoc.beforeAnnul =
				needSignIzvPol && noticeDoc
					? function (success, modal) {
							createSignIzvPol(
								function (selectedCertificate, selectedPoa) {
									success(selectedCertificate, selectedPoa);
								},
								document,
								modal
							);
					  }
					: null;
		}

		var needChangeCountSignature = edi.constants.DSF_FOR_MULTY_SIGN.some((it) => it === document.type);
		var canMultiSign = edi.utils.getAttributeByName(docHeader.attributes, 'canMultiSign');
		canMultiSign = canMultiSign === true || canMultiSign === 'true';
		var isMultiSign = canMultiSign && needChangeCountSignature;
		var attrName = 'countSignature_' + side;
		var attr = { countSignature: parseInt(edi.utils.getAttributeByName(docHeader.attributes, attrName), 10) };

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: document,
			direction: direction,
			moduleData: moduleData,
			actionProps: {
				SIGN: {
					label: isMultiSign ? 'document.sign.document' : null,
					methodAddOptions: {
						useBeforeAction: needSignIzvPol && noticeDoc ? true : useBeforeSignAction,
						beforeInit: function (success) {
							var afterChangeCountSignature = function () {
								if (needSignIzvPol && noticeDoc) {
									createSignIzvPol(function (selectedCertificate, selectedPoa) {
										callBeforeInit(success, selectedCertificate, selectedPoa);
									}, document);
								} else {
									callBeforeInit(success);
								}
							};
							//если есть атрибут и документ из списка DSF_FOR_MULTY_SIGN и есть право
							if (isMultiSign) {
								//изменить атрибуты и вызвать afterChangeCountSignature
								edi.methods.documents.changeCountSignatureAttribute(
									1,
									attr,
									document.type,
									document.id,
									direction,
									afterChangeCountSignature
								);
							} else {
								afterChangeCountSignature();
							}
						},
						afterFailure: function () {
							if (isMultiSign) {
								edi.methods.documents.changeCountSignatureAttribute(
									-1,
									attr,
									document.type,
									document.id,
									direction,
									null
								);
							}
						},
						signProperties: {
							signContentUrl: edi.utils.formatString(
								'AB' === edi.login.getAuthType()
									? edi.rest.services.DOCUMENTS.SIGN_AB.GET
									: edi.rest.services.DOCUMENTS.SIGN.GET,
								{ documentId: document.id }
							),
							signRefuse: function () {
								moduleData.tab?.setLoading(false);
								if (isMultiSign) {
									edi.methods.documents.changeCountSignatureAttribute(
										-1,
										attr,
										document.type,
										document.id,
										direction,
										null
									);
								}
							}
						}
					}
				},
				SEND:
					canMultiSign &&
					needChangeCountSignature &&
					edi.permissions.hasPermission('SEND_' + document.type + '_BUTTON')
						? {
								failure: function () {
									edi.methods.documents.changeCountSignatureAttribute(
										1,
										attr,
										document.type,
										document.id,
										direction,
										null
									);
									edi.document.actions.defaultFailureHandler(
										moduleData.tab,
										'document.error.in.sending.process'
									);
								},
								beforeAction: function (success) {
									let newValue = document.countSignatures;
									edi.methods.documents.changeCountSignatureAttribute(
										null,
										attr,
										document.type,
										document.id,
										direction,
										success,
										newValue
									);
								}
						  }
						: {},
				COMPLETE:
					canMultiSign &&
					needChangeCountSignature &&
					edi.permissions.hasPermission('COMPLETE_' + document.type + '_BUTTON')
						? {
								failure: function () {
									edi.methods.documents.changeCountSignatureAttribute(
										1,
										attr,
										document.type,
										document.id,
										direction,
										null
									);
									edi.document.actions.defaultFailureHandler(
										moduleData.tab,
										'document.error.in.sending.process'
									);
								},
								beforeAction: function (success) {
									let newValue =
										document.countSignatures -
										parseInt(
											edi.utils.getAttributeByName(
												moduleData.initData.data.attributes,
												'countSignature_' + edi.constants.CONTRACT_SIDES.SUPPLIER
											),
											10
										);
									edi.methods.documents.changeCountSignatureAttribute(
										null,
										attr,
										document.type,
										document.id,
										direction,
										success,
										newValue
									);
								}
						  }
						: {},
				EXPORT: {
					label: edi.i18n.getMessage('action.export.document'),
					exportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.sign'),
					extendedExportBtnLabel: edi.i18n.getMessage('export.group.request.menu.btn.all'),
					addExtendedExport: true,
					exportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.SIMPLE, {
						documentId: moduleData.initData.id
					}),
					extendedExportBtnUrl: edi.utils.formatString(edi.rest.services.DOCUMENTS.EXPORT.COMMON, {
						documentId: moduleData.initData.id
					})
				},
				REFRESH: {
					handler: function () {
						changeHandler(document);
					}
				},
				REJECT: {
					beforeAction:
						needSignIzvPol && noticeDoc
							? function (success) {
									createSignIzvPol(function (selectedCertificate, selectedPoa) {
										success(selectedCertificate, selectedPoa);
									}, document);
							  }
							: null,
					methodAddOptions: {
						process: {
							url: edi.rest.services.DOCUMENTS.DSF_REJECT.POST,
							method: 'POST',
							params: {
								parentId: document.id
							},
							properties: function (reasonText) {
								return {
									reason: reasonText
								};
							},
							callback: function (signData, success, failed, selectedCertificate, selectedPoa) {
								edi.document.actions.signUtochDocument(
									signData.data,
									document,
									success,
									failed,
									moduleData.tab,
									selectedCertificate,
									selectedPoa
								);
							}
						}
					}
				},
				ANNUL: {
					methodAddOptions: annulDoc
				}
			}
		});

		return actionsPanel;
	};

	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	let changeHandler = function (data) {
		if (data && data.id === moduleData.initData.data.id) {
			if (data.deleted) {
				edi.modulesHandler.removeModule(moduleData);
			} else {
				renderData(null);
			}
		}
	};

	let showIzvPolSignModal = function (document) {
		izvPolSignModal = edi.core.showWarn('dsf.need.sign.izv.pol', function () {
			createSignIzvPol(function () {
				edi.events.documents.fireEvent('change', {
					id: document.id
				});
			}, document);
		});
	};

	/**
	 * Get document's header data from backend
	 * @param	{Function}	success
	 * @param	{Function}	fail
	 */
	let getDocHeader = function (success, fail) {
		edi.rest.sendRequest(
			edi.document.actions.formatHeaderUri(moduleData.initData),
			'GET',
			undefined,
			function (resp) {
				if (resp?.data) {
					moduleData.initData.data = resp.data;
					docHeader = resp.data;
					success(resp);
				} else {
					fail(resp);
				}
			},
			fail
		);
	};

	/**
	 * Get document's content from backend
	 * @param	{Function}	success
	 * @param	{Function}	fail
	 */
	let getDocContent = function (success, fail) {
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (resp) {
				if (resp?.data) {
					docContent = resp.data;
					success();
				} else {
					fail(resp);
				}
			},
			fail
		);
	};

	/**
	 * Get notices or utoch from linked documents
	 * @param	{Function}	success
	 * @param	{Function}	fail
	 */
	let getNotices = function (success, fail) {
		const businessState = edi.utils.getObjectProperty(docHeader, 'businessState');
		let utochReasonText = edi.utils.getAttributeByName(docHeader.attributes, 'UTOCH_REASON');
		let hasAnnul =
			businessState === edi.constants.STATE.ON_ANNUL || businessState === edi.constants.STATE.ANNUL_SIGN;
		let hasIzvPol =
			docHeader.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER &&
			+edi.utils.getAttributeByName(docHeader.attributes, 'senderSignaturesCount') > 0 &&
			edi.utils.getAttributeByName(docHeader.attributes, 'receiptNoticeExpected') === 'true';

		annulDoc = null;
		latestAnnulRejectReasonText = null;
		utochDoc = null;
		noticeDoc = null;
		if (utochReasonText || hasAnnul || hasIzvPol) {
			let url = edi.document.actions.formatLinkedTreeUri(moduleData.initData, {
				depth: edi.constants.DEFAULT.TREE_DEPTH
			});
			edi.rest.sendRequest(
				url,
				'GET',
				{},
				function (responseData) {
					if (Array.isArray(responseData?.items)) {
						let annulActiveStates = [edi.constants.STATE.WAIT_PARTNER_DECISION, edi.constants.STATE.DRAFT];
						responseData.items.forEach((doc) => {
							if (
								doc.type === edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_PRANNUL &&
								annulActiveStates.some((it) => it === doc.state)
							) {
								annulDoc = doc;
							} else if (doc.type === edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_UVUTOCH) {
								utochDoc = doc;
							} else if (doc.type === edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_IZVPOL) {
								noticeDoc = doc;
							}
						});
						let annulDocRejected = responseData.items
							.filter(
								(doc) =>
									doc.type === edi.constants.DOCUMENT_TYPES.EDI_DSF_DP_PRANNUL &&
									doc.state === edi.constants.STATE.REJECTED
							)
							.sort((a, b) => (a.modifyDate > b.modifyDate ? -1 : 1))[0];
						latestAnnulRejectReasonText = annulDocRejected
							? edi.utils.getAttributeByName(annulDocRejected.attributes, 'REJECT_REASON')
							: null;
					}
					success();
				},
				fail
			);
		} else {
			success();
		}
	};

	/**
	 * Renders module layout
	 * @param	{Function}	initCallBack	callback that must be called on module initialization
	 */
	let renderData = function (initCallBack) {
		moduleData.tab.removeAll();
		moduleData.tab.setLoading();

		let failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', () =>
			edi.modulesHandler.removeModule(moduleData)
		);

		let finishLoad = function () {
			let documentDirection = edi.utils.getDocumentDirection(docHeader.toOrg, docHeader.fromOrg);

			if (docContent?.reference) {
				docContent.sender.address.addr_category = docContent?.reference?.addressTypeSender;
				docContent.receiver.address.addr_category = docContent?.reference?.addressTypeReceiver;
			}

			moduleData.tab.add(createModuleActionsPanel(docContent));
			let modulePanel = createDetailsModulePanel();
			modulePanel.add(createDetailsPanel());
			moduleData.tab.add(modulePanel);

			let showNeedSignIzvPol =
				(docHeader.state === edi.constants.STATE.DSF_DP_IZVPOL_RECEIVER &&
					edi.constants.DIRECTIONS.INCOMING === documentDirection &&
					edi.utils.getAttributeByName(docHeader.attributes, 'RECEIVER_SIGNATURE_EXPECTED') === 'false') ||
				(docHeader.state === edi.constants.STATE.DSF_DP_IZVPOL_SENDER &&
					edi.constants.DIRECTIONS.OUTGOING === documentDirection);

			if (showNeedSignIzvPol && noticeDoc) {
				showIzvPolSignModal(docHeader);
			} else if (izvPolSignModal && !izvPolSignModal.isDestroyed) {
				izvPolSignModal.close();
			}

			if (
				annulDoc &&
				annulDoc.state === edi.constants.STATE.WAIT_PARTNER_DECISION &&
				annulDoc.toOrg.id === edi.core.getUserOrgID()
			) {
				edi.methods.documents.showActiveAnnulModal(annulDoc, moduleData, {
					decisionMandatory: false
				});
			}

			if (docHeader.toOrg.id && docHeader.fromOrg.id && docContent?.reference?.subDocType) {
				edi.methods.custom_fields.initCustomFields({
					docType: edi.constants.DOCUMENT_TYPES.DSF_CLIENT,
					subDocType: docContent.reference.subDocType,
					toOrgId: docHeader.toOrg.id,
					fromOrgId: docHeader.fromOrg.id,
					docId: docContent.header,
					container: customFieldsContainer,
					topPath: topPath,
					readOnly: true
				});
			}

			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};

		getDocHeader(function () {
			getDocContent(function () {
				getNotices(finishLoad, failure);
			}, failure);
		}, failure);
	};
	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
