edi.i18n.trMap.STATE.REJECTED.key = 'documents.status.REJECTED.OUTGOING';
edi.i18n.trMap.STATE.REJECTED.INCOMING.key = 'documents.status.REJECTED.OUTGOING';
edi.i18n.trMap.STATE.REJECTED.OUTGOING.key = 'documents.status.REJECTED.OUTGOING';
edi.i18n.trMap.STATE.REVOKED = {
	LOOP: {
		POWER_OF_ATTORNEY: {
			key: 'documents.status.POWER_OF_ATTORNEY_REVOKED'
		}
	}
};
edi.i18n.trMap.BUSINESS_STATE.DSF_DP_IZVPOL_RECEIVER.INCOMING.key = 'documents.status.DSF_DP_IZVPOL_RECEIVER';
edi.i18n.trMap.BUSINESS_STATE.DSF_DP_IZVPOL_RECEIVER.OUTGOING.key = 'documents.status.DSF_DP_IZVPOL_RECEIVER';
