import { createCompanySelector } from '@Components/company.selector.js';
import { createProductGridBase } from '@Components/product.grid';
import { createActionsPanel, createDetailsModulePanel, createPanel } from '@Components/panels';
import { createDateLabel, createField, createLabel } from '@Components/fields';
import { createTwoColumnsLayout } from '@Components/miscComponents';
import {
	ALCDES_LINE_CERTIFICATE_COLUMN,
	ALCDES_LINE_COLUMN,
	ALCDES_LINE_PARTY_COLUMN,
	ALCDES_LINE_REFERENCE_COLUMN,
	ALCDES_SUMMARY_DETAIL_COLUMN
} from './columns';
import {
	ALCDES_LINE_CERTIFICATE_MODEL,
	ALCDES_LINE_MODEL,
	ALCDES_LINE_PARTY_MODEL,
	ALCDES_LINE_REFERENCE_MODEL,
	ALCDES_SUMMARY_DETAIL_MODEL
} from './models';
import { createDocumentHeaderPanel } from '@Edi/specialComponents/documentHeaderPanel/DocumentHeaderPanel';

/**
 * Class for alcdes details
 * @author Pavel Pirogov
 */
Ext.namespace('edi.modules');
edi.modules['document.details.alcdes'] = function () {
	var moduleData,
		productValues = {
			products: []
		},
		document;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};
	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.documents.on('change', changeHandler);
	};
	/**
	 * Creates despatch advice form
	 * @returns {Object}
	 */
	var createDetailsPanel = function (document) {
		var headPanel = createDocumentHeaderPanel(moduleData.initData, {
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS
		});
		var parties = edi.utils.getObjectProperty(document, 'DespatchAdvice-Parties');

		var headData = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('documents.create.alcdes.nr'),
					input: createLabel({
						columnWidth: 0.7333,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchAdviceNumber'),
						valueLabel: true
					}),
					mandatory: true,
					containerConfig: {
						columnWidth: 0.75
					}
				}),
				createDateLabel({
					margin: '0 0 0 5',
					columnWidth: 0.25,
					text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchAdviceDate'),
					valueLabel: true
				})
			]
		});

		var WaybillNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('waybill.number'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.WaybillNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var WaybillDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('waybill.date'),
					input: createDateLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.WaybillDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var contractLabel = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('document.select.contract'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.Reference.ContractNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var orderNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('buyer.order.number'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.BuyerOrderNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var orderDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('buyer.order.date'),
					input: createDateLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.BuyerOrderDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var DespatchReferenceNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('despatch.reference.number'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchReferenceNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var DespatchReferenceDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('despatch.reference.date'),
					input: createDateLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchReferenceDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var invoiceNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('alcdes.invoice.number'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.InvoiceNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var invoiceDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('alcdes.invoice.date'),
					input: createDateLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.InvoiceDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var despatchDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('despatch.date'),
					input: createDateLabel({
						columnWidth: 0.6,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				}),
				createLabel({
					margin: '0 0 0 5',
					columnWidth: 0.125,
					text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DespatchTime'),
					valueLabel: true
				})
			]
		});

		var estimatedDeliveryDate = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('estimated.delivery.date'),
					input: createDateLabel({
						columnWidth: 0.6,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.EstimatedDeliveryDate'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 0.5
					}
				})
			]
		});

		var alcdesRemarks = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('remarks'),
					input: createLabel({
						columnWidth: 0.8,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.Remarks'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var transportStore = edi.stores.initModeOfTransportStore();
		var modeOfTransportValue = transportStore.findRecord(
			'id',
			edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.ModeOfTransport')
		);
		var modeOfTransport = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('mode.of.transport'),
					input: createLabel({
						columnWidth: 0.5,
						text: modeOfTransportValue ? modeOfTransportValue.get('name') : undefined,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var Capacity = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('column.capacity'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.Capacity'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var trackValue = edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.Track');
		var Track = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('column.track'),
					input: createLabel({
						columnWidth: 0.5,
						text:
							trackValue == 'true'
								? edi.i18n.getMessage('track.true')
								: trackValue == 'false'
								? edi.i18n.getMessage('track.false')
								: undefined,
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var ConveyanceReferenceNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('conveyance.reference.number'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(
							document,
							'DespatchAdvice-Transport.ConveyanceReferenceNumber'
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var carrierName = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('carrier.name'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.CarrierName'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var termsOfDelivery = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('terms.of.delivery'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.TermsOfDelivery'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var vehicleType = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('vehicle.type'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.VehicleType'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});
		var sealNumber = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('seal.number'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Transport.SealNumber'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var functionalDocumentCode = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('functional.document.code'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.i18n.getMessage(
							'functional.document.code.' +
								edi.utils.getObjectProperty(document, 'DespatchAdvice-Header.DocumentFunctionCode')
						),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var createCS = function (selectorConfig) {
			Ext.applyIf(selectorConfig, {
				userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
				is_valid: true,
				readOnly: true,
				valuesByMap: true,
				fieldValues: parties
			});

			return createCompanySelector(selectorConfig);
		};

		var buyer = createCS({
			title: 'document.buyer',
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('Buyer', {
				includeFields: ['company_name', 'company_iln', 'company_util_reg_nr', 'company_inn']
			})
		});

		var seller = createCS({
			title: 'document.seller',
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('Seller', {
				excludeFields: ['license_type', 'license_name']
			})
		});
		var delivery = createCS({
			title: 'document.delivery',
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('DeliveryPoint', {
				includeFields: ['company_name', 'company_iln', 'addr_zip', 'addr_country', 'addr_city', 'addr_street']
			})
		});

		var carrier = createCS({
			title: 'document.carrier',
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('Carrier', {
				excludeFields: [
					'company_util_reg_nr',
					'addr_area',
					'addr_region_code',
					'addr_region_name',
					'addr_city',
					'addr_street',
					'addr_country'
				]
			})
		});

		var shipFrom = createCS({
			title: 'document.ship.from',
			fieldsMap: edi.selectors.utils.getLegacyFieldsMap('ShipFrom', {
				includeFields: ['company_name', 'company_iln', 'company_kpp']
			})
		});

		var lines = edi.utils.getObjectProperty(document, 'DespatchAdvice-Consignment.Packing-Sequence.Line', true),
			i;
		if ('string' != typeof lines) {
			productValues = {
				products: []
			};
			for (i = 0; i < lines.length; i++) {
				var values = lines[i]['Line-Item'];
				values['Line-License'] = lines[i]['Line-License'];
				values['Line-Parties'] = lines[i]['Line-Parties'];
				values['Line-Reference'] = lines[i]['Line-Reference'];
				values['Line-Certificate'] = lines[i]['Line-Certificate'];
				values['Package-Identification'] = lines[i]['Package-Identification'];
				values['Line-AdditionalInformation'] = lines[i]['Line-AdditionalInformation'];
				values['TaxRate'] = edi.renderers.taxRateString(values['TaxRate']);
				productValues.products.push(values);
			}
		}
		var productsGrid = createProductGridBase({
			title: edi.i18n.getMessage('despatch.advice.items'),
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			gridModel: ALCDES_LINE_MODEL,
			totalModel: ALCDES_SUMMARY_DETAIL_MODEL,
			gridColumnConfig: ALCDES_LINE_COLUMN,
			totalColumnConfig: ALCDES_SUMMARY_DETAIL_COLUMN,
			data: productValues.products,
			margin: '16 0 0',
			readOnly: true,
			docType: edi.constants.DOCUMENT_TYPES.LEGACY_ALCDES,
			docData: document,
			changeValuesBeforeEdit: function (values) {
				if (values.UnitOfMeasure) {
					const okeiStore = edi.stores.initLegacyOkeiStore();
					const val = edi.renderers.UnitOfMeasure(values.UnitOfMeasure);
					const rec = okeiStore.findRecordByName(val);
					if (rec) {
						values.UnitOfMeasure = rec.get('name_international');
					}
				}
			},
			modalFormConfig: {
				title: 'document.desadv.line',
				modalFields: [
					{
						title: edi.i18n.getMessage('line.item.tab.product'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.ean'),
								name: 'EAN',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('column.quantity.ordered'),
								allowDecimals: true,
								name: 'OrderedQuantity',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.quantity.despatched'),
								name: 'QuantityDespatched',
								allowDecimals: true,
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.of.measure'),
								type: 'okeiCode'
							},
							{
								title: edi.i18n.getMessage('line.item.item.type'),
								name: 'ItemType',
								type: 'combo',
								store: edi.stores.initItemTypeStore()
							},
							{
								title: edi.i18n.getMessage('line.item.item.description'),
								name: 'ItemDescription'
							},
							{
								title: edi.i18n.getMessage('line.item.buyer.item.code'),
								name: 'BuyerItemCode'
							},
							{
								title: edi.i18n.getMessage('line.item.supplier.item.code'),
								name: 'SupplierItemCode'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.tax.and.price'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.unit.net.price'),
								allowDecimals: true,
								name: 'UnitNetPrice',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.tax.rate'),
								name: 'TaxRate'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.package.and.delivery'),
						items: [
							{
								title: edi.i18n.getMessage('line.item.expiration.date'),
								name: 'ExpirationDate',
								type: 'label'
							},
							{
								title: edi.i18n.getMessage('line.item.packaging.date'),
								name: 'PackagingDate',
								type: 'label'
							},
							{
								title: edi.i18n.getMessage('line.item.alcohol.code'),
								name: 'AlcoholCode'
							},
							{
								title: edi.i18n.getMessage('line.item.unit.volume'),
								allowDecimals: true,
								name: 'UnitVolume',
								type: 'number'
							},
							{
								title: edi.i18n.getMessage('line.item.country.of.origin'),
								name: 'Line-AdditionalInformation.CountryOfOrigin',
								type: 'combo',
								store: edi.stores.initOrgCountryStoreISO2()
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.license'),
						items: [
							{
								title: edi.i18n.getMessage('column.license.series'),
								name: 'Line-License.Series'
							},
							{
								title: edi.i18n.getMessage('column.license.number'),
								name: 'Line-License.Number'
							},
							{
								title: edi.i18n.getMessage('column.license.issuing.authority'),
								name: 'Line-License.IssuingAuthority'
							},
							{
								title: edi.i18n.getMessage('column.license.date.of.issue'),
								name: 'Line-License.DateOfIssue',
								type: 'label'
							},
							{
								title: edi.i18n.getMessage('column.license.expiration.date'),
								name: 'Line-License.ExpirationDate',
								type: 'label'
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.parties'),
						bodyPadding: 0,
						items: [
							{
								type: 'editableGrid',
								name: 'Line-Parties.Line-Party',
								config: {
									fields: [
										{
											name: 'PartyType',
											type: 'combo',
											store: edi.stores.createSimpleInlineStore(['MF', 'IM'], function (id) {
												return edi.i18n.getMessage('party.type.' + id);
											}),
											forceSelection: true,
											allowBlank: false
										},
										{
											name: 'Name'
										},
										{
											name: 'ILN',
											type: 'number',
											enforceMaxLength: true
										},
										{
											name: 'TaxID',
											enforceMaxLength: true
										},
										{
											name: 'TaxRegistrationReasonCode',
											enforceMaxLength: true
										}
									],
									limit: undefined,
									model: ALCDES_LINE_PARTY_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										columns: edi.columns.get(ALCDES_LINE_PARTY_COLUMN),
										height: 300,
										disablePaging: true
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.reference'),
						bodyPadding: 0,
						items: [
							{
								type: 'editableGrid',
								name: 'Line-Reference.ReferenceElements.Reference-Element',
								config: {
									fields: [
										{
											name: 'Reference-Id',
											isTextarea: true
										},
										{
											name: 'Reference-Type',
											type: 'hidden',
											value: 'ABT'
										}
									],
									limit: undefined,
									model: ALCDES_LINE_REFERENCE_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										columns: edi.columns.get(ALCDES_LINE_REFERENCE_COLUMN),
										height: 300,
										disablePaging: true
									}
								}
							}
						]
					},
					{
						title: edi.i18n.getMessage('line.item.tab.certificate'),
						bodyPadding: 0,
						items: [
							{
								type: 'editableGrid',
								name: 'Line-Certificate.Certificates.certificate',
								config: {
									fields: [
										{
											name: 'Number'
										},
										{
											name: 'IssuingAuthority'
										},
										{
											name: 'DateOfIssue',
											type: 'date'
										},
										{
											name: 'ExpirationDate',
											title: edi.i18n.getMessage('column.license.expiration.date'),
											type: 'date'
										}
									],
									limit: undefined,
									model: ALCDES_LINE_CERTIFICATE_MODEL,
									gridConfig: {
										cls: 'modal-grid',
										columns: edi.columns.get(ALCDES_LINE_CERTIFICATE_COLUMN),
										height: 300,
										disablePaging: true
									}
								}
							}
						]
					}
				]
			}
		});

		var totalWeight = createPanel({
			layout: 'column',
			cls: 'edi-create-field-line',
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			items: [
				createField({
					title: edi.i18n.getMessage('column.total.weight'),
					input: createLabel({
						columnWidth: 0.5,
						text: edi.utils.getObjectProperty(document, 'DespatchAdvice-Summary.TotalWeight'),
						valueLabel: true
					}),
					containerConfig: {
						columnWidth: 1
					}
				})
			]
		});

		var twoColumnsLayout = createTwoColumnsLayout(
			[headData, despatchDate, estimatedDeliveryDate, alcdesRemarks, seller, buyer, delivery, carrier, shipFrom],
			[
				orderNumber,
				orderDate,
				invoiceNumber,
				invoiceDate,
				WaybillNumber,
				WaybillDate,
				DespatchReferenceNumber,
				DespatchReferenceDate,
				modeOfTransport,
				carrierName,
				vehicleType,
				sealNumber,
				termsOfDelivery,
				contractLabel,
				Capacity,
				Track,
				ConveyanceReferenceNumber,
				totalWeight,
				functionalDocumentCode
			],
			0.625
		);

		return createPanel({
			cls: 'edi-details-panel',
			bodyPadding: 10,
			autoScroll: true,
			items: [headPanel, twoColumnsLayout, productsGrid]
		});
	};

	/**
	 * Creates action panel
	 */
	var createModuleActionsPanel = function (document) {
		var data = moduleData.initData.data;
		document.id = data.id;
		var actionsPanel = createActionsPanel();
		var direction = edi.utils.getDocumentDirection(data.toOrg, data.fromOrg);
		var needSignatures = edi.document.actions.getSignCount(data);
		var needSignatures2 =
			edi.constants.DIRECTIONS.OUTGOING === direction
				? 0
				: edi.document.actions.getSignCount(data, edi.constants.CONTRACT_SIDES.SUPPLIER);
		var signaturesCount = needSignatures + needSignatures2 - data.countSignatures;
		signaturesCount = signaturesCount < 0 ? 0 : signaturesCount; //Legacy documents could have optional sign, that will lead to needed number of signs less than zero

		const customButtons = [];
		const createDocConfig = [
			{
				permission: 'CREATE_LEGACY_INVOICE',
				title: 'action.invoice',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_INVOICE,
				config: {
					isFromTransformation: true,
					buyerOrg: moduleData.initData.data.toOrg
				}
			},
			{
				permission: 'CREATE_LEGACY_REC_ADV',
				title: 'action.recadv',
				docType: edi.constants.DOCUMENT_TYPES.LEGACY_REC_ADV,
				config: {
					isFromTransformation: true,
					buyerOrg: moduleData.initData.data.toOrg
				}
			}
		];

		const basedDocuments = edi.document.actions.createListBasedDocuments(
			'CREATE_FROM',
			data,
			moduleData,
			document,
			'alcdes',
			createDocConfig
		);

		if (basedDocuments?.length) {
			customButtons.push(edi.document.actions.createBasedAddDocumentActionsButton(basedDocuments));
		}

		edi.document.actions.createDocumentActionButtons(actionsPanel, {
			data: data,
			direction: direction,
			moduleData: moduleData,
			needSignatures: signaturesCount,
			actionProps: {
				EDIT: {
					moduleName: 'document.create.alcdes'
				},
				REFRESH: {
					handler: function () {
						changeHandler(data);
					}
				},
				CUSTOM_BUTTONS: {
					buttons: customButtons
				}
			}
		});

		return actionsPanel;
	};
	/**
	 * Change handler, that will initiate refresh of module visuals
	 */
	var changeHandler = function (data) {
		edi.document.actions.changeHandler(
			data,
			moduleData,
			function (headerData) {
				moduleData.initData.data = headerData.data;
			},
			renderData
		);
	};
	/**
	 * Renders module layout
	 * @Param    {Function}    initCalBack    callback that must be called on module initialization finish
	 */
	var renderData = function (initCallBack) {
		moduleData.tab.setLoading();
		var modulePanel = createDetailsModulePanel();

		var continueWithRendering = function () {
			modulePanel.add(createDetailsPanel(document));
			moduleData.tab.add(createModuleActionsPanel(document));
			moduleData.tab.add(modulePanel);
			if ('function' == typeof initCallBack) {
				initCallBack();
			} else {
				moduleData.tab.setLoading(false);
			}
		};

		var failure = edi.document.actions.defaultFailureHandler(moduleData.tab, 'error.getting.data', function () {
			edi.modulesHandler.removeModule(moduleData);
		});
		edi.rest.sendRequest(
			edi.document.actions.formatDetailsUri(moduleData.initData),
			'GET',
			{},
			function (data) {
				if (data && data.data) {
					document = data.data;
					continueWithRendering();
				} else {
					failure(data);
				}
			},
			failure
		);
	};
	/**
	 * Routine that must be done before module destroy
	 * @r    turn    {Boo        an}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.documents.un('change', changeHandler);
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};
