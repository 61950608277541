import { createModulePanel } from '@Components/panels';
import { initTabWithGrid } from '@Core/specialComponents/miscComponents';
import {
	createActionsColumnConfig,
	createGrid,
	createGridActionBar,
	createGridCheckboxSelectionModel
} from '@Components/grid';
import { methods, getValueByTypeError } from './methods';
import { createOrgDetails } from './tabs/org_details';
import { createTreeGrid } from '@Components/tree.grid';
import { createPagingBar } from '@Components/grid';
import { showErrorToast } from '@Ediweb/core';
import { createTariffsPage } from './tabs/TARIFFS/tariffs';
import { createTabPanel } from '@UIkit/components/tab';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { tourEvents, tours } from '@Ediweb/components/tour';

const ORG_PROFILE_MODULE_NAME = 'org.profile';
const ORG_PROFILE_TOUR_FLAG_NAME = 'user.orgProfileHints';
const ORG_PROFILE_TOUR_TARGET_1 = 'org-profile-tour-target-1';
const ORG_PROFILE_TOUR_TARGET_2 = 'org-profile-tour-target-2';
const ORG_PROFILE_TOUR_TARGET_3 = 'org-profile-tour-target-3';
const ORG_PROFILE_TOUR_TARGET_4 = 'org-profile-tour-target-4';

Ext.namespace('edi.modules');
edi.modules[ORG_PROFILE_MODULE_NAME] = function () {
	var moduleData,
		tabPanel = null,
		gridNameTab,
		grids = {},
		options = {};
	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {
		edi.events.subdivisions.on('change', gridDataChangedHandler);
		edi.events.employees.on('change', gridDataChangedHandler);
		edi.events.routes.on('change', gridDataChangedHandler);
		edi.events.org_profile.on('activatetab', activateTab);
	};

	var getName = function (gridName, item) {
		var namesStr = '';
		if (gridName === 'subdivisions' || gridName === 'routes') {
			namesStr += item.get('name');
		}
		if (gridName === 'employees') {
			let user = edi.renderers.getUserInfo(item);
			namesStr += '<b>' + `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}` + '</b>';
		}
		return namesStr;
	};

	var deleteHandler = function (id, gridName, deleteContentText, callback) {
		var removeLoading = function () {
			moduleData.tab.setLoading(false);
		};
		edi.core.confirm(
			'delete.' + gridName + '.title.one',
			deleteContentText,
			function () {
				moduleData.tab.setLoading();
				methods[gridName].delete([id], moduleData, callback);
			},
			removeLoading,
			undefined,
			removeLoading
		);
	};

	var createActionColumn = function (gridName, rules) {
		let actionColumns = [
			{
				tooltip: edi.i18n.getMessage('ediweb.columns.action.edit'),
				glyph: edi.constants.ICONS.EDIT,
				handler: function (grid, rowIndex) {
					var record = grid.getStore().getAt(rowIndex);
					if (gridName === 'routes') {
						options.routeType = record.get('routeType');
					}
					if (gridName === 'employees') {
						const success = function (resp) {
							moduleData.tab.setLoading(false);
							options.dropcatRoles = resp.items;
							//options.dropcatRoles = ['ONBOARDING_CR', 'ONBOARDING_TC'];
							methods[gridName].createOrUpdate(record, options);
						};
						const fail = edi.rest.getErrorHandler();
						edi.rest.sendRequest(
							edi.rest.services.ORG_PROFILE.EMPLOYEES.DROPCAT_ROLES.GET,
							'GET',
							null,
							success,
							fail
						);
					} else {
						methods[gridName].createOrUpdate(record, options);
					}
				},
				isHidden: function () {
					return !edi.permissions.hasPermission(rules?.edit);
				}
			},
			{
				tooltip: edi.i18n.getMessage('ediweb.columns.action.delete'),
				glyph: edi.constants.ICONS.DELETE,
				handler: function (grid, rowIndex) {
					moduleData.tab.setLoading();
					const record = grid.getStore().getAt(rowIndex);
					const id = record.get('id');
					var nameStr = getName(gridName, record);
					let deleteContentText = edi.i18n.getMessage('delete.' + gridName + '.one', [nameStr]);

					if (gridName === 'employees') {
						var checkEmployees = function () {
							edi.rest.sendRequest(
								edi.utils.formatString(edi.rest.services.ORG_PROFILE.EMPLOYEES.CHECK.GET, {
									id: id
								}),
								'GET',
								null,
								function (resp) {
									if (!resp.data) {
										deleteContentText = edi.i18n.getMessage('delete.' + gridName + '.in.route', [
											nameStr
										]);
									}

									moduleData.tab.setLoading(false);
								},
								function (data) {
									var additionalData = getValueByTypeError(data);
									var errorTitle = edi.i18n.getMessage('controller.employees.error.title');

									showErrorToast(
										errorTitle,
										edi.i18n.getMessage(data.typeError, [additionalData]),
										null,
										function () {
											moduleData.tab.setLoading(false);
											edi.events.routes.fireEvent('change');
										}
									);
								}
							);
						};
						deleteHandler(id, gridName, deleteContentText, checkEmployees);
					} else {
						deleteHandler(id, gridName, deleteContentText);
					}
				},
				isHidden: function (view, rowIndex, colIndex, item, record) {
					return !edi.permissions.hasPermission(rules?.delete);
				},
				isActionDisabled: function (view, rowIdx, colIdx, item, record) {
					var store = view.getStore();
					if (gridName === 'subdivisions') {
						var id = record.get('id');
						var isHeaddivision =
							store.data.items.filter(function (r) {
								var headdivisionId = r.get('headdivision') ? r.get('headdivision').id : null;
								return headdivisionId === id;
							}).length > 0;

						return isHeaddivision;
					}
					if (gridName === 'employees') {
						return (
							!edi.constants.ORG_PROFILE.EMPLOYEES.DELETE_STATE.some(
								(it) => it === record.get('status')
							) || record.get('userAdmin')
						);
					} else {
						return false;
					}
				}
			}
		];
		return createActionsColumnConfig({
			items: actionColumns
		});
	};

	var createGridCmp = function (gridName, columns) {
		columns = columns ? columns : [];
		var tabArray = [],
			model,
			url,
			rules = {},
			createBtn = null;
		switch (gridName) {
			case 'subdivisions':
				model = edi.models.getModel('EW_SUBDIVISIONS');
				url = edi.rest.services.ORG_PROFILE.SUBDIVISIONS.GET;
				rules = {
					add: 'CLIENT_SUBDIVISIONS_CREATE',
					edit: 'CLIENT_SUBDIVISIONS_EDIT',
					delete: 'CLIENT_SUBDIVISIONS_DELETE'
				};
				break;
			case 'employees':
				model = edi.models.getModel('EW_EMPLOYEES');
				url = edi.rest.services.ORG_PROFILE.EMPLOYEES.GET;
				rules = {
					add: 'CLIENT_EMPLOYEES_CREATE',
					edit: 'CLIENT_EMPLOYEES_EDIT',
					delete: 'CLIENT_EMPLOYEES_DELETE'
				};
				break;
			case 'routes':
				model = edi.models.getModel('EW_ROUTES');
				url = edi.rest.services.ORG_PROFILE.ROUTES.GET;
				rules = {
					add: 'CLIENT_ROUTES_CREATE',
					edit: 'CLIENT_ROUTES_EDIT',
					delete: 'CLIENT_ROUTES_DELETE'
				};
				break;
		}

		var actions = createActionColumn(gridName, rules);

		var actionItems = [],
			topBar,
			selectionProcessor,
			groupActionsItemsSelected = [],
			groupActionsItemsExcepted = [];

		if (actions !== null) {
			columns.push(actions);
		}

		if (edi.permissions.hasPermission(rules?.delete)) {
			actionItems.push({
				id: edi.constants.DOCUMENT_ACTIONS.DELETE,
				name: edi.i18n.getMessage('form.btn.delete'),
				isAvailableFunc:
					gridName === 'employees'
						? function (record) {
								return (
									edi.constants.ORG_PROFILE.EMPLOYEES.DELETE_STATE.some(
										(it) => it === record.get('status')
									) && !record.get('userAdmin')
								);
						  }
						: null
			});
		}

		if (edi.permissions.hasPermission(rules?.add)) {
			var menu = new Ext.menu.Menu({
				plain: true,
				items: [
					{
						text: edi.i18n.getMessage('org.profile.routes.universal'),
						handler: function () {
							options.routeType = edi.constants.ORG_PROFILE.ROUTES.ROUTE_TYPE.GLOBAL;
							methods[gridName].createOrUpdate(null, options);
						}
					},
					{
						text: edi.i18n.getMessage('org.profile.routes.private'),
						handler: function () {
							options.routeType = edi.constants.ORG_PROFILE.ROUTES.ROUTE_TYPE.PRIVATE;
							methods[gridName].createOrUpdate(null, options);
						}
					}
				]
			});
			createBtn = createButton({
				text: edi.i18n.getMessage('form.btn.add'),
				cls: [BUTTON_CLS.primary],
				glyph: edi.constants.ICONS.PLUS,
				handler: function () {
					if (gridName === 'employees') {
						const success = function (resp) {
							moduleData.tab.setLoading(false);
							options.dropcatRoles = resp.items;
							//options.dropcatRoles = ['ONBOARDING_CR', 'ONBOARDING_TC'];
							methods[gridName].createOrUpdate(null, options);
						};
						const fail = function (data) {
							edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
						};
						edi.rest.sendRequest(
							edi.rest.services.ORG_PROFILE.EMPLOYEES.DROPCAT_ROLES.GET,
							'GET',
							null,
							success,
							fail
						);
					} else {
						methods[gridName].createOrUpdate(null, options);
					}
				}
			});

			if (gridName === 'routes') {
				createBtn = createButton({
					text: edi.i18n.getMessage('form.btn.add'),
					cls: [BUTTON_CLS.primary],
					glyph: edi.constants.ICONS.PLUS,
					menu: menu
				});
			}
		}

		if (actionItems.length > 0) {
			var groupActionsItemClick = function (id) {
				var ids,
					i,
					namesStr = '';
				if (edi.constants.DOCUMENT_ACTIONS.DELETE === id) {
					ids = [];
					for (i = 0; i < groupActionsItemsSelected.length; i++) {
						const item = groupActionsItemsSelected[i];
						ids.push(item.get('id'));
						if (i < groupActionsItemsSelected.length - 1) {
							namesStr += ', ';
						}
					}

					const exceptionCreateList = function () {
						let exceptionNamesStr = '';
						groupActionsItemsExcepted.forEach(function (item, index) {
							const first = item.get('firstName');
							const patron = item.get('middleName');
							exceptionNamesStr +=
								'<b>' +
								item.get('lastName') +
								(first ? ' ' + first : '') +
								(patron ? ' ' + patron : '') +
								'</b>';
							if (index < groupActionsItemsExcepted.length - 1) {
								exceptionNamesStr += ', ';
							}
						});
						return exceptionNamesStr;
					};
					if (gridName === 'employees') {
						const createEmployeesList = function () {
							let namesStr = '';
							for (let i = 0; i < groupActionsItemsSelected.length; i++) {
								const item = groupActionsItemsSelected[i];
								namesStr += getName(gridName, item);
								if (i < groupActionsItemsSelected.length - 1) {
									namesStr += ', ';
								}
							}
							return namesStr;
						};

						methods[gridName].delete(ids, moduleData, null, createEmployeesList, exceptionCreateList);
					} else {
						const createRoutesList = function () {
							let namesStr = '';
							for (let i = 0; i < groupActionsItemsSelected.length; i++) {
								var item = groupActionsItemsSelected[i];
								namesStr += item.get('name');
								if (i < groupActionsItemsSelected.length - 1) {
									namesStr += ', ';
								}
							}
							return namesStr;
						};

						methods[gridName].delete(ids, moduleData, null, createRoutesList, exceptionCreateList);
					}
				}
			};
			topBar = createGridActionBar({
				actionCfgs: actionItems,
				defaultHandler: groupActionsItemClick
			});

			selectionProcessor = function (model, selected, actionItems, isProcessible) {
				var selectedDocsCount;
				groupActionsItemsSelected = [];
				groupActionsItemsExcepted = [];
				if (selected.length) {
					for (var i = 0; i < actionItems.length; i++) {
						if (isProcessible(actionItems[i].rulesData)) {
							selectedDocsCount = 0;
							for (var j = 0; j < selected.length; j++) {
								const isAvailableFunc = actionItems[i]?.rulesData?.isAvailableFunc ?? null;
								let isAvailable = true;
								if (isAvailableFunc) {
									const buttonData = actionItems[i]?.rulesData?.buttonData ?? {};
									isAvailable = isAvailableFunc(selected[j], buttonData);
								}
								if (isAvailable) {
									selectedDocsCount++;
									groupActionsItemsSelected.push(selected[j]);
								} else {
									groupActionsItemsExcepted.push(selected[j]);
								}
							}
							actionItems[i].setTooltip(actionItems[i].rulesData.name + ' (' + selectedDocsCount + ')');
							actionItems[i].setDisabled(!selectedDocsCount);
						}
					}
				} else {
					for (i = 0; i < actionItems.length; i++) {
						if (isProcessible(actionItems[i].rulesData)) {
							actionItems[i].setTooltip(actionItems[i].rulesData.name + ' (0)');
							actionItems[i].setDisabled(true);
						}
					}
				}
			};
		}
		tabArray = [createBtn];
		if (gridName !== 'subdivisions') {
			tabArray.push(topBar);
		}

		if (gridName === 'subdivisions') {
			var treeStore = new Ext.data.TreeStore({
				folderSort: true,
				model: model,
				proxy: {
					type: 'ajax',
					url: url,
					reader: {
						type: 'json',
						rootProperty: function (data) {
							// Extract child nodes from the items or children property in the dataset
							return data.items || data.children;
						}
					}
				}
			});
			return createTreeGrid({
				cls: 'edi-ediweb-subdivisions-tree-grid edi-ediweb-grid edi-product-grid-container with-row-lines',
				gridModelConfig: 'EW_SUBDIVISIONS',
				gridColumnsConfig: 'ew_subdivisions',
				store: treeStore,
				tbar: tabArray,
				bbar: createPagingBar({
					store: treeStore,
					hidePageSizeCombo: true,
					allowExport: false,
					navigateBackCfg: {
						hidden: true
					},
					navigateForwardCfg: {
						hidden: true
					}
				}),
				rowActions: [
					{
						tooltip: edi.i18n.getMessage('ediweb.columns.action.edit'),
						glyph: edi.constants.ICONS.EDIT,
						handler: function (grid, rowIndex) {
							var record = grid.getStore().getAt(rowIndex);
							methods[gridName].createOrUpdate(record, options);
						},
						isHidden: function () {
							return !edi.permissions.hasPermission(rules?.edit);
						}
					},
					{
						tooltip: edi.i18n.getMessage('ediweb.columns.action.delete'),
						glyph: edi.constants.ICONS.DELETE,
						handler: function (grid, rowIndex) {
							moduleData.tab.setLoading();
							const record = grid.getStore().getAt(rowIndex);
							const id = record.get('id');
							const nameStr = getName(gridName, record);
							let deleteContentText = edi.i18n.getMessage('delete.' + gridName + '.one', [nameStr]);

							const checkSubdivision = function () {
								const fail = function (data) {
									const additionalData = getValueByTypeError(data);
									var errorTitle = edi.i18n.getMessage('controller.subdivisions.error.title');

									showErrorToast(
										errorTitle,
										edi.i18n.getMessage(data.typeError, [additionalData]),
										null,
										function () {
											moduleData.tab.setLoading(false);
											edi.events.routes.fireEvent('change');
										}
									);
								};
								const success = function () {
									moduleData.tab.setLoading(false);
								};
								const url = edi.utils.formatString(
									edi.rest.services.ORG_PROFILE.SUBDIVISIONS.CHECK.GET,
									{
										subdivisionId: id
									}
								);
								edi.rest.sendRequest(url, 'GET', null, success, fail);
							};

							deleteHandler(id, gridName, deleteContentText, checkSubdivision);
						},
						isHidden: function (view, rowIndex, colIndex, item, record) {
							return !edi.permissions.hasPermission(rules?.delete);
						},
						isActionDisabled: function (view, rowIdx, colIdx, item, record) {
							return record.get('children').length > 0;
						}
					}
				],
				viewConfig: {
					emptyTextTplOptions: {
						enabled: true,
						iconName: 'subdivisions',
						title: edi.i18n.getMessage('ediweb.subdivisions.empty.text.tpl.title'),
						contentText: edi.i18n.getMessage('ediweb.subdivisions.empty.text.tpl.contentText')
					}
				}
			});
		}

		return createGrid({
			proxyConfig: {
				type: 'ajax',
				url: url
			},
			storeConfig: {
				model: model,
				sortOnLoad: true,
				sorters: {
					property: 'modifyDate',
					direction: 'DESC'
				},
				autoLoad: true
			},
			gridConfig: {
				columns: columns,
				padding: 0,
				region: 'center',
				selModel:
					topBar && topBar.menuActionItemsCnt && gridName !== 'subdivisions'
						? createGridCheckboxSelectionModel({
								topBar: topBar,
								selectionProcessor: selectionProcessor
						  })
						: undefined,
				tbar: tabArray,
				listeners: {
					celldblclick: function (view, td, cellIndex, record) {}
				}
			},
			viewConfig: {
				emptyTextTplOptions: {
					enabled: true,
					iconName: gridName,
					title: edi.i18n.getMessage('ediweb.empty.text.tpl.title.' + gridName),
					contentText: edi.i18n.getMessage('ediweb.empty.text.tpl.contentText.' + gridName)
				}
			},
			pagingBarConfig: {
				allowExport: false
			}
		});
	};
	/**
	 * Reloads grid and resets filter form
	 */
	var gridDataChangedHandler = function () {
		for (var i in grids) {
			//в grids лежит еще и таб Реквизиты, а он совсем не грид, поэтому смотрим есть ли у него стор
			if (grids.hasOwnProperty(i) && grids[i].getStore) {
				var store = grids[i].getStore();
				var gridSelModel = grids[i].getSelectionModel();
				if (gridSelModel) {
					gridSelModel.deselectAll();
				}
				store.reload();
			}
		}
	};

	var renderData = function (initCallBack) {
		var modulePanel = createModulePanel({
			layout: 'border',
			region: 'center'
		});
		tabPanel = createTabPanel({
			margin: '16 0 0 0'
		});

		let tabs = {};
		grids = {};
		options = {
			stores: {}
		};

		grids.org_details = createOrgDetails();

		if (edi.permissions.hasPermission('CLIENT_TARIFFS')) {
			grids.tariffs = createTariffsPage();
			grids.tariffs.hintCls = ORG_PROFILE_TOUR_TARGET_1;
		}

		if (edi.permissions.hasPermission('CLIENT_SUBDIVISIONS_READ')) {
			grids.subdivisions = createGridCmp('subdivisions', edi.columns.get('ew_subdivisions'));
			grids.subdivisions.hintCls = ORG_PROFILE_TOUR_TARGET_2;
			options.stores['subdivisions'] = grids.subdivisions.getStore();
		}
		if (edi.permissions.hasPermission('CLIENT_EMPLOYEES_READ')) {
			grids.employees = createGridCmp('employees', edi.columns.get('ew_employees'));
			grids.employees.hintCls = ORG_PROFILE_TOUR_TARGET_3;
		}
		if (edi.permissions.hasPermission('CLIENT_ROUTES_READ')) {
			grids.routes = createGridCmp('routes', edi.columns.get('ew_routes'));
			grids.routes.hintCls = ORG_PROFILE_TOUR_TARGET_4;
		}

		tabs = Object.assign(tabs, grids);

		var isFirst = true;
		var activeTab = edi.utils.getObjectProperty(moduleData, 'initData.data.activeTab');
		if (activeTab && tabs.hasOwnProperty(activeTab)) {
			isFirst = false;
		}

		for (var i in tabs) {
			if (grids.hasOwnProperty(i)) {
				initTabWithGrid(
					i,
					grids[i],
					isFirst || activeTab === i,
					tabPanel,
					{
						title: tabs[i]?.tabTitle ? tabs[i].tabTitle : `ediweb.org.profile.tab.${i}`,
						callback: (tabName) => (gridNameTab = tabName),
						cls: grids[i].hintCls
					},
					{
						hideIcon: true
					}
				);

				isFirst = false;
			}
		}

		modulePanel.add(tabPanel);
		moduleData.tab.add(modulePanel);

		if ('function' == typeof initCallBack) {
			initCallBack();
			if (edi.core.getExtraData(ORG_PROFILE_TOUR_FLAG_NAME) !== 'true') {
				setTimeout(() => {
					if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
						tourEvents.fireEvent('start_tour', ORG_PROFILE_MODULE_NAME, moduleData);
					}
				}, 500);
			}
		}
	};

	var isActiveTab = function (tab) {
		var tabId = tab && tab.getId ? tab.getId() : null;
		var activeTab = tabPanel && tabPanel.getActiveTab ? tabPanel.getActiveTab() : null;
		var tabPanelId = activeTab && activeTab.getId ? activeTab.getId() : null;
		return tabId && tabPanelId && tabId === tabPanelId;
	};

	/**
	 * Activates module's tab by it's name
	 * @param	{String}	newTabName
	 */
	var activateTab = function (newTabName) {
		let grid = grids[newTabName] || grids['settings'];
		let tab = grid?.tab;
		if (tab && !tab.isDestroyed && tabPanel && !tabPanel.isDestroyed) {
			if (isActiveTab(tab)) {
				gridDataChangedHandler(grid);
				return;
			}
			tabPanel.setActiveTab(tab);
		}
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	var onDestroy = function () {
		edi.events.subdivisions.un('change', gridDataChangedHandler);
		edi.events.employees.un('change', gridDataChangedHandler);
		edi.events.routes.un('change', gridDataChangedHandler);
		edi.events.org_profile.un('activatetab', activateTab);

		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};

tours[ORG_PROFILE_MODULE_NAME] = {
	getName: () => ORG_PROFILE_MODULE_NAME,
	createSteps: function () {
		const steps = [];

		const step1 = document.querySelector(`.${ORG_PROFILE_TOUR_TARGET_1}`);
		step1 &&
			steps.push({
				getTitle: () => edi.i18n.getMessage('org.profile.tour.step.1.title'),
				getContent: () => edi.i18n.getMessage('org.profile.tour.step.1.content'),
				getTargetEl: () => step1,
				position: 'right'
			});

		const step2 = document.querySelector(`.${ORG_PROFILE_TOUR_TARGET_2}`);
		step2 &&
			steps.push({
				getTitle: () => edi.i18n.getMessage('org.profile.tour.step.2.title'),
				getContent: () => edi.i18n.getMessage('org.profile.tour.step.2.content'),
				getTargetEl: () => step2,
				position: 'right'
			});

		const step3 = document.querySelector(`.${ORG_PROFILE_TOUR_TARGET_3}`);
		step3 &&
			steps.push({
				getTitle: () => edi.i18n.getMessage('org.profile.tour.step.3.title'),
				getContent: () => edi.i18n.getMessage('org.profile.tour.step.3.content'),
				getTargetEl: () => step3,
				position: steps.length >= 2 ? 'left' : 'right'
			});

		const step4 = document.querySelector(`.${ORG_PROFILE_TOUR_TARGET_4}`);
		step4 &&
			steps.push({
				getTitle: () => edi.i18n.getMessage('org.profile.tour.step.4.title'),
				getContent: () => edi.i18n.getMessage('org.profile.tour.step.4.content'),
				getTargetEl: () => step4,
				position: steps.length >= 2 ? 'left' : 'right'
			});

		return steps;
	},
	onTourClosed: function () {
		const tour = this;
		//поставим флаг, что юзер прошел все страницы тура
		if (tour.currentStepNum === tour.steps.length - 1) {
			edi.core.setExtraData(ORG_PROFILE_TOUR_FLAG_NAME, 'true');
		}
	}
};
