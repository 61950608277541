import { PACKING_ID_FIELD_NAME, get, getProductIdent } from './createTree';
import { createTextField, getInputMethodByType } from '@Components/fields';
import { createForm, createTab, createTabPanel } from '@Components/panels';
import { createSaveButton } from '@Components/buttons';
import { isPacking, Packing, Product, Tree, TreeRecord } from './definitions';
// @ts-ignore
import { createModalPanel, MODAL_SIZE } from '@UIkit/components/modal';
// @ts-ignore
import { createFieldBlock, FIELD_BLOCK_CLS } from '@UIkit/components/panels';
// @ts-ignore
import { BUTTON_CLS } from '@UIkit/components/buttons';

/**
 * Saves modal values to packing record in tree
 */
const saveValues = function (
	tree: Tree,
	modal: ExtComponent,
	record?: TreeRecord<Packing>,
	options?: { onCreate?: (newPackNode: TreeRecord<Packing>) => void }
) {
	let isCreateNew = !record;
	let values = edi.utils.collectFormValues(modal.formPanel);
	let currentPackId = get(record, PACKING_ID_FIELD_NAME);
	let newPackId = values[PACKING_ID_FIELD_NAME];

	if (isCreateNew) {
		if (tree.maps.secondLvlPacks[newPackId] || tree.maps.thirdLvlPacks[newPackId]) {
			edi.core.showWarn('desadv.packing.with.same.id.already.exists');
		} else {
			let newPackNode: TreeRecord<Packing> = tree.createAndAppend(values);
			tree.maps.secondLvlPacks[newPackId] = newPackNode.data.data;
			closeModal(modal);
			tree.callback();
			tree.getStore().sort();

			if (typeof options?.onCreate === 'function') {
				options.onCreate(newPackNode);
			}
		}
	} else {
		//with changing SSCC we need to update links to this pack in tree's maps
		if (currentPackId !== newPackId) {
			if (tree.maps.thirdLvlPacks[currentPackId]) {
				//change SSCC on 3d lvl pack
				//update children's links
				Object.values(tree.maps.secondLvlPacks)
					.filter((pack) => pack.thirdLvlPackingId === currentPackId)
					.forEach((pack) => (pack.thirdLvlPackingId = newPackId));
				//replace pack in maps
				tree.maps.thirdLvlPacks[newPackId] = tree.maps.thirdLvlPacks[currentPackId];
				delete tree.maps.thirdLvlPacks[currentPackId];
			} else if (tree.maps.secondLvlPacks[currentPackId]) {
				//change SSCC on 2nd lvl pack
				//collect secondLvlPackIds for replace
				let newSecondLvlPackIdsMap: {
					[key: Product['productID']]: Product['secondLvlPackingIds'];
				} = {};
				Object.values(tree.maps.productsByPack)
					.filter((prod) => prod.packingId === currentPackId)
					.forEach((prod) => {
						let newSecondLvlPackIds = Ext.clone(prod.secondLvlPackingIds) || [];
						let packIndexForReplace = newSecondLvlPackIds.indexOf(currentPackId);
						newSecondLvlPackIds.splice(packIndexForReplace, 1, newPackId);
						newSecondLvlPackIdsMap[prod.productID] = newSecondLvlPackIds;
						prod.packingId = newPackId;
					});
				//update products with new secondLvlPackIds
				Object.values(tree.maps.productsById).forEach((prod) => {
					if (newSecondLvlPackIdsMap[prod.productID]) {
						prod.secondLvlPackingIds = newSecondLvlPackIdsMap[prod.productID];
					}
				});
				//update products with new secondLvlPackIds
				//replace ident of product in map with new packingId
				Object.values(tree.maps.productsByPack).forEach((prod) => {
					let prodId = prod.productID;
					if (newSecondLvlPackIdsMap[prodId]) {
						prod.secondLvlPackingIds = newSecondLvlPackIdsMap[prodId];
						if (!!prod.packingId) {
							let oldIdent = getProductIdent({
								productID: prodId,
								packingId: currentPackId
							});
							let newIdent = getProductIdent(prod);
							tree.maps.productsByPack[newIdent] = tree.maps.productsByPack[oldIdent];
							delete tree.maps.productsByPack[oldIdent];
						}
					}
				});
				//replace pack in maps
				tree.maps.secondLvlPacks[newPackId] = tree.maps.secondLvlPacks[currentPackId];
				delete tree.maps.secondLvlPacks[currentPackId];
			}
		}

		let packToUpdate = tree.maps.secondLvlPacks[newPackId] || tree.maps.thirdLvlPacks[newPackId];
		Ext.merge(packToUpdate, values);
		record?.set('data', values);
		closeModal(modal);
		tree.rebuildTree();
		tree.callback();
		tree.getStore().sort();
	}
};

/**
 * Show and hide fields by packing type
 */
const showHideFieldsByPackType = function (modal: ExtComponent, packingType?: Packing['Type'], readOnly = false) {
	let field = readOnly ? 'label' : 'field';
	let fieldsToHide: ExtComponent[] =
		packingType === edi.constants.PACKAGE_TYPES.PALLET
			? modal.query(`${field}[isOtherPackingTypeField="true"]`)
			: modal.query(`${field}[isPalletPackingTypeField="true"]`);

	let fieldsToShow: ExtComponent[] =
		packingType === edi.constants.PACKAGE_TYPES.PALLET
			? modal.query(`${field}[isPalletPackingTypeField="true"]`)
			: modal.query(`${field}[isOtherPackingTypeField="true"]`);

	fieldsToHide.forEach((f) => f.getFieldBlock().hide());
	fieldsToShow.forEach((f) => f.getFieldBlock().show());
};

/**
 * Creates field via config
 */
const createFieldFn = function (config: AnyObject, packingData: Packing | {}, isReadOnly = false): ExtComponent {
	if (typeof config.type === 'function') {
		return config.type({}, packingData);
	}

	let inputMethod = config.type ? getInputMethodByType(config.type) : createTextField;
	delete config.type;

	let input = inputMethod(
		Object.assign(
			{
				valueSrc: packingData,
				readOnly: isReadOnly
			},
			config
		)
	);

	return createFieldBlock({
		cls: FIELD_BLOCK_CLS.small,
		title: config.title || edi.i18n.getMessage('desadv.packing.' + config.name),
		items: [input]
	});
};

/**
 * Creates tabpanel with fields
 */
const createTabPanelFn = function (
	tree: Tree,
	packingData: Packing | {},
	options?: { isDetails?: boolean; isEdit?: boolean; isCreate?: boolean }
): ExtComponent {
	let opts = Object.assign(
		{
			isDetails: true,
			isEdit: false,
			isCreate: false
		},
		options
	);

	let tabConfigs: AnyObject[] | ExtComponent[] = [
		{
			title: edi.i18n.getMessage('line.item.tab.data.packing'),
			customFieldTab: 'DATA_PACKAGING',
			items: [
				{
					name: 'Type',
					type: 'combo',
					readOnly: opts.isEdit || opts.isDetails,
					store: edi.stores.createSimpleInlineStore(
						Object.values(edi.constants.PACKAGE_TYPES),
						(id: string) => edi.i18n.getMessage('desadv.packing.type.' + id)
					),
					text: opts.isDetails
						? edi.i18n.getMessage('desadv.packing.type.' + (isPacking(packingData) ? packingData.Type : ''))
						: null,
					allowBlank: false,
					listeners: {
						select: function (combo: ExtComponent, record?: ExtRecord<any>) {
							let modal = tree.openedModal;
							let packingIdInput = modal.down(`[name="${PACKING_ID_FIELD_NAME}"]`);

							let packingType: Packing['Type'] | undefined = record?.get('id');

							showHideFieldsByPackType(modal, packingType, opts.isDetails);

							packingIdInput.setEditable(packingType !== edi.constants.PACKAGE_TYPES.PALLET);
						}
					}
				},
				{
					name: PACKING_ID_FIELD_NAME,
					type: 'trigger',
					allowBlank: false,
					triggers: {
						generate: {
							extraCls: 'edi-icon edi-icon-LOOP',
							tooltip: tree.partnerId
								? edi.i18n.getMessage('desadv.packing.generate.number')
								: edi.i18n.getMessage('desadv.packing.generate.number.no.partner'),
							handler() {
								if (tree.partnerId) {
									let modal = tree.openedModal;
									let packingIdInput: ExtComponent = modal.down(`[name="${PACKING_ID_FIELD_NAME}"]`);
									let extensionSSCC = edi.utils.getObjectProperty(
										edi.utils.getOrg({
											orgId: tree.partnerId
										}),
										'attributes.extensionSSCC.value'
									);
									if (extensionSSCC === '') {
										extensionSSCC = edi.constants.SKU.PALLET;
									}
									edi.methods.sscc.get(extensionSSCC, function (value: string) {
										packingIdInput.setValue(value);
									});
								}
							}
						}
					}
				},
				{
					name: 'VSDNumber'
				},
				{
					name: 'BatchNumber'
				},
				{
					name: 'PackProductionDate',
					type: 'date',
					text: opts.isDetails
						? edi.renderers.fnsDateFromClient(
								isPacking(packingData) ? packingData['PackProductionDate'] : ''
						  )
						: null
				},
				{
					name: 'PackExpirationDate',
					type: 'date',
					text: opts.isDetails
						? edi.renderers.fnsDateFromClient(
								isPacking(packingData) ? packingData['PackExpirationDate'] : ''
						  )
						: null
				},
				{
					name: 'PackagingUnit',
					isPalletPackingTypeField: true,
					type: 'combo',
					store: 'initPackagingUnit',
					hidden: tree?.maps?.thirdLvlPacks[
						isPacking(packingData) ? packingData['ID-Begin'] : ''
					]?.hasOwnProperty('ID-Begin'),
					labelConf: {
						hidden: tree?.maps?.thirdLvlPacks[
							isPacking(packingData) ? packingData['ID-Begin'] : ''
						]?.hasOwnProperty('ID-Begin')
					},
					textConverter: function (value: string) {
						let obj = edi.stores.data.packagingUnit.find(function (item: { id: string; value: AnyObject }) {
							return item.id === value;
						});
						return obj ? obj.name : '';
					}
				},
				{
					name: 'PackagingUnitUniversalCode',
					isOtherPackingTypeField: true
				},
				{
					name: 'PackagingUnitName',
					isOtherPackingTypeField: true
				},
				{
					name: 'PackagingUnitBuyerCode',
					isOtherPackingTypeField: true
				},
				{
					name: 'PackagingUnitSupplierCode',
					isOtherPackingTypeField: true
				}
			]
		},
		{
			title: edi.i18n.getMessage('line.item.tab.dimensions.packing'),
			customFieldTab: 'DIMENSIONS_PACKING',
			items: [
				{
					name: 'SizeUnitOfMeasure',
					type: 'combo',
					store: edi.stores.createMemoryStore(
						edi.stores.data.okei_codes.filter(function (item: { id: string; value: AnyObject }) {
							return ['003', '004', '005', '006'].indexOf(item.id) !== -1;
						}),
						'OKEI_CODES'
					),
					textConverter: function (value: string) {
						let obj = edi.stores.data.okei_codes.find(
							(item: { id: string; value: AnyObject }) => item.id === value
						);
						return obj ? obj.name : '';
					},
					value: '006'
				},
				{
					name: 'SizeLength'
				},
				{
					name: 'SizeWidth'
				},
				{
					name: 'SizeHeight'
				}
			]
		},
		{
			title: edi.i18n.getMessage('line.item.tab.weight.characteristics'),
			customFieldTab: 'WEIGHT_CHARACTERISTICS',
			items: [
				{
					name: 'WeightUnitOfMeasure',
					type: 'combo',
					store: edi.stores.createMemoryStore(
						edi.stores.data.okei_codes.filter(function (item: { id: string; value: AnyObject }) {
							return ['161', '163', '166', '132', '206'].indexOf(item.id) !== -1;
						}),
						'OKEI_CODES'
					),
					textConverter: function (value: string) {
						let obj = edi.stores.data.okei_codes.find(
							(item: { id: string; value: AnyObject }) => item.id === value
						);
						return obj ? obj.name : '';
					},
					value: '166'
				},
				{
					name: 'WeightNetWeight'
				},
				{
					name: 'WeightGrossWeight'
				},
				{
					name: 'WeightUnitWeight'
				},
				{
					name: 'WeightBoxWeight'
				},
				{
					name: 'WeightPalletWeight'
				}
			]
		}
	];
	if (tree.customPackingFields && Object.keys(tree.customPackingFields).length) {
		Object.entries(tree.customPackingFields).forEach(function ([tabName, fields]) {
			let tab = tabConfigs.find(function (item: AnyObject) {
				return item.customFieldTab === tabName;
			});
			if (tab) {
				tab.items = tab.items.concat(fields);
			}
		});
	}

	let tabs: ExtComponent[] = [];
	let defaultTabConfig: AnyObject = {
		title: 'Untitled',
		closable: false,
		padding: '16 24',
		layout: {
			type: 'grid',
			gap: [24, 16]
		},
		scrollable: 'y',
		items: []
	};

	tabConfigs.forEach((configForm) => {
		let tabConfig = Ext.clone(configForm);

		if (Array.isArray(tabConfig.items) && tabConfig.items.length) {
			tabConfig.items = tabConfig.items.map((fieldConfig) =>
				createFieldFn(fieldConfig, packingData, opts.isDetails)
			);
		}

		Ext.applyIf(tabConfig, defaultTabConfig);
		tabs.push(createTab(tabConfig) as ExtComponent);
	});

	return createTabPanel({
		activeTab: 0,
		items: tabs
	}) as ExtComponent;
};

/**
 * Close modal if it exists
 */
const closeModal = function (modal: ExtComponent) {
	if (modal && !modal.isDestroyed) {
		modal.close();
	}
};

/**
 * Return only values which are described in model
 */
const getLineValuesFromNode = function (node: TreeRecord<Packing>, modelName: Tree['gridModel']): Packing {
	let record: ExtRecord<Packing> = edi.models.createInstance(modelName, node.data.data);
	return record.data;
};

/**
 * Shows add/edit packing modal
 */
const showPackingModal = function (
	tree: Tree,
	packingRecord?: TreeRecord<Packing>,
	options?: {
		isDetails?: boolean;
		isEdit?: boolean;
		isCreate?: boolean;
		afterCreateCallback?: (newPackNode: TreeRecord<Packing>) => void;
	}
) {
	let opts = Object.assign(
		{
			isDetails: true,
			isEdit: false,
			isCreate: false,
			afterCreateCallback: () => {}
		},
		options
	);

	let packingData: Packing | {} = !!packingRecord ? getLineValuesFromNode(packingRecord, tree.gridModel) : {};

	let modalTitle = edi.i18n.getMessage('desadv.packing.modal.create');
	if (opts.isEdit || opts.isDetails) {
		modalTitle =
			edi.i18n.getMessage('desadv.packing.modal.' + (opts.isDetails ? 'read' : 'edit')) +
			' ' +
			(isPacking(packingData) ? packingData[PACKING_ID_FIELD_NAME] : '');
	}

	let formPanel: ExtComponent = createForm({
		layout: 'fit',
		items: [createTabPanelFn(tree, packingData, opts)]
	}) as ExtComponent;

	let saveBtn =
		opts.isEdit || opts.isCreate
			? createSaveButton(
					() =>
						saveValues(tree, modal, packingRecord, {
							onCreate: opts.afterCreateCallback
						}),
					{
						cls: BUTTON_CLS.primary,
						bindToForm: formPanel
					}
			  )
			: null;

	let modal: ExtComponent = createModalPanel({
		title: modalTitle,
		width: MODAL_SIZE.widthMedium,
		layout: 'fit',
		items: [formPanel],
		buttonsBefore: [saveBtn]
	});

	modal.formPanel = formPanel;
	modal.formGrids = {};
	tree.openedModal = modal;

	modal.show();
	showHideFieldsByPackType(modal, isPacking(packingData) ? packingData['Type'] : undefined, opts.isDetails);
	formPanel.isValid();
};

export { showPackingModal };
