import './scss/invitations.mass.scss';

import { createLabel } from '@UIkit/components/fields_old/Label';
import {
	createForm,
	createPanel,
	createContainer,
	createFormContainer,
	createFieldBlock
} from '@UIkit/components/panels';
import { createGrid } from '@UIkit/components/grid';
import { BUTTON_CLS, createButton } from '@UIkit/components/buttons';
import { createTextField, createCombo } from '@UIkit/components/fields';
import { createAddModulePanel } from '@Components/panels';
import {
	send,
	getInvitationDataCount,
	success,
	updateInvitationInMassInvitation,
	getMassInvitationColumns,
	getMassInvitation,
	getCountErrorInvitation,
	getTooltip
} from './utils';
import { getErrorReportPanel } from './renderers';
import { tourEvents, tours } from '@Ediweb/components/tour';
import { createFileDropper } from '@Components/FileDropper/FileDropper';
import { getProductsAndRoles } from '@Ediweb/modules/INVITATIONS/methods';

const INVITATION_MASS_MODULE_NAME = 'invitations.create.mass.person';
const INVITATION_MASS_TOUR_TARGET_1 = 'invitations-mass-tour-target-1';
const INVITATION_MASS_TOUR_TARGET_2 = 'invitations-mass-tour-target-2';
const INVITATION_MASS_TOUR_TARGET_3 = 'invitations-mass-tour-target-3';
const INVITATION_MASS_TOUR_TARGET_4 = 'invitations-mass-tour-target-4';

Ext.namespace('edi.modules');
edi.modules[INVITATION_MASS_MODULE_NAME] = function () {
	let moduleData,
		id,
		isEdit = false,
		form,
		receiverPanel,
		massInvitationsList = [],
		massInvitationsGrid,
		fileDropper,
		maxFileSize;

	/**
	 * Main module initialization method
	 * @param    {Object}    data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	this.init = function (data, initCallBack) {
		moduleData = data;
		moduleData.getTourOpts = () => ({
			isEdit,
			hasErrors: !!getCountErrorInvitation(massInvitationsList)
		});

		renderData(initCallBack);
		return onDestroy;
	};

	/**
	 * On module render. Fired after initCallBack. Used for events subscriptions.
	 */
	this.onRender = function () {};

	var changeHandler = function (data) {
		moduleData.isChanged = false;
		edi.events.invitations.fireEvent('change');
		edi.events.massInvitation.fireEvent('change', moduleData?.initData?.data?.id ?? null);
		moduleData.initData.data = data;
		moduleData.tab.removeAll();
		renderData(data);
		moduleData.tab.setTitle(edi.i18n.getMessage('ediweb.invitations.mass.person.edit', null, null, null));
		moduleData.tab.setLoading(false);
	};

	/**
	 * Creates despatch advise form
	 * @returns {Object}
	 */
	let createModuleForm = function (invitationData) {
		const state = isEdit ? edi.utils.getObjectProperty(invitationData, 'ediDocument.state') : null;

		let title = createContainer({
			items: [
				createLabel({
					cls: 'heading_02',
					text: edi.i18n.getMessage('ediweb.invitations.to.exchange', null, null, null)
				})
			]
		});

		let massInvitationName = createTextField({
			fieldLabel: edi.i18n.getMessage('invitation.mass.name', null, null, null),
			name: 'nameOfInvitationList',
			value: isEdit ? edi.utils.getObjectProperty(invitationData, 'massInvitation.nameOfInvitationList') : null,
			allowBlank: false
		});
		let massInvitationNamePanel = createFieldBlock({
			title: edi.i18n.getMessage('invitation.mass.name', null, null, null),
			items: [massInvitationName]
		});

		receiverPanel = createPanel({
			items: []
		});

		let [product, senderRole, receiverRole] = getProductsAndRoles(invitationData, 'massInvitation', isEdit, {
			product: INVITATION_MASS_TOUR_TARGET_2
		});

		let uploadForm = null,
			file;
		if (isEdit) {
			file = invitationData.massInvitation.file;
		}
		let massInvitationReceiversPanel = createFieldBlock({
			cls: INVITATION_MASS_TOUR_TARGET_1,
			title: edi.i18n.getMessage('invitation.mass.receivers', null, null, null),
			layout: {
				type: 'hbox'
			},
			items: [
				(uploadForm = createForm({
					flex: 1,
					submitEmptyText: false,
					bodyPadding: 0,
					items: [
						(fileDropper = createFileDropper({
							cls: 'drag-file ediweb-file-dropper',
							name: 'attachment',
							itemId: 'fileDropper',
							allowBlank: false,
							readOnly: isEdit,
							fileValidator: function (file) {
								if (!file || edi.utils.isEmptyObject(file)) return '';
								const fileNameArray = file.fileName.split('.');
								const extension = fileNameArray.length > 1 ? fileNameArray[1] : '';
								if (extension !== 'xls') return edi.i18n.getMessage('file.dropper.format.error');
								return '';
							},
							getAllowedFileTypesText: () =>
								edi.i18n.getMessage('document.dsf.upload.file.types.and.limit', {
									limit: maxFileSize
								}),
							fileData:
								isEdit && !!file
									? {
											fileName: file?.fileName,
											fileDate: edi.utils.formatDate(
												file?.modifyDate,
												edi.constants.DATE_FORMAT.FNS
											),
											fileTime: edi.utils.formatDate(
												file?.modifyDate,
												edi.constants.DATE_FORMAT.TIME
											),
											fileSize: edi.utils.formatFileSize(file?.fileSize)
									  }
									: null
						}))
					]
				})),
				createButton({
					margin: '0 0 0 16',
					cls: [BUTTON_CLS.secondary, BUTTON_CLS.small],
					text: edi.i18n.getMessage('form.btn.download_template', null, null, null),
					formBind: false,
					glyph: edi.constants.ICONS.FOLDER_ATTACHMENT,
					handler: function () {
						let url = edi.rest.services.MASS_INVITATIONS.DOWNLOAD_ATTACHMENT;
						edi.rest.downloadFile(url);
					}
				})
			]
		});

		massInvitationsList = isEdit ? edi.utils.getObjectProperty(invitationData, 'massInvitation.invitations') : [];

		const actionColumns =
			state === edi.constants.STATE.DRAFT
				? [
						{
							tooltip: edi.i18n.getMessage('ediweb.columns.action.edit', null, null, null),
							glyph: edi.constants.ICONS.EDIT,
							handler: function (grid, rowIndex) {
								const record = grid.getStore().getAt(rowIndex);
								updateInvitationInMassInvitation(
									record,
									id,
									moduleData,
									failure,
									function (dataInvitation) {
										const updateInvitationData = {
											ediDocument: invitationData.ediDocument,
											id: id,
											massInvitation: dataInvitation?.data ?? {}
										};
										changeHandler(updateInvitationData);
									}
								);
							},
							isActionDisabled: function (view, rowIndex, colIndex, item, record) {
								return record.get('state') !== edi.constants.INVITATIONS.STATE.DRAFT;
							}
						},
						{
							tooltip: edi.i18n.getMessage('ediweb.columns.action.delete', null, null, null),
							glyph: edi.constants.ICONS.DELETE,
							handler: function (grid, rowIndex) {
								edi.core.confirm(null, 'ediweb.invitations.mass.confirm.delete', function () {
									moduleData.tab.setLoading(edi.i18n.getMessage('loading.text', null, null, null));
									const record = grid.getStore().getAt(rowIndex);
									const url = edi.utils.formatString(edi.rest.services.INVITATIONS.DELETE, {
										id: record.get('id')
									});
									edi.rest.sendRequest(
										url,
										'DELETE',
										Ext.encode({}),
										function () {
											getMassInvitation(
												id,
												function (dataInvitation) {
													const updateInvitationData = {
														ediDocument: invitationData.ediDocument,
														id: id,
														massInvitation: dataInvitation?.data ?? {}
													};
													changeHandler(updateInvitationData);
												},
												failure
											);
										},
										failure
									);
								});
							},
							isActionDisabled: function (view, rowIndex, colIndex, item, record) {
								return record.get('state') !== edi.constants.INVITATIONS.STATE.DRAFT;
							}
						}
				  ]
				: [];

		const columns = getMassInvitationColumns(actionColumns, isEdit);

		massInvitationsGrid = isEdit
			? createGrid({
					proxyConfig: {
						type: 'memory',
						data: massInvitationsList
					},
					storeConfig: {
						model: edi.models.getModel('MASS_INVITATIONS_LIST'),
						autoLoad: true
					},
					viewConfig: {
						emptyText: '    ',
						getRowClass: function (record) {
							return !!record.get('errors').length ? 'invitations-mass-grid-error-sending' : '';
						}
					},
					gridConfig: {
						cls: 'invitations-mass-grid',
						columns: columns,
						padding: '0 0 0 0',
						region: 'center',
						border: 1,
						disablePaging: true,
						listeners: {
							afterrender: function (element) {
								let gridView = element.getView();
								gridView.tip = Ext.create('Ext.tip.ToolTip', {
									target: gridView.el,
									delegate: gridView.itemSelector,
									trackMouse: true,
									renderTo: Ext.getBody(),
									listeners: {
										beforeshow: function updateTipBody(tip) {
											const triggerElement = tip?.triggerElement ?? null;
											if (!triggerElement) return;
											const record = gridView?.getRecord(triggerElement) ?? null;
											if (!record) return;

											const errors = getTooltip(record);
											tip.update(errors);
										}
									}
								});
							}
						}
					}
			  })
			: null;
		const errorReportPanel = isEdit ? getErrorReportPanel(massInvitationsList, !isEdit, massInvitationsGrid) : null;
		errorReportPanel?.addCls(INVITATION_MASS_TOUR_TARGET_4);

		var createAndSendButton = createButton({
			cls: [BUTTON_CLS.primary],
			text: edi.i18n.getMessage(isEdit ? 'form.btn.save.and.send' : 'form.btn.send'),
			glyph: edi.constants.ICONS.SEND,
			handler: function () {
				if (!edi.utils.setFocusToDocumentsWithGrid(form)) return;
				moduleData.tab.setLoading(edi.i18n.getMessage('loading.text', null, null, null));
				send(id, moduleData, failure, true, massInvitationsList.length);
			}
		});

		let createMassInvitation = function (successCallback, failureCallback) {
			edi.core.submitUploadForm(
				uploadForm,
				edi.rest.services.FILE.POST,
				'loading.text',
				function (responseFileData) {
					if (responseFileData && responseFileData.data && responseFileData.data.id) {
						let values = edi.utils.collectFormValues(form);
						edi.utils.clearEmptyValues(values);

						values.file = responseFileData.data.id;
						values = JSON.stringify(values);

						let data = {
							data: values,
							docType: edi.constants.DOCUMENT_TYPES.MASS_INVITATION,
							toOrgId: edi.core.getUserOrgID()
						};

						edi.rest.sendRequest(
							edi.rest.services.DOCUMENTS.POST,
							'POST',
							Ext.encode(data),
							function (responseData) {
								successCallback(responseData.data);
							},
							failureCallback
						);
						return;
					}
					failureCallback(responseFileData);
				},
				failureCallback
			);
		};

		const failure = function (data) {
			fileDropper?.clearFileData(true);
			edi.core.logMessage(
				'Error ' + (isEdit ? 'saving ' : 'creating ') + edi.constants.DOCUMENT_TYPES.INVITATION,
				'warn'
			);
			edi.core.showError(edi.utils.formatComplexServerError(data, 'error.server'));
			moduleData && moduleData.tab && 'function' == typeof moduleData.tab.setLoading
				? moduleData.tab.setLoading(false)
				: null;
		};

		let createSaveToDraftButton = createButton({
			cls: [isEdit ? BUTTON_CLS.secondary : BUTTON_CLS.primary],
			text: edi.i18n.getMessage(
				isEdit ? 'ediweb.form.btn.save.to.draft' : 'ediweb.form.btn.create.list',
				null,
				null,
				null
			),
			handler: function () {
				if (!edi.utils.setFocusToDocumentsWithGrid(form)) return;
				const isValidFile = fileDropper.fileValidator(fileDropper.fileData);
				if (!!isValidFile) return;

				moduleData.tab.setLoading(edi.i18n.getMessage('loading.text', null, null, null));
				if (isEdit) {
					let values = edi.utils.collectFormValues(form);
					edi.utils.clearEmptyValues(values);
					let docData = {
						data: Ext.encode(values),
						docType: edi.constants.DOCUMENT_TYPES.MASS_INVITATION
					};
					docData = {
						UPDATE: true,
						UPDATE_PARAMS: docData
					};
					const url = edi.utils.formatString(edi.rest.services.DOCUMENTS.PUT, { documentId: id });
					edi.rest.sendRequest(
						url,
						'PUT',
						Ext.encode(docData),
						function () {
							success(moduleData);
						},
						failure
					);
					return;
				}

				createMassInvitation(
					function (data) {
						const newInvitationId = data?.id ?? null;
						const invitationsCount = parseInt(
							edi.utils.getAttributeByName(data.attributes, 'invitationsCount'),
							10
						);
						const isDuplicate = edi.utils.getObjectProperty(data, 'attributes.duplicates').value === 'true';

						getInvitationDataCount(
							newInvitationId,
							function (dataInvitation) {
								const updateInvitationData = {
									ediDocument: data,
									id: newInvitationId,
									massInvitation: dataInvitation?.data ?? {}
								};
								changeHandler(updateInvitationData);
							},
							failure,
							false,
							invitationsCount,
							isDuplicate
						);
					},
					function (data) {
						failure(data);
					}
				);
			}
		});

		var cancelButton = createButton({
			cls: [BUTTON_CLS.secondary],
			text: edi.i18n.getMessage('ediweb.cancel.btn'),
			handler: function () {
				if (!moduleData || !moduleData.isChanged) {
					edi.modulesHandler.removeModule(moduleData);
					return;
				}
				edi.core.confirm(
					isEdit ? 'cancel.edit.mass.invitation' : 'cancel.new.mass.invitation',
					isEdit ? 'mass.invitation.data.not.saved' : 'entered.data.not.saved',
					function () {
						moduleData.isChanged = false;
						edi.modulesHandler.removeModule(moduleData);
					}
				);
			}
		});

		const rolesContainer = createContainer({
			cls: INVITATION_MASS_TOUR_TARGET_3,
			layout: {
				type: 'grid',
				gap: [0, 24],
				area: [[6, 6]]
			},
			items: [senderRole, receiverRole]
		});

		form = createForm({
			bodyPadding: '24',
			autoScroll: true,
			items: [
				createFormContainer({
					// cls: 'edi-ediweb-form-maxwidth',
					width: 1200,
					gap: 24,
					area: [7, 7, 3, 7, 6, 6],
					colOffset: 1,
					items: [
						title,
						massInvitationNamePanel,
						product,
						massInvitationReceiversPanel,
						rolesContainer,
						errorReportPanel
					]
				}),
				createContainer({
					items: [massInvitationsGrid]
				})
			],
			buttonAlign: 'left',
			buttons: isEdit
				? [createAndSendButton, cancelButton, createSaveToDraftButton]
				: [createSaveToDraftButton, cancelButton],
			listeners: {
				afterrender: function () {
					if (
						isEdit &&
						moduleData.getTourOpts()?.hasErrors &&
						edi.core.getExtraData('user.loadMassInvitationHint') !== 'true'
					) {
						if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
							tourEvents.fireEvent('start_tour', INVITATION_MASS_MODULE_NAME, moduleData);
						}
					}
				}
			}
		});

		form.isValid();
		form.on('validitychange', checkValid);
		edi.utils.processModuleFormChange(form, moduleData);
		return form;
	};
	/**
	 * Checks validity, and enables/disables create button
	 */
	let checkValid = function () {
		const hasInvalid = form.hasInvalidField();
		const isValidFile = fileDropper.fileValidator(fileDropper.fileData);
		return !hasInvalid && !isValidFile;
	};

	/**
	 * Creates action pane above the data panel
	 */

	/**
	 * Renders module
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	let renderData = function (initCallBack) {
		const continueRendering = function (maxFileSizeValue) {
			maxFileSize = maxFileSizeValue;
			let invitationData = moduleData.initData.data;
			let modulePanel = createAddModulePanel();

			if (invitationData && invitationData.id) {
				isEdit = true;
				id = invitationData.id;

				let form = createModuleForm(invitationData);
				modulePanel.add(form);
				moduleData.tab.add(modulePanel);
				if ('function' == typeof initCallBack) {
					initCallBack();
				}
			} else {
				let createModulePanel = function () {
					modulePanel.add(createModuleForm());
					moduleData.tab.add(modulePanel);
					if ('function' == typeof initCallBack) {
						initCallBack();
						if (edi.core.getExtraData('user.createMassInvitationHints') !== 'true') {
							if (edi.modulesHandler.getActiveModule().modName === moduleData.modName) {
								tourEvents.fireEvent('start_tour', INVITATION_MASS_MODULE_NAME, moduleData);
							}
						}
					}
				};

				createModulePanel();
			}
		};

		edi.methods.loadSettingsAndContinue(continueRendering);
	};

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	let onDestroy = function () {
		edi.core.logMessage('Initiated onDestroy for module ' + moduleData.name);
		return true;
	};
};

tours[INVITATION_MASS_MODULE_NAME] = {
	getName: () => INVITATION_MASS_MODULE_NAME,
	getStartErrorText: function () {
		const tour = this;
		return tour.externalOpts?.isEdit && !tour.externalOpts?.hasErrors ? 'error.tour.not.available' : null;
	},
	onTourClosed: function () {
		const tour = this;
		//поставим флаг что юзер прошел все страницы тура
		if (tour.currentStepNum === tour.steps.length - 1) {
			if (tour.externalOpts?.isEdit) {
				edi.core.setExtraData('user.loadMassInvitationHint', 'true');
			} else {
				edi.core.setExtraData('user.createMassInvitationHints', 'true');
			}
		}
	},
	createSteps: function () {
		const tour = this;
		if (!tour.externalOpts?.isEdit) {
			return [
				{
					getTitle: () => edi.i18n.getMessage('invitations.mass.tour.step.1.title'),
					getContent: () => edi.i18n.getMessage('invitations.mass.tour.step.1.content'),
					getTargetEl: (tour) =>
						(tour?.queryRoot || document).querySelector(`.${INVITATION_MASS_TOUR_TARGET_1}`),
					position: 'right'
				},
				{
					getTitle: () => edi.i18n.getMessage('invitations.mass.tour.step.2.title'),
					getContent: () => edi.i18n.getMessage('invitations.mass.tour.step.2.content'),
					getTargetEl: (tour) =>
						(tour?.queryRoot || document).querySelector(`.${INVITATION_MASS_TOUR_TARGET_2}`),
					position: 'right'
				},
				{
					getTitle: () => edi.i18n.getMessage('invitations.mass.tour.step.3.title'),
					getContent: () => edi.i18n.getMessage('invitations.mass.tour.step.3.content'),
					getTargetEl: (tour) =>
						(tour?.queryRoot || document).querySelector(`.${INVITATION_MASS_TOUR_TARGET_3}`),
					position: 'right'
				}
			];
		} else if (tour.externalOpts?.isEdit && tour.externalOpts?.hasErrors) {
			return [
				{
					getTitle: () => edi.i18n.getMessage('invitations.mass.tour.step.4.title'),
					getContent: () => edi.i18n.getMessage('invitations.mass.tour.step.4.content'),
					getTargetEl: (tour) =>
						(tour?.queryRoot || document).querySelector(`.${INVITATION_MASS_TOUR_TARGET_4}`),
					position: 'right'
				}
			];
		} else {
			return null;
		}
	}
};
