import {
	createDetailsModulePanel,
	createFormForModule,
	createPanel,
	createTab,
	createTabPanel,
	TAB_PANEL_CLS
} from '@Components/panels';
import { createDownloadButton } from '@Components/buttons';
import { createActionsColumnConfig, createGrid } from '@Components/grid';
import { createModuleFilterForm } from '@Components/ModuleFilterForm/ModuleFilterForm';
import { createLoccatFormItems, createProdcatFormItems } from '@Edi/modules/catalog/methods';
import { PRODUCT_CATALOG_COLUMN_CONFIG_NAME } from '@Edi/modules/catalog/columns';
import { createLabelForDetails } from '@UIkit/components/fields';
import { createMaxWidthContainerForDetail } from '@UIkit/components/panels';

export class PartnerDetails {
	moduleData;
	objectData;
	grids = {};
	activeTab;
	tabCounter = 0;
	filterObjects = {};
	moduleTabPanel;
	moduleForm;

	/**
	 * Main module initialization method
	 * @param    {Object}        data            module data from modules handler
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	init(data, initCallBack) {
		const me = this;
		me.moduleData = data;
		me.objectData = me.moduleData.initData.data;
		me.activeTab = 0;
		me.renderData(initCallBack);
		return me.onDestroy.bind(me);
	}

	/**
	 * Renders module layout
	 * @param    {Function}    initCallBack    callback that must be called on module initialization finish
	 */
	renderData(initCallBack) {
		const me = this;
		me.moduleData.tab.setLoading();
		me.tabCounter = 0;

		edi.document.actions.changeTabTitle(me.moduleData.tab, me.objectData.name, false);
		me.moduleForm = createFormForModule({
			cls: 'edi-form document-details-form',
			split: true,
			collapseMode: 'mini',
			region: 'north',
			items: me.getFormFields()
		});
		me.moduleTabPanel = me.createModuleTabPanel();
		const modulePanel = createDetailsModulePanel({
			layout: 'border',
			autoScroll: null,
			overflowY: 'auto',
			items: [
				createPanel({
					region: 'center',
					layout: 'border',
					items: [me.moduleForm, me.moduleTabPanel]
				})
			]
		});

		me.moduleData.tab.add(modulePanel);

		if (typeof initCallBack === 'function') {
			initCallBack();
		} else {
			me.moduleData.tab.setLoading(false);
		}
	}

	createFilterExportButton({ exportUrl }) {
		const me = this;
		return createDownloadButton(
			function () {
				edi.rest.downloadFile(
					edi.utils.formatString(exportUrl, {
						partnerId: me.objectData.id
					}),
					me.objectData.id,
					null,
					me.moduleData.tab
				);
			},
			{
				disabled: true
			}
		);
	}

	createModuleFilter({ url, grid, gridName, exportButton, createFilterFormItemsFn }) {
		const me = this;
		const { formItemsMap, items } = createFilterFormItemsFn();
		const fireSearch = function () {
			if (!me.filterObjects[gridName]) {
				me.filterObjects[gridName] = edi.filters.createGridFilter(url, filterForm, grid, null, undefined, {
					preventExceptions: true
				});
			}
			me.filterObjects[gridName].filter();
		};
		const filterForm = createModuleFilterForm(
			{
				header: false,
				formItemsMap,
				items
			},
			fireSearch,
			null,
			{
				beforeDefault: [exportButton]
			}
		);
		return { fireSearch, filterForm };
	}

	getCatalogConfig({ catalogType }) {
		const me = this;
		let columnsName,
			url,
			exportUrl,
			model,
			gridName,
			createFilterFormItemsFn = () => ({}),
			isProdcat = false;

		if (catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT) {
			isProdcat = true;
			gridName = 'prodcat';
			model = 'PRODCAT_LINE';
			columnsName = PRODUCT_CATALOG_COLUMN_CONFIG_NAME;
			url = edi.rest.services.PARTNER.CATALOGS.PRODCAT.FILTER.GET;
			exportUrl = edi.rest.services.CATALOGS.PRODCAT.DOWNLOAD.GET;
			createFilterFormItemsFn = createProdcatFormItems;
		} else if (catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT) {
			gridName = 'loccat';
			model = 'LOCCAT_LINE';
			columnsName = 'addresscatalog_lines';
			url = edi.rest.services.PARTNER.CATALOGS.LOCCAT.FILTER.GET;
			exportUrl = edi.rest.services.CATALOGS.LOCCAT.DOWNLOAD.GET;
			createFilterFormItemsFn = createLoccatFormItems;
		} else if (catalogType === edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT) {
			gridName = 'delcat';
			columnsName = 'delcat_lines';
			model = 'DELCAT_LINE';
			url = edi.rest.services.PARTNER.CATALOGS.DELCAT.FILTER.GET;
			exportUrl = edi.rest.services.CATALOGS.DELCAT.DOWNLOAD.GET;
			createFilterFormItemsFn = createLoccatFormItems;
		}

		url = edi.utils.formatString(url, {
			type: catalogType,
			partnerId: me.objectData.id
		});

		return {
			columnsName,
			url,
			exportUrl,
			model,
			gridName,
			createFilterFormItemsFn,
			isProdcat
		};
	}

	getGridColumns({ columnsName, catalogType }) {
		return edi.columns.get(columnsName).concat([
			createActionsColumnConfig({
				width: edi.utils.getActionColumnWidth(1),
				items: [
					{
						glyph: edi.constants.ICONS.DETAILS,
						handler: function (grid, rowIndex) {
							const recordData = grid.getStore().getAt(rowIndex).getData();
							edi.methods.catalog.item.details(recordData, catalogType);
						}
					}
				]
			})
		]);
	}

	createGrid({ isProdcat, model, exportButton, catalogType, columnsName }) {
		const me = this;
		const columns = me.getGridColumns({ columnsName, catalogType });
		return createGrid({
			proxyConfig: {
				type: 'ajax',
				url: null
			},
			storeConfig: {
				model: edi.models.getModel(model),
				autoLoad: false,
				remoteSort: true,
				sortOnLoad: true,
				sorters: {
					property: isProdcat ? 'creationDate' : 'name',
					direction: isProdcat ? 'DESC' : 'ASC'
				},
				listeners: {
					load: function (comp, records, successful) {
						if (exportButton) {
							exportButton.setDisabled(!successful);
						}
					}
				}
			},
			gridConfig: {
				cls: 'tab-grid with-filter',
				columns: columns,
				region: 'center',
				border: 0,
				autoScroll: true,
				disableSelection: true,
				listeners: {
					celldblclick: function (view, td, cellIndex, record, tr, rowIndex) {
						const recordData = record.getData();
						edi.methods.catalog.item.details(recordData, catalogType);
					}
				}
			}
		});
	}

	// Create catalog tab base on catalogType
	createCatalogTab(catalogType) {
		const me = this;
		const { columnsName, url, model, gridName, isProdcat, exportUrl, createFilterFormItemsFn } =
			me.getCatalogConfig({
				catalogType
			});
		const exportButton = me.createFilterExportButton({ exportUrl });
		const grid = me.createGrid({ isProdcat, model, exportButton, catalogType, columnsName });
		const { filterForm, fireSearch } = me.createModuleFilter({
			url,
			grid,
			gridName,
			exportButton,
			createFilterFormItemsFn
		});

		me.grids[gridName] = grid;
		let tabIndex = me.tabCounter++;

		const tab = createTab({
			title: edi.i18n.getMessage('catalog.' + gridName + '.details'),
			closable: false,
			bodyPadding: 0,
			layout: 'border',
			border: 0,
			listeners: {
				activate: function () {
					me.activeTab = tabIndex;
					me.objectData.activeTab = me.activeTab;
				},
				render: function () {
					fireSearch();
				}
			},
			autoScroll: false,
			items: [filterForm, grid]
		});
		tab.grid = grid;
		return tab;
	}

	/**
	 * Creates tab panel
	 * @returns {Object}s
	 */
	createModuleTabPanel() {
		const me = this;
		let tabs = [];

		if (edi.permissions.hasPermission('READ_CATALOG_V2')) {
			tabs = tabs.concat([
				me.createCatalogTab(edi.constants.DOCUMENT_TYPES.LEGACY_DELCAT),
				me.createCatalogTab(edi.constants.DOCUMENT_TYPES.LEGACY_LOCCAT),
				me.createCatalogTab(edi.constants.DOCUMENT_TYPES.LEGACY_PRODCAT)
			]);
		}

		return createTabPanel(
			{
				activeTab: me.activeTab,
				cls: [TAB_PANEL_CLS.simple, 'module-tabs'],
				items: tabs
			},
			true
		);
	}

	createFormFields() {
		return {
			name: {
				order: 10,
				methodDetail: (objectData) =>
					createLabelForDetails({
						title: edi.i18n.getMessage('company.name'),
						text: objectData.name
					})
			},
			country: {
				order: 20,
				methodDetail: (objectData) => {
					let countryValue;
					if (objectData.country) {
						const countryStore = edi.stores.initOrgCountryStore();
						const recordIndex = countryStore.find('id', objectData.country);
						if (recordIndex !== -1) {
							const record = countryStore.getAt(recordIndex);
							countryValue = record.get('name');
						} else {
							countryValue = edi.i18n.getMessage('country.other');
						}
					}
					return createLabelForDetails({
						title: edi.i18n.getMessage('company.country'),
						text: countryValue
					});
				}
			},
			regNr: {
				order: 30,
				methodDetail: (objectData) =>
					createLabelForDetails({
						title: edi.i18n.getMessage('company.util.reg.nr'),
						text: objectData.regNr
					})
			},
			orgType: {
				order: 40,
				methodDetail: (objectData) =>
					createLabelForDetails({
						title: edi.i18n.getMessage('organization.type'),
						text:
							objectData.orgType === edi.constants.ORGANIZATION_TYPES.INDIVIDUAL
								? edi.i18n.getMessage('company.type.individual')
								: edi.i18n.getMessage('company.type.company')
					})
			},
			iln: {
				order: 50,
				methodDetail: (objectData) =>
					createLabelForDetails({
						title: edi.i18n.getMessage('company.gln.short'),
						text: objectData.iln
					})
			},
			phones: {
				order: 60,
				methodDetail: (objectData) =>
					createLabelForDetails({
						title: edi.i18n.getMessage('company.contact.phone'),
						text: objectData.phones
					})
			},
			inn: {
				order: 70,
				methodDetail: (objectData) =>
					createLabelForDetails({
						title: edi.i18n.getMessage('company.inn.short'),
						text: objectData.inn
					})
			},
			faxes: {
				order: 80,
				methodDetail: (objectData) =>
					createLabelForDetails({
						title: edi.i18n.getMessage('company.contact.fax'),
						text: objectData.faxes
					})
			},
			kpp: {
				order: 90,
				methodDetail: (objectData) =>
					createLabelForDetails({
						title: edi.i18n.getMessage('company.kpp.short'),
						text: objectData.kpp
					})
			}
		};
	}

	getFormFields() {
		const me = this;
		const formFieldsConfig = me.createFormFields();
		const fieldsCfg = Object.values(formFieldsConfig ?? {});
		const gridArea = [];
		const fields = fieldsCfg
			.sort((a, b) => a.order - b.order)
			.map((field) => field.methodDetail(me.objectData))
			.filter(Boolean);
		fields.forEach((field, i) => {
			if (!(i % 2)) {
				gridArea.push([6, 6]);
			}
		});
		return createMaxWidthContainerForDetail({
			userCls: edi.constants.FIELD_BLOCK_CLASS_FOR_TESTERS,
			layout: {
				type: 'grid',
				gap: [8, 16],
				area: gridArea
			},
			items: fields
		});
	}

	/**
	 * Routine that must be done before module destroy
	 * @return    {Boolean}        false to stop module destroy
	 */
	onDestroy() {
		const me = this;
		for (let i in me.filterObjects) {
			if (me.filterObjects.hasOwnProperty(i) && me.filterObjects[i].searchTimeout) {
				clearTimeout(me.filterObjects[i].searchTimeout);
			}
		}
		edi.core.logMessage('Initiated onDestroy for module ' + me.moduleData.name, null);
		return true;
	}
}

Ext.namespace('edi.modules');
edi.modules['partner.details'] = PartnerDetails;
